import { FC, useContext, useMemo, useState } from 'react';
import { DKLabel, DKIcons, DKIcon } from 'deskera-ui-library';
import { useAppSelector } from '../../../../../Redux/Hooks';
import { selectDocumentFormDataByKeys } from '../../../../../Redux/Slices/DocumentSlice';
import { DOCUMENT_KEYS } from '../../../Utilities/DocConstants';
import {
  CURRENCY_PRECISION,
  DOCUMENT_MODE
} from '../../../../../Constants/Constant';
import NumberFormatService from '../../../../../Services/NumberFormat';
import { CommonDraftPropsContext } from '../../../Utilities/DocContext';
import { activeTenantInfo } from '../../../../../Redux/Slices/AuthSlice';
import { roundingOffStr } from '../../../../../SharedComponents/DocumentForm/NewDocumentHelper';
import cloneDeep from 'lodash/cloneDeep';

import { updateCurrencyAndExchangeRate } from '../../../Helper/Common/DocDataHelper';
import { getDocumentByIDFromStore } from '../../../Helper/DocumentHelper';
import Utility from '../../../../../Utility/Utility';

interface ICurrencyExchangeProps {}

const DocCurrencyExchange: FC<ICurrencyExchangeProps> = () => {
  const { draftId, documentMode } = useContext(CommonDraftPropsContext);
  const tenantInfo = useAppSelector(activeTenantInfo);
  const document = getDocumentByIDFromStore(draftId)?.populateFormData || {};

  const [exchangeRate, currency, totalAmount] = useAppSelector(
    selectDocumentFormDataByKeys(draftId, [
      DOCUMENT_KEYS.EXCHANGE_RATE,
      DOCUMENT_KEYS.CURRENCY,
      DOCUMENT_KEYS.TOTAL_AMOUNT
    ])
  );

  const [currentExchangeRate, setExchangeRate] = useState(
    +roundingOffStr(exchangeRate ? 1 / exchangeRate : 0, CURRENCY_PRECISION)
  );

  const totalAmountInBaseCurrency = useMemo(() => {
    let baseTotal =
      totalAmount && exchangeRate
        ? Utility.roundOff((totalAmount * 1) / exchangeRate)
        : 0;
    return baseTotal;
  }, [exchangeRate, totalAmount]);

  return (
    <div className="row parent-width mb-l justify-content-between align-items-start number-hide-arrows fs-s">
      <div className="parent-width">
        <DKLabel
          text={`Conversion: ${
            exchangeRate &&
            NumberFormatService.getNumber(totalAmountInBaseCurrency)
          }(${tenantInfo.currency}) at`}
        />
        <div
          className={
            'row border rounded border-gray-300 w-full text-sm font-normal tracking-normal placeholder-gray-600::placeholder p-1 mt-s ' +
            'focus:outline-none focus:ring-2 focus:ring-blue-200'
          }
        >
          <div
            className="row currency-select-trigger align-items-center content-start"
            // style={{ width: 'fit-content' }}
          >
            <div className="ml-1">{`1 ${currency}`}</div>
            <div className="currency-dropdown-flag ml-2">
              <span
                className={`currency-dropdown-selector-flag flag ${currency}`}
              ></span>
            </div>
            <DKIcon
              className="ml-r"
              style={{
                transform: 'rotate(90deg)'
              }}
              src={DKIcons.ic_sort}
            />
          </div>
          <div className="row align-items-start content-end ml-s">
            <input
              onBlur={(e: any) => {
                const rate = e.target.value ? +e.target.value : exchangeRate;
                if (
                  +e.target.value !== 0 &&
                  exchangeRate !== 1 / (rate as number)
                ) {
                  updateCurrencyAndExchangeRate(
                    draftId,
                    documentMode,
                    cloneDeep(document),
                    currency,
                    1 / rate
                  );
                }
              }}
              className={`text-align-right outline-none hover:bg-blue-100 focus:bg-blue-100`}
              value={currentExchangeRate}
              type={'number'}
              onChange={(e: any) => {
                setExchangeRate(+e.target.value);
              }}
              style={{ width: '62%' }}
              disabled={documentMode === DOCUMENT_MODE.VIEW}
            />
            <div className="ml-1">{`${tenantInfo.currency}`}</div>
            <div className="currency-dropdown-flag ml-2">
              <span
                className={`currency-dropdown-selector-flag flag ${tenantInfo.currency}`}
              ></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DocCurrencyExchange;
