import {
  TypedUseSelectorHook,
  shallowEqual,
  useDispatch,
  useSelector
} from 'react-redux';
import type { RootState, AppDispatch } from './Store';

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

/**
 * @description
 * Performs shallow equality check on inner properties of array
 * Ignores the reference check on array
 */
export const isSelectorArraysEqual = (left: unknown[], right: unknown[]) => {
  if (!left && !right) return true;
  else if (!Array.isArray(left) || !Array.isArray(right)) return false;
  else if (left.length !== right.length) return false;
  else if (left.length === 0) return true;

  return left.every((leftValue, index) =>
    shallowEqual(leftValue, right[index])
  );
};
