import { useCallback, useContext, useEffect } from 'react';
import { shallowEqual } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { CommonDraftPropsContext } from '../../Utilities/DocContext';
import { useAppDispatch, useAppSelector } from '../../../../Redux/Hooks';
import {
  updateMultipleKeysInDocument,
  selectDocumentFormDataByKeys
} from '../../../../Redux/Slices/DocumentSlice';
import { activeTenantInfo } from '../../../../Redux/Slices/AuthSlice';
import {
  isDocumentInDraftMode,
  isEditableDraft,
  isPurchaseDocument
} from '../../Utilities/DocCommonUtils';
import Utility from '../../../../Utility/Utility';
import {
  getFormattedAddress,
  isDocContactInactive
} from '../../../../SharedComponents/DocumentForm/NewDocumentHelper';
import DocContactSelector from '../Common/DocContactSelector';
import DocAddress from '../Common/DocAddress';
import { DraftTypes } from '../../../../Models/Drafts';
import DocAddressCustomFieldHolder from './DocAddressCustomFieldHolder';
import {
  BOOKS_ADDRESS_TYPES,
  DOCUMENT_MODE
} from '../../../../Constants/Constant';
import { DOCUMENT_KEYS } from '../../Utilities/DocConstants';

const BillToSection = () => {
  const { t } = useTranslation();
  const { draftId, draftType, documentMode } = useContext(
    CommonDraftPropsContext
  );
  const [
    billTo,
    contact,
    documentType,
    receiveGoodsStatus,
    fulfillmentStatus,
    paymentStatus,
    totalAmount,
    duplicate,
    isConverting,
    customLocationCFValue
  ] = useAppSelector(
    selectDocumentFormDataByKeys(draftId, [
      'billTo',
      'contact',
      'documentType',
      'receiveGoodsStatus',
      'fulfillmentStatus',
      'paymentStatus',
      'totalAmount',
      'duplicate',
      'isConverting',
      DOCUMENT_KEYS.CUSTOM_LOCATION_CF_VALUE
    ]),
    shallowEqual
  );
  const dispatch = useAppDispatch();
  const tenantInfo = useAppSelector(activeTenantInfo);

  const isPurchaseTypeDoc = isPurchaseDocument(documentType);

  let buyerName = '';
  if (isPurchaseTypeDoc) {
    buyerName = tenantInfo?.name;
    if (!Utility.isEmpty(customLocationCFValue)) {
      buyerName = customLocationCFValue?.locationDetails?.company;
    }
  } else {
    buyerName = !Utility.isEmpty(contact?.name)
      ? `${contact?.name}-(${contact?.currencyCode})`
      : '';
  }

  buyerName = buyerName || '-';

  const getBuyerBillingAddressList = useCallback((): any[] => {
    return (
      (isPurchaseTypeDoc
        ? tenantInfo?.billingAddresses
        : contact?.billingAddress) || []
    );
  }, [
    contact?.billingAddress,
    isPurchaseTypeDoc,
    tenantInfo?.billingAddresses
  ]);

  useEffect(() => {
    if (Utility.isEmpty(getFormattedAddress(billTo))) {
      // Preferred address
      let buyerPreferredBillingAddress = getBuyerBillingAddressList().find(
        (address) => address.preferred
      );
      if (Utility.isEmptyObject(buyerPreferredBillingAddress)) {
        buyerPreferredBillingAddress = getBuyerBillingAddressList()?.[0];
      }

      dispatch(
        updateMultipleKeysInDocument({
          draftId,
          keysToUpdate: {
            [DOCUMENT_KEYS.BILL_TO]: buyerPreferredBillingAddress
          }
        })
      );
    }
  }, [billTo, dispatch, draftId, getBuyerBillingAddressList]);

  const showInactiveIndicator =
    !isPurchaseTypeDoc &&
    (documentMode === DOCUMENT_MODE.COPY ||
      documentMode === DOCUMENT_MODE.NEW) &&
    (duplicate || isConverting) &&
    isDocContactInactive(contact);

  const allowContactSelection =
    isDocumentInDraftMode(draftType) && !isPurchaseTypeDoc;
  /**
   * @todo TBD, Do we need this permission as present in NewDocument2 for canEditContact?
   * GranularPermissionsHelper.hasPermissionFor(PERMISSIONS_BY_MODULE.CONTACTS.CREATE); */

  return (
    <div className="column document-address-width">
      <DocContactSelector
        title={t(`DOCUMENT.BILL_TO`)}
        contactName={buyerName}
        needSelectorButton={false}
        canEdit={allowContactSelection}
        canValidate={true}
        showInactiveIndicator={showInactiveIndicator}
      />
      <DocAddress
        isPurchaseDocument={isPurchaseTypeDoc}
        isSellerInfo={false}
        contact={contact || null}
        address={billTo}
        placeholder={draftType === DraftTypes.READONLY ? '-' : 'Select address'}
        canEdit={isEditableDraft({
          draftType,
          documentType,
          receiveGoodsStatus,
          fulfillmentStatus,
          paymentStatus,
          totalAmount
        })}
        addressList={getBuyerBillingAddressList() || []}
        preferCustomLocationDetails={
          isPurchaseTypeDoc && !Utility.isEmpty(customLocationCFValue)
        }
        locationDetails={
          isPurchaseTypeDoc && !Utility.isEmpty(customLocationCFValue)
            ? customLocationCFValue?.locationDetails
            : null
        }
        dataKey={'billTo'}
      />
      {!isPurchaseTypeDoc && Utility.isNotEmpty(billTo?.customFields) && (
        <DocAddressCustomFieldHolder
          addressType={BOOKS_ADDRESS_TYPES.BILL_TO}
        />
      )}
    </div>
  );
};

export default BillToSection;
