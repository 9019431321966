// Imported CSS for draft model container
import './DraftPopup.scss';

// Imported React DOM for create portal in overlay container
import ReactDOM from 'react-dom';

// Imported deskera-ui-library
import {
  DKIcons,
  DKLabel,
  DKButton,
  DKListPicker,
  DKSpinner,
  DKTooltipWrapper,
  DKIcon
} from 'deskera-ui-library';

// Imported reducers from drafts slice
import {
  selectDrafts,
  removeDraft,
  setDraftActionAvailibility,
  setDraftValidationDisplayStatus
} from '../../Redux/Slices/DraftsSlice';

// Icons for the draft popup
import ic_minus from '../../Assets/Icons/ic_minus.png';
import ic_expand from '../../Assets/Icons/ic_expand.svg';
import ic_shrink from '../../Assets/Icons/ic_shrink.svg';

// Imported dispatch & appselector for sending or reciving data from store exported from Redux Hooks
import { useAppSelector, useAppDispatch } from '../../Redux/Hooks';
import DraftForms from './DraftForms';
import { useState, useEffect, useRef, Fragment } from 'react';
import DraftService from '../../Services/Drafts';
import { DraftTypes, DOCUMENT_TYPE_LIST } from '../../Models/Drafts';
import { useTranslation } from 'react-i18next';
import {
  APPROVAL_STATUS,
  APPROVAL_STATUS_LIST,
  FULFILLMENT_STATUS,
  LABELS,
  PAYMENT_STATUS,
  RECEIVED_GOODS_STATUS
} from '../../Constants/Constant';
import { localizedText } from '../../Services/Localization/Localization';
import PopupWrapperService from '../../Services/PopupWrapper';
import {
  getLogButton,
  isSalesOrderVisible
} from '../DocumentForm/NewDocumentHelper';
import { DynamicPopupWrapper } from '../PopupWrapper';
import { DRAFT_DOC_SUPPORTED_MODULE_LABELS } from '../../Components/Document/Utilities/DocConstants';
import { onDocumentViewChanged } from '../../Redux/Slices/DocumentSlice';
import TransactionLog from '../TransactionLogs';
import {
  selectShowMainDocsInFullScreen,
  setShowMainDocsInFullScreen
} from '../../Redux/Slices/CommonDataSlice';
import Utility from '../../Utility/Utility';
import { docReplaceURLToModuleURL } from '../../Components/Document/Utilities/DocCommonUtils';
import { useHistory } from 'react-router-dom';

// Created a const for get element of overlay contaier
const portalDiv = document.getElementById(
  'overlay-container'
) as HTMLDivElement;

export default function DraftPopup() {
  const childRefForm = useRef<any>();

  // Fetching drafts from store
  const draftsValue: any = useAppSelector(selectDrafts);
  let prevDraftsCount = useRef<number>(0);
  /*     const columnConfig: any = useAppSelector(selectDraftsColumnConfig); */
  // const isSavedColumnId: string = useAppSelector(isSaveColumnId);
  const { t } = useTranslation();
  const showMainDocsInFullscreen = useAppSelector(
    selectShowMainDocsInFullScreen
  );

  const [showSavePicker, setShowSavePicker] = useState(false);
  const [cancelClicked, setCancelClicked] = useState(false);
  const [showTransactionLog, setShowTransactionLog] = useState(false);
  const [transactionData, setTransactionData] = useState<any>();

  let history = useHistory();

  // Dispatching value to store
  const dispatch = useAppDispatch();

  const docsSupportedForFullScreen = [
    LABELS.INVOICES,
    LABELS.BILLS,
    LABELS.SALES_ORDER,
    LABELS.PURCHASE_ORDERS,
    LABELS.QUOTES,
    LABELS.REQUISITION,
    LABELS.EXPENSE_BILL
  ];

  // If any draft opened as center then root pointer event for root node
  useEffect(() => {
    const maximizedDraftCount = draftsValue
      ? draftsValue.filter((draft: any) => draft.isMaximized).length
      : 0;

    if (prevDraftsCount.current !== maximizedDraftCount) {
      handlePointerEvent(maximizedDraftCount > 0);

      if (prevDraftsCount.current < maximizedDraftCount) {
        PopupWrapperService.increaseZIndex();
      } else if (PopupWrapperService.zIndex > 1) {
        PopupWrapperService.decreaseZIndex();
      }

      prevDraftsCount.current = maximizedDraftCount;
    }
  }, [draftsValue]);

  // Set view of maximized or minized
  const setViewOfDrafts = (data: any, isMaximized: boolean) => {
    dispatch(onDocumentViewChanged({ draftId: data.id, isMaximized }));
    if (!isMaximized) {
      childRefForm.current.savePopulateData(data.id);
    }
  };

  // Handle pointer events when modal box appear
  const handlePointerEvent = (hasMaximizedDrafts: boolean) => {
    if (!portalDiv) return;

    if (hasMaximizedDrafts) {
      portalDiv.style.position = 'relative';
      portalDiv.style.zIndex = `${PopupWrapperService.zIndex}`;
    } else {
      portalDiv.style.position = 'static';
      portalDiv.style.zIndex = 'unset';
    }
  };

  // Open selected draft on center align
  const draftAlignCenter = (isCenter: boolean, data: any) => {
    dispatch(
      onDocumentViewChanged({ draftId: data.id, isMaximized: isCenter })
    );

    if (isCenter) {
      if (!DOCUMENT_TYPE_LIST.includes(data.type) && data.isMaximized) {
        childRefForm.current.savePopulateData(data.id);
      }
    } else {
      childRefForm.current.savePopulateData(data.id);
    }
  };

  //  Render draft content section
  const renderDraftDataOnView = (draftData: any) => {
    /* let formPayload = { draftData, columnConfig }; */

    let formDraftData = draftData;

    if (draftData.isLoading) {
      formDraftData = { ...draftData, draftType: DraftTypes.READONLY };
    }
    return draftData.isMaximized ? (
      <DraftForms ref={childRefForm} draftData={formDraftData} />
    ) : // <div style={{ display: 'none' }}>
    //   <DraftForms ref={childRefForm} draftData={formDraftData} />
    // </div>
    null;
  };

  //   Remove drafts from store
  const onCloseDraft = (data: any) => {
    if (!Utility.isEmpty(data) && !data.isLoading) {
      setCancelClicked(true);
      if (!Utility.isEmpty(childRefForm?.current)) {
        data.populateFormData?.isConverting
          ? childRefForm.current?.closeDraftPopup()
          : childRefForm.current?.saveAsDraft(data.id);
      } else {
        dispatch(removeDraft(data.id));
      }
    }
  };

  //   Remove drafts from store
  const saveAsDraft = (data: any) => {
    if (!Utility.isEmpty(data) && !data.isLoading) {
      setCancelClicked(false);
      childRefForm.current?.saveAsDraft(data.id);
    }
  };

  const saveDraft = (data: any, closeDoc = false) => {
    if (!Utility.isEmpty(data) && !data.isLoading) {
      setCancelClicked(false);
      dispatch(setDraftValidationDisplayStatus({ id: data.id, status: false }));
      if (data.data.draftType === DraftTypes.UPDATE) {
        childRefForm.current?.update(data.id);
      } else {
        childRefForm.current?.save(
          data.id,
          closeDoc,
          data?.data?.isCashInvoice
        );
      }
    }
  };

  const removeDrafts = async (draftdata: any) => {
    if (!Utility.isEmpty(draftdata) && !draftdata.isLoading) {
      setCancelClicked(true);
      dispatch(setDraftActionAvailibility({ id: draftdata.id, status: true }));
      if (childRefForm.current) {
        childRefForm.current?.closeDraftPopup();
      } else {
        docReplaceURLToModuleURL(history);
      }
      dispatch(removeDraft(draftdata.id));
      if (draftdata && !draftdata.isSaved) {
        try {
          await DraftService.deleteDraftRecords(
            draftdata.id,
            draftdata.data.tableId
          ).catch((err) => {
            console.error('Error deleting draft: ', err);
          });
        } catch (err) {
          console.error('Error deleting draft: ', err);
        }
      }
    }
  };

  function onMemoSave(data: any) {
    if (!data.isLoading) {
      setCancelClicked(false);
      childRefForm.current.updateMemo(data.id);
    }
  }

  const allowedMaximizedView = (data: any) => {
    if (DOCUMENT_TYPE_LIST.includes(data.type)) {
      return false;
    } else {
      return true;
    }
  };

  const getOnlySave = (title: any) => {
    if (title === LABELS.FA_BILL || title === LABELS.FA_ORDER) {
      return true;
    }
    return false;
  };

  const isShowSaveButton = (data: any) => {
    let isAllowSave = true;
    if (data) {
      if (data.isErrorOnSave) {
        isAllowSave = !data.isErrorOnSave;
      }
    }
    return isAllowSave;
  };

  const showFullScreenIcon = (data: any) =>
    docsSupportedForFullScreen.includes(data?.data?.type);

  const getShrinkExpandButton = (data: any) => {
    return (
      <DKButton
        icon={showMainDocsInFullscreen ? ic_shrink : ic_expand}
        onClick={() => {
          dispatch(setShowMainDocsInFullScreen(!showMainDocsInFullscreen));
        }}
        disabled={data.isLoading}
        className="border-m mr-r"
      />
    );
  };

  // Render draft view actions
  const renderDraftViewActions = (data: any) => {
    let saveOptions = ['Save as draft', 'Save & close'];
    if (data?.data?.draftType === DraftTypes.READONLY) {
      return (
        <>
          <>
            <DKButton
              icon={DKIcons.ic_arrow_up2}
              onClick={() => setViewOfDrafts(data, true)}
              className="bg-white border-m mr-r"
            />
            <DKButton
              icon={DKIcons.ic_close_}
              onClick={() =>
                data.data.draftType === DraftTypes.DRAFT ||
                data.data.draftType === DraftTypes.NEW
                  ? onCloseDraft(data)
                  : removeDrafts(data)
              }
              className="bg-white border-m"
            />
          </>
          {data.data.populateFormData.salesInvoiceItems &&
            !data?.data?.hideSave &&
            data.isMaximized && (
              <div
                className={`row border-radius-m justify-content-between ${
                  data.isLoading ? 'border-m' : 'bg-button'
                } position-relative`}
                style={{ borderColor: data.isLoading ? '' : 'transparent' }}
              >
                <div className={`row`}>
                  <DKButton
                    title={
                      data.isLoading && !cancelClicked
                        ? 'Saving Memo'
                        : 'Save Memo'
                    }
                    onClick={() =>
                      setTimeout(() => {
                        onMemoSave(data);
                      }, 100)
                    }
                    disabled={data.isLoading}
                    className={`column border-radius-none ${
                      data.isLoading ? 'text-gray' : 'text-white'
                    }`}
                    style={{
                      paddingRight: 12
                    }}
                  />
                  {data.isLoading && !cancelClicked && (
                    <DKSpinner
                      iconClassName="ic-s"
                      className="column pl-xs pr-m"
                    />
                  )}
                </div>
              </div>
            )}
        </>
      );
    }

    return data.isMaximized ? (
      <>
        {!data.hideMinimizer ? (
          <DKButton
            icon={ic_minus}
            onClick={() => setViewOfDrafts(data, false)}
            disabled={data.isLoading}
            className="border-m mr-r"
          />
        ) : null}
        {showFullScreenIcon(data) && getShrinkExpandButton(data)}
        {allowedMaximizedView(data) ? (
          data.isCenterAlign ? (
            <DKButton
              icon={DKIcons.ic_import}
              onClick={() => draftAlignCenter(false, data)}
              className="border-m mr-r"
            />
          ) : (
            <DKButton
              icon={DKIcons.ic_export}
              onClick={() => draftAlignCenter(true, data)}
              className="border-m mr-r"
            />
          )
        ) : (
          ''
        )}
        {getLogButton(data?.data) && (
          <DKButton
            title="Logs"
            onClick={() => {
              setTransactionData(data?.data?.populateFormData);
              setShowTransactionLog(true);
            }}
            disabled={data.isLoading}
            className={
              data.isLoading
                ? 'border-m text-gray mr-r'
                : 'bg-white border-m mr-r'
            }
          />
        )}
        <DKButton
          title="Close"
          onClick={() => {
            (data.data.draftType === DraftTypes.DRAFT &&
              !data.data.draftCode) ||
            data.data.draftType === DraftTypes.NEW ||
            data.data.draftType === DraftTypes.COPY
              ? onCloseDraft(data)
              : removeDrafts(data);
            // dispatch(fetchInvoices()); // Why this call in draft popup
          }}
          disabled={data.isLoading}
          className={
            data.isLoading ? 'border-m text-gray' : 'bg-white border-m'
          }
        />
        <div
          className={`row border-radius-m justify-content-between ${
            data.isLoading ? 'border-m' : 'bg-button'
          } position-relative`}
          style={{ borderColor: data.isLoading ? '' : 'transparent' }}
        >
          {isShowSaveButton(data) && (
            <div className={`row`}>
              <DKButton
                title={data.isLoading && !cancelClicked ? 'Saving' : 'Save'}
                onClick={() => {
                  dispatch(
                    setDraftValidationDisplayStatus({
                      id: data.id,
                      status: true
                    })
                  );
                  setTimeout(() => {
                    saveDraft(data);
                  }, 100);
                }}
                disabled={data.isLoading || data.isErrorOnSave}
                className={`column border-radius-none ${
                  data.isLoading ? 'text-gray' : 'text-white'
                }`}
                style={{
                  paddingRight:
                    data.data.draftType !== DraftTypes.UPDATE &&
                    !getOnlySave(data.data.title)
                      ? 0
                      : 12
                }}
              />
              {data.isLoading && !cancelClicked && (
                <DKSpinner iconClassName="ic-s" className="column pl-xs pr-m" />
              )}
            </div>
          )}

          {data.data.draftType !== DraftTypes.UPDATE &&
          !data.isLoading &&
          isShowSaveButton(data) &&
          !getOnlySave(data.data.title) ? (
            <Fragment>
              <DKButton
                icon={
                  data.isLoading
                    ? DKIcons.ic_arrow_down2
                    : DKIcons.white.ic_arrow_down2
                }
                disabled={data.isLoading}
                className={`border-radius-none`}
                onClick={() => setShowSavePicker(true)}
              />
              {showSavePicker ? (
                <DKListPicker
                  data={saveOptions}
                  className="position-absolute z-index-3 border-m shadow-m"
                  style={{
                    top: 40,
                    right: 0,
                    width: 'max-content'
                  }}
                  onSelect={(index: number, title: string) => {
                    setShowSavePicker(false);
                    switch (index) {
                      case 0:
                        saveAsDraft(data);
                        break;
                      case 1:
                        saveDraft(data, true);
                        break;
                    }
                  }}
                  onClose={() =>
                    setTimeout(() => setShowSavePicker(false), 100)
                  }
                />
              ) : null}
            </Fragment>
          ) : null}
        </div>
      </>
    ) : (
      <>
        {allowedMaximizedView(data) ? (
          <DKButton
            icon={DKIcons.ic_arrow_up2}
            onClick={() => setViewOfDrafts(data, true)}
            className="bg-white border-m mr-r"
          />
        ) : (
          ''
        )}

        {allowedMaximizedView(data) ? (
          <DKButton
            icon={DKIcons.ic_arrow_up2}
            onClick={() => setViewOfDrafts(data, true)}
            className="bg-white border-m mr-r"
          />
        ) : (
          ''
        )}
        {data.isCenterAlign ? (
          <DKButton
            icon={DKIcons.ic_import}
            onClick={() => draftAlignCenter(false, data)}
            className="border-m mr-r"
          />
        ) : (
          <DKButton
            icon={DKIcons.ic_arrow_up2}
            onClick={() => draftAlignCenter(true, data)}
            className="bg-white border-m mr-r"
          />
        )}
        <DKButton
          icon={DKIcons.ic_close_}
          onClick={() =>
            data.data.draftType === DraftTypes.DRAFT ||
            data.data.draftType === DraftTypes.NEW
              ? onCloseDraft(data)
              : removeDrafts(data)
          }
          className="bg-white border-m"
        />
      </>
    );
  };

  const getPaymentBadgeData = (item: any) => {
    switch (item.data?.type || item.type) {
      case LABELS.BILLS:
      case LABELS.INVOICES:
      case LABELS.EXPENSE_BILL:
      case LABELS.FA_BILL:
        switch (item.populateFormData?.paymentStatus) {
          case PAYMENT_STATUS.RECEIVED:
            return {
              title: 'paid',
              color: 'bg-chip-green text-green'
            };
          case PAYMENT_STATUS.PARTIAL:
            return {
              title: 'Partially Paid',
              color: 'bg-chip-blue text-blue'
            };
          case PAYMENT_STATUS.PENDING:
          default:
            return {
              title: 'unpaid',
              color: 'bg-chip-red text-red'
            };
        }
      default:
        return null;
    }
  };
  const getFullfillmentBadgeData = (item: any) => {
    switch (item.data?.type || item.type) {
      case LABELS.QUOTES:
        if (isSalesOrderVisible()) {
          return null;
        }
        switch (item.populateFormData?.fulfillmentStatus) {
          case FULFILLMENT_STATUS.FULLY_FULFILLED:
            return {
              title: localizedText('Fulfilled'),
              color: 'bg-chip-green text-green'
            };
          case FULFILLMENT_STATUS.PARTIAL_FULFILLED:
            return {
              title: `Partially ${localizedText('Fulfilled')}`,
              color: 'bg-chip-blue text-blue'
            };
          case FULFILLMENT_STATUS.UNFULFILLED:
          default:
            return {
              title: localizedText('unfulfilled'),
              color: 'bg-chip-red text-red'
            };
        }
      case LABELS.SALES_ORDER:
      case LABELS.INVOICES:
        switch (item.populateFormData?.fulfillmentStatus) {
          case FULFILLMENT_STATUS.FULLY_FULFILLED:
            return {
              title: localizedText('Fulfilled'),
              color: 'bg-chip-green text-green'
            };
          case FULFILLMENT_STATUS.PARTIAL_FULFILLED:
            return {
              title: `Partially ${localizedText('Fulfilled')}`,
              color: 'bg-chip-blue text-blue'
            };
          case FULFILLMENT_STATUS.UNFULFILLED:
          default:
            return {
              title: localizedText('unfulfilled'),
              color: 'bg-chip-red text-red'
            };
        }
      case LABELS.PURCHASE_ORDERS:
      case LABELS.FA_ORDER:
      case LABELS.FA_BILL:
        switch (item.populateFormData?.receiptStatus) {
          case RECEIVED_GOODS_STATUS.FULLY_RECEIVED:
            return {
              title: 'Received',
              color: 'bg-chip-green text-green'
            };
          case RECEIVED_GOODS_STATUS.PARTIAL_RECEIVED:
            return {
              title: 'Partially Received',
              color: 'bg-chip-blue text-blue'
            };
          case RECEIVED_GOODS_STATUS.NOT_RECEIVED:
            return {
              title: 'Not Received',
              color: 'bg-chip-red text-red'
            };
          default:
            return null;
        }
      case LABELS.WORK_OUT:
        switch (item.populateFormData?.receiptStatus) {
          case RECEIVED_GOODS_STATUS.FULLY_RECEIVED:
            return {
              title: 'Received',
              color: 'bg-chip-green text-green'
            };
          case RECEIVED_GOODS_STATUS.PARTIAL_RECEIVED:
            return {
              title: 'Partially Received',
              color: 'bg-chip-blue text-blue'
            };
          case RECEIVED_GOODS_STATUS.NOT_RECEIVED:
            return {
              title: 'Not Received',
              color: 'bg-chip-red text-red'
            };
          default:
            return null;
        }
      case LABELS.BILLS:
        switch (item.populateFormData?.receiveGoodsStatus) {
          case RECEIVED_GOODS_STATUS.FULLY_RECEIVED:
            return {
              title: 'Received',
              color: 'bg-chip-green text-green'
            };
          case RECEIVED_GOODS_STATUS.PARTIAL_RECEIVED:
            return {
              title: 'Partially Received',
              color: 'bg-chip-blue text-blue'
            };
          case RECEIVED_GOODS_STATUS.NOT_RECEIVED:
            return {
              title: 'Not Received',
              color: 'bg-chip-red text-red'
            };
          default:
            return null;
        }
      default:
        return null;
    }
  };
  const getDraftBadges = (item: any) => {
    let paymentBadge = null,
      fulfillmentBadge = null,
      badgeData = [];

    switch (item.data?.draftType || item.draftType) {
      case DraftTypes.DRAFT:
        badgeData.push({
          title: DraftTypes.DRAFT,
          color: 'bg-chip-blue text-blue'
        });
        break;
      case DraftTypes.READONLY:
        paymentBadge = getPaymentBadgeData(item);
        paymentBadge && badgeData.push(paymentBadge);
        fulfillmentBadge = getFullfillmentBadgeData(item);
        fulfillmentBadge && badgeData.push(fulfillmentBadge);
        badgeData.push({
          title: DraftTypes.READONLY,
          color: 'bg-chip-orange text-orange'
        });
        break;
      case DraftTypes.UPDATE:
        paymentBadge = getPaymentBadgeData(item);
        paymentBadge && badgeData.push(paymentBadge);
        fulfillmentBadge = getFullfillmentBadgeData(item);
        fulfillmentBadge && badgeData.push(fulfillmentBadge);
        break;
      default:
    }

    return badgeData;
  };

  const getDraftTitle = (draft: any) => {
    if (
      draft.data.draftType === DraftTypes.READONLY ||
      draft.data.draftType === DraftTypes.UPDATE
    ) {
      return `${t(`DRAFTS.TITLE.${draft.data.title}`)} - ${
        draft?.data?.populateFormData?.documentSequenceCode
      }`;
    } else {
      if (draft.data.draftType === `draft`) {
        return `Draft - ${draft?.data?.draftCode ?? ''}`;
      } else {
        return t(`DRAFTS.ADD_NEW`) + t(`DRAFTS.TITLE.${draft.data.title}`);
      }
    }
  };

  const getHeader = (item: any) => {
    const badgesData = getDraftBadges(item);
    const isPurchaseToleranceSettingsEnabled =
      Utility.isPurchaseToleranceSettingsEnabled(
        item.populateFormData.documentType,
        item.populateFormData
      );
    const isSellsToleranceSettingsEnabled =
      Utility.isSellsToleranceSettingsEnabled(
        item.populateFormData.documentType,
        item.populateFormData
      );
    return (
      <div className="row justify-content-between p-h-s p-v-s bg-gray1 rounded-tr-md rounded-tl-md ">
        <div className="row pop-header-drag-handle">
          <DKLabel
            text={getDraftTitle(item)}
            className={`fw-m${item.isCenterAlign ? ' fs-l' : ' fs-r'}`}
          />
          {(isPurchaseToleranceSettingsEnabled ||
            isSellsToleranceSettingsEnabled) &&
            (item.populateFormData.fulfillmentStatus ===
              APPROVAL_STATUS.PENDING_FOR_APPROVAL ||
              item.populateFormData.receiptStatus ===
                APPROVAL_STATUS.PENDING_FOR_APPROVAL) && (
              <>
                <DKTooltipWrapper
                  content={
                    isSellsToleranceSettingsEnabled
                      ? 'The corresponding Fulfillment to this SO<br>is <b>Pending for approval</b>. Please take <br>action on it before updating this sales<br>order.'
                      : isPurchaseToleranceSettingsEnabled
                      ? 'The corresponding Goods Receive to<br>this PO is <b>Pending for approval</b>.<br>Please take action on it before updating<br>this purchase order.'
                      : ''
                  }
                  tooltipClassName="bg-deskera-secondary parent-width"
                >
                  <DKIcon
                    src={DKIcons.ic_info}
                    className="ic-s opacity-60 cursor-pointer ml-s"
                  />
                </DKTooltipWrapper>
                <DKLabel
                  text={APPROVAL_STATUS_LIST.PENDING_FOR_APPROVAL}
                  style={{
                    textTransform: 'capitalize'
                  }}
                  className={`text-dark-gray bg-chip-blue text-blue border-radius-r ml-r p-h-s p-v-xs fw-m`}
                />
              </>
            )}
          {item.isMaximized && badgesData?.length
            ? badgesData.map((badgeData) => (
                <DKLabel
                  text={badgeData.title}
                  style={{
                    textTransform: 'capitalize'
                  }}
                  className={`text-dark-gray ${badgeData.color} border-radius-r ml-r p-h-s p-v-xs fw-m`}
                />
              ))
            : null}
        </div>
        <div className="row width-auto">{renderDraftViewActions(item)}</div>
      </div>
    );
  };

  const renderDraft = () => {
    if (draftsValue.length > 0) {
      return (
        <div className="row-reverse gap-2 position-fixed bottom-0 right-4 z-10 overflow-scroll hide-scroll-bar bg-transparent">
          {draftsValue.map((item: any) =>
            !item.isCenterAlign ? (
              <div
                key={item.id}
                className={`draft-container border-s shadow-s bg-white rounded-tr-md rounded-tl-md ${
                  item.isMaximized ? 'maximized-draft-wrapper' : 'minimized'
                }`}
              >
                {getHeader(item)}
                {renderDraftDataOnView(item)}
              </div>
            ) : (
              <></>
            )
          )}

          {draftsValue.map((item: any) =>
            DRAFT_DOC_SUPPORTED_MODULE_LABELS.includes(
              item.type
            ) ? null : item.isCenterAlign ? (
              <DynamicPopupWrapper
                popupWrapperElement={portalDiv}
                key={item.id}
              >
                <div className="transparent-background pointer-events-auto">
                  <div
                    className="popup-window shadow-m maximized-draft-wrapper overflow-hidden"
                    style={{
                      maxWidth:
                        item &&
                        item.data &&
                        item.data.title === 'Revenue Arrangement'
                          ? 2152
                          : showFullScreenIcon(item) && showMainDocsInFullscreen
                          ? '100%'
                          : 1078,
                      width:
                        showFullScreenIcon(item) && showMainDocsInFullscreen
                          ? '100%'
                          : '90%',
                      height:
                        showFullScreenIcon(item) && showMainDocsInFullscreen
                          ? '100%'
                          : '95%',
                      maxHeight:
                        showFullScreenIcon(item) && showMainDocsInFullscreen
                          ? '100%'
                          : '95%',
                      padding: 0,
                      pointerEvents: 'auto',
                      borderRadius:
                        showFullScreenIcon(item) && showMainDocsInFullscreen
                          ? 0
                          : 4
                    }}
                  >
                    {getHeader(item)}
                    {renderDraftDataOnView(item)}
                  </div>
                </div>
              </DynamicPopupWrapper>
            ) : (
              <></>
            )
          )}
          {showTransactionLog && (
            <TransactionLog
              data={transactionData}
              onCancel={() => {
                setShowTransactionLog(false);
              }}
            />
          )}
        </div>
      );
    }
  };

  // Render drafts in overlay portal section
  return portalDiv
    ? ReactDOM.createPortal(
        <div className="column parent-width overlay app-font">
          <div className="column parent-width position-relative p-s">
            {renderDraft()}
          </div>
        </div>,
        portalDiv
      )
    : null;
}
