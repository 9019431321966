import { removeLoader, showLoader } from 'deskera-ui-library';
import ApiConstants from '../Constants/ApiConstants';
import { COMMON_CONSTANT, MODULES_NAME } from '../Constants/Constant';
import { COMPLIANCE_SPECIFIC_FIELD_NAME } from '../Constants/Enum';
import TenantManager from '../Managers/TenantManager';
import { CreditLimitPayload } from '../Models/Interfaces';
import { OnboardingStep1Form } from '../Models/Onboarding';
import { OrganisationFormPayload } from '../Models/OrganisationProfile';
import Utility from '../Utility/Utility';
import AuthService from './Auth';
import http from './Interceptor';

import http2 from './Interceptor2';
import { Store } from '../Redux/Store';

class TenantService {
  static getTenantDetails() {
    const countryCode = Utility.getTenantSpecificApiCode(
      COMPLIANCE_SPECIFIC_FIELD_NAME.FETCH_TENANT_SETTINGS
    );
    return http
      .get(ApiConstants.URL.TENANT.GET_DETAILS(countryCode))
      .then(
        (res: any) => {
          return Promise.resolve(res);
        },
        (err) => {
          return Promise.reject(err);
        }
      )
      .catch((error: any) => {
        console.log(error, 'Error Recieved');
        return Promise.reject(error);
      });
  }

  //for bookkeeper
  static getTenantDetailsWithoutCountryCOde() {
    return http
      .get(ApiConstants.URL.TENANT.GET_TENANT_DETAILS)
      .then(
        (res: any) => {
          return Promise.resolve(res);
        },
        (err) => {
          return Promise.reject(err);
        }
      )
      .catch((error: any) => {
        console.log(error, 'Error Recieved');
        return Promise.reject(error);
      });
  }

  static getOrgWithoutSetup(params: any) {
    showLoader('Creating new organisation...');
    return http
      .post(ApiConstants.URL.TENANT.ORG_WITHOUT_SETUP, {
        tenantName: params.tenantName,
        isBookkeeper: params.isBookkeeper,
        refreshToken: params.refreshToken
      })
      .then(
        (res) => {
          removeLoader();
          return Promise.resolve(res);
        },
        (err) => {
          removeLoader();
          return Promise.reject(err);
        }
      );
  }

  static getAllTenants() {
    return http.get(ApiConstants.URL.TENANT.TENANTS).then(
      (res) => {
        TenantManager.setAllTenants(res);
        return Promise.resolve(res);
      },
      (err) => {
        return Promise.reject(err);
      }
    );
  }

  static getAllTenantsDetailed(userId: string) {
    return http.get(ApiConstants.URL.TENANT.TENANTS_DETAILED(userId)).then(
      (res) => {
        TenantManager.setAllTenants(res);
        return Promise.resolve(res);
      },
      (err) => {
        return Promise.reject(err);
      }
    );
  }

  static getAllClientTenants() {
    return http.get(ApiConstants.URL.TENANT.CLIENT_DETAILS).then(
      (res) => {
        return Promise.resolve(res);
      },
      (err) => {
        return Promise.reject(err);
      }
    );
  }

  static getAppCustomization() {
    return http.get(ApiConstants.URL.TENANT.APP_CUSTOMIZATION).then(
      (res) => {
        return Promise.resolve(res);
      },
      (err) => {
        return Promise.reject(err);
      }
    );
  }

  static postAppCustomization(payload: any) {
    const finalEndPoint = ApiConstants.URL.TENANT.APP_CUSTOMIZATION;
    return http.post(`${finalEndPoint}`, payload).catch((error: any) => {
      return Promise.reject(error);
    });
  }

  static getSubscription() {
    return http.get(ApiConstants.URL.SUBSCRIPTIONS.GET_SUBSCRIPTION).then(
      (res) => {
        return Promise.resolve(res);
      },
      (err) => {
        return Promise.reject(err);
      }
    );
  }

  static getBooksSubscription() {
    return http
      .get(ApiConstants.URL.SUBSCRIPTIONS.GET_BOOKS_SUBSCRIPTION_INFO)
      .then(
        (res) => {
          return Promise.resolve(res);
        },
        (err) => {
          return Promise.reject(err);
        }
      );
  }

  static getPermissions(payload: any) {
    return http
      .post(ApiConstants.URL.TENANT.GET_USER_PERMISSIONS, payload)
      .then(
        (res) => {
          return Promise.resolve(res);
        },
        (err) => {
          return Promise.reject(err);
        }
      );
  }

  static getFeaturePermissions() {
    return http.get(ApiConstants.URL.TENANT.GET_FEATURE_PERMISSIONS).then(
      (res) => {
        return Promise.resolve(res);
      },
      (err) => {
        return Promise.reject(err);
      }
    );
  }

  static getUserInfo(userId: number) {
    return http.get(ApiConstants.URL.USER.USER_INFO_BY_ID(userId)).then(
      (res) => {
        return Promise.resolve(res);
      },
      (err) => {
        return Promise.reject(err);
      }
    );
  }

  static getTenantSettings(tenantID: any) {
    const finalEndPoint: string = `tenants/${tenantID}/settings/info`;
    return http2
      .get(finalEndPoint)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static updateOrgSetting(payload: any) {
    const countryCode = Utility.getTenantSpecificApiCode(
      COMPLIANCE_SPECIFIC_FIELD_NAME.PATCH_TENANT_SETTINGS
    );
    return http
      .patch(ApiConstants.URL.TENANT.UPDATE_ORG(countryCode), payload)
      .then(
        (res) => {
          return Promise.resolve(res);
        },
        (err) => {
          return Promise.reject(err);
        }
      );
  }

  static updateAccountSettingINOrgSetting(data: any) {
    const countryCode = Utility.getTenantSpecificApiCode(
      COMPLIANCE_SPECIFIC_FIELD_NAME.PATCH_TENANT_SETTINGS
    );
    return http
      .patch(ApiConstants.URL.TENANT.UPDATE_ORG(countryCode), data)
      .then(
        (res) => {
          return Promise.resolve(res);
        },
        (err) => {
          return Promise.reject(err);
        }
      );
  }

  static createDemoOrg(country: string) {
    const payload = {
      refreshToken: localStorage.getItem('refreshData')
    };
    return http.post(ApiConstants.URL.TENANT.DEMO_ORG(country), payload).then(
      (res) => {
        return Promise.resolve(res);
      },
      (err) => {
        return Promise.reject(err);
      }
    );
  }

  static patchOrgWithSetup(payload: OnboardingStep1Form, countryCode?: string) {
    let endPoint = ApiConstants.URL.TENANT.ORG_WITH_SETUP(
      countryCode ? countryCode.toLowerCase() : ''
    );
    return http.put(endPoint, payload).then(
      (res) => {
        return Promise.resolve(res);
      },
      (err) => {
        return Promise.reject(err);
      }
    );
  }

  static updateOrgStatus(payload: any) {
    return http.post(ApiConstants.URL.TENANT.UPDATE_ORG_STATUS, payload).then(
      (res) => {
        return Promise.resolve(res);
      },
      (err) => {
        return Promise.reject(err);
      }
    );
  }

  static getTenantSetupStatus() {
    return http.get(ApiConstants.URL.TENANT.UPDATE_ORG_STATUS).then(
      (res) => {
        return Promise.resolve(res);
      },
      (err) => {
        return Promise.reject(err);
      }
    );
  }

  static createOrgWithSetup(
    payload: OnboardingStep1Form,
    countryCode?: string
  ) {
    let endPoint = ApiConstants.URL.TENANT.ORG_WITH_SETUP(
      countryCode ? countryCode.toLowerCase() : ''
    );
    return http.post(endPoint, payload).then(
      (res) => {
        return Promise.resolve(res);
      },
      (err) => {
        return Promise.reject(err);
      }
    );
  }

  static updatePaymentTerm(payload: any) {
    const finalEndPoint: string = `tenants/in`;
    return http.patch(finalEndPoint, payload).then(
      (res) => {
        return Promise.resolve(res);
      },
      (err) => {
        return Promise.reject(err);
      }
    );
  }

  static getUsersList() {
    let query = `?limit=50&page=0&sortDir=ASC&sort=firstName`;
    const url = ApiConstants.URL.USER.USERS + query;
    return http.get(url).then(
      (res) => {
        return Promise.resolve(res);
      },
      (err) => {
        return Promise.reject(err);
      }
    );
  }

  static getAllUsers() {
    let query = `?limit=100&page=0&sortDir=ASC&sort=firstName`;
    const url = ApiConstants.URL.USER.USER_INFO + query;
    return http.get(url).then(
      (res: any) => {
        return Promise.resolve(res);
      },
      (err: any) => {
        return Promise.reject(err);
      }
    );
  }

  static getUsersListShortInfo() {
    // return http.get(`${ApiConstants.URL.USER.USERS}?limit=50&page=0`).then(
    return http
      .get(`${ApiConstants.URL.USER.USERS_SHORT_INFO}?limit=50&page=0`)
      .then(
        (res) => {
          return Promise.resolve(res);
        },
        (err) => {
          return Promise.reject(err);
        }
      );
  }

  static getDefaultFY() {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json'
      },
      observe: 'response',
      responseType: 'string' as 'json'
    };
    const finalEndPoint: string =
      ApiConstants.URL.TENANT.FETCH_ATTACHMENT +
      `?${COMMON_CONSTANT.FILE}=${COMMON_CONSTANT.DEFAULT_FY_FILE}`;
    return http
      .get(finalEndPoint, config)
      .then((res) => {
        return Promise.resolve(res);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }

  static subscriptionRegistration() {
    const finalEndPoint = ApiConstants.URL.SUBSCRIPTIONS.GET_SUBSCRIPTION;
    const payload = {};
    return http.post(finalEndPoint, payload).then(
      (res) => {
        return Promise.resolve(res);
      },
      (err) => {
        return Promise.reject(err);
      }
    );
  }

  static startTrialPeriodOnboarding() {
    const query = 'Trial=AIO&Currency=usd';
    const finalEndPoint =
      ApiConstants.URL.SUBSCRIPTIONS.GET_SUBSCRIPTION + '?' + query;
    const payload = {};
    return http2.put(finalEndPoint, payload).then(
      (res) => {
        return Promise.resolve(res);
      },
      (err) => {
        return Promise.reject(err);
      }
    );
  }

  static updateControlAccountSettings(tenandId: any, payload: any) {
    return http
      .post(
        ApiConstants.URL.TENANT.UPDATE_CONTROL_ACC_SETTING(tenandId),
        payload
      )
      .then(
        (res) => {
          return Promise.resolve(res);
        },
        (err) => {
          return Promise.reject(err);
        }
      );
  }

  static getContactInfo() {
    return http.get(ApiConstants.URL.TENANT.TENANT_CONTACT_INFO).then(
      (res) => {
        return Promise.resolve(res);
      },
      (err) => {
        return Promise.reject(err);
      }
    );
  }

  static updateContactInfo(request: any) {
    return http
      .patch(ApiConstants.URL.TENANT.TENANT_CONTACT_INFO, request)
      .then(
        (res) => {
          return Promise.resolve(res);
        },
        (err) => {
          return Promise.reject(err);
        }
      );
  }

  static deleteBarcodeSettings() {
    return http2
      .delete(
        ApiConstants.URL.TENANT.BARCODE_SETTINGS(AuthService.getUserTenantID())
      )
      .then(
        (res) => {
          return Promise.resolve(res);
        },
        (err) => {
          return Promise.reject(err);
        }
      );
  }

  static checkValidUENNuber(uen: any) {
    return http.get(ApiConstants.URL.TENANT.CHECK_UEN_NO(uen)).then(
      (res) => {
        return Promise.resolve(res);
      },
      (err) => {
        return Promise.reject(err);
      }
    );
  }

  static getParentTenants() {
    return http
      .get(ApiConstants.URL.TENANT.GET_PARENT_TENANTS)
      .then(
        (res: any) => {
          return Promise.resolve(res);
        },
        (err) => {
          return Promise.reject(err);
        }
      )
      .catch((error: any) => {
        console.log(error, 'Error Recieved');
        return Promise.reject(error);
      });
  }

  static getSubsidiaryTenants(tenantId: number) {
    return http
      .get(ApiConstants.URL.TENANT.GET_SUBSIDIARY_TENANTS(String(tenantId)))
      .then(
        (res: any) => {
          return Promise.resolve(res);
        },
        (err) => {
          return Promise.reject(err);
        }
      )
      .catch((error: any) => {
        console.log(error, 'Error Recieved');
        return Promise.reject(error);
      });
  }

  static getTenantsByEmail(email: string) {
    return http
      .get(ApiConstants.URL.TENANT.GET_TENANT_BY_EMAIL(email))
      .then(
        (res: any) => {
          return Promise.resolve(res);
        },
        (err) => {
          return Promise.reject(err);
        }
      )
      .catch((error: any) => {
        console.log(error, 'Error Recieved');
        return Promise.reject(error);
      });
  }

  static getTenantsSubscriptionPlan(tenantId: string) {
    return http
      .get(
        ApiConstants.URL.TENANT.GET_TENANT_SUBSCRIPTION_PLAN_DETAILS(tenantId)
      )
      .then(
        (res: any) => {
          return Promise.resolve(res);
        },
        (err) => {
          return Promise.reject(err);
        }
      )
      .catch((error: any) => {
        console.log(error, 'Error Recieved');
        return Promise.reject(error);
      });
  }

  static getUserPreferences() {
    return http.get(ApiConstants.URL.USER.PREFERENCE);
  }

  static updateUserPreferences(dataToUpdate: { [key: string]: any }) {
    return http.patch(ApiConstants.URL.USER.PREFERENCE, dataToUpdate);
  }
}

export const isSecurityEntryGateTxnEnabled = (): boolean => {
  return !!Store.getState().authInfo?.currentTenantInfo?.data
    ?.additionalSettings?.SECURITY_GATE_ENTRY;
};

export default TenantService;
