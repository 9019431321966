import { FC, useContext, useEffect, useMemo, useState } from 'react';
import { DKIcon, DKIcons, DKLabel } from 'deskera-ui-library';
import { useAppDispatch, useAppSelector } from '../../../../../Redux/Hooks';
import { selectDocumentById, selectDocumentFormDataByKeys } from '../../../../../Redux/Slices/DocumentSlice';
import {
  DOCUMENT_KEYS,
  IDocSummaryAmount
} from '../../../Utilities/DocConstants';
import { CommonDraftPropsContext } from '../../../Utilities/DocContext';
import { getTenantTaxSystem } from '../../../../../SharedComponents/DocumentForm/NewDocumentHelper';
import {
  COUNTRY_CODES,
  DOC_TYPE,
  GST_TYPE,
  PRODUCT_OFFERING_TYPE,
  TAX_SYSTEM
} from '../../../../../Constants/Constant';
import { activeTenantInfo } from '../../../../../Redux/Slices/AuthSlice';
import Utility from '../../../../../Utility/Utility';
import {
  getAdditionalChargesTotalAndTax,
  getTaxFromCharge
} from '../../../Helper/Common/DocDataHelper';
import FormUtil from '../../../../../SharedComponents/FormUtil/FormUtil';
import NumberFormatService from '../../../../../Services/NumberFormat';
import TitleAndAmount from './TitleAndAmount';
import { useTranslation } from 'react-i18next';
import {
  ausConfig,
  belgiumConfig,
  caConfig,
  germanyConfig,
  inConfig,
  indonesiaConfig,
  myConfig,
  nzConfig,
  phConfig,
  sgConfig,
  uaeConfig,
  usConfig,
  ukConfig,
  ilConfig,
  krConfig
} from '../../../../../SharedComponents/FormUtil/FormDocumentUtilConstants';
import { selectAdditionalBuyCharges, selectAddtionalSellCharges } from '../../../../../Redux/Slices/AdditionalChargesSlice';
import { isSalesDocument } from '../../../Utilities/DocCommonUtils';

interface IDocTaxGroupDetailsProps {
  hasTaxGroup: boolean;
  showPayments: boolean;
  summaryAmounts: IDocSummaryAmount;
}

const DocTaxGroupDetails: FC<IDocTaxGroupDetailsProps> = ({
  summaryAmounts,
  showPayments,
  hasTaxGroup
}) => {
  const { t } = useTranslation();
  const { draftId } = useContext(CommonDraftPropsContext);
  const document = useAppSelector(selectDocumentById(draftId));
  const [
    knockoffInfo,
    additionalCharges,
    gstType,
    documentType,
    items,
    currency
  ] = useAppSelector(
    selectDocumentFormDataByKeys(draftId, [
      DOCUMENT_KEYS.KNOCK_OFF_INFO,
      DOCUMENT_KEYS.ADDITIONAL_CHARGES,
      DOCUMENT_KEYS.GST_TYPE,
      DOCUMENT_KEYS.DOCUMENT_TYPE,
      DOCUMENT_KEYS.ITEMS,
      DOCUMENT_KEYS.CURRENCY
    ])
  );
  const { country } = useAppSelector(activeTenantInfo);

  const memoizedTotalPayment = useMemo(
    () =>
      knockoffInfo?.reduce(
        (total: number, paymentInfo: any) =>
          total + Number(paymentInfo?.amount * paymentInfo?.exchangeRate || 0),
        0
      ),
    [knockoffInfo]
  );

  const additionalBuyCharges = useAppSelector(selectAdditionalBuyCharges);
  const additionalSellCharges = useAppSelector(selectAddtionalSellCharges);

  const [taxGroupDetail, setTaxGroupDetail] = useState<any>({});
  const [showTaxGroupDetails, setShowTaxGroupDetails] =
    useState<boolean>(false);
  const [showPaymentGroupDetails, setShowPaymentGroupDetails] =
    useState<boolean>(false);

    const chargesFromStore = isSalesDocument(documentType)
    ? additionalSellCharges
    : additionalBuyCharges;

  const hasTaxGroupDetails = useMemo(() => {
    return Boolean(
      (country === COUNTRY_CODES.IN &&
        (summaryAmounts.GST.cess !== 0 ||
          [GST_TYPE.INTER, GST_TYPE.INTRA].includes(gstType as GST_TYPE))) ||
        Object.keys(taxGroupDetail).length
    );
  }, [summaryAmounts, taxGroupDetail, gstType, country]);

  const canShowAdditionalChargeTaxes = () => {
    let areTaxesVisible = true;
    if (country === COUNTRY_CODES.IN && gstType === GST_TYPE.EXEMPT) {
      areTaxesVisible = false;
    }
    const isBillOrOrder = [DOC_TYPE.BILL, DOC_TYPE.ORDER].includes(
      documentType
    );
    if (country === COUNTRY_CODES.US && isBillOrOrder) {
      areTaxesVisible = false;
    }
    return areTaxesVisible;
  };

  useEffect(() => {
    setTaxGroupDetail(buildTaxGroupDetails());
  }, [items, additionalCharges]);

  const buildTaxBreakUp = ({ taxAmount, tax }: any) => {
    const taxBreakup: any[] = [];
    if (getTenantTaxSystem() === TAX_SYSTEM.INDIA_GST) {
      if (gstType === GST_TYPE.INTER) {
        taxBreakup.push({
          taxName: 'IGST',
          taxPercent: tax?.percent,
          taxAmount: taxAmount
        });
      }
      if (gstType === GST_TYPE.INTRA) {
        taxBreakup.push({
          taxName: 'CGST',
          taxPercent: tax?.percent / 2,
          taxAmount: taxAmount / 2
        });
        taxBreakup.push({
          taxName: 'SGST',
          taxPercent: tax?.percent / 2,
          taxAmount: taxAmount / 2
        });
      }
    }
    return taxBreakup;
  };

  const buildTaxGroupDetails = () => {
    let updatedTaxGroupDetail: any = {};
    if (Utility.isNotEmpty(items)) {
      items?.forEach((item: any) => {
        if (!Utility.isEmpty(item?.taxDetails)) {
          item.taxDetails.forEach((tax: any) => {
            if (
              updatedTaxGroupDetail[tax.taxName] !== undefined &&
              updatedTaxGroupDetail[tax.taxName] !== null
            ) {
              updatedTaxGroupDetail[tax.taxName] =
                updatedTaxGroupDetail[tax.taxName] + Number(tax.taxAmount);
            } else {
              updatedTaxGroupDetail[tax.taxName] = Number(tax.taxAmount);
            }
          });
        } else if (item?.taxAmount) {
          updatedTaxGroupDetail['Tax'] = updatedTaxGroupDetail['Tax']
            ? updatedTaxGroupDetail['Tax']
            : 0;
          updatedTaxGroupDetail['Tax'] += Number(item?.taxAmount);
        }
      });
      let extraChargeTaxes: any[] = [];
      if (canShowAdditionalChargeTaxes()) {
        additionalCharges?.additionalChargesDetails.forEach((charge: any) => {
          const chargeToUse = chargesFromStore?.find((item:any) => item.name === charge.additionalCharge);
          if (chargeToUse.isTaxable) {
            const tax = getTaxFromCharge({ charge: chargeToUse, documentType, country });
            const taxName = tax?.name;
            const hsnOrSacInfo = chargeToUse?.indiaProperties
              ? chargeToUse.offeringType === PRODUCT_OFFERING_TYPE.SERVICES
                ? chargeToUse?.indiaProperties?.SAC
                  ? 'SAC: ' + chargeToUse?.indiaProperties?.SAC
                  : ''
                : chargeToUse?.indiaProperties?.HSN
                ? 'HSN: ' + chargeToUse?.indiaProperties?.HSN
                : ''
              : '';

            const taxBreakUp = buildTaxBreakUp({
              taxAmount: charge.taxAmount,
              tax
            });

            if (taxBreakUp.length > 0) {
              let taxes: any[] = [];
              taxBreakUp.forEach((tax: any, index: number) => {
                taxes.push({
                  taxName: tax.taxName,
                  taxPercent: tax.taxPercent,
                  taxAmount: tax.taxAmount,
                  hsnOrSacInfo:
                    index === taxBreakUp.length - 1 ? hsnOrSacInfo : undefined
                });
              });
              const extraChargeTax = {
                name: chargeToUse.name,
                taxes
              };
              extraChargeTaxes.push(extraChargeTax);
            } else {
              const extraChargeTax = {
                name: chargeToUse.name,
                taxes: [
                  {
                    taxName: taxName,
                    taxPercent: tax?.percent,
                    hsnOrSacInfo,
                    taxAmount: charge.taxAmount
                  }
                ]
              };
              extraChargeTaxes.push(extraChargeTax);
            }
          }
        });
      }
      if (Utility.isNotEmpty(additionalCharges?.globalDiscounts)) {
        additionalCharges?.globalDiscounts?.forEach((charge: any) => {
          const chargeToUse = chargesFromStore?.find((item:any) => item.name === charge.additionalCharge);
          if (chargeToUse.isTaxable) {
            const tax = getTaxFromCharge(chargeToUse);
            const taxName = tax?.name;
            const hsnOrSacInfo = chargeToUse?.indiaProperties
              ? chargeToUse.offeringType === PRODUCT_OFFERING_TYPE.SERVICES
                ? chargeToUse?.indiaProperties?.SAC
                  ? 'SAC: ' + chargeToUse?.indiaProperties?.SAC
                  : ''
                : chargeToUse?.indiaProperties?.HSN
                ? 'HSN: ' + chargeToUse?.indiaProperties?.HSN
                : ''
              : '';

            const taxBreakUp = buildTaxBreakUp({
              taxAmount: charge.taxAmount,
              tax
            });

            if (taxBreakUp.length > 0) {
              let taxes: any[] = [];
              taxBreakUp.forEach((tax: any, index: number) => {
                taxes.push({
                  taxName: tax.taxName,
                  taxPercent: tax.taxPercent,
                  taxAmount: tax.taxAmount,
                  hsnOrSacInfo:
                    index === taxBreakUp.length - 1 ? hsnOrSacInfo : undefined
                });
              });
              const extraChargeTax = {
                name: chargeToUse.name,
                taxes
              };
              extraChargeTaxes.push(extraChargeTax);
            } else {
              const extraChargeTax = {
                name: chargeToUse.name,
                taxes: [
                  {
                    taxName: taxName,
                    taxPercent: tax?.percent,
                    hsnOrSacInfo,
                    taxAmount: charge.taxAmount
                  }
                ]
              };
              extraChargeTaxes.push(extraChargeTax);
            }
          }
        });
      }
      if (extraChargeTaxes.length) {
        updatedTaxGroupDetail = {
          ...updatedTaxGroupDetail,
          extraChargeTaxes
        };
      }
    }
    return updatedTaxGroupDetail;
  };

  const getExtraChargeTitleAndAmount = (
    extraChargeDetail: any,
    currencyCode?: string
  ) => {
    return (
      <>
        {getTileWithBullet(extraChargeDetail.name)}
        {extraChargeDetail.taxes?.map((tax: any, index: number) => (
          <div
            className={`row parent-width justify-content-between align-items-start ${
              index === extraChargeDetail?.taxes?.length - 1 ? 'mb-m' : 'mb-xs'
            }`}
            style={{ width: '100%' }}
          >
            <div
              className="row width-auto"
              style={{
                minWidth: 100
              }}
            >
              <div className="column parent-width">
                <DKLabel
                  text={`${tax.taxName ? '+ ' + tax.taxName : '+ Tax'}`}
                  style={{
                    marginLeft: 32
                  }}
                />
                <DKLabel
                  text={tax.hsnOrSacInfo}
                  className="text-gray fs-s"
                  style={{
                    marginLeft: 32
                  }}
                />
              </div>
            </div>
            <DKLabel
              text={`${Utility.getCurrencySymbolFromCode(
                currencyCode ? currencyCode : currency
              )} ${`${
                tax.taxAmount < 0 ? '(' : ''
              }${NumberFormatService.getNumber(Math.abs(tax.taxAmount))}${
                tax.taxAmount < 0 ? ')' : ''
              }`}`}
              style={{
                wordBreak: 'break-all'
              }}
              className={`ml-r text-wrap`}
            />
          </div>
        ))}
      </>
    );
  };

  const getTileWithBullet = (title: string) => {
    return (
      <DKLabel
        text={title}
        className="column parent-width mb-xs"
        style={{
          display: 'list-item',
          listStyleType: 'disc',
          listStylePosition: 'inside',
          marginLeft: 18
        }}
      />
    );
  };

  const getTaxGroupDetails = () => {
    let taxGroupContainer;
    let extraChargeTaxesContainer;
    //move
    if (country === COUNTRY_CODES.IN) {
      taxGroupContainer = (
        <>
          {gstType !== GST_TYPE.EXEMPT && getTileWithBullet('Tax on Sub-total')}
          {gstType === GST_TYPE.INTER && (
            <TitleAndAmount
              title={`+ ${t(`DOCUMENT.DOCUMENT_SUMMARY_VIEW.IGST`)}`}
              amount={summaryAmounts.GST.igst}
              titleClassName="fw-r ml-xxl"
              currencyCode={currency}
            />
          )}
          {gstType === GST_TYPE.INTRA && (
            <TitleAndAmount
              title={`+ ${t(`DOCUMENT.DOCUMENT_SUMMARY_VIEW.CGST`)}`}
              amount={summaryAmounts.GST.cgst}
              titleClassName="fw-r ml-xxl"
              currencyCode={currency}
            />
          )}
          {gstType === GST_TYPE.INTRA && (
            <TitleAndAmount
              title={`+ ${t(`DOCUMENT.DOCUMENT_SUMMARY_VIEW.SGST`)}`}
              amount={summaryAmounts.GST.sgst}
              titleClassName="fw-r ml-xxl"
              currencyCode={currency}
            />
          )}
          {summaryAmounts.GST.cess !== 0 && (
            <TitleAndAmount
              title={`+ ${t(`DOCUMENT.DOCUMENT_SUMMARY_VIEW.CESS`)}`}
              amount={summaryAmounts.GST.cess}
              titleClassName="fw-r ml-xxl"
              currencyCode={currency}
            />
          )}
          {!gstType && (
            <TitleAndAmount
              title={`+ Tax`}
              amount={summaryAmounts.tax}
              titleClassName="fw-r ml-xxl"
              currencyCode={currency}
            />
          )}
        </>
      );
    } else {
      taxGroupContainer = Object.keys(taxGroupDetail).map((key: any, index) => {
        //   taxGroupContainer
        return (
          <>
            {key !== 'extraChargeTaxes' &&
              getTileWithBullet('Tax on Sub-total')}
            {key !== 'extraChargeTaxes' && taxGroupDetail.length === 0 && (
              <TitleAndAmount
                title={`+ Tax`}
                amount={summaryAmounts.tax}
                titleClassName="fw-r ml-xxl"
                currencyCode={currency}
              />
            )}
            {key !== 'extraChargeTaxes' && (
              <TitleAndAmount
                title={key}
                amount={taxGroupDetail[key]}
                titleClassName="fw-r ml-xxl"
                currencyCode={currency}
              />
            )}
          </>
        );
      });
    }

    extraChargeTaxesContainer = taxGroupDetail.extraChargeTaxes?.map(
      (taxDetail: any, index: any) => {
        if (!Utility.isEmpty(taxDetail.name)) {
          return <>{getExtraChargeTitleAndAmount(taxDetail)}</>;
        } else {
          return <></>;
        }
      }
    );

    return (
      <>
        {taxGroupContainer}
        {extraChargeTaxesContainer}
      </>
    );
  };

  const getPaymentCode = (record: any) => {
    switch (record?.documentType) {
      case 'CREDIT_NOTE':
      case 'DEBIT_NOTE':
      case 'SALES_INVOICE':
      case 'PURCHASE_INVOICE':
        return record?.linkedDocumentSequence;
      case 'RECEIVE_PAYMENT':
      case 'MAKE_PAYMENT':
      case 'DEPOSIT_ADVPAYMENT':
      case 'EXPENSE_PREPAYMENT':
        return record?.documentCode;
      default:
        return '';
    }
  };

  const getPaymentsGroup = () => {
    return (
      <>
        {knockoffInfo?.map((paymentInfo: any) => (
          <div key={paymentInfo.documentCode} className="parent-width">
            <TitleAndAmount
              title={getPaymentCode(paymentInfo)}
              amount={
                Number(paymentInfo?.amount || 0) -
                Number(paymentInfo?.whtAmount || 0)
              }
              titleClassName="fw-r ml-xxl"
              currencyCode={paymentInfo.currency}
            />
            {paymentInfo?.whtAmount > 0 && (
              <TitleAndAmount
                title={'WHT'}
                amount={paymentInfo?.whtAmount || 0}
                titleClassName="fw-r ml-xxl"
                currencyCode={paymentInfo.currency}
              />
            )}
          </div>
        ))}
      </>
    );
  };

  const getComplianceConfig = () => {
    switch (country) {
      case COUNTRY_CODES.IN:
        return inConfig;
      case COUNTRY_CODES.SG:
        return sgConfig;
      case COUNTRY_CODES.MY:
        return myConfig;
      case COUNTRY_CODES.ID:
        return indonesiaConfig;
      case COUNTRY_CODES.PH:
        return phConfig;
      case COUNTRY_CODES.NZ:
        return nzConfig;
      case COUNTRY_CODES.DE:
        return germanyConfig;
      case COUNTRY_CODES.US:
        return usConfig;
      case COUNTRY_CODES.AE:
        return uaeConfig;
      case COUNTRY_CODES.UK:
        return ukConfig;
      case COUNTRY_CODES.BE:
        return belgiumConfig;
      case COUNTRY_CODES.AU:
        return ausConfig;
      case COUNTRY_CODES.CA:
        return caConfig;
      case COUNTRY_CODES.IL:
        return ilConfig;
      case COUNTRY_CODES.KR:
        return krConfig;
      default:
        return null;
    }
  };

  const showGroupDetails =
    hasTaxGroupDetails || (showPayments && Boolean(memoizedTotalPayment));
  const additionalChargesAmountInfo = getAdditionalChargesTotalAndTax(
    additionalCharges?.additionalChargesDetails
  );

  if (hasTaxGroup) {
    if (!showPayments || memoizedTotalPayment) {
      return (
        <>
          <div
            className="row width-auto mb-m justify-content-between align-items-start"
            style={{
              width: '100%',
              pointerEvents: 'all',
              cursor: showGroupDetails ? 'pointer' : 'default'
            }}
          >
            <div
              className="row width-auto"
              style={{
                minWidth: 100
              }}
              onClick={
                showPayments
                  ? () => setShowPaymentGroupDetails((prevState) => !prevState)
                  : () => setShowTaxGroupDetails((prevState) => !prevState)
              }
            >
              <div className="flex flex-row align-items-center">
                <span className="mr-1 ml-r fw-m ">
                  {showPayments ? 'Payments' : 'Tax (+)'}
                </span>
                {showGroupDetails ? (
                  <DKIcon
                    src={DKIcons.ic_arrow_right}
                    className="ml-xs ic-xs opacity-5"
                    style={{
                      transition: 'transform 0.1s ease-in-out',
                      transform: (
                        showPayments
                          ? showPaymentGroupDetails
                          : showTaxGroupDetails
                      )
                        ? 'rotate(90deg)'
                        : 'rotate(0deg)'
                    }}
                  />
                ) : null}
              </div>
            </div>
            <DKLabel
              text={`${Utility.getCurrencySymbolFromCode(
                currency
              )} ${NumberFormatService.getNumber(
                showPayments
                  ? memoizedTotalPayment
                  : summaryAmounts.tax + (additionalChargesAmountInfo?.tax ?? 0)
              )}`}
              style={{
                wordBreak: 'break-all'
              }}
              className={`ml-r text-wrap`}
            />
          </div>

          {showPayments
            ? showPaymentGroupDetails
              ? getPaymentsGroup()
              : null
            : showTaxGroupDetails
            ? getTaxGroupDetails()
            : null}
        </>
      );
    }
    return null;
  }
  const complianceFields = getComplianceConfig();
  if (Utility.isNotEmpty(complianceFields)) {
    return (
      <FormUtil
        parentData={document?.populateFormData ?? {}}
        complianceFields={complianceFields}
        updateParentData={(key: any, value: any) => {}}
        sectionType={'subtotalTax'}
        isDocument={true}
      />
    );
  }

  return <></>;
};

export default DocTaxGroupDetails;
