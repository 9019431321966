import { useCallback, useContext, useEffect, useState } from 'react';
import { showAlert } from 'deskera-ui-library';
import { CommonDraftPropsContext } from '../../Utilities/DocContext';
import { useAppDispatch, useAppSelector } from '../../../../Redux/Hooks';
import {
  selectDocumentFormDataByKeys,
  selectDocumentMetaDataByKey,
  updateMultipleKeysInDocument
} from '../../../../Redux/Slices/DocumentSlice';
import { shallowEqual } from 'react-redux';
import DocAddress from '../Common/DocAddress';
import { useTranslation } from 'react-i18next';
import {
  isDocumentInDraftMode,
  isEditableDraft,
  isPurchaseDocument
} from '../../Utilities/DocCommonUtils';
import { activeTenantInfo } from '../../../../Redux/Slices/AuthSlice';
import DocContactSelector from '../Common/DocContactSelector';
import { DraftTypes } from '../../../../Models/Drafts';
import Utility from '../../../../Utility/Utility';
import {
  BOOKS_ADDRESS_TYPES,
  DOCUMENT_MODE
} from '../../../../Constants/Constant';
import DocAddressCustomFieldHolder from './DocAddressCustomFieldHolder';
import { isDocContactInactive } from '../../../../SharedComponents/DocumentForm/NewDocumentHelper';
import { DOCUMENT_KEYS } from '../../Utilities/DocConstants';
import DocOrgUpdatePopup from '../Common/DocOrgUpdatePopup';
import { ORGANISATION_FORM_TAB } from '../../../../Constants/Enum';

/**
 * Component to show seller info
 * This component will show the shipFrom info on the top. It will show/handle:
 * - Name, addresses and address CF of the CONTACT for SALES document
 * - Name, addresses and address CF of the ORGANIZATION for PURCHASE document
 */
const DocSellerInfo = () => {
  const { t } = useTranslation();
  const { draftId, draftType, documentMode } = useContext(
    CommonDraftPropsContext
  );
  const canValidate = useAppSelector(
    selectDocumentMetaDataByKey(draftId, 'canValidate')
  );
  const [
    shipFrom,
    contact,
    documentType,
    receiveGoodsStatus,
    fulfillmentStatus,
    paymentStatus,
    totalAmount,
    duplicate,
    isConverting,
    customLocationCFValue
  ] = useAppSelector(
    selectDocumentFormDataByKeys(draftId, [
      DOCUMENT_KEYS.SHIP_FROM,
      DOCUMENT_KEYS.CONTACT,
      DOCUMENT_KEYS.DOCUMENT_TYPE,
      'receiveGoodsStatus',
      'fulfillmentStatus',
      'paymentStatus',
      'totalAmount',
      'duplicate',
      'isConverting',
      DOCUMENT_KEYS.CUSTOM_LOCATION_CF_VALUE
    ]),
    shallowEqual
  );
  const dispatch = useAppDispatch();
  const tenantInfo = useAppSelector(activeTenantInfo);
  const [needUpdateOrgPopup, setNeedUpdateOrgPopup] = useState(false);

  const isPurchaseTypeDoc = isPurchaseDocument(documentType);

  let sellerName =
    (isPurchaseTypeDoc
      ? `${contact?.name}-(${contact?.currencyCode})`
      : tenantInfo?.name) || '-';

  if (!isPurchaseTypeDoc && !Utility.isEmpty(customLocationCFValue)) {
    sellerName = customLocationCFValue?.locationDetails?.company;
  }

  const showTenantAddressAlert = useCallback(() => {
    const alertButtonConfig = [
      {
        title: 'Cancel',
        className: 'bg-gray2 border-m ',
        onClick: () => {}
      },
      {
        title: 'Add address',
        className: 'bg-button text-white ml-r',
        onClick: () => setNeedUpdateOrgPopup(true)
      }
    ];

    showAlert(
      'Tax Compliance',
      isPurchaseTypeDoc
        ? "To calculate purchase tax correctly, we would need your organisation's address."
        : "To calculate sales tax correctly, we would need your organisation's address where you collect tax.",
      alertButtonConfig
    );
  }, [isPurchaseTypeDoc]);

  useEffect(() => {
    if (!Utility.isEmpty(tenantInfo)) {
      const complianceStatus = Utility.getTenantComplianceSettings(tenantInfo);
      if (
        !complianceStatus.complianceSetting &&
        !tenantInfo.shippingAddresses?.length &&
        (documentMode === DOCUMENT_MODE.NEW ||
          documentMode === DOCUMENT_MODE.COPY)
      ) {
        showTenantAddressAlert();
      }
    }
  }, [documentMode, documentType, showTenantAddressAlert, tenantInfo]);

  const getSellerShippingAddressList = useCallback((): any[] => {
    return (
      (isPurchaseTypeDoc
        ? contact?.shippingAddress
        : tenantInfo?.shippingAddresses) || []
    );
  }, [
    contact?.shippingAddress,
    isPurchaseTypeDoc,
    tenantInfo?.shippingAddresses
  ]);

  useEffect(() => {
    if (Utility.isEmpty(shipFrom)) {
      // Preffered seller address
      let sellerPreferredShippingAddress = getSellerShippingAddressList().find(
        (address) => address.preferred
      );
      if (Utility.isEmptyObject(sellerPreferredShippingAddress)) {
        sellerPreferredShippingAddress = getSellerShippingAddressList()?.[0];
      }

      dispatch(
        updateMultipleKeysInDocument({
          draftId,
          keysToUpdate: {
            [DOCUMENT_KEYS.SHIP_FROM]: sellerPreferredShippingAddress
          }
        })
      );
    }
  }, [dispatch, draftId, getSellerShippingAddressList, shipFrom]);

  const showInactiveIndicator =
    isPurchaseTypeDoc &&
    (documentMode === DOCUMENT_MODE.COPY ||
      documentMode === DOCUMENT_MODE.NEW) &&
    (duplicate || isConverting) &&
    isDocContactInactive(contact);

  const canEditContact = isDocumentInDraftMode(draftType) && isPurchaseTypeDoc;
  // GranularPermissionsHelper.hasPermissionFor(
  //   PERMISSIONS_BY_MODULE.CONTACTS.CREATE
  // );

  return isPurchaseTypeDoc && Utility.isEmpty(contact) ? (
    <DocContactSelector needSelectorButton={true} canValidate={!!canValidate} />
  ) : (
    <div className="column document-address-width gap-1">
      <DocContactSelector
        title=""
        contactName={sellerName}
        needSelectorButton={false}
        canEdit={canEditContact}
        canValidate={true}
        showInactiveIndicator={showInactiveIndicator}
      />
      <DocAddress
        isPurchaseDocument={isPurchaseTypeDoc}
        isSellerInfo={true}
        contact={contact || null}
        address={shipFrom}
        placeholder={draftType === DraftTypes.READONLY ? '-' : 'Select address'}
        canEdit={isEditableDraft({
          draftType,
          documentType,
          receiveGoodsStatus,
          fulfillmentStatus,
          paymentStatus,
          totalAmount
        })}
        addressList={getSellerShippingAddressList() || []}
        preferCustomLocationDetails={
          !isPurchaseTypeDoc && !Utility.isEmpty(customLocationCFValue)
        }
        locationDetails={
          !isPurchaseTypeDoc && !Utility.isEmpty(customLocationCFValue)
            ? customLocationCFValue?.locationDetails
            : null
        }
        dataKey={'shipFrom'}
      />
      {isPurchaseTypeDoc && Utility.isNotEmpty(shipFrom?.customFields) && (
        <DocAddressCustomFieldHolder
          addressType={BOOKS_ADDRESS_TYPES.SHIP_FROM}
        />
      )}
      {needUpdateOrgPopup && (
        <DocOrgUpdatePopup
          activeOrgProfileTab={ORGANISATION_FORM_TAB.ADDRESS_INFO}
          onClosePopup={() => setNeedUpdateOrgPopup(false)}
        />
      )}
    </div>
  );
};

export default DocSellerInfo;
