import { useCallback, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { CommonDraftPropsContext } from '../../Utilities/DocContext';
import { useAppDispatch, useAppSelector } from '../../../../Redux/Hooks';
import {
  selectDocumentFormDataByKeys,
  updateMultipleKeysInDocument
} from '../../../../Redux/Slices/DocumentSlice';
import { shallowEqual } from 'react-redux';
import { activeTenantInfo } from '../../../../Redux/Slices/AuthSlice';
import {
  isDocumentInDraftMode,
  isDropShipDocument,
  isEditableDraft,
  isPurchaseDocument
} from '../../Utilities/DocCommonUtils';
import { getLinkedDocumentDetailsForDropship } from '../../Helper/Common/DocDataHelper';
import Utility from '../../../../Utility/Utility';
import DocContactSelector from '../Common/DocContactSelector';
import DocAddress from '../Common/DocAddress';
import { DraftTypes } from '../../../../Models/Drafts';
import DocAddressCustomFieldHolder from './DocAddressCustomFieldHolder';
import {
  BOOKS_ADDRESS_TYPES,
  DOCUMENT_MODE
} from '../../../../Constants/Constant';
import { isDocContactInactive } from '../../../../SharedComponents/DocumentForm/NewDocumentHelper';
import { DOCUMENT_KEYS } from '../../Utilities/DocConstants';

const ShipToSection = () => {
  const { t } = useTranslation();
  const { draftId, draftType, documentMode } = useContext(
    CommonDraftPropsContext
  );
  const [
    shipTo,
    contact,
    documentType,
    dropShip,
    dropShipName,
    linkedDocuments,
    receiveGoodsStatus,
    fulfillmentStatus,
    fulfillmentType,
    paymentStatus,
    totalAmount,
    duplicate,
    isConverting,
    customLocationCFValue
  ] = useAppSelector(
    selectDocumentFormDataByKeys(draftId, [
      'shipTo',
      'contact',
      'documentType',
      'dropShip',
      'dropShipName',
      'linkedDocuments',
      'receiveGoodsStatus',
      'fulfillmentStatus',
      'fulfillmentType',
      'paymentStatus',
      'totalAmount',
      'duplicate',
      'isConverting',
      DOCUMENT_KEYS.CUSTOM_LOCATION_CF_VALUE
    ]),
    shallowEqual
  );

  const dispatch = useAppDispatch();
  const tenantInfo = useAppSelector(activeTenantInfo);

  const isPurchaseTypeDoc = isPurchaseDocument(documentType);
  const isDropship = isDropShipDocument({ dropShip, fulfillmentType });

  // Add dropship contact name
  const addDropShipContactName = useCallback(async () => {
    // console.log('CALLING addDropShipContactName ...');
    const dropShipContactName = await getLinkedDocumentDetailsForDropship(
      linkedDocuments,
      contact
    );
    if (
      !Utility.isEmpty(dropShipContactName) &&
      dropShipContactName !== dropShipName
    ) {
      dispatch(
        updateMultipleKeysInDocument({
          draftId,
          keysToUpdate: { [DOCUMENT_KEYS.DROP_SHIP_NAME]: dropShipContactName }
        })
      );
    }
  }, [contact, dispatch, draftId, dropShipName, linkedDocuments]);

  if (isDropship && !dropShipName) {
    addDropShipContactName();
  }

  // Evaluate buyer name based on dropsip info(if any)
  let buyerName = '';
  if (isPurchaseDocument(documentType) && !isDropship) {
    buyerName = tenantInfo?.name;
    if (!Utility.isEmpty(customLocationCFValue)) {
      buyerName = customLocationCFValue?.locationDetails?.company;
    }
  } else {
    if (!Utility.isEmpty(dropShipName)) {
      buyerName = dropShipName;
    } else {
      buyerName = contact?.name
        ? `${contact?.name}-(${contact?.currencyCode})`
        : '';
    }
  }

  const getBuyerShippingAddressList = useCallback((): any[] => {
    return isPurchaseTypeDoc
      ? tenantInfo?.shippingAddresses
      : contact?.shippingAddress || [];
  }, [
    contact?.shippingAddress,
    isPurchaseTypeDoc,
    tenantInfo?.shippingAddresses
  ]);

  useEffect(() => {
    if (Utility.isEmpty(shipTo)) {
      let buyerPreferredShippingAddress = getBuyerShippingAddressList()?.find(
        (address) => address.preferred
      );
      if (Utility.isEmptyObject(buyerPreferredShippingAddress)) {
        buyerPreferredShippingAddress = getBuyerShippingAddressList()?.[0];
      }
      dispatch(
        updateMultipleKeysInDocument({
          draftId,
          keysToUpdate: {
            [DOCUMENT_KEYS.SHIP_TO]: buyerPreferredShippingAddress
          }
        })
      );
    }
  }, [dispatch, draftId, getBuyerShippingAddressList, shipTo]);

  const showInactiveIndicator =
    !isPurchaseTypeDoc &&
    (documentMode === DOCUMENT_MODE.COPY ||
      documentMode === DOCUMENT_MODE.NEW) &&
    (duplicate || isConverting) &&
    isDocContactInactive(contact);

  const allowContactSelection =
    isDocumentInDraftMode(draftType) && !isPurchaseTypeDoc;

  /**
   * @todo TBD do we need below condition and modification in the flow, as present in NewDocument2,
   */
  // GranularPermissionsHelper.hasPermissionFor(
  //   PERMISSIONS_BY_MODULE.CONTACTS.CREATE
  // );

  // const shipToCopy = { ...shipTo };
  //   if (
  //     addressType === BOOKS_ADDRESS_TYPES.SHIPPING_ADDRESS &&
  //     getTenantTaxSystem() === TAX_SYSTEM.INDIA_GST
  //   ) {
  //     delete shipToCopy.placeOfSupply;
  //   }

  return (
    <div className="column document-address-width">
      <DocContactSelector
        title={t(`DOCUMENT.SHIP_TO`)}
        contactName={buyerName}
        needSelectorButton={false}
        canEdit={allowContactSelection}
        canValidate={true}
        showInactiveIndicator={showInactiveIndicator}
      />
      <DocAddress
        isPurchaseDocument={isPurchaseTypeDoc}
        isSellerInfo={false}
        contact={contact || null}
        address={shipTo}
        placeholder={draftType === DraftTypes.READONLY ? '-' : 'Select address'}
        canEdit={
          isEditableDraft({
            draftType,
            documentType,
            receiveGoodsStatus,
            fulfillmentStatus,
            paymentStatus,
            totalAmount
          }) && !isDropship
        }
        addressList={getBuyerShippingAddressList()}
        preferCustomLocationDetails={
          isPurchaseTypeDoc &&
          !isDropship &&
          !Utility.isEmpty(customLocationCFValue)
        }
        locationDetails={
          isPurchaseTypeDoc &&
          !isDropship &&
          !Utility.isEmpty(customLocationCFValue)
            ? customLocationCFValue?.locationDetails
            : null
        }
        dataKey={'shipTo'}
      />
      {!isPurchaseTypeDoc &&
        !isDropship &&
        Utility.isNotEmpty(shipTo?.customFields) && (
          <DocAddressCustomFieldHolder
            addressType={BOOKS_ADDRESS_TYPES.SHIP_TO}
          />
        )}
    </div>
  );
};

export default ShipToSection;
