import {
  DKButton,
  DKDataGrid,
  DKInput,
  DKLabel,
  INPUT_TYPE,
  Toggle,
  DKSpinner,
  showAlert
} from 'deskera-ui-library';
import { useEffect, useState } from 'react';
import { INPUT_VIEW_DIRECTION } from '../../../Constants/Constant';
import { useAppDispatch, useAppSelector } from '../../../Redux/Hooks';

const FTPConfigurationPopup = (props: any) => {
  const FORM_CONFIGS = [
    {
      id: 'host',
      key: 'host',
      name: 'Host',
      type: INPUT_TYPE.TEXT,
      canValidate: false,
      readOnly: false,
      width: '',
      className: 'web-width-48',
      value: props?.bankFileData?.host || ``
    },
    {
      id: 'port',
      key: 'port',
      name: 'Port',
      type: INPUT_TYPE.TEXT,
      readOnly: false,
      canValidate: false,
      width: '',
      className: 'web-width-48',
      value: props?.bankFileData?.port || ``
    },
    {
      id: 'username',
      key: 'username',
      name: 'User Name',
      type: INPUT_TYPE.TEXT,
      readOnly: false,
      canValidate: false,
      width: '',
      className: 'web-width-48',
      value: props?.bankFileData?.username || ``
    },
    {
      id: 'password',
      key: 'password',
      name: 'Password',
      type: INPUT_TYPE.PASSWORD,
      readOnly: false,
      canValidate: false,
      width: '',
      className: 'web-width-48',
      value: props?.bankFileData?.password || ``
    },
    {
      id: 'targetPath',
      key: 'targetPath',
      name: 'Target Path',
      type: INPUT_TYPE.TEXT,
      readOnly: false,
      canValidate: false,
      width: '',
      className: 'parent-width',
      value: props?.bankFileData?.targetPath || ``
    }
  ];

  const [formConfig, setFormConfig] = useState(FORM_CONFIGS);
  const [saveApiLoading, setSaveApiLoading] = useState(false);

  const validateFormData = () => {
    let isValid = true;
    const newConfig = [...formConfig]
    newConfig.forEach((form: any) => {
      if (!form.value) {
        isValid = false;
        form.canValidate = true;
      }
    });

    return { isValid, newConfig };
  };

  const onSave = () => {
    const formData: any = {}
    const { isValid, newConfig } = validateFormData();
    if (isValid) {
      newConfig.forEach((form: any) => {
        formData[form.key] = form.value
      })
      props.onUpdate(formData)
    } else {
      setFormConfig(newConfig);
    }
  };

  const getHeader = () => {
    return (
      <div className="row justify-content-between p-h-r p-v-s bg-gray1">
        <div className="row width-auto">
          <DKLabel text={`FTP Configuration`} className="fw-m fs-l" />
        </div>
        <div className="row width-auto">
          <DKButton
            title={'Cancel'}
            className="bg-white border-m mr-r"
            onClick={() => {
              props.onClose();
            }}
          />
          <div className={`row border-radius-m ${saveApiLoading ? 'bg-gray1 border-m' : 'bg-button text-white'}`}>
            <DKButton
              title={'Save'}
              className=""
              onClick={() => {
                onSave();
              }}
            />
            {saveApiLoading && <DKSpinner iconClassName="ic-s" />}
          </div>
        </div>
      </div>
    );
  };

  const getForm = () => {
    return (
      <div className="row row-responsive flex-wrap justify-content-between gap-4">
        {formConfig?.map((item: any, index: any) => {
          return (
            <div className={item.className || ''}>
              {getInputField(item, index)}
            </div>
          );
        })}
      </div>
    );
  };

  const onChange = (text: any, obj: any, index: any, isBlur: any): void => {
    const newConfig = [...formConfig];
    newConfig[index] = {
      ...newConfig[index],
      value: isBlur ? text.trim() : text,
      canValidate: true
    };
    setFormConfig(newConfig);
  }

  const getInputField = (obj: any, index: any) => {
    return (
      <DKInput
        title={obj.name || ``}
        placeholder={obj?.name ?? ''}
        required={true}
        readOnly={obj?.readOnly}
        canValidate={obj?.canValidate}
        direction={INPUT_VIEW_DIRECTION.VERTICAL}
        value={obj.value || ''}
        className={obj.className || ''}
        onChange={(text: any) => {
          onChange(text, obj, index, false)
        }}
        onBlur={(event: any) => {
          onChange(event.target.value, obj, index, true)
        }}
      />
    );
  };

  return (
    <div className="transparent-background">
      <div
        className="popup-window"
        style={{
          maxWidth: 520,
          width: '90%',
          maxHeight: '95%',
          padding: 0
        }}
      >
        {getHeader()}
        <div className="column parent-width p-h-l p-v-m pb-xl ">
          {getForm()}
        </div>
      </div>
    </div>
  );
};

export default FTPConfigurationPopup;
