import { useContext } from 'react';
import { shallowEqual } from 'react-redux';
import { CommonDraftPropsContext } from '../../Utilities/DocContext';
import DocSerialNumberSelector from '../Common/DocSerialNumberSelector';
import { selectDocumentFormDataByKeys } from '../../../../Redux/Slices/DocumentSlice';
import { useAppSelector } from '../../../../Redux/Hooks';
import { isDocumentFullScreenON } from '../../../../SharedComponents/DocumentForm/NewDocumentHelper';
import DocDates from '../Common/DocDates';
import DocCommonInputs from './DocCommonInputs';
import DocLinkedDocuments from './DocLinkedDocuments';
import DocRCMCheck from './DocRCMCheck';

const DocRightInfoPanel = () => {
  const { draftId } = useContext(CommonDraftPropsContext);
  const [documentType] = useAppSelector(
    selectDocumentFormDataByKeys(draftId, ['documentType']),
    shallowEqual
  );

  const isDocumentInFullScreen = isDocumentFullScreenON(documentType);

  return (
    <div className="column" style={{ width: 250, gap: 5 }}>
      <DocSerialNumberSelector />
      {!isDocumentInFullScreen && <DocDates showFullscreenLayout={false} />}
      {!isDocumentInFullScreen && <DocCommonInputs inRightPanel={true} />}
      <DocRCMCheck />
      <DocLinkedDocuments />
    </div>
  );
};

export default DocRightInfoPanel;
