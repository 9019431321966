import Utility from '../../../../../Utility/Utility';
import { JC_STATUS } from '../../../Constants/MRPColumnConfigs';
import { getUpdatedWarehouseInventoryDataConsideringUOM } from '../../../JobCard/AddJobCard/AddJobCardPresenter';
import { JC_PROCESS_TYPE } from '../../../Shared/JobCardListComponent/JobCardListPresenter';

export const renderProductNameByProductCode = (
  productCode: string,
  workOrderData: any
) => {
  const { workOrderItems } = workOrderData;
  const item = workOrderItems?.find(
    (woItem: any) => woItem?.itemName?.productId === productCode
  );
  return item?.itemName?.name || '-';
};

export const getStatusCard = (value: any) => {
  return JC_STATUS[value as keyof typeof JC_STATUS] || {};
};

export const buildJCPayload = (row: any, product: any) => {
  let [existingJCDetail] = row?.jobCardLinkDetails || [];
  if (!existingJCDetail) {
    existingJCDetail = {};
  }

  const jcDetails: any = {};
  jcDetails.id = existingJCDetail.id;
  jcDetails.jobCardCode = row.jobCardCode;
  jcDetails.qcNeeded =
    row?.localProcessType === JC_PROCESS_TYPE.PROCESSING ? true : false;
  jcDetails.workOrderCode = row.workOrderCode;
  jcDetails.productCode = existingJCDetail?.productCode ?? null;
  jcDetails.processType = existingJCDetail?.processType ?? null;
  jcDetails.itemWarehouseInventoryData =
    existingJCDetail?.itemWarehouseInventoryData ?? [];
  jcDetails.documentUOMSchemaDefinition = Utility.isNotEmpty(product)
    ? product?.documentUOMSchemaDefinition
    : existingJCDetail?.documentUOMSchemaDefinition;
  jcDetails.stockUom = Utility.isNotEmpty(product)
    ? product?.documentUOMSchemaDefinition?.sinkUOM
    : existingJCDetail?.documentUOMSchemaDefinition?.sinkUOM;
  return jcDetails;
};

export const toolTipContent = ({
  itemQuantity,
  passedQuantity,
  rejectedQuantity,
  processType
}: {
  itemQuantity: any;
  passedQuantity: any;
  rejectedQuantity: any;
  processType: JC_PROCESS_TYPE;
}) => {
  if (processType === JC_PROCESS_TYPE.PRODUCTION) return '';

  return `<div class="column">
<div class="row">Total Quantity &nbsp;<b>${itemQuantity || 0}</b></div>
<div class="row">Passed Quantity &nbsp;<b>${passedQuantity || 0}</b></div>
<div class="row">Rejected Quantity &nbsp;<b>${rejectedQuantity || 0}</b></div>
</div>`;
};

const getUpdatedJCLinkDetails = (jobCardLinkDetails: any) => {
  let updatedData = jobCardLinkDetails?.map((jcLinkDetail: any) => {
    return {
      ...jcLinkDetail,
      itemQuantity: jcLinkDetail?.uomItemQuantity ?? jcLinkDetail?.itemQuantity,
      passedQuantity:
        jcLinkDetail?.uomPassedQuantity ?? jcLinkDetail?.passedQuantity,
      rejectedQuantity:
        jcLinkDetail?.uomRejectedQuantity ?? jcLinkDetail?.rejectedQuantity,
      itemWarehouseInventoryData:
        getUpdatedWarehouseInventoryDataConsideringUOM(
          jcLinkDetail?.itemWarehouseInventoryData
        ) ?? [],
      passedWarehouseInventoryData:
        getUpdatedWarehouseInventoryDataConsideringUOM(
          jcLinkDetail?.passedWarehouseInventoryData
        ),
      rejectedWarehouseInventoryData:
        getUpdatedWarehouseInventoryDataConsideringUOM(
          jcLinkDetail?.rejectedWarehouseInventoryData
        )
    };
  });

  return updatedData ?? [];
};

export const getQueriedJobCardsProcessed = (rows: any) => {
  let updatedRows = rows?.map((itemRow: any) => {
    return {
      ...itemRow,
      jobCardLinkDetails: getUpdatedJCLinkDetails(itemRow?.jobCardLinkDetails),
      localJobCardLinkDetails: getUpdatedJCLinkDetails(
        itemRow?.jobCardLinkDetails
      ),
      jobCardLinkDetailsAvailableToAssign:
        itemRow?.jobCardLinkDetailsAvailableToAssign?.map(
          (jcAvailToAssign: any) => {
            return {
              ...jcAvailToAssign,
              warehouseInventoryData:
                getUpdatedWarehouseInventoryDataConsideringUOM(
                  jcAvailToAssign?.warehouseInventoryData
                )
            };
          }
        )
    };
  });

  return updatedRows;
};
