import { useContext, useEffect, useState } from 'react';
import { CommonDraftPropsContext } from '../../Utilities/DocContext';
import { useAppDispatch, useAppSelector } from '../../../../Redux/Hooks';
import {
  selectDocumentFormDataByKeys,
  updateMultipleKeysInDocument
} from '../../../../Redux/Slices/DocumentSlice';
import { shallowEqual } from 'react-redux';
import { CustomFieldsHolder } from '../../../../SharedComponents/CustomFieldsHolder/CustomFieldsHolder';
import {
  getCFModuleFromDocType,
  getColumnConfigFromDocType
} from '../../Helper/Common/CustomFieldsHelper';
import { LocationDTO } from '../../../../Models/Interfaces';
import Utility from '../../../../Utility/Utility';
import useDebounce from '../../../../Hooks/useDebounce';
import { onLocationCFChange } from '../../Helper/DocumentUpdates/LocationCFChangeHelper';
import {
  DOCUMENT_MODE,
  LOCATION_CLASS_ENUM
} from '../../../../Constants/Constant';
import { locationsData } from '../../../../Redux/Slices/LocationSlice';
import LocationService from '../../../../Services/Location';
import { DOCUMENT_KEYS } from '../../Utilities/DocConstants';

/**
 * Component to show and handle document level custom fields
 */
const DocCustomFieldHolder = () => {
  const locationData = useAppSelector(locationsData);
  const { draftId, documentMode } = useContext(CommonDraftPropsContext);
  const [contact, customField, documentType, dropShip, fulfillmentType] =
    useAppSelector(
      selectDocumentFormDataByKeys(draftId, [
        'contact',
        'customField',
        'documentType',
        'dropShip',
        'fulfillmentType'
      ]),
      shallowEqual
    );

  const dispatch = useAppDispatch();
  const columnConfigInfo = getColumnConfigFromDocType(documentType);
  const [customFields, setCustomFields] = useState(
    !Utility.isEmpty(customField) ? [...customField] : []
  );
  const debouncedCustomFields = useDebounce(customFields, 500);

  useEffect(() => {
    dispatch(
      updateMultipleKeysInDocument({
        draftId,
        keysToUpdate: {
          [DOCUMENT_KEYS.CUSTOM_FIELDS]: !Utility.isEmpty(debouncedCustomFields)
            ? [...debouncedCustomFields]
            : []
        }
      })
    );
  }, [debouncedCustomFields, dispatch, draftId]);

  const updateLocationCFFlagInDocument = (updatedValue: any) => {
    dispatch(
      updateMultipleKeysInDocument({
        draftId,
        keysToUpdate: { [DOCUMENT_KEYS.CUSTOM_LOCATION_CF_VALUE]: updatedValue }
      })
    );
  };

  return (
    <div className="row">
      <CustomFieldsHolder
        moduleName={getCFModuleFromDocType(documentType)}
        customFieldsList={customFields}
        documentMode={documentMode}
        onUpdate={async (updatedCFList) => {
          // Handle location CF, when CF list is updated
          const listContainsLocation = updatedCFList.find(
            (cf: any) => cf.label === LOCATION_CLASS_ENUM.LOCATION
          );
          if (listContainsLocation && listContainsLocation?.value) {
            const locationObjForCF = locationData.find(
              (loc: any) => loc.label === listContainsLocation.value
            );
            if (locationObjForCF) {
              updateLocationCFFlagInDocument(locationObjForCF);
            } else if (
              documentMode === DOCUMENT_MODE.EDIT ||
              documentMode === DOCUMENT_MODE.VIEW
            ) {
              // Handle edit mode when location CF is deleted ...
              // Get the address from payload
              const locationCFinDocument = customField?.find(
                (x: any) => x.label === LOCATION_CLASS_ENUM.LOCATION
              );

              if (locationCFinDocument) {
                if (locationCFinDocument.value === '') {
                  updateLocationCFFlagInDocument(undefined);
                } else {
                  try {
                    const locationObj =
                      await LocationService.getLocationByLabel(
                        locationCFinDocument.value
                      );
                    if (!Utility.isEmpty(locationObj)) {
                      updateLocationCFFlagInDocument(locationObj);
                    }
                  } catch (err) {
                    console.error('Error loading location by label: ', err);
                  }
                }
              }
            }
          }

          setCustomFields(updatedCFList);
        }}
        onLocationUpdate={(locCF: LocationDTO) => {
          onLocationCFChange({
            draftId,
            documentType,
            locCF,
            dropShip,
            fulfillmentType
          });
        }}
        contact={contact}
        columnConfig={columnConfigInfo.columnConfig}
        columnConfigTableId={columnConfigInfo.tableId}
      />
    </div>
  );
};

export default DocCustomFieldHolder;
