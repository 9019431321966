import { useContext } from 'react';
import { CommonDraftPropsContext } from '../../Utilities/DocContext';
import {
  selectDocumentFormDataByKeys,
  selectDocumentMetaDataByKey
} from '../../../../Redux/Slices/DocumentSlice';
import { useAppSelector } from '../../../../Redux/Hooks';
import { shallowEqual } from 'react-redux';
import { isPurchaseDocument } from '../../Utilities/DocCommonUtils';
import Utility from '../../../../Utility/Utility';
import DocContactSelector from '../Common/DocContactSelector';
import BillToSection from './BillToSection';
import ShipToSection from './ShipToSection';

/**
 * @description
 * This is independent of Document type
 */
const DocBuyerInfo = () => {
  const { draftId } = useContext(CommonDraftPropsContext);
  const canValidate = useAppSelector(
    selectDocumentMetaDataByKey(draftId, 'canValidate')
  );
  const [contact, documentType] = useAppSelector(
    selectDocumentFormDataByKeys(draftId, ['contact', 'documentType']),
    shallowEqual
  );

  return !isPurchaseDocument(documentType) && Utility.isEmpty(contact) ? (
    <DocContactSelector needSelectorButton={true} canValidate={!!canValidate} />
  ) : (
    <div className={`row gap-2 align-items-start`}>
      <BillToSection />
      <ShipToSection />
    </div>
  );
};

/*******
 *  @todo Add warning, placeofsupply & destination for IN org..
 * Also check for deleted contact on opening form..
 * const getWarningForInactiveContact = () => {
    if (
      (props.documentMode === DOCUMENT_MODE.COPY ||
        props.documentMode === DOCUMENT_MODE.NEW) &&
      (booksDocument?.duplicate || booksDocument?.isConverting) &&
      isDocContactInactive(contact)
    ) {
      return (
        <DKTooltipWrapper
          content="Inactive contact"
          tooltipClassName="bg-deskera-secondary width-auto"
          tooltipPositionAbsolute={true}
          tooltipStyle={{ top: 20, left: 'unset' }}
        >
          <div className="row">
            <DKIcon
              src={DKIcons.ic_warning_red}
              className="ic-s ml-s"
              onClick={() => {}}
            />
          </div>
        </DKTooltipWrapper>
      );
    } else {
      return null;
    }
  };

 * const getPlaceOfSupply = () => {
  return (
    <div style={{ width: 150 }}>
      <DKInput
        value={pos || ''}
        title={
          Utility.isSalesDocument(props.booksDocument)
            ? 'Place of Supply'
            : 'Source of Supply'
        }
        direction={INPUT_VIEW_DIRECTION.VERTICAL}
        formatter={(obj: any) => {
          return obj.label;
        }}
        readOnly={!checkForCustomField}
        className="parent-width mt-5"
        type={INPUT_TYPE.DROPDOWN}
        required={false}
        titleStyle={{
          color: 'gray'
        }}
        onChange={(value: any) => {
          setPos(value);
          let updatedState = {
            ...booksDocument,
            placeOfSupply: value.value
          };
          setBooksDocument({ ...updatedState });
          onPlaceOfsupplyChange(value);
        }}
        dropdownConfig={{
          allowSearch: true,
          searchableKey: 'label',
          data: indianStatesOptions,
          renderer: (index: any, obj: any) => {
            return (
              <div className="flex flex-row w-full justify-content-between">
                <DKLabel
                  style={{ fontSize: '13px' }}
                  className="text-base border-radius-s"
                  text={obj.label}
                />
              </div>
            );
          }
        }}
      />
    </div>
  );
};
const getSourceOfDestination = () => {
  return (
    <div style={{ width: 150 }}>
      <DKInput
        value={dos || ''}
        title={'Destination of Supply'}
        direction={INPUT_VIEW_DIRECTION.VERTICAL}
        formatter={(obj: any) => {
          return obj.label;
        }}
        className="parent-width mt-5"
        type={INPUT_TYPE.DROPDOWN}
        required={false}
        titleStyle={{
          color: 'gray'
        }}
        readOnly={!checkForCustomField}
        onChange={(value: any) => {
          setDos(value);
          let updatedState = {
            ...booksDocument
          };
          setBooksDocument({ ...updatedState });
          onDestinationOfsupplyChange(value);
        }}
        dropdownConfig={{
          allowSearch: true,
          searchableKey: 'label',
          data: indianStatesOptions,
          renderer: (index: any, obj: any) => {
            return (
              <div className="flex flex-row w-full justify-content-between">
                <DKLabel
                  style={{ fontSize: '13px' }}
                  className="text-base border-radius-s"
                  text={obj.label}
                />
              </div>
            );
          }
        }}
      />
    </div>
  );
}; */

export default DocBuyerInfo;
