import { useAppSelector, isSelectorArraysEqual } from '../../Redux/Hooks';
import { selectDocumentDraftContextData } from '../../Redux/Slices/DocumentSlice';
import { DynamicPopupWrapper } from '../../SharedComponents/PopupWrapper';
import Canvas from './Canvas';
import ActionBar from './Views/ActionBar/ActionBar';
import { CommonDraftPropsContext } from './Utilities/DocContext';
import { DraftTypes } from '../../Models/Drafts';
import { DOCUMENT_MODE } from '../../Constants/Constant';
import { selectShowMainDocsInFullScreen } from '../../Redux/Slices/CommonDataSlice';

interface IDocumentFormProps {}

const DocumentForm = (props: IDocumentFormProps) => {
  const draftDocs: any[] = useAppSelector(
    selectDocumentDraftContextData,
    isSelectorArraysEqual
  );
  const showMainDocsInFullscreen = useAppSelector(
    selectShowMainDocsInFullScreen
  );

  const getDocumentMode = (draftType: DraftTypes) => {
    switch (draftType) {
      case DraftTypes.NEW:
      case DraftTypes.DRAFT:
        return DOCUMENT_MODE.NEW;
      case DraftTypes.COPY:
        return DOCUMENT_MODE.COPY;
      case DraftTypes.UPDATE:
        return DOCUMENT_MODE.EDIT;
      case DraftTypes.READONLY:
        return DOCUMENT_MODE.VIEW;
    }
  };

  return (
    <>
      {draftDocs
        .filter((draftDoc) => draftDoc.isMaximized)
        .map((draftDoc) => {
          return (
            <DynamicPopupWrapper key={draftDoc.id}>
              <CommonDraftPropsContext.Provider
                value={{
                  draftId: draftDoc.id,
                  draftType: draftDoc.draftType,
                  documentMode: getDocumentMode(draftDoc.draftType),
                  isCashInvoice: draftDoc.isCashInvoice || false
                }}
              >
                <div className="transparent-background pointer-events-auto">
                  <div
                    className="popup-window shadow-m maximized-draft-wrapper overflow-hidden"
                    style={{
                      maxWidth: showMainDocsInFullscreen ? '100%' : 1078,
                      width: showMainDocsInFullscreen ? '100%' : '90%',
                      height: showMainDocsInFullscreen ? '100%' : '95%',
                      maxHeight: showMainDocsInFullscreen ? '100%' : '95%',
                      padding: 0,
                      pointerEvents: 'auto',
                      borderRadius: showMainDocsInFullscreen ? 0 : 4,
                      overflow: 'hidden'
                    }}
                  >
                    <ActionBar />
                    <Canvas />
                  </div>
                </div>
              </CommonDraftPropsContext.Provider>
            </DynamicPopupWrapper>
          );
        })}
    </>
  );
};

export default DocumentForm;
