import ApiConstants from '../Constants/ApiConstants';
import { Invoice } from '../Models/Invoice';
import Utility from '../Utility/Utility';
import http from './Interceptor';

export interface CreditNoteAPIConfig {
  SearchTerm?: string;
  Limit?: number;
  Page?: number;
  QueryParam?: any;
  Query?: any;
  Sort?: any;
  SortDir?: any;
}

const defaultConfig: CreditNoteAPIConfig = {
  SearchTerm: '',
  Limit: 25,
  Page: 0,
  QueryParam: '',
  Query: ''
};
class CreditNoteService {
  static apiConfig: CreditNoteAPIConfig = defaultConfig;
  static abortController: any = null;

  static getCreditNoteByPage() {
    Utility.cancelRequest(CreditNoteService.abortController);
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    }

    let queryString: string = `?${
      this.apiConfig.Query ? '&query=' + this.apiConfig.Query : ''
    }&search=${this.apiConfig.SearchTerm ? encodeURIComponent(this.apiConfig.SearchTerm) : ''}&limit=${this.apiConfig.Limit}&page=${
      this.apiConfig.Page
    }${
      this.apiConfig.QueryParam ? this.apiConfig.QueryParam : ''
    }&cntype=null&isCustomDetailLineReport=false`;

    if (this.apiConfig.Sort) {
      queryString += `&sort=${this.apiConfig.Sort}`;
    }
    if (this.apiConfig.SortDir) {
      queryString += `&sortDir=${this.apiConfig.SortDir}`;
    }

    const finalEndpoint: string =
      ApiConstants.URL.CREDIT_NOTES.FETCH_CREDIT_NOTES + queryString;

    CreditNoteService.abortController = Utility.createAbortController();

    return http
      .get(`${finalEndpoint}`, {
        signal: CreditNoteService.abortController.signal
      })
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static getCreditNoteWithCode(code: string) {
    let url = `${ApiConstants.URL.BASE}notes/credit?search=${code}&limit=25&page=0&cntype=null&isCustomDetailLineReport=false`;
    return http
      .get(url)
      .then((res: any) => {
        return Promise.resolve(res);
      })
      .catch((err: any) => {
        return Promise.reject(err);
      });
  }

  static createCreditNote(payload: any) {
    return http
      .post(`${ApiConstants.URL.CREDIT_NOTES.CREATE_CREDIT_NOTE}`, payload)
      .catch((err: any) => {
        console.error('Error while trying to post status: ', err);
        return Promise.reject(err);
      });
  }

  static updateCreditNote(id: number, payload: any) {
    return http
      .put(`${ApiConstants.URL.CREDIT_NOTES.UPDATE_CREDIT_NOTES(id)}`, payload)
      .catch((err: any) => {
        console.error('Error updating credit note: ', err);
        return Promise.reject(err);
      });
  }

  static deleteCreditNote(id: number) {
    return http
      .delete(`${ApiConstants.URL.CREDIT_NOTES.DELETE_CREDIT_NOTE(id)}`)
      .catch((err: any) => {
        console.error('Error updating credit note: ', err);
        return Promise.reject(err);
      });
  }

  static getCreditNote(id: string) {
    const queryString = '?search=' + id;
    const endpoint =
      ApiConstants.URL.CREDIT_NOTES.FETCH_CREDIT_NOTES + queryString;
    return http.get(endpoint);
  }
}

export default CreditNoteService;
