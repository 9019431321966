import ApiConstants from '../Constants/ApiConstants';
import Utility from '../Utility/Utility';
import http from './Interceptor';

export interface ExpenseAllocationTemplatesApiConfig {
  SearchTerm?: string;
  Query?: string;
  Limit?: number;
  Page?: number;
}

export const defaultTemplateConfig: ExpenseAllocationTemplatesApiConfig = {
  SearchTerm: '',
  Limit: 25,
  Page: 0,
  Query: ''
};

class ExpenseAllocationService {
  static templatesApiConfig: ExpenseAllocationTemplatesApiConfig;
  static templatesAbortController: any = null;

  static getExpenseAllocationTemplatesEndpoint() {
    if (Utility.isEmpty(this.templatesApiConfig)) {
      this.templatesApiConfig = defaultTemplateConfig;
    }
    let queryString: string = `?search=${
      this.templatesApiConfig.SearchTerm
        ? this.templatesApiConfig.SearchTerm
        : ''
    }&limit=${
      this.templatesApiConfig.Limit ? this.templatesApiConfig.Limit : 25
    }&page=${this.templatesApiConfig.Page ? this.templatesApiConfig.Page : 0}${
      this.templatesApiConfig.Query
        ? '&query=' + this.templatesApiConfig.Query
        : ''
    }`;

    const finalEndpoint: string =
      ApiConstants.URL.EXPENSE_ALLOCATION.EXPENSE_ALLOCATION_TEMPLATES + queryString;
    return finalEndpoint;
  }

  static getExpenseAllocationTemplatesByPage() {
    Utility.cancelRequest(ExpenseAllocationService.templatesAbortController);

    ExpenseAllocationService.templatesAbortController =
      Utility.createAbortController();
    return http
      .get(`${ExpenseAllocationService.getExpenseAllocationTemplatesEndpoint()}`, {
        signal: ExpenseAllocationService.templatesAbortController.signal
      })
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static createExpenseAllocationTemplate(data: any) {
    const finalEndPoint: string =
      ApiConstants.URL.EXPENSE_ALLOCATION.EXPENSE_ALLOCATION_TEMPLATES;

    return http
      .post(finalEndPoint, data)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static updateExpenseAllocationTemplate(data: any) {
    const finalEndPoint: string =
      ApiConstants.URL.EXPENSE_ALLOCATION.UPDATE_EXPENSE_ALLOCATION(data.id);

    return http
      .put(finalEndPoint, data)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static deleteExpenseAllocationTemplate(id: string) {
    const finalEndPoint: string =
      ApiConstants.URL.EXPENSE_ALLOCATION.UPDATE_EXPENSE_ALLOCATION(id);

    return http
      .delete(finalEndPoint)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static runExpenseAllocation(id: string, data: any) {
    const finalEndPoint: string =
      ApiConstants.URL.EXPENSE_ALLOCATION.RUN_EXPENSE_ALLOCATION(id);

    return http
      .post(finalEndPoint, data)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static updateExpenseAllocationStatus(id: string, status: string) {
    const finalEndPoint: string =
      ApiConstants.URL.EXPENSE_ALLOCATION.UPDATE_EXPENSE_ALLOCATION_STATUS(id) + '?active=' + status;

    return http
      .put(finalEndPoint)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }
}

export default ExpenseAllocationService;
