import { DKIcon, DKIcons, DKLabel, DKTooltipWrapper } from 'deskera-ui-library';
import { DocumentConfigUtility } from '../../../../Utility/DocumentConfigUtility';

export const discountColumnRenderer = ({ data, currency }: any) => {
  const hasPercent = data?.rowData?.discountInPercent;
  return (
    <div className="column parent-width align-items-end">
      <DKLabel
        className="fs-m"
        text={
          hasPercent
            ? data.value + '%'
            : DocumentConfigUtility.amountFormatter(data.value, currency)
        }
      />
    </div>
  );
};

export const cascadingDiscountColumnRenderer = ({
  data,
  column,
  currency
}: any) => {
  const hasPercent = data?.value?.toString()?.includes('%');
  return (
    <div className="column parent-width align-items-end">
      <DKLabel
        className="fs-m"
        text={
          hasPercent
            ? data.value
            : DocumentConfigUtility.amountFormatter(data.value, currency)
        }
      />
      {hasPercent ? (
        <DKLabel
          className="text-gray fs-s"
          text={
            data?.rowData?.[`${column.key}_details`]
              ? DocumentConfigUtility.amountFormatter(
                  data?.rowData?.[`${column.key}_details`]?.unitDiscount,
                  currency
                )
              : ''
          }
        />
      ) : null}
    </div>
  );
};

export const amortizationTemplateColumnRenderer = (index: any, value: any) => (
  <DKLabel text={`${value?.name}`} />
);

export const getIndicatorForInactiveContact = () => {
  return (
    <DKTooltipWrapper
      content="Inactive contact"
      tooltipClassName="bg-deskera-secondary width-auto"
    >
      <DKIcon
        src={DKIcons.ic_warning_red}
        className="ic-s ml-s"
        onClick={() => {}}
      />
    </DKTooltipWrapper>
  );
};
