import { FC, useContext } from 'react';
import { DKCheckMark } from 'deskera-ui-library';
import { useAppDispatch, useAppSelector } from '../../../../../Redux/Hooks';
import {
  calculateTaxesAndAmountsForAllLineItems,
  selectDocumentFormDataByKeys,
  updateMultipleKeysInDocument
} from '../../../../../Redux/Slices/DocumentSlice';
import { CommonDraftPropsContext } from '../../../Utilities/DocContext';
import {
  DOCUMENT_KEYS,
  DOC_LINE_ITEM_KEYS
} from '../../../Utilities/DocConstants';
import { useTranslation } from 'react-i18next';
import { cloneDeep } from 'lodash';
interface IUnitPriceTaxCheckProps {}

const UnitPriceTaxCheck: FC<IUnitPriceTaxCheckProps> = () => {
  const dispatch = useAppDispatch();
  const { t, i18n } = useTranslation();

  const { draftId } = useContext(CommonDraftPropsContext);

  const [unitPriceGstInclusive, items] = useAppSelector(
    selectDocumentFormDataByKeys(draftId, [
      DOCUMENT_KEYS.UNIT_PRICE_GST_INCLUSIVE,
      DOCUMENT_KEYS.ITEMS
    ])
  );

  const handleOnClick = () => {
    let copyOfItems = cloneDeep(items);
    copyOfItems.forEach((item: any) => {
      item[DOC_LINE_ITEM_KEYS.UNIT_PRICE_GST_INCLUSIVE] =
        !unitPriceGstInclusive;
    });

    dispatch(
      updateMultipleKeysInDocument({
        draftId,
        keysToUpdate: {
          [DOCUMENT_KEYS.ITEMS]: copyOfItems,
          [DOCUMENT_KEYS.UNIT_PRICE_GST_INCLUSIVE]: !unitPriceGstInclusive
        }
      })
    );
    dispatch(
      calculateTaxesAndAmountsForAllLineItems({ draftId, items: copyOfItems })
    );
  };

  return (
    <div
      className="flex flex-row box-border w-full items-start width-auto mb-l justify-content-between"
      style={{ width: '100%', marginTop: -32 }}
    >
      <div className="flex flex-row items-end justify-end w-full">
        <DKCheckMark
          color="bg-button"
          isSelected={unitPriceGstInclusive}
          onClick={handleOnClick}
          className="text-black z-index-1"
          title={t(
            `DOCUMENT.DOCUMENT_SUMMARY_VIEW.UNIT_PRICE_IS_TAX_INCLUSIVE`
          )}
        />
      </div>
    </div>
  );
};

export default UnitPriceTaxCheck;
