import { DKButton, DKIcons, DKListPicker, DKSpinner } from 'deskera-ui-library';
import { DraftTypes } from '../../../../Models/Drafts';
import { useState } from 'react';
import { DOC_SAVE_OPTION } from '../../Helper/View/ActionBarHelper';

interface ActionBarSaveButtonProps {
  draftType: DraftTypes;
  isApprovalRequired: boolean;
  isSaving: boolean;
  isErrorOnSave: boolean;
  onSave: (option: DOC_SAVE_OPTION) => void;
  className?: string;
  style?: any;
}
const ActionBarSaveButton = (props: ActionBarSaveButtonProps) => {
  const [showSavePicker, setShowSavePicker] = useState(false);
  let saveOptions = ['Save as draft', 'Save & close'];

  return (
    <div
      className={`row width-auto justify-content-between ${
        props.isSaving ? 'border-m' : 'bg-button'
      } position-relative ${props.className || ''}`}
      style={{
        ...(props.style ? props.style : {}),
        borderColor: props.isSaving ? '' : 'transparent'
      }}
    >
      <div className={`row`}>
        <DKButton
          title={props.isSaving ? 'Saving' : 'Save'}
          onClick={() => {
            if (props.isApprovalRequired) {
              props.onSave(DOC_SAVE_OPTION.SAVE_AS_DRAFT);
            } else {
              props.onSave(DOC_SAVE_OPTION.SAVE);
            }
          }}
          disabled={props.isSaving || props.isErrorOnSave}
          className={`column border-radius-none ${
            props.isSaving ? 'text-gray' : 'text-white'
          }`}
          style={{
            maxHeight: 34,
            paddingRight:
              (props.draftType !== DraftTypes.UPDATE &&
                !props.isApprovalRequired) ||
              props.isSaving
                ? 0
                : 12
          }}
        />
        {props.isSaving && (
          <DKSpinner iconClassName="ic-s" className="column pl-xs pr-m" />
        )}
      </div>
      {props.draftType !== DraftTypes.UPDATE &&
      !props.isSaving &&
      !props.isApprovalRequired ? (
        <>
          <DKButton
            icon={
              props.isSaving
                ? DKIcons.ic_arrow_down2
                : DKIcons.white.ic_arrow_down2
            }
            disabled={props.isSaving}
            className={`border-radius-none`}
            onClick={() => setShowSavePicker(true)}
          />
          {showSavePicker ? (
            <DKListPicker
              data={saveOptions}
              className="position-absolute z-index-3 border-m shadow-m"
              style={{
                top: 35,
                right: 0,
                width: 'max-content'
              }}
              onSelect={(index: number, title: string) => {
                setShowSavePicker(false);
                switch (index) {
                  case 0:
                    props.onSave(DOC_SAVE_OPTION.SAVE_AS_DRAFT);
                    break;
                  case 1:
                    props.onSave(DOC_SAVE_OPTION.SAVE_AND_CLOSE);
                    break;
                }
              }}
              onClose={() => setTimeout(() => setShowSavePicker(false), 100)}
            />
          ) : null}
        </>
      ) : null}
    </div>
  );
};
export default ActionBarSaveButton;
