import cloneDeep from 'lodash/cloneDeep';
import {
  COUNTRY_CODES,
  CURRENCY_PRECISION,
  DOC_TYPE,
  FULFILLMENT_STATUS,
  GST_TYPE,
  PAYMENT_STATUS,
  PRODUCT_TYPE,
  RECEIVED_GOODS_STATUS,
  ROUNDING_METHOD,
  TAX_SYSTEM
} from '../../../../Constants/Constant';
import { NUMBERS } from '../../../../Constants/Enum';
import { AdditionalChargeForDoc } from '../../../../Models/Document';
import { DraftTypes } from '../../../../Models/Drafts';
import { Store } from '../../../../Redux/Store';
import AuthService from '../../../../Services/Auth';
import { ADDITIONAL_CHARGE_METHODS } from '../../../../SharedComponents/AdditionalCharges/AdditionalCharges';
import Utility, { deepClone } from '../../../../Utility/Utility';
import {
  calculateAmountsForSummary,
  calculateChargeAmountAndTax,
  getAdditionalChargesTotalAndTax
} from '../../Helper/Common/DocDataHelper';
import {
  CHARGE_TYPE,
  DOCUMENT_KEYS,
  IDocSummaryAmount
} from '../../Utilities/DocConstants';
import {
  convertToCurrenctExchangeRate,
  getTenantTaxSystem
} from '../../../../SharedComponents/DocumentForm/NewDocumentHelper';
import { DocLineItemCalculator } from '../../Helper/Calculator/DocLineItemCalculator';
import { updateMultipleKeysInDocument } from '../../../../Redux/Slices/DocumentSlice';

export const calculateManualAdditionalChargeDistribution = (
  items: any[],
  existingChargeDetails: any[],
  existingChargeIndex: number,
  selectedCharge: any,
  itemsLength: number
): any => {
  if (existingChargeIndex > -1) {
    return existingChargeDetails[existingChargeIndex].chargeAmount
      ? existingChargeDetails[existingChargeIndex].chargeAmount
      : 0;
  } else {
    let manualApportionChargesInItems: any[] = [];

    items.forEach((item: any) => {
      const additionalCharge =
        item.additionalCharges?.additionalChargesDetails?.find(
          (c: any) => c.additionalCharge === selectedCharge.additionalCharge
        );
      if (!Utility.isEmpty(additionalCharge)) {
        manualApportionChargesInItems.push(additionalCharge);
      }
    });
    if (manualApportionChargesInItems.length) {
      const manualApportionChargesTotal = manualApportionChargesInItems.reduce(
        (total: number, detail: any) =>
          total + Number(detail?.chargeAmount || 0),
        0
      );
      if (manualApportionChargesTotal !== selectedCharge.chargeAmount) {
        return Utility.roundOffToTenantDecimalScale(
          selectedCharge?.chargeAmount / itemsLength
        );
      } else {
        return 0;
      }
    } else {
      return Utility.roundOffToTenantDecimalScale(
        selectedCharge?.chargeAmount / itemsLength
      );
    }
  }
};

export function updateLineLevelAdditionalChargeAllocation(
  additionalCharges: any,
  selectedCharge: any,
  items: any
) {
  let itemsCopy = items?.length
    ? [...items].map((item: any) => {
        let expectedDeliveryDateObject = item.expectedDeliveryDt;
        let itemCopy = deepClone(item);
        // Date object should not be converted in String
        itemCopy.expectedDeliveryDt = expectedDeliveryDateObject;
        return itemCopy;
      })
    : [];

  let existingChargeIndexInTransaction =
    additionalCharges?.additionalChargesDetails?.findIndex(
      (charge: any) =>
        charge.additionalCharge === selectedCharge.additionalCharge
    );
  let totalTempChargeAmount = 0;

  if (existingChargeIndexInTransaction > -1) {
    switch (selectedCharge?.apportionValue) {
      case ADDITIONAL_CHARGE_METHODS.APPORTION_ON_QTY:
        let totalProductQty = 0;
        let perQtyItemCharge = 0;
        totalProductQty = itemsCopy
          ?.filter(
            (item: any) =>
              item?.product?.type === PRODUCT_TYPE.TRACKED ||
              item?.product?.type === PRODUCT_TYPE.BILL_OF_MATERIALS
          )
          ?.reduce((total: number, array: any) => {
            return total + array.productQuantity;
          }, 0);

        perQtyItemCharge = selectedCharge?.chargeAmount / totalProductQty;

        totalTempChargeAmount = 0;
        itemsCopy = itemsCopy?.map((item: any, index: number) => {
          if (
            item?.product?.type !== PRODUCT_TYPE.TRACKED &&
            item?.product?.type !== PRODUCT_TYPE.BILL_OF_MATERIALS
          ) {
            return {
              ...item,
              additionalCharges: {
                additionalChargesDetails: []
              }
            };
          }

          let copyAdditionalChargeDetails: any = item?.additionalCharges
            ? item?.additionalCharges
            : { additionalChargesDetails: [] };
          let existingChargeIndexInLineItem =
            copyAdditionalChargeDetails?.additionalChargesDetails?.findIndex(
              (charge: any) =>
                charge.additionalCharge === selectedCharge.additionalCharge
            );
          let existingChargeDetails = [
            ...copyAdditionalChargeDetails.additionalChargesDetails
          ];

          let chargeAmount = Utility.roundOffToTenantDecimalScale(
            item?.productQuantity * perQtyItemCharge
          );
          totalTempChargeAmount += chargeAmount;
          if (index === itemsCopy?.length - 1) {
            if (totalTempChargeAmount !== +selectedCharge?.chargeAmount) {
              const chargeDelta =
                +selectedCharge.chargeAmount - totalTempChargeAmount;
              chargeAmount += chargeDelta;
            }
          }

          let tempDetails = {
            id: selectedCharge?.id,
            additionalCharge: selectedCharge?.additionalCharge,
            additionalChargeAccountCode:
              selectedCharge?.additionalChargeAccountCode,
            addtionalChargeTax: selectedCharge?.addtionalChargeTax ?? null,
            addtionalChargeTaxCode:
              selectedCharge?.addtionalChargeTaxCode ?? null,
            isPreCharge: selectedCharge.isPreCharge,
            isPercent: selectedCharge?.isPercent,
            percent: selectedCharge?.percent,
            chargeAmount: chargeAmount,
            apportionFlag: selectedCharge?.apportionFlag || true,
            apportionValue: selectedCharge?.apportionValue || null
          };

          if (existingChargeIndexInLineItem !== -1) {
            existingChargeDetails[existingChargeIndexInLineItem] = tempDetails;
          } else {
            existingChargeDetails.push(tempDetails);
          }

          item = {
            ...item,
            additionalCharges: {
              additionalChargesDetails: existingChargeDetails
            }
          };

          return item;
        });

        break;
      case ADDITIONAL_CHARGE_METHODS.APPORTION_ON_VALUE:
        let totalSumAllAmount = 0;
        totalSumAllAmount = itemsCopy
          ?.filter(
            (item: any) =>
              item?.product?.type === PRODUCT_TYPE.TRACKED ||
              item?.product?.type === PRODUCT_TYPE.BILL_OF_MATERIALS
          )
          ?.reduce((total: number, obj: any) => {
            return total + +obj.totalAmount;
          }, 0);

        totalTempChargeAmount = 0;
        itemsCopy = itemsCopy?.map((item: any, index: number) => {
          if (
            item?.product?.type !== PRODUCT_TYPE.TRACKED &&
            item?.product?.type !== PRODUCT_TYPE.BILL_OF_MATERIALS
          ) {
            return {
              ...item,
              additionalCharges: {
                additionalChargesDetails: []
              }
            };
          }

          let copyAdditionalChargeDetails: any = item?.additionalCharges
            ? item?.additionalCharges
            : { additionalChargesDetails: [] };
          let existingChargeIndex =
            copyAdditionalChargeDetails?.additionalChargesDetails?.findIndex(
              (charge: any) =>
                charge.additionalCharge === selectedCharge.additionalCharge
            );
          let existingChargeDetails = [
            ...copyAdditionalChargeDetails.additionalChargesDetails
          ];

          let chargeAmount = Utility.roundOffToTenantDecimalScale(
            (item?.totalAmount / totalSumAllAmount) *
              selectedCharge?.chargeAmount
          );
          totalTempChargeAmount += chargeAmount;
          if (index === itemsCopy?.length - 1) {
            if (totalTempChargeAmount !== +selectedCharge?.chargeAmount) {
              const chargeDelta =
                +selectedCharge.chargeAmount - totalTempChargeAmount;
              chargeAmount = Utility.roundOffToTenantDecimalScale(
                chargeAmount + chargeDelta
              );
            }
          }

          let tempDetails = {
            id: selectedCharge?.id,
            additionalCharge: selectedCharge?.additionalCharge,
            additionalChargeAccountCode:
              selectedCharge?.additionalChargeAccountCode,
            addtionalChargeTax: selectedCharge?.addtionalChargeTax ?? null,
            addtionalChargeTaxCode:
              selectedCharge?.addtionalChargeTaxCode ?? null,
            isPreCharge: selectedCharge.isPreCharge,
            isPercent: selectedCharge?.isPercent,
            percent: selectedCharge?.percent,
            chargeAmount: chargeAmount,
            apportionFlag: selectedCharge?.apportionFlag || true,
            apportionValue: selectedCharge?.apportionValue || null
          };

          if (existingChargeIndex !== -1) {
            existingChargeDetails[existingChargeIndex] = tempDetails;
          } else {
            existingChargeDetails.push(tempDetails);
          }

          item = {
            ...item,
            additionalCharges: {
              additionalChargesDetails: existingChargeDetails
            }
          };

          return item;
        });
        break;
      case ADDITIONAL_CHARGE_METHODS.APPORTION_MANUAL:
        itemsCopy = itemsCopy?.map((item: any) => {
          if (
            item?.product?.type !== PRODUCT_TYPE.TRACKED &&
            item?.product?.type !== PRODUCT_TYPE.BILL_OF_MATERIALS
          ) {
            return {
              ...item,
              additionalCharges: {
                additionalChargesDetails: []
              }
            };
          }

          let copyAdditionalChargeDetails: any = item?.additionalCharges
            ? item?.additionalCharges
            : { additionalChargesDetails: [] };
          let existingChargeIndex =
            copyAdditionalChargeDetails?.additionalChargesDetails?.findIndex(
              (charge: any) =>
                charge.additionalCharge === selectedCharge.additionalCharge
            );
          let existingChargeDetails = [
            ...copyAdditionalChargeDetails.additionalChargesDetails
          ];
          let tempDetails = {
            id: selectedCharge?.id,
            additionalCharge: selectedCharge?.additionalCharge,
            additionalChargeAccountCode:
              selectedCharge?.additionalChargeAccountCode,
            addtionalChargeTax: selectedCharge?.addtionalChargeTax ?? null,
            addtionalChargeTaxCode:
              selectedCharge?.addtionalChargeTaxCode ?? null,
            isPreCharge: selectedCharge.isPreCharge,
            isPercent: selectedCharge?.isPercent,
            percent: selectedCharge?.percent,
            chargeAmount: calculateManualAdditionalChargeDistribution(
              items,
              existingChargeDetails,
              existingChargeIndex,
              selectedCharge,
              itemsCopy?.filter(
                (item: any) =>
                  item?.product?.type === PRODUCT_TYPE.TRACKED ||
                  item?.product?.type === PRODUCT_TYPE.BILL_OF_MATERIALS
              ).length
            ),
            apportionFlag: selectedCharge?.apportionFlag || true,
            apportionValue: selectedCharge?.apportionValue || null
          };

          if (existingChargeIndex !== -1) {
            existingChargeDetails[existingChargeIndex] = tempDetails;
          } else {
            existingChargeDetails.push(tempDetails);
          }

          item = {
            ...item,
            additionalCharges: {
              additionalChargesDetails: existingChargeDetails
            }
          };

          return item;
        });
        break;
    }
  } else {
    itemsCopy = itemsCopy?.map((item: any) => {
      let existingChargeDetails =
        item?.additionalCharges?.additionalChargesDetails?.filter(
          (charge: any) =>
            charge.additionalCharge !== selectedCharge.additionalCharge
        );

      item = {
        ...item,
        additionalCharges: {
          additionalChargesDetails: existingChargeDetails
        }
      };
      return item;
    });
  }

  return itemsCopy;
}

export function canUpdateProductForBillAndInvoiceSpecialCase(
  draftType: DraftTypes,
  documentType: DOC_TYPE,
  receiveGoodsStatus: RECEIVED_GOODS_STATUS,
  paymentStatus: PAYMENT_STATUS,
  fulfillmentStatus: FULFILLMENT_STATUS,
  totalAmount: number
): boolean {
  //* this function equivalent to checkForCustomField state in NewDocument2
  let canUpdate = true;
  if (draftType === DraftTypes.UPDATE) {
    if (documentType === DOC_TYPE.BILL) {
      canUpdate = !(
        receiveGoodsStatus !== RECEIVED_GOODS_STATUS.NOT_RECEIVED ||
        paymentStatus !== PAYMENT_STATUS.PENDING ||
        totalAmount === NUMBERS.ZERO
      );
    }
    if (documentType === DOC_TYPE.INVOICE) {
      canUpdate = !(
        fulfillmentStatus !== FULFILLMENT_STATUS.UNFULFILLED ||
        paymentStatus !== PAYMENT_STATUS.PENDING ||
        totalAmount === NUMBERS.ZERO
      );
    }
  }
  return canUpdate;
}

export function getDiscountRelatedKeys(discount: string) {
  let updatedItemKeys: any = {};
  let discountInNum = 0;
  if (discount) {
    if (discount.toString().indexOf('%') > -1) {
      updatedItemKeys.discountInPercent = true;
      discountInNum = Number(discount.replace('%', ''));
    } else {
      discountInNum = Number(discount);
      updatedItemKeys.discountInPercent = false;
    }
    updatedItemKeys.discount = Utility.roundOff(
      discountInNum,
      CURRENCY_PRECISION
    );
    return updatedItemKeys;
  } else {
    updatedItemKeys.discount = discountInNum;
  }
  return updatedItemKeys;
}

/**
 *
 * @param param0 updated document Items from which total is derived
 * @returns total amount of the document calculated from each item totalAmount excluding optional items
 */
export const getTotalOfItems = ({ items }: any) => {
  return items?.reduce(
    (total: number, item: any) =>
      total + (item?.total && !item?.optional ? item?.total : 0),
    0
  );
};

/**
 *
 * @param params object required to calculate document total
 * @returns total of the document calculated considering TCS,TDS and round off difference
 */
export const getTotal = ({
  items,
  roundOffAmountInDocumentCurrency,
  tcsPercentage,
  gstType,
  decimalScale
}: any): number => {
  let total = getTotalOfItems({ items });
  const summaryAmount: IDocSummaryAmount = calculateAmountsForSummary({
    items,
    gstType,
    tcsPercentage,
    decimalScale,
    taxSystem: getTenantTaxSystem()
  });

  if (isNaN(roundOffAmountInDocumentCurrency)) return Number(total);
  if (tcsPercentage && getTenantTaxSystem(true) === TAX_SYSTEM.INDIA_GST) {
    return (
      Number(total) +
      Number(roundOffAmountInDocumentCurrency) +
      Number(summaryAmount.tcsAmount) -
      Number(summaryAmount.tdsAmount)
    );
  }
  if (getTenantTaxSystem(true) === TAX_SYSTEM.INDIA_GST) {
    let tdsAmount = 0;
    if (items) {
      tdsAmount = items.reduce(
        (total: number, docItem: any) =>
          total +
          Number(docItem.tdsInfoIndia ? docItem.tdsInfoIndia.tdsAmount : 0),
        0
      );
    }
    return (
      Number(total) +
      Number(roundOffAmountInDocumentCurrency) +
      Number(summaryAmount.tcsAmount) -
      Number(tdsAmount)
    );
  }
  return Number(total) + Number(roundOffAmountInDocumentCurrency);
};

type ParamTypeForCalculateTotal = {
  additionalCharges: AdditionalChargeForDoc | undefined;
  items: any[] | undefined;
  knockoffInfo: any[] | undefined;
  tdsInfo: any[] | undefined;
  gstType: GST_TYPE | undefined;
  roundOffAmountInDocumentCurrency: number;
  tcsPercentage: number | undefined;
  isManualRoundOff?: boolean;
};
type ReturnTypeForCalculateTotal = {
  [DOCUMENT_KEYS.TOTAL_AMOUNT]: any;
  [DOCUMENT_KEYS.DUE_AMOUNT]: any;
  [DOCUMENT_KEYS.ITEMS]: any;
  [DOCUMENT_KEYS.AMOUNT_TO_RECEIVE_OR_PAY]: any;
  [DOCUMENT_KEYS.ROUND_OFF_AMOUNT_IN_DOCUMENT_CURRENCY]: any;
};
/**
 *
 * @param {Object}  values required to calculate total
 * @returns {ReturnTypeForCalculateTotal} partial Document object
 * @description This method calculate total of the document from items considering additional charges applied,
 * payment done, TCS Applied and any other amount added in document
 */
export const calculateTotalOnDocDataChange = ({
  additionalCharges,
  items,
  knockoffInfo,
  tdsInfo,
  gstType,
  tcsPercentage,
  roundOffAmountInDocumentCurrency,
  isManualRoundOff = false
}: ParamTypeForCalculateTotal): ReturnTypeForCalculateTotal => {
  const { additionalSettings, decimalScale, country } =
    AuthService.currentTenantInfo ||
    Store.getState().authInfo.currentTenantInfo.data;

  const additionalChargeTotal = getAdditionalChargesTotalAndTax(
    additionalCharges?.additionalChargesDetails || []
  );

  const additionalDiscountTotal = getAdditionalChargesTotalAndTax(
    additionalCharges?.globalDiscounts || [],
    CHARGE_TYPE.DISCOUNT
  );
  const totalCharge =
    !isNaN(additionalChargeTotal?.total) && !isNaN(additionalChargeTotal?.tax)
      ? additionalChargeTotal.total + additionalChargeTotal.tax
      : 0;

  const totalDiscount =
    !isNaN(additionalDiscountTotal?.total) &&
    !isNaN(additionalDiscountTotal?.tax)
      ? additionalDiscountTotal.total + additionalDiscountTotal.tax
      : 0;

  let total = 0;
  total = getTotal({
    items,
    roundOffAmountInDocumentCurrency,
    gstType,
    decimalScale,
    tcsPercentage
  });

  let roundOffDiff = roundOffAmountInDocumentCurrency ?? 0;
  // TODO - recalculateRounding ref were used in condition;
  if (!!additionalSettings?.ROUND_OFF?.autoRoundOff && !isManualRoundOff) {
    total = total - Number(roundOffAmountInDocumentCurrency);
    total =
      total +
      totalCharge -
      totalDiscount -
      (additionalCharges?.globalDiscount?.amount &&
      !isNaN(additionalCharges?.globalDiscount?.amount)
        ? additionalCharges?.globalDiscount?.amount
        : 0);

    switch (additionalSettings?.ROUND_OFF?.roundingMethod) {
      case ROUNDING_METHOD.ROUND_OFF:
        roundOffDiff = Utility.roundOff(
          Math.round(total) - total,
          decimalScale
        );
        total = Math.round(total);
        break;
      case ROUNDING_METHOD.ROUND_UP:
        roundOffDiff = Utility.roundOff(Math.ceil(total) - total);
        total = Math.ceil(total);
        break;
      case ROUNDING_METHOD.ROUND_DOWN:
        roundOffDiff = Utility.roundOff(Math.floor(total) - total);
        total = Math.floor(total);
        break;

      default:
        roundOffDiff = Utility.roundOff(
          Math.round(total) - total,
          decimalScale
        );
        total = Math.round(total);
        break;
    }
  } else {
    total =
      total +
      totalCharge -
      totalDiscount -
      (additionalCharges?.globalDiscount?.amount &&
      !isNaN(additionalCharges?.globalDiscount?.amount)
        ? additionalCharges?.globalDiscount?.amount
        : 0);
  }

  let paidAmount =
    knockoffInfo?.reduce(
      (acc: number, paymentInfo: any) =>
        acc +
        Number(paymentInfo?.amount || 0) *
          Number(paymentInfo?.exchangeRate || 1),
      0
    ) || 0;
  if (country === COUNTRY_CODES.IN) {
    const tdsAmount =
      tdsInfo?.reduce(
        (total: number, tdsData: any) =>
          total + Number(tdsData?.tdsAmount || 0),
        0
      ) || 0;
    paidAmount = paidAmount + tdsAmount;
  }
  // Allocate additional Charges on line level after Additional charges change
  const remainingCharges =
    additionalCharges?.additionalChargesDetails?.map(
      (charge) => charge.additionalCharge
    ) || [];
  let copyOfItems = cloneDeep(items);
  try {
    copyOfItems?.forEach((item) => {
      const additionalChargesDetails =
        item?.additionalCharges?.additionalChargesDetails?.filter(
          (charge: any) => remainingCharges.includes(charge.additionalCharge)
        ) || [];
      item[DOCUMENT_KEYS.ADDITIONAL_CHARGES] = {
        ...item?.additionalCharges,
        additionalChargesDetails
      };
    });
    additionalCharges?.additionalChargesDetails?.forEach((charge) => {
      copyOfItems = updateLineLevelAdditionalChargeAllocation(
        additionalCharges,
        charge,
        copyOfItems
      );
    });
  } catch (error) {
    console.log(error);
  }

  return {
    [DOCUMENT_KEYS.TOTAL_AMOUNT]: total,
    [DOCUMENT_KEYS.DUE_AMOUNT]: Utility.roundOff(
      total - paidAmount,
      decimalScale
    ),
    [DOCUMENT_KEYS.ITEMS]: copyOfItems,
    [DOCUMENT_KEYS.AMOUNT_TO_RECEIVE_OR_PAY]: total - paidAmount,
    [DOCUMENT_KEYS.ROUND_OFF_AMOUNT_IN_DOCUMENT_CURRENCY]: roundOffDiff
  };
};
export const reCalculateAdditionalCharges = async ({
  document,
  draftId
}: any) => {
  try {
    const copyOfCharges = cloneDeep(
      document?.additionalCharges.additionalChargesDetails ?? []
    );
    const copyOfDiscounts = cloneDeep(
      document?.additionalCharges.globalDiscounts ?? []
    );
    const {
      items,
      gstType,
      decimalScale,
      tcsPercentage,
      taxSystem,
      exchangeRate,
      previousExchangeRate
    } = document;
    const copyOfItems = cloneDeep(items)?.map((item: any) => {
      const docLineItemCalculator = new DocLineItemCalculator(item, null);
      docLineItemCalculator.updateCalculatedValues();
      if (docLineItemCalculator.lineItem.tdsInfoIndia) {
        docLineItemCalculator.lineItem.tdsInfoIndia.tdsAmount =
          convertToCurrenctExchangeRate(
            exchangeRate,
            previousExchangeRate,
            docLineItemCalculator.lineItem.tdsInfoIndia.tdsAmount
          );
      }
      if (getTenantTaxSystem() === TAX_SYSTEM.INDIA_GST) {
        if (docLineItemCalculator.lineItem.igstAmount) {
          docLineItemCalculator.lineItem.igstAmount =
            convertToCurrenctExchangeRate(
              exchangeRate,
              previousExchangeRate,
              docLineItemCalculator.lineItem.igstAmount
            );
        }
        if (docLineItemCalculator.lineItem.cessAmount) {
          docLineItemCalculator.lineItem.cessAmount =
            convertToCurrenctExchangeRate(
              exchangeRate,
              previousExchangeRate,
              docLineItemCalculator.lineItem.cessAmount
            );
        }
        if (docLineItemCalculator.lineItem.sgstAmount) {
          docLineItemCalculator.lineItem.sgstAmount =
            convertToCurrenctExchangeRate(
              exchangeRate,
              previousExchangeRate,
              docLineItemCalculator.lineItem.sgstAmount
            );
        }
        if (docLineItemCalculator.lineItem.cgstAmount) {
          docLineItemCalculator.lineItem.cgstAmount =
            convertToCurrenctExchangeRate(
              exchangeRate,
              previousExchangeRate,
              docLineItemCalculator.lineItem.cgstAmount
            );
        }
      }

      return docLineItemCalculator.lineItem;
    });

    const summaryAmount = calculateAmountsForSummary({
      items: copyOfItems,
      gstType,
      decimalScale,
      tcsPercentage,
      taxSystem
    });

    let responses = [];

    if (Utility.isNotEmpty(copyOfCharges)) {
      const chargeTaxCalculationCalls: Promise<any>[] = [];
      copyOfCharges?.forEach((charge: any) => {
        chargeTaxCalculationCalls.push(
          calculateChargeAmountAndTax({
            document,
            charge: { ...charge, chargeValue: charge.chargeAmount },
            summaryAmount,
            isManualChange: false
          })
        );
      });
      responses = await Promise.all(chargeTaxCalculationCalls);
      responses?.forEach((res: any, index: number) => {
        if (copyOfCharges?.[index]) {
          copyOfCharges[index] = {
            ...copyOfCharges[index],
            chargeAmount: res.chargeAmount,
            taxAmount: res.taxOnChargeAmount
          };
        }
      });
    }

    if (Utility.isNotEmpty(copyOfDiscounts)) {
      const discountTaxCalculationCalls: Promise<any>[] = [];
      copyOfDiscounts?.forEach((charge: any) => {
        discountTaxCalculationCalls.push(
          calculateChargeAmountAndTax({
            document,
            charge: { ...charge, chargeValue: charge.chargeAmount },
            summaryAmount,
            isManualChange: false
          })
        );
      });
      responses = await Promise.all(discountTaxCalculationCalls);
      responses?.forEach((res: any, index: number) => {
        if (copyOfDiscounts?.[index]) {
          copyOfDiscounts[index] = {
            ...copyOfDiscounts[index],
            chargeAmount: res.chargeAmount,
            taxAmount: res.taxOnChargeAmount
          };
        }
      });
    }

    const copyOfGlobalDiscount =
      cloneDeep(document?.additionalCharges?.globalDiscount) ?? {};
    if (copyOfGlobalDiscount?.amount && copyOfGlobalDiscount.amount > 0) {
      copyOfGlobalDiscount.amount = convertToCurrenctExchangeRate(
        exchangeRate,
        previousExchangeRate,
        copyOfGlobalDiscount?.amount
      );
    }

    if (
      Utility.isNotEmpty(copyOfCharges) ||
      Utility.isNotEmpty(copyOfDiscounts) ||
      Utility.isNotEmpty(copyOfGlobalDiscount)
    ) {
      const updatedAdditionalCharges = {
        ...(document?.additionalCharges || {}),
        globalDiscount: copyOfGlobalDiscount,
        additionalChargesDetails: copyOfCharges || [],
        globalDiscounts: copyOfDiscounts || []
      };
      Store.dispatch(
        updateMultipleKeysInDocument({
          draftId,
          keysToUpdate: {
            [DOCUMENT_KEYS.ADDITIONAL_CHARGES]: updatedAdditionalCharges
          }
        })
      );
    }
  } catch (error) {
    console.log(error);
  }
};
