import { FC, useContext, useState } from 'react';
import { DKButton, DKListPicker2, DKIcons } from 'deskera-ui-library';
import { useAppSelector } from '../../../../../Redux/Hooks';
import { activeTenantInfo } from '../../../../../Redux/Slices/AuthSlice';
import { selectDocumentFormDataByKeys } from '../../../../../Redux/Slices/DocumentSlice';
import { DOCUMENT_KEYS } from '../../../Utilities/DocConstants';
import { DOCUMENT_MODE } from '../../../../../Constants/Constant';
import { CommonDraftPropsContext } from '../../../Utilities/DocContext';
import { selectCurrencyListWithExchangeRateByDocDate } from '../../../../../Redux/Slices/CommonDataSlice';
import { updateCurrencyAndExchangeRate } from '../../../Helper/Common/DocDataHelper';
import { getDocumentByIDFromStore } from '../../../Helper/DocumentHelper';
import cloneDeep from 'lodash/cloneDeep';

interface ICurrencySelectorProps {}

const DocCurrencySelector: FC<ICurrencySelectorProps> = () => {
  const { multicurrencyEnabled } = useAppSelector(activeTenantInfo);
  const { draftId, documentMode } = useContext(CommonDraftPropsContext);
  const document = getDocumentByIDFromStore(draftId)?.populateFormData || {};
  const [currency] = useAppSelector(
    selectDocumentFormDataByKeys(draftId, [DOCUMENT_KEYS.CURRENCY])
  );
  const activeMultiCurrencyList = useAppSelector(
    selectCurrencyListWithExchangeRateByDocDate
  );
  const [showMultiCurrencyList, setShowMultiCurrencyList] =
    useState<boolean>(false);

  const onCurrencyUpdate = (selectedCurrency: any) => {
    setShowMultiCurrencyList(false);

    updateCurrencyAndExchangeRate(
      draftId,
      documentMode,
      cloneDeep(document),
      selectedCurrency.currencyCode,
      selectedCurrency.currencyExchangeRate
    );
  };

  return (
    <div className="position-relative ml-s">
      <DKButton
        title={`${currency}`}
        className="text-black bg-gray-200 border-radius-l"
        style={{
          paddingLeft: 12,
          paddingRight: 12,
          paddingTop: 2,
          paddingBottom: 2
        }}
        disabled={documentMode === DOCUMENT_MODE.VIEW}
        onClick={() => {
          if (multicurrencyEnabled) {
            setShowMultiCurrencyList((prevValue: boolean) => !prevValue);
          }
        }}
        icon={multicurrencyEnabled && DKIcons.ic_arrow_down2}
        isReverse
      />
      {multicurrencyEnabled && showMultiCurrencyList && (
        <DKListPicker2
          title="Currencies"
          data={activeMultiCurrencyList.filter((item: any) => {
            return item.currencyStatus === 'ACTIVE';
          })}
          style={{
            width: 280
          }}
          allowSearch={true}
          searchableKey="currencyName"
          className="position-absolute z-index-3 right-0 bottom-7 shadow-m"
          onSelect={(index: number, currency: any) => {
            onCurrencyUpdate(currency);
          }}
          onClose={() => {
            setShowMultiCurrencyList(false);
          }}
          renderer={(index: number, obj: any) => {
            return (
              <div className="row parent-width justify-content-between">
                <div>{obj.currencyName}</div>
                <div className="ml-s">{`(${obj.currencyCode})`}</div>
              </div>
            );
          }}
        />
      )}
    </div>
  );
};

export default DocCurrencySelector;
