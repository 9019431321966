import { useContext, useEffect, useState } from 'react';
import { shallowEqual } from 'react-redux';
import {
  DKCheckMark,
  DKIcon,
  DKIcons,
  DKLabel,
  DKTooltipWrapper,
  showAlert
} from 'deskera-ui-library';
import { CommonDraftPropsContext } from '../../Utilities/DocContext';
import { useAppDispatch, useAppSelector } from '../../../../Redux/Hooks';
import {
  calculateTaxesAndAmountsForAllLineItems,
  selectDocumentFormDataByKeys,
  updateMultipleKeysInDocument
} from '../../../../Redux/Slices/DocumentSlice';
import { DOCUMENT_KEYS } from '../../Utilities/DocConstants';
import {
  DOCUMENT_MODE,
  RCM_DOCUMENTS,
  TAX_SYSTEM
} from '../../../../Constants/Constant';
import { getTenantTaxSystem } from '../../../../SharedComponents/DocumentForm/NewDocumentHelper';
import {
  fetchDefaultAccounts,
  selectDefaultAccounts
} from '../../../../Redux/Slices/AccountsSlice';
import Utility, { deepClone } from '../../../../Utility/Utility';
import { DEFAULT_ACCOUNT_MODULE } from '../../../../Models/Common';
import DefaultAccountsSettingsPopup from '../../../Accounting/COA/DefaultAccountsSettingsPopup';

/**
 * Component to show checkbox for "Is RCM Applicable" in Right Panel
 */
const DocRCMCheck = () => {
  const { draftId, documentMode } = useContext(CommonDraftPropsContext);
  const [documentType, applyRcmCheck] = useAppSelector(
    selectDocumentFormDataByKeys(draftId, [
      DOCUMENT_KEYS.DOCUMENT_TYPE,
      DOCUMENT_KEYS.APPLY_RCM_CHECK
    ]),
    shallowEqual
  );

  const dispatch = useAppDispatch();
  const defaultAccounts = useAppSelector(selectDefaultAccounts);
  const [doesDefaultRCMAccountExists, setDoesDefaultRCMAccountExists] =
    useState(false);
  const [showDefaultAccountSettings, setShowDefaultAccountSettings] =
    useState(false);

  useEffect(() => {
    const defaultAccountCl = deepClone(defaultAccounts?.content || []);
    const doesRCmAccountEXists = defaultAccountCl.find(
      (defaults) => defaults.module === DEFAULT_ACCOUNT_MODULE.GL_FOR_ITC_BLOCK
    );
    if (
      !Utility.isEmpty(doesRCmAccountEXists?.accountCode) &&
      doesRCmAccountEXists?.accountCode !== 'NA'
    ) {
      setDoesDefaultRCMAccountExists(true);
    } else {
      setDoesDefaultRCMAccountExists(false);
    }
  }, [defaultAccounts]);

  const getDetailAccountSelectionPopup = () => {
    return (
      <DefaultAccountsSettingsPopup
        onCancel={() => {
          setShowDefaultAccountSettings(false);
        }}
        onSave={() => {
          setShowDefaultAccountSettings(false);
          dispatch(fetchDefaultAccounts());
        }}
      />
    );
  };

  const getInfoIcon = () => {
    return (
      <DKTooltipWrapper
        content="Click gear icon in COA to check/map default RCM account"
        tooltipClassName="bg-deskera-secondary width-auto z-index-10"
        tooltipPositionAbsolute={true}
        tooltipStyle={{ top: 16, left: 'unset', right: 0, minWidth: 250 }}
      >
        <DKIcon
          src={DKIcons.ic_info}
          className="ic-xs-2 opacity-50 mr-s"
          onClick={() => {}}
        />
      </DKTooltipWrapper>
    );
  };

  const handleAndUpdateRCMCheck = (check: boolean) => {
    if (check === true) {
      if (doesDefaultRCMAccountExists) {
        dispatch(
          updateMultipleKeysInDocument({
            draftId,
            keysToUpdate: {
              [DOCUMENT_KEYS.APPLY_RCM_CHECK]: check
            }
          })
        );
        dispatch(calculateTaxesAndAmountsForAllLineItems({ draftId }));
      } else {
        const defaultAccountUpdateBtn = [
          {
            title: 'Cancel',
            className: 'bg-gray-2 border-m',
            onClick: () => {}
          },
          {
            title: 'Yes, Update',
            className: 'bg-blue text-white ml-r',
            onClick: () => {
              setShowDefaultAccountSettings(true);
            }
          }
        ];
        showAlert(
          'Alert',
          'Please map default RCM account.',
          defaultAccountUpdateBtn
        );
      }
    } else {
      dispatch(
        updateMultipleKeysInDocument({
          draftId,
          keysToUpdate: {
            [DOCUMENT_KEYS.APPLY_RCM_CHECK]: check
          }
        })
      );
      dispatch(calculateTaxesAndAmountsForAllLineItems({ draftId }));
    }
  };

  const getRCMCheckBoxWithLabel = () => {
    return (
      <div className="row align-items-center justify-content-between p-v-xs listPickerBG gap-2">
        <div className="row width-auto">
          <div className="column width-auto">{getInfoIcon()}</div>
          <DKLabel
            className="column width-auto fw-m"
            text="Is RCM Applicable?"
          />
        </div>
        <div className="column">
          <DKCheckMark
            color="bg-button"
            isSelected={applyRcmCheck}
            disabled={documentMode === DOCUMENT_MODE.VIEW}
            onClick={() => {
              handleAndUpdateRCMCheck(!applyRcmCheck);
            }}
          />
        </div>
      </div>
    );
  };

  return RCM_DOCUMENTS.includes(documentType) &&
    getTenantTaxSystem() === TAX_SYSTEM.INDIA_GST ? (
    <>
      {getRCMCheckBoxWithLabel()}
      {showDefaultAccountSettings && getDetailAccountSelectionPopup()}
    </>
  ) : null;
};

export default DocRCMCheck;
