import ReactDOM from 'react-dom';
import './SubscriptionAlert.scss';
import { Fragment, useEffect, useState } from 'react';
import {
  DKLabel,
  DKButton,
  DKListPicker,
  showToast,
  DKIcons,
  DKSpinner
} from 'deskera-ui-library';
import { Provider } from 'react-redux';

// import IAM from "../../services/iam";
import UserManager from '../../Managers/UserManager';

import Popup from './Popup';
import ic_external_link from '../../Assets/Icons/ic_external.png';
import IamService from '../../Services/iam';
import AppManager from '../../Managers/AppManager';
import { Store } from '../../Redux/Store';
import {
  fetchAllTenantsInfo,
  fetchSubscriptionInfo,
  subscriptionInfo
} from '../../Redux/Slices/AuthSlice';
import AuthService from '../../Services/Auth';
import { AUTHORIZATION_STATUS } from '../../Constants/Enum';
import { useAppDispatch, useAppSelector } from '../../Redux/Hooks';
import { getTrialDays, isTrialPlanAvailable } from '../Menu/MenuHelper';
import { localizedText } from '../../Services/Localization/Localization';
import { getCapitalized } from '../../Utility/Utility';
import { isMobileWebView } from '../../Utility/ViewportSizeUtils';

interface ISubscriptionAlertProps {
  customMessage: string;
  popupId: string;
  onClose?: () => void;
}

export const SUBSCRIPTION_EVENTS = {
  SUBSCRIPTION_CHECK: 'onAuthorizationCheckStateChanged'
};

const SubscriptionAlert = (props: ISubscriptionAlertProps) => {
  const [tenantList, setTenantList] = useState<any>([]);
  const [authorizationStatus, setAuthorizationStatus] = useState(
    AUTHORIZATION_STATUS.DENIED
  );
  const [showTenantList, setShowTenantList] = useState(false);

  const handleAuthorizationStatusUpdate = (eventData: any) => {
    setAuthorizationStatus(eventData.detail.status);
  };

  const dispatch = useAppDispatch();
  const subscriptionDetails = useAppSelector(subscriptionInfo);
  const isPlanAvailable =
    !subscriptionDetails?.InTrial && !subscriptionDetails?.TrialOver;

  useEffect(() => {
    Store.dispatch(fetchAllTenantsInfo()).then((res: any) =>
      setTenantList(res?.payload || [])
    );
    loadSubscriptionInfo();

    document.addEventListener(
      SUBSCRIPTION_EVENTS.SUBSCRIPTION_CHECK,
      handleAuthorizationStatusUpdate
    );

    return () => {
      document.removeEventListener(
        SUBSCRIPTION_EVENTS.SUBSCRIPTION_CHECK,
        handleAuthorizationStatusUpdate
      );
    };
  }, []);

  const loadSubscriptionInfo = async () => {
    try {
      const newRes = await dispatch(fetchSubscriptionInfo());
      return newRes;
    } catch (err) {
      console.error('Error loading subscription: ', err);
      return Promise.reject(err);
    }
  };

  const removePopUp = () => {
    if (props.popupId)
      ReactDOM.unmountComponentAtNode(document.getElementById(props.popupId)!);

    document.getElementById(props.popupId)?.remove();

    props.onClose && props.onClose();
  };

  return (
    <Popup>
      {getAlertHeader()}
      {getAlertMessage()}
      {getAlertControls()}
    </Popup>
  );

  function getAlertHeader() {
    return (
      <div className="row">
        <div className="row">
          <DKLabel
            text={
              authorizationStatus === AUTHORIZATION_STATUS.AUTHORIZED
                ? 'Subscribed Successfully!'
                : isTrialPlanAvailable(subscriptionDetails)
                ? 'No Active Subscription!'
                : 'Subscription Expired!'
            }
            className="fs-m fw-m mr-l width-auto"
          />
          {authorizationStatus === AUTHORIZATION_STATUS.PENDING ? (
            <DKSpinner />
          ) : null}
        </div>
        {authorizationStatus === AUTHORIZATION_STATUS.AUTHORIZED && (
          <DKButton
            title="Close"
            // icon={DKIcons.ic_close_2}
            onClick={() => removePopUp()}
            className="border-m cursor-hand"
          />
        )}
      </div>
    );
  }

  function getAlertMessage() {
    return (
      <DKLabel
        text={
          authorizationStatus === AUTHORIZATION_STATUS.AUTHORIZED
            ? `Your subscription has been successfully done, you can now continue using the app.`
            : props.customMessage ||
              `It looks like you don't have any active plan and you have already used your trial period. Please switch to an ${localizedText(
                'organisation'
              )} having active subscription or subscribe to a plan to continue using the product.`
        }
        className="mt-l"
      />
    );
  }

  function getAlertControls() {
    return (
      <div className="row row-responsive mt-xl">
        {authorizationStatus === AUTHORIZATION_STATUS.AUTHORIZED ? null : (
          // <DKButton
          //   title="Close"
          //   icon={DKIcons.ic_close_2}
          //   onClick={() => removePopUp()}
          //   className="bg-gray2 border-m cursor-hand"
          // />
          <Fragment>
            <div className="position-relative">
              <DKButton
                title={`Switch ${getCapitalized(
                  localizedText('organisation')
                )}`}
                icon={DKIcons.ic_arrow_down}
                isReverse
                onClick={() => setShowTenantList(true)}
                className="bg-gray2 border-m cursor-hand"
              />
              {showTenantList ? (
                <DKListPicker
                  data={tenantList.map((tenant: any) => tenant.tenantName)}
                  className="position-absolute parent-width z-index-3 border-m shadow-s hide-scroll-bar"
                  style={{
                    top: '90%',
                    left: 0
                  }}
                  onSelect={(index: any, selectedName: any) =>
                    handleTenantSelection(tenantList[index])
                  }
                  onClose={() => setShowTenantList(false)}
                />
              ) : null}
            </div>
            <DKButton
              title={'Subscribe Now'}
              onClick={() => {
                window.open(process.env.REACT_APP_URL_GO + `billing`, '_blank');
              }}
              className="bg-blue text-white ml-r cursor-hand"
            />
            {/* <DKButton
              title="Logout"
              onClick={() => {
                AppManager.logout();
              }}
              icon={DKIcons.white.ic_logout}
              className="ml-r bg-red text-white cursor-hand"
            /> */}
          </Fragment>
        )}
      </div>
    );
  }

  //////////////////////////////////////////////////////////
  function handleTenantSelection(newSelectedTenantDetails: any) {
    if (newSelectedTenantDetails.tenantId !== AuthService.getUserTenantID()) {
      IamService.switchTenant({
        tenantId: newSelectedTenantDetails.tenantId
      }).then(
        (res) => {
          AppManager.refreshApp();
        },
        (err) => {}
      );
    } else {
      showToast('Current Organisation');
    }

    setShowTenantList(false);
  }
};

export const hideSubscriptinAlert = () => {
  const id: any = `subscription-alert-top-menu`;
  if (document.getElementById(id)) {
    document.body.removeChild(document.getElementById(id) as any);
  }
};

export const showSubscriptionAlert = (customMessage: any) => {
  if (!isMobileWebView()) {
    const id: any = `subscription-alert-top-menu`;
    if (document.getElementById(id)) {
      document.body.removeChild(document.getElementById(id) as any);
    }
    let div = document.createElement('div');
    div.className = 'subscription-alert app-font';
    div.setAttribute('id', id);
    ReactDOM.render(
      <Provider store={Store}>
        <SubscriptionAlert popupId={id} customMessage={customMessage} />
      </Provider>,
      document.body.appendChild(div)
    );
  }
};
export default SubscriptionAlert;
