import { showAlert } from 'deskera-ui-library';
import {
  APP_NAME,
  COUNTRY_CODES,
  DOC_TYPE,
  LABELS,
  MRP_APP_NAME
} from '../../../Constants/Constant';
import {
  GROUP_NAMES_ARR,
  MODULE_CODES_TO_HIDE,
  MRP_ROLES_TO_FILTER,
  PERMISSIONS_BY_MODULE,
  SYSTEM_DEFINED_ROLE_SHORT_CODES
} from '../../../Constants/Permission';
import { Store } from '../../../Redux/Store';
import { localizedText } from '../../../Services/Localization/Localization';
import Utility, { deepClone, getCapitalized } from '../../../Utility/Utility';
import { isTabletView } from '../../../Utility/ViewportSizeUtils';
import AuthService from '../../../Services/Auth';

export class GranularPermissionsHelper {
  static permissionInfo: any;

  /**
   * @deprecated use checkUserPermission instead
   */
  static hasPermissionFor(permission?: string) {
    if (
      isTabletView() &&
      (permission?.endsWith('import') || permission?.endsWith('export'))
    ) {
      return false;
    }
    //temporary solution for checking permission for modules like settings
    if (permission === PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT) {
      return true;
    }
    let allPermissions: any = Store.getState().permissions;
    let userPermissions = allPermissions?.usersRolesData?.permissions?.ERP;
    if (userPermissions?.includes(permission)) {
      return true;
    } else {
      return false;
    }
  }

  static getUpdatedRoleGroupDto(
    isEditFlow: boolean,
    updatedCopy: any,
    selectedUserPermissions?: any
  ) {
    let modulesCopy;
    if (!isEditFlow) {
      modulesCopy = updatedCopy?.modules?.map((item1: any) => {
        let innerModules: any[] = [];
        item1.modules?.forEach((item2: any) => {
          if (!getPermissionsCodesToHide()?.includes(item2?.code)) {
            innerModules.push({
              ...item2,
              isSelected: false
            });
          }
        });
        return {
          ...item1,
          modules: innerModules,
          isAllSelected: false
        };
      });
    } else {
      modulesCopy = updatedCopy?.modules?.map((item1: any) => {
        let innerModules: any[] = [];
        item1.modules?.forEach((item2: any) => {
          if (!getPermissionsCodesToHide()?.includes(item2?.code)) {
            innerModules.push({
              ...item2,
              isSelected: selectedUserPermissions?.moduleUuids?.includes(
                item2.uuid
              )
            });
          }
        });
        let isAllSelectedValue = innerModules.every(
          (module: any) => !!module.isSelected
        );
        return {
          ...item1,
          modules: innerModules,
          isAllSelected: isAllSelectedValue
        };
      });
    }

    if (!Utility.isMRPWithURLCheck()) {
      modulesCopy = modulesCopy?.filter((module: any) => {
        if (module?.group === 'mrp_buy') return true;
        return module?.appName !== MRP_APP_NAME;
      });
    }

    return modulesCopy;
  }

  static getPaidOrGuestUserRoleGroupDtos(
    isPaid: boolean,
    appPermissionMetadata: any
  ) {
    if (isPaid) {
      let erpMetaData: any = {};

      appPermissionMetadata?.roleGroupDto?.forEach((item: any) => {
        if (!item.guest) {
          if (
            item.appName === APP_NAME &&
            item.shortCode === SYSTEM_DEFINED_ROLE_SHORT_CODES.ADMIN
          ) {
            erpMetaData = item;
          }
        }
      });
      let allModulesAvailableInSystem = [...erpMetaData.modules];
      if (!Utility.isMRPWithURLCheck()) {
        allModulesAvailableInSystem = allModulesAvailableInSystem.filter(
          (module) => {
            // keep mrp_buy in case of role creation from books
            if (module?.group === 'mrp_buy') return true;
            return module?.appName !== MRP_APP_NAME;
          }
        );
      }
      return {
        ...erpMetaData,
        modules: allModulesAvailableInSystem.filter(Utility.isNotEmpty)
      };
    } else {
      return appPermissionMetadata?.roleGroupDto?.find((item: any) => {
        return (
          item.shortCode === SYSTEM_DEFINED_ROLE_SHORT_CODES.BUY_SELL_GUEST &&
          item.guest
        );
      });
    }
  }

  static getGroupName(module: any) {
    let name = module?.modules?.[0]?.name;
    if (name === 'Quote') {
      name = getCapitalized(localizedText('quote'));
    }
    if (name === LABELS.PRICE_LIST) {
      const isAdvancePriceListEnabled =
        Store.getState()?.authInfo?.currentTenantInfo?.data
          ?.isAdvancePriceListEnabled;
      name = isAdvancePriceListEnabled ? LABELS.PRICE_BOOK : LABELS.PRICE_LIST;
    }
    return name;
  }
  /**
   *
   * @returns - array of role groups for ERP and MRP (if enabled)
   */
  static getAppMetaData() {
    let allMetadataPermissions =
      Store.getState().permissions?.permissionMetadata;
    const roleGroupDto = [];
    const erpMetaData = allMetadataPermissions?.find((item: any) => {
      return item.appName === APP_NAME;
    });
    if (Utility.isNotEmpty(erpMetaData?.roleGroupDto)) {
      roleGroupDto.push(...erpMetaData.roleGroupDto);
    }
    if (Utility.isMRPWithURLCheck()) {
      const mrpMetaData = allMetadataPermissions?.find((item: any) => {
        return item.appName === MRP_APP_NAME;
      });
      if (Utility.isNotEmpty(mrpMetaData?.roleGroupDto)) {
        roleGroupDto.push(...mrpMetaData.roleGroupDto);
      }
    }

    return { roleGroupDto };
  }

  static getSequencedMetadata(parsedMetadata: any) {
    let copyParsedMetadata = [...parsedMetadata];
    let erpMetadata = parsedMetadata?.find((item: any) => {
      return item.appName === APP_NAME;
    });
    let copyRoleGroupDto = [...erpMetadata.roleGroupDto];

    let adminModules: any[] = [];
    let guestModules: any[] = [];

    //admin
    let copyRoleGroupDtoAdmin = copyRoleGroupDto.find((item: any) => {
      return item.shortCode === SYSTEM_DEFINED_ROLE_SHORT_CODES.ADMIN;
    });
    copyRoleGroupDtoAdmin?.modules?.forEach((item: any) => {
      GROUP_NAMES_ARR?.forEach((groupName: any, index: number) => {
        if (groupName.id === item.group) {
          item = {
            ...item,
            sequence: index,
            appName: groupName.appName,
            hideFromUI: groupName.hideFromUI
          };
          adminModules[index] = item;
        }
      });
    });
    copyRoleGroupDtoAdmin = {
      ...copyRoleGroupDtoAdmin,
      modules: adminModules
    };

    //guest
    let copyRoleGroupDtoNonPaid = copyRoleGroupDto.find((item: any) => {
      return item.shortCode === SYSTEM_DEFINED_ROLE_SHORT_CODES.BUY_SELL_GUEST;
    });
    copyRoleGroupDtoNonPaid?.modules?.forEach((item: any) => {
      const UPDATED_GROUP_NAMES_ARR = GROUP_NAMES_ARR.filter((item: any) => {
        return item.isAvailableForAdminSuperset;
      });
      UPDATED_GROUP_NAMES_ARR?.find((groupName: any, index: number) => {
        if (
          groupName.id === item.group &&
          groupName.isAvailableForAdminSuperset
        ) {
          item = {
            ...item,
            sequence: index,
            appName: groupName.appName,
            hideFromUI: groupName.hideFromUI
          };
          guestModules[index] = item;
        }
      });
    });
    copyRoleGroupDtoNonPaid = {
      ...copyRoleGroupDtoNonPaid,
      modules: guestModules
    };

    const newList = copyRoleGroupDto.map((item: any) => {
      if (item.shortCode === SYSTEM_DEFINED_ROLE_SHORT_CODES.ADMIN) {
        item = {
          ...item,
          modules: adminModules
        };
      }
      if (item.shortCode === SYSTEM_DEFINED_ROLE_SHORT_CODES.BUY_SELL_GUEST) {
        item = {
          ...item,
          modules: guestModules
        };
      }
      return item;
    });

    erpMetadata = {
      ...erpMetadata,
      roleGroupDto: newList
    };

    copyParsedMetadata = copyParsedMetadata.map((item: any) => {
      if (item.appName === APP_NAME) {
        item = erpMetadata;
      }
      return item;
    });

    return copyParsedMetadata;
  }

  static hasBankModulePermission() {
    return (
      checkUserPermission(PERMISSIONS_BY_MODULE.BANK.BANK_CONNECT) ||
      checkUserPermission(PERMISSIONS_BY_MODULE.BANK.RECONCILE) ||
      checkUserPermission(PERMISSIONS_BY_MODULE.BANK.DELETE) ||
      checkUserPermission(PERMISSIONS_BY_MODULE.BANK.EXPORT_BANK_TRANSACTION) ||
      checkUserPermission(PERMISSIONS_BY_MODULE.BANK.IMPORT_STATEMENT)
    );
  }

  static isAnyDocCreatePermissionAvailable() {
    return (
      checkUserPermission(PERMISSIONS_BY_MODULE.INVOICE.CREATE) ||
      checkUserPermission(PERMISSIONS_BY_MODULE.QUOTATION.CREATE) ||
      checkUserPermission(PERMISSIONS_BY_MODULE.PURCHASE_ORDER.CREATE) ||
      checkUserPermission(PERMISSIONS_BY_MODULE.BILL.CREATE)
    );
  }

  static getAttachFilePermissionForExpenseDeposit(type: any) {
    if (
      type === DOC_TYPE.DEPOSIT ||
      type === DOC_TYPE.DEPOSIT_ADVANCED_PAYMENT
    ) {
      return checkUserPermission(PERMISSIONS_BY_MODULE.DEPOSIT.ATTACH_FILE);
    }

    if (type === DOC_TYPE.EXPENSE || type === DOC_TYPE.PREPAYMENT) {
      return checkUserPermission(PERMISSIONS_BY_MODULE.EXPENSE.ATTACH_FILE);
    }

    return true;
  }

  static hasDocumentReadOnly(permissionModule: any) {
    let isReadonly: boolean = false;
    if (
      checkUserPermission(permissionModule.VIEW) &&
      !checkUserPermission(permissionModule.CREATE) &&
      !checkUserPermission(permissionModule.EDIT)
    ) {
      isReadonly = true;
    } else if (
      !checkUserPermission(permissionModule.VIEW) &&
      !checkUserPermission(permissionModule.CREATE) &&
      !checkUserPermission(permissionModule.EDIT)
    ) {
      isReadonly = true;
    } else if (
      checkUserPermission(permissionModule.VIEW) &&
      checkUserPermission(permissionModule.CREATE) &&
      !checkUserPermission(permissionModule.EDIT)
    ) {
      isReadonly = true;
    }
    return isReadonly;
  }

  static hasMrpModulePermission = () => {
    const subsciptionInfo =
      AuthService.subscriptionInfo?.ProductsEnabled ||
      Store.getState()?.authInfo.subscriptionInfo.data?.ProductsEnabled;
    const mrpProduct = subsciptionInfo?.find((product: any) => {
      return product === MRP_APP_NAME;
    });
    return !Utility.isEmpty(mrpProduct);
  };
}
export const checkModulePermissionByModuleName = (
  appName: string,
  module: string
): boolean => {
  if (
    isTabletView() &&
    (module?.endsWith('import') || module?.endsWith('export'))
  ) {
    return false;
  }
  //temporary solution for checking permission for modules like settings
  if (module === PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT) {
    return true;
  }
  const allPermissions = Store.getState().permissions;
  let userPermissions = allPermissions?.usersRolesData?.permissions?.[appName];

  if (userPermissions?.includes(module)) {
    return true;
  } else {
    return false;
  }
};

export const showNoPermissionAlert = (): void => {
  showAlert(
    'No Permissions!',
    `You don't have permission to view this module, please contact owner/admin of this ${localizedText(
      'organisation'
    )}`
  );
};

/**
 *
 * @param user - object of user to find role
 * @returns - role of given object
 */
export const findUserRole = (user: any) => {
  let roleShortInfo: any = {};
  const roleShortInfoArray = user?.roleShortInfo?.filter((role: any) =>
    [APP_NAME, MRP_APP_NAME].includes(role.appName)
  );
  // *@info - user can have both ERP and MRP roles, if accessing MRP then check for MRP role, not found then use ERP role (either one of the role would be there)
  if (Utility.isMRPWithURLCheck()) {
    roleShortInfo = roleShortInfoArray.find(
      (role: any) =>
        role.appName === MRP_APP_NAME &&
        !MRP_ROLES_TO_FILTER.includes(role.shortCode)
    );
  }
  if (Utility.isEmptyObject(roleShortInfo)) {
    roleShortInfo = roleShortInfoArray.find(
      (role: any) => role.appName === APP_NAME
    );
  }
  return roleShortInfo;
};

export const checkUserPermission = (module: string): boolean => {
  const allAvailablePermissions =
    Store?.getState()?.permissions?.allAvailablePermissions;
  //**  info need to find appName in which this permission belongs
  const appName =
    allAvailablePermissions?.find((perm: any) => perm?.code?.includes(module))
      ?.appName ?? APP_NAME;

  return checkModulePermissionByModuleName(appName, module);
};

export const parseAllAvailAbleModulePermissions = (
  appPermissionMetadata: any[]
) => {
  try {
    const ERP_AND_MRP_ROLE_GROUPS: any[] = [];
    appPermissionMetadata.forEach((app) => {
      if (app.appName === APP_NAME || app.appName === MRP_APP_NAME) {
        ERP_AND_MRP_ROLE_GROUPS.push(...app.roleGroupDto);
      }
    });
    const allAdminModules: any[] = [];

    ERP_AND_MRP_ROLE_GROUPS?.forEach((role: any) => {
      if (
        (role.shortCode === 'admin' && role.appName === APP_NAME) ||
        (role.shortCode === 'mrp_admin' && role.appName === MRP_APP_NAME)
      ) {
        allAdminModules.push(...role.modules);
      }
    });
    const combinedModules = allAdminModules?.reduce((acc: any[], curr: any) => {
      if (Utility.isNotEmpty(curr?.modules)) {
        acc.push(...curr?.modules);
      }
      return acc;
    }, []);
    return combinedModules;
  } catch (error) {
    return [];
  }
};

export const isOwnerOfOrganization = (userId: number) => {
  const allUsers = Store.getState()?.authInfo?.usersList?.data?.content ?? [];
  const roleShortInfo =
    allUsers?.find((user: any) => user.id === userId)?.roleShortInfo ?? [];

  if (Utility.isEmptyObject(roleShortInfo)) return false;
  return roleShortInfo?.some(
    (role: any) => role.appName === 'ORGANISATION' && role.shortCode === 'owner'
  );
};

export const hasAnySettingsPermission = (): boolean => {
  const allSettingPermissions = PERMISSIONS_BY_MODULE.SETTINGS;
  return Object.values<string>(allSettingPermissions).some(
    (permission: string) => checkUserPermission(permission)
  );
};
export const getPermissionsCodesToHide = (): string[] => {
  const modulesCodesToHide: string[] = deepClone(MODULE_CODES_TO_HIDE);
  const tenantInfo = Store.getState()?.authInfo?.currentTenantInfo?.data;
  if (tenantInfo?.country !== COUNTRY_CODES.IN) {
    modulesCodesToHide.push(
      ...['m_erp_settings_tcs_setup', 'm_erp_settings_tds_setup']
    );
  }
  if (tenantInfo?.country !== COUNTRY_CODES.US) {
    modulesCodesToHide.push('m_erp_settings_agencies');
  }
  return modulesCodesToHide;
};
