import { useRef, useState } from 'react';
import { showAlert } from 'deskera-ui-library';
import Utility, { deepClone } from '../../../../Utility/Utility';
import {
  PRODUCE_PRODUCT_TYPE,
  PRODUCT_TYPE
} from '../../../../Constants/Constant';
import {
  BomConfigurationModel,
  BomOperation,
  ComponentProduct,
  NewBomConfigurationModel
} from './BomConfigurationModel';

export default function BomDetailsTabViewModel(bomModel: any) {
  const [bomMetaDetails, setBomMetaDetails] = useState<BomConfigurationModel[]>(
    bomModel?.map((itemBomModel: any) => {
      return {
        ...itemBomModel,
        bomProductsConfiguration: itemBomModel?.bomProductsConfiguration?.map(
          (itemBomProduct: any) => {
            return {
              ...itemBomProduct,
              quantity: itemBomProduct?.documentUOMSchemaDefinition
                ? itemBomProduct?.uomQuantity
                : itemBomProduct?.quantity
            };
          }
        )
      };
    })
  );
  var currentEditableIndex = useRef(0);
  const TITLES = {
    COMPONENT_PRODUCT: 'Component Products',
    BY_PRODUCT: 'By-Products',
    ADDITIONAL_COST: 'Additional Cost',
    OPERATIONS: 'Operations'
  };

  function addComponentProduct() {
    let bomDetailMetaCopy = [...bomMetaDetails];
    let currentConfig = { ...bomDetailMetaCopy[currentEditableIndex.current] };
    var tempComponent: ComponentProduct[] = [];
    if (!Utility.isEmpty(currentConfig?.bomProductsConfiguration)) {
      tempComponent = [...currentConfig?.bomProductsConfiguration];
    }
    var component: ComponentProduct = {
      itemId: undefined,
      itemName: undefined,
      quantity: 0,
      cost: 0,
      name: '',
      priceOfItem: 0,
      stockUom: undefined,
      productCode: '',
      bomProductSubstitutesDetails: [],
      produceProductType: PRODUCE_PRODUCT_TYPE.NONE,
      documentSequenceCode: '',
      productType: PRODUCT_TYPE.TRACKED
    };

    tempComponent.push(component);
    currentConfig.bomProductsConfiguration = [...tempComponent];
    bomDetailMetaCopy[currentEditableIndex.current] = currentConfig;
    setBomMetaDetails(bomDetailMetaCopy);
  }

  function removeComponent(index: any) {
    let bomMetaDetailsCopy = [...bomMetaDetails];
    let currentConfig = bomMetaDetailsCopy[currentEditableIndex.current];
    var tmp = [...currentConfig?.bomProductsConfiguration];
    tmp.splice(index, 1);
    // setComponentProduct(tmp);
    currentConfig.bomProductsConfiguration = tmp;
    bomMetaDetailsCopy[currentEditableIndex.current] = currentConfig;
    setBomMetaDetails(bomMetaDetailsCopy);
  }

  function removeScrapCoProduct(index: any) {
    let bomMetaDetailsCopy = [...bomMetaDetails];
    let currentConfig = bomMetaDetailsCopy[currentEditableIndex.current];
    var tmp = currentConfig?.byProducts ? [...currentConfig?.byProducts] : [];
    tmp.splice(index, 1);
    // setComponentProduct(tmp);
    currentConfig.byProducts = tmp;
    bomMetaDetailsCopy[currentEditableIndex.current] = currentConfig;
    setBomMetaDetails(bomMetaDetailsCopy);
  }

  function addAttachment(attachment: any) {
    let bomMetaDetailsCopy = [...bomMetaDetails];
    let currentConfig = bomMetaDetailsCopy[currentEditableIndex.current];
    currentConfig.attachments = [...currentConfig.attachments, attachment];
    bomMetaDetailsCopy[currentEditableIndex.current] = currentConfig;
    setBomMetaDetails(bomMetaDetailsCopy);
  }

  function setAttachment(attachment: any) {
    let bomMetaDetailsCopy = [...bomMetaDetails];
    bomMetaDetailsCopy?.forEach((bomDetail: any, index: any) => {
      bomMetaDetailsCopy[index].attachments = attachment[bomDetail.id] ?? [];
    });
    setBomMetaDetails(bomMetaDetailsCopy);
  }

  function deleteAttachment(attachmentId: any) {
    let bomMetaDetailsCopy = [...bomMetaDetails];
    let currentConfig = bomMetaDetailsCopy[currentEditableIndex.current];
    currentConfig.attachments = currentConfig.attachments?.filter(
      (attachments: any) => attachments.attachmentId !== attachmentId
    );
    bomMetaDetailsCopy[currentEditableIndex.current] = currentConfig;
    setBomMetaDetails(bomMetaDetailsCopy);
  }

  function removeAdditionalCost(index: any) {
    let bomMetaDetailsCopy = [...bomMetaDetails];
    let currentConfig = bomMetaDetailsCopy[currentEditableIndex.current];
    var tmp = [...currentConfig?.bomAddCostConfiguration];
    tmp.splice(index, 1);
    currentConfig.bomAddCostConfiguration = tmp;
    bomMetaDetailsCopy[currentEditableIndex.current] = currentConfig;
    setBomMetaDetails(bomMetaDetailsCopy);
  }

  const removeOperation = (index: number) => {
    let bomMetaDetailsCopy = [...bomMetaDetails];
    let currentConfig = bomMetaDetailsCopy[currentEditableIndex.current];
    var tmp = [...currentConfig?.bomOperationsConfiguration];
    tmp.splice(index, 1);
    // setComponentProduct(tmp);
    currentConfig.bomOperationsConfiguration = tmp;
    bomMetaDetailsCopy[currentEditableIndex.current] = currentConfig;
    setBomMetaDetails(bomMetaDetailsCopy);
  };

  function getComponentProducts() {
    //   const componentRearranged = getComponentProducts()?.sort((a, b) => {
    //   if (a.active && !b.active) {
    //     return -1;
    //   } else if (!a.active && b.active) {
    //     return 1;
    //   } else {
    //     return 0;
    //   }
    // });
    return bomMetaDetails;
  }

  const onRowUpdate = (title: string, data: any) => {
    if (title === TITLES.COMPONENT_PRODUCT) {
      updateComponentProduct(data);
    } else if (title === TITLES.BY_PRODUCT) {
      updateByProduct(data);
    } else if (title === TITLES.ADDITIONAL_COST) {
      updateAdditionalCost(data);
    } else if (title === TITLES.OPERATIONS) {
      updateOperation(data);
    }
  };

  const updateComponentProduct = (data: any) => {
    let bomMetaDetailsCopy = [...bomMetaDetails];
    let currentConfig = bomMetaDetailsCopy[currentEditableIndex.current];
    var editedRecords = [...currentConfig?.bomProductsConfiguration];
    let selectedComponent: any = { ...editedRecords[data.rowIndex] };
    let selectedProduct = data.rowData.product;
    switch (data.columnKey) {
      case 'product':
        selectedComponent = {};
        selectedComponent.itemId = selectedProduct.id;
        selectedComponent.itemName = selectedProduct.name;
        selectedComponent.productCode = selectedProduct.productId;
        selectedComponent.productType = selectedProduct.type;
        selectedComponent.name = selectedProduct.name;
        selectedComponent.quantity =
          selectedComponent && selectedComponent.quantity
            ? selectedComponent.quantity
            : 1;
        selectedComponent.uomQuantity =
          selectedComponent && selectedComponent.quantity
            ? selectedComponent.quantity
            : 1;

        selectedComponent.cost = selectedProduct.purchasePrice ?? 0;
        selectedComponent.priceOfItem = selectedProduct.salesPrice;
        selectedComponent.defaultStockUom = selectedProduct.stockUom;
        selectedComponent.stockUom = selectedProduct.stockUom;
        selectedComponent.bomProductSubstitutesDetails =
          selectedProduct.productSubstitutesDetails;
        selectedComponent.documentSequenceCode =
          selectedProduct.documentSequenceCode;
        selectedComponent.uomSchemaDto = selectedProduct?.uomSchemaDto;
        selectedComponent.multipleUomSchema =
          selectedProduct?.multipleUomSchema ?? false;
        selectedComponent.produceProductType = PRODUCE_PRODUCT_TYPE.NONE;
        break;
      case 'uom':
        if (data.rowData?.uom?.isBaseUom) {
          selectedComponent.stockUom = data.rowData?.uom?.id;
          selectedComponent.documentUOMSchemaDefinition = null;
        } else {
          selectedComponent.stockUom = data.rowData?.uom?.id;
          selectedComponent.documentUOMSchemaDefinition = data.rowData?.uom;
        }
        selectedComponent.quantity = 0;

        if (data?.rowData?.productType === PRODUCT_TYPE.NON_TRACKED) {
          selectedComponent.quantity = 1;
          selectedComponent.uomQuantity = 1;
        }

        break;
      case 'quantity':
        selectedComponent.quantity = Utility.roundOffToTenantDecimalScale(
          data.rowData.quantity
        );
        selectedComponent.uomQuantity = Utility.roundOffToTenantDecimalScale(
          data.rowData.quantity
        );
        break;
      case 'cost':
        selectedComponent.cost = Utility.roundOffToTenantDecimalScale(
          data.rowData.cost
        );
    }

    editedRecords[data.rowIndex] = selectedComponent;
    currentConfig.bomProductsConfiguration = editedRecords;
    bomMetaDetailsCopy[currentEditableIndex.current] = currentConfig;
    setBomMetaDetails(bomMetaDetailsCopy);
  };

  const updateByProduct = (data: any) => {
    let bomMetaDetailsCopy = [...bomMetaDetails];
    let currentConfig = bomMetaDetailsCopy[currentEditableIndex.current];
    var editedRecords = currentConfig?.byProducts
      ? [...currentConfig?.byProducts]
      : [];
    let selectedComponent = { ...editedRecords[data.rowIndex] };
    let selectedProduct = data.rowData.product;
    switch (data.columnKey) {
      case 'product':
        selectedComponent.itemId = selectedProduct.id;
        selectedComponent.itemName = selectedProduct.name;
        selectedComponent.name = selectedProduct.name;
        selectedComponent.quantity =
          selectedComponent && selectedComponent.quantity
            ? selectedComponent.quantity
            : 1;
        selectedComponent.cost =
          selectedProduct.salesPrice *
          (selectedComponent && selectedComponent.quantity
            ? selectedComponent.quantity
            : 0);
        selectedComponent.priceOfItem = selectedProduct.salesPrice;
        selectedComponent.stockUom = selectedProduct.stockUom;
        selectedComponent.bomProductSubstitutesDetails =
          selectedProduct.productSubstitutesDetails;
        selectedComponent.documentSequenceCode =
          selectedProduct.documentSequenceCode;
        selectedComponent.produceProductType =
          data?.rowData?.produceProductType;
        break;
      case 'quantity':
        selectedComponent.quantity = Utility.roundOffToTenantDecimalScale(
          data.rowData.quantity
        );
        break;
      case 'produceProductType':
        selectedComponent.produceProductType =
          data?.rowData?.produceProductType;
        break;
    }

    editedRecords[data.rowIndex] = selectedComponent;
    currentConfig.byProducts = editedRecords;
    bomMetaDetailsCopy[currentEditableIndex.current] = currentConfig;
    setBomMetaDetails(bomMetaDetailsCopy);
  };

  const updateAdditionalCost = (data: any) => {
    let bomMetaDetailsCopy = [...bomMetaDetails];
    let currentConfig = bomMetaDetailsCopy[currentEditableIndex.current];
    var editedRecords = [...currentConfig?.bomAddCostConfiguration];
    let selectedOperation = editedRecords[data.rowIndex];
    selectedOperation.label = data?.rowData.label;
    selectedOperation.price = Utility.roundOffToTenantDecimalScale(
      data?.rowData.price
    );
    editedRecords[data.rowIndex] = selectedOperation;
    currentConfig.bomAddCostConfiguration = editedRecords;
    bomMetaDetailsCopy[currentEditableIndex.current] = currentConfig;
    setBomMetaDetails(bomMetaDetailsCopy);
  };

  const updateOperation = (data: any) => {
    let bomMetaDetailsCopy = [...bomMetaDetails];
    let currentConfig = bomMetaDetailsCopy[currentEditableIndex.current];
    var editedRecords = [...currentConfig?.bomOperationsConfiguration];
    let selectedOperation: any = {
      ...editedRecords[data.rowIndex],
      productOptions: currentConfig.bomProductsConfiguration
    };
    let selectedProduct = data.rowData.operation;
    switch (data.columnKey) {
      case 'operation':
        selectedOperation.operationId = selectedProduct.id;
        selectedOperation.operationName = selectedProduct.name;
        selectedOperation.costPerHour = selectedProduct.costPerHour || 0;
        selectedOperation.fixedRate = selectedProduct.fixedRate || 0;
        selectedOperation.totalCost =
          (selectedProduct.operationTime / 60) * selectedProduct.costPerHour ||
          0;
        selectedOperation.operationTime = selectedProduct.operationTime || 0;
        break;
      case 'price':
        selectedOperation.price = data?.rowData.price;
        break;
      case 'operationTime':
        selectedOperation.operationTime = +data?.rowData.operationTime ?? 0;
        break;
      case 'opDependency':
        if (
          data?.rowData?.opDependency?.operationName?.toLowerCase() === 'none'
        ) {
          selectedOperation.opDependency = null;
          selectedOperation.operationDependency = {
            opDependencyList: []
          };
        } else {
          const dependentOn = data?.rowData?.opDependency;
          if (editedRecords) {
            let findDependentInOperationList = editedRecords?.find(
              (record: any) => {
                return record.operationId === dependentOn.operationId;
              }
            );
            if (findDependentInOperationList) {
              if (
                selectedOperation?.operationId ===
                findDependentInOperationList?.operationDependency
                  ?.opDependencyList?.[0]
              ) {
                showAlert(
                  'Cyclic dependency found!',
                  `You can't select this operation as its creating cyclic dependency on one of the operation.`
                );
                selectedOperation.opDependency = null;
                selectedOperation.operationDependency = {
                  opDependencyList: []
                };
                return;
              }
            }
          }
          selectedOperation.opDependency = data?.rowData?.opDependency;
          selectedOperation.operationDependency = {
            opDependencyList: [data?.rowData?.opDependency?.operationId]
          };
        }

        break;
      case 'product':
        selectedOperation.productCode = data?.rowData.product?.productCode;
        selectedOperation.product = data?.rowData.product;
        updateDependentOperation(selectedOperation.operationId, editedRecords);
        break;
      case 'processType':
        selectedOperation.processType = data?.rowData.processType?.value;
        selectedOperation.productCode = null;

        if (selectedOperation.processType === 'PROCESSING') {
          selectedOperation.qcNeeded = true;
          selectedOperation.nonEditableColumns = ['qcNeeded'];
        } else {
          selectedOperation.qcNeeded = false;
          selectedOperation.nonEditableColumns = [];
        }

        updateDependentOperation(selectedOperation.operationId, editedRecords);
        break;
      case 'qcNeeded':
        selectedOperation.qcNeeded = data?.rowData.qcNeeded === 'Yes';
        break;
    }
    editedRecords[data.rowIndex] = selectedOperation;
    currentConfig.bomOperationsConfiguration = editedRecords;
    bomMetaDetailsCopy[currentEditableIndex.current] = currentConfig;
    setBomMetaDetails(bomMetaDetailsCopy);
  };

  const addScrapCoSubProduct = () => {
    let bomDetailMetaCopy = [...bomMetaDetails];
    let currentConfig = { ...bomDetailMetaCopy[currentEditableIndex.current] };
    var tempByProducts: any[] = [];
    if (!Utility.isEmpty(currentConfig?.byProducts)) {
      tempByProducts = currentConfig?.byProducts
        ? [...currentConfig?.byProducts]
        : [];
    }
    var component = {
      itemId: null,
      itemName: null,
      quantity: 0,
      cost: 0,
      produceProductType: PRODUCE_PRODUCT_TYPE.SCRAP
    };
    tempByProducts.push(component);
    currentConfig.byProducts = [...tempByProducts];
    bomDetailMetaCopy[currentEditableIndex.current] = currentConfig;
    setBomMetaDetails(bomDetailMetaCopy);
  };

  const addAdditionalCost = () => {
    let bomDetailMetaCopy = [...bomMetaDetails];
    let currentConfig = { ...bomDetailMetaCopy[currentEditableIndex.current] };

    var tempAdditionalCosts: any[] = [];
    if (!Utility.isEmpty(currentConfig?.bomAddCostConfiguration)) {
      tempAdditionalCosts = [...currentConfig?.bomAddCostConfiguration];
    }
    var cost = {
      label: '',
      price: 0
    };
    tempAdditionalCosts.push(cost);
    currentConfig.bomAddCostConfiguration = [...tempAdditionalCosts];
    bomDetailMetaCopy[currentEditableIndex.current] = currentConfig;
    setBomMetaDetails(bomDetailMetaCopy);
  };

  const addAnOperations = () => {
    let bomDetailMetaCopy = [...bomMetaDetails];
    let currentConfig = { ...bomDetailMetaCopy[currentEditableIndex.current] };
    var tempOperations: any[] = [];
    if (!Utility.isEmpty(currentConfig?.bomOperationsConfiguration)) {
      tempOperations = [...currentConfig?.bomOperationsConfiguration];
    }
    var cost = {
      operationId: null,
      operationName: '',
      totalCost: 0,
      costPerHour: 0,
      fixedRate: 0
    };
    tempOperations.push(cost);
    currentConfig.bomOperationsConfiguration = [...tempOperations];
    bomDetailMetaCopy[currentEditableIndex.current] = currentConfig;
    setBomMetaDetails(bomDetailMetaCopy);
  };

  const addAttachements = () => {
    let bomDetailMetaCopy = [...bomMetaDetails];
    let currentConfig = { ...bomDetailMetaCopy[currentEditableIndex.current] };
    var tempOperations: any[] = [];
    if (!Utility.isEmpty(currentConfig?.bomOperationsConfiguration)) {
      tempOperations = [...currentConfig?.bomOperationsConfiguration];
    }
    var cost = {
      operationId: null,
      operationName: '',
      totalCost: 0,
      costPerHour: 0,
      fixedRate: 0
    };
    tempOperations.push(cost);
    currentConfig.bomOperationsConfiguration = [...tempOperations];
    bomDetailMetaCopy[currentEditableIndex.current] = currentConfig;
    setBomMetaDetails(bomDetailMetaCopy);
  };

  const addOperationsFromTemplate = (bomOperations: BomOperation[]) => {
    let bomDetailMetaCopy = [...bomMetaDetails];
    let currentConfig = { ...bomDetailMetaCopy[currentEditableIndex.current] };

    let productCodes = currentConfig.bomProductsConfiguration?.map(
      (item) => item.productCode
    );

    let updatedBomOperations = bomOperations.map((bomOperation) => ({
      ...bomOperation,
      productCode: productCodes.includes(bomOperation.productCode)
        ? bomOperation.productCode
        : undefined
    }));

    currentConfig.bomOperationsConfiguration = [...updatedBomOperations];
    bomDetailMetaCopy[currentEditableIndex.current] = currentConfig;
    setBomMetaDetails(bomDetailMetaCopy);
  };

  function addMoreConfig(object?: BomConfigurationModel) {
    setBomMetaDetails([
      ...bomMetaDetails,
      deepClone(object ?? NewBomConfigurationModel)
    ]);
  }

  function updateBOMName(name: string, index: number) {
    let copyBomMetaDetails = [...bomMetaDetails];
    let currentConfig = copyBomMetaDetails[index];
    currentConfig.name = name;
    copyBomMetaDetails[index] = currentConfig;
    setBomMetaDetails(copyBomMetaDetails);
  }

  function updateDefaultConfig(index: number) {
    let copyBomMetaDetails = [...bomMetaDetails].map(
      (configObj: any, configIndex: number) => {
        let copyConfigObj = { ...configObj };
        // if (index === configIndex) {
        //   if (configObj.active) {
        //     copyConfigObj.isDefault = true;
        //   }
        // }
        return {
          ...copyConfigObj,
          isDefault: copyConfigObj?.active ? index === configIndex : false
        };
      }
    );
    setBomMetaDetails(copyBomMetaDetails);
  }

  function deleteConfigurationAtIndex(index: number) {
    let copyConfig = [...bomMetaDetails];
    let currentConfig: BomConfigurationModel = { ...copyConfig[index] };
    if (currentConfig.isDefault) {
    } else {
      copyConfig.splice(index, 1);
      setBomMetaDetails(copyConfig);
    }
    return currentConfig.isDefault;
  }

  function updatedCurrentEditableIndex(index: number) {
    currentEditableIndex.current = index;
  }

  function updateSubstituteForComponentProduct(
    selectedComponentProductIndex: number,
    selectedProducts: any
  ) {
    let bomDetailMetaCopy = [...bomMetaDetails];
    let currentConfig = { ...bomDetailMetaCopy[currentEditableIndex.current] };
    const copyOfComponentProducts = deepClone(
      currentConfig?.bomProductsConfiguration
    );
    copyOfComponentProducts[
      selectedComponentProductIndex
    ].bomProductSubstitutesDetails = [];
    copyOfComponentProducts[
      selectedComponentProductIndex
    ].bomProductSubstitutesDetails = selectedProducts;
    currentConfig.bomProductsConfiguration = copyOfComponentProducts;
    bomDetailMetaCopy[currentEditableIndex.current] = currentConfig;
    setBomMetaDetails(bomDetailMetaCopy);
  }

  /**
   * Returns if Item is default and updates if not.
   * @return {number} index of editable item.
   */
  function updateActiveStatusForConfigAtIndex(index: number) {
    let bomDetailMetaCopy = [...bomMetaDetails];
    let currentConfig: BomConfigurationModel = { ...bomDetailMetaCopy[index] };
    if (currentConfig.isDefault) {
    } else {
      currentConfig.active = !currentConfig.active;
      bomDetailMetaCopy[index] = currentConfig;
      setBomMetaDetails(bomDetailMetaCopy);
    }
    return currentConfig.isDefault;
  }

  function getCurrentSelectedConfig() {
    return bomMetaDetails[currentEditableIndex.current] ?? {};
  }

  function getCurrentEditableIndex() {
    return currentEditableIndex.current;
  }

  function getUnchangedProduct(
    initialProductWithNoCurrentChange: any,
    selectedComponentProductIndex: any,
    currentConfiguration: any
  ) {
    const metaProductFound =
      initialProductWithNoCurrentChange?.bomMetaDetailsList?.find((f1: any) => {
        return f1.id === currentConfiguration?.id;
      });

    const substituteObjFound =
      metaProductFound?.bomProductsConfiguration?.[
        selectedComponentProductIndex
      ]?.['bomProductSubstitutesDetails'];

    return substituteObjFound;
  }

  const updateDependentOperation = (opId: any, records: any[]) => {
    if (!opId || !records?.length) {
      return;
    }

    records?.forEach((op: any) => {
      if (op.operationDependency?.opDependencyList?.includes(opId)) {
        op.qcNeeded = false;
        op.productCode = null;

        updateDependentOperation(op.operationId, records);
      }
    });
  };

  return {
    addComponentProduct,
    getComponentProducts,
    removeComponent,
    onRowUpdate,
    addScrapCoSubProduct,
    addAdditionalCost,
    addAnOperations,
    removeScrapCoProduct,
    removeAdditionalCost,
    removeOperation,
    updateDefaultConfig,
    addMoreConfig,
    deleteConfigurationAtIndex,
    updateBOMName,
    updatedCurrentEditableIndex,
    updateSubstituteForComponentProduct,
    updateActiveStatusForConfigAtIndex,
    getCurrentSelectedConfig,
    getCurrentEditableIndex,
    getUnchangedProduct,
    addOperationsFromTemplate,
    addAttachment,
    setAttachment,
    deleteAttachment
  };
}
