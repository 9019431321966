export interface JobCardQCModel {
  id: Number;
  jobCardCode: string;
  workOrderCode: string;
  productCode: string;
  itemQuantity: number;
  processType: string; //JC_PROCESS_TYPE
  itemWarehouseInventoryData: WarehouseInventoryData[];
  status: string; //PROCESS_QC_STATUS
  passedQuantity: number;
  passedWarehouseInventoryData: any[];
  rejectedQuantity: number;
  rejectedWarehouseInventoryData: any[];
  workOrderChildDetails: any | null;
  qcNeeded: boolean;
  qcReason: string;
}

export const JobCardQCInitialState: JobCardQCModel = {
  id: 0,
  jobCardCode: '',
  workOrderCode: '',
  productCode: '',
  itemQuantity: 0,
  processType: '', //JC_PROCESS_TYPE
  itemWarehouseInventoryData: [],
  status: '', //PROCESS_QC_STATUS
  passedQuantity: 0,
  passedWarehouseInventoryData: [],
  rejectedQuantity: 0,
  rejectedWarehouseInventoryData: [],
  workOrderChildDetails: {},
  qcReason: '',
  qcNeeded: true
};
export interface WarehouseInventoryData {
  id?: number | null;
  warehouseCode: string | null;
  code?: string | null;
  rowCode?: string | null;
  rowName?: string | null;
  rackCode?: string | null;
  rackName?: string | null;
  binCode?: string | null;
  binName?: string | null;
  warehouseName?: string | null;
  quantity: number;
  advancedTrackingData?: any[] | null;
}
