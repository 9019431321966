import { INPUT_TYPE } from 'deskera-ui-library';
import {
  CONTACT_FIELD_KEY_NAMES,
  DropdownSelectionType,
  FORM_ELEMENTS,
  ROUNDING_METHOD
} from '../Constants/Constant';
import {
  COMPOSITE_SCHEME_OPTION,
  GST_BUSINESS_TYPE,
  ORGANISATION_FORM_TAB
} from '../Constants/Enum';
import { AddressType, CallBackPayloadType } from './Interfaces';
import {
  AddressInfo,
  DropdownTag,
  InputTag,
  MulitSelectDropdown,
  MultiDropdownTag,
  PhoneNumberOption
} from './NewContact';
import { CalendarType } from './PaymentPopup';
import { CustomField } from './CustomField';
import { AdditionalSettings } from './Common';

export interface OrganisationProfileState {
  orgName: string;
  bookBeginningStartDate: string;
  country: string;
  dateFormat: string;
  businessType: string;
  orgPetName: string;
  numberFormat: string;
  financialStartDate: string;
  currency: string;
  decimalScale: number;
  multicurrencyEnabled: boolean;
  shippingAddress: AddressType[];
  billingAddress: AddressType[];
  firstName: string;
  firstNameInitials: string;
  usersList: any[];
  locationLabel: any;
  classLabel: any;
  // IND Compliance
  tanNumber: OrganisationProfileComplianceField;
  interestRate: OrganisationProfileComplianceField;
  panNumber: OrganisationProfileComplianceField;
  cinNumber: OrganisationProfileComplianceField;
  tcsRegistered: OrganisationProfileComplianceField;
  eInvoiceEnabled: OrganisationProfileComplianceField;
  gstEFilingEnabled: OrganisationProfileComplianceField;
  gstRegistered: OrganisationProfileComplianceField;
  gstIn: OrganisationProfileComplianceField;
  gstRegsiteredDate: OrganisationProfileComplianceField;
  sezOverseaTrading: OrganisationProfileComplianceField;
  overSeaServices: OrganisationProfileComplianceField;
  reverseCharge: OrganisationProfileComplianceField;
  comositeScheme: OrganisationProfileComplianceField;
  comositeSchemeType: OrganisationProfileComplianceField;

  // SG Compliance
  gstNo: OrganisationProfileComplianceField;
  uenNumber: OrganisationProfileComplianceField;
  optInInvoiceNow: OrganisationProfileComplianceField;
  peppolId: OrganisationProfileComplianceField;
  vendorId: OrganisationProfileComplianceField;

  // USA Compliance
  taxIdNumber: OrganisationProfileComplianceField;
  taxStates: OrganisationProfileComplianceField;
  collectTaxes: OrganisationProfileComplianceField;

  // INDONESIA Compliance
  npwpNumber: OrganisationProfileComplianceField;
  activateExcise: OrganisationProfileComplianceField;

  // MALAYSIA Compliance
  businessRegistrationNumber: OrganisationProfileComplianceField;
  salesTaxRegistrationNumber: OrganisationProfileComplianceField;
  serviceTaxRegistrationNumber: OrganisationProfileComplianceField;
  exemptionNumber: OrganisationProfileComplianceField;
  expiryDate: OrganisationProfileComplianceField;
  isBusinessTaxExempt: OrganisationProfileComplianceField;
  isExemptCertificate: OrganisationProfileComplianceField;

  // UAE Compliance
  vatNumber: OrganisationProfileComplianceField;
  isBusinessDesignatedZone: OrganisationProfileComplianceField;
  rcmAccount: OrganisationProfileComplianceField;
  contactOutsideUAE: OrganisationProfileComplianceField;
  isExciseActivatedUAE: OrganisationProfileComplianceField;

  // PHILIPPINES Compliance
  vatNumberPH: OrganisationProfileComplianceField;

  // Canada Compliance
  gstNumber: OrganisationProfileComplianceField;
  businessNumber: OrganisationProfileComplianceField;

  // UK Compliance
  ukBusinessRegistrationNumber: OrganisationProfileComplianceField;
  vatIdentificationNumber: OrganisationProfileComplianceField;
  vatScheme: OrganisationProfileComplianceField;
  currentFlatRate: OrganisationProfileComplianceField;

  additionalSettings: AdditionalSettings;
}

export interface OrganisationProfileComplianceField {
  hidden: boolean;
  value: string | number | boolean;
}

export interface OrganisationProfileFormState {
  organisation: OrganisationForm;
  finance: FinanceForm;
  compliance: ComplianceForm;
  addressInfo: Array<OrgAddressInfo>;
  additionalSettings: AdditionalSettingsForm;
  activeShippingAddressIndex: number;
  activeBillingAddressIndex: number;
  activeReturnAddressIndex: number;
  activeTab: ORGANISATION_FORM_TAB;
}

export interface OrganisationProfileFormProps {
  passingInteraction: (callback: CallBackPayloadType) => void;
  activeTab: ORGANISATION_FORM_TAB;
  closePopup?: () => void;
}

export interface OrganisationForm {
  name: InputTag<''>;
  taxResidency: DropdownTag<''>;
  dateFormat: DropdownTag<''>;
  businessType: DropdownTag<''>;
}

export interface FinanceForm {
  financialStartDate: InputTag<string>;
  booksBeginningDate: InputTag<string>;
  reportingCurrency: InputTag<string>;
  multicurrencyCheck: Toggle;
  multicurrencyList: MulitSelectDropdown;
  decimalPlace: DropdownTag<string>;
  timezone: DropdownTag<string>;
  numberFormat: DropdownTag<string>;
}

export interface ComplianceForm {
  // IND Compliance
  gstRegistered: Toggle;
  tcsRegistered: Toggle;
  gstin: InputTag<''>;
  gstRegisteredDate: CalendarType;
  sezOverseaTrading: Checkbox;
  digitalService: Checkbox;
  reverseCharge: Checkbox;
  isCompositeScheme: Checkbox;
  compositeSchemeOption: { value: COMPOSITE_SCHEME_OPTION };
  enableInvoice: Toggle;
  enableEWayBill: Toggle;
  tanNumber: InputTag<''>;
  panNumber: InputTag<''>;
  cinNumber: InputTag<''>;
  interestRate: InputTag<''>;
  gstEFiling: Toggle;
  gstUsername: InputTag<''>;

  // SG Compliance
  uen: InputTag<''>;
  gstNo: InputTag<''>;
  optInInvoiceNow: Toggle;
  peppolId: InputTag<''>;
  vendorId: InputTag<''>;
  peppolMarketingOptIn: Toggle;

  // US Compliance
  donTCollectTax: Toggle;
  taxIdNumber: InputTag<''>;
  taxCollectionStates: MultiDropdownTag<string>;

  // Indonesia Compliance
  npwpNumber: InputTag<''>;
  isExciseDuty: Toggle;
  exciseAccount: DropdownTag<''>;

  // Malaysia Compliance
  businessRegistrationNumber: InputTag<''>;
  salesTaxNumber: InputTag<''>;
  serviceTaxNumber: InputTag<''>;
  isTaxExempt: Toggle;
  exemptNumber: InputTag<''>;
  expiryDate: CalendarType;
  isExemptCertificate: Toggle;
  exemptCriteria: DropdownTag<''>;

  // UAE Compliance
  vatNumber: InputTag<''>;
  isDesignatedZoneBusiness: Toggle;
  isContactsOutsideUAE: Toggle;
  taxOptionForContactOutsideUAE: DropdownTag<''>;
  isExciseDutyUAE: Toggle;
  exciseAccountUAE: DropdownTag<''>;

  // Philippines Compliance
  vatNumberPH: InputTag<''>;

  // Canada Compliance
  gstNumberCA: InputTag<''>;
  businessNumber: InputTag<''>;

  // UK Compliance
  ukBusinessRegistrationNumber: InputTag<''>;
  vatIdentificationNumber: InputTag<''>;
  vatScheme: DropdownTag<''>;
  makingTaxDigital: Toggle;
  // Israel Compliance
  ilDeductionFileId: InputTag<''>;
  ilEngagementType: InputTag<''>;
  ilIncomeTaxNo: InputTag<''>;
  ilLegalName: InputTag<''>;
  ilNationalInsuranceNo: InputTag<''>;
  ilParentSubsidiaryName: InputTag<''>;
  ilReturnEmail: InputTag<''>;
  ilVatCode: DropdownTag<''>;
  ilVatRegistrationNo: InputTag<''>;
  ilWithholdingTaxAccount: DropdownTag<''>;
  ilWhtType: DropdownTag<''>;
}

export interface OrgAddressInfo {
  contactName: InputTag<CONTACT_FIELD_KEY_NAMES>;
  address: InputTag<CONTACT_FIELD_KEY_NAMES>;
  address2: InputTag<CONTACT_FIELD_KEY_NAMES>;
  state: DropdownTag<CONTACT_FIELD_KEY_NAMES>;
  city: DropdownTag<CONTACT_FIELD_KEY_NAMES>;
  postalCode: InputTag<CONTACT_FIELD_KEY_NAMES>;
  country: DropdownTag<CONTACT_FIELD_KEY_NAMES>;
  countryNumberOptions: PhoneNumberOption;
  contactNumber: InputTag<CONTACT_FIELD_KEY_NAMES>;
  email: InputTag<CONTACT_FIELD_KEY_NAMES>;
  customFields: CustomField[];
}

interface Klass {
  trackClasses: Toggle;
  assignClasses: DropdownTag<''>;
  warnOnClassNotSelected: Checkbox;
  mandatory: Toggle;
}

interface Location {
  locationLabel: DropdownTag<''>;
  trackLocation: Toggle;
  mandatory: Toggle;
}
interface IROUND_OFF {
  roundingMethod: DropdownTag<''>;
  autoRoundOff: boolean;
}

export interface AdditionalSettingsForm {
  CLASS: Klass;
  LOCATION: Location;
  ROUND_OFF: IROUND_OFF;
}

interface Toggle {
  isChecked: boolean;
  isDisabled: boolean;
  hidden: boolean;
  isApiCallSuccess?: boolean;
}

interface Checkbox {
  isChecked: boolean;
  isDisabled: boolean;
  hidden: boolean;
}

export const initialOrganisationProfileFormState: OrganisationProfileFormState =
  {
    organisation: {
      name: {
        key: '',
        hidden: false,
        value: '',
        type: FORM_ELEMENTS.INPUT,
        hasError: false,
        isMandatory: true
      },
      taxResidency: {
        key: '',
        hidden: false,
        value: { label: '', value: '' },
        selectionType: DropdownSelectionType.SINGLE,
        type: FORM_ELEMENTS.DROPDOWN
      },
      dateFormat: {
        key: '',
        hidden: false,
        value: { label: '', value: '' },
        selectionType: DropdownSelectionType.SINGLE,
        type: FORM_ELEMENTS.DROPDOWN
      },
      businessType: {
        key: '',
        hidden: false,
        value: { label: '', value: '' },
        selectionType: DropdownSelectionType.SINGLE,
        type: FORM_ELEMENTS.DROPDOWN
      }
    },
    finance: {
      financialStartDate: {
        key: '',
        hidden: false,
        value: '',
        type: FORM_ELEMENTS.INPUT,
        hasError: false,
        isMandatory: true
      },
      booksBeginningDate: {
        key: '',
        hidden: false,
        value: '',
        type: FORM_ELEMENTS.INPUT,
        hasError: false,
        isMandatory: true
      },
      reportingCurrency: {
        key: '',
        hidden: false,
        value: '',
        type: FORM_ELEMENTS.INPUT,
        hasError: false,
        isMandatory: true
      },
      multicurrencyCheck: {
        isChecked: false,
        isDisabled: false,
        hidden: false
      },
      multicurrencyList: {
        key: '',
        hidden: false,
        value: [],
        selectionType: DropdownSelectionType.MULTIPLE,
        type: FORM_ELEMENTS.DROPDOWN
      },
      decimalPlace: {
        key: '',
        hidden: false,
        value: { label: '', value: '' },
        selectionType: DropdownSelectionType.SINGLE,
        type: FORM_ELEMENTS.DROPDOWN
      },
      timezone: {
        key: '',
        hidden: false,
        value: { label: '', value: '' },
        selectionType: DropdownSelectionType.SINGLE,
        type: FORM_ELEMENTS.DROPDOWN
      },
      numberFormat: {
        key: '',
        hidden: false,
        value: { label: '', value: '' },
        selectionType: DropdownSelectionType.SINGLE,
        type: FORM_ELEMENTS.DROPDOWN
      }
    },
    compliance: {
      // Indian Compliance
      gstRegistered: {
        isChecked: false,
        isDisabled: false,
        hidden: true
      },
      gstRegisteredDate: {
        isOpen: false,
        date: '',
        hidden: true
      },
      gstin: {
        key: '',
        hidden: true,
        value: '',
        type: FORM_ELEMENTS.INPUT,
        hasError: false,
        isMandatory: true
      },
      sezOverseaTrading: {
        isChecked: false,
        isDisabled: false,
        hidden: true
      },
      digitalService: {
        isChecked: false,
        isDisabled: false,
        hidden: true
      },
      reverseCharge: {
        isChecked: false,
        isDisabled: false,
        hidden: true
      },
      isCompositeScheme: {
        isChecked: false,
        isDisabled: false,
        hidden: true
      },
      compositeSchemeOption: {
        value: COMPOSITE_SCHEME_OPTION.NONE
      },
      tcsRegistered: {
        isChecked: false,
        isDisabled: false,
        hidden: true
      },
      enableInvoice: {
        isChecked: false,
        isDisabled: true,
        hidden: true,
        isApiCallSuccess: false
      },
      enableEWayBill: {
        isChecked: false,
        isDisabled: true,
        hidden: true,
        isApiCallSuccess: false
      },
      gstEFiling: {
        isChecked: false,
        isDisabled: true,
        hidden: true,
        isApiCallSuccess: false
      },
      gstUsername: {
        key: '',
        hidden: true,
        value: '',
        type: FORM_ELEMENTS.INPUT,
        hasError: false,
        isMandatory: false
      },
      tanNumber: {
        key: '',
        hidden: true,
        value: '',
        type: FORM_ELEMENTS.INPUT,
        hasError: false,
        isMandatory: false
      },
      panNumber: {
        key: '',
        hidden: true,
        value: '',
        type: FORM_ELEMENTS.INPUT,
        hasError: false,
        isMandatory: false
      },
      cinNumber: {
        key: '',
        hidden: true,
        value: '',
        type: FORM_ELEMENTS.INPUT,
        hasError: false,
        isMandatory: false
      },
      interestRate: {
        key: '',
        hidden: true,
        value: '',
        type: FORM_ELEMENTS.INPUT,
        hasError: false,
        isMandatory: false
      },

      // SG Compliance
      peppolMarketingOptIn: {
        isChecked: false,
        isDisabled: false,
        hidden: true
      },
      uen: {
        key: '',
        hidden: true,
        value: '',
        type: FORM_ELEMENTS.INPUT,
        hasError: false,
        isMandatory: false
      },
      gstNo: {
        key: '',
        hidden: true,
        value: '',
        type: FORM_ELEMENTS.INPUT,
        hasError: false,
        isMandatory: false
      },
      optInInvoiceNow: {
        isChecked: false,
        isDisabled: false,
        hidden: true
      },
      peppolId: {
        key: '',
        hidden: true,
        value: '',
        type: FORM_ELEMENTS.INPUT,
        hasError: false,
        isMandatory: false
      },
      vendorId: {
        key: '',
        hidden: true,
        value: '',
        type: FORM_ELEMENTS.INPUT,
        hasError: false,
        isMandatory: true
      },

      // USA Compliance
      donTCollectTax: {
        isChecked: true,
        isDisabled: false,
        hidden: true
      },
      taxIdNumber: {
        key: '',
        hidden: true,
        value: '',
        type: FORM_ELEMENTS.INPUT,
        hasError: false,
        isMandatory: false
      },
      taxCollectionStates: {
        key: '',
        hidden: true,
        value: [],
        selectionType: DropdownSelectionType.MULTIPLE,
        type: FORM_ELEMENTS.DROPDOWN
      },

      // INDONESIA Compliance
      npwpNumber: {
        key: '',
        hidden: true,
        value: '',
        type: FORM_ELEMENTS.INPUT,
        hasError: false,
        isMandatory: false
      },
      isExciseDuty: {
        isChecked: false,
        isDisabled: false,
        hidden: true
      },
      exciseAccount: {
        key: '',
        hidden: true,
        value: { label: '', value: '' },
        selectionType: DropdownSelectionType.SINGLE,
        type: FORM_ELEMENTS.DROPDOWN
      },

      // MALAYSIA Compliance
      businessRegistrationNumber: {
        key: '',
        hidden: true,
        value: '',
        type: FORM_ELEMENTS.INPUT,
        hasError: false,
        isMandatory: false
      },
      salesTaxNumber: {
        key: '',
        hidden: true,
        value: '',
        type: FORM_ELEMENTS.INPUT,
        hasError: false,
        isMandatory: false
      },
      serviceTaxNumber: {
        key: '',
        hidden: true,
        value: '',
        type: FORM_ELEMENTS.INPUT,
        hasError: false,
        isMandatory: false
      },
      isTaxExempt: {
        isChecked: false,
        isDisabled: false,
        hidden: true
      },
      exemptNumber: {
        key: '',
        hidden: true,
        value: '',
        type: FORM_ELEMENTS.INPUT,
        hasError: false,
        isMandatory: false
      },
      expiryDate: {
        isOpen: false,
        date: '',
        hidden: true
      },
      isExemptCertificate: {
        isChecked: false,
        isDisabled: false,
        hidden: true
      },
      exemptCriteria: {
        key: '',
        hidden: true,
        value: { label: '', value: '' },
        selectionType: DropdownSelectionType.SINGLE,
        type: FORM_ELEMENTS.DROPDOWN
      },
      // UAE Compliance
      vatNumber: {
        key: '',
        hidden: true,
        value: '',
        type: FORM_ELEMENTS.INPUT,
        hasError: false,
        isMandatory: false
      },
      isDesignatedZoneBusiness: {
        isChecked: false,
        isDisabled: false,
        hidden: true
      },
      isContactsOutsideUAE: {
        isChecked: false,
        isDisabled: false,
        hidden: true
      },
      isExciseDutyUAE: {
        isChecked: false,
        isDisabled: false,
        hidden: true
      },
      exciseAccountUAE: {
        key: '',
        hidden: true,
        value: { label: '', value: '' },
        selectionType: DropdownSelectionType.SINGLE,
        type: FORM_ELEMENTS.DROPDOWN
      },
      taxOptionForContactOutsideUAE: {
        key: '',
        hidden: true,
        value: { label: '', value: '' },
        selectionType: DropdownSelectionType.SINGLE,
        type: FORM_ELEMENTS.DROPDOWN
      },
      // Philippines Compliance
      vatNumberPH: {
        key: '',
        hidden: true,
        value: '',
        type: FORM_ELEMENTS.INPUT,
        hasError: false,
        isMandatory: false
      },

      // Canada Compliance
      gstNumberCA: {
        key: '',
        hidden: true,
        value: '',
        type: FORM_ELEMENTS.INPUT,
        hasError: false,
        isMandatory: false
      },
      businessNumber: {
        key: '',
        hidden: true,
        value: '',
        type: FORM_ELEMENTS.INPUT,
        hasError: false,
        isMandatory: false
      },

      // Israel compliance
      ilDeductionFileId: {
        key: '',
        hidden: true,
        value: '',
        type: FORM_ELEMENTS.INPUT,
        hasError: false,
        isMandatory: false
      },
      ilEngagementType: {
        key: '',
        hidden: true,
        value: '',
        type: FORM_ELEMENTS.INPUT,
        hasError: false,
        isMandatory: false
      },
      ilIncomeTaxNo: {
        key: '',
        hidden: true,
        value: '',
        type: FORM_ELEMENTS.INPUT,
        hasError: false,
        isMandatory: false
      },
      ilLegalName: {
        key: '',
        hidden: true,
        value: '',
        type: FORM_ELEMENTS.INPUT,
        hasError: false,
        isMandatory: false
      },
      ilNationalInsuranceNo: {
        key: '',
        hidden: true,
        value: '',
        type: FORM_ELEMENTS.INPUT,
        hasError: false,
        isMandatory: false
      },
      ilParentSubsidiaryName: {
        key: '',
        hidden: true,
        value: '',
        type: FORM_ELEMENTS.INPUT,
        hasError: false,
        isMandatory: false
      },
      ilReturnEmail: {
        key: '',
        hidden: true,
        value: '',
        type: FORM_ELEMENTS.INPUT,
        hasError: false,
        isMandatory: false
      },
      ilVatCode: {
        key: '',
        hidden: true,
        value: { label: '', value: '' },
        type: FORM_ELEMENTS.DROPDOWN,
        selectionType: DropdownSelectionType.SINGLE,
        hasError: false,
        isMandatory: false
      },
      ilVatRegistrationNo: {
        key: '',
        hidden: true,
        value: '',
        type: FORM_ELEMENTS.INPUT,
        hasError: false,
        isMandatory: false
      },
      ilWithholdingTaxAccount: {
        key: '',
        hidden: true,
        value: { label: '', value: '' },
        type: FORM_ELEMENTS.DROPDOWN,
        selectionType: DropdownSelectionType.SINGLE,
        hasError: false,
        isMandatory: false
      },
      ilWhtType: {
        key: '',
        hidden: true,
        value: { label: '', value: '' },
        type: FORM_ELEMENTS.DROPDOWN,
        selectionType: DropdownSelectionType.SINGLE,
        hasError: false,
        isMandatory: false
      },
      // UK Compliance
      ukBusinessRegistrationNumber: {
        key: '',
        hidden: true,
        value: '',
        type: FORM_ELEMENTS.INPUT,
        hasError: false,
        isMandatory: false
      },
      vatIdentificationNumber: {
        key: '',
        hidden: true,
        value: '',
        type: FORM_ELEMENTS.INPUT,
        hasError: false,
        isMandatory: false
      },
      vatScheme: {
        key: '',
        hidden: true,
        value: { label: '', value: '' },
        selectionType: DropdownSelectionType.SINGLE,
        type: FORM_ELEMENTS.DROPDOWN
      },
      makingTaxDigital: {
        isChecked: false,
        isDisabled: false,
        hidden: true
      }
    },
    addressInfo: [
      {
        contactName: {
          key: CONTACT_FIELD_KEY_NAMES.CONTACT_NAME,
          hidden: false,
          value: '',
          type: FORM_ELEMENTS.INPUT,
          hasError: false,
          defaultValue: ''
        },
        address: {
          key: CONTACT_FIELD_KEY_NAMES.ADDRESS,
          hidden: false,
          value: '',
          type: FORM_ELEMENTS.INPUT,
          hasError: false,
          defaultValue: ''
        },
        address2: {
          key: CONTACT_FIELD_KEY_NAMES.ADDRESS2,
          hidden: false,
          value: '',
          type: FORM_ELEMENTS.INPUT,
          hasError: false,
          defaultValue: ''
        },
        state: {
          key: CONTACT_FIELD_KEY_NAMES.STATE,
          hidden: false,
          value: { label: '', value: '' },
          type: FORM_ELEMENTS.DROPDOWN,
          selectionType: DropdownSelectionType.SINGLE,
          hasError: false
        },
        city: {
          key: CONTACT_FIELD_KEY_NAMES.CITY,
          selectionType: DropdownSelectionType.SINGLE,
          hidden: false,
          value: { label: '', value: '' },
          type: FORM_ELEMENTS.INPUT,
          hasError: false,
          isMandatory: false
        },
        country: {
          key: CONTACT_FIELD_KEY_NAMES.STATE,
          hidden: false,
          value: { label: '', value: '' },
          type: FORM_ELEMENTS.DROPDOWN,
          selectionType: DropdownSelectionType.SINGLE,
          hasError: false
        },
        postalCode: {
          key: CONTACT_FIELD_KEY_NAMES.POSTAL_CODE,
          hidden: false,
          value: '',
          type: FORM_ELEMENTS.INPUT,
          hasError: false,
          defaultValue: ''
        },
        countryNumberOptions: {
          hidden: true,
          value: null,
          selectionType: DropdownSelectionType.SINGLE,
          options: [],
          type: FORM_ELEMENTS.DROPDOWN,
          showDropdown: false
        },
        contactNumber: {
          key: CONTACT_FIELD_KEY_NAMES.MOBILE_NUMBER,
          hidden: false,
          value: '',
          type: FORM_ELEMENTS.INPUT,
          hasError: false,
          errorMsg: '',
          isMandatory: true
        },
        email: {
          key: CONTACT_FIELD_KEY_NAMES.EMAIL,
          hidden: false,
          value: '',
          type: FORM_ELEMENTS.INPUT,
          hasError: false,
          errorMsg: 'Invalid email address'
        },
        customFields: []
      }
    ],
    additionalSettings: {
      CLASS: {
        trackClasses: {
          isChecked: false,
          isDisabled: true,
          hidden: false,
          isApiCallSuccess: false
        },
        mandatory: {
          isChecked: false,
          isDisabled: true,
          hidden: false,
          isApiCallSuccess: false
        },
        assignClasses: {
          key: '',
          hidden: false,
          value: { label: '', value: '' },
          selectionType: DropdownSelectionType.SINGLE,
          type: FORM_ELEMENTS.DROPDOWN
        },
        warnOnClassNotSelected: {
          hidden: true,
          isChecked: false,
          isDisabled: true
        }
      },
      LOCATION: {
        trackLocation: {
          isChecked: false,
          isDisabled: true,
          hidden: false,
          isApiCallSuccess: false
        },
        mandatory: {
          isChecked: false,
          isDisabled: true,
          hidden: false,
          isApiCallSuccess: false
        },
        locationLabel: {
          key: '',
          hidden: false,
          value: { label: '', value: '' },
          selectionType: DropdownSelectionType.SINGLE,
          type: FORM_ELEMENTS.DROPDOWN
        }
      },
      ROUND_OFF: {
        autoRoundOff: false,
        roundingMethod: {
          key: '',
          hidden: false,
          value: { label: '', value: '' },
          selectionType: DropdownSelectionType.SINGLE,
          type: FORM_ELEMENTS.DROPDOWN
        }
      }
    },
    activeShippingAddressIndex: 0,
    activeBillingAddressIndex: 0,
    activeReturnAddressIndex: 0,
    activeTab: ORGANISATION_FORM_TAB.GENERAL_INFO
  };

export interface OrganisationFormPayload {
  address: AddressType;
  billingAddresses: AddressType[];
  shippingAddresses: AddressType[];
  currency: string;
  dateFormat: string;
  decimalScale: number;
  id: number;
  multicurrency: boolean;
  multicurrencyEnabled: boolean;
  name: string;
  numberFormat: string;
  currencyCodesToActivate?: string[];
  timezone: string;

  // india compliance
  tcsEnabled?: boolean;
  tdsPaymentInterestRateIndia?: number;
  gstin?: string | null;
  gstRegistered?: boolean;
  gstRegistrationDate?: string | null;
  indiaEinvoice?: boolean;
  indiaGstr?: boolean;
  indiaGstrUsername?: string | null;
  registeredToCompositionScheme?: boolean | null;
  sezOrImportExport?: null | boolean;
  compositionSchemeType?: null | COMPOSITE_SCHEME_OPTION;
  enableReverseCharge?: null | boolean;
  trackDigitalServices?: null | boolean;
  tanNumberIndia?: string | null;
  panIndia?: string | null;
  cinIndia?: string | null;

  // israel compliance
  ilDeductionFileId?: string | null;
  ilEngagementType?: string | null;
  ilIncomeTaxNo?: string | null;
  ilLegalName?: string | null;
  ilNationalInsuranceNo?: string | null;
  ilParentSubsidiaryName?: string | null;
  ilReturnEmail?: string | null;
  ilVatCode?: string | null;
  ilVatRegistrationNo?: string | null;
  ilWithholdingTaxAccount?: string | null;
  ilReturnAddress?: AddressType[];

  // sg compliance
  gstNo?: string;
  uenNo?: string;
  peppolOptIn?: boolean;
  peppolMarketingOptIn?: boolean;
  peppolId?: string;
  vendorId?: string | null;

  // usa compliance
  nexusStates?: string;
  complianceEnabled?: boolean;

  // indonesia compliance
  npwpNo?: string;
  exciseApplicable?: boolean;
  exciseAccount?: string;

  // malaysia compliance
  businessRegistrationNo?: string;
  salesTaxRegistrationNo?: string;
  serviceTaxRegistrationNo?: string;
  exemption?: boolean;
  exemptionNo?: string;
  expiryDate?: string;
  exemptionCerificate?: boolean;
  exemptionCriteria?: string;

  // uae compliance
  vatNoUAE?: string;
  outsideContact?: boolean;
  rcmAccount?: string;
  // exciseApplicable?;
  // exciseAccount?
  isDesignatedZone?: boolean;

  // philippines compliance
  vatNoPhilippines?: string;

  // canada compliance
  canadianBusinessNo?: string;
  gstNumber?: string;

  // uk compliance
  businessRegistrationNoUK?: string;
  vatIdentificationNumberUK?: string;
  vatSchemeUK?: string;
  flatRatePercentageListUK?: any[];

  additionalSettings: {
    CLASS: {
      trackClasses: boolean;
      mandatory: boolean;
      assignClasses: string;
      warnOnClassNotSelected: boolean;
      classCFId?: any;
    };
    LOCATION: {
      trackLocation: boolean;
      mandatory: boolean;
      locationLabel: string;
      locationCFId?: any;
    };
    BUSINESS_TYPE?: {
      label: string;
      value: string;
    };
    ROUND_OFF?: {
      autoRoundOff: boolean;
      roundingMethod: string;
    };
    ACTIVE_DATE_RANGE_SETTING?: {
      isActiveDateRange: boolean;
      activeFromDate: string;
      activeToDate: string;
    };
  };
}

export enum ORGANISATION_PROFILE_FIELDS_NAME {
  DATE_FORMAT = 'dateFormat',
  BUSINESS_TYPE = 'businessType',
  NUMBER_FORMAT = 'numberFormat',
  DECIMAL_PLACE = 'decimalPlace',
  DROPDOWN_CITY = 'city',
  DROPDOWN_STATE = 'state',
  COUNTRY = 'country',
  EXCISE_ACCOUNT = 'exciseAccount',
  EXEMPTION_CRITERIA = 'exemptCriteria',
  EXCISE_ACCOUNT_UAE = 'exciseAccountUAE',
  OUTSIDE_CONTACT = 'outsideContact',
  VAT_SCHEME = 'vatScheme',
  TIMEZONE = 'timezone'
}

export enum ORGANISATION_FORM_FIELD_NAME {
  CONTACT_NAME = 'contactName',
  ADDRESS = 'address',
  ADDRESS2 = 'address2',
  POSTAL_CODE = 'postalCode',
  INPUT_FIELD_CITY = 'city',
  INPUT_FIELD_STATE = 'state',
  TAN_NUMBER = 'tanNumber',
  INTEREST_RATE = 'interestRate',
  GSTIN = 'gstin',
  GST_E_FILING_USERNAME = 'username',
  UEN_NUMBER = 'uen',
  GST_NO = 'gstNo',
  PEPPOL_ID = 'peppolId',
  VENDOR_ID = 'vendorId',
  TAX_ID_NUMBER = 'taxIdNumber',
  NPWP_NUMBER = 'npwpNumber',
  EXEMPTION_NUMBER = 'exemptNumber',
  BUSINESS_REGISTRATION_NUMBER = 'businessRegistrationNumber',
  UK_BUSINESS_REGISTRATION_NUMBER = 'ukBusinessRegistrationNumber',
  SALES_TAX_NUMBER = 'salesTaxNumber',
  SERVICE_TAX_NUMBER = 'serviceTaxNumber',
  VAT_NUMBER = 'vatNumber',
  VAT_NUMBER_PH = 'vatNumberPH',
  GST_NUMBER_CA = 'gstNumberCA',
  BUSINESS_NUMBER = 'businessNumber',
  PAN_NUMBER = 'panNumber',
  CIN_NUMBER = 'cinNumber',
  VAT_IDENTIFICATION_NUMBER = 'vatIdentificationNumber',
  IL_DEDUCTION_FILE_ID = 'ilDeductionFileId',
  IL_ENGAGEMENT_TYPE = 'ilEngagementType',
  IL_INCOME_TAX_NO = 'ilIncomeTaxNo',
  IL_LEGAL_NAME = 'ilLegalName',
  IL_NATIONAL_INSURANCE_NO = 'ilNationalInsuranceNo',
  IL_PARENT_SUBSIDIARY_NAME = 'ilParentSubsidiaryName',
  IL_RETURN_EMAIL = 'ilReturnEmail',
  IL_VAT_CODE = 'ilVatCode',
  IL_VAT_REGISTRATION_NO = 'ilVatRegistrationNo',
  IL_WITHHOLDING_TAX_ACCOUNT = 'ilWithholdingTaxAccount',
  IL_WHT_TYPE = 'ilWHTType',
  MOBILE_NUMBER = 'contactNumber',
  EMAIL = 'emailId'
}

export enum ORGANISATION_COMPLIANCE_FIELD_NAME {
  GST_REGISTERED = 'gstRegistered',
  TCS_REGISTERED = 'tcsRegistered',
  GST_E_FILING = 'gstEFiling',
  ENABLE_INVOICE = 'enableInvoice',
  OPT_IN_INVOICE_NOW = 'invoiceNow',
  DONT_COLLECT_TAX = 'dontCollectTax',
  ACTIVATE_EXCISE_DUTY = 'isExciseDuty',
  IS_EXEMPT_BUSINESS = 'isExemptBusiness',
  IS_EXEMPT_CERTIFICATE = 'isExemptCertificate',
  IS_CONTACTS_OUTSIDE_UAE = 'isContactsOutsideUAE',
  BUSINESS_IN_DESIGNATED_ZONE = 'businessInDesignatedZone',
  IS_EXCISE_DUTY_UAE = 'isExciseDutyUAE',
  ENABLE_EWAY_BILL = 'enableEWayBill',
  MAKING_TAX_DIGITAL = 'makingTaxDigital',
  PEPPOL_MARKETING_OPT_IN = 'peppolMarketingOptIn'
}

export enum ORGANISATION_ADDITIONAL_SETTINGS_FIELD_NAME {
  TRACK_CLASSES = 'trackClasses',
  MANDATORY_CLASSES = 'mandatoryClasses',
  MANDATORY_LOCATIONS = 'mandatoryLocations',
  ASSIGN_CLASSES = 'assignClasses',
  WARN_ON_CLASS_NOT_SELECTED = 'warnOnClassNotSelected',
  TRACK_LOCATION = 'trackLocation',
  LOCATION_LABEL = 'locationLabel',
  AUTO_ROUND_OFF = 'autoRoundOff',
  ROUNDING_METHOD = 'roundingMethod',
  VAT_SCHEME = 'vatScheme'
}

export enum ORGANISATION_FORM_DATE_FIELDS {
  EXPIRY_DATE = 'expiryDate',
  GST_REGISTRATION_DATE = 'gstRegistrationDate'
}

export const UK_FLAT_RATE_SCHEME_COLS = [
  {
    id: 'flatRatePercentage',
    key: 'flatRatePercentage',
    name: 'Flat Rate Percentage (%)',
    type: INPUT_TYPE.NUMBER,
    width: 190,
    systemField: true,
    editable: true,
    hidden: false,
    uiVisible: true,
    textAlign: 'right',
    required: false
  },
  {
    id: 'applyFrom',
    key: 'applyFrom',
    name: 'Apply From',
    type: INPUT_TYPE.DATE,
    width: 190,
    systemField: true,
    editable: true,
    hidden: false,
    uiVisible: true,
    textAlign: 'right',
    required: false
  }
];
