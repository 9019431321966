import {
  APPROVAL_STATUS,
  DOC_TYPE,
  DOCUMENT_MODE
} from '../../../../Constants/Constant';
import { AnyDocument, DraftTypes, IDocument } from '../../../../Models/Drafts';
import { onDocumentClose } from '../../../../Redux/Slices/DocumentSlice';
import { fetchDrafts } from '../../../../Redux/Slices/DraftsSlice';
import { fetchOrders } from '../../../../Redux/Slices/PurchaseOrdersSlice';
import { fetchQuotes } from '../../../../Redux/Slices/QuotesSlice';
import { fetchSalesOrders } from '../../../../Redux/Slices/SalesOrderSlice';
import { Store } from '../../../../Redux/Store';
import AuthService from '../../../../Services/Auth';
import DraftService from '../../../../Services/Drafts';
import Utility from '../../../../Utility/Utility';
import { DOC_SAVE_OPTION, setIsSavingDocument } from '../View/ActionBarHelper';

/**
 * Delegator for saving/updating a draft
 */
export class DocSaver {
  draftToSave: IDocument<AnyDocument>;
  documentMode: DOCUMENT_MODE;
  saveOption: DOC_SAVE_OPTION;
  tenantInfo: any;
  draftTableId: any;
  draftColumnConfig: any[];
  draftTypeColId: any;
  isSaveColumnId: any;
  userInfo: any;

  constructor(data: {
    draftToSave: IDocument<AnyDocument>;
    documentMode: DOCUMENT_MODE;
    saveOption: DOC_SAVE_OPTION;
    tenantInfo: any;
  }) {
    this.draftToSave = { ...data.draftToSave };
    this.documentMode = data.documentMode;
    this.saveOption = data.saveOption;
    this.tenantInfo = data.tenantInfo;
    const draftsData = Store.getState().drafts as any;
    this.draftTableId = draftsData.tableId;
    this.draftColumnConfig = draftsData.columnConfig;
    this.draftTypeColId = draftsData.draftTypeColumnId;
    this.isSaveColumnId = draftsData.isSaveColumnId as any;
    this.userInfo = Store.getState().authInfo?.userInfo?.data;
  }

  /**
   * Check for approval required and return updated `payload` with `isApprovalRequired` flag
   * @param docToSave
   * @param approvalRequired
   */
  async updatePayloadWithApprovalData(
    docToSave: AnyDocument,
    approvalRequired?: boolean
  ) {
    let payload = { ...docToSave };
    try {
      let isApprovalRequired = false;
      if (
        typeof approvalRequired !== 'undefined' &&
        approvalRequired !== null
      ) {
        isApprovalRequired = approvalRequired;
      } else {
        isApprovalRequired = await Utility.isApprovalRequired(payload);
      }

      if (isApprovalRequired) {
        payload['approvalStatus'] = APPROVAL_STATUS['PENDING_FOR_APPROVAL'];
        payload['multiApprovalDetails'] = {
          currentLevel: payload?.['multiApprovalDetails']?.currentLevel || 1,
          approvalHistory:
            payload?.['multiApprovalDetails']?.approvalHistory || [],
          approvalRequiredFor: this.userInfo?.[0]?.email || ''
        };
      } else {
        payload['approvalStatus'] = payload['approvalStatus']
          ? payload['approvalStatus']
          : APPROVAL_STATUS['NOT_REQUIRED'];
      }
      payload['createdUserName'] = AuthService.getUserName();
      return { payload, isApprovalRequired };
    } catch (err: any) {
      console.error('Error loading approval info: ', err);
    }
  }

  /**
   * Save as draft
   * @param docToSave
   */
  async saveDraft(docToSave: AnyDocument) {
    const documentTouched =
      docToSave?.autoCreatePOFromWO ?? docToSave?.isDocumentTouched;

    // If nothing has been added in the draft, then close it
    if (
      this.draftToSave?.draftType === DraftTypes.NEW &&
      !documentTouched &&
      this.draftToSave.isMaximized
    ) {
      try {
        Store.dispatch(onDocumentClose({ draftId: this.draftToSave?.id }));
        setIsSavingDocument(this.draftToSave?.id, false);
      } catch (err) {
        console.error('Error deleting draft record: ', err);
        setIsSavingDocument(this.draftToSave?.id, false);
      }
    } else {
      // If draft has some updates then save it, if it is new or duplicate
      if (
        this.draftToSave.draftType === DraftTypes.NEW ||
        this.draftToSave.draftType === DraftTypes.COPY
      ) {
        try {
          await DraftService.createRecord(docToSave, this.draftToSave);
          let sourceDocument = DraftService.getSourceDoc(docToSave);
          switch (sourceDocument?.documentType) {
            case DOC_TYPE.QUOTE:
              Store.dispatch(fetchQuotes());
              break;
            case DOC_TYPE.SALES_ORDER:
              Store.dispatch(fetchSalesOrders());
              break;
            case DOC_TYPE.ORDER:
              Store.dispatch(fetchOrders());
              break;
          }
        } catch (err) {
          console.error('Error creating draft', err);
        }
      } else {
        // if updating an existing draft then update record
        try {
          await DraftService.updateRecord(
            docToSave,
            this.draftToSave,
            this.draftColumnConfig,
            this.draftTableId
          );
        } catch (err) {
          console.error('error updating draft', err);
        }
      }
      Store.dispatch(
        fetchDrafts({
          tableId: this.draftTableId,
          isSaveColumnId: this.isSaveColumnId
        })
      );
      setIsSavingDocument(this.draftToSave?.id, false);
      Store.dispatch(onDocumentClose({ draftId: this.draftToSave?.id }));
    }
  }
}
