/* This slice is for new DocumentForm and will be responsible for managing state for currently opened/maximized drafts */

import {
  PayloadAction,
  createAsyncThunk,
  createSelector,
  createSlice
} from '@reduxjs/toolkit';
import { AnyDocument, IDocument } from '../../Models/Drafts';
import {
  removeDraft,
  createBlankDraft as createBlankDocument,
  setViewOfDraft
} from './DraftsSlice';
import { RootState } from '../Store';
import {
  DOC_LINE_ITEM_KEYS,
  DOCUMENT_KEYS,
  DRAFT_DOC_SUPPORTED_MODULE_LABELS
} from '../../Components/Document/Utilities/DocConstants';
import {
  BOOKS_DATE_FORMAT,
  DOC_TYPE,
  DOCUMENT_MODE,
  TAX_SYSTEM
} from '../../Constants/Constant';

import {
  getParsedDocumentDataToDisplay,
  getUpdatedMemoOnProductDelete
} from '../../Components/Document/Helper/Common/DocDataHelper';
import Utility from '../../Utility/Utility';
import {
  calculateLineItemTaxesAndAmount,
  getExpectedDeliveryDateFromItems
} from '../../Components/Document/Helper/DocRowHelper';
import { calculateTotalOnDocDataChange } from '../../Components/Document/Views/Footer/DocSummaryHelper';
import { getTenantTaxSystem } from '../../SharedComponents/DocumentForm/NewDocumentHelper';
import DateFormatService from '../../Services/DateFormat';

/* ************************************************ MODELS/STATE **************************************************** */
export interface IDocumentState {
  documents: IDocument[];
  shouldUpdateColumnConfig: boolean;
  columns: string;
}

const initialState: IDocumentState = {
  documents: [],
  columns: '[]',
  shouldUpdateColumnConfig: false
};

/*************************************************COMMON UTILS************************************************* */

export function findDocumentById(documents: IDocument[], id: number) {
  return documents.find((document) => document.id === id);
}

/*************************************************ASYNC CODE************************************************* */
/**
 * @description - use only if you need to perform some async operation on Document Change otherwise, Use updateMultipleKeysInDocument()
 */
export const onDocumentFormFieldUpdate = createAsyncThunk(
  'documentsData/field',
  (
    data: {
      draftId: number;
      dataKey: keyof AnyDocument;
      value: unknown;
      documentMode?: DOCUMENT_MODE;
      isCashInvoice?: boolean;
    },
    thunk
  ) => {
    switch (data.dataKey) {
      case DOCUMENT_KEYS.CONTACT:
        thunk.dispatch(
          setIsDocumentTouched({
            draftId: data.draftId,
            isDocumentTouched: true
          })
        );
        break;
      case DOCUMENT_KEYS.BILL_TO:
      case DOCUMENT_KEYS.SHIP_TO:
      case DOCUMENT_KEYS.SHIP_FROM:
        break;
      case DOCUMENT_KEYS.DOCUMENT_DATE:
        break;
      case DOCUMENT_KEYS.VALID_TILL_DATE:
      case DOCUMENT_KEYS.FULFILLMENT_DATE:
        thunk.dispatch(
          setIsDocumentTouched({
            draftId: data.draftId,
            isDocumentTouched: true
          })
        );
        break;
      case DOCUMENT_KEYS.UNIT_PRICE_GST_INCLUSIVE:
        /* thunk.dispatch(updateAllLineItem({ isTaxInclusive }, true)) */
        break;
      case DOCUMENT_KEYS.MEMO:
        thunk.dispatch(
          setIsDocumentTouched({
            draftId: data.draftId,
            isDocumentTouched: true
          })
        );
        break;
      default:
    }

    return data;
  }
);

export const onDocumentViewChanged = createAsyncThunk(
  'documentsData/viewChanged',
  (data: { draftId: number; isMaximized: boolean }, thunk) => {
    thunk.dispatch(
      setViewOfDraft({ id: data.draftId, isMaximized: data.isMaximized })
    );

    return data;
  }
);

/**
 * For closing a draft
 */
export const onDocumentClose = createAsyncThunk(
  'documentsData/close',
  (data: { draftId: number }, thunk) => {
    thunk.dispatch(removeDraft(data.draftId));
    return data.draftId;
  }
);

/* ************************************************** REDUCER ******************************************************* */

export const DocumentSlice = createSlice({
  name: 'documents',
  initialState: initialState,
  reducers: {
    addNewItemToDocument: (state, action: PayloadAction<any>) => {
      state.documents = state.documents.map((document: IDocument) => {
        if (document.id === action.payload.draftId) {
          document.populateFormData.items?.push(action.payload.item);
        }
        return document;
      });
    },
    updateColumnForGrid: (state) => {
      state.shouldUpdateColumnConfig = !state.shouldUpdateColumnConfig;
    },
    onRowDragEnd: (
      state,
      action: PayloadAction<{
        draftId: any;
        fromIndex: number;
        toIndex: number;
      }>
    ) => {
      state.documents = state.documents.map((document: IDocument) => {
        if (document.id === action.payload.draftId) {
          let itemsCopy = [...(document.populateFormData.items ?? [])];
          const removedItem = itemsCopy.splice(action.payload.fromIndex, 1)[0];
          itemsCopy.splice(action.payload.toIndex, 0, removedItem);
          itemsCopy.forEach((item, index) => {
            if (item) {
              item.lineNumber = index + 1;
            }
          });
          document.populateFormData.items = itemsCopy.sort(
            (item1: any, item2: any) => item1.lineNumber - item2.lineNumber
          );
        }
        return document;
      });
    },
    deleteItem: (state, action) => {
      state.documents = state.documents.map((document: IDocument) => {
        if (document.id === action.payload.draftId) {
          let itemsCopy = [...(document.populateFormData.items ?? [])];
          itemsCopy.splice(action.payload.rowIndex, 1);
          itemsCopy.forEach((item, index) => {
            if (item) {
              item.lineNumber = index + 1;
            }
          });
          if (Utility.isEmptyObject(itemsCopy)) {
            document.populateFormData.reservedStock = false;
          }
          const sortedItems = itemsCopy.sort(
            (item1: any, item2: any) => item1.lineNumber - item2.lineNumber
          );

          // Handle memo update for UK org
          if (getTenantTaxSystem() === TAX_SYSTEM.UK) {
            const updatedMemoObj = getUpdatedMemoOnProductDelete(
              sortedItems,
              document.populateFormData?.memo || '',
              document.populateFormData?.previousMemo || ''
            );
            document.populateFormData = {
              ...document.populateFormData,
              ...updatedMemoObj
            };
          }

          // Handle shipByDateUpdate from expectedDeliveryDate
          const documentType = document.populateFormData.documentType;
          if (
            Utility.isMRPWithURLCheck() &&
            (documentType === DOC_TYPE.BILL || documentType === DOC_TYPE.ORDER)
          ) {
            const shipByDate = DateFormatService.getDateFromStr(
              document.populateFormData.fulfillmentDate,
              BOOKS_DATE_FORMAT['DD-MM-YYYY']
            );
            const documentDate = DateFormatService.getDateFromStr(
              document.populateFormData.documentDate,
              BOOKS_DATE_FORMAT['DD-MM-YYYY']
            );
            let expectedDeliveryDtForRow =
              document.populateFormData?.items?.[action.payload.rowIndex]
                ?.expectedDeliveryDt;
            if (typeof expectedDeliveryDtForRow === 'number') {
              expectedDeliveryDtForRow = new Date(expectedDeliveryDtForRow);
            }
            let updatedShipByDate: Date;
            if (
              shipByDate?.getTime() ===
              (expectedDeliveryDtForRow as Date)?.getTime()
            ) {
              updatedShipByDate = getExpectedDeliveryDateFromItems(
                documentDate,
                sortedItems,
                documentDate
              ) as Date;
            } else {
              updatedShipByDate = getExpectedDeliveryDateFromItems(
                shipByDate,
                sortedItems,
                documentDate
              ) as Date;
            }
            if (updatedShipByDate) {
              if (typeof updatedShipByDate === 'number') {
                updatedShipByDate = new Date(updatedShipByDate);
              }
              document.populateFormData = {
                ...document.populateFormData,
                [DOCUMENT_KEYS.FULFILLMENT_DATE]:
                  DateFormatService.getDateStrFromDate(
                    updatedShipByDate,
                    BOOKS_DATE_FORMAT['DD-MM-YYYY']
                  )
              };
            }
          }

          // Update items array
          document.populateFormData.isDocumentTouched = true;
          document.populateFormData.items = !Utility.isEmpty(sortedItems)
            ? [...sortedItems]
            : [];
        }

        return document;
      });
    },
    clearItems: (state, action) => {
      state.documents = state.documents.map((document: IDocument) => {
        if (document.id === action.payload.draftId) {
          document.populateFormData.items = [];
          document.populateFormData.reservedStock = false;
          // Handle memo update for UK org
          if (getTenantTaxSystem() === TAX_SYSTEM.UK) {
            document.populateFormData.memo = '';
            document.populateFormData.previousMemo = '';
          }

          // Handle shipByDateUpdate from expectedDeliveryDate
          const documentType = document.populateFormData.documentType;
          if (
            Utility.isMRPWithURLCheck() &&
            (documentType === DOC_TYPE.BILL || documentType === DOC_TYPE.ORDER)
          ) {
            document.populateFormData = {
              ...document.populateFormData,
              [DOCUMENT_KEYS.FULFILLMENT_DATE]:
                document.populateFormData?.documentDate
            };
          }
        }

        return document;
      });
    },
    updateDocOnContactChange: (state, action) => {
      state.documents = state.documents.map((document: IDocument) => {
        if (document.id === action.payload.draftId) {
          document.populateFormData = {
            ...document.populateFormData,
            ...action.payload.updatedObj
          };
        }
        return document;
      });
    },
    updateMultipleKeysInDocument: (
      state,
      action: PayloadAction<{ draftId: number; keysToUpdate: any }>
    ) => {
      // action.payload.keysToUpdate contains the keys/values to be pushed in document
      state.documents = state.documents.map((document: IDocument) => {
        const keys = Object.keys(action.payload.keysToUpdate);
        if (document.id === action.payload.draftId) {
          document.populateFormData = {
            ...document.populateFormData,
            ...action.payload.keysToUpdate
          };
          let shouldCalculateTotal = false;
          let isManualRoundOff = false;
          keys.forEach((key: any) => {
            if (
              [
                DOCUMENT_KEYS.ADDITIONAL_CHARGES,
                DOCUMENT_KEYS.ITEMS,
                DOCUMENT_KEYS.TCS_PERCENTAGE,
                DOCUMENT_KEYS.EXCHANGE_RATE,
                DOCUMENT_KEYS.ROUND_OFF_AMOUNT_IN_DOCUMENT_CURRENCY
              ].includes(key)
            ) {
              shouldCalculateTotal = true;
            }
            if (key === DOCUMENT_KEYS.ROUND_OFF_AMOUNT_IN_DOCUMENT_CURRENCY) {
              isManualRoundOff = true;
            }
          });
          if (shouldCalculateTotal) {
            document.populateFormData = {
              ...document.populateFormData,
              ...calculateTotalOnDocDataChange({
                additionalCharges:
                  document.populateFormData[DOCUMENT_KEYS.ADDITIONAL_CHARGES],
                items: document.populateFormData[DOCUMENT_KEYS.ITEMS],
                knockoffInfo:
                  document.populateFormData[DOCUMENT_KEYS.KNOCK_OFF_INFO],
                tdsInfo: document.populateFormData[DOCUMENT_KEYS.TDS_INFO],
                tcsPercentage:
                  document.populateFormData[DOCUMENT_KEYS.TCS_PERCENTAGE],
                gstType: document.populateFormData[DOCUMENT_KEYS.GST_TYPE],
                roundOffAmountInDocumentCurrency:
                  document.populateFormData[
                    DOCUMENT_KEYS.ROUND_OFF_AMOUNT_IN_DOCUMENT_CURRENCY
                  ],
                isManualRoundOff
              })
            };
          }
        }
        return document;
      });
    },
    calculateTaxesAndAmountsForAllLineItems: (state, action) => {
      // Update taxes and amount of all line amounts when contact, exchange rate etc. are changed
      state.documents = state.documents.map((document: IDocument) => {
        if (document.id === action.payload.draftId) {
          let items: any[] = [];
          if (!Utility.isEmpty(action.payload?.items)) {
            items = [...action.payload?.items];
          } else {
            items = document?.populateFormData?.items
              ? [...document.populateFormData.items]
              : [];
          }
          items = items.map((item) => {
            return calculateLineItemTaxesAndAmount(
              { ...item },
              document?.populateFormData?.documentType,
              document?.populateFormData?.contact,
              document?.populateFormData?.gstType,
              document?.populateFormData?.applyRcmCheck
            );
          });
          document.populateFormData.items = items;
        }
        document.populateFormData = {
          ...document.populateFormData,
          ...calculateTotalOnDocDataChange({
            additionalCharges:
              document.populateFormData[DOCUMENT_KEYS.ADDITIONAL_CHARGES],
            items: document.populateFormData[DOCUMENT_KEYS.ITEMS],
            knockoffInfo:
              document.populateFormData[DOCUMENT_KEYS.KNOCK_OFF_INFO],
            tdsInfo: document.populateFormData[DOCUMENT_KEYS.TDS_INFO],
            tcsPercentage:
              document.populateFormData[DOCUMENT_KEYS.TCS_PERCENTAGE],
            gstType: document.populateFormData[DOCUMENT_KEYS.GST_TYPE],
            roundOffAmountInDocumentCurrency:
              document.populateFormData[
                DOCUMENT_KEYS.ROUND_OFF_AMOUNT_IN_DOCUMENT_CURRENCY
              ]
          })
        };
        return document;
      });
    },
    setIsDocumentTouched: (
      state,
      action: PayloadAction<{ draftId: number; isDocumentTouched: boolean }>
    ) => {
      state.documents = state.documents.map((draftDoc) => {
        if (draftDoc.id === action.payload.draftId) {
          draftDoc.populateFormData = {
            ...draftDoc.populateFormData,
            [DOCUMENT_KEYS.IS_DOC_TOUCHED]: action.payload.isDocumentTouched
          };
        }

        return draftDoc;
      });
    },
    setDraftMetaDataKeys: (
      state,
      action: PayloadAction<{
        draftId: number;
        keysToUpdate: { [k in keyof IDocument]?: IDocument[k] };
      }>
    ) => {
      state.documents = state.documents.map((draftDoc) => {
        if (draftDoc.id === action.payload.draftId) {
          draftDoc = {
            ...draftDoc,
            ...action.payload.keysToUpdate
          };
        }

        return draftDoc;
      });
    },
    setDocColumns: (state, action) => {
      state.columns = action.payload;
    },
    clearAmortizationDetails: (
      state,
      action: PayloadAction<{ draftId: number; rowIndex: number }>
    ) => {
      state.documents = state.documents.map((draftDoc) => {
        if (draftDoc.id === action.payload.draftId) {
          const copyOfItems = draftDoc.populateFormData.items
            ? [...draftDoc.populateFormData.items]
            : [];
          const indexToDeleteFrom = action.payload.rowIndex;
          copyOfItems[indexToDeleteFrom] = {
            ...copyOfItems[indexToDeleteFrom],
            [DOC_LINE_ITEM_KEYS.AMORTIZATION_DOC_ITEM_DETAILS]: null,
            [DOC_LINE_ITEM_KEYS.AMORTIZATION_TEMPLATE]: null,
            [DOC_LINE_ITEM_KEYS.AMORTIZATION_START_DATE]: null,
            [DOC_LINE_ITEM_KEYS.AMORTIZATION_END_DATE]: null
          };
          draftDoc.populateFormData = {
            ...draftDoc.populateFormData,
            [DOCUMENT_KEYS.ITEMS]: copyOfItems
          };
        }
        return draftDoc;
      });
    },
    addCopyOfRow: (
      state,
      action: PayloadAction<{ rowIndex: number; draftId: number }>
    ) => {
      state.documents = state.documents.map((draftDoc) => {
        if (draftDoc.id === action.payload.draftId) {
          const rowIndex = action.payload.rowIndex;
          let copyOfItems = draftDoc.populateFormData.items
            ? [...draftDoc.populateFormData.items]
            : [];
          let copiedItem = {
            ...copyOfItems[rowIndex],
            id: undefined,
            reservedQuantitiesData: null
          };

          copyOfItems.splice(rowIndex + 1, 0, copiedItem);
          copyOfItems = copyOfItems.map((item: any, index: number) => {
            return {
              ...item,
              lineNumber: index + 1
            };
          });

          draftDoc.populateFormData = {
            ...draftDoc.populateFormData,
            [DOCUMENT_KEYS.ITEMS]: copyOfItems,
            [DOCUMENT_KEYS.IS_DOC_TOUCHED]: true
          };
        }
        return draftDoc;
      });
    },
    insertNewRow: (
      state,
      action: PayloadAction<{ rowIndex: number; draftId: number; newRow: any }>
    ) => {
      state.documents = state.documents.map((draftDoc) => {
        if (draftDoc.id === action.payload.draftId) {
          const rowIndex = action.payload.rowIndex;
          let copyOfItems = draftDoc.populateFormData.items
            ? [...draftDoc.populateFormData.items]
            : [];

          copyOfItems.splice(rowIndex + 1, 0, action.payload.newRow);
          copyOfItems = copyOfItems.map((item: any, index: number) => {
            return {
              ...item,
              lineNumber: index + 1
            };
          });

          draftDoc.populateFormData = {
            ...draftDoc.populateFormData,
            [DOCUMENT_KEYS.ITEMS]: copyOfItems,
            [DOCUMENT_KEYS.IS_DOC_TOUCHED]: true
          };
        }
        return draftDoc;
      });
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        createBlankDocument.fulfilled,
        (state, action: PayloadAction<IDocument>) => {
          if (!DRAFT_DOC_SUPPORTED_MODULE_LABELS.includes(action.payload?.type))
            return;

          const newDraftDoc = getParsedDocumentDataToDisplay(action.payload);
          /* Utility to get default form state, or modify existing state, based on type */
          state.documents = (state.documents || []).concat(newDraftDoc);
        }
      )
      .addCase(
        onDocumentViewChanged.fulfilled,
        (
          state,
          action: PayloadAction<{
            draftId: number;
            isMaximized: boolean;
          }>
        ) => {
          state.documents = state.documents.map((draftDoc) => {
            if (draftDoc.id !== action.payload.draftId) return draftDoc;

            return { ...draftDoc, isMaximized: action.payload.isMaximized };
          });
        }
      )
      .addCase(onDocumentFormFieldUpdate.fulfilled, (state, action) => {
        state.documents = state.documents.map((draftDoc) => {
          if (draftDoc.id === action.payload.draftId) {
            draftDoc.populateFormData = {
              ...draftDoc.populateFormData,
              [action.payload.dataKey]: action.payload.value
            };
          }

          return draftDoc;
        });
      })
      .addCase(
        onDocumentClose.fulfilled,
        (state, action: PayloadAction<number>) => {
          state.documents = (state.documents || []).filter(
            (draftDoc) => draftDoc.id !== action.payload
          );
        }
      );
  }
});

export const {
  addNewItemToDocument,
  updateColumnForGrid,
  onRowDragEnd,
  deleteItem,
  clearItems,
  clearAmortizationDetails,
  setDocColumns,
  updateDocOnContactChange,
  updateMultipleKeysInDocument,
  calculateTaxesAndAmountsForAllLineItems,
  setIsDocumentTouched,
  setDraftMetaDataKeys,
  addCopyOfRow,
  insertNewRow
} = DocumentSlice.actions;

/****************************************************SELECTORS**************************************************** */

const selfSelector = (rootState: RootState) => rootState.documentsData;

export const selectDocuments = (state: RootState) =>
  state.documentsData.documents;

export const selectDocumentDraftContextData = createSelector(
  selfSelector,
  (state) =>
    state.documents.map((document) => ({
      id: document.id,
      draftType: document.draftType,
      isCashInvoice: document.isCashInvoice,
      isMaximized: document.isMaximized
    }))
);

export const selectDocumentById = (draftId: number) =>
  createSelector(selfSelector, (state) =>
    findDocumentById(state.documents, draftId)
  );

/**
 * @description:
 * Selects draft metaData such as: draftType, populateFormData etc.
 */
export const selectDocumentMetaDataByKey = (
  draftId: number,
  dataKey: keyof IDocument
) =>
  createSelector(selfSelector, (state) => {
    const draftDoc = findDocumentById(state.documents, draftId);
    return draftDoc?.[dataKey];
  });

/**
 * @description:
 * Selects multiple metaData keys from single draftDoc such as: draftType, populateFormData etc.
 */
export const selectDocumentMetaDataByKeys = (
  draftId: number,
  dataKeys: (keyof IDocument)[]
) =>
  createSelector(selfSelector, (state: IDocumentState) => {
    const draftDoc = findDocumentById(state.documents, draftId);
    return draftDoc ? dataKeys.map((dataKey) => draftDoc[dataKey]) : [];
  });

/**
 * @description:
 * Selects draft formData such as: contact, documentDate, lineItems etc.
 * @param: draftId & formData key
 */
export const selectDocumentFormDataByKey = (
  draftId: number,
  dataKey: keyof AnyDocument | (keyof AnyDocument)[]
) =>
  createSelector(selfSelector, (state) => {
    const selectedDoc = findDocumentById(state.documents, draftId);
    const formData = selectedDoc?.populateFormData;

    if (Array.isArray(dataKey)) {
      return formData ? dataKey.map((dataKey) => formData[dataKey]) : [];
    } else {
      return formData?.[dataKey];
    }
  });

/**
 * @description:
 * Selects multiple properties at once from draft formData
 * such as: contact, documentDate, lineItems etc.
 * @param: draftId & formData keys list
 */
export const selectDocumentFormDataByKeys = (
  draftId: number,
  dataKeys: (keyof AnyDocument)[]
) =>
  createSelector(selfSelector, (state: IDocumentState) => {
    const selectedDoc = findDocumentById(state.documents, draftId);
    const formData = selectedDoc?.populateFormData;

    return formData ? dataKeys.map((dataKey) => formData[dataKey]) : [];
  });
export const selectShouldUpdateColumnConfig = () =>
  createSelector(selfSelector, (state) => state.shouldUpdateColumnConfig);

/**
 * @description:
 * Selects multiple properties at once from draft formData
 * such as: contact, documentDate, lineItems etc.
 * @param: draftId & formData keys list
 */
export const selectCurrentActiveDocument = (draftId: number) =>
  createSelector(selfSelector, (state: IDocumentState) => {
    const selectedDoc = findDocumentById(state.documents, draftId);
    const formData = selectedDoc?.populateFormData;

    return formData;
  });

export const selectColumns = () =>
  createSelector(selfSelector, (state) => state.columns);

export default DocumentSlice.reducer;
