export interface paymentItemDtoList {
  description?: any;
  documentCode?: any;
  accountName?: any;
  documentType?: any;
  exchangeRate?: any;
  currency?: any;
  paymentAmount?: any;
  taxAmount?: any;
  taxCode?: any;
  taxMappingDtoList?: any;
  customField?: any;
  isTdsApplicableContact?: any;
  isTdsApplicableAccount?: any;
  isTdsApplicableProduct?: any;
  tdsInfoIndia?: any;
  taxList?: any;
}

export interface RecievePaymentFeeDTO {
  accountCode?: string;
  amount?: string;
}

export interface ExpenseDepositeModel {
  accountName?: any;
  amount?: any;
  contactCode?: any;
  contactName?: any;
  currency?: any;
  documentDate?: any;
  exchangeRate?: any;
  memo?: any;
  paymentType?: any;
  recurringActivated?: any;
  recurringPayment?: any;
  referenceDate?: any;
  referenceNumber?: any;
  customField?: any;
  gstExchangeRate?: any;
  accountCodePayTo?: any;
  accountCodePayFrom?: any;
  receivePaymentItemDtoList?: paymentItemDtoList[];
  makePaymentItemDtoList?: paymentItemDtoList[];
  attachmentIds?: any;
  receivePaymentFeeDtoList?: RecievePaymentFeeDTO[];
  interCompany?: boolean;
  applyRcmCheck?: boolean;
}

export const expenseModel: ExpenseDepositeModel = {
  accountCodePayTo: null,
  receivePaymentItemDtoList: undefined,
  gstExchangeRate: 1,
  exchangeRate: 1,
  interCompany: false,
  customField: [],
  applyRcmCheck: false,
};

export const depositModel: ExpenseDepositeModel = {
  accountCodePayFrom: null,
  makePaymentItemDtoList: undefined,
  gstExchangeRate: 1,
  exchangeRate: 1,
  interCompany: false
};
