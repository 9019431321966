import { INPUT_TYPE, showAlert } from 'deskera-ui-library';
import {
  DOC_TYPE,
  DOCUMENT_MODE,
  MODULE_NAME_FOR_STORAGE_UTILITY,
  TAX_SYSTEM,
  UOM_NA_ID
} from '../../../../Constants/Constant';
import { AnyDocument } from '../../../../Models/Drafts';
import { calculateTaxesAndAmountsForAllLineItems } from '../../../../Redux/Slices/DocumentSlice';
import { fetchPriceBookList } from '../../../../Redux/Slices/PriceBookSlice';
import { Store } from '../../../../Redux/Store';
import AuthService from '../../../../Services/Auth';
import { localizedText } from '../../../../Services/Localization/Localization';
import PriceListService from '../../../../Services/PriceList';
import {
  getMainModuleName,
  getTenantTaxSystem,
  getUomPrice
} from '../../../../SharedComponents/DocumentForm/NewDocumentHelper';
import Utility, { deepClone } from '../../../../Utility/Utility';
import { isPriceListEnabled } from '../../Utilities/DocCommonUtils';
import { getDocumentByIDFromStore } from '../DocumentHelper';
import { calculateTaxesForUS } from '../DocRowHelper';

export enum CHANGE_TYPE {
  CONTACT_CHANGED = 'CONTACT_CHANGED',
  CONTACT_ADDRESS_CHANGED = 'CONTACT_ADDRESS_CHANGED',
  DOC_DATE_CHANGED = 'DOC_DATE_CHANGED',
  EXCHANGE_RATE_CHANGED = 'EXCHANGE_RATE_CHANGED',
  PRODUCT_CHANGED = 'PRODUCT_CHANGED',
  QUANTITY_CHANGED = 'QUANTITY_CHANGED',
  UOM_CHANGED = 'UOM_CHANGED',
  PRICE_BOOK_CHANGED = 'PRICE_BOOK_CHANGED',
  PRODUCTS_ADDED_FROM_PRODUCT_GROUP_POPUP = 'PRODUCTS_ADDED_FROM_PRODUCT_GROUP_POPUP',
  PRODUCTS_ADDED_FROM_BARCODE_POPUP = 'PRODUCTS_ADDED_FROM_BARCODE_POPUP'
}
// TODO: pricing API is alco called when products are selected from grouping popup

type ChangePayload = {
  type: CHANGE_TYPE | null | undefined;
  rowIndex?: number | null;
  updateFromIndex?: number | null; // Only use with product grouping or basrcode popup selections
};

/**
 * Call price list API based on CHANGE_TYPE
 * and apply prices accordingly
 */
export const updatePriceFromPriceList = (data: {
  draftId: number;
  documentMode: DOCUMENT_MODE;
  change: ChangePayload;
  contactColumnConfig?: any[];
}) => {
  const { draftId, documentMode, change, contactColumnConfig } = data;
  const { type: changeType, rowIndex, updateFromIndex } = change;
  const tempDocument = getDocumentByIDFromStore(draftId)
    ?.populateFormData as AnyDocument;
  const documentType = tempDocument?.documentType;
  const tenantInfo = AuthService.currentTenantInfo;

  const processUpdatePricingValue = (
    productsToUpdate: any[],
    lineItems: any[],
    exchangeRate: number,
    priceListResponse: any,
    updateExistingItem: boolean,
    shippingInfo: any
  ) => {
    let itemsToUpdate: any[] = [];
    productsToUpdate.forEach((productToUpdate) => {
      let itemToUpdate: any = {};
      if (updateExistingItem) {
        itemToUpdate = lineItems.find(
          (item: any) =>
            productToUpdate.productId ===
              (item.productCode || item?.product?.productId) &&
            productToUpdate.quantity === +item.productQuantity &&
            productToUpdate.uomId === item.documentUom &&
            (!itemsToUpdate.length ||
              itemsToUpdate.findIndex(
                (x) => x.lineNumber === item.lineNumber
              ) === -1)
        );
      } else {
        itemToUpdate = lineItems.find(
          (item: any) =>
            productToUpdate.productId ===
              (item.productCode || item?.product?.productId) &&
            productToUpdate.quantity === +item.productQuantity &&
            productToUpdate.uomId === item.documentUom &&
            rowIndex === item.lineNumber - 1
        );
      }

      if (!Utility.isEmpty(itemToUpdate)) {
        itemsToUpdate.push(itemToUpdate);
      }
    });

    itemsToUpdate = itemsToUpdate.map((item, index) => {
      if (
        // productsToUpdate.includes(item.product.productId) &&
        updateExistingItem ||
        (!updateExistingItem && rowIndex === item.lineNumber - 1)
      ) {
        const priceIndexInPriceList = priceListResponse.findIndex(
          (resp: any) =>
            resp.productId === (item.productCode || item?.product?.productId) &&
            resp.quantity === +item.productQuantity &&
            resp.uomId === item.documentUom &&
            !!resp?.price
        );

        const priceInPriceList =
          priceIndexInPriceList !== -1
            ? priceListResponse[priceIndexInPriceList].price
            : 0;
        const isPricePresentInPriceList = priceIndexInPriceList !== -1;

        let productData = item.product ? item.product : undefined;
        let unitPrice = 0;

        const isUnitPriceValid = item.unitPrice !== undefined;

        const isDocDateOrContactOrExchangeRateUpdatedManually =
          changeType === CHANGE_TYPE.DOC_DATE_CHANGED ||
          changeType === CHANGE_TYPE.CONTACT_CHANGED ||
          changeType === CHANGE_TYPE.QUANTITY_CHANGED ||
          changeType === CHANGE_TYPE.EXCHANGE_RATE_CHANGED;

        const isContactAddressChanged =
          tenantInfo?.isAdvancePriceListEnabled &&
          changeType === CHANGE_TYPE.CONTACT_ADDRESS_CHANGED;

        const isPriceBookChanged =
          tenantInfo?.isAdvancePriceListEnabled &&
          changeType === CHANGE_TYPE.PRICE_BOOK_CHANGED;

        const isSellUtilityDocument =
          getMainModuleName(documentType) ===
          MODULE_NAME_FOR_STORAGE_UTILITY.SELL;
        const isBuyUtilityDocument =
          getMainModuleName(documentType) ===
          MODULE_NAME_FOR_STORAGE_UTILITY.BUY;

        const isSalesPriceZero =
          isSellUtilityDocument && productData.salesPrice === 0;
        const isPurchasePriceZero =
          isBuyUtilityDocument && productData.purchasePrice === 0;

        const isCurrentIndexValid =
          typeof rowIndex === 'number' &&
          rowIndex >= 0 &&
          rowIndex !== item.lineNumber - 1;

        let docPriceIndexInPriceList: number = -1;
        // Set discount and discountInPercent, if PriceBook is enabled
        if (tenantInfo?.isAdvancePriceListEnabled) {
          docPriceIndexInPriceList = priceListResponse.findIndex(
            (resp: any) =>
              resp.productId === item.productCode &&
              resp.quantity === +item.productQuantity &&
              resp.uomId === item.documentUom
          );
          if (docPriceIndexInPriceList !== -1) {
            item = {
              ...item,
              discount:
                priceListResponse?.[docPriceIndexInPriceList]?.discount ?? 0,
              discountInPercent:
                priceListResponse?.[docPriceIndexInPriceList]
                  ?.discountInPercent ?? false
            };
          }
        }

        if (isPricePresentInPriceList) {
          unitPrice = priceInPriceList;
        } else if (
          isUnitPriceValid &&
          (isDocDateOrContactOrExchangeRateUpdatedManually ||
            isContactAddressChanged ||
            isPriceBookChanged ||
            isSalesPriceZero ||
            isPurchasePriceZero ||
            isCurrentIndexValid)
        ) {
          // when contact/document date changed
          // when line item is not updated (uom, qty, etc) and no price in price list
          if (
            tenantInfo.isAdvancePriceListEnabled &&
            (changeType === CHANGE_TYPE.DOC_DATE_CHANGED ||
              changeType === CHANGE_TYPE.CONTACT_CHANGED ||
              changeType === CHANGE_TYPE.QUANTITY_CHANGED ||
              changeType === CHANGE_TYPE.EXCHANGE_RATE_CHANGED ||
              changeType === CHANGE_TYPE.CONTACT_ADDRESS_CHANGED)
          ) {
            unitPrice =
              priceListResponse?.[docPriceIndexInPriceList]?.price ?? 0;
          } else {
            unitPrice = item.unitPrice;
          }
        } else {
          unitPrice =
            (getMainModuleName(documentType) ===
            MODULE_NAME_FOR_STORAGE_UTILITY.SELL
              ? productData.salesPrice
              : productData.purchasePrice) || 0;
          unitPrice = getUomPrice(unitPrice, item.documentUOMSchemaDefinition);
        }

        if (isPricePresentInPriceList) {
          // Below line is applying exchangeRate multiple time
          // unitPrice = unitPrice ? unitPrice * exchangeRate : 0;
          // } else {
          if (unitPrice < 0) {
            unitPrice = item.unitPrice;
          }
        }

        if (
          documentMode === DOCUMENT_MODE.NEW &&
          (item.isPartialInvoice || item.isPartialBill) &&
          item.product.stockUom === UOM_NA_ID
        ) {
          unitPrice = item.pendingAmount; // Need to check pending amount usage
        }

        if (typeof unitPrice !== 'undefined' && unitPrice !== null) {
          item = {
            ...item,
            unitPrice: unitPrice || item.unitPrice,
            uomUnitPrice: unitPrice
          };
        }
      }
      return item;
    });

    itemsToUpdate.forEach((item) => {
      // Remove 'unitPrice' from invalidFields if unit price is not 0
      const unitPriceIndexInInvalidFieldsArr = item.invalidFields?.findIndex(
        (field: string) => field === 'unitPrice'
      );
      if (item.unitPrice !== 0 && unitPriceIndexInInvalidFieldsArr !== -1) {
        item.invalidFields?.splice(unitPriceIndexInInvalidFieldsArr, 1);
      }

      const index = lineItems.findIndex(
        (x) => x.lineNumber === item.lineNumber
      );
      if (index !== -1) {
        lineItems[index] = item;
      }
    });

    // Calculate taxes based on updated line items
    if (getTenantTaxSystem() === TAX_SYSTEM.US) {
      calculateTaxesForUS({
        draftId,
        indexToUpdate: undefined,
        updatedItems: lineItems
      });
    } else {
      Store.dispatch(
        calculateTaxesAndAmountsForAllLineItems({ draftId, items: lineItems })
      );
    }
  };

  const showPriceListAlert = (
    productsPayload: any[],
    lineItems: any[],
    exchangeRate: number,
    priceListResponse: any,
    updateExistingItem: boolean,
    shippingInfo: any,
    docField: any
  ) => {
    const alertButtonConfig = [
      {
        title: "Don't update",
        className: 'bg-gray2 border-m ',
        onClick: () => {
          // If exchange rate is changed manually and user clicks don't update
          // then calculate taxes again to apply exchage rate converion without price list prices
          if (change.type === CHANGE_TYPE.EXCHANGE_RATE_CHANGED) {
            if (getTenantTaxSystem() === TAX_SYSTEM.US) {
              calculateTaxesForUS({ draftId, items: lineItems });
            } else {
              Store.dispatch(
                calculateTaxesAndAmountsForAllLineItems({ draftId })
              );
            }
          }
        }
      },
      {
        title: 'Update',
        className: 'bg-button text-white ml-r',
        onClick: () => {
          processUpdatePricingValue(
            productsPayload,
            [...lineItems],
            exchangeRate,
            priceListResponse,
            updateExistingItem,
            shippingInfo
          );
        }
      }
    ];

    showAlert(
      'Update Unit price',
      `Do you want to update all unit prices to follow the Price List of the new ${docField} date?`,
      alertButtonConfig
    );
  };

  // Get contact custom field from contact column config
  const getContactCustomFields = (): any[] => {
    const contactCustomFields = contactColumnConfig?.filter((column: any) => {
      return !column.systemField && column.type === INPUT_TYPE.SELECT;
    });
    return deepClone(contactCustomFields ?? []);
  };

  const getPricing = async (
    productsPayload: any[],
    lineItems: any[],
    showPriceUpdateAlert: boolean,
    currencyCode: string,
    updateExistingItem = true,
    exchangeRate?: number,
    shippingInfo?: any,
    returnResponse?: boolean
  ) => {
    const priceListEnabled = isPriceListEnabled();
    const priceBookEnabled = tenantInfo.isAdvancePriceListEnabled;
    const {
      billTo,
      contact,
      documentDate,
      documentType,
      shipFrom,
      shipTo,
      priceListId
    } = tempDocument;
    if (
      productsPayload.length &&
      currencyCode &&
      documentDate &&
      (priceListEnabled || priceBookEnabled)
    ) {
      // Removed check for empty contact to resolve ZEN-10989
      const mainModuleType = getMainModuleName(documentType);
      let payload: any = {
        contactCode: contact?.code ? contact?.code : '',
        priceListPricingItems: productsPayload,
        type: mainModuleType,
        currency: currencyCode,
        documentDate: documentDate,
        exchangeRate: exchangeRate
      };
      // Modify the payload if price book is enabled
      if (priceBookEnabled) {
        const savedCustomFields: any[] = [];
        // Get contacts select custom fields from contact's column config
        let contactSelectTypeCustomFields = getContactCustomFields();
        contactSelectTypeCustomFields?.forEach((cfColumn: any) => {
          // contacts cf attached in contact object withing Books
          const contactCFsInBooks = contact?.customField ?? [];
          const cfIdFromContactColConfig =
            cfColumn?.columnCode?.split('_')?.[2];
          let cfValue = '';
          if (contactCFsInBooks?.length > 0) {
            const filteredContactCFInBooks = contactCFsInBooks?.find(
              (cf: any) => cf.id === +cfIdFromContactColConfig
            );
            if (!Utility.isEmptyObject(filteredContactCFInBooks)) {
              cfValue = filteredContactCFInBooks?.value;
            }
          }
          savedCustomFields.push({
            code: cfColumn?.columnCode,
            value: cfValue
          });
        });
        let billingAddress: any;
        let shippingAddress: any;
        if (mainModuleType === MODULE_NAME_FOR_STORAGE_UTILITY.SELL) {
          billingAddress = billTo;
          shippingAddress = shipTo;
        } else if (mainModuleType === MODULE_NAME_FOR_STORAGE_UTILITY.BUY) {
          billingAddress = shipFrom;
          shippingAddress = shipFrom;
        }
        payload = {
          ...payload,
          isAdvance: true,
          priceListId: priceListId ?? 0,
          contact: contact
            ? {
                billingAddress: [billingAddress],
                shippingAddress: [shippingAddress],
                customField: savedCustomFields
              }
            : null
        };
      }

      try {
        const priceListResponse = await PriceListService.getPricesFromPriceList(
          payload
        );
        if (returnResponse) {
          return priceListResponse;
        }
        // check and do not show alert if no multiple prices in updated product
        let isPriceListForUpdatedProduct = true;
        if (rowIndex !== null && rowIndex !== undefined) {
          let product = lineItems[rowIndex];
          if (product) {
            let priceListForProduct = priceListResponse.find(
              (item: any) => item.productId === product.productCode
            );
            const priceToCompare = product.unitPrice;
            if (
              priceListForProduct &&
              priceListForProduct?.priceListShortInfos?.length === 0 &&
              (priceListForProduct?.price === priceToCompare ||
                priceListForProduct?.price === 0)
            ) {
              isPriceListForUpdatedProduct = false;
            }
          }
        }
        if (
          showPriceUpdateAlert &&
          isPriceListForUpdatedProduct &&
          !Utility.isEmpty(changeType) &&
          (changeType === CHANGE_TYPE.CONTACT_CHANGED ||
            changeType === CHANGE_TYPE.CONTACT_ADDRESS_CHANGED ||
            changeType === CHANGE_TYPE.DOC_DATE_CHANGED ||
            changeType === CHANGE_TYPE.QUANTITY_CHANGED ||
            changeType === CHANGE_TYPE.UOM_CHANGED ||
            changeType === CHANGE_TYPE.EXCHANGE_RATE_CHANGED ||
            changeType === CHANGE_TYPE.PRICE_BOOK_CHANGED)
        ) {
          let docField: any = 'invoice';
          if (documentType === DOC_TYPE.BILL) {
            docField = 'bill';
          } else if (
            documentType === DOC_TYPE.ORDER ||
            documentType === DOC_TYPE.SALES_ORDER
          ) {
            docField = 'order';
          } else if (documentType === DOC_TYPE.INVOICE) {
            docField = 'invoice';
          } else if (documentType === DOC_TYPE.QUOTE) {
            docField = localizedText('quote');
          }
          showPriceListAlert(
            productsPayload,
            [...lineItems],
            exchangeRate as number,
            priceListResponse,
            updateExistingItem && isPriceListForUpdatedProduct,
            shippingInfo,
            docField
          );
        } else {
          processUpdatePricingValue(
            productsPayload,
            [...lineItems],
            exchangeRate as number,
            priceListResponse,
            updateExistingItem && isPriceListForUpdatedProduct,
            shippingInfo
          );
        }
      } catch (err: any) {
        console.error('Error loading prices: ', err);
        if (
          err?.code === 404 &&
          err?.debugMessage === "Price book doesn't exist" &&
          priceBookEnabled
        ) {
          // re-fetch price book list
          let query = `isAdvance=true`;
          Store.dispatch(fetchPriceBookList({ search: '', limit: 500, query }));
        }
      }
    }
  };

  let productsPayload: any[] = [];
  switch (changeType) {
    case CHANGE_TYPE.PRODUCTS_ADDED_FROM_PRODUCT_GROUP_POPUP:
    case CHANGE_TYPE.PRODUCTS_ADDED_FROM_BARCODE_POPUP:
      if (typeof updateFromIndex === 'number') {
        const rowsForPricingPayload = tempDocument.items?.filter(
          (row: any, index: number) => index >= updateFromIndex
        );
        const productsPayload =
          rowsForPricingPayload?.map((row: any) => ({
            productId: row.product.productId,
            uomId: row.stockUom,
            quantity: row.productQuantity
          })) || [];
        const priceListResponse = getPricing(
          productsPayload,
          tempDocument.items || [],
          false,
          tempDocument.currency,
          false,
          tempDocument.exchangeRate,
          null,
          true
        );
        return priceListResponse;
      }
      break;
    case CHANGE_TYPE.PRODUCT_CHANGED:
      productsPayload =
        tempDocument.items
          ?.filter((item) => !Utility.isEmpty(item.product))
          .map((item, index: number) => {
            return {
              productId: item.product?.productId,
              uomId: item.documentUom,
              quantity: 1
            };
          }) || [];
      getPricing(
        productsPayload,
        tempDocument.items || [],
        true,
        tempDocument.currency,
        false,
        tempDocument.exchangeRate
      );
      break;

    case CHANGE_TYPE.QUANTITY_CHANGED:
      productsPayload =
        tempDocument.items
          ?.filter((item) => !Utility.isEmpty(item.product))
          .map((item) => {
            return {
              productId: item.product?.productId,
              uomId: item.documentUom,
              quantity: +item.productQuantity
            };
          }) || [];
      // TODO: handle firstAmountChangeDone to evaluate showPriceUpdateAlert
      getPricing(
        productsPayload,
        tempDocument.items || [],
        true, //TODO: evaluate showPriceUpdateAlert
        tempDocument.currency,
        false,
        tempDocument.exchangeRate
      );
      break;

    case CHANGE_TYPE.UOM_CHANGED:
      productsPayload =
        tempDocument.items
          ?.filter((item) => !Utility.isEmpty(item.product))
          .map((item) => {
            return {
              productId: item.product?.productId,
              uomId: item.documentUom,
              quantity: +item.productQuantity
            };
          }) || [];

      getPricing(
        productsPayload,
        tempDocument.items || [],
        true,
        tempDocument.currency,
        false,
        tempDocument.exchangeRate
      );
      break;
    case CHANGE_TYPE.CONTACT_CHANGED:
    case CHANGE_TYPE.CONTACT_ADDRESS_CHANGED:
      if (tempDocument.items?.length) {
        const productsPayload = tempDocument.items
          ?.filter((item) => !Utility.isEmpty(item.product))
          ?.map((item) => ({
            productId: item.product?.productId,
            uomId: item.documentUom,
            quantity: +item.productQuantity
          }));
        getPricing(
          productsPayload,
          tempDocument.items,
          true,
          tempDocument.currency,
          true,
          tempDocument.exchangeRate,
          { shipTo: tempDocument.shipTo, shipFrom: tempDocument.shipFrom }
        );
      }
      break;
    case CHANGE_TYPE.DOC_DATE_CHANGED:
    case CHANGE_TYPE.EXCHANGE_RATE_CHANGED:
    case CHANGE_TYPE.PRICE_BOOK_CHANGED:
      if (tempDocument.items?.length) {
        const productsPayload = tempDocument.items
          ?.filter((item) => !Utility.isEmpty(item.product))
          ?.map((item) => ({
            productId: item.product?.productId,
            uomId: item.documentUom,
            quantity: +item.productQuantity
          }));
        getPricing(
          productsPayload,
          tempDocument.items,
          true,
          tempDocument.currency,
          true,
          tempDocument.exchangeRate
        );
      }
      break;

    default:
      break;
  }
};
