import { createContext } from 'react';
import {
  DraftTypes,
  IDraftDocBuilderCommonProps
} from '../../../Models/Drafts';
import { DOCUMENT_MODE } from '../../../Constants/Constant';

export const CommonDraftPropsContext =
  createContext<IDraftDocBuilderCommonProps>({
    draftId: -1,
    draftType: DraftTypes.NEW,
    documentMode: DOCUMENT_MODE.NEW,
    isCashInvoice: false
  });
