import { PartialNullable } from './Interfaces';

export default interface ITable {
  _id?: string;
  name?: string;
  columnsMetaData?: IColumn[];
  default?: boolean;
}
export interface ITableOption {
  id: number | string;
  name: string;
  color?: string; // className
  systemField?: boolean;
}

export interface IColumn {
  id?: string;
  key?: string;
  name: string;
  type?: IColumnType;
  index?: number;
  options?: IColumnOption[];
  required?: boolean;
  width?: number;
  editable?: boolean;
  systemField?: boolean;
  columnCode: string;
  hidden?: boolean;
  uiVisible?: boolean;
  dataSource?: any;
  onSelect?: (obj: any) => void;
  renderer?: (obj: any) => JSX.Element;
  textAlign?: 'left' | 'right' | 'center';
  allowColumnEdit?: boolean;
  allowColumnSort?: boolean;
  formatter?: any;
  allowNegativeNumber?: boolean;
  allowFilter?: boolean;
  dropdownConfig?: PartialNullable<IColumnDropdownConfig>;
  allowAddOption?: boolean;
  freezed?: boolean;
  disableWebhook?: boolean;
}
type IColumnDropdownConfig = {
  style: { minWidth: number };
  className: string;
  title: string;
  allowSearch: boolean;
  searchableKey: string;
  canEdit: boolean;
  canDelete: boolean;
  data: any[];
  renderer: (index: any, value: any) => JSX.Element;
  searchApiConfig: {
    getUrl?: (searchValue: string) => string;
    dataParser?: (response: any) => any[];
    debounceTime?: number;
  };
  onSelect: Function;
  onClear: Function;
  button?: {
    title?: string;
    className?: string;
    onClick?: Function;
  } | null;
};
export interface IColumnOption {
  id?: any;
  name?: string;
  color?: string; //css class ex: bg-gray,
  systemField?: boolean;
  selectedInd?: number[];
  defaultOption?: boolean;
  status?: string;
}

export interface ITableFilter {
  logicalOperator: 'or' | 'and';
  conditions: IFilterCondition[];
}

export interface IFilterCondition {
  colId: string;
  opr: string;
  value: filterCondition;
}

export interface IFilterPopupCondition {
  key: string;
  condition: filterCondition;
  value: any;
}

export enum IColumnType {
  Text = 'text',
  Number = 'number',
  Select = 'select',
  Email = 'email',
  MultiSelect = 'multi-select',
  Date = 'date',
  Button = 'button',
  select = 'select',
  TEXT = 'text',
  PASSWORD = 'password',
  NUMBER = 'number',
  EMAIL = 'email',
  DATE = 'date',
  URL = 'url',
  PHONE = 'phone',
  SELECT = 'select',
  MULTI_SELECT = 'multi-select',
  CHECKBOX = 'checkbox',
  BUTTON = 'button',
  MORE = 'more',
  LONG_TEXT = 'long text',
  DROPDOWN = 'dropdown',
  FILE = 'file',
  ADDRESS = 'address',
  FORMULA = 'formula'
}

export enum DK_INPUT_TYPE {
  Text = 'text',
  Number = 'number',
  Select = 'select',
  Email = 'email',
  MultiSelect = 'multi-select',
  Date = 'date',
  Button = 'button',
  select = 'select',
  TEXT = 'text',
  PASSWORD = 'password',
  NUMBER = 'number',
  EMAIL = 'email',
  DATE = 'date',
  URL = 'url',
  PHONE = 'phone',
  SELECT = 'select',
  MULTI_SELECT = 'multi-select',
  CHECKBOX = 'checkbox',
  BUTTON = 'button',
  MORE = 'more',
  LONG_TEXT = 'long text',
  DROPDOWN = 'dropdown',
  FILE = 'file',
  ADDRESS = 'address',
  FORMULA = 'formula'
}
export type filterCondition =
  | 'is'
  | 'isnt'
  | 'in'
  | 'nin'
  | 'c'
  | 'nc'
  | 'sw'
  | 'ew'
  | 'eq'
  | 'neq'
  | 'emp'
  | 'nemp'
  | 'lt'
  | 'gt'
  | 'lte'
  | 'gte';

export interface AddRecordButtonConfig {
  title: string;
  icon?: string;
  className?: string;
  style?: any;
  onClick?: () => void;
}

export interface GridHeaderButtonConfig extends AddRecordButtonConfig {
  id?: string;
  isReverse?: boolean;
  hidden?: boolean;
  renderer?: () => JSX.Element;
}

export interface ColumnDeleteType {
  columnIndex: number;
  columnData: IColumn;
}

export interface RowUpdateType {
  columnKey: string;
  rowIndex: number;
  rowData: any;
}

export interface RowOpenType {
  rowIndex: number;
  rowData: any;
}
export type DataGridCallBackParam<T> = {
  columnKey: string;
  rowData: T;
  rowIndex: number;
  columnData: IColumn;
};

interface IRowBase {
  id?: string;
  invalidFields?: string[];
  allowRowEdit?: boolean;
  rowContextMenu?: { icon: any; title: any; onClick: (data: any) => void }[];
  rowButtons?: {
    icon: any;
    title: any;
    className: string;
    onClick: (data: any) => void;
  }[];
  expanded?: boolean;
  expandableView?: JSX.Element;
  nonEditableColumns?: string[];
  selected: boolean;
  [key: string]: any;
}

// Define the mapped type for IRow
export type IRow<K extends string> = IRowBase & {
  [key in K]: any;
};

export type IOnRowUpdate<R, Ck> = {
  rowIndex: number;
  rowData: R;
  columnKey: Ck;
  columnData: IColumn[];
};

export type IOnColumnSort = {
  columnData: IColumn;
  columnIndex: number;
  order: 'ASC' | 'DESC';
};
