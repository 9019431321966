import { DOC_TYPE, TEMPLATE_CATEGORY } from '../../../../Constants/Constant';
import { localizedText } from '../../../../Services/Localization/Localization';
import Utility from '../../../../Utility/Utility';

export default class DocActionsHelper {
  static getDirectFulfillmentTitle = (documentType: DOC_TYPE) => {
    switch (documentType) {
      case DOC_TYPE.INVOICE:
        return localizedText('Direct Fulfillment of Invoice');
      case DOC_TYPE.SALES_ORDER:
        return localizedText('Direct Fulfillment of Sales Order');
      case DOC_TYPE.QUOTE:
        return localizedText('Direct Fulfillment of Quotation');
      default:
        return '';
    }
  };
  static setPopupTitle = (docType: DOC_TYPE) => {
    switch (docType) {
      case DOC_TYPE.INVOICE:
        return 'Invoice';
      case DOC_TYPE.SALES_ORDER:
        return 'Sales Order';
      case DOC_TYPE.QUOTE:
        return Utility.isUSorg() ? 'Estimate' : 'Quotation';
      case DOC_TYPE.BILL:
        return 'Bill';
      case DOC_TYPE.ORDER:
        return 'Purchase Order';
      case DOC_TYPE.REQUISITION:
        return 'Requisition';
      case DOC_TYPE.JOB_WORK_OUT_ORDER:
        return 'Job Work Out';
      default:
        break;
    }
  };

  static getPrintDocTypeFromDocType = (docType: any) => {
    switch (docType) {
      case DOC_TYPE.INVOICE:
        return TEMPLATE_CATEGORY.INVOICE;
      case DOC_TYPE.SALES_ORDER:
        return TEMPLATE_CATEGORY.SALES_ORDER;
      case DOC_TYPE.EXPENSE_BILL:
      case DOC_TYPE.BILL:
        return TEMPLATE_CATEGORY.BILLS;
      case DOC_TYPE.ORDER:
        return TEMPLATE_CATEGORY.ORDER;
      case DOC_TYPE.QUOTE:
        return TEMPLATE_CATEGORY.QUOTE;
      default:
        break;
    }
  };
}
