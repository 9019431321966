
import React from 'react';
import DKTable from './DKTable';
import { HeaderColumn } from './HeaderColumn';
import { ItemColumn } from './ItemColumn';
import { TableOption } from './TableOption';
import { getFontWeight, getNameToId, getTextAlign } from './TableUtility';
import { getLocalisedText } from "../../Translate/LanguageManager";
import ComponentManager from "../../Manager/ComponentManager";
import { COLOR_BLUE, PREVIEW_SCALE, PRINT_SCALE, TableColumnPopupType } from "../../Constants/Constants";
import { FooterRow } from './FooterRow';
import Utility, { getVW, getFontSizeClass, getBoundingClientRect } from '../../Utilities/Utility';
import { HTMLTag } from '../../Utilities/HTMLTag';
import DKAdditionalTable from './DKAdditionalTable';
import TemplateSettingsManager from '../../Manager/TemplateSettingsManager';
import MultiPageUtility from '../../Utilities/MultiPageUtility';
import { Asset } from '../../assets';
interface Props {
    tableOption: TableOption;
    data: any;
    headerColumn: HeaderColumn[];
    footerRow: FooterRow[];
    onDataUpdateCallback?: (data: any) => void;
    stockColumn?: HeaderColumn[];
    stockData?: any;
    showStockTable: Boolean;
    stockTableCallback?: any;
    onUpdateRowIndexCallback?: (rowIndex: number, action: string) => void;
    paymentColumn?: HeaderColumn[];
    paymentData?: any;
    showPaymentTable: Boolean;
    paymentTableCallback?: any;
    isReadOnlyMode: Boolean;
    documentType: string;
    onUpdateTableWidth: (header: any, percentage: number) => void;
    rawData: any;
    groupHeaderIndexList?: number[];
    tableStyle: any;
    additionalTableCallback: any;
    additionalTablePrefix?: any;
    tableColumnOptions: any
}

interface States {
    // showStockTable: Boolean
    selectedFooterRow: number | undefined
    data: any
    footerTableWidth: number
    footerTableHeight: number
}
export default class DKDocumentTable extends React.Component<Props, States> {

    constructor(props: any) {
        super(props)
        this.state = {
            selectedFooterRow: undefined,
            data: undefined,
            footerTableWidth: 0,
            footerTableHeight: 0,
        }
    }

    componentWillMount() {
        this.setState({ data: this.getTableData(this.props.data, this.props.headerColumn, this.props.footerRow) }, () => this.getTableHeight() )
    }

    componentWillReceiveProps(nextProp: any) {
        this.setState({ data: this.getTableData(nextProp.data, nextProp.headerColumn, nextProp.footerRow) }, () => this.getTableHeight())
    }
    render() {
        if (this.isShowTable()) {
            return (
                <div>
                    <DKTable
                        tableOption={this.props.tableOption}
                        data={this.state.data}
                        headerColumn={this.props.headerColumn}
                        isStockOrPaymentTable={false}
                        isPaymentTable={false}
                        onUpdateRowIndex={(index, action) => {
                            if(this.props.onUpdateRowIndexCallback !== undefined) {
                                this.props.onUpdateRowIndexCallback(index, action)
                            }
                        }}
                        onFooterRowChange={(index: number, action: string) => {
                            this.onFooterRowChange(index, action)
                        }}
                        documentType={this.props.documentType}
                        additionalTablePrefix={this.props.additionalTablePrefix}
                        onUpdateTableWidth={(header, percentage) => {
                            this.props.onUpdateTableWidth(header, percentage)
                        }}
                        rawData={this.props.rawData}
                        groupHeaderIndexList={this.props.groupHeaderIndexList}
                        tableColumnOptions={this.props.tableColumnOptions}
                    />
                    {this.getIsRenderSubTable() &&
                        <div
                            id={HTMLTag.SUB_TABLE_PREFIX + this.getDocumentTypePrefix() + '_' + this.getAdditionalTablePrefix()}
                            style={{
                                width: '100%',
                                zIndex: 0,
                                display: 'block',
                            }}>
                                {this.renderSubTable()}
                        </div>
                    }
                </div>
            )
        }
        else {
            return (<div>
                The Table is hide by prop.isShowTable flag
            </div>)
        }
    }

    private getTableData(tableData: any, headerColumn: HeaderColumn[], footerRow: FooterRow[]) {
        if (this.isShowFooter() && this.props.footerRow.length > 0) {
            // return this.props.data
            var newData = tableData
            //get the last 2 column index
            var headerList = headerColumn.map(x => x.columnOption.isShowColumn)
            var columns = headerList.filter( x => true)

            if(columns.length > 2) {
                var headerIndexList: { index: number; isShow: boolean; }[] = []
                headerList.forEach((element, index) => {
                    headerIndexList.push({
                        index: index,
                        isShow: element
                    })
                });
                headerIndexList = headerIndexList.filter( x => x.isShow)
                var footerHeaderIndex = headerIndexList[headerIndexList.length - 2]
                var footerValueIndex = headerIndexList[headerIndexList.length - 1]

                footerRow.forEach(element => {
                    var newRow: any = []
                    if(element.isSelected) {
                        for (let index = 0; index < headerColumn.length; index++) {
                            if (footerHeaderIndex && footerHeaderIndex.index === index) {
                                var header = element.header as HeaderColumn
                                var data = header.toItemColumn()
                                newRow.push(data)
                            }
                            else if (footerValueIndex && footerValueIndex.index === index) {
                                newRow.push(element.value)
                            }
                            else {
                                var newItemColumn = new ItemColumn()
                                newItemColumn.style.borderColor = 'transparent'
                                newRow.push(newItemColumn)
                            }
                        }
                    }
                    newData.push(newRow)
                });

                return newData
            }
        }
        return tableData
    }

    private renderSubTable() {
        var marginTop = this.state.footerTableHeight
        if (Utility.getIsUsingTableGrid()) {
            marginTop = marginTop + 1
        }
        let paddingTop = 5
        if(this.props.rawData.printScale === PREVIEW_SCALE) {
            paddingTop = 5 * PRINT_SCALE / PREVIEW_SCALE
        }
        return (
            <div
            style={{
                display: 'flex',
                flexDirection: 'row',
                position: 'relative',
                // marginTop: -marginTop,   
                width: this.state.footerTableWidth,
            }}>
                <div style={{
                    // width: '100%',
                    paddingTop: Utility.getIsUsingTableGrid() ? undefined : paddingTop,
                    position: 'relative',
                }}>
                    <div style={{
                            paddingLeft: Utility.getIsUsingTableGrid() ? undefined : 10,
                            paddingRight: Utility.getIsUsingTableGrid() ? undefined : 20
                        }}>
                        {this.isContainPaymentData() && this.props.showPaymentTable && <div className="ColumnDiv" style={{ position: 'relative' }}>
                            {ComponentManager.addVerticalSpace(20)}
                            {!this.props.isReadOnlyMode && <div id={'editTableButton'}
                                className="ColumnDiv"
                                style={{
                                    cursor: 'pointer',
                                    position: 'absolute',
                                    top: getVW(10),
                                    right: getVW(-8),
                                    backgroundColor: COLOR_BLUE,
                                    width: getVW(20),
                                    height: getVW(20),
                                    borderRadius: '50%',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                                onClick={() => this.props.paymentTableCallback()}
                            >
                                <img src={Asset.icon.ic_close_white} alt="edit" style={{ width: 8 }} />
                            </div>}
                            <DKTable
                                tableOption={this.getPaymentTableOption()}
                                data={this.props.paymentData}
                                headerColumn={this.props.paymentColumn ? this.props.paymentColumn : []}
                                isStockOrPaymentTable={true}
                                isPaymentTable={true}
                                onUpdateRowIndex={(index, action) => {
                                    console.log(index, action)
                                }}
                                onUpdateTableWidth={() => { }}
                                rawData={this.props.rawData}
                                tableColumnOptions={this.props.tableColumnOptions}
                            />
                        </div>}
                        {this.isContainStockData() && this.props.showStockTable && <div className="ColumnDiv" style={{ position: 'relative' }}>
                            {ComponentManager.addVerticalSpace(15)}
                            <text className={"docFontStyleBold" + getFontSizeClass()}>{getLocalisedText('stock_items')}</text>
                            {ComponentManager.addVerticalSpace(5)}
                            {!this.props.isReadOnlyMode && <div id={'editTableButton'}
                                className="ColumnDiv"
                                style={{
                                    cursor: 'pointer',
                                    position: 'absolute',
                                    top: getVW(30),
                                    right: getVW(-8),
                                    backgroundColor: COLOR_BLUE,
                                    width: getVW(20),
                                    height: getVW(20),
                                    borderRadius: '50%',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                                onClick={() => this.props.stockTableCallback()}
                            >
                                <img src={Asset.icon.ic_close_white} alt="edit" style={{ width: getVW(8) }} />
                            </div>}
                            <DKTable
                                tableOption={this.getStockTableOption()}
                                data={this.props.stockData}
                                headerColumn={this.props.stockColumn ? this.props.stockColumn : []}
                                isStockOrPaymentTable={true}
                                isPaymentTable={false}
                                onUpdateRowIndex={(index, action) => {
                                    console.log(index, action)
                                }}
                                onUpdateTableWidth={() => { }}
                                rawData={this.props.rawData}
                                tableColumnOptions={this.props.tableColumnOptions}
                            />
                        </div>}
                        <table 
                            style={{
                                marginTop: -marginTop,
                            }}
                            // id={HTMLTag.SUB_TABLE_PREFIX + this.getDocumentTypePrefix() + '_' + this.getAdditionalTablePrefix()}
                            className='container'>
                                <tr className='row-cell'>
                                    <td className='cell'>
                                        <DKAdditionalTable
                                            rawData={this.props.rawData}
                                            tableStyle={this.props.tableStyle}
                                            tableOption={this.props.tableOption}
                                            onAdditionalTableClicked={this.props.additionalTableCallback}
                                        />
                                    </td>
                                </tr>
                        </table>
                    </div>
                </div>
            </div>
        )
    }

    private getTableHeight() {
        var tableWidth = undefined
        var tableHeight = undefined
        var footerTableWidth = this.state.footerTableWidth
        var footerTableHeight = this.state.footerTableHeight

        if(this.props.documentType !== undefined && this.getIsRenderSubTable()) {
            //to obtains footer tab

            const { width, height } = this.getFooterTableDimension()
            tableWidth = width
            tableHeight = height

            if(tableHeight !== undefined) {
                if(!isNaN(tableHeight)) {
                    footerTableHeight = tableHeight
                }
            }

            if(tableWidth !== undefined) {
                if(!isNaN(tableWidth)) {
                    footerTableWidth = tableWidth
                }
            }

            var headerRowId = HTMLTag.TABLE_HEADER_PREFIX + this.getDocumentTypePrefix() + '_' + this.getAdditionalTablePrefix()
            var headerRow = document.getElementById(headerRowId)
            var subTableWidth = 0
            if (headerRow !== undefined && headerRow !== null) {
                //to obtains width for sub table
                var headerRowSize = headerRow.childNodes.length

                if(headerRowSize > 2 ) {
                    headerRow.childNodes.forEach((element: any, index) => {
                        if(headerRowSize - index > 2) {
                            var item = document.getElementById(element.id)
                            if (item?.offsetWidth !== undefined && item?.offsetWidth !== null){
                                subTableWidth = subTableWidth + item?.offsetWidth
                            }
                        }
                    });
                }
                else {
                    if(!TemplateSettingsManager.getIsMultiProductTable()) {
                        footerTableHeight = 0
                    }
                }
            }


            if (subTableWidth > 0) {
                footerTableWidth = subTableWidth
            }
            
            if(this.props.rawData.printScale === undefined) {
                footerTableHeight = footerTableHeight - 10
            }

            this.setState({
                footerTableWidth: footerTableWidth,
                footerTableHeight: footerTableHeight,
            })
        }
    }

    private getFooterTableDimension() {
        var width: any = undefined
        var height: any = undefined

        var id = HTMLTag.TABLE_FOOTER_PREFIX + this.getDocumentTypePrefix() + '_' + this.getAdditionalTablePrefix()
        var table = getBoundingClientRect(id)
        if (table !== undefined && table !== null) {
            width = table.width
            height = table.height
        }

        if(this.props.rawData.documentIndex !== undefined && this.props.rawData.documentIndex !== null) {
            var template = MultiPageUtility.getTemplateByDocumentIndex(this.props.rawData.documentIndex)

            if(template !== undefined && template !== null) {
                var rect = MultiPageUtility.getTemplateRect(template, id)

                if(rect !== undefined) {
                    width = rect.width
                    height = rect.height
                }
            }
        }
        return { width: width, height: height}
    }
    private getPaymentTableOption() {
        var tableOption = new TableOption()
        tableOption.isShowBorder = true
        tableOption.isShowFooter = true
        tableOption.isShowSortButton = false
        return tableOption
    }

    private getStockTableOption() {
        var tableOption = new TableOption()
        tableOption.isShowBorder = true
        tableOption.isShowFooter = true
        tableOption.isShowSortButton = false
        return tableOption
    }
    private renderSubTableItem(element: FooterRow, rowIndex: number) {
        return (
            <div className='row-cell'
                key={'row-cell-'+ rowIndex}
                onMouseEnter={() => this.setState({ selectedFooterRow: rowIndex })}
                onMouseLeave={() => this.setState({ selectedFooterRow: undefined })}
                >
                {this.renderSubTableColumn(element.header, rowIndex, this.isShowSortButton(rowIndex))}
                {this.renderSubTableValue(element.value, element.header)}
            </div>
        )
    }

    private renderSubTableColumn(footerHeader: HeaderColumn, rowIndex: number, isShowArrowDiv: boolean,) {
        if (footerHeader.columnOption.isShowColumn) {
            return (
                <div
                    className='cell'
                    id={'footer_header_' + getNameToId(footerHeader.name)}
                    style={{
                        width: '50%',
                        height: footerHeader.style.height,
                        background: footerHeader.style.backgroundColor,
                        textOverflow: 'clip ellipsis',
                        borderWidth: this.isShowBorder() ? footerHeader.style.borderWidth : undefined,
                        borderColor: this.isShowBorder() ? footerHeader.style.borderColor : undefined,
                        borderStyle: this.isShowBorder() ? footerHeader.style.borderStyle : undefined,
                        borderCollapse: 'collapse',
                        paddingTop: footerHeader.style.paddingTop,
                        paddingBottom: footerHeader.style.paddingBottom,
                        paddingLeft: footerHeader.style.paddingLeft,
                        paddingRight: footerHeader.style.paddingRight,
                        marginTop: footerHeader.style.marginTop,
                        marginBottom: footerHeader.style.marginBottom,
                        marginLeft: footerHeader.style.marginLeft,
                        marginRight: footerHeader.style.marginRight,
                        fontSize: footerHeader.style.fontSize,
                        fontWeight: getFontWeight(footerHeader.style.fontWeight),
                        verticalAlign: footerHeader.style.verticalAlignment,
                    }}>
                    <div style={{
                        display: isShowArrowDiv ? 'flex' : 'block',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}>
                        {isShowArrowDiv && this.renderArrowButtonBox(rowIndex)}
                        <div className={"docFontStyle" + getFontSizeClass()} style={{
                            color: footerHeader.style.fontColor,
                            fontStyle: footerHeader.style.fontStyle,
                            textAlign: getTextAlign(footerHeader.style.alignment),
                        }}>{(footerHeader.name === TableColumnPopupType.discount) ? (getLocalisedText(footerHeader.label) + ' (-)') : getLocalisedText(footerHeader.label)}</div>
                    </div>
                </div>
            )
        }
        else {
            return (<div></div>)
        }
    }

    renderArrowButtonBox(rowIndex: number) {
        var filterFooterRows = this.props.footerRow.filter(x =>x.isSelected)
        return (
            <div style={{
                display: 'flex',
                flexDirection: 'row',
            }}>
                {rowIndex > 0 &&
                <img
                    className='arrow-button'
                    src={Asset.icon.ic_upArrow}
                    alt={''}
                    onClick={() =>
                        this.onFooterRowChange(rowIndex, 'up')
                    }
                />
                }
                {rowIndex < filterFooterRows.length - 2 &&
                <img
                    className='arrow-button'
                    src={Asset.icon.ic_downArrow}
                    alt={''}
                    onClick={() =>
                        this.onFooterRowChange(rowIndex, 'down')
                    }
                />
                }
            </div>
        )
    }
    private renderSubTableValue(item: string | ItemColumn, headerFooter: HeaderColumn) {
        var headerDefinition = headerFooter

        var itemColumnValue = item as ItemColumn
        if (itemColumnValue.name !== undefined) {
            if (this.isUseHeaderStyle()) {
                return this.renderPlainColumn(itemColumnValue.value, headerDefinition, itemColumnValue.name)
            }
            else {
                return this.renderCustomStyleColumn(itemColumnValue, headerDefinition)
            }
        }

        var stringValue = item as string
        if (stringValue !== undefined) {
            return this.renderPlainColumn(stringValue, headerDefinition)
        }

        return (<div></div>)
    }
    private renderPlainColumn(value: string, footerHeader: HeaderColumn, name?: string) {
        if (footerHeader === undefined || footerHeader === null) {
            return (<div></div>)
        }
        if (footerHeader.columnOption.isShowColumn) {
            return (
                <div
                    className='cell'
                    style={{
                        width: '50%',
                        height: footerHeader.style.height,
                        background: footerHeader.style.backgroundColor,
                        textOverflow: 'clip ellipsis',
                        borderWidth: this.isShowBorder() ? footerHeader.style.borderWidth : undefined,
                        borderColor: this.isShowBorder() ? footerHeader.style.borderColor : undefined,
                        borderStyle: this.isShowBorder() ? footerHeader.style.borderStyle : undefined,
                        borderCollapse: 'collapse',
                        paddingTop: footerHeader.style.paddingTop,
                        paddingBottom: footerHeader.style.paddingBottom,
                        paddingLeft: footerHeader.style.paddingLeft,
                        paddingRight: footerHeader.style.paddingRight,
                        marginTop: footerHeader.style.marginTop,
                        marginBottom: footerHeader.style.marginBottom,
                        marginLeft: footerHeader.style.marginLeft,
                        marginRight: footerHeader.style.marginRight,
                        fontSize: footerHeader.style.fontSize,
                        fontWeight: getFontWeight(footerHeader.style.fontWeight),
                        verticalAlign: footerHeader.style.verticalAlignment,
                    }}>
                    <div className={"docFontStyle" + getFontSizeClass()} style={{
                        textAlign: getTextAlign(footerHeader.style.alignment),
                        color: footerHeader.style.fontColor,
                        fontStyle: footerHeader.style.fontStyle,
                    }}>{value}</div>
                </div>
            )
        }
    }

    private renderCustomStyleColumn(element: ItemColumn, header: HeaderColumn) {
        if (header === undefined || header === null) {
            return (<div></div>)
        }
        return (
            <div
                className='cell'
                style={{
                    width: '50%',
                    height: element.style.height,
                    background: element.style.backgroundColor,
                    textOverflow: 'clip ellipsis',
                    borderWidth: this.isShowBorder() ? element.style.borderWidth : undefined,
                    borderColor: this.isShowBorder() ? element.style.borderColor : undefined,
                    borderStyle: this.isShowBorder() ? element.style.borderStyle : undefined,
                    borderCollapse: 'collapse',
                    paddingTop: element.style.paddingTop,
                    paddingBottom: element.style.paddingBottom,
                    paddingLeft: element.style.paddingLeft,
                    paddingRight: element.style.paddingRight,
                    marginTop: element.style.marginTop,
                    marginBottom: element.style.marginBottom,
                    marginLeft: element.style.marginLeft,
                    marginRight: element.style.marginRight,
                    verticalAlign: element.style.verticalAlignment,
                }}>
                <div className={"docFontStyle" + getFontSizeClass()} style={{
                    textAlign: getTextAlign(element.style.alignment),
                    color: element.style.fontColor,
                    fontStyle: element.style.fontStyle,
                    fontSize: element.style.fontSize
                }}>{element.value}</div>
            </div>
        )
    }

    private onFooterRowChange(rowIndex: number, action: string) {
        if (this.props.footerRow === undefined || this.props.footerRow === null) {
            return
        }

        if (this.props.footerRow.length === 0 || this.props.footerRow.length === 1) {
            return
        }

        var newArray: FooterRow[] = []
        var selectedItem = this.props.footerRow[rowIndex]
        var selectedList = this.props.footerRow.map(x =>x.isSelected)
        var newIndex = rowIndex
        var isFound = false

        this.props.footerRow.forEach((element: FooterRow, index: number) => {
            if (selectedItem.header.name !== element.header.name) {
                newArray.push(element)
            }
        });

        if (action === 'up') {
            newIndex = newIndex - 1
            for (let index = newIndex; index > 0; index--) {
                if(selectedList[index] === false && !isFound) {
                    newIndex -= 1
                }
                else {
                    isFound = true
                }
            }
            if (newIndex <= 0) {
                newIndex = 0
            }
        }
        else {
            newIndex = rowIndex + 1
            for (let index = newIndex; index < this.props.footerRow.length; index++) {
                if (selectedList[index] === false && !isFound) {
                    newIndex += 1
                }
                else {
                    isFound = true
                }
            }
            if (newIndex >= this.props.footerRow.length-1) {
                newIndex = this.props.footerRow.length - 2
            }
        }

        newArray.splice(newIndex, 0, selectedItem)
        if(this.props.onDataUpdateCallback !== undefined) {
            this.props.onDataUpdateCallback(newArray)
        }
    }

    // getFooterWidth() {
    //     if(this.props.documentType === undefined) {
    //         return 60.1
    //     }
    //     else {
    //         if(!getIsAccountModule(undefined, this.props.documentType)) {
    //             var productTableWidth = new ProductTableWidth()
    //             return productTableWidth.getFooterWidth()
    //         }
    //         else {
    //             return 60.1
    //         }
    //     }
    // }

    // getFooterLeftWidth() {
    //     return this.getFooterWidth() + '%'
    // }

    // getFooterRightWidth() {
    //     var remainWidth = 100 - this.getFooterWidth()
    //     return remainWidth + '%'
    // }
    private isShowTable() {
        return this.props.tableOption.isShowTable ? this.props.tableOption.isShowTable : false
    }
    private isShowHeader() {
        return this.props.tableOption.isShowHeader ? this.props.tableOption.isShowHeader : true
    }

    private isUseHeaderStyle() {
        return this.props.tableOption.isUseHeaderStyle
    }

    private isShowBorder() {
        return this.props.tableOption.isShowBorder ? this.props.tableOption.isShowBorder : false
    }

    private isShowFooter() {
        return this.props.tableOption.isShowFooter ? this.props.tableOption.isShowFooter : false
    }

    private isContainStockData() {
        var stockData = this.props.stockData ? this.props.stockData : []
        if (stockData.length > 0) {
            return true
        }
        else {
            return false
        }
    }

    private isContainPaymentData() {
        var paymentData = this.props.paymentData ? this.props.paymentData : []
        if (paymentData.length > 0) {
            return true
        } else {
            return false
        }
    }

    private isShowSubTable() {
        if (Utility.getIsReceiptPaperSize()) {
            return false
        }

        let isShowSubTable = false
        var additionalTableConfiguration = TemplateSettingsManager.defaultTemplateSettings.additionalTable
        if (additionalTableConfiguration !== undefined) {
            if (additionalTableConfiguration.isVisible !== undefined && additionalTableConfiguration.isVisible !== null) {
                if (additionalTableConfiguration.elements !== undefined && additionalTableConfiguration.elements !== null) {
                    if (additionalTableConfiguration.elements.length > 0) {
                        isShowSubTable = additionalTableConfiguration.isVisible
                    }
                }
            }
        }

        if (isShowSubTable) {
            let isMultiProductTable = TemplateSettingsManager.getIsMultiProductTable()

            if(isMultiProductTable) {
                if(this.props.tableOption.isShowHeader) {
                    isShowSubTable = false
                }
            }
        }

            return isShowSubTable
    }

    private getIsRenderSubTable() {
        return (
            (this.isContainStockData() && this.props.showStockTable) ||
            (this.isContainPaymentData() && this.props.showPaymentTable) ||
            (this.isShowSubTable())
        )
    }

    private isShowSortButton(rowIndex: number) {
        var isSelectedRow = this.props.footerRow.filter(x => x.isSelected)
        if (isSelectedRow.length === rowIndex+1) {
            return false
        }
        if (this.state.selectedFooterRow === undefined) {
            return false
        }
        if (this.props.tableOption.isShowSortButton) {
            if (rowIndex === this.state.selectedFooterRow) {
                return true
            }
            else {
                return false
            }
        }
        else {
            return false
        }
    }


    getDocumentTypePrefix() {
        var prefix = ''

        if(this.props.documentType !== undefined && this.props.documentType !== null) {
            prefix = this.props.documentType;
        }

        return prefix
    }
    
    getAdditionalTablePrefix() {
        var prefix = ''

        if(this.props.additionalTablePrefix !== undefined && this.props.additionalTablePrefix !== null) {
            prefix = this.props.additionalTablePrefix
        }

        return prefix
    }

}
