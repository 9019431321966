import { FC, useContext, useEffect, useState } from 'react';
import {
  DKButton,
  DKIcons,
  DKLabel,
  DKListPicker2,
  DKInput,
  INPUT_TYPE,
  INPUT_VIEW_DIRECTION
} from 'deskera-ui-library';
import { useAppDispatch, useAppSelector } from '../../../../../Redux/Hooks';
import {
  selectDocumentFormDataByKeys,
  updateMultipleKeysInDocument
} from '../../../../../Redux/Slices/DocumentSlice';
import {
  CHARGE_TYPE,
  DOCUMENT_KEYS,
  IDocSummaryAmount
} from '../../../Utilities/DocConstants';
import { DOCUMENT_MODE, REGEX } from '../../../../../Constants/Constant';
import { CommonDraftPropsContext } from '../../../Utilities/DocContext';
import { GlobalDiscountOption } from '../DocSummary';
import Utility from '../../../../../Utility/Utility';
import NumberFormatService from '../../../../../Services/NumberFormat';
import useDebounce from '../../../../../Hooks/useDebounce';
import { activeTenantInfo } from '../../../../../Redux/Slices/AuthSlice';
import { getAdditionalChargesTotalAndTax } from '../../../Helper/Common/DocDataHelper';
import { getTotal } from '../DocSummaryHelper';

interface IDocGlobalDiscountProps {
  summaryAmount: IDocSummaryAmount;
}

const DocGlobalDiscount: FC<IDocGlobalDiscountProps> = ({ summaryAmount }) => {
  const dispatch = useAppDispatch();
  const { draftId, documentMode } = useContext(CommonDraftPropsContext);
  const [
    additionalCharges,
    currency,
    items,
    roundOffAmountInDocumentCurrency,
    tcsPercentage,
    gstType
  ] = useAppSelector(
    selectDocumentFormDataByKeys(draftId, [
      DOCUMENT_KEYS.ADDITIONAL_CHARGES,
      DOCUMENT_KEYS.CURRENCY,
      DOCUMENT_KEYS.ITEMS,
      DOCUMENT_KEYS.ROUND_OFF_AMOUNT_IN_DOCUMENT_CURRENCY,
      DOCUMENT_KEYS.TCS_AMOUNT,
      DOCUMENT_KEYS.GST_TYPE
    ])
  );
  const [globalDiscountInvalid, setGlobalDiscountInvalid] =
    useState<boolean>(false);
  const [showGlobalDiscountOptions, setShowGlobalDiscountOptions] =
    useState<boolean>(false);
  const [globalDiscountValue, setGlobalDiscountValue] = useState<any>(0);
  const { decimalScale, additionalSettings } = useAppSelector(activeTenantInfo);

  const debouncedGlobalDiscountValue = useDebounce(globalDiscountValue, 500);

  const globalDiscount = additionalCharges?.globalDiscount;

  useEffect(() => {
    setGlobalDiscountValue(getGlobalDiscountValue());
  }, [additionalCharges.globalDiscount]);

  useEffect(() => {
    if (Utility.isNotEmpty(debouncedGlobalDiscountValue)) {
      validateAndSetGlobalDiscount();
    }
  }, [debouncedGlobalDiscountValue]);

  const validateAndSetGlobalDiscount = (
    discountOptionValue?: GlobalDiscountOption
  ) => {
    let discountValue = debouncedGlobalDiscountValue;
    let discountValueInvalid = false;
    let discountInPercent = false;

    const additionalChargeTotal = getAdditionalChargesTotalAndTax(
      additionalCharges.additionalChargesDetails
    );
    const additionalDiscountTotal = getAdditionalChargesTotalAndTax(
      additionalCharges.globalDiscounts,
      CHARGE_TYPE.DISCOUNT
    );
    const preTaxAmount =
      (summaryAmount?.subTotal || 0) -
      additionalDiscountTotal.total -
      (summaryAmount?.discount || 0) +
      additionalChargeTotal.total;
    const postTaxAmount =
      getTotal({
        items,
        roundOffAmountInDocumentCurrency,
        tcsPercentage,
        gstType,
        decimalScale
      }) +
      additionalChargeTotal.total +
      additionalChargeTotal.tax;

    const totalToCompareWith = discountOptionValue
      ? discountOptionValue === GlobalDiscountOption.PRE_TAX
        ? preTaxAmount
        : postTaxAmount
      : globalDiscount?.isSubTotalOnly
      ? preTaxAmount
      : postTaxAmount;

    const matcher = String(discountValue).match(REGEX.PERCENT_NUMER);
    if (!matcher) {
      discountValueInvalid = true;
    } else if ('%' === matcher[4]) {
      const percentDiscount = Number(discountValue.replace('%', ''));
      if (percentDiscount > 100) {
        discountValueInvalid = true;
      } else {
        discountValueInvalid = false;
        discountValue = percentDiscount;
      }
      discountInPercent = true;
    } else if (
      discountValue < 0 ||
      (discountValue as number) > (totalToCompareWith as number)
    ) {
      discountValueInvalid = true;
    }
    setGlobalDiscountInvalid(discountValueInvalid);

    let discountAmount = 0;
    if (discountInPercent) {
      discountAmount = Utility.roundOff(
        (totalToCompareWith * discountValue) / 100,
        decimalScale
      );
    } else {
      discountAmount = discountValue;
    }

    const updatedDiscount = {
      ...globalDiscount,
      isSubTotalOnly: discountOptionValue
        ? discountOptionValue === GlobalDiscountOption.PRE_TAX
        : globalDiscount?.isSubTotalOnly,
      isPercent: discountInPercent,
      percent: discountInPercent ? discountValue : 0,
      amount: discountAmount,
      hasError: discountValueInvalid
    };

    if (!Utility.areObjectsEqual(updatedDiscount, globalDiscount)) {
      dispatch(
        updateMultipleKeysInDocument({
          draftId,
          keysToUpdate: {
            [DOCUMENT_KEYS.ADDITIONAL_CHARGES]: {
              ...additionalCharges,
              globalDiscount: updatedDiscount
            }
          }
        })
      );
    }
  };

  const globalDiscountRemoved = () => {
    setGlobalDiscountValue(null);
    dispatch(
      updateMultipleKeysInDocument({
        draftId,
        keysToUpdate: {
          [DOCUMENT_KEYS.ADDITIONAL_CHARGES]: {
            ...additionalCharges,
            globalDiscount: null
          }
        }
      })
    );
  };

  const getGlobalDiscountValue = () => {
    let discountInputValue: any = 0;
    if (globalDiscount) {
      if (globalDiscount.isPercent) {
        discountInputValue = `${globalDiscount.percent}%`;
      } else {
        discountInputValue = globalDiscount.amount;
      }
    }
    return discountInputValue;
  };

  const getOptions = () => {
    return (
      <div className="column parent-width position-relative">
        <DKLabel text="Global Discount (-)" className="fw-m" />
        {showGlobalDiscountOptions && (
          <DKListPicker2
            title=""
            data={[GlobalDiscountOption.PRE_TAX, GlobalDiscountOption.POST_TAX]}
            style={{
              width: 150
            }}
            allowSearch={false}
            className="position-absolute z-index-3 left-0 top-10 shadow-m"
            onSelect={(index: number, option: any) => {
              setShowGlobalDiscountOptions(false);
            }}
            onClose={() => {
              if (showGlobalDiscountOptions) {
                setShowGlobalDiscountOptions(false);
              }
            }}
            renderer={(index: number, obj: any) => {
              return (
                <div className="row parent-width">
                  <div
                    style={{
                      whiteSpace: 'pre-wrap',
                      textAlign: 'left'
                    }}
                  >
                    {obj}
                  </div>
                </div>
              );
            }}
          />
        )}
        {/* Hiding global discount application level till pre-tax calculations are fixed */}
        {false && (
          <DKButton
            className="text-blue fs-s"
            style={{
              paddingLeft: 0,
              paddingTop: 0,
              fontSize: 11
            }}
            title={`${
              globalDiscount?.isSubTotalOnly
                ? GlobalDiscountOption.PRE_TAX
                : GlobalDiscountOption.POST_TAX
            }`}
            onClick={() => {
              setShowGlobalDiscountOptions(!showGlobalDiscountOptions);
            }}
          />
        )}
      </div>
    );
  };

  const getInput = () => {
    return (
      <div>
        <DKInput
          required={true}
          canValidate={globalDiscountInvalid}
          validator={(value: string) => !globalDiscountInvalid}
          errorMessage="Invalid value"
          title=""
          type={INPUT_TYPE.TEXT}
          readOnly={documentMode === DOCUMENT_MODE.VIEW}
          value={globalDiscountValue ?? 0}
          valueStyle={{
            background: '#fff',
            paddingTop: 0,
            paddingBottom: 0,
            minWidth: 100
          }}
          textAlign="right"
          className="width-auto text-align-right"
          direction={INPUT_VIEW_DIRECTION.VERTICAL}
          onChange={(changedValue: any) => {
            setGlobalDiscountValue(changedValue);
          }}
        />
        {!globalDiscountInvalid &&
          !Utility.isEmpty(globalDiscount) &&
          globalDiscount.isPercent &&
          !isNaN(globalDiscount?.amount) && (
            <DKLabel
              className="text-align-right"
              text={`${Utility.getCurrencySymbolFromCode(
                currency
              )} ${NumberFormatService.getNumber(globalDiscount.amount)}`}
            />
          )}
      </div>
    );
  };
  console.log(globalDiscount);

  if (
    !!additionalSettings?.MULTIPLE_GLOBAL_DISCOUNT ||
    Utility.isEmptyObject(globalDiscount) ||
    (globalDiscount?.amount === 0 && !globalDiscount?.addedManually)
  )
    return null;

  return (
    <div
      className="row parent-width mb-m justify-content-between align-items-start position-relative parent-block"
      style={{ width: '100%' }}
    >
      {documentMode !== DOCUMENT_MODE.VIEW && (
        <DKButton
          title=""
          icon={DKIcons.ic_delete}
          className="position-absolute child-block"
          style={{ left: -30, top: -7, opacity: 0.5 }}
          onClick={globalDiscountRemoved}
        />
      )}
      <div
        className="row width-auto"
        style={{
          minWidth: 150,
          paddingLeft: 8
        }}
      >
        {getOptions()}
      </div>
      {getInput()}
    </div>
  );
};

export default DocGlobalDiscount;
