import {
  DKButton,
  DKIcon,
  DKIcons,
  DKListPicker2,
  removeLoader,
  showAlert,
  showLoader
} from 'deskera-ui-library';
import { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import BuyConfig, { BUY_CONFIG_BTN } from '../../../../Configs/BuyConfig';
import SellConfig, { SELL_CONFIG_BTN } from '../../../../Configs/SellConfig';
import {
  BUY_TYPE,
  DOCUMENT_STATUS,
  DOC_PATH_WITH_ID_REGEX,
  DOC_TYPE,
  FULFILLMENT_STATUS,
  FULFILLMENT_TYPE,
  JWO_BILLED_STATUS,
  JWO_DISPATCH_STATUS,
  JWO_RECEIPT_STATUS,
  LABELS,
  MRP_APP_NAME,
  OPENING_DOCUMENT_LABEL,
  POPUP_CALLBACKS_TYPE,
  PRODUCE_PRODUCT_TYPE,
  RECEIVED_GOODS_STATUS
} from '../../../../Constants/Constant';
import { PERMISSIONS_BY_MODULE } from '../../../../Constants/Permission';
import AppManager from '../../../../Managers/AppManager';
import RouteManager, { PAGE_ROUTES } from '../../../../Managers/RouteManager';
import { billInitialState } from '../../../../Models/Bill';
import { DraftTypes } from '../../../../Models/Drafts';
import {
  CallBackPayloadType,
  UpdateCallBacksRefType
} from '../../../../Models/Interfaces';
import { PurchaseOrder } from '../../../../Models/Order';
import { useAppDispatch, useAppSelector } from '../../../../Redux/Hooks';
import { subscriptionInfo } from '../../../../Redux/Slices/AuthSlice';
import {
  setCreateWOFromSalesInvoice,
  setCreateWOFromSalesOrder
} from '../../../../Redux/Slices/CommonDataSlice';
import {
  selectCurrentActiveDocument,
  selectDocumentFormDataByKeys
} from '../../../../Redux/Slices/DocumentSlice';
import {
  createBlankDraft,
  draftTableId,
  removeDraft,
  selectDraftsColumnConfig
} from '../../../../Redux/Slices/DraftsSlice';
import ContactService from '../../../../Services/Contact';
import { localizedText } from '../../../../Services/Localization/Localization';
import ProductService from '../../../../Services/Product';
import WorkoutService from '../../../../Services/Workout';
import {
  checkIfAllProductsActiveInDoc,
  getEmailPopup,
  getPrintPreview,
  getRowIdByModule,
  isSalesOrderVisible,
  setEmailStatusIds
} from '../../../../SharedComponents/DocumentForm/NewDocumentHelper';
import ReceivedGoodsRecordsForAsset from '../../../../SharedComponents/ReceivedGoodsPopup/ReceivedGoodsRecordForAsset';
import Utility from '../../../../Utility/Utility';
import {
  isTabletView,
  isViewportLarge
} from '../../../../Utility/ViewportSizeUtils';
import { billEmailTemplateConfig } from '../../../Bills/BillHelper';
import { invoiceEmailTemplateConfig } from '../../../Invoices/InvoiceHelper';
import {
  WorkOrderHelper,
  validateSelectedInvoiceForInterLinkedWO,
  validateSelectedSOForInterLinkedWO
} from '../../../Mrp/WorkOrder/WorkOrderHelper';
import { orderEmailTemplateConfig } from '../../../PurchaseOrders/PurchaseOrderHelper';
import { quoteEmailTemplateConfig } from '../../../Quotations/QuoteHelper';
import { reqEmailTemplateConfig } from '../../../Requisitions/RequisitionHelper';
import { salesOrderEmailTemplateConfig } from '../../../SalesOrders/SalesOrderHelper';
import {
  GranularPermissionsHelper,
  checkUserPermission
} from '../../../Settings/GranularPermissions/GranularPermissionsHelper';
import { DOCUMENT_KEYS } from '../../Utilities/DocConstants';
import { CommonDraftPropsContext } from '../../Utilities/DocContext';
import { AddStockTransferActionPopup } from './DocActionPopups/AddStockTransferActionPopup';
import { DropshipWarningActionPopup } from './DocActionPopups/DropshipWarningActionPopup';
import { FulfillmentActionPopup } from './DocActionPopups/FulfillmentActionPopup';
import { FulfillmentConfirmationActionPopup } from './DocActionPopups/FulfillmentConfirmationActionPopup';
import { PaymentActionPopup } from './DocActionPopups/PaymentActionPopup';
import { PaymentRecordsActionPopup } from './DocActionPopups/PaymentRecordsActionPopup';
import { PurchaseOrderToBillActionPopup } from './DocActionPopups/PurchaseOrderToBillActionPopup';
import { QuoteToInvoiceActionPopup } from './DocActionPopups/QuoteToInvoiceActionPopup';
import { QuoteToSalesOrderActionPopup } from './DocActionPopups/QuoteToSalesOrderActionPopup';
import { ReceiveGoodsActionPopup } from './DocActionPopups/ReceiveGoodsActionPopup';
import { RequisitionToPurchaseOrderActionPopup } from './DocActionPopups/RequisitionToPurchaseOrderActionPopup';
import { SalesOrderToInvoiceActionPopup } from './DocActionPopups/SalesOrderToInvoiceActionPopup';
import DocActionsHelper from './DocActionsHelper';
import { ReceivedGoodsAssetActionPopup } from './DocActionPopups/ReceivedGoodsAssetActionPopup';

export enum ActionMenuEvent {
  EMAIL = 'EMAIL',
  PRINT = 'PRINT',
  GET_PAID = 'GET_PAID',
  FULFILL = 'FULFILL',
  PAY = 'PAY',
  RECEIVE_GOODS = 'RECEIVE_GOODS',
  OFFSET_PAYMENT = 'OFFSET_PAYMENT',
  SET_RECURRING = 'SET_RECURRING',
  PAYMENT_RECORDS = 'PAYMENT_RECORDS',
  FULLFILLMENT_RECORDS = 'FULLFILLMENT_RECORDS',
  RECEIVE_GOODS_RECORDS = 'RECEIVE_GOODS_RECORDS'
}

export default function DocActions(props: any) {
  const { t, i18n } = useTranslation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [menuItems, setMenuItems] = useState<any[]>([]);
  const [stockTransferItems, setStockTransferItems] = useState<any>({});
  const [isOpeningForm, setIsOpeningForm] = useState(false);
  const [booksDocument, setBooksDocument] = useState<any>(null);

  // For Payments
  const [showPaymentPopup, setShowPaymentPopup] = useState<boolean>(false);
  const [showPaymentRecord, setShowPaymentRecord] = useState<boolean>(false);

  // For Conversion
  const [
    showQuoteToInvoiceConversionPopup,
    setShowQuoteToInvoiceConversionPopup
  ] = useState<boolean>(false);
  const [showQuoteToSOConversionPopup, setShowQuoteToSOConversionPopup] =
    useState<boolean>(false);

  const [showSOConversionPopup, setShowSOConversionPopup] =
    useState<boolean>(false);
  const [showPOConversionPopup, setShowPOConversionPopup] =
    useState<boolean>(false);

  const [showReqConversionPopup, setShowReqConversionPopup] =
    useState<boolean>(false);

  // For Fulfillment
  const [
    showFulfillmentConfirmationPopup,
    setShowFulfillmentConfirmationPopup
  ] = useState<boolean>(false);
  const [showFulfillmentPopup, setShowFulfillmentPopup] =
    useState<boolean>(false);
  const [showDropShipWarningPopup, setShowDropShipWarningPopup] =
    useState<boolean>(false);
  const [showReceivedGoodsPopup, setReceivedGoodsPopup] =
    useState<boolean>(false);
  const [showReceivedGoodsAssetPopup, setReceivedGoodsAssetPopup] =
    useState<boolean>(false);
  const [showStockTransferPopup, setShowStockTransferPopup] =
    useState<boolean>(false);

  //for WO
  const [modulePermissions, setModulePermissions] = useState<any>({});
  const [isDesktop, setIsDesktop] = useState(isViewportLarge());

  const refInitialState: UpdateCallBacksRefType = {
    pushDataToParent: { type: POPUP_CALLBACKS_TYPE.NONE },
    storeCallbacksRef: { updateInvoice: 'click' }
  };
  const addDocRef = useRef<UpdateCallBacksRefType>(refInitialState);
  const history = useHistory();

  const dispatch = useAppDispatch();
  const subscriptionDetails = useAppSelector(subscriptionInfo);
  const tableId = useAppSelector(draftTableId);
  const draftsColumnConfig = useAppSelector(selectDraftsColumnConfig);

  // New Store Level Variables
  const { draftId, documentMode, draftType } = useContext(
    CommonDraftPropsContext
  );
  const [documentType] = useAppSelector(
    selectDocumentFormDataByKeys(draftId, [DOCUMENT_KEYS.DOCUMENT_TYPE])
  );

  const documentObject = useAppSelector(selectCurrentActiveDocument(draftId));

  const closeAllPopups = () => {
    setShowFulfillmentPopup(false);
    setShowFulfillmentConfirmationPopup(false);
    setShowPaymentPopup(false);
    setShowPaymentRecord(false);
    setReceivedGoodsPopup(false);
    setReceivedGoodsAssetPopup(false);
    setShowQuoteToSOConversionPopup(false);
    setShowSOConversionPopup(false);
    setShowQuoteToInvoiceConversionPopup(false);
    if (showFulfillmentConfirmationPopup) {
      dispatch(removeDraft(draftId));
      if (props.closeMenuContainer) {
        props.closeMenuContainer();
      }
    }

    if (
      showPaymentPopup ||
      showFulfillmentPopup ||
      showReceivedGoodsPopup ||
      showReceivedGoodsAssetPopup
    ) {
      if (props.closeMenuContainer) {
        props.closeMenuContainer();
      }
    }
  };

  useEffect(() => {
    AppManager.handleWindowResizeListener(onWindowResize, true);
    return () => {
      AppManager.handleWindowResizeListener(onWindowResize, false);
    };
  }, []);

  const onWindowResize = () => {
    setIsDesktop(isViewportLarge);
  };

  useEffect(() => {
    //permissions
    switch (documentType) {
      case DOC_TYPE.INVOICE:
        setModulePermissions(PERMISSIONS_BY_MODULE.INVOICE);
        break;
      case DOC_TYPE.QUOTE:
        setModulePermissions(PERMISSIONS_BY_MODULE.QUOTATION);
        break;
      case DOC_TYPE.BILL:
        setModulePermissions(PERMISSIONS_BY_MODULE.BILL);
        break;
      case DOC_TYPE.ORDER:
        setModulePermissions(PERMISSIONS_BY_MODULE.PURCHASE_ORDER);
        break;
      case DOC_TYPE.SALES_ORDER:
        setModulePermissions(PERMISSIONS_BY_MODULE.SALES_ORDER);
        break;
      case DOC_TYPE.REQUISITION:
        setModulePermissions(PERMISSIONS_BY_MODULE.REQUISITION);
        break;
      case DOC_TYPE.JOB_WORK_OUT_ORDER:
        setModulePermissions(PERMISSIONS_BY_MODULE.SETTINGS.ORGANISATION);
        break;
    }
  }, []);

  useEffect(() => {
    if (
      !Utility.isEmpty(documentObject) &&
      JSON.stringify(documentObject) !== JSON.stringify(booksDocument)
    ) {
      setBooksDocument(documentObject);
    }
  }, [documentObject]);

  useEffect(() => {
    if (!Utility.isEmpty(modulePermissions)) {
      populateMenu();
    }
  }, [booksDocument, modulePermissions]);

  const populateMenu = () => {
    let menuBtns: any[] = getCommonActionBtns();
    if (
      documentType === DOC_TYPE.INVOICE ||
      documentType === DOC_TYPE.QUOTE ||
      documentType === DOC_TYPE.SALES_ORDER
    ) {
      menuBtns = [...menuBtns, ...getSellActionBtns()];
    }

    if (
      documentType === DOC_TYPE.BILL ||
      documentType === DOC_TYPE.ORDER ||
      documentType === DOC_TYPE.REQUISITION
    ) {
      menuBtns = [...menuBtns, ...getBuyActionBtns()];
    }

    if (documentType === DOC_TYPE.JOB_WORK_OUT_ORDER) {
      menuBtns = [...getJobWorkoutActionBtns(documentObject)];
    }

    // Conditions to show create workorder button
    // for sales order
    if (Utility.isMRP() && documentType === DOC_TYPE.SALES_ORDER) {
      const atLeastOneLinkedDocIsWO =
        documentObject?.linkedWorkOrderDocuments?.some(
          (doc: any) => doc.documentType === DOC_TYPE.WORK_ORDER
        );
      const soHasRemainingQty =
        WorkOrderHelper.soHasRemainingQtyToBuild(documentObject);

      if (!atLeastOneLinkedDocIsWO || soHasRemainingQty) {
        menuBtns = [...menuBtns, ...getWorkOrderActionBtns(documentObject)];
      }
    }
    // for sales invoice
    if (Utility.isMRP() && documentType === DOC_TYPE.INVOICE) {
      const atLeastOneLinkedDocIsWO = documentObject?.linkedDocuments?.some(
        (doc: any) => doc.documentType === DOC_TYPE.WORK_ORDER
      );
      if (!atLeastOneLinkedDocIsWO) {
        menuBtns = [...menuBtns, ...getWorkOrderActionBtns(documentObject)];
      }
    }

    if (checkIsFa()) {
      menuBtns = menuBtns.filter(
        (btn: any) => btn.title === 'Pay' || btn.title === 'Receive Goods'
      );
    }
    setMenuItems(menuBtns);
  };

  const convertPOToBill = () => {
    const order: any = documentObject;
    if (
      order?.dropShip ||
      (order?.receiveGoodsStatus === RECEIVED_GOODS_STATUS.FULLY_RECEIVED &&
        !order?.hasPartialBill)
    ) {
      order && convertToBill(order);
    } else {
      setShowPOConversionPopup(true);
    }
  };

  const convertToBill = (po: PurchaseOrder) => {
    let selectedOrder = {
      ...po,
      entityId: po.id ? po.id : undefined,
      documentType: DOC_TYPE.ORDER,
      documentCode: po.poCode,
      vendorCode: Utility.getValue(po.vendorCode, po.contactCode),
      buyType: Utility.getValue(po.buyType, po.orderType),
      orderType: po.buyType,
      status: !po.id ? DOCUMENT_STATUS.OPEN : po.status,
      placeOfSupply: po.placeOfSupply,
      reorder: po.reorder || false,
      dropShip: po.dropShip ? po.dropShip : false,
      backOrder: po.backOrder ? po.backOrder : false,
      linkedPurchaseInvoices: po.linkedPurchaseInvoices,
      orderDueDate: Utility.getValue(po.dueDate, po.validTillDate),
      fulfillmentDate: Utility.getValue(po.fulfillmentDate, po.receiveByDate),
      validTillDate: po.dueDate,
      receiveGoodsStatus:
        po.receiveGoodsStatus ||
        po.receiptStatus ||
        RECEIVED_GOODS_STATUS.NOT_RECEIVED,
      currency: Utility.getValue(po.currency, po.currencyCode),
      items: po.purchaseOrderItems?.map((item: any) => {
        return {
          ...item,
          documentItemCode: Utility.getValue(
            item.documentItemCode,
            item.purchaseOrderItemCode,
            item.purchaseInvoiceItemCode
          )
        };
      }),
      hasPartialBill:
        po.linkedPurchaseInvoices && po.linkedPurchaseInvoices.length > 0
    };
    let bill: any = {
      ...billInitialState,
      ...selectedOrder
    };

    bill = {
      ...bill,
      id: undefined,
      documentType: DOC_TYPE.BILL,
      receiveByDate: bill.fulfillmentDate,
      purchaseInvoiceDueDate: bill.validTillDate,
      purchaseInvoiceType: bill.buyType,
      supplierInvoiceNo: bill.taxInvoiceNo,
      purchaseInvoiceDate: bill.documentDate,
      isPartialInvoice: true,
      attachments: bill.entityId ? bill.attachments : [],
      attachmentIds: bill.entityId ? bill.attachments?.map(Number) : [],
      purchaseInvoiceCode: undefined,
      receivedComplete: false,
      recurring: false,
      recurringActivated: false,
      status: DOCUMENT_STATUS.OPEN,
      documentCode: bill.documentCode,
      billDue: Utility.getValue(
        bill.dueDate,
        bill.purchaseInvoiceDueDate,
        bill.validTillDate
      ),
      documentDate: Utility.getValue(
        bill.documentDate,
        bill.purchaseInvoiceDate
      ),
      validTillDate: Utility.getValue(
        bill.validTillDate,
        bill.purchaseInvoiceDueDate
      ),
      fulfillmentDate: Utility.getValue(
        bill.fulfillmentDate,
        bill.receiveByDate
      ),
      currency: bill.currency,
      receiveGoodsStatus:
        bill.receiptStatus ||
        bill.receiveGoodsStatus ||
        RECEIVED_GOODS_STATUS.NOT_RECEIVED,
      buyType: Utility.getValue(bill.buyType, bill.purchaseInvoiceType),
      gstin: bill.gstin,
      dropShip: bill.dropShip ? bill.dropShip : false,
      placeOfSupply: bill.placeOfSupply,
      isCreditable: bill.isCreditable,
      isPartialBill: true,
      taxInvoiceNo: bill.taxInvoiceNo,
      customerType: bill.customerType,
      vendorType: bill.vendorType,
      openingInvoice: bill.openingInvoice,
      customField: bill.customField,
      paymentStatus: bill.paymentStatus,
      whtRate: bill.whtRate && bill.whtRate !== null ? bill.whtRate : 0,
      tdsInfoIndia: bill.tdsInfoIndia,
      tdsProcessedFlag: bill.tdsProcessedFlag,
      contact: {
        ...bill.contact,
        ...bill.contactDto,
        address: Utility.getStringAddress(bill.contactDto)
      },
      contactCode: bill.contactDto.code,
      purchaseInvoiceProducts: bill.items?.map((item: any) => {
        return {
          ...item,
          id: undefined,
          documentItemCode: undefined,
          unitPriceGstInclusive: bill.unitPriceGstInclusive,
          exchangeRate: bill.exchangeRate,
          currency: bill.currencyCode,
          linkedOrderItem: item.id,
          taxAmount: 1 * item.taxAmount,
          isPartialInvoice: true,
          isPartialBill: true,
          exciseRateIndo: item.exciseRate,
          taxDetails: item.taxDetails.map((tax: any) => {
            const taxItem = { ...tax };
            delete taxItem.id;
            return taxItem;
          })
        };
      }),
      linkedDocuments: [
        {
          documentCode: bill.documentCode as string,
          documentType: bill.documentType,
          documentSequenceCode: bill.documentSequenceCode as string
        }
      ]
    };

    const { contactDto, items, poCode, purchaseOrderItems, ...billToSave } =
      bill;

    if (!Utility.isEmpty(billToSave)) {
      let payloadData: any = {
        type: LABELS.BILLS,
        title: LABELS.BILLS,
        isMaximized: true,
        isCenterAlign: true,
        populateFormData: billToSave,
        isSaved: true,
        tableId: tableId,
        columnConfig: draftsColumnConfig
      };

      dispatch(removeDraft(draftId));
      if (props.closeMenuContainer) {
        props.closeMenuContainer();
      }

      if (
        DOC_PATH_WITH_ID_REGEX.test(history?.location?.pathname) &&
        history?.location?.pathname?.includes(PAGE_ROUTES.ORDERS)
      ) {
        history.replace(PAGE_ROUTES.ORDERS);
      }

      dispatch(createBlankDraft({ payloadData, draftType: DraftTypes.NEW }));
    }
  };

  const getEmailTemplateConfigFromDocType = (docType: any) => {
    switch (docType) {
      case DOC_TYPE.INVOICE:
        return invoiceEmailTemplateConfig;
      case DOC_TYPE.SALES_ORDER:
        return salesOrderEmailTemplateConfig;
      case DOC_TYPE.EXPENSE_BILL:
      case DOC_TYPE.BILL:
        return billEmailTemplateConfig;
      case DOC_TYPE.QUOTE:
        return quoteEmailTemplateConfig;
      case DOC_TYPE.ORDER:
        return orderEmailTemplateConfig;
      case DOC_TYPE.REQUISITION:
        return reqEmailTemplateConfig;
    }
  };

  const isDocumentTouched = () => {
    if (documentObject?.isDocumentTouched) {
      showAlert(
        'Warning!',
        'Document has unsaved changes. Please save the document first.',
        [
          {
            title: 'Cancel',
            className: 'bg-gray2 border-m',
            onClick: () => {}
          },
          {
            title: 'Save',
            className: 'bg-button text-white ml-r',
            onClick: () => {
              if (props.updateWithoutClosing) {
                props.updateWithoutClosing();
              }
            }
          }
        ]
      );
      return true;
    } else {
      return false;
    }
  };

  const onEmailCallBack = (data: any, id: any, type: any) => {
    setEmailStatusIds(id, type);
  };

  const getCommonActionBtns = () => {
    let buttons = [];
    if (
      Object.keys(modulePermissions).includes('VIEW')
        ? checkUserPermission(modulePermissions['EDIT'])
        : true
    ) {
      if (documentType !== DOC_TYPE.REQUISITION) {
        buttons.push({
          title: 'Preview',
          icon: DKIcons.ic_view,
          className: 'column text-blue fw-m',
          onClick: () => {
            if (documentType === DOC_TYPE.REQUISITION) {
              showAlert('', 'Work in Process......');
            } else {
              setIsMenuOpen(false);
              if (!isDocumentTouched()) {
                getPrintPreview(
                  DocActionsHelper.getPrintDocTypeFromDocType(documentType),
                  documentObject
                );
              }
            }
          }
        });
      }
    }
    if (
      Object.keys(modulePermissions).includes('EMAIL')
        ? GranularPermissionsHelper.hasPermissionFor(modulePermissions['EMAIL'])
        : true
    ) {
      if (documentType !== DOC_TYPE.REQUISITION) {
        buttons.push({
          title: 'Email',
          icon: DKIcons.ic_email,
          className: 'column text-blue fw-m',

          onClick: () => {
            if (documentType === DOC_TYPE.REQUISITION) {
              showAlert('', 'Work in Process......');
            } else {
              setIsMenuOpen(false);
              if (!isDocumentTouched()) {
                getEmailPopup(
                  DocActionsHelper.getPrintDocTypeFromDocType(documentType),
                  documentObject,
                  getEmailTemplateConfigFromDocType(documentType),
                  (data: any) =>
                    onEmailCallBack(
                      data,
                      getRowIdByModule(documentObject, documentType),
                      documentType
                    )
                );
              }
            }
          }
        });
      }
    }
    if (
      modulePermissions?.['PRINT'] &&
      checkUserPermission(modulePermissions['PRINT'])
    ) {
      if (!isTabletView() && documentType !== DOC_TYPE.REQUISITION) {
        buttons.push({
          title: 'Print',
          icon: DKIcons.ic_printer,
          className: 'column text-blue fw-m',
          onClick: () => {
            if (documentType === DOC_TYPE.REQUISITION) {
              showAlert('', 'Work in Process......');
            } else {
              setIsMenuOpen(false);
              if (!isDocumentTouched()) {
                getPrintPreview(
                  DocActionsHelper.getPrintDocTypeFromDocType(documentType),
                  documentObject
                );
              }
            }
          }
        });
      }
    }
    return buttons;
  };

  const getSellActionBtns = () => {
    let menuBtns = [];
    if (
      ((documentType === DOC_TYPE.QUOTE &&
        GranularPermissionsHelper.hasPermissionFor(
          PERMISSIONS_BY_MODULE.QUOTATION.CONVERT
        ) &&
        !documentObject?.linkedSalesOrder?.length) ||
        (documentType === DOC_TYPE.SALES_ORDER &&
          checkUserPermission(PERMISSIONS_BY_MODULE.INVOICE.CREATE))) &&
      SellConfig.showActionButton(
        SELL_CONFIG_BTN.CONVERT,
        documentObject,
        documentType
      )
    ) {
      menuBtns.push({
        title: 'Convert to Invoice',
        icon: DKIcons.ic_convert_3,
        className: 'p-v-0 text-blue underline grid-action-link-h-padding',
        onClick: () => {
          if (!isDocumentTouched()) {
            if (documentType === DOC_TYPE.QUOTE) {
              setShowQuoteToInvoiceConversionPopup(true);
            }
            if (documentType === DOC_TYPE.SALES_ORDER) {
              setShowSOConversionPopup(true);
            }
          }
        }
      });
    }

    if (
      documentType === DOC_TYPE.QUOTE &&
      SellConfig.showActionButton(
        SELL_CONFIG_BTN.CONVERT,
        documentObject,
        DOC_TYPE.QUOTE
      ) &&
      checkUserPermission(PERMISSIONS_BY_MODULE.SALES_ORDER.CREATE) &&
      isSalesOrderVisible() &&
      (documentObject?.linkedSalesInvoices === null ||
        documentObject?.linkedSalesInvoices?.length === 0 ||
        documentObject?.linkedSalesOrders?.length > 0)
    ) {
      menuBtns.push({
        title: 'Convert to Sales Order',
        icon: DKIcons.ic_convert_3,
        className: 'p-v-0 text-blue underline grid-action-link-h-padding',
        onClick: () => {
          if (!isDocumentTouched()) {
            setShowQuoteToSOConversionPopup(true);
          }
        }
      });
    }

    if (
      documentType === DOC_TYPE.INVOICE &&
      SellConfig.showActionButton(
        SELL_CONFIG_BTN.GET_PAID,
        documentObject,
        documentType
      ) &&
      checkUserPermission(PERMISSIONS_BY_MODULE.INVOICE.EDIT)
    ) {
      menuBtns.push({
        title: 'Get Paid',
        icon: DKIcons.ic_getPaid,
        className: 'column text-blue fw-m',
        onClick: () => {
          if (!isDocumentTouched()) {
            setShowPaymentPopup(true);
          }
        }
      });
    }
    let showFulfillOption = SellConfig.showActionButton(
      SELL_CONFIG_BTN.FULFILL,
      documentObject,
      documentType
    );
    const isQuoteDocumentType = documentType === DOC_TYPE.QUOTE;
    if (!isQuoteDocumentType) {
      showFulfillOption =
        showFulfillOption && checkUserPermission(modulePermissions['FULFILL']);
    } else {
      if (!isSalesOrderVisible()) {
        showFulfillOption =
          showFulfillOption &&
          checkUserPermission(PERMISSIONS_BY_MODULE.QUOTATION.FULFILL);
      } else {
        showFulfillOption = false;
      }
    }

    if (showFulfillOption) {
      menuBtns.push({
        title: localizedText('Fulfill'),
        icon: DKIcons.ic_delivery,
        className: 'column text-blue fw-m',
        onClick: () => {
          if (!isDocumentTouched()) {
            const docData = documentObject;
            if (
              documentType === DOC_TYPE.INVOICE &&
              !checkIfAllProductsActiveInDoc(docData, DOC_TYPE.INVOICE)
            ) {
              showAlert(
                'Unable to fulfill!',
                'Invoice has inactive products, please remove them and try again'
              );
              return;
            }
            if (docData?.fulfillmentType === FULFILLMENT_TYPE.PICK_PACK_SHIP) {
              dispatch(removeDraft(draftId));
              RouteManager.navigateToPage(
                PAGE_ROUTES.PICK_PACK_SHIP,
                'picking-list'
              );
              return;
            }
            const displayFulfillmentConfirmation =
              !Utility.isAllNonTracked(docData?.items ?? []) &&
              docData?.fulfillmentStatus === FULFILLMENT_STATUS.UNFULFILLED &&
              docData?.fulfillmentType !== FULFILLMENT_TYPE.DROP_SHIP;
            if (displayFulfillmentConfirmation) {
              setShowFulfillmentConfirmationPopup(true);
            } else {
              setShowFulfillmentPopup(true);
            }
          }
        }
      });
    }
    return menuBtns;
  };

  const getBuyActionBtns = () => {
    let menuBtns = [];
    if (
      documentType === DOC_TYPE.ORDER &&
      checkUserPermission(PERMISSIONS_BY_MODULE.BILL.CREATE) &&
      BuyConfig.showActionButton(
        BUY_CONFIG_BTN.CONVERT,
        documentObject,
        documentType
      )
    ) {
      menuBtns.push({
        title: 'Convert',
        icon: DKIcons.ic_convert_3,
        className: 'p-v-0 text-blue underline grid-action-link-h-padding',
        onClick: () => {
          convertPOToBill();
        }
      });
    }

    if (
      documentType === DOC_TYPE.REQUISITION &&
      BuyConfig.showActionButton(
        BUY_CONFIG_BTN.CONVERT,
        documentObject,
        documentType
      ) &&
      checkUserPermission(PERMISSIONS_BY_MODULE.PURCHASE_ORDER.CREATE)
    ) {
      menuBtns.push({
        title: 'Convert',
        icon: DKIcons.ic_convert_3,
        className: 'p-v-0 text-blue underline grid-action-link-h-padding',
        onClick: () => {
          setShowReqConversionPopup(true);
        }
      });
    }

    if (
      documentType === DOC_TYPE.BILL &&
      BuyConfig.showActionButton(
        BUY_CONFIG_BTN.MAKE_PAYMENT,
        documentObject,
        documentType
      ) &&
      GranularPermissionsHelper.hasPermissionFor(
        PERMISSIONS_BY_MODULE.BILL.CREATE_PAYMENT_TXN
      )
    ) {
      menuBtns.push({
        title: 'Pay',
        icon: DKIcons.ic_currency,
        className: 'column text-blue fw-m',
        onClick: () => {
          if (!isDocumentTouched()) {
            setShowPaymentPopup(true);
          }
        }
      });
    }

    if (
      BuyConfig.showActionButton(
        BUY_CONFIG_BTN.RECEIVED,
        documentObject,
        documentType
      ) &&
      ((documentType === DOC_TYPE.ORDER &&
        checkUserPermission(
          PERMISSIONS_BY_MODULE.PURCHASE_ORDER.RECEIVE_GOODS
        )) ||
        (documentType === DOC_TYPE.BILL &&
          GranularPermissionsHelper.hasPermissionFor(
            PERMISSIONS_BY_MODULE.BILL.RECEIVE_GOODS
          )))
    ) {
      if (
        documentObject?.receiptStatus !== RECEIVED_GOODS_STATUS.FULLY_RECEIVED
      ) {
        menuBtns.push({
          title: 'Receive Goods',
          icon: DKIcons.ic_delivery,
          className: 'column text-blue fw-m',
          onClick: () => {
            if (!isDocumentTouched()) {
              if (
                documentObject?.purchaseInvoiceType === 'ASSET' ||
                documentObject?.orderType === 'ASSET'
              ) {
                setReceivedGoodsAssetPopup(true);
              } else setReceivedGoodsPopup(true);
            }
          }
        });
      }
    }
    return menuBtns;
  };

  const getJobWorkoutActionBtns = (document: any) => {
    let menuBtns = [];

    if (
      checkUserPermission(PERMISSIONS_BY_MODULE.STOCK_TRANSFER.CREATE) &&
      checkUserPermission(PERMISSIONS_BY_MODULE.JOB_WORKOUTS.EDIT) &&
      document.dispatchStatus !== JWO_DISPATCH_STATUS.DISPATCHED
    ) {
      menuBtns.push({
        title: 'Dispatch Goods',
        icon: DKIcons.ic_delivery,
        className: 'column text-blue fw-m',
        onClick: () => {
          if (!isDocumentTouched()) {
            showLoader(OPENING_DOCUMENT_LABEL);
            const jwo = documentObject;
            let productIds = jwo?.jobWorkOutOrderItems?.map(
              (item: any) => item.productCode
            );
            ProductService.getProductsByProductIds(productIds)
              .then((response: any) => {
                let allProductDetails = response;
                WorkoutService.fetchJobWorkOutDetails(jwo?.jwoCode).then(
                  (jwodata: any) => {
                    let jobWorkOutDetails = jwodata;
                    if (
                      allProductDetails?.length &&
                      !Utility.isEmpty(jobWorkOutDetails)
                    ) {
                      jobWorkOutDetails?.jobWorkOutOrderItems?.forEach(
                        (workOutItem: any) => {
                          allProductDetails.forEach((product: any) => {
                            if (
                              workOutItem?.productCode === product?.productId
                            ) {
                              workOutItem.product.bomProductsConfiguration =
                                product?.bomProductsConfiguration?.filter(
                                  (item: any) =>
                                    item.produceProductType ===
                                    PRODUCE_PRODUCT_TYPE.NONE
                                );
                            }
                          });
                        }
                      );
                    }

                    let stockTransferItems = {
                      ...jwo,
                      stockTransferItems:
                        jobWorkOutDetails?.jobWorkOutOrderItems,
                      linkedDocuments: [
                        {
                          documentCode: jwo?.jwoCode,
                          documentSequenceCode: jwo?.documentSequenceCode,
                          documentType: DOC_TYPE.JOB_WORK_OUT_ORDER
                        }
                      ]
                    };
                    setStockTransferItems({ ...stockTransferItems });
                    setShowStockTransferPopup(true);
                    removeLoader();
                  },
                  (err) => {
                    console.error(
                      'Error while fetching document details: ',
                      err
                    );
                    removeLoader();
                  }
                );
              })
              .catch((err: any) => {
                console.log(err, 'product fetch failed');
              });
          }
        }
      });
    }

    if (
      checkUserPermission(PERMISSIONS_BY_MODULE.BILL.CREATE) &&
      document.billedStatus !== JWO_BILLED_STATUS.BILLED
    ) {
      menuBtns.push({
        title: 'Create Bill',
        icon: DKIcons.ic_convert_3,
        className: 'p-v-0 text-blue underline grid-action-link-h-padding',
        onClick: () => {
          if (!isOpeningForm) {
            createBill();
          }
        }
      });
    }
    if (
      document.dispatchStatus === JWO_DISPATCH_STATUS.DISPATCHED &&
      document.receiveGoodsStatus !== JWO_RECEIPT_STATUS.FULLY_RECEIVED &&
      checkUserPermission(PERMISSIONS_BY_MODULE.JOB_WORKOUTS.EDIT)
    ) {
      menuBtns.push({
        title: 'Receive Goods',
        icon: DKIcons.ic_delivery,
        className: 'column text-blue fw-m',
        onClick: () => {
          if (!isDocumentTouched()) {
            setReceivedGoodsPopup(true);
          }
        }
      });
    }

    return menuBtns;
  };

  // Change URL to base module URL
  const replaceURLToModuleURL = () => {
    if (DOC_PATH_WITH_ID_REGEX.test(history?.location?.pathname)) {
      if (history?.location?.pathname?.includes(PAGE_ROUTES.SALES_ORDERS)) {
        history.replace(PAGE_ROUTES.SALES_ORDERS);
      } else if (history?.location?.pathname?.includes(PAGE_ROUTES.INVOICES)) {
        history.replace(PAGE_ROUTES.INVOICES);
      }
    }
  };

  const getWorkOrderActionBtns = (doc: any) => {
    let menuBtns = [];

    const mrpProduct = subscriptionDetails?.ProductsEnabled?.find(
      (product: any) => {
        return product === MRP_APP_NAME;
      }
    );
    if (
      checkUserPermission(PERMISSIONS_BY_MODULE.WORK_ORDER.CREATE) &&
      !Utility.isEmpty(mrpProduct) &&
      Utility.isMRPWithURLCheck()
    ) {
      menuBtns.push({
        title: 'Create WO',
        icon: DKIcons.ic_info,
        className: 'p-v-0 text-blue underline grid-action-link-h-padding',
        onClick: async () => {
          let payload: any = {};
          if (doc.documentType === DOC_TYPE.SALES_ORDER) {
            let salesOrderObjectUpdated =
              WorkOrderHelper.deductConvertedQuantityForSOtoWOFlow(doc);
            await validateSelectedSOForInterLinkedWO(
              salesOrderObjectUpdated,
              () => {
                payload = {
                  create: true,
                  salesOrderData: salesOrderObjectUpdated
                };
                dispatch(setCreateWOFromSalesOrder(payload));
                dispatch(removeDraft(documentObject?.id));
                if (props.closeMenuContainer) {
                  props.closeMenuContainer();
                }
                replaceURLToModuleURL();
              }
            );
          } else if (doc.documentType === DOC_TYPE.INVOICE) {
            await validateSelectedInvoiceForInterLinkedWO(doc, () => {
              payload = { create: true, salesInvoiceData: doc };
              dispatch(setCreateWOFromSalesInvoice(payload));
              dispatch(removeDraft(documentObject?.id));
              if (props.closeMenuContainer) {
                props.closeMenuContainer();
              }
              replaceURLToModuleURL();
            });
          }
        }
      });
    }

    return menuBtns;
  };

  const createBill = () => {
    // showLoader('Creating bill…');
    const jwo = documentObject;
    let contactId = jwo?.contactDto?.id;
    let memo: any[] = [];
    jwo?.jobWorkOutOrderItems?.length &&
      jwo?.jobWorkOutOrderItems?.map((item: any) => {
        memo.push(
          `${item.product.name}(Qty: ${item.productQuantity})(Amount: ${item.totalAmount}) \n`
        );
      });
    ContactService.getContactDetailsById(contactId).then(
      (contactInfo: any) => {
        let docDetailsData = billInitialState;
        docDetailsData = {
          ...docDetailsData,
          contact: contactInfo,
          contactCode: contactInfo.code,
          contactDto: contactInfo,
          needToUpdateAddresses: true,
          linkedDocuments: [
            {
              documentCode: jwo?.jwoCode,
              documentSequenceCode: jwo?.documentSequenceCode ?? '',
              documentType: DOC_TYPE.JOB_WORK_OUT_ORDER
            }
          ],
          showBillCustomAlert: jwo?.showBillCustomAlert ?? false,
          memo: memo.join('')
        };
        if (!Utility.isEmpty(docDetailsData)) {
          let payloadData: any = {
            type: LABELS.BILLS,
            title: LABELS.BILLS,
            isMaximized: true,
            isCenterAlign: true,
            populateFormData: docDetailsData,
            isSaved: true
          };

          dispatch(
            createBlankDraft({ payloadData, draftType: DraftTypes.NEW })
          );
          if (props.closeMenuContainer) {
            props.closeMenuContainer();
          }
          removeLoader();
        }
        setIsOpeningForm(false);
      },
      (err: any) => {
        removeLoader();
        setIsOpeningForm(false);
        console.error('Error loading contact details: ', err);
      }
    );
  };

  const checkIsFa = () => {
    if (documentType === DOC_TYPE.BILL || documentType === DOC_TYPE.ORDER) {
      if (documentType === DOC_TYPE.BILL) {
        if (documentObject?.purchaseInvoiceType === BUY_TYPE.ASSET) {
          return true;
        }
      }
      if (documentType === DOC_TYPE.ORDER) {
        if (documentObject?.orderType === BUY_TYPE.ASSET) {
          return true;
        }
      }
    }
    return false;
  };

  const parentChildInteraction = (passingData: CallBackPayloadType) => {
    const paymentUpdated = showPaymentPopup || showPaymentRecord;
    switch (passingData.type) {
      case POPUP_CALLBACKS_TYPE.RECEIVE_PAYMENT:
        addDocRef.current.storeCallbacksRef.receivePayment = passingData.data;
        break;
      case POPUP_CALLBACKS_TYPE.BILL_PAYMENT:
        addDocRef.current.storeCallbacksRef.billPayment = passingData.data;
        break;
      case POPUP_CALLBACKS_TYPE.FULFILLMENT:
        addDocRef.current.storeCallbacksRef.fulfillment = passingData.data;
        break;
      case POPUP_CALLBACKS_TYPE.DIRECT_FULFILLMENT:
        setShowFulfillmentPopup(true);
        setShowFulfillmentConfirmationPopup(false);
        break;
      case POPUP_CALLBACKS_TYPE.DROPSHIP_FULFILLMENT:
        setShowFulfillmentPopup(false);
        setShowFulfillmentConfirmationPopup(false);
        setShowDropShipWarningPopup(true);

        break;
      case POPUP_CALLBACKS_TYPE.PICK_PACK_SHIP:
        setShowFulfillmentConfirmationPopup(false);
        RouteManager.navigateToPage(PAGE_ROUTES.PICK_PACK_SHIP);
        break;
      case POPUP_CALLBACKS_TYPE.RECEIVED_GOODS:
        addDocRef.current.storeCallbacksRef.receivedGoods = passingData.data;
        break;
    }
  };

  return (
    <div
      className={
        documentType === DOC_TYPE.REQUISITION || checkIsFa()
          ? `row position-relative justify-content-center pointer-events-auto ${
              props.className ? props.className : ''
            }`
          : `row position-relative pointer-events-auto ${
              props.className ? props.className : ''
            }`
      }
    >
      {isMenuOpen && (
        <DKListPicker2
          title=""
          data={menuItems}
          style={{
            width: 'auto'
          }}
          allowSearch={false}
          className="position-absolute z-index-3 top-9 right-0 shadow-m"
          onSelect={(index: number, action: any) => {
            if (action.onClick) {
              action.onClick();
            }
          }}
          onClose={() => {
            setTimeout(() => {
              setIsMenuOpen(false);
            }, 100);
          }}
          renderer={(index: number, obj: any) => {
            return (
              <div className="row parent-width">
                <DKIcon src={obj.icon} className="ic-s" />
                <div className="ml-s">{`${obj.title}`}</div>
              </div>
            );
          }}
        />
      )}

      {props.position === 'top' && (
        <DKButton
          icon={
            draftType === DraftTypes.READONLY
              ? DKIcons.ic_arrow_down2
              : DKIcons.white.ic_arrow_down2
          }
          className={`border-radius-m ${
            draftType === DraftTypes.READONLY ? 'border-m' : ''
          }`}
          style={{
            paddingleft: 0
          }}
          disabled={props.auditLogView}
          onClick={() => setIsMenuOpen(!isMenuOpen)}
        />
      )}
      {props.position === 'bottom' && (
        <div className="row flex-wrap justify-content-start gap-2">
          {menuItems.map((menuItem, index) => {
            return (
              <DKButton
                title={menuItem.title}
                icon={menuItem.icon}
                className={`row border-m`}
                disabled={props.auditLogView}
                onClick={() => menuItem.onClick()}
              />
            );
          })}
        </div>
      )}
      {showPaymentPopup && (
        <PaymentActionPopup
          removePopup={() => {
            closeAllPopups();
          }}
        />
      )}
      {showPaymentRecord && (
        <PaymentRecordsActionPopup
          removePopup={() => {
            closeAllPopups();
          }}
        />
      )}
      {showFulfillmentConfirmationPopup && (
        <FulfillmentConfirmationActionPopup
          removePopup={() => {
            closeAllPopups();
          }}
          passingInteraction={(callback: CallBackPayloadType, data: any) => {
            parentChildInteraction(callback);
          }}
          closeMenuContainer={() => {
            closeAllPopups();
          }}
          directFulfillmentClicked={() => {
            setShowFulfillmentPopup(true);
            setShowFulfillmentConfirmationPopup(false);
          }}
        />
      )}

      {showFulfillmentPopup && (
        <FulfillmentActionPopup
          booksDocument={booksDocument}
          removePopup={() => {
            closeAllPopups();
          }}
        />
      )}

      {showDropShipWarningPopup && (
        <DropshipWarningActionPopup
          removePopup={() => {
            closeAllPopups();
          }}
        />
      )}

      {showReceivedGoodsPopup && (
        <ReceiveGoodsActionPopup
          removePopup={() => {
            closeAllPopups();
          }}
        />
      )}

      {showReceivedGoodsAssetPopup && (
        <ReceivedGoodsAssetActionPopup
          removePopup={() => {
            closeAllPopups();
          }}
        />
      )}

      {showQuoteToInvoiceConversionPopup && (
        <QuoteToInvoiceActionPopup
          removePopup={() => {
            closeAllPopups();
          }}
          closeMenuContainer={() => {
            if (props.closeMenuContainer) {
              props.closeMenuContainer();
            }
          }}
        />
      )}
      {showQuoteToSOConversionPopup && (
        <QuoteToSalesOrderActionPopup
          removePopup={() => {
            closeAllPopups();
          }}
          closeMenuContainer={() => {
            if (props.closeMenuContainer) {
              props.closeMenuContainer();
            }
          }}
        />
      )}
      {showSOConversionPopup && (
        <SalesOrderToInvoiceActionPopup
          removePopup={() => {
            closeAllPopups();
          }}
          closeMenuContainer={() => {
            if (props.closeMenuContainer) {
              props.closeMenuContainer();
            }
          }}
        />
      )}
      {showPOConversionPopup && (
        <PurchaseOrderToBillActionPopup
          removePopup={() => {
            closeAllPopups();
          }}
          closeMenuContainer={() => {
            if (props.closeMenuContainer) {
              props.closeMenuContainer();
            }
          }}
        />
      )}
      {showReqConversionPopup && (
        <RequisitionToPurchaseOrderActionPopup
          removePopup={() => {
            closeAllPopups();
          }}
          closeMenuContainer={() => {
            if (props.closeMenuContainer) {
              props.closeMenuContainer();
            }
          }}
        />
      )}
      {showStockTransferPopup && (
        <AddStockTransferActionPopup
          removePopup={() => {
            closeAllPopups();
          }}
          stockTransferItems={stockTransferItems}
          closeMenuContainer={() => {
            if (props.closeMenuContainer) {
              props.closeMenuContainer();
            }
          }}
        />
      )}
    </div>
  );
}
