import React, { Component } from "react";
import "../../index.css";
import "../../CommonStyles/FontStyle.css";
import {
    COLOR_LIGHTGRAY,
    COLOR_LIGHTGRAY2,
    EditorInfoType,
    FONT_SIZE
} from '../../Constants/Constants';
import DKCustomFieldSection from "../../DKUILibrary/DKCustomFieldSection";
import DKEInvoiceSection, { eInvoiceSectionPosition } from "../../DKUILibrary/DKEInvoiceSection";
import DKSignatureSection from "../../DKUILibrary/DKSignatureSection";
import { AddressType } from "../../Helper/AddressParser";
import ComponentManager from "../../Manager/ComponentManager";
import DocumentManager from '../../Manager/DocumentManager';
import { getLocalisedText } from '../../Translate/LanguageManager';
import { HTMLTag } from "../../Utilities/HTMLTag";
import Utility, { getFontSizeClass, getIsFulfillment, getIsGoodsReceipt, getPageHeight, getVW } from '../../Utilities/Utility';
import TopSectionAboveTableUtility from "../../Utilities/TopSectionAboveTableUtility";
import DKComponentListTable from "../../DKUILibrary/DKComponentListTable";
import DKApprovalSection from "../../DKUILibrary/DKApprovalSection";

export default class DocumentTemplate29 extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: this.props.data,
        };
    }

    componentWillReceiveProps(nextProp) {
        this.setState({
            data: nextProp.data,
        });
    }

    getRowValueSection(title, value, titleColor = "black") {
        return (
            <div
                className="RowDiv"
                style={{
                    justifyContent: "space-evenly",
                    flex: 1,
                }}
            >
                <text className={"RowReverseDiv docFontStyleBold " + getFontSizeClass()}
                    style={{
                        color: titleColor,
                        width: '50%',
                        justifyContent: 'end',
                        paddingRight: getVW(Utility.convertToPrintScale(20, this.state.data.printScale)),
                        ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular }, this.state.data.printScale),
                    }}>
                    {title.toUpperCase()}
                </text>
                <div
                    className="RowDiv"
                    style={{
                        flexDirection: 'row',
                        border: 'solid',
                        borderColor: 'lightgray',
                        borderWidth: getVW(1),
                        width: '50%'
                    }}>
                    <text className={"RowDiv docFontStyle" + getFontSizeClass()}
                        style={{
                            paddingLeft: getVW(Utility.convertToPrintScale(10, this.state.data.printScale)),
                            height: getVW(Utility.convertToPrintScale(25, this.state.data.printScale)),
                            justifyContent: Utility.getIsArabicLang() ? 'flex-end' : 'center',
                            paddingRight: Utility.getIsArabicLang() ? getVW(Utility.convertToPrintScale(10, this.state.data.printScale)) : undefined,
                            ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular }, this.state.data.printScale),
                        }}>
                        {value}
                    </text>
                </div>
            </div>
        );
    }

    getTableSection() {
        return (
            <div className="ColumnDiv" style={{ width: '100%' }}>
                {
                    TopSectionAboveTableUtility.getIsTopSectionAboveTableIsVisible() &&
                    <>
                        <div style={{ ...TopSectionAboveTableUtility.getTopSectionWithTableFontStyleProp(undefined, this.state.data.printScale) }}
                            dangerouslySetInnerHTML={{ __html: TopSectionAboveTableUtility.getHtmlString(this.state.data) }} />
                        {ComponentManager.addVerticalSpace(5)}
                    </>
                }
                {
                    Utility.getIsShowAccountProductTable(this.state.data) && 
                        <div className="RowDiv">
                            {this.getProductTable()}
                        </div>
                }
                {Utility.getIsShowAccountProductTable(this.state.data) && ComponentManager.addVerticalSpace(20)}
                <div className="RowDiv">
                    {this.getEditableDocumentTable()}
                </div>
                {/*{ComponentManager.addVerticalSpace(40)}*/}
                {/*{ComponentManager.addVerticalSpace(170)}*/}
            </div>
        )
    }

    getCompanyNameLabel() {
        return (
            <div className="RowDiv"
                style={{
                    alignItems: 'flex-start',
                    width: Utility.getIsArabicLang() ? getVW(`${Utility.convertToPrintScale(12.5, this.state.data.printScale)}vw`, true) : getVW(`${Utility.convertToPrintScale(20, this.state.data.printScale)}vw`, true),
                    justifyContent: Utility.getIsArabicLang() ? 'flex-end' : 'flex-start',
                }}>
                <text className={"docFontStyleBold" + getFontSizeClass()}
                    style={{
                        paddingBottom: getVW(5),
                        color: this.state.data.themeColor,
                        ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.medium }, this.state.data.printScale),
                    }}>
                        {getLocalisedText(Utility.getCompanyName(this.state.data))}
                </text>
            </div>
        )
    }

    getCompanyInformationView() {
        return (
            <div className="ColumnDiv" style={{ width: getVW(`${Utility.convertToPrintScale(20, this.state.data.printScale)}vw`, true)}}>
                <div className={"docFontStyle" + getFontSizeClass()}
                    style={{
                        whiteSpace: 'pre-wrap',
                        ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular, ownerType: Utility.getOwnerType(this.state.data, AddressType.from) }, this.state.data.printScale),
                    }}
                    dangerouslySetInnerHTML={{__html: getLocalisedText(Utility.getCompanyAddress(this.state.data))}}>
                </div>
            </div>
        )
    }

    getInvoiceDetailsView() {
        return (
            <div className="RowDiv" style={{ justifyContent: 'flex-end', width: '100%' }}>
                <div className="ColumnDiv" style={{ justifyContent: 'space-between', width: '80%', height: '100%' }}>
                    {this.getDocumentDetailsSection()}
                </div>
            </div>
        )
    }

    getDocumentDetailsSection() {
        var documentDetailList = Utility.getDocumentDetailsList(this.state.data)
        return documentDetailList.map(element => {
            var value = ''
            if (element.key !== undefined) {
                value = this.state.data[element.key]
            }
            else if (element.customFieldValue !== undefined) {
                value = element.customFieldValue
            }
            return this.getRowValueSection(
                element.label,
                value,
            )
        });
    }

    getDocumentTypeLabel() {
        return (
            <div className="RowDiv" style={{ justifyContent: 'flex-end', top: 0 }}>
                <text
                    style={{
                        fontWeight: "bold",
                        textAlign: "right",
                        color: this.state.data.themeColor,
                        ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.large }, this.state.data.printScale),
                    }}
                >
                    {getLocalisedText(this.state.data.documentType)}
                </text>
            </div>
        )
    }


    addLeftView() {
        return (
            <div className="ColumnDiv" style={{ width: getVW(`${Utility.convertToPrintScale(50, this.state.data.printScale)}vw`, true)}}>
                {this.state.data.showCompanyName && this.getCompanyNameLabel()}
                {this.state.data.showFrom && this.getCompanyInformationView()}
                {ComponentManager.addVerticalSpace(Utility.convertToPrintScale(110, this.state.data.printScale))}
                {this.state.data.showClientBillToAddress && ComponentManager.getDescriptionSection(
                    this.state.data.billToTitle,
                    Utility.getClientBillToAddress(this.state.data),
                    "35%",
                    this.state.data.themeColor,
                    true,
                    '#000000',
                    getVW(6),
                    Utility.getOwnerType(this.state.data, AddressType.billTo),
                    this.state.data.printScale
                )}
                    {ComponentManager.addVerticalSpace(Utility.convertToPrintScale(20, this.state.data.printScale))}
                    {this.getPlaceOfSupplyInfoSection()}
            </div>
        )
    }

    addRightView() {
        return (
            <div className="ColumnDiv" style={{ alignItems: 'flex-end', width: getVW(`${Utility.convertToPrintScale(50, this.state.data.printScale)}vw`, true)}}>
                {this.getDocumentTypeLabel()}
                {ComponentManager.addVerticalSpace(20)}
                {ComponentManager.getLogoVisibility(this.state.data.showCompanyLogo, this.state.data.isReadOnlyMode, this.state.data.logo) && ComponentManager.getLogo(this.state.data.logo, Utility.convertToPrintScale(80, this.state.data.printScale), {position: 'static'})}
                {ComponentManager.addVerticalSpace(20)}
                {this.getInvoiceDetailsView()}
            </div>
        )
    }

    addHeaderSection() {
        return (
            <div className="RowDiv" style={{ width: '100%', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                {this.addLeftView()}
                {this.addRightView()}
            </div>
        )
    }

    getTermsAndConditions() {
        return (
            <div id={HTMLTag.TERMS_NOTE_PREFIX + HTMLTag.TERMS} style={{ bottom: 0, width: '100%' }}>
                {this.state.data.termsAndCondition &&
                    ComponentManager.getTermsAndConditionOrNotesSection(
                        Utility.getTermsAndConditionTitle(getLocalisedText('terms_&_condition')),
                        this.state.data.termsAndCondition,
                        "100%",
                        'gray',
                        undefined,
                        undefined,
                        getVW(Utility.convertToPrintScale(6, this.state.data.printScale)),
                        this.state.data.printScale,
                        Utility.getTnCOrNotesFontSettingsByType(EditorInfoType.termsAndCondition)
                    )}
                {ComponentManager.addVerticalSpace('1vw')}
            </div>
        )
    }

    render() {
        return (
            <div className='DocumentHolder ColumnDiv' style={{ justifyContent: 'space-between', minHeight: getPageHeight(this.state.data.printScale) }}>
                <div className='parent-width' style={{ width: '100%', paddingBottom: getVW(30)  }}>
                    <DKEInvoiceSection
                        data={this.state.data}
                        position={eInvoiceSectionPosition.top}
                    />
                    {this.addHeaderSection()}
                    {(Utility.getIsShowSourceOfSupply(this.state.data) || Utility.getIsShowDestinationOfSupply(this.state.data)) && ComponentManager.addVerticalSpace(10)}
                    {(Utility.getIsShowSourceOfSupply(this.state.data) || Utility.getIsShowDestinationOfSupply(this.state.data)) && this.getSourceOfSupplyInfoSection()}
                    {Utility.getIsContainsCustomField('top') && ComponentManager.addVerticalSpace(15)}
                    <DKCustomFieldSection data={this.state.data} onCustomFieldClick={this.props.onCustomFieldClick} customFieldPosition={'top'}/>
                    {ComponentManager.addVerticalSpace(15)}
                    {this.getTableSection()}
                    {Utility.getIsShowExchangeRate(this.state.data) && ComponentManager.addVerticalSpace(20)}
                    {Utility.getIsShowExchangeRate(this.state.data) && this.getExchangeRateTable(this.getTableStyle(), this.state.data)}
                    {Utility.getIsContainsCustomField('bottom') && ComponentManager.addVerticalSpace(15)}
                    <DKCustomFieldSection data={this.state.data} onCustomFieldClick={this.props.onCustomFieldClick} customFieldPosition={'bottom'} />
                    {Utility.getIsIncludesBomProductTable() && <DKComponentListTable data={this.state.data} />}
                    <div className="RowReverseDiv" id={HTMLTag.SIGNATURE}>
                        <div className="ColumnDiv">
                            {ComponentManager.addVerticalSpace(Utility.convertToPrintScale(30, this.state.data.printScale))}
                            {this.state.data.showSignature && ComponentManager.getSignature(this.state.data.signature, this.state.data.printScale)}
                        </div>
                    </div>
                </div>
                <div className='parent-width' style={{ width: '100%' }}>
                    {(getIsFulfillment(this.state.data.type) || getIsGoodsReceipt(this.state.data.type)) && <DKSignatureSection data={this.state.data} onSignatureSectionClicked={this.props.tableClicked} />}
                    {(this.state.data.showTermsAndCondition && this.state.data.termsAndCondition) && this.getTermsAndConditions()}
                    {Utility.getIsShowApprovalSection() && <DKApprovalSection data={this.state.data} style={this.getTableStyle()} />}
                </div>
                <DKEInvoiceSection
                    data={this.state.data}
                    position={eInvoiceSectionPosition.bottom}
                />
            </div>
        );
    }

    getEditableDocumentTable() {
        return ComponentManager.getDKDocumentTable(
            this.props.tableColumn,
            this.getTableStyle(),
            this.props.data,
            this.props.tableClicked,
            this.props.stockTableClicked,
            this.props.onUpdateDataCallback,
            this.props.paymentTableClicked,
            this.props.additionalTableCallback,
        )
    }

    getProductTable() {
        if(Utility.getIsShowAccountProductTable(this.state.data)) {
            return ComponentManager.getDKProductTable(
                Utility.getTableColumnForAccountProduct(),
                this.getTableStyle(),
                this.props.data,
                this.props.tableClicked,
                this.props.stockTableClicked,
                this.props.onUpdateDataCallback,
                this.props.paymentTableClicked,
                this.props.additionalTableCallback,
            )
        }
    }

    getTableStyle() {
        let style = DocumentManager.getTableDefaultStyle();
        style.headerBackgroundColor = '#ffffff';
        style.headerTextColor = this.state.data.themeColor;
        style.headerBorderColor = COLOR_LIGHTGRAY2;
        style.itemBorderColor = COLOR_LIGHTGRAY2;
        style.itemBackgroundColor = COLOR_LIGHTGRAY;
        style.itemBackgroundColorIsAlternate = true;
        style.footerBackgroundColor = COLOR_LIGHTGRAY;
        style.footerTextColor = "black";
        style.footerBorderColor = "transparent";
        style.subTotalBorderColor = 'transparent';
        return style;
    }

    getPlaceOfSupplyInfoSection() {
        return (
            <div
                className="RowDiv"
                style={{
                    alignItems: "flex-start",
                    justifyContent: 'space-between',
                    backgroundColor: "white",
                }}
            >
            {this.getPlaceOfSupply()}

            </div>
        )
    }
    getPlaceOfSupply() {
        return <div className='RowDiv' style={{ width: '70%' }}>
            <div className='ColumnDiv' style={{
                width: '100%'
            }}>
                {Utility.getIsShowPlaceOfSupply(this.state.data) && ComponentManager.getDescriptionSection(
                    getLocalisedText('place_of_supply'),
                    this.state.data.placeOfSupply,
                    "100%",
                    this.state.data.themeColor,
                    false,
                    '#000000',
                    getVW(6),
                    true,
                    this.state.data.printScale
                )}
            </div>

        </div>;

    }


    getSourceOfSupplyInfoSection() {
        return (
            <div
                className="RowDiv"
                style={{
                    alignItems: "flex-start",
                    justifyContent: 'space-between',
                    backgroundColor: "white",
                }}
            >
                {this.getSourceOfSupply()}
            </div>
        )
    }

 getSourceOfSupply() {
        return <div className='RowDiv' style={{ width: '70%' }}>
            <div className='ColumnDiv' style={{
                width: '40%'
            }}>
                {Utility.getIsShowSourceOfSupply(this.state.data) && ComponentManager.getDescriptionSection(
                    getLocalisedText('source_of_supply'),
                    this.state.data.sourceOfSupply,
                    "100%",
                    this.state.data.themeColor,
                    false,
                    '#000000',
                    getVW(6),
                    true,
                    this.state.data.printScale
                )}
            </div>
            <div className='ColumnDiv' style={{
                width: '40%'
            }}>
                {Utility.getIsShowDestinationOfSupply(this.state.data) && ComponentManager.getDescriptionSection(
                    getLocalisedText('destination_of_supply'),
                    this.state.data.destinationOfSupply,
                    "100%",
                    this.state.data.themeColor,
                    false,
                    '#000000',
                    getVW(6),
                    true,
                    this.state.data.printScale
                )}
          </div>

        </div>;
    }

    getExchangeRateTable(tableStyle, data) {
        return (
            <div id={HTMLTag.EXCHANGE_RATE} className="RowDiv" style={{ justifyContent: "flex-end" }}>
                <div style={{ width: "45%" }}>
                    {ComponentManager.getExchangeRateTable(tableStyle, data)}
                </div>
            </div>
        );
    }
}

