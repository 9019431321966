import { isEqual } from 'lodash';
import { RootState, Store } from '../../../../Redux/Store';
import { AnyDocument, DraftTypes } from '../../../../Models/Drafts';
import {
  COMPLAINCE_CURRENCY,
  COUNTRY_CODES,
  CURRENCIES,
  DOCUMENT_MODE,
  DOC_TYPE,
  TAX_SYSTEM
} from '../../../../Constants/Constant';
import Utility from '../../../../Utility/Utility';
import {
  getIndianStateOptions,
  getTaxFromProduct,
  updateCurrencyAndExchangeRate
} from '../Common/DocDataHelper';
import AuthService from '../../../../Services/Auth';
import {
  checkContact,
  getComponentDetailsForDocumentLineItems,
  getTenantTaxSystem,
  isGSTExchangeRateRequired
} from '../../../../SharedComponents/DocumentForm/NewDocumentHelper';
import { getDocumentByIDFromStore } from '../DocumentHelper';
import {
  isPriceBookEnabled,
  isPriceListEnabled,
  isPurchaseDocument,
  isSalesDocument
} from '../../Utilities/DocCommonUtils';
import { loadAccountGroupsForCashInvoice } from '../Common/CashInvoiceHelper';
import {
  calculateTaxesAndAmountsForAllLineItems,
  updateDocOnContactChange,
  updateMultipleKeysInDocument
} from '../../../../Redux/Slices/DocumentSlice';
import {
  DOCUMENT_KEYS,
  countriesWithTaxDependantOnContact
} from '../../Utilities/DocConstants';
import { CHANGE_TYPE, updatePriceFromPriceList } from './PriceListHelper';
import { calculateTaxesForUS } from '../DocRowHelper';
import {
  COMMON_EVENTS,
  commonCustomEvent,
  DOC_POPUP_TYPE
} from '../../../../Services/event/commonEvents';

// Handle updates on contact change
export const onDocContactChange = async (data: {
  draftId: number;
  isContactChanged: boolean;
  newContact: any;
  documentMode: DOCUMENT_MODE;
  draftType: DraftTypes;
  isCashInvoice: boolean;
}) => {
  const {
    draftId,
    newContact,
    documentMode,
    draftType,
    isContactChanged,
    isCashInvoice
  } = data;
  const oldDocument = getDocumentByIDFromStore(draftId)?.populateFormData;
  if (isContactChanged && oldDocument) {
    let tempDocument: AnyDocument = { ...oldDocument } as AnyDocument;
    const updateAddresses =
      Utility.isEmpty(oldDocument?.contact) ||
      !isEqual(oldDocument?.contact, newContact) ||
      oldDocument.isPartialInvoice ||
      oldDocument.isPartialBill;

    const activeMultiCurrencyList = (Store.getState() as RootState).commonData
      .data.currencyListWithExchangeRateByDocDate;

    tempDocument = {
      ...tempDocument,
      contact: newContact,
      contactDto: newContact,
      contactCode: newContact.code
    };

    if (
      documentMode === DOCUMENT_MODE.NEW ||
      documentMode === DOCUMENT_MODE.COPY
    ) {
      tempDocument = setCurrencyAndExchangeRateUpdates(
        draftId,
        documentMode,
        tempDocument,
        newContact,
        activeMultiCurrencyList
      );
    }

    tempDocument = setGSTExchangeRate(tempDocument, activeMultiCurrencyList);

    // Open tax exchange rate popup for SG
    if (
      !isTenantAndCurrencySg() &&
      showGstCurrencyTax() &&
      documentMode !== DOCUMENT_MODE.VIEW &&
      !Utility.isEmpty(newContact)
    ) {
      let currencyCode = newContact.currencyCode;
      if (isGSTExchangeRateRequired(getTenantTaxSystem(), currencyCode)) {
        commonCustomEvent.dispatch(COMMON_EVENTS.DOC_POPUP_SHOW, {
          type: DOC_POPUP_TYPE.SHOW_TAX_EXCHANGE_RATE_POPUP
        });
      }
    }

    // Update gstType, addresses etc.
    tempDocument = checkContact(
      { ...tempDocument },
      { ...newContact },
      !!updateAddresses,
      true
    );

    // Set payment term for document
    tempDocument = setPaymentTermForDocument(tempDocument, newContact);

    // Set buyer reference
    tempDocument = updateContactBuyerReference(tempDocument, newContact);

    tempDocument = setPlaceOfSupplyAndDestinationOfSupply(
      tempDocument,
      newContact
    );

    // If isCashInvoice then update the account groups list for payment
    if (isCashInvoice) {
      updateAccountGroupsForCashInvoice(draftId, tempDocument);
    }

    tempDocument = await updateLineItems(
      oldDocument,
      tempDocument,
      documentMode,
      draftType
    );

    // Set isDocumentTouched when contact is selected
    tempDocument = {
      ...tempDocument,
      isDocumentTouched: true
    };

    Store.dispatch(
      updateDocOnContactChange({ draftId, updatedObj: tempDocument })
    );

    if (getTenantTaxSystem() === TAX_SYSTEM.US) {
      calculateTaxesForUS({ draftId, indexToUpdate: undefined });
    } else {
      Store.dispatch(calculateTaxesAndAmountsForAllLineItems({ draftId }));
    }

    if (isPriceListEnabled() || isPriceBookEnabled()) {
      const contactColumnConfig = Store.getState().contacts.columnConfig || [];
      // Price List Call: for contact change
      updatePriceFromPriceList({
        draftId,
        documentMode,
        change: {
          type: CHANGE_TYPE.CONTACT_CHANGED,
          rowIndex: null
        },
        contactColumnConfig: [...contactColumnConfig]
      });
    }
  }
};

const isTenantAndCurrencySg = (): boolean => {
  const tenantInfo = AuthService.currentTenantInfo;
  return (
    tenantInfo &&
    tenantInfo.currency === CURRENCIES.SG &&
    tenantInfo.country === COUNTRY_CODES.SG
  );
};

const showGstCurrencyTax = () => {
  const tenantInfo = AuthService.currentTenantInfo;
  return isGSTExchangeRateRequired(
    tenantInfo && tenantInfo.country,
    tenantInfo && tenantInfo.currency
  );
};

const updateLineItems = async (
  oldDocument: AnyDocument,
  tempDocument: AnyDocument,
  documentMode: DOCUMENT_MODE,
  draftType: DraftTypes
) => {
  tempDocument = {
    ...tempDocument,
    items: tempDocument.items?.map((item: any) => {
      const tdsInfoIndia =
        documentMode === DOCUMENT_MODE.EDIT ||
        documentMode === DOCUMENT_MODE.VIEW ||
        (draftType === DraftTypes.DRAFT &&
          oldDocument?.contact?.code === tempDocument.contactCode)
          ? item.tdsInfoIndia
          : undefined;
      let tax =
        documentMode === DOCUMENT_MODE.NEW ||
        documentMode === DOCUMENT_MODE.COPY
          ? !Utility.isEmpty(item.tax)
            ? countriesWithTaxDependantOnContact.includes(
                AuthService.currentTenantInfo?.country
              )
              ? getTaxFromProduct(
                  { ...item.product },
                  tempDocument.documentType,
                  tempDocument.contact,
                  tempDocument.documentDate
                )
              : item.tax
            : getTaxFromProduct(
                { ...item.product },
                tempDocument.documentType,
                tempDocument.contact,
                tempDocument.documentDate
              )
          : !Utility.isEmpty(item.tax)
          ? item.tax
          : null;
      return { ...item, gstType: tempDocument.gstType, tdsInfoIndia, tax };
    })
  };

  // Update component list details on contact change
  if (
    Utility.isComponentDetailsForFGOnInvoiceSOQuote() &&
    Utility.isNotEmpty(tempDocument?.items?.[0]?.product) &&
    (documentMode === DOCUMENT_MODE.NEW ||
      documentMode === DOCUMENT_MODE.COPY) &&
    !(tempDocument?.isPartialInvoice || tempDocument?.isPartialSalesOrder)
  ) {
    const lineItems = await getUpdatedProductRowsForComponentListDetails(
      tempDocument?.items || [],
      tempDocument?.contact,
      tempDocument?.documentType
    );
    tempDocument = {
      ...tempDocument,
      items: lineItems ? [...lineItems] : []
    };
  }
  return tempDocument;
};

const getUpdatedProductRowsForComponentListDetails = async (
  productRowsArr: any,
  contactObj: any,
  documentType: DOC_TYPE
) => {
  try {
    let updatedProductRows = await getComponentDetailsForDocumentLineItems(
      productRowsArr,
      contactObj,
      documentType
    );

    return updatedProductRows ? [...updatedProductRows] : [];
  } catch (err: any) {
    console.error('Error fetching component details: ', err);
  }
};

const updateAccountGroupsForCashInvoice = async (
  draftId: any,
  tempDocument: AnyDocument
) => {
  try {
    const response = await loadAccountGroupsForCashInvoice(
      tempDocument.currency || AuthService.currentTenantInfo?.currency,
      tempDocument.documentType
    );
    Store.dispatch(
      updateMultipleKeysInDocument({
        draftId,
        keysToUpdate: {
          [DOCUMENT_KEYS.PAYMENT_ACCOUNT_GROUP_OPTIONS]:
            response?.paymentMethodOptions || [],
          [DOCUMENT_KEYS.ACCOUNT_GROUP_FOR_PAYMENT]:
            response?.defaultAccountGroupOption || undefined
        }
      })
    );
  } catch (err: any) {
    console.error(
      'ContactChangeHelper -> Error loading account groups for cash invoice: ',
      err
    );
  }
};

const setCurrencyAndExchangeRateUpdates = (
  draftId: number,
  documentMode: DOCUMENT_MODE,
  tempDoc: AnyDocument,
  newContact: any,
  activeMultiCurrencyList: any[]
) => {
  const contactCurrency = activeMultiCurrencyList.find(
    (currency) => currency.currencyCode === newContact.currencyCode
  );
  if (typeof contactCurrency !== 'undefined' && contactCurrency !== null) {
    let currencyUpdatedDoc = updateCurrencyAndExchangeRate(
      draftId,
      documentMode,
      tempDoc,
      contactCurrency.currencyCode,
      contactCurrency.currencyExchangeRate,
      false
    );
    tempDoc = {
      ...tempDoc,
      ...currencyUpdatedDoc
    };
  }
  return tempDoc;
};

const setGSTExchangeRate = (
  tempDoc: AnyDocument,
  activeMultiCurrencyList: any[]
) => {
  if (
    AuthService.currentTenantInfo?.multicurrencyEnabled ||
    isGSTExchangeRateRequired(
      getTenantTaxSystem(),
      AuthService.currentTenantInfo?.currency
    )
  ) {
    if (COMPLAINCE_CURRENCY[AuthService.userDetails?.country]) {
      const currencyDetails = activeMultiCurrencyList.find(
        (x: any) =>
          x.currencyCode ===
          COMPLAINCE_CURRENCY[AuthService.userDetails?.country]
      );
      if (currencyDetails) {
        const gstExchangeRate = currencyDetails.currencyExchangeRate;
        tempDoc = {
          ...tempDoc,
          gstExchangeRate: 1 / gstExchangeRate
        };
      }
    }
  }
  return tempDoc;
};

const setPlaceOfSupplyAndDestinationOfSupply = (
  tempDocument: AnyDocument,
  contact: any
) => {
  if (getTenantTaxSystem() === TAX_SYSTEM.INDIA_GST) {
    if (isSalesDocument(tempDocument.documentType)) {
      let state = !Utility.isEmpty(tempDocument?.shipTo?.placeOfSupply)
        ? tempDocument?.shipTo?.placeOfSupply
        : tempDocument?.shipTo?.state;
      let pos = getIndianStateOptions(contact).filter(
        (ele: any) => ele?.value?.toLowerCase() === state?.toLowerCase()
      );
      let shipToAddress = {
        ...tempDocument.shipTo,
        placeOfSupply: pos ? pos?.[0]?.value : ''
      };
      tempDocument = {
        ...tempDocument,
        shipTo: shipToAddress,
        placeOfSupply: pos ? pos?.[0]?.value : ''
      };
    }
    if (
      isPurchaseDocument(tempDocument.documentType) ||
      Utility.isDropship(tempDocument)
    ) {
      let posState: any = !Utility.isEmpty(
        tempDocument?.shipFrom?.placeOfSupply
      )
        ? tempDocument?.shipFrom?.placeOfSupply
        : tempDocument?.shipFrom?.state;

      let pos = getIndianStateOptions(contact).filter(
        (ele: any) => ele?.value?.toLowerCase() === posState?.toLowerCase()
      );
      let shipFrom = !Utility.isEmpty(tempDocument.shipFrom)
        ? tempDocument.shipFrom
        : AuthService.currentTenantInfo?.shippingAddresses?.length
        ? AuthService.currentTenantInfo?.shippingAddresses?.[0]
        : null;
      let shipFromAddress = {
        ...shipFrom,
        placeOfSupply: pos ? pos?.[0]?.value : ''
      };
      tempDocument = { ...tempDocument, shipFrom: shipFromAddress };

      let dosState: any = !Utility.isEmpty(
        tempDocument?.shipTo?.destinationOfSupply
      )
        ? tempDocument?.shipTo?.destinationOfSupply
        : tempDocument?.shipTo?.state;

      let dos = getIndianStateOptions(contact).filter(
        (ele: any) => ele?.value?.toLowerCase() === dosState?.toLowerCase()
      );
      let shipToAddress = {
        ...tempDocument.shipTo,
        destinationOfSupply: dos ? dos?.[0]?.value : ''
      };
      tempDocument = { ...tempDocument, shipTo: shipToAddress };
    }
  }
  return tempDocument;
};

const setPaymentTermForDocument = (tempDocument: AnyDocument, contact: any) => {
  const allPaymentTerms: any[] = (Store.getState() as RootState).paymentTerms
    ?.data?.content;
  if (
    !Utility.isEmpty(contact) &&
    !Utility.isEmpty(allPaymentTerms) &&
    allPaymentTerms?.length
  ) {
    const paymentTerm = allPaymentTerms?.find(
      (paymentTerm: any) => String(paymentTerm.id) === contact.paymentTermCode
    );
    tempDocument = {
      ...tempDocument,
      paymentTerm: !Utility.isEmpty(paymentTerm) ? paymentTerm?.termName : ''
    };
  }
  return tempDocument;
};

const updateContactBuyerReference = (
  tempDocument: AnyDocument,
  contact: any
) => {
  const contactCF = contact?.customField?.find(
    (cf: any) => cf?.label === 'Buyer Reference'
  );
  if (!Utility.isEmpty(contactCF)) {
    const buyerRefValue = contactCF?.value;
    tempDocument = {
      ...tempDocument,
      buyerReference: buyerRefValue
    };
  }
  return tempDocument;
};
