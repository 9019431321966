import { useState } from 'react';
import { DKLabel, DKListPicker2 } from 'deskera-ui-library';
import Utility from '../../../../Utility/Utility';
import { getStatusChipColor } from '../../WorkOrder/WorkOrderHelper';
import { JOB_CARD_STATUS } from '../../Constants/MRPColumnConfigs';
import { JC_PROCESS_TYPE } from './JobCardListPresenter';
interface IJCQcNeededProps {
  column: any;
  row: any;
  rowIndex: number;
  products: any[];
  onQCToggle: (qcNeeded: boolean) => void;
}
const JCQcNeeded: React.FC<IJCQcNeededProps> = ({
  column,
  row,
  rowIndex,
  onQCToggle
}: IJCQcNeededProps) => {
  const [dropDownOpened, showHideDropdown] = useState<boolean>(false);
  const [linkedItem] = row?.jobCardLinkDetails || {};
  if (Utility.isEmptyObject(linkedItem)) return <div> - </div>;
  let { qcNeeded } = linkedItem;

  return (
    <div
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        if (
          row?.localJobCardLinkDetails?.[0]?.processType ===
          JC_PROCESS_TYPE.PROCESSING
        ) {
          return;
        }
        if (
          Utility.isEmptyObject(linkedItem?.itemWarehouseInventoryData) &&
          row?.status !== JOB_CARD_STATUS.COMPLETED
        ) {
          showHideDropdown(true);
        }
      }}
    >
      <DKLabel
        style={{ width: 90 }}
        key={`${column.name}-${rowIndex}`}
        text={qcNeeded ? 'Yes' : 'No'}
        className={`${getStatusChipColor(qcNeeded ? 'completed' : '')}`}
      />
      {dropDownOpened && (
        <DKListPicker2
          data={['Yes', 'No']}
          allowSearch={false}
          className={`position-absolute z-index-3 bg-white border-m`}
          renderer={(index: number, item: any) => (
            <div
              className="parent-width"
              onClick={(e: any) => {
                e.preventDefault();
                e.stopPropagation();
                onQCToggle(item === 'Yes');
                showHideDropdown(false);
              }}
            >
              <DKLabel
                style={{ width: 90 }}
                key={`${column.name}-${rowIndex}`}
                text={item}
                className={`${getStatusChipColor(
                  item === 'Yes' ? 'completed' : ''
                )}`}
              />
            </div>
          )}
          onSelect={() => {}}
          onClose={() => {
            showHideDropdown(false);
          }}
        />
      )}
    </div>
  );
};
export default JCQcNeeded;
