import {
  CESS_RULE_AMOUNT,
  CESS_RULE_QUANTITY,
  EXCISE_FLAT,
  EXCISE_PERCENT,
  GST_TYPE,
  TAX_SYSTEM
} from '../../../../Constants/Constant';
import { CASCADING_DISCOUNT_TYPE } from '../../../../Constants/Enum';
import { DocumentItem } from '../../../../Models/DocumentItem';
import Utility from '../../../../Utility/Utility';
import {
  CASCADING_DISCOUNT_PREFIX,
  evaluateTaxGroup,
  evaluateTaxGroupInclusiveTax,
  getTenantTaxSystem,
  roundOff
} from '../../../../SharedComponents/DocumentForm/NewDocumentHelper';
import AuthService from '../../../../Services/Auth';

export class DocLineItemCalculator {
  lineItem: DocumentItem;
  tenantInfo: any;
  taxSystem: TAX_SYSTEM;

  constructor(item: DocumentItem, tenantInfo: any) {
    this.lineItem = item;
    this.tenantInfo = tenantInfo ?? AuthService.currentTenantInfo;
    this.taxSystem = getTenantTaxSystem();
  }

  setInitialValues() {
    this.setSubTotal();
    this.setDiscountAmount();
    this.setCascadingDiscountAmount();
    this.setTotalWithDiscount();
  }

  updateCalculatedValues() {
    this.setSubTotal();
    this.setDiscountAmount();
    this.setCascadingDiscountAmount();
    this.setTotalWithDiscount();
    this.setTotal();
  }

  /**
   * Can be used for NON-US countries to
   * - set initial values
   * - calculate taxAmount, and
   * - calculate total
   */
  updateValuesWithTaxAmount() {
    this.setSubTotal();
    this.setDiscountAmount();
    this.setCascadingDiscountAmount();
    this.setTotalWithDiscount();
    this.lineItem.taxAmount = this.calculateTaxAmount();
    this.setTotal();
  }

  /**
   * Set subTotal for line item
   */
  setSubTotal() {
    const price = this.lineItem.unitPrice || 0;
    const quantity = this.lineItem.productQuantity || 0;
    if (getTenantTaxSystem() === TAX_SYSTEM.INDIA_GST) {
      this.lineItem.cessPercentage = this.getCessPercentage(price);
      let itemBaseAmount = 0;
      const amountBeforeDiscount = price * quantity;
      if (this.lineItem.unitPriceGstInclusive && this.lineItem.tax?.percent) {
        itemBaseAmount =
          this.getTaxInclusiveBaseAmountForIndia(amountBeforeDiscount);
      } else {
        itemBaseAmount = amountBeforeDiscount;
      }
      this.lineItem.subTotal = itemBaseAmount;
    } else {
      let calculatedPrice = price;
      if (this.lineItem.unitPriceGstInclusive && this.lineItem.tax?.percent) {
        calculatedPrice = this.getTaxInclusiveBaseAmount(price);
      }
      this.lineItem.subTotal = calculatedPrice * quantity;
    }
  }

  /**
   * Set total with discount for line item
   */
  setTotalWithDiscount() {
    if (
      typeof this.lineItem.exciseAmount === 'undefined' ||
      this.lineItem.exciseAmount === null
    ) {
      this.lineItem.exciseAmount = 0;
    }

    this.lineItem.totalWithDiscount =
      typeof this.lineItem.subTotal === 'number' &&
      typeof this.lineItem.exciseAmount === 'number' &&
      typeof this.lineItem.discountAmount === 'number' &&
      typeof this.lineItem.cascadingDiscountAmount === 'number'
        ? this.lineItem.subTotal +
          this.lineItem.exciseAmount -
          this.lineItem.discountAmount -
          this.lineItem.cascadingDiscountAmount
        : 0;
  }

  /**
   * Set discount amount for line item
   */
  setDiscountAmount() {
    this.lineItem.totalDiscountAmount = 0;
    if (
      typeof this.lineItem.discount !== 'undefined' &&
      this.lineItem.discount !== null
    ) {
      if (this.lineItem.discountInPercent) {
        if (this.lineItem.unitPriceGstInclusive) {
          let baseAmount = Utility.roundOffToTenantDecimalScale(
            this.lineItem.subTotal ? this.lineItem.subTotal : 0
          );

          if (getTenantTaxSystem() === TAX_SYSTEM.INDIA_GST) {
            this.lineItem.discountAmount = Utility.roundOffToTenantDecimalScale(
              Utility.roundOffToTenantDecimalScale(baseAmount) *
                (this.lineItem.discount / 100)
            );
          } else {
            this.lineItem.discountAmount = Utility.roundOffToTenantDecimalScale(
              Utility.roundOffToTenantDecimalScale(
                this.lineItem.subTotal ? this.lineItem.subTotal : 0
              ) *
                (this.lineItem.discount / 100)
            );
          }
        } else {
          this.lineItem.discountAmount = Utility.roundOffToTenantDecimalScale(
            ((this.lineItem.subTotal as number) *
              Number(this.lineItem.discount)) /
              100
          );
        }
      } else {
        this.lineItem.discountAmount = Number(this.lineItem.discount);
      }
    } else {
      this.lineItem.discountAmount = 0;
    }
    this.lineItem.totalDiscountAmount += this.lineItem.discountAmount;
  }

  /**
   * Set cascading discount amount for line item
   */
  setCascadingDiscountAmount() {
    this.lineItem.totalDiscountAmount = this.lineItem.totalDiscountAmount || 0;
    const cascadingDiscountSettings =
      this.tenantInfo?.additionalSettings?.CASCADING_DISCOUNTS;
    this.lineItem.cascadingDiscountAmount = 0;
    if (cascadingDiscountSettings?.enable) {
      const price = this.lineItem.unitPrice || 0;
      const quantity = this.lineItem.productQuantity || 0;
      let unitSubTotal = price;
      const itemDiscountMethod = this.lineItem?.itemDiscountMethod;
      const discountDetailsKeys = Object.keys(this.lineItem)?.filter(
        (key: string) =>
          key.startsWith(CASCADING_DISCOUNT_PREFIX) && key.endsWith('_details')
      );
      let discountDetails: any[] = [];
      discountDetailsKeys.forEach((discKey: string) => {
        const lineItem = this.lineItem as any;
        if (!Utility.isEmpty(lineItem[discKey])) {
          discountDetails.push(lineItem[discKey]);
        }
      });
      discountDetails?.sort(
        (discount1: any, discount2: any) =>
          discount1.discountIndex - discount2.discountIndex
      );

      if (!discountDetails.length) {
        this.lineItem.cascadingDiscountAmount = 0;
      } else {
        if (getTenantTaxSystem() === TAX_SYSTEM.INDIA_GST) {
          this.lineItem.cessPercentage = this.getCessPercentage(price);
          if (
            this.lineItem.unitPriceGstInclusive &&
            this.lineItem.tax?.percent
          ) {
            unitSubTotal = this.getTaxInclusiveBaseAmountForIndia(price);
          }
        } else {
          if (
            this.lineItem.unitPriceGstInclusive &&
            this.lineItem.tax?.percent
          ) {
            unitSubTotal = this.getTaxInclusiveBaseAmount(price);
          }
        }
        let unitDiscount = 0;
        let totalDiscount = 0;
        if (itemDiscountMethod === CASCADING_DISCOUNT_TYPE.SUBTOTAL) {
          discountDetails.forEach((disc: any) => {
            if (disc.isPercent) {
              unitDiscount = (unitSubTotal * +disc.discount) / 100;
              unitDiscount = Utility.roundOffToTenantDecimalScale(unitDiscount);
            } else {
              unitDiscount = +disc.discount;
            }
            const keyToUpdate = `${CASCADING_DISCOUNT_PREFIX}${disc.id}_details`;
            (this.lineItem as any)[keyToUpdate] = {
              ...(this.lineItem as any)[keyToUpdate],
              unitDiscount: unitDiscount
            };
            totalDiscount += Utility.roundOffToTenantDecimalScale(
              unitDiscount * quantity
            );
          });
          this.lineItem.cascadingDiscountAmount = totalDiscount;
        } else if (itemDiscountMethod === CASCADING_DISCOUNT_TYPE.CASCADE) {
          let currentAmount = unitSubTotal;
          discountDetails.forEach((disc: any) => {
            if (disc.isPercent) {
              unitDiscount = (currentAmount * +disc.discount) / 100;
              unitDiscount = Utility.roundOffToTenantDecimalScale(unitDiscount);
            } else {
              unitDiscount = +disc.discount;
            }
            const keyToUpdate = `${CASCADING_DISCOUNT_PREFIX}${disc.id}_details`;
            (this.lineItem as any)[keyToUpdate] = {
              ...(this.lineItem as any)[keyToUpdate],
              unitDiscount: unitDiscount
            };
            totalDiscount += Utility.roundOffToTenantDecimalScale(
              unitDiscount * quantity
            );
            currentAmount -= unitDiscount;
          });
          this.lineItem.cascadingDiscountAmount = totalDiscount;
        }
      }
      this.lineItem.totalDiscountAmount +=
        this.lineItem.cascadingDiscountAmount;
    }
  }

  /**
   * Set total for line item after tax amount has been calculated
   */
  setTotal() {
    this.lineItem.total = this.lineItem.isRcmApplied
      ? (this.lineItem.totalWithDiscount || 0) +
        Number(this.lineItem.otherTaxAmount || 0)
      : (this.lineItem.totalWithDiscount || 0) +
        Number(this.lineItem.taxAmount || 0);

    if (
      getTenantTaxSystem() === TAX_SYSTEM.INDIA_GST &&
      this.lineItem.unitPriceGstInclusive &&
      this.lineItem.taxSystem === TAX_SYSTEM.INDIA_GST
    ) {
      let totalAfterDiscount =
        Utility.roundOffToTenantDecimalScale(this.lineItem.subTotal || 0) -
        (this.lineItem.discountAmount || 0) -
        (this.lineItem.cascadingDiscountAmount || 0);
      let otherTotalTax = Utility.roundOffToTenantDecimalScale(
        totalAfterDiscount * ((this.lineItem.tax?.otherRate || 0) / 100)
      );

      otherTotalTax = Utility.roundOffToTenantDecimalScale(otherTotalTax);
      let taxAmount = 0;

      switch (this.lineItem.gstType) {
        case GST_TYPE.INTRA:
          if (!this.lineItem.isRcmApplied) {
            taxAmount =
              Utility.roundOffToTenantDecimalScale(
                this.lineItem.cgstAmount || 0
              ) +
              Utility.roundOffToTenantDecimalScale(
                this.lineItem.sgstAmount || 0
              );
          }
          break;
        case GST_TYPE.INTER:
          if (!this.lineItem.isRcmApplied) {
            taxAmount = Utility.roundOffToTenantDecimalScale(
              this.lineItem.igstAmount || 0
            );
          }
          break;
        case GST_TYPE.EXEMPT:
          // Do nothing for GST exempt
          break;
      }

      this.lineItem.totalAmount =
        Utility.roundOffToTenantDecimalScale(totalAfterDiscount) +
        Utility.roundOffToTenantDecimalScale(taxAmount) +
        otherTotalTax +
        (!this.lineItem.isRcmApplied && this.lineItem.cessAmount
          ? this.lineItem.cessAmount
          : 0);
    } else {
      this.lineItem.totalAmount = Utility.roundOffToTenantDecimalScale(
        this.lineItem.total
      );
    }
  }

  /**
   * Main method for trigerring tax calculations
   */
  calculateTaxAmount = () => {
    let tax: number = 0;
    switch (getTenantTaxSystem()) {
      case TAX_SYSTEM.INDIA_GST:
        tax = this.calculateIndiaTax() as number;
        break;
      case TAX_SYSTEM.SG:
      case TAX_SYSTEM.AUSTRALIA:
      case TAX_SYSTEM.NZ:
      case TAX_SYSTEM.NL:
      case TAX_SYSTEM.BE:
      case TAX_SYSTEM.CANADA:
      case TAX_SYSTEM.UK:
      case TAX_SYSTEM.DE:
        tax = this.calculateTaxGroup();
        break;
      default:
        const calculateTax =
          (typeof this.lineItem.taxPreference === 'boolean' &&
            this.lineItem.taxPreference) ||
          typeof this.lineItem.taxPreference === 'undefined' ||
          this.lineItem.taxPreference === null;
        if (calculateTax) {
          tax =
            this.lineItem.tax && this.lineItem.tax.percent !== undefined
              ? this.getTaxInPercent(this.lineItem.tax.percent)
              : this.lineItem.taxAmount || 0;
        } else {
          tax = 0;
        }

        break;
    }
    this.lineItem.taxAmount = tax;
    return tax;
  };

  /**
   * Calculate tax for India
   */
  calculateIndiaTax() {
    this.lineItem.cgstRate = 0;
    this.lineItem.sgstRate = 0;
    this.lineItem.igstRate = 0;
    this.lineItem.otherTaxAmount = 0;
    this.lineItem.otherRate = 0;
    this.lineItem.rcmRate = 0;
    this.lineItem.taxDetails = [];
    if (this.lineItem.tax && !this.lineItem.tax.isTaxGroup) {
      this.lineItem.rcmRate =
        this.lineItem.tax && this.lineItem.tax.percent
          ? this.lineItem.tax.percent
          : 0;
      switch (this.lineItem.gstType) {
        case GST_TYPE.INTER:
          this.lineItem.igstRate = 0;
          if (
            this.lineItem.compositionTaxPercent &&
            this.lineItem.compositionTaxPercent > 0
          ) {
            this.lineItem.igstRate = this.lineItem.compositionTaxPercent;
          } else if (this.lineItem.tax && this.lineItem.tax.percent) {
            this.lineItem.igstRate = this.lineItem.tax.percent;
          }
          this.lineItem.rcmRate = this.lineItem.igstRate;
          break;
        case GST_TYPE.INTRA:
          let halfGstRate = 0;
          if (
            this.lineItem.compositionTaxPercent &&
            this.lineItem.compositionTaxPercent > 0
          ) {
            halfGstRate = this.lineItem.compositionTaxPercent / 2;
          } else if (this.lineItem.tax && this.lineItem.tax.percent) {
            halfGstRate = this.lineItem.tax.percent / 2;
          }
          this.lineItem.sgstRate = halfGstRate;
          this.lineItem.cgstRate = halfGstRate;
          this.lineItem.rcmRate =
            this.lineItem.sgstRate + this.lineItem.cgstRate;
          break;
        case GST_TYPE.EXEMPT:
          this.lineItem.sgstRate = 0;
          this.lineItem.cgstRate = 0;
          this.lineItem.igstRate = 0;
          break;
      }

      if (this.lineItem.userSetTaxes) {
        this.lineItem.igstAmount = this.lineItem.igstAmount || 0;
      } else {
        this.lineItem.igstAmount = this.lineItem.igstRate
          ? this.getTaxInPercent(this.lineItem.igstRate)
          : 0;
      }

      if (this.lineItem.unitPriceGstInclusive) {
        // Unit price inclusive
        let totalAfterDiscount =
          Utility.roundOffToTenantDecimalScale(this.lineItem.subTotal || 0) -
          (this.lineItem.discountAmount || 0) -
          (this.lineItem.cascadingDiscountAmount || 0);

        let totalGSTTax = 0;

        if (this.lineItem.userSetTaxes) {
          if (this.lineItem?.gstType === GST_TYPE.INTER) {
            this.lineItem.igstAmount = this.lineItem.igstAmount || 0;
          }
          if (this.lineItem?.gstType === GST_TYPE.INTRA) {
            this.lineItem.cgstAmount = this.lineItem.cgstAmount || 0;
            this.lineItem.sgstAmount = this.lineItem.sgstAmount || 0;
          }
          this.lineItem.cessAmount = this.lineItem.cessAmount || 0;
        } else {
          totalGSTTax = Utility.roundOffToTenantDecimalScale(
            (totalAfterDiscount *
              (this.lineItem?.gstType !== GST_TYPE.EXEMPT &&
              this.lineItem?.tax?.percent
                ? this.lineItem?.tax?.percent
                : 0)) /
              100
          );
          this.lineItem.cgstAmount =
            this.lineItem.cgstRate && this.lineItem.cgstRate > 0
              ? Utility.roundOffToTenantDecimalScale(totalGSTTax / 2)
              : 0;
          this.lineItem.sgstAmount = this.lineItem.cgstAmount;

          this.lineItem.igstAmount =
            this.lineItem.igstRate && this.lineItem.igstRate > 0
              ? Utility.roundOffToTenantDecimalScale(totalGSTTax)
              : 0;

          this.lineItem.cessAmount = this.calculateCessAmount();
        }
      } else {
        // NOT Unit price inclusive
        if (this.lineItem.userSetTaxes) {
          this.lineItem.cgstAmount = this.lineItem.cgstAmount || 0;
          this.lineItem.sgstAmount = this.lineItem.sgstAmount || 0;
          this.lineItem.cessAmount = this.lineItem.cessAmount || 0;
        } else {
          this.lineItem.cgstAmount = 0;
          if (this.lineItem.cgstRate && this.lineItem.cgstRate > 0) {
            const tempTaxValueFromPercent = this.lineItem?.tax?.percent
              ? this.lineItem?.tax?.percent / 100
              : 0;
            const cgstAmount =
              ((this.lineItem.totalWithDiscount || 0) *
                tempTaxValueFromPercent) /
              2;
            this.lineItem.cgstAmount =
              Utility.roundOffToTenantDecimalScale(cgstAmount);
          }
          this.lineItem.sgstAmount = this.lineItem.cgstAmount;
          this.lineItem.cessAmount = this.calculateCessAmount();
        }
      }

      this.lineItem.cgstAmount = Utility.roundOffToTenantDecimalScale(
        this.lineItem.cgstAmount || 0
      );
      this.lineItem.sgstAmount = Utility.roundOffToTenantDecimalScale(
        this.lineItem.sgstAmount || 0
      );
      this.lineItem.otherTaxAmount = Utility.roundOffToTenantDecimalScale(
        this.lineItem.otherTaxAmount
      );
      this.lineItem.igstAmount = Utility.roundOffToTenantDecimalScale(
        this.lineItem.igstAmount
      );
      if (this.lineItem.cessAmount) {
        this.lineItem.cessAmount = Utility.roundOffToTenantDecimalScale(
          this.lineItem.cessAmount
        );
      }

      return (
        this.lineItem.cgstAmount +
        this.lineItem.sgstAmount +
        (this.lineItem.igstAmount || 0) +
        (this.lineItem.cessAmount || 0)
      );
    } else if (this.lineItem.tax && this.lineItem.tax.isTaxGroup) {
      let tax: number = 0;
      let exemptAmount: number = 0;
      tax = this.calculateTaxGroup();
      exemptAmount = this.calculateGSTTax();
      tax = Number(tax) - Number(exemptAmount);
      this.lineItem.cessAmount = !this.lineItem.userSetTaxes
        ? this.lineItem.isRcmApplied
          ? null
          : this.calculateCessAmount()
        : this.lineItem.cessAmount;
      tax += this.lineItem.cessAmount as number;
      return tax;
    }
  }

  /**
   * Calculate exempt amount and GST tax for tax groups
   */
  calculateGSTTax() {
    this.lineItem.cgstAmount = 0;
    this.lineItem.sgstAmount = 0;
    this.lineItem.igstAmount = 0;
    this.lineItem.otherTaxAmount = 0;
    let exemptAmount = 0;
    this.lineItem.tax?.taxGroupDetails?.forEach((element: any) => {
      if (!element.additionalTaxIn) {
        switch (this.lineItem.gstType) {
          case GST_TYPE.INTER:
            this.lineItem.igstRate =
              this.lineItem.compositionTaxPercent &&
              this.lineItem.compositionTaxPercent > 0
                ? this.lineItem.compositionTaxPercent
                : element.percentage;
            if (
              typeof this.lineItem.rcmRate === 'number' &&
              typeof this.lineItem.igstRate === 'number'
            ) {
              this.lineItem.rcmRate += this.lineItem.igstRate;
            }
            break;
          case GST_TYPE.INTRA:
            const halfGstRate =
              (this.lineItem.compositionTaxPercent &&
              this.lineItem.compositionTaxPercent > 0
                ? this.lineItem.compositionTaxPercent
                : element.percentage) / 2;
            if (
              typeof this.lineItem.cgstRate === 'number' &&
              typeof this.lineItem.sgstRate === 'number' &&
              typeof this.lineItem.rcmRate === 'number'
            ) {
              this.lineItem.cgstRate += halfGstRate;
              this.lineItem.sgstRate += halfGstRate;
              this.lineItem.rcmRate +=
                this.lineItem.cgstRate + this.lineItem.sgstRate;
            } else {
              this.lineItem.cgstRate = 0;
              this.lineItem.sgstRate = 0;
              this.lineItem.rcmRate = 0;
            }
            break;
          default:
            this.lineItem.rcmRate += element.percentage;
        }
      } else {
        this.lineItem.otherRate += element.percentage;
      }
    });

    this.lineItem.taxDetails.forEach((taxDetail: any) => {
      if (!taxDetail.additionalTaxIn) {
        switch (this.lineItem.gstType) {
          case GST_TYPE.INTER:
            this.lineItem.igstAmount += taxDetail.taxAmount;

            break;
          case GST_TYPE.INTRA:
            const halfGstAmount = taxDetail.taxAmount / 2;
            if (
              typeof this.lineItem.cgstAmount === 'number' &&
              typeof this.lineItem.sgstAmount === 'number'
            ) {
              this.lineItem.cgstAmount += halfGstAmount;
              this.lineItem.sgstAmount += halfGstAmount;
            }
            break;
          case GST_TYPE.EXEMPT:
            exemptAmount += taxDetail.taxAmount;
            taxDetail.taxAmount = 0;
            break;
        }
      } else {
        this.lineItem.otherTaxAmount += taxDetail.taxAmount;
      }
    });

    this.lineItem.cgstAmount = Utility.roundOffToTenantDecimalScale(
      this.lineItem.cgstAmount
    );
    this.lineItem.sgstAmount = Utility.roundOffToTenantDecimalScale(
      this.lineItem.sgstAmount
    );
    this.lineItem.igstAmount = Utility.roundOffToTenantDecimalScale(
      this.lineItem.igstAmount
    );
    this.lineItem.otherTaxAmount = Utility.roundOffToTenantDecimalScale(
      this.lineItem.otherTaxAmount
    );

    return exemptAmount;
  }

  /**
   * Calculate excise amount
   */
  calculateExciseAmount() {
    if (this.lineItem.exciseRate && this.lineItem.exciseType) {
      switch (this.lineItem.exciseType) {
        case EXCISE_PERCENT:
          this.lineItem.exciseAmount =
            typeof this.lineItem.subTotal === 'number'
              ? Utility.roundOffToTenantDecimalScale(
                  (this.lineItem.subTotal * this.lineItem.exciseRate) / 100
                )
              : 0;
          break;
        case EXCISE_FLAT:
          let quantity = this.lineItem.productQuantity || 0;
          this.lineItem.exciseAmount = Utility.roundOffToTenantDecimalScale(
            quantity * this.lineItem.exciseRate
          );
          break;
      }
    }
  }

  /**
   * Calculate tax group
   */
  calculateTaxGroup() {
    let taxDetails;
    if (!this.lineItem.unitPriceGstInclusive) {
      taxDetails = evaluateTaxGroup(
        this.lineItem.tax,
        this.lineItem.totalWithDiscount as number
      );
    } else {
      const totalAmount =
        (this.lineItem.unitPrice || 0) * (this.lineItem.productQuantity || 0);
      const totalTax = Utility.roundOffToTenantDecimalScale(
        totalAmount -
          (typeof this.lineItem.subTotal === 'number'
            ? this.lineItem.subTotal
            : 0)
      );
      taxDetails = evaluateTaxGroupInclusiveTax(
        this.lineItem.tax,
        this.lineItem.totalWithDiscount as number,
        totalTax
      );
    }
    this.lineItem.taxDetails = taxDetails;

    let tax = 0;
    taxDetails.forEach((taxDetail: any) => (tax += taxDetail.taxAmount));
    return tax;
  }

  /**
   *
   * @param percent Get tax amount on totalWithDiscount from percentage value
   * @returns
   */
  private getTaxInPercent(percent: number) {
    return percent
      ? Utility.roundOffToTenantDecimalScale(
          ((this.lineItem.totalWithDiscount || 0) * percent) / 100
        )
      : 0;
  }

  /**
   * Calculates tax inclusive base amount
   * @param baseAmount
   * @returns
   */
  private getTaxInclusiveBaseAmount(baseAmount: number) {
    const taxPercent =
      this.lineItem.tax && this.lineItem.tax.percent
        ? this.lineItem.tax.percent
        : 0;
    const taxInclusiveBaseAmount = baseAmount / (1 + taxPercent / 100);

    return taxInclusiveBaseAmount;
  }

  /**
   * Calculates tax inclusive base amount for India (GST ON)
   * @param baseAmount
   * @returns
   */
  private getTaxInclusiveBaseAmountForIndia(baseAmount: number) {
    const taxPercent =
      this.lineItem.gstType !== GST_TYPE.EXEMPT &&
      this.lineItem.tax &&
      this.lineItem.tax.percent
        ? this.lineItem.tax.percent
        : 0;
    const cessPercentage = this.lineItem.cessPercentage || 0;
    const taxInclusiveBaseAmount =
      baseAmount / (1 + (taxPercent + cessPercentage) / 100);

    return taxInclusiveBaseAmount;
  }

  private calculateCessAmount(totalAfterDiscount?: number) {
    let cessAmount = 0;
    const taxableAmount =
      typeof totalAfterDiscount === 'number'
        ? totalAfterDiscount
        : this.lineItem.totalWithDiscount;

    if (this.lineItem.cessRule && taxableAmount) {
      let cessExpression = '';
      if (this.lineItem.productQuantity) {
        cessExpression = this.lineItem.cessRule.replace(
          CESS_RULE_QUANTITY,
          `${this.lineItem.productQuantity}`
        );
      }

      if (taxableAmount) {
        cessExpression = cessExpression
          ? cessExpression.replace(CESS_RULE_AMOUNT, `${taxableAmount}`)
          : this.lineItem.cessRule.replace(
              CESS_RULE_AMOUNT,
              `${taxableAmount}`
            );
      }

      if (cessExpression) {
        cessAmount = eval(cessExpression);
      }
    }
    return roundOff(cessAmount);
  }

  /**
   * Calculate CESS percentage from unitPrice
   * @param unitPrice
   * @returns percent value for CESS
   */
  private getCessPercentage(unitPrice: number) {
    const cessAmount = this.calculateCessAmount(unitPrice);
    return (cessAmount / unitPrice) * 100;
  }
}
