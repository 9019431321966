import ApiConstants from '../Constants/ApiConstants';
import Utility from '../Utility/Utility';
import { showToast, TOAST_TYPE, showAlert } from 'deskera-ui-library';
import { COMPLIANCE_SPECIFIC_FIELD_NAME } from '../Constants/Enum';
import http from './StockManagementInterceptor';
import http2 from './Interceptor2';

export interface StockTransferAPIConfig {
  SearchTerm?: string;
  Query?: string;
  Limit?: number;
  Page?: number;
  IsVariant?: boolean;
  type?: string;
  QueryParam?: any;
  isOB?: boolean;
}

const defaultConfig: StockTransferAPIConfig = {
  SearchTerm: '',
  Limit: 25,
  Page: 0,
  Query: '',
  IsVariant: false,
  type: '',
  QueryParam: ''
};
class StockTransferService {
  static apiConfig: StockTransferAPIConfig;
  static moduleName: COMPLIANCE_SPECIFIC_FIELD_NAME =
    COMPLIANCE_SPECIFIC_FIELD_NAME.PRODUCT;
  static abortController: any = null;

  static getStockTransferEndPoint() {
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    }
    const queryString: string = `/search?search=${
      this.apiConfig.SearchTerm
    }&limit=${this.apiConfig.Limit}&page=${this.apiConfig.Page}${
      this.apiConfig.Query ? '&query=' + this.apiConfig.Query : ''
    }${this.apiConfig.QueryParam ? this.apiConfig.QueryParam : ''}`;
    // const countryCode = Utility.getTenantSpecificApiCode(
    //   StockTransferService.moduleName
    // );
    const finalEndpoint: string =
      ApiConstants.URL.PRODUCT.FETCH_STOCK_TRANSFER + queryString;
    return finalEndpoint;
  }

  static getProductEndpoint() {
    // stock/products/brief-search?search=a
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    }

    const queryString = `/brief-search?search=${this.apiConfig.SearchTerm}&limit=25&page=0`;
    const finalEndpoint: string =
      ApiConstants.URL.BASE.replace('v1/', 'v1') +
      ApiConstants.URL.PRODUCT.FETCH_PRODUCTS +
      queryString;
    return finalEndpoint;
  }
  static getProductBriefEndpoint() {
    // stock/products/brief-search?search=a
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    }
    // const queryString = `/brief-search?search=${this.apiConfig.SearchTerm}`;
    const finalEndpoint: string =
      ApiConstants.URL.STOCK_BASE_API.replace('v1/', 'v1') +
      ApiConstants.URL.PRODUCT.FETCH_STOCK_TRANSFER_PRODUCT_BRIEF;
    return finalEndpoint;
  }

  static getStockTransfersByPage() {
    Utility.cancelRequest(StockTransferService.abortController);

    StockTransferService.abortController = Utility.createAbortController();
    return http
      .get(`${StockTransferService.getStockTransferEndPoint()}`, {
        signal: StockTransferService.abortController.signal
      })
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static getStockTransferProducts() {
    Utility.cancelRequest(StockTransferService.abortController);
    console.log('zzz');

    StockTransferService.abortController = Utility.createAbortController();
    return http
      .get(`${StockTransferService.getProductEndpoint()}`, {
        signal: StockTransferService.abortController.signal
      })
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static fetchProductDocSeqCode(data: any) {
    const finalEndPoint: string =
      ApiConstants.URL.PRODUCT.FETCH_STOCK_TRANSFER_PRODUCT_SEQ_CODE;

    return http2
      .post(finalEndPoint, data)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }
  static fetchProductBrief() {
    return http
      .get(`${StockTransferService.getProductBriefEndpoint()}`)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }
  static createStockTransfer(data: any) {
    const finalEndPoint: string = ApiConstants.URL.PRODUCT.FETCH_STOCK_TRANSFER;

    return http
      .post(finalEndPoint, data)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }
  static updateStockTransfer(data: any) {
    const finalEndPoint: string = ApiConstants.URL.PRODUCT.FETCH_STOCK_TRANSFER;

    return http
      .put(finalEndPoint, data)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static deleteStockTransfer(productIds: string[]) {
    const countryCode = Utility.getTenantSpecificApiCode(
      StockTransferService.moduleName
    );
    const finalEndPoint: string =
      ApiConstants.URL.PRODUCT.FETCH_PRODUCTS + countryCode;
    return http
      .delete(finalEndPoint, { data: productIds })
      .then(
        (response: any) => {
          const res: any = response;
          if (res?.failure?.length > 0) {
            showAlert(
              'Oops!',
              'This product is already in use, cannot be deleted'
            );
          } else {
            // showToast('StockTransfer deleted successfully', TOAST_TYPE.SUCCESS);
          }
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static deleteStockTransferRecord(id: string) {
    return http2
      .delete(`${ApiConstants.URL.STOCK_MANAGEMENT.DELETE_STOCK_TRANSFER(id)}`)
      .catch((err: any) => {
        return Promise.reject(err);
      });
  }

  static exportStockTransfer(moduleName: string) {
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    }

    const query: string = `&limit=${this.apiConfig.Limit}&page=${
      this.apiConfig.Page
    }&search=${this.apiConfig.SearchTerm}&query=${
      this.apiConfig.Query ? this.apiConfig.Query : ''
    }${this.apiConfig.QueryParam ? this.apiConfig.QueryParam : ''}`;

    return http2
      .get(
        `${ApiConstants.URL.STOCK_MANAGEMENT.EXPORT_STOCK(moduleName)}${query}`
      )
      .catch((err: any) => {
        console.error('Error exporting stock list: ', err);
        return Promise.reject(err);
      });
  }
  static getStockAdjustmentReason(type: string) {
    return http2
      .get(ApiConstants.URL.STOCK_MANAGEMENT.ADJUSTMENT_REASON(type))
      .catch((err: any) => {
        return Promise.reject(err);
      });
  }
}

export default StockTransferService;
