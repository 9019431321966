import { DOC_TYPE, MODULES_NAME } from '../../../../Constants/Constant';
import { Store } from '../../../../Redux/Store';

export const getCFModuleFromDocType = (documentType: DOC_TYPE) => {
  let module: any;

  switch (documentType) {
    case DOC_TYPE.INVOICE:
      module = MODULES_NAME.INVOICE;
      break;
    case DOC_TYPE.BILL:
      module = MODULES_NAME.BILL;
      break;
    case DOC_TYPE.QUOTE:
      module = MODULES_NAME.QUOTATION;
      break;
    case DOC_TYPE.SALES_ORDER:
      module = MODULES_NAME.SALESORDER;
      break;
    case DOC_TYPE.ORDER:
      module = MODULES_NAME.ORDER;
      break;
    case DOC_TYPE.JOB_WORK_OUT_ORDER:
      module = MODULES_NAME.JOB_WORK_OUT;
      break;
    default:
      break;
  }

  return module as MODULES_NAME;
};

export const getColumnConfigFromDocType = (documentType: DOC_TYPE) => {
  let columnConfigInfo: { tableId?: any; columnConfig?: any } = {};
  const storeState = Store.getState();
  switch (documentType) {
    case DOC_TYPE.QUOTE:
      columnConfigInfo = {
        columnConfig: storeState.quotes.columnConfig,
        tableId: storeState.quotes.configTableId
      };
      break;
    case DOC_TYPE.SALES_ORDER:
      columnConfigInfo = {
        columnConfig: storeState.salesOrder.columnConfig,
        tableId: storeState.salesOrder.configTableId
      };
      break;
    case DOC_TYPE.INVOICE:
      columnConfigInfo = {
        columnConfig: storeState.invoices.columnConfig,
        tableId: storeState.invoices.configTableId
      };
      break;
    case DOC_TYPE.ORDER:
      columnConfigInfo = {
        columnConfig: storeState.purchaseOrders.columnConfig,
        tableId: storeState.purchaseOrders.configTableId
      };
      break;
    case DOC_TYPE.BILL:
      columnConfigInfo = {
        columnConfig: storeState.bills.columnConfig,
        tableId: storeState.bills.configTableId
      };
      break;
    case DOC_TYPE.JOB_WORK_OUT_ORDER:
      columnConfigInfo = {
        columnConfig: storeState.workOut.columnConfig,
        tableId: storeState.workOut.configTableId
      };
      break;
  }

  return columnConfigInfo;
};
