import { useContext } from 'react';
import { CommonDraftPropsContext } from '../../Utilities/DocContext';
import { DKButton } from 'deskera-ui-library';
import BarcodeIcon from '../../../../Assets/ic_barcode.png';
import { useAppDispatch, useAppSelector } from '../../../../Redux/Hooks';
import {
  addNewItemToDocument,
  calculateTaxesAndAmountsForAllLineItems,
  clearItems,
  selectColumns,
  selectDocumentFormDataByKeys,
  updateMultipleKeysInDocument
} from '../../../../Redux/Slices/DocumentSlice';
import { DOCUMENT_KEYS } from '../../Utilities/DocConstants';
import { DraftTypes } from '../../../../Models/Drafts';
import {
  canUpdateDocument,
  getBlankRowItem,
  isSalesDocument
} from '../../Utilities/DocCommonUtils';
import {
  DOCUMENT_MODE,
  DOC_TYPE,
  FULFILLMENT_STATUS
} from '../../../../Constants/Constant';
import { getDocumentByIDFromStore } from '../../Helper/DocumentHelper';
import { activeTenantInfo } from '../../../../Redux/Slices/AuthSlice';
import { selectProductCustomFields } from '../../../../Redux/Slices/CommonDataSlice';
import {
  COMMON_EVENTS,
  DOC_POPUP_TYPE,
  commonCustomEvent
} from '../../../../Services/event/commonEvents';
import Utility from '../../../../Utility/Utility';
import { showComponenListSection } from '../../../../SharedComponents/DocumentForm/NewDocumentHelper';

enum ACTION_KEYS {
  ADD_ITEM,
  SCAN_BARCODE,
  CLEAR_ITEMS,
  RESERVE_STOCK,
  SHOW_COMPONENT_LIST
}

const DocGridActions = ({ copyOfDocument }: any) => {
  const { draftId, draftType, documentMode } = useContext(
    CommonDraftPropsContext
  );
  const document: any = getDocumentByIDFromStore(draftId) ?? {};
  const { additionalSettings } = useAppSelector(activeTenantInfo);

  const dispatch = useAppDispatch();
  const [
    items,
    documentType,
    sourceFulfillmentStatus,
    reservedStock,
    isPartialInvoice,
    isPartialBill,
    isPartialSalesOrder,
    dropShip,
    fulfillmentType,
    linkedDocuments,
    fulfillmentStatus,
    paymentStatus,
    receiveGoodsStatus,
    totalAmount,
    showComponentList
  ] = useAppSelector(
    selectDocumentFormDataByKeys(draftId, [
      DOCUMENT_KEYS.ITEMS,
      DOCUMENT_KEYS.DOCUMENT_TYPE,
      DOCUMENT_KEYS.SOURCE_FULFILLMENT_STATUS,
      DOCUMENT_KEYS.RESERVED_STOCK,
      DOCUMENT_KEYS.IS_PARTIAL_INVOICE,
      DOCUMENT_KEYS.IS_PARTIAL_BILL,
      DOCUMENT_KEYS.IS_PARTIAL_SALES_ORDER,
      DOCUMENT_KEYS.DROPSHIP,
      DOCUMENT_KEYS.FULFILLMENT_TYPE,
      DOCUMENT_KEYS.LINKED_DOCUMENTS,
      DOCUMENT_KEYS.FULFILLMENT_STATUS,
      DOCUMENT_KEYS.PAYMENT_STATUS,
      DOCUMENT_KEYS.RECEIVE_GOODS_STATUS,
      DOCUMENT_KEYS.TOTAL_AMOUNT,
      DOCUMENT_KEYS.SHOW_COMPONENT_LIST
    ])
  );
  const columns = useAppSelector(selectColumns());
  const productCFData = useAppSelector(selectProductCustomFields);
  const salesDocument = isSalesDocument(documentType);
  const isReadOnly = draftType === DraftTypes.READONLY;
  const isDraft = draftType === DraftTypes.DRAFT;
  const isViewMode = documentMode === DOCUMENT_MODE.VIEW;
  const isPartialDoc =
    !!isPartialBill || !!isPartialSalesOrder || !!isPartialInvoice;
  const isDropship = Utility.isDropship({ dropShip, fulfillmentType });
  const isOrder = documentType === DOC_TYPE.ORDER;
  const hasLinkedPurchaseReq =
    linkedDocuments?.[0]?.documentType === DOC_TYPE.PURCHASE_REQUEST;
  const isPartialFulfill = [
    FULFILLMENT_STATUS.FULLY_FULFILLED,
    FULFILLMENT_STATUS.PARTIAL_FULFILLED
  ].includes(sourceFulfillmentStatus);

  const canUpdateDocumentData = canUpdateDocument({
    documentType,
    fulfillmentStatus,
    paymentStatus,
    draftType,
    receiveGoodsStatus,
    totalAmount
  });

  const textColorClass = `${isReadOnly ? 'text-gray' : 'text-blue'}`;

  const ACTIONS = [
    {
      id: ACTION_KEYS.ADD_ITEM,
      onClick: () => handleAction(ACTION_KEYS.ADD_ITEM),
      title: '+ Add Item',
      style: { zIndex: 1, paddingLeft: 0 },
      className: `fw-m p-0 walkthrough-step-6 ${textColorClass}`,
      visible: true
    },
    {
      id: ACTION_KEYS.SCAN_BARCODE,
      onClick: () => handleAction(ACTION_KEYS.SCAN_BARCODE),
      title: 'Scan Barcode',
      style: { zIndex: 1 },
      className: `fw-m p-0 ${textColorClass}`,
      visible: true,
      icon: BarcodeIcon
    },
    {
      id: ACTION_KEYS.CLEAR_ITEMS,
      onClick: () => handleAction(ACTION_KEYS.CLEAR_ITEMS),
      title: '- Clear all items',
      style: { zIndex: 1 },
      className: `fw-m p-0 ${textColorClass}`,
      visible: items?.length > 0
    },
    {
      id: ACTION_KEYS.RESERVE_STOCK,
      onClick: () => handleAction(ACTION_KEYS.RESERVE_STOCK),
      title: (reservedStock ? 'Deactivate ' : 'Activate ') + 'reserved stock',
      style: { zIndex: 1 },
      className: `fw-m p-0 ${textColorClass}`,
      visible: salesDocument && !isPartialFulfill && items?.length > 0
    },
    {
      id: ACTION_KEYS.SHOW_COMPONENT_LIST,
      onClick: () => handleAction(ACTION_KEYS.SHOW_COMPONENT_LIST),
      title: (showComponentList ? 'Hide ' : 'Show ') + 'component list',
      style: { zIndex: 1 },
      className: `fw-m p-0 ${textColorClass}`,
      visible: salesDocument && showComponenListSection(items)
    }
  ];

  const handleAction = (action: ACTION_KEYS) => {
    switch (action) {
      case ACTION_KEYS.ADD_ITEM:
        if (
          additionalSettings?.PRODUCT_GROUP_ENABLED &&
          documentType !== DOC_TYPE.JOB_WORK_OUT_ORDER
        ) {
          commonCustomEvent.dispatch(COMMON_EVENTS.DOC_POPUP_SHOW, {
            type: DOC_POPUP_TYPE.SHOW_PRODUCT_GROUPING_POPUP
          });
        } else {
          dispatch(
            addNewItemToDocument({
              draftId,
              item: getBlankRowItem(
                document,
                JSON.parse(columns),
                documentMode,
                draftType,
                productCFData?.content ?? []
              )
            })
          );
        }

        break;
      case ACTION_KEYS.CLEAR_ITEMS:
        dispatch(clearItems({ draftId }));
        dispatch(calculateTaxesAndAmountsForAllLineItems({ draftId }));
        break;
      case ACTION_KEYS.RESERVE_STOCK:
        dispatch(
          updateMultipleKeysInDocument({
            draftId,
            keysToUpdate: { [DOCUMENT_KEYS.RESERVED_STOCK]: !reservedStock }
          })
        );
        break;
      case ACTION_KEYS.SCAN_BARCODE:
        commonCustomEvent.dispatch(COMMON_EVENTS.DOC_POPUP_SHOW, {
          type: DOC_POPUP_TYPE.SHOW_BARCODE_SEARCH_POPUP
        });
        break;
      case ACTION_KEYS.SHOW_COMPONENT_LIST:
        dispatch(
          updateMultipleKeysInDocument({
            draftId,
            keysToUpdate: {
              [DOCUMENT_KEYS.SHOW_COMPONENT_LIST]: !showComponentList
            }
          })
        );
        break;
      default:
        break;
    }
  };

  if (
    !(
      isPartialDoc ||
      isDropship ||
      isViewMode ||
      (isOrder && hasLinkedPurchaseReq)
    ) ||
    (isPartialDoc &&
      !isViewMode &&
      additionalSettings?.EDIT_CONVERTED_DOC &&
      canUpdateDocumentData &&
      (!copyOfDocument?.reservedStock || isDraft))
  ) {
    return (
      <div className="row height-auto">
        {ACTIONS.map((action) => {
          if (action.visible) {
            return (
              <DKButton
                title={action.title}
                onClick={action.onClick}
                className={action.className}
                style={{ ...action.style }}
                icon={action.icon}
              />
            );
          }
          return null;
        })}
      </div>
    );
  }
  return null;
};

export default DocGridActions;
