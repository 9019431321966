import { useContext } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../Redux/Hooks';
import { CommonDraftPropsContext } from '../../../Utilities/DocContext';
import {
  selectCurrentActiveDocument,
  selectDocumentFormDataByKeys
} from '../../../../../Redux/Slices/DocumentSlice';
import { DOCUMENT_KEYS } from '../../../Utilities/DocConstants';
import { useTranslation } from 'react-i18next';
import {
  BtnType,
  CallBackPayloadType,
  PopupClickActionType,
  UpdateCallBacksRefType
} from '../../../../../Models/Interfaces';
import {
  DOC_TYPE,
  POPUP_CALLBACKS_TYPE,
  POPUP_CLICK_TYPE,
  POPUP_TYPE
} from '../../../../../Constants/Constant';
import PopupWrapper from '../../../../../SharedComponents/PopupWrapper';
import AddStockTransferPopup from '../../../../StockManagement/StockTransfer/AddStockTransfer';

export const AddStockTransferActionPopup = (props: any) => {
  const dispatch = useAppDispatch();
  const { draftId, documentMode, draftType } = useContext(
    CommonDraftPropsContext
  );
  const [documentType] = useAppSelector(
    selectDocumentFormDataByKeys(draftId, [DOCUMENT_KEYS.DOCUMENT_TYPE])
  );

  const documentObject = useAppSelector(selectCurrentActiveDocument(draftId));
  const { t, i18n } = useTranslation();
  const refInitialState: UpdateCallBacksRefType = {
    pushDataToParent: { type: POPUP_CALLBACKS_TYPE.NONE },
    storeCallbacksRef: { updateInvoice: 'click' }
  };

  const dropShipButtonConfig: BtnType[] = [
    {
      title: t(`INVOICES.DIALOG.DROPSHIP.BUTTON.NO`),
      class: 'border-m mr-s',
      clickAction: POPUP_CLICK_TYPE.CLOSE_POPUP
    },
    {
      title: t(`INVOICES.DIALOG.DROPSHIP.BUTTON.YES`),
      class: 'bg-app text-white mr-ss',
      clickAction: POPUP_CLICK_TYPE.CONFIRM_DROPSHIP
    }
  ];

  const catchClicks = (data: PopupClickActionType) => {
    switch (data.type) {
      case POPUP_CLICK_TYPE.CLOSE_POPUP:
        props.removePopup();
        break;
    }
  };
  return (
    <PopupWrapper
      clickAction={catchClicks}
      type={POPUP_TYPE.POPUP}
      title={``}
      btnList={dropShipButtonConfig}
      width={'600px'}
      height={'auto'}
    >
      <AddStockTransferPopup
        records={[]}
        selectedRecord={null}
        detail={props.stockTransferItems}
        document={DOC_TYPE.JOB_WORK_OUT_ORDER}
        passingInteraction={(callback: CallBackPayloadType) => {}}
        onSave={() => {
          if (props.closeMenuContainer) {
            props.closeMenuContainer();
          }
          props.removePopup();
        }}
        onCancel={() => {
          if (props.closeMenuContainer) {
            props.closeMenuContainer();
          }
          props.removePopup();
        }}
        allowFullScreen
      />
    </PopupWrapper>
  );
};
