import { useCallback, useContext, useEffect, useState } from 'react';
import { CommonDraftPropsContext } from '../../Utilities/DocContext';
import { useAppDispatch, useAppSelector } from '../../../../Redux/Hooks';
import {
  selectDocumentFormDataByKeys,
  updateMultipleKeysInDocument
} from '../../../../Redux/Slices/DocumentSlice';
import { shallowEqual } from 'react-redux';
import { CustomFieldsHolder } from '../../../../SharedComponents/CustomFieldsHolder/CustomFieldsHolder';
import { getColumnConfigFromDocType } from '../../Helper/Common/CustomFieldsHelper';
import {
  BOOKS_ADDRESS_TYPES,
  MODULES_NAME
} from '../../../../Constants/Constant';
import Utility from '../../../../Utility/Utility';

interface DocAddressCustomFieldHolderProps {
  addressType?: BOOKS_ADDRESS_TYPES;
}

/**
 * Component to show/handle document custom fields
 */
const DocAddressCustomFieldHolder = (
  props: DocAddressCustomFieldHolderProps
) => {
  const { draftId, documentMode } = useContext(CommonDraftPropsContext);
  const [billTo, contact, documentType, shipFrom, shipTo] = useAppSelector(
    selectDocumentFormDataByKeys(draftId, [
      'billTo',
      'contact',
      'documentType',
      'shipFrom',
      'shipTo'
    ]),
    shallowEqual
  );

  const [billToUpdated, setBillToUpdated] = useState(false);
  const [shipToUpdated, setShipToUpdated] = useState(false);
  const [shipFromUpdated, setShipFromUpdated] = useState(false);
  const [contactUpdated, setContactUpdated] = useState(false);

  const getAddressCustomFields = () => {
    let addressCFs: any[] = [];
    const addressType = props.addressType;
    if (addressType === BOOKS_ADDRESS_TYPES.BILL_TO) {
      addressCFs = billTo?.customFields;
    } else if (addressType === BOOKS_ADDRESS_TYPES.SHIP_TO) {
      addressCFs = shipTo?.customFields;
    } else if (addressType === BOOKS_ADDRESS_TYPES.SHIP_FROM) {
      addressCFs = shipFrom?.customFields;
    }
    return addressCFs;
  };

  const dispatch = useAppDispatch();
  const addressCustomFields = getAddressCustomFields() || [];
  const columnConfigInfo = getColumnConfigFromDocType(documentType);

  const updateAddressCustomFields = useCallback(
    (updatedValue: any) => {
      if (!Utility.isEmpty(updatedValue)) {
        // console.log('Address CF updated: ', props.addressType, updatedValue);
        dispatch(
          updateMultipleKeysInDocument({
            draftId,
            keysToUpdate: { [props.addressType as string]: updatedValue }
          })
        );
      }
    },
    [dispatch, draftId, props.addressType]
  );

  useEffect(() => {
    setBillToUpdated(true);
  }, [billTo]);
  useEffect(() => {
    setShipToUpdated(true);
  }, [shipTo]);
  useEffect(() => {
    setShipFromUpdated(true);
  }, [shipFrom]);
  useEffect(() => {
    setContactUpdated(true);
  }, [contact]);

  const handleAddressCFUpdate = (updatedCFs: any[]) => {
    let updatedValue: any = {};
    let updatedObj: any;
    switch (props.addressType) {
      case BOOKS_ADDRESS_TYPES.SHIP_FROM:
        updatedValue = { ...shipFrom, customFields: [...updatedCFs] };
        updatedObj = shipFrom.customFields || [];
        break;
      case BOOKS_ADDRESS_TYPES.SHIP_TO:
        updatedValue = { ...shipTo, customFields: [...updatedCFs] };
        updatedObj = shipTo.customFields || [];
        break;
      case BOOKS_ADDRESS_TYPES.BILL_TO:
        updatedValue = { ...billTo, customFields: [...updatedCFs] };
        updatedObj = billTo.customFields || [];
        break;
    }
    if (
      JSON.stringify(updatedObj) !== JSON.stringify(updatedValue.customFields)
    ) {
      updateAddressCustomFields(updatedValue);
    }
  };

  return (
    <div className="row">
      <CustomFieldsHolder
        moduleName={MODULES_NAME.CONTACT_ADDRESS}
        customFieldsList={addressCustomFields}
        documentMode={documentMode}
        onUpdate={(updatedCFList) => {
          handleAddressCFUpdate(updatedCFList);
        }}
        contact={contact}
        hideAddfieldButton={true}
        columnConfig={columnConfigInfo.columnConfig}
        columnConfigTableId={columnConfigInfo.tableId}
        addressUpdate={
          billToUpdated || shipToUpdated || shipFromUpdated || contactUpdated
        }
        updateAddressField={() => {
          setBillToUpdated(false);
          setShipToUpdated(false);
          setShipFromUpdated(false);
          setContactUpdated(false);
        }}
      />
    </div>
  );
};

export default DocAddressCustomFieldHolder;
