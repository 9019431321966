import { Fragment, useCallback, useEffect, useState } from 'react';
import {
  DKLabel,
  DKListPicker2,
  DKTooltipWrapper,
  Toggle,
  DKIcon,
  DKIcons,
  DKButton,
  showAlert,
  showLoader,
  removeLoader
} from 'deskera-ui-library';
import BomExplosionAllocationConfirmmation, {
  BOM_EXPLOSION_ALLOCATION_TYPE
} from '../../../BomExplosionAllocationConfirmation';
import {
  BOMExplosionHelper,
  MASTER_ALLOCATE_TYPE,
  allItemsHaveAvailQtyForStockRequest,
  autoAllocateProduct,
  filterWIPProductsOnly,
  getBomProductIdsForAutoAllocation,
  getProductsFromType,
  hideWIPRMButton,
  isAnySubstitutePicked
} from '../../../../BomExplosion/BOMExplosionHelper';
import { WORK_ORDER_PR_PO } from '../../../../../Settings/AdvancedSettings/AdvancedSettings';
import Utility from '../../../../../../Utility/Utility';
import { IWorkOrder } from '../../../../../../Services/MRP/WorkOrder';
import ProductService from '../../../../../../Services/Product';
import { useAppSelector } from '../../../../../../Redux/Hooks';
import { activeTenantInfo } from '../../../../../../Redux/Slices/AuthSlice';
import { checkUserPermission } from '../../../../../Settings/GranularPermissions/GranularPermissionsHelper';
import { PERMISSIONS_BY_MODULE } from '../../../../../../Constants/Permission';
import { BOM_EXPLOSION_COLUMN_KEYS } from '../../../../Constants/MRPColumnConfigs';

interface BomExplosionPopupHeaderProps {
  bomExplosionData: any;
  activeWorkOrder: IWorkOrder;
  warehouseProductsInfo: any;
  isReadOnlyMode: boolean;
  isAdhocEnabled: boolean;
  selectedProductList: any[];
  displayedProductRows: any[];
  woInsufficientSetting: any;
  hideMainProductRowsWithSubstitute: boolean;
  onClose: (needRefresh?: any) => void;
  onButtonClick: (type: string, data?: any) => void;
  updateTrackingDetails: (
    type: string,
    trackingDetails: any,
    selectedProductItem?: any,
    isAutoAllocationFlow?: boolean,
    substituteItem?: any
  ) => void;
}

const BomExplosionHeader = (props: BomExplosionPopupHeaderProps) => {
  const [showAutoCreatePicker, setShowAutoCreatePicker] = useState(false);
  const [showAllocationTypePopup, setShowAllocationTypePopup] = useState(false);
  const [disableAutoAllocationRM, setDisableAutoAllocationRM] = useState(false);
  const [disableAutoAllocationWIP, setDisableAutoAllocationWIP] =
    useState(false);
  const [autoAllocateButtonType, setAutoAllocateButtonType] = useState('');
  const tenantInfo = useAppSelector(activeTenantInfo);
  // Check RM Products
  const hasRMProducts = getProductsFromType(
    props?.bomExplosionData?.bomProductConfiguration,
    MASTER_ALLOCATE_TYPE.RM,
    true
  );

  // Check WIP Products
  const hasWIPProducts = getProductsFromType(
    props?.bomExplosionData?.bomProductConfiguration,
    MASTER_ALLOCATE_TYPE.WIP,
    true
  );

  /* const hasProductWithAvailableSubstitutes = (
    props.displayedProductRows || []
  ).some((row) =>
    Utility.isNotEmpty(row[BOM_EXPLOSION_COLUMN_KEYS.AVAILABLE_SUBSTITUTES])
  ); */

  const firstLevelProducts = (props.displayedProductRows || []).filter(
    (row) => row.level === 1
  );
  // Check PO or PR Button Visible
  const showAutoCreatePOPR =
    BOMExplosionHelper.isCreatePOPRVisible(firstLevelProducts);

  // Check WO Button Visible
  const showAutoCreateWO =
    BOMExplosionHelper.isCreateWOVisible(firstLevelProducts);

  const isWOSettingForPO =
    Utility.isEmpty(props.woInsufficientSetting) ||
    props.woInsufficientSetting === WORK_ORDER_PR_PO.PURCHASE_ORDER;

  const disableAutoAllocationButton = useCallback(() => {
    let bomExplosionData: any = { ...(props.bomExplosionData || {}) };
    const { disableAutoRMButton, disableAutoWIPButton } =
      hideWIPRMButton(bomExplosionData);

    setDisableAutoAllocationRM(disableAutoRMButton);
    setDisableAutoAllocationWIP(disableAutoWIPButton);
  }, [props.bomExplosionData]);

  useEffect(() => {
    disableAutoAllocationButton();
  }, [props?.bomExplosionData, disableAutoAllocationButton]);

  /**
   * TODO: Need to refactor/reduce the method below
   */
  const autoAllocateSelectedProductsByType = (
    warehouseProductsByIdResponse: any,
    allocationType: BOM_EXPLOSION_ALLOCATION_TYPE,
    productTypeForAllocation: string
  ) => {
    const rrbEnabled = tenantInfo?.additionalSettings?.ROW_RACK_BIN?.filter(
      (item: any) => item?.enable
    );

    let quantitiesAllocated: any = [];
    removeLoader();

    const specificTypeOfProduct = getProductsFromType(
      props.bomExplosionData?.bomProductConfiguration,
      productTypeForAllocation,
      false
    );

    specificTypeOfProduct?.forEach((rawMaterialItem: any) =>
      autoAllocateProduct({
        rawMaterialItem,
        warehouseProductsByIdResponse,
        activeWorkOrder: props.activeWorkOrder,
        allocationType,
        rrbEnabled,
        quantitiesAllocated,
        updateTrackingDetails: props.updateTrackingDetails
      })
    );
    let allocatedItems = quantitiesAllocated?.filter(
      (rmItem: any) => rmItem
    )?.length;
    let notAllocatedItems = quantitiesAllocated?.filter(
      (rmItem: any) => !rmItem
    )?.length;

    if (specificTypeOfProduct?.length === allocatedItems) {
      showAlert(
        'Quantities allocated.',
        `We have auto allocated quantities from the ${
          productTypeForAllocation === MASTER_ALLOCATE_TYPE.WIP ? 'WIP' : 'raw'
        } materials.`
      );
    } else if (specificTypeOfProduct?.length === notAllocatedItems) {
      showAlert(
        'No quantities allocated.',
        `No quantities were allocated from the ${
          productTypeForAllocation === MASTER_ALLOCATE_TYPE.WIP ? 'WIP' : 'raw'
        } materials.`
      );
    } else if (
      allocatedItems > 0 &&
      allocatedItems < specificTypeOfProduct?.length
    ) {
      showAlert(
        'Partially allocated.',
        `Some products were not allocated due to non availability.`
      );
    }
  };

  const initiateAutoAllocationForSelectedProductType = (
    productTypeForAllocation: string,
    allocationType: BOM_EXPLOSION_ALLOCATION_TYPE
  ) => {
    const productIds = getBomProductIdsForAutoAllocation(
      props.bomExplosionData,
      productTypeForAllocation
    );

    if (Utility.isEmpty(productIds)) {
      showAlert(
        'Please note !',
        `There are no raw materials assigned to <span class="fw-b">${
          props.activeWorkOrder?.productName ?? ''
        }</span> component products`
      );
      return;
    }

    showLoader('Hold on! We are allocating the quantities meanwhile.');
    ProductService.fetchWarehouseProductsByID(productIds)
      .then((res) => {
        autoAllocateSelectedProductsByType(
          res,
          allocationType,
          productTypeForAllocation
        );
      })
      .catch((err) => {
        console.log('Master allocateion failed ', err);
        removeLoader();
      });
  };

  /** *****************RENDERERS****************** */
  const getHeading = () => {
    return (
      <div className="row pop-header-drag-handle">
        <DKLabel text="BOM Explosion" className="fs-l fw-m mr-s" />
        <DKTooltipWrapper
          content="Bill of Material (BOM) Explosion refers to the list of materials that are required to manufacture"
          tooltipStyle={{
            zIndex: 10
          }}
        >
          <DKIcon src={DKIcons.ic_info} className="opacity-5 ic-xs-2" />
        </DKTooltipWrapper>
      </div>
    );
  };

  const getLabelInfo = () => {
    return (
      <Fragment>
        {/* bom */}
        <div
          className=" border-radius-s bg-blue "
          style={{ width: 12, height: 12 }}
        />
        <DKLabel text="BOM" className="ml-s" />
        {/* raw material */}
        <div
          className=" border-radius-s bg-yellow ml-xl "
          style={{ width: 12, height: 12 }}
        />
        <DKLabel text="RM" className="ml-s" />
        {/* substitutes */}
        <div
          className=" border-radius-s bg-chip-blue ml-xl "
          style={{ width: 12, height: 12 }}
        />
        <DKLabel text="Substitutes" className="ml-s mr-xl" />
      </Fragment>
    );
  };

  const getAllotmentTypePopUp = () => {
    return (
      <div className="column position-absolute" style={{ top: 40, right: 0 }}>
        <BomExplosionAllocationConfirmmation
          onlySubstitutesVisible={props.hideMainProductRowsWithSubstitute}
          substituteAdded={isAnySubstitutePicked(props?.bomExplosionData)}
          onClose={() => {
            setShowAllocationTypePopup(false);
          }}
          onSelect={(selectedAllocationType: BOM_EXPLOSION_ALLOCATION_TYPE) => {
            initiateAutoAllocationForSelectedProductType(
              autoAllocateButtonType,
              selectedAllocationType
            );
            setShowAllocationTypePopup(false);
          }}
        />
      </div>
    );
  };

  const renderCloseButton = () => {
    return (
      <DKButton
        title="Close"
        onClick={() => props.onClose()}
        className="bg-white border-m ml-s"
      />
    );
  };

  const renderBulkRemoveProductsButton = () => {
    const selectedProducts = props.selectedProductList || [];
    const isDeleteRestricted = selectedProducts.some(
      (rowData) => !rowData.isBomRowEditModeEnabled
    );
    return (
      !props.isReadOnlyMode &&
      props.isAdhocEnabled &&
      Utility.isNotEmpty(selectedProducts) &&
      !isDeleteRestricted && (
        <DKButton
          title="Bulk Remove"
          onClick={() => props.onButtonClick('bulkRemove')}
          className="bg-white border-m ml-s"
        />
      )
    );
  };

  const renderSubstituteOnlyToggle = () => {
    return (
      <div className="row width-auto">
        <DKLabel text="Substitutes only" style={{ whiteSpace: 'nowrap' }} />
        <Toggle
          isOn={props.hideMainProductRowsWithSubstitute}
          onChange={() => props.onButtonClick('toggleShowOnlySubstitute')}
          className="box-content ml-s"
        />
      </div>
    );
  };

  const renderCreateWOButton = () => {
    return (
      !props.isReadOnlyMode &&
      props.selectedProductList.length > 0 &&
      !BOMExplosionHelper.isWOAlreadyCreated(props.selectedProductList) &&
      filterWIPProductsOnly(props.selectedProductList)?.length > 0 && (
        <DKButton
          title="Create WO"
          onClick={() => props.onButtonClick('createWO')}
          className="bg-white border-m ml-s"
        />
      )
    );
  };

  const renderCreateStockRequestButton = () => {
    return (
      props.selectedProductList.length > 0 &&
      (Utility.isBinAllocationMandatory() ||
        Utility.isWarehouseTaggingEnabled()) &&
      allItemsHaveAvailQtyForStockRequest(props.selectedProductList) && (
        <DKButton
          title={`Create Stock Request`}
          onClick={async () => {
            props.onButtonClick('createStocksRequest');
          }}
          className="bg-white border-m ml-s"
        />
      )
    );
  };

  const renderCreatePOPRButton = () => {
    const hasCreatePO =
      props.woInsufficientSetting === WORK_ORDER_PR_PO.PURCHASE_ORDER &&
      checkUserPermission(PERMISSIONS_BY_MODULE.PURCHASE_ORDER.CREATE);
    const hasCreatePR =
      props.woInsufficientSetting === WORK_ORDER_PR_PO.PURCHASE_REQUISITION &&
      checkUserPermission(PERMISSIONS_BY_MODULE.REQUISITION.CREATE);

    if (props.selectedProductList.length <= 0) {
      return null;
    }

    if (hasCreatePO) {
      return (
        <DKButton
          title={`Create PO`}
          onClick={() => {
            props.onButtonClick('createPO');
          }}
          className="bg-white border-m ml-s"
        />
      );
    } else {
      if (hasCreatePR) {
        return (
          <DKButton
            title={`Create PR`}
            onClick={() => {
              props.onButtonClick('createPR');
            }}
            className="bg-white border-m ml-s"
          />
        );
      } else {
        return null;
      }
    }
  };

  const renderAutoCreatePickerButton = () => {
    type MenuItem = { title: string; onClick: () => void };
    const autoCreateMenuItems: MenuItem[] = [];

    if (
      showAutoCreateWO &&
      checkUserPermission(PERMISSIONS_BY_MODULE.WORK_ORDER.CREATE)
    ) {
      autoCreateMenuItems.push({
        title: 'WO',
        onClick: () => props.onButtonClick('createAutoWO')
      });
    }
    const hasPOPermission = checkUserPermission(
      PERMISSIONS_BY_MODULE.PURCHASE_ORDER.CREATE
    );
    const hasPRPermission = checkUserPermission(
      PERMISSIONS_BY_MODULE.REQUISITION.CREATE
    );

    if (showAutoCreatePOPR) {
      if (hasPOPermission && isWOSettingForPO) {
        autoCreateMenuItems.push({
          title: 'PO',
          onClick: () => props.onButtonClick('createAutoPO')
        });
      } else {
        if (hasPRPermission) {
          autoCreateMenuItems.push({
            title: 'PR',
            onClick: () => props.onButtonClick('createAutoPR')
          });
        }
      }
    }

    return (
      !props.isReadOnlyMode &&
      props.selectedProductList.length === 0 &&
      Utility.isNotEmpty(autoCreateMenuItems) && (
        <div className="row width-auto position-relative">
          <DKButton
            title="Auto Create"
            icon={DKIcons.ic_arrow_down2}
            isReverse={true}
            onClick={() => setShowAutoCreatePicker(true)}
            className="bg-white border-m ml-s"
          />
          {showAutoCreatePicker && (
            <DKListPicker2
              title="Auto create"
              className="position-absolute z-index-3 border-m border-radius-s top-0 left-0"
              data={autoCreateMenuItems}
              displayKey={'title'}
              onSelect={(index: number, obj: MenuItem) => obj?.onClick?.()}
              onClose={() => setShowAutoCreatePicker(false)}
            />
          )}
        </div>
      )
    );
  };

  const renderAutoAllocateWIPButton = () => {
    return (
      !props.isReadOnlyMode &&
      hasWIPProducts && (
        <DKButton
          title="Auto-Allocate WIP"
          disabled={disableAutoAllocationWIP}
          onClick={() => {
            setShowAllocationTypePopup(true);
            setAutoAllocateButtonType(MASTER_ALLOCATE_TYPE.WIP);
          }}
          className="bg-white border-m ml-s"
        />
      )
    );
  };

  const renderAutoAllocateRMButton = () => {
    return (
      !props.isReadOnlyMode &&
      hasRMProducts && (
        <DKButton
          title="Auto-Allocate RM"
          disabled={disableAutoAllocationRM}
          onClick={() => {
            setShowAllocationTypePopup(true);
            setAutoAllocateButtonType(MASTER_ALLOCATE_TYPE.RM);
          }}
          className="bg-white border-m ml-s"
        />
      )
    );
  };

  const renderSubstituteAssignmentButton = () => {
    return (
      !props.isReadOnlyMode && (
        <DKButton
          title="Assign Substitutes"
          onClick={() => props.onButtonClick('assignSubstitutes')}
          className="bg-white border-m ml-s"
        />
      )
    );
  };

  const onTapSaveButton = () => {
    if (props.selectedProductList?.length === 0) {
      props.onButtonClick('saveBomExplosion');
    } else {
      let buttons = [
        {
          title: 'Later',
          className: 'bg-gray2 border-m ',
          onClick: () => {
            props.onButtonClick('saveLaterBOMExplosion');
          }
        },
        {
          title: 'Yes',
          className: 'bg-app text-white ml-r',
          onClick: () => {
            if (
              props.woInsufficientSetting === WORK_ORDER_PR_PO.PURCHASE_ORDER
            ) {
              props.onButtonClick('createPO');
            }
            if (
              props.woInsufficientSetting ===
              WORK_ORDER_PR_PO.PURCHASE_REQUISITION
            ) {
              props.onButtonClick('createPR');
            }
          }
        }
      ];
      showAlert(
        'Warning',
        `Raise ${
          props.woInsufficientSetting === WORK_ORDER_PR_PO.PURCHASE_REQUISITION
            ? 'Purchase Requisition'
            : 'Purchase Order'
        } for the items with insufficient stock?`,
        buttons
      );
    }
  };

  const renderSaveButton = () => {
    return (
      !props.isReadOnlyMode && (
        <DKButton
          title="Save"
          className="bg-button text-white ml-s"
          onClick={() => {
            onTapSaveButton();
          }}
        />
      )
    );
  };

  return (
    <div
      className="row justify-content-between bg-gray1 p-l"
      style={{ height: 60 }}
    >
      {getHeading()}
      <div className="row width-auto">
        {getLabelInfo()}

        {renderSubstituteOnlyToggle()}

        {renderSubstituteAssignmentButton()}

        {renderBulkRemoveProductsButton()}

        {/* create WO */}
        {renderCreateWOButton()}

        {/* auto create WO */}
        {renderAutoCreatePickerButton()}

        {/* create stock request button */}
        {renderCreateStockRequestButton()}

        {/* create po/pr button */}
        {renderCreatePOPRButton()}

        <div className="row width-auto position-relative">
          {/* auto allocate wip */}
          {renderAutoAllocateWIPButton()}
          {/* auto allocate rm */}
          {renderAutoAllocateRMButton()}
          {showAllocationTypePopup && getAllotmentTypePopUp()}
        </div>

        {/* close button */}
        {renderCloseButton()}

        {/* save button */}
        {renderSaveButton()}
      </div>
    </div>
  );
};

export default BomExplosionHeader;
