import ApiConstants from "../../Constants/ApiConstants";
import { IMPORT_MODULE } from "../../Constants/Constant";
import { COMPLIANCE_SPECIFIC_FIELD_NAME } from "../../Constants/Enum";
import Utility from "../../Utility/Utility";
import { DKLabel } from 'deskera-ui-library';

///////////////////////////////////////
/* Import Export File Type Constants */
///////////////////////////////////////
const supportedFileTypes = {
  CSV: 'csv',
  XLSX: 'xlsx',
  XML: 'xml'
};
const acceptedFileFormats = ['.csv', '.xlsx','.xml'];
const supportedMimeTypes = {
  csv: 'text/csv;charset=utf-8;',
  xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
};
const bufferTypes = {
  csv: 'string',
  xlsx: 'array'
};
////////////////////////////////////////

////////////////////////////////////////
/* Mapping Screen */
///////////////////////////////////////
const excludedColumnCodes = [
  //   COLUMN_CODE.CONTACT.STATUS,
  //   COLUMN_CODE.CONTACT.SEGMENT,
];
const headerMapDisplaySections = [
  {
    name: 'Imported Header',
    key: 'importedKey'
    /* column Type => Text */
  },
  {
    name: 'Deskera Header',
    key: 'dkColKey'
    /* column Type => Select */
  },
  {
    name: 'Sample',
    key: 'sampleData'
    /* column Type => Text */
  }
];
/////////////////////////////////////////

////////////////////////////////////////
/* Import Options */
////////////////////////////////////////
// const importOptions = JSON.stringify([
const importOptions = JSON.stringify([
  {
    title: 'Import Options',
    options: [
      {
        value: 'ADD',
        displayValue: 'Add New Records',
        tooltips:
          'If this option is chosen, only the new records from the uploaded file will be considered.',
        checked: false
      },
      {
        value: 'UPDATE',
        displayValue: 'Update Existing Records',
        tooltips:
          'If this option is chosen, any new records will not be considered. Only the records present in the system will be compared with the imported file records',
        checked: false
      },
      {
        value: 'ADD_AND_UPDATE',
        displayValue: 'Add New, And Update Existing Records',
        tooltips:
          'If this option is chosen, system will check for the new as well as updated records.',
        checked: true
      }
    ],
    selectedOption: 'ADD_AND_UPDATE',
    payloadKey: 'importOption'
  },
  {
    title: 'In case of Duplicate Records',
    options: [
      {
        value: 'REJECT',
        displayValue: 'Reject the duplicate',
        checked: true
      },
      {
        value: 'IMPORT',
        displayValue: 'Import the duplicate rows',
        checked: false
      }
    ],
    selectedOption: 'REJECT',
    payloadKey: 'duplicateRecordOption'
  },
  {
    title: 'In case of Invalid Values',
    options: [
      {
        value: 'REJECT_ROW',
        displayValue: 'Reject the Entire Row',
        tooltips:
          'If any value is invalid and not corrected, then the entire row will not be imported',
        checked: false
      },
      {
        value: 'REJECT_VALUE',
        displayValue: 'Reject the Invalid Value, But Import the Row',
        tooltips:
          'If a particular value is invalid, then only that value will not be imported, but the rest of the row will be imported. If the invalid value is for a mandatory field then the default value will be taken.',
        checked: true
      }
      // {
      //   value: 'ADD_VALUE',
      //   displayValue: 'Add the Value in the Dropdown',
      //   tooltips:
      //     'If the field is a dropdown, then the invalid value will be validated by adding in the system',
      //   checked: true
      // }
    ],
    selectedOption: 'REJECT_VALUE',
    payloadKey: 'invalidValueOption'
  },
  {
    title: 'In case of Invalid Values',
    options: [
      {
        value: 'REJECT',
        displayValue: 'Reject the duplicate',
        checked: true
      },
      {
        value: 'IMPORT',
        displayValue: 'Import with update',
        tooltips:
          'If this option is chosen, system will check for the new as well as updated records.',
        checked: false
      }
    ],
    selectedOption: 'REJECT',
    payloadKey: 'duplicateRecordOption'
  }
]);

const contactImportOptions = JSON.stringify([
  {
    title: 'Import Options',
    options: [
      {
        value: 'ADD',
        displayValue: 'Add New Records',
        tooltips:
          'If this option is chosen, only the new records from the uploaded file will be considered.',
        checked: false
      },
      {
        value: 'UPDATE',
        displayValue: 'Update Existing Records',
        tooltips:
          'If this option is chosen, any new records will not be considered. Only the records present in the system will be compared with the imported file records',
        checked: false
      },
      {
        value: 'ADD_AND_UPDATE',
        displayValue: 'Add New, And Update Existing Records',
        tooltips:
          'If this option is chosen, system will check for the new as well as updated records.',
        checked: true
      }
    ],
    selectedOption: 'ADD_AND_UPDATE',
    payloadKey: 'importOption'
  },
  {
    title: 'In case of Duplicate Records',
    subtitle:
      "Contact's record is duplicate, when the Contact's Name and GSTIN matches",
    options: [
      {
        value: 'REJECT',
        displayValue: 'Reject the duplicate',
        checked: true
      },
      {
        value: 'IMPORT',
        displayValue: 'Import the duplicate rows',
        checked: false
      }
    ],
    selectedOption: 'REJECT',
    payloadKey: 'duplicateRecordOption'
  },
  {
    title: 'In case of Invalid Values',
    options: [
      {
        value: 'REJECT_ROW',
        displayValue: 'Reject the Entire Row',
        tooltips:
          'If any value is invalid and not corrected, then the entire row will not be imported',
        checked: false
      },
      {
        value: 'REJECT_VALUE',
        displayValue: 'Reject the Invalid Value, But Import the Row',
        tooltips:
          'If a particular value is invalid, then only that value will not be imported, but the rest of the row will be imported. If the invalid value is for a mandatory field then the default value will be taken.',
        checked: true
      },
      // {
      //   value: 'ADD_VALUE',
      //   displayValue: 'Add the Value in the Dropdown',
      //   tooltips:
      //     'If the field is a dropdown, then the invalid value will be validated by adding in the system',
      //   checked: true
      // }
    ],
    selectedOption: 'REJECT_VALUE',
    payloadKey: 'invalidValueOption'
  }
]);

const productImportOptions = JSON.stringify([
  {
    title: 'In case of Duplicate Values',
    subtitle: 'Product will be considered duplicate, when the sequence code matches with the existing product.',
    options: [
      {
        value: 'REJECT',
        displayValue: 'Reject the duplicate',
        checked: true
      },
      {
        value: 'IMPORT',
        displayValue: 'Import with update',
        tooltips:
          'If this option is chosen, system will check for the new as well as updated records.',
        checked: false
      },
    ],
    selectedOption: 'REJECT',
    payloadKey: 'duplicateRecordOption'
  },
  {
    title: 'In case of Invalid Values',
    options: [
      {
        value: 'REJECT_ROW',
        displayValue: 'Reject the Entire Row',
        tooltips:
          'If any value is invalid and not corrected, then the entire row will not be imported',
        checked: false
      },
      {
        value: 'REJECT_VALUE',
        displayValue: 'Reject the Invalid Value, But Import the Row',
        tooltips:
          'If a particular value is invalid, then only that value will not be imported, but the rest of the row will be imported. If the invalid value is for a mandatory field then the default value will be taken.',
        checked: true
      }
      // {
      //   value: 'ADD_VALUE',
      //   displayValue: 'Add the Value in the Dropdown',
      //   tooltips:
      //     'If the field is a dropdown, then the invalid value will be validated by adding in the system',
      //   checked: true
      // }
    ],
    selectedOption: 'REJECT_VALUE',
    payloadKey: 'invalidValueOption'
  }
]);

const invoiceImportOptions = JSON.stringify([
  {
    title: 'In case of Invalid Values',
    options: [
      {
        value: 'REJECT_ROW',
        displayValue: 'Reject the Entire Row',
        tooltips:
          'If any value is invalid and not corrected, then the entire row will not be imported',
        checked: false
      },
      {
        value: 'REJECT_VALUE',
        displayValue: 'Reject the Invalid Value, But Import the Row',
        tooltips:
          'If a particular value is invalid, then only that value will not be imported, but the rest of the row will be imported. If the invalid value is for a mandatory field then the default value will be taken.',
        checked: true
      },
      // {
      //   value: 'ADD_VALUE',
      //   displayValue: 'Add the Value in the Dropdown',
      //   tooltips:
      //     'If the field is a dropdown, then the invalid value will be validated by adding in the system',
      //   checked: true
      // }
    ],
    selectedOption: 'REJECT_VALUE',
    payloadKey: 'invalidValueOption'
  },
  {
    subtitle:
      'Default product tax will be populated if tax is not provided'
  }
]);


const standardDocImportOptions = JSON.stringify([
  {
    subtitle:
      'Default product tax will be populated if tax is not provided'
  }
]);



const bankImportOptions = JSON.stringify([
  {
    title: 'In case of Duplicate Records',
    subtitle:
      'Bank transaction record is duplicate, when the reference number and cheque number matches',
    options: [
      {
        value: 'REJECT',
        displayValue: 'Reject the duplicate',
        checked: true
      },
      {
        value: 'IMPORT',
        displayValue: 'Import the duplicate rows',
        checked: false
      }
    ],
    selectedOption: 'REJECT',
    payloadKey: 'duplicateRecordOption'
  }
]);

const expensePrepaymentImportOptions = JSON.stringify([
  {
    title: 'In case of Invalid Values',
    options: [
      {
        value: 'REJECT_ROW',
        displayValue: 'Reject the Entire Row',
        tooltips:
          'If any value is invalid and not corrected, then the entire row will not be imported',
        checked: false
      },
      {
        value: 'REJECT_VALUE',
        displayValue: 'Reject the Invalid Value, But Import the Row',
        tooltips:
          'If a particular value is invalid, then only that value will not be imported, but the rest of the row will be imported. If the invalid value is for a mandatory field then the default value will be taken.',
        checked: true
      },
      // {
      //   value: 'ADD_VALUE',
      //   displayValue: 'Add the Value in the Dropdown',
      //   tooltips:
      //     'If the field is a dropdown, then the invalid value will be validated by adding in the system',
      //   checked: true
      // }
    ],
    selectedOption: 'REJECT_VALUE',
    payloadKey: 'invalidValueOption'
  }
]);
///////////////////////////////////////

export const getProductURL = (module) => {
  let URL = `${ApiConstants.URL.BASE}products`;

  const countryCode = Utility.getTenantSpecificApiCode(COMPLIANCE_SPECIFIC_FIELD_NAME.PRODUCT);
  if (countryCode !== '') {
    URL += `/${countryCode}`;
  }

  URL += `?limit=${process.env.REACT_APP_IMPORT_OPTIONS_COUNT}&page=0&sort=name&sortDir=ASC&query=active=true`;

  switch (module) {
    case IMPORT_MODULE.INVOICE:
    case IMPORT_MODULE.QUOTATION:
    case IMPORT_MODULE.SALES_ORDER:
    case IMPORT_MODULE.PRICE_LIST:
      URL += `&productTransactionType=SALES,BOTH`;
      break;
    case IMPORT_MODULE.ORDER:
    case IMPORT_MODULE.BILL:
      URL += `&productTransactionType=PURCHASE,BOTH`;
      break;
    default:
      break;
  }

  return URL;
}

const getContactURL = () => {
  let URL = `${ApiConstants.URL.BASE}contacts`;

  const countryCode = Utility.getTenantSpecificApiCode(COMPLIANCE_SPECIFIC_FIELD_NAME.CONTACT);
  if (countryCode !== '') {
    URL += `/${countryCode}`;
  }

  URL += `?limit=${process.env.REACT_APP_IMPORT_OPTIONS_COUNT}&page=0&sort=name&sortDir=ASC&query=status=ACTIVE`;

  return URL;
}

const getProductHSNAndSacData = (response) => {
  let combinedData = [];
  if(response && (response?.hsnCodes || response?.sacCodes)) {
    if(Array.isArray(response?.hsnCodes)) {
        const slicedHsn = response.hsnCodes.splice(0, process.env.REACT_APP_IMPORT_OPTIONS_COUNT);
        combinedData = combinedData.concat(slicedHsn);
    }
    if(Array.isArray(response?.sacCodes)) {
        const slicedSac = response.sacCodes.splice(0,process.env.REACT_APP_IMPORT_OPTIONS_COUNT);
        combinedData = combinedData.concat(slicedSac);
    }
  }
  return combinedData;
}
 
const REMOTE_DATA_FIELD = {
  PRODUCT_NAME: 'productName',
  PRODUCT_CODE: 'productCode',
  CONTACT_NAME: 'contactName',
  HSN_OR_SAC_CODE: 'hsnOrSacCode'
};

const REMOTE_DATA_CONFIG = {
  [REMOTE_DATA_FIELD.PRODUCT_NAME]: {
    method: 'GET',
    getUrl: (module) => {
      return (searchText) => {
        return `${getProductURL(module)}&search=${searchText}`;
      }
    },
    dataParser: (response) => {
      return response?.content || [];
    },
    renderer: (index, obj) => {
      if (obj.documentSequenceCode) {
        return <DKLabel text={`${obj.name} (${obj.documentSequenceCode})`} />;
      } else {
        return <DKLabel text={`${obj.label}`} />;
      }
    }
  },
  [REMOTE_DATA_FIELD.PRODUCT_CODE]: {
    method: 'GET',
    getUrl: (module) => {
      return (searchText) => {
        return `${getProductURL(module)}&search=${searchText}`;
      }
    },
    dataParser: (response) => {
      return Array.isArray(response?.content) ? response.content.map(p => {
        return {
          color: "",
          id: p.documentSequenceCode,
          label: p.documentSequenceCode,
          name: p.documentSequenceCode,
          value: p.documentSequenceCode
        };
      }) : [];
    },
    renderer: (index, obj) => {
      if (obj.documentSequenceCode) {
        return <DKLabel text={obj.documentSequenceCode} />;
      } else {
        return <DKLabel text={obj.label} />;
      }
    }
  },
  [REMOTE_DATA_FIELD.CONTACT_NAME]: {
    method: 'GET',
    getUrl: (module) => {
      return (searchText) => {
        return `${getContactURL()}&search=${searchText}`;
      }
    },
    dataParser: (response) => {
      return response?.content || [];
    },
    renderer: (index, obj) => {
      return <DKLabel text={`${obj.name}`} />;
    }
  },
  [REMOTE_DATA_FIELD.HSN_OR_SAC_CODE]: {
    method: 'GET',
    getUrl: (module) => {
      return (searchText) => {
        return `${ApiConstants.URL.BASE}${ApiConstants.URL.ACCOUNTS.FETCH_HSN_SAC_ALL}?sort=code&sortDir=ASC&search=${searchText}`;
      }
    },
    dataParser: (response) => {
      if(response) {
        return getProductHSNAndSacData(response).map(c => {
          return {
            color: "",
            id: c.code,
            label: c.code,
            name: c.code,
            value: c.code
          }
        })
      }
      return [];
    },
    renderer: (index, obj) => {
      if (obj.code) {
        return <DKLabel text={`${obj.code}`} />;
      } else {
        return <DKLabel text={`${obj.label}`} />;
      }
    }
  }
}

export {
  /* Import Export Constants */
  supportedFileTypes,
  supportedMimeTypes,
  bufferTypes,
  acceptedFileFormats,
  /* Column Mapper Screen */
  excludedColumnCodes,
  headerMapDisplaySections,
  /* Confirmation Screen */
  importOptions,
  contactImportOptions,
  productImportOptions,
  invoiceImportOptions,
  standardDocImportOptions,
  bankImportOptions,
  expensePrepaymentImportOptions,
  REMOTE_DATA_CONFIG
};
