import { showAlert } from 'deskera-ui-library';
import {
  APPROVAL_STATUS,
  BOOKS_DATE_FORMAT,
  DOC_TYPE,
  DOCUMENT_MODE,
  LABELS,
  RECORD_SAVED_EVENT_DOC_TYPE
} from '../../../../Constants/Constant';
import { PAGE_ROUTES } from '../../../../Managers/RouteManager';
import { AnyDocument, DraftTypes, IDocument } from '../../../../Models/Drafts';
import {
  onDocumentClose,
  updateMultipleKeysInDocument
} from '../../../../Redux/Slices/DocumentSlice';
import { Store } from '../../../../Redux/Store';
import AuthService from '../../../../Services/Auth';
import DateFormatService from '../../../../Services/DateFormat';
import NumberFormatService from '../../../../Services/NumberFormat';
import PurchaseOrderService from '../../../../Services/PurchaseOrder';
import {
  cascadingDiscountsInvalidMessage,
  checkGSTINPresentForSelectedContact,
  checkIfTotalDiscountInvalid,
  convertExpectedDeliveryDateInString,
  customFieldsContainsErrors,
  getDocumentAlert,
  inactiveContactMessage,
  isDocContactInactive,
  rebuildCascadingDiscountsForSaving,
  showAlertOnDocAPIError,
  updateAddressAsPerLocationCF
} from '../../../../SharedComponents/DocumentForm/NewDocumentHelper';
import Utility, { deepClone } from '../../../../Utility/Utility';
import {
  DOC_SAVE_OPTION,
  setCanValidateDocument,
  setIsSavingDocument
} from '../View/ActionBarHelper';
import { DocSaver } from './DocSaver';
import {
  fetchOrders,
  updateRefreshCallback
} from '../../../../Redux/Slices/PurchaseOrdersSlice';
import { COMPLIANCE_SPECIFIC_FIELD_NAME } from '../../../../Constants/Enum';
import {
  deleteDrafts,
  fetchDrafts
} from '../../../../Redux/Slices/DraftsSlice';
import { fetchInvoices } from '../../../../Redux/Slices/InvoicesSlice';
import { fetchQuotes } from '../../../../Redux/Slices/QuotesSlice';
import { fetchSalesOrders } from '../../../../Redux/Slices/SalesOrderSlice';
import { updatePOPRAssociation } from '../../../../Redux/Slices/MRP/WorkOrderSlice';
import { handleMachineShortFallPOCreate } from '../../../Mrp/MachineMaster/MachineMasterHelper';
import { ADDITIONAL_CHARGE_METHODS } from '../../../../SharedComponents/AdditionalCharges/AdditionalCharges';
import { documentUpdated } from '../../../../Redux/Slices/CommonDataSlice';
import {
  COMMON_EVENTS,
  commonCustomEvent
} from '../../../../Services/event/commonEvents';

export class PurchaseOrderSaver extends DocSaver {
  draftId: number;
  documentMode: DOCUMENT_MODE;
  tenantInfo: any;
  docToSave: AnyDocument;
  saveOption: DOC_SAVE_OPTION;
  draftType: DraftTypes;
  replaceURLCallback: (() => void) | undefined;

  constructor(data: {
    draftToSave: IDocument<AnyDocument>;
    documentMode: DOCUMENT_MODE;
    saveOption: DOC_SAVE_OPTION;
    tenantInfo: any;
  }) {
    super(data);
    this.draftId = data.draftToSave.id;
    this.draftType = data.draftToSave.draftType;
    this.documentMode = data.documentMode;
    this.saveOption = data.saveOption;
    this.tenantInfo = data.tenantInfo;
    this.docToSave = this.updatedDoc({ ...data.draftToSave?.populateFormData });
  }

  // TODO: Handle this `onLinkedRecordUpdated` - This is not needed now, as we close form after update
  /* useEffect(() => {
    const onLinkedRecordUpdated = (data: { detail: IRecordSavedEventData }) => {
      if (
        !data?.detail ||
        data.detail.linkedDocType !== DOC_TYPE.ORDER ||
        data.detail.linkedDocId !== updatedOrder.poCode
      )
        return;

      updateForm(updatedOrder, updatedOrder, true);
    };

    commonCustomEvent.on(
      COMMON_EVENTS.RECORD_SAVED,
      onLinkedRecordUpdated,
      true
    );

    return () =>
      commonCustomEvent.remove(
        COMMON_EVENTS.RECORD_SAVED,
        onLinkedRecordUpdated
      );
  }, [updatedOrder]); */

  private updatedDoc = (doc: AnyDocument) => {
    if (this.tenantInfo.additionalSettings?.CASCADING_DISCOUNTS?.enable) {
      doc = {
        ...doc,
        items: rebuildCascadingDiscountsForSaving(doc)
      };
    }

    let orderWithUpdate = {
      ...doc,
      purchaseOrderItems: doc.items,
      orderDueDate: doc.validTillDate,
      dueDate: doc.validTillDate,
      receiveByDate: doc.fulfillmentDate,
      vendorCode: doc.contactCode,
      currencyCode: doc.currencyCode,
      currency: doc.currency,
      documentDate: doc.documentDate,
      totalAmount: doc.totalAmount,
      dropShipName: doc.dropShipName || ''
    };

    let editBillWithExtraKeys = {
      documentCode: '',
      documentType: DOC_TYPE.ORDER,
      currencyCode: doc.currencyCode,
      currency: doc.currency,
      priceListId: doc?.priceListId,
      priceListName: doc?.priceListName,
      entityId: this.documentMode === DOCUMENT_MODE.EDIT ? doc?.id : undefined,
      purchaseOrderItems: orderWithUpdate.purchaseOrderItems?.map((billDoc) => {
        return {
          ...billDoc,
          documentItemCode: billDoc.purchaseOrderItemCode
        };
      })
    };

    if (this.documentMode === DOCUMENT_MODE.EDIT) {
      orderWithUpdate = { ...orderWithUpdate, ...editBillWithExtraKeys };
    }

    return orderWithUpdate;
  };

  private sendTriggerOnApproval(payload: any) {
    let emails = Utility.getApproverEmail(payload);
    let sum =
      payload &&
      payload.purchaseOrderItems
        .map((item: any) => item.totalAmount)
        .reduce((prev: any, curr: any) => prev + curr, 0);
    let payloadObj = {
      contactCode: payload.contactCode,
      totalAmount: NumberFormatService.getNumber(sum),
      userName: AuthService.getUserName(),
      currency: Utility.getCurrencySymbolFromCode(payload.currency),
      approverMap: Object.fromEntries(emails),
      currentLevel: 1,
      approvalHistory: payload['multiApprovalDetails']?.approvalHistory || []
    };
    PurchaseOrderService.sendTriggerOnApproval(payloadObj).then(
      (response: any) => {},
      (err: any) => {
        console.error(
          'Error while sending approval trigger (PO -> sendTriggerOnApproval): ',
          err
        );
      }
    );
  }

  async saveAsDraft(approvalRequired?: boolean) {
    try {
      const approvalData = await super.updatePayloadWithApprovalData(
        this.docToSave,
        approvalRequired
      );
      const isApprovalRequired = approvalData?.isApprovalRequired;
      this.docToSave = approvalData?.payload || this.docToSave;

      let parsedItems =
        this.docToSave?.items && !Utility.isEmpty(this.docToSave?.items)
          ? [...this.docToSave.items]
          : [];
      parsedItems = parsedItems?.map((poItem: any) => {
        return {
          ...poItem,
          expectedDeliveryDt:
            typeof poItem.expectedDeliveryDt !== 'undefined' &&
            poItem.expectedDeliveryDt !== null &&
            poItem.expectedDeliveryDt !== ''
              ? DateFormatService.getDateStrFromDate(
                  poItem.expectedDeliveryDt,
                  BOOKS_DATE_FORMAT['DD-MM-YYYY']
                )
              : ''
        };
      });
      this.docToSave.items = parsedItems;
      this.docToSave.purchaseOrderItems = parsedItems;

      await super.saveDraft(this.docToSave);
      if (isApprovalRequired) {
        this.sendTriggerOnApproval(this.docToSave);
      }
      this.replaceURLCallback?.();
    } catch (err: any) {
      console.error('Error while saving draft (PO -> saveAsDraft): ', err);
    }
  }

  /**
   * Handles create and update PO calls
   */
  save(closeOnUpdate = true) {
    if (this.draftType === DraftTypes.UPDATE) {
      this.updateOrder(closeOnUpdate);
    } else {
      this.createOrder(true);
    }
  }

  private updateForm(
    docResp: any,
    payload: any,
    isUpdate = false,
    closeOnUpdate = true
  ) {
    PurchaseOrderService.fetchOrderDetails(docResp.poCode).then(
      (doc: any) => {
        const formData = {
          ...doc,
          documentType: DOC_TYPE.ORDER,
          items: [...doc.purchaseOrderItems].map((savedItem: any) => {
            const itemFromDocToSave = this.docToSave?.items?.find(
              (item: any) => item.lineNumber === savedItem.lineNumber
            );
            if (!Utility.isEmptyObject(itemFromDocToSave)) {
              return {
                ...itemFromDocToSave,
                ...savedItem
              };
            }
            return { ...savedItem };
          }),
          contact: {
            ...doc.contact,
            ...doc.contactDto
          },
          isDocumentTouched: false,
          documentDate: payload.documentDate,
          fulfillmentDate: payload.fulfillmentDate,
          validTillDate: payload.validTillDate,
          contactCode: payload.contactCode
        };
        if (!closeOnUpdate) {
          Store.dispatch(
            updateMultipleKeysInDocument({
              draftId: this.draftId,
              keysToUpdate: formData
            })
          );
        } else {
          Store.dispatch(onDocumentClose({ draftId: this.draftId }));
          if (!isUpdate) {
            if (payload.showCustomAlert) {
              showAlert(
                'Purchase Order Created!',
                `Purchase Order has been created successfully. ${
                  this.docToSave?.isCreateFromMRP
                    ? 'Please save current WO to link the created PO.'
                    : ''
                }`
              );
              Store.dispatch(updateRefreshCallback(new Date()));
              return;
            }
            getDocumentAlert(
              'Purchase Order created!',
              'Purchase Order has been created successfully.',
              formData,
              this.draftToSave,
              DOCUMENT_MODE.EDIT,
              PAGE_ROUTES.ORDERS
            );
            setIsSavingDocument(this.draftId, false);
          }
        }
      },
      (err) => {
        console.error('Error loading updated doc: ', err);
      }
    );
  }

  // Create Order
  private async createOrder(closeDoc: boolean) {
    setIsSavingDocument(this.draftId, true);
    setCanValidateDocument(this.draftId, true);

    let payload: any = deepClone(this.docToSave);
    payload = updateAddressAsPerLocationCF(payload);
    if (this.tenantInfo.additionalSettings?.CASCADING_DISCOUNTS?.enable) {
      payload = {
        ...payload,
        purchaseOrderItems: rebuildCascadingDiscountsForSaving(payload)
      };
    }
    if (
      payload.documentType === DOC_TYPE.BILL ||
      payload.documentType === DOC_TYPE.ORDER
    ) {
      payload = {
        ...payload,
        purchaseOrderItems: convertExpectedDeliveryDateInString(payload)
      };
    }
    delete payload?.items;
    if (
      (this.documentMode === DOCUMENT_MODE.COPY ||
        this.documentMode === DOCUMENT_MODE.NEW) &&
      payload?.duplicate
    ) {
      const isContactInactive = isDocContactInactive(payload?.contact);
      if (isContactInactive) {
        showAlert('Error', inactiveContactMessage);
        setIsSavingDocument(this.draftId, false);
        return;
      }
    }

    if (payload.attachmentIds?.length) {
      payload.attachments = payload.attachmentIds.map(
        (attachmentId: any) => `${attachmentId}`
      );
    }

    if (!this.isDocValid(payload)) {
      setIsSavingDocument(this.draftId, false);
      return;
    }

    let isApproval = await Utility.isApprovalRequired(payload);
    if (isApproval === null) {
      setIsSavingDocument(this.draftId, false);
      return;
    } else if (isApproval) {
      this.saveAsDraft(isApproval);
      return;
    } else {
      payload['approvalStatus'] = APPROVAL_STATUS['NOT_REQUIRED'];
    }

    if (this.draftType === DraftTypes.DRAFT) {
      payload[
        'draftReferenceId'
      ] = `${this.draftTableId}/record/${this.draftId}`;
    }

    PurchaseOrderService.createOrder(
      payload,
      Utility.getTenantSpecificApiCode(
        COMPLIANCE_SPECIFIC_FIELD_NAME.PURCHASE_ORDER
      )
    ).then(
      (response: any) => {
        if (this.draftToSave) {
          if (closeDoc) {
            // Remove draft popup
            Store.dispatch(onDocumentClose({ draftId: this.draftId }));
          }
          if (this.draftType === DraftTypes.DRAFT) {
            Store.dispatch(
              deleteDrafts({
                recordId: this.draftId,
                tableId: this.draftTableId
              })
            );
          }
          this.updateForm(response, payload, false);
        } else {
          setIsSavingDocument(this.draftId, false);
        }
        Store.dispatch(
          fetchDrafts({
            tableId: this.draftTableId,
            isSaveColumnId: this.isSaveColumnId,
            draftTypeColId: this.draftTypeColId,
            draftTypeColValue: LABELS.PURCHASE_ORDERS
          })
        );

        handleMachineShortFallPOCreate(payload, response);

        if (response?.dropShip || response?.backOrder) {
          switch (response?.linkedDocuments?.[0]?.documentType) {
            case DOC_TYPE.INVOICE:
              Store.dispatch(fetchInvoices());
              break;
            case DOC_TYPE.QUOTE:
              Store.dispatch(fetchQuotes());
              break;
            case DOC_TYPE.SALES_ORDER:
              Store.dispatch(fetchSalesOrders());
              break;
            default:
              break;
          }
        } else {
          Store.dispatch(fetchOrders());
        }

        // for MRP
        if (payload?.updateResponseInStore) {
          let updatedRes: any = { ...response };
          if (payload?.isNonShortFallSettingFlow) {
            updatedRes = {
              ...updatedRes,
              isNonShortFallSettingFlow: payload?.isNonShortFallSettingFlow
            };
          }
          Store.dispatch(updatePOPRAssociation(updatedRes));
        }

        this.replaceURLCallback?.();
      },
      (err) => {
        console.error('Error while creating Order: ', err);
        setIsSavingDocument(this.draftId, false);
        showAlertOnDocAPIError(err);
        this.replaceURLCallback?.();
      }
    );
  }

  // Update Order
  private updateOrder(closeOnUpdate = true) {
    setIsSavingDocument(this.draftId, true);
    setCanValidateDocument(this.draftId, true);

    let payload: any = deepClone(this.docToSave);
    payload = { ...payload, contact: payload.contactDto };
    payload = updateAddressAsPerLocationCF(payload);
    if (this.tenantInfo.additionalSettings?.CASCADING_DISCOUNTS?.enable) {
      payload = {
        ...payload,
        purchaseOrderItems: rebuildCascadingDiscountsForSaving(payload)
      };
    }
    if (
      payload.documentType === DOC_TYPE.BILL ||
      payload.documentType === DOC_TYPE.ORDER
    ) {
      payload = {
        ...payload,
        purchaseOrderItems: convertExpectedDeliveryDateInString(payload)
      };
    }
    delete payload?.items;
    delete payload?.contactDto;

    if (payload.attachmentIds?.length) {
      payload.attachments = payload.attachmentIds.map(
        (attachmentId: any) => `${attachmentId}`
      );
    }

    if (!this.isDocValid(payload)) {
      setIsSavingDocument(this.draftId, false);
      return;
    }

    PurchaseOrderService.updateOrder(
      payload,
      Utility.getTenantSpecificApiCode(
        COMPLIANCE_SPECIFIC_FIELD_NAME.PURCHASE_ORDER
      )
    ).then(
      (res: any) => {
        if (this.draftToSave) {
          if (closeOnUpdate) {
            Store.dispatch(onDocumentClose({ draftId: this.draftId }));
          }
          setIsSavingDocument(this.draftId, false);
          this.updateForm(res, payload, true, closeOnUpdate);
        }

        // Dispatch custom event
        commonCustomEvent.dispatch(COMMON_EVENTS.RECORD_SAVED, {
          id: res?.poCode,
          type: RECORD_SAVED_EVENT_DOC_TYPE.PURCHASE_ORDER,
          linkedDocId: res?.linkedDocuments?.[0]?.documentCode,
          linkedDocType:
            res?.linkedDocuments?.[0]?.documentType ||
            RECORD_SAVED_EVENT_DOC_TYPE.BILL,
          isEdit: true
        });

        Store.dispatch(
          fetchDrafts({
            tableId: this.draftTableId,
            isSaveColumnId: this.isSaveColumnId,
            draftTypeColId: this.draftTypeColId,
            draftTypeColValue: LABELS.PURCHASE_ORDERS
          })
        );
        Store.dispatch(fetchOrders());
        setIsSavingDocument(this.draftId, false);
        this.replaceURLCallback?.();
        if (payload?.paymentMilestoneFlag) {
          Store.dispatch(
            documentUpdated({
              newData: payload,
              oldData: this.draftToSave?.populateFormData
            })
          );
        }
      },
      (err) => {
        console.error('Error updating PO: ', err);
        setIsSavingDocument(this.draftId, false);
        showAlertOnDocAPIError(err);
        this.replaceURLCallback?.();
      }
    );
  }

  private isDocValid(docToValidate: any) {
    // Validate Manual Document Sequence Code
    if (
      Utility.isEmpty(docToValidate.documentSequenceCode) &&
      Utility.isEmpty(docToValidate.sequenceFormat) &&
      docToValidate.manualMode
    ) {
      return false;
    }

    if (Utility.isEmpty(docToValidate.contact)) {
      return false;
    }

    if (Utility.isEmpty(docToValidate.purchaseOrderItems)) {
      return false;
    }

    if (!Utility.isEmpty(docToValidate.documentDate)) {
      const docDate = DateFormatService.getDateFromStr(
        docToValidate.documentDate,
        BOOKS_DATE_FORMAT['DD-MM-YYYY']
      );
      if (
        !Utility.checkActiveDateRangeValidation(
          docDate,
          this.tenantInfo,
          'Order date',
          docToValidate.documentType
        )
      ) {
        return false;
      }
      if (!Utility.checkClosingDate(docDate, 'Order date')) {
        return false;
      }
    }

    // Custom fields validation
    const customFieldHasErrors = customFieldsContainsErrors(
      docToValidate.customField
    );
    if (customFieldHasErrors) {
      return false;
    }
    // Custom fields validation ends

    // Line item errors
    let lineItemsHasErrors = false;
    for (let i = 0; i < docToValidate.purchaseOrderItems.length; i++) {
      const item = docToValidate.purchaseOrderItems[i];
      if (item.hasError || item.invalidFields?.length) {
        lineItemsHasErrors = true;
        break;
      }
    }

    if (lineItemsHasErrors) {
      return false;
    }
    // Line item errors ends

    // Contact GSTIN check
    if (!checkGSTINPresentForSelectedContact(docToValidate)) {
      return false;
    }
    // Contact GSTIN check ends

    // Check for -ve total amount
    const totalBeforeTax = docToValidate?.purchaseOrderItems?.length
      ? docToValidate?.purchaseOrderItems?.reduce(
          (total: number, item: any) => {
            return total + item?.totalWithDiscount;
          },
          0
        )
      : 0;
    if (totalBeforeTax < 0) {
      showAlert(
        'Invalid amount!',
        'Purchase order amount can not be less than 0.'
      );
      return false;
    }
    // Check for -ve total amount ends

    // Cascading discounts validation
    const cascadingDiscountSettings =
      this.tenantInfo.additionalSettings?.CASCADING_DISCOUNTS;
    if (cascadingDiscountSettings?.enable) {
      const isTotalDiscountInvalid = checkIfTotalDiscountInvalid(
        docToValidate,
        'purchaseOrderItems'
      );
      if (isTotalDiscountInvalid) {
        showAlert('Error!', cascadingDiscountsInvalidMessage);
        return false;
      }
    }
    // Cascading discounts validation ends

    // Additional charges check
    let additionalChargesHasErrors = false;
    const additionalChargesDetails =
      docToValidate.additionalCharges?.additionalChargesDetails;
    if (!Utility.isEmpty(additionalChargesDetails)) {
      for (let i = 0; i < additionalChargesDetails.length; i++) {
        const item = additionalChargesDetails[i];
        if (item.hasError) {
          additionalChargesHasErrors = true;
          break;
        }
      }
    }
    if (additionalChargesHasErrors) {
      return false;
    }

    let manualApportionCharges = additionalChargesDetails.filter(
      (item: any) =>
        item.apportionValue &&
        item.apportionValue === ADDITIONAL_CHARGE_METHODS.APPORTION_MANUAL
    );
    let manualApportionError = false;
    for (let charge of manualApportionCharges) {
      let manualApportionChargesInItems: any[] = [];

      docToValidate.purchaseOrderItems.forEach((item: any) => {
        const additionalCharge =
          item.additionalCharges.additionalChargesDetails.find(
            (c: any) => c.additionalCharge === charge.additionalCharge
          );
        if (!Utility.isEmpty(additionalCharge)) {
          manualApportionChargesInItems.push(additionalCharge);
        }
      });
      if (manualApportionChargesInItems.length) {
        const manualApportionChargesTotal =
          manualApportionChargesInItems.reduce(
            (total: number, detail: any) =>
              total + Number(detail?.chargeAmount || 0),
            0
          );
        if (manualApportionChargesTotal !== +charge.chargeAmount) {
          manualApportionError = true;
          break;
        }
      }
    }
    if (manualApportionError) {
      showAlert(
        'Oops!',
        `One or more line items contains incorrect allocation for additional charges`
      );
      return false;
    }

    // Additional charges check ends

    // Discount check
    const discountHasErrors =
      docToValidate.additionalCharges?.globalDiscount?.hasError;
    if (discountHasErrors) {
      return false;
    }
    // Discount check ends

    return true;
  }
}
