import {
  DKButton,
  DKCalendar,
  DKDataGrid,
  DKIcon,
  DKIcons,
  DKLabel,
  DKListPicker2,
  INPUT_TYPE,
  showAlert,
  DKInput,
  DKLine
} from 'deskera-ui-library';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ic_arrowdown from '../../../Assets/Icons/ic_arrow_down2.png';
import ic_arrowup from '../../../Assets/Icons/ic_arrow_up2.png';
import {
  BOOKS_ADDRESS_TYPES,
  BOOKS_DATE_FORMAT,
  CLASS_ASSIGNMENT,
  COMPLAINCE_CURRENCY,
  COMPOSTION_TAX_PERCENT,
  COUNTRY_CODES,
  CURRENCIES,
  CUSTOM_FIELD_TYPE,
  CUSTOM_NUMBER_INPUT_MODULES,
  DATE_FORMAT,
  DOCUMENT_MODE,
  DOC_TYPE,
  DOC_TYPE_TO_ATTACHMENT_MAP,
  GST_TYPE,
  INPUT_VIEW_DIRECTION,
  LOCATION_CLASS_ENUM,
  MODULES_NAME,
  MODULE_NAME_FOR_STORAGE_UTILITY,
  POPUP_CALLBACKS_TYPE,
  POPUP_CLICK_TYPE,
  POPUP_TYPE,
  PRODUCE_PRODUCT_TYPE,
  PRODUCT_TYPE,
  REGEX,
  STATUS_TYPE,
  TAX_SYSTEM,
  TAX_TYPES,
  UOM_NA_ID
} from '../../../Constants/Constant';
import { PERMISSIONS_BY_MODULE } from '../../../Constants/Permission';
import { NotesConfigManager } from '../../../Managers/NotesConfigManager';
import { initialNoteState } from '../../../Models/CreateAndEditNotes';
import {
  BtnType,
  CallBackPayloadType,
  PopupClickActionType,
  UpdateCallBacksRefType,
  BooksAddress,
  ReactSelectOptionsType,
  LocationDTO,
  LoadingBtnType
} from '../../../Models/Interfaces';
import { useAppDispatch, useAppSelector } from '../../../Redux/Hooks';
import {
  addAccounts,
  fetchAccoutnsList,
  selectedAccounts
} from '../../../Redux/Slices/AccountsSlice';
import {
  activeTenantInfo,
  appCustomizationInfo,
  eInvoiceAuthInfo,
  fetchEInvoiceAuthInfo
} from '../../../Redux/Slices/AuthSlice';
import {
  fetchClassesByDimensionId,
  selectCurrencyListWithExchangeRate,
  selectCustomFields,
  selectTaxes,
  selectProductCustomFields,
  selectUOMs,
  selectSalesTax,
  selectPurchaseTax,
  selectIsLoadingPopupWrapper,
  selectShowMainDocsInFullScreen
} from '../../../Redux/Slices/CommonDataSlice';
import {
  fetchContacts,
  selectContacts
} from '../../../Redux/Slices/ContactsSlice';
import {
  fetchCreditNotes,
  selectCreditNoteColumnConfig,
  selectCreditNoteColumnConfigTableId
} from '../../../Redux/Slices/CreditNoteSlice';
import {
  fetchDebit,
  selectDebitColumnConfig,
  selectDebitNoteColumnConfigTableId
} from '../../../Redux/Slices/DebitNoteSlice';
import AttachmentService from '../../../Services/Attachment';
import AuthService from '../../../Services/Auth';
import ContactService, { ContactAPIConfig } from '../../../Services/Contact';
import CreditNoteService from '../../../Services/CreditNote';
import DateFormatService from '../../../Services/DateFormat';
import DebitService from '../../../Services/Debit';
import { CustomFieldsHolder } from '../../../SharedComponents/CustomFieldsHolder/CustomFieldsHolder';
import CustomNumberFormatInput from '../../../SharedComponents/CustomNumberFormat/CustomNumberFormatInput';
import { GSTExchangeRateDialog } from '../../../SharedComponents/DocumentForm/GSTExchangeRateDialog';
import {
  createLineItem,
  customFieldsContainsErrors,
  getFormattedAddressObj,
  getMainModuleName,
  getNewCFItem,
  getNewColumnForCF,
  getTenantTaxSystem,
  getUomQuantity,
  isGSTExchangeRateRequired,
  rcmAppliedIndia,
  updateGstType
} from '../../../SharedComponents/DocumentForm/NewDocumentHelper';
import FormatAmount from '../../../SharedComponents/FormatAmount';
import PopupWrapper from '../../../SharedComponents/PopupWrapper';
import { ConfigUtility } from '../../../Utility/ConfigUtility';
import { NotesConfigUtility } from '../../../Utility/NotesConfigUtility';
import Utility, {
  convertBooksDateFormatToUILibraryFormat,
  deepClone,
  getCapitalized
} from '../../../Utility/Utility';
import AddContact from '../../Contacts/AddContact';
import { triggerDownload } from '../../ImportExport/utility/ExportData';
import {
  GranularPermissionsHelper,
  checkUserPermission
} from '../../Settings/GranularPermissions/GranularPermissionsHelper';
import AddTax from '../../Settings/Tax/AddTax';
import {
  getColumnConfigType,
  getNewColumn,
  getNewItem
} from '../JournalEntry/JEHelper';
import './CreateAndEditNotes.scss';
import { showLinkedDocumentSection } from './NotesHelper';
import AddClass from '../../Settings/Classes/AddClass';
import {
  fetchCategoryDimensions,
  selectDimensions
} from '../../../Redux/Slices/LocationSlice';
import { isViewportLarge } from '../../../Utility/ViewportSizeUtils';
import AppManager from '../../../Managers/AppManager';
import {
  CONTACT_FORM_TAB,
  ORGANISATION_FORM_TAB,
  PRODUCT_OFFERING_TYPE
} from '../../../Constants/Enum';
import {
  BillingAddressType,
  ShippingAddressType
} from '../../../Models/Document';
import { INDIAN_STATES_MOCK_DATA } from '../../../Constants/StaticData';
import { AddressType } from '../../../Models/EditAddress';
import InvoiceService, { InvoiceAPIConfig } from '../../../Services/Invoice';
import BillService from '../../../Services/Bill';
import { DocumentItem } from '../../../Models/DocumentItem';
import { DocumentConfigManager } from '../../../Managers/DocumentConfigManger';
import { DraftTypes } from '../../../Models/Drafts';
import { DocumentConfigUtility } from '../../../Utility/DocumentConfigUtility';
import { ItemTaxCalculator } from '../../../SharedComponents/DocumentForm/ItemTaxCalculator';
import TaxService from '../../../Services/Tax';
import { selectProductsWithVariants } from '../../../Redux/Slices/ProductsSlice';
import PriceListService from '../../../Services/PriceList';
import {
  getInvoiceNowStatus,
  INVOICE_NOW_TYPES
} from '../../Invoices/InvoiceHelper';
import { removeDraft } from '../../../Redux/Slices/DraftsSlice';
import RouteManager, { PAGE_ROUTES } from '../../../Managers/RouteManager';
import CancelEInvoice from '../../Settings/OrganisationProfile/CancelEInvoice';
import { IRPCredPopup } from '../../Settings/OrganisationProfile/IRPCredPopup';
import EInvoiceService from '../../../Services/EInvoice';
import { ADDITIONAL_CHARGE_METHODS } from '../../../SharedComponents/AdditionalCharges/AdditionalCharges';
import UpdateAddress from '../../../SharedComponents/DocumentForm/UpdateAddress';
import OrganisationProfileForm from '../../Settings/OrganisationProfile/OrganisationProfileForm';
import { localizedText } from '../../../Services/Localization/Localization';
import TaxGroupDistribution from '../../Tax/TaxGroupDistribution';
import useScreenResize from '../../../Hooks/useScreenResize';
import { selectTax } from '../../../Redux/Slices/TaxSlice';

export interface Iprops {
  documentMode?: DOCUMENT_MODE;
  populateFormData: any;
  passingInteraction: any;
  notesType: string;
  allowFullScreen: boolean;
  readOnly?: boolean;
  showLinkedDocPopup?: any;
}

const CreateAndEditNotes: React.FC<Iprops> = (props) => {
  const accountsData = useAppSelector(selectedAccounts);
  const tenantDetails = useAppSelector(activeTenantInfo);
  const currencyExchangeRates = useAppSelector(
    selectCurrencyListWithExchangeRate
  );
  const allTaxes = useAppSelector(selectTax);
  const taxes = useAppSelector(selectTaxes);
  const contactsData = useAppSelector(selectContacts);
  const creditColumnConfig = useAppSelector(selectCreditNoteColumnConfig);
  const creditConfigTableId = useAppSelector(
    selectCreditNoteColumnConfigTableId
  );
  const debitColumnConfig = useAppSelector(selectDebitColumnConfig);
  const debitConfigTableId = useAppSelector(selectDebitNoteColumnConfigTableId);

  const hideTaxView = getTenantTaxSystem() === TAX_SYSTEM.US;

  const { t, i18n } = useTranslation();

  const notesData = props.populateFormData;
  const isEditMode = Utility.isEmpty(notesData) ? false : true;
  const [taxList, setTaxlist] = useState<any>({});
  const [showCurrencySelection, setShowCurrencySelection] = useState(false);
  const [isReadOnly, setIsReadOnly] = useState(props.readOnly);
  const [openContactSelector, setOpenContactSelector] = useState(false);
  const [showAddContactPopup, setShowAddContactPopup] = useState(false);
  const [showTaxPopup, setShowTaxPopup] = useState(false);
  const [columnConfig, setColumnConfig] = useState(
    NotesConfigManager.docConfigs
  );
  const [accountRows, setAccountRows] = useState<any[]>([]);
  const [showCustomFieldView, setShowCustomFieldView] = useState(false);
  const [attachments, setAttachments] = useState<any>([]);
  const [submitButtonTapped, setSubmitButtonTapped] = useState(false);
  const [isActionBtnDisabled, setIsActionBtnDisabled] =
    useState<boolean>(false);

  //multi-currency states
  const [showMultiCurrencyList, setShowMultiCurrencyList] = useState(false);
  const [conversionRate, setConversionRate] = useState<any>();
  const [showTaxExchangeRatePopup, setShowTaxExchangeRatePopup] =
    useState(false);

  const [docDateOpen, setDocDateOpen] = useState(false);
  const [customFieldsData, setCustomFieldsData] = useState([]);
  const selectCustomFieldsData: any = useAppSelector(selectCustomFields);
  const systemDimensions = useAppSelector(selectDimensions);
  const [showAddClassPopup, setShowAddClassPopup] = useState(false);

  type ReactOptionType = ReactSelectOptionsType<string, string>;
  const isBillOrOrder = props.notesType === DOC_TYPE.DEBIT_NOTE;
  const [contactMode, setContactMode] = useState(DOCUMENT_MODE.EDIT);
  const [openBillingAddressList, setOpenBillingAddressList] = useState(false);
  const [openShippingAddressList, setOpenShippingAddressList] = useState(false);
  const [openShipFromForBuy, setOpenShipFromForBuy] = useState(false);
  const [showUpdateOrgPopup, setShowUpdateOrgPopup] = useState(false);
  const [indianStatesOptions, setIndianStatesOptions] = useState<any>([]);
  const [pos, setPos] = useState<any>([]);
  const [dos, setDos] = useState<any>([]);
  const shippingAddressBlock = useRef<any>(null);

  const [shipToOrFromAddressChangedForUS, setShipToOrFromAddressChangedForUS] =
    useState(false);

  const [activeContactTab, setActiveContactTab] = useState<CONTACT_FORM_TAB>(
    CONTACT_FORM_TAB.GENERAL_INFO
  );
  const [activeOrgProfileTab, setActiveOrgProfileTab] =
    useState<ORGANISATION_FORM_TAB>(ORGANISATION_FORM_TAB.GENERAL_INFO);
  const [booksAddressType, setBooksAddressType] = useState<BOOKS_ADDRESS_TYPES>(
    BOOKS_ADDRESS_TYPES.NONE
  );

  const [showUpdateAddressPopup, setShowUpdateAddressPopup] =
    useState<boolean>(false);

  const [selectedAddressType, setSelectedAddressType] = useState<string>(
    BOOKS_ADDRESS_TYPES.NONE
  );

  const [billingAddress, setBillingAddress] = useState<
    BillingAddressType | null | any
  >(null);
  const [shippingAddress, setShippingAddress] = useState<
    ShippingAddressType | null | any
  >(null);
  const [editModeBillingAddresses, setEditModeBillingAddresses] = useState<
    BooksAddress[]
  >([]);
  const [editModeShippingAddresses, setEditModeShippingAddresses] = useState<
    BooksAddress[]
  >([]);

  const [booksDocument, setBooksDocument] = useState<any>({
    shipFrom: null,
    billTo: null,
    shipTo: null
  });
  const [customLocation, setCustomLocation] = useState<LocationDTO>();
  const [showInvoiceList, setShowInvoiceList] = useState(false);
  const [invoiceList, setInvoiceList] = useState<any>([]);
  const [showBillList, setShowBillList] = useState(false);
  const [billList, setBillList] = useState<any>([]);
  const [selectedDocumentType, setSelectedDocumentType] = useState<any>(
    isBillOrOrder ? DOC_TYPE.BILL : DOC_TYPE.INVOICE
  );
  const [selectedDocument, setSelectedDocument] = useState<any>(undefined);
  const [loadingDocumentList, setLoadingDocumentList] = useState(false);
  const [productRows, setProductRows] = useState<DocumentItem[]>([]);
  const [documentType, setDocumentType] = useState(
    isBillOrOrder ? DOC_TYPE.BILL : DOC_TYPE.INVOICE
  );
  const [showProductPopup, setShowProductPopup] = useState(false);
  const [productColumnConfig, setProductColumnConfig] = useState(
    isBillOrOrder
      ? DocumentConfigManager.get(DOC_TYPE.BILL)
      : DocumentConfigManager.get(DOC_TYPE.INVOICE)
  );
  const [roundOffDirty, setRoundOffDirty] = useState<any>(!isEditMode);
  const productCFfromStore = useAppSelector(selectProductCustomFields);
  const salesTaxes = useAppSelector(selectSalesTax);
  const purchaseTaxes = useAppSelector(selectPurchaseTax);
  const dimensionData = useAppSelector(selectDimensions);
  const productsData = useAppSelector(selectProductsWithVariants);
  const uomsData = useAppSelector(selectUOMs);
  const appCustomizationData = useAppSelector(appCustomizationInfo);
  const popupLoading = useAppSelector(selectIsLoadingPopupWrapper);

  // const [lastRowProductUpdatedIndex, setLastRowProductUpdatedIndex] = useState<any>(null);

  const [productToEdit, setProductToEdit] = useState<any>(null);
  const [productCustomFields, setProductCustomFields] = useState<any[]>([]);

  const [currentIndex, setCurrentIndex] = useState(-1);
  const [selectedProductUOMS, setSelectedProductUOMS] = useState<any>();
  const [productsLastUnitPrice, setProductsLastUnitPrice] = useState<any>(null);
  const [selectedProductPriceList, setSelectedProductPriceList] = useState<
    number[]
  >([]);
  const [barcodeSearchData, setBarcodeSearchData] = useState<any[]>([]);
  const [hasShownProductLimitAlert, setHasShownProductLimitAlert] =
    useState(false);
  const [lineItemsTouched, setLineItemsTouched] = useState(false);

  const [showIRPCredPopup, setShowIRPCredPopup] = useState(false);
  const [showCancelEInvoice, setShowCancelEInvoice] = useState(false);
  const [IRPCredPopupType, setIRPCredPopupType] = useState('');

  const [lastUpdatedColumn, setLastUpdatedColumn] = useState<any>(null);
  const [indexToEdit, setIndexToEdit] = useState(-1);
  const [islineItemPercentage, setIslineItemPercentage] = useState(false);

  const lastProductRowUpdatedIndex = useRef<any>(null);
  const additionalCharges = useRef<any>(null);
  const isQuantityChangedManually = useRef<boolean>(false);
  const isUOMChangedManually = useRef<boolean>(false);
  const usTaxCalculateAlertVisible = useRef<boolean>(false);
  const isDocDateUpdatedManually = useRef<boolean>(false);
  const isContactChangedManually = useRef<boolean>(false);
  const eInvoiceAuthInfoData = useAppSelector(eInvoiceAuthInfo);
  const showMainDocsInFullscreen = useAppSelector(
    selectShowMainDocsInFullScreen
  );
  const isFullScreenMode = !!props.allowFullScreen && showMainDocsInFullscreen;
  const gridContainerRef = useRef<HTMLDivElement | null>(null);
  const [gridWidth] = useScreenResize(gridContainerRef);
  const productGridContainerRef = useRef<HTMLDivElement | null>(null);
  const [productGridWidth] = useScreenResize(productGridContainerRef);

  const refInitialState: UpdateCallBacksRefType = {
    pushDataToParent: { type: POPUP_CALLBACKS_TYPE.NONE },
    storeCallbacksRef: { updateContact: 'click' }
  };

  const editAddressRef = useRef<UpdateCallBacksRefType>(refInitialState);
  const addTaxRef = useRef<UpdateCallBacksRefType>(refInitialState);

  const orgProfileRef = useRef<UpdateCallBacksRefType>(refInitialState);

  const popupBtnConfigForEInvoice: BtnType[] = [
    {
      title: 'Cancel',
      class: 'border-m mr-s',
      clickAction: POPUP_CLICK_TYPE.CLOSE_POPUP
    },
    {
      title: 'Save',
      class: 'bg-app text-white mr-ss',
      clickAction: POPUP_CLICK_TYPE.IRP_CREDENTIAL
    }
  ];

  const loadingIRPCredPopupBtnConfig: LoadingBtnType[] = [
    {
      title: t(`PRICE_LIST.BUTTON.CANCEL`),
      class: 'border-m mr-s',
      type: 'CLOSE'
    },
    {
      title: 'Saving',
      class: 'bg-app text-white mr-ss',
      type: 'ACTION'
    }
  ];

  let currDate: any = new Date();
  if (
    props.notesType === DOC_TYPE.CREDIT_NOTE &&
    (props.documentMode === DOCUMENT_MODE.EDIT ||
      props.documentMode === DOCUMENT_MODE.VIEW)
  ) {
    currDate = DateFormatService.getDateFromStr(
      notesData.cnDate,
      BOOKS_DATE_FORMAT['DD-MM-YYYY']
    );
  }
  if (
    props.notesType === DOC_TYPE.DEBIT_NOTE &&
    (props.documentMode === DOCUMENT_MODE.EDIT ||
      props.documentMode === DOCUMENT_MODE.VIEW)
  ) {
    currDate = DateFormatService.getDateFromStr(
      notesData.dnDate,
      BOOKS_DATE_FORMAT['DD-MM-YYYY']
    );
  }
  const [currentDate, setCurrentDate] = useState(currDate);

  const getRate = (currObj: any) => {
    return (1 / Number(currObj?.currencyExchangeRate)).toFixed(4);
  };

  const getRateWithoutFormat = (currObj: any) => {
    return 1 / Number(currObj?.currencyExchangeRate);
  };

  const getSelectedTax = (taxCode: any) => {
    let filtered = taxes.filter((item) => {
      return item.code === taxCode;
    });

    if (filtered.length > 0) {
      let first = filtered[0];
      return {
        label: first.name,
        value: first.code
      };
    } else {
      return null;
    }
  };

  const getlineItems = () => {
    let lineItemsPayload: any[] = [];
    notesData.lineItems?.map((item: any, index: number) => {
      let taxList_payload: any = {
        lineTaxAmount: item.taxAmount,
        accountCode: item.accountCode,
        taxCode: item.taxCode,
        taxAmount: item.taxAmount,
        amount: item.amount,
        name: item.taxList[0]?.taxName,
        id: item.taxList[0]?.taxId,
        percent: item.taxList[0]?.taxRate,
        code: item.taxList[0]?.taxCode,
        type: item.taxList[0]?.taxType,
        additionalTaxIn: item.taxList[0]?.additionalTaxIn,
        lineAmount: item.taxableAmount,
        amountDue: item.amountDue,
        customField: item.customField
      };
      let tmpTaxList: any = [];
      if (item['taxList'] !== undefined && item['taxList'].length > 0) {
        item['taxList'].forEach((tax: any) => {
          let taxDetail = {
            id: tax.id,
            taxSeqCode: tax.taxSeqCode,
            taxCode: tax.taxCode,
            taxId: tax.taxId,
            name: tax.taxName,
            taxName: tax.taxName,
            taxApplyTo: tax.taxApplyTo,
            taxAmount: tax.taxAmount,
            taxRate: tax.taxRate,
            percentage: tax.taxRate,
            taxableAmount: tax.taxableAmount,
            additionalTaxIn: tax.additionalTaxIn,
            lineAmount: item.amount
          };
          tmpTaxList.push(taxDetail);
        });
      } else {
        if (item.taxCode !== undefined && item.taxCode !== null) {
          if (taxes !== undefined) {
            let taxDetail = getSelectedTax(item.taxCode);
            if (taxDetail !== undefined && taxDetail !== null) {
              taxList_payload['name'] = taxDetail['label'];
            }
          }
        }
      }
      taxList_payload['taxGroupDetails'] = tmpTaxList;

      let selectedTax = taxes.filter((tax: any) => tax.code === item.taxCode);
      // To show inactive / deleted taxes
      if (
        !Utility.isEmpty(item.taxCode) &&
        selectedTax.length === 0 &&
        Array.isArray(allTaxes.content)
      ) {
        selectedTax = allTaxes.content.filter(
          (tax: any) => tax.code === item.taxCode
        );
      }

      let selectedaccount =
        accountsData &&
        accountsData.content &&
        accountsData.content.filter(
          (acc: any) => acc.code === item.accountCode
        );

      const filteredCurr = currencyExchangeRates.find((curr) => {
        if (!Utility.isEmpty(notesData)) {
          //edit
          return curr.currencyCode === notesData.currency;
        } else {
          return curr.currencyCode === tenantInfo.currency;
        }
      });

      const rate = getRateWithoutFormat(filteredCurr);

      lineItemsPayload[index] = {
        lineItemNumber: index + 1,
        lineSelectedAccount: !Utility.isEmpty(selectedaccount)
          ? selectedaccount[0]
          : {},
        lineAmount: item.amount,
        lineTaxAmount: item.taxAmount,
        lineSelectedTax: !Utility.isEmpty(selectedTax)
          ? {
              ...selectedTax[0],
              taxGroupDetails: taxList_payload?.taxGroupDetails ?? []
            }
          : {}, //item.taxCode,
        lineAmountBase: 0,
        lineTaxList: taxList_payload,
        lineLineAmount: item.amount + item.taxAmount,
        lineAmtInBase: (
          Number(item.amount + item.taxAmount) * Number(rate)
        ).toFixed(3),
        customField: item.customField
      };
    });

    return lineItemsPayload;
  };

  const getFormData = () => {
    let newLineItemsData: any = getlineItems();
    let baseLineAmount = 0,
      baseTaxAmount = 0;
    if (newLineItemsData && newLineItemsData.length > 0) {
      newLineItemsData.forEach((item: any) => {
        baseLineAmount = baseLineAmount + Number(item.lineAmount);
        baseTaxAmount = baseTaxAmount + item.lineTaxAmount;
      });
    }

    return {
      id: notesData.id,
      contactCode: notesData.contactCode,
      customField: notesData.customField,
      calendarView: false,
      currentDate: DateFormatService.getDateFromStr(
        props.notesType === DOC_TYPE.DEBIT_NOTE
          ? notesData.dnDate
          : notesData.cnDate,
        BOOKS_DATE_FORMAT['DD-MM-YYYY']
      ),
      exchangeRate: notesData.currencyExchangeRate,
      gstExchangeRate: notesData?.gstExchangeRate,
      currentCurrency: notesData.currency,
      lineItemsData: {
        items: newLineItemsData
      },
      memoText: notesData.memo,
      autoNumberingCode: notesData.code,
      linkedDocuments: notesData.linkedDocuments,
      lineSummary: {
        lineLineAmountTotal: baseLineAmount,
        lineTaxAmountTotal: baseTaxAmount,
        total: baseLineAmount + baseTaxAmount
      },
      salesInvoiceCode: notesData.salesInvoiceCode,
      salesReturnCode: notesData.salesReturnCode,
      purchaseInvoiceCode: notesData.purchaseInvoiceCode,
      purchaseReturnCode: notesData.purchaseReturnCode,
      reason: notesData.reason,
      einvoiceInfoIndia: notesData.einvoiceInfoIndia,
      einvoiceInfoIndiaCancel: notesData.einvoiceInfoIndiaCancel,
      isCancelEinvoice: notesData.isCancelEinvoice,
      type: notesData.type,
      shipFrom: notesData.companyAddress
    };
  };

  const getDocumentData = () => {
    const limit: number = 1000;
    var queryString: string = '';

    if (!Utility.isEmpty(contact)) {
      queryString = `contactCode=${contact.code},dueAmount>0,currency=${contact.currencyCode}`;
    } else {
      return;
    }
    var invoiceDocumentList: any = [];
    invoiceDocumentList.push({
      currency: '',
      documentSequenceCode: 'none',
      dueAmount: ''
    });
    var billDocumentList: any = [];
    billDocumentList.push({
      currency: '',
      documentSequenceCode: 'none',
      dueAmount: ''
    });
    if (isBillOrOrder) {
      BillService.getBillsWithCountry(queryString, limit, '').then(
        (res: any) => {
          let billList: any[] = res.content;
          if (billList.length > 0) {
            billList = billList.filter((x) => x.dueAmount > 0);
          }
          billDocumentList.push(...billList);
          setBillList(billDocumentList);
          setLoadingDocumentList(false);
        }
      );
      InvoiceService.getInvoicesWithCountry(queryString, limit, '').then(
        (res: any) => {
          let invoiceList: any[] = res.content;
          if (billList.length > 0) {
            invoiceList = invoiceList.filter((x) => x.dueAmount > 0);
          }
          invoiceDocumentList.push(...invoiceList);
          setInvoiceList(invoiceDocumentList);
          setLoadingDocumentList(false);
        }
      );
    } else {
      InvoiceService.getInvoicesWithCountry(queryString, limit, '').then(
        (res: any) => {
          let invoiceList: any[] = res.content;
          if (billList.length > 0) {
            invoiceList = invoiceList.filter((x) => x.dueAmount > 0);
          }
          invoiceDocumentList.push(...invoiceList);
          setInvoiceList(invoiceDocumentList);
          setLoadingDocumentList(false);
        }
      );
    }
  };

  const getDocumentByFormData = (formData: any) => {
    if (isBillOrOrder) {
      if (formData.type === 'SALES') {
        setSelectedDocumentType(DOC_TYPE.INVOICE);
        getDocumentByCode(formData.salesInvoiceCode, DOC_TYPE.INVOICE);
      } else {
        getDocumentByCode(formData.purchaseInvoiceCode, DOC_TYPE.BILL);
      }
    } else {
      if (!Utility.isEmpty(formData.salesInvoiceCode)) {
        getDocumentByCode(formData.salesInvoiceCode, DOC_TYPE.INVOICE);
      }
    }
  };

  const getDocumentByCode = (code: any, type: DOC_TYPE) => {
    if (type === DOC_TYPE.INVOICE) {
      if (!Utility.isEmpty(code)) {
        InvoiceService.getInvoiceDetailsByCode(code).then((res: any) => {
          setSelectedDocument(getAdditionalSelectedDocumentInfo(res));
        });
      }
    } else if (type === DOC_TYPE.BILL) {
      if (!Utility.isEmpty(code)) {
        BillService.fetchBillDetails(code).then((res: any) => {
          setSelectedDocument(getAdditionalSelectedDocumentInfo(res));
        });
      }
    }
  };

  const onAddressUpdated = () => {
    setShowUpdateAddressPopup(false);
    const response = ContactService.getContactDetailsById(contact.id);
    response?.then((res: any) => {
      if (props.documentMode === DOCUMENT_MODE.EDIT) {
        setEditModeBillingAddresses(res.billingAddress);
        setEditModeShippingAddresses(res.shippingAddress);
      }
      setContact({ ...res });
    });
  };

  //states
  const test = useAppDispatch();
  const [formData, setFormData] = useState<any>(
    isEditMode ? getFormData() : initialNoteState
  );
  const [contact, setContact] = useState<any>(null);
  const [showTaxGroupDetails, setShowTaxGroupDetails] = useState(false);
  const [taxGroupDetail, setTaxGroupDetail] = useState([]);
  const [hasTaxGroup, setHasTaxGroup] = useState(false);
  const [gstType, setGstType] = useState<any>(GST_TYPE.INTER);
  const [isBillToAddressUpdated, setIsBillToAddressUpdated] = useState(false);
  const [isShipToAddressUpdated, setIsShipToAddressUpdated] = useState(false);
  const [isShipFromAddressUpdated, setIsShipFromAddressUpdated] =
    useState(false);
  const [isContactUpdated, setIsContactUpdated] = useState(false);

  //selectors
  const tenantInfo = useAppSelector(activeTenantInfo);
  const currency = useAppSelector(selectCurrencyListWithExchangeRate);

  const dispatch = useAppDispatch();
  const [isDesktop, setIsDesktop] = useState(isViewportLarge());

  //effects
  useEffect(() => {
    AppManager.handleWindowResizeListener(onWindowResize, true);
    return () => {
      AppManager.handleWindowResizeListener(onWindowResize, false);
    };
  }, []);

  const onWindowResize = () => {
    setIsDesktop(isViewportLarge);
  };

  useEffect(() => {
    ConfigUtility.structuredAccountsArr = [];
    if (!contactsData?.content) {
      dispatch(fetchContacts());
    }

    if (!accountsData?.content) {
      loadAccounts();
    }

    if (getTenantTaxSystem() === TAX_SYSTEM.INDIA_GST) {
      let states: ReactOptionType[] = getIndianStatesOption();
      setIndianStatesOptions(states);
    }

    registerInteractions();

    fetchAttachments();

    const filteredCurr = currencyExchangeRates.find((curr) => {
      if (!Utility.isEmpty(notesData)) {
        //edit
        return curr.currencyCode === notesData.currency;
      } else {
        return curr.currencyCode === tenantInfo.currency;
      }
    });

    const rate = getRateWithoutFormat(
      filteredCurr ? filteredCurr : tenantInfo.currency
    );
    setFormData({
      ...formData,
      currentCurrency: filteredCurr
        ? filteredCurr.currencyCode
        : tenantInfo.currency
    });
    updateConfig();
    setConversionRate(rate);

    let documentUpdates: any = {};
    if (props.documentMode === DOCUMENT_MODE.NEW) {
      let { billingAddress, shippingAddress } =
        updateBillingShippingAddress(true);
      if (billingAddress) {
        documentUpdates.billTo = billingAddress;
      }
      if (shippingAddress) {
        documentUpdates.shipTo = shippingAddress;
      }
      if (getTenantTaxSystem() === TAX_SYSTEM.INDIA_GST && isBillOrOrder) {
        updatePlaceOfSupplyOnAddress(shippingAddress);
      }
    }

    setLoadingDocumentList(true);
    getDocumentData();
    loadContactWithNotesData();

    return () => {
      setFormData(initialNoteState);
    };
  }, []);

  useEffect(() => {
    registerInteractions();
    updateConfig();
  }, [currentDate]);

  useEffect(() => {
    updateConfig();

    // this code is to change the line amount in base currency field when currency is changed from dropdown
    if (
      formData.lineItemsData.items &&
      formData.lineItemsData.items.length > 0 &&
      Utility.isEmpty(notesData)
    ) {
      formData.lineItemsData.items.map((item: any) => {
        updateLineItemList(item, null, 'lineAmount');
      });
    }
  }, [formData.currentCurrency]);

  useEffect(() => {
    let result = taxes
      .filter((tax: any) => {
        if (tax.type === TAX_TYPES.BOTH) {
          return tax.type;
        } else {
          return props.notesType === DOC_TYPE.DEBIT_NOTE
            ? tax.type === TAX_TYPES.PURCHASE
            : tax.type === TAX_TYPES.SALES;
        }
      })
      .map((t) => {
        return {
          label: t.name,
          value: t.code
        };
      });
    setTaxlist(result);
  }, [taxes]);

  useEffect(() => {
    if (isEditMode) {
      setFormData(getFormData());
    }
  }, [accountsData]);

  useEffect(() => {
    let tmpTaxGroupDetail: any = {};
    if (
      formData['lineItemsData'] &&
      formData['lineItemsData']['items'] &&
      formData['lineItemsData']['items'].length > 0
    ) {
      formData['lineItemsData']['items'].forEach((element: any) => {
        if (
          element['lineSelectedTax'] &&
          element['lineSelectedTax']['taxGroupDetails'] &&
          element['lineSelectedTax']['taxGroupDetails'].length > 0
        ) {
          element['lineSelectedTax']['taxGroupDetails'].forEach((tax: any) => {
            let taxAmount = element.lineAmount ? element.lineAmount : 0;
            // taxAmount = (taxAmount * tax.percentage) / 100;
            taxAmount =
              Number(tax.taxAmount) ?? (taxAmount * tax.percentage) / 100;
            if (
              tmpTaxGroupDetail[tax.name] !== undefined &&
              tmpTaxGroupDetail[tax.name] !== null
            ) {
              tmpTaxGroupDetail[tax.name] =
                Number(tmpTaxGroupDetail[tax.name]) + taxAmount;
            } else {
              tmpTaxGroupDetail[tax.name] = taxAmount;
            }
          });
        } else if (element['lineSelectedTax'] && element['lineTaxAmount']) {
          if (
            tmpTaxGroupDetail[element['lineSelectedTax']['name']] !==
              undefined &&
            tmpTaxGroupDetail[element['lineSelectedTax']['name']] !== null
          ) {
            tmpTaxGroupDetail[element['lineSelectedTax']['name']] =
              tmpTaxGroupDetail[element['lineSelectedTax']['name']] +
              element['lineTaxAmount'];
          } else {
            tmpTaxGroupDetail[element['lineSelectedTax']['name']] =
              element['lineTaxAmount'];
          }
        }
      });
    }
    setTaxGroupDetail(tmpTaxGroupDetail);

    if (
      notesData?.salesInvoice !== undefined &&
      notesData?.salesInvoice !== null
    ) {
      setSelectedDocument(notesData?.salesInvoice);
    }
    if (
      notesData?.purchaseInvoice !== undefined &&
      notesData?.purchaseInvoice !== null
    ) {
      setSelectedDocument(notesData?.purchaseInvoice);
    } else {
      if (props.documentMode === DOCUMENT_MODE.EDIT) {
        let isContainsLinkDocuments = false;
        if (formData.linkedDocuments && formData.linkedDocuments !== null) {
          if (formData.linkedDocuments.length > 0) {
            isContainsLinkDocuments = true;
          }
        }

        if (isContainsLinkDocuments) {
          getDocumentByFormData(formData);
        }
      } else {
        getDocumentByFormData(formData);
      }
    }
  }, [formData]);

  useEffect(() => {
    registerInteractions();
    // updateLineSummary();
    if (tenantInfo && tenantInfo.country && tenantInfo.country === 'IN') {
      if (contact && contact.code && tenantInfo.complianceEnabled) {
        updateCreditAndDebitGstType(selectedDocument);
      } else {
        setHasTaxGroup(false);
      }
    }
  }, [formData, contact]);

  useEffect(() => {
    setGSTExchangeRate();
    //for selecting contacts currency
    if (!Utility.isEmpty(contact) && !isEditMode) {
      const filteredCurr = currencyExchangeRates.find((curr) => {
        return curr.currencyCode === contact.currencyCode;
      });
      const rate = getRateWithoutFormat(filteredCurr);
      // setFormData({
      //   ...formData,
      //   currentCurrency: filteredCurr.currencyCode
      // });
      setConversionRate(rate);
    }

    if (!Utility.isEmpty(contact)) {
      addEmptyRowForValidation(true);
      let { billingAddress, shippingAddress } =
        updateBillingShippingAddress(false);
      setLoadingDocumentList(true);
      getDocumentData();

      var indianStates = indianStatesOptions;
      if (indianStates.length === 0) {
        indianStates = getIndianStatesOption();
      }

      let tempDocument: any = { ...booksDocument };
      if (getTenantTaxSystem() === TAX_SYSTEM.INDIA_GST) {
        if (!isBillOrOrder) {
          let state = !Utility.isEmpty(tempDocument?.shipTo?.placeOfSupply)
            ? tempDocument?.shipTo?.placeOfSupply
            : tempDocument?.shipTo?.state || shippingAddress?.state;

          if (state === undefined) {
            state =
              tenantInfo.billingAddresses?.length > 0
                ? tenantInfo.billingAddresses[0].state
                : undefined;
            if (notesData?.placeOfSupply) {
              state = notesData?.placeOfSupply;
            }
          }

          let pos = indianStates.filter(
            (ele: any) => ele?.value?.toLowerCase() === state?.toLowerCase()
          );

          setPos(pos[0]);
          let shipToAddress = {
            ...tempDocument.shipTo,
            placeOfSupply: pos ? pos?.[0]?.value : ''
          };
          tempDocument = { ...tempDocument, shipTo: shipToAddress };
        }
        if (isBillOrOrder) {
          let defaultPosState = shippingAddress?.state;
          if (contact.billingAddress !== undefined) {
            if (contact.billingAddress.length > 0) {
              defaultPosState = contact.billingAddress[0].state;
            }
          }

          let posState: any = !Utility.isEmpty(
            tempDocument?.shipFrom?.placeOfSupply
          )
            ? tempDocument?.shipFrom?.placeOfSupply
            : tempDocument?.shipFrom?.state || defaultPosState;

          if (posState === undefined) {
            if (notesData) {
              posState = notesData.placeOfSupply;
            }
          }
          let pos = indianStates.filter(
            (ele: any) => ele?.value?.toLowerCase() === posState?.toLowerCase()
          );

          setPos(pos[0]);
          let shipFrom = !Utility.isEmpty(tempDocument.shipFrom)
            ? tempDocument.shipFrom
            : tenantInfo.shippingAddresses?.length
            ? tenantInfo.shippingAddresses[0]
            : null;
          let shipFromAddress = {
            ...shipFrom,
            placeOfSupply: pos ? pos?.[0]?.value : ''
          };
          tempDocument = { ...tempDocument, shipFrom: shipFromAddress };

          let dosState: any = !Utility.isEmpty(
            tempDocument?.shipTo?.destinationOfSupply
          )
            ? tempDocument?.shipTo?.destinationOfSupply
            : tempDocument?.shipTo?.state || shippingAddress?.state;

          if (dosState === undefined) {
            dosState =
              tenantInfo.shippingAddresses?.length > 0
                ? tenantInfo.shippingAddresses[0].state
                : undefined;
            if (notesData) {
              if (notesData.destinationOfSupply) {
                dosState = notesData.destinationOfSupply;
              }
            }
          }

          let dos = indianStates.filter(
            (ele: any) => ele?.value?.toLowerCase() === dosState?.toLowerCase()
          );

          setDos(dos[0]);
          let shipToAddress = {
            ...tempDocument.shipTo,
            destinationOfSupply: dos ? dos?.[0]?.value : ''
          };
          tempDocument = { ...tempDocument, shipTo: shipToAddress };
        }
      }
      setIsContactUpdated(!isContactUpdated);
    }
  }, [contact]);

  useEffect(() => {
    updateCurrencyExchangeRate(formData.currentCurrency);
  }, [formData.currentCurrency]);

  useEffect(() => {
    if (tenantInfo.country) {
      getComplianceConfig();
    }
    if (props.documentMode === DOCUMENT_MODE.NEW) {
      setBooksDocument({
        ...booksDocument,
        shipFrom: tenantInfo?.shippingAddresses?.[0]
      });
    }
  }, [tenantInfo]);

  useEffect(() => {
    updateConfig();
  }, [accountsData, taxes]);

  useEffect(() => {
    // if (tenantInfo.currency === formData.currentCurrency) {
    //   setConversionRate(1);
    // }
  }, [conversionRate]);

  useEffect(() => {
    //update product row
    if (selectedDocument !== undefined) {
      var lineItems: any[] = [];
      registerInteractions();
      if (selectedDocument.documentSequenceCode !== undefined) {
        if (isBillOrOrder) {
          if (selectedDocumentType === DOC_TYPE.INVOICE) {
            lineItems = [
              ...(selectedDocument.salesInvoiceItems as DocumentItem[])
            ];
          } else {
            lineItems = [
              ...(selectedDocument.purchaseInvoiceProducts as DocumentItem[])
            ];
          }
        } else {
          lineItems = [
            ...(selectedDocument.salesInvoiceItems as DocumentItem[])
          ];
        }

        if (
          notesData?.creditNoteProducts !== undefined &&
          notesData?.creditNoteProducts !== null
        ) {
          lineItems = convertProductNoteList(
            lineItems,
            notesData?.creditNoteProducts
          );
        }
        if (
          notesData?.debitNoteProducts !== undefined &&
          notesData?.debitNoteProducts !== null
        ) {
          lineItems = convertProductNoteList(
            lineItems,
            notesData?.debitNoteProducts
          );
        } else if (
          notesData?.salesReturn !== undefined &&
          notesData?.salesReturn !== null
        ) {
          lineItems = convertReturnProductList(
            lineItems,
            notesData?.salesReturn.returnedItems
          );
        } else if (
          notesData?.purchaseReturn !== undefined &&
          notesData?.purchaseReturn !== null
        ) {
          lineItems = convertReturnProductList(
            lineItems,
            notesData?.purchaseReturn.returnedItems
          );
        }

        if (lineItems && lineItems.length > 0) {
          lineItems = lineItems.filter((x) => x.productQuantity > 0);
        }

        //need to get tax object by code
        let taxData = !isBillOrOrder ? salesTaxes : purchaseTaxes;
        lineItems.forEach((element) => {
          if (element.taxCode !== undefined) {
            let tax = taxData.find((x) => x.code === element.taxCode);
            if (tax) {
              element.tax = tax;
            }
          }
        });

        let updatedLineItemsWithConfigs = getLineItemsWithUpdatedColumnConfig([
          ...lineItems
        ]);

        if (updatedLineItemsWithConfigs?.items) {
          lineItems = [...updatedLineItemsWithConfigs.items];
        }

        if (getTenantTaxSystem() === TAX_SYSTEM.INDIA_GST) {
          let gstType = updateGstType(selectedDocument);
          lineItems = lineItems.map((item: any) => {
            return { ...item, gstType: gstType };
          });
        }

        if (updatedLineItemsWithConfigs?.updatedConfigs) {
          setColumnConfig([...updatedLineItemsWithConfigs?.updatedConfigs]);
        }

        lineItems = getLineItemsWithNonEditableCells(lineItems);

        if (getTenantTaxSystem() === TAX_SYSTEM.INDIA_GST) {
          let states: ReactOptionType[] = getIndianStatesOption();
          setIndianStatesOptions(states);
        }

        // updateConfig();
        if (props.documentMode) {
          // Don't show tooltip in VIEW mode
          DocumentConfigUtility.documentMode = props.documentMode;
        }
        // Set doc type in dicument config utility
        DocumentConfigUtility.documentType = documentType;

        if (lineItems && lineItems.length > 0) {
          lineItems.forEach((rowData, index) => {
            calculateTaxesAndAmount(index, [...(lineItems as DocumentItem[])]);
            calculateAndSetUomRelatedDataOnEdit(index, [
              ...(lineItems as DocumentItem[])
            ]);
          });
        }

        lastProductRowUpdatedIndex.current = 0;
        //update the account line item
        var totalAmount = 0;
        if (selectedDocument !== undefined && selectedDocument !== null) {
          if (
            selectedDocument.dueAmount !== undefined &&
            selectedDocument.dueAmount !== null
          ) {
            totalAmount = selectedDocument.dueAmount;
          }
        }
        if (
          totalAmount > 0 &&
          formData.lineItemsData.items !== undefined &&
          formData.lineItemsData.items !== null
        ) {
          if (formData.lineItemsData.items.length === 1) {
            var item = formData.lineItemsData.items[0];
            var newLineItemsData = formData.lineItemsData;
            if (item.lineLineAmount === 0) {
              if (
                item.lineSelectedAccount === undefined ||
                item.lineSelectedAccount === null ||
                item.lineSelectedAccount === ''
              ) {
                item.lineAmount = Math.abs(totalAmount);
                item.lineLineAmount = Math.abs(totalAmount);
                newLineItemsData.items = [item];
                setFormData({
                  ...formData,
                  lineItemsData: newLineItemsData
                });
              }
            }
          }
        }
      }
    }
    updateProductConfig();
  }, [selectedDocument]);

  useEffect(() => {
    if (
      lastProductRowUpdatedIndex.current !== null &&
      !Utility.isEmpty(
        productRows[lastProductRowUpdatedIndex.current as any]?.product
      ) &&
      Utility.isEmpty(barcodeSearchData)
    ) {
      let index = lastProductRowUpdatedIndex.current as number;
      lastProductRowUpdatedIndex.current = null;
      calculateTaxAfterProductRowsUpdate(index);
    } else {
      setBarcodeSearchData([]);
    }
    if (!hasShownProductLimitAlert) {
      if (productRows.length >= 50) {
        setHasShownProductLimitAlert(true);
        showAlert(
          'Warning',
          'Application may behave slower as there are more than 50 line items in the form.'
        );
      }
    }

    updateProductConfig();
  }, [productRows]);

  useEffect(() => {
    registerInteractions();
  }, [booksDocument, billingAddress, shippingAddress]);

  //////////////////////////////////// custom fields - start ///////////////////////////////

  const getModuleFromDocType = () => {
    let module: any = '';

    switch (props.notesType) {
      case DOC_TYPE.CREDIT_NOTE:
        return 'CREDITNOTE';

      case DOC_TYPE.DEBIT_NOTE:
        return 'DEBITNOTE';

      default:
        break;
    }

    return module;
  };

  useEffect(() => {
    if (!Utility.isEmpty(selectCustomFieldsData)) {
      let oldColConfigs = [...columnConfig];

      let accCustomFields = selectCustomFieldsData?.content?.filter(
        (item: any) => {
          return (
            item.modules?.includes('ACCOUNT') &&
            item.status === STATUS_TYPE.ACTIVE
          );
        }
      );

      accCustomFields?.forEach((accCF: any) => {
        const newItem = getNewColumn(accCF);
        oldColConfigs.push(newItem);
      });

      setCustomFieldsData(accCustomFields);
      setColumnConfig(oldColConfigs.filter((col: any) => !col.hidden));
    }
  }, [selectCustomFieldsData]);

  useEffect(() => {
    const updatedState = { ...formData };
    let oldColConfigs = [...columnConfig];

    if (formData.id && !Utility.isEmpty(customFieldsData)) {
      formData?.lineItemsData?.items?.map((item1: any, index1: any) => {
        updatedState.lineItemsData.items[index1]['cfs'] = item1?.customField;
        item1?.customField?.map((item: any, index: any) => {
          let filteredCF: any = customFieldsData?.find(
            (cf: any) => cf.id === item.id
          );
          if (!Utility.isEmpty(filteredCF)) {
            const newItem = getNewItem(item, filteredCF);
            let cfValue;
            if (
              filteredCF.fieldType.toLowerCase() ===
              INPUT_TYPE.DATE.toLowerCase()
            ) {
              cfValue = DateFormatService.getDateFromStr(
                item.value,
                BOOKS_DATE_FORMAT['MM/DD/YYYY']
              );
            } else if (filteredCF.fieldType.toLowerCase() === 'user') {
              const tempCF = filteredCF?.attributes?.find(
                (attr: any) => attr.code === item.value
              );
              if (tempCF) {
                cfValue = tempCF ? tempCF : '';
              }
            } else if (
              filteredCF.fieldType.toLowerCase() ===
              CUSTOM_FIELD_TYPE.DROPDOWN.toLowerCase()
            ) {
              cfValue = item ? item : '';
            } else {
              cfValue = item.value ? item.value : '';
            }

            let alreadyPresentConfig = oldColConfigs?.find(
              (col: any) => col.id === item.id
            );

            if (Utility.isEmpty(alreadyPresentConfig)) {
              oldColConfigs.push(newItem);
            }
            updatedState.lineItemsData.items[index1][item.id] = cfValue;
          }
        });
      });
      setColumnConfig([...oldColConfigs]);
      setFormData({ ...updatedState });
    }
  }, [customFieldsData]);

  //////////////// INVOICE NOW SECTION FOR DOCUMENT /////////////////////
  const isContainsLinkDocument = (documentData?: any) => {
    if (
      documentData.salesInvoiceCode !== undefined &&
      documentData.salesInvoiceCode !== null
    ) {
      return true;
    }
    return false;
  };

  const getInvoiceNow = (documentData?: any) => {
    if (
      isContainsLinkDocument(documentData) &&
      tenantInfo.country === COUNTRY_CODES.IN &&
      tenantInfo.indiaEinvoice
    ) {
      if (props.documentMode !== DOCUMENT_MODE.NEW) {
        let _status: any = {
          title: '',
          color: ''
        };
        switch (getInvoiceNowStatus(documentData)) {
          case INVOICE_NOW_TYPES.FAILED:
            _status = {
              title: INVOICE_NOW_TYPES.FAILED,
              color: 'bg-chip-red text-red '
            };
            break;
          case INVOICE_NOW_TYPES.GENERATED:
            _status = {
              title: INVOICE_NOW_TYPES.GENERATED,
              color: 'bg-chip-green text-green'
            };
            break;
          case INVOICE_NOW_TYPES.PENDING:
            _status = {
              title: INVOICE_NOW_TYPES.PENDING,
              color: 'bg-chip-orange text-orange'
            };
            break;
          case INVOICE_NOW_TYPES.CANCELLED:
            _status = {
              title: INVOICE_NOW_TYPES.CANCELLED,
              color: 'bg-chip-red text-red '
            };
            break;
        }
        return (
          <div className={`column parent-width position-relative`}>
            <div className="row justify-content-between align-items-center mt-1 bg-gray-50 p-2 border-radius-m items-center">
              <div className="flex items-center">
                <DKLabel className="fw-m" text={`e-Invoice Status :`} />
                <DKLabel
                  text={Utility.convertInTitleCase(_status.title)}
                  style={{
                    textTransform: 'capitalize'
                  }}
                  className={`text-dark-gray ${_status.color} border-radius-r ml-r p-h-s p-v-xs fw-m mr-r`}
                />
                {_status.title === INVOICE_NOW_TYPES.PENDING && (
                  <DKLabel
                    className="ml-3"
                    text={`Push your invoice to the e-invoicing portal(IRP) to genrate an IRN`}
                  />
                )}
                {_status.title === INVOICE_NOW_TYPES.FAILED &&
                  documentData.einvoiceInfoIndia.errorDetails && (
                    <DKLabel
                      className="ml-3"
                      text={`${documentData.einvoiceInfoIndia.errorDetails[0].errorMessage}`}
                    />
                  )}
              </div>
              <div>
                {_status.title === INVOICE_NOW_TYPES.PENDING && (
                  <DKButton
                    title={'Generate e-Invoice'}
                    className="text-green fw-m"
                    onClick={() => {
                      generateEInvoiceIndia();
                    }}
                  />
                )}
                {(_status.title === INVOICE_NOW_TYPES.CANCELLED ||
                  _status.title === INVOICE_NOW_TYPES.FAILED) && (
                  <DKButton
                    title={'View Failure Log'}
                    className="text-blue fw-m"
                    onClick={() => {
                      redirectEInvoiceFailureLog();
                    }}
                  />
                )}
                {_status.title === INVOICE_NOW_TYPES.GENERATED && (
                  <DKButton
                    title={'Cancel e-Invoice'}
                    className="text-red fw-m"
                    onClick={() => {
                      cancelEInvoiceIndia();
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        );
      }
    }
  };

  const loadCreditNotesDetails = () => {
    CreditNoteService.getCreditNote(formData.autoNumberingCode)
      .then(
        (data: any) => {
          const creditNoteList: any[] = data['content'];
          const creditNote: any = creditNoteList[0];
          if (creditNote) {
            setFormData({
              ...formData,
              einvoiceInfoIndia: creditNote.einvoiceInfoIndia,
              einvoiceInfoIndiaCancel: creditNote.einvoiceInfoIndiaCancel,
              isCancelEinvoice: creditNote.isCancelEinvoice
            });
          }
        },
        (err) => {
          console.error('Error while fetching invoice details: ', err);
        }
      )
      .catch((err) => {
        // null
      });
  };

  const loadDebitNotesDetails = () => {
    DebitService.getDebitNote(formData.autoNumberingCode)
      .then(
        (data: any) => {
          const creditNoteList: any[] = data['content'];
          const creditNote: any = creditNoteList[0];
          if (creditNote) {
            setFormData({
              ...formData,
              einvoiceInfoIndia: creditNote.einvoiceInfoIndia,
              einvoiceInfoIndiaCancel: creditNote.einvoiceInfoIndiaCancel,
              isCancelEinvoice: creditNote.isCancelEinvoice
            });
          }
        },
        (err) => {
          console.error('Error while fetching invoice details: ', err);
        }
      )
      .catch((err) => {
        // null
      });
  };

  const showInvoiceNowDetails = () => {
    if (props.documentMode !== DOCUMENT_MODE.NEW) {
      var einvoiceInfoIndia = notesData?.einvoiceInfoIndia;
      if (einvoiceInfoIndia) {
        return (
          <div className="w-3/4">
            <div className="row">
              <span className="fw-m mr-2">IRN Number : </span>
            </div>
            <div className="row mb-1">{einvoiceInfoIndia.irn}</div>
            {getInvoiceNowStatus(notesData) !== INVOICE_NOW_TYPES.CANCELLED && (
              <>
                <div className="row">
                  <span className="fw-m mr-2">Acknowledgement Number : </span>
                  {einvoiceInfoIndia && einvoiceInfoIndia.ackNo}
                </div>
                <div className="row">
                  <span className="fw-m mr-2">Acknowledgement Date : </span>
                  {einvoiceInfoIndia && einvoiceInfoIndia.ackDt}
                </div>
              </>
            )}
          </div>
        );
      }
    }
  };

  const generateEInvoiceIndia = () => {
    const generateEInvoice = (authToken: any) => {
      try {
        let payload = { ...authToken };
        payload.gstin = tenantInfo.gstin;
        payload.id = notesData.id;
        payload.documentCode = notesData.code;
        payload.documentType = isBillOrOrder
          ? DOC_TYPE.DEBIT_NOTE
          : DOC_TYPE.CREDIT_NOTE;
        EInvoiceService.generateEInvoice(payload).then(
          (res: any) => {
            const config: InvoiceAPIConfig = {
              ...InvoiceService.apiConfig
            };

            if (!Utility.isEmpty(res?.errorDetails)) {
              let errors = res?.errorDetails;
              if (errors?.length) {
                let HTML = `<div>
                          <ul>
                            ${errors.map((error: any) => {
                              return `<li>${error?.errorMessage}</li>`;
                            })}
                          </ul>
                        </div>`;
                showAlert('e-Invoice portal reported errors below', HTML);
              }
            }

            if (isBillOrOrder) {
              loadDebitNotesDetails();
            } else {
              loadCreditNotesDetails();
            }
          },
          (err: any) => {
            console.error('Error generating e-Invoice: ', err);
          }
        );
      } catch (error: any) {}
    };

    if (notesData && tenantInfo) {
      if (
        Utility.isEmpty(eInvoiceAuthInfoData) ||
        eInvoiceAuthInfoData.status !== '1'
      ) {
        dispatch(fetchEInvoiceAuthInfo({})).then((authToken: any) => {
          if (Utility.isEmpty(authToken) || authToken.payload.status !== '1') {
            if (
              authToken?.payload?.status === '0' &&
              authToken?.payload?.errors?.[0]?.errorMessage
            ) {
              showAlert(
                'Error!',
                authToken?.payload?.errors?.[0]?.errorMessage
              );
            }
            setShowIRPCredPopup(true);
          } else {
            generateEInvoice(authToken.payload);
          }
        });
      } else {
        generateEInvoice(eInvoiceAuthInfoData);
      }
    }
  };

  const cancelEInvoiceIndia = () => {
    if (notesData && tenantInfo) {
      if (
        Utility.isEmpty(eInvoiceAuthInfoData) ||
        eInvoiceAuthInfoData.status !== '1'
      ) {
        dispatch(fetchEInvoiceAuthInfo({})).then((authToken: any) => {
          if (Utility.isEmpty(authToken) || authToken.payload.status !== '1') {
            setShowIRPCredPopup(true);
          } else {
            if (
              authToken?.status === '0' &&
              authToken?.errors?.[0]?.errorMessage
            ) {
              showAlert('Error!', authToken?.errors?.[0]?.errorMessage);
            }
            setShowCancelEInvoice(true);
          }
        });
      } else {
        setShowCancelEInvoice(true);
      }
    }
  };

  const redirectEInvoiceFailureLog = () => {
    dispatch(removeDraft(notesData?.id));
    RouteManager.navigateToPage(PAGE_ROUTES.FAILED_INVOICES);
  };

  const loadContactWithNotesData = async () => {
    if (Utility.isEmpty(contact) && notesData?.contact) {
      loadContactsByCode(notesData?.contact.code);
    }
  };

  const getIndianStatesOption = () => {
    let states: ReactOptionType[] = [];
    const indianStates = INDIAN_STATES_MOCK_DATA;
    indianStates.forEach((state) => {
      let option: ReactOptionType = {
        label: state.name,
        value: state.code
      };
      states.push(option);
    });
    return states;
  };

  const loadContactsByCode = (code: string) => {
    const config: ContactAPIConfig = {
      ...ContactService.apiConfig,
      Page: 0,
      Limit: 1,
      IncludeOpeningAmounts: false,
      IncludeOweAmounts: false
    };
    try {
      ContactService.apiConfig = config;
      const response = ContactService.fetchContactByContactCode(code).then(
        (data: any) => {
          return data.content;
        }
      );

      if (Utility.isEmpty(contact)) {
        response?.then((res) => {
          const contactList: any[] = res;
          if (contactList.length > 0) {
            setContact(contactList[0]);
          } else {
            setContact(notesData?.contact);
          }
        });
      }
      return response;
    } catch (err) {
      console.error('Error loading contacts: ', err);
    }
  };

  const getLineItemsWithUpdatedColumnConfig = (
    items: any[],
    existingCFs?: any[]
  ) => {
    let productCFs: any[] = [];
    if (existingCFs && existingCFs.length) {
      productCFs = [...existingCFs];
    } else if (
      !Utility.isEmpty(productCFfromStore) &&
      productCFfromStore?.content?.length
    ) {
      productCFs = [...productCFfromStore?.content];
    }
    productCFs.sort(
      (field1: any, field2: any) =>
        field1.customFieldIndex - field2.customFieldIndex
    );
    if (!Utility.isEmpty(productCFs)) {
      let updatedConfigs = [...columnConfig].filter(
        (field: any) => !field.isCustomField
      );

      productCFs?.forEach((prodCF: any) => {
        const newItem = getNewColumnForCF(
          prodCF,
          props.documentMode !== DOCUMENT_MODE.VIEW
        );
        updatedConfigs.push(newItem);
      });

      if (
        props.documentMode !== DOCUMENT_MODE.NEW &&
        !Utility.isEmpty(productCFs)
      ) {
        items?.forEach((lineItem: any, lineItemIndex: any) => {
          lineItem?.customField?.forEach((item: any, index: any) => {
            let filteredCF: any = productCFs?.find(
              (cf: any) => cf.id === item.id
            );
            if (!Utility.isEmpty(filteredCF)) {
              const newItem = getNewCFItem(
                item,
                filteredCF,
                props.documentMode !== DOCUMENT_MODE.VIEW
              );

              let alreadyPresentConfig = updatedConfigs?.find(
                (col: any) => col.id === item.id
              );
              if (Utility.isEmpty(alreadyPresentConfig)) {
                updatedConfigs.push(newItem);
              }

              if (filteredCF) {
                if (
                  typeof item.value !== 'undefined' &&
                  item.value !== null &&
                  item.value !== ''
                ) {
                  if (
                    filteredCF.fieldType.toLowerCase() ===
                    INPUT_TYPE.DATE.toLowerCase()
                  ) {
                    items[lineItemIndex][item.id] =
                      DateFormatService.getDateFromStr(
                        item.value,
                        BOOKS_DATE_FORMAT['MM/DD/YYYY']
                      );
                  } else if (filteredCF.fieldType.toLowerCase() === 'user') {
                    const tempCF = filteredCF?.attributes?.find(
                      (attr: any) => attr.code === item.value
                    );
                    if (tempCF) {
                      items[lineItemIndex][item.id] = tempCF.value;
                    }
                  } else {
                    items[lineItemIndex][item.id] = item.value;
                  }
                } else {
                  items[lineItemIndex][item.id] = '';
                }
              }
            }
          });
        });
      }
      return { items, updatedConfigs };
    } else {
      return { items: null, updatedConfigs: [...columnConfig] };
    }
  };

  const getLineItemsWithNonEditableCells = (lineItems: any[]) => {
    lineItems = lineItems.map((item: any) => {
      let updatedItem = { ...item, isTaxable: true };
      if (getTenantTaxSystem() === TAX_SYSTEM.MALAYSIA) {
        updatedItem = {
          ...updatedItem,
          isTaxable: updatedItem.product
            ? updatedItem?.product.exemptedMalaysia
            : true
        };
      }
      if (
        (typeof updatedItem?.product?.taxPreference !== 'undefined' &&
          updatedItem?.product?.taxPreference !== null &&
          !updatedItem?.product?.taxPreference) ||
        !updatedItem.isTaxable
      ) {
        updatedItem.nonEditableColumns = updatedItem.nonEditableColumns
          ? [...updatedItem.nonEditableColumns].filter(
              (field) => field !== 'tax'
            )
          : [];
        updatedItem?.nonEditableColumns?.push('tax');
      }
      return updatedItem;
    });

    return lineItems;
  };

  const convertReturnProductList = (lineItems: any, returnItems: any) => {
    if (returnItems.length > 0) {
      var newLineItem: any[] = [];
      returnItems.forEach((element: any) => {
        var item = lineItems.find(
          (x: any) => x.productCode === element.productCode
        );

        if (item) {
          item.productQuantity = element.quantityToReturn;
          newLineItem.push(item);
        }
      });
      return newLineItem;
    }
    return lineItems;
  };

  const convertProductNoteList = (lineItems: any, items: any) => {
    if (items.length > 0) {
      var newLineItem: any[] = [];
      items.forEach((element: any) => {
        var item = lineItems.find(
          (x: any) => x.productCode === element.productCode
        );

        if (item) {
          item = convertProductNoteItem(item, element);
          newLineItem.push(item);
        }
      });
      return newLineItem;
    }
    return lineItems;
  };

  const convertProductNoteItem = (productItem: any, productNoteItem: any) => {
    var item = productItem;
    item.productCode = productNoteItem.productCode;
    item.productQuantity = productNoteItem.productQuantity;
    item.productDescription = productNoteItem.productDescription;
    item.unitPrice = productNoteItem.unitPrice;
    item.discount = productNoteItem.discount;
    item.discountInPercent = productNoteItem.discountInPercentFlg;
    item.taxCode = productNoteItem.taxCode;
    item.taxAmount = productNoteItem.taxAmount;
    item.totalAmount = productNoteItem.totalAmount;
    item.lineNumber = productNoteItem.productOrder;
    item.sgstRate = productNoteItem.inSgstRate;
    item.cgstRate = productNoteItem.inCgstRate;
    item.igstRate = productNoteItem.inIgstRate;
    item.sgstAmount = productNoteItem.inSgstAmount;
    item.cgstAmount = productNoteItem.inCgstAmount;
    item.igstAmount = productNoteItem.inIgstAmount;
    item.cessAmount = productNoteItem.inCessAmount;
    item.cessRule = productNoteItem.inCessRule;
    item.cessPercentage = productNoteItem.inCessRate;
    item.inHsnOrSacCode = productNoteItem.inHsnOrSacCode;
    return item;
  };

  const setGSTValueManual = (rowIndex: any, productRow: any, rowItem: any) => {
    let row = productRow;
    let gstType = rowItem.gstType ? rowItem.gstType : row && row.gstType;
    if (typeof gstType === 'undefined' || gstType === null || gstType === '') {
      gstType = updateGstType(selectedDocument);
    }
    if (
      tenantInfo?.country === TAX_SYSTEM.INDIA_GST &&
      ItemTaxCalculator.item &&
      row &&
      row.userSetTaxes &&
      gstType !== GST_TYPE.EXEMPT
    ) {
      let amount = 0;
      if (gstType === GST_TYPE.INTER) {
        amount = Number(row.igstAmount);
      } else if (gstType === GST_TYPE.INTRA) {
        amount = Number(row.cgstAmount) + Number(row.sgstAmount);
      }
      let subTotal = row.subTotal;
      if (Utility.isEmpty(subTotal)) {
        subTotal = rowItem.subTotal;
      }
      if (!row.unitPriceGstInclusive) {
        let per = (Number(amount) / Number(subTotal)) * 100;
        let tax = deepClone(ItemTaxCalculator.item['tax']);
        let prevTax = ItemTaxCalculator.item['tax'];
        tax['percent'] = per;
        tax['isTaxGroup'] = false;
        ItemTaxCalculator.item['tax'] = tax;
        if (gstType === GST_TYPE.INTER) {
          ItemTaxCalculator.item['igstAmount'] = row.igstAmount;
        }
        ItemTaxCalculator.item.taxAmount =
          ItemTaxCalculator.calculateTaxAmount();
        ItemTaxCalculator.item['tax'] = prevTax;
        ItemTaxCalculator.setTotal();

        if (gstType === GST_TYPE.INTER) {
          ItemTaxCalculator.item['igstAmount'] = row.igstAmount;
          ItemTaxCalculator.item['igstRate'] = prevTax.percent;
        } else if (gstType === GST_TYPE.INTRA) {
          amount = Number(row.cgstAmount) + Number(row.sgstAmount);
          ItemTaxCalculator.item['cgstAmount'] = row.cgstAmount;
          ItemTaxCalculator.item['sgstAmount'] = row.sgstAmount;
          ItemTaxCalculator.item['cgstRate'] = prevTax.percent / 2;
          ItemTaxCalculator.item['sgstRate'] = prevTax.percent / 2;
        }
      } else {
        // making unitPriceGstInclusive flag false for calculating subtotal without unitPriceGstInclusive flag
        //for calculating manual user taxes and subtotal
        // ItemTaxCalculator.item.unitPriceGstInclusive = false;
        ItemTaxCalculator.setSubTotal();
        let calculatedSubTotal =
          ItemTaxCalculator.item.subTotal || row.subTotal;
        // ItemTaxCalculator.item.unitPriceGstInclusive = true;
        ItemTaxCalculator.setTotal();

        if (gstType === GST_TYPE.INTER) {
          ItemTaxCalculator.item['igstAmount'] = row.igstAmount;
          ItemTaxCalculator.item.taxAmount = Utility.roundingOff(
            row.igstAmount + row.cessAmount,
            tenantInfo.decimalScale
          );
        } else if (gstType === GST_TYPE.INTRA) {
          amount = Utility.roundingOff(
            Number(row.cgstAmount) + Number(row.sgstAmount),
            tenantInfo.decimalScale
          );
          ItemTaxCalculator.item.taxAmount = Utility.roundingOff(
            amount + row.cessAmount,
            tenantInfo.decimalScale
          );
          ItemTaxCalculator.item['cgstAmount'] = row.cgstAmount;
          ItemTaxCalculator.item['sgstAmount'] = row.sgstAmount;
        }
        if (ItemTaxCalculator && ItemTaxCalculator.item) {
          let taxAmount = ItemTaxCalculator?.item?.taxAmount || 0;
          ItemTaxCalculator.item.subTotal = Utility.roundingOff(
            calculatedSubTotal,
            tenantInfo.decimalScale
          );
        }
      }
      // setCurrentRowGSTInfo(null);
    }
  };

  const calculateTaxesAndAmount = (
    rowIndex: number,
    unsavedRows?: any,
    isBarcode?: boolean
  ) => {
    if (!unsavedRows) {
      unsavedRows = [...productRows];
    }

    let row = { ...unsavedRows[rowIndex] };

    if (!Utility.isEmpty(row?.product)) {
      let item = {
        ...row,
        taxCode: row?.tax?.code,
        taxName: row?.tax?.name,
        taxSystem: getTenantTaxSystem(),
        gstType: Utility.getValue(row.gstType, booksDocument.gstType),
        unitPriceGstInclusive: row.unitPriceGstInclusive,
        exciseAmount: 0,
        cessRule: row.product.cessRule ? row.product.cessRule : '',
        isRcmApplied: rcmAppliedIndia(
          documentType,
          contact?.gstTreatment,
          row.product
        )
      };
      item = {
        ...item,
        ...getDiscountRelatedKeys(
          row['discountInPercent'] ? row['discount'] + '%' : row['discount']
        )
      };
      ItemTaxCalculator.tenantInfo = tenantInfo;
      ItemTaxCalculator.item = item;
      if (!ItemTaxCalculator.item.taxAmount) {
        ItemTaxCalculator.item.taxAmount = 0;
      }
      ItemTaxCalculator.setInitialValues();
      const taxAmount = ItemTaxCalculator.calculateTaxAmount();
      ItemTaxCalculator.item.taxAmount = taxAmount;
      ItemTaxCalculator.updateCalculatedValues();
      // for landed cost calculation for india only
      // setTotalAmountforLandedCost(rowIndex);
      if (
        getTenantTaxSystem() === TAX_SYSTEM.INDIA_GST &&
        (props.documentMode !== DOCUMENT_MODE.NEW ||
          selectedDocument.isPartialInvoice ||
          !Utility.isEmpty(contact))
      ) {
        setGSTValueManual(rowIndex, row, item);
      }
      ItemTaxCalculator.item.amount = ItemTaxCalculator.item.totalAmount || 0;
      unsavedRows[rowIndex] = { ...ItemTaxCalculator.item };
      lastProductRowUpdatedIndex.current = null;
      // selectedDocument.items = unsavedRows
      // if (lineItemsTouched) {
      //   setSelectedDocument((prevBooksDocument: any) => {
      //     return {
      //       ...prevBooksDocument,
      //       items: [...unsavedRows],
      //       isDocumentTouched: lineItemsTouched || prevBooksDocument.isDocumentTouched
      //     };
      //   });
      //   setLineItemsTouched(false);
      // }
    }
    if (isBarcode) {
      return { ...ItemTaxCalculator.item };
    } else {
      setProductRows([...unsavedRows]);
    }

    return unsavedRows;
  };

  const getDiscountRelatedKeys = (discount: string) => {
    let updatedItemKeys: any = {};
    let discountInNum = 0;
    if (discount) {
      if (discount.toString().indexOf('%') > -1) {
        updatedItemKeys.discountInPercent = true;
        discountInNum = Number(discount.replace('%', ''));
      } else {
        discountInNum = Number(discount);
        updatedItemKeys.discountInPercent = false;
      }
      updatedItemKeys.discount = Utility.roundOff(discountInNum);
      return updatedItemKeys;
    } else {
      updatedItemKeys.discount = discountInNum;
    }
    return updatedItemKeys;
  };

  const calculateAndSetUomRelatedDataOnEdit = (
    rowIndex: number,
    unsavedRows?: any
  ) => {
    let item = unsavedRows[rowIndex];
    let clonedItem: any = {};

    let unitPrice = item.unitPrice;

    if (
      props.documentMode === DOCUMENT_MODE.NEW &&
      (item.isPartialInvoice || item.isPartialBill) &&
      item.product.stockUom === UOM_NA_ID
    ) {
      unitPrice = item.pendingAmount;
    }

    const pendingQuantity = item.pendingQuantity;
    let finalQty;
    if (item.isPartialInvoice && !item.id) {
      finalQty = pendingQuantity;
      if (finalQty <= 0) {
        finalQty = 1;
      }
    } else if (item.isPartialBill && !item.id) {
      const receivedButNotBilled = Utility.receivedButNotBilledQuantity(item);
      finalQty =
        receivedButNotBilled > 0 && receivedButNotBilled < pendingQuantity
          ? receivedButNotBilled
          : pendingQuantity;
      if (finalQty <= 0) {
        finalQty = 1;
      }
    } else {
      finalQty = item.productQuantity;
    }

    item.productQuantity = finalQty;
    item.unitPrice = unitPrice;

    item.uom = getSelectedUomForEdit(item);
    if (item.documentUOMSchemaDefinition) {
      item.unitPrice = item.uomUnitPrice || item.unitPrice;
      item.productQuantity = item.uomQuantity || item.productQuantity;
    }

    if (getTenantTaxSystem() === TAX_SYSTEM.INDIA_GST) {
      clonedItem = deepClone(item);
    }

    ItemTaxCalculator.item = item;
    if (!ItemTaxCalculator.item.taxAmount) {
      ItemTaxCalculator.item.taxAmount = 0;
    }
    ItemTaxCalculator.tenantInfo = tenantInfo;
    ItemTaxCalculator.setInitialValues();
    const taxAmount = ItemTaxCalculator.calculateTaxAmount();
    ItemTaxCalculator.item.taxAmount = taxAmount;
    ItemTaxCalculator.updateCalculatedValues();
    if (
      getTenantTaxSystem() === TAX_SYSTEM.INDIA_GST &&
      (props.documentMode !== DOCUMENT_MODE.NEW ||
        selectedDocument.isPartialInvoice ||
        !Utility.isEmpty(contact))
    ) {
      let row = { ...unsavedRows[rowIndex] };
      setGSTValueManual(rowIndex, clonedItem, clonedItem);
    }

    unsavedRows[rowIndex] = { ...ItemTaxCalculator.item };
    lastProductRowUpdatedIndex.current = rowIndex;
    setProductRows([...unsavedRows]);
    // setSelectedDocument((prevBooksDocument: any) => {
    //   return {
    //     ...prevBooksDocument,
    //     items: [...unsavedRows]
    //   };
    // });
  };

  const getSelectedUomForEdit = (lineItem: any) => {
    if (lineItem.documentUOMSchemaDefinition) {
      return lineItem.documentUOMSchemaDefinition;
    } else {
      let filtered = uomsData.filter(
        (uom: any) => uom.id === lineItem?.documentUom
      );
      let defaultUOM: any;
      if (!Utility.isEmpty(filtered)) {
        defaultUOM = { ...filtered[0], isBaseUom: true };
      }
      return defaultUOM;
    }
  };

  const getUpdatedSGTaxList = () => {
    return taxes.filter((taxItem: any) => {
      if (
        taxItem.effectiveEndDate !== undefined &&
        taxItem.effectiveEndDate !== null
      ) {
        if (
          currentDate >=
            DateFormatService.getDateFromStr(
              taxItem.effectiveStartDate,
              BOOKS_DATE_FORMAT['YYYY-MM-DD']
            ) &&
          currentDate <=
            DateFormatService.getDateFromStr(
              taxItem.effectiveEndDate,
              BOOKS_DATE_FORMAT['YYYY-MM-DD']
            )
        ) {
          return taxItem;
        }
      } else {
        if (
          currentDate >=
          DateFormatService.getDateFromStr(
            taxItem.effectiveStartDate,
            BOOKS_DATE_FORMAT['YYYY-MM-DD']
          )
        ) {
          return taxItem;
        }
      }
    });
  };

  const calculateTaxAfterProductRowsUpdate = (
    index: number,
    isBarcode?: boolean | undefined
  ) => {
    if (
      getTenantTaxSystem() === TAX_SYSTEM.US &&
      lastUpdatedColumn &&
      lastUpdatedColumn !== 'taxAmount'
    ) {
      calculateUSTax(index);
    } else {
      setLastUpdatedColumn(null);
      return calculateTaxesAndAmount(index, undefined, isBarcode);
    }
  };

  const calculateManualAdditionalChargeDistribution = (
    existingChargeDetails: any[],
    existingChargeIndex: number,
    selectedCharge: any,
    itemsLength: number
  ) => {
    if (existingChargeIndex > -1) {
      return existingChargeDetails[existingChargeIndex].chargeAmount
        ? existingChargeDetails[existingChargeIndex].chargeAmount
        : 0;
    } else {
      let manualApportionChargesInItems: any[] = [];

      productRows.forEach((item: any) => {
        const additionalCharge =
          item.additionalCharges?.additionalChargesDetails?.find(
            (c: any) => c.additionalCharge === selectedCharge.additionalCharge
          );
        if (!Utility.isEmpty(additionalCharge)) {
          manualApportionChargesInItems.push(additionalCharge);
        }
      });
      if (manualApportionChargesInItems.length) {
        const manualApportionChargesTotal =
          manualApportionChargesInItems.reduce(
            (total: number, detail: any) =>
              total + Number(detail?.chargeAmount || 0),
            0
          );
        if (manualApportionChargesTotal !== selectedCharge.chargeAmount) {
          return Utility.roundOffToTenantDecimalScale(
            selectedCharge?.chargeAmount / itemsLength
          );
        } else {
          return 0;
        }
      } else {
        return Utility.roundOffToTenantDecimalScale(
          selectedCharge?.chargeAmount / itemsLength
        );
      }
    }
  };

  const updateLineLevelAdditionalChargeAllocation = (
    selectedCharge: any,
    items: any
  ) => {
    let itemsCopy = deepClone(items);

    let existingChargeIndexInTransaction =
      additionalCharges.current?.additionalChargesDetails?.findIndex(
        (charge: any) =>
          charge.additionalCharge === selectedCharge.additionalCharge
      );
    let totalTempChargeAmount = 0;

    if (existingChargeIndexInTransaction > -1) {
      switch (selectedCharge?.apportionValue) {
        case ADDITIONAL_CHARGE_METHODS.APPORTION_ON_QTY:
          let totalProductQty = 0;
          let perQtyItemCharge = 0;
          totalProductQty = itemsCopy
            ?.filter(
              (item: any) => item?.product?.type === PRODUCT_TYPE.TRACKED
            )
            ?.reduce((total: number, array: any) => {
              return total + array.productQuantity;
            }, 0);

          perQtyItemCharge = selectedCharge?.chargeAmount / totalProductQty;

          totalTempChargeAmount = 0;
          itemsCopy = itemsCopy?.map((item: any, index: number) => {
            if (item?.product?.type !== PRODUCT_TYPE.TRACKED) {
              return {
                ...item,
                additionalCharges: {
                  additionalChargesDetails: []
                }
              };
            }

            let copyAdditionalChargeDetails: any = item?.additionalCharges
              ? item?.additionalCharges
              : { additionalChargesDetails: [] };
            let existingChargeIndexInLineItem =
              copyAdditionalChargeDetails?.additionalChargesDetails?.findIndex(
                (charge: any) =>
                  charge.additionalCharge === selectedCharge.additionalCharge
              );
            let existingChargeDetails = [
              ...copyAdditionalChargeDetails.additionalChargesDetails
            ];

            let chargeAmount = Utility.roundOffToTenantDecimalScale(
              item?.productQuantity * perQtyItemCharge
            );
            totalTempChargeAmount += chargeAmount;
            if (index === itemsCopy?.length - 1) {
              if (totalTempChargeAmount !== +selectedCharge?.chargeAmount) {
                const chargeDelta =
                  +selectedCharge.chargeAmount - totalTempChargeAmount;
                chargeAmount += chargeDelta;
              }
            }

            let tempDetails = {
              id: selectedCharge?.id,
              additionalCharge: selectedCharge?.additionalCharge,
              additionalChargeAccountCode:
                selectedCharge?.additionalChargeAccountCode,
              addtionalChargeTax: selectedCharge?.addtionalChargeTax ?? null,
              addtionalChargeTaxCode:
                selectedCharge?.addtionalChargeTaxCode ?? null,
              isPreCharge: selectedCharge.isPreCharge,
              isPercent: selectedCharge?.isPercent,
              percent: selectedCharge?.percent,
              chargeAmount: chargeAmount,
              apportionFlag: selectedCharge?.apportionFlag || true,
              apportionValue: selectedCharge?.apportionValue || null
            };

            if (existingChargeIndexInLineItem !== -1) {
              existingChargeDetails[existingChargeIndexInLineItem] =
                tempDetails;
            } else {
              existingChargeDetails.push(tempDetails);
            }

            item = {
              ...item,
              additionalCharges: {
                additionalChargesDetails: existingChargeDetails
              }
            };

            return item;
          });

          break;
        case ADDITIONAL_CHARGE_METHODS.APPORTION_ON_VALUE:
          let totalSumAllAmount = 0;
          totalSumAllAmount = itemsCopy
            ?.filter(
              (item: any) => item?.product?.type === PRODUCT_TYPE.TRACKED
            )
            ?.reduce((total: number, obj: any) => {
              return total + +obj.totalAmount;
            }, 0);

          totalTempChargeAmount = 0;
          itemsCopy = itemsCopy?.map((item: any, index: number) => {
            if (item?.product?.type !== PRODUCT_TYPE.TRACKED) {
              return {
                ...item,
                additionalCharges: {
                  additionalChargesDetails: []
                }
              };
            }

            let copyAdditionalChargeDetails: any = item?.additionalCharges
              ? item?.additionalCharges
              : { additionalChargesDetails: [] };
            let existingChargeIndex =
              copyAdditionalChargeDetails?.additionalChargesDetails?.findIndex(
                (charge: any) =>
                  charge.additionalCharge === selectedCharge.additionalCharge
              );
            let existingChargeDetails = [
              ...copyAdditionalChargeDetails.additionalChargesDetails
            ];

            let chargeAmount = Utility.roundOffToTenantDecimalScale(
              (item?.totalAmount / totalSumAllAmount) *
                selectedCharge?.chargeAmount
            );
            totalTempChargeAmount += chargeAmount;
            if (index === itemsCopy?.length - 1) {
              if (totalTempChargeAmount !== +selectedCharge?.chargeAmount) {
                const chargeDelta =
                  +selectedCharge.chargeAmount - totalTempChargeAmount;
                chargeAmount = Utility.roundOffToTenantDecimalScale(
                  chargeAmount + chargeDelta
                );
              }
            }

            let tempDetails = {
              id: selectedCharge?.id,
              additionalCharge: selectedCharge?.additionalCharge,
              additionalChargeAccountCode:
                selectedCharge?.additionalChargeAccountCode,
              addtionalChargeTax: selectedCharge?.addtionalChargeTax ?? null,
              addtionalChargeTaxCode:
                selectedCharge?.addtionalChargeTaxCode ?? null,
              isPreCharge: selectedCharge.isPreCharge,
              isPercent: selectedCharge?.isPercent,
              percent: selectedCharge?.percent,
              chargeAmount: chargeAmount,
              apportionFlag: selectedCharge?.apportionFlag || true,
              apportionValue: selectedCharge?.apportionValue || null
            };

            if (existingChargeIndex !== -1) {
              existingChargeDetails[existingChargeIndex] = tempDetails;
            } else {
              existingChargeDetails.push(tempDetails);
            }

            item = {
              ...item,
              additionalCharges: {
                additionalChargesDetails: existingChargeDetails
              }
            };

            return item;
          });
          break;
        case ADDITIONAL_CHARGE_METHODS.APPORTION_MANUAL:
          itemsCopy = itemsCopy?.map((item: any) => {
            if (item?.product?.type !== PRODUCT_TYPE.TRACKED) {
              return {
                ...item,
                additionalCharges: {
                  additionalChargesDetails: []
                }
              };
            }

            let copyAdditionalChargeDetails: any = item?.additionalCharges
              ? item?.additionalCharges
              : { additionalChargesDetails: [] };
            let existingChargeIndex =
              copyAdditionalChargeDetails?.additionalChargesDetails?.findIndex(
                (charge: any) =>
                  charge.additionalCharge === selectedCharge.additionalCharge
              );
            let existingChargeDetails = [
              ...copyAdditionalChargeDetails.additionalChargesDetails
            ];
            let tempDetails = {
              id: selectedCharge?.id,
              additionalCharge: selectedCharge?.additionalCharge,
              additionalChargeAccountCode:
                selectedCharge?.additionalChargeAccountCode,
              addtionalChargeTax: selectedCharge?.addtionalChargeTax ?? null,
              addtionalChargeTaxCode:
                selectedCharge?.addtionalChargeTaxCode ?? null,
              isPreCharge: selectedCharge.isPreCharge,
              isPercent: selectedCharge?.isPercent,
              percent: selectedCharge?.percent,
              chargeAmount: calculateManualAdditionalChargeDistribution(
                existingChargeDetails,
                existingChargeIndex,
                selectedCharge,
                itemsCopy?.filter(
                  (item: any) => item?.product?.type === PRODUCT_TYPE.TRACKED
                ).length
              ),
              apportionFlag: selectedCharge?.apportionFlag || true,
              apportionValue: selectedCharge?.apportionValue || null
            };

            if (existingChargeIndex !== -1) {
              existingChargeDetails[existingChargeIndex] = tempDetails;
            } else {
              existingChargeDetails.push(tempDetails);
            }

            item = {
              ...item,
              additionalCharges: {
                additionalChargesDetails: existingChargeDetails
              }
            };

            return item;
          });
          break;
      }
    } else {
      itemsCopy = itemsCopy?.map((item: any) => {
        let existingChargeDetails =
          item?.additionalCharges?.additionalChargesDetails?.filter(
            (charge: any) =>
              charge.additionalCharge !== selectedCharge.additionalCharge
          );

        item = {
          ...item,
          additionalCharges: {
            additionalChargesDetails: existingChargeDetails
          }
        };
        return item;
      });
    }

    return itemsCopy;
  };

  const showTaxField = () =>
    getTenantTaxSystem() === TAX_SYSTEM.US &&
    tenantInfo.complianceEnabled &&
    !isBillOrOrder;

  const calculateUSTax = (
    updatedItemIndex: any,
    items?: DocumentItem[],
    shippingInfo?: any
  ) => {
    let lineItems = items?.length ? items : [...productRows];

    if (lineItems.length > 0) {
      ItemTaxCalculator.tenantInfo = tenantInfo;
      let payload: any = {
        customerCode: contact?.avalaraCustomerCode,
        companyCode: tenantInfo.avalaraCode,
        shipTo: shippingInfo ? shippingInfo.shipTo : booksDocument.shipTo,
        shipFrom: shippingInfo ? shippingInfo.shipFrom : booksDocument.shipFrom,
        lines: [],
        docDate: DateFormatService.getDateStrFromDate(
          selectedDocument ? selectedDocument.documentDate : new Date(),
          BOOKS_DATE_FORMAT['YYYY-MM-DD']
        )
      };

      if (contact?.taxExempted === true) {
        payload['exemptionNo'] = contact.code;
      }
      if (
        getTenantTaxSystem() === TAX_SYSTEM.US &&
        !tenantInfo.complianceEnabled &&
        payload.shipTo &&
        (!payload.shipTo.postalCode ||
          !payload.shipTo.address1 ||
          !payload.shipTo.state)
      ) {
        payload.shipTo = null;
      }
      if (
        getTenantTaxSystem() === TAX_SYSTEM.US &&
        payload.shipTo &&
        payload.shipTo.country !== COUNTRY_CODES.US &&
        payload.shipTo.country !== 'United States of America' &&
        payload.shipTo.country !== 'United States'
      ) {
        enableTaxColumForUS(false);
      } else {
        enableTaxColumForUS(true);
      }

      lineItems = lineItems?.length
        ? lineItems?.map((item: any) => {
            let itemCopy = deepClone(item);
            ItemTaxCalculator.item = itemCopy;
            ItemTaxCalculator.setInitialValues();
            itemCopy = { ...ItemTaxCalculator.item };
            return itemCopy;
          })
        : [];

      if (
        typeof additionalCharges.current?.additionalChargesDetails !==
          'undefined' &&
        additionalCharges.current?.additionalChargesDetails !== null
      ) {
        let remainingCharges =
          additionalCharges.current?.additionalChargesDetails?.map(
            (item: any) => item.additionalCharge
          );

        lineItems = lineItems.map((item: any) => {
          let itemCopy = { ...item };
          let additionalChargesDetails =
            itemCopy?.additionalCharges?.additionalChargesDetails ?? [];
          additionalChargesDetails = additionalChargesDetails.filter(
            (charge: any) => remainingCharges.includes(charge.additionalCharge)
          );
          return {
            ...itemCopy,
            additionalCharges: {
              additionalChargesDetails
            }
          };
        });

        additionalCharges.current?.additionalChargesDetails?.forEach(
          (charge: any) => {
            lineItems = updateLineLevelAdditionalChargeAllocation(
              charge,
              lineItems
            );
          }
        );
      }

      lineItems.forEach((row: any, index: number) => {
        ItemTaxCalculator.item = {
          ...row,
          ...getDiscountRelatedKeys(
            row.discountInPercent ? row.discount + '%' : row.discount
          )
        };
        ItemTaxCalculator.setInitialValues();
        let taxAmount: any = ItemTaxCalculator.calculateTaxAmount();
        if (updatedItemIndex === undefined || updatedItemIndex === index) {
          taxAmount = row.product.taxable ? null : 0;
        }
        if (items !== undefined) {
          taxAmount = null;
        }

        payload.lines.push({
          amount: ItemTaxCalculator.item.totalWithDiscount,
          description: ItemTaxCalculator.item.productDescription,
          quantity: ItemTaxCalculator.item.productQuantity,
          taxAmount: taxAmount,
          taxCode: ItemTaxCalculator.item.product.categoryCode
        });
        ItemTaxCalculator.item.taxAmount = null as any;
      });

      if (showTaxField()) {
        TaxService.calculateUsTax(payload).then(
          (taxes: any) => {
            let prodRows: any[] = [...lineItems];
            taxes.lines.forEach((taxLine: any, index: number) => {
              ItemTaxCalculator.item = {
                ...prodRows[index],
                ...getDiscountRelatedKeys(
                  prodRows[index].discountInPercent
                    ? prodRows[index].discount + '%'
                    : prodRows[index].discount
                )
              };
              ItemTaxCalculator.setInitialValues();
              ItemTaxCalculator.item.taxAmount = null as any;
              ItemTaxCalculator.item.taxAmount = taxLine.tax;
              ItemTaxCalculator.updateCalculatedValues();
              prodRows[index] = ItemTaxCalculator.item;
            });
            setProductRows([...prodRows]);
            setBooksDocument((prevBooksDocument: any) => {
              return {
                ...prevBooksDocument,
                items: [...prodRows]
              };
            });
          },
          (err: any) => {
            console.error('Error calculating tax: ', err.data);
            if (
              !usTaxCalculateAlertVisible.current &&
              err.data?.errorMessage?.includes('postal code is not valid')
            ) {
              usTaxCalculateAlertVisible.current = true;
              showUSInvalidZipCodeAlert(err.data?.errorMessage);
            }
            handleUSLineItemsWhenTaxIsOff(
              updatedItemIndex,
              lineItems,
              payload.shipTo
            );
          }
        );
      } else {
        handleUSLineItemsWhenTaxIsOff(
          updatedItemIndex,
          lineItems,
          payload.shipTo
        );
      }
    }
  };

  const handleUSLineItemsWhenTaxIsOff = (
    updatedItemIndex: any,
    lineItems: DocumentItem[],
    shipTo: any
  ) => {
    let prodRows: any[] = [...lineItems];
    lineItems.forEach((row: any, index: number) => {
      ItemTaxCalculator.item = {
        ...row,
        ...getDiscountRelatedKeys(
          row.discountInPercent ? row.discount + '%' : row.discount
        )
      };
      ItemTaxCalculator.setInitialValues();
      let taxAmount = ItemTaxCalculator.calculateTaxAmount();
      if (updatedItemIndex === undefined || updatedItemIndex === index) {
        taxAmount = (row.product.taxable ? null : 0) as number;
      }
      ItemTaxCalculator.item.taxAmount = taxAmount;
      ItemTaxCalculator.updateCalculatedValues();
      prodRows[index] = ItemTaxCalculator.item;
    });
    setBooksDocument((prevState: any) => ({
      ...prevState,
      shipTo: shipTo,
      items: [...prodRows]
    }));
    setProductRows([...prodRows]);
  };

  const showUSInvalidZipCodeAlert = (message: string) => {
    const alertButtonConfig = [
      {
        title: 'Cancel',
        className: 'bg-gray2 border-m mr-s',
        onClick: () => {
          usTaxCalculateAlertVisible.current = false;
        }
      },
      {
        title: 'Update',
        className: 'bg-button text-white',
        onClick: () => {
          setContactMode(DOCUMENT_MODE.EDIT);
          setShowAddContactPopup(true);
          usTaxCalculateAlertVisible.current = false;
        }
      }
    ];
    showAlert('Error', message, alertButtonConfig);
  };

  const enableTaxColumForUS = (flag: boolean) => {
    let config = columnConfig;
    config = config.map((column: any) => {
      if (column.key === 'taxAmount') {
        column.editable = flag;
      }
    });
    setColumnConfig(columnConfig);
  };

  const hideTaxAmountColumn = () => {
    let flag = true;
    if (
      getTenantTaxSystem() === TAX_SYSTEM.US &&
      Utility.isComplianceEnabled()
    ) {
      flag = false;
    }

    return flag;
  };

  const isContainSalesOrPurchaseReturn = () => {
    var isContains = false;
    if (notesData.salesReturn !== undefined && notesData.salesReturn !== null) {
      if (
        notesData.salesReturn.salesReturnCode !== undefined &&
        notesData.salesReturn.salesReturnCode !== null
      ) {
        isContains = true;
      }
    } else if (
      notesData.purchaseReturn !== undefined &&
      notesData.purchaseReturn !== null
    ) {
      if (
        notesData.purchaseReturn.purchaseReturnCode !== undefined &&
        notesData.purchaseReturn.purchaseReturnCode !== null
      ) {
        isContains = true;
      }
    } else if (
      [DOC_TYPE.INVOICE, DOC_TYPE.BILL].includes(selectedDocumentType)
    ) {
      isContains = true;
    }

    return isContains;
  };

  const updateProductConfig = () => {
    let config = productColumnConfig;
    let documentDate = selectedDocument
      ? selectedDocument?.documentDate
      : new Date();

    var isContainSalesReturnOrPurchaseReturn = isContainSalesOrPurchaseReturn();
    config.forEach((conf: any) => {
      switch (conf.key) {
        // case 'customField':
        //   const data = getClassDimensionData();
        //   conf.hidden = hideClassColumn();
        //   conf.editable =
        //     props.documentMode !== DOCUMENT_MODE.VIEW &&
        //     props.draftType !== DraftTypes.READONLY;
        //   conf.dropdownConfig.data = data?.length ? data : [];
        //   conf.dropdownConfig.button.onClick = () => setShowAddClassPopup(true);
        //   break;
        case 'productDescription':
          conf.editable = props.documentMode === DOCUMENT_MODE.NEW;
          break;
        case 'product':
          let productDataArray =
            productsData?.content?.length > 0 ? productsData.content : [];
          conf.editable =
            props.documentMode === DOCUMENT_MODE.NEW &&
            !isContainSalesReturnOrPurchaseReturn;
          conf.dropdownConfig.data =
            productDataArray.length > 0
              ? DocumentConfigUtility.productDataParser(
                  { content: productDataArray },
                  documentType
                )
              : [];
          conf.dropdownConfig.searchApiConfig.getUrl = (search: any) =>
            DocumentConfigManager.getProductURL(search, tenantInfo, '');
          conf.dropdownConfig.searchApiConfig.dataParser = (data: any) =>
            DocumentConfigUtility.productDataParser(data, documentType);
          if (
            GranularPermissionsHelper.hasPermissionFor(
              PERMISSIONS_BY_MODULE.PRODUCTS.CREATE
            )
          ) {
            conf.dropdownConfig.button.onClick = () =>
              setShowProductPopup(true);
          } else {
            conf.dropdownConfig.button = null;
          }
          break;
        case 'tax':
          let taxData = !isBillOrOrder ? salesTaxes : purchaseTaxes;
          taxData = taxData.filter((taxItem: any) => {
            if (
              taxItem.effectiveEndDate !== undefined &&
              taxItem.effectiveEndDate !== null
            ) {
              if (
                documentDate >=
                  DateFormatService.getDateFromStr(
                    taxItem.effectiveStartDate,
                    BOOKS_DATE_FORMAT['YYYY-MM-DD']
                  ) &&
                documentDate <=
                  DateFormatService.getDateFromStr(
                    taxItem.effectiveEndDate,
                    BOOKS_DATE_FORMAT['YYYY-MM-DD']
                  )
              ) {
                return taxItem;
              }
            } else {
              if (
                documentDate >=
                DateFormatService.getDateFromStr(
                  taxItem.effectiveStartDate,
                  BOOKS_DATE_FORMAT['YYYY-MM-DD']
                )
              ) {
                return taxItem;
              }
            }
          });

          conf.editable = props.documentMode === DOCUMENT_MODE.NEW;
          // isEditMode &&
          // (Utility.isEmpty(props.permissionKeys?.EDIT_TAX)
          //   ? true
          //   : GranularPermissionsHelper.hasPermissionFor(
          //     props.permissionKeys.EDIT_TAX
          //   ));
          conf.hidden = getTenantTaxSystem() === TAX_SYSTEM.US;
          conf.dropdownConfig.data = taxData?.length
            ? DocumentConfigUtility.taxDataParser(
                { content: taxData },
                documentType
              )
            : [];
          conf.dropdownConfig.searchApiConfig.getUrl = (search: any) =>
            DocumentConfigManager.getTaxURL(
              search,
              DateFormatService.getDateStrFromDate(
                documentDate,
                BOOKS_DATE_FORMAT['YYYY-MM-DD']
              )
            );
          conf.dropdownConfig.searchApiConfig.dataParser = (data: any) =>
            DocumentConfigUtility.taxDataParser(data, documentType);
          if (!checkUserPermission(PERMISSIONS_BY_MODULE.SETTINGS.TAX)) {
            conf.dropdownConfig.button = null;
          } else {
            conf.dropdownConfig.button.onClick = () => setShowTaxPopup(true);
          }

          break;
        case 'uom':
          conf.editable = props.documentMode === DOCUMENT_MODE.NEW;
          conf.dropdownConfig.data = selectedProductUOMS || [];
          break;
        case 'taxAmount':
          conf.hidden = hideTaxAmountColumn();
          conf.name =
            getTenantTaxSystem() === TAX_SYSTEM.US ? 'Tax' : 'Tax Amount';
          conf.editable =
            !(!hideTaxAmountColumn() && contact && contact.taxExempted) &&
            props.documentMode === DOCUMENT_MODE.NEW;

          // &&
          // props.draftType !== DraftTypes.READONLY &&
          // (Utility.isEmpty(props.permissionKeys?.EDIT_TAX)
          //   ? true
          //   : GranularPermissionsHelper.hasPermissionFor(
          //     props.permissionKeys.EDIT_TAX
          //   ));
          conf.type =
            !hideTaxAmountColumn() && contact && contact.taxExempted
              ? INPUT_TYPE.TEXT
              : INPUT_TYPE.NUMBER;
          conf.formatter = (data: any) => {
            if (!hideTaxAmountColumn() && contact && contact.taxExempted) {
              // Tax exempted contact for US
              return 'Exempted';
            } else {
              return DocumentConfigUtility.amountFormatter(
                data.value,
                booksDocument.currency
              );
            }
          };
          break;
        case 'totalAmount':
          conf.formatter = (data: any) =>
            DocumentConfigUtility.amountFormatter(
              data.value,
              booksDocument.currency
            );
          break;
        case 'discount':
          conf.editable = props.documentMode === DOCUMENT_MODE.NEW;
          // props.documentMode !== DOCUMENT_MODE.VIEW &&
          // props.draftType !== DraftTypes.READONLY &&
          // (Utility.isEmpty(props.permissionKeys?.EDIT_DISCOUNT)
          //   ? true
          //   : GranularPermissionsHelper.hasPermissionFor(
          //     props.permissionKeys.EDIT_DISCOUNT
          //   ));
          conf.formatter = (data: any) => {
            const row = productRows[data.rowIndex];
            return row?.discountInPercent
              ? data.value + '%'
              : DocumentConfigUtility.amountFormatter(
                  data.value,
                  booksDocument.currency
                );
          };
          break;
        case 'unitPrice':
          conf.editable = props.documentMode === DOCUMENT_MODE.NEW;
          // props.documentMode !== DOCUMENT_MODE.VIEW &&
          // props.draftType !== DraftTypes.READONLY &&
          // (Utility.isEmpty(props.permissionKeys?.EDIT_PRICE)
          //   ? true
          //   : GranularPermissionsHelper.hasPermissionFor(
          //     props.permissionKeys.EDIT_PRICE
          //   ));
          conf.formatter = (data: any) =>
            DocumentConfigUtility.amountFormatter(
              data.value,
              booksDocument.currency
            );
          conf.dropdownConfig.data = selectedProductPriceList;
          break;
        case 'productQuantity':
          conf.editable = props.documentMode === DOCUMENT_MODE.NEW;
          // (Utility.isDropship(props.booksDocument) &&
          //   props.booksDocument.documentType === DOC_TYPE.ORDER) ||
          //   props.documentMode === DOCUMENT_MODE.VIEW ||
          //   props.draftType === DraftTypes.READONLY ||
          //   (props?.booksDocument?.documentType === DOC_TYPE.ORDER &&
          //     props?.booksDocument?.linkedDocuments?.[0]?.documentType ===
          //     'PURCHASE_REQUEST')
          //   ? false
          //   : true;
          break;
        default:
          conf.editable = props.documentMode === DOCUMENT_MODE.NEW;
          // conf.editable =
          //   props.documentMode !== DOCUMENT_MODE.VIEW &&
          //   props.draftType !== DraftTypes.READONLY;
          const classData = dimensionData?.content?.find(
            (data: any) => data.label === LOCATION_CLASS_ENUM.CLASS
          );
          if (classData && classData.id === conf.id) {
            conf.hidden = hideClassColumn();
            conf.dropdownConfig.button = {
              title: '+ Add New',
              className: 'bg-button text-white',
              onClick: () => setShowAddClassPopup(true)
            };
          }
          if (conf.type === INPUT_TYPE.DROPDOWN && !!conf.dropdownConfig) {
            conf.dropdownConfig.allowSearch =
              conf.dropdownConfig?.data?.length > 5;
            conf.dropdownConfig.searchableKey = 'value';
          }
          break;
      }
    });
    setProductColumnConfig(config.filter((col: any) => !col.hidden));
  };

  const isPriceListEnabled = () => {
    const hiddenApps = appCustomizationData?.hiddenApps?.split(',');
    return !hiddenApps?.includes('PRICE_LIST');
  };

  const showPriceListAlert = (
    productsPayload: any[],
    lineItems: any[],
    exchangeRate: number,
    priceListResponse: any,
    updateExistingItem: boolean,
    shippingInfo: any
  ) => {
    const alertButtonConfig = [
      {
        title: "Don't update",
        className: 'bg-gray2 border-m ',
        onClick: () => {
          // reset isDateUpdatedManually && isContactChangedManually
          isDocDateUpdatedManually.current = false;
          isContactChangedManually.current = false;
          isQuantityChangedManually.current = false;
          isUOMChangedManually.current = false;
        }
      },
      {
        title: 'Update',
        className: 'bg-button text-white ml-r',
        onClick: () => {
          processUpdatePricingValue(
            productsPayload,
            lineItems,
            exchangeRate,
            priceListResponse,
            updateExistingItem,
            shippingInfo
          );
        }
      }
    ];

    showAlert(
      'Update Unit price',
      'Do you want to update all unit prices to follow the Price List of the new invoice date?',
      alertButtonConfig
    );
  };

  const processUpdatePricingValue = (
    productsToUpdate: any[],
    lineItems: any[],
    exchangeRate: number,
    priceListResponse: any,
    updateExistingItem: boolean,
    shippingInfo: any
  ) => {
    let itemsToUpdate: any[] = [];
    productsToUpdate.forEach((productToUpdate) => {
      let itemToUpdate: any = {};
      if (updateExistingItem) {
        itemToUpdate = lineItems.find(
          (item: any) =>
            productToUpdate.productId === item.productCode &&
            productToUpdate.quantity === +item.productQuantity &&
            productToUpdate.uomId === item.documentUom &&
            (!itemsToUpdate.length ||
              itemsToUpdate.findIndex(
                (x) => x.lineNumber === item.lineNumber
              ) === -1)
        );
      } else {
        itemToUpdate = lineItems.find(
          (item: any) =>
            productToUpdate.productId === item.productCode &&
            productToUpdate.quantity === +item.productQuantity &&
            productToUpdate.uomId === item.documentUom &&
            currentIndex === item.lineNumber - 1
        );
      }

      if (!Utility.isEmpty(itemToUpdate)) {
        itemsToUpdate.push(itemToUpdate);
      }
    });

    itemsToUpdate = itemsToUpdate.map((item, index) => {
      if (
        // productsToUpdate.includes(item.product.productId) &&
        updateExistingItem ||
        (!updateExistingItem && currentIndex === item.lineNumber - 1)
      ) {
        const priceIndexInPriceList = priceListResponse.findIndex(
          (resp: any) =>
            resp.productId === item.productCode &&
            resp.quantity === +item.productQuantity &&
            resp.uomId === item.documentUom
        );

        const priceInPriceList = priceListResponse[priceIndexInPriceList].price;
        const isPricePresentInPriceList = priceIndexInPriceList !== -1;

        let productData = item.product ? item.product : undefined;
        let unitPrice = productData
          ? isPricePresentInPriceList
            ? priceInPriceList
            : getMainModuleName(documentType) ===
              MODULE_NAME_FOR_STORAGE_UTILITY.SELL
            ? productData.salesPrice
            : productData.purchasePrice
          : 0;

        if (!isPricePresentInPriceList) {
          unitPrice = unitPrice ? unitPrice * exchangeRate : 0;
        } else {
          if (unitPrice <= 0) {
            unitPrice = item.unitPrice;
          }
        }

        if (
          props.documentMode === DOCUMENT_MODE.NEW &&
          (item.isPartialInvoice || item.isPartialBill) &&
          item.product.stockUom === UOM_NA_ID
        ) {
          unitPrice = item.pendingAmount; // Need to check pending amount usage
        }

        if (typeof unitPrice !== 'undefined' && unitPrice !== null) {
          item = { ...item, unitPrice, uomUnitPrice: unitPrice };
        }
      }
      return item;
    });

    itemsToUpdate.forEach((item) => {
      // Remove 'unitPrice' from invalidFields if unit price is not 0
      const unitPriceIndexInInvalidFieldsArr = item.invalidFields?.findIndex(
        (field: string) => field === 'unitPrice'
      );
      if (item.unitPrice !== 0 && unitPriceIndexInInvalidFieldsArr !== -1) {
        item.invalidFields?.splice(unitPriceIndexInInvalidFieldsArr, 1);
      }

      const index = lineItems.findIndex(
        (x) => x.lineNumber === item.lineNumber
      );
      if (index !== -1) {
        lineItems[index] = item;
      }
    });

    // Calculate taxes based on updated line items
    if (getTenantTaxSystem() === TAX_SYSTEM.US) {
      if (updateExistingItem) {
        calculateUSTax(undefined, lineItems, shippingInfo);
      } else {
        calculateUSTax(currentIndex, lineItems, shippingInfo);
      }
    } else {
      lineItems.forEach((item: any, index: number) => {
        calculateTaxesAndAmount(index, lineItems);
      });
    }

    // reset isDateUpdatedManually && isContactChangedManually
    isDocDateUpdatedManually.current = false;
    isContactChangedManually.current = false;
    isQuantityChangedManually.current = false;
    isUOMChangedManually.current = false;
  };

  const getPricing = async (
    productsPayload: any[],
    lineItems: any[],
    currencyCode: string,
    updateExistingItem = true,
    exchangeRate?: number,
    changedValues?: any,
    shippingInfo?: any
  ) => {
    let documentDate = selectedDocument
      ? selectedDocument.documentDate
      : new Date();
    if (
      productsPayload.length &&
      currencyCode &&
      documentDate &&
      isPriceListEnabled()
    ) {
      if (Utility.isEmpty(contact)) {
        return;
      }
      let payload = {
        contactCode: contact.code ? contact.code : '',
        priceListPricingItems: productsPayload,
        type: getMainModuleName(documentType),
        currency: currencyCode,
        documentDate: DateFormatService.getDateStrFromDate(
          documentDate,
          BOOKS_DATE_FORMAT['DD-MM-YYYY']
        ),
        exchangeRate: exchangeRate
      };

      try {
        const priceListResponse = await PriceListService.getPricesFromPriceList(
          payload
        );

        if (
          !Utility.isEmpty(changedValues) &&
          (changedValues.contactChanged ||
            changedValues.dateChanged ||
            changedValues.quantityChanged ||
            changedValues.uomChanged)
        ) {
          showPriceListAlert(
            productsPayload,
            lineItems,
            exchangeRate as number,
            priceListResponse,
            updateExistingItem,
            shippingInfo
          );
        } else {
          processUpdatePricingValue(
            productsPayload,
            lineItems,
            exchangeRate as number,
            priceListResponse,
            updateExistingItem,
            shippingInfo
          );
        }
      } catch (err) {
        console.error('Error loading prices: ', err);
      }
    }
  };

  const updateUomSchemaForLineItem = (index: number, uomData: any) => {
    let rows: any = [...productRows];
    let selectedRow = { ...rows[index] };
    let unitPrice = selectedRow.unitPrice ? selectedRow.unitPrice : 0;
    if (unitPrice) {
      if (selectedRow.documentUOMSchemaDefinition) {
        const documentUOMSchemaDefinition =
          selectedRow.documentUOMSchemaDefinition;
        unitPrice =
          (unitPrice * documentUOMSchemaDefinition.sinkConversionFactor) /
            documentUOMSchemaDefinition.sourceConversionFactor || 0;
      }
      if (!uomData.isBaseUom) {
        unitPrice =
          (unitPrice * uomData.sourceConversionFactor) /
          uomData.sinkConversionFactor;
      }
    }
    if (unitPrice) {
      selectedRow.unitPrice = Utility.roundOff(unitPrice);
      selectedRow.uomUnitPrice = Utility.roundOff(unitPrice);
    }
    if (uomData.isBaseUom) {
      selectedRow.documentUOMSchemaDefinition = null;
      selectedRow.documentUom = uomData.id;
    } else {
      selectedRow.documentUOMSchemaDefinition = {
        uid: uomData.uid,
        sourceUOM: uomData.sourceUOM,
        sourceConversionFactor: uomData.sourceConversionFactor,
        sinkUOM: uomData.sinkUOM,
        sinkConversionFactor: uomData.sinkConversionFactor,
        id: uomData.sinkUOM,
        name: uomData.name,
        isBaseUom: false,
        schemaId: selectedRow.product.uomSchemaDto.id
      };
      selectedRow.documentUom = uomData.sinkUOM;
    }
    return selectedRow;
  };

  const onProductRowUpdate = ({ columnKey, rowData, rowIndex }: any) => {
    setLastUpdatedColumn(columnKey);
    let rows: any = [...productRows];
    let selectedRows = rows[rowIndex];
    let dataToUpdate: any = rowData && rowData[columnKey];
    selectedRows.invalidFields = selectedRows?.invalidFields
      ? [...selectedRows.invalidFields].filter((field) => field !== columnKey)
      : [];

    setRoundOffDirty(false);
    if (getTenantTaxSystem() === TAX_SYSTEM.INDIA_GST) {
      rows[rowIndex]['userSetTaxes'] = false;
    }
    switch (columnKey) {
      case 'product':
        setCurrentIndex(rowIndex);
        if (!validateProduct(rowData[columnKey])) {
          if (Utility.isEmpty(rows[rowIndex]?.product)) {
            rows[rowIndex] = {};
            selectedRows.invalidFields.push('product');
            setProductRows(rows);
          } else {
            setProductRows(rows);
          }
          return;
        }
        rows[rowIndex][columnKey] = dataToUpdate;
        break;
      case 'productQuantity':
        if (!dataToUpdate || isNaN(dataToUpdate)) {
          dataToUpdate = undefined;
          selectedRows.invalidFields.push('productQuantity');
        } else {
          dataToUpdate = Utility.roundingOff(
            dataToUpdate,
            tenantInfo.decimalScale
          );
          selectedRows.uomQuantity = Utility.roundOff(
            dataToUpdate,
            tenantInfo.decimalScale
          );
        }
        if (
          dataToUpdate &&
          !isNaN(dataToUpdate) &&
          (rowData.isPartialInvoice ||
            rowData.isPartialBill ||
            rowData.isPartialSalesOrder) &&
          isQuantityMoreThatPendingQuantity(+dataToUpdate, rowData)
        ) {
          dataToUpdate = undefined;
          selectedRows.invalidFields.push('productQuantity');
          showAlert(
            'Error!',
            `Quantity should not be more than pending quantity`
          );
        }
        if (
          dataToUpdate &&
          !isNaN(dataToUpdate) &&
          (+dataToUpdate === 0 || +dataToUpdate < 0)
        ) {
          dataToUpdate = undefined;
          selectedRows.invalidFields.push('productQuantity');
        }
        if (!selectedRows.invalidFields.includes('productQuantity')) {
          if (!Utility.isEmpty(selectedRows?.product)) {
            isQuantityChangedManually.current = true;
            const products = rows
              .filter((item: any) => !Utility.isEmpty(item.product))
              .map((item: any, index: number) => ({
                productId:
                  selectedRows.lineNumber === currentIndex + 1 &&
                  index === currentIndex
                    ? selectedRows?.product.productId
                    : item.product.productId,
                uomId:
                  selectedRows.lineNumber === currentIndex + 1 &&
                  index === currentIndex
                    ? selectedRows?.documentUom
                    : item.documentUom,
                quantity:
                  selectedRows.lineNumber === currentIndex + 1 &&
                  index === currentIndex
                    ? +dataToUpdate
                    : item.productQuantity
              }));

            rows[rowIndex][columnKey] = +dataToUpdate;

            // Fetch price list price after first change for new rows.
            // For existing rows price list price will be fetched as usual.
            let fetchUnitPrice = false;
            if (
              typeof rows[rowIndex]['id'] !== 'undefined' &&
              rows[rowIndex]['id'] !== null
            ) {
              fetchUnitPrice = true;
            } else {
              if (!rows[rowIndex]['firstAmountChangeDone']) {
                rows[rowIndex]['firstAmountChangeDone'] = true;
              } else {
                fetchUnitPrice = true;
              }
            }
            if (fetchUnitPrice) {
              getPricing(
                products,
                rows,
                booksDocument.currency,
                true,
                booksDocument.exchangeRate,
                { quantityChanged: true }
              );
            }
          }
        }
        rows[rowIndex][columnKey] = dataToUpdate;
        rows[rowIndex]['reservedQuantitiesData'] = null;
        break;
      case 'unitPrice':
        if (dataToUpdate === '' || +dataToUpdate === 0 || +dataToUpdate < 0) {
          dataToUpdate = undefined;
          selectedRows.invalidFields.push('unitPrice');
        } else {
          dataToUpdate = Utility.roundingOff(
            dataToUpdate,
            tenantInfo.decimalScale
          );
          selectedRows.uomUnitPrice = Utility.roundingOff(
            dataToUpdate,
            tenantInfo.decimalScale
          );
        }
        rows[rowIndex][columnKey] = dataToUpdate;
        break;
      case 'discount':
        ItemTaxCalculator.item = selectedRows;
        ItemTaxCalculator.setInitialValues();
        const taxAmount = ItemTaxCalculator.calculateTaxAmount();
        ItemTaxCalculator.item.taxAmount = taxAmount;
        ItemTaxCalculator.updateCalculatedValues();
        const matcher = String(dataToUpdate).match(REGEX.PERCENT_NUMER);
        rows[rowIndex].discountInPercent = false;
        if (!matcher) {
          dataToUpdate = 0;
        } else if ('%' === matcher[4]) {
          const percentDiscount = Number(dataToUpdate.replace('%', ''));
          if (percentDiscount > 100) {
            dataToUpdate = 100;
          } else {
            dataToUpdate = percentDiscount;
          }
          rows[rowIndex].discountInPercent = true;
        } else if (
          dataToUpdate < 0 ||
          (dataToUpdate as number) >
            (ItemTaxCalculator?.item?.subTotal as number)
        ) {
          selectedRows.invalidFields.push('discount');
        }
        rows[rowIndex][columnKey] = dataToUpdate;
        break;
      case 'tax':
        // If product is non-taxable, then don't update the tax value.
        // We need to handle this in grid by making the cell non-editable.
        if (
          typeof selectedRows.product.taxPreference !== 'undefined' &&
          selectedRows.product.taxPreference !== null &&
          !selectedRows.product.taxPreference
        ) {
          dataToUpdate = null;
        }
        rows[rowIndex][columnKey] = dataToUpdate;
        break;
      case 'taxAmount':
        const tax = selectedRows.tax;
        const updates: any = {
          taxCode: tax ? tax.code : '',
          taxName: tax ? tax.name : ''
        };
        if (!tax) {
          updates.taxAmount = 0;
        }
        rows[rowIndex] = {
          ...rows[rowIndex],
          ...updates
        };
        rows[rowIndex][columnKey] = dataToUpdate;
        break;
      case 'uom':
        rows[rowIndex] = updateUomSchemaForLineItem(rowIndex, dataToUpdate);
        if (!selectedRows.invalidFields.includes('uom')) {
          if (
            !Utility.isEmpty(selectedRows?.product) &&
            !selectedRows.invalidFields.includes('productQuantity')
          ) {
            isUOMChangedManually.current = true;
            const products = rows
              .filter((item: any) => !Utility.isEmpty(item.product))
              .map((item: any, index: number) => ({
                productId:
                  selectedRows.lineNumber === currentIndex + 1 &&
                  index === currentIndex
                    ? selectedRows?.product.productId
                    : item.product.productId,
                uomId:
                  selectedRows.lineNumber === currentIndex + 1 &&
                  index === currentIndex
                    ? rows[rowIndex]?.documentUom
                    : item.documentUom,
                quantity:
                  selectedRows.lineNumber === currentIndex + 1 &&
                  index === currentIndex
                    ? +selectedRows?.productQuantity
                    : item.productQuantity
              }));
            getPricing(
              products,
              rows,
              booksDocument.currency,
              true,
              booksDocument.exchangeRate,
              { uomChanged: true }
            );
          }
        }
        rows[rowIndex][columnKey] = dataToUpdate;
        rows[rowIndex]['reservedQuantitiesData'] = null;
        break;
      default:
        const CFColumnConfig = columnConfig?.find(
          (cf: any) => cf?.id === columnKey && cf.isCustomField
        );
        const filteredCF: any = productCustomFields?.find(
          (field: any) => field.id === columnKey
        );
        let cfValue = '';
        if (!Utility.isEmpty(filteredCF)) {
          if (
            filteredCF.fieldType.toLowerCase() ===
            CUSTOM_FIELD_TYPE.USER.toLowerCase()
          ) {
            const tempCFOption = filteredCF?.attributes?.find(
              (attr: any) => attr.code === dataToUpdate.code
            );
            if (tempCFOption) {
              cfValue = tempCFOption?.code;
            }
          } else if (
            filteredCF.fieldType.toLowerCase() ===
            CUSTOM_FIELD_TYPE.DROPDOWN.toLowerCase()
          ) {
            cfValue = dataToUpdate?.value;
          } else {
            cfValue =
              filteredCF.fieldType.toLowerCase() ===
              INPUT_TYPE.DATE.toLowerCase()
                ? DateFormatService.getDateStrFromDate(
                    new Date(dataToUpdate),
                    BOOKS_DATE_FORMAT['MM/DD/YYYY']
                  )
                : dataToUpdate;
          }
          if (CFColumnConfig && filteredCF) {
            const cfToUpdate = {
              id: filteredCF.id,
              shortName: filteredCF.shortName,
              module: filteredCF.module,
              code: filteredCF.code,
              label: filteredCF.label,
              value: cfValue
            };
            let existingCFs = [...rows[rowIndex].customField];
            const existingCFIndex = existingCFs.findIndex(
              (cf: any) => cf?.id === cfToUpdate?.id
            );
            if (existingCFIndex === -1) {
              existingCFs = [...existingCFs, cfToUpdate];
            } else {
              existingCFs[existingCFIndex] = cfToUpdate;
            }
            rows[rowIndex].customField = existingCFs;
          }
          rows[rowIndex][columnKey] =
            filteredCF?.fieldType?.toLowerCase() ===
            INPUT_TYPE.DATE.toLowerCase()
              ? new Date(dataToUpdate)
              : Utility.isObject(dataToUpdate)
              ? dataToUpdate.value
              : dataToUpdate;
          break;
        } else {
          rows[rowIndex][columnKey] = Utility.isObject(dataToUpdate)
            ? dataToUpdate.value
            : dataToUpdate;
          break;
        }
    }
    // if (columnKey !== 'uom') {
    // rows[rowIndex][columnKey] = dataToUpdate;
    // }
    lastProductRowUpdatedIndex.current = rowIndex;
    if (columnKey === 'product') {
      lastProductRowUpdatedIndexChange(rowIndex, dataToUpdate);
    } else {
      setProductRows(rows);
      setLineItemsTouched(true);
    }
  };

  const isQuantityMoreThatPendingQuantity = (
    quantity: number,
    lineItem: any
  ) => {
    let pendingQuantity = +lineItem.pendingQuantity;
    if (!Utility.isEmpty(lineItem.documentUOMSchemaDefinition)) {
      pendingQuantity = pendingQuantity * lineItem.uomQuantity;
    }

    return quantity > pendingQuantity;
  };

  const validateProduct = (product: any) => {
    const offeringType = product.offeringType;
    const productType = product.type;
    const hsnOrSacCode = product.hsnOrSacCode;
    const uqcIndia = product.uqcIndia;

    let showPopup = false;
    let message = '';

    if (
      offeringType === PRODUCT_OFFERING_TYPE.GOODS &&
      (productType === PRODUCT_TYPE.TRACKED ||
        productType === PRODUCT_TYPE.NON_TRACKED) &&
      (Utility.isEmpty(hsnOrSacCode) || Utility.isEmpty(uqcIndia))
    ) {
      showPopup = true;
      message =
        'Product HSN/SAC code and UQC required. Please update or select another product.';
    }

    if (
      offeringType === PRODUCT_OFFERING_TYPE.SERVICES &&
      productType === PRODUCT_TYPE.NON_TRACKED &&
      Utility.isEmpty(hsnOrSacCode)
    ) {
      showPopup = true;
      message =
        'Product HSN/SAC code required. Please update or select another product.';
    }

    if (getTenantTaxSystem() === TAX_SYSTEM.INDIA_GST && showPopup) {
      showAlert('Missing details', message, [
        {
          title: 'Cancel',
          className: 'bg-gray1 text-black-light',
          onClick: () => {
            return;
          }
        },
        {
          title: 'Update Product',
          className: 'bg-button text-white ml-2',
          onClick: () => {
            setProductToEdit(product);
            return;
          }
        }
      ]);
      return false;
    }
    return true;
  };

  const getUomPrice = (basePrice: any, documentUOMSchemaDefinition: any) => {
    if (!isNaN(basePrice) && documentUOMSchemaDefinition) {
      return Utility.roundOff(
        (basePrice * documentUOMSchemaDefinition.sourceConversionFactor) /
          documentUOMSchemaDefinition.sinkConversionFactor
      );
    }
    return basePrice;
  };

  // Update this for user CF
  const addProductCustomFieldsToLineItem = (lineItem: any, product: any) => {
    let cfs: any[] = [];
    if (
      !Utility.isEmpty(productCustomFields) &&
      !Utility.isEmpty(product.customField)
    ) {
      // Set default values of CFs when new line is added
      product.customField.forEach((productCF: any) => {
        const filteredCF = productCustomFields?.find(
          (field: any) =>
            field.id === productCF.id && field.status === STATUS_TYPE.ACTIVE
        );
        if (filteredCF) {
          let cfToUpdate = {
            id: filteredCF.id,
            shortName: filteredCF.shortName,
            module: filteredCF.module,
            code: filteredCF.code,
            label: filteredCF.label,
            value: ''
          };
          let valueOfCF = '';
          if (
            typeof productCF.value !== 'undefined' &&
            productCF.value !== null &&
            productCF.value !== ''
          ) {
            if (
              filteredCF.fieldType.toLowerCase() ===
              INPUT_TYPE.DATE.toLowerCase()
            ) {
              lineItem[productCF.id] = DateFormatService.getDateFromStr(
                productCF.value,
                BOOKS_DATE_FORMAT['MM/DD/YYYY']
              );
            } else if (
              filteredCF.fieldType.toLowerCase() ===
                INPUT_TYPE.DROPDOWN.toLowerCase() ||
              filteredCF.fieldType.toLowerCase() === 'user'
            ) {
              const tempCF = filteredCF?.attributes?.find(
                (attr: any) => attr.code === productCF.value
              );
              if (tempCF) {
                lineItem[productCF.id] = tempCF;
              }
            } else {
              lineItem[productCF.id] = productCF.value;
            }
            valueOfCF = productCF.value;
          } else {
            lineItem[productCF.id] = '';
          }
          cfToUpdate.value = valueOfCF;
          cfs.push(cfToUpdate);
        }
      });
    }
    return { ...lineItem, customField: cfs };
  };

  const fetchProductUnitPrice = async (rows?: DocumentItem[]) => {
    const inlineProductsList = rows?.length ? [...rows] : [...productRows];
    let productCodes: string[] = [];
    inlineProductsList.forEach((item) => {
      if (!Utility.isEmpty(item.product)) {
        const code = item.product.productId || item.productCode;
        productCodes.push(code);
      }
    });
    const contactCode = Utility.isObject(contact) ? contact.code : '';
    if (
      productCodes.length > 0 &&
      documentType !== DOC_TYPE.JOB_WORK_OUT_ORDER
    ) {
      try {
        const unitePriceData = await PriceListService.fetchLastPricesOfProduct(
          productCodes,
          documentType,
          contactCode
        );
        const unitPricesList = unitePriceData.unitPrices;
        unitPricesList.forEach((product: any) => {
          setProductsLastUnitPrice((prevState: any) => {
            return {
              ...prevState,
              [product.productCode]: product.lastUnitPrices
            };
          });
        });
      } catch (err) {
        console.error('Error fetchin last 3 prices of product: ', err);
      }
    }
  };

  const lastProductRowUpdatedIndexChange = (
    rowIndex: number,
    product: any,
    isBarcode?: boolean,
    items?: DocumentItem[]
  ) => {
    // setUomsForSelectedProduct(product);
    if (!booksDocument.isDocumentTouched) {
      setBooksDocument((prevState: any) => {
        return {
          ...prevState,
          isDocumentTouched: true
        };
      });
    }

    let rows = items ? [...items] : [...productRows];
    let selectedRow = rows[rowIndex];
    if (!Utility.isEmpty(product)) {
      let updatedLineItem: DocumentItem | any = {} as any;
      if (
        props.documentMode === DOCUMENT_MODE.EDIT ||
        props.documentMode === DOCUMENT_MODE.COPY
      ) {
        updatedLineItem =
          !Utility.isEmpty(selectedRow.productName) &&
          product.name === selectedRow.productName
            ? {
                ...selectedRow,
                gstType: Utility.getValue(
                  selectedRow.gstType,
                  booksDocument.gstType
                )
              }
            : createLineItem(
                product,
                Utility.getValue(selectedRow.gstType, booksDocument.gstType),
                selectedRow.lineNumber,
                booksDocument.documentType as DOC_TYPE
              );
      } else {
        updatedLineItem = createLineItem(
          product,
          Utility.getValue(selectedRow.gstType, booksDocument.gstType),
          selectedRow.lineNumber,
          booksDocument.documentType as DOC_TYPE
        );
      }

      updatedLineItem = {
        ...updatedLineItem,
        advancedTracking: product.advancedTracking,
        unitPriceGstInclusive: selectedRow.unitPriceGstInclusive
      };

      if (getTenantTaxSystem() === TAX_SYSTEM.INDIA_GST) {
        if (contact && product) {
          updatedLineItem = {
            ...updatedLineItem,
            isRcmApplied: rcmAppliedIndia(
              documentType,
              contact.gstTreatment,
              product
            )
          };
        }
      } else if (getTenantTaxSystem() === TAX_SYSTEM.MALAYSIA) {
        updatedLineItem = {
          ...updatedLineItem,
          isTaxable: product ? product.exemptedMalaysia : true
        };
      }

      if (!isBillOrOrder && tenantInfo.registeredToCompositionScheme) {
        updatedLineItem = {
          ...updatedLineItem,
          compositionTaxPercent:
            COMPOSTION_TAX_PERCENT[tenantInfo.compositionSchemeType]
        };
      }

      let tax = getTax({ ...product });

      updatedLineItem.invalidFields = updatedLineItem.invalidFields
        ? [...updatedLineItem.invalidFields].filter(
            (field) => field !== 'unitPrice'
          )
        : [];
      if (updatedLineItem.unitPrice === 0) {
        updatedLineItem.invalidFields.push('unitPrice');
      }

      if (
        (typeof product.taxPreference !== 'undefined' &&
          product.taxPreference !== null &&
          !product.taxPreference) ||
        !updatedLineItem.isTaxable
      ) {
        updatedLineItem.nonEditableColumns = updatedLineItem.nonEditableColumns
          ? [...updatedLineItem.nonEditableColumns].filter(
              (field) => field !== 'tax'
            )
          : [];
        updatedLineItem?.nonEditableColumns?.push('tax');
      }

      //Update UOM Values

      if (isBarcode) {
        updatedLineItem.discount = product.discountAmount;
        updatedLineItem.discountAmount = product.discountAmount;
      }

      updatedLineItem.uomQuantity = getUomQuantity(
        selectedRow.productQuantity,
        selectedRow.documentUOMSchemaDefinition
      );
      updatedLineItem.uomUnitPrice = getUomPrice(
        selectedRow.unitPrice,
        selectedRow.documentUOMSchemaDefinition
      );
      updatedLineItem.uomAvailableQuantity = getUomQuantity(
        selectedRow.availableQuantity,
        selectedRow.documentUOMSchemaDefinition
      );
      updatedLineItem.uomFulfilledQuantity = getUomQuantity(
        selectedRow.fulfilledQuantity,
        selectedRow.documentUOMSchemaDefinition
      );
      updatedLineItem.uomQuantityFulfilled = getUomQuantity(
        selectedRow.quantityFulfilled,
        selectedRow.documentUOMSchemaDefinition
      );
      updatedLineItem.uomPendingQuantity = getUomQuantity(
        selectedRow.pendingQuantity,
        selectedRow.documentUOMSchemaDefinition
      );
      updatedLineItem.uomReceivedQuantityInBills = getUomQuantity(
        selectedRow.receivedQuantityInBills,
        selectedRow.documentUOMSchemaDefinition
      );
      updatedLineItem.uomReceivedQuantityInOrder = getUomQuantity(
        selectedRow.receivedQuantityInOrder,
        selectedRow.documentUOMSchemaDefinition
      );

      // updatedLineItem.uomUnitPrice = selectedRow.unitPrice;
      // updatedLineItem.uomQuantity = selectedRow.productQuantity;

      updatedLineItem = addProductCustomFieldsToLineItem(
        { ...updatedLineItem },
        product
      );

      updatedLineItem = {
        ...updatedLineItem,
        unitPrice: updatedLineItem.unitPrice
          ? updatedLineItem.unitPrice * booksDocument.exchangeRate
          : 0,
        unitPriceGstInclusive: booksDocument.unitPriceGstInclusive,
        documentUom: product.stockUom,
        cessRule: product.cessRule ? product.cessRule : '',
        cessRuleDescription: product.cessRuleDescription,
        cessNonAdvol: product?.cessNonAdvol,
        stockUom: product.stockUom,
        reservedQuantitiesData: null
      };
      rows[rowIndex] = { ...updatedLineItem, tax };
      lastProductRowUpdatedIndex.current = rowIndex;

      if (isBarcode) {
        return { ...updatedLineItem, tax };
      } else {
        setProductRows(rows);
        const productsPayload = [
          { productId: product.productId, uomId: product.stockUom, quantity: 1 }
        ];
        getPricing(
          productsPayload,
          rows,
          booksDocument.currency,
          false,
          booksDocument.exchangeRate
        );
        fetchProductUnitPrice(rows);
      }
    }
  };

  const onProductRowClick = ({ rowIndex }: any) => {
    let lineitem = { ...productRows[rowIndex] };
    lastProductRowUpdatedIndex.current = rowIndex;
    setUomsForSelectedProduct(lineitem.product);
    setPricesForSelectedProduct(lineitem);
    setCurrentIndex(rowIndex);
  };

  const setUomsForSelectedProduct = (product: any) => {
    let productCopy = { ...product };
    let uoms = [];
    let filtered = uomsData.filter(
      (uom: any) => uom.id === productCopy.stockUom
    );
    if (!Utility.isEmpty(filtered)) {
      uoms.push({ ...filtered[0], isBaseUom: true });
    }
    if (
      !Utility.isEmpty(productCopy) &&
      !Utility.isEmpty(productCopy.uomSchemaDto)
    ) {
      // uoms[0] = { ...uoms[0], isBaseUom: true };
      let processedUoms = productCopy.uomSchemaDto.uomSchemaDefinitions.map(
        (uomSchema: any) => {
          let filtered = uomsData.filter(
            (uom: any) => uom.id === uomSchema.sinkUOM
          );
          let name = '';
          if (filtered && filtered.length > 0) {
            name = filtered[0].name;
          }
          return {
            ...uomSchema,
            name: name,
            isBaseUom: false
          };
        }
      );
      uoms = uoms.concat(processedUoms);
    }
    setSelectedProductUOMS(uoms);
  };

  const setPricesForSelectedProduct = (lineItem: any) => {
    if (
      lineItem &&
      !Utility.isEmpty(lineItem.product) &&
      !Utility.isEmpty(productsLastUnitPrice) &&
      productsLastUnitPrice[lineItem.product.productId]
    ) {
      setSelectedProductPriceList([
        ...productsLastUnitPrice[lineItem.product.productId]
      ]);
    }
  };

  const updateTaxList = () => {
    let taxData = !isBillOrOrder ? salesTaxes : purchaseTaxes;
    let documentDate = selectedDocument
      ? selectedDocument.documentDate
      : new Date();
    taxData = taxData.filter((taxItem: any) => {
      if (
        taxItem.effectiveEndDate !== undefined &&
        taxItem.effectiveEndDate !== null
      ) {
        if (
          documentDate >=
            DateFormatService.getDateFromStr(
              taxItem.effectiveStartDate,
              BOOKS_DATE_FORMAT['YYYY-MM-DD']
            ) &&
          documentDate <=
            DateFormatService.getDateFromStr(
              taxItem.effectiveEndDate,
              BOOKS_DATE_FORMAT['YYYY-MM-DD']
            )
        ) {
          return taxItem;
        }
      } else {
        if (
          documentDate >=
          DateFormatService.getDateFromStr(
            taxItem.effectiveStartDate,
            BOOKS_DATE_FORMAT['YYYY-MM-DD']
          )
        ) {
          return taxItem;
        }
      }
    });

    return taxData;
  };

  const getDefaultTaxForSG = (tax: any) => {
    let taxList = updateTaxList();
    const taxSystem = getTenantTaxSystem();
    if (taxSystem === TAX_SYSTEM.SG) {
      if (taxList.length > 0) {
        let newTax = taxList.filter((item: any) => {
          if (
            item.description.replace(/[0-9]/g, '') ===
            tax?.description.replace(/[0-9]/g, '')
          ) {
            return item;
          }
        });
        tax = newTax[0];
      }
    }
    return tax;
  };

  const getTax = (product: any) => {
    let tax: any = null;
    const taxSystem = getTenantTaxSystem();
    if (taxSystem === TAX_SYSTEM.INDIA_GST && product.taxPreference === false) {
      return null;
    } else if (
      taxSystem === TAX_SYSTEM.MALAYSIA &&
      product.exemptedMalaysia === false
    ) {
      return null;
    } else if (taxSystem === TAX_SYSTEM.US) {
      return null;
    } else {
      let taxCode: any = null;
      if (isBillOrOrder) {
        taxCode = product.salesTaxCode;
        tax = salesTaxes.find((tax) => tax.code === taxCode);
        tax = getDefaultTaxForSG(tax);
        return tax ? tax : taxCode === '' ? null : taxCode;
      } else {
        taxCode = product.purchaseTaxCode;
        tax = purchaseTaxes.find((tax) => tax.code === taxCode);
        tax = getDefaultTaxForSG(tax);
        return tax ? tax : taxCode === '' ? null : taxCode;
      }
    }
  };

  const getColumnConfigFromDocType = () => {
    let columnConfigInfo: { tableId?: any; columnConfig?: any } = {};

    switch (props.notesType) {
      case DOC_TYPE.CREDIT_NOTE:
        columnConfigInfo = {
          columnConfig: creditColumnConfig,
          tableId: creditConfigTableId
        };
        break;
      case DOC_TYPE.DEBIT_NOTE:
        columnConfigInfo = {
          columnConfig: debitColumnConfig,
          tableId: debitConfigTableId
        };
        break;
      default:
        break;
    }

    return columnConfigInfo;
  };

  /////////////////////////////////// custom fields - end //////////////////////////////////

  const setGSTExchangeRate = () => {
    if (
      tenantInfo.multicurrencyEnabled ||
      isGSTExchangeRateRequired(getTenantTaxSystem(), tenantInfo.currency)
    ) {
      if (COMPLAINCE_CURRENCY[AuthService.userDetails?.country]) {
        const currencyDetails = currencyExchangeRates.find(
          (x) =>
            x.currencyCode ===
            COMPLAINCE_CURRENCY[AuthService.userDetails?.country]
        );
        if (currencyDetails && formData.currentCurrency) {
          const gstExchangeRate = currencyDetails.currencyExchangeRate;
          setFormData({
            ...formData,
            gstExchangeRate: 1 / gstExchangeRate
          });
        }
      }
    }

    // Check for opening tax exchange rate popup
    if (
      !isTenantAndCurrencySg() &&
      showGstCurrencyTax() &&
      !Utility.isEmpty(contact)
    ) {
      let currencyCode = contact.currencyCode;
      if (isGSTExchangeRateRequired(getTenantTaxSystem(), currencyCode)) {
        setShowTaxExchangeRatePopup(true);
      }
    }
  };

  const isTenantAndCurrencySg = (): boolean => {
    return (
      tenantInfo &&
      tenantInfo.currency === CURRENCIES.SG &&
      tenantInfo.country === COUNTRY_CODES.SG
    );
  };

  const showGstCurrencyTax = () => {
    return isGSTExchangeRateRequired(
      tenantInfo && tenantInfo.country,
      tenantInfo && tenantInfo.currency
    );
  };

  const updateCreditAndDebitGstType = (document: any) => {
    let gstType: GST_TYPE | undefined = undefined;
    if (props.notesType === DOC_TYPE.CREDIT_NOTE) {
      let tenantState = document.shipFrom?.state;
      let state: any = '';
      if (Utility.isEmpty(document.shipTo?.placeOfSupply)) {
        state = document.shipTo?.state;
      } else {
        state = document.shipTo?.placeOfSupply;
      }

      gstType = Utility.checkSezForSales(
        state,
        contact.shippingAddress?.state as string,
        contact.customerType,
        tenantState
      );
    } else if (props.notesType === DOC_TYPE.DEBIT_NOTE) {
      let placeOfSupply: any = !Utility.isEmpty(
        document.shipFrom?.placeOfSupply
      )
        ? document.shipFrom?.placeOfSupply
        : document.shipFrom?.state;
      let DestinationState: any = document.shipTo?.state;
      if (Utility.isEmpty(document.shipTo?.destinationOfSupply)) {
        DestinationState = document.shipTo?.state;
      } else {
        DestinationState = document.shipTo?.destinationOfSupply;
      }

      gstType = Utility.getVendorGstType(
        placeOfSupply,
        contact.vendorType,
        contact.gstTreatment,
        false,
        DestinationState
      );
    }
    setGstType(gstType);
  };

  const getComplianceConfig = () => {
    switch (tenantInfo.country) {
      case COUNTRY_CODES.IN:
        setHasTaxGroup(true);
        break;

      case COUNTRY_CODES.US:
        setHasTaxGroup(false);
        break;

      case COUNTRY_CODES.SG:
        setHasTaxGroup(true);
        break;

      case COUNTRY_CODES.MY:
        setHasTaxGroup(false);
        break;

      case COUNTRY_CODES.ID:
        setHasTaxGroup(false);
        break;

      case COUNTRY_CODES.PH:
        setHasTaxGroup(false);
        break;

      case COUNTRY_CODES.NZ:
        setHasTaxGroup(true);
        break;

      case COUNTRY_CODES.DE:
        setHasTaxGroup(true);
        break;

      case COUNTRY_CODES.AE:
        setHasTaxGroup(false);
        break;
      case COUNTRY_CODES.UK:
        setHasTaxGroup(true);
        break;
      case COUNTRY_CODES.BE:
        setHasTaxGroup(true);
        break;
      case COUNTRY_CODES.AU:
        setHasTaxGroup(true);
        break;
      case COUNTRY_CODES.CA:
        setHasTaxGroup(true);
        break;

      default:
        break;
    }
  };

  const addEmptyRowForValidation = (check: boolean) => {
    if (
      formData?.lineItemsData?.items?.length === 0 &&
      !Utility.isEmpty(contact)
    ) {
      addNewItem();
    }
  };

  const registerInteractions = () => {
    /*
     * register parents calls to child methods
     */
    if (props.passingInteraction)
      props.passingInteraction({
        type: POPUP_CALLBACKS_TYPE.CREATE_DEBIT,
        data: () => {
          setSubmitButtonTapped(true);
          addEmptyRowForValidation(true);
          createDebit();
        }
      });
    props.passingInteraction({
      type: POPUP_CALLBACKS_TYPE.UPDATE_DEBIT,
      data: () => {
        setSubmitButtonTapped(true);
        addEmptyRowForValidation(true);
        updateDebit();
      }
    });
    props.passingInteraction({
      type: POPUP_CALLBACKS_TYPE.CREATE_CREDIT,
      data: () => {
        setSubmitButtonTapped(true);
        addEmptyRowForValidation(true);
        createCredit();
      }
    });
    props.passingInteraction({
      type: POPUP_CALLBACKS_TYPE.UPDATE_CREDIT,
      data: () => {
        setSubmitButtonTapped(true);
        addEmptyRowForValidation(true);
        updateCredit();
      }
    });
  };

  const validateForm = () => {
    if (
      Utility.isEmpty(formData.documentSequenceCode) &&
      Utility.isEmpty(formData.sequenceFormat) &&
      formData.manualMode
    ) {
      return false;
    }

    if (Utility.isEmpty(contact)) {
      return false;
    }

    //yyyy-mm-dd
    const bbDate = DateFormatService.getDateFromStr(
      tenantInfo.bookBeginningStartDate,
      BOOKS_DATE_FORMAT['YYYY-MM-DD']
    );

    if (currentDate < bbDate) {
      let buttons = [
        {
          title: 'Ok',
          className: 'bg-button text-white',
          onClick: () => {}
        }
      ];
      showAlert(
        'Please Note!',
        `Date selected should be greater than books beginning date <div class="fw-b">(${DateFormatService.getFormattedDateString(
          tenantInfo.bookBeginningStartDate,
          BOOKS_DATE_FORMAT['YYYY-MM-DD'],
          BOOKS_DATE_FORMAT['DD/MM/YYYY']
        )}).</div>`,
        buttons
      );
      return false;
    }
    if (!Utility.checkClosingDate(currentDate, 'Document date')) {
      return false;
    }

    if (
      !Utility.checkActiveDateRangeValidation(
        currentDate,
        tenantInfo,
        'Document date',
        props.notesType
      )
    ) {
      return false;
    }

    if (Utility.isEmpty(formData.lineItemsData.items)) {
      return false;
    }

    if (
      !Utility.isEmpty(
        formData.lineItemsData.items.filter((item: any) => {
          return Utility.isEmpty(item.lineSelectedAccount);
        })
      )
    ) {
      return false;
    }

    if (
      selectedDocument && (selectedDocument.dueAmount < formData.lineSummary?.lineLineAmountTotal)
    ) {
      let noteType = props.notesType === DOC_TYPE.DEBIT_NOTE? "debit" : "credit"
      let message = "The total amount of the " + noteType + " note exceeds the amount due on the linked document."
      showAlert(
        'Error', message
      );
      return false;
    }

    if (
      !Utility.isEmpty(
        formData.lineItemsData.items.filter((item: any) => {
          return (
            item.lineAmount === null ||
            item.lineAmount === undefined ||
            item.lineAmount === ''
          );
        })
      )
    ) {
      return false;
    }

    // Custom fields validation
    const customFieldHasErrors = customFieldsContainsErrors(
      formData.customField
    );
    if (customFieldHasErrors) {
      return false;
    }
    // Custom fields validation ends

    return true;
  };

  const getLineItemCFs = (lineItem: any) => {
    let oldColConfigs = [...columnConfig];
    let colConfigsWithOnlyCF = oldColConfigs.filter(
      (item: any) => item.isCustomField
    );
    let newCfs: any[] = [];
    if (!Utility.isEmpty(selectCustomFieldsData?.content)) {
      colConfigsWithOnlyCF.forEach((colConfigItem: any) => {
        const cf: any = selectCustomFieldsData?.content.find(
          (cfItem: any) => colConfigItem.id === cfItem.id
        );
        if (typeof cf !== 'undefined' && cf !== null) {
          let cfValue;
          if (cf.fieldType.toLowerCase() === INPUT_TYPE.DATE.toLowerCase()) {
            cfValue = DateFormatService.getDateStrFromDate(
              lineItem[cf.id],
              BOOKS_DATE_FORMAT['MM/DD/YYYY']
            );
          } else if (cf.fieldType.toLowerCase() === 'user') {
            const tempCF = cf?.attributes?.find(
              (attr: any) => attr.code === lineItem[cf.id]?.code
            );
            if (tempCF) {
              cfValue = tempCF.code;
            }
          } else if (
            cf.fieldType.toLowerCase() === INPUT_TYPE.DROPDOWN.toLowerCase()
          ) {
            cfValue = lineItem[cf.id] ? lineItem[cf.id].value : '';
          } else {
            cfValue = lineItem[cf.id] ? lineItem[cf.id] : '';
          }

          newCfs.push({
            id: cf.id,
            code: cf.code,
            label: cf.label,
            module: 'ACCOUNT',
            shortName: cf.shortName,
            value: cfValue
          });
        }
      });
    }

    return newCfs;
  };

  const getFormattedPayload = () => {
    let lineItemsPayload: any[] = [];
    formData.lineItemsData.items.forEach((item: any, index: number) => {
      let tmpLineTax: any[] = [];
      if (
        item.lineSelectedTax.taxGroupDetails &&
        item.lineSelectedTax.isTaxGroup &&
        item.lineSelectedTax.taxGroupDetails.length > 0
      ) {
        item.lineSelectedTax.taxGroupDetails.forEach((element: any) => {
          let taxAmount = item.lineAmount ? item.lineAmount : 0;
          taxAmount = (taxAmount * element.percentage) / 100;
          let tax = {
            taxAmount: taxAmount,
            taxId: element.id,
            taxName: element.name,
            taxRate: element.percentage,
            taxCode: element.code,
            taxSeqCode: element.code,
            taxType: element.type,
            taxApplyTo: element.applyTo,
            additionalTaxIn: element.additionalTaxIn,
            taxableAmount: item.lineAmount
          };
          tmpLineTax.push(tax);
        });
      }
      if (item.lineSelectedTax && !item.lineSelectedTax.isTaxGroup) {
        let tax = {
          taxAmount: item.lineTaxAmount,
          taxId: item.lineSelectedTax.id,
          taxName: item.lineSelectedTax.name,
          taxRate: item.lineSelectedTax.percent,
          taxCode: item.lineSelectedTax.taxCode,
          taxSeqCode: item.lineSelectedTax.code,
          taxType: item.lineSelectedTax.type,
          additionalTaxIn: item.lineSelectedTax.additionalTaxIn,
          taxableAmount: item.lineAmount
        };
        tmpLineTax.push(tax);
      }
      lineItemsPayload[index] = {
        accountCode: item.lineSelectedAccount.code,
        amount: item.lineAmount,
        taxAmount: item.lineTaxAmount,
        taxCode: item.lineSelectedTax.code,
        taxList: tmpLineTax,
        amountDue: 0,
        customField: getLineItemCFs(item)
      };
    });

    const conRate = 1 / Number(conversionRate);

    const payload = {
      attachments: null,
      attachmentIds: null,
      currency: formData.currentCurrency,
      contactCode: contact && contact.code ? contact.code : '',
      contact: {
        address: 'N/A',
        name: contact && contact.name ? contact.name : ''
      },
      currencyExchangeRate: conRate,
      sequenceFormat: formData.sequenceFormat,
      documentSequenceCode: formData.documentSequenceCode,
      memo: formData.memoText,
      unitPriceGstInclusive: false,
      gstExchangeRate: formData.gstExchangeRate,
      customField: formData.customField,
      lineItems: lineItemsPayload,
      cnDate:
        props.notesType === DOC_TYPE.CREDIT_NOTE
          ? DateFormatService.getDateStrFromDate(
              currentDate,
              BOOKS_DATE_FORMAT['DD-MM-YYYY']
            )
          : undefined,
      dnDate:
        props.notesType === DOC_TYPE.DEBIT_NOTE
          ? DateFormatService.getDateStrFromDate(
              currentDate,
              BOOKS_DATE_FORMAT['DD-MM-YYYY']
            )
          : undefined,
      type: props.notesType === DOC_TYPE.DEBIT_NOTE ? 'PURCHASE' : 'SALES',
      reason: formData.reason ? formData.reason : '',
      customerTypeIndia: contact?.customerType,
      vendorTypeIndia: contact?.vendorType,
      gstTreatmentIndia: contact?.gstTreatment,
      gstIn: contact?.gstin
    };

    if (
      props.documentMode === DOCUMENT_MODE.NEW &&
      !Utility.isEmpty(payload.documentSequenceCode)
    ) {
      delete payload.sequenceFormat;
    }
    payload.customField = formData.customField;
    payload.attachmentIds = formData.attachmentIds;
    payload.attachments = formData.attachments;
    return payload;
  };

  const getFormattedPayloadWithInvoice = (payload: any) => {
    payload.billTo = formData.billTo
      ? formData.billTo
      : billingAddress?.currentBillingAddress;
    payload.shipTo = formData.shipTo
      ? formData.shipTo
      : shippingAddress?.currentShippingAddress;
    payload.companyAddress = getCompanyAddress();

    if (selectedDocument === undefined) {
      return payload;
    }

    payload.placeOfSupply = formData.placeOfSupply
      ? formData.placeOfSupply
      : pos && pos.value ? pos.value : '';
    payload.salesInvoiceCode = selectedDocument.salesInvoiceCode;

    var salesReturnCode = '';
    if (
      notesData?.salesReturnCode !== undefined &&
      notesData?.salesReturnCode !== null
    ) {
      salesReturnCode = payload.salesReturnCode;
    } else if (notesData?.salesReturn?.salesReturnCode !== undefined) {
      salesReturnCode = notesData?.salesReturn?.salesReturnCode;
    }
    payload.salesReturnCode = salesReturnCode;
    var productList: any[] = [];
    var amount = 0;
    if (productRows !== undefined && productRows !== null) {
      if (productRows.length > 0) {
        productRows.forEach((element) => {
          amount = amount + (element.totalAmount ? element.totalAmount : 0);
          productList.push({
            discount: element.discount,
            discountInPercentFlg: element.discountInPercent,
            productCode: element.productCode,
            productDescription: element.productDescription,
            productOrder: element.lineNumber,
            productQuantity: element.productQuantity,
            taxAmount: element.taxAmount,
            taxCode: element.taxCode,
            totalAmount: element.totalAmount,
            unitPrice: element.unitPrice,
            inCessAmount: element.cessAmount,
            inCessRate: element.cessPercentage,
            inCessRule: element.cessRule,
            inCgstAmount: element.cgstAmount,
            inCgstRate: element.cgstRate,
            inIgstAmount: element.igstAmount,
            inIgstRate: element.igstRate,
            inSgstAmount: element.sgstAmount,
            inSgstRate: element.sgstRate,
            inHsnOrSacCode: element.hsnOrSacCode
          });
        });
      }
    }

    payload.creditNoteProducts = productList;

    //compare the same products are using parent document
    if (
      selectedDocument.salesInvoiceItems &&
      selectedDocument.salesInvoiceItems !== null
    ) {
      if (selectedDocument.salesInvoiceItems.length > 0) {
        let totalAmountList = selectedDocument.salesInvoiceItems.map(
          (element: any) => element.totalAmount
        );
        var totalAmount = totalAmountList.reduce(
          (a: number, b: number) => a + b
        );
        if (totalAmount === amount) {
          if (
            formData.lineSummary.lineLineAmountTotal ===
            amount + selectedDocument.roundOffAmountInDocumentCurrency
          ) {
            amount = amount + selectedDocument.roundOffAmountInDocumentCurrency;
          }
        }
      }
    }

    if (islineItemPercentage) {
      amount = formData.lineSummary.lineLineAmountTotal;
    }

    //fix decimal place caused by line item calculation
    if (formData.lineSummary?.total > 0) {
      if (amount > formData.lineSummary?.total) {
        amount = formData.lineSummary?.total;
      }
    }

    var linkedDocuments = [
      {
        amount: amount,
        currency: formData.currentCurrency,
        documentCode: selectedDocument.salesInvoiceCode,
        documentSeqCode: selectedDocument.documentSequenceCode,
        documentType: DOC_TYPE.INVOICE,
        exchangeRate: formData.gstExchangeRate,
        knockOffDate: payload.cnDate
      }
    ];
    payload.linkedDocuments = linkedDocuments;
    return payload;
  };

  const getFormattedPayloadWithBill = (payload: any) => {
    payload.billTo = formData.billTo
      ? formData.billTo
      : billingAddress?.currentBillingAddress;
    payload.shipTo = formData.shipTo
      ? formData.shipTo
      : shippingAddress?.currentShippingAddress;
    payload.companyAddress = getCompanyAddress();

    if (selectedDocument === undefined) {
      return payload;
    }

    payload.placeOfSupply = formData.placeOfSupply
      ? formData.placeOfSupply
      : pos.value;
    payload.destinationOfSupply = dos.value;

    if (selectedDocumentType === DOC_TYPE.BILL) {
      payload.purchaseInvoiceCode = selectedDocument.purchaseInvoiceCode;
    } else if (selectedDocumentType === DOC_TYPE.INVOICE) {
      payload.salesInvoiceCode = selectedDocument.salesInvoiceCode;
    }

    var purchaseReturnCode = '';
    if (
      notesData?.purchaseReturnCode !== undefined &&
      notesData?.purchaseReturnCode !== null
    ) {
      purchaseReturnCode = payload.purchaseReturnCode;
    } else if (notesData?.purchaseReturn?.purchaseReturnCode !== undefined) {
      purchaseReturnCode = notesData?.purchaseReturn?.purchaseReturnCode;
    }

    payload.purchaseReturnCode = purchaseReturnCode;
    var productList: any[] = [];
    var amount = 0;
    if (productRows !== undefined && productRows !== null) {
      if (productRows.length > 0) {
        productRows.forEach((element) => {
          amount = amount + (element.totalAmount ? element.totalAmount : 0);
          productList.push({
            discount: element.discount,
            discountInPercentFlg: element.discountInPercent,
            productCode: element.productCode,
            productDescription: element.productDescription,
            productOrder: element.lineNumber,
            productQuantity: element.productQuantity,
            taxAmount: element.taxAmount,
            taxCode: element.taxCode,
            totalAmount: element.totalAmount,
            unitPrice: element.unitPrice,
            inCessAmount: element.cessAmount,
            inCessRate: element.cessPercentage,
            inCessRule: element.cessRule,
            inCgstAmount: element.cgstAmount,
            inCgstRate: element.cgstRate,
            inIgstAmount: element.igstAmount,
            inIgstRate: element.igstRate,
            inSgstAmount: element.sgstAmount,
            inSgstRate: element.sgstRate,
            inHsnOrSacCode: element.hsnOrSacCode
          });
        });
      }
    }

    if (selectedDocumentType === DOC_TYPE.BILL) {
      payload.debitNoteProducts = productList;
    } else if (selectedDocumentType === DOC_TYPE.INVOICE) {
      payload.debitNoteProducts = productList;
    }

    //compare the same products are using parent document
    if (
      selectedDocument.purchaseInvoiceProducts &&
      selectedDocument.purchaseInvoiceProducts !== null
    ) {
      if (selectedDocument.purchaseInvoiceProducts.length > 0) {
        let totalAmountList = selectedDocument.purchaseInvoiceProducts.map(
          (element: any) => element.totalAmount
        );
        var totalAmount = totalAmountList.reduce(
          (a: number, b: number) => a + b
        );
        if (totalAmount === amount) {
          if (
            formData.lineSummary.lineLineAmountTotal ===
            amount + selectedDocument.roundOffAmountInDocumentCurrency
          ) {
            amount = amount + selectedDocument.roundOffAmountInDocumentCurrency;
          }
        }
      }
    }

    if (islineItemPercentage) {
      amount = formData.lineSummary.lineLineAmountTotal;
    }

    //fix decimal place caused by line item calculation
    if (formData.lineSummary?.total > 0) {
      if (amount > formData.lineSummary?.total) {
        amount = formData.lineSummary?.total;
      }
    }

    var linkedDocuments = [
      {
        amount: amount,
        currency: formData.currentCurrency,
        documentCode:
          selectedDocumentType === DOC_TYPE.BILL
            ? selectedDocument.purchaseInvoiceCode
            : selectedDocument.salesInvoiceCode,
        documentSeqCode: selectedDocument.documentSequenceCode,
        documentType: selectedDocumentType,
        exchangeRate: formData.gstExchangeRate,
        knockOffDate: payload.dnDate
      }
    ];
    payload.linkedDocuments = linkedDocuments;
    payload.type =
      selectedDocumentType === DOC_TYPE.BILL ? 'PURCHASE' : 'SALES';
    return payload;
  };

  const checkIfReasonExist = (payload: any) => {
    if (
      payload.reason !== undefined &&
      payload.reason !== null &&
      payload.reason !== ''
    ) {
      return true;
    }
    return false;
  };

  const showAlertForReason = (isShow: boolean) => {
    if (isShow) {
      showAlert('Warning', 'Please select a reason before save this document.');
    }
  };

  const getAdditionalSelectedDocumentInfo = (document: any) => {
    if (props.documentMode === DOCUMENT_MODE.NEW) {
      var shipTo = undefined;

      if (
        shippingAddress?.currentShippingAddress !== undefined &&
        shippingAddress?.currentShippingAddress !== null
      ) {
        shipTo = deepClone(shippingAddress?.currentShippingAddress);
      }

      if (!Utility.isEmpty(contact)) {
        document.contact = contact;
      }
      if (isBillOrOrder) {
        if (shipTo) {
          shipTo.destinationOfSupply = '';
          if (dos !== undefined && dos !== null) {
            shipTo.destinationOfSupply = dos.value;
          }
        }
        document.shipTo = shipTo;
        document.shipFrom = deepClone(getCompanyAddress());
        if (document.shipFrom) {
          document.shipFrom.placeOfSupply = '';
          if (pos !== undefined && pos !== null) {
            document.shipFrom.placeOfSupply = pos.value;
          }
        }
        document.documentType = DOC_TYPE.BILL;
      } else {
        if (shipTo) {
          if (pos !== undefined && pos !== null) {
            shipTo.placeOfSupply = pos.value;
          }
        }
        document.shipTo = shipTo;
        document.shipFrom = deepClone(getCompanyAddress());

        document.documentType = DOC_TYPE.INVOICE;
      }
    } else {
      document.documentType = selectedDocumentType;
    }

    return document;
  };

  const createDebit = () => {
    const validated = validateForm();
    if (!validated) {
      return;
    }
    var payload = getFormattedPayload();
    payload = getFormattedPayloadWithBill(payload);

    let isExist = checkIfReasonExist(payload);

    if (!isExist) {
      showAlertForReason(!isExist);
      return;
    }
    if (payload) {
      if (props.passingInteraction) {
        props.passingInteraction({
          type: POPUP_CALLBACKS_TYPE.API_CALL_IN_PROGRESS
        });
      }
      DebitService.createDebitNote(payload)
        .then(
          (res) => {
            dispatch(fetchDebit());
            props.passingInteraction({ type: POPUP_CLICK_TYPE.CLOSE_POPUP });
          },
          (err) => {
            props.passingInteraction({
              type: POPUP_CALLBACKS_TYPE.API_CALL_STOP_PROGRESS
            });
          }
        )
        .catch((error) => {
          props.passingInteraction({
            type: POPUP_CALLBACKS_TYPE.API_CALL_STOP_PROGRESS
          });
        });
    }
  };

  const updateDebit = () => {
    const validated = validateForm();

    if (!validated) {
      return;
    }

    var payload = getFormattedPayload();
    payload = getFormattedPayloadWithBill(payload);

    let isExist = checkIfReasonExist(payload);
    if (!isExist) {
      showAlertForReason(!isExist);
      return;
    }

    if (payload) {
      if (props.passingInteraction) {
        props.passingInteraction({
          type: POPUP_CALLBACKS_TYPE.API_CALL_IN_PROGRESS
        });
      }
      DebitService.updateDebitNote(formData.id, payload)
        .then(
          (res) => {
            dispatch(fetchDebit());
            props.passingInteraction({ type: POPUP_CLICK_TYPE.CLOSE_POPUP });
          },
          (err) => {
            props.passingInteraction({
              type: POPUP_CALLBACKS_TYPE.API_CALL_STOP_PROGRESS
            });
          }
        )
        .catch((error) => {
          props.passingInteraction({
            type: POPUP_CALLBACKS_TYPE.API_CALL_STOP_PROGRESS
          });
        });
    }
  };

  const createCredit = () => {
    const validated = validateForm();
    if (!validated) {
      return;
    }
    var payload = getFormattedPayload();
    payload = getFormattedPayloadWithInvoice(payload);
    let isExist = checkIfReasonExist(payload);
    if (!isExist) {
      showAlertForReason(!isExist);
      return;
    }

    if (payload) {
      if (props.passingInteraction) {
        props.passingInteraction({
          type: POPUP_CALLBACKS_TYPE.API_CALL_IN_PROGRESS
        });
      }
      CreditNoteService.createCreditNote(payload)
        .then(
          (res) => {
            dispatch(fetchCreditNotes());
            props.passingInteraction({ type: POPUP_CLICK_TYPE.CLOSE_POPUP });
          },
          (err) => {
            props.passingInteraction({
              type: POPUP_CALLBACKS_TYPE.API_CALL_STOP_PROGRESS
            });
          }
        )
        .catch((error) => {
          props.passingInteraction({
            type: POPUP_CALLBACKS_TYPE.API_CALL_STOP_PROGRESS
          });
        });
    }
  };

  const updateCredit = () => {
    const validated = validateForm();
    if (!validated) {
      return;
    }
    var payload = getFormattedPayload();
    payload = getFormattedPayloadWithInvoice(payload);

    let isExist = checkIfReasonExist(payload);
    if (!isExist) {
      showAlertForReason(!isExist);
      return;
    }

    if (payload) {
      if (props.passingInteraction) {
        props.passingInteraction({
          type: POPUP_CALLBACKS_TYPE.API_CALL_IN_PROGRESS
        });
      }
      CreditNoteService.updateCreditNote(formData.id, payload)
        .then(
          (res) => {
            dispatch(fetchCreditNotes());
            props.passingInteraction({ type: POPUP_CLICK_TYPE.CLOSE_POPUP });
          },
          (err) => {
            props.passingInteraction({
              type: POPUP_CALLBACKS_TYPE.API_CALL_STOP_PROGRESS
            });
          }
        )
        .catch((error) => {
          props.passingInteraction({
            type: POPUP_CALLBACKS_TYPE.API_CALL_STOP_PROGRESS
          });
        });
    }
  };

  const updateCurrencyExchangeRate = (symbol: any) => {
    let filtered = currency.filter((item) => {
      return item.currencyCode === symbol;
    });
    if (filtered.length > 0) {
      let first = filtered[0];
      setFormData({
        ...formData,
        exchangeRate: first.currencyExchangeRate
      });
    } else {
      return null;
    }
  };

  const loadAccounts = async () => {
    try {
      const data = await dispatch(fetchAccoutnsList());
      const payload: any = data.payload;
      dispatch(addAccounts(payload)); // save in redux store
    } catch (err) {
      console.error('Error loading tenant info: ', err);
    }
  };

  const getLeftPosForShippingAddress = () => {
    if (shippingAddressBlock) {
      const popup = shippingAddressBlock.current?.closest('.popup-screen');
      if (popup) {
        const popupLeft = popup.getBoundingClientRect().left;
        const rect = shippingAddressBlock.current?.getBoundingClientRect();
        return rect.left - popupLeft + 5;
      } else {
        return 198;
      }
    } else {
      return 198;
    }
  };

  const getBottomPosForShippingAddress = () => {
    if (shippingAddressBlock) {
      const popup = shippingAddressBlock.current?.closest('.shipping-address');
      if (popup) {
        return popup.getBoundingClientRect().bottom - 80;
      } else {
        return undefined;
      }
    } else {
      return undefined;
    }
  };

  const getContactPicker = (item: string) => {
    const contactList =
      contactsData?.content?.filter(
        (contact: any) => contact.status.toLowerCase() === STATUS_TYPE.active
      ) || null;

    const contactMargin =
      selectedAddressType === BOOKS_ADDRESS_TYPES.SHIPPING_ADDRESS ||
      selectedAddressType === 'COMPANY'
        ? 'mt-l'
        : '';

    var left = 0;
    if (selectedAddressType === 'COMPANY') {
      left = 20;
    } else {
      left = [
        BOOKS_ADDRESS_TYPES.NONE,
        BOOKS_ADDRESS_TYPES.BILLING_ADDRESS
      ].includes(booksAddressType)
        ? 0
        : getLeftPosForShippingAddress();
    }

    return openContactSelector ? (
      <DKListPicker2
        data={contactList}
        className={
          'position-absolute z-index-3 bg-white border-m ' + contactMargin
        }
        style={{
          left: left,
          minWidth: 200
        }}
        renderer={(index: number, contactObj: any) => contactObj.name}
        onSelect={(index: number, contactObj: any) => {
          setFormData({
            ...formData,
            currentCurrency: contactObj.currencyCode
          });
          setSelectedDocument(undefined);
          setContact(contactObj);
          // setProductRows([]);
          // setDetailedContact(contactObj.id);
          setOpenContactSelector(false);
        }}
        onClose={() => setTimeout(() => setOpenContactSelector(false), 100)}
        allowSearch={true}
        searchApiConfig={{
          getUrl: (searchValue: string) => {
            const config: ContactAPIConfig = {
              ...ContactService.apiConfig,
              Page: 0,
              SearchTerm: searchValue,
              Limit: 20,
              IncludeOpeningAmounts: false,
              IncludeOweAmounts: false,
              Query: 'status=active'
            };

            ContactService.apiConfig = config;

            return ContactService.getContactsApiUrl();
          },
          dataParser: (response: any) => {
            return response?.content || [];
          },
          debounceTime: 300
        }}
        button={
          GranularPermissionsHelper.hasPermissionFor(
            PERMISSIONS_BY_MODULE.COA.CREATE
          )
            ? {
                title: '+ Add New',
                icon: DKIcons.ic_contact,
                className: 'bg-button text-white',
                style: {},
                onClick: () => {
                  setContactMode(DOCUMENT_MODE.NEW);
                  setShowAddContactPopup(true);
                }
              }
            : null
        }
      />
    ) : null;
  };

  const getLinkedDocumentsView = () => {
    return (
      <div
        className="row width-auto mb-2 justify-content-between"
        style={{ width: 300 }}
      >
        <div className="row width-auto">
          <DKIcon
            src={DKIcons.data_type.ic_url}
            className="ic-s"
            style={{ opacity: 0.6 }}
          />
          <DKLabel text={'Linked Document '} className={'fw-m ml-r'} />
        </div>
        <div className="row width-auto">
          <DKLabel
            text={`${
              formData?.linkedDocuments?.[0].documentType === DOC_TYPE.INVOICE
                ? 'Invoice'
                : 'Bill'
            } #${
              formData?.linkedDocuments?.[0].documentSeqCode
                ? formData?.linkedDocuments?.[0].documentSeqCode
                : formData?.linkedDocuments?.[0].documentCode
            } `}
            className={''}
          />
          <DKIcon
            src={DKIcons.ic_arrow_down2}
            className="ic-s cursor-hand"
            onClick={() => {
              props.showLinkedDocPopup();
            }}
          />
        </div>
      </div>
    );
  };

  const getCalendarView2 = (
    selectedDate: any,
    onSelect: any,
    toggleView: any
  ) => {
    return (
      <DKCalendar
        className="position-absolute bg-white border-m z-index-3 p-s border-radius-s shadow-m border-box"
        style={{ right: 0, top: 20 }}
        selectedDate={selectedDate}
        onSelectDate={(newDate: Date) => {
          // onSelect(newDate);
          if (
            Utility.checkActiveDateRangeValidation(
              newDate,
              tenantInfo,
              'Document date',
              props.notesType
            ) &&
            Utility.checkClosingDate(newDate, 'Document date')
          ) {
            setCurrentDate(newDate);
          }
          toggleView(false);
        }}
        onClose={() => setTimeout(() => toggleView(false))}
      />
    );
  };

  const getDocumentDateField = () => {
    return (
      <DKInput
        className={''}
        title="Document Date"
        canValidate={false}
        type={INPUT_TYPE.DATE}
        value={currentDate}
        titleStyle={{ color: 'gray' }}
        valueStyle={{ minHeight: 33 }}
        onChange={(date: Date) => {
          if (
            Utility.checkActiveDateRangeValidation(
              date,
              tenantInfo,
              'Document date',
              props.notesType
            ) &&
            Utility.checkClosingDate(date, 'Document date')
          ) {
            setCurrentDate(date);
          } else {
            setCurrentDate(currentDate);
          }
        }}
        datePickerConfig={{
          isDateRange: false
        }}
        direction={INPUT_VIEW_DIRECTION.VERTICAL}
        required={false}
        dateFormat={convertBooksDateFormatToUILibraryFormat(
          tenantDetails.dateFormat.toUpperCase()
        )}
      />
    );
  };

  const getCalendarView1 = () => {
    return (
      <div
        className="column position-relative parent-width"
        style={{ width: 300 }}
      >
        <div
          className="row parent-width mb-2 justify-content-between cursor-pointer"
          style={{ width: 300 }}
          onClick={() => {
            setDocDateOpen(!docDateOpen);
          }}
        >
          <div className="row width-auto">
            <DKIcon
              src={DKIcons.data_type.ic_date}
              className="ic-s"
              style={{ opacity: 0.6 }}
            />
            <DKLabel
              text={t(`ACCOUNTING.DEBIT_NOTES.DIALOG.DOCUMENT_DATE`)}
              className={'fw-m ml-r'}
            />
          </div>
          <DKLabel
            text={DateFormatService.getDateStrFromDate(
              currentDate,
              tenantDetails.dateFormat
                ? tenantDetails.dateFormat.toUpperCase()
                : BOOKS_DATE_FORMAT['DD-MM-YYYY']
            )}
            className={'ml-r '}
          />
        </div>
        {docDateOpen &&
          getCalendarView2(
            currentDate,
            (date: any) => {
              setCurrentDate(date);
            },
            setDocDateOpen
          )}
      </div>
    );
  };

  const getCurrencyFieldForFullScreen = () => {
    return (
      <DKInput
        title={'Currency'}
        titleStyle={{ color: 'gray' }}
        value={formData.currentCurrency}
        valueStyle={{
          minHeight: 33,
          whiteSpace: 'pre-wrap',
          wordBreak: 'break-word'
        }}
        canValidate={false}
        direction={INPUT_VIEW_DIRECTION.VERTICAL}
        type={INPUT_TYPE.DROPDOWN}
        required={false}
        readOnly={props.documentMode === DOCUMENT_MODE.VIEW}
        onChange={(obj: any) => {
          setFormData({
            ...formData,
            currentCurrency: obj.currencyCode
          });
          setShowMultiCurrencyList(false);
          setConversionRate(getRateWithoutFormat(obj));
          updateConfig();
        }}
        dropdownConfig={{
          title: 'Currencies',
          className: '',
          style: {},
          allowSearch: true,
          searchableKey: 'currencyName',
          data: currencyExchangeRates.filter((item: any) => {
            return item.currencyStatus === 'ACTIVE';
          }),
          renderer: (index: any, obj: any) => {
            return (
              <div className="row parent-width justify-content-between gap-2">
                <div>{obj.currencyName}</div>
                <div
                  style={{ minWidth: 40, textAlign: 'right' }}
                >{`(${obj.currencyCode})`}</div>
              </div>
            );
          },
          onSelect: (index: any, value: any) => {}
        }}
      />
    );
  };

  const getCurrencyView = () => {
    return (
      <div
        className="row width-auto mb-m justify-content-between"
        style={{
          width: 300
        }}
      >
        <div
          className="row width-auto cursor-hand"
          onClick={() => {
            setShowMultiCurrencyList(!showMultiCurrencyList);
          }}
        >
          <DKIcon
            src={DKIcons.ic_currency}
            className="ic-s"
            style={{ opacity: 0.6 }}
          />
          <DKLabel
            text={`${t(`ACCOUNTING.DEBIT_NOTES.DIALOG.CURRENCY`)}:`}
            className={'fw-m ml-r'}
          />
        </div>
        <div className="position-relative ">
          <DKButton
            title={formData.currentCurrency}
            className="bg-transparent text-black align-items-start fs-r"
            style={{
              paddingLeft: 0,
              paddingRight: 0,
              paddingTop: 0,
              paddingBottom: 0
            }}
            onClick={() => {
              setShowMultiCurrencyList(!showMultiCurrencyList);
            }}
            icon={tenantInfo.multicurrencyEnabled && DKIcons.ic_arrow_down2}
            isReverse
          />
          {tenantInfo.multicurrencyEnabled &&
            Utility.isEmpty(notesData) &&
            showMultiCurrencyList && (
              <DKListPicker2
                title="Currencies"
                data={currencyExchangeRates.filter((item: any) => {
                  return item.currencyStatus === 'ACTIVE';
                })}
                style={{
                  width: 280
                }}
                allowSearch={true}
                searchableKey="currencyName"
                className="position-absolute z-index-3 right-0 shadow-m"
                onSelect={(index: number, value: any) => {
                  setFormData({
                    ...formData,
                    currentCurrency: value.currencyCode
                  });
                  setShowMultiCurrencyList(false);
                  setConversionRate(getRateWithoutFormat(value));
                  updateConfig();
                }}
                onClose={() => {
                  setShowMultiCurrencyList(!showMultiCurrencyList);
                }}
                renderer={(index: number, obj: any) => {
                  return (
                    <div className="row parent-width justify-content-between">
                      <div>{obj.currencyName}</div>
                      <div className="ml-s">{`(${obj.currencyCode})`}</div>
                    </div>
                  );
                }}
              />
            )}
        </div>
      </div>
    );
  };

  const getDocumentListView = (type: DOC_TYPE) => {
    const documentList = type === DOC_TYPE.INVOICE ? invoiceList : billList;
    const title = type === DOC_TYPE.INVOICE ? '# Link Invoice' : '# Link Bill';
    const selectedDocSeqCode =
      selectedDocument && type === selectedDocumentType
        ? selectedDocument.documentSequenceCode
        : '';

    return (
      <div style={{ width: 280 }}>
        <DKInput
          title={title}
          titleStyle={{ color: 'gray' }}
          placeholder="Select"
          value={selectedDocSeqCode}
          valueStyle={{
            minHeight: 33,
            whiteSpace: 'pre-wrap',
            wordBreak: 'break-word'
          }}
          required={false}
          canValidate={false}
          direction={INPUT_VIEW_DIRECTION.VERTICAL}
          type={INPUT_TYPE.DROPDOWN}
          onChange={(value: any) => {
            if (value.documentSequenceCode === 'none') {
              setSelectedDocument(undefined);
            } else {
              setSelectedDocument(getAdditionalSelectedDocumentInfo(value));
            }
            setSelectedDocumentType(type);

            if (formData.lineItemsData.items.length === 1) {
              var newLineItemsData = formData.lineItemsData;
              var item = formData.lineItemsData.items[0];
              item.lineAmount = 0;
              item.lineLineAmount = 0;
              newLineItemsData.items = [item];
              setFormData({
                ...formData,
                lineItemsData: newLineItemsData
              });
            }
          }}
          dropdownConfig={{
            className: '',
            style: {},
            allowSearch: true,
            searchableKey: 'documentSequenceCode',
            data: documentList,
            renderer: (index: any, obj: any) => {
              return (
                <div className="row parent-width justify-content-between">
                  <div>{obj.documentSequenceCode}</div>
                  <div className="ml-s">{`${obj.currency} ${obj.dueAmount}`}</div>
                </div>
              );
            },
            onSelect: (index: any, value: any) => {}
          }}
        />
      </div>
    );
  };

  /**
   * Custom Numbering Format
   */
  const selectedFormat = (selected: any) => {
    /**
     * RECEIVE Selected format {id: "", text: ""}
     */

    if (selected.manualMode) {
      setFormData({
        ...formData,
        sequenceFormat: selected.id,
        documentSequenceCode: selected.text,
        manualMode: selected.manualMode
      });
    } else {
      if (selected.id) {
        setFormData({
          ...formData,
          sequenceFormat: selected.id,
          manualMode: selected.manualMode
        });
      }
    }
  };
  /**
   * Custom Numbering Format
   */

  const getAutoNumberingView = () => {
    let module_name = '';

    switch (props.notesType) {
      case DOC_TYPE.DEBIT_NOTE:
        module_name = CUSTOM_NUMBER_INPUT_MODULES.DEBIT_NOTE;
        break;
      case DOC_TYPE.CREDIT_NOTE:
        module_name = CUSTOM_NUMBER_INPUT_MODULES.CREDIT_NOTE;
        break;
    }

    if (module_name) {
      return (
        <div
          className={'row width-auto mb-2 justify-content-between'}
          style={{ width: isFullScreenMode ? 200 : 300 }}
        >
          <div className="row width-auto">
            <DKIcon
              src={DKIcons.data_type.ic_number}
              className="ic-xs-2"
              style={{ opacity: 0.6 }}
            />
            <DKLabel text={'No:'} className={'fw-m ml-r'} />
          </div>
          {isEditMode && (
            <DKLabel
              text={props.populateFormData.documentSequenceCode}
              className={'ml-r '}
            />
          )}
          {(props.documentMode === DOCUMENT_MODE.NEW ||
            props.documentMode === DOCUMENT_MODE.COPY) && (
            <div className="w-9/12 -mr-1">
              <CustomNumberFormatInput
                selectedFormat={selectedFormat}
                showCompact={true}
                extraClass={'top-12 right-0'}
                module={module_name}
              />
            </div>
          )}
        </div>
      );
    }
  };

  const getContactSelector = () => {
    return (
      <div className="column parent-width position-relative">
        <div
          className={`${
            Utility.isEmpty(contact) && submitButtonTapped
              ? 'bg-chip-red border-red text-red'
              : Utility.isEmpty(contact)
              ? 'bg-chip-blue border-blue text-blue'
              : 'bg-white border-gr text-black-light fw-b pl-0'
          } p-v-s p-h-r border-radius-s mt-s cursor-pointer overflow-hidden whitespace-nowrap`}
          style={{
            border: Utility.isEmpty(contact) ? 'dashed' : 'none',
            paddingLeft: !Utility.isEmpty(contact) ? 0 : 'none',
            borderWidth: Utility.isEmpty(contact) ? 1 : 0.5
          }}
          onClick={() => {
            if (!isEditMode) {
              setOpenContactSelector((prevValue) => !prevValue);
            }
          }}
        >
          {' '}
          <DKLabel text={`+ Add a Contact`} />
        </div>
        {booksAddressType === BOOKS_ADDRESS_TYPES.NONE &&
          openContactSelector &&
          getContactPicker('add new contact')}
      </div>
    );
  };

  const getUpdateOrgPopup = () => {
    const popupConfigForOrganisationProfile: BtnType[] = [
      {
        title: t(`SETTINGS.ORGANIZATION_PROFILE.BUTTON.CANCEL`),
        class: 'border-m mr-s',
        clickAction: POPUP_CLICK_TYPE.CLOSE_POPUP
      },
      {
        title: t(`SETTINGS.ORGANIZATION_PROFILE.BUTTON.UPDATE`),
        class: 'bg-app text-white mr-ss',
        clickAction: POPUP_CLICK_TYPE.UPDATE_ORGANISATION
      }
    ];
    return showUpdateOrgPopup ? (
      <PopupWrapper
        clickAction={catchClicks}
        type={POPUP_TYPE.POPUP}
        title={`Update ${getCapitalized(
          localizedText('organisation')
        )} Profile`}
        btnList={popupConfigForOrganisationProfile}
        width={'40%'}
        height={!isDesktop ? '75%' : '95%'}
        disableClickOutside
      >
        <OrganisationProfileForm
          passingInteraction={(callback: CallBackPayloadType) => {
            parentChildInteraction(callback);
          }}
          activeTab={activeOrgProfileTab}
        />
      </PopupWrapper>
    ) : null;
  };

  const updateBillingShippingAddress = (requireAddressCheck?: boolean) => {
    const billingAddressList: BooksAddress[] = isBillOrOrder
      ? tenantInfo.billingAddresses
      : contact?.billingAddress;

    const shippingAddressList: BooksAddress[] = isBillOrOrder
      ? tenantInfo.shippingAddresses
      : contact?.shippingAddress;

    let billingAdd: BooksAddress | undefined;
    let shippingAdd: BooksAddress | undefined;

    let canShowTenantAddressAlert = false;
    const complianceStatus = Utility.getTenantComplianceSettings(tenantInfo);
    if (
      !complianceStatus.complianceSetting &&
      (props.documentMode === DOCUMENT_MODE.NEW ||
        props.documentMode === DOCUMENT_MODE.COPY)
    ) {
      canShowTenantAddressAlert = true;
    }
    if (isBillOrOrder) {
      billingAdd =
        billingAddressList?.filter((address: any) => address.preferred)[0] ||
        (billingAddressList?.length ? billingAddressList[0] : null);

      if (notesData !== undefined && billingAdd !== null) {
        if (notesData.billTo) {
          billingAdd = notesData.billTo;
        }
      }

      shippingAdd =
        shippingAddressList?.filter((address: any) => address.preferred)[0] ||
        (shippingAddressList?.length ? shippingAddressList[0] : null);

      if (notesData !== undefined && shippingAdd !== null) {
        if (notesData.shipTo) {
          shippingAdd = notesData.shipTo;
        }
      }

      if (
        requireAddressCheck &&
        canShowTenantAddressAlert &&
        !shippingAddress
      ) {
        // showTenantAddressAlert();
      }
    } else {
      billingAdd = billingAddressList?.filter(
        (address) => address.preferred === true
      )[0];

      shippingAdd = shippingAddressList?.filter(
        (address) => address.preferred === true
      )[0];

      const tenantHasShippingAddress = Boolean(
        tenantInfo.shippingAddresses?.length
      );
      if (
        requireAddressCheck &&
        canShowTenantAddressAlert &&
        !tenantHasShippingAddress
      ) {
        showTenantAddressAlert();
      }
    }

    var newBillingAddress = billingAdd;
    var newShippingAddress = shippingAdd;

    if (billingAddress === null && props.documentMode !== DOCUMENT_MODE.NEW) {
      newBillingAddress = notesData.billTo as BooksAddress;
      setBillingAddress({
        billingAddress: billingAddressList,
        currentBillingAddress: notesData.billTo as BooksAddress
      });
    } else {
      setBillingAddress({
        billingAddress: billingAddressList,
        currentBillingAddress: billingAdd as BooksAddress
      });
    }

    if (shippingAddress === null && props.documentMode !== DOCUMENT_MODE.NEW) {
      newShippingAddress = notesData.shipTo as BooksAddress;
      setShippingAddress({
        shippingAddress: shippingAddressList,
        currentShippingAddress: notesData.shipTo as BooksAddress
      });
    } else {
      setShippingAddress({
        shippingAddress: shippingAddressList,
        currentShippingAddress: shippingAdd as BooksAddress
      });
    }

    return {
      billingAddress: newBillingAddress,
      shippingAddress: newShippingAddress
    };
  };

  const showTenantAddressAlert = () => {
    const alertButtonConfig = [
      {
        title: 'Cancel',
        className: 'bg-gray2 border-m ',
        onClick: () => {}
      },
      {
        title: 'Add address',
        className: 'bg-button text-white ml-r',
        onClick: () => setShowUpdateOrgPopup(true)
      }
    ];

    showAlert(
      `Tax Compliance`,
      `To calculate sales tax correctly, we would need your organisation's address where you collect tax.`,
      alertButtonConfig
    );
  };

  const getFormattedAddress = (address: any, isVendorType?: boolean) => {
    const { contactName, line1, line2, cityAndState, countryAndPostalCode } =
      getFormattedAddressObj(address);

    let formattedAddress = '';
    if (!Utility.isEmpty(contactName) && !isVendorType) {
      formattedAddress += contactName + ', <br/>';
    }
    if (!Utility.isEmpty(line1) && !isVendorType) {
      formattedAddress += line1 + ', ';
    }
    if (!Utility.isEmpty(line2) && !isVendorType) {
      formattedAddress += line2 + ', ';
    }

    if (isVendorType && !Utility.isEmpty(address.state)) {
      formattedAddress += address.state + ', ';
    } else if (!Utility.isEmpty(cityAndState)) {
      formattedAddress += cityAndState + ', ';
    }

    if (!Utility.isEmpty(countryAndPostalCode)) {
      formattedAddress += countryAndPostalCode;
    }

    return formattedAddress;
  };

  const updatePlaceOfSupplyOnAddress = (address: any) => {
    let pos = indianStatesOptions.filter(
      (ele: any) =>
        ele.value?.toLowerCase() === (address.state?.toLowerCase() || '')
    );
    let obj: any = { ...pos[0] };
    if (!isBillOrOrder) {
      setPos(pos[0]);
      onPlaceOfsupplyChange(obj, address);
    } else {
      setDos(pos[0]);
      onDestinationOfsupplyChange(obj, address);
    }
  };

  const updatePlaceOfSupplyOnAddressBuy = (address: any) => {
    let pos = indianStatesOptions.filter(
      (ele: any) =>
        ele?.value?.toLowerCase() === (address?.state?.toLowerCase() || '')
    );
    let obj: any = { ...pos[0] };
    setPos(pos[0]);
    onPlaceOfsupplyChange(obj, address);
  };

  const onPlaceOfsupplyChange = (value: any, address?: any) => {
    if (getTenantTaxSystem() === TAX_SYSTEM.INDIA_GST) {
      let tempDocument: any = { ...booksDocument };
      if (!isBillOrOrder) {
        let shipToAddress: any = {};
        if (!Utility.isEmpty(address)) {
          shipToAddress = {
            ...address,
            placeOfSupply: value.value
          };
        } else {
          shipToAddress = {
            ...tempDocument.shipTo,
            placeOfSupply: value.value
          };
        }
        const gstType = updateGstType({
          ...tempDocument,
          shipTo: shipToAddress
        });

        tempDocument = {
          ...tempDocument,
          shipTo: shipToAddress,
          gstType: gstType,
          placeOfSupply: value.value,
          items: tempDocument.items?.map((item: any) => {
            return { ...item, gstType: gstType };
          })
        };
      } else {
        let shipFromAddress: any = {};
        if (!Utility.isEmpty(address)) {
          shipFromAddress = {
            ...address,
            placeOfSupply: value.value
          };
        } else {
          shipFromAddress = {
            ...tempDocument.shipFrom,
            placeOfSupply: value.value
          };
        }
        let shipToAddress = {
          ...tempDocument.shipTo,
          destinationOfSupply: dos.value
        };
        const gstType = updateGstType({
          ...tempDocument,
          shipFrom: shipFromAddress,
          shipTo: shipToAddress
        });

        tempDocument = {
          ...tempDocument,
          shipFrom: shipFromAddress,
          shipTo: shipToAddress,
          gstType: gstType,
          items: tempDocument.items?.map((item: any) => {
            return { ...item, gstType: gstType };
          })
        };
      }

      setBooksDocument({
        ...tempDocument
      });
      // tempDocument.items.forEach((item: any, index: number) => {
      //   calculateTaxesAndAmount(index, tempDocument.items);
      // });
    }
  };

  const onDestinationOfsupplyChange = (value: any, address?: any) => {
    if (getTenantTaxSystem() === TAX_SYSTEM.INDIA_GST) {
      let tempDocument: any = { ...booksDocument };
      let shipToAddress: any = {};
      if (!Utility.isEmpty(address)) {
        shipToAddress = {
          ...address,
          destinationOfSupply: value.value
        };
      } else {
        shipToAddress = {
          ...tempDocument.shipTo,
          destinationOfSupply: value.value
        };
      }
      let shipFromAddress = {
        ...tempDocument.shipFrom,
        placeOfSupply: pos.value,
        destinationOfSupply: value.value
      };
      const gstType = updateGstType({
        ...tempDocument,
        shipTo: shipToAddress,
        shipFrom: shipFromAddress
      });
      tempDocument = {
        ...tempDocument,
        shipTo: shipToAddress,
        shipFrom: shipFromAddress,
        gstType: gstType,
        items: tempDocument.items?.map((item: any) => {
          return { ...item, gstType: gstType };
        })
      };
      setBooksDocument({
        ...tempDocument
      });
      // tempDocument.items.forEach((item: any, index: number) => {
      //   calculateTaxesAndAmount(index, tempDocument.items);
      // });
    }
  };

  const getBillingAddress = () => {
    var addressName = '';
    if (isBillOrOrder) {
      addressName = tenantInfo.name;
    } else {
      if (!Utility.isEmpty(contact)) {
        addressName = `${contact.name}-(${
          contact.currencyCode ? contact.currencyCode : tenantDetails.currency
        })`;
      }
    }

    var canEditContact =
      props.documentMode !== DOCUMENT_MODE.EDIT &&
      !isBillOrOrder &&
      GranularPermissionsHelper.hasPermissionFor(
        PERMISSIONS_BY_MODULE.CONTACTS.CREATE
      );

    const hasAddressCustomFields =
      Utility.isNotEmpty(billingAddress?.currentBillingAddress?.customFields) ||
      Utility.isNotEmpty(shippingAddress?.currentShippingAddress?.customFields);

    return (
      <div
        className="position-relative align-items-start"
        style={{
          minHeight: hasAddressCustomFields ? 100 : 0
        }}
      >
        <div
          className={`row ${
            canEditContact ? 'cursor-pointer listPickerBG' : ''
          }`}
          title={canEditContact ? 'Change contact' : ''}
          onClick={() => {
            if (canEditContact) {
              setActiveContactTab(CONTACT_FORM_TAB.GENERAL_INFO);
              setOpenContactSelector((prevValue) => !prevValue);
              setBooksAddressType(BOOKS_ADDRESS_TYPES.BILLING_ADDRESS);
              setSelectedAddressType(BOOKS_ADDRESS_TYPES.BILLING_ADDRESS);
            }
          }}
        >
          <DKLabel text={addressName} className="fw-m fs-r" />
        </div>
        {selectedAddressType === BOOKS_ADDRESS_TYPES.BILLING_ADDRESS &&
          openContactSelector &&
          getContactPicker('bill address')}
        <div
          className={`row ${
            !GranularPermissionsHelper.hasPermissionFor(
              PERMISSIONS_BY_MODULE.CONTACTS.CREATE
            )
              ? ''
              : 'cursor-pointer listPickerBG'
          }`}
          onClick={(e: any) => {
            if (
              GranularPermissionsHelper.hasPermissionFor(
                PERMISSIONS_BY_MODULE.CONTACTS.CREATE
              )
            ) {
              setOpenBillingAddressList(true);
            }
          }}
        >
          <DKLabel
            text={
              !Utility.isEmpty(billingAddress?.currentBillingAddress)
                ? getFormattedAddress(billingAddress?.currentBillingAddress)
                : ''
            }
          />
        </div>
        {openBillingAddressList && (
          <DKListPicker2
            data={
              isBillOrOrder
                ? tenantInfo.billingAddresses
                : billingAddress?.billingAddress
            }
            className="position-absolute z-index-3 bg-white border-m shadow-m"
            style={{ minWidth: 250 }}
            renderer={(index: number, addressObj: any) => (
              <div
                style={{
                  width: 200,
                  whiteSpace: 'pre-wrap',
                  textAlign: 'left'
                }}
                dangerouslySetInnerHTML={{
                  __html: getFormattedAddress(addressObj)
                }}
              >
                {/* {getFormattedAddress(addressObj)} */}
              </div>
            )}
            onEdit={(index: number, obj: any) => {}}
            button={{
              title: 'Manage address',
              className: 'text-white fw-m bg-button justify-content-center',
              onClick: () => {
                if (isBillOrOrder) {
                  setActiveOrgProfileTab(ORGANISATION_FORM_TAB.ADDRESS_INFO);
                  setShowUpdateOrgPopup(true);
                } else {
                  setActiveContactTab(CONTACT_FORM_TAB.ADDRESS_INFO);
                  setContactMode(DOCUMENT_MODE.EDIT);
                  setShowAddContactPopup(true);
                }
                setOpenBillingAddressList(false);
              }
            }}
            canEdit={false}
            onSelect={(index: number, addressObj: any) => {
              setBillingAddress({
                billingAddress:
                  billingAddress?.billingAddress as BooksAddress[],
                currentBillingAddress: addressObj
              });
              setFormData({
                ...formData,
                billTo: addressObj
              });
              setBooksDocument((prevState: any) => ({
                ...prevState,
                billTo: addressObj
              }));
              setOpenBillingAddressList(false);
              setIsBillToAddressUpdated(!isBillToAddressUpdated);
            }}
            onClose={() => {
              setOpenBillingAddressList(false);
            }}
            allowSearch={false}
          />
        )}
      </div>
    );
  };

  const getShippingAddress = () => {
    var addressName = '';
    if (isBillOrOrder) {
      addressName = tenantInfo.name;
    } else {
      if (!Utility.isEmpty(contact)) {
        addressName = `${contact.name}-(${
          contact.currencyCode ? contact.currencyCode : tenantDetails.currency
        })`;
      }
    }

    var canEditContact =
      props.documentMode !== DOCUMENT_MODE.EDIT &&
      !isBillOrOrder &&
      GranularPermissionsHelper.hasPermissionFor(
        PERMISSIONS_BY_MODULE.CONTACTS.CREATE
      );

    const hasAddressCustomFields =
      Utility.isNotEmpty(billingAddress?.currentBillingAddress?.customFields) ||
      Utility.isNotEmpty(shippingAddress?.currentShippingAddress?.customFields);

    return (
      <div
        className=" align-items-start"
        style={{
          minHeight: hasAddressCustomFields ? 100 : 0
        }}
      >
        <div
          className={`row align-items-start ${
            canEditContact ? 'cursor-pointer listPickerBG' : ''
          }`}
          title={canEditContact ? 'Change contact' : ''}
          onClick={() => {
            if (canEditContact && !isBillOrOrder) {
              setActiveContactTab(CONTACT_FORM_TAB.GENERAL_INFO);
              setOpenContactSelector(true);
              setBooksAddressType(BOOKS_ADDRESS_TYPES.SHIPPING_ADDRESS);
              setSelectedAddressType(BOOKS_ADDRESS_TYPES.SHIPPING_ADDRESS);
            }
          }}
        >
          <DKLabel text={addressName} className="fw-m fs-r" />
        </div>
        {selectedAddressType === BOOKS_ADDRESS_TYPES.SHIPPING_ADDRESS &&
          getContactPicker('ship add')}
        <div
          className={`row ${
            !GranularPermissionsHelper.hasPermissionFor(
              PERMISSIONS_BY_MODULE.CONTACTS.CREATE
            )
              ? ''
              : 'cursor-pointer listPickerBG'
          }`}
          onClick={(e: any) => {
            if (
              GranularPermissionsHelper.hasPermissionFor(
                PERMISSIONS_BY_MODULE.CONTACTS.CREATE
              )
            ) {
              setOpenShippingAddressList(true);
            }
          }}
        >
          <DKLabel
            text={
              !Utility.isEmpty(shippingAddress?.currentShippingAddress)
                ? getFormattedAddress(shippingAddress?.currentShippingAddress)
                : ''
            }
          />
        </div>
        {openShippingAddressList && (
          <DKListPicker2
            data={shippingAddress?.shippingAddress}
            className="position-absolute z-index-3 bg-white border-m shadow-m"
            style={{
              minWidth: 250,
              top: getBottomPosForShippingAddress()
            }}
            renderer={(index: number, addressObj: any) => (
              <div
                style={{
                  width: 200,
                  whiteSpace: 'pre-wrap',
                  textAlign: 'left'
                }}
                dangerouslySetInnerHTML={{
                  __html: getFormattedAddress(addressObj)
                }}
              >
                {/* {getFormattedAddress(addressObj)} */}
              </div>
            )}
            onEdit={(index: number, obj: any) => {}}
            button={{
              title: 'Manage address',
              className: 'text-white fw-m bg-button justify-content-center',
              onClick: () => {
                if (isBillOrOrder) {
                  setActiveOrgProfileTab(ORGANISATION_FORM_TAB.ADDRESS_INFO);
                  setShowUpdateOrgPopup(true);
                } else {
                  setActiveContactTab(CONTACT_FORM_TAB.ADDRESS_INFO);
                  setContactMode(DOCUMENT_MODE.EDIT);
                  setShowAddContactPopup(true);
                }
                setOpenShippingAddressList(false);
              }
            }}
            canEdit={false}
            onSelect={(index: number, addressObj: any) => {
              setShippingAddress({
                shippingAddress:
                  shippingAddress?.shippingAddress as BooksAddress[],
                currentShippingAddress: addressObj
              });
              setFormData({
                ...formData,
                shipTo: addressObj
              });
              if (getTenantTaxSystem() === TAX_SYSTEM.US) {
                setShipToOrFromAddressChangedForUS(true);
              }
              setBooksDocument((prevState: any) => ({
                ...prevState,
                shipTo: addressObj
              }));
              if (getTenantTaxSystem() === TAX_SYSTEM.INDIA_GST) {
                updatePlaceOfSupplyOnAddress(addressObj);
              }
              setOpenShippingAddressList(false);
              setIsShipToAddressUpdated(!isShipToAddressUpdated);
            }}
            onClose={() => {
              setOpenShippingAddressList(false);
            }}
            allowSearch={false}
          />
        )}
      </div>
    );
  };

  const getCompanySections = () => {
    return (
      <div className="row justify-content-between align-items-start ">
        {!Utility.isEmpty(customLocation) && isBillOrOrder
          ? getCustomCompanyDetails()
          : getCompanyDetails()}
        <div className="column align-items-end">Right section</div>
      </div>
    );
  };

  const getCustomCompanyDetails = (title?: string) => {
    const orgName = customLocation?.locationDetails?.title;
    const address = getFormattedAddress(
      customLocation?.locationDetails?.address,
      isBillOrOrder
    );
    const phone = customLocation?.locationDetails?.phone;
    const email = customLocation?.locationDetails?.email;

    return (
      <div
        className={`column`}
        style={{
          maxWidth: 250
        }}
      >
        <div className="column width-auto">
          <div className="row">
            <DKLabel text={title} className="fw-b fs-r text-gray" />
          </div>
          <div className={`row`}>
            <DKLabel text={orgName} className="fw-m fs-r" />
          </div>
          <DKLabel text={address} className="parent-width" />
          {phone && <DKLabel text={phone} className="parent-width" />}
          {email && <DKLabel text={email} className="parent-width" />}
        </div>
      </div>
    );
  };

  const getCompanyAddress = () => {
    var companyAddress: any =
      tenantInfo?.shippingAddresses?.[0] ?? tenantInfo?.address;

    if (formData.shipFrom !== undefined && formData.shipFrom !== null) {
      if (Utility.isNotEmpty(booksDocument.shipFrom)) {
        return { ...formData.shipFrom, ...booksDocument.shipFrom };
      } else {
        return formData.shipFrom;
      }
    }

    if (!isBillOrOrder) {
      if (booksDocument !== undefined && booksDocument !== null) {
        if (
          booksDocument.shipFrom !== undefined &&
          booksDocument.shipFrom !== null
        ) {
          companyAddress = booksDocument.shipFrom;
        }
      }
    } else {
      if (contact?.billingAddress?.length > 0) {
        companyAddress = contact?.billingAddress[0];
      } else {
        companyAddress = null;
      }
    }

    if (companyAddress === null) {
      if (props.documentMode !== DOCUMENT_MODE.NEW && notesData) {
        companyAddress = notesData.companyAddress;
      }
    }

    return companyAddress;
  };

  const getCompanyDetails = () => {
    const canEditContact =
      isBillOrOrder && props.documentMode !== DOCUMENT_MODE.EDIT;

    var companyAddress = getCompanyAddress();

    return (
      <div
        className={`column parent-block`}
        style={{
          maxWidth: 350,
          marginTop: canEditContact ? -10 : 0,
          marginLeft: canEditContact ? -10 : 0,
          padding: canEditContact ? 10 : 0
        }}
      >
        <div className="row width-auto">
          {isBillOrOrder && Utility.isEmpty(contact) ? (
            getContactSelector()
          ) : (
            <div
              className={`${
                canEditContact && !Utility.isEmpty(contact)
                  ? 'cursor-pointer border-box'
                  : ''
              }`}
              onClick={() => {
                if (canEditContact && !Utility.isEmpty(contact)) {
                  setOpenContactSelector((prevValue) => !prevValue);
                  setBooksAddressType(BOOKS_ADDRESS_TYPES.BILLING_ADDRESS);
                  setSelectedAddressType('COMPANY');
                }
              }}
            >
              <div
                className={`row justify-content-between ${
                  canEditContact ? 'listPickerBG' : ''
                }`}
              >
                <DKLabel
                  text={
                    isBillOrOrder
                      ? `${contact.name}${
                          contact.currencyCode
                            ? `-(${contact.currencyCode})`
                            : ''
                        }`
                      : tenantInfo.name
                  }
                  className="fw-m fs-r"
                />
              </div>
            </div>
          )}
        </div>
        {selectedAddressType === 'COMPANY' && getContactPicker('COMP')}
        {Utility.isEmpty(companyAddress) ? null : (
          // Ship from for Bill/Order
          <>
            {isBillOrOrder ? (
              <div>
                <div
                  title="Edit selected contact"
                  className="column width-auto listPickerBG cursor-pointer"
                  onClick={() => {
                    if (
                      isBillOrOrder &&
                      GranularPermissionsHelper.hasPermissionFor(
                        PERMISSIONS_BY_MODULE.CONTACTS.CREATE
                      )
                    ) {
                      setOpenShipFromForBuy(true);
                    }
                  }}
                >
                  <DKLabel
                    text={getFormattedAddress(companyAddress)}
                    className="parent-width"
                  />
                </div>
                {openShipFromForBuy && (
                  <DKListPicker2
                    data={contact?.billingAddress}
                    className="position-absolute z-index-3 bg-white border-m shadow-m"
                    style={{ minWidth: 250 }}
                    renderer={(index: number, addressObj: any) => (
                      <div
                        style={{
                          width: 200,
                          whiteSpace: 'pre-wrap',
                          textAlign: 'left'
                        }}
                        dangerouslySetInnerHTML={{
                          __html: getFormattedAddress(addressObj)
                        }}
                      ></div>
                    )}
                    onEdit={(index: number, obj: any) => {}}
                    button={{
                      title: 'Manage address',
                      className:
                        'text-white fw-m bg-button justify-content-center',
                      onClick: () => {
                        setActiveContactTab(CONTACT_FORM_TAB.ADDRESS_INFO);
                        setContactMode(DOCUMENT_MODE.EDIT);
                        setShowAddContactPopup(true);
                        setOpenShipFromForBuy(false);
                      }
                    }}
                    canEdit={false}
                    onSelect={(index: number, addressObj: any) => {
                      setBooksDocument((prevState: any) => {
                        return {
                          ...prevState,
                          shipFrom: addressObj
                        };
                      });
                      if (
                        getTenantTaxSystem() === TAX_SYSTEM.INDIA_GST &&
                        isBillOrOrder
                      ) {
                        updatePlaceOfSupplyOnAddressBuy(addressObj);
                      }
                      setFormData({
                        ...formData,
                        shipFrom: addressObj
                      });
                      setOpenShipFromForBuy(false);
                      setIsShipFromAddressUpdated(!isShipFromAddressUpdated);
                    }}
                    onClose={() => {
                      setOpenShipFromForBuy(false);
                    }}
                    allowSearch={false}
                  />
                )}
                {Utility.isNotEmpty(contact) &&
                  getAddressCustomFields(BOOKS_ADDRESS_TYPES.SHIP_FROM)}
              </div>
            ) : (
              // Ship from for Invoice/Quote
              <div>
                <div
                  title="Edit selected contact"
                  className="row width-auto listPickerBG cursor-pointer"
                  onClick={() => {
                    if (
                      !isBillOrOrder &&
                      GranularPermissionsHelper.hasPermissionFor(
                        PERMISSIONS_BY_MODULE.CONTACTS.CREATE
                      )
                    ) {
                      setOpenShipFromForBuy(true);
                    }
                  }}
                >
                  <DKLabel
                    text={getFormattedAddress(companyAddress)}
                    className="parent-width"
                  />
                </div>
                {openShipFromForBuy && (
                  <DKListPicker2
                    data={tenantInfo?.shippingAddresses}
                    className="position-absolute z-index-3 bg-white border-m shadow-m"
                    style={{ minWidth: 250 }}
                    renderer={(index: number, addressObj: any) => (
                      <div
                        style={{
                          width: 200,
                          whiteSpace: 'pre-wrap',
                          textAlign: 'left'
                        }}
                        dangerouslySetInnerHTML={{
                          __html: getFormattedAddress(addressObj)
                        }}
                      >
                        {/* {getFormattedAddress(addressObj)} */}
                      </div>
                    )}
                    onEdit={(index: number, obj: any) => {}}
                    button={{
                      title: 'Manage address',
                      className:
                        'text-white fw-m bg-button justify-content-center',
                      onClick: () => {
                        setActiveOrgProfileTab(
                          ORGANISATION_FORM_TAB.ADDRESS_INFO
                        );
                        setShowUpdateOrgPopup(true);
                        setOpenShipFromForBuy(false);
                      }
                    }}
                    canEdit={false}
                    onSelect={(index: number, addressObj: any) => {
                      setBooksDocument((prevState: any) => {
                        return {
                          ...prevState,
                          shipFrom: addressObj
                        };
                      });
                      if (getTenantTaxSystem() === TAX_SYSTEM.US) {
                        setShipToOrFromAddressChangedForUS(true);
                      }

                      if (
                        getTenantTaxSystem() === TAX_SYSTEM.INDIA_GST &&
                        isBillOrOrder
                      ) {
                        updatePlaceOfSupplyOnAddress(addressObj);
                      }
                      setFormData({
                        ...formData,
                        shipFrom: addressObj
                      });
                      setOpenShipFromForBuy(false);
                      setIsShipFromAddressUpdated(!isShipFromAddressUpdated);
                    }}
                    onClose={() => {
                      setOpenShipFromForBuy(false);
                    }}
                    allowSearch={false}
                  />
                )}
                {Utility.isNotEmpty(companyAddress?.customFields) &&
                  getAddressCustomFields(BOOKS_ADDRESS_TYPES.SHIP_FROM)}
              </div>
            )}
          </>
        )}
      </div>
    );
  };

  const getPlaceOfSupply = () => {
    return (
      <div
        style={{ width: isFullScreenMode ? '100%' : 150 }}
        className={`${isFullScreenMode ? '' : 'mb-m'}`}
      >
        <DKInput
          value={pos || ''}
          title={!isBillOrOrder ? 'Place of Supply' : 'Source of Supply'}
          direction={INPUT_VIEW_DIRECTION.VERTICAL}
          readOnly={true}
          formatter={(obj: any) => {
            return obj.label;
          }}
          className="parent-width mt-5"
          type={INPUT_TYPE.DROPDOWN}
          required={false}
          titleStyle={{
            color: 'gray'
          }}
          valueStyle={{ minHeight: 33 }}
          onChange={(value: any) => {
            setPos(value);
            let updatedState = {
              ...booksDocument,
              placeOfSupply: value.value
            };
            setFormData({
              ...formData,
              placeOfSupply: value.value
            });
            setBooksDocument({ ...updatedState });
            onPlaceOfsupplyChange(value);
          }}
          dropdownConfig={{
            allowSearch: true,
            searchableKey: 'label',
            data: indianStatesOptions,
            renderer: (index: any, obj: any) => {
              return (
                <div className="flex flex-row w-full justify-content-between">
                  <DKLabel
                    style={{ fontSize: '13px' }}
                    className="text-base border-radius-s"
                    text={obj.label}
                  />
                </div>
              );
            }
          }}
        />
      </div>
    );
  };

  const getSourceOfDestination = () => {
    return (
      <div style={{ width: 150 }}>
        <DKInput
          value={dos || ''}
          title={'Destination of Supply'}
          direction={INPUT_VIEW_DIRECTION.VERTICAL}
          readOnly={true}
          formatter={(obj: any) => {
            return obj.label;
          }}
          className="parent-width mt-5"
          type={INPUT_TYPE.DROPDOWN}
          required={false}
          titleStyle={{
            color: 'gray'
          }}
          onChange={(value: any) => {
            setDos(value);
            let updatedState = {
              ...booksDocument
            };
            setBooksDocument({ ...updatedState });
            onDestinationOfsupplyChange(value);
          }}
          dropdownConfig={{
            allowSearch: true,
            searchableKey: 'label',
            data: indianStatesOptions,
            renderer: (index: any, obj: any) => {
              return (
                <div className="flex flex-row w-full justify-content-between">
                  <DKLabel
                    style={{ fontSize: '13px' }}
                    className="text-base border-radius-s"
                    text={obj.label}
                  />
                </div>
              );
            }
          }}
        />
      </div>
    );
  };

  const getReasonPicker = () => {
    var reasonList = [
      'Sales/Purchase Return',
      'Post Sales/Purchase Discount',
      'Deficiency in Services',
      'Correction in Invoice',
      'Change in POS',
      'Finalization of provisional assessment',
      'Others'
    ];
    return (
      <div style={{ width: 280, marginTop: isFullScreenMode ? 0 : 18 }}>
        <DKInput
          value={formData.reason || ''}
          title={'Reason'}
          direction={INPUT_VIEW_DIRECTION.VERTICAL}
          formatter={(obj: any) => {
            return obj;
          }}
          className="parent-width"
          type={INPUT_TYPE.DROPDOWN}
          required={true}
          titleStyle={{
            color: 'gray'
          }}
          valueStyle={{
            minHeight: 33,
            whiteSpace: 'pre-wrap',
            wordBreak: 'break-word'
          }}
          onChange={(value: any) => {
            setFormData({
              ...formData,
              reason: value
            });
          }}
          dropdownConfig={{
            allowSearch: true,
            searchableKey: '',
            data: reasonList,
            renderer: (index: any, obj: any) => {
              return (
                <div className="flex flex-row w-full justify-content-between">
                  <DKLabel
                    style={{ fontSize: '13px' }}
                    className="text-base border-radius-s"
                    text={obj}
                  />
                </div>
              );
            }
          }}
        />
      </div>
    );
  };

  const getAddClassForm = () => (
    <AddClass
      data={null}
      onSuccess={() => {
        dispatch(fetchCategoryDimensions());
        dispatch(fetchClassesByDimensionId());
      }}
      onCancel={() => {
        setShowAddClassPopup(false);
      }}
    />
  );

  const hideClassColumn = () => {
    let hideClassCol = false;
    const classSettings = tenantInfo.additionalSettings?.CLASS;
    if (
      !classSettings?.trackClasses ||
      classSettings?.assignClasses === CLASS_ASSIGNMENT.TRANSACTION
    ) {
      hideClassCol = true;
    }
    return hideClassCol;
  };

  const updateConfig = () => {
    let config = columnConfig;

    var isEdit = true;

    if (
      formData.linkedDocuments !== undefined &&
      formData.linkedDocuments !== null
    ) {
      if (formData.linkedDocuments.length > 0) {
        isEdit = false;
      }
    }

    config.forEach((conf: any) => {
      switch (conf.key) {
        case 'lineSelectedAccount':
          let newData = Utility.getAccountsStructured(accountsData?.content);
          ConfigUtility.fillStructuredAccountArray(newData);
          let newStructuredData = ConfigUtility.structuredAccountsArr;
          newStructuredData = newStructuredData.filter(
            (x: any) => x.status.toUpperCase() === STATUS_TYPE.ACTIVE
          );
          conf.dropdownConfig.data = newStructuredData;

          conf.dropdownConfig.renderer = (index: any, obj: any) => {
            return ConfigUtility.getAccountRow(obj);
          };

          conf.dropdownConfig.searchApiConfig.getUrl = (search: any) =>
            NotesConfigManager.getAccountURL(search);
          break;
        case 'lineAmount':
          conf.editable = isEdit;
          conf.formatter = (data: any) =>
            NotesConfigUtility.amountFormatter(
              data.value,
              formData.currentCurrency
            );
          break;

        case 'lineSelectedTax':
          conf.editable = isEdit;
          let taxData = getUpdatedSGTaxList();
          conf.renderer = (obj: any) => {
            return (
              <span className="row justify-content-end">{obj.value.name}</span>
            );
          };
          conf.hidden = hideTaxView;
          conf.uiVisible = !hideTaxView;
          conf.dropdownConfig.data = taxData?.length
            ? NotesConfigUtility.taxDataParser(taxData, props.notesType)
            : [];
          conf.dropdownConfig.searchApiConfig.getUrl = (search: any) =>
            NotesConfigManager.getTaxURL(
              search,
              DateFormatService.getDateStrFromDate(
                currentDate,
                BOOKS_DATE_FORMAT['YYYY-MM-DD']
              )
            );
          conf.dropdownConfig.searchApiConfig.dataParser = (data: any) =>
            NotesConfigUtility.taxDataParser(data, props.notesType);
          conf.dropdownConfig.button.onClick = () => setShowTaxPopup(true); //Show Create Tax Popup
          break;
        case 'lineTaxAmount':
          conf.editable = false;
          conf.hidden = hideTaxView;
          conf.uiVisible = !hideTaxView;
          conf.formatter = (data: any) => {
            return NotesConfigUtility.amountFormatter(
              data.value,
              formData.currentCurrency
            );
          };
          break;

        case 'lineLineAmount':
          conf.editable = isEdit;
          conf.formatter = (data: any) => {
            return NotesConfigUtility.amountFormatter(
              data.value,
              formData.currentCurrency
            );
          };
          break;
        case 'lineAmtInBase':
          conf.hidden = formData.currentCurrency === tenantInfo.currency;
          conf.formatter = (data: any) => {
            return NotesConfigUtility.amountFormatter(
              data.value,
              tenantInfo.currency
            );
          };
          break;
        default:
          const classData = systemDimensions?.content?.find(
            (data: any) => data.label === LOCATION_CLASS_ENUM.CLASS
          );
          if (classData && classData.id === conf.id) {
            conf.hidden = hideClassColumn();
            conf.dropdownConfig.button = {
              title: '+ Add New',
              className: 'bg-button text-white',
              onClick: () => setShowAddClassPopup(true)
            };
          }
          if (conf.type === INPUT_TYPE.DROPDOWN) {
            conf.dropdownConfig.allowSearch =
              conf.dropdownConfig?.data?.length > 5;
            conf.dropdownConfig.searchableKey = 'value';
          }
          break;
      }
    });

    setColumnConfig(config.filter((col: any) => !col.hidden));
  };

  const onRowClick = ({ columnData, rowIndex, rowData }: any) => {
    if (
      tenantInfo.country === COUNTRY_CODES.UK &&
      columnData.id === 'taxAmount' &&
      rowData?.lineSelectedTax?.isTaxGroup
    ) {
      setIndexToEdit(rowIndex);
    }
  };

  const calculateTax = (lineItem: any) => {
    let amtValue = Number(lineItem.lineAmount);
    let taxAmout: number = 0;
    if (!Utility.isEmpty(lineItem.lineSelectedTax)) {
      // const selectedTaxList = taxes.find((tax) => {
      //   return tax.code === lineItem.lineSelectedTax;
      // });
      taxAmout = amtValue * (lineItem.lineSelectedTax.percent / 100);
    } else {
      taxAmout = 0;
    }
    return Utility.roundOffToTenantDecimalScale(taxAmout);
  };

  const updateFormData = (
    updates: any,
    taxAmount: number,
    totalAmount: number,
    totalAmtInBase: number,
    memo?: string
  ) => {
    const newLineItemsData = formData.lineItemsData;
    if (newLineItemsData.items) {
      newLineItemsData.items.map((item: any) => {
        if (item.lineItemNumber === updates.lineItemNumber) {
          item.linePercentage = updates.linePercentage;
          item.lineAmount = Math.abs(updates.lineAmount);
          item.lineItemNumber = updates.lineItemNumber;
          item.lineLineAmount = Math.abs(totalAmount);
          item.lineSelectedAccount = updates.lineSelectedAccount;
          item.lineSelectedTax = updates.lineSelectedTax;
          item.lineTaxAmount = Math.abs(taxAmount);
          item.lineAmountBase = updates.lineAmountBase;
          item.lineTaxList = updates.lineTaxList;
          item.lineHasError = updates.lineHasError;
          item.lineAmtInBase = totalAmtInBase;
          let cInvalidFields = [];
          if (Utility.isEmpty(updates.lineSelectedAccount)) {
            cInvalidFields.push('lineSelectedAccount');
          }
          item.invalidFields = cInvalidFields;
          return item;
        } else {
          // return updates;
        }
      });
    }
    // for bottom summary
    let baseLineAmount = 0,
      baseTaxAmount = 0;
    if (newLineItemsData.items && newLineItemsData.items.length > 0) {
      newLineItemsData.items.forEach((item: any) => {
        baseLineAmount = baseLineAmount + Number(item.lineAmount);
        baseTaxAmount = baseTaxAmount + item.lineTaxAmount;
      });
    }
    setFormData({
      ...formData,
      memoText: memo,
      lineItemsData: newLineItemsData,
      lineSummary: {
        lineLineAmountTotal: baseLineAmount,
        lineTaxAmountTotal: baseTaxAmount,
        total: baseLineAmount + baseTaxAmount
      }
    });
  };

  const updateLineItemList = (rowData: any, rowIndex: any, columnKey: any) => {
    let taxAmount = 0;
    let totalAmount = 0;
    let totalAmtInBase: any = 0;
    var updates = deepClone(rowData);
    let memoText = formData.memoText;
    if (!Utility.isEmpty(updates)) {
      const updatedState = { ...formData };
      if (columnKey === 'lineSelectedAccount') {
        if (updates[columnKey]?.customField?.length > 0) {
          // Handle custom fields when account is selected
          const availableCFs: any[] = selectCustomFieldsData?.content?.filter(
            (item: any) =>
              item.modules?.includes(MODULES_NAME.ACCOUNT) &&
              item.status === STATUS_TYPE.ACTIVE
          );
          availableCFs.forEach((availableCF: any) => {
            const existingCF = updates[columnKey]?.customField?.find(
              (cf: any) => cf.id === availableCF.id
            );
            if (existingCF) {
              if (
                typeof existingCF.value !== 'undefined' &&
                existingCF.value !== null &&
                existingCF.value !== ''
              ) {
                if (
                  availableCF.fieldType.toLowerCase() ===
                  INPUT_TYPE.DATE.toLowerCase()
                ) {
                  updatedState.lineItemsData.items[rowIndex][availableCF.id] =
                    DateFormatService.getDateFromStr(
                      existingCF.value,
                      BOOKS_DATE_FORMAT['MM/DD/YYYY']
                    );
                } else if (
                  availableCF.fieldType.toLowerCase() ===
                  CUSTOM_FIELD_TYPE.USER.toLowerCase()
                ) {
                  const cfValue = availableCF?.attributes?.find(
                    (attr: any) => attr.code === existingCF.value
                  );
                  updatedState.lineItemsData.items[rowIndex][availableCF.id] =
                    cfValue ? cfValue : '';
                } else {
                  if (availableCF.fieldType === CUSTOM_FIELD_TYPE.DROPDOWN) {
                    let selectedAttribute = availableCF.attributes.find(
                      (attribute: any) => attribute.value === existingCF.value
                    );
                    updatedState.lineItemsData.items[rowIndex][availableCF.id] =
                      selectedAttribute;
                  } else {
                    updatedState.lineItemsData.items[rowIndex][availableCF.id] =
                      existingCF.value;
                  }
                }
              } else {
                updatedState.lineItemsData.items[rowIndex][availableCF.id] = '';
              }
            } else {
              updatedState.lineItemsData.items[rowIndex][availableCF.id] = '';
            }
          });
        } else {
          const availableCFs: any[] = selectCustomFieldsData?.content?.filter(
            (item: any) =>
              item.modules?.includes(MODULES_NAME.ACCOUNT) &&
              item.status === STATUS_TYPE.ACTIVE
          );
          availableCFs.forEach((availableCF: any) => {
            delete updatedState.lineItemsData.items[rowIndex][availableCF.id];
          });
        }
      } else {
        if (
          columnKey !== 'lineAmount' &&
          columnKey !== 'lineSelectedTax' &&
          columnKey !== 'lineTaxAmount' &&
          columnKey !== 'lineLineAmount' &&
          columnKey !== 'lineAmtInBase'
        ) {
          //update column values for custom fields directly here
          const configForColumnKey = columnConfig.find(
            (config: any) => config.key === columnKey
          );
          if (configForColumnKey.isCustomField) {
            const cfInfo: any = customFieldsData.find(
              (cf: any) => cf.id === columnKey
            );
            if (cfInfo) {
              if (
                cfInfo.fieldType.toLowerCase() === INPUT_TYPE.DATE.toLowerCase()
              ) {
                updatedState.lineItemsData.items[rowIndex][columnKey] =
                  new Date(updates[columnKey]);
              } else if (
                cfInfo.fieldType.toLowerCase() ===
                  CUSTOM_FIELD_TYPE.USER.toLowerCase() ||
                cfInfo.fieldType.toLowerCase() ===
                  CUSTOM_FIELD_TYPE.DROPDOWN.toLowerCase()
              ) {
                // Use updates[columnKey].value for User or Dropdown type
                updatedState.lineItemsData.items[rowIndex][columnKey] =
                  updates[columnKey];
              } else {
                updatedState.lineItemsData.items[rowIndex][columnKey] =
                  updates[columnKey];
              }
            }
          }
        }
      }

      if (columnKey === 'lineSelectedTax') {
        updates['linePercentage'] = '';
        setIslineItemPercentage(false);
        if (
          rowIndex === formData.lineItemsData.items.length - 1 &&
          tenantInfo.country === COUNTRY_CODES.UK
        ) {
          memoText = rowData.lineSelectedTax?.defaultMemoUk;
        }
        if (
          updates?.[columnKey]?.isTaxGroup &&
          tenantInfo.country === COUNTRY_CODES.UK
        ) {
          let taxGroupList = [...updates?.[columnKey]?.taxGroupDetails]?.map(
            (taxItem: any) => {
              let taxItemCopy = { ...taxItem };
              taxItemCopy.taxAmount =
                updates.lineAmount * (taxItemCopy.percentage / 100);
              return taxItemCopy;
            }
          );
          updates.lineSelectedTax.taxGroupDetails = taxGroupList;
          updates.lineTaxList = taxGroupList;
        }
      }

      if (columnKey === 'lineAmount') {
        updates['linePercentage'] = '';
        setIslineItemPercentage(false);
        if (updates?.['lineSelectedTax']?.isTaxGroup) {
          let taxGroupList = [
            ...updates?.['lineSelectedTax']?.taxGroupDetails
          ]?.map((taxItem: any) => {
            let taxItemCopy = { ...taxItem };
            taxItemCopy.taxAmount =
              updates.lineAmount * (taxItemCopy.percentage / 100);
            return taxItemCopy;
          });
          updates.lineSelectedTax.taxGroupDetails = taxGroupList;
          updates.lineTaxList = taxGroupList;
        }
      }
      if (columnKey === 'lineTaxAmount') {
        updates['linePercentage'] = '';
        setIslineItemPercentage(false);
        taxAmount = Number(updates.lineTaxAmount);
      } else {
        taxAmount = calculateTax(updates);
      }

      if (columnKey === 'linePercentage') {
        if (selectedDocument) {
          let parentDocumentTotalAmount = selectedDocument.dueAmount;
          let parentDocumentTotalTaxAmount = selectedDocument.taxAmount;
          if (updates.linePercentage === undefined) {
            updates['linePercentage'] = '';
            setIslineItemPercentage(false);
          }
          const matcher = String(updates.linePercentage).match(
            REGEX.PERCENT_NUMER
          );
          if (matcher) {
            var percentage = 0;
            const percentDiscount = Number(
              updates.linePercentage.replace('%', '')
            );
            if (percentDiscount > 100) {
              percentage = 100;
            } else {
              percentage = percentDiscount;
              updates.lineAmount =
                ((parentDocumentTotalAmount - parentDocumentTotalTaxAmount) *
                  percentage) /
                100;
              updates.taxAmount =
                parentDocumentTotalTaxAmount > 0
                  ? (parentDocumentTotalTaxAmount * percentage) / 100
                  : 0;
              setIslineItemPercentage(true);
            }
          }
        }
      }
      totalAmount = Number(updates.lineAmount) + taxAmount;

      totalAmtInBase = (
        (Number(updates.lineAmount) + taxAmount) *
        Number(conversionRate)
      ).toFixed(3);
    }

    updateFormData(updates, taxAmount, totalAmount, totalAmtInBase, memoText);
  };

  const onRowUpdate = ({ columnKey, rowData, rowIndex }: any) => {
    setLastUpdatedColumn(columnKey);
    updateLineItemList(rowData, rowIndex, columnKey);
  };

  const onDelete = ({ rowIndex }: any) => {
    let rows = [...formData.lineItemsData.items];
    rows.splice(rowIndex, 1);

    // update bottom summary after deleting lineItems
    let baseLineAmount = 0,
      baseTaxAmount = 0;
    if (rows && rows.length > 0) {
      rows.forEach((item: any) => {
        baseLineAmount = baseLineAmount + Number(item.lineAmount);
        baseTaxAmount = baseTaxAmount + item.lineTaxAmount;
      });
    }
    let lastIndex = rows.length - 1 ?? 0;
    setFormData({
      ...formData,
      memoText:
        rows[lastIndex]?.selectedTaxCode?.defaultMemoUk ?? formData.memoText,
      lineItemsData: {
        items: rows
      },
      lineSummary: {
        lineLineAmountTotal: baseLineAmount,
        lineTaxAmountTotal: baseTaxAmount,
        total: baseLineAmount + baseTaxAmount
      }
    });
  };

  const getLineActionButton = () => {
    if (!isReadOnly) {
      return [
        {
          icon: DKIcons.ic_delete,
          className: ' p-0',
          onClick: onDelete
        }
      ];
    } else {
      return [];
    }
  };

  const getAccountLineItemColumnConfig = () => {
    var itemConfigs = columnConfig;

    if (selectedDocument && props.documentMode === DOCUMENT_MODE.NEW) {
      var keys = itemConfigs.map((element: any) => {
        return element.key;
      });

      let indexOf = keys.indexOf('lineAmount');

      if (!keys.includes('linePercentage')) {
        let lineAmountPercentage = {
          id: 'percentage',
          key: 'linePercentage',
          name: 'Amount(%)',
          type: INPUT_TYPE.TEXT,
          width: 150,
          systemField: true,
          editable: true,
          hidden: false,
          uiVisible: true,
          textAlign: 'right'
        };
        itemConfigs.splice(indexOf + 1, 0, lineAmountPercentage);
      }
    }
    return itemConfigs;
  };

  const getDataGrid = () => (
    <div ref={gridContainerRef} className="column parent-width">
      <DKDataGrid
        needShadow={false}
        needColumnIcons={false}
        needBorder={true}
        needTrailingColumn={true}
        allowBulkOperation={false}
        allowColumnSort={false}
        filterData={[]}
        allowColumnDelete={false}
        allowRowEdit={true}
        allowColumnEdit={false}
        allowFilter={false}
        allowColumnAdd={false}
        allowBottomRowAdd={false}
        allowSearch={false}
        allowShare={false}
        // width={960}
        rows={formData.lineItemsData.items}
        columns={[
          ...getAccountLineItemColumnConfig(),
          {
            id: 'action',
            key: 'action',
            name: '',
            type: INPUT_TYPE.BUTTON,
            width: 250,
            options: getLineActionButton()
          }
        ]}
        onRowUpdate={onRowUpdate}
        onRowClick={onRowClick}
        width={gridWidth}
      />
    </div>
  );

  const addNewItem = () => {
    if (formData.lineItemsData.items) {
      setFormData({
        ...formData,
        lineItemsData: {
          items: [
            ...formData.lineItemsData.items,
            {
              invalidFields: ['lineSelectedAccount'],
              lineItemNumber: formData.lineItemsData.items.length + 1,
              lineSelectedAccount: '',
              lineAmount: 0,
              lineSelectedTax: '',
              lineTaxAmount: 0,
              lineLineAmount: 0,
              lineAmountBase: 0,
              lineTaxList: [],
              lineHasError: false
            }
          ]
        }
      });
    }
  };

  const getProductDataGrid = () => (
    <DKDataGrid
      needShadow={false}
      needColumnIcons={false}
      needBorder={true}
      needTrailingColumn={true}
      allowBulkOperation={false}
      allowColumnSort={false}
      filterData={[]}
      allowColumnDelete={false}
      allowRowEdit={true}
      allowColumnEdit={false}
      allowFilter={false}
      allowColumnAdd={false}
      allowBottomRowAdd={false}
      allowSearch={false}
      allowShare={false}
      // width={960}
      rows={productRows}
      columns={[
        ...productColumnConfig
        // {
        //   id: 'action',
        //   key: 'action',
        //   name: '',
        //   type: INPUT_TYPE.BUTTON,
        //   width: 250,
        //   options: getLineActionButton()
        // }
      ]}
      onRowUpdate={onProductRowUpdate}
      onRowClick={onProductRowClick}
      width={productGridWidth}
    />
  );

  ////////////////////////////////////////////////////
  ////////// ATTACHMENT FUNCTIONALITIES //////////////
  ////////////////////////////////////////////////////
  const fetchAttachments = () => {
    const moduleType = DOC_TYPE_TO_ATTACHMENT_MAP[props.notesType];

    const entityId =
      props.populateFormData.id || props.populateFormData.entityId;

    if (!entityId) return;

    AttachmentService.attachmentConfig = {
      ...AttachmentService.attachmentConfig,
      Module: moduleType,
      EntityId: entityId
    };

    AttachmentService.getAllAttachments().then((attachmentList: any) => {
      setAttachments(attachmentList);
      // setFormData({
      //   ...formData,
      //   attachmentIds: attachmentList.map(
      //     (attachment: any) => attachment.attachmentId
      //   ),
      //   attachments: attachmentList.map((attachment: any) =>
      //     JSON.stringify(attachment)
      //   )
      // });
    });
  };

  const uploadAttachmentToAWS = (file: File) => {
    const moduleType = DOC_TYPE_TO_ATTACHMENT_MAP[props.notesType];
    AttachmentService.attachmentConfig = {
      Module: moduleType
    };
    if (file.size && file.size / (1024 * 1024) > 5) {
      showAlert(
        'Attachment size limit exceeded',
        'It seems the file size is more than 5 MB, Please compress the file and try again.'
      );
      return;
    }

    AttachmentService.uploadAttachment(file)
      .then((res) => {
        const newAttachments = [...attachments, res];
        setAttachments(newAttachments);
        let newAttachmentIdsArray = formData.attachmentIds
          ? [...formData.attachmentIds, res.attachmentId]
          : [res.attachmentId];
        let newAttachmentsArray = formData.attachments
          ? [...formData.attachments, JSON.stringify(res)]
          : [JSON.stringify(res)];
        setFormData({
          ...formData,
          attachmentIds: newAttachmentIdsArray,
          attachments: newAttachmentsArray
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const removeAttachment = (attachmentId: any) => {
    AttachmentService.deleteAttachment(attachmentId)
      .then((res) => {
        const newAttachments = attachments.filter(
          (attachment: any) => attachmentId !== attachment.attachmentId
        );

        setAttachments(newAttachments);
        // setFormData({
        //   ...formData,
        //   attachmentIds: newAttachments.map(
        //     (attachment: any) => attachment.attachmentId
        //   ),
        //   attachments: newAttachments.map((attachment: any) =>
        //     JSON.stringify(attachment)
        //   )
        // });
      })
      .catch(() => {});
  };

  const triggerAttachmentUpload = () => {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.addEventListener('change', (e) => {
      const target = e.target as HTMLInputElement;
      target?.files &&
        Array.from(target.files).forEach((file: File) =>
          uploadAttachmentToAWS(file)
        );
    });
    input.click();
  };

  const triggerAttachmentDownload = (
    attachmentId: any,
    attachmentName: string
  ) => {
    AttachmentService.downloadAttachment(attachmentId)
      .then((absolutePath) => {
        triggerDownload(null, attachmentName, absolutePath);
      })
      .catch(() => {});
  };

  const getAttachments = () => {
    return (
      <div className="row justify-content-start flex-wrap gap-2">
        {attachments.map((attachment: any) => (
          <div
            className="row border-m border-radius-s p-h-r p-v-s mr-r bg-gray0 overflow-hidden"
            key={attachment.attachmentId}
            style={{
              width: '18rem'
            }}
          >
            <DKIcon
              src={DKIcons.ic_document}
              className="ic-s cursor-pointer"
              onClick={() => {
                triggerAttachmentDownload(
                  attachment.attachmentId,
                  attachment.attachmentFileName
                );
              }}
            />
            <div
              className="ml-s w-auto truncate cursor-pointer border-none py-2"
              onClick={() => {
                triggerAttachmentDownload(
                  attachment.attachmentId,
                  attachment.attachmentFileName
                );
              }}
            >
              {attachment.attachmentFileName}
            </div>
            <DKIcon
              src={DKIcons.ic_delete}
              className="ic-s ml-l cursor-pointer"
              onClick={() => removeAttachment(attachment.attachmentId)}
            />
          </div>
        ))}
      </div>
    );
  };

  const lineItemHeaderView = () => {
    const getTaxGroupDetails = () => {
      let taxGroupContainer;
      if (tenantInfo.country === 'IN') {
        taxGroupContainer = (
          <>
            <div className="flex flex-col item-start">
              {gstType === GST_TYPE.INTER && (
                <>
                  <div className="flex flex-row item-end justify-between mt-2 pr-2">
                    <div className="flex flex-col items-end w-24">
                      +{t(`DOCUMENT.DOCUMENT_SUMMARY_VIEW.IGST`)}
                    </div>
                    <div className="flex flex-row items-end justify-end w-24">
                      <FormatAmount
                        value={
                          formData.lineSummary?.lineTaxAmountTotal
                            ? (formData.lineSummary?.lineTaxAmountTotal).toFixed(
                                3
                              )
                            : ''
                        }
                        currencyCode={formData.currentCurrency}
                      />
                    </div>
                  </div>
                </>
              )}
              {gstType === GST_TYPE.INTRA && (
                <>
                  <div className="flex flex-row item-end justify-between mt-2 pr-2">
                    <div className="flex flex-col items-end w-24">
                      +{t(`DOCUMENT.DOCUMENT_SUMMARY_VIEW.CGST`)}
                    </div>
                    <div className="flex flex-row items-end justify-end w-24">
                      <FormatAmount
                        value={(formData.lineSummary?.lineTaxAmountTotal).toFixed(
                          3
                        )}
                        currencyCode={formData.currentCurrency}
                      />
                    </div>
                  </div>
                  <div className="flex flex-row item-end justify-between mt-2 pr-2">
                    <div className="flex flex-col items-end w-24">
                      +{t(`DOCUMENT.DOCUMENT_SUMMARY_VIEW.SGST`)}
                    </div>
                    <div className="flex flex-row items-end justify-end w-24">
                      <FormatAmount
                        value={(formData.lineSummary?.lineTaxAmountTotal).toFixed(
                          3
                        )}
                        currencyCode={formData.currentCurrency}
                      />
                    </div>
                  </div>
                </>
              )}
              {gstType === GST_TYPE.EXEMPT && <></>}
            </div>
          </>
        );
      } else {
        taxGroupContainer = Object.keys(taxGroupDetail).map(
          (key: any, index) => {
            //   taxGroupContainer
            if (key !== undefined && key !== null) {
              return (
                <div className="flex flex-col item-start">
                  <div className="flex flex-row item-end justify-between mt-2 pr-2">
                    <div className="flex flex-col items-end w-24">{key}</div>
                    <div className="flex flex-row items-end justify-end w-24">
                      <FormatAmount
                        value={taxGroupDetail[key]}
                        currencyCode={formData.currentCurrency}
                      />
                    </div>
                  </div>
                </div>
              );
            }
            return <></>;
          }
        );
      }
      return taxGroupContainer;
    };

    const sgdTax = () => {
      let amount = formData.lineSummary?.lineTaxAmountTotal / conversionRate;
      const taxinSGD = amount * (1 / formData.gstExchangeRate);
      return taxinSGD.toFixed(3);
    };

    const showBaseTaxForSG = () => {
      return (
        getTenantTaxSystem() === TAX_SYSTEM.SG &&
        tenantInfo.currency !== CURRENCIES.SG
      );
    };

    const getTaxContainer = (hasTaxGroup: boolean) => {
      if (hasTaxGroup) {
        return (
          <>
            <div className="flex flex-row item-end justify-between mt-2 pr-2">
              <div
                className="flex flex-col items-end w-24 cursor-pointer font-medium"
                onClick={() =>
                  setShowTaxGroupDetails((prevState) => !prevState)
                }
              >
                <div className="flex flex-row items-start">
                  <span className="mr-1">+Tax</span>
                  {showTaxGroupDetails ? (
                    <DKIcon src={ic_arrowup} className="ic-s" />
                  ) : (
                    <DKIcon src={ic_arrowdown} className="ic-s" />
                  )}
                </div>
              </div>
              <div className="flex flex-row items-end justify-end w-24 font-medium">
                <FormatAmount
                  value={
                    formData.lineSummary?.lineTaxAmountTotal
                      ? (formData.lineSummary?.lineTaxAmountTotal).toFixed(3)
                      : ''
                  }
                  currencyCode={formData.currentCurrency}
                />
              </div>
            </div>
            {showTaxGroupDetails && <> {getTaxGroupDetails()}</>}
            {/* sgd compliance specific  */}
            {showBaseTaxForSG() && (
              <div className="flex flex-row item-end justify-between pr-2">
                <div className="flex flex-col items-end w-24 text-sm">
                  (SGD)
                </div>
                <div className="flex flex-row justify-end items-end w-24">
                  <FormatAmount
                    value={
                      formData.lineSummary?.lineTaxAmountTotal ? sgdTax() : ''
                    }
                    currencyCode={formData.currentCurrency}
                  />
                </div>
              </div>
            )}
          </>
        );
      } else {
        return (
          <>
            <div className="flex flex-row item-end justify-between mt-2 pr-2">
              <div className="flex flex-col items-end w-24 font-medium">
                <div className="flex flex-row items-start">
                  <span className="mr-1">+Tax</span>
                </div>
              </div>
              <div className="flex flex-row items-end justify-end w-24 font-medium">
                <FormatAmount
                  value={
                    formData.lineSummary?.lineTaxAmountTotal
                      ? formData.lineSummary?.lineTaxAmountTotal
                      : ''
                  }
                  currencyCode={formData.currentCurrency}
                />
              </div>
            </div>
          </>
        );
      }
    };

    return (
      <div className="flex flex-col items-start mt-4 w-full">
        <div className="row parent-width justify-content-between align-items-start">
          <div className="flex flex-col w-full items-start">
            <textarea
              className="w-full h-24 resize-none p-2 border rounded outline-none border-gray-200 hover:border-gray-300 focus:border-gray-400"
              placeholder={t(`ACCOUNTING.DEBIT_NOTES.DIALOG.MEMO_OPTIONAL`)}
              onChange={(e: any) => {
                setFormData({
                  ...formData,
                  memoText: e.target.value
                });
              }}
              value={formData.memoText}
            ></textarea>
            <div className="column parent-width mt-2">
              <DKButton
                title={
                  <>
                    + Attach Files
                    <span className="text-gray fw-r ml-s">(Max 5MB)</span>
                  </>
                }
                icon={DKIcons.ic_attachment}
                className={`${
                  isReadOnly ? 'text-gray' : 'text-blue'
                } mt-r fw-m`}
                // className={`${
                //   props.draftType === DraftTypes.READONLY
                //     ? 'text-gray'
                //     : 'text-blue'
                // } mt-r fw-m`}
                // disabled={props.draftType === DraftTypes.READONLY}
                onClick={triggerAttachmentUpload}
              />
              <div className="row mt-r">{getAttachments()}</div>
            </div>
          </div>

          {/* summary */}
          <div className="flex flex-col w-full items-end">
            <div className="flex flex-col" style={{ width: 300 }}>
              {/* subtotal --- start */}
              <div className="flex flex-row item-end justify-between pr-2">
                <div className="flex flex-col items-end w-24 text-sm">
                  {t(`ACCOUNTING.DEBIT_NOTES.DIALOG.SUB_TOTAL`)}
                </div>
                <div className="flex flex-row justify-end items-end w-24">
                  <FormatAmount
                    value={
                      formData.lineSummary?.lineLineAmountTotal
                        ? (formData.lineSummary?.lineLineAmountTotal).toFixed(3)
                        : ''
                    }
                    currencyCode={formData.currentCurrency}
                  />
                </div>
              </div>
              {/* subtotal --- end */}
              {!hideTaxView && getTaxContainer(hasTaxGroup)}

              {/* total --- start */}
              <div className="flex flex-row item-end justify-between mt-2 pt-2 border-t font-semibold">
                <div className="flex flex-col items-end w-24 text-sm">
                  {t(`ACCOUNTING.DEBIT_NOTES.DIALOG.TOTAL`)}
                  {formData.currentCurrency
                    ? `(${formData.currentCurrency})`
                    : ''}
                </div>
                <div className="flex flex-row justify-end items-end w-24 pr-2">
                  <FormatAmount
                    value={
                      formData.lineSummary?.total
                        ? (formData.lineSummary?.total).toFixed(3)
                        : ''
                    }
                    currencyCode={formData.currentCurrency}
                  />
                </div>
              </div>
              {/* total --- end */}

              {/* total for multi currency - start */}
              {tenantInfo.multicurrencyEnabled &&
                tenantInfo.currency !== formData.currentCurrency && (
                  <div className="flex flex-row item-end justify-between pr-2">
                    <div className="flex flex-col items-end w-24 text-sm">
                      {tenantInfo.currency}
                    </div>
                    <div className="flex flex-row justify-end items-end w-24">
                      <FormatAmount
                        value={
                          formData.lineSummary?.total
                            ? (
                                formData.lineSummary?.total * conversionRate
                              ).toFixed(3)
                            : ''
                        }
                        currencyCode={tenantInfo.currency}
                      />
                    </div>
                  </div>
                )}
              {/* total for multi currency - end */}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const catchClicks = (data: PopupClickActionType) => {
    switch (data.type) {
      case POPUP_CLICK_TYPE.CLOSE_POPUP:
        showAddContactPopup && setShowAddContactPopup(false);
        showTaxPopup && setShowTaxPopup(false);
        showUpdateOrgPopup && setShowUpdateOrgPopup(false);
        setShowIRPCredPopup(false);
        // showAddressPopup && setShowAddressPopup(false);
        break;
      case POPUP_CLICK_TYPE.CREATE_CONTACT:
        editAddressRef.current?.storeCallbacksRef.copyContact();
        break;
      case POPUP_CLICK_TYPE.UPDATE_CONTACT:
        editAddressRef.current?.storeCallbacksRef.updateContact();
        break;
      case POPUP_CLICK_TYPE.CREATE_TAX:
        addTaxRef.current?.storeCallbacksRef.createTax();
        break;
      case POPUP_CLICK_TYPE.IRP_CREDENTIAL:
        orgProfileRef.current?.storeCallbacksRef.createIRPCredentials();
        break;
      case POPUP_CLICK_TYPE.UPDATE_ADDRESS:
        editAddressRef.current?.storeCallbacksRef.updateAddress();
        break;
      case POPUP_CLICK_TYPE.UPDATE_ORGANISATION:
        editAddressRef.current?.storeCallbacksRef.updateOrganisationProfile();
        break;
    }
  };

  const parentChildInteraction = (passingData: CallBackPayloadType) => {
    switch (passingData.type) {
      case POPUP_CALLBACKS_TYPE.CREATE_CONTACT:
        editAddressRef.current.storeCallbacksRef.copyContact = passingData.data;
        break;
      case POPUP_CALLBACKS_TYPE.UPDATE_CONTACT:
        editAddressRef.current.storeCallbacksRef.updateContact =
          passingData.data;
        break;
      case POPUP_CALLBACKS_TYPE.CREATE_CONTACT_SUCCESS:
        setShowAddContactPopup(false);
        break;
      case POPUP_CALLBACKS_TYPE.CREATE_TAX:
        addTaxRef.current.storeCallbacksRef.createTax = passingData.data;
        break;
      case POPUP_CALLBACKS_TYPE.CREATE_TAX_SUCCESS:
        setShowTaxPopup(false);
        break;
      case POPUP_CALLBACKS_TYPE.CLOSE_POPUP:
        showAddContactPopup && setShowAddContactPopup(false);
        showTaxPopup && setShowTaxPopup(false);
        showUpdateOrgPopup && setShowUpdateOrgPopup(false);
        break;
      case POPUP_CALLBACKS_TYPE.IRP_CREDENTIAL:
        orgProfileRef.current.storeCallbacksRef.createIRPCredentials =
          passingData.data;
        break;
      case POPUP_CALLBACKS_TYPE.ADDRESS_UPDATE_SUCCESSFUL:
        onAddressUpdated();
        break;
      case POPUP_CALLBACKS_TYPE.UPDATE_ORGANISATION:
        editAddressRef.current.storeCallbacksRef.updateOrganisationProfile =
          passingData.data;
        break;
      case POPUP_CALLBACKS_TYPE.UPDATE_ADDRESS:
        editAddressRef.current.storeCallbacksRef.updateAddress =
          passingData.data;
        break;
    }
  };

  const getAddContactPopup = () => {
    const buttonConfig: BtnType[] = [
      {
        title: t(`DOCUMENT.BUTTON.CANCEL`),
        class: 'border-m mr-s',
        clickAction: POPUP_CLICK_TYPE.CLOSE_POPUP
      },
      {
        title: contactMode === DOCUMENT_MODE.NEW ? 'Create' : 'Update',
        class: 'bg-button text-white mr-ss',
        clickAction:
          contactMode === DOCUMENT_MODE.NEW
            ? POPUP_CLICK_TYPE.CREATE_CONTACT
            : POPUP_CLICK_TYPE.UPDATE_CONTACT
      }
    ];
    return showAddContactPopup ? (
      <PopupWrapper
        clickAction={catchClicks}
        type={POPUP_TYPE.POPUP}
        title={
          contactMode === DOCUMENT_MODE.NEW
            ? 'Create Contact'
            : 'Update Contact'
        }
        btnList={buttonConfig}
        disableClickOutside={true}
        width={!isDesktop ? '95%' : '40%'}
        minWidth={!isDesktop ? '' : '550px'}
        height={'95%'}
      >
        <AddContact
          activeTab={activeContactTab}
          contactMode={contactMode}
          populateFormData={
            contactMode === DOCUMENT_MODE.NEW ? null : { ...contact }
          }
          passingInteraction={(callback: CallBackPayloadType) => {
            parentChildInteraction(callback);
          }}
          onSuccess={(res: any) => setContact(res)}
        />
      </PopupWrapper>
    ) : null;
  };

  const getTaxForm = () => (
    <PopupWrapper
      clickAction={catchClicks}
      type={POPUP_TYPE.POPUP}
      title={t(`SETTINGS.TAX.ADD_TAX_RATE`)}
      btnList={[
        {
          title: t(`SETTINGS.TAX.BUTTON.CANCEL`),
          class: 'border-m mr-s',
          clickAction: POPUP_CLICK_TYPE.CLOSE_POPUP
        },
        {
          title: t(`SETTINGS.TAX.BUTTON.CREATE`),
          class: 'bg-app text-white mr-ss',
          clickAction: POPUP_CLICK_TYPE.CREATE_TAX
        }
      ]}
      width="45%"
      height="75%"
    >
      <AddTax
        populateFormData={null}
        passingInteraction={(callback: CallBackPayloadType) => {
          parentChildInteraction(callback);
        }}
      />
    </PopupWrapper>
  );

  const getCurrencyRateField = () => {
    return (
      <div
        className="row parent-width mb-m"
        style={{ width: isFullScreenMode ? 'auto' : 300 }}
      >
        <DKIcon
          style={{
            transform: 'rotate(90deg)',
            opacity: 0.6
          }}
          src={DKIcons.ic_sort}
        />
        <div className="parent-width">
          <div className={'row w-full fs-r'}>
            <div className="row currency-select-trigger">
              <div className="ml-1">{`1 ${formData.currentCurrency}`}</div>
              {/* <div className="currency-dropdown-flag ml-2">
                <span
                  className={`currency-dropdown-selector-flag flag ${formData.currentCurrency}`}
                ></span>
              </div> */}
            </div>

            <input
              // onFocus={onFocus}
              // onBlur={onBlur}
              className={`text-align-right outline-none`}
              value={conversionRate}
              type={'s'}
              style={{
                width: '50px'
              }}
              onChange={(e: any) => {
                setConversionRate(e.target.value.replace(/^0+/, ''));
              }}
              disabled //={!Utility.isEmpty(notesData)}
            />
            <div className="ml-1">{`${tenantInfo.currency}`}</div>
            {/* <div className="currency-dropdown-flag ml-2">
              <span
                className={`currency-dropdown-selector-flag flag ${tenantInfo.currency}`}
              ></span>
            </div> */}
          </div>
        </div>
      </div>
    );
  };

  ////////////////////// GST Exchange Rate - start ///////////////////////

  const getTaxExchangeRatePopup = () => {
    return (
      <GSTExchangeRateDialog
        TaxResidencyCurrency={
          COMPLAINCE_CURRENCY[AuthService.userDetails.country]
        }
        TransferCurrency={formData.currentCurrency}
        GstExchangeRate={formData.gstExchangeRate}
        onClose={() => setShowTaxExchangeRatePopup(false)}
        onUpdateTaxRate={(rate: number) => {
          setFormData({
            ...formData,
            gstExchangeRate: rate
          });
          setShowTaxExchangeRatePopup(false);
        }}
      />
    );
  };

  ///////////////////// GST Exchange Rate - end /////////////////////////

  const getAddressCustomFields = (type: any) => {
    let customFieldsToPass: any[] = [];
    if (type === BOOKS_ADDRESS_TYPES.BILLING_ADDRESS) {
      customFieldsToPass =
        billingAddress?.currentBillingAddress?.customFields ?? [];
    } else if (type === BOOKS_ADDRESS_TYPES.SHIPPING_ADDRESS) {
      customFieldsToPass =
        shippingAddress?.currentShippingAddress?.customFields ?? [];
    } else if (type === BOOKS_ADDRESS_TYPES.SHIP_FROM) {
      customFieldsToPass = getCompanyAddress()?.customFields ?? [];
    }
    return Utility.isNotEmpty(customFieldsToPass) ? (
      <div className={`column parent-width`}>
        <CustomFieldsHolder
          moduleName={MODULES_NAME.CONTACT_ADDRESS}
          customFieldsList={customFieldsToPass}
          columnConfig={getColumnConfigFromDocType().columnConfig}
          columnConfigTableId={getColumnConfigFromDocType().tableId}
          onUpdate={(list: any[]) => {
            if (type === BOOKS_ADDRESS_TYPES.BILLING_ADDRESS) {
              let currentBillAddr: any = billingAddress?.currentBillingAddress;
              let billingAddressCopy = {
                ...billingAddress,
                currentBillingAddress: {
                  ...currentBillAddr,
                  customFields: [...list]
                }
              };
              setBillingAddress(billingAddressCopy);
            } else if (type === BOOKS_ADDRESS_TYPES.SHIPPING_ADDRESS) {
              let currentShipAddr: any = billingAddress?.currentBillingAddress;
              let shippingAddressCopy: BillingAddressType = {
                ...shippingAddress,
                currentShippingAddress: {
                  ...currentShipAddr,
                  customFields: [...list]
                }
              };
              setShippingAddress(shippingAddressCopy);
            } else if (type === BOOKS_ADDRESS_TYPES.SHIP_FROM) {
              let bookDocumentCopy = { ...booksDocument };
              bookDocumentCopy = {
                ...bookDocumentCopy,
                shipFrom: {
                  ...bookDocumentCopy?.shipFrom,
                  customFields: [...list]
                }
              };
              setBooksDocument(bookDocumentCopy);
            }
          }}
          hideAddfieldButton={true}
          contact={contact}
          addressUpdate={
            isBillToAddressUpdated ||
            isShipToAddressUpdated ||
            isContactUpdated ||
            isShipFromAddressUpdated
          }
          updateAddressField={() => {
            setIsBillToAddressUpdated(false);
            setIsShipToAddressUpdated(false);
            setIsContactUpdated(false);
            setIsShipFromAddressUpdated(false);
          }}
        />
      </div>
    ) : null;
  };

  return (
    <div className="px-3 popup-screen">
      {getInvoiceNow(formData)}
      <div className="row justify-content-between align-items-start">
        <div
          className="flex flex-row text-sm justify-between"
          style={{
            minWidth: 170,
            maxWidth: 450,
            marginLeft: -10,
            paddingRight: 10,
            paddingLeft: 10,
            paddingBottom: 10
          }}
        >
          {getCompanyDetails()}
        </div>
      </div>
      <div className="flex flex-row w-full text-sm justify-between">
        {Utility.isEmpty(contact) && !isBillOrOrder && (
          <div className="flex flex-row w-full text-sm justify-between">
            <div
              className={`flex-col flex-wrap items-start mt-l parent-width ${
                isFullScreenMode ? 'mb-l' : ''
              }`}
            >
              {getContactSelector()}
            </div>
          </div>
        )}
        <div
          className="row parent-width fs-r justify-space-between mt-l"
          style={{ maxWidth: 700 }}
        >
          <div
            className="column flex-wrap align-items-start parent-width"
            style={{
              minWidth: 170,
              maxWidth: 340,
              marginLeft: -10,
              paddingRight: 10,
              paddingLeft: 10,
              paddingBottom: 10
            }}
          >
            <div className={`column document-address-block`}>
              {getBillingAddress()}
              {Utility.isNotEmpty(contact) &&
                getAddressCustomFields(BOOKS_ADDRESS_TYPES.BILLING_ADDRESS)}
            </div>
          </div>
          <div
            className="column flex-wrap align-items-start parent-width"
            style={{
              minWidth: 170,
              maxWidth: 340,
              marginLeft: -10,
              paddingRight: 10,
              paddingLeft: 10,
              paddingBottom: 10
            }}
          >
            <div
              className={`column document-address-block shipping-address`}
              ref={shippingAddressBlock}
            >
              {getShippingAddress()}
              {Utility.isNotEmpty(contact) &&
                getAddressCustomFields(BOOKS_ADDRESS_TYPES.SHIPPING_ADDRESS)}
            </div>
          </div>
        </div>
        <div className="column flex-wrap align-items-end">
          {getAutoNumberingView()}
          {!isFullScreenMode && getCalendarView1()}
          {!isFullScreenMode && getCurrencyView()}
          {!isFullScreenMode &&
            tenantInfo.multicurrencyEnabled &&
            tenantInfo.currency !== formData.currentCurrency &&
            getCurrencyRateField()}
          {showLinkedDocumentSection(formData?.linkedDocuments, isEditMode) &&
            getLinkedDocumentsView()}
        </div>
      </div>

      {(Utility.isNotEmpty(
        billingAddress?.currentBillingAddress?.customFields
      ) ||
        Utility.isNotEmpty(
          shippingAddress?.currentShippingAddress?.customFields
        )) && <DKLine className="mb-r" />}

      <div
        className="row width-auto align-items-start flex-wrap"
        style={{ maxWidth: !showMainDocsInFullscreen ? 570 : 870, gap: 10 }}
      >
        <div className={`column mb-r`}>
          {!isFullScreenMode &&
            getTenantTaxSystem() === TAX_SYSTEM.INDIA_GST &&
            !Utility.isEmpty(contact) &&
            selectedDocument !== undefined &&
            getPlaceOfSupply()}

          {isFullScreenMode && (
            <div
              className="row align-items-end flex-wrap mb-l"
              style={{ gap: 12 }}
            >
              <div
                className="mt-r"
                style={{
                  width: 280
                }}
              >
                {getDocumentDateField()}
              </div>
              {getTenantTaxSystem() === TAX_SYSTEM.INDIA_GST &&
                !Utility.isEmpty(contact) &&
                selectedDocument !== undefined && (
                  <div
                    style={{
                      width: 280
                    }}
                  >
                    {getPlaceOfSupply()}
                  </div>
                )}
              <div
                style={{
                  width: 280
                }}
              >
                {getCurrencyFieldForFullScreen()}
              </div>

              {tenantInfo.multicurrencyEnabled &&
                tenantInfo.currency !== formData.currentCurrency && (
                  <div
                    className="bg-dk-input border-radius-s"
                    style={{
                      width: 280,
                      height: 33,
                      border: '1.2px solid rgb(235, 235, 235)',
                      padding: '4px 4px 0 2px'
                    }}
                  >
                    {getCurrencyRateField()}
                  </div>
                )}
            </div>
          )}

          <div className="row align-items-end flex-wrap" style={{ gap: 12 }}>
            {props.documentMode === DOCUMENT_MODE.NEW &&
              isBillOrOrder &&
              getDocumentListView(DOC_TYPE.BILL)}
            {props.documentMode === DOCUMENT_MODE.NEW &&
              getDocumentListView(DOC_TYPE.INVOICE)}
            {isFullScreenMode && getReasonPicker()}
          </div>

          {!isFullScreenMode && getReasonPicker()}
        </div>
      </div>

      <div
        className={`column parent-width mb-r ${isFullScreenMode ? 'mt-r' : ''}`}
        style={{
          marginLeft: formData.customField?.length ? 0 : -12
        }}
      >
        <CustomFieldsHolder
          moduleName={getModuleFromDocType()}
          customFieldsList={
            !Utility.isEmpty(notesData) ? formData.customField : []
          }
          columnConfig={getColumnConfigFromDocType().columnConfig}
          columnConfigTableId={getColumnConfigFromDocType().tableId}
          onUpdate={(list: any[]) => {
            setFormData({
              ...formData,
              customField: list
            });
          }}
          contact={contact}
        />
      </div>
      {getDataGrid()}
      {showTaxExchangeRatePopup && getTaxExchangeRatePopup()}
      {!isReadOnly && (
        <div className="row width-auto">
          <DKButton
            title={`+ ${t('DOCUMENT.ADD_ITEM')}`}
            onClick={() => addNewItem()}
            className={`text-blue fw-m mb-l`}
            disabled={false}
            style={{ marginTop: -20, zIndex: 1, paddingLeft: 0 }}
          />
        </div>
      )}
      <div ref={productGridContainerRef} className="column parent-width">
        {selectedDocument && getProductDataGrid()}
      </div>
      <div className="flex flex-col items-start w-full">
        {lineItemHeaderView()}
      </div>
      {getAddContactPopup()}
      {getUpdateOrgPopup()}
      {showTaxPopup && getTaxForm()}
      {showAddClassPopup && getAddClassForm()}
      {showUpdateAddressPopup && (
        <UpdateAddress
          contactDetails={
            props.documentMode === DOCUMENT_MODE.EDIT
              ? booksDocument.contactDto
              : booksDocument.contact
          }
          addressList={
            booksAddressType === BOOKS_ADDRESS_TYPES.BILLING_ADDRESS
              ? billingAddress?.billingAddress
              : shippingAddress?.shippingAddress
          }
          addressType={booksAddressType}
          onCancel={() => setShowUpdateAddressPopup(false)}
          onAddressUpdate={() => onAddressUpdated()}
        />
      )}
      <div className="mt-">{showInvoiceNowDetails()}</div>
      {showIRPCredPopup && (
        <PopupWrapper
          clickAction={catchClicks}
          type={POPUP_TYPE.POPUP}
          title={'Enter your IRP Credentials'}
          btnList={popupBtnConfigForEInvoice}
          isLoading={popupLoading}
          loadingBtnList={loadingIRPCredPopupBtnConfig}
          width={!isDesktop ? '80%' : '35%'}
          maxHeight={'95%'}
          disableClickOutside={true}
        >
          <IRPCredPopup
            passingInteraction={(callback: CallBackPayloadType) => {
              parentChildInteraction(callback);
            }}
            gstIn={tenantInfo.gstin}
            sessionOutMessage={true}
            onCancel={() => {
              setShowIRPCredPopup(false);
              if (IRPCredPopupType === INVOICE_NOW_TYPES.PENDING) {
                generateEInvoiceIndia();
              } else if (IRPCredPopupType === INVOICE_NOW_TYPES.GENERATED) {
                cancelEInvoiceIndia();
              }
            }}
          />
        </PopupWrapper>
      )}
      {showCancelEInvoice && (
        <CancelEInvoice
          data={{
            isBulkCancel: false,
            id: notesData?.id,
            irn: notesData?.einvoiceInfoIndia?.irn,
            documentCode: notesData?.code,
            documentType: props.notesType
          }}
          onCancel={() => {
            setShowCancelEInvoice(false);
            // const config: InvoiceAPIConfig = {
            //   ...InvoiceService.apiConfig
            // };
            // loadInvoices(config);
          }}
        />
      )}
      {indexToEdit >= 0 && (
        <TaxGroupDistribution
          taxList={
            formData.lineItemsData.items?.[indexToEdit]['lineSelectedTax']
              ?.taxGroupDetails
          }
          onCancel={() => {
            setIndexToEdit(-1);
          }}
          onApply={(taxList: any[]) => {
            let copyFormData = { ...formData };
            let copyLineItemData = { ...copyFormData?.lineItemsData };
            let copyItems = [...copyLineItemData.items];
            let copyItemToEdit = { ...copyItems[indexToEdit] };
            let copyLineSelectedTax = { ...copyItemToEdit.lineSelectedTax };
            copyLineSelectedTax.taxGroupDetails = taxList;
            copyItemToEdit.lineSelectedTax = copyLineSelectedTax;
            copyItems[indexToEdit] = copyItemToEdit;
            copyLineItemData.items = copyItems;
            copyFormData.lineItemsData = copyLineItemData;
            setFormData(copyFormData);
            setIndexToEdit(-1);
          }}
        />
      )}
    </div>
  );
};
export default CreateAndEditNotes;
