import ApiConstants from '../Constants/ApiConstants';
import {
  COLUMN_TYPE,
  CUSTOM_FIELD_TYPE,
  MODULES_NAME
} from '../Constants/Constant';
import { INPUT_TYPE, showAlert } from 'deskera-ui-library';
import { CustomFieldPayload, CustomFieldResponse } from '../Models/CustomField';
import { ColumnDeleteType, IColumn } from '../Models/Table';
import http from './Interceptor';
import http2 from './Interceptor2';
import TableService from './Table';
import Utility from '../Utility/Utility';

export const CF_INPUT_TYPE = {
  ...INPUT_TYPE,
  USER: 'user'
};

export interface CustomFieldAPIConfig {
  SearchTerm?: string;
  Limit?: number;
  Page?: number;
  Query?: string;
}

const defaultConfig: CustomFieldAPIConfig = {
  SearchTerm: '',
  Limit: 1000,
  Page: 0
};

class CustomFieldService {
  static abortController: any = null;
  static apiConfig: CustomFieldAPIConfig;
  static getCustomFieldEndPoint() {
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    }
    const queryString: string = `?search=${this.apiConfig.SearchTerm}&limit=${
      this.apiConfig.Limit
    }${this.apiConfig.Query ? '&query=' + this.apiConfig.Query : ''}`;
    const finalEndpoint: string =
      ApiConstants.URL.CUSTOM_FIELD.FETCH + queryString;
    return finalEndpoint;
  }

  static getFieldType(inputType: any) {
    switch (inputType) {
      case CF_INPUT_TYPE.SELECT:
        return CUSTOM_FIELD_TYPE.DROPDOWN;
      case CF_INPUT_TYPE.NUMBER:
        return CUSTOM_FIELD_TYPE.NUMBER;
      case CF_INPUT_TYPE.DATE:
        return CUSTOM_FIELD_TYPE.DATE;
      case CF_INPUT_TYPE.USER:
        return CUSTOM_FIELD_TYPE.USER;
      default:
        return CUSTOM_FIELD_TYPE.TEXT;
    }
  }

  static createCustomField(
    columnData: IColumn,
    moduleName: MODULES_NAME,
    tableId: string,
    parentCfDetails: any
  ) {
    let moduleList: MODULES_NAME[] = [];
    moduleList.push(moduleName);
    const customFieldPayload: CustomFieldPayload = {
      defaultValue: '',
      fieldType: CustomFieldService.getFieldType(columnData.type),
      label: columnData.name,
      mandatory: false,
      maxLength: 100,
      modules: moduleList
    };

    if (columnData.type === CF_INPUT_TYPE.SELECT) {
      if (columnData.options && columnData.options.length > 0) {
        customFieldPayload.attributes = columnData.options.map((option) => {
          const data: any = {
            defaultOption: option.defaultOption,
            status: 'ACTIVE',
            value: option.name
          };

          if (
            parentCfDetails?.attributes &&
            option.selectedInd &&
            option.selectedInd.length > 0
          ) {
            let ids = '';
            option.selectedInd.forEach((ind) => {
              const customFieldDetails = parentCfDetails.attributes[ind];
              ids = ids + customFieldDetails.id + ',';
            });
            ids = ids.substring(0, ids.length - 1);
            data.parentId = ids;
          }
          return data;
        });
      } else {
        customFieldPayload.attributes = [];
      }
    }

    return new Promise((resolve, reject) => {
      http2
        .post(
          `${ApiConstants.URL.CUSTOM_FIELD.CREATE_CUSTOM_FIELD}`,
          customFieldPayload
        )
        .then((customFieldRes: any) => {
          const customFieldResponse: CustomFieldResponse = customFieldRes;
          const customFieldCode: string = customFieldResponse.code;
          const customFieldId: number = customFieldResponse.id;
          const newColumnCode: string = `${COLUMN_TYPE.CUSTOM}_${customFieldCode}_${customFieldId}`;
          const columnConfigPayload: IColumn = {
            name: columnData.name,
            width: columnData.width,
            options: columnData.options,
            required: false,
            systemField: false,
            editable: columnData.editable,
            hidden: columnData.hidden,
            type: columnData.type,
            index: columnData.index,
            uiVisible: columnData.uiVisible,
            columnCode: newColumnCode
          };
          if (tableId !== undefined && tableId !== null && tableId !== '') {
            TableService.getTable(tableId)
              .then((res: any) => {
                resolve({
                  customFieldRes,
                  columnConfig: res.columnsMetaData
                });
              })
              .catch((error) => {
                reject(error);
              });
          } else {
            // There are certain modules which are not using DKDatagrid but has Custom Fields
            resolve(true);
          }
        })
        .catch((error) => {
          console.log(error, 'Error');
          reject(error);
        });
    });
  }

  static getDefaultValue(columnData: any, config: any) {
    if (columnData.type === CF_INPUT_TYPE.SELECT) {
      if (columnData.options && columnData.options.length > 0) {
        const found = columnData.options.find((option: any) => {
          return option.defaultOption;
        });
        if (found) {
          return found?.name ? found.name : '';
        } else {
          return '';
        }
      }
    } else {
      return Utility.isNullish(config.defaultValue) ? '' : config.defaultValue;
    }
  }

  static createCustomFieldForDocument(
    columnData: IColumn,
    moduleNames: MODULES_NAME[],
    parentCfDetails: any,
    config?: any
  ) {
    const customFieldPayload: CustomFieldPayload = {
      defaultValue: CustomFieldService.getDefaultValue(columnData, config),
      fieldType: CustomFieldService.getFieldType(columnData.type),
      label: columnData.name,
      mandatory: columnData.required as boolean,
      modules: moduleNames
    };

    if (columnData.type === CF_INPUT_TYPE.TEXT) {
      customFieldPayload.maxLength = config?.maxLength || 100;
    }

    if (columnData.type === CF_INPUT_TYPE.NUMBER) {
      customFieldPayload.decimalPrecision = config?.decimalPrecision || 2;
    }

    if (columnData.type === CF_INPUT_TYPE.SELECT) {
      if (columnData.options && columnData.options.length > 0) {
        customFieldPayload.attributes = columnData.options.map((option) => {
          const data: any = {
            defaultOption: option.defaultOption,
            status: option.status,
            value: option.name
          };

          if (
            parentCfDetails?.attributes &&
            option.selectedInd &&
            option.selectedInd.length > 0
          ) {
            let ids = '';
            option.selectedInd.forEach((ind) => {
              const customFieldDetails = parentCfDetails.attributes[ind];
              ids = ids + customFieldDetails.id + ',';
            });
            ids = ids.substring(0, ids.length - 1);
            data.parentId = ids;
          }
          return data;
        });
        customFieldPayload.parent = parentCfDetails;
      } else {
        customFieldPayload.attributes = [];
      }
    }

    return new Promise((resolve, reject) => {
      http2
        .post(
          `${ApiConstants.URL.CUSTOM_FIELD.CREATE_CUSTOM_FIELD}`,
          customFieldPayload
        )
        .then((customFieldRes: any) => {
          TableService.getAllViews()
            .then((res: any) => {
              Utility.setupColumConfigs(res);
              resolve({ customFieldRes });
            })
            .catch((err) => {
              console.log(err);
            });
          // if (tableId !== undefined && tableId !== null && tableId !== '') {
          //   TableService.getTable(tableId)
          //     .then((res: any) => {
          //       resolve({ customFieldRes, columnConfig: res.columnsMetaData });
          //     })
          //     .catch((error) => {
          //       reject(error);
          //     });
          // } else {
          //   // There are certain modules which are not using DKDatagrid but has Custom Fields
          //   resolve({ customFieldRes });
          // }
        })
        .catch((error) => {
          console.log(error, 'Error');
          reject(error);
        });
    });
  }

  static updateCustomFieldForDocument(
    columnData: IColumn,
    moduleNames: MODULES_NAME[],
    parentCfDetails: any,
    config?: any,
    tableId?: any,
    columnConfig?: any[],
    customFieldToUpdate?: any
  ) {
    let customFieldPayload = customFieldToUpdate;
    customFieldPayload = {
      ...customFieldPayload,
      defaultValue: CustomFieldService.getDefaultValue(columnData, config),
      fieldType: CustomFieldService.getFieldType(columnData.type),
      maxLength: config.maxLength || 100,
      label: columnData.name,
      mandatory: columnData.required,
      modules: moduleNames
    };

    if (columnData.type === CF_INPUT_TYPE.SELECT) {
      if (columnData.options && columnData.options.length > 0) {
        customFieldPayload.attributes = columnData.options.map((option) => {
          const data: any = {
            defaultOption: option.defaultOption,
            status: option.status,
            value: option.name
          };

          if (
            customFieldToUpdate?.attributes &&
            customFieldToUpdate.attributes.length > 0
          ) {
            const customFieldDetail = customFieldToUpdate.attributes.find(
              (attr: any) => attr.id === option.id
            );

            if (customFieldDetail) {
              data.code = customFieldDetail.code;
            }
          }

          if (
            parentCfDetails?.attributes &&
            option.selectedInd &&
            option.selectedInd.length > 0
          ) {
            let ids = '';
            option.selectedInd.forEach((ind) => {
              const customFieldDetails = parentCfDetails.attributes[ind];
              ids = ids + customFieldDetails.id + ',';
            });
            ids = ids.substring(0, ids.length - 1);
            data.parentId = ids;
          }
          return data;
        });
        customFieldPayload.parent = parentCfDetails;
      } else {
        customFieldPayload.attributes = [];
      }
    }

    return new Promise((resolve, reject) => {
      http2
        .put(
          `${ApiConstants.URL.CUSTOM_FIELD.UPDATE_CUSTOM_FIELD(
            customFieldToUpdate.id
          )}`,
          customFieldPayload
        )
        .then((customFieldRes: any) => {
          TableService.getAllViews()
            .then((res: any) => {
              Utility.setupColumConfigs(res);
              resolve({ customFieldRes });
            })
            .catch((err) => {
              console.log(err);
            });
          // if (tableId !== undefined && tableId !== null && tableId !== '') {
          //   TableService.getTable(tableId)
          //     .then((res: any) => {
          //       resolve({ customFieldRes, columnConfig: res.columnsMetaData });
          //     })
          //     .catch((error) => {
          //       reject(error);
          //     });
          // } else {
          //   // There are certain modules which are not using DKDatagrid but has Custom Fields
          //   resolve({ customFieldRes });
          // }
        })
        .catch((error) => {
          if (!!error?.data?.errorMessage) {
            if (error.data?.errorMessage?.includes('is already present')) {
              showAlert(
                'Error',
                `Field with name "${columnData.name}" is already present.`
              );
            } else {
              showAlert('Error!', error?.data?.errorMessage);
            }
          }
          reject(error);
        });
    });
  }

  static deleteCustomField(
    customFieldId: number,
    columnData: any,
    tableId: string,
    columnConfigList: IColumn[]
  ) {
    return new Promise((resolve, reject) => {
      return http
        .delete(
          `${ApiConstants.URL.CUSTOM_FIELD.DELETE_CUSTOM_FIELD(customFieldId)}`
        )
        .then((res: any) => {
          TableService.getAllViews()
            .then((res: any) => {
              Utility.setupColumConfigs(res);
              resolve(res);
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  static getCustomFields(param?: any, avoidCancelPreviousRequest?: boolean) {
    if (!avoidCancelPreviousRequest) {
      Utility.cancelRequest(CustomFieldService.abortController);
    }
    let finalURL =
      ApiConstants.URL.CUSTOM_FIELD.FETCH +
      `?query=module=${param?.module ? param?.module : ''},fieldType=${
        param.fieldType ? param.fieldType : ''
      },isSystem=${
        typeof param.isSystem === 'boolean' ? param.isSystem : ''
      },status=${param.status ? param.status : ''}&search=${
        param?.searchTerm ? param?.searchTerm : ''
      }&limit=${param.limit}&page=${param.page ? param.page : ''}&sort=${
        param.sort ? param.sort : ''
      }&sortDir=${param.sortDir ? param.sortDir : ''}`;

    CustomFieldService.abortController = avoidCancelPreviousRequest
      ? null
      : Utility.createAbortController();
    return http
      .get(finalURL, {
        signal: avoidCancelPreviousRequest
          ? null
          : CustomFieldService.abortController.signal
      })
      .then(
        (res: any) => {
          return Promise.resolve(res);
        },
        (error) => {
          return Promise.reject(error);
        }
      )
      .catch((err) => {
        return Promise.reject(err);
      });
  }

  static getCustomFieldById(id: number): Promise<any> {
    return http2.get(ApiConstants.URL.CUSTOM_FIELD.FETCH + `/${id}`);
  }

  static updateCustomField(
    id: number,
    payload: any,
    tableId = ''
  ): Promise<{ res: any; newColumnConfig: any }> {
    return http2
      .put(`${ApiConstants.URL.CUSTOM_FIELD.UPDATE_CUSTOM_FIELD(id)}`, payload)
      .then((cfResponse) => {
        return TableService.getTable(tableId)
          .then((res: any) => {
            return Promise.resolve({
              res: cfResponse,
              newColumnConfig: res.columnsMetaData
            });
          })
          .catch((err) => {
            return Promise.reject(err);
          });
      });
  }

  static updateCustomFieldStatus(
    id: number,
    payload: any,
    tableId = ''
  ): Promise<{ res: any; newColumnConfig: any }> {
    return http2
      .patch(
        `${ApiConstants.URL.CUSTOM_FIELD.UPDATE_CUSTOM_FIELD(id)}`,
        payload
      )
      .then((res: any) => {
        TableService.getAllViews()
          .then((res: any) => {
            Utility.setupColumConfigs(res);
          })
          .catch((err) => {
            console.log(err);
          });
        return Promise.resolve(res);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }

  static getCustomFieldUrl(
    searchTerm: string,
    fieldType: string,
    limit: number = 10
  ) {
    const { BASE, CUSTOM_FIELD } = ApiConstants.URL;
    const url = `${BASE}${CUSTOM_FIELD.FETCH.slice(
      CUSTOM_FIELD.FETCH.startsWith('/') ? 1 : 0
    )}`;

    return `${url}?query=fieldType=${fieldType}&search=${
      searchTerm ? encodeURIComponent(searchTerm) : ''
    }&limit=${limit}`;
  }

  static updateCustomFieldOrder = (payload: any) => {
    return http2
      .patch(`${ApiConstants.URL.CUSTOM_FIELD.UPDATE_CF_ORDER}`, payload)
      .then((res) => {
        return Promise.resolve(res);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  };

  static getCustomFieldsByModules(modules?: any[]) {
    if (modules && modules?.length > 0) {
      let body = {
        application: 'ERP',
        modules: modules
      };

      return http
        .post(ApiConstants.URL.CUSTOM_FIELD.FETCH_BY_MODULES, body)
        .then(
          (res: any) => {
            return Promise.resolve(res);
          },
          (error) => {
            return Promise.reject(error);
          }
        )
        .catch((err) => {
          return Promise.reject(err);
        });
    }
  }
}
export const mapCFToColumns = (
  availableCustomFields: any[],
  columnConfigs: IColumn[],
  fieldsToMap: any[]
): any => {
  const customFields: any = {};
  fieldsToMap?.forEach((cf: any) => {
    const columnCode: string = `${COLUMN_TYPE.CUSTOM}_${cf.code}_${cf.id}`;
    const colType = columnConfigs.find(
      (config) => config.columnCode === columnCode
    )?.type;
    const cfInAvailableCF = availableCustomFields?.find(
      (field: any) => field.id === cf.id
    );
    const isUserTypeCF = cfInAvailableCF
      ? cfInAvailableCF.fieldType === CUSTOM_FIELD_TYPE.USER
      : false;
    let cfValue = '';
    if (isUserTypeCF) {
      const tempCF = cfInAvailableCF?.attributes?.find(
        (attr: any) => attr.code === cf.value
      );
      cfValue = tempCF ? tempCF.value : '';
    } else {
      cfValue = cf ? cf.value : '';
    }
    customFields[columnCode] =
      colType === INPUT_TYPE.SELECT ? [cfValue] : cfValue;
  });
  return customFields;
};
export const isCustomFieldsValid = (customFields: any[] = []): boolean => {
  let customFieldValid = true;
  for (let i = 0; i < customFields?.length; i++) {
    if (customFields[i].mandatory) {
      customFieldValid = Utility.isNotEmpty(customFields[i]?.value);
      if (!customFieldValid) break;
    }
  }
  return customFieldValid;
};

export default CustomFieldService;
