import { useContext, useEffect, useState } from 'react';
import {
  DKInput,
  DKLabel,
  INPUT_TYPE,
  INPUT_VIEW_DIRECTION
} from 'deskera-ui-library';
import { CommonDraftPropsContext } from '../../Utilities/DocContext';
import { useAppDispatch, useAppSelector } from '../../../../Redux/Hooks';
import {
  selectDocumentFormDataByKeys,
  updateMultipleKeysInDocument
} from '../../../../Redux/Slices/DocumentSlice';
import { activeTenantInfo } from '../../../../Redux/Slices/AuthSlice';
import {
  fetchPriceBookList,
  priceBookSelector
} from '../../../../Redux/Slices/PriceBookSlice';
import Utility from '../../../../Utility/Utility';
import {
  DEFAULT_PRICE_BOOK,
  DEFAULT_PRICE_BOOK_ID,
  getMainModuleName
} from '../../../../SharedComponents/DocumentForm/NewDocumentHelper';
import { DOCUMENT_KEYS } from '../../Utilities/DocConstants';
import {
  CHANGE_TYPE,
  updatePriceFromPriceList
} from '../../Helper/DocumentUpdates/PriceListHelper';
import { selectContactsColumnConfig } from '../../../../Redux/Slices/ContactsSlice';

interface PriceBookSelectorProps {
  className?: string;
}
const PriceBookSelector = (props: PriceBookSelectorProps) => {
  const { draftId, documentMode, draftType } = useContext(
    CommonDraftPropsContext
  );
  const [documentType, priceListId] = useAppSelector(
    selectDocumentFormDataByKeys(draftId, [
      DOCUMENT_KEYS.DOCUMENT_TYPE,
      DOCUMENT_KEYS.PRICE_LIST_ID
    ])
  );

  const dispatch = useAppDispatch();
  const tenantInfo = useAppSelector(activeTenantInfo);
  const priceBookList = useAppSelector(priceBookSelector);
  const contactColumnConfig = useAppSelector(selectContactsColumnConfig);

  const [selectedPriceBook, setSelectedPriceBook] = useState<any>({
    id: 0,
    name: 'Default'
  });
  const [priceBookOptions, setPriceBookOptions] = useState<any[]>([]);

  useEffect(() => {
    // fetch the price books if it is not available in store.
    if (tenantInfo?.isAdvancePriceListEnabled) {
      if (Utility.isEmptyObject(priceBookList)) {
        let query = `isAdvance=true`;
        dispatch(fetchPriceBookList({ search: '', limit: 500, query }));
      } else {
        let priceBook = DEFAULT_PRICE_BOOK;
        if (
          typeof priceListId !== 'undefined' &&
          priceListId !== null &&
          priceListId !== DEFAULT_PRICE_BOOK_ID
        ) {
          priceBook = priceBookList.content?.find(
            (pb: any) => pb.id === priceListId
          );
          if (!Utility.isEmpty(priceBook)) {
            setSelectedPriceBook(priceBook);
            dispatch(
              updateMultipleKeysInDocument({
                draftId,
                keysToUpdate: {
                  priceListId: priceBook.id,
                  priceListName: priceBook.name
                }
              })
            );
          }
        }
      }
    }
  }, [
    dispatch,
    draftId,
    priceBookList,
    priceListId,
    tenantInfo?.isAdvancePriceListEnabled
  ]);

  useEffect(() => {
    const mainModuleType = getMainModuleName(documentType);
    let filteredPriceBooks: any =
      priceBookList?.content?.filter(
        (priceBook: any) =>
          priceBook.active && priceBook.type === mainModuleType
      ) ?? [];
    filteredPriceBooks = [{ id: 0, name: 'Default' }, ...filteredPriceBooks];
    setPriceBookOptions(filteredPriceBooks);
  }, [documentType, priceBookList]);

  const handlePriceBookUpdate = (priceBook: any) => {
    if (!Utility.isEmpty(priceBook)) {
      dispatch(
        updateMultipleKeysInDocument({
          draftId,
          keysToUpdate: {
            priceListId: priceBook.id,
            priceListName: priceBook.name || ''
          }
        })
      );

      // Price List Call: for price book change
      updatePriceFromPriceList({
        draftId,
        documentMode,
        change: {
          type: CHANGE_TYPE.PRICE_BOOK_CHANGED,
          rowIndex: null
        },
        contactColumnConfig: contactColumnConfig
      });
    }
  };

  return (
    <div
      className={`column ${props.className || ''}`}
      style={{ width: 150, maxWidth: 200 }}
    >
      <DKInput
        title="Price Book"
        titleStyle={{ color: '#808080' }}
        required={false}
        type={INPUT_TYPE.DROPDOWN}
        value={
          !Utility.isEmptyObject(selectedPriceBook) ? selectedPriceBook : ''
        }
        direction={INPUT_VIEW_DIRECTION.VERTICAL}
        formatter={(obj: any) => {
          return obj.name;
        }}
        onChange={(value: any) => {
          if (value?.id !== selectedPriceBook?.id) {
            setSelectedPriceBook({ ...value });
            handlePriceBookUpdate({ ...value });
          }
        }}
        dropdownConfig={{
          className: '',
          style: {},
          allowSearch: priceBookOptions?.length > 5,
          searchableKey: 'name',
          data: [...priceBookOptions] || [],
          renderer: (index: any, obj: any) => {
            return <DKLabel text={`${obj.name}`} />;
          },
          onSelect: (index: any, value: any) => {}
        }}
      />
    </div>
  );
};
export default PriceBookSelector;
