import { useContext, useRef } from 'react';
import {
  DOC_TYPE,
  POPUP_CALLBACKS_TYPE,
  POPUP_CLICK_TYPE,
  POPUP_TYPE
} from '../../../../../Constants/Constant';
import { DraftTypes } from '../../../../../Models/Drafts';
import {
  BtnType,
  CallBackPayloadType,
  PopupClickActionType,
  UpdateCallBacksRefType
} from '../../../../../Models/Interfaces';
import { useAppDispatch, useAppSelector } from '../../../../../Redux/Hooks';
import { updatePopulateFormData } from '../../../../../Redux/Slices/DraftsSlice';
import BillService from '../../../../../Services/Bill';
import InvoiceService from '../../../../../Services/Invoice';
import { localizedText } from '../../../../../Services/Localization/Localization';
import PurchaseOrderService from '../../../../../Services/PurchaseOrder';
import QuotationService from '../../../../../Services/Quotation';
import RequisitionService from '../../../../../Services/Requisition';
import SalesOrderService from '../../../../../Services/SalesOrder';
import WorkoutService from '../../../../../Services/Workout';
import FulfillmentConfirmation from '../../../../../SharedComponents/FulfillmentPopup/FulfillmentConfirmationPopup';
import PopupWrapper from '../../../../../SharedComponents/PopupWrapper';
import Utility, { deepClone } from '../../../../../Utility/Utility';
import {
  FULFILLMENT_STATUS,
  RECEIVED_GOODS_STATUS
} from '../../../../Automation/AutomationConstants';
import { getUpdatedBillObject } from '../../../../Bills/BillHelper';
import { getUpdatedInvoiceObject } from '../../../../Invoices/InvoiceHelper';
import { getJobWorkOutObject } from '../../../../Mrp/Workout/WorkOutHelper';
import { getUpdatedPurchaseOrderObject } from '../../../../PurchaseOrders/PurchaseOrderHelper';
import { getUpdatedQuoteObject } from '../../../../Quotations/QuoteHelper';
import { getUpdatedRequisitonOrderObject } from '../../../../Requisitions/RequisitionHelper';
import { getUpdatedSalesOrderObject } from '../../../../SalesOrders/SalesOrderHelper';
import { CommonDraftPropsContext } from '../../../Utilities/DocContext';
import {
  selectCurrentActiveDocument,
  selectDocumentFormDataByKeys
} from '../../../../../Redux/Slices/DocumentSlice';
import { DOCUMENT_KEYS } from '../../../Utilities/DocConstants';
import { useTranslation } from 'react-i18next';

export const FulfillmentConfirmationActionPopup = (props: any) => {
  const dispatch = useAppDispatch();
  const { draftId, documentMode, draftType } = useContext(
    CommonDraftPropsContext
  );
  const booksDocument = useAppSelector(selectCurrentActiveDocument(draftId));
  const [documentType] = useAppSelector(
    selectDocumentFormDataByKeys(draftId, [DOCUMENT_KEYS.DOCUMENT_TYPE])
  );
  const { t, i18n } = useTranslation();
  const refInitialState: UpdateCallBacksRefType = {
    pushDataToParent: { type: POPUP_CALLBACKS_TYPE.NONE },
    storeCallbacksRef: { updateInvoice: 'click' }
  };
  const addDocRef = useRef<UpdateCallBacksRefType>(refInitialState);

  const catchClicks = (data: PopupClickActionType) => {
    switch (data.type) {
      case POPUP_CLICK_TYPE.CLOSE_POPUP:
        props.removePopup();
        break;
      case POPUP_CLICK_TYPE.FULFILLMENT:
        addDocRef.current?.storeCallbacksRef.fulfillment();
        break;
    }
  };
  const paymentRecordsBtnConfig: BtnType[] = [
    {
      title: t(`Close`),
      class: 'border-m mr-s',
      clickAction: POPUP_CLICK_TYPE.CLOSE_POPUP
    }
  ];
  const setPopupTitle = (docType: DOC_TYPE) => {
    switch (docType) {
      case DOC_TYPE.INVOICE:
        return 'Invoice';
      case DOC_TYPE.SALES_ORDER:
        return 'Sales Order';
      case DOC_TYPE.QUOTE:
        return Utility.isUSorg() ? 'Estimate' : 'Quotation';
      case DOC_TYPE.BILL:
        return 'Bill';
      case DOC_TYPE.ORDER:
        return 'Purchase Order';
      case DOC_TYPE.REQUISITION:
        return 'Requisition';
      case DOC_TYPE.JOB_WORK_OUT_ORDER:
        return 'Job Work Out';
      default:
        break;
    }
  };

  const updateDocument = (doc: any, paymentUpdated: boolean) => {
    let draftType;

    if (paymentUpdated) {
      if (doc.knockoffInfo) {
        if (doc.knockoffInfo.length) {
          draftType = DraftTypes.READONLY;
        } else {
          draftType = DraftTypes.UPDATE;
        }
      } else {
        draftType = DraftTypes.UPDATE;
      }
    } else {
      if (
        doc.documentType === DOC_TYPE.INVOICE ||
        doc.documentType === DOC_TYPE.QUOTE ||
        doc.documentType === DOC_TYPE.SALES_ORDER ||
        doc.documentType === DOC_TYPE.REQUISITION ||
        doc.documentType === DOC_TYPE.JOB_WORK_OUT_ORDER
      ) {
        if (doc.fulfillmentStatus === FULFILLMENT_STATUS.UNFULFILLED) {
          draftType = DraftTypes.UPDATE;
        } else {
          draftType = DraftTypes.READONLY;
        }
      }

      if (doc.documentType === DOC_TYPE.BILL) {
        if (doc.receiveGoodsStatus === RECEIVED_GOODS_STATUS.NOT_RECEIVED) {
          draftType = DraftTypes.UPDATE;
        } else {
          draftType = DraftTypes.READONLY;
        }
      }

      if (doc.documentType === DOC_TYPE.ORDER) {
        if (doc.receiptStatus === RECEIVED_GOODS_STATUS.NOT_RECEIVED) {
          draftType = DraftTypes.UPDATE;
        } else {
          draftType = DraftTypes.READONLY;
        }
      }
    }

    dispatch(
      updatePopulateFormData({
        id: draftId,
        formdata: doc,
        draftType: draftType,
        actionFromDocument: true
      })
    );
  };

  const parentChildInteraction = (passingData: CallBackPayloadType) => {
    //TODO - check these props for all action popups
    const paymentUpdated = props.showPaymentPopup || props.showPaymentRecord;
    switch (passingData.type) {
      case POPUP_CALLBACKS_TYPE.CLOSE_POPUP:
        if (documentType === DOC_TYPE.INVOICE) {
          booksDocument?.salesInvoiceCode &&
            InvoiceService.getInvoiceByCode(
              booksDocument?.salesInvoiceCode
            ).then(
              (invoice: any) => {
                let invoiceDetailsData: any = getUpdatedInvoiceObject(invoice);
                updateDocument(invoiceDetailsData, paymentUpdated);
                props.removePopup();
              },
              (err) => {
                console.error('Error loading updated invoice: ', err);
              }
            );
        }
        if (documentType === DOC_TYPE.BILL) {
          BillService.fetchBillDetails(booksDocument?.purchaseInvoiceCode).then(
            (bill: any) => {
              const billDetailsData: any = getUpdatedBillObject(bill);
              updateDocument(billDetailsData, paymentUpdated);
              props.removePopup();
            },
            (err) => {
              console.error('Error loading updated bill: ', err);
            }
          );
        }
        if (documentType === DOC_TYPE.QUOTE) {
          QuotationService.getQuoteByCode(booksDocument?.quotationCode).then(
            (data: any) => {
              const quoteDetailsData: any = getUpdatedQuoteObject(data);
              updateDocument(quoteDetailsData, paymentUpdated);
              props.removePopup();
            },
            (err) => {
              console.error('Error loading updated quotation: ', err);
            }
          );
        }
        if (documentType === DOC_TYPE.ORDER) {
          PurchaseOrderService.fetchOrderDetails(booksDocument?.poCode).then(
            (data: any) => {
              const poDetailsData: any = getUpdatedPurchaseOrderObject(data);
              updateDocument(poDetailsData, paymentUpdated);
              props.removePopup();
            },
            (err) => {
              console.error('Error loading Purchase order: ', err);
            }
          );
        }
        if (documentType === DOC_TYPE.REQUISITION) {
          RequisitionService.fetchOrderDetails(
            booksDocument?.purchaseRequestCode
          ).then(
            (data: any) => {
              const poDetailsData: any = getUpdatedRequisitonOrderObject(data);
              updateDocument(poDetailsData, paymentUpdated);
              props.removePopup();
            },
            (err) => {
              console.error('Error loading requisition order: ', err);
            }
          );
        }

        if (documentType === DOC_TYPE.SALES_ORDER) {
          SalesOrderService.getSalesOrderByCode(
            booksDocument?.salesOrderCode
          ).then(
            (data: any) => {
              const salesOrderDetailsData: any =
                getUpdatedSalesOrderObject(data);
              updateDocument(salesOrderDetailsData, paymentUpdated);
              props.removePopup();
            },
            (err) => {
              console.error('Error loading updated order: ', err);
            }
          );
        }

        if (documentType === DOC_TYPE.JOB_WORK_OUT_ORDER) {
          WorkoutService.fetchJobWorkOutDetails(booksDocument?.jwoCode).then(
            (data: any) => {
              const jobWorkOutDetailsData: any = getJobWorkOutObject(data);
              updateDocument(jobWorkOutDetailsData, paymentUpdated);
              props.removePopup();
            },
            (err) => {
              console.error('Error loading updated Job Work Out: ', err);
            }
          );
        }
        break;
      case POPUP_CALLBACKS_TYPE.DIRECT_FULFILLMENT:
        if (props.passingInteraction) {
          props.passingInteraction(
            {
              type: passingData.type
            },
            passingData.data
          );
        }
        if (props.directFulfillmentClicked) {
          props.directFulfillmentClicked();
        }
        break;
      case POPUP_CALLBACKS_TYPE.FULFILLMENT:
        addDocRef.current.storeCallbacksRef.fulfillment = passingData.data;
        break;
    }
  };

  return (
    <PopupWrapper
      clickAction={catchClicks}
      type={POPUP_TYPE.POPUP}
      title={`${localizedText('Fulfill Your')} ${setPopupTitle(documentType)}`}
      width={'460px'}
      height={'auto'}
      overflowVisible={true}
      disableClickOutside={true}
      btnList={paymentRecordsBtnConfig}
    >
      <FulfillmentConfirmation
        openDraftId={draftId}
        documentDetails={deepClone(booksDocument)}
        fulfillmentType={booksDocument?.fulfillmentType}
        passingInteraction={(callback: CallBackPayloadType, data: any) => {
          parentChildInteraction(callback);
        }}
        closePopup={() => {
          props.removePopup();
        }}
        closeMenuContainer={() => {
          if (props.closeMenuContainer) {
            props.closeMenuContainer();
          }
        }}
        documentType={documentType}
      />
    </PopupWrapper>
  );
};
