import { DOC_TYPE, FULFILLMENT_TYPE } from '../../../../Constants/Constant';
import { LocationDTO } from '../../../../Models/Interfaces';
import AuthService from '../../../../Services/Auth';
import {
  isDropShipDocument,
  isPurchaseDocument,
  isSalesDocument
} from '../../Utilities/DocCommonUtils';
import { updateMultipleKeysInDocument } from '../../../../Redux/Slices/DocumentSlice';
import { Store } from '../../../../Redux/Store';
import Utility from '../../../../Utility/Utility';
import { DOCUMENT_KEYS } from '../../Utilities/DocConstants';

/**
 * Handler for location CF change
 */
export const onLocationCFChange = (data: {
  draftId: number;
  documentType: DOC_TYPE;
  locCF: LocationDTO;
  dropShip: boolean;
  fulfillmentType: FULFILLMENT_TYPE;
}) => {
  const { draftId, documentType, locCF, dropShip, fulfillmentType } = data;
  const tenantInfo = AuthService.currentTenantInfo;
  let keysToUpdate: any = {};

  // Set defaults, if "None" is selected
  if (Utility.isEmpty(locCF)) {
    const orgPreferredBillingAddress: any = tenantInfo.billingAddresses?.find(
      (address: any) => address.preferred
    );
    const orgPreferredShippingAddress: any = tenantInfo.shippingAddresses?.find(
      (address: any) => address.preferred
    );
    if (isSalesDocument(documentType)) {
      keysToUpdate = {
        shipFrom: orgPreferredShippingAddress
      };
    } else if (isPurchaseDocument(documentType)) {
      const isDocDropShip = isDropShipDocument({ dropShip, fulfillmentType });
      if (!isDocDropShip) {
        keysToUpdate = {
          billTo: orgPreferredBillingAddress,
          shipTo: orgPreferredShippingAddress
        };
      } else {
        keysToUpdate = {
          billTo: orgPreferredBillingAddress
        };
      }
    }
    if (!Utility.isEmpty(keysToUpdate)) {
      // Update the store
      Store.dispatch(updateMultipleKeysInDocument({ draftId, keysToUpdate }));
    }
  }

  Store.dispatch(
    updateMultipleKeysInDocument({
      draftId,
      keysToUpdate: { [DOCUMENT_KEYS.CUSTOM_LOCATION_CF_VALUE]: locCF }
    })
  );
};
