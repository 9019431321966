import { useContext } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../Redux/Hooks';
import { CommonDraftPropsContext } from '../../../Utilities/DocContext';
import {
  selectCurrentActiveDocument,
  selectDocumentFormDataByKeys
} from '../../../../../Redux/Slices/DocumentSlice';
import { DOCUMENT_KEYS } from '../../../Utilities/DocConstants';
import { useTranslation } from 'react-i18next';
import {
  BtnType,
  PopupClickActionType,
  UpdateCallBacksRefType
} from '../../../../../Models/Interfaces';
import {
  POPUP_CALLBACKS_TYPE,
  POPUP_CLICK_TYPE,
  POPUP_TYPE
} from '../../../../../Constants/Constant';
import PopupWrapper from '../../../../../SharedComponents/PopupWrapper';
import SalesOrderToInvoicePopup from '../../../../SalesOrders/SalesOrderToInvoice';
import { removeDraft } from '../../../../../Redux/Slices/DraftsSlice';
import { deepClone } from '../../../../../Utility/Utility';

export const SalesOrderToInvoiceActionPopup = (props: any) => {
  const dispatch = useAppDispatch();
  const { draftId, documentMode, draftType } = useContext(
    CommonDraftPropsContext
  );
  const [documentType] = useAppSelector(
    selectDocumentFormDataByKeys(draftId, [DOCUMENT_KEYS.DOCUMENT_TYPE])
  );

  const documentObject = useAppSelector(selectCurrentActiveDocument(draftId));
  const { t, i18n } = useTranslation();
  const refInitialState: UpdateCallBacksRefType = {
    pushDataToParent: { type: POPUP_CALLBACKS_TYPE.NONE },
    storeCallbacksRef: { updateInvoice: 'click' }
  };
  const convertPopupBtnConfig: BtnType[] = [
    {
      title: 'Close',
      class: 'border-m mr-s bg-white',
      clickAction: POPUP_CLICK_TYPE.CLOSE_POPUP
    }
  ];

  const catchClicks = (data: PopupClickActionType) => {
    switch (data.type) {
      case POPUP_CLICK_TYPE.CLOSE_POPUP:
        props.removePopup();
        break;
    }
  };

  const getDocObjectForConversion = () => {
    let doc: any = deepClone(documentObject);
    doc = {
      ...doc,
      items: doc?.items?.map((item: any) => ({
        ...item,
        advancedTracking: item?.product?.advancedTracking
      }))
    };
    return doc;
  };

  return (
    <PopupWrapper
      clickAction={catchClicks}
      type={POPUP_TYPE.POPUP}
      title="Convert to Invoice"
      btnList={convertPopupBtnConfig}
      width={'600px'}
      height={'auto'}
      disableClickOutside={true}
      overflowVisible={true}
    >
      <SalesOrderToInvoicePopup
        salesOrderDocument={getDocObjectForConversion()}
        closePopup={() => {
          dispatch(removeDraft(draftId));
          if (props.closeMenuContainer) {
            props.closeMenuContainer();
          }
        }}
      />
    </PopupWrapper>
  );
};
