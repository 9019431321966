import { DOCUMENT_MODE, LABELS } from './../Constants/Constant';
import { Document } from './Document';

export enum DraftTypes {
  NEW = 'new',
  UPDATE = 'update',
  DRAFT = 'draft',
  COPY = 'copy',
  READONLY = 'read-only'
}

export const DOCUMENT_TYPE_LIST = [
  LABELS.PRODUCTS,
  LABELS.CONTACT,
  LABELS.INVOICES,
  LABELS.BILLS,
  LABELS.PURCHASE_ORDERS,
  LABELS.WORK_OUT,
  LABELS.QUOTES,
  LABELS.SALES_ORDER,
  LABELS.EXPENSE_BILL,
  LABELS.REQUISITION,
  LABELS.SUPPLIER_QUOTE
];

export const UPDATE_TYPES = {
  SAVE_POPULATE_DATA: 'save_populate_data',
  UPDATE: 'update'
};

/* ******** New Draft Builder Types ********* */
export interface IDraftDocBuilderCommonProps {
  draftId: number;
  draftType: DraftTypes;
  documentMode: DOCUMENT_MODE;
  isCashInvoice?: boolean;
}

export interface AnyDocument extends Document {
  [k: string]: any;
}
export interface IDocument<T = AnyDocument> {
  id: number;
  title?: string;
  isMaximized: boolean;
  /** @deprecated: remove usage, as it's duplicate of isMaximized */
  isCenterAlign: boolean;
  populateFormData: T;
  /** @deprecated: remove usage of "data" key, as redundant */
  data?: unknown;
  isSaved: boolean;
  type: string; // type is evaluated from LABELS object
  draftType: DraftTypes;
  isLoading: boolean;
  canValidate: boolean;
  hideMinimizer?: boolean;
  isErrorOnSave?: boolean;
  draftCode?: string; // used to set draft sequence code
  isCashInvoice?: boolean;
}
