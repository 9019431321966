
import ComponentManager from "../../../Manager/ComponentManager"
import { getLocalisedText } from "../../../Translate/LanguageManager"
import Utility, { getIsInvoice, getIsSalesOrder, getVW } from "../../../Utilities/Utility"
import { ColumnStyle } from "../ColumnStyle"
import { HeaderColumn } from "../HeaderColumn"
import { ItemColumn } from "../ItemColumn"
import { TableOption } from "../TableOption"
import { FooterRow } from "../FooterRow"
import { StockIssueTableWidth } from "./StockIssueTableWidth"
import NumberUtility from "../../../Utilities/NumberUtility"
import { FONT_SIZE, PRINT_SCALE, TableDimensionType, TableColumnPopupType, CONSTANTS } from "../../../Constants/Constants"
import TemplateSettingsManager from "../../../Manager/TemplateSettingsManager"
import { TableHeader } from "../TableHeader"
import { getIsSelectTotalColumn, getSortedHeader, getSortedLineItems, getTablePadding } from "../TableUtility"
import TableFooterUtility from "../../../Utilities/TableFooterUtility"
import TableColumnAlignmentParser from "../../../Helper/TableColumnAlignmentParser"
import { StockIssueTableItem } from "./StockIssueTableItem"
import { ProductTableGroupHeaderColumn } from "../product/ProductTableGroupHeaderColumn"

export class StockIssueTable {
    static footerColumnList: HeaderColumn[]
    static groupHeaderIndexList: number[]
    static getTableOption(canUserEdit: boolean) {
        var tableOption = new TableOption()
        tableOption.isShowBorder = true
        tableOption.isShowFooter = true
        tableOption.isShowSortButton = canUserEdit
        tableOption.isReceiptFooter = Utility.getIsReceiptPaperSize()
        return tableOption
    }

    static getDefaultHeaderColumn(tableStyle: any, isStock: boolean, printScale: number = PRINT_SCALE) {
        var newHeaderColumn = new HeaderColumn()
        newHeaderColumn.type = 'string'
        newHeaderColumn.style = new ColumnStyle()
        newHeaderColumn.style.backgroundColor = tableStyle.headerBackgroundColor
        newHeaderColumn.style.borderColor = tableStyle.headerBorderColor
        newHeaderColumn.style.fontColor = tableStyle.headerTextColor
        newHeaderColumn.style.borderStyle = 'solid'
        newHeaderColumn.style.borderWidth = getVW(Utility.convertToPrintScale(1, printScale))
        if (tableStyle.headerBorderBottomColor !== undefined) {
            newHeaderColumn.style.borderBottomColor = tableStyle.headerBorderBottomColor
            newHeaderColumn.style.borderBottomWidth = tableStyle.headerBorderBottomWidth
        }
        newHeaderColumn.style.paddingTop = isStock ? getVW(Utility.convertToPrintScale(2.5, printScale)) : getTablePadding(getVW(Utility.convertToPrintScale(11, printScale)), TableDimensionType.PADDING_VERTICAL)
        newHeaderColumn.style.paddingBottom = isStock ? getVW(Utility.convertToPrintScale(2.5, printScale)) : getTablePadding(getVW(Utility.convertToPrintScale(11, printScale)), TableDimensionType.PADDING_VERTICAL)
        newHeaderColumn.style.paddingLeft = isStock ? getVW(Utility.convertToPrintScale(2.5, printScale)) : getTablePadding(getVW(Utility.convertToPrintScale(11, printScale)), TableDimensionType.PADDING_HORIZONTAL)
        newHeaderColumn.style.paddingRight = isStock ? getVW(Utility.convertToPrintScale(2.5, printScale)) : getTablePadding(getVW(Utility.convertToPrintScale(11, printScale)), TableDimensionType.PADDING_HORIZONTAL)
        newHeaderColumn.style.fontSize = Utility.getIsReceiptPaperSize() ? FONT_SIZE.large : FONT_SIZE.regular
        newHeaderColumn.style.fontWeight = 'bold'
        return newHeaderColumn
    }

    static getHeaderColumnList(columnOption: any, tableStyle: any, rawData: any) {
        var headerColumnList: HeaderColumn[] = []
        if (columnOption === undefined || columnOption === null) {
            return
        }
        if (columnOption.length === 0) {
            return
        }
        var sortedHeader = getSortedHeader(columnOption);
        var defaultTableColumnAlignmentList = TableColumnAlignmentParser.getDefaultAlignmentForProduct()
        var tableColumnAlignmentList = TableColumnAlignmentParser.getTableColumnAlignment()
        var tableColumnVerticalAlignmentList = TableColumnAlignmentParser.getTableColumnVerticalAlignment()
        var footerList = TableFooterUtility.getFooterListByType('product')
        if (getIsInvoice(rawData.type) || getIsSalesOrder(rawData.type)) {
            var index = footerList.indexOf(TableColumnPopupType.totalAmount);
            footerList.splice(index !== -1 ? index + 1 : 5, 0, TableColumnPopupType.cogs);
        }
        if (sortedHeader.length > 0) {
            sortedHeader.forEach(element => {
                if (!footerList.includes(element.type)) {
                    if (element.globalDiscountAccountCode !== undefined) {
                        element.code = element.globalDiscountAccountCode
                    }
                    var newHeaderColumn = this.getDefaultHeaderColumn(tableStyle, false, rawData.printScale)
                    newHeaderColumn = this.updateHeaderColumn(newHeaderColumn, new TableHeader(element), rawData, defaultTableColumnAlignmentList, tableColumnAlignmentList, tableColumnVerticalAlignmentList)
                    headerColumnList.push(newHeaderColumn)
                }
            });
        }

        var width = columnOption[0].width

        if (width === undefined || width === null || this.getIsResetRequiredForColumnWidth(columnOption)) {
            var newColumnOption = columnOption
            var stockIssueTableWidth = this.getHeaderColumnWidth(headerColumnList)
            headerColumnList.forEach(element => {
                element.style.width = this.getHeaderItemColumnWidth(element.name, stockIssueTableWidth, element.code) + '%'
            });

            newColumnOption.forEach((element: any) => {
                element.width = this.getHeaderItemColumnWidth(element.type, stockIssueTableWidth, element.code) + '%'
            });

            TemplateSettingsManager.updateTablePopupInfo(newColumnOption)
        }
        else {
            headerColumnList.forEach(element => {
                var items = columnOption.filter((x: TableHeader) => x.type === element.name && x.code === element.code)
                if (items !== undefined && items !== null) {
                    if (items.length > 0) {
                        var width = items[0].width
                        if (width !== undefined) {
                            element.style.width = width
                        }
                    }
                }
            });
        }
        // headerColumnList = setLastColumnHeaderRightAlign(headerColumnList, footerList)
        return headerColumnList
    }

    static getIsResetRequiredForColumnWidth(columnOptions: any) {
        var isResetRequired = false
        var dataColumn = columnOptions.filter((x: any) => x.type !== TableColumnPopupType.discount && x.type !== TableColumnPopupType.tax)
        var selectedColumnWidth = dataColumn.filter((x: any) => x.isSelected)
        selectedColumnWidth.forEach((element: any) => {
            if (element.width === null || element.width === undefined) {
                isResetRequired = true
            }
            else if (element.width === '0%') {
                isResetRequired = true
            }
        });

        var isNotSelectedColumn = dataColumn.filter((x: any) => !x.isSelected)

        isNotSelectedColumn.forEach((element: any) => {
            if (element.width !== null && element.width !== undefined) {
                var num = Number(element.width.replace('%', ''))
                if (num > 0) {
                    isResetRequired = true
                }
            }
        });

        return isResetRequired
    }

    static updateHeaderColumn(headerColumn: HeaderColumn, tableHeader: TableHeader, data: any, defaultTableColumnAlignmentList: any, tableColumnAlignmentList: any, tableColumnVerticalAlignmentList: any) {
        headerColumn.columnOption.isShowColumn = tableHeader.isSelected
        headerColumn.name = tableHeader.type
        headerColumn.label = getLocalisedText(tableHeader.name)
        headerColumn.code = tableHeader.code
        headerColumn.style.alignment = 'center'

        var columnAlignment = defaultTableColumnAlignmentList.find((x: { type: string, alignment: string }) => x.type === tableHeader.type)
        if (columnAlignment !== undefined) {
            headerColumn.style.alignment = columnAlignment.alignment
        }

        if (TableColumnPopupType.productCustomField === tableHeader.type) {
            headerColumn.label = tableHeader.name

            if (tableHeader.code === undefined) {
                headerColumn.style.alignment = 'left'
            }
        }

        if (tableColumnAlignmentList !== undefined && tableColumnAlignmentList !== null) {
            if (tableColumnAlignmentList.length > 0) {
                var savedColumnAlignment: any = undefined
                if (tableHeader.type === TableColumnPopupType.productCustomField && tableHeader.code !== undefined) {
                    savedColumnAlignment = tableColumnAlignmentList.find((x: { type: string, alignment: string, code: string | undefined }) => x.type === tableHeader.type && x.code === tableHeader.code)
                }
                else {
                    savedColumnAlignment = tableColumnAlignmentList.find((x: { type: string, alignment: string }) => x.type === tableHeader.type)
                }
                if (savedColumnAlignment !== undefined) {
                    headerColumn.style.alignment = savedColumnAlignment.alignment
                }
            }
        }

        if (tableColumnVerticalAlignmentList !== undefined && tableColumnVerticalAlignmentList !== null) {
            if (tableColumnVerticalAlignmentList.length > 0) {
                var savedColumnVerticalAligment: any = undefined
                if (tableHeader.type === TableColumnPopupType.productCustomField && tableHeader.code !== undefined) {
                    savedColumnVerticalAligment = tableColumnVerticalAlignmentList.find((x: { type: string, alignment: string, code: string | undefined }) => x.type === tableHeader.type && x.code === tableHeader.code)
                }
                else {
                    savedColumnVerticalAligment = tableColumnVerticalAlignmentList.find((x: { type: string, alignment: string }) => x.type === tableHeader.type)
                }
                if (savedColumnVerticalAligment !== undefined) {
                    headerColumn.style.verticalAlignment = savedColumnVerticalAligment.alignment
                }
            }
        }
        return headerColumn
    }

    static getHeaderColumnWidth(headerColumnList: HeaderColumn[]) {
        var showWidthConfig = new StockIssueTableWidth()
        showWidthConfig.setIsShowWidth(headerColumnList)
        showWidthConfig.getTableHeaderWidths()
        return showWidthConfig
    }

    static getHeaderItemColumnWidth(type: string, stockIssueTableWidth: StockIssueTableWidth, code?: string): number {
        var width = 0
        var itemWidth = 0
        switch (type) {
            case TableColumnPopupType.lineNumber:
                width = stockIssueTableWidth.lineNumber
                break;
            case TableColumnPopupType.code:
                width = stockIssueTableWidth.code
                break;
            case TableColumnPopupType.productName:
                width = stockIssueTableWidth.name
                break;
            case TableColumnPopupType.description:
                width = stockIssueTableWidth.description
                break;
            case TableColumnPopupType.productNameWithDescription:
                width = stockIssueTableWidth.nameWithDescription
                break;
            case TableColumnPopupType.requiredQty:
                width = stockIssueTableWidth.requiredQty
                break;
            case TableColumnPopupType.issuedQty:
                width = stockIssueTableWidth.issuedQty
                break;
            case TableColumnPopupType.serialBatch:
                width = stockIssueTableWidth.serialBatch
                break;
            case TableColumnPopupType.sourceWarehouseName:
                width = stockIssueTableWidth.sourceWarehouseName
                break;
            case TableColumnPopupType.sourceWarehouseCode:
                width = stockIssueTableWidth.sourceWarehouseCode
                break;
            case TableColumnPopupType.targetWarehouseName:
                width = stockIssueTableWidth.targetWarehouseName
                break;
            case TableColumnPopupType.targetWarehouseCode:
                width = stockIssueTableWidth.targetWarehouseCode
                break;
            case TableColumnPopupType.uom:
                width = stockIssueTableWidth.uom
                break;
            case TableColumnPopupType.productCustomField:
                width = stockIssueTableWidth.productCustomField
                if (code !== undefined) {
                    itemWidth = stockIssueTableWidth.getProductCustomFieldWidthByCode(code)
                    if (itemWidth !== undefined) {
                        width = itemWidth
                    }
                }
                break;
            default:
                break;
        }

        return width
    }

    static getData(documentData: any) {
        var lineItems: string[][] = []
        var dummyData = [
            [getLocalisedText('item_name'), getLocalisedText("item_description"), '1', '0.00', '0.00'],
            [getLocalisedText('item_name'), getLocalisedText("item_description"), '1', '0.00', '0.00'],
            [getLocalisedText('item_name'), getLocalisedText("item_description"), '1', '0.00', '0.00'],
        ]
        if (documentData === undefined || documentData === null) {
            return dummyData
        }
        else {
            var currency = NumberUtility.getCurrency(documentData)
            if (documentData.lineItems === undefined || documentData.lineItems === null) {
                return dummyData
            }
            if (documentData.lineItems.length === 0) {
                return []
            }

            var sortedItems = getSortedLineItems(documentData.lineItems)

            //processing grouping for the for product attribute
            var groupItems = this.getGroupData(sortedItems, documentData, currency)

            groupItems.forEach((element: any) => {
                lineItems.push(element)
            });

            return lineItems
        }
    }

    static getGroupHeaderIndexList() {
        return this.groupHeaderIndexList
    }

    static getAttributeString(customFields: any) {
        var label = ''
        if (customFields !== undefined && customFields !== null) {
            if (customFields.length > 0) {
                customFields.forEach((element: any, index: number) => {
                    label = label + element.label + ' - ' + element.value
                    if (index < customFields.length - 1) {
                        label = label + ', '
                    }
                });
            }
        }
        return label
    }
    static getDefaultItemColumn(tableStyle: any, index: number, isStock: boolean, printScale: number = PRINT_SCALE) {
        var newItemColumn = new ItemColumn()
        newItemColumn.type = 'string'
        newItemColumn.style = new ColumnStyle()
        if (tableStyle.itemBackgroundColorIsAlternate) {
            newItemColumn.style.backgroundColor = (index % 2 !== 0) ? 'transparent' : tableStyle.itemBackgroundColor
        }
        else {
            newItemColumn.style.backgroundColor = tableStyle.itemBackgroundColor
        }
        newItemColumn.style.borderColor = tableStyle.itemBorderColor
        // newItemColumn.style.fontColor = tableStyle.headerTextColor
        newItemColumn.style.borderStyle = 'solid'
        newItemColumn.style.borderWidth = getVW(Utility.convertToPrintScale(1, printScale))
        newItemColumn.style.paddingTop = isStock ? getVW(Utility.convertToPrintScale(2.5, printScale)) : getTablePadding(getVW(Utility.convertToPrintScale(13, printScale)), TableDimensionType.PADDING_VERTICAL)
        newItemColumn.style.paddingBottom = isStock ? getVW(Utility.convertToPrintScale(2.5, printScale)) : getTablePadding(getVW(Utility.convertToPrintScale(12.6, printScale)), TableDimensionType.PADDING_VERTICAL)
        newItemColumn.style.paddingLeft = isStock ? getVW(Utility.convertToPrintScale(2.5, printScale)) : getTablePadding(getVW(Utility.convertToPrintScale(11, printScale)), TableDimensionType.PADDING_HORIZONTAL)
        newItemColumn.style.paddingRight = isStock ? getVW(Utility.convertToPrintScale(2.5, printScale)) : getTablePadding(getVW(Utility.convertToPrintScale(11, printScale)), TableDimensionType.PADDING_HORIZONTAL)

        if (isStock) {
            newItemColumn.style.fontSize = FONT_SIZE.xSmall
        }
        else {
            newItemColumn.style.fontSize = Utility.getIsReceiptPaperSize() ? FONT_SIZE.large : FONT_SIZE.regular
        }
        return newItemColumn
    }


    static getItemColumn(data: any, tableStyle: any, isStock: boolean = false, rawData: any) {
        var itemColumnList: ItemColumn[][] = []
        if (data === undefined) {
            return itemColumnList
        }
        if (data.length === 0) {
            return itemColumnList
        }
        var isLastRowIndex = data.length - 1
        data.forEach((column: any[], index: number) => {
            var rowList: ItemColumn[] = []
            if (isLastRowIndex === index && getIsSelectTotalColumn()) {
                column.forEach(element => {
                    var newItemColumn = this.getDefaultItemColumn(tableStyle, index, isStock, rawData.printScale)
                    newItemColumn.value = element
                    newItemColumn.isTotalColumn = true
                    rowList.push(newItemColumn)
                });
            }
            else {
                column.forEach(element => {
                    var newItemColumn = this.getDefaultItemColumn(tableStyle, index, isStock, rawData.printScale)
                    newItemColumn.value = element
                    rowList.push(newItemColumn)
                });
            }

            itemColumnList.push(rowList)
        });
        return itemColumnList
    }

    static parseTaxBreakdown(footerColumnList: HeaderColumn[], data: any, columnOption: any, tableStyle: any) {
        var newFooterColumnList = footerColumnList
        data.taxBreakdown.forEach((tax: { taxName: string }) => {
            if (tax.taxName !== 'Rounding Off') {
                newFooterColumnList.push(this.setFooterHeader(tableStyle, tax.taxName, tax.taxName, true, undefined, data.printScale))
            }
        });

        return newFooterColumnList
    }

    static getFooterData(documentData: any) {
        var footerValue: string[] = []
        return footerValue
    }


    static getFooterRow(data: any, tableColumnOptions: TableOption, tableStyle: any) {
        var keyValue: FooterRow[] = []
        return keyValue
    }


    static isValidTaxBreakdownAmount(data: any, roundingOffValue: number, taxAmount: number) {
        var total = ComponentManager.getTotalOfLineItems(data.lineItems, false, false, roundingOffValue)
        if (data.total !== undefined) {
            total = data.total
        }

        var subTotal = ComponentManager.getTotalOfLineItems(data.lineItems, false, true, 0)

        var taxOrDiscount = total - subTotal

        if (taxAmount > taxOrDiscount) {
            return false
        }
        return true
    }

    static getFooterValue(element: HeaderColumn, data: any, roundingOffValue: number, currency: string) {
        switch (element.name) {
            case 'subtotal':
                var defaultSubtotal = Utility.toCurrencyFormat(ComponentManager.getTotalOfLineItems(data.lineItems, false, true, 0), currency)
                if (data.subTotal !== undefined && data.subTotal !== null) {
                    defaultSubtotal = Utility.toCurrencyFormat(data.subTotal, currency)
                }
                return defaultSubtotal
            case TableColumnPopupType.discount:
                var defaultDiscount = Utility.toCurrencyFormat(ComponentManager.getDiscountOnLineItems(data.lineItems), currency)
                if (data.discount !== undefined && data.discount !== null) {
                    defaultDiscount = Utility.toCurrencyFormat(data.discount, currency)
                }
                return defaultDiscount
            case TableColumnPopupType.tax:
                var defaultTax = Utility.toCurrencyFormat(ComponentManager.getTaxOnLineItems(data.lineItems), currency)
                if (data.taxAmount !== undefined && data.taxAmount !== null) {
                    defaultTax = Utility.toCurrencyFormat(data.taxAmount, currency)
                }
                return defaultTax
            case TableColumnPopupType.amount:
                var total = Utility.toCurrencyFormat(ComponentManager.getTotalOfLineItems(data.lineItems, false, false, roundingOffValue), currency)
                if (data.total !== undefined) {
                    total = Utility.toCurrencyFormat(data.total, currency)
                }
                return total
            case TableColumnPopupType.cogs:
                var cogs = getLocalisedText('na')
                if (data.cogs !== null && data.cogs !== undefined) {
                    cogs = Utility.toCurrencyFormat(data.cogs, currency)
                }
                return cogs
            case TableColumnPopupType.roundingOff:
                var roundingOff = 0
                if (data.roundingOff !== undefined) {
                    roundingOff = data.roundingOff
                }
                return Utility.toCurrencyFormat(roundingOff, currency)
            case TableColumnPopupType.amountInWords:
                return data.amountInWords ? data.amountInWords : ''
            case TableColumnPopupType.paymentFooter:
                var payment = ''
                if (ComponentManager.getIsContainsPayments(data)) {
                    if (element.index !== undefined) {
                        if (data.payments[element.index] !== undefined && data.payments[element.index] !== null && data.payments[element.index]) {
                            currency = Utility.getCurrencySymbolFromCode(data.payments[element.index].currency)
                        }
                        payment = Utility.toCurrencyFormat(data.payments[element.index].amount, currency)
                    }
                }
                return payment
            case TableColumnPopupType.beforeTax:
                var beforeTax = ComponentManager.getTotalOfLineItems(data.lineItems, false, true, 0) - ComponentManager.getDiscountOnLineItems(data.lineItems)
                var defaultBeforeTax = Utility.toCurrencyFormat(beforeTax, currency)
                if (data.amountBeforeTax !== undefined && data.amountBeforeTax !== null) {
                    defaultBeforeTax = Utility.toCurrencyFormat(data.amountBeforeTax, currency)
                }
                return defaultBeforeTax
            case TableColumnPopupType.outstandingAmount:
                var due = Utility.toCurrencyFormat(ComponentManager.getOutstandingAmount(data, roundingOffValue), currency)
                if (data.due !== undefined) {
                    due = Utility.toCurrencyFormat(data.due, currency)
                }
                return due
            case TableColumnPopupType.outstandingBalance:
                var outstandingBalance = ''
                if (data.outstandingBalance !== undefined) {
                    outstandingBalance = Utility.toCurrencyFormat(data.outstandingBalance, currency)
                }
                return outstandingBalance
            case TableColumnPopupType.outstandingBalanceIncludesCurrent:
                var outstandingBalanceIncludesCurrent = ''

                if (data.outstandingBalanceIncludesCurrent !== undefined && data.outstandingBalanceIncludesCurrent !== null) {
                    outstandingBalanceIncludesCurrent = Utility.toCurrencyFormat(data.outstandingBalanceIncludesCurrent, currency)
                }
                // var currentTotal = ComponentManager.getTotalOfLineItems(data.lineItems, false, false, roundingOffValue)
                // if (data.total !== undefined) {
                //     currentTotal = data.total
                // }
                // if (data.outstandingBalance !== undefined) {
                //     outstandingBalanceIncludesCurrent = data.outstandingBalance + currentTotal
                // }
                // if (outstandingBalanceIncludesCurrent !== '') {
                //     outstandingBalanceIncludesCurrent = Utility.toCurrencyFormat(outstandingBalanceIncludesCurrent, currency)
                // }
                // else if (currentTotal !== undefined) {
                //     outstandingBalanceIncludesCurrent = Utility.toCurrencyFormat(currentTotal, currency)
                // }

                return outstandingBalanceIncludesCurrent
            default:
                return ''
        }
    }

    static setFooterHeader(tableStyle: any, type: string, name: string, isShowRecord: boolean, index?: number, printScale?: number) {
        var newFooterColumn = this.getDefaultFooterHeaderColumn(tableStyle, printScale)
        newFooterColumn.name = type
        newFooterColumn.label = name
        newFooterColumn.isShowRecord = isShowRecord
        newFooterColumn.index = index
        return newFooterColumn
    }
    static getDefaultFooterHeaderColumn(tableStyle: any, printScale: number = PRINT_SCALE) {
        var newFooterHeaderColumn = new HeaderColumn()
        newFooterHeaderColumn.type = 'string'
        newFooterHeaderColumn.style = new ColumnStyle()
        if (tableStyle.subTotalBorderColor !== undefined && tableStyle.subTotalBorderColor !== null) {
            newFooterHeaderColumn.style.borderColor = tableStyle.subTotalBorderColor
        }
        else {
            newFooterHeaderColumn.style.borderColor = tableStyle.itemBorderColor
        }

        newFooterHeaderColumn.style.fontColor = tableStyle.itemTextColor
        newFooterHeaderColumn.style.borderStyle = 'solid'
        newFooterHeaderColumn.style.borderWidth = getVW(Utility.convertToPrintScale(1, printScale))
        newFooterHeaderColumn.style.paddingTop = getTablePadding(getVW(Utility.convertToPrintScale(11, printScale)), TableDimensionType.PADDING_VERTICAL)
        newFooterHeaderColumn.style.paddingBottom = getTablePadding(getVW(Utility.convertToPrintScale(11, printScale)), TableDimensionType.PADDING_VERTICAL)
        newFooterHeaderColumn.style.paddingLeft = getTablePadding(getVW(Utility.convertToPrintScale(11, printScale)), TableDimensionType.PADDING_HORIZONTAL)
        newFooterHeaderColumn.style.paddingRight = getTablePadding(getVW(Utility.convertToPrintScale(11, printScale)), TableDimensionType.PADDING_HORIZONTAL)
        newFooterHeaderColumn.style.fontSize = Utility.getIsReceiptPaperSize() ? FONT_SIZE.large : FONT_SIZE.regular
        // newFooterHeaderColumn.style.alignment = 'right'
        return newFooterHeaderColumn
    }

    static setLastFooterHeader(tableStyle: any, type: string, name: string, printScale: number) {
        var newFooterColumn = this.getDefaultFooterHeaderColumn(tableStyle, printScale)
        newFooterColumn.name = type
        newFooterColumn.label = getLocalisedText(name)
        newFooterColumn.style.backgroundColor = tableStyle.footerBackgroundColor
        newFooterColumn.style.fontColor = tableStyle.footerTextColor
        newFooterColumn.style.borderColor = tableStyle.footerBorderColor
        newFooterColumn.style.fontWeight = 'bold'
        if (type === TableColumnPopupType.outstandingAmount || type === TableColumnPopupType.amountInWords) {
            if (tableStyle.footerTextColor === 'white' || tableStyle.footerTextColor === 'transparent') {
                if (tableStyle.footerBackgroundColor === undefined) {
                    newFooterColumn.style.fontColor = undefined
                }
                if (tableStyle.footerBackgroundColor === 'transparent') {
                    newFooterColumn.style.fontColor = undefined
                }
            }
        }

        if (tableStyle.lastRowTopColor !== undefined) {
            newFooterColumn.style.borderTopColor = tableStyle.lastRowTopColor
            newFooterColumn.style.borderTopWidth = tableStyle.lastRowTopWidth
        }
        if (tableStyle.lastRowBottomColor !== undefined) {
            newFooterColumn.style.borderBottomColor = tableStyle.lastRowBottomColor
            newFooterColumn.style.borderBottomWidth = tableStyle.lastRowBottomWidth
        }

        return newFooterColumn
    }

    static getIsShowTotal(tableStyle: any, type: string) {
        if (type === TableColumnPopupType.amount) {
            if (tableStyle.footerTextColor === 'white' || tableStyle.footerTextColor === 'transparent') {
                if (tableStyle.footerBackgroundColor === undefined) {
                    return false
                }
                if (tableStyle.footerBackgroundColor === 'transparent') {
                    return false
                }
            }
        }
        return true
    }

    static getStockHeaderList(tableStyle: any, rawData: any) {
        var stockHeaderList = ['products', 'batch_no', 'expiry', 'qty']
        var stockHeaderColumnList: HeaderColumn[] = []
        stockHeaderList.forEach(element => {
            var newHeaderColumn = this.getDefaultHeaderColumn(tableStyle, true, rawData.printScale)
            newHeaderColumn = this.updateStockHeaderColumn(newHeaderColumn, element)
            stockHeaderColumnList.push(newHeaderColumn)
        });
        return stockHeaderColumnList
    }

    static getPaymentHeaderList(tableStyle: any, rawData: any) {
        var stockHeaderList = ['payment']
        var stockHeaderColumnList: HeaderColumn[] = []
        stockHeaderList.forEach(element => {
            var newHeaderColumn = this.getDefaultHeaderColumn(tableStyle, true, rawData.printScale)
            newHeaderColumn = this.updateStockHeaderColumn(newHeaderColumn, element)
            stockHeaderColumnList.push(newHeaderColumn)
        });
        return stockHeaderColumnList
    }

    static updateStockHeaderColumn(stockHeaderColumn: HeaderColumn, value: string) {
        stockHeaderColumn.columnOption.isShowColumn = true
        stockHeaderColumn.name = value
        stockHeaderColumn.label = getLocalisedText(value)
        stockHeaderColumn.style.fontSize = FONT_SIZE.xSmall

        switch (value) {
            case 'products':
                stockHeaderColumn.style.alignment = 'left'
                stockHeaderColumn.style.width = '50%'
                break;
            case 'batch_no':
                stockHeaderColumn.style.alignment = 'left'
                stockHeaderColumn.style.width = '20%'
                break;
            case 'expiry':
                stockHeaderColumn.style.alignment = 'left'
                stockHeaderColumn.style.width = '20%'
                break;
            case 'qty':
                stockHeaderColumn.style.alignment = 'center'
                stockHeaderColumn.style.width = '10%'
                break;
            case 'payment':
                stockHeaderColumn.style.alignment = 'left'
                stockHeaderColumn.style.width = '100%'
                break;
            default:
                break;
        }

        return stockHeaderColumn
    }

    static getStockData(documentData: any) {
        var stockItems: string[][] = []
        var dummyData = [
            ['Stock A', 'T087231', '31/12/2002', '4'],
            ['Stock B', 'T087231', '31/12/2002', '4'],
            ['Stock C', 'T087231', '31/12/2002', '4'],
            ['Stock D', 'T087231', '31/12/2002', '4'],
        ]
        if (documentData === undefined || documentData === null) {
            return dummyData
        }
        else {
            if (documentData.stockItems === undefined || documentData.stockItems === null) {
                return []
            }
            if (documentData.stockItems.length === 0) {
                return []
            }

            documentData.stockItems.forEach((element: any) => {
                stockItems.push(new BatchLineItem(element).toString())
            });
            return stockItems
        }
    }

    static getPaymentData(documentData: any) {
        var paymentItems: string[][] = []
        var dummyData = [
            ['www.google.com'],
        ]
        if (documentData === undefined || documentData === null) {
            return dummyData
        }
        else {
            if (documentData.paymentItems === undefined || documentData.paymentItems === null) {
                return []
            }
            if (documentData.paymentItems.length === 0) {
                return []
            }

            documentData.paymentItems.forEach((element: any) => {
                paymentItems.push(new PaymentLineItem(element).toString())
            });
            return paymentItems
        }
    }

    static onRowChange(data: any, rowIndex: number, action: string, onDataChangeCallback: any) {
        if (data === undefined || data === null) {
            onDataChangeCallback(data)
        }

        if (data.lineItems === undefined || data.lineItems === null) {
            onDataChangeCallback(data)
        }

        if (data.lineItems.length === 0 || data.lineItems.length === 1) {
            onDataChangeCallback(data)
        }

        var newArray: any[] = []
        data.lineItems.forEach((element: any, index: number) => {
            if (index !== rowIndex) {
                newArray.push(element)
            }
        });
        var newIndex = rowIndex
        if (action === 'up') {
            newIndex = rowIndex - 1
            if (newIndex <= 0) {
                newIndex = 0
            }
        }
        else if (action === 'down') {
            newIndex = rowIndex + 1
            if (newIndex >= data.lineItems.length) {
                newIndex = data.lineItems.length - 1
            }
        }
        var selectedItem = data.lineItems[rowIndex]
        newArray.splice(newIndex, 0, selectedItem)
        newArray.forEach((element, index) => {
            element.lineNumber = index + 1
        });
        data.lineItems = newArray
        onDataChangeCallback(data)
    }

    static onUpdateTableWidth(header: HeaderColumn, percentage: number, data: any, columnOptions: any,callback: any) {
        if (columnOptions === undefined || columnOptions === null) {
            return
        }

        if (columnOptions.length === 0) {
            return
        }

        var dataColumn = columnOptions.filter((x: any) => x.type !== TableColumnPopupType.discount || x.type !== TableColumnPopupType.tax)
        var isSelectedCount = dataColumn.filter((x: any) => x.isSelected).length - 1
        var offsetPercentage = percentage / isSelectedCount

        var newColumnOptions: any[] = []
        columnOptions.forEach((element: any) => {
            if (element.isSelected) {
                var item = element
                var width = element.width
                if (element.type === TableColumnPopupType.discount || element.type === TableColumnPopupType.tax) {
                    item.width = '0%'
                }
                else if (element.type === header.name && header.code === element.code) {
                    if (element.width !== undefined && element.width !== null) {
                        width = Number(element.width.replace('%', '')) + percentage
                        item.width = width + '%'
                    }
                }
                else {
                    if (element.width !== undefined && element.width !== null) {
                        width = Number(element.width.replace('%', '')) - offsetPercentage
                        item.width = width + '%'
                    }
                }
                newColumnOptions.push(item)
            }
            else {
                if (element.width !== undefined) {
                    element.width = '0%'
                }
                newColumnOptions.push(element)
            }
        });

        TemplateSettingsManager.updateTablePopupInfo(newColumnOptions);
        var newData = data;
        newData.tableColumnPopup = newColumnOptions;
        callback(newData);
    }

    static getIsContainsAdditionalCharge(documentData: any) {
        if (documentData.additionalCharge !== undefined && documentData.additionalCharge !== null) {
            if (documentData.additionalCharge.length > 0) {
                return true
            }
        }

        return false
    }

    static getGroupData(items: any, documentData: any, currency: string, tableInfo?: any) {
        var lineItems: string[][] = []
        var newProductGrouping = TemplateSettingsManager.getProductGrouping()
        var grouping = 'none'
        if (newProductGrouping !== undefined && newProductGrouping !== null) {
            if (newProductGrouping.selection !== undefined) {
                grouping = newProductGrouping.selection
            }
        }
        if (grouping === 'none') {
            items.forEach((element: any) => {
                lineItems.push(new StockIssueTableItem(element, documentData, currency).toString(tableInfo))
            });
            return lineItems
        }
        var attributes: string[] = []
        this.groupHeaderIndexList = []
        items.forEach((element: any) => {
            var label = ''
            if (element.customFields !== undefined && element.customFields !== null) {
                label = this.getAttributeString(element.customFields)
            }
            if (!attributes.includes(label)) {
                attributes.push(label)
            }
        });

        if (grouping === CONSTANTS.PRODUCT_GROUP) {
            attributes = []
            // var groupIds: string[] = []
            items.forEach((element: any) => {
                var label = ''
                // var groupId = ''
                if (element.productGroupName !== undefined && element.productGroupName !== null) {
                    label = element.productGroupName
                    // groupId = element.productGroupId
                }
                if (!attributes.includes(label)) {
                    attributes.push(label)
                    // groupIds.push(groupId)
                }
            });

            // if not single group present, remove none and show as it is record.
            if (attributes.length === 1 && attributes[0] === '') {
                items.forEach((element: any) => {
                    lineItems.push(new StockIssueTableItem(element, documentData, currency).toString(tableInfo))
                });
                return lineItems
            }
        }

        if (grouping !== CONSTANTS.PRODUCT_GROUP) {
            attributes = attributes.sort((a: string, b: string) => a.localeCompare(b))
        }
        //split the item based om attr
        var attributesGroupedItems: any[] = []
        attributes.forEach((element: any) => {
            var groupedItems: any[] = []
            items.forEach((item: any) => {
                if (grouping === CONSTANTS.PRODUCT_GROUP) {
                    if (item.productGroupName === element) {
                        groupedItems.push(item)
                    }
                } else {
                    if (this.getAttributeString(item.customFields) === element) {
                        groupedItems.push(item)
                    }
                }
            });
            if (element === '') {
                element = 'none'
            }
            var val = {
                key: element,
                list: groupedItems
            }
            attributesGroupedItems.push(val)
        });

        var itemCount = 1
        attributesGroupedItems.forEach((element) => {
            if (element.list !== undefined && element.list !== null) {
                if (element.list.length > 0) {
                    this.groupHeaderIndexList.push(lineItems.length)
                    lineItems.push(new ProductTableGroupHeaderColumn(element.key).toString())
                }
                element.list.forEach((element: any) => {
                    var newElement = element
                    newElement.lineNumber = itemCount
                    itemCount += 1
                    lineItems.push(new StockIssueTableItem(newElement, documentData, currency).toString())
                });
            }
        });
        return lineItems
    }
}

export class TableLineItem {
    name: string;
    quantity: string;
    description: string;
    unitPrice: string;
    tax: number;
    discount: number;
    rawItemValue: any;
    constructor(lineItem: any) {
        this.name = lineItem.name
        this.quantity = lineItem.quantity
        this.description = lineItem.description
        this.unitPrice = lineItem.unitPrice
        this.tax = lineItem.tax
        this.discount = lineItem.discount
        this.rawItemValue = lineItem
    }

    toString(): string[] {
        return [
            getLocalisedText(this.name),
            getLocalisedText(this.description),
            parseFloat(this.quantity).toFixed(2),
            Utility.toCurrencyFormat(parseFloat(this.unitPrice) * 1),
            this.rawItemValue.priceInclusiveOfTax ?
                Utility.toCurrencyFormat(ComponentManager.getProductTaxInclusivePrice(this.rawItemValue))
                : Utility.toCurrencyFormat(parseFloat(this.quantity) * parseFloat(this.unitPrice)),
        ]
    }
}

export class BatchLineItem {
    productCode: string;
    productName: string;
    productDesc: string;
    serialBatchNumber: string;
    sequence: number;
    subSequence: number;
    expiryDate: string;
    manufacturingDate: string;
    quantity: string;
    rawItemValue: any;

    constructor(batchLineItem: any) {
        this.productCode = batchLineItem.productCode
        this.productName = batchLineItem.productName
        this.productDesc = batchLineItem.productDesc
        this.serialBatchNumber = batchLineItem.serialBatchNumber
        this.sequence = batchLineItem.sequence
        this.subSequence = batchLineItem.subSequence
        this.expiryDate = batchLineItem.expiryDate
        this.manufacturingDate = batchLineItem.manufacturingDate
        this.quantity = batchLineItem.quantity
        this.rawItemValue = batchLineItem
    }

    toString(): string[] {
        return [
            //To be shown
            this.productName,
            this.serialBatchNumber,
            this.expiryDate,
            parseFloat(this.quantity).toFixed(2),

            // //For reference
            // this.productCode,
            // this.productDesc,
            // this.sequence,
            // this.subSequence,
            // this.manufacturingDate,
            // this.rawItemValue,
        ]
    }
}

export class PaymentLineItem {
    paymentLink: string;
    rawItemValue: any;

    constructor(paymentLineItem: any) {
        this.paymentLink = paymentLineItem
        this.rawItemValue = paymentLineItem
    }

    toString(): string[] {
        return [this.paymentLink]
    }
}
