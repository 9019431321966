import {
  DKButton,
  DKIcon,
  DKIcons,
  DKLabel,
  showAlert,
  showToast
} from 'deskera-ui-library';
import { Fragment, useContext, useEffect, useState } from 'react';
import {
  DOCUMENT_MODE,
  DOC_TYPE,
  DOC_TYPE_TO_ATTACHMENT_MAP
} from '../../../../Constants/Constant';
import { DraftTypes } from '../../../../Models/Drafts';
import { useAppDispatch, useAppSelector } from '../../../../Redux/Hooks';
import {
  selectDocumentFormDataByKey,
  selectDocumentFormDataByKeys,
  updateMultipleKeysInDocument
} from '../../../../Redux/Slices/DocumentSlice';
import AttachmentService from '../../../../Services/Attachment';
import { triggerDownload } from '../../../ImportExport/utility/ExportData';
import { CommonDraftPropsContext } from '../../Utilities/DocContext';
import { DOCUMENT_KEYS } from '../../Utilities/DocConstants';
import { canUpdateDocument } from '../../Utilities/DocCommonUtils';
import Utility from '../../../../Utility/Utility';

const DocAttachment = (props: any) => {
  const dispatch = useAppDispatch();
  const { draftId, draftType } = useContext(CommonDraftPropsContext);
  const [isRemovingAttachment, setIsRemovingAttachment] = useState(false);

  const attachmentData = useAppSelector(
    selectDocumentFormDataByKey(draftId, DOCUMENT_KEYS.ATTACHMENTS)
  );

  const [
    id,
    entityId,
    documentType,
    fulfillmentStatus,
    paymentStatus,
    receiveGoodsStatus,
    totalAmount,
    isPartialInvoice,
    isConverting,
    sourceDocTypeForConversion,
    isPartialSalesOrder
  ] = useAppSelector(
    selectDocumentFormDataByKeys(draftId, [
      DOCUMENT_KEYS.ID,
      DOCUMENT_KEYS.ENTITY_ID,
      DOCUMENT_KEYS.DOCUMENT_TYPE,
      DOCUMENT_KEYS.FULFILLMENT_STATUS,
      DOCUMENT_KEYS.PAYMENT_STATUS,
      DOCUMENT_KEYS.RECEIVE_GOODS_STATUS,
      DOCUMENT_KEYS.TOTAL_AMOUNT,
      DOCUMENT_KEYS.IS_PARTIAL_INVOICE,
      DOCUMENT_KEYS.IS_CONVERTING,
      DOCUMENT_KEYS.SOURCE_DOCTYPE_FOR_CONVERSION,
      DOCUMENT_KEYS.IS_PARTIAL_SALES_ORDER
    ])
  );

  const isReadOnly = draftType === DraftTypes.READONLY;
  const canUpdateDocumentData = canUpdateDocument({
    documentType,
    fulfillmentStatus,
    paymentStatus,
    receiveGoodsStatus,
    totalAmount,
    draftType
  });

  const [attachments, setAttachments] = useState<any[]>([]);
  const [newAttachments, setNewAttachments] = useState<any[]>([]);

  useEffect(() => {
    if (draftType === DraftTypes.DRAFT) {
      const attachmentsToAdd = attachmentData?.map((attachment: any) =>
        JSON.parse(attachment)
      );
      setAttachments(attachmentsToAdd);
    } else {
      fetchAttachments();
    }
  }, []);

  const fetchAttachments = () => {
    let moduleType = '';
    if (isPartialInvoice && isConverting && documentType === DOC_TYPE.INVOICE) {
      if (sourceDocTypeForConversion === DOC_TYPE.SALES_ORDER) {
        moduleType = DOC_TYPE_TO_ATTACHMENT_MAP[DOC_TYPE.SALES_ORDER];
      } else if (sourceDocTypeForConversion === DOC_TYPE.QUOTE) {
        moduleType = DOC_TYPE_TO_ATTACHMENT_MAP[DOC_TYPE.QUOTE];
      }
    } else if (
      isPartialSalesOrder &&
      isConverting &&
      documentType === DOC_TYPE.SALES_ORDER
    ) {
      moduleType = DOC_TYPE_TO_ATTACHMENT_MAP[DOC_TYPE.QUOTE];
    } else if (
      isPartialInvoice &&
      isConverting &&
      documentType === DOC_TYPE.BILL
    ) {
      moduleType = DOC_TYPE_TO_ATTACHMENT_MAP[DOC_TYPE.ORDER];
    } else {
      moduleType = DOC_TYPE_TO_ATTACHMENT_MAP[documentType];
    }

    const entityUniqueIdId = id || entityId;

    if (!entityUniqueIdId) return;

    AttachmentService.attachmentConfig = {
      ...AttachmentService.attachmentConfig,
      Module: moduleType,
      EntityId: entityUniqueIdId
    };

    AttachmentService.getAllAttachments().then((attachmentList: any) => {
      setAttachments(attachmentList);
      if (
        props.documentMode === DOCUMENT_MODE.COPY ||
        props.documentMode === DOCUMENT_MODE.NEW
      ) {
        dispatch(
          updateMultipleKeysInDocument({
            draftId,
            keysToUpdate: {
              [DOCUMENT_KEYS.ATTACHMENT_IDS]: attachmentList.map(
                (attachment: any) => attachment.attachmentId
              ),
              [DOCUMENT_KEYS.ATTACHMENTS]: attachmentList.map(
                (attachment: any) => JSON.stringify(attachment)
              )
            }
          })
        );
        if (
          (isPartialInvoice && isConverting) ||
          (isPartialSalesOrder && isConverting) ||
          props.documentMode === DOCUMENT_MODE.COPY
        ) {
          const newlyAddedAttachments = [...attachmentList];
          setNewAttachments(newlyAddedAttachments);
        }
      }
    });
  };

  const uploadAttachmentToAWS = (file: File) => {
    const moduleType = DOC_TYPE_TO_ATTACHMENT_MAP[documentType];
    const entityUniqueId =
      documentType === DOC_TYPE.ORDER ||
      documentType === DOC_TYPE.JOB_WORK_OUT_ORDER ||
      documentType === DOC_TYPE.QUOTE
        ? id || entityId
        : '';

    AttachmentService.attachmentConfig = {
      ...AttachmentService.attachmentConfig,
      Module: moduleType,
      EntityId: entityUniqueId
    };

    if (file.size && file.size / (1024 * 1024) > 5) {
      showAlert(
        'Attachment size limit exceeded',
        'It seems the file size is more than 5 MB, Please compress the file and try again.'
      );

      return;
    }

    AttachmentService.uploadAttachment(file)
      .then((res) => {
        const attachmentForListing = [...attachments, res];
        const newlyAddedAttachments = [...newAttachments, res];
        setAttachments(attachmentForListing);
        setNewAttachments(newlyAddedAttachments);

        dispatch(
          updateMultipleKeysInDocument({
            draftId,
            keysToUpdate: {
              [DOCUMENT_KEYS.ATTACHMENTS]: newlyAddedAttachments.map(
                (attachment: any) => JSON.stringify(attachment)
              ),
              [DOCUMENT_KEYS.ATTACHMENT_IDS]: newlyAddedAttachments.map(
                (attachment: any) => attachment.attachmentId
              )
            }
          })
        );
      })
      .catch((err) => {
        showToast(
          'Something went wrong while uploading the attachment, please try again.'
        );
      });
  };

  const triggerAttachmentUpload = () => {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.addEventListener('change', (e) => {
      const target = e.target as HTMLInputElement;
      target?.files &&
        Array.from(target.files).forEach((file: File) =>
          uploadAttachmentToAWS(file)
        );
    });
    input.click();
  };

  const removeAttachment = (attachmentId: any) => {
    setIsRemovingAttachment(true);
    AttachmentService.deleteAttachment(attachmentId)
      .then((res) => {
        const attachmentForListing = attachments.filter(
          (attachment: any) => attachmentId !== attachment.attachmentId
        );

        const newlyAddedAttachments = newAttachments.filter(
          (attachment: any) => attachmentId !== attachment.attachmentId
        );

        setAttachments(attachmentForListing);
        setNewAttachments(newlyAddedAttachments);
        dispatch(
          updateMultipleKeysInDocument({
            draftId,
            keysToUpdate: {
              [DOCUMENT_KEYS.ATTACHMENTS]: newlyAddedAttachments.map(
                (attachment: any) => JSON.stringify(attachment)
              ),
              [DOCUMENT_KEYS.ATTACHMENT_IDS]: newlyAddedAttachments.map(
                (attachment: any) => attachment.attachmentId
              )
            }
          })
        );
        setIsRemovingAttachment(false);
      })
      .catch(() => {
        showToast(
          'Something went wrong while removing the attachment, please try again.'
        );
        setIsRemovingAttachment(false);
      });
  };

  const triggerAttachmentDownload = (
    attachmentId: any,
    attachmentName: string
  ) => {
    AttachmentService.downloadAttachment(attachmentId)
      .then((absolutePath) => {
        triggerDownload(null, attachmentName, absolutePath);
      })
      .catch(() => {
        showToast('Something went wrong, while downloading the attachment.');
      });
  };

  const getAttachments = () => {
    return (
      <div className="row justify-content-start flex-wrap mt-r mb-r">
        {attachments?.map((attachment: any) => (
          <div
            className={`row width-auto border-m border-radius-s p-h-s p-v-s mr-r bg-gray0 ${
              isRemovingAttachment ? 'pointer-events-none' : ''
            }`}
            key={attachment.attachmentId}
          >
            <DKIcon
              src={DKIcons.ic_document}
              className="ic-xs-2 cursor-pointer mr-xs opacity-50 hover:opacity-60"
              onClick={() => {
                triggerAttachmentDownload(
                  attachment.attachmentId,
                  attachment.attachmentFileName
                );
              }}
            />
            <div
              className="cursor-pointer border-none"
              title={attachment.attachmentFileName}
              onClick={() => {
                triggerAttachmentDownload(
                  attachment.attachmentId,
                  attachment.attachmentFileName
                );
              }}
            >
              <DKLabel
                text={attachment.attachmentFileName}
                style={{
                  maxWidth: 150,
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis'
                }}
              />
            </div>

            <DKIcon
              src={DKIcons.ic_delete}
              className={`ic-xs-2 ml-s cursor-pointer opacity-50 hover:opacity-60 ${
                props.draftType === DraftTypes.READONLY
                  ? 'pointer-events-none'
                  : ''
              }`}
              onClick={() => removeAttachment(attachment.attachmentId)}
            />
          </div>
        ))}
      </div>
    );
  };

  return (
    <Fragment>
      <DKButton
        title={
          <>
            + Attach files
            <span className="text-gray fw-r ml-s">(Max 5MB)</span>
          </>
        }
        className={`${
          isReadOnly || !canUpdateDocumentData ? 'text-gray' : 'text-blue'
        } mt-r fw-m`}
        style={{ paddingLeft: 0 }}
        disabled={isReadOnly || !canUpdateDocumentData}
        onClick={triggerAttachmentUpload}
      />
      <div className="row pointer-events-auto">{getAttachments()}</div>
    </Fragment>
  );
};

export default DocAttachment;
