import { FC, useContext, useMemo, useState } from 'react';
import { DKButton, DKListPicker2 } from 'deskera-ui-library';
import { AdditionalChargeDetails } from '../../../../../Models/Document';
import {
  MODULE_TYPE,
  DOCUMENT_MODE,
  ADDITIONAL_CHARGES_TYPE,
  COUNTRY_CODES,
  PRODUCT_TYPE
} from '../../../../../Constants/Constant';
import AddAdditionalCharges from '../../../../Invoices/AddAdditionalCharges';
import { isSalesDocument } from '../../../Utilities/DocCommonUtils';
import { useAppDispatch, useAppSelector } from '../../../../../Redux/Hooks';
import {
  selectAdditionalBuyCharges,
  selectAddtionalSellCharges
} from '../../../../../Redux/Slices/AdditionalChargesSlice';
import {
  selectDocumentFormDataByKeys,
  updateMultipleKeysInDocument
} from '../../../../../Redux/Slices/DocumentSlice';
import {
  CHARGE_TYPE,
  DOCUMENT_KEYS,
  IDocSummaryAmount
} from '../../../Utilities/DocConstants';
import { CommonDraftPropsContext } from '../../../Utilities/DocContext';
import { activeTenantInfo } from '../../../../../Redux/Slices/AuthSlice';
import Utility from '../../../../../Utility/Utility';
import {
  buildAdditionalChargesToSaveInStore,
  calculateChargeAmountAndTax
} from '../../../Helper/Common/DocDataHelper';
import { cloneDeep } from 'lodash';

interface IAddChargeButtonProps {
  charges: any[] | undefined;
  discounts: any[] | undefined;
  summaryAmount: IDocSummaryAmount;
}
const CHARGES = ['Additional Charges', 'Global Discount'];

const AddChargeButton: FC<IAddChargeButtonProps> = ({
  charges,
  discounts,
  summaryAmount
}) => {
  const dispatch = useAppDispatch();
  const { draftId, documentMode } = useContext(CommonDraftPropsContext);
  const [additionalCharges, documentType, items, gstType] = useAppSelector(
    selectDocumentFormDataByKeys(draftId, [
      DOCUMENT_KEYS.ADDITIONAL_CHARGES,
      DOCUMENT_KEYS.DOCUMENT_TYPE,
      DOCUMENT_KEYS.ITEMS,
      DOCUMENT_KEYS.GST_TYPE
    ])
  );
  const { country, additionalSettings } = useAppSelector(activeTenantInfo);
  const isSalesTypeDocument = isSalesDocument(documentType);
  const additionalBuyCharges = useAppSelector(selectAdditionalBuyCharges);
  const additionalSellCharges = useAppSelector(selectAddtionalSellCharges);

  const [showChargeDiscountPicker, setShowChargeDiscountPicker] =
    useState<boolean>(false);
  const [showAllAdditionalCharges, setShowAllAdditionalCharges] =
    useState(false);
  const [showAllAdditionalDiscounts, setShowAllAdditionalDiscounts] =
    useState(false);
  const [showAddAdditionalCharges, setShowAddAdditionalCharges] =
    useState(false);
  const [showAddAdditionalDiscounts, setShowAddAdditionalDiscounts] =
    useState(false);
  const [selectChargeForEdit, setSelectChargeForEdit] = useState<
    AdditionalChargeDetails | undefined
  >();

  const canAddDiscounts =
    Utility.isEmptyObject(additionalCharges?.globalDiscount) ||
    additionalCharges?.globalDiscount?.amount === 0;

  const getUnselectedAdditionalCharges = (): any[] => {
    const selectedChargesNames = charges?.map((charge) => charge.name);

    const chargesToSelectFrom = isSalesTypeDocument
      ? [...additionalSellCharges]
      : [...additionalBuyCharges];

    let unSelectedCharges: any[] =
      [...chargesToSelectFrom].filter(
        (charge) =>
          !charge.isDiscount && !selectedChargesNames?.includes(charge.name)
      ) ?? [];

    return unSelectedCharges;
  };

  const getUnselectedAdditionalDiscount = (): any[] => {
    const selectedChargesNames = discounts?.map((charge) => charge.name);
    const chargesToSelectFrom = isSalesTypeDocument
      ? [...additionalSellCharges]
      : [...additionalBuyCharges];

    let unSelectedCharges: any[] = chargesToSelectFrom?.filter(
      (charge) =>
        charge.isDiscount &&
        !charge.isItemDiscount &&
        !selectedChargesNames?.includes(charge.name)
    );
    return unSelectedCharges;
  };

  const showUnSelectedAdditionalChargesList = () => {
    const unSelectedCharges: any[] = getUnselectedAdditionalCharges();
    return (
      <DKListPicker2
        title="Select charge"
        data={unSelectedCharges}
        style={{
          width: 180,
          left: 140,
          top: -120
        }}
        allowSearch={true}
        searchableKey={'name'}
        canEdit={true}
        canDelete={false}
        className="position-absolute z-index-3 shadow-m border-m"
        onSelect={(index: number, charge: any) => {
          onChargeSelect(charge, CHARGE_TYPE.ADDITIONAL_CHARGE);
        }}
        onEdit={(index: any, charge: any) => {
          setShowAllAdditionalCharges(false);
          setShowChargeDiscountPicker(false);
          setSelectChargeForEdit(charge);
          setShowAddAdditionalCharges(true);
        }}
        onClose={() => {
          if (showAllAdditionalCharges) {
            setShowAllAdditionalCharges(false);
            setShowChargeDiscountPicker(false);
          }
        }}
        renderer={(index: number, charge: any) => {
          return (
            <div
              className="text-align-left fw-r"
              style={{ whiteSpace: 'pre-wrap' }}
            >
              {charge.name}
            </div>
          );
        }}
        button={{
          title: 'Add Charge',
          className: 'row bg-button text-white justify-content-center',
          onClick: () => {
            setSelectChargeForEdit(undefined);
            setShowAddAdditionalCharges(true);
          }
        }}
      />
    );
  };

  const showUnSelectedAdditionalDiscountList = () => {
    const unSelectedDiscount: any[] = getUnselectedAdditionalDiscount();
    return (
      <DKListPicker2
        title="Select discount"
        data={unSelectedDiscount}
        style={{
          width: 180,
          left: 140,
          top: -120
        }}
        allowSearch={true}
        searchableKey={'name'}
        canEdit={true}
        canDelete={false}
        className="position-absolute z-index-3 shadow-m border-m"
        onSelect={(index: number, charge: any) =>
          onChargeSelect(charge, CHARGE_TYPE.DISCOUNT)
        }
        onEdit={(index: any, charge: any) => {
          setShowAllAdditionalCharges(false);
          setShowAllAdditionalDiscounts(false);
          setShowChargeDiscountPicker(false);
          setSelectChargeForEdit(charge);
          setShowAddAdditionalDiscounts(true);
        }}
        onClose={() => {
          if (showAllAdditionalDiscounts) {
            setShowAllAdditionalCharges(false);
            setShowAllAdditionalDiscounts(false);
            setShowChargeDiscountPicker(false);
          }
        }}
        renderer={(index: number, charge: any) => {
          return (
            <div
              className="text-align-left fw-r"
              style={{ whiteSpace: 'pre-wrap' }}
            >
              {charge.name}
            </div>
          );
        }}
        button={{
          title: 'Add Discount',
          className: 'row bg-button text-white justify-content-center',
          onClick: () => {
            setSelectChargeForEdit(undefined);
            setShowAddAdditionalDiscounts(true);
          }
        }}
      />
    );
  };
  const setInitialValuesForGlobalDiscount = () => {
    const copyOfAdditionalCharges = cloneDeep(additionalCharges);
    dispatch(
      updateMultipleKeysInDocument({
        draftId,
        keysToUpdate: {
          [DOCUMENT_KEYS.ADDITIONAL_CHARGES]: {
            ...copyOfAdditionalCharges,
            globalDiscount: {
              amount: 0,
              isPercent: false,
              isSubTotalOnly: false,
              percent: 0,
              addedManually: true
            }
          }
        }
      })
    );
  };

  const handleChargeTypeSelect = (option: any) => {
    switch (CHARGES[option]) {
      case CHARGES[0]:
        const unSelectedCharges: any[] = getUnselectedAdditionalCharges();
        if (!unSelectedCharges.length) {
          setSelectChargeForEdit(undefined);
          setShowAddAdditionalCharges(true);
        } else {
          setShowAllAdditionalCharges(true);
        }
        break;
      case CHARGES[1]:
        const isMultipleDiscountOn =
          !!additionalSettings?.MULTIPLE_GLOBAL_DISCOUNT;
        if (isMultipleDiscountOn) {
          const unSelectedCharges: any[] = getUnselectedAdditionalDiscount();
          if (!unSelectedCharges.length) {
            setSelectChargeForEdit(undefined);
            setShowAddAdditionalDiscounts(true);
          } else {
            setShowAllAdditionalDiscounts(true);
          }
        } else {
          setShowChargeDiscountPicker(false);
          setShowAllAdditionalCharges(false);
          setShowAllAdditionalDiscounts(false);
          setInitialValuesForGlobalDiscount();
        }
        break;
    }
  };

  const onChargeSelect = (
    charge: any,
    chargeType: CHARGE_TYPE,
    newChargeAdded = false
  ) => {
    if (!Utility.isEmpty(charge)) {
      const chargesToUse =
        (chargeType === CHARGE_TYPE.ADDITIONAL_CHARGE ? charges : discounts) ||
        [];
      calculateChargeAmountAndTax({
        draftId,
        charge,
        charges: chargesToUse,
        summaryAmount
      }).then(async (data) => {
        charge = {
          ...charge,
          chargeValue: data.chargeAmount,
          taxAmount: data.taxOnChargeAmount
        };
        const updatedAdditionalCharges = cloneDeep(additionalCharges);
        if (chargeType === CHARGE_TYPE.ADDITIONAL_CHARGE) {
          updatedAdditionalCharges[CHARGE_TYPE.ADDITIONAL_CHARGE] = [
            ...chargesToUse,
            charge
          ];
          updatedAdditionalCharges[CHARGE_TYPE.DISCOUNT] = discounts;
        } else {
          updatedAdditionalCharges[CHARGE_TYPE.ADDITIONAL_CHARGE] = charges;
          updatedAdditionalCharges[CHARGE_TYPE.DISCOUNT] = [
            ...chargesToUse,
            charge
          ];
        }
        const itemHasTrackedBOM = items?.some(
          (item: any) =>
            item?.product?.type === PRODUCT_TYPE.TRACKED ||
            item?.product?.type === PRODUCT_TYPE.BILL_OF_MATERIALS
        );
        const addCharges = buildAdditionalChargesToSaveInStore({
          gstType,
          documentType,
          additionalCharges: updatedAdditionalCharges,
          hasBOMOrTracked: itemHasTrackedBOM
        });
        dispatch(
          updateMultipleKeysInDocument({
            draftId,
            keysToUpdate: { [DOCUMENT_KEYS.ADDITIONAL_CHARGES]: addCharges }
          })
        );

        setShowAllAdditionalCharges(false);
        setShowAllAdditionalDiscounts(false);
        setShowChargeDiscountPicker(false);
        setShowAddAdditionalDiscounts(false);
        setShowAddAdditionalCharges(false);
      });
    }
  };
  const allowToAdd: boolean = useMemo(
    () => items?.every((item: any) => Utility.isNotEmpty(item?.product)),
    [items]
  );
  const getChargeAndDiscountPicker = () => {
    let optionsArr = [CHARGES[0]];
    if (canAddDiscounts) {
      optionsArr.push(CHARGES[1]);
    }
    return (
      <div className="row position-relative mb-m">
        {showChargeDiscountPicker && (
          <DKListPicker2
            title=""
            data={optionsArr}
            style={{
              width: 150
            }}
            allowSearch={false}
            className="position-absolute z-index-3 left-0 bottom-7 shadow-m  border-m"
            onSelect={handleChargeTypeSelect}
            onClose={() => {
              if (showChargeDiscountPicker) {
                setShowChargeDiscountPicker(false);
              }
            }}
            renderer={(index: number, obj: any) => {
              return (
                <div className="row parent-width">
                  <div>{obj}</div>
                </div>
              );
            }}
          />
        )}
        {showAllAdditionalCharges && showUnSelectedAdditionalChargesList()}
        {showAllAdditionalDiscounts && showUnSelectedAdditionalDiscountList()}
        {documentMode !== DOCUMENT_MODE.VIEW && (
          <DKButton
            className={`${!allowToAdd ? 'text-gray' : 'text-blue'} fw-m`}
            style={{ paddingLeft: 8, paddingTop: 0, paddingBottom: 0 }}
            disabled={!allowToAdd}
            title={`+ Add Charges${!canAddDiscounts ? '' : '/Discount'}`}
            onClick={() => {
              setShowChargeDiscountPicker(!showChargeDiscountPicker);
            }}
          />
        )}
      </div>
    );
  };

  const getAdditionalChargesPopUp = () => {
    return (
      showAddAdditionalCharges && (
        <AddAdditionalCharges
          object={selectChargeForEdit}
          onCreated={(charge: any) => {
            const moduleType = isSalesDocument(documentType)
              ? MODULE_TYPE.SELL
              : MODULE_TYPE.BUY;
            if (
              !Utility.isEmpty(charge) &&
              (charge.applyTo === ADDITIONAL_CHARGES_TYPE.BOTH ||
                charge.applyTo === moduleType)
            ) {
              onChargeSelect(charge, CHARGE_TYPE.ADDITIONAL_CHARGE, true);
            }
          }}
          onClose={() => {
            setShowAddAdditionalCharges(false);
          }}
        />
      )
    );
  };

  const getAdditionalDiscountsPopUp = () => {
    return (
      showAddAdditionalDiscounts && (
        <AddAdditionalCharges
          isDiscount={true}
          object={selectChargeForEdit}
          onCreated={(charge: any) => {
            const moduleType = isSalesDocument(documentType)
              ? MODULE_TYPE.SELL
              : MODULE_TYPE.BUY;
            if (
              !Utility.isEmpty(charge) &&
              (charge.applyTo === ADDITIONAL_CHARGES_TYPE.BOTH ||
                charge.applyTo === moduleType)
            ) {
              onChargeSelect(charge, CHARGE_TYPE.DISCOUNT, true);
            }
          }}
          onClose={() => {
            setShowAddAdditionalCharges(false);
            setShowAddAdditionalDiscounts(false);
          }}
        />
      )
    );
  };

  const showAddChargeButton = [
    COUNTRY_CODES.IN,
    COUNTRY_CODES.US,
    COUNTRY_CODES.SG
  ].includes(country);
  if (!showAddChargeButton) return null;

  return (
    <>
      {getChargeAndDiscountPicker()}
      {getAdditionalChargesPopUp()}
      {getAdditionalDiscountsPopUp()}
    </>
  );
};

export default AddChargeButton;
