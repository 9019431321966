import {
  COMMON_CONSTANT,
  DOC_TYPE,
  STATUS_TYPE
} from '../../../../Constants/Constant';
import { makePaymentMethod } from '../../../../Models/PaymentMethod';
import { Store } from '../../../../Redux/Store';
import AccountsService from '../../../../Services/Accounts';
import Utility from '../../../../Utility/Utility';

export const loadAccountGroupsForCashInvoice = async (
  currency: string,
  documentType: DOC_TYPE
) => {
  try {
    const accountGroups: any[] = await AccountsService.fetchAccountGroup(
      currency
    );
    const filterAccounts =
      Store.getState().authInfo?.currentTenantInfo?.data?.additionalSettings
        ?.ACCOUNT?.showCardCashBankAccountsOnly;
    let filteredAccountGroups = [];
    if (filterAccounts) {
      filteredAccountGroups = accountGroups?.filter(
        (acc: any) =>
          ([COMMON_CONSTANT.CASH, COMMON_CONSTANT.BANK].includes(
            acc.accountGroup
          ) ||
            (acc.accountGroup === COMMON_CONSTANT.CURRENT_LIABILITY &&
              acc.isCreditCard === true)) &&
          acc.status === STATUS_TYPE.ACTIVE
      );
    } else {
      filteredAccountGroups = accountGroups.filter(
        (el) =>
          el.accountGroup === COMMON_CONSTANT.BANK ||
          el.accountGroup === COMMON_CONSTANT.CASH ||
          (el.accountGroup === COMMON_CONSTANT.CURRENT_LIABILITY &&
            el.isCreditCard) ||
          (el.accountGroup === COMMON_CONSTANT.CURRENT_ASSETS &&
            el.isUndepositedFundAccount &&
            documentType === DOC_TYPE.INVOICE)
      );
    }

    let paymentMethodOptions: any[] = [];
    const paymentMethods = filteredAccountGroups.map((filteredAccountGroup) =>
      makePaymentMethod(filteredAccountGroup)
    );

    paymentMethods.forEach((method) => {
      let option: any = {
        label: method.name,
        accountGroupName: Utility.convertInTitleCase(method.accountGroup),
        value: method.code,
        isUndepositedFundAccount: method.isUndepositedFundAccount ? true : false
      };
      let found = false;
      paymentMethodOptions.forEach((paymentMethodOption) => {
        if (
          paymentMethodOption.label === option.label &&
          paymentMethodOption.accountGroup === option.accountGroup
        ) {
          found = true;
        }
      });
      if (!found) {
        paymentMethodOptions.push(option);
      }
    });

    const defaultAccountGroupOption =
      paymentMethodOptions.find(
        (el: any) => el.value === COMMON_CONSTANT.DEFAULT_DEPOSIT_TO
      ) || paymentMethodOptions[0];

    return { paymentMethodOptions, defaultAccountGroupOption };
  } catch (err: any) {
    console.error('Error loading account groups: ', err);
  }
};
