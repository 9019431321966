import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { shallowEqual } from 'react-redux';
import {
  DKIcons,
  DKIconText,
  DKInput,
  DKLabel,
  INPUT_VIEW_DIRECTION,
  INPUT_TYPE
} from 'deskera-ui-library';
import { CommonDraftPropsContext } from '../../../Utilities/DocContext';
import { useAppDispatch, useAppSelector } from '../../../../../Redux/Hooks';
import {
  selectDocumentFormDataByKeys,
  setIsDocumentTouched,
  updateMultipleKeysInDocument
} from '../../../../../Redux/Slices/DocumentSlice';
import DateFormatService from '../../../../../Services/DateFormat';
import {
  BOOKS_DATE_FORMAT,
  CURRENCY_PRECISION,
  DOC_TYPE,
  STATUS_TYPE,
  TAX_SYSTEM
} from '../../../../../Constants/Constant';
import {
  activeTenantInfo,
  featurePermissions
} from '../../../../../Redux/Slices/AuthSlice';
import {
  getCalendarView,
  getDocDateLabel,
  validateAndUpdateDate
} from '../../../Helper/View/DocDatesHelper';
import Utility, {
  convertBooksDateFormatToUILibraryFormat
} from '../../../../../Utility/Utility';
import {
  getTenantTaxSystem,
  getValidTillDateFromDocDate,
  roundingOffStr
} from '../../../../../SharedComponents/DocumentForm/NewDocumentHelper';
import { addDays } from 'date-fns';
import { fetchCurrencyExchangeRateByDocDate } from '../../../../../Redux/Slices/CommonDataSlice';
import { DOCUMENT_KEYS } from '../../../Utilities/DocConstants';
import {
  getTaxFromProduct,
  updateExpectedDeliveryDateForLineItem
} from '../../../Helper/Common/DocDataHelper';
import { DocLineItemCalculator } from '../../../Helper/Calculator/DocLineItemCalculator';
import AuthService from '../../../../../Services/Auth';
import { selectContactsColumnConfig } from '../../../../../Redux/Slices/ContactsSlice';
import {
  CHANGE_TYPE,
  updatePriceFromPriceList
} from '../../../Helper/DocumentUpdates/PriceListHelper';
import { getExpectedDeliveryDateFromItems } from '../../../Helper/DocRowHelper';
import { FEATURE_PERMISSIONS } from '../../../../../Constants/Permission';

interface IDocDatesProps {
  showFullscreenLayout: boolean;
}

/**
 * Component to show and handle document date interactions
 */
const DocumentDate = (props: IDocDatesProps) => {
  const { draftId, draftType, documentMode } = useContext(
    CommonDraftPropsContext
  );
  const [
    contact,
    documentType,
    documentDate,
    fulfillmentDate,
    items,
    currency,
    gstExchangeRate,
    previousExchangeRate
  ] = useAppSelector(
    selectDocumentFormDataByKeys(draftId, [
      DOCUMENT_KEYS.CONTACT,
      DOCUMENT_KEYS.DOCUMENT_TYPE,
      DOCUMENT_KEYS.DOCUMENT_DATE,
      DOCUMENT_KEYS.FULFILLMENT_DATE,
      DOCUMENT_KEYS.ITEMS,
      DOCUMENT_KEYS.CURRENCY,
      DOCUMENT_KEYS.GST_EXCHANGE_RATE,
      DOCUMENT_KEYS.PREVIOUS_EXCHANGE_RATE
    ]),
    shallowEqual
  );
  const docDateLabel = getDocDateLabel(documentType);
  const currentDate = new Date();

  const tenantInfo = useAppSelector(activeTenantInfo);
  const contactColumnConfig = useAppSelector(selectContactsColumnConfig);
  const featurePermissionsInfo = useAppSelector(featurePermissions);

  const dispatch = useAppDispatch();

  const [docDateOpen, setDocDateOpen] = useState(false);
  const [docDate, setDocDate] = useState(
    documentDate
      ? DateFormatService.getDateFromStr(
          documentDate,
          BOOKS_DATE_FORMAT['DD-MM-YYYY']
        )
      : currentDate
  );

  const isInitialDocumentDateUpdateInProgress = useRef(false);

  /**
   * Fetches updated exchange rate by doc date and sets it in store.
   * Also, evaluate the document keys(`exchangeRate`, `previousExchangeRate` and `gstExchangeRate`) to be updated.
   * @param tempDocDate updated document date
   * @returns Object containing updated/existing `exchangeRate`, `previousExchangeRate` and `gstExchangeRate`.
   */
  const getUpdatedCurrencyExchangeRateAsPerNewDocDate = async (
    tempDocDate: Date
  ) => {
    try {
      const tempDocDateForFetchingExchangeRate = addDays(tempDocDate, 1);
      const updatedExchangeRatesByDocDateRespose = await dispatch(
        fetchCurrencyExchangeRateByDocDate(
          DateFormatService.getDateStrFromDate(
            tempDocDateForFetchingExchangeRate,
            BOOKS_DATE_FORMAT['YYYY-MM-DD']
          )
        )
      );
      const currenciesList =
        ((updatedExchangeRatesByDocDateRespose?.payload as any)
          ?.content as any[]) || [];
      if (!Utility.isEmptyObject(currenciesList)) {
        const activeCurrenciesInfo = currenciesList?.filter(
          (currencyObj: any) =>
            currencyObj?.currencyStatus === STATUS_TYPE.ACTIVE
        );
        const currExchangeRate = activeCurrenciesInfo?.find(
          (data: any) => data?.currencyCode === currency
        )?.currencyExchangeRate;
        const preciseCurrencyExchangeRate = roundingOffStr(
          1 / currExchangeRate,
          CURRENCY_PRECISION
        );
        const currencyChanged = false;
        const exchangeRate = 1 / parseFloat(preciseCurrencyExchangeRate);
        const prevExchangeRate = exchangeRate;
        let calculatedGSTExchangeRate = 1;
        // Here currency never changes so GST exchange rate will remain same
        if (currencyChanged && gstExchangeRate) {
          calculatedGSTExchangeRate =
            (gstExchangeRate * exchangeRate) / previousExchangeRate;
        }
        return {
          exchangeRate: exchangeRate,
          previousExchangeRate: prevExchangeRate,
          gstExchangeRate: calculatedGSTExchangeRate
        };
      }
    } catch (err: any) {
      console.error(
        'Error fetching multicurrency list on doc date change: ',
        err
      );
    }
  };

  const handleDocDateChange = useCallback(
    async (tempDocDateStr: string) => {
      let objectWithUpdatedKeys: any = {};

      let tempDocDate = DateFormatService.getDateFromStr(
        tempDocDateStr,
        BOOKS_DATE_FORMAT['DD-MM-YYYY']
      );

      // Fetch active multi currency list for updated doc date, if multi-currency is enabled
      // and update corresponding keys
      if (tenantInfo?.multicurrencyEnabled) {
        const updatedExchangeRateObj =
          await getUpdatedCurrencyExchangeRateAsPerNewDocDate(tempDocDate);
        objectWithUpdatedKeys = {
          ...objectWithUpdatedKeys,
          ...updatedExchangeRateObj
        };
      }

      // Update validTillDate
      const updatedValidTillDate = getValidTillDateFromDocDate(
        tempDocDate,
        contact || null
      );

      let docLineItems = items?.length ? [...items] : [];
      let updatedShipByDate = tempDocDate;

      // Update expectedDeliveryDt(line level expected delivery date) for lead time
      if (
        Utility.isMRPWithURLCheck() &&
        (documentType === DOC_TYPE.BILL || documentType === DOC_TYPE.ORDER)
      ) {
        docLineItems = docLineItems.map((item: any) => {
          let leadTimeValue = item?.product?.leadTimeDetails?.[0]?.leadTime
            ? item?.product?.leadTimeDetails?.[0]?.leadTime
            : item?.product?.leadTime;
          item = {
            ...item,
            expectedDeliveryDt: updateExpectedDeliveryDateForLineItem(
              leadTimeValue || 0,
              tempDocDate
            )?.getTime()
          };
          return item;
        });

        // Update document shipByDate(fulfillmentDate)
        const oldDocumentDate = DateFormatService.getDateFromStr(
          documentDate,
          BOOKS_DATE_FORMAT['DD-MM-YYYY']
        );
        let shipByDate = DateFormatService.getDateFromStr(
          fulfillmentDate,
          BOOKS_DATE_FORMAT['DD-MM-YYYY']
        );

        updatedShipByDate = getExpectedDeliveryDateFromItems(
          oldDocumentDate > tempDocDate ? tempDocDate : shipByDate,
          docLineItems,
          tempDocDate
        ) as Date;
        if (updatedShipByDate) {
          if (typeof updatedShipByDate === 'number') {
            updatedShipByDate = new Date(updatedShipByDate);
          }
        }
      }

      // Update line level amortization item details (amortizationStartDate, amortizationEndDate)
      if (
        documentType === DOC_TYPE.BILL &&
        featurePermissionsInfo?.Supported?.includes(
          FEATURE_PERMISSIONS.AMORTIZATION
        ) &&
        tenantInfo?.additionalSettings?.AMORTIZATION
      ) {
        docLineItems = docLineItems.map((item: any) => {
          if (
            !Utility.isEmpty(item) &&
            !Utility.isEmpty(item.amortizationTemplate)
          ) {
            let amortizationItemDetails = {};

            let startDate = new Date(tempDocDate);
            let startDateStr = DateFormatService.getDateStrFromDate(
              startDate,
              BOOKS_DATE_FORMAT['DD-MM-YYYY']
            );

            let endDate = new Date(tempDocDate);
            endDate.setMonth(
              endDate.getMonth() + item?.product?.amortizationPeriod || 1
            );
            let endDateStr = DateFormatService.getDateStrFromDate(
              endDate,
              BOOKS_DATE_FORMAT['DD-MM-YYYY']
            );

            if (Utility.isEmpty(item?.amortizationDocumentItemDetails)) {
              amortizationItemDetails = {
                startDate: startDateStr,
                endDate: endDateStr
              };
            } else {
              amortizationItemDetails = {
                ...item?.amortizationDocumentItemDetails,
                startDate: startDateStr,
                endDate: endDateStr
              };
            }

            item = {
              ...item,
              amortizationDocumentItemDetails: amortizationItemDetails,
              amortizationStartDate: startDate.valueOf(),
              amortizationEndDate: endDate.valueOf()
            };
          }
          return item;
        });
      }

      if (!Utility.isEmpty(docLineItems)) {
        if (
          getTenantTaxSystem() === TAX_SYSTEM.SG ||
          getTenantTaxSystem() === TAX_SYSTEM.MALAYSIA
        ) {
          docLineItems = docLineItems.map((item: any, index: number) => {
            if (item && Object.keys(item.product).length !== 0) {
              let taxObject = getTaxFromProduct(
                item?.product,
                documentType,
                contact,
                tempDocDateStr
              );
              let copyItem = { ...item, tax: taxObject };
              const itemCalculator = new DocLineItemCalculator(
                copyItem,
                AuthService.currentTenantInfo
              );
              itemCalculator.updateValuesWithTaxAmount();

              return {
                ...itemCalculator.lineItem,
                taxCode: taxObject?.code,
                taxName: taxObject?.name
              };
            } else {
              return item;
            }
          });
        }
      }

      objectWithUpdatedKeys = {
        ...objectWithUpdatedKeys,
        items: docLineItems,
        documentDate: tempDocDateStr,
        validTillDate: DateFormatService.getDateStrFromDate(
          updatedValidTillDate,
          BOOKS_DATE_FORMAT['DD-MM-YYYY']
        ),
        fulfillmentDate: DateFormatService.getDateStrFromDate(
          updatedShipByDate,
          BOOKS_DATE_FORMAT['DD-MM-YYYY']
        )
      };

      dispatch(
        updateMultipleKeysInDocument({
          draftId,
          keysToUpdate: objectWithUpdatedKeys
        })
      );

      // Price List Call: for document date change
      updatePriceFromPriceList({
        draftId,
        documentMode,
        change: {
          type: CHANGE_TYPE.DOC_DATE_CHANGED,
          rowIndex: null
        },
        contactColumnConfig
      });
    },
    [
      contact,
      contactColumnConfig,
      dispatch,
      documentDate,
      documentMode,
      documentType,
      draftId,
      featurePermissionsInfo?.Supported,
      fulfillmentDate,
      items,
      tenantInfo?.additionalSettings?.AMORTIZATION,
      tenantInfo?.multicurrencyEnabled
    ]
  );

  useEffect(() => {
    if (!documentDate && !isInitialDocumentDateUpdateInProgress.current) {
      console.log('NO DOC DATE SET...', docDate);
      handleDocDateChange(
        DateFormatService.getDateStrFromDate(
          docDate,
          BOOKS_DATE_FORMAT['DD-MM-YYYY']
        )
      );
      isInitialDocumentDateUpdateInProgress.current = true;
    }
  }, [
    dispatch,
    docDate,
    documentDate,
    documentMode,
    draftId,
    handleDocDateChange
  ]);

  const updateDocDate = (date: Date) => {
    setDocDate(date);
    dispatch(setIsDocumentTouched({ draftId, isDocumentTouched: true }));
    handleDocDateChange(
      DateFormatService.getDateStrFromDate(
        date,
        BOOKS_DATE_FORMAT['DD-MM-YYYY']
      )
    );
  };

  const getFullScreenView = () => {
    return (
      <div
        style={{
          width: 150,
          maxWidth: 200
        }}
      >
        <DKInput
          className="parent-width"
          title={docDateLabel}
          value={docDate}
          titleStyle={{ color: 'gray' }}
          valueStyle={{ minHeight: 33 }}
          type={INPUT_TYPE.DATE}
          onChange={(newDate: any) => {
            validateAndUpdateDate(
              newDate,
              DateFormatService.getDateFromStr(
                tenantInfo.bookBeginningStartDate,
                BOOKS_DATE_FORMAT['YYYY-MM-DD']
              ),
              (date: any) => {
                updateDocDate(date);
              },
              `${docDateLabel} cannot be before books beginning date.`,
              true,
              documentType,
              () => {
                setDocDate(new Date(docDate));
              }
            );
            // isDocDateUpdatedManually.current = true;
          }}
          direction={INPUT_VIEW_DIRECTION.VERTICAL}
          required={false}
          dateFormat={convertBooksDateFormatToUILibraryFormat(
            tenantInfo.dateFormat
          )}
        />
      </div>
    );
  };

  const getNonFullscreenView = () => {
    return (
      <div className="row position-relative p-v-xs listPickerBG gap-2">
        <div
          className="row justify-content-between cursor-hand"
          onClick={() => setDocDateOpen((value) => !value)}
        >
          <DKIconText
            icon={DKIcons.data_type.ic_date}
            iconClassName="ic-with-text-height opacity-60"
            text={docDateLabel}
            textClassName="fw-m"
          />
          <DKLabel text={DateFormatService.getDateStrFromDate(docDate)} />
        </div>
        {docDateOpen &&
          getCalendarView(
            docDate,
            (newDate: any) => {
              validateAndUpdateDate(
                newDate,
                DateFormatService.getDateFromStr(
                  tenantInfo.bookBeginningStartDate,
                  BOOKS_DATE_FORMAT['YYYY-MM-DD']
                ),
                (date: any) => {
                  updateDocDate(date);
                },
                `${docDateLabel} cannot be before books beginning date.`,
                true,
                documentType
              );
            },
            setDocDateOpen
          )}
      </div>
    );
  };

  return props.showFullscreenLayout
    ? getFullScreenView()
    : getNonFullscreenView();
};

export default DocumentDate;
