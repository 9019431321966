import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IColumn } from '../../Models/Table';
import { RootState } from '../Store';
import { API_STATUS } from '../../Constants/Constant';
import { ConfigPayload, GridState } from '../../Models/ReduxStore';
import AutomationService from '../../Services/Automation';

const initialState: GridState = {
  data: {} as any,
  columnConfig: [],
  status: API_STATUS.IDLE
};

/////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////// API CALLS AND STORE IN REDUX STORE //////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////

export const fetchAutomation = createAsyncThunk(
  'automation',
  async (tableId: any) => {
    try {
      const response = await AutomationService.getAutomationData(tableId);
      return response;
    } catch (err) {
      console.error('Error fetching automations: ', err);
    }
  }
);

export const fetchapprovalConditionList = createAsyncThunk(
  'approvalConditionList',
  async (forceRefreshInterceptorCache?: boolean) => {
    try {
      /** 
       * By default caching approval conditions call for 10 mins, 
       * In case forceRefreshInterceptorCache is not requested. */
      const DEFAULT_CACHE_TIME = 10 * 60 * 1000;
      const response = await AutomationService.getApprovalConditions(forceRefreshInterceptorCache, DEFAULT_CACHE_TIME);
      return response;
    } catch (err) {
      console.error('Error fetching approval Conditions: ', err);
    }
  }
);
///////////////////////////////////////////////////////////////////////////////////////////////////////////

export const AutomationSlice = createSlice({
  name: 'automation',
  initialState,
  reducers: {
    addAutomationColumnConfig: (
      state,
      action: PayloadAction<ConfigPayload>
    ) => {
      const configs = action.payload.config;
      const tableId = action.payload.tableId;
      let configsToStore: any[] = [];
      configs.forEach((config) => {
        configsToStore.push({
          ...config,
          key: config.columnCode,
          options: config.options ? config.options : []
        });
      });
      state.columnConfig = configsToStore;
      state.configTableId = tableId;
    },
    addColumnToAutomationColumnConfig: (
      state,
      action: PayloadAction<IColumn>
    ) => {
      state.columnConfig = [...state.columnConfig, action.payload];
    },
    updateColumnConfig: (state, action: PayloadAction<IColumn[]>) => {
      state.columnConfig = [...action.payload];
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAutomation.pending, (state) => {
        state.status = API_STATUS.LOADING;
      })
      .addCase(fetchAutomation.fulfilled, (state, action) => {
        state.status = API_STATUS.IDLE;

        let AutomationList: any = action.payload;
        state.data = AutomationList;
      })
      .addCase(fetchapprovalConditionList.pending, (state) => {
        state.status = API_STATUS.LOADING;
      })
      .addCase(fetchapprovalConditionList.fulfilled, (state, action) => {
        state.status = API_STATUS.IDLE;
        state.data = action.payload;
      })
      .addCase(fetchapprovalConditionList.rejected, (state, action) => {
        state.status = API_STATUS.FAILED;
      });
  }
});

export const {
  addAutomationColumnConfig,
  addColumnToAutomationColumnConfig,
  updateColumnConfig
} = AutomationSlice.actions;

export const selectAutomation = (state: RootState) => state.automation.data;
export const selectAutomationColumnConfig = (state: RootState) =>
  state.automation.columnConfig;
export const selectAutomationColumnConfigTableId = (state: RootState) =>
  state.automation.configTableId;
export const approvalConditionList = (state: RootState) =>
  state.automation.data.approvalConditionList;

export default AutomationSlice.reducer;
