import { BOOKS_DATE_FORMAT } from '../Constants/Constant';
import DateFormatService from '../Services/DateFormat';
import { localizedText } from '../Services/Localization/Localization';
import { getCapitalized } from '../Utility/Utility';

export interface JELineItem {
  accountCode?: string;
  description?: string;
  amount?: number;
  cdType?: string;
  accountName?: string;
}
export interface BookSpecifcJE {
  switchCurrency: {
    value: {
      title: string;
      value: string;
    };
  };
}
export interface JournalEntryModel {
  documentType: any;
  id?: string;
  jeCode?: string;
  currency?: string;
  exchangeRate?: number;
  currencyExchangeRate?: number;
  documentSequenceCode?: string;
  jeDate?: string;
  memo?: string;
  recurringActivated?: boolean;
  recurringJE?: boolean;
  generateCNDN?: boolean;
  recurringStatus?: any;
  type: string;
  includeGSTReport?: boolean;
  lineItems: JELineItem[];
  transferType?: string;
  transferNumber?: number;
  amount?: number;
  chequeDate?: string;
  transferDate?: string;
  chequePrinted?: boolean;
  toAccount?: string;
  toBankCurrency?: string;
  bankTransferExchangeRate?: string;
  fromAccount?: string;
  fromBankCurrency?: string;
  foreignBankToBaseExchangeRate?: string;
  baseExchangeRate?: string;
  toExchangeRate?: string;
  bankToBaseExchangeRate?: string;
  attachmentIds?: string;
  customField?: string;
  documentCustomField?: string;
  taxAdjustmentTypeSA?: string;
  taxReturnSectionSA?: string;
  journalVATDetailsSA?: {};
  journalBillDetailsSA?: {};
  includeInTaxReport?: boolean;
  taxAdjustmentDetails?: any;
  taxAdjustmentSubType?: any;
  taxDetails?: any;
  conversionRate?: any;
  amountInDocumentCurrency?: number;
  interCompany?: boolean;
  bookSpecific?: BookSpecifcJE | null;
}

export const CD_TYPE = {
  CREDIT: 'CREDIT',
  DEBIT: 'DEBIT'
};

export const JE_TYPES = {
  NORMAL_JE: 'NORMAL_JE',
  BANK_TRANSFER: 'BANK_TRANSFER',
  PARTY_JE: 'PARTY_JE',
  BOOK_SPECIFIC_JE: 'BOOK_SPECIFIC_JE'
};

export const transferTypes = [
  {
    label: `${getCapitalized(localizedText('cheque'))}`,
    value: 'CHEQUE'
  },
  {
    label: 'Bank Transfer',
    value: 'BANK_TRANSFER'
  },
  {
    label: 'Card',
    value: 'CARD'
  }
];

export const TRANSFER_TYPES_KEYS = {
  BANK_TRANSFER: 'BANK_TRANSFER',
  CHEQUE: 'CHEQUE',
  CARD: 'CARD'
};

export const JENormalInitialState: JournalEntryModel = {
  documentType: 'JOURNAL_ENTRY',
  recurringActivated: false,
  documentSequenceCode: '',
  recurringJE: false,
  generateCNDN: false,
  type: JE_TYPES.NORMAL_JE,
  includeGSTReport: false,
  amount: 0,
  exchangeRate: 0,
  chequePrinted: false,
  journalVATDetailsSA: {},
  journalBillDetailsSA: {},
  lineItems: [],
  includeInTaxReport: false,
  taxAdjustmentDetails: {
    taxAdjustmentType: {
      selectedValue: '',
      options: []
    },
    taxAdjustmentSubType: {
      selectedValue: '',
      options: []
    },
    taxDetails: {},
    showAddTaxAdjustmentPopup: false
  },
  interCompany: false,
  bookSpecific: null
};

export const PartyJEInitialState: JournalEntryModel = {
  documentType: 'JOURNAL_ENTRY',
  recurringActivated: false,
  documentSequenceCode: '',
  recurringJE: false,
  generateCNDN: false,
  type: JE_TYPES.PARTY_JE,
  includeGSTReport: false,
  amount: 0,
  chequePrinted: false,
  journalVATDetailsSA: {},
  journalBillDetailsSA: {},
  lineItems: [],
  includeInTaxReport: false,
  taxAdjustmentDetails: {
    taxAdjustmentType: {
      selectedValue: '',
      options: []
    },
    taxAdjustmentSubType: {
      selectedValue: '',
      options: []
    },
    taxDetails: {},
    showAddTaxAdjustmentPopup: false
  },
  interCompany: false
};

export const JEFundTransferInitialState: JournalEntryModel = {
  documentType: 'JOURNAL_ENTRY',
  recurringActivated: false,
  recurringJE: false,
  generateCNDN: false,
  type: JE_TYPES.BANK_TRANSFER,
  includeGSTReport: false,
  amount: undefined,
  amountInDocumentCurrency: undefined,
  chequePrinted: false,
  journalVATDetailsSA: {},
  journalBillDetailsSA: {},
  exchangeRate: 1,
  currencyExchangeRate: 1,
  jeDate: DateFormatService.getDateStrFromDate(
    new Date(),
    BOOKS_DATE_FORMAT['DD-MM-YYYY']
  ),
  chequeDate: DateFormatService.getDateStrFromDate(
    new Date(),
    BOOKS_DATE_FORMAT['DD-MM-YYYY']
  ),
  transferDate: DateFormatService.getDateStrFromDate(
    new Date(),
    BOOKS_DATE_FORMAT['DD-MM-YYYY']
  ),
  lineItems: [
    {
      accountCode: '',
      amount: undefined,
      cdType: 'CREDIT',
      description: '',
      accountName: ''
    },
    {
      accountCode: '',
      amount: undefined,
      cdType: 'DEBIT',
      description: '',
      accountName: ''
    }
  ],
  conversionRate: '',
  interCompany: false
};
