import React, { Component } from 'react'
import { DocumentSectionType } from '../Constants/Constants';
import HeaderFooterUtility from '../Utilities/HeaderFooterUtility';
import { HTMLTag } from '../Utilities/HTMLTag';
import ComponentManager from './ComponentManager';
import TemplateSettingsManager from './TemplateSettingsManager';

export default class HeaderFooterManager extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        return (
            <div id={HTMLTag.HEADER_AND_FOOTER_MANAGER}
            >
                {this.getHeaderDiv()}
                {this.props.mainContent}
                {this.getFooterDiv()}
            </div>
        )
    }

    getHeaderDiv() {
        var headerFooter = TemplateSettingsManager.defaultTemplateSettings.headerFooter
        if (this.getIsShowHeaderFooter()) {
            var header = HeaderFooterUtility.getContainerByType(headerFooter, DocumentSectionType.header, this.props.documentData)
            if (header !== undefined) {
                return <div
                    id={HTMLTag.PAGE_HEADER_PREFIX}
                    onClick={() => this.props.onHeaderFooterClick(DocumentSectionType.header)}>
                    {header}
                    {ComponentManager.addVerticalSpace(2)}
                    {ComponentManager.addDivider({ backgroundColor: 'lightgrey', height: 1 })}
                    {ComponentManager.addVerticalSpace(2)}
                </div>
            }
        }
        return <></>
    }

    getFooterDiv() {
        var headerFooter = TemplateSettingsManager.defaultTemplateSettings.headerFooter
        if (this.getIsShowHeaderFooter()) {
            var footer = HeaderFooterUtility.getContainerByType(headerFooter, DocumentSectionType.footer, this.props.documentData)
            if (footer !== undefined) {
                return <div
                    id={HTMLTag.PAGE_FOOTER_PREFIX}
                    onClick={() => this.props.onHeaderFooterClick(DocumentSectionType.footer)}>
                    {ComponentManager.addVerticalSpace(2)}
                    {ComponentManager.addDivider({ backgroundColor: 'lightgrey', height: 1 })}
                    {ComponentManager.addVerticalSpace(2)}
                    {footer}
                </div>
            }
        }
        return <></>
    }

    getIsShowHeaderFooter() {
        var headerFooter = TemplateSettingsManager.defaultTemplateSettings.headerFooter
        return headerFooter !== undefined && headerFooter !== null && TemplateSettingsManager.getHeaderFooterIsVisible() && !this.props.isReadOnlyMode
    }
}
