import { useContext } from 'react';
import { shallowEqual } from 'react-redux';
import { DKLabel } from 'deskera-ui-library';
import { CommonDraftPropsContext } from '../../Utilities/DocContext';
import { useAppSelector } from '../../../../Redux/Hooks';
import { selectDocumentFormDataByKeys } from '../../../../Redux/Slices/DocumentSlice';
import { DOCUMENT_KEYS } from '../../Utilities/DocConstants';
import { DOC_TYPE, DOCUMENT_MODE } from '../../../../Constants/Constant';
import Utility from '../../../../Utility/Utility';
import {
  getInvoiceNowStatus,
  INVOICE_NOW_TYPES
} from '../../../Invoices/InvoiceHelper';

const DocEInvoiceDetails = () => {
  const { draftId, documentMode } = useContext(CommonDraftPropsContext);
  const [
    documentType,
    einvoiceInfoIndia,
    einvoiceInfoIndiaCancel,
    isCancelEinvoice
  ] = useAppSelector(
    selectDocumentFormDataByKeys(draftId, [
      DOCUMENT_KEYS.DOCUMENT_TYPE,
      DOCUMENT_KEYS.E_INVOICE_INFO_INDIA,
      DOCUMENT_KEYS.E_INVOICE_INFO_INDIA_CANCEL,
      DOCUMENT_KEYS.IS_CANCEL_E_INVOICE
    ]),
    shallowEqual
  );

  const invoiceNowStatus = getInvoiceNowStatus({
    einvoiceInfoIndia,
    einvoiceInfoIndiaCancel,
    isCancelEinvoice
  });

  if (
    documentType !== DOC_TYPE.INVOICE ||
    documentMode === DOCUMENT_MODE.NEW ||
    documentMode === DOCUMENT_MODE.COPY ||
    Utility.isEmpty(einvoiceInfoIndia)
  ) {
    return null;
  }

  return (
    <div className="column width-auto mt-xl">
      <div className="row width-auto">
        <DKLabel className="fw-m" text="IRN Number: " />
        <DKLabel text={einvoiceInfoIndia.irn || ''} />
      </div>
      {invoiceNowStatus !== INVOICE_NOW_TYPES.CANCELLED && (
        <div className="column width-auto">
          <div className="row width-auto mt-r">
            <DKLabel className="fw-m" text="Acknowledgement Number: " />
            <DKLabel text={einvoiceInfoIndia.ackNo || ''} />
          </div>
          <div className="row width-auto mt-r">
            <DKLabel className="fw-m" text="Acknowledgement Date: " />
            <DKLabel text={einvoiceInfoIndia.ackDt || ''} />
          </div>
        </div>
      )}
    </div>
  );
};

export default DocEInvoiceDetails;
