import { useContext } from 'react';
import { DKIconText, DKIcons, DKLabel } from 'deskera-ui-library';
import { CommonDraftPropsContext } from '../../Utilities/DocContext';
import { useAppSelector } from '../../../../Redux/Hooks';
import { selectDocumentFormDataByKeys } from '../../../../Redux/Slices/DocumentSlice';
import { DOCUMENT_KEYS } from '../../Utilities/DocConstants';
import { DOC_TYPE, FULFILLMENT_TYPE } from '../../../../Constants/Constant';

const DocLinkedDocuments = (props: any) => {
  const { draftId } = useContext(CommonDraftPropsContext);

  const [
    documentType,
    dropShip,
    backOrder,
    fulfillmentType,
    linkedDocuments,
    linkedPurchaseInvoices,
    linkedSalesInvoices,
    linkedQuotationDocuments,
    linkedSalesOrders
  ] = useAppSelector(
    selectDocumentFormDataByKeys(draftId, [
      DOCUMENT_KEYS.DOCUMENT_TYPE,
      DOCUMENT_KEYS.DROPSHIP,
      DOCUMENT_KEYS.BACK_ORDER,
      DOCUMENT_KEYS.FULFILLMENT_TYPE,
      DOCUMENT_KEYS.LINKED_DOCUMENTS,
      DOCUMENT_KEYS.LINKED_PURCHASE_INVOICES,
      DOCUMENT_KEYS.LINKED_SALES_INVOICES,
      DOCUMENT_KEYS.LINKED_QUOTATION_ITEMS,
      DOCUMENT_KEYS.LINKED_SALES_ORDERS
    ])
  );

  const getLinkedDocuments = () => {
    let tempLinkedDocuments: any[] = [];
    let linkedWorkOrders = linkedDocuments?.filter(
      (woItem: any) => woItem?.documentType === DOC_TYPE.WORK_ORDER
    );
    switch (documentType) {
      case DOC_TYPE.QUOTE:
        if (linkedSalesInvoices && linkedSalesInvoices?.length) {
          tempLinkedDocuments = [
            ...tempLinkedDocuments,
            ...linkedSalesInvoices
          ];
        }
        if (linkedSalesOrders && linkedSalesOrders?.length) {
          tempLinkedDocuments = [...tempLinkedDocuments, ...linkedSalesOrders];
        }
        if (fulfillmentType === FULFILLMENT_TYPE.DROP_SHIP || backOrder) {
          tempLinkedDocuments = [...tempLinkedDocuments, ...linkedDocuments];
        }
        if (linkedWorkOrders && linkedWorkOrders.length) {
          tempLinkedDocuments = [...tempLinkedDocuments, ...linkedWorkOrders];
        }
        break;
      case DOC_TYPE.SALES_ORDER:
        if (linkedQuotationDocuments && linkedQuotationDocuments?.length) {
          tempLinkedDocuments = [
            ...tempLinkedDocuments,
            ...linkedQuotationDocuments
          ];
        }
        if (linkedSalesInvoices && linkedSalesInvoices?.length) {
          tempLinkedDocuments = [
            ...tempLinkedDocuments,
            ...linkedSalesInvoices
          ];
        }
        if (
          (fulfillmentType === FULFILLMENT_TYPE.DROP_SHIP || backOrder) &&
          linkedDocuments &&
          linkedDocuments?.length
        ) {
          tempLinkedDocuments = [...tempLinkedDocuments, ...linkedDocuments];
        }
        if (linkedWorkOrders && linkedWorkOrders.length) {
          tempLinkedDocuments = [...tempLinkedDocuments, ...linkedWorkOrders];
        }
        break;
      case DOC_TYPE.ORDER:
      case DOC_TYPE.JOB_WORK_OUT_ORDER:
        tempLinkedDocuments = linkedPurchaseInvoices?.length
          ? linkedPurchaseInvoices
          : dropShip || backOrder
          ? linkedDocuments
          : null;
        tempLinkedDocuments = linkedPurchaseInvoices?.length
          ? linkedDocuments?.[0]?.documentType === 'PURCHASE_REQUEST'
            ? tempLinkedDocuments.concat(linkedDocuments)
            : tempLinkedDocuments
          : linkedDocuments;
        break;
      case DOC_TYPE.INVOICE:
      case DOC_TYPE.BILL:
      default:
        tempLinkedDocuments = linkedDocuments?.length
          ? linkedDocuments
          : fulfillmentType === FULFILLMENT_TYPE.DROP_SHIP || backOrder
          ? linkedDocuments
          : null;
    }
    return tempLinkedDocuments;
  };

  const linkedToDocs = getLinkedDocuments();

  return linkedToDocs?.length ? (
    <div className="row justify-content-between align-items-start p-v-xs listPickerBG gap-2">
      <DKIconText
        icon={DKIcons.data_type.ic_url}
        iconClassName="ic-with-text-height opacity-60"
        text="Linked To"
        textClassName="fw-m"
      />
      <div
        className="column ml-r fs-r align-items-end"
        style={{ whiteSpace: 'break-spaces' }}
      >
        {linkedToDocs.map((doc: any) => (
          <DKLabel text={doc.documentSequenceCode} />
        ))}
      </div>
    </div>
  ) : null;
};

export default DocLinkedDocuments;
