import ApiConstants from '../Constants/ApiConstants';
import Utility from '../Utility/Utility';
import { showToast, TOAST_TYPE, showAlert } from 'deskera-ui-library';
import { COMPLIANCE_SPECIFIC_FIELD_NAME } from '../Constants/Enum';
import http2 from './Interceptor2';
import http from './Interceptor';
export interface StockRequestAPIConfig {
  SearchTerm?: string;
  Query?: string;
  Limit?: number;
  Page?: number;
  IsVariant?: boolean;
  type?: string;
  QueryParam?: any;
  isOB?: boolean;
  sort?: string;
  sortDir?: string;
}

const defaultConfig: StockRequestAPIConfig = {
  SearchTerm: '',
  Limit: 20,
  Page: 0,
  Query: '',
  IsVariant: false,
  type: '',
  QueryParam: '',
  sort: 'createdAt',
  sortDir: 'desc'
};
class StockRequestService {
  static apiConfig: StockRequestAPIConfig;
  // static moduleName: COMPLIANCE_SPECIFIC_FIELD_NAME =
  //   COMPLIANCE_SPECIFIC_FIELD_NAME.PRODUCT;
  static abortController: any = null;

  static getStockRequestEndPoint() {
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    }
    // ${this.apiConfig.Limit}
    const queryString: string = `?limit=${this.apiConfig.Limit}&page=${
      this.apiConfig.Page
    }&search=${this.apiConfig.SearchTerm}&${this.apiConfig.Query}&${
      this.apiConfig.QueryParam ? this.apiConfig.QueryParam : ''
    }`;
    const finalEndpoint =
      ApiConstants.URL.PRODUCT.FETCH_STOCK_REQUEST + queryString;
    return finalEndpoint;
  }

  static getStockRequestByPage() {
    Utility.cancelRequest(StockRequestService.abortController);

    StockRequestService.abortController = Utility.createAbortController();
    return http
      .get(`${StockRequestService.getStockRequestEndPoint()}`, {
        signal: StockRequestService.abortController.signal
      })
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static createStockRequest(data: any) {
    const finalEndPoint: string = ApiConstants.URL.PRODUCT.FETCH_STOCK_REQUEST;
    console.log('createdata', data);
    return http
      .post(finalEndPoint, data)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }
  static fetchProductDocSeqCode(data: any) {
    const finalEndPoint: string =
      ApiConstants.URL.PRODUCT.FETCH_STOCK_TRANSFER_PRODUCT_SEQ_CODE;

    return http
      .post(finalEndPoint, data)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }
  static updateStockRequest(data: any, id: any) {
    const finalEndPoint1: string = ApiConstants.URL.PRODUCT.FETCH_STOCK_REQUEST;
    const finalEndpoint = finalEndPoint1 + id;
    return http
      .put(finalEndpoint, data)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static approveStockRequest(payload: any, id: number) {
    const approveUrl = ApiConstants.URL.PRODUCT.STOCK_REQUEST_APPROVAL + id;
    return http.put(approveUrl, payload).catch((err: any) => {
      console.error('Error Approving Stock Request: ', err);
      return Promise.reject(err);
    });
  }
  static getStockRequestById(id: any) {
    const finalEndpoint = ApiConstants.URL.PRODUCT.FETCH_STOCK_REQUEST + id;
    return http.get(finalEndpoint).catch((err: any) => {
      console.error('Error fetching Stock Request: ', err);
      return Promise.reject(err);
    });
  }
  static deleteStockRequest(id: any) {
    const finalEndpoint = ApiConstants.URL.PRODUCT.FETCH_STOCK_REQUEST + id;
    return http2.delete(finalEndpoint).catch((err: any) => {
      console.error('Error deleting stock Request: ', err);
      return Promise.reject(err);
    });
  }

  static deleteStockRequestByCode(code: any) {
    const finalEndpoint = ApiConstants.URL.STOCK_REQUEST.DELETE_BY_CODE(code);
    return http2.delete(finalEndpoint).catch((err: any) => {
      console.error('Error deleting stock Request: ', err);
      return Promise.reject(err);
    });
  }
}

export default StockRequestService;
