import {
  DKLabel,
  DKLine,
  DKTooltipWrapper,
  DKIcon,
  DKIcons
} from 'deskera-ui-library';
import { FC, useContext, useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import {
  COMPLAINCE_CURRENCY,
  COUNTRY_CODES,
  CURRENCIES,
  DOCUMENT_MODE,
  DOC_TYPE,
  TAX_SYSTEM
} from '../../../../Constants/Constant';
import { useAppSelector } from '../../../../Redux/Hooks';
import { activeTenantInfo } from '../../../../Redux/Slices/AuthSlice';
import { selectDocumentFormDataByKeys } from '../../../../Redux/Slices/DocumentSlice';
import AuthService from '../../../../Services/Auth';
import { getTenantTaxSystem } from '../../../../SharedComponents/DocumentForm/NewDocumentHelper';
import Utility from '../../../../Utility/Utility';
import { DOCUMENT_KEYS, IDocSummaryAmount } from '../../Utilities/DocConstants';
import { CommonDraftPropsContext } from '../../Utilities/DocContext';
import DocAdditionalChargesDiscounts from './Summary/DocAdditionalChargesDiscounts';
import UnitPriceTaxCheck from './Summary/UnitPriceTaxCheck';
import TitleAndAmount from './Summary/TitleAndAmount';
import DocCurrencyExchange from './Summary/DocCurrencyExchange';
import { calculateAmountsForSummary } from '../../Helper/Common/DocDataHelper';
import DocGlobalDiscount from './Summary/DocGlobalDiscount';
import DocRoundOffInput from './Summary/DocRoundOffInput';
import DocTCS from './Summary/DocTCS';
import DocTaxGroupDetails from './Summary/DocTaxGroupDetails';

export interface ISummaryProps {}

export enum GlobalDiscountOption {
  PRE_TAX = 'Pre-Tax',
  POST_TAX = 'Post-Tax'
}

const DocSummary: FC<ISummaryProps> = (props: any) => {
  const { t } = useTranslation();
  const { draftId, documentMode } = useContext(CommonDraftPropsContext);
  const [
    items,
    documentType,
    additionalChargesObject,
    roundOffAmountInDocumentCurrency,
    exchangeRate,
    gstTypeValue,
    tcsPercentage,
    currency,
    gstExchangeRate,
    totalAmount
  ] = useAppSelector(
    selectDocumentFormDataByKeys(draftId, [
      DOCUMENT_KEYS.ITEMS,
      DOCUMENT_KEYS.DOCUMENT_TYPE,
      DOCUMENT_KEYS.ADDITIONAL_CHARGES,
      DOCUMENT_KEYS.ROUND_OFF_AMOUNT_IN_DOCUMENT_CURRENCY,
      DOCUMENT_KEYS.EXCHANGE_RATE,
      DOCUMENT_KEYS.GST_TYPE,
      DOCUMENT_KEYS.TCS_PERCENTAGE,
      DOCUMENT_KEYS.CURRENCY,
      DOCUMENT_KEYS.GST_EXCHANGE_RATE,
      DOCUMENT_KEYS.TOTAL_AMOUNT
    ])
  );

  const tenantInfo = useAppSelector(activeTenantInfo);
  const isManualRoundOff = useRef<boolean>(false);

  const showCommonTaxBlocks = () => {
    let flag = true;
    if (getTenantTaxSystem() === TAX_SYSTEM.US) {
      if (!tenantInfo.complianceEnabled) {
        flag = false;
      } else {
        if (documentType === DOC_TYPE.BILL || documentType === DOC_TYPE.ORDER) {
          flag = false;
        }
      }
    }

    return flag;
  };

  const summaryAmounts: IDocSummaryAmount = useMemo(
    () =>
      calculateAmountsForSummary({
        items,
        gstTypeValue,
        tcsPercentage,
        taxSystem: getTenantTaxSystem(),
        decimalScale: tenantInfo.decimalScale
      }),
    [tenantInfo.decimalScale, items, tcsPercentage, gstTypeValue]
  );

  useEffect(() => {
    if (
      documentMode &&
      (documentMode === DOCUMENT_MODE.EDIT ||
        documentMode === DOCUMENT_MODE.VIEW)
    ) {
      if (
        roundOffAmountInDocumentCurrency &&
        roundOffAmountInDocumentCurrency !== 0
      ) {
        isManualRoundOff.current = true;
      }
    }
  }, []);

  const canShowTaxInBaseCurrency = () => {
    return (
      getTenantTaxSystem() === TAX_SYSTEM.SG &&
      tenantInfo.currency !== CURRENCIES.SG
    );
  };

  const getBaseCurrency = (): string => {
    return CURRENCIES[tenantInfo.country];
  };

  const showTaxesInBaseCurrency = () => {
    if (!canShowTaxInBaseCurrency()) return null;
    return (
      <div
        className="row parent-width mb-m justify-content-between align-items-start"
        style={{ width: '100%' }}
      >
        <div
          className="row width-auto ml-xxl"
          style={{
            minWidth: 100
          }}
        >
          <DKLabel text={`(${getBaseCurrency()})`} className={''} />
        </div>
        <DKLabel
          text={`${Utility.getCurrencySymbolFromCode(
            COMPLAINCE_CURRENCY[AuthService.userDetails?.country]
          )} ${(summaryAmounts.tax / (gstExchangeRate as number)).toFixed(
            tenantInfo.decimalScale
          )}`}
          style={{
            wordBreak: 'break-all'
          }}
        />
      </div>
    );
  };

  const hasTaxGroup = useMemo(() => {
    switch (tenantInfo.country) {
      case COUNTRY_CODES.IN:
      case COUNTRY_CODES.SG:
      case COUNTRY_CODES.UK:
      case COUNTRY_CODES.BE:
      case COUNTRY_CODES.AU:
      case COUNTRY_CODES.CA:
      case COUNTRY_CODES.IL:
      case COUNTRY_CODES.KR:
      case COUNTRY_CODES.NZ:
      case COUNTRY_CODES.DE:
      case COUNTRY_CODES.US:
        return true;
      case COUNTRY_CODES.MY:
      case COUNTRY_CODES.ID:
      case COUNTRY_CODES.PH:
      case COUNTRY_CODES.AE:
        return false;
      default:
        return false;
    }
  }, [tenantInfo.country]);

  const documentHasOptionalItem = () => {
    return Boolean(items?.some((item: any) => !!item.optional));
  };

  const isGlobalDiscountOnSubTotal =
    !!additionalChargesObject?.globalDiscount?.isSubTotalOnly;

  return (
    <div className="column">
      {getTenantTaxSystem() !== TAX_SYSTEM.US && <UnitPriceTaxCheck />}
      <TitleAndAmount
        title={t(`DOCUMENT.DOCUMENT_SUMMARY_VIEW.SUB_TOTAL`)}
        titleClassName="fw-m"
        amount={summaryAmounts.totalWithDiscount}
        currencyCode={currency}
      />
      <TitleAndAmount
        title={'Total Discount (-)'}
        titleClassName="fw-m"
        amount={
          summaryAmounts.discount + summaryAmounts.cascadingDiscountAmount
        }
        currencyCode={currency}
      />
      {showCommonTaxBlocks() && hasTaxGroup && (
        <TitleAndAmount
          title={t(`DOCUMENT.DOCUMENT_SUMMARY_VIEW.BEFORE_TAX`)}
          titleClassName="fw-m"
          amount={
            summaryAmounts.totalWithDiscount -
            summaryAmounts.discount -
            summaryAmounts.cascadingDiscountAmount
          }
          currencyCode={currency}
        />
      )}

      {<DocAdditionalChargesDiscounts summaryAmount={summaryAmounts} />}

      {isGlobalDiscountOnSubTotal && (
        <DocGlobalDiscount summaryAmount={summaryAmounts} />
      )}

      {showCommonTaxBlocks() && (
        <DocTaxGroupDetails
          showPayments={false}
          hasTaxGroup={hasTaxGroup}
          summaryAmounts={summaryAmounts}
        />
      )}
      {showTaxesInBaseCurrency()}

      {tenantInfo.country === COUNTRY_CODES.IN &&
        documentType === DOC_TYPE.BILL && (
          <TitleAndAmount
            title={`TDS (-)`}
            titleClassName="fw-m"
            amount={summaryAmounts.tdsAmount}
            currencyCode={currency}
          />
        )}
      <DocTCS summaryAmounts={summaryAmounts} />

      {!isGlobalDiscountOnSubTotal && (
        <DocGlobalDiscount summaryAmount={summaryAmounts} />
      )}

      <DocRoundOffInput />

      <DKLine className="bg-light-gray mb-m" />

      <TitleAndAmount
        title={`${t(`DOCUMENT.DOCUMENT_SUMMARY_VIEW.TOTAL`)}${
          tenantInfo.multicurrencyEnabled ? '' : '(' + tenantInfo.currency + ')'
        }`}
        titleClassName="walkthrough-step-7 fs-l fw-m"
        amount={totalAmount}
        currencyCode={currency}
        amountClassName="fs-l fw-m"
        needCurrencySelector={true}
        icon={
          documentHasOptionalItem() ? (
            <DKTooltipWrapper
              content={`The optional products price will not be <br> included in group total`}
            >
              <DKIcon
                src={DKIcons.ic_info}
                className="ic-s opacity-6 cursor-hand"
                style={{
                  marginLeft: -16
                }}
              />
            </DKTooltipWrapper>
          ) : null
        }
      />
      {tenantInfo.multicurrencyEnabled && currency !== tenantInfo.currency && (
        <DocCurrencyExchange />
      )}

      {/* Payments accordion */}
      <DocTaxGroupDetails
        showPayments={true}
        hasTaxGroup={true}
        summaryAmounts={summaryAmounts}
      />
    </div>
  );
};
export default DocSummary;
