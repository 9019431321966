import React, { Component } from "react";
import "../../index.css";
import "../../CommonStyles/FontStyle.css";
import { EditorInfoType, FONT_SIZE } from '../../Constants/Constants';
import DKCustomFieldSection from "../../DKUILibrary/DKCustomFieldSection";
import DKEInvoiceSection, { eInvoiceSectionPosition } from "../../DKUILibrary/DKEInvoiceSection";
import DKSignatureSection from "../../DKUILibrary/DKSignatureSection";
import { AddressType } from "../../Helper/AddressParser";
import ComponentManager from "../../Manager/ComponentManager";
import DocumentManager from '../../Manager/DocumentManager';
import { getLocalisedText } from '../../Translate/LanguageManager';
import { HTMLTag } from "../../Utilities/HTMLTag";
import Utility, { getFontSizeClass, getCapitalized, getPageHeight, getVW, getIsFulfillment, getIsGoodsReceipt, getIsJournalEntry } from "../../Utilities/Utility";
import TopSectionAboveTableUtility from "../../Utilities/TopSectionAboveTableUtility";
import DKComponentListTable from "../../DKUILibrary/DKComponentListTable";
import DKApprovalSection from "../../DKUILibrary/DKApprovalSection";

export default class DocumentTemplate22 extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: this.props.data,
        };
    }

    componentWillReceiveProps(nextProp) {
        this.setState({
            data: nextProp.data,
        });
    }

    render() {
        return (
            <div className='DocumentHolder ColumnDiv' style={{ justifyContent: 'space-between', minHeight: getPageHeight(this.state.data.printScale)}}>
                <div className='parent-width' style={{ width: '100%', paddingBottom: getVW(30)  }}>
                    <DKEInvoiceSection
                        data={this.state.data}
                        position={eInvoiceSectionPosition.top}
                    />
                    {this.getHeaderSection()}
                    {ComponentManager.addVerticalSpace(Utility.convertToPrintScale(60, this.state.data.printScale))}
                    {this.getDocumentTypeTitleSection()}
                    {ComponentManager.addVerticalSpace(Utility.convertToPrintScale(20, this.state.data.printScale))}
                    {ComponentManager.addDivider({ backgroundColor: '#4E4E4E', height: getVW(Utility.convertToPrintScale(2, this.state.data.printScale)), width: '100%' })}
                    {this.getAddressPlusDocumentInfoSection()}
                    {ComponentManager.addDivider({ backgroundColor: '#4E4E4E', height: getVW(Utility.convertToPrintScale(2, this.state.data.printScale)), width: '100%' })}
                    {Utility.getIsContainsCustomField('top') && ComponentManager.addVerticalSpace(10)}
                    <DKCustomFieldSection data={this.state.data} onCustomFieldClick={this.props.onCustomFieldClick} customFieldPosition={'top'} />
                    {Utility.getIsContainsCustomField('top') && ComponentManager.addVerticalSpace(10)}
                    {Utility.getIsContainsCustomField('top') && ComponentManager.addDivider({ backgroundColor: '#AAAAAA', height: getVW(2), width: '100%' })}
                    {
                        TopSectionAboveTableUtility.getIsTopSectionAboveTableIsVisible() &&
                        <>
                            <div style={{ ...TopSectionAboveTableUtility.getTopSectionWithTableFontStyleProp(undefined, this.state.data.printScale) }}
                                dangerouslySetInnerHTML={{ __html: TopSectionAboveTableUtility.getHtmlString(this.state.data) }} />
                            {ComponentManager.addVerticalSpace(5)}
                        </>
                    }
                    {this.getProductTable()}
                    {Utility.getIsShowAccountProductTable(this.state.data) && ComponentManager.addVerticalSpace(10)}
                    {this.getTableSection()}
                    {Utility.getIsShowExchangeRate(this.state.data) && ComponentManager.addVerticalSpace(20)}
                    {Utility.getIsShowExchangeRate(this.state.data) && this.getExchangeRateTable(this.getTableStyle(), this.state.data)}
                    {Utility.getIsShowExchangeRate(this.state.data) && ComponentManager.addVerticalSpace(20)}
                    {Utility.getIsContainsCustomField('bottom') && ComponentManager.addDivider({ backgroundColor: '#4E4E4E', height: getVW(2), width: '100%' })}
                    {Utility.getIsContainsCustomField('bottom') && ComponentManager.addVerticalSpace(10)}
                    <DKCustomFieldSection data={this.state.data} onCustomFieldClick={this.props.onCustomFieldClick} customFieldPosition={'bottom'} />
                    {Utility.getIsContainsCustomField('bottom') && ComponentManager.addVerticalSpace(10)}
                    {Utility.getIsContainsCustomField('bottom') && ComponentManager.addDivider({ backgroundColor: '#AAAAAA', height: getVW(2), width: '100%' })}
                    {Utility.getIsIncludesBomProductTable() && <DKComponentListTable data={this.state.data} />}
                    <div id={HTMLTag.SIGNATURE}>
                        {ComponentManager.addVerticalSpace(Utility.convertToPrintScale(30, this.state.data.printScale))}
                        {this.state.data.showSignature && ComponentManager.getSignature(this.state.data.signature, this.state.data.printScale)}
                    </div>
                </div>
                <div className='parent-width' style={{ width: '100%' }}>
                    {(getIsFulfillment(this.state.data.type) || getIsGoodsReceipt(this.state.data.type)) && <DKSignatureSection data={this.state.data} onSignatureSectionClicked={this.props.tableClicked} />}
                    {this.getFooterSection()}
                    {Utility.getIsShowApprovalSection() && <DKApprovalSection data={this.state.data} style={this.getTableStyle()} />}
                </div>
                <DKEInvoiceSection
                    data={this.state.data}
                    position={eInvoiceSectionPosition.bottom}
                />
            </div>
        );
    }

    /////// Header Section ///////////
    getHeaderSection() {
        return (
            <div className="RowDiv" style={{ justifyContent: 'center' }}>
                <div className="ColumnDiv" style={{ alignItems: 'center' }}>
                    {ComponentManager.getLogoVisibility(this.state.data.showCompanyLogo, this.state.data.isReadOnlyMode, this.state.data.logo) && ComponentManager.getLogo(this.state.data.logo, Utility.convertToPrintScale(80, this.state.data.printScale), {position: "relative"})}
                    {this.state.data.showCompanyName && <text className={"docFontStyleBold" + getFontSizeClass()}
                        style={{
                            marginTop: getVW(15),
                            color: this.state.data.themeColor,
                            ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.large }, this.state.data.printScale)
                        }}>{getLocalisedText(Utility.getCompanyName(this.state.data))}</text>}
                </div>
            </div>
        )
    }

    /////// Document Type Title Section ///////////
    getDocumentTypeTitleSection() {
        return (
            <div className="RowReverseDiv">
                <text className={"docFontStyleBold" + getFontSizeClass()}
                    style={{
                        color: this.state.data.themeColor,
                        ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.large }, this.state.data.printScale)
                        }}>
                    {getCapitalized(getLocalisedText(this.state.data.documentType))}</text>
            </div>
        )
    }

    /////// Address + Document Info Section ///////////
    getAddressPlusDocumentInfoSection() {
        let borderColor = (this.state.data.showRefNumber || this.state.data.showStartDate || this.state.data.showDueDate || this.state.data.showClientBillToAddress) ? '#AAAAAA' : 'transparent'
        return (
            <div className="RowDiv" style={{ alignItems: "flex-start" }}>
                <div className="ColumnDiv" style={{
                    width: '50%', borderRightColor: borderColor,
                    borderRightWidth: getVW(Utility.convertToPrintScale(2, this.state.data.printScale)), borderRightStyle: 'solid'
                }}>
                    <div style={{ width: Utility.getIsArabicLang() ? '85%' : '70%' }}>
                        {ComponentManager.addVerticalSpace(10)}
                        {this.getDocumentDetailsSection()}
                        {ComponentManager.addVerticalSpace(10)}
                    </div>
                    {((this.state.data.showRefNumber || this.state.data.showStartDate || this.state.data.showDueDate) && this.state.data.showClientBillToAddress) && ComponentManager.addDivider({ backgroundColor: '#AAAAAA', height: getVW(Utility.convertToPrintScale(2, this.state.data.printScale)), width: '100%' })}
                    <div className="RowDiv" style={{
                        marginTop: getVW(10),
                        marginBottom: getVW(10),
                        width: '100%',
                        alignItems: "flex-start",
                        justifyContent: "space-between"
                    }}>
                        {this.state.data.showClientBillToAddress && this.getBillingAddress()}
                    </div>
                    <div className="RowDiv" style={{
                        marginTop: getVW(10),
                        marginBottom: getVW(10),
                        width: '100%',
                        alignItems: "flex-start",
                        justifyContent: "space-between"
                    }}>
                        {Utility.getIsShowPlaceOfSupply(this.state.data) && this.getPlaceOfSupplyInfoSection()}
                    </div>
                    <div className="RowDiv" style={{
                        marginTop: getVW(10),
                        marginBottom: getVW(10),
                        width: '100%',
                        alignItems: "flex-start",
                        justifyContent: "space-between"
                    }}>
                        {(Utility.getIsShowSourceOfSupply(this.state.data) || Utility.getIsShowDestinationOfSupply(this.state.data)) && this.getSourceOfSupplyInfoSection()}
                    </div>
                </div>
                <div className="RowReverseDiv"
                    style={{ marginTop: getVW(10), marginBottom: getVW(10), width: '50%', alignItems: 'flex-start' }}>
                    {this.state.data.showFrom && <div className={"docFontStyle" + getFontSizeClass()}
                        style={{
                            textAlign: 'right',
                            width: '75%',
                            overflow: 'hidden',
                            ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular, ownerType: Utility.getOwnerType(this.state.data, AddressType.from) }, this.state.data.printScale)
                        }}
                            dangerouslySetInnerHTML={{__html:getLocalisedText(Utility.getCompanyAddress(this.state.data))}}>
                    </div>}
                </div>
            </div>)
    }

    getDocumentDetailsSection() {
        var documentDetailList = Utility.getDocumentDetailsList(this.state.data)
        return documentDetailList.map(element => {
            var value = ''
            if (element.key !== undefined) {
                value = this.state.data[element.key]
            }
            else if (element.customFieldValue !== undefined) {
                value = element.customFieldValue
            }
            return ComponentManager.getRowValueSection(
                element.label,
                value,
                "black",
                "black",
                "regular",
                true,
                this.state.data.printScale
            )
        });
    }

    getBillingAddress() {
        return <>
            <div className="RowDiv"
                style={{
                    width: Utility.getIsArabicLang() ? '25%' : '45%',
                    justifyContent: Utility.getIsArabicLang() ? 'flex-end' : 'flex-start',
                }}>
                <text className={"docFontStyleBold" + getFontSizeClass()}
                    style={{
                        textAlign: 'left',
                        ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular, ownerType: Utility.getOwnerType(this.state.data, AddressType.billTo) }, this.state.data.printScale)
                    }}>{getLocalisedText(this.state.data.billToTitle).toUpperCase() + ':'}</text>
            </div>
            <div className="RowDiv" style={{ width: '75%', justifyContent: "flex-start" }}>
                <div className={"docFontStyle" + getFontSizeClass()}
                    style={{
                        width: Utility.getIsArabicLang() ? '79%' : '75%',
                        overflow: 'hidden',
                        textAlign: Utility.getIsArabicLang() ? 'right' : 'left',
                        ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular, ownerType: Utility.getOwnerType(this.state.data, AddressType.billTo) }, this.state.data.printScale)
                    }}
                        dangerouslySetInnerHTML={{__html:getLocalisedText(Utility.getClientBillToAddress(this.state.data))}}>
                </div>
            </div>
        </>;
    }

    /////// Table Section ///////////
    getTableSection() {
        return (<div className="RowDiv">
            {this.getEditableDocumentTable()}
        </div>)
    }

    /////// Footer Section ///////////
    getFooterSection() {
        return (
            <div id={HTMLTag.TERMS_NOTE_PREFIX} className="ColumnDiv" style={{ bottom: 0, width: '100%', justifyContent: 'center', alignItems: "flex-start" }}>
                {(this.state.data.showTermsAndCondition && this.state.data.termsAndCondition) && ComponentManager.getTermsAndConditionOrNotesSection(
                    Utility.getTermsAndConditionTitle(getLocalisedText('terms_&_condition')),
                    this.state.data.termsAndCondition,
                    "100%",
                    undefined,
                    undefined,
                    undefined,
                    getVW(Utility.convertToPrintScale(6, this.state.data.printScale)),
                    this.state.data.printScale,
                    Utility.getTnCOrNotesFontSettingsByType(EditorInfoType.termsAndCondition)
                )}
                {((this.state.data.showTermsAndCondition && this.state.data.termsAndCondition) && (this.state.data.showNotes && this.state.data.notes)) && ComponentManager.addVerticalSpace(Utility.convertToPrintScale(20, this.state.data.printScale))}
                {(this.state.data.showNotes && this.state.data.notes) && ComponentManager.getTermsAndConditionOrNotesSection(
                    Utility.getNotesTitle(getIsJournalEntry(this.state.data.type) ? getLocalisedText('memo') : getLocalisedText('notes')),
                    this.state.data.notes,
                    "100%",
                    undefined,
                    undefined,
                    undefined,
                    getVW(Utility.convertToPrintScale(6, this.state.data.printScale)),
                    this.state.data.printScale,
                    Utility.getTnCOrNotesFontSettingsByType(EditorInfoType.notes)
                )}
            </div>
        )
    }

    getEditableDocumentTable() {
        return ComponentManager.getDKDocumentTable(
            this.props.tableColumn,
            this.getTableStyle(),
            this.props.data,
            this.props.tableClicked,
            this.props.stockTableClicked,
            this.props.onUpdateDataCallback,
            this.props.paymentTableClicked,
            this.props.additionalTableCallback,
        )
    }

    getProductTable() {
        if(Utility.getIsShowAccountProductTable(this.state.data)) {
            return ComponentManager.getDKProductTable(
                Utility.getTableColumnForAccountProduct(),
                this.getTableStyle(),
                this.props.data,
                this.props.tableClicked,
                this.props.stockTableClicked,
                this.props.onUpdateDataCallback,
                this.props.paymentTableClicked,
                this.props.additionalTableCallback,
            )
        }
    }

    getTableStyle() {
        let style = DocumentManager.getTableDefaultStyle();
        style.headerTextColor = "#000000";
        style.footerTextColor = "#000000";
        style.headerBackgroundColor = "transparent";
        style.headerBorderColor = "transparent";
        style.itemBorderColor = "transparent";
        style.footerBackgroundColor = this.state.data.themeColor + '4D';
        style.footerBorderColor = "transparent";
        return style;
    }

    getPlaceOfSupplyInfoSection() {
        return (
            <div
                className="RowDiv"
                style={{
                    alignItems: "flex-start",
                    justifyContent: 'space-between',
                    backgroundColor: "white",
                }}
            >
                {this.getPlaceOfSupply()}

            </div>
        )
    }

    getPlaceOfSupply() {
        return <>
            <div className="RowDiv"
                style={{
                    width: Utility.getIsArabicLang() ? '25%' : '45%',
                    justifyContent: Utility.getIsArabicLang() ? 'flex-end' : 'flex-start',
                }}>
                <text className={"docFontStyleBold" + getFontSizeClass()}
                    style={{
                        textAlign: 'left',
                        ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular, ownerType: true }, this.state.data.printScale)
                    }}>{getLocalisedText('place_of_supply').toUpperCase() + ':'}</text>
            </div>
            <div className="RowDiv" style={{ width: '75%', justifyContent: "flex-start" }}>
                <div className={"docFontStyle" + getFontSizeClass()}
                    style={{
                        width: Utility.getIsArabicLang() ? '79%' : '75%',
                        overflow: 'hidden',
                        textAlign: Utility.getIsArabicLang() ? 'right' : 'left',
                        ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular, ownerType: true }, this.state.data.printScale)
                    }}
                        dangerouslySetInnerHTML={{__html: this.state.data.placeOfSupply}}>
                </div>
            </div>
        </>;

    }

    getSourceOfSupplyInfoSection() {
        return (
            <div  style={{
                    width:'100%'
                }}>
                    {Utility.getIsShowSourceOfSupply(this.state.data) &&
                        <div
                            className="RowDiv"
                            style={{
                                alignItems: "flex-start",
                                justifyContent: 'space-between',
                                backgroundColor: "white",
                            }}
                        >
                            {this.getSourceOfSupply()}
                        </div>
                    }
                {Utility.getIsShowDestinationOfSupply(this.state.data) &&
                    <>
                    {ComponentManager.addVerticalSpace(10)}
                    <div
                        className="RowDiv"
                        style={{
                            alignItems: "flex-start",
                            justifyContent: 'space-between',
                            backgroundColor: "white",
                        }}
                    >
                        {this.getDestinationOfSupply()}
                    </div>
                    </>
                }
            </div>
        )
    }

    getDestinationOfSupply() {
        return<>
        <div className="RowDiv"
                style={{
                    width: Utility.getIsArabicLang() ? '25%' : '45%',
                    justifyContent: Utility.getIsArabicLang() ? 'flex-end' : 'flex-start',
                }}>
                <text className={"docFontStyleBold" + getFontSizeClass()}
                    style={{
                        textAlign: 'left',
                        ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular, ownerType:true }, this.state.data.printScale)
                    }}>{getLocalisedText('destination_of_supply').toUpperCase() + ':'}</text>
            </div>
            <div className="RowDiv" style={{ width: '75%', justifyContent: "flex-start" }}>
                <div className={"docFontStyle" + getFontSizeClass()}
                    style={{
                        width: Utility.getIsArabicLang() ? '79%' : '75%',
                        overflow: 'hidden',
                        textAlign: Utility.getIsArabicLang() ? 'right' : 'left',
                        ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular, ownerType: true}, this.state.data.printScale)
                    }}
                        dangerouslySetInnerHTML={{__html:this.state.data.destinationOfSupply}}>
                </div>
            </div>
        </>
    }

     getSourceOfSupply() {

        return <>
            <div className="RowDiv"
                style={{
                    width: Utility.getIsArabicLang() ? '25%' : '45%',
                    justifyContent: Utility.getIsArabicLang() ? 'flex-end' : 'flex-start',
                }}>
                <text className={"docFontStyleBold" + getFontSizeClass()}
                    style={{
                        textAlign: 'left',
                        ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular, ownerType:true }, this.state.data.printScale)
                    }}>{getLocalisedText('source_of_supply').toUpperCase() + ':'}</text>
            </div>
            <div className="RowDiv" style={{ width: '75%', justifyContent: "flex-start" }}>
                <div className={"docFontStyle" + getFontSizeClass()}
                    style={{
                        width: Utility.getIsArabicLang() ? '79%' : '75%',
                        overflow: 'hidden',
                        textAlign: Utility.getIsArabicLang() ? 'right' : 'left',
                        ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular, ownerType: true }, this.state.data.printScale)
                    }}
                        dangerouslySetInnerHTML={{__html: this.state.data.sourceOfSupply}}>
                </div>
            </div>
        </>;
    }

    getExchangeRateTable(tableStyle, data) {
        const style = {
            ...tableStyle,
            headerBorderBottomColor: "#AAAAAA",
            headerBorderBottomWidth: getVW(2),
        };

        return (
            <div id={HTMLTag.EXCHANGE_RATE} className="RowDiv" style={{ justifyContent: "flex-end" }}>
                <div style={{ width: "45%" }}>
                    {ComponentManager.addDivider({ backgroundColor: '#4E4E4E', height: getVW(2), width: '100%' })}
                    {ComponentManager.getExchangeRateTable(style, data)}                        
                </div>
            </div>
        );
    }
}
