import { showAlert, INPUT_TYPE } from 'deskera-ui-library';
import {
  BOOKS_DATE_FORMAT,
  COMPOSTION_TAX_PERCENT,
  CURRENCY_PRECISION,
  CUSTOM_FIELD_TYPE,
  DOCUMENT_MODE,
  DOC_TYPE,
  GST_TYPE,
  OVERSEAS,
  PRODUCT_TYPE,
  RCM_DOCUMENTS,
  REGEX,
  STATUS_TYPE,
  TAX_SYSTEM,
  TRACKING_TYPE,
  UOM_NA_ID,
  VENDOR_IMPORT
} from '../../../Constants/Constant';
import {
  ADVANCE_TRACKING,
  CONTACT_FORM_TAB,
  PRODUCT_OFFERING_TYPE
} from '../../../Constants/Enum';
import { Store } from '../../../Redux/Store';
import {
  CASCADING_DISCOUNT_PREFIX,
  calculateUSTaxAPIErrorMessages,
  checkIfLineLevelCustomFieldIsValid,
  createLineItem,
  getTenantTaxSystem,
  getUomPrice,
  getUomQuantity,
  rcmAppliedIndiaWithCheckRCMApply,
  rebuildAdvancedTrackingMetaDtosAndUOMInfo,
  updateAvailableQtyBasedOnUOM,
  updateGstType,
  updateRowDataWithParentCFValues
} from '../../../SharedComponents/DocumentForm/NewDocumentHelper';
import Utility from '../../../Utility/Utility';
import {
  BILL_QTY_COLUMNS_IN_DOC,
  COUNTRIES_WITH_NO_MANUAL_TAX_UPDATE,
  DOCUMENT_KEYS,
  DOC_LINE_ITEM_KEYS
} from '../Utilities/DocConstants';
import {
  getCascadingDiscountKeyValue,
  getDocumentByIDFromStore
} from './DocumentHelper';
import { DocLineItemCalculator } from './Calculator/DocLineItemCalculator';
import DateFormatService from '../../../Services/DateFormat';
import { DocumentItem } from '../../../Models/DocumentItem';
import { isSalesDocument, showZipCodeAlert } from '../Utilities/DocCommonUtils';
import {
  getInitialAmortizationDataForEdit,
  getTaxFromProduct,
  updateExpectedDeliveryDateForLineItem
} from './Common/DocDataHelper';
import { FEATURE_PERMISSIONS } from '../../../Constants/Permission';
import { updateMultipleKeysInDocument } from '../../../Redux/Slices/DocumentSlice';
import cloneDeep from 'lodash/cloneDeep';
import TaxService from '../../../Services/Tax';
import AuthService from '../../../Services/Auth';
import { DraftTypes } from '../../../Models/Drafts';
import { CF_INPUT_TYPE } from '../../../Services/CustomField';
import {
  COMMON_EVENTS,
  DOC_POPUP_TYPE,
  commonCustomEvent
} from '../../../Services/event/commonEvents';
import ProductService from '../../../Services/Product';

export const onRowUpdate = ({
  columnKey,
  rowData,
  rowIndex,
  draftId,
  documentMode,
  productCustomFields,
  columnConfig,
  amortizationTemplates,
  isAddedFromBarcode
}: any) => {
  const document: any = getDocumentByIDFromStore(draftId) ?? {};
  const tenantInfo = Store.getState().authInfo.currentTenantInfo.data;
  const {
    additionalSettings,
    decimalScale,
    registeredToCompositionScheme,
    compositionSchemeType
  } = tenantInfo;

  const featurePermissionsInfo =
    Store.getState().authInfo.featurePermissions.data;

  const {
    items,
    documentDate,
    contact,
    gstType,
    isPartialInvoice,
    documentType,
    isPartialSalesOrder,
    isPartialBill,
    exchangeRate,
    fulfillmentDate,
    unitPriceGstInclusive,
    applyRcmCheck
  } = document?.populateFormData ?? {};
  let currentRow = cloneDeep(items[rowIndex]);
  let dataToUpdate: any = rowData && rowData[columnKey];
  const copyOfItems = cloneDeep(items);

  const isUpdateDateReceiveByDateRequiredOnProductChange = () => {
    let maxDateProductObject = [...items]?.reduce(function (a: any, b: any) {
      let firstDate = a?.expectedDeliveryDt
        ? new Date(a?.expectedDeliveryDt)
        : new Date();
      let secondDate = b?.expectedDeliveryDt
        ? new Date(b?.expectedDeliveryDt)
        : new Date();
      return firstDate > secondDate ? a : b;
    });
    if (
      typeof maxDateProductObject.expectedDeliveryDt === 'undefined' ||
      maxDateProductObject.expectedDeliveryDt === null
    ) {
      maxDateProductObject = {
        ...maxDateProductObject,
        expectedDeliveryDt: DateFormatService.getDateFromStr(
          documentDate,
          BOOKS_DATE_FORMAT['DD-MM-YYYY']
        )
      };
    }
    // convert into Date object
    let dateObject = new Date(maxDateProductObject.expectedDeliveryDt);
    const temShipByDate = DateFormatService.getDateFromStr(
      fulfillmentDate,
      BOOKS_DATE_FORMAT['DD-MM-YYYY']
    );
    if (
      dateObject.getTime() === temShipByDate.getTime() ||
      dateObject.getTime() > temShipByDate.getTime()
    ) {
      return true;
    } else {
      return false;
    }
  };

  if (currentRow) {
    currentRow['invalidFields'] = Array.isArray(currentRow?.invalidFields)
      ? [...currentRow['invalidFields']].filter((field) => field !== columnKey)
      : [];
  }

  const onProductChange = () => {
    const getNewRow = () => {
      return createLineItem(
        dataToUpdate,
        Utility.getValue(currentRow.gstType, gstType),
        currentRow.lineNumber,
        documentType
      );
    };

    let isUpdateReceiveByDateRequired = false;
    if (
      (documentType === DOC_TYPE.BILL || documentType === DOC_TYPE.ORDER) &&
      Utility.isNotEmpty(copyOfItems)
    ) {
      isUpdateReceiveByDateRequired =
        isUpdateDateReceiveByDateRequiredOnProductChange();
    }

    const cascadingDiscountKeyValues = getCascadingDiscountKeyValue(currentRow);
    const itemDiscountMethod = currentRow?.itemDiscountMethod;
    let updatedRow: DocumentItem | any = {} as any;
    if (Utility.isNotEmpty(dataToUpdate)) {
      if (
        documentMode === DOCUMENT_MODE.EDIT ||
        documentMode === DOCUMENT_MODE.COPY
      ) {
        if (
          Utility.isNotEmpty(currentRow?.productCode) &&
          dataToUpdate?.productId === currentRow?.product
        ) {
          updatedRow = {
            ...currentRow,
            gstType: Utility.getValue(currentRow.gstType, gstType)
          };
        } else {
          updatedRow = getNewRow();
        }
      } else {
        updatedRow = getNewRow();
      }
      updatedRow = {
        ...updatedRow,
        ...cascadingDiscountKeyValues,
        itemDiscountMethod: itemDiscountMethod,
        advancedTracking: dataToUpdate.advancedTracking,
        unitPriceGstInclusive: currentRow.unitPriceGstInclusive,
        basePrice: currentRow?.basePrice ? currentRow?.basePrice : '',
        allocationType:
          dataToUpdate?.revenueRecognitionInfo &&
          dataToUpdate?.revenueRecognitionInfo.allocationType
            ? dataToUpdate?.revenueRecognitionInfo.allocationType
            : '',
        isPartialInvoice: isPartialInvoice ?? false,
        isPartialSalesOrder: isPartialSalesOrder ?? false,
        isPartialBill: isPartialBill ?? false,
        availableQty: dataToUpdate.inventory?.availableQuantity
      };

      if (Utility.isComponentDetailsForFGOnInvoiceSOQuote()) {
        updatedRow = {
          ...updatedRow,
          bomComponentGroupDetails: currentRow?.bomComponentGroupDetails,
          unmodifiedBomComponentGroupDetails:
            currentRow?.unmodifiedBomComponentGroupDetails
        };
      }

      if (additionalSettings?.REV_REC) {
        updatedRow[DOC_LINE_ITEM_KEYS.BASE_PRICE] = dataToUpdate.basePrice;
        updatedRow[DOC_LINE_ITEM_KEYS.ALLOCATION_TYPE] =
          dataToUpdate.allocationType;
      }
      if (documentType === DOC_TYPE.BILL || documentType === DOC_TYPE.ORDER) {
        // update expectedDeliveryDt
        const evaluatedExpectedDeliveryDt =
          updateExpectedDeliveryDateForLineItem(
            dataToUpdate?.leadTimeDetails?.[0]?.leadTime || 0,
            DateFormatService.getDateFromStr(
              documentDate,
              BOOKS_DATE_FORMAT['DD-MM-YYYY']
            )
          ).getTime();
        updatedRow[DOC_LINE_ITEM_KEYS.EXPECTED_DELIVERY_DT] =
          evaluatedExpectedDeliveryDt ? evaluatedExpectedDeliveryDt : '';
      }
      if (getTenantTaxSystem() === TAX_SYSTEM.INDIA_GST) {
        if (contact && dataToUpdate) {
          updatedRow = {
            ...updatedRow,
            isRcmApplied: rcmAppliedIndiaWithCheckRCMApply(
              documentType,
              contact.gstTreatment,
              dataToUpdate,
              applyRcmCheck
            )
          };
        }
      } else if (getTenantTaxSystem() === TAX_SYSTEM.MALAYSIA) {
        updatedRow = {
          ...updatedRow,
          isTaxable: dataToUpdate ? dataToUpdate.exemptedMalaysia : true
        };
      }
      if (isSalesDocument(documentType) && registeredToCompositionScheme) {
        updatedRow = {
          ...updatedRow,
          compositionTaxPercent: COMPOSTION_TAX_PERCENT[compositionSchemeType]
        };
      }

      currentRow[DOC_LINE_ITEM_KEYS.USER_SET_TAXES] = false;

      let tax = getTaxFromProduct(
        dataToUpdate,
        documentType,
        contact,
        documentDate
      );
      updatedRow.invalidFields = updatedRow.invalidFields
        ? [...updatedRow.invalidFields].filter(
            (field) => field !== DOC_LINE_ITEM_KEYS.UNIT_PRICE
          )
        : [];
      if (updatedRow.unitPrice === 0) {
        updatedRow.invalidFields.push(DOC_LINE_ITEM_KEYS.UNIT_PRICE);
      }

      if (
        (typeof dataToUpdate.taxPreference !== 'undefined' &&
          dataToUpdate.taxPreference !== null &&
          !dataToUpdate.taxPreference) ||
        !updatedRow.isTaxable
      ) {
        updatedRow.nonEditableColumns = updatedRow.nonEditableColumns
          ? [...updatedRow.nonEditableColumns].filter(
              (field) => field !== DOC_LINE_ITEM_KEYS.TAX
            )
          : [];
        updatedRow?.nonEditableColumns?.push(DOC_LINE_ITEM_KEYS.TAX);
      }
      if (updatedRow?.isRcmApplied) {
        let copyOfNonEditableColumns = updatedRow?.nonEditableColumns || [];
        copyOfNonEditableColumns =
          copyOfNonEditableColumns?.filter(
            (field: string) => field !== DOC_LINE_ITEM_KEYS.TAX
          ) || [];
        copyOfNonEditableColumns?.push(DOC_LINE_ITEM_KEYS.TAX);
        updatedRow.nonEditableColumns = copyOfNonEditableColumns;
      }
      if (updatedRow && updatedRow.nonEditableColumns) {
        updatedRow.nonEditableColumns = [...updatedRow.nonEditableColumns];
        updatedRow.nonEditableColumns.push(DOC_LINE_ITEM_KEYS.ALLOCATION_TYPE);
      }
      if (!!isAddedFromBarcode) {
        updatedRow.discount = dataToUpdate.discountAmount;
        updatedRow.discountAmount = dataToUpdate.discountAmount;
      }

      updatedRow.uomQuantity = getUomQuantity(
        currentRow.productQuantity,
        currentRow.documentUOMSchemaDefinition
      );
      updatedRow.uomUnitPrice = getUomPrice(
        currentRow.unitPrice,
        currentRow.documentUOMSchemaDefinition
      );
      updatedRow.uomAvailableQuantity = getUomQuantity(
        currentRow.availableQuantity,
        currentRow.documentUOMSchemaDefinition
      );
      updatedRow.uomFulfilledQuantity = getUomQuantity(
        currentRow.fulfilledQuantity,
        currentRow.documentUOMSchemaDefinition
      );
      updatedRow.uomQuantityFulfilled = getUomQuantity(
        currentRow.quantityFulfilled,
        currentRow.documentUOMSchemaDefinition
      );
      updatedRow.uomPendingQuantity = getUomQuantity(
        currentRow.pendingQuantity,
        currentRow.documentUOMSchemaDefinition
      );
      updatedRow.uomReceivedQuantityInBills = getUomQuantity(
        currentRow.receivedQuantityInBills,
        currentRow.documentUOMSchemaDefinition
      );
      updatedRow.uomReceivedQuantityInOrder = getUomQuantity(
        currentRow.receivedQuantityInOrder,
        currentRow.documentUOMSchemaDefinition
      );
      updatedRow.basePrice = currentRow.basePrice ? currentRow.basePrice : 0;
      updatedRow.allocationType = currentRow?.revenueRecognitionInfo
        ?.allocationType
        ? currentRow.revenueRecognitionInfo.allocationType
        : '';

      updatedRow = addProductCustomFieldsToLineItem(
        { ...updatedRow },
        dataToUpdate,
        productCustomFields
      );

      updatedRow = checkIfLineLevelCustomFieldIsValid(
        updatedRow,
        productCustomFields
      );

      updatedRow = {
        ...updatedRow,
        unitPrice: updatedRow.unitPrice
          ? updatedRow.unitPrice * exchangeRate
          : 0,
        unitPriceGstInclusive: unitPriceGstInclusive,
        documentUom: dataToUpdate.stockUom,
        cessRule: dataToUpdate.cessRule,
        cessRuleDescription: dataToUpdate.cessRuleDescription,
        cessNonAdvol: dataToUpdate?.cessNonAdvol,
        stockUom: dataToUpdate.stockUom,
        reservedQuantitiesData: null,
        basePrice: dataToUpdate?.basePrice ? dataToUpdate?.basePrice : 0,
        allocationType: dataToUpdate?.revenueRecognitionInfo?.allocationType
          ? dataToUpdate?.revenueRecognitionInfo?.allocationType
          : ''
      };

      if (
        documentType === DOC_TYPE.BILL &&
        featurePermissionsInfo?.Supported?.includes(
          FEATURE_PERMISSIONS.AMORTIZATION
        ) &&
        additionalSettings?.AMORTIZATION
      ) {
        if (!updatedRow?.product?.amortizationTemplateCode) {
          if (Utility.isEmptyObject(updatedRow?.nonEditableColumns)) {
            updatedRow.nonEditableColumns = [];
          }
          updatedRow.nonEditableColumns = [
            ...updatedRow.nonEditableColumns,
            DOC_LINE_ITEM_KEYS.AMORTIZATION_TEMPLATE,
            DOC_LINE_ITEM_KEYS.AMORTIZATION_START_DATE,
            DOC_LINE_ITEM_KEYS.AMORTIZATION_END_DATE
          ];
        }
        const docDate = DateFormatService.getDateFromStr(
          documentDate,
          BOOKS_DATE_FORMAT['DD-MM-YYYY']
        );
        if (dataToUpdate?.amortizationPeriod > 0) {
          let startDate = new Date(docDate.valueOf());
          let endDate = new Date(docDate.valueOf());
          endDate.setMonth(
            endDate.getMonth() + dataToUpdate?.amortizationPeriod || 1
          );

          endDate.setDate(endDate.getDate() - 1);

          let amortizationItemDetails = {
            startDate: DateFormatService.getDateStrFromDate(
              startDate,
              BOOKS_DATE_FORMAT['DD-MM-YYYY']
            ),
            endDate: DateFormatService.getDateStrFromDate(
              endDate,
              BOOKS_DATE_FORMAT['DD-MM-YYYY']
            ),
            deferralAccountCode: dataToUpdate?.deferredExpenseAccountCode,
            templateCode: dataToUpdate?.amortizationTemplateCode
          };

          let amortizationTemplate = amortizationTemplates.find(
            (template: any) =>
              template.documentSeqCode ===
              dataToUpdate?.amortizationTemplateCode
          );

          if (!Utility.isEmpty(amortizationTemplate)) {
            updatedRow.amortizationDocumentItemDetails = {
              ...amortizationItemDetails
            };
            updatedRow.amortizationTemplate = amortizationTemplate;
            updatedRow.amortizationStartDate = startDate.valueOf();
            updatedRow.amortizationEndDate = endDate.valueOf();
          }
        } else {
          let amortizationItemDetails = {
            startDate: null,
            endDate: null,
            deferralAccountCode: dataToUpdate?.deferredExpenseAccountCode,
            templateCode: dataToUpdate?.amortizationTemplateCode
          };
          let amortizationTemplate = amortizationTemplates.find(
            (template: any) =>
              template.documentSeqCode ===
              dataToUpdate?.amortizationTemplateCode
          );

          if (!Utility.isEmpty(amortizationTemplate)) {
            updatedRow.amortizationDocumentItemDetails = {
              ...amortizationItemDetails
            };
            updatedRow.amortizationTemplate = amortizationTemplate;
            updatedRow.amortizationStartDate = null;
            updatedRow.amortizationEndDate = null;
          }
        }
      }

      if (
        (documentType === DOC_TYPE.SALES_ORDER ||
          documentType === DOC_TYPE.INVOICE) &&
        featurePermissionsInfo?.Supported?.includes(
          FEATURE_PERMISSIONS.REVENUE_RECOGNITION_ADV
        ) &&
        additionalSettings?.REV_REC
      ) {
        if (!dataToUpdate?.revenueRecognitionInfo?.revRecRule) {
          if (Utility.isEmptyObject(updatedRow?.nonEditableColumns)) {
            updatedRow.nonEditableColumns = [];
          } else {
            updatedRow.nonEditableColumns = [
              ...updatedRow.nonEditableColumns,
              DOC_LINE_ITEM_KEYS.REV_REC_START_DATE,
              DOC_LINE_ITEM_KEYS.REV_REC_END_DATE
            ];
          }
        }
      }
      if (
        updatedRow.type === PRODUCT_TYPE.NON_TRACKED &&
        updatedRow?.uom?.id === UOM_NA_ID
      ) {
        if (Utility.isEmptyObject(updatedRow?.nonEditableColumns)) {
          updatedRow.nonEditableColumns = [];
        }
        updatedRow.nonEditableColumns = [
          ...updatedRow.nonEditableColumns,
          DOC_LINE_ITEM_KEYS.PRODUCT_QUANTITY
        ];
      }

      // ZEN-12456, ZEN-12853 Overseas Contact RCM for Bills
      if (
        (documentType === DOC_TYPE.BILL || documentType === DOC_TYPE.ORDER) &&
        !updatedRow?.isRcmApplied
      ) {
        if (
          contact?.gstTreatment === OVERSEAS &&
          contact?.vendorType === VENDOR_IMPORT
        ) {
          const zeroTax =
            Store.getState()?.commonData?.data?.tax?.purchase?.find(
              (tax: any) => tax.percent === 0
            );

          if (!Utility.isEmptyObject(zeroTax)) {
            updatedRow = {
              ...updatedRow,
              igstRate: 0,
              igstAmount: 0,
              taxCode: zeroTax.taxCode,
              taxName: zeroTax.name,
              taxAmount: 0
            };
            tax = { ...zeroTax };
          }
        }
      }

      updatedRow = { ...updatedRow, tax };

      // Update shipByDate(fulfillmentDate) as per expectedDeliveryDt
      if (
        Utility.isMRPWithURLCheck() &&
        (documentType === DOC_TYPE.BILL || documentType === DOC_TYPE.ORDER)
      ) {
        /*
        Create tempItems and push the updatedRow at rowIndex
        This helps in passing proper parameters to getExpectedDeliveryDateFromItems()
      */
        let docShipByDate;
        let tempItems = [...copyOfItems];
        tempItems[rowIndex] = { ...updatedRow };
        const docDate = DateFormatService.getDateFromStr(
          documentDate,
          BOOKS_DATE_FORMAT['DD-MM-YYYY']
        );
        if (isUpdateReceiveByDateRequired) {
          docShipByDate = getExpectedDeliveryDateFromItems(
            docDate,
            tempItems,
            docDate
          );
        } else {
          const shipByDate = fulfillmentDate
            ? DateFormatService.getDateFromStr(
                fulfillmentDate,
                BOOKS_DATE_FORMAT['DD-MM-YYYY']
              )
            : new Date();
          docShipByDate = getExpectedDeliveryDateFromItems(
            shipByDate,
            tempItems,
            docDate
          );
        }
        if (docShipByDate) {
          if (typeof docShipByDate === 'number') {
            docShipByDate = new Date(docShipByDate);
          }
          Store.dispatch(
            updateMultipleKeysInDocument({
              draftId,
              keysToUpdate: {
                [DOCUMENT_KEYS.FULFILLMENT_DATE]:
                  DateFormatService.getDateStrFromDate(
                    docShipByDate,
                    BOOKS_DATE_FORMAT['DD-MM-YYYY']
                  )
              }
            })
          );
        }
      }
    }

    return updatedRow;
  };

  const handleProductUpdate = () => {
    const product = rowData[DOC_LINE_ITEM_KEYS.PRODUCT];
    if (!validateProduct(product)) {
      if (Utility.isEmptyObject(product)) {
        currentRow = {};
        currentRow?.invalidFields?.push(DOC_LINE_ITEM_KEYS.PRODUCT);
      }
    }
    if (Utility.isNotEmpty(currentRow)) {
      currentRow = {
        ...currentRow,
        [DOC_LINE_ITEM_KEYS.PRODUCT]: dataToUpdate,
        [DOC_LINE_ITEM_KEYS.DOCUMENT_SEQUENCE_CODE]:
          dataToUpdate?.documentSequenceCode,
        [DOC_LINE_ITEM_KEYS.AVAILABLE_QUANTITY]:
          dataToUpdate?.inventory?.availableQuantity
      };
    }
    return onProductChange();
  };

  const handleProductQtyUpdate = () => {
    if (
      !Number.isInteger(Number(dataToUpdate)) &&
      currentRow?.product?.advancedTracking === ADVANCE_TRACKING.SERIAL
    ) {
      showAlert(
        'Error',
        `The quantity of serial products cannot be entered as a decimal number.`
      );
      dataToUpdate = 0;
      currentRow?.invalidFields?.push(DOC_LINE_ITEM_KEYS.PRODUCT_QUANTITY);
    } else {
      if (!dataToUpdate || isNaN(dataToUpdate)) {
        dataToUpdate = 0;
        currentRow?.invalidFields?.push(DOC_LINE_ITEM_KEYS.PRODUCT_QUANTITY);
      } else {
        dataToUpdate = Utility.roundingOff(dataToUpdate, decimalScale);
        currentRow[DOC_LINE_ITEM_KEYS.UOM_QUANTITY] = Utility.roundOff(
          dataToUpdate,
          decimalScale
        );
      }
    }

    if (
      dataToUpdate &&
      !isNaN(dataToUpdate) &&
      (rowData.isPartialInvoice ||
        rowData.isPartialBill ||
        rowData.isPartialSalesOrder) &&
      isQuantityMoreThatPendingQuantity(+dataToUpdate, rowData, documentType)
    ) {
      dataToUpdate = 0;
      currentRow?.invalidFields?.push(DOC_LINE_ITEM_KEYS.PRODUCT_QUANTITY);
      showAlert('Error!', `Quantity should not be more than pending quantity`);
    }

    if (dataToUpdate && !isNaN(dataToUpdate) && dataToUpdate <= 0) {
      dataToUpdate = 0;
      currentRow?.invalidFields?.push(DOC_LINE_ITEM_KEYS.PRODUCT_QUANTITY);
    }

    if (
      !currentRow.invalidFields.includes(DOC_LINE_ITEM_KEYS.PRODUCT_QUANTITY)
    ) {
      if (Utility.isNotEmpty(currentRow[DOC_LINE_ITEM_KEYS.PRODUCT])) {
        if (Utility.isNotEmpty(currentRow)) {
          currentRow[DOC_LINE_ITEM_KEYS.PRODUCT_QUANTITY] = +dataToUpdate;
          currentRow[DOC_LINE_ITEM_KEYS.QUANTITY_ORDERED] = +dataToUpdate;
        }
        currentRow[DOC_LINE_ITEM_KEYS.PRODUCT_QUANTITY] = +dataToUpdate;
        currentRow[DOC_LINE_ITEM_KEYS.RESERVED_QUANTITIES_DATA] = null;
        if (Utility.isNotEmpty(currentRow[DOC_LINE_ITEM_KEYS.TDS_INFO_INDIA])) {
          currentRow[DOC_LINE_ITEM_KEYS.TDS_INFO_INDIA] = null;
        }
      }
    }
    currentRow[DOC_LINE_ITEM_KEYS.PRODUCT_QUANTITY] = +dataToUpdate;
    return currentRow;
  };

  const handleUnitPriceUpdate = () => {
    let updatedPrice;
    if (dataToUpdate) {
      if (typeof dataToUpdate === 'object' && 'price' in dataToUpdate) {
        updatedPrice = dataToUpdate.price;
      } else {
        updatedPrice = dataToUpdate;
      }
    } else {
      updatedPrice = 0;
    }
    if (updatedPrice === '') {
      updatedPrice = undefined;
      currentRow?.invalidFields?.push(DOC_LINE_ITEM_KEYS.UNIT_PRICE);
    } else {
      updatedPrice = Utility.roundingOff(updatedPrice, decimalScale);
      currentRow[DOC_LINE_ITEM_KEYS.UOM_UNIT_PRICE] = Utility.roundingOff(
        updatedPrice,
        decimalScale
      );
    }
    if (Utility.isNotEmpty(currentRow)) {
      currentRow[DOC_LINE_ITEM_KEYS.UNIT_PRICE] = updatedPrice;
      if (Utility.isNotEmpty(currentRow[DOC_LINE_ITEM_KEYS.TDS_INFO_INDIA])) {
        currentRow[DOC_LINE_ITEM_KEYS.TDS_INFO_INDIA] = null;
      }
    }
    return currentRow;
  };

  const handleDiscountUpdate = () => {
    const docLineItem = new DocLineItemCalculator(currentRow, {
      additionalSettings
    });
    docLineItem.setInitialValues();
    docLineItem.lineItem.taxAmount = docLineItem.calculateTaxAmount();
    docLineItem.updateCalculatedValues();
    const matcher = String(dataToUpdate).match(REGEX.PERCENT_NUMER);
    if (Utility.isNotEmpty(currentRow)) {
      currentRow[DOC_LINE_ITEM_KEYS.DISCOUNT_IN_PERCENT] = false;
    }
    if (!matcher) {
      dataToUpdate = 0;
    } else if ('%' === matcher[4]) {
      const percentDiscount = Number(dataToUpdate.replace('%', ''));
      if (percentDiscount > 100) {
        dataToUpdate = 100;
      } else {
        dataToUpdate = percentDiscount;
      }
      if (Utility.isNotEmpty(currentRow)) {
        currentRow[DOC_LINE_ITEM_KEYS.DISCOUNT_IN_PERCENT] = true;
      }
    } else if (
      dataToUpdate < 0 ||
      (dataToUpdate as number) > (docLineItem.lineItem.subTotal as number)
    ) {
      currentRow?.invalidFields?.push(DOC_LINE_ITEM_KEYS.DISCOUNT);
    }
    if (Utility.isNotEmpty(currentRow)) {
      currentRow[DOC_LINE_ITEM_KEYS.DISCOUNT] = dataToUpdate;
      if (Utility.isNotEmpty(currentRow[DOC_LINE_ITEM_KEYS.TDS_INFO_INDIA])) {
        currentRow[DOC_LINE_ITEM_KEYS.TDS_INFO_INDIA] = null;
      }
    }
    return currentRow;
  };

  const handleTaxUpdate = () => {
    if (
      typeof currentRow?.product?.taxPreference !== 'undefined' &&
      currentRow?.product?.taxPreference !== null &&
      !currentRow?.product?.taxPreference
    ) {
      dataToUpdate = null;
    }
    if (Utility.isNotEmpty(currentRow)) {
      currentRow[DOC_LINE_ITEM_KEYS.TAX] = dataToUpdate;
    }
    if (getTenantTaxSystem() === TAX_SYSTEM.UK) {
      //TODO - Handle memo Change
    }

    currentRow[DOC_LINE_ITEM_KEYS.USER_SET_TAXES] = false;
    return currentRow;
  };

  const handleTaxAmountUpdate = () => {
    const tax = currentRow.tax;
    const updates: any = {
      taxCode: tax ? tax.code : '',
      taxName: tax ? tax.name : ''
    };
    if (!tax) {
      updates.taxAmount = 0;
    }
    currentRow = {
      ...currentRow,
      ...updates
    };
    if (currentRow) {
      currentRow[DOC_LINE_ITEM_KEYS.TAX_AMOUNT] = dataToUpdate;
      if (Number(dataToUpdate) > 0) {
        currentRow[DOC_LINE_ITEM_KEYS.TAX_AMOUNT] = Number(dataToUpdate);
      }
    }
    return currentRow;
  };

  const handleUOMUpdate = () => {
    currentRow = updateUomSchemaForLineItem(currentRow, dataToUpdate);
    if (!currentRow?.invalidFields?.includes(DOC_LINE_ITEM_KEYS.UOM)) {
      if (Utility.isNotEmpty(currentRow)) {
        currentRow[DOC_LINE_ITEM_KEYS.UOM] = dataToUpdate;
        currentRow[DOC_LINE_ITEM_KEYS.RESERVED_QUANTITIES_DATA] = null;
      }
      if (
        dataToUpdate?.id === UOM_NA_ID &&
        dataToUpdate?.type === PRODUCT_TYPE.NON_TRACKED
      ) {
        if (Utility.isEmptyObject(currentRow?.nonEditableColumns)) {
          currentRow.nonEditableColumns = [
            ...currentRow.nonEditableColumns,
            DOC_LINE_ITEM_KEYS.PRODUCT_QUANTITY
          ];
        } else {
          currentRow.nonEditableColumns = [DOC_LINE_ITEM_KEYS.PRODUCT_QUANTITY];
        }
      }
      currentRow[DOC_LINE_ITEM_KEYS.AVAILABLE_QTY] =
        updateAvailableQtyBasedOnUOM(
          currentRow,
          currentRow[DOC_LINE_ITEM_KEYS.AVAILABLE_QUANTITY]
        );
    }
    return currentRow;
  };

  const handleAmortizationTemplateUpdate = () => {
    if (Utility.isNotEmpty(currentRow)) {
      currentRow[DOC_LINE_ITEM_KEYS.AMORTIZATION_TEMPLATE] = dataToUpdate;
    }
    const docDate = documentDate
      ? DateFormatService.getDateFromStr(
          documentDate,
          BOOKS_DATE_FORMAT['DD-MM-YYYY']
        )
      : new Date();
    if (dataToUpdate?.amortizationPeriod > 0) {
      let startDate = new Date(
        (currentRow?.amortizationStartDate || docDate).valueOf()
      );
      let endDate = new Date(
        (currentRow?.amortizationStartDate || docDate).valueOf()
      );
      endDate.setMonth(
        endDate.getMonth() + dataToUpdate?.amortizationPeriod || 1
      );

      if (dataToUpdate?.amortizationPeriod > 0) {
        endDate.setDate(endDate.getDate() - 1);
      }

      let amortizationItemDetails = {
        startDate: DateFormatService.getDateStrFromDate(
          startDate,
          BOOKS_DATE_FORMAT['DD-MM-YYYY']
        ),
        endDate: DateFormatService.getDateStrFromDate(
          endDate,
          BOOKS_DATE_FORMAT['DD-MM-YYYY']
        ),
        deferralAccountCode: currentRow?.product?.deferredExpenseAccountCode,
        templateCode: dataToUpdate?.documentSeqCode
      };

      if (currentRow) {
        currentRow.amortizationDocumentItemDetails = amortizationItemDetails;
        currentRow.amortizationStartDate = startDate.valueOf();
        currentRow.amortizationEndDate = endDate.valueOf();
      }
    } else {
      let amortizationItemDetails = {
        startDate: null,
        endDate: null,
        deferralAccountCode: currentRow?.product?.deferredExpenseAccountCode,
        templateCode: dataToUpdate?.documentSeqCode
      };

      if (currentRow) {
        currentRow.amortizationDocumentItemDetails = amortizationItemDetails;
        currentRow.amortizationStartDate = null;
        currentRow.amortizationEndDate = null;
      }
    }
    return currentRow;
  };

  const handleAmortizationStartDateUpdate = () => {
    let amortizationItemDetailsForStartDate = {};
    dataToUpdate = new Date(dataToUpdate);
    const docDate = documentDate
      ? DateFormatService.getDateFromStr(
          documentDate,
          BOOKS_DATE_FORMAT['DD-MM-YYYY']
        )
      : new Date();
    let startDateStr = DateFormatService.getDateStrFromDate(
      dataToUpdate,
      BOOKS_DATE_FORMAT['DD-MM-YYYY']
    );
    if (Utility.isEmpty(currentRow?.amortizationDocumentItemDetails)) {
      amortizationItemDetailsForStartDate = {
        startDate: startDateStr
      };
    } else {
      amortizationItemDetailsForStartDate = {
        ...currentRow?.amortizationDocumentItemDetails,
        startDate: startDateStr
      };
    }

    const startDateCompare = DateFormatService.getDateStrFromDate(
      dataToUpdate,
      BOOKS_DATE_FORMAT['YYYY-MM-DD']
    );
    const endDateCompare = DateFormatService.getDateStrFromDate(
      new Date(currentRow?.amortizationEndDate),
      BOOKS_DATE_FORMAT['YYYY-MM-DD']
    );
    if (
      currentRow?.amortizationEndDate === null ||
      (currentRow && startDateCompare <= endDateCompare)
    ) {
      currentRow.amortizationDocumentItemDetails =
        amortizationItemDetailsForStartDate;
      currentRow[columnKey] = dataToUpdate.valueOf();
    } else {
      if (!Utility.isEmpty(currentRow.amortizationTemplate)) {
        let startDate = new Date(docDate.valueOf());
        let endDate = new Date(docDate.valueOf());
        endDate.setMonth(
          endDate.getMonth() +
            currentRow.amortizationTemplate?.amortizationPeriod || 1
        );
        if (currentRow.amortizationTemplate?.amortizationPeriod > 0) {
          endDate.setDate(endDate.getDate() - 1);
        }
        const currEndDateStr = DateFormatService.getDateStrFromDate(
          endDate,
          BOOKS_DATE_FORMAT['DD-MM-YYYY']
        );

        const currStartDate = DateFormatService.getDateStrFromDate(
          startDate,
          BOOKS_DATE_FORMAT['DD-MM-YYYY']
        );

        if (Utility.isEmpty(currentRow.amortizationDocumentItemDetails)) {
          amortizationItemDetailsForStartDate = {
            endDate: currEndDateStr,
            startDate: currStartDate
          };
        } else {
          amortizationItemDetailsForStartDate = {
            ...currentRow?.amortizationDocumentItemDetails,
            endDate: currEndDateStr,
            startDate: currStartDate
          };
        }

        currentRow.amortizationDocumentItemDetails =
          amortizationItemDetailsForStartDate;

        currentRow[DOC_LINE_ITEM_KEYS.AMORTIZATION_END_DATE] =
          endDate.valueOf();
        currentRow[DOC_LINE_ITEM_KEYS.AMORTIZATION_START_DATE] =
          startDate.valueOf();
        showAlert(
          'Alert',
          'Amortization Start Date cannot be after Amortization End Date'
        );
      } else {
        showAlert(
          'Alert',
          'Cannot set the Date as Amortization Template is Empty'
        );
      }
    }
    return currentRow;
  };

  const handleAmortizationEndDateUpdate = () => {
    let amortizationItemDetailsForEndDate = {};
    dataToUpdate = new Date(dataToUpdate);
    const docDate = documentDate
      ? DateFormatService.getDateFromStr(
          documentDate,
          BOOKS_DATE_FORMAT['DD-MM-YYYY']
        )
      : new Date();

    let endDateStr = DateFormatService.getDateStrFromDate(
      dataToUpdate,
      BOOKS_DATE_FORMAT['DD-MM-YYYY']
    );
    if (Utility.isEmpty(currentRow.amortizationDocumentItemDetails)) {
      amortizationItemDetailsForEndDate = {
        endDate: endDateStr
      };
    } else {
      amortizationItemDetailsForEndDate = {
        ...currentRow?.amortizationDocumentItemDetails,
        endDate: endDateStr
      };
    }
    const startDateCompareForEndDate = DateFormatService.getDateStrFromDate(
      new Date(currentRow?.amortizationStartDate),
      BOOKS_DATE_FORMAT['YYYY-MM-DD']
    );
    const endDateCompareForEndDate = DateFormatService.getDateStrFromDate(
      dataToUpdate,
      BOOKS_DATE_FORMAT['YYYY-MM-DD']
    );
    if (
      currentRow?.amortizationStartDate === null ||
      (currentRow && endDateCompareForEndDate >= startDateCompareForEndDate)
    ) {
      currentRow.amortizationDocumentItemDetails =
        amortizationItemDetailsForEndDate;
      currentRow[columnKey] = dataToUpdate.valueOf();
    } else {
      if (!Utility.isEmpty(currentRow.amortizationTemplate)) {
        let startDate = new Date(docDate.valueOf());
        let endDate = new Date(docDate.valueOf());

        endDate.setMonth(
          endDate.getMonth() +
            currentRow.amortizationTemplate?.amortizationPeriod || 1
        );
        if (currentRow.amortizationTemplate?.amortizationPeriod > 0) {
          endDate.setDate(endDate.getDate() - 1);
        }
        const currEndDateStr = DateFormatService.getDateStrFromDate(
          endDate,
          BOOKS_DATE_FORMAT['DD-MM-YYYY']
        );

        const currStartDate = DateFormatService.getDateStrFromDate(
          startDate,
          BOOKS_DATE_FORMAT['DD-MM-YYYY']
        );

        if (Utility.isEmpty(currentRow.amortizationDocumentItemDetails)) {
          amortizationItemDetailsForEndDate = {
            endDate: currEndDateStr,
            startDate: currStartDate
          };
        } else {
          amortizationItemDetailsForEndDate = {
            ...currentRow?.amortizationDocumentItemDetails,
            endDate: currEndDateStr,
            startDate: currStartDate
          };
        }

        currentRow.amortizationDocumentItemDetails =
          amortizationItemDetailsForEndDate;

        currentRow['amortizationEndDate'] = endDate.valueOf();
        currentRow['amortizationStartDate'] = startDate.valueOf();

        showAlert(
          'Alert',
          'Amortization End Date cannot be before Amortization Start Date'
        );
      } else {
        showAlert(
          'Alert',
          'Cannot set the Date as Amortization Template is empty'
        );
      }
    }
    return currentRow;
  };

  const handleRevRecStartDate = () => {
    dataToUpdate = new Date(dataToUpdate);
    let revRecStartDate = DateFormatService.getDateStrFromDate(
      dataToUpdate,
      BOOKS_DATE_FORMAT['DD-MM-YYYY']
    );
    if (currentRow) {
      currentRow.revRecDocumentItemDetails = {
        ...(currentRow.revRecDocumentItemDetails || {}),
        startDate: revRecStartDate
      };
      currentRow[DOC_LINE_ITEM_KEYS.REV_REC_START_DATE] =
        dataToUpdate instanceof Date ? dataToUpdate.valueOf() : null;
    }
    return currentRow;
  };

  const handleRevRecEndDate = () => {
    dataToUpdate = new Date(dataToUpdate);
    let revRecEndDate = DateFormatService.getDateStrFromDate(
      dataToUpdate,
      BOOKS_DATE_FORMAT['DD-MM-YYYY']
    );
    if (currentRow) {
      currentRow.revRecDocumentItemDetails = {
        ...(currentRow.revRecDocumentItemDetails || {}),
        endDate: revRecEndDate
      };
      currentRow[DOC_LINE_ITEM_KEYS.REV_REC_END_DATE] =
        dataToUpdate instanceof Date ? dataToUpdate.valueOf() : null;
    }
    return currentRow;
  };

  const handleExpectedDeliveryDate = () => {
    const docDate = documentDate
      ? DateFormatService.getDateFromStr(
          documentDate,
          BOOKS_DATE_FORMAT['DD-MM-YYYY']
        )
      : new Date();
    let selectedDate = dataToUpdate;
    if (typeof dataToUpdate === 'string') {
      // use getTime() to change date into timestamp... helps in storing dates in store
      selectedDate = new Date(dataToUpdate).getTime();
    }

    currentRow[DOC_LINE_ITEM_KEYS.EXPECTED_DELIVERY_DT] = selectedDate
      ? typeof selectedDate === 'number'
        ? selectedDate
        : new Date(selectedDate).getTime()
      : '';

    let tempItems = [...copyOfItems];
    tempItems[rowIndex] = { ...currentRow };
    let expectedDeliveryDt = getExpectedDeliveryDateFromItems(
      selectedDate,
      tempItems,
      docDate
    );

    // update Document ShipBy Date to expectedDeliveryDt
    if (expectedDeliveryDt) {
      if (typeof expectedDeliveryDt === 'number') {
        expectedDeliveryDt = new Date(expectedDeliveryDt);
      }
      Store.dispatch(
        updateMultipleKeysInDocument({
          draftId,
          keysToUpdate: {
            [DOCUMENT_KEYS.FULFILLMENT_DATE]:
              DateFormatService.getDateStrFromDate(
                expectedDeliveryDt,
                BOOKS_DATE_FORMAT['DD-MM-YYYY']
              )
          }
        })
      );
    }
    return currentRow;
  };

  const handleCascadingDiscountUpdate = () => {
    const docLineItem = new DocLineItemCalculator(currentRow, {
      additionalSettings
    });
    const matcher = String(dataToUpdate).match(REGEX.PERCENT_NUMER);
    let isPercent = false;
    if (!matcher) {
      dataToUpdate = 0;
    } else if ('%' === matcher[4]) {
      const percentDiscount = Number(dataToUpdate.replace('%', ''));
      if (percentDiscount > 100) {
        dataToUpdate = 100;
      } else {
        dataToUpdate = percentDiscount;
      }
      isPercent = true;
    } else if (
      dataToUpdate < 0 ||
      (dataToUpdate as number) > (docLineItem?.lineItem?.subTotal as number)
    ) {
      currentRow?.invalidFields?.push(columnKey);
    }
    const discountKeyToUpdate = columnKey;
    const discountDetailsKeyToUpdate = columnKey + `_details`;
    if (currentRow) {
      currentRow[discountKeyToUpdate] = isPercent
        ? dataToUpdate + '%'
        : dataToUpdate;
      currentRow[discountDetailsKeyToUpdate] = {
        ...currentRow[discountDetailsKeyToUpdate],
        discount: dataToUpdate,
        isPercent: isPercent,
        discountIndex: currentRow[discountDetailsKeyToUpdate]?.['discountIndex']
      };
    }
    return currentRow;
  };

  const handleProductCFUpdates = () => {
    const CFColumnConfig = columnConfig?.find(
      (cf: any) => cf?.id === columnKey && cf.isCustomField
    );
    const filteredCF: any = productCustomFields?.find(
      (field: any) => field.id === columnKey
    );
    let cfValue = '';
    if (!Utility.isEmpty(filteredCF)) {
      if (
        filteredCF.fieldType.toLowerCase() ===
        CUSTOM_FIELD_TYPE.USER.toLowerCase()
      ) {
        const tempCFOption = filteredCF?.attributes?.find(
          (attr: any) => attr.code === dataToUpdate.code
        );
        if (tempCFOption) {
          cfValue = tempCFOption?.code;
        }
      } else if (
        filteredCF.fieldType.toLowerCase() ===
        CUSTOM_FIELD_TYPE.DROPDOWN.toLowerCase()
      ) {
        cfValue = dataToUpdate?.value === 'None' ? null : dataToUpdate?.value;
        //TODO
        // cfUpdatedTimeMap.current = {
        //   ...cfUpdatedTimeMap.current,
        //   [filteredCF.id]: new Date().getTime()
        // };
      } else {
        cfValue =
          filteredCF.fieldType.toLowerCase() === INPUT_TYPE.DATE.toLowerCase()
            ? DateFormatService.getDateStrFromDate(
                new Date(dataToUpdate),
                BOOKS_DATE_FORMAT['MM/DD/YYYY']
              )
            : dataToUpdate;
      }
      if (CFColumnConfig && filteredCF) {
        const cfToUpdate = {
          id: filteredCF.id,
          shortName: filteredCF.shortName,
          module: filteredCF.module,
          code: filteredCF.code,
          label: filteredCF.label,
          value: cfValue
        };
        let existingCFs = currentRow?.customField
          ? [...currentRow.customField]
          : [];
        const existingCFIndex = existingCFs.findIndex(
          (cf: any) => cf?.id === cfToUpdate?.id
        );
        if (existingCFIndex === -1) {
          existingCFs = [...existingCFs, cfToUpdate];
        } else {
          existingCFs[existingCFIndex] = cfToUpdate;
        }
        if (currentRow) {
          currentRow.customField = existingCFs;
        }
      }
      if (currentRow) {
        if (
          filteredCF?.fieldType?.toLowerCase() === INPUT_TYPE.DATE.toLowerCase()
        ) {
          currentRow[columnKey] = new Date(dataToUpdate);
        } else if (Utility.isObject(dataToUpdate)) {
          if (
            filteredCF?.fieldType?.toLowerCase() ===
            INPUT_TYPE.DROPDOWN.toLowerCase()
          ) {
            currentRow[columnKey] =
              dataToUpdate?.value === 'None' ? null : dataToUpdate?.value;
            const { rowData } = updateRowDataWithParentCFValues(
              dataToUpdate.value,
              { ...currentRow },
              filteredCF,
              productCustomFields
            );
            currentRow = rowData;
          } else {
            currentRow[columnKey] = dataToUpdate.value;
          }
        } else {
          currentRow[columnKey] = dataToUpdate;
        }
      }
      currentRow = checkIfLineLevelCustomFieldIsValid(
        currentRow,
        productCustomFields
      );
    } else {
      if (currentRow) {
        currentRow[columnKey] = Utility.isObject(dataToUpdate)
          ? dataToUpdate.value
          : dataToUpdate;

        currentRow = checkIfLineLevelCustomFieldIsValid(
          currentRow,
          productCustomFields
        );
      }
    }
    return currentRow;
  };

  const handleDefaultUpdates = () => {
    if (columnKey?.toString()?.startsWith(CASCADING_DISCOUNT_PREFIX)) {
      return handleCascadingDiscountUpdate();
    } else {
      return handleProductCFUpdates();
    }
  };

  switch (columnKey) {
    case DOC_LINE_ITEM_KEYS.PRODUCT:
      const row = handleProductUpdate();
      copyOfItems[rowIndex] = row;
      break;
    case DOC_LINE_ITEM_KEYS.PRODUCT_QUANTITY:
      copyOfItems[rowIndex] = handleProductQtyUpdate();
      break;
    case DOC_LINE_ITEM_KEYS.UNIT_PRICE:
      copyOfItems[rowIndex] = handleUnitPriceUpdate();
      break;
    case DOC_LINE_ITEM_KEYS.DISCOUNT:
      copyOfItems[rowIndex] = handleDiscountUpdate();
      break;
    case DOC_LINE_ITEM_KEYS.TAX:
      copyOfItems[rowIndex] = handleTaxUpdate();
      break;
    case DOC_LINE_ITEM_KEYS.TAX_AMOUNT:
      copyOfItems[rowIndex] = handleTaxAmountUpdate();
      break;
    case DOC_LINE_ITEM_KEYS.UOM:
      copyOfItems[rowIndex] = handleUOMUpdate();
      break;
    case DOC_LINE_ITEM_KEYS.AMORTIZATION_TEMPLATE:
      copyOfItems[rowIndex] = handleAmortizationTemplateUpdate();
      break;
    case DOC_LINE_ITEM_KEYS.AMORTIZATION_START_DATE:
      copyOfItems[rowIndex] = handleAmortizationStartDateUpdate();
      break;
    case DOC_LINE_ITEM_KEYS.AMORTIZATION_END_DATE:
      copyOfItems[rowIndex] = handleAmortizationEndDateUpdate();
      break;
    case DOC_LINE_ITEM_KEYS.REV_REC_START_DATE:
      copyOfItems[rowIndex] = handleRevRecStartDate();
      break;
    case DOC_LINE_ITEM_KEYS.REV_REC_END_DATE:
      copyOfItems[rowIndex] = handleRevRecEndDate();
      break;
    case DOC_LINE_ITEM_KEYS.EXPECTED_DELIVERY_DT:
      copyOfItems[rowIndex] = handleExpectedDeliveryDate();
      break;
    default:
      copyOfItems[rowIndex] = handleDefaultUpdates();
      break;
  }

  return copyOfItems;
};
const validateProduct = (product: any) => {
  const { offeringType, type, hsnOrSacCode, uqcIndia } = product;
  let showPopup = false;
  let message = '';
  if (
    offeringType === PRODUCT_OFFERING_TYPE.GOODS &&
    (type === PRODUCT_TYPE.NON_TRACKED || type === PRODUCT_TYPE.TRACKED) &&
    (Utility.isEmptyObject(hsnOrSacCode) || Utility.isEmptyObject(uqcIndia))
  ) {
    showPopup = true;
    message =
      'Product HSN/SAC code and UQC required. Please update or select another product.';
  }
  if (
    offeringType === PRODUCT_OFFERING_TYPE.SERVICES &&
    type === PRODUCT_TYPE.NON_TRACKED &&
    Utility.isEmptyObject(hsnOrSacCode)
  ) {
    showPopup = true;
    message =
      'Product HSN/SAC code required. Please update or select another product.';
  }
  if (showPopup && getTenantTaxSystem() === TAX_SYSTEM.INDIA_GST) {
    showAlert('Missing details', message, [
      {
        title: 'Cancel',
        className: 'bg-gray1 text-black-light',
        onClick: () => {
          return;
        }
      },
      {
        title: 'Update Product',
        className: 'bg-button text-white ml-2',
        onClick: () => {
          // TODO - setProductToEdit(product);
          return;
        }
      }
    ]);
    return false;
  }
  return true;
};
const isQuantityMoreThatPendingQuantity = (
  quantity: number,
  lineItem: any,
  documentType: DOC_TYPE
) => {
  let pendingQuantity = +lineItem.pendingQuantity;
  if (
    documentType === DOC_TYPE.SALES_ORDER ||
    documentType === DOC_TYPE.INVOICE
  ) {
    pendingQuantity = +lineItem.pendingQtyToConvert;
  }
  if (!Utility.isEmpty(lineItem.documentUOMSchemaDefinition)) {
    pendingQuantity = getUomQuantity(
      pendingQuantity,
      lineItem.documentUOMSchemaDefinition
    );
  }

  return quantity > pendingQuantity;
};
const updateUomSchemaForLineItem = (currentRow: any, uomData: any) => {
  let unitPrice = currentRow.unitPrice ? currentRow.unitPrice : 0;
  if (unitPrice) {
    if (currentRow.documentUOMSchemaDefinition) {
      const documentUOMSchemaDefinition =
        currentRow.documentUOMSchemaDefinition;
      unitPrice =
        (unitPrice * documentUOMSchemaDefinition.sinkConversionFactor) /
          documentUOMSchemaDefinition.sourceConversionFactor || 0;
    }
    if (!uomData.isBaseUom) {
      unitPrice =
        (unitPrice * uomData.sourceConversionFactor) /
        uomData.sinkConversionFactor;
    }
  }
  if (unitPrice) {
    currentRow.unitPrice = Utility.roundOff(unitPrice);
    currentRow.uomUnitPrice = Utility.roundOff(unitPrice);
  }
  if (uomData.isBaseUom) {
    currentRow.documentUOMSchemaDefinition = null;
    currentRow.documentUom = uomData.id;
  } else {
    currentRow.documentUOMSchemaDefinition = {
      uid: uomData.uid,
      sourceUOM: uomData.sourceUOM,
      sourceConversionFactor: uomData.sourceConversionFactor,
      sinkUOM: uomData.sinkUOM,
      sinkConversionFactor: uomData.sinkConversionFactor,
      id: uomData.sinkUOM,
      name: uomData.name,
      isBaseUom: false,
      schemaId: currentRow.product.uomSchemaDto.id
    };
    currentRow.documentUom = uomData.sinkUOM;
  }
  return currentRow;
};
const addProductCustomFieldsToLineItem = (
  lineItem: any,
  product: any,
  productCustomFields: any[]
) => {
  let cfs: any[] = [];
  if (
    !Utility.isEmpty(productCustomFields) &&
    !Utility.isEmpty(product.customField)
  ) {
    // Set default values of CFs when new line is added
    product.customField.forEach((productCF: any) => {
      const filteredCF = productCustomFields?.find(
        (field: any) =>
          field.id === productCF.id && field.status === STATUS_TYPE.ACTIVE
      );
      if (filteredCF) {
        let cfToUpdate = {
          id: filteredCF.id,
          shortName: filteredCF.shortName,
          module: filteredCF.module,
          code: filteredCF.code,
          label: filteredCF.label,
          value: ''
        };
        let valueOfCF = '';
        const activeFilteredCFAttributes = filteredCF?.attributes?.filter(
          (attr: any) => attr?.status?.toUpperCase() === STATUS_TYPE.ACTIVE
        );
        if (
          typeof productCF.value !== 'undefined' &&
          productCF.value !== null &&
          productCF.value !== ''
        ) {
          if (
            filteredCF.fieldType.toLowerCase() === INPUT_TYPE.DATE.toLowerCase()
          ) {
            lineItem[productCF.id] = DateFormatService.getDateFromStr(
              productCF.value,
              BOOKS_DATE_FORMAT['MM/DD/YYYY']
            );
          } else if (filteredCF.fieldType.toLowerCase() === 'user') {
            const tempCF = activeFilteredCFAttributes?.find(
              (attr: any) => attr.code === productCF.value
            );
            if (tempCF) {
              lineItem[productCF.id] = tempCF;
            }
          } else if (
            filteredCF.fieldType.toLowerCase() ===
            INPUT_TYPE.DROPDOWN.toLowerCase()
          ) {
            const tempCF = activeFilteredCFAttributes?.find(
              (attr: any) => attr.value === productCF.value
            );
            if (tempCF) {
              lineItem[productCF.id] = tempCF;
            }
          } else {
            lineItem[productCF.id] = productCF.value;
          }
          valueOfCF = productCF.value;
        } else {
          lineItem[productCF.id] = '';
        }
        cfToUpdate.value = valueOfCF;
        cfs.push(cfToUpdate);
      }
    });
  }
  return { ...lineItem, customField: cfs };
};
export const getExpectedDeliveryDateFromItems = (
  date: any,
  items: any[],
  documentDate: any
) => {
  if (Utility.isEmpty(items)) {
    return;
  }
  let dateToUpdate: Date = new Date(date);
  let calculatedShipByDate: Date;
  let maxDateProductObject = items?.reduce(function (a: any, b: any) {
    let firstDate = a?.expectedDeliveryDt
      ? new Date(a?.expectedDeliveryDt)
      : new Date();
    let secondDate = b?.expectedDeliveryDt
      ? new Date(b?.expectedDeliveryDt)
      : new Date();
    return firstDate > secondDate ? a : b;
  });
  if (
    typeof maxDateProductObject?.expectedDeliveryDt !== 'undefined' &&
    maxDateProductObject?.expectedDeliveryDt !== null
  ) {
    calculatedShipByDate =
      ((maxDateProductObject?.expectedDeliveryDt || documentDate) > dateToUpdate
        ? maxDateProductObject?.expectedDeliveryDt
        : dateToUpdate) || documentDate;
  } else {
    calculatedShipByDate = dateToUpdate;
  }
  return calculatedShipByDate;
};
export const getDiscountRelatedKeys = (discount: string) => {
  let updatedItemKeys: any = {};
  let discountInNum = 0;
  if (discount) {
    if (discount.toString().indexOf('%') > -1) {
      updatedItemKeys.discountInPercent = true;
      discountInNum = Number(discount.replace('%', ''));
    } else {
      discountInNum = Number(discount);
      updatedItemKeys.discountInPercent = false;
    }
    updatedItemKeys.discount = Utility.roundOff(
      discountInNum,
      CURRENCY_PRECISION
    );
    return updatedItemKeys;
  } else {
    updatedItemKeys.discount = discountInNum;
  }
  return updatedItemKeys;
};
/**
 * Calculate taxes and amount of single line item on relevant column updates like
 * - Product change
 * - UOM change
 * - quantity change
 * - discount change
 * - price change
 */
export const calculateLineItemTaxesAndAmount = (
  updatedRow: any,
  documentType: DOC_TYPE,
  contact?: any,
  gstType?: GST_TYPE,
  applyRcmCheck?: boolean
) => {
  if (!Utility.isEmpty(updatedRow?.product)) {
    let row: any = { ...updatedRow };
    let item = {
      ...row,
      taxCode: row?.tax?.code,
      taxName: row?.tax?.name,
      taxSystem: getTenantTaxSystem(),
      gstType: Utility.getValue(row.gstType, gstType),
      unitPriceGstInclusive: row.unitPriceGstInclusive,
      exciseAmount: 0,
      cessRule: row.product.cessRule,
      isRcmApplied: rcmAppliedIndiaWithCheckRCMApply(
        documentType,
        contact?.gstTreatment,
        row.product,
        applyRcmCheck
      )
    };

    // Handle tax column editable key depending on whether RCM is applied
    if (
      getTenantTaxSystem() === TAX_SYSTEM.INDIA_GST &&
      RCM_DOCUMENTS.includes(documentType)
    ) {
      item.nonEditableColumns = item.nonEditableColumns
        ? [...item.nonEditableColumns].filter(
            (field) => field !== DOC_LINE_ITEM_KEYS.TAX
          )
        : [];
      if (item?.isRcmApplied) {
        item?.nonEditableColumns?.push(DOC_LINE_ITEM_KEYS.TAX);
      }
    }

    item = {
      ...item,
      ...getDiscountRelatedKeys(
        row['discountInPercent'] ? row['discount'] + '%' : row['discount']
      )
    };

    const itemCalculator = new DocLineItemCalculator(
      item,
      AuthService.currentTenantInfo
    );

    if (item.userSetTaxes) {
      // Handle user set taxAmount
      itemCalculator.setInitialValues();

      if (!COUNTRIES_WITH_NO_MANUAL_TAX_UPDATE.includes(getTenantTaxSystem())) {
        itemCalculator.lineItem.taxAmount = item.taxAmount || 0;
      } else {
        // For manual GST value updates for India this will execute
        itemCalculator.lineItem.taxAmount = itemCalculator.calculateTaxAmount();
      }

      itemCalculator.updateCalculatedValues();
    } else {
      // For NO user set taxAmount
      itemCalculator.updateValuesWithTaxAmount();
    }

    // TODO: Need to check with Onkar about skipping tax calculation for UK - Tushar
    // if (skipCalculateTaxLocal.current) {
    //   taxAmount = item.taxAmount;
    // } else {
    //   taxAmount = ItemTaxCalculator.calculateTaxAmount();
    //   // TODO: HANDLE MANUAL TAX AMOUNT UPDATE HERE
    // }

    // TODO: Manual update for GST India - Tushar
    // if (
    //   getTenantTaxSystem() === TAX_SYSTEM.INDIA_GST &&
    //   (props.documentMode !== DOCUMENT_MODE.NEW ||
    //     props.booksDocument.isPartialInvoice ||
    //     !Utility.isEmpty(contact))
    // ) {
    //   setGSTValueManual(rowIndex, row, item);
    // }
    try {
      if (
        getTenantTaxSystem() === TAX_SYSTEM.INDIA_GST &&
        documentType === DOC_TYPE.BILL &&
        Utility.isNotEmpty(itemCalculator.lineItem?.landedCostDetails)
      ) {
        itemCalculator.lineItem = checkAndUpdateLandedCost(
          itemCalculator.lineItem
        );
      }
    } catch (error) {
      console.error('Error While Calculating Landed Cost:', error);
    }

    return { ...itemCalculator.lineItem };
  } else {
    return updatedRow;
  }
};
/**
 *
 * @param {Object} data - an object containing required params
 * @param {string} data.draftId - document id for which needs to update the Taxes
 * @param {string} data.indexToUpdate - current item Index
 * @description this will take all items and build payload for US Tax Calculation API & then update the items in state
 */
export const calculateTaxesForUS = async ({
  draftId,
  indexToUpdate,
  updatedItems
}: any) => {
  const document: any = getDocumentByIDFromStore(draftId) ?? {};
  const {
    contact,
    documentDate,
    shipTo,
    documentType,
    shipFrom,
    items,
    gstType
  } = document?.populateFormData;
  const tenantInfo = AuthService.currentTenantInfo;
  let copyOfItems = cloneDeep(
    Utility.isNotEmpty(updatedItems) ? updatedItems : items
  );

  const geAPIPayload = () => {
    let docDate = DateFormatService.getDateFromStr(
      documentDate,
      BOOKS_DATE_FORMAT['DD-MM-YYYY']
    );
    const payload: any = {
      customerCode: contact?.avalaraCustomerCode,
      companyCode: tenantInfo.avalaraCode,
      shipTo: shipTo,
      shipFrom: shipFrom,
      lines: [],
      docDate: DateFormatService.getDateStrFromDate(
        docDate,
        BOOKS_DATE_FORMAT['YYYY-MM-DD']
      )
    };
    if (contact?.taxExempted === true) {
      payload['exemptionNo'] = contact.code;
    }
    if (
      !tenantInfo?.complianceEnabled &&
      (Utility.isEmptyObject(payload?.shipTo?.postalCode) ||
        Utility.isEmptyObject(payload?.shipTo?.address1) ||
        Utility.isEmptyObject(payload?.shipTo?.state))
    ) {
      payload.shipTo = null;
    }
    copyOfItems = copyOfItems?.map((item: any) => {
      const docLineItem = new DocLineItemCalculator(item, tenantInfo);
      docLineItem.setInitialValues();
      return docLineItem.lineItem;
    });

    copyOfItems.forEach((item: any, index: number) => {
      const docLineItem = new DocLineItemCalculator(item, tenantInfo);

      docLineItem.lineItem = {
        ...item,
        ...getDiscountRelatedKeys(
          item.discountInPercent ? item.discount + '%' : item.discount
        )
      };
      docLineItem.setInitialValues();
      let taxAmount: any = docLineItem.calculateTaxAmount();
      if (indexToUpdate === undefined || indexToUpdate === index) {
        taxAmount = item?.product?.taxable ? null : 0;
      }

      if (item.taxAmount !== undefined && item.taxAmount !== null) {
        if (item.taxAmount !== taxAmount) {
          if (item.taxAmount > 0 && !item.product.taxable) {
            taxAmount = item.taxAmount;
          }
        }
      }
      payload.lines.push({
        amount: docLineItem.lineItem.totalWithDiscount,
        description: docLineItem.lineItem.productDescription,
        quantity: docLineItem.lineItem.productQuantity,
        taxAmount: taxAmount,
        taxCode: docLineItem.lineItem.product?.categoryCode
      });
      docLineItem.lineItem.taxAmount = null as any;
    });

    return payload;
  };

  const isTaxVisible = () => {
    return (
      tenantInfo.complianceEnabled &&
      !(
        documentType === DOC_TYPE.BILL ||
        documentType === DOC_TYPE.ORDER ||
        documentType === DOC_TYPE.JOB_WORK_OUT_ORDER
      )
    );
  };

  if (
    Utility.isNotEmpty(copyOfItems) &&
    getTenantTaxSystem() === TAX_SYSTEM.US
  ) {
    if (isTaxVisible()) {
      try {
        const taxes = await TaxService.calculateUsTax(geAPIPayload());
        taxes?.lines?.forEach((taxLine: any, index: number) => {
          const docLineItem = new DocLineItemCalculator(
            copyOfItems[index],
            tenantInfo
          );
          docLineItem.lineItem = {
            ...copyOfItems[index],
            ...getDiscountRelatedKeys(
              copyOfItems[index].discountInPercent
                ? copyOfItems[index].discount + '%'
                : copyOfItems[index].discount
            )
          };
          docLineItem.setInitialValues();
          docLineItem.lineItem.taxAmount = null as any;
          docLineItem.lineItem.taxAmount = taxLine.tax;
          docLineItem.updateCalculatedValues();
          copyOfItems[index] = docLineItem.lineItem;
        });
      } catch (error: any) {
        console.error('Error calculating tax: ', error?.data);
        if (
          calculateUSTaxAPIErrorMessages.some((msg: string) =>
            error.data?.errorMessage?.includes(msg)
          )
        ) {
          const handleAlert = () => {
            commonCustomEvent.dispatch(COMMON_EVENTS.DOC_POPUP_SHOW, {
              contact: cloneDeep(contact),
              activeContactTab: CONTACT_FORM_TAB.ADDRESS_INFO,
              type: DOC_POPUP_TYPE.SHOW_ADD_CONTACT_POPUP
            });
          };

          showZipCodeAlert(error.data?.errorMessage, handleAlert, () => {});
        }
        copyOfItems = copyOfItems.map((item: any) =>
          calculateLineItemTaxesAndAmount(item, documentType, contact, gstType)
        );
      }
    } else {
      copyOfItems = copyOfItems.map((item: any) =>
        calculateLineItemTaxesAndAmount(item, documentType, contact, gstType)
      );
    }
  }

  Store.dispatch(
    updateMultipleKeysInDocument({
      draftId,
      keysToUpdate: { [DOCUMENT_KEYS.ITEMS]: copyOfItems }
    })
  );
};
/** @description Putting additional fields in document in edit case */
export const getNonEditableFieldsInItem = ({
  item,
  reservedStock,
  documentType,
  draftType
}: any): string[] => {
  const nonEditableColumns: string[] = item?.nonEditableColumns ?? [];
  const taxPreference = item?.product?.taxPreference;
  if (
    (typeof taxPreference !== 'undefined' &&
      taxPreference !== null &&
      !taxPreference) ||
    !item.isTaxable
  ) {
    if (!nonEditableColumns.includes(DOC_LINE_ITEM_KEYS.TAX)) {
      nonEditableColumns.push(DOC_LINE_ITEM_KEYS.TAX);
    }
  }
  if (!!item?.[DOC_LINE_ITEM_KEYS.IS_RCM_APPLIED]) {
    if (!nonEditableColumns.includes(DOC_LINE_ITEM_KEYS.TAX)) {
      nonEditableColumns.push(DOC_LINE_ITEM_KEYS.TAX);
    }
  }
  if (!nonEditableColumns.includes(DOC_LINE_ITEM_KEYS.ALLOCATION_TYPE)) {
    nonEditableColumns.push(DOC_LINE_ITEM_KEYS.ALLOCATION_TYPE);
  }
  if (
    item?.[DOC_LINE_ITEM_KEYS.LINKED_ORDER_ITEM] ||
    item?.[DOC_LINE_ITEM_KEYS.LINKED_QUOTE_ITEM]
  ) {
    if (!nonEditableColumns.includes(DOC_LINE_ITEM_KEYS.PRODUCT)) {
      nonEditableColumns.push(DOC_LINE_ITEM_KEYS.PRODUCT);
    }
  }
  if (reservedStock && draftType !== DraftTypes.DRAFT) {
    nonEditableColumns.push(
      ...[DOC_LINE_ITEM_KEYS.PRODUCT_QUANTITY, DOC_LINE_ITEM_KEYS.UOM]
    );
  }
  if (documentType === DOC_TYPE.ORDER) {
    nonEditableColumns.push(...BILL_QTY_COLUMNS_IN_DOC);
  }
  if (
    item?.[DOC_LINE_ITEM_KEYS.TYPE] === PRODUCT_TYPE.NON_TRACKED &&
    item?.[DOC_LINE_ITEM_KEYS.PRODUCT]?.stockUom === UOM_NA_ID
  ) {
    if (!nonEditableColumns.includes(DOC_LINE_ITEM_KEYS.PRODUCT_QUANTITY)) {
      nonEditableColumns.push(DOC_LINE_ITEM_KEYS.PRODUCT_QUANTITY);
    }
  }
  return nonEditableColumns;
};
export const getCascadingDiscountFields = ({
  items,
  additionalSettings
}: any) => {
  const copyOfItems = cloneDeep(items);
  const discountSettings = additionalSettings?.CASCADING_DISCOUNTS;
  if (!!discountSettings?.enable) {
    const orderedDiscounts = Utility.isNotEmpty(
      discountSettings?.discountDetails
    )
      ? [...discountSettings?.discountDetails]
      : [];
    orderedDiscounts.sort(
      (discount1: any, discount2: any) => discount1.level - discount2.level
    );
    copyOfItems.forEach((item: any, index: number) => {
      if (Utility.isNotEmpty(item?.additionalCharges?.globalDiscounts)) {
        const existingLineDiscounts =
          item.additionalCharges?.globalDiscounts?.filter(
            (disc: any) => disc.isItemDiscount
          ) ?? [];
        existingLineDiscounts?.forEach((lineDiscount: any, index: number) => {
          if (Utility.isNotEmpty(lineDiscount)) {
            const isPercent = !!lineDiscount?.isPercent;
            lineDiscount = {
              ...lineDiscount,
              isPercent,
              chargeValue: isPercent ? 0 : lineDiscount.amount,
              percentageValue: isPercent ? lineDiscount?.percent : 0
            };
            let discountValue = +lineDiscount.chargeValue || 0;
            if (isPercent) {
              discountValue = +lineDiscount.percentageValue;
            }

            const discountKeyToUpdate =
              CASCADING_DISCOUNT_PREFIX + `${lineDiscount.id}`;
            const discountDetailsKeyToUpdate =
              CASCADING_DISCOUNT_PREFIX + `${lineDiscount.id}_details`;

            item[discountKeyToUpdate] = isPercent
              ? discountValue + '%'
              : discountValue;

            item[discountDetailsKeyToUpdate] = {
              id: lineDiscount.id,
              discount: discountValue,
              isPercent: lineDiscount.isPercent,
              accountCode: lineDiscount.accountCode,
              name: lineDiscount.name,
              discountIndex: index,
              unitDiscount: 0
            };
          }
        });
      }
    });
  }
  return copyOfItems;
};
export const getProductCustomFields = ({ items, productCustomFields }: any) => {
  const copyOfItems = cloneDeep(items);
  const customFields = cloneDeep(
    Utility.isNotEmpty(productCustomFields) ? productCustomFields : []
  );
  if (Utility.isNotEmpty(customFields)) {
    copyOfItems.forEach((item: any, index: number) => {
      item?.customField?.forEach((field: any) => {
        const currentCF = productCustomFields?.find(
          (cf: any) => cf.id === field.id
        );
        if (Utility.isNotEmpty(currentCF)) {
          if (Utility.isNotEmpty(field?.value)) {
            if (
              currentCF?.fieldType?.toLowerCase() ===
              INPUT_TYPE.DATE.toLowerCase()
            ) {
              item[field.id] = DateFormatService.getDateFromStr(
                field.value,
                BOOKS_DATE_FORMAT['MM/DD/YYYY']
              );
            } else if (
              currentCF.fieldType?.toLowerCase === CF_INPUT_TYPE.USER
            ) {
              const attribute = currentCF?.attributes?.find(
                (attr: any) => attr.code === field.value
              );
              item[field.id] = attribute?.value ?? '';
            } else {
              item[field.id] = field.value;
            }
          } else {
            item[field.id] = '';
          }
        }
      });
    });
  }
  return copyOfItems;
};
export const getUOMFields = ({
  items,
  UOMData,
  isPartialDoc,
  documentMode
}: any) => {
  const copyOfItems = cloneDeep(items);
  copyOfItems.forEach((item: any) => {
    let unitPrice = item[DOC_LINE_ITEM_KEYS.UNIT_PRICE] || 0;
    const product = item[DOC_LINE_ITEM_KEYS.PRODUCT];
    if (
      documentMode === DOCUMENT_MODE.NEW &&
      isPartialDoc &&
      product?.stockUom === UOM_NA_ID
    ) {
      unitPrice = item[DOC_LINE_ITEM_KEYS.PENDING_AMOUNT];
    }
    item[DOC_LINE_ITEM_KEYS.UNIT_PRICE] = unitPrice;
    if (
      Utility.isNotEmpty(
        item[DOC_LINE_ITEM_KEYS.DOCUMENT_UOM_SCHEMA_DEFINITION]
      )
    ) {
      item[DOC_LINE_ITEM_KEYS.UOM] =
        item[DOC_LINE_ITEM_KEYS.DOCUMENT_UOM_SCHEMA_DEFINITION];

      item[DOC_LINE_ITEM_KEYS.UNIT_PRICE] =
        item[DOC_LINE_ITEM_KEYS.UOM_UNIT_PRICE] ||
        item[DOC_LINE_ITEM_KEYS.UNIT_PRICE];

      item[DOC_LINE_ITEM_KEYS.PRODUCT_QUANTITY] =
        item[DOC_LINE_ITEM_KEYS.UOM_QUANTITY] ||
        item[DOC_LINE_ITEM_KEYS.PRODUCT_QUANTITY];
    } else {
      let filtered = UOMData.filter((uom: any) => uom.id === item?.documentUom);
      if (!Utility.isEmpty(filtered)) {
        item[DOC_LINE_ITEM_KEYS.UOM] = { ...filtered[0], isBaseUom: true };
      }
    }
  });
  return copyOfItems;
};
/**
 * @description This method will calculate and return additional keys for document
 * @param param an object containing required params
 * @param param.draftId - document id for which needs to update the Taxes
 * @param param.draftType - draftType of document
 * @returns {Object} partial document containing keys to update only
 */
export const getAdditionalFieldsForDoc = ({
  draftId,
  draftType,
  documentMode,
  productCustomFields,
  UOMData,
  amortizationTemplates
}: any): any => {
  const document: any = getDocumentByIDFromStore(draftId) ?? {};
  const {
    items,
    documentType,
    documentDate,
    contact,
    reservedStock,
    isPartialInvoice,
    isPartialBill,
    shipFrom,
    shipTo,
    isPartialSalesOrder,
    applyRcmCheck
  } = document?.populateFormData;
  const { additionalSettings } =
    Store.getState().authInfo.currentTenantInfo.data;
  const taxSystem = getTenantTaxSystem();
  let copyOfItems = cloneDeep(items);

  const updatedDocument: any = {};
  // gstType to be evaluated if contact is selected
  let gstType = GST_TYPE.DEFAULT;
  if (taxSystem === TAX_SYSTEM.INDIA_GST) {
    gstType = Utility.getIndiaDefaultTaxType(shipFrom?.state, shipTo?.state);
    if (Utility.isNotEmpty(contact)) {
      gstType = updateGstType(document?.populateFormData) as GST_TYPE;
    }
  }
  // **** Put Line level keys here ***** //
  copyOfItems?.forEach((item: any) => {
    item[DOC_LINE_ITEM_KEYS.GST_TYPE] = gstType;
    let isTaxable = true;
    if (taxSystem === TAX_SYSTEM.MALAYSIA) {
      isTaxable = !!item?.product?.exemptedMalaysia;
    }
    item[DOC_LINE_ITEM_KEYS.IS_TAXABLE] = isTaxable;
    if (taxSystem === TAX_SYSTEM.INDIA_GST) {
      item[DOC_LINE_ITEM_KEYS.IS_RCM_APPLIED] =
        rcmAppliedIndiaWithCheckRCMApply(
          documentType,
          contact?.gstTreatment,
          item.product,
          applyRcmCheck
        );
    }

    item[DOC_LINE_ITEM_KEYS.NON_EDITABLE_COLUMNS] = getNonEditableFieldsInItem({
      item,
      reservedStock,
      documentType,
      draftType
    });
  });

  // **** Put Doc level keys here **** //
  updatedDocument[DOCUMENT_KEYS.TAX_SYSTEM] = taxSystem;
  updatedDocument[DOCUMENT_KEYS.GST_TYPE] = gstType;
  updatedDocument[DOCUMENT_KEYS.ROUND_OFF_AMOUNT_IN_DOCUMENT_CURRENCY] =
    document?.populateFormData?.[
      DOCUMENT_KEYS.ROUND_OFF_AMOUNT_IN_DOCUMENT_CURRENCY
    ] ?? 0;
  updatedDocument[DOCUMENT_KEYS.CURRENCY] = Utility.isNotEmpty(
    document?.populateFormData?.[DOCUMENT_KEYS.CURRENCY]
  )
    ? document?.populateFormData?.[DOCUMENT_KEYS.CURRENCY]
    : AuthService.currentTenantInfo?.currency;
  updatedDocument[DOCUMENT_KEYS.CURRENCY_CODE] = Utility.isNotEmpty(
    document?.populateFormData?.[DOCUMENT_KEYS.CURRENCY]
  )
    ? document?.populateFormData?.[DOCUMENT_KEYS.CURRENCY]
    : AuthService.currentTenantInfo?.currency;

  // Handle imported invoice fulfillmentDate
  if (
    Utility.isNotEmpty(
      document?.populateFormData?.[DOCUMENT_KEYS.DOCUMENT_DATE]
    ) &&
    Utility.isEmpty(
      document?.populateFormData?.[DOCUMENT_KEYS.FULFILLMENT_DATE]
    )
  ) {
    updatedDocument[DOCUMENT_KEYS.FULFILLMENT_DATE] = Utility.isNotEmpty(
      document?.populateFormData?.[DOCUMENT_KEYS.FULFILLMENT_DATE]
    )
      ? document?.populateFormData?.[DOCUMENT_KEYS.FULFILLMENT_DATE]
      : document?.populateFormData?.[DOCUMENT_KEYS.DOCUMENT_DATE];
  }

  // **** Sort by line number and assign new line numbers **** //
  copyOfItems = copyOfItems
    ?.sort((item1: any, item2: any) => item1.lineNumber - item2.lineNumber)
    .map((item: any, index: number) => ({ ...item, lineNumber: index + 1 }));

  copyOfItems = getInitialAmortizationDataForEdit({
    templates: amortizationTemplates,
    documentDate,
    items: copyOfItems
  });

  copyOfItems = getCascadingDiscountFields({
    items: copyOfItems,
    additionalSettings
  });

  copyOfItems = getProductCustomFields({
    productCustomFields,
    items: copyOfItems
  });

  // support for ZEN-13467
  copyOfItems = copyOfItems?.map((item: any) => {
    if (Utility.isEmpty(item.invalidFields)) {
      item.invalidFields = [];
    }
    if (documentMode !== DOCUMENT_MODE.VIEW) {
      item = checkIfLineLevelCustomFieldIsValid(item, productCustomFields);
    }
    return item;
  });

  const isPartialDoc = isPartialInvoice || isPartialBill || isPartialSalesOrder;

  copyOfItems = getUOMFields({
    items: copyOfItems,
    UOMData,
    isPartialDoc,
    documentMode
  });

  copyOfItems = copyOfItems.map((item: any) => {
    // Get updated line item
    const docLineItem = calculateLineItemTaxesAndAmount(
      item,
      documentType,
      contact,
      gstType,
      applyRcmCheck
    );

    const expectedDeliveryDt =
      docLineItem[DOC_LINE_ITEM_KEYS.EXPECTED_DELIVERY_DT];
    if (
      (documentType === DOC_TYPE.BILL || documentType === DOC_TYPE.ORDER) &&
      typeof expectedDeliveryDt === 'string'
    ) {
      docLineItem[DOC_LINE_ITEM_KEYS.EXPECTED_DELIVERY_DT] =
        DateFormatService.getDateFromStr(
          expectedDeliveryDt,
          BOOKS_DATE_FORMAT['DD-MM-YYYY']
        ).getTime(); // Save expectedDeliveryDt as timestamp in store
    }

    return docLineItem;
  });
  // set GST Value Manually
  updatedDocument[DOCUMENT_KEYS.ITEMS] = copyOfItems;
  return updatedDocument;
};
export const convertReservedBaseUOMToSchema = (
  selectedProductInfo: any[],
  lineItem: any
): any[] => {
  const reservedData = selectedProductInfo?.[0];
  if (
    reservedData &&
    reservedData.advancedTrackingType !== TRACKING_TYPE.SERIAL
  ) {
    let availableQuantity = lineItem.availableQuantity;
    const documentUOMSchemaDefinition = lineItem.documentUOMSchemaDefinition;
    if (
      documentUOMSchemaDefinition &&
      availableQuantity &&
      !documentUOMSchemaDefinition.isBaseUom
    ) {
      selectedProductInfo.forEach((obj) => {
        if (obj.availableQuantity) {
          obj.availableQuantity = Utility.getUomQuantityDecimalPrecision(
            obj.availableQuantity,
            documentUOMSchemaDefinition,
            10
          );
        }
        if (obj.reservedQuantity) {
          obj.reservedQuantity = Utility.getUomQuantityDecimalPrecision(
            obj.reservedQuantity,
            documentUOMSchemaDefinition,
            10
          );
        }
        if (obj.advancedTrackingType === TRACKING_TYPE.BATCH) {
          obj.advancedTrackingMetaDtos.forEach((item: any) => {
            if (item.batchSize) {
              item.batchSize = Utility.getUomQuantityDecimalPrecision(
                item.batchSize,
                documentUOMSchemaDefinition,
                10
              );
            }
            if (item.reservedQuantity) {
              item.reservedQuantity = Utility.getUomQuantityDecimalPrecision(
                item.reservedQuantity,
                documentUOMSchemaDefinition,
                10
              );
            }
          });
        }
      });
    }
  }
  return selectedProductInfo;
};
export const buildProductDataToReserveStock = (
  items: any[],
  lineItem: any,
  selectedProductInfo: any[]
) => {
  const productId = lineItem.product.id;
  const exitingReservedQuantitiesDataInLines = items
    ?.filter(
      (item: any) =>
        item.lineNumber !== lineItem.lineNumber &&
        item.product?.id === productId &&
        !Utility.isEmpty(item.reservedQuantitiesData)
    )
    .map((data: any) =>
      rebuildAdvancedTrackingMetaDtosAndUOMInfo(
        data.reservedQuantitiesData,
        data.documentUOMSchemaDefinition
      )
    )
    .reduce((acc: any[], curVal: any[]) => {
      return acc.concat(curVal);
    }, []);

  if (selectedProductInfo && exitingReservedQuantitiesDataInLines) {
    selectedProductInfo.forEach((obj) => {
      const warehouseObj = exitingReservedQuantitiesDataInLines.find(
        (o: any) => o.warehouseCode === obj.warehouseCode
      );
      if (warehouseObj) {
        const availableQty =
          obj.reservedQuantity + Number(warehouseObj.reservedQuantity);
        obj.reservedQuantity = availableQty < 0 ? 0 : Number(availableQty);
        if (
          obj.advancedTrackingType !== TRACKING_TYPE.NONE &&
          obj.advancedTrackingMetaDtos &&
          obj.advancedTrackingMetaDtos.length > 0 &&
          warehouseObj.advancedTrackingMetaDtos
        ) {
          obj.advancedTrackingMetaDtos.forEach((e: any) => {
            const batchObj = warehouseObj.advancedTrackingMetaDtos.find(
              (o: any) => o.serialBatchNumber === e.serialBatchNumber
            );
            if (batchObj) {
              const availableBatchQty =
                e.reservedQuantity + Number(batchObj.reservedQuantity);
              e.reservedQuantity =
                availableBatchQty < 0 ? 0 : Number(availableBatchQty);
            }
          });
        }
      }
    });
  }

  selectedProductInfo = convertReservedBaseUOMToSchema(
    selectedProductInfo,
    lineItem
  );
  return {
    selectedProductInfo: selectedProductInfo,
    lineItem: lineItem
  };
};
export const fetchProductReservedInfo = async (
  { rowData }: any,
  items: any[]
) => {
  const product = rowData?.product;
  const productId = product?.productId;
  if (!Utility.isEmpty(productId)) {
    try {
      const reserveInfo: any[] = await ProductService.getProductReservedInfo([
        productId
      ]);
      if (reserveInfo?.length) {
        const selectedProductInfo = reserveInfo?.filter(
          (obj) => obj.productCode === productId
        );

        if (selectedProductInfo?.length) {
          const data = buildProductDataToReserveStock(
            items,
            rowData,
            selectedProductInfo
          );
          return Promise.resolve(data);
        }
      } else {
        showAlert(
          'No quantity available!',
          'Selected product has no available quantity to reserve stock.'
        );
        return Promise.reject({
          errorMessage:
            'Selected product has no available quantity to reserve stock.'
        });
      }
    } catch (error) {
      console.error('Error while fetching product reserved info: ', error);
      return Promise.reject(error);
    }
  }
};
export const checkAndUpdateLandedCost = (row: DocumentItem): DocumentItem => {
  const { landedCostDetails } = row;
  const category =
    !Utility.isEmpty(landedCostDetails) &&
    JSON.parse(landedCostDetails?.landedCostCategory);
  if (category?.value === 'CUSTOM_DUTY') {
    let totalSum = row?.landedCostDetails?.productDetails.reduce(function (
      prev: any,
      cur: any
    ) {
      return prev + cur['customDutyAndOtherCharges'];
    },
    0);
    row[DOC_LINE_ITEM_KEYS.TOTAL_AMOUNT] = Number(totalSum);
    row[DOC_LINE_ITEM_KEYS.TOTAL] = Number(totalSum);
    row[DOC_LINE_ITEM_KEYS.SUBTOTAL] = Number(totalSum);
    row[DOC_LINE_ITEM_KEYS.UNIT_PRICE] = Number(totalSum);
    const zeroTax = Store.getState()?.commonData?.data?.tax?.purchase?.find(
      (tax: any) => tax.percent === 0
    );
    row[DOC_LINE_ITEM_KEYS.TAX] = zeroTax;
    row['taxCode'] = zeroTax.code;
    row['taxDetails'] = [];
    row['taxName'] = zeroTax.name;
    row.taxAmount = 0;
  }
  return row;
};
