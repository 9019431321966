import { useContext, useRef } from 'react';
import {
  DOC_TYPE,
  FULFILLMENT_STATUS,
  POPUP_CALLBACKS_TYPE,
  POPUP_CLICK_TYPE,
  POPUP_TYPE,
  RECEIVED_GOODS_STATUS
} from '../../../../../Constants/Constant';
import {
  BtnType,
  CallBackPayloadType,
  PopupClickActionType,
  UpdateCallBacksRefType
} from '../../../../../Models/Interfaces';
import { useAppDispatch, useAppSelector } from '../../../../../Redux/Hooks';
import PopupWrapper from '../../../../../SharedComponents/PopupWrapper';
import { CommonDraftPropsContext } from '../../../Utilities/DocContext';
import {
  selectCurrentActiveDocument,
  selectDocumentFormDataByKeys
} from '../../../../../Redux/Slices/DocumentSlice';
import { useTranslation } from 'react-i18next';
import DocActionsHelper from '../DocActionsHelper';
import { DOCUMENT_KEYS } from '../../../Utilities/DocConstants';
import { deepClone } from '../../../../../Utility/Utility';
import InvoiceService from '../../../../../Services/Invoice';
import { getUpdatedInvoiceObject } from '../../../../Invoices/InvoiceHelper';
import { DraftTypes } from '../../../../../Models/Drafts';
import { updatePopulateFormData } from '../../../../../Redux/Slices/DraftsSlice';
import BillService from '../../../../../Services/Bill';
import QuotationService from '../../../../../Services/Quotation';
import PurchaseOrderService from '../../../../../Services/PurchaseOrder';
import RequisitionService from '../../../../../Services/Requisition';
import SalesOrderService from '../../../../../Services/SalesOrder';
import WorkoutService from '../../../../../Services/Workout';
import { getJobWorkOutObject } from '../../../../Mrp/Workout/WorkOutHelper';
import { getUpdatedSalesOrderObject } from '../../../../SalesOrders/SalesOrderHelper';
import { getUpdatedRequisitonOrderObject } from '../../../../Requisitions/RequisitionHelper';
import { getUpdatedPurchaseOrderObject } from '../../../../PurchaseOrders/PurchaseOrderHelper';
import { getUpdatedQuoteObject } from '../../../../Quotations/QuoteHelper';
import { getUpdatedBillObject } from '../../../../Bills/BillHelper';
import Fulfillment from '../../../../../SharedComponents/FulfillmentPopup/Fulfillment';

export const FulfillmentActionPopup = (props: any) => {
  const dispatch = useAppDispatch();
  const { draftId, documentMode, draftType } = useContext(
    CommonDraftPropsContext
  );
  const [documentType] = useAppSelector(
    selectDocumentFormDataByKeys(draftId, [DOCUMENT_KEYS.DOCUMENT_TYPE])
  );

  const documentObject = useAppSelector(selectCurrentActiveDocument(draftId));
  const { t, i18n } = useTranslation();
  const refInitialState: UpdateCallBacksRefType = {
    pushDataToParent: { type: POPUP_CALLBACKS_TYPE.NONE },
    storeCallbacksRef: { updateInvoice: 'click' }
  };

  const addDocRef = useRef<UpdateCallBacksRefType>(refInitialState);

  const fulfillmentPopupBtnConfig: BtnType[] = [
    {
      title: t(`INVOICES.DIALOG.DIRECT_FULFILLMENT_OF_INVOICES.BUTTON.CANCEL`),
      class: 'border-m mr-s',
      clickAction: POPUP_CLICK_TYPE.CLOSE_POPUP
    },
    {
      title: t(
        `INVOICES.DIALOG.DIRECT_FULFILLMENT_OF_INVOICES.BUTTON.FULLFILL`
      ),
      class: 'bg-app text-white mr-ss',
      clickAction: POPUP_CLICK_TYPE.FULFILLMENT
    }
  ];

  const catchClicks = (data: PopupClickActionType) => {
    switch (data.type) {
      case POPUP_CLICK_TYPE.CLOSE_POPUP:
        props.removePopup();
        break;
      case POPUP_CLICK_TYPE.FULFILLMENT:
        addDocRef.current?.storeCallbacksRef.fulfillment();
        break;
    }
  };

  const updateDocument = (doc: any, paymentUpdated: boolean) => {
    let draftType;

    if (paymentUpdated) {
      if (doc.knockoffInfo) {
        if (doc.knockoffInfo.length) {
          draftType = DraftTypes.READONLY;
        } else {
          draftType = DraftTypes.UPDATE;
        }
      } else {
        draftType = DraftTypes.UPDATE;
      }
    } else {
      if (
        documentType === DOC_TYPE.INVOICE ||
        documentType === DOC_TYPE.QUOTE ||
        documentType === DOC_TYPE.SALES_ORDER ||
        documentType === DOC_TYPE.REQUISITION ||
        documentType === DOC_TYPE.JOB_WORK_OUT_ORDER
      ) {
        if (doc.fulfillmentStatus === FULFILLMENT_STATUS.UNFULFILLED) {
          draftType = DraftTypes.UPDATE;
        } else {
          draftType = DraftTypes.READONLY;
        }
      }

      if (documentType === DOC_TYPE.BILL) {
        if (doc.receiveGoodsStatus === RECEIVED_GOODS_STATUS.NOT_RECEIVED) {
          draftType = DraftTypes.UPDATE;
        } else {
          draftType = DraftTypes.READONLY;
        }
      }

      if (documentType === DOC_TYPE.ORDER) {
        if (doc.receiptStatus === RECEIVED_GOODS_STATUS.NOT_RECEIVED) {
          draftType = DraftTypes.UPDATE;
        } else {
          draftType = DraftTypes.READONLY;
        }
      }
    }

    dispatch(
      updatePopulateFormData({
        id: props.draftId,
        formdata: doc,
        draftType: draftType,
        actionFromDocument: true
      })
    );
  };

  const parentChildInteraction = (passingData: CallBackPayloadType) => {
    const paymentUpdated = props.showPaymentPopup || props.showPaymentRecord;
    switch (passingData.type) {
      case POPUP_CALLBACKS_TYPE.CLOSE_POPUP:
        if (documentType === DOC_TYPE.INVOICE) {
          InvoiceService.getInvoiceByCode(
            props.booksDocument.salesInvoiceCode
          ).then(
            (invoice: any) => {
              let invoiceDetailsData: any = getUpdatedInvoiceObject(invoice);
              updateDocument(invoiceDetailsData, paymentUpdated);
              props.removePopup();
            },
            (err) => {
              console.error('Error loading updated invoice: ', err);
            }
          );
        }
        if (documentType === DOC_TYPE.BILL) {
          BillService.fetchBillDetails(
            props.booksDocument.purchaseInvoiceCode
          ).then(
            (bill: any) => {
              const billDetailsData: any = getUpdatedBillObject(bill);
              updateDocument(billDetailsData, paymentUpdated);
              props.removePopup();
            },
            (err) => {
              console.error('Error loading updated bill: ', err);
            }
          );
        }
        if (documentType === DOC_TYPE.QUOTE) {
          QuotationService.getQuoteByCode(
            props.booksDocument.quotationCode
          ).then(
            (data: any) => {
              const quoteDetailsData: any = getUpdatedQuoteObject(data);
              updateDocument(quoteDetailsData, paymentUpdated);
              props.removePopup();
            },
            (err) => {
              console.error('Error loading updated quotation: ', err);
            }
          );
        }
        if (documentType === DOC_TYPE.ORDER) {
          PurchaseOrderService.fetchOrderDetails(
            props.booksDocument.poCode
          ).then(
            (data: any) => {
              const poDetailsData: any = getUpdatedPurchaseOrderObject(data);
              updateDocument(poDetailsData, paymentUpdated);
              props.removePopup();
            },
            (err) => {
              console.error('Error loading Purchase order: ', err);
            }
          );
        }
        if (documentType === DOC_TYPE.REQUISITION) {
          RequisitionService.fetchOrderDetails(
            props.booksDocument.purchaseRequestCode
          ).then(
            (data: any) => {
              const poDetailsData: any = getUpdatedRequisitonOrderObject(data);
              updateDocument(poDetailsData, paymentUpdated);
              props.removePopup();
            },
            (err) => {
              console.error('Error loading requisition order: ', err);
            }
          );
        }

        if (documentType === DOC_TYPE.SALES_ORDER) {
          SalesOrderService.getSalesOrderByCode(
            props.booksDocument.salesOrderCode
          ).then(
            (data: any) => {
              const salesOrderDetailsData: any =
                getUpdatedSalesOrderObject(data);
              updateDocument(salesOrderDetailsData, paymentUpdated);
              props.removePopup();
            },
            (err) => {
              console.error('Error loading updated order: ', err);
            }
          );
        }

        if (documentType === DOC_TYPE.JOB_WORK_OUT_ORDER) {
          WorkoutService.fetchJobWorkOutDetails(
            props.booksDocument.jwoCode
          ).then(
            (data: any) => {
              const jobWorkOutDetailsData: any = getJobWorkOutObject(data);
              updateDocument(jobWorkOutDetailsData, paymentUpdated);
              props.removePopup();
            },
            (err) => {
              console.error('Error loading updated Job Work Out: ', err);
            }
          );
        }

        break;
      case POPUP_CALLBACKS_TYPE.FULFILLMENT:
        addDocRef.current.storeCallbacksRef.fulfillment = passingData.data;
        break;
    }
  };

  return (
    <PopupWrapper
      clickAction={catchClicks}
      type={POPUP_TYPE.POPUP}
      title={DocActionsHelper.getDirectFulfillmentTitle(documentType)}
      height={'auto'}
      minHeight={'50%'}
      width={'100%'}
      disableClickOutside={true}
      btnList={fulfillmentPopupBtnConfig}
    >
      <Fulfillment
        documentDetails={deepClone(documentObject)}
        passingInteraction={(callback: CallBackPayloadType) => {
          parentChildInteraction(callback);
        }}
        closePopup={() => {
          props.removePopup();
        }}
        closeMenuContainer={() => {
          if (props.closeMenuContainer) {
            props.closeMenuContainer();
          }
        }}
        documentType={documentType}
      />
    </PopupWrapper>
  );
};
