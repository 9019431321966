import React, { useEffect, useState } from 'react';
import RouteManager from '../../../Managers/RouteManager';
import {
  DKInput,
  DKButton,
  INPUT_TYPE,
  INPUT_VIEW_DIRECTION,
  TOAST_TYPE,
  showToast,
  DKLabel,
  removeLoader,
  showLoader
} from 'deskera-ui-library';
import './GettingStarted.scss';
import InputLabel from '../../../SharedComponents/InputLabel/InputLabel';
import Select from 'react-select';
import { Toggle } from '../../../SharedComponents/ToggleBtn/Toggle';
import img_services from '../../../Assets/new_services.svg';
import img_digital from '../../../Assets/new_product.svg';
import img_inventory from '../../../Assets/new_buy_sell.svg';
import { TOGGLE_SIZE } from '../../../Constants/Enum';
import {
  GettingStartedState,
  gettingStartedState,
  InventoryToggleBtn,
  OnboardingSetting,
  OnboardingStep1Form,
  OnboardingStep2Form,
  OrgSetupCompleted,
  Step1FormFields,
  Step2FormCards
} from '../../../Models/Onboarding';
import Utility, {
  convertBooksDateFormatToUILibraryFormat,
  deepClone
} from '../../../Utility/Utility';
import { IAddress, ReactSelectOptionsType } from '../../../Models/Interfaces';
import {
  COUNTRIES_LIST,
  INDIAN_STATES_MOCK_DATA
} from '../../../Constants/StaticData';
import CurrencyDropdown from '../../../SharedComponents/CurrencyDropdown/CurrencyDropdown';
import DateFormatService from '../../../Services/DateFormat';
import {
  APP_NAME,
  BOOKS_DATE_FORMAT,
  COMMON_CONSTANT,
  COUNTRY_CODES,
  CURRENCIES,
  DATE_FORMAT,
  GSTIN_REGEX_PATTERN
} from '../../../Constants/Constant';
import { Information } from '../../../Models/PaymentPopup';
import TenantService from '../../../Services/Tenant';
import IamService from '../../../Services/iam';
import AppManager from '../../../Managers/AppManager';
import { useAppSelector } from '../../../Redux/Hooks';
import { isBookKeeper } from '../../../Redux/Slices/AuthSlice';
import AuthService from '../../../Services/Auth';

interface GettingStartedProps {
  details: any;
}

const GettingStarted: React.FC<GettingStartedProps> = (props) => {
  type ReactSelectType = ReactSelectOptionsType<string, string>;
  const [formState, setFormState] = useState<GettingStartedState>(
    deepClone(gettingStartedState)
  );

  const isBookKeeperFlag = useAppSelector(isBookKeeper);

  useEffect(() => {
    RouteManager.setPresenter({ props });
    // fetch financial years dates for different countries
    fetchFinancialYearDates();
    const companyName = props.details?.name || '';
    const updatedState = formState;
    const countryListOptions: ReactSelectType[] = [];
    const indianStateOptions: ReactSelectType[] = [];
    COUNTRIES_LIST.forEach((country) => {
      const option: ReactSelectType = {
        label: country.name,
        value: country.code
      };
      if (
        country.currencyCode === CURRENCIES.IN && 
        ( 
          Utility.isEmpty(props.details?.country) ||
          (!Utility.isEmpty(props.details?.country) && props.details?.country === COUNTRY_CODES.IN)
        )
      ) {
        updatedState.step1Form.formInfo.country.defaultValue = option;
        updatedState.step1Form.formInfo.country.value = option;

        updatedState.step1Form.formInfo.currency.value = {
          label: '',
          value: CURRENCIES.IN
        };
      } else if (
        !Utility.isEmpty(props.details?.country) &&
        country.code === props.details?.country
      ) {
        updatedState.step1Form.formInfo.country.defaultValue = option;
        updatedState.step1Form.formInfo.country.value = option;
        updatedState.step1Form.formInfo.currency.value = {
          label: '',
          value: country.currencyCode
        };
      }
      countryListOptions.push(option);
    });

    INDIAN_STATES_MOCK_DATA.forEach((state, index) => {
      const option: ReactSelectType = { label: state.name, value: state.code };
      if (state.stateCode === 'AN' && 
          ( 
            Utility.isEmpty(props.details?.country) ||
            (!Utility.isEmpty(props.details?.country) && props.details?.country === COUNTRY_CODES.IN)
          )
      ) {
        updatedState.step1Form.formInfo.state.defaultValue = option;
        updatedState.step1Form.formInfo.state.value = option;
      }
      indianStateOptions.push(option);
    });
    if (!Utility.isEmpty(props.details?.country) && props.details?.country !== COUNTRY_CODES.IN) {
      updatedState.step1Form.formInfo.state.hidden = true;
    }
    updatedState.step1Form.formInfo.country.options = countryListOptions;
    updatedState.step1Form.formInfo.state.options = indianStateOptions;

    // set todays Date
    updatedState.step1Form.formInfo.booksBeginningDate = {
      isOpen: false,
      date: DateFormatService.getDateStrFromDate(
        new Date(),
        BOOKS_DATE_FORMAT['DD-MM-YYYY']
      )
    };
    updatedState.step1Form.formInfo.financialStartDate = {
      isOpen: false,
      date: DateFormatService.getDateStrFromDate(
        new Date(),
        BOOKS_DATE_FORMAT['DD-MM-YYYY']
      )
    };
    updatedState.step1Form.formInfo.organisationName.value = companyName;
    updatedState.calendarDateFormat = BOOKS_DATE_FORMAT['DD-MM-YYYY'];
    setFormState({ ...updatedState });
    return () => {
      // cleanup
    };
  }, []);

  const backToStep1Form = () => {
    const updatedState = formState;
    updatedState.onStep1Form = true;
    setFormState({ ...updatedState });
  };

  const forwardToStep2Form = () => {
    const isFormValid = formFieldValidation();
    if (!isFormValid) {
      return;
    }
    const updatedState = formState;
    updatedState.onStep1Form = false;
    setFormState({ ...updatedState });
  };

  const formFieldValidation = (): boolean => {
    let formHasError: boolean = false;
    const updatedState = deepClone(formState);
    const { gstIn, gstRegistered, organisationName } =
      updatedState.step1Form.formInfo;

    if (!organisationName.hidden && !organisationName.value.toString().trim()) {
      organisationName.hasError = true;
      organisationName.errorMsg = 'Organisation name is required';
      formHasError = true;
    }

    /* INDIAN COMPLIANCE*/

    // gst registered
    if (gstRegistered && !gstIn.hidden && gstIn.value) {
      const gstRegex = new RegExp(GSTIN_REGEX_PATTERN);
      if (!gstRegex.test(gstIn.value.toString())) {
        gstIn.hasError = true;
        gstIn.errorMsg = 'GSTIN must be 15 characters long';
        formHasError = true;
      }
    }

    if (formHasError) {
      setFormState({ ...updatedState });
      return false;
    }
    return true;
  };

  const cardChecked = (cardType: Step2FormCards) => {
    const updatedState = formState;
    switch (cardType) {
      case Step2FormCards.INVENTORY:
        updatedState.step2Form.formInfo.inventory.isChecked =
          !updatedState.step2Form.formInfo.inventory.isChecked;
        break;
      case Step2FormCards.PRODUCTS:
        updatedState.step2Form.formInfo.productsChecked =
          !updatedState.step2Form.formInfo.productsChecked;
        break;
      case Step2FormCards.SERVICES:
        updatedState.step2Form.formInfo.serviceChecked =
          !updatedState.step2Form.formInfo.serviceChecked;
        break;

      default:
        break;
    }
    setFormState({ ...updatedState });
  };

  const dropdownOptionChanged = (
    fieldValue: ReactSelectOptionsType<string, string> | null,
    fieldName: Step1FormFields
  ) => {
    const updatedState = formState;
    if (fieldValue) {
      switch (fieldName) {
        case Step1FormFields.COUNTRY:
          setFinancialYearDates(fieldValue.value, updatedState);
          setCountrySpecificDateFormat(fieldValue.value, updatedState);
          if (fieldValue.value !== COUNTRY_CODES.IN) {
            updatedState.step1Form.formInfo.state.hidden = true;
          } else {
            updatedState.step1Form.formInfo.state.hidden = false;
          }
          const countryDetails = COUNTRIES_LIST.find(
            (country) => country.code === fieldValue.value
          );
          if (countryDetails) {
            updatedState.step1Form.formInfo.currency.value = {
              label: '',
              value: countryDetails.currencyCode
            };
          }
          updatedState.step1Form.formInfo.country.value = fieldValue;
          break;
        case Step1FormFields.STATE:
          updatedState.step1Form.formInfo.state.value = fieldValue;
          break;
      }
      setFormState({ ...updatedState });
    }
  };

  const formFieldChanged = (
    fieldValue: string | Date | boolean,
    fieldName: Step1FormFields
  ) => {
    const updatedState = formState;
    switch (fieldName) {
      case Step1FormFields.ORG_NAME:
        updatedState.step1Form.formInfo.organisationName.value =
          fieldValue as string;
        break;
      case Step1FormFields.BOOKS_BEGINNING_DATE:
        updatedState.step1Form.formInfo.booksBeginningDate = {
          isOpen: false,
          date: DateFormatService.getDateStrFromDate(
            fieldValue as Date,
            BOOKS_DATE_FORMAT['DD-MM-YYYY']
          )
        };
        break;
      case Step1FormFields.FINANCIAL_START_DATE:
        updatedState.step1Form.formInfo.financialStartDate = {
          isOpen: false,
          date: DateFormatService.getDateStrFromDate(
            fieldValue as Date,
            BOOKS_DATE_FORMAT['DD-MM-YYYY']
          )
        };
        break;
      case Step1FormFields.GST_REGISTERED:
        updatedState.step1Form.formInfo.gstRegistered = fieldValue as boolean;
        if (
          updatedState.step1Form.formInfo.country.value.value ===
          COUNTRY_CODES.IN
        ) {
          updatedState.step1Form.formInfo.gstIn.hidden = !fieldValue as boolean;
        }
        break;
      case Step1FormFields.GST_IN:
        const gstRegex = new RegExp(GSTIN_REGEX_PATTERN);
        updatedState.step1Form.formInfo.gstIn.hasError = false;
        updatedState.step1Form.formInfo.gstIn.value = fieldValue as string;
        if (fieldValue && !gstRegex.test(fieldValue as string)) {
          updatedState.step1Form.formInfo.gstIn.hasError = true;
          updatedState.step1Form.formInfo.gstIn.errorMsg =
            'GSTIN must be 15 characters long';
        }
        break;
      default:
        break;
    }

    setFormState({ ...updatedState });
  };

  const inventoryToggle = (
    toggleValue: boolean,
    toggleType: InventoryToggleBtn
  ) => {
    const updatedState = formState;
    switch (toggleType) {
      case InventoryToggleBtn.PRICE_LIST:
        updatedState.step2Form.formInfo.inventory.isPriceList = toggleValue;
        break;
      case InventoryToggleBtn.PPS:
        updatedState.step2Form.formInfo.inventory.isPPS = toggleValue;
        break;
      case InventoryToggleBtn.WAREHOUSE:
        updatedState.step2Form.formInfo.inventory.isMultiwarehouse =
          toggleValue;
        break;
      case InventoryToggleBtn.E_WAY_BILL:
        updatedState.step2Form.formInfo.inventory.isEWayBill = toggleValue;
        break;

      default:
        break;
    }
    setFormState({ ...updatedState });
  };

  const grabCurrencyInfo = (data: Information) => {
    const updatedState = formState;
    const option: ReactSelectType = {
      label: data.selectedCurrencyName,
      value: data.selectedCurrencyCode
    };
    updatedState.step1Form.formInfo.currency.value = option;
    setFormState({ ...updatedState });
  };

  const fetchFinancialYearDates = () => {
    TenantService.getDefaultFY()
      .then((response: any) => {
        fetch(response)
          .then((response) => {
            return response.json();
          })
          .then((stringifiedData) => {
            const defaultDate =
              stringifiedData[COMMON_CONSTANT.DEFAULT_FY_DATE];
            const updatedState = formState;
            updatedState.financeYearDates = defaultDate;
            setFinancialYearDates('IN', updatedState);
            setFormState({ ...updatedState });
          });
      })
      .catch((error) => {
        console.log(error, 'error');
      });
  };

  const setFinancialYearDates = (
    countryCode: string,
    updatedState: GettingStartedState
  ) => {
    const countryFinancialYearDates = updatedState.financeYearDates?.find(
      (country) => country.countryLookupCode === countryCode
    );
    if (countryFinancialYearDates) {
      let currentYear = new Date().getFullYear();
      const currentMonth = new Date().getMonth();

      if (countryCode === COUNTRY_CODES.IN && currentMonth < 3) {
        currentYear = currentYear - 1;
      }
      const financialDate =
        countryFinancialYearDates.financialStartDate + '-' + currentYear;
      updatedState.step1Form.formInfo.financialStartDate.date =
        DateFormatService.getFormattedDateString(
          financialDate,
          BOOKS_DATE_FORMAT['MM-DD-YYYY'],
          BOOKS_DATE_FORMAT['DD-MM-YYYY']
        );
      updatedState.step1Form.formInfo.booksBeginningDate.date =
        updatedState.step1Form.formInfo.financialStartDate.date;
    } else {
      updatedState.step1Form.formInfo.booksBeginningDate = {
        isOpen: false,
        date: DateFormatService.getDateStrFromDate(
          new Date(),
          BOOKS_DATE_FORMAT['DD-MM-YYYY']
        )
      };
      updatedState.step1Form.formInfo.financialStartDate = {
        isOpen: false,
        date: DateFormatService.getDateStrFromDate(
          new Date(),
          BOOKS_DATE_FORMAT['DD-MM-YYYY']
        )
      };
    }
  };

  const setCountrySpecificDateFormat = (
    countryCode: string,
    updatedState: GettingStartedState
  ) => {
    if (
      countryCode === COUNTRY_CODES.IN ||
      countryCode === COUNTRY_CODES.SG ||
      countryCode === COUNTRY_CODES.UK ||
      countryCode === COUNTRY_CODES.BE ||
      countryCode === COUNTRY_CODES.ID ||
      countryCode === COUNTRY_CODES.MY
    ) {
      updatedState.calendarDateFormat = BOOKS_DATE_FORMAT['DD-MM-YYYY'];
    } else if (
      countryCode === COUNTRY_CODES.US ||
      countryCode === COUNTRY_CODES.AU
    ) {
      updatedState.calendarDateFormat = BOOKS_DATE_FORMAT['MM-DD-YYYY'];
    } else if (countryCode === COUNTRY_CODES.DE) {
      updatedState.calendarDateFormat = BOOKS_DATE_FORMAT['YYYY-MM-DD'];
    }
  };

  const createOrg = () => {
    if (
      !formState.step2Form.formInfo.inventory.isChecked &&
      !formState.step2Form.formInfo.productsChecked &&
      !formState.step2Form.formInfo.serviceChecked
    ) {
      showToast('Please select atleast One Option', TOAST_TYPE.WARNING);
      return;
    }

    const userIdFromAuthService = AuthService.getUserID()
      ? AuthService.getUserID()
      : '';
    const userId = props?.details?.userId
      ? props?.details?.userId?.toString()
      : userIdFromAuthService;

    const step1Form: OnboardingStep1Form = {
      tenantName:
        formState.step1Form.formInfo.organisationName.value.toString(),
      financialStartDate: formState.step1Form.formInfo.financialStartDate.date,
      bookBeginningStartDate:
        formState.step1Form.formInfo.booksBeginningDate.date,
      currencyLookUpCode: formState.step1Form.formInfo.currency.value.value,
      countryLookupCode: formState.step1Form.formInfo.country.value.value,
      complianceEnabled: formState.step1Form.formInfo.gstRegistered,
      numberFormat: 'us',
      purpose: '',
      industry: '',
      size: '',
      dateFormatLookUpCode: DATE_FORMAT.DD_MM_YYYY,
      userId: userId,
      isBookkeeper: isBookKeeperFlag
    };

    let hiddenApps: string = 'PPS,WAREHOUSE,PRICE_LIST';

    if (step1Form.countryLookupCode === 'IN') {
      step1Form.gstRegistered = formState.step1Form.formInfo.gstRegistered;
      step1Form.gstin = formState.step1Form.formInfo.gstIn.value
        ? formState.step1Form.formInfo.gstIn.value.toString()
        : '';
      step1Form.nexusStates = formState.step1Form.formInfo.state.value.label;

      const address: IAddress = {
        city: '',
        country: step1Form.countryLookupCode,
        postalCode: '',
        preferred: true,
        state: step1Form.nexusStates
      };
      step1Form.billingAddresses = [address];
      step1Form.shippingAddresses = [address];
      hiddenApps = 'PPS,WAREHOUSE,PRICE_LIST,E_WAY';
    }

    if (formState.step2Form.formInfo.inventory.isChecked) {
      if (formState.step2Form.formInfo.inventory.isPPS) {
        hiddenApps = hiddenApps.replace('PPS,', '');
      }
      if (formState.step2Form.formInfo.inventory.isMultiwarehouse) {
        hiddenApps = hiddenApps.replace('WAREHOUSE,', '');
      }
      if (formState.step2Form.formInfo.inventory.isPriceList) {
        if (step1Form.countryLookupCode === 'IN') {
          hiddenApps = hiddenApps.replace('PRICE_LIST,', '');
        } else {
          hiddenApps = hiddenApps.replace('PRICE_LIST', '');
        }
      }
      if (
        formState.step2Form.formInfo.inventory.isEWayBill &&
        step1Form.countryLookupCode === 'IN'
      ) {
        hiddenApps = hiddenApps.replace('E_WAY', '');
      }
    } else if (!formState.step2Form.formInfo.inventory.isChecked) {
      if (step1Form.countryLookupCode === 'IN') {
        hiddenApps = 'PPS,WAREHOUSE,PRICE_LIST,E_WAY';
      } else {
        hiddenApps = 'PPS,WAREHOUSE,PRICE_LIST';
      }
    }

    const onboardingSetting: OnboardingSetting = {
      showGettingStarted: true,
      services: formState.step2Form.formInfo.serviceChecked,
      digitalProducts: formState.step2Form.formInfo.productsChecked,
      inventory: formState.step2Form.formInfo.inventory.isChecked
    };

    const step2Form: OnboardingStep2Form = {
      application: APP_NAME,
      hiddenApps: hiddenApps,
      customLabels: {},
      onBoardingSetting: onboardingSetting,
      isResetCustomLabels: false,
      isResetHiddenApps: 'true'
    };
    const loadStep1FormApi = step1FormApi(step1Form);
    const loadStep2FormApi = step2FormApi(step2Form);
    showLoader();
    Promise.all([loadStep1FormApi, loadStep2FormApi]).then((res) => {
      const response1: any = res[0];
      const tenantId = response1.tenantId;
      const loadTenantDetailPatchApi = updatedTenantDetail(step1Form);
      Promise.all([loadTenantDetailPatchApi])
        .then((res) => {
          const loadSwitchTenantApi = switchTenant(tenantId);
          const loadUpdateOrgStatusApi = updateOrgStatus();

          Promise.all([loadSwitchTenantApi, loadUpdateOrgStatusApi])
            .then((res) => {
              removeLoader();
              setTimeout(() => {
                AppManager.refreshApp();
              }, 1000);
            })
            .catch((err) => {
              removeLoader();
              console.log('An error occured: ', err);
            });
        })
        .catch((err) => {});
    });
  };

  const switchTenant = async (tenantId: number) => {
    try {
      const response = IamService.switchTenant({ tenantId: tenantId });
      return response;
    } catch (error) {
      console.error('Error switching tenant: ', error);
    }
  };

  const updateOrgStatus = async () => {
    try {
      const response = TenantService.updateOrgStatus(OrgSetupCompleted);
      return response;
    } catch (error) {
      console.error('Error updating Org Status: ', error);
    }
  };

  const step1FormApi = async (step1Form: OnboardingStep1Form) => {
    let countryCode = Utility.countriesRequiringCodeForCreation.includes(
      step1Form.countryLookupCode ? step1Form.countryLookupCode : ''
    )
      ? step1Form.countryLookupCode
      : '';
    try {
      const response = TenantService.patchOrgWithSetup(step1Form, countryCode);
      return response;
    } catch (error) {
      console.error('Error creating step1Form: ', error);
    }
  };

  const step2FormApi = async (step2Form: OnboardingStep2Form) => {
    try {
      const response = TenantService.postAppCustomization(step2Form);
      return response;
    } catch (error) {
      console.error('Error creating step2Form: ', error);
    }
  };

  const updatedTenantDetail = async (step1Form: OnboardingStep1Form) => {
    try {
      const response = TenantService.updateOrgSetting(step1Form);
      return response;
    } catch (error) {
      console.error('Error creating step1Form: ', error);
    }
  };

  return (
    <div className="w-full h-screen flex flex-col items-center">
      {formState.onStep1Form && (
        <div className="step-1 w-full flex flex-col items-center text-center pt-10">
          <div className="text-xs font-bold text-blue-700">Step 1 of 2</div>
          <div className="text-4xl font-semibold">
            Tell us about you and your business
          </div>
          <div className="w-1/3 mt-5">
            <InputLabel
              text={'What is your Organisation Name ?'}
              className="text-left text-gray-600"
            />
            <DKInput
              type={INPUT_TYPE.TEXT}
              direction={INPUT_VIEW_DIRECTION.VERTICAL}
              required
              onChange={(value: string) =>
                formFieldChanged(value, Step1FormFields.ORG_NAME)
              }
              value={formState.step1Form.formInfo.organisationName.value}
              canValidate={
                formState.step1Form.formInfo.organisationName.hasError
              }
              validator={(value: string) => {
                return !formState.step1Form.formInfo.organisationName.hasError;
              }}
              errorMessage={
                formState.step1Form.formInfo.organisationName.errorMsg
              }
            />
          </div>
          <div className="w-1/3 flex flex-wrap mt-8">
            {formState.step1Form.formInfo.country.options && (
              <div
                className={'flex-1 pr-4 text-left'}
                style={{ minWidth: '250px', maxWidth: '460px' }}
              >
                <DKInput
                  title={'Which country are you from ?'}
                  type={INPUT_TYPE.DROPDOWN}
                  direction={INPUT_VIEW_DIRECTION.VERTICAL}
                  value={formState.step1Form.formInfo.country.value}
                  formatter = {(obj: any) => {
                    return obj?.label;
                  }}
                  dropdownConfig={{
                    data: formState.step1Form.formInfo.country.options,
                    style: { minWidth: 150 },
                    className: 'shadow-m width-auto',
                    renderer: (index: any, obj: any) => {
                      return <DKLabel text={`${obj?.label}`} />;
                    },
                    searchableKey: 'label',
                    allowSearch: true
                  }}
                  onChange={(value: any) => {
                    dropdownOptionChanged(value, Step1FormFields.COUNTRY);
                  }}
                  readOnly={!Utility.isEmpty(props.details?.country)}
                />
                <div className="mt-5"></div>
              </div>
            )}
            {!formState.step1Form.formInfo.state.hidden &&
              formState.step1Form.formInfo.state.options && (
                <div
                  className={'flex-1 pr-4 text-left'}
                  style={{ minWidth: '250px', maxWidth: '460px' }}
              >
                <DKInput
                  title={'Select State'}
                  type={INPUT_TYPE.DROPDOWN}
                  direction={INPUT_VIEW_DIRECTION.VERTICAL}
                  value={formState.step1Form.formInfo.state.value}
                  formatter = {(obj: any) => {
                    return obj?.label;
                  }}
                  dropdownConfig={{
                    data: formState.step1Form.formInfo.state.options,
                    style: { minWidth: 150 },
                    className: 'shadow-m width-auto',
                    renderer: (index: any, obj: any) => {
                      return <DKLabel text={`${obj?.label}`} />;
                    },
                    searchableKey: 'label',
                    allowSearch: true
                  }}
                  onChange={(value: any) => {
                    dropdownOptionChanged(value, Step1FormFields.STATE);
                  }}
                />
                  <div className="mt-5"></div>
                </div>
              )}
            <div
              className={'flex-1 pr-4'}
              style={{ minWidth: '250px', maxWidth: '460px' }}
            >
              <DKLabel
                text={'Which currency do you use ?'}
                className=" fs-r text-align-left unselectable mb-xs "
              />
              <CurrencyDropdown
                currencyCode={
                  formState.step1Form.formInfo.currency.value
                    ? formState.step1Form.formInfo.currency.value.value
                    : 'INR'
                }
                getInfo={grabCurrencyInfo}
                isDisabled = {!Utility.isEmpty(props.details?.country)}
              />
              <div className="mt-5"></div>
            </div>
            <div
              className={'flex-1 pr-4'}
              style={{ minWidth: '250px', maxWidth: '460px' }}
            ></div>
          </div>

          <div className="w-1/3 flex flex-wrap my-5">
            <div
              className={'flex-1 pr-4'}
              style={{ minWidth: '250px', maxWidth: '460px' }}
            >
              <DKInput
                title="Financial Year Start Date"
                value={DateFormatService.getDateFromStr(
                  formState.step1Form.formInfo.financialStartDate.date,
                  BOOKS_DATE_FORMAT['DD-MM-YYYY']
                )}
                type={INPUT_TYPE.DATE}
                onChange={(date: Date) =>
                  formFieldChanged(date, Step1FormFields.FINANCIAL_START_DATE)
                }
                direction={INPUT_VIEW_DIRECTION.VERTICAL}
                required={false}
                dateFormat={convertBooksDateFormatToUILibraryFormat(
                  formState.calendarDateFormat
                )}
              />
            </div>

            <div
              className={'flex-1 pr-4'}
              style={{ minWidth: '250px', maxWidth: '460px' }}
            >
              <DKInput
                title="Book Beginning Date"
                value={DateFormatService.getDateFromStr(
                  formState.step1Form.formInfo.booksBeginningDate.date,
                  BOOKS_DATE_FORMAT['DD-MM-YYYY']
                )}
                type={INPUT_TYPE.DATE}
                onChange={(date: Date) =>
                  formFieldChanged(date, Step1FormFields.BOOKS_BEGINNING_DATE)
                }
                direction={INPUT_VIEW_DIRECTION.VERTICAL}
                required={false}
                dateFormat={convertBooksDateFormatToUILibraryFormat(
                  formState.calendarDateFormat
                )}
              />
            </div>
          </div>
          {formState.step1Form.formInfo.country.value.value === 'IN' && (
            <div
              className="w-1/3 flex align-items-center mb-m"
              style={{ height: '62px' }}
            >
              <div className="row align-items-center">
                <InputLabel
                  text={
                    formState.step1Form.formInfo.country.value.value === 'IN'
                      ? 'GST Registered'
                      : 'Do you collect tax? '
                  }
                  className="text-left mr-6 text-gray-600"
                />
                <Toggle
                  onChange={(checked) =>
                    formFieldChanged(checked, Step1FormFields.GST_REGISTERED)
                  }
                  value={formState.step1Form.formInfo.gstRegistered}
                />
              </div>
              {!formState.step1Form.formInfo.gstIn.hidden && (
                <DKInput
                  title="GSTIN Number"
                  className="text-gray-600 parent-width"
                  canValidate={formState.step1Form.formInfo.gstIn.hasError}
                  required={formState.step1Form.formInfo.gstIn.isMandatory}
                  value={formState.step1Form.formInfo.gstIn.value}
                  onChange={(value: string) =>
                    formFieldChanged(value, Step1FormFields.GST_IN)
                  }
                  direction={INPUT_VIEW_DIRECTION.VERTICAL}
                  validator={(value: string) => {
                    return !formState.step1Form.formInfo.gstIn.hasError;
                  }}
                  errorMessage={formState.step1Form.formInfo.gstIn.errorMsg}
                />
              )}
            </div>
          )}
          <div className="w-1/3">
            <div style={{ width: '3.4rem' }}>
              <DKButton
                title={'Next'}
                className="bg-app text-white text-center"
                onClick={forwardToStep2Form}
              />
            </div>
          </div>
        </div>
      )}

      {!formState.onStep1Form && (
        <div className="step-1 w-full flex flex-col items-center text-center pt-10">
          <div className="text-xs font-bold text-blue-700">Step 2 of 2</div>
          <div className="text-4xl font-semibold">
            What does your business deal with?
          </div>
          <div className="text-base font-semibold text-gray-600">
            This is just to get you started. You can always change it later in
            the settings.
          </div>
          <div className="w-max mt-5">
            <div className="step2-card">
              <div className="icon">
                <img
                  className="w-full h-full"
                  src={img_services}
                  alt="img-services"
                />
              </div>
              <div className="text-left">
                <div className="text-base font-semibold">Services</div>
                <div className="text-xs font-normal mt-1">
                  Provide professional services like consulting, real estate,
                  IT/ITES, processing etc.
                </div>
              </div>

              <div className="absolute top-3 right-0">
                <input
                  type="checkbox"
                  className="form-checkbox h-5 w-5 text-green-600 mr-2"
                  onClick={(e) => cardChecked(Step2FormCards.SERVICES)}
                />
              </div>
            </div>
          </div>

          <div className="w-max mt-5">
            <div className="step2-card">
              <div className="icon">
                <img
                  className="w-full h-full"
                  src={img_digital}
                  alt="img-digital"
                />
              </div>
              <div className="text-left">
                <div className="text-base font-semibold">Digital Products</div>
                <div className="text-xs font-normal mt-1">
                  Sell digital products like eBooks, Softwares, Audio and Music,
                  Documents etc.
                </div>
              </div>
              <div className="absolute top-3 right-0">
                <input
                  type="checkbox"
                  className="form-checkbox h-5 w-5 text-green-600 mr-2"
                  onClick={(e) => cardChecked(Step2FormCards.PRODUCTS)}
                />
              </div>
            </div>
          </div>

          <div className="w-max mt-5">
            <div className="step2-card">
              <div className="icon">
                <img
                  className="w-full h-full"
                  src={img_inventory}
                  alt="img-inventory"
                />
              </div>
              <div className="text-left">
                <div className="text-base font-semibold">Inventory</div>
                <div className="text-xs font-normal mt-1">
                  Products you buy and/ or sell and that you track quantities
                  of.
                </div>
                <div className="flex mb-1 mt-3">
                  <div className="w-40 text-xs font-semibold">
                    Pick Pack Ship Service
                  </div>
                  <Toggle
                    onChange={(checked) =>
                      inventoryToggle(checked, InventoryToggleBtn.PPS)
                    }
                    toggleSize={TOGGLE_SIZE.SMALL}
                    isDisabled={
                      !formState.step2Form.formInfo.inventory.isChecked
                    }
                  />
                </div>
                <div className="flex mb-1">
                  <div className="w-40 text-xs font-semibold">
                    Multiple warehouses
                  </div>
                  <Toggle
                    onChange={(checked) =>
                      inventoryToggle(checked, InventoryToggleBtn.WAREHOUSE)
                    }
                    toggleSize={TOGGLE_SIZE.SMALL}
                    isDisabled={
                      !formState.step2Form.formInfo.inventory.isChecked
                    }
                  />
                </div>
                <div className="flex mb-1">
                  <div className="w-40 text-xs font-semibold">Price List</div>
                  <Toggle
                    onChange={(checked) =>
                      inventoryToggle(checked, InventoryToggleBtn.PRICE_LIST)
                    }
                    toggleSize={TOGGLE_SIZE.SMALL}
                    isDisabled={
                      !formState.step2Form.formInfo.inventory.isChecked
                    }
                  />
                </div>
                {formState.step1Form.formInfo.country.value.value === 'IN' && (
                  <div className="flex">
                    <div className="w-40 text-xs font-semibold">E-Way Bill</div>
                    <Toggle
                      onChange={(checked) =>
                        inventoryToggle(checked, InventoryToggleBtn.E_WAY_BILL)
                      }
                      toggleSize={TOGGLE_SIZE.SMALL}
                      isDisabled={
                        !formState.step2Form.formInfo.inventory.isChecked
                      }
                    />
                  </div>
                )}
              </div>

              <div className="absolute top-3 right-0">
                <input
                  type="checkbox"
                  className="form-checkbox h-5 w-5 text-green-600 mr-2"
                  onClick={(e) => cardChecked(Step2FormCards.INVENTORY)}
                />
              </div>
            </div>
          </div>

          <div className="w-1/3 flex mt-5">
            <div className="w-20 mr-3">
              <DKButton
                title={'Back'}
                className="border-m"
                onClick={backToStep1Form}
              />
            </div>
            <div className="w-20">
              <DKButton
                title={'All Set'}
                className="bg-app text-white"
                onClick={createOrg}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default GettingStarted;
