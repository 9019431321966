import {
  DKButton,
  DKIcon,
  DKIcons,
  DKInput,
  DKLabel,
  DKLine,
  INPUT_TYPE,
  INPUT_VIEW_DIRECTION,
  showAlert,
  showLoader,
  removeLoader
} from 'deskera-ui-library';
import React, { useEffect, useRef, useState } from 'react';
import {
  BOOKS_DATE_FORMAT,
  DATE_FORMAT,
  FORM_ELEMENTS,
  POPUP_CALLBACKS_TYPE,
  QTY_ROUNDOFF_PRECISION,
  TRACKING_TYPE
} from '../../Constants/Constant';
import { DATE_COMPARISON } from '../../Constants/Enum';
import {
  BATCH_SERIAL_TRACKING_FIELD_NAMES,
  BatchRow,
  BatchSerialTrackingProps,
  BatchSerialTrackingState,
  BatchTrackedMetaData,
  SerialRow,
  SerialTrackedMetaData,
  initialBatchSerialTrackingState
} from '../../Models/BatchSerialTracking';
import { InputTag } from '../../Models/NewContact';
import { useAppDispatch, useAppSelector } from '../../Redux/Hooks';
import { activeTenantInfo } from '../../Redux/Slices/AuthSlice';
import { fetchProducts } from '../../Redux/Slices/ProductsSlice';
import DateFormatService from '../../Services/DateFormat';
import ProductService from '../../Services/Product';
import Utility, {
  convertBooksDateFormatToUILibraryFormat,
  deepClone
} from '../../Utility/Utility';

export const BatchSerialTrackingPopup: React.FC<BatchSerialTrackingProps> = (
  props
) => {
  const [batchSerialState, setBatchSerialState] =
    useState<BatchSerialTrackingState>(
      deepClone(initialBatchSerialTrackingState)
    );
  const appSelector = useAppSelector;
  const tenantDetails = appSelector(activeTenantInfo);
  const trackingType = props.productDetails?.advancedTracking;
  const decimalPlace = tenantDetails?.decimalScale || 2;
  const dispatch = useAppDispatch();
  var totalAssignedOpeningQuantity = useRef(0);

  const prepareObject = async () => {
    const productDetails = props.productDetails;
    const advancedTrackingMetaData = props.advancedTrackingMetaData;
    var updatedState = deepClone(initialBatchSerialTrackingState);
    await setTotalAssignedOpeningQuantity();

    if (trackingType === TRACKING_TYPE.BATCH) {
      const productDetails = props.productDetails;
      updatedState.batch.openingQuantity =
        productDetails?.inventory?.openingQuantity || 0;
      updatedState.batch.unassignedQuantity =
        totalAssignedOpeningQuantity.current;

      if (advancedTrackingMetaData) {
        updatedState = setInitialStateForBatchProduct(
          advancedTrackingMetaData,
          updatedState
        );
      }
    } else if (trackingType === TRACKING_TYPE.SERIAL) {
      updatedState = setInitialStateSerialForProduct(
        updatedState,
        productDetails,
        tenantDetails,
        decimalPlace,
        advancedTrackingMetaData
      );
    }
    setBatchSerialState({ ...updatedState });
  };

  useEffect(() => {
    prepareObject();
    return () => {
      // cleanup
    };
  }, []);

  useEffect(() => {
    registerInteractions();
  });

  const registerInteractions = () => {
    /*
     * register parents calls to child methods
     */

    props.passingInteraction({
      type: POPUP_CALLBACKS_TYPE.SAVE_ADVANCED_TRACKING,
      data: () => {
        createAdvancedTracking();
      }
    });
  };

  const setTotalAssignedOpeningQuantity = async () => {
    // fetch unassigned opening quantity from server.
    showLoader();
    let unassignedQty =
      await ProductService.getProductUnassignedOpeningQuantity(
        props.productDetails?.productId
      );
    removeLoader();
    totalAssignedOpeningQuantity.current = Number(unassignedQty ?? 0);
  };

  const setInitialStateForBatchProduct = (
    advancedTrackingMetaData: any[],
    batchState: BatchSerialTrackingState
  ) => {
    var updatedState = { ...batchState };
    const newlyAddedBatchList: BatchRow[] = [];
    let totalQtyInAllBatches = 0;
    let totalFulfilledQty = 0;
    let totalReturnedQty = 0;
    advancedTrackingMetaData.forEach((element) => {
      const batchNumber: InputTag<string> = {
        key: '',
        hidden: false,
        value: element.serialBatchNumber,
        type: FORM_ELEMENTS.INPUT,
        hasError: false,
        isMandatory: true,
        isDisabled: true
      };

      const manufacturedDate: InputTag<string> = {
        key: '',
        hidden: false,
        value: element.manufacturingDate ? element.manufacturingDate : '',
        type: FORM_ELEMENTS.DATE,
        hasError: false,
        isMandatory: true,
        isDisabled: true
      };

      const expiryDate: InputTag<string> = {
        key: '',
        hidden: false,
        value: element.expiryDate ? element.expiryDate : '',
        type: FORM_ELEMENTS.DATE,
        hasError: false,
        isMandatory: true,
        isDisabled: true
      };

      const batchQuantity: InputTag<string> = {
        key: '',
        hidden: false,
        value: element.batchSize,
        type: FORM_ELEMENTS.INPUT,
        hasError: false,
        isMandatory: true,
        isDisabled: true
      };
      const availaleBatchQuantity: InputTag<string> = {
        key: '',
        hidden: false,
        value:
          element.batchSize -
          element?.reservedQuantity -
          element?.batchSizeFulfilled,
        type: FORM_ELEMENTS.INPUT,
        hasError: false,
        isMandatory: true,
        isDisabled: true
      };
      totalQtyInAllBatches += parseFloat(element.batchSize);
      totalFulfilledQty += parseFloat(element.batchSizeFulfilled);
      totalReturnedQty += parseFloat(
        element.advancedTrackingReturned?.batchSizeReturned || 0
      );
      const newlyAddedBatch: BatchRow = {
        batchNumber: batchNumber,
        manufacturedDate: manufacturedDate,
        expiryDate: expiryDate,
        qtyInBatch: batchQuantity,
        availableQtyInBatch: availaleBatchQuantity,
        isExisting: true,
        id: element.id
      };
      newlyAddedBatchList.push(newlyAddedBatch);
    });

    updatedState.batch.toalQuantityInBatches = totalQtyInAllBatches;
    updatedState.batch.batchList = newlyAddedBatchList;
    return updatedState;
  };

  const setInitialStateSerialForProduct = (
    serialState: BatchSerialTrackingState,
    productDetails: any,
    tenantDetails: any,
    decimalPlace: any,
    advancedTrackingMetaData: any[] | undefined
  ) => {
    var updatedState = { ...serialState };
    updatedState.serial.openingQuantity =
      productDetails?.inventory?.openingQuantity || 0;
    updatedState.serial.unassignedQuantity =
      totalAssignedOpeningQuantity.current;
    updatedState.serial.currencyCode = tenantDetails.currency;
    if (updatedState.serial.openingQuantity !== 0) {
      const openingValuation = productDetails?.inventory?.openingValuation || 0;
      // unitprice used as string to preserve trailing zeros
      updatedState.serial.unitPrice = Utility.toFixedWithoutRounding(
        openingValuation / updatedState.serial.openingQuantity,
        decimalPlace
      ).toString();
    }

    if (advancedTrackingMetaData) {
      let totalSerials = 0;
      let totalFulfilledQty = 0;
      let totalReturnedQty = 0;
      const newlyAddedSerialList: SerialRow[] = [];
      advancedTrackingMetaData.forEach((element) => {
        const serialNumber: InputTag<string> = {
          key: '',
          hidden: false,
          value: element.serialBatchNumber,
          type: FORM_ELEMENTS.INPUT,
          hasError: false,
          isMandatory: true,
          isDisabled: true
        };

        const newlyAddedSerial: SerialRow = {
          inputField: serialNumber,
          amount: Utility.toFixedWithoutRounding(
            element.acquiredCost,
            decimalPlace
          ).toString()
          // amount: updatedState.serial.unitPrice
        };
        totalSerials += 1;
        newlyAddedSerialList.push(newlyAddedSerial);
        totalFulfilledQty += parseFloat(element.batchSizeFulfilled);
        totalReturnedQty += parseFloat(
          element.advancedTrackingReturned?.batchSizeReturned || 0
        );
      });
      if (updatedState.serial.unassignedQuantity === 0) {
        updatedState.serial.noNewSerials = true;
      }
      updatedState.serial.serialList = newlyAddedSerialList;
    }
    return updatedState;
  };

  const calculateQuantityToAssign = () => {
    let openingQuantityProduct = Number(
      props?.productDetails?.inventory?.openingQuantity ?? 0
    );
    return Number(
      openingQuantityProduct - batchSerialState.batch.unassignedQuantity
    );
  };

  const addNewSerial = () => {
    const updatedState = deepClone(batchSerialState);
    if (updatedState.serial.unassignedQuantity === 0) {
      showAlert(
        'Error',
        'You are not allowed to assign serial number more than the receiving quantity.',
        [
          {
            title: 'Ok',
            className: 'bg-button text-white',
            onClick: () => {}
          }
        ]
      );
      return;
    }
    if (!batchSerialState.serial.serialInputField.value) {
      updatedState.serial.serialInputField.hasError = true;
      updatedState.serial.serialInputField.errorMsg =
        'Serial Number is required';
      setBatchSerialState({ ...updatedState });
      return;
    }
    // allow save btn click
    updatedState.saveBtnDisabled = false;
    const receivedSerialNumbers = updatedState.serial.serialInputField.value
      .toString()
      .trim()
      .split(',');
    const serialNumbers = receivedSerialNumbers.map((serialNumber) =>
      serialNumber.trim()
    );
    // push the serial numbers to the serial array
    const newlyAddedSerialNumber: SerialRow[] = [];
    serialNumbers.forEach((newSerialNumber) => {
      const newSerialNumberField: InputTag<string> = {
        key: '',
        hidden: false,
        value: newSerialNumber,
        type: FORM_ELEMENTS.INPUT,
        hasError: false,
        isMandatory: true
      };
      const newSerialRow: SerialRow = {
        inputField: newSerialNumberField,
        amount: updatedState.serial.unitPrice
      };
      newlyAddedSerialNumber.push(newSerialRow);
    });
    // push the new serial numbers to front of the serial array
    updatedState.serial.serialList = [
      ...newlyAddedSerialNumber,
      ...updatedState.serial.serialList
    ];
    // check Duplicate Serial Number
    checkDuplicateSerial(updatedState);
    updatedState.serial.unassignedQuantity -= newlyAddedSerialNumber.length;
    updatedState.serial.serialInputField.value = '';
    setBatchSerialState({ ...updatedState });
    setTimeout(() => {
      setBatchSerialState({ ...updatedState });
    }, 1000);
  };

  const addNewBatch = () => {
    const updatedState = deepClone(batchSerialState);
    const {
      unassignedQuantity,
      batchInputField,
      batchList,
      batchQuantityField
    } = updatedState.batch;
    if (unassignedQuantity <= 0) {
      showAlert(
        'Error',
        'You are not allowed to assign batch more than the available quantity.'
      );
      return;
    }

    if (!batchSerialState.batch.batchInputField.value) {
      batchInputField.hasError = true;
      batchInputField.errorMsg = 'Batch Number is required';
      setBatchSerialState({ ...updatedState });
      return;
    }

    if (
      isNaN(Number(batchQuantityField.value)) ||
      Number(batchQuantityField.value) <= 0
    ) {
      batchQuantityField.hasError = true;
      batchQuantityField.errorMsg = 'Batch Quantity is required';
      setBatchSerialState({ ...updatedState });
      return;
    }
    // allow save btn click
    updatedState.saveBtnDisabled = false;
    const batchNumber: InputTag<string> = {
      key: '',
      hidden: false,
      value: batchInputField.value,
      type: FORM_ELEMENTS.INPUT,
      hasError: false,
      isMandatory: true
    };

    const manufacturedDate: InputTag<string> = {
      key: '',
      hidden: false,
      value: Utility.formatDate(new Date(), DATE_FORMAT.DD_MM_YYYY),
      type: FORM_ELEMENTS.DATE,
      hasError: false,
      isMandatory: true
    };
    const nextYearDate = new Date();
    nextYearDate.setDate(nextYearDate.getDay() + 365);
    const expiryDate: InputTag<string> = {
      key: '',
      hidden: false,
      value: Utility.formatDate(nextYearDate, DATE_FORMAT.DD_MM_YYYY),
      type: FORM_ELEMENTS.DATE,
      hasError: false,
      isMandatory: true
    };

    const batchQuantity: InputTag<string> = {
      key: '',
      hidden: false,
      value: batchQuantityField.value,
      type: FORM_ELEMENTS.INPUT,
      hasError: false,
      isMandatory: true
    };

    const newlyAddedBatch: BatchRow = {
      batchNumber: batchNumber,
      manufacturedDate: manufacturedDate,
      expiryDate: expiryDate,
      qtyInBatch: batchQuantity,
      availableQtyInBatch: { ...batchQuantity, isDisabled: true },
      isExisting: false
    };

    batchList.splice(0, 0, newlyAddedBatch);
    checkDuplicateBatch(updatedState, true);
    updatedState.batch.batchInputField.value = '';
    updatedState.batch.batchQuantityField.value = '';
    batchInputField.value = '';
    batchQuantityField.value = '';
    setBatchSerialState({ ...updatedState });
    // setTimeout(() => {
    //   setBatchSerialState({ ...updatedState });
    // }, 1000);
  };

  const checkDuplicateSerial = (updatedState: BatchSerialTrackingState) => {
    updatedState.serial.isValid = true;
    const existingSerialNumbers = deepClone(updatedState.serial.serialList);
    const receivedSerialNumbers: SerialRow[] = [];
    existingSerialNumbers.forEach((serial) => {
      let isDuplicate = false;
      receivedSerialNumbers.forEach((receivedSerial) => {
        if (serial.inputField.value === receivedSerial.inputField.value) {
          isDuplicate = true;
          receivedSerial.inputField.hasError = true;
          receivedSerial.inputField.errorMsg = 'Serial already exists';
          // serial form validation fails
          updatedState.serial.isValid = false;
        }
      });
      if (isDuplicate) {
        serial.inputField.hasError = true;
        serial.inputField.errorMsg = 'Serial already exists';
      } else {
        serial.inputField.hasError = false;
      }
      receivedSerialNumbers.push(serial);
    });
    updatedState.serial.serialList = receivedSerialNumbers;
  };

  const checkDuplicateBatch = (
    updatedState: BatchSerialTrackingState,
    checkOnAddBatch?: boolean
  ) => {
    const existingBatches = deepClone(updatedState.batch.batchList);
    let totalQtyInAllBatches = 0;
    let totalQtyInNewBatches = 0;
    const receivedBatches: BatchRow[] = [];
    existingBatches.forEach((batch) => {
      let isDuplicate = false;
      receivedBatches.forEach((receivedBatch) => {
        if (
          batch.batchNumber.value === receivedBatch.batchNumber.value &&
          !batch.isExisting
        ) {
          isDuplicate = true;
          receivedBatch.batchNumber.hasError = true;
          receivedBatch.batchNumber.errorMsg = 'Batch already exists';
        }
        if (
          batch.batchNumber.value === receivedBatch.batchNumber.value &&
          !receivedBatch.isExisting
        ) {
          isDuplicate = true;
          receivedBatch.batchNumber.hasError = true;
          receivedBatch.batchNumber.errorMsg = 'Batch already exists';
        }
      });
      if (isDuplicate) {
        batch.batchNumber.hasError = true;
        batch.batchNumber.errorMsg = 'Batch already exists';
      } else {
        batch.batchNumber.hasError = false;
      }
      if (!batch.qtyInBatch.hasError && !batch.isExisting) {
        totalQtyInNewBatches += Number(batch.qtyInBatch.value);
      }
      if (!batch.qtyInBatch.hasError) {
        totalQtyInAllBatches += Number(batch.qtyInBatch.value);
      }
      receivedBatches.push(batch);
    });
    updatedState.batch.batchList = receivedBatches;
    updatedState.batch.toalQuantityInBatches = totalQtyInAllBatches;
    updatedState.batch.unassignedQuantity =
      totalAssignedOpeningQuantity.current - totalQtyInNewBatches;
  };

  const removeSerial = (index: number) => {
    const updatedState = deepClone(batchSerialState);
    updatedState.serial.serialList.splice(index, 1);
    updatedState.serial.unassignedQuantity += 1;
    checkDuplicateSerial(updatedState);
    setBatchSerialState({ ...updatedState });
  };

  const removeBatch = (index: number) => {
    const updatedState = deepClone(batchSerialState);
    updatedState.batch.batchList.splice(index, 1);
    checkDuplicateBatch(updatedState);
    setBatchSerialState({ ...updatedState });
  };

  const formFieldUpdated = (
    value: string,
    fieldName: BATCH_SERIAL_TRACKING_FIELD_NAMES
  ) => {
    const updatedState = deepClone(batchSerialState);
    switch (fieldName) {
      case BATCH_SERIAL_TRACKING_FIELD_NAMES.SERIAL_NUMBER:
        updatedState.serial.serialInputField.value = value;
        updatedState.serial.serialInputField.hasError = false;
        break;
      case BATCH_SERIAL_TRACKING_FIELD_NAMES.BATCH_NUMBER:
        updatedState.batch.batchInputField.value = value;
        updatedState.batch.batchInputField.hasError = false;
        break;
      case BATCH_SERIAL_TRACKING_FIELD_NAMES.BATCH_QUANTITY:
        updatedState.batch.batchQuantityField.value = value;
        updatedState.batch.batchQuantityField.hasError = false;
        if (isNaN(Number(value))) {
          updatedState.batch.batchQuantityField.hasError = true;
          updatedState.batch.batchQuantityField.errorMsg = 'Invalid Quantity';
        }
        break;

      default:
        break;
    }
    setBatchSerialState({ ...updatedState });
  };

  const inlineFormFieldUpdated = (
    value: string,
    index: number,
    fieldType: BATCH_SERIAL_TRACKING_FIELD_NAMES
  ) => {
    const updatedState = deepClone(batchSerialState);
    switch (fieldType) {
      case BATCH_SERIAL_TRACKING_FIELD_NAMES.SERIAL_NUMBER:
        updatedState.serial.serialList[index].inputField.value = value;
        updatedState.serial.serialList[index].inputField.hasError = false;
        checkDuplicateSerial(updatedState);
        break;
      case BATCH_SERIAL_TRACKING_FIELD_NAMES.BATCH_NUMBER:
        updatedState.batch.batchList[index].batchNumber.value = value;
        updatedState.batch.batchList[index].batchNumber.hasError = false;
        checkDuplicateBatch(updatedState);
        break;
      case BATCH_SERIAL_TRACKING_FIELD_NAMES.BATCH_QUANTITY:
        updatedState.batch.batchList[index].qtyInBatch.value = value;
        updatedState.batch.batchList[index].qtyInBatch.hasError = false;
        if (isNaN(Number(value)) || Number(value) <= 0) {
          updatedState.batch.batchList[index].qtyInBatch.value = 0;
          updatedState.batch.batchList[index].qtyInBatch.hasError = true;
          updatedState.batch.batchList[index].qtyInBatch.errorMsg =
            'Invalid Quantity';
        } else {
          checkDuplicateBatch(updatedState);
        }
        break;
      default:
        break;
    }
    setBatchSerialState({ ...updatedState });
  };

  const inlineFormFieldBlur = (
    index: number,
    fieldType: BATCH_SERIAL_TRACKING_FIELD_NAMES
  ) => {
    const updatedState = deepClone(batchSerialState);
    switch (fieldType) {
      case BATCH_SERIAL_TRACKING_FIELD_NAMES.BATCH_QUANTITY:
        const currentValue: any =
          updatedState.batch.batchList[index].qtyInBatch.value;
        updatedState.batch.batchList[index].qtyInBatch.value = isNaN(
          currentValue
        )
          ? 0
          : Utility.roundingOff(currentValue, QTY_ROUNDOFF_PRECISION);
        updatedState.batch.batchList[index].availableQtyInBatch.value =
          updatedState.batch.batchList[index].qtyInBatch.value;

        break;
      default:
        break;
    }
    setBatchSerialState({ ...updatedState });
  };

  const calendarDateChanged = (
    date: Date,
    index: number,
    fieldName: BATCH_SERIAL_TRACKING_FIELD_NAMES
  ) => {
    const updatedState = deepClone(batchSerialState);
    switch (fieldName) {
      case BATCH_SERIAL_TRACKING_FIELD_NAMES.EXPIRY_DATE:
        updatedState.batch.batchList[index].expiryDate.value =
          Utility.formatDate(date, DATE_FORMAT.DD_MM_YYYY);
        checkDateValidity(updatedState, index);
        break;
      case BATCH_SERIAL_TRACKING_FIELD_NAMES.MANUFACTURED_DATE:
        updatedState.batch.batchList[index].manufacturedDate.value =
          Utility.formatDate(date, DATE_FORMAT.DD_MM_YYYY);
        checkDateValidity(updatedState, index);
        break;
      default:
        break;
    }
    setBatchSerialState({ ...updatedState });
    setTimeout(() => {
      setBatchSerialState({ ...updatedState });
    }, 500);
  };

  const checkDateValidity = (
    updatedState: BatchSerialTrackingState,
    index: number
  ) => {
    updatedState.batch.batchList[index].expiryDate.hasError = false;
    const expiryDateStr =
      updatedState.batch.batchList[index].expiryDate.value.toString();
    const manufacturedDateStr =
      updatedState.batch.batchList[index].manufacturedDate.value.toString();
    const expiryDate = DateFormatService.getDateFromStr(
      expiryDateStr,
      BOOKS_DATE_FORMAT['DD-MM-YYYY']
    );
    const manufacturedDate = DateFormatService.getDateFromStr(
      manufacturedDateStr,
      BOOKS_DATE_FORMAT['DD-MM-YYYY']
    );
    const result = DateFormatService.compareDates(manufacturedDate, expiryDate);
    if (result === DATE_COMPARISON.LEFT_DATE_IS_AFTER_RIGHT_DATE) {
      updatedState.batch.batchList[index].expiryDate.hasError = true;
      updatedState.batch.batchList[index].expiryDate.errorMsg =
        'Invalid expiry date';
    }
  };

  const checkBatchsValidation = () => {
    let isValid = true;
    batchSerialState.batch.batchList.forEach((batch) => {
      if (
        batch.batchNumber.hasError ||
        batch.qtyInBatch.hasError ||
        batch.expiryDate.hasError
      ) {
        isValid = false;
      }
    });
    return isValid;
  };

  const formValidation = () => {
    const updatedState = deepClone(batchSerialState);
    let formHasError: boolean = false;

    if (trackingType === TRACKING_TYPE.SERIAL) {
      if (updatedState.serial.serialList.length === 0) {
        formHasError = true;
        showAlert('Error', 'Please add atleast one serial');
      } else if (updatedState.serial.unassignedQuantity > 0) {
        formHasError = true;
        showAlert(
          'Error',
          'You are not allowed to assign serial number less than the receiving quantity.',
          [
            {
              title: 'Ok',
              className: 'bg-button text-white',
              onClick: () => {}
            }
          ]
        );
      } else if (updatedState.serial.unassignedQuantity < 0) {
        formHasError = true;
        showAlert(
          'Error',
          'You are not allowed to assign serial number more than the opening quantity.',
          [
            {
              title: 'Ok',
              className: 'bg-button text-white',
              onClick: () => {}
            }
          ]
        );
      } else if (!updatedState.serial.isValid) {
        formHasError = true;
        showAlert(
          'Error',
          'Serial Form has invalid data. Please check the form and try again.'
        );
      } else if (updatedState.serial.noNewSerials) {
        formHasError = true;
        showAlert('Error', 'You cannot assign new serial numbers.');
      }
    } else if (trackingType === TRACKING_TYPE.BATCH) {
      let qtyToAssign = batchSerialState.batch.unassignedQuantity;

      const allBatchsValid = checkBatchsValidation();
      if (updatedState.batch.batchList.length === 0) {
        formHasError = true;
        showAlert('Error', 'Please add atleast one batch');
      } else if (
        Utility.roundingOff(qtyToAssign, tenantDetails.decimalScale) > 0 ||
        Math.sign(
          Utility.roundingOff(qtyToAssign, tenantDetails.decimalScale)
        ) === -1
      ) {
        formHasError = true;
        showAlert(
          'Error',
          'You are not allowed to create new batches with quantity less than unassigned quantity.'
        );
      } else if (
        Utility.roundingOff(
          updatedState.batch.unassignedQuantity,
          tenantDetails.decimalScale
        ) < 0
      ) {
        formHasError = true;
        showAlert(
          'Error',

          'You are not allowed to create new batches with quantity more than opening quantity.'
        );
      } else if (!allBatchsValid) {
        formHasError = true;
        showAlert(
          'Error',

          'Batch Form has invalid data. Please check the form and try again.'
        );
      }
    } else if (updatedState.saveBtnDisabled) {
      formHasError = true;
      showAlert('Error', 'Please add new batch/serial');
    }

    if (formHasError) {
      setBatchSerialState({ ...updatedState });
      return false;
    }
    return true;
  };

  const createAdvancedTracking = () => {
    const isValid = formValidation();
    if (!isValid) {
      return;
    }

    const productPayload = deepClone(props.productDetails);
    const updatedState = deepClone(batchSerialState);

    let existingTrackingData: any[] = [];
    if (props.advancedTrackingMetaData) {
      existingTrackingData = props.advancedTrackingMetaData.map((item) =>
        (item.serialBatchNumber ?? '').trim()
      );
    }

    if (trackingType === TRACKING_TYPE.BATCH) {
      // updatedState.batch.batchList = updatedState.batch.batchList.filter(
      //   (batch) => {
      //     let batchAlreadlyExists: boolean = false;
      //     existingTrackingData.forEach((trackingData) => {
      //       if (batch.batchNumber.value.toString().trim() === trackingData) {
      //         batchAlreadlyExists = true;
      //       }
      //     });
      //     return !batchAlreadlyExists;
      //   }
      // );

      const batchTrackingMetaDataList: BatchTrackedMetaData[] =
        updatedState.batch.batchList.map((batch) => {
          let batchTrackingMetaData: BatchTrackedMetaData = {
            serialBatchNumber: batch.batchNumber.value.toString(),
            batchSize: Number(batch.qtyInBatch.value),
            expiryDate: batch.expiryDate.value.toString(),
            manufacturingDate: batch.manufacturedDate.value.toString()
          };
          if (batch.isExisting) {
            batchTrackingMetaData = { ...batchTrackingMetaData, id: batch.id };
          }
          return batchTrackingMetaData;
        });

      productPayload['advancedTrackingMetaData'] = batchTrackingMetaDataList;
    } else if (trackingType === TRACKING_TYPE.SERIAL) {
      updatedState.serial.serialList = updatedState.serial.serialList.filter(
        (serial) => {
          let findSerialNumberInExisting = existingTrackingData?.find(
            (existingSerialNumber: string) =>
              String(serial.inputField.value ?? '').trim() ===
              existingSerialNumber
          );
          // if serial is not present in Existing then add it
          return Utility.isEmpty(findSerialNumberInExisting);
        }
      );

      const serialTrackingMetaDataList: SerialTrackedMetaData[] =
        updatedState.serial.serialList.map((serial) => {
          const serialTrackingMetaData: SerialTrackedMetaData = {
            serialBatchNumber: serial.inputField.value.toString(),
            batchSize: 1
          };
          return serialTrackingMetaData;
        });
      productPayload['advancedTrackingMetaData'] = serialTrackingMetaDataList;
    }

    // disable save button
    props.passingInteraction({
      type: POPUP_CALLBACKS_TYPE.API_CALL_IN_PROGRESS
    });

    ProductService.updateProduct(productPayload)
      .then((res) => {
        showAlert('Success', 'Advanced Tracking added successfully');
        props.passingInteraction({
          type: POPUP_CALLBACKS_TYPE.CLOSE_BATCH_SERIAL_TRACKING
        });
        dispatch(fetchProducts());
      })
      .catch((error) => {
        showAlert('Error', 'Advanced Tracking Failed.');
        props.passingInteraction({
          type: POPUP_CALLBACKS_TYPE.API_CALL_STOP_PROGRESS
        });
      });
  };

  if (trackingType === TRACKING_TYPE.SERIAL) {
    return (
      <div className="column p-h-m p-v-m parent-width">
        <div className="column parent-width">
          <div className="parent-width row flex-wrap justify-content-between align-items-center">
            <DKLabel
              text={`Opening Quantity: ${batchSerialState.serial.openingQuantity}`}
              className=" fw-s"
            />
            <DKLabel
              text={`Unassigned Quantity: ${batchSerialState.serial.unassignedQuantity}`}
              className=" fw-s"
            />
          </div>
          <div className="parent-width row justify-content-between align-items-center mt-m">
            <div style={{ width: '80%' }}>
              <DKInput
                title=""
                required={batchSerialState.serial.serialInputField.isMandatory}
                value={batchSerialState.serial.serialInputField.value}
                onChange={(value: string) =>
                  formFieldUpdated(
                    value,
                    BATCH_SERIAL_TRACKING_FIELD_NAMES.SERIAL_NUMBER
                  )
                }
                direction={INPUT_VIEW_DIRECTION.VERTICAL}
                canValidate={batchSerialState.serial.serialInputField.hasError}
                validator={(value: string) => {
                  return !batchSerialState.serial.serialInputField.hasError;
                }}
                errorMessage={batchSerialState.serial.serialInputField.errorMsg}
              />
            </div>
            <DKButton
              title={'Assign'}
              className={'bg-app text-white fs-xm'}
              onClick={() => addNewSerial()}
            />
          </div>
          <div className="parent-width mt-l">
            <DKLabel
              text="Use comma separator for multiple entries (e.g. Serial1,Serial2)"
              className="fw-m fs-s"
            />
          </div>
          <DKLine className="parent-width mt-m bg-gray4" />
          <div className="parent-width row justify-content-between align-items-center mt-m">
            <DKLabel text="Serial Number" className="fw-m fs-m" />
            <DKLabel
              text={`Acquired Cost (${batchSerialState.serial.currencyCode})`}
              className="fw-m fs-m"
            />
          </div>
          <DKLine className="parent-width mt-m bg-gray4" />

          {batchSerialState.serial.serialList.map((serial, index) => {
            return (
              <div className="parent-width mt-m" key={index}>
                <div className="parent-width row justify-content-between align-items-center">
                  <div style={{ width: '60%' }}>
                    <DKInput
                      title=""
                      required={serial.inputField.isMandatory}
                      canValidate={serial.inputField.hasError}
                      value={serial.inputField.value}
                      onChange={(value: string) =>
                        inlineFormFieldUpdated(
                          value,
                          index,
                          BATCH_SERIAL_TRACKING_FIELD_NAMES.SERIAL_NUMBER
                        )
                      }
                      direction={INPUT_VIEW_DIRECTION.VERTICAL}
                      validator={(value: string) => {
                        return !serial.inputField.hasError;
                      }}
                      errorMessage={serial.inputField.errorMsg}
                      readOnly={serial.inputField.isDisabled}
                    />
                  </div>
                  <div className="flex align-items-center">
                    <DKLabel
                      text={`${batchSerialState.serial.currencyCode} ${serial.amount}`}
                      className="fw-m fs-m"
                    />
                    {!serial.inputField.isDisabled && (
                      <DKIcon
                        src={DKIcons.ic_delete}
                        className="ic-s cursor-pointer ml-r"
                        onClick={() => removeSerial(index)}
                      />
                    )}
                  </div>
                </div>
                <DKLine className="parent-width mt-xl bg-gray4" />
              </div>
            );
          })}
        </div>
      </div>
    );
  } else {
    let qtyToAssign = calculateQuantityToAssign();
    qtyToAssign = qtyToAssign > 0 ? qtyToAssign : 0;
    return (
      <div className="column p-h-m parent-width">
        <div className="column parent-width">
          <div className="parent-width row justify-content-end align-items-center">
            {/* <div className="column parent-width ">
              <DKLabel text={`Opening Quantity`} className="fw-s" />
              <DKLabel
                text={`${batchSerialState.batch.openingQuantity}`}
                className="fw-s"
              />
            </div> */}
            <div className="column parent-width align-items-end">
              <DKLabel text={`Unassigned Quantity`} className="fw-s" />
              <DKLabel
                text={`${Utility.roundingOff(
                  Number(batchSerialState.batch.unassignedQuantity),
                  tenantDetails.decimalScale
                )}`}
                className="fw-s"
              />
            </div>
            {/* <div className="column parent-width align-items-end">
              <DKLabel text={`Total qty. in Batch`} className="ml-m fw-s" />
              <DKLabel
                text={`${Utility.roundingOff(
                  batchSerialState.batch.toalQuantityInBatches,
                  tenantDetails.decimalScale
                )}`}
                className="ml-m fw-s"
              />
            </div> */}
          </div>
          <DKLine className="mt-m" />
          <div className="row justify-content-between align-items-end mt-m gap-2">
            <DKInput
              title="Batch Number"
              required={batchSerialState.batch.batchInputField.isMandatory}
              value={batchSerialState.batch.batchInputField.value}
              onChange={(value: string) =>
                formFieldUpdated(
                  value,
                  BATCH_SERIAL_TRACKING_FIELD_NAMES.BATCH_NUMBER
                )
              }
              direction={INPUT_VIEW_DIRECTION.VERTICAL}
              canValidate={batchSerialState.batch.batchInputField.hasError}
              validator={(value: string) => {
                return !batchSerialState.batch.batchInputField.hasError;
              }}
              errorMessage={batchSerialState.batch.batchInputField.errorMsg}
            />
            <DKInput
              type={INPUT_TYPE.NUMBER}
              title="Batch Size"
              required={batchSerialState.batch.batchQuantityField.isMandatory}
              value={batchSerialState.batch.batchQuantityField.value}
              onChange={(value: string) =>
                formFieldUpdated(
                  value,
                  BATCH_SERIAL_TRACKING_FIELD_NAMES.BATCH_QUANTITY
                )
              }
              direction={INPUT_VIEW_DIRECTION.VERTICAL}
              canValidate={batchSerialState.batch.batchQuantityField.hasError}
              validator={(value: string) => {
                return !batchSerialState.batch.batchQuantityField.hasError;
              }}
              errorMessage={batchSerialState.batch.batchQuantityField.errorMsg}
            />
            <DKButton
              title={'Add Batch'}
              className={'bg-app text-white fs-xm'}
              onClick={() => addNewBatch()}
            />
          </div>
          <div className="parent-width mt-l">
            <DKLabel
              text="Add Batch number, one at a time"
              className="fw-m fs-s"
            />
          </div>
          <DKLine className="parent-width mt-m bg-gray4" />
          <div className="parent-width row align-items-center mt-m">
            <div style={{ width: '25%' }}>
              <DKLabel text="Batch Number" className="fw-m fs-m" />
            </div>
            <div style={{ width: '25%' }} className="ml-r">
              <DKLabel text="Manufactured Date" className="fw-m fs-m" />
            </div>
            <div style={{ width: '20%' }} className="ml-r">
              <DKLabel text="Expiry Date" className="fw-m fs-m" />
            </div>
            <div style={{ width: '20%' }} className="ml-r">
              <DKLabel text="Batch Size" className="fw-m fs-m" />
            </div>
            <div style={{ width: '20%' }} className="ml-r">
              <DKLabel text="Available Qty in Batch" className="fw-m fs-m" />
            </div>
          </div>
          <DKLine className="parent-width mt-m bg-gray4" />

          {batchSerialState.batch.batchList.map((batch, index) => {
            return (
              <div className="parent-width mt-m" key={index}>
                <div className="parent-width row align-items-center">
                  <div style={{ width: '25%' }}>
                    <DKInput
                      title=""
                      required={batch.batchNumber.isMandatory}
                      canValidate={batch.batchNumber.hasError}
                      value={batch.batchNumber.value}
                      onChange={(value: string) =>
                        inlineFormFieldUpdated(
                          value,
                          index,
                          BATCH_SERIAL_TRACKING_FIELD_NAMES.BATCH_NUMBER
                        )
                      }
                      direction={INPUT_VIEW_DIRECTION.VERTICAL}
                      validator={(value: string) => {
                        return !batch.batchNumber.hasError;
                      }}
                      errorMessage={batch.batchNumber.errorMsg}
                      readOnly={batch.qtyInBatch.isDisabled}
                    />
                  </div>
                  <div style={{ width: '25%' }} className="ml-r">
                    <DKInput
                      title=""
                      value={
                        batch?.manufacturedDate?.value
                          ? DateFormatService.getDateFromStr(
                              batch?.manufacturedDate?.value?.toString(),
                              BOOKS_DATE_FORMAT['DD-MM-YYYY']
                            )
                          : null
                      }
                      type={INPUT_TYPE.DATE}
                      onChange={(value: Date) =>
                        calendarDateChanged(
                          value,
                          index,
                          BATCH_SERIAL_TRACKING_FIELD_NAMES.MANUFACTURED_DATE
                        )
                      }
                      direction={INPUT_VIEW_DIRECTION.VERTICAL}
                      required={batch.manufacturedDate.isMandatory}
                      canValidate={batch.batchNumber.hasError}
                      dateFormat={convertBooksDateFormatToUILibraryFormat(
                        tenantDetails.dateFormat
                      )}
                      validator={(value: string) => {
                        return !batch.manufacturedDate.hasError;
                      }}
                      errorMessage={batch.manufacturedDate.errorMsg}
                      readOnly={false}
                    />
                  </div>
                  <div style={{ width: '20%' }} className="ml-r">
                    <DKInput
                      title=""
                      value={
                        batch?.expiryDate?.value
                          ? DateFormatService.getDateFromStr(
                              batch?.expiryDate?.value?.toString(),
                              BOOKS_DATE_FORMAT['DD-MM-YYYY']
                            )
                          : null
                      }
                      onChange={(value: Date) =>
                        calendarDateChanged(
                          value,
                          index,
                          BATCH_SERIAL_TRACKING_FIELD_NAMES.EXPIRY_DATE
                        )
                      }
                      type={INPUT_TYPE.DATE}
                      direction={INPUT_VIEW_DIRECTION.VERTICAL}
                      required={batch.expiryDate.isMandatory}
                      canValidate={batch.expiryDate.hasError}
                      dateFormat={convertBooksDateFormatToUILibraryFormat(
                        tenantDetails.dateFormat
                      )}
                      validator={(value: string) => {
                        return !batch.expiryDate.hasError;
                      }}
                      errorMessage={batch.expiryDate.errorMsg}
                      readOnly={false}
                    />
                  </div>
                  <div
                    className="flex align-items-center ml-r"
                    style={{ width: '20%' }}
                  >
                    <div style={{ width: '85%' }}>
                      <DKInput
                        title=""
                        required={batch.qtyInBatch.isMandatory}
                        canValidate={batch.qtyInBatch.hasError}
                        value={batch.qtyInBatch.value}
                        onChange={(value: string) =>
                          inlineFormFieldUpdated(
                            value,
                            index,
                            BATCH_SERIAL_TRACKING_FIELD_NAMES.BATCH_QUANTITY
                          )
                        }
                        onBlur={() =>
                          inlineFormFieldBlur(
                            index,
                            BATCH_SERIAL_TRACKING_FIELD_NAMES.BATCH_QUANTITY
                          )
                        }
                        direction={INPUT_VIEW_DIRECTION.VERTICAL}
                        validator={(value: string) => {
                          return !batch.qtyInBatch.hasError;
                        }}
                        errorMessage={batch.qtyInBatch.errorMsg}
                        readOnly={batch.qtyInBatch.isDisabled}
                      />
                    </div>
                  </div>
                  <div
                    className="flex align-items-center ml-r"
                    style={{ width: '20%' }}
                  >
                    <div style={{ width: '85%' }}>
                      <DKInput
                        title=""
                        required={batch.availableQtyInBatch?.isMandatory}
                        canValidate={batch.availableQtyInBatch?.hasError}
                        value={batch.availableQtyInBatch.value}
                        onChange={(value: string) =>
                          inlineFormFieldUpdated(
                            value,
                            index,
                            BATCH_SERIAL_TRACKING_FIELD_NAMES.BATCH_QUANTITY
                          )
                        }
                        onBlur={() =>
                          inlineFormFieldBlur(
                            index,
                            BATCH_SERIAL_TRACKING_FIELD_NAMES.BATCH_QUANTITY
                          )
                        }
                        direction={INPUT_VIEW_DIRECTION.VERTICAL}
                        validator={(value: string) => {
                          return !batch.availableQtyInBatch.hasError;
                        }}
                        errorMessage={batch.availableQtyInBatch.errorMsg}
                        readOnly={batch.availableQtyInBatch.isDisabled}
                      />
                    </div>
                    {!batch.qtyInBatch.isDisabled && (
                      <DKIcon
                        src={DKIcons.ic_delete}
                        className="ic-s cursor-pointer ml-m"
                        onClick={() => removeBatch(index)}
                      />
                    )}
                  </div>
                </div>
                <DKLine className="parent-width mt-xl bg-gray4" />
              </div>
            );
          })}
        </div>
      </div>
    );
  }
};
