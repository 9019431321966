import { FC, useContext, useEffect, useState } from 'react';
import { DKLabel, DKIcon, DKIcons } from 'deskera-ui-library';
import TCSSelection from '../../../../../SharedComponents/DocumentForm/TCSSelection';
import { DocumentConfigUtility } from '../../../../../Utility/DocumentConfigUtility';
import { DOCUMENT_MODE, TAX_SYSTEM } from '../../../../../Constants/Constant';
import {
  DOCUMENT_KEYS,
  IDocSummaryAmount
} from '../../../Utilities/DocConstants';
import { t } from 'i18next';
import { useAppDispatch, useAppSelector } from '../../../../../Redux/Hooks';
import {
  selectDocumentFormDataByKeys,
  updateMultipleKeysInDocument
} from '../../../../../Redux/Slices/DocumentSlice';
import { CommonDraftPropsContext } from '../../../Utilities/DocContext';
import { CallBackPayloadType } from '../../../../../Models/Interfaces';
import AddTCSRate from '../../../../Settings/TCSSetup/AddTCSRate';
import { activeTenantInfo } from '../../../../../Redux/Slices/AuthSlice';
import { getTotalOfItems } from '../DocSummaryHelper';
import { selectActiveTCSRates } from '../../../../../Redux/Slices/TCSSetupSlice';

interface IDocTCSProps {
  summaryAmounts: IDocSummaryAmount;
}

const DocTCS: FC<IDocTCSProps> = ({ summaryAmounts }) => {
  const dispatch = useAppDispatch();
  const { draftId, documentMode } = useContext(CommonDraftPropsContext);
  const { country, tcsEnabled } = useAppSelector(activeTenantInfo);
  const [currency, tcsRateId, items] = useAppSelector(
    selectDocumentFormDataByKeys(draftId, [
      DOCUMENT_KEYS.CURRENCY,
      DOCUMENT_KEYS.TCS_RATE_ID,
      DOCUMENT_KEYS.ITEMS
    ])
  );
  const [showTCSPicker, setShowTCSPicker] = useState<boolean>(false);
  const [tcsDetails, setTCSDetails] = useState<any>(null);
  const [showAddTCSPopup, setShowTCSPopup] = useState(false);
  const tcsRatesData = useAppSelector(selectActiveTCSRates);

  const isTCSEnabled = country === TAX_SYSTEM.INDIA_GST && tcsEnabled;

  useEffect(() => {
    if (tcsRateId) {
      setTCSDetails(
        tcsRatesData.find((rate: any) => rate.id === tcsRateId) || {}
      );
    }
  }, [tcsRatesData]);

  if (!isTCSEnabled) return null;

  const handleOnTCSChange = (tcsData: any) => {
    const total = getTotalOfItems({ items });
    try {
      const tcsAmount = (Number(total) * Number(tcsData?.ratePercentage)) / 100;
      dispatch(
        updateMultipleKeysInDocument({
          draftId,
          keysToUpdate: {
            [DOCUMENT_KEYS.TCS_RATE_ID]: tcsData?.id,
            [DOCUMENT_KEYS.TCS_PERCENTAGE]: tcsData?.ratePercentage,
            [DOCUMENT_KEYS.TCS_AMOUNT]: tcsAmount
          }
        })
      );
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <div
        className="row width-auto mb-l justify-content-between position-relative"
        style={{ width: '100%' }}
      >
        {(!showTCSPicker || documentMode === DOCUMENT_MODE.VIEW) && (
          <div
            className="row width-auto cursor-pointer"
            onClick={() => setShowTCSPicker(true)}
          >
            <DKLabel
              text={t(`DOCUMENT.DOCUMENT_SUMMARY_VIEW.TCS`)}
              className={'fw-m ml-r'}
            />
            {tcsDetails && (
              <DKLabel text={`(${tcsDetails.taxName})`} className={`ml-r`} />
            )}
            <DKIcon
              src={DKIcons.ic_arrow_down}
              className="ic-xs ml-r opacity-5"
            />
          </div>
        )}
        {showTCSPicker && !(documentMode === DOCUMENT_MODE.VIEW) && (
          <TCSSelection
            rateId={tcsRateId}
            onClose={() => setShowTCSPicker(false)}
            onNewAdd={() => setShowTCSPopup(true)}
            onSelect={(index: number, rate: any) => {
              handleOnTCSChange(rate);
              setTCSDetails(rate);
              setShowTCSPicker(false);
            }}
            className="position-absolute z-index-3"
          />
        )}

        <div className="flex flex-col items-end w-24 number-hide-arrows">
          <DKLabel
            text={DocumentConfigUtility.amountFormatter(
              summaryAmounts.tcsAmount,
              currency
            )}
            className={'ml-r'}
          />
        </div>
      </div>
      {showAddTCSPopup && (
        <AddTCSRate
          isEdit={false}
          passingInteraction={(callback: CallBackPayloadType) => {
            handleOnTCSChange(callback?.data);
            setTCSDetails(callback?.data);
            setShowTCSPopup(false);
          }}
          onCancel={() => {
            setShowTCSPopup(false);
          }}
        />
      )}
    </>
  );
};

export default DocTCS;
