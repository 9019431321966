import React, { Component } from "react";
import "../../index.css";
import "../../CommonStyles/FontStyle.css";
import { COLOR_LIGHTGRAY, FONT_SIZE, LINE_ITEM_HEIGHT, EditorInfoType } from '../../Constants/Constants';
import DKCustomFieldSection from "../../DKUILibrary/DKCustomFieldSection";
import DKEInvoiceSection, { eInvoiceSectionPosition } from "../../DKUILibrary/DKEInvoiceSection";
import DKSignatureSection from "../../DKUILibrary/DKSignatureSection";
import { AddressType } from "../../Helper/AddressParser";
import ComponentManager from "../../Manager/ComponentManager";
import DocumentManager from '../../Manager/DocumentManager';
import { getLocalisedText } from '../../Translate/LanguageManager';
import { HTMLTag } from "../../Utilities/HTMLTag";
import Utility, { getFontSizeClass, getCapitalized, getPageHeight, getVW, getIsFulfillment, getIsGoodsReceipt, getIsJournalEntry } from "../../Utilities/Utility";
import TopSectionAboveTableUtility from "../../Utilities/TopSectionAboveTableUtility";
import DKComponentListTable from "../../DKUILibrary/DKComponentListTable";
import DKApprovalSection from "../../DKUILibrary/DKApprovalSection";

export default class DocumentTemplate34 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: this.props.data,
        };
    }

    componentWillReceiveProps(nextProp) {
        this.setState({
            data: nextProp.data,
        });
    }

    //////////////////////////////////////////////////
    //////////////////////////////////////////////////
    //////////////////////////////////////////////////

    render() {
        return (
            <div className="DocumentHolder" style={{ minHeight: getPageHeight(this.state.data.printScale)}}>
                <DKEInvoiceSection
                    data={this.state.data}
                    position={eInvoiceSectionPosition.top}
                />
                {this.getHeaderSection()}
                <DKCustomFieldSection data={this.state.data} onCustomFieldClick={this.props.onCustomFieldClick} customFieldPosition={'top'} />
                {ComponentManager.addVerticalSpace(Utility.convertToPrintScale(20, this.state.data.printScale))}
                {
                    TopSectionAboveTableUtility.getIsTopSectionAboveTableIsVisible() &&
                    <>
                        <div style={{ ...TopSectionAboveTableUtility.getTopSectionWithTableFontStyleProp(undefined, this.state.data.printScale) }}
                            dangerouslySetInnerHTML={{ __html: TopSectionAboveTableUtility.getHtmlString(this.state.data) }} />
                        {ComponentManager.addVerticalSpace(5)}
                    </>
                }
                {this.getProductTable()}
                {Utility.getIsShowAccountProductTable(this.state.data) && ComponentManager.addVerticalSpace(10)}
                {this.getEditableDocumentTable()}
                {Utility.getIsShowExchangeRate(this.state.data) && ComponentManager.addVerticalSpace(20)}
                {Utility.getIsShowExchangeRate(this.state.data) && this.getExchangeRateTable(this.getTableStyle(), this.state.data)}
                {Utility.getIsShowExchangeRate(this.state.data) && ComponentManager.addVerticalSpace(20)}
                <DKCustomFieldSection data={this.state.data} onCustomFieldClick={this.props.onCustomFieldClick} customFieldPosition={'bottom'} />
                {Utility.getIsIncludesBomProductTable() && <DKComponentListTable data={this.state.data} />}
                {(getIsFulfillment(this.state.data.type) || getIsGoodsReceipt(this.state.data.type)) &&
                    <>
                        <div className='parent-width' style={{ marginTop: getVW(10) }}>
                            <DKSignatureSection data={this.state.data} onSignatureSectionClicked={this.props.tableClicked} />
                        </div>
                    </>
                }
                {this.getNotesAndSignatureSection()}
                {Utility.getIsShowApprovalSection() && <DKApprovalSection data={this.state.data} style={this.getTableStyle()} />}
                <DKEInvoiceSection
                    data={this.state.data}
                    position={eInvoiceSectionPosition.bottom}
                />
            </div>
        );
    }


    /////// Header Section ///////////
    getHeaderSection() {
        return (
            <div className="RowDiv" style={{justifyContent: 'space-between', alignItems: 'flex-start'}}>
                <div className="ColumnDiv" style={{width: '60%'}}>
                    {this.state.data.showCompanyName && this.getCompanyName()}
                    {this.state.data.showFrom && this.getCompanyAddress()}
                </div>
                <div className="ColumnDiv" style={{alignItems: 'flex-end', width: '50%'}}>
                    {ComponentManager.getLogoVisibility(this.state.data.showCompanyLogo, this.state.data.isReadOnlyMode, this.state.data.logo) && ComponentManager.getLogo(this.state.data.logo, Utility.convertToPrintScale(80, this.state.data.printScale), {position: 'relative'})}
                    {ComponentManager.addVerticalSpace(5)}
                    {ComponentManager.addVerticalSpace(Utility.getImageSize(EditorInfoType.logo))}
                    {this.getDocumentDetailsSection()}
                    {this.state.data.showClientBillToAddress && ComponentManager.addVerticalSpace(20)}
                    {this.state.data.showClientBillToAddress && this.getBillingAddress()}
                    {Utility.getIsShowPlaceOfSupply(this.state.data) && this.getPlaceOfSupplyInfoSection()}
                    {(Utility.getIsShowSourceOfSupply(this.state.data) || Utility.getIsShowDestinationOfSupply(this.state.data)) && this.getSourceOfSupply()}
                </div>
            </div>
        )
    }

    getDocumentDetailsSection() {
        var documentDetailList = Utility.getDocumentDetailsList(this.state.data)
        return documentDetailList.map(element => {
            var value = ''
            if (element.key !== undefined) {
                value = this.state.data[element.key]
            }
            else if (element.customFieldValue !== undefined) {
                value = element.customFieldValue
            }
            return <>
                <div className="RowDiv" style={{ justifyContent: 'space-between', alignItems: 'flex-start' }}>
                    <text className={"docFontStyleBold" + getFontSizeClass()}
                        style={{
                            width: '50%',
                            textAlign: 'right',
                            marginRight: getVW(10),
                            ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular }, this.state.data.printScale),
                        }}>
                        {element.label}
                    </text>
                    <text className={"docFontStyle" + getFontSizeClass()}
                        style={{
                            width: '50%',
                            textAlign: 'right',
                            ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular }, this.state.data.printScale),
                        }}>
                        {value}
                    </text>
                </div>
                {ComponentManager.addVerticalSpace(5)}
            </>;
        });
    }

    getBillingAddress() {
        return <>
            <div className="RowDiv" style={{ alignItems: 'flex-start' }}>
                <text className={"docFontStyleBold" + getFontSizeClass()}
                    style={{
                        width: '50%',
                        textAlign: 'right',
                        marginRight: getVW(10),
                        ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular, ownerType: Utility.getOwnerType(this.state.data, AddressType.billTo) }, this.state.data.printScale),
                    }}>
                    {getLocalisedText(this.state.data.billToTitle).toUpperCase()}
                </text>
                <div className={"docFontStyle" + getFontSizeClass()}
                    style={{
                        width: '50%',
                        textAlign: 'right',
                        ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular, ownerType: Utility.getOwnerType(this.state.data, AddressType.billTo) }, this.state.data.printScale),
                    }}
                    dangerouslySetInnerHTML={{ __html: getLocalisedText(Utility.getClientBillToAddress(this.state.data)) }}
                >
                </div>
            </div>
            {ComponentManager.addVerticalSpace(20)}
        </>;
    }

    getCompanyAddress() {
        return <div className="RowDiv parent-width"
                style={{
                    justifyContent: 'space-between',
                    alignItems: 'flex-start'
                }}>
            <div className={"RowDiv docFontStyle" + getFontSizeClass()}
                style={{
                    display: 'block',
                    textAlign: Utility.getIsArabicLang() ? 'right' : 'left',
                    overflow: 'hidden',
                    color: "#ABABAB",
                    paddingBottom: getVW(10),
                    flexDirection: Utility.getIsArabicLang() ? 'row-reverse' : 'row',
                    ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.medium, ownerType: Utility.getOwnerType(this.state.data, AddressType.from) }, this.state.data.printScale),
                }}
                dangerouslySetInnerHTML={{__html: getLocalisedText(Utility.getCompanyAddress(this.state.data))}}>
            </div>
        </div>;
    }

    getCompanyName() {
        return <>
            <div className={"RowDiv docFontStyleBold" + getFontSizeClass()}
                style={{
                    marginTop: getVW(15),
                    color: this.state.data.themeColor,
                    flexDirection: Utility.getIsArabicLang() ? 'row-reverse' : 'row',
                    ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.xLarge }, this.state.data.printScale),
                }}>
                    {getLocalisedText(Utility.getCompanyName(this.state.data))}
            </div>
            {ComponentManager.addVerticalSpace(10)}
        </>;
    }

    /////// Header Section ///////////
    getFooterSection() {
        return (
            <div className="ColumnDiv" style={{
                marginTop: getVW(0),
                // width: '57%',
                border: 'solid',
                borderColor: COLOR_LIGHTGRAY,
                borderWidth: getVW(Utility.convertToPrintScale(2, this.state.data.printScale)),
            }}>
                {!getIsJournalEntry(this.state.data.type) &&
                    <>
                    <div
                        className="RowDiv"
                        style=
                        {{
                            backgroundColor: this.state.data.themeColor,
                            height: getVW(`${Utility.convertToPrintScale(Number(LINE_ITEM_HEIGHT.replace('vw', '')), this.state.data.printScale)}vw`, true),
                            justifyContent: Utility.getIsArabicLang() ? 'flex-end' : 'flex-start',
                            alignItems: 'center'
                        }}>
                        <text className={"docFontStyleBold" + getFontSizeClass()}
                            style={{
                                paddingLeft: getVW(Utility.convertToPrintScale(10, this.state.data.printScale)),
                                color: 'white',
                                paddingRight: Utility.getIsArabicLang() ? getVW(Utility.convertToPrintScale(10, this.state.data.printScale)) : 0,
                                ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular }, this.state.data.printScale),
                            }}>
                            {getCapitalized(getLocalisedText('other_comments'))}
                        </text>
                    </div>
                    </>
                }
                <div id={HTMLTag.TERMS_NOTE_PREFIX}  className="ColumnDiv" style={{width: '100%', justifyContent: 'center', alignItems: "flex-start"  }}>
                    <div className='parent-width'
                        style={{
                            padding: getVW(10),
                            paddingLeft: Utility.getIsArabicLang() ? 0 : getVW(Utility.convertToPrintScale(10, this.state.data.printScale)),
                            paddingRight: Utility.getIsArabicLang() ? 0 : getVW(Utility.convertToPrintScale(10, this.state.data.printScale)),
                            width: Utility.getIsArabicLang() ? '98%': '100%'
                        }}>
                        {(this.state.data.showTermsAndCondition && this.state.data.termsAndCondition) &&
                            ComponentManager.getTermsAndConditionOrNotesSection(
                            Utility.getTermsAndConditionTitle(getLocalisedText('terms_&_condition')),
                            this.state.data.termsAndCondition,
                            "100%",
                            undefined,
                            undefined,
                            undefined,
                            getVW(Utility.convertToPrintScale(6, this.state.data.printScale)),
                            this.state.data.printScale,
                            Utility.getTnCOrNotesFontSettingsByType(EditorInfoType.termsAndCondition)
                        )}
                        {((this.state.data.showTermsAndCondition && this.state.data.termsAndCondition) && (this.state.data.showNotes && this.state.data.notes)) && ComponentManager.addVerticalSpace(Utility.convertToPrintScale(20, this.state.data.printScale))}
                        {(this.state.data.showNotes && this.state.data.notes) &&
                            ComponentManager.getTermsAndConditionOrNotesSection(
                            Utility.getNotesTitle(getIsJournalEntry(this.state.data.type) ? getLocalisedText('memo') : getLocalisedText('notes')),
                            this.state.data.notes,
                            "100%",
                            undefined,
                            undefined,
                            undefined,
                            getVW(Utility.convertToPrintScale(6, this.state.data.printScale)),
                            this.state.data.printScale,
                            Utility.getTnCOrNotesFontSettingsByType(EditorInfoType.notes)
                        )}
                    </div>
                </div>
                {/*<div*/}
                {/*    className="RowDiv"*/}
                {/*    style=*/}
                {/*        {{*/}
                {/*            width: '100%',*/}
                {/*            paddingBottom : getVW(20),*/}
                {/*            paddingTop: getVW(8)*/}
                {/*        }}>*/}
                {/*    <text className={"docFontStyle" + getFontSizeClass()} style={{ paddingLeft: getVW(10) }}>*/}
                {/*        {this.state.data.notes}*/}
                {/*    </text>*/}
                {/*</div>*/}
            </div>
        )
    }

    getEditableDocumentTable() {
        return ComponentManager.getDKDocumentTable(
            this.props.tableColumn,
            this.getTableStyle(),
            this.props.data,
            this.props.tableClicked,
            this.props.stockTableClicked,
            this.props.onUpdateDataCallback,
            this.props.paymentTableClicked,
            this.props.additionalTableCallback,
        )
    }

    getProductTable() {
        if(Utility.getIsShowAccountProductTable(this.state.data)) {
            return ComponentManager.getDKProductTable(
                Utility.getTableColumnForAccountProduct(),
                this.getTableStyle(),
                this.props.data,
                this.props.tableClicked,
                this.props.stockTableClicked,
                this.props.onUpdateDataCallback,
                this.props.paymentTableClicked,
                this.props.additionalTableCallback,
            )
        }
    }

    getNotesAndSignatureSection() {
        return <div className="RowDiv" style={{alignItems: 'flex-start', marginTop: getVW(Utility.convertToPrintScale(10, this.state.data.printScale))}}>
            <div style={{width: '100%'}}>
                {((this.state.data.showTermsAndCondition && this.state.data.termsAndCondition) || (this.state.data.showNotes && this.state.data.notes)) && this.getFooterSection()}
            </div>
            <div id={HTMLTag.SIGNATURE} className="RowDiv" style={{width: '67%'}}>
                {this.state.data.showSignature && ComponentManager.getSignature(this.state.data.signature, this.state.data.printScale)}
            </div>
        </div>;
    }

    getTableStyle() {
        let style = DocumentManager.getTableDefaultStyle();
        style.headerBackgroundColor = this.state.data.themeColor;
        style.headerTextColor = "#ffffff";
        style.headerBorderColor = this.state.data.themeColor;
        style.itemBorderColor = 'transparent';
        style.footerBackgroundColor = '#000000CC';
        style.footerTextColor = "white";
        style.footerBorderColor = "transparent";
        style.subTotalBorderColor = 'transparent';
        return style;
    }
    getPlaceOfSupplyInfoSection() {
        return (
            <div
                className="RowDiv"
                style={{
                    alignItems: "flex-start",
                    justifyContent: 'space-between',
                    backgroundColor: "white",
                }}
            >
                {this.getPlaceOfSupply()}

            </div>
        )
    }
    getPlaceOfSupply() {

        return <>
        <div className="RowDiv" style={{ alignItems: 'flex-start' }}>
            <text className={"docFontStyleBold" + getFontSizeClass()}
                style={{
                    width: '50%',
                    textAlign: 'right',
                    marginRight: getVW(10),
                    ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular, ownerType: true }, this.state.data.printScale),
                }}>
                {getLocalisedText('place_of_supply').toUpperCase()}
            </text>
            <div className={"docFontStyle" + getFontSizeClass()}
                style={{
                    width: '50%',
                    textAlign: 'right',
                    ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular, ownerType: true }, this.state.data.printScale),
                }}
                dangerouslySetInnerHTML={{ __html: this.state.data.placeOfSupply }}
            >
            </div>
        </div>
        {ComponentManager.addVerticalSpace(20)}
    </>;

    }

    getSourceOfSupply() {
        return <>
            {Utility.getIsShowSourceOfSupply(this.state.data) &&
                <>
                <div className="RowDiv" style={{ alignItems: 'flex-start' }}>
                    <text className={"docFontStyleBold" + getFontSizeClass()}
                        style={{
                            width: '50%',
                            textAlign: 'right',
                            marginRight: getVW(10),
                            ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular, ownerType: true }, this.state.data.printScale),
                        }}>
                        {getLocalisedText('source_of_supply').toUpperCase()}
                    </text>
                    <div className={"docFontStyle" + getFontSizeClass()}
                        style={{
                            width: '50%',
                            textAlign: 'right',
                            ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular, ownerType: true }, this.state.data.printScale),
                        }}
                        dangerouslySetInnerHTML={{ __html: this.state.data.sourceOfSupply }}
                    >
                    </div>
                </div>
                {ComponentManager.addVerticalSpace(20)}
                </>
            }
            {Utility.getIsShowDestinationOfSupply(this.state.data) &&
                <>
                <div className="RowDiv" style={{ alignItems: 'flex-start' }}>
                    <text className={"docFontStyleBold" + getFontSizeClass()}
                        style={{
                            width: '50%',
                            textAlign: 'right',
                            marginRight: getVW(10),
                            ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular, ownerType: true }, this.state.data.printScale),
                        }}>
                        {getLocalisedText('destination_of_supply').toUpperCase()}
                    </text>
                    <div className={"docFontStyle" + getFontSizeClass()}
                        style={{
                            width: '50%',
                            textAlign: 'right',
                            ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular, ownerType: true }, this.state.data.printScale),
                        }}
                        dangerouslySetInnerHTML={{ __html: this.state.data.destinationOfSupply }}
                    >
                    </div>
                </div>
                {ComponentManager.addVerticalSpace(20)}
                </>
            }
        </>;
    }

    getExchangeRateTable(tableStyle, data) {
        return (
            <div id={HTMLTag.EXCHANGE_RATE} className="RowDiv" style={{ justifyContent: "flex-end" }}>
                <div style={{ width: "45%" }}>
                    {ComponentManager.getExchangeRateTable(tableStyle, data)}
                </div>
            </div>
        );
    }
}
