import React, { Component } from "react";
import "../../index.css";
import "../../CommonStyles/FontStyle.css";
import { COLOR_LIGHTGRAY, EditorInfoType, FONT_SIZE, LINE_ITEM_HEIGHT_2 } from '../../Constants/Constants';
import DKCustomFieldSection from "../../DKUILibrary/DKCustomFieldSection";
import DKEInvoiceSection, { eInvoiceSectionPosition } from "../../DKUILibrary/DKEInvoiceSection";
import DKSignatureSection from "../../DKUILibrary/DKSignatureSection";
import { AddressType } from "../../Helper/AddressParser";
import ComponentManager from "../../Manager/ComponentManager";
import DocumentManager from '../../Manager/DocumentManager';
import { getLocalisedText } from '../../Translate/LanguageManager';
import { HTMLTag } from "../../Utilities/HTMLTag";
import Utility, { getFontSizeClass, getIsFulfillment, getIsGoodsReceipt, getIsJournalEntry, getPageHeight, getVW } from "../../Utilities/Utility";
import TopSectionAboveTableUtility from "../../Utilities/TopSectionAboveTableUtility";
import DKComponentListTable from "../../DKUILibrary/DKComponentListTable";
import DKApprovalSection from "../../DKUILibrary/DKApprovalSection";

export default class DocumentTemplate20 extends Component {
    arabicHeaderWidth = '55%'

    constructor(props) {
        super(props);
        this.state = {
            data: this.props.data,
        };
    }

    componentWillReceiveProps(nextProp) {
        this.setState({
            data: nextProp.data,
        });
    }

    getDocumentTitleLabel() {
        return (
            <div className="RowDiv" style={{ justifyContent: 'flex-end', top: 0 }}>
                <text
                    style={{
                        fontWeight: "bold",
                        textAlign: "right",
                        color: this.state.data.themeColor + 'BF',
                        ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.xLarge }, this.state.data.printScale)
                    }}
                >
                    {getLocalisedText(this.state.data.documentType)}
                </text>
            </div>
        )
    }

    getRowValueSection(title, value, titleColor = "black") {
        return (
            <div
                className="RowDiv"
                style={{
                    justifyContent: "space-evenly",
                    flex: 1,
                }}
            >
                <text className={"RowReverseDiv docFontStyleBold" + getFontSizeClass()}
                    style={{
                        color: titleColor,
                        width: '50%',
                        justifyContent: 'end',
                        paddingRight: getVW(Utility.convertToPrintScale(20, this.state.data.printScale)),
                        ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular }, this.state.data.printScale)
                    }}>
                    {title.toUpperCase()}
                </text>
                <div
                    className="RowDiv"
                    style={{
                        border: 'solid',
                        borderColor: 'lightgray',
                        borderWidth: getVW(1),
                        width: '50%'
                    }}>
                    <text className={"RowDiv docFontStyle" + getFontSizeClass()}
                        style={{
                            height: getVW(`${Utility.convertToPrintScale(Number(LINE_ITEM_HEIGHT_2.replace('vw', '')), this.state.data.printScale)}vw`, true),
                            justifyContent: Utility.getIsArabicLang() ? 'flex-end' : 'center',
                            paddingRight: Utility.getIsArabicLang() ? getVW(Utility.convertToPrintScale(10, this.state.data.printScale)) : 0,
                            paddingLeft: Utility.getIsArabicLang() ? 0 : getVW(Utility.convertToPrintScale(10, this.state.data.printScale)),
                            ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular }, this.state.data.printScale)
                        }}>
                        {value}
                    </text>
                </div>
            </div>
        );
    }

    getInvoiceDetailsView() {
        return (
            <div className="RowDiv"
                style={{
                    justifyContent: 'flex-end',
                    width: '100%'
                }}>
                <div className="ColumnDiv"
                    style={{
                        justifyContent: 'space-between',
                        width: '80%',
                        height: '100%'
                    }}>
                    {this.getDocumentDetailsSection()}
                </div>
            </div>
        )
    }

    getDocumentDetailsSection() {
        var documentDetailList = Utility.getDocumentDetailsList(this.state.data)
        return documentDetailList.map(element => {
            var value = ''
            if (element.key !== undefined) {
                value = this.state.data[element.key]
            }
            else if (element.customFieldValue !== undefined) {
                value = element.customFieldValue
            }
            return this.getRowValueSection(
                element.label,
                value,
                this.state.data.themeColor
            )
        });
    }

    getCompanyNameLabel() {
        return (
            <div className="RowDiv" style={{
                flexDirection: Utility.getIsArabicLang() ? 'row-reverse' : 'row',
                width: Utility.getIsArabicLang() ? this.arabicHeaderWidth : '100%',
            }}>
                <text className={"docFontStyleBold" + getFontSizeClass()}
                    style={{
                        color: this.state.data.themeColor,
                        ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.xLarge }, this.state.data.printScale)
                    }}
                >
                    {getLocalisedText(Utility.getCompanyName(this.state.data))}
                </text>
            </div>
        )
    }

    getCompanyInformationView() {
        return (
            <div className="ColumnDiv">
                <div className={"docFontStyle" + getFontSizeClass()}
                    style={{
                        whiteSpace: 'pre-wrap',
                        ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.medium, ownerType: Utility.getOwnerType(this.state.data, AddressType.from) }, this.state.data.printScale)
                    }}
                    dangerouslySetInnerHTML={{__html:getLocalisedText(Utility.getCompanyAddress(this.state.data))}}>
                </div>
            </div>
        )
    }

    addVerticalSpace(height) {
        return (
            <div style={{ height: height }} />
        )
    }

    getAddressAndDocDetailView(title, value, addressType) {
        return (
            <div className="ColumnDiv" style={{ width: '30%' }}>

                <div className="RowDiv"
                    style={{
                        backgroundColor: this.state.data.themeColor,
                        height: getVW(`${Utility.convertToPrintScale(1.4, this.state.data.printScale)}vw`, true),
                        width: '100%',
                        alignItems: 'center',
                        justifyContent: Utility.getIsArabicLang() ? 'flex-end' : 'flex-start',
                        paddingLeft: Utility.getIsArabicLang() ? 0 : getVW(5),
                        paddingRight: Utility.getIsArabicLang() ? getVW(5) : 0,
                    }}>
                    <text className={"docFontStyleBold" + getFontSizeClass()}
                        style={{
                            color: 'white',
                            ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular}, this.state.data.printScale)
                        }}>
                        {getLocalisedText(title).toUpperCase()}
                    </text>
                </div>

                <div className={"RowDiv docFontStyle" + getFontSizeClass()}
                    style={{
                        display: 'block',
                        marginTop: getVW(6),
                        justifyContent: Utility.getIsArabicLang() ? 'flex-end' : 'flex-start',
                        paddingLeft: Utility.getIsArabicLang() ? 0 : getVW(5),
                        paddingRight: Utility.getIsArabicLang() ? getVW(5) : 0,
                        ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular, ownerType: Utility.getOwnerType(this.state.data, addressType) }, this.state.data.printScale)
                    }}
                    dangerouslySetInnerHTML={{__html:getLocalisedText(value)}}>
                </div>

            </div>
        )
    }

    addFooterView() {
        return (
            <div className="ColumnDiv"
                style={{
                    bottom: getVW(5),
                    width: '100%',
                    border: 'solid',
                    borderColor: COLOR_LIGHTGRAY,
                    borderWidth: getVW(Utility.convertToPrintScale(2, this.state.data.printScale)),
                }}>
                    {
                        !getIsJournalEntry(this.state.data.type) &&
                        <>
                        <div
                            className="RowDiv"
                            style=
                            {{
                                backgroundColor: 'gray',
                                height: getVW(`${Utility.convertToPrintScale(1.4, this.state.data.printScale)}vw`, true),
                                justifyContent: Utility.getIsArabicLang() ? 'flex-end' : 'flex-start',
                                alignItems: 'center'
                            }}>
                            <text className={"docFontStyleBold" + getFontSizeClass()}
                                style={{
                                    paddingLeft: getVW(Utility.convertToPrintScale(10, this.state.data.printScale)),
                                    color: 'white',
                                    paddingRight: Utility.getIsArabicLang() ? getVW(Utility.convertToPrintScale(5, this.state.data.printScale)) : 0,
                                    ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular }, this.state.data.printScale)
                                }}>
                                {getLocalisedText('other_comments')}
                            </text>
                        </div>
                        </>
                    }

                <div id={HTMLTag.TERMS_NOTE_PREFIX}  style={{ padding: getVW(Utility.convertToPrintScale(8, this.state.data.printScale)), alignSelf: Utility.getIsArabicLang() ? 'flex-end' : 'auto'}}>
                    {(this.state.data.showTermsAndCondition && this.state.data.termsAndCondition) && ComponentManager.getTermsAndConditionOrNotesSection(
                        Utility.getTermsAndConditionTitle(getLocalisedText('terms_&_condition')),
                        this.state.data.termsAndCondition,
                        "100%",
                        'black',
                        undefined,
                        undefined,
                        getVW(Utility.convertToPrintScale(6, this.state.data.printScale)),
                        this.state.data.printScale,
                        Utility.getTnCOrNotesFontSettingsByType(EditorInfoType.termsAndCondition)
                    )}
                    {((this.state.data.showTermsAndCondition && this.state.data.termsAndCondition) && (this.state.data.showNotes && this.state.data.notes)) && ComponentManager.addVerticalSpace(10)}
                    {(this.state.data.showNotes && this.state.data.notes) && ComponentManager.getTermsAndConditionOrNotesSection(
                        Utility.getNotesTitle(getIsJournalEntry(this.state.data.type) ? getLocalisedText('memo') : getLocalisedText('notes')),
                        this.state.data.notes,
                        "100%",
                        'black',
                        undefined,
                        undefined,
                        getVW(Utility.convertToPrintScale(6, this.state.data.printScale)),
                        this.state.data.printScale,
                        Utility.getTnCOrNotesFontSettingsByType(EditorInfoType.notes)
                    )}
                </div>
            </div>

        )
    }

    addHeaderSection() {
        return (
            <div className="RowDiv" style={{ width: '100%', alignItems: 'flex-start' }}>
                <div className="ColumnDiv" style={{ width: '100%' }}>
                    {this.state.data.showCompanyName && this.getCompanyNameLabel()}
                    {this.state.data.showCompanyName && ComponentManager.addVerticalSpace(10)}
                    {this.state.data.showFrom && this.getCompanyInformationView()}
                </div>
                <div className="ColumnDiv" style={{ width: '100%' }}>
                    {this.getDocumentTitleLabel()}
                    {ComponentManager.addVerticalSpace(10)}
                    {ComponentManager.addVerticalSpace(10)}
                    {this.getInvoiceDetailsView()}
                </div>

            </div>
        )
    }

    addBottomCommentSection() {
        return (
            <div className="ColumnDiv" style={{
                marginTop: getVW(-140),
                width: '57%',
                border: 'solid',
                borderColor: COLOR_LIGHTGRAY,
                borderWidth: getVW(2),
            }}>
                <div
                    className="RowDiv"
                    style=
                    {{
                        backgroundColor: this.state.data.themeColor,
                        height: getVW('1.4vw', true),
                        justifyContent: 'flex-start',
                        alignItems: 'center'
                    }}>
                    <text className={"docFontStyleBold" + getFontSizeClass()}
                        style={{
                            paddingLeft: getVW(10),
                            color: 'white',
                            ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular }, this.state.data.printScale)
                        }}>
                        OTHER COMMENTS
                    </text>
                </div>

                <div
                    className="RowDiv"
                    style=
                    {{
                        width: '100%',
                        paddingBottom: getVW(20),
                        paddingTop: getVW(8)
                    }}>
                    <text className={"docFontStyle" + getFontSizeClass()} style={{ paddingLeft: getVW(10) }}>
                        {this.state.data.notes}
                    </text>
                </div>
            </div>
        )
    }

    addAddresses() {
        return (
            <div className="RowDiv" style={{ justifyContent: 'flex-start', alignItems: 'flex-start' }}>
                {this.state.data.showClientBillToAddress && this.getAddressAndDocDetailView(this.state.data.billToTitle, Utility.getClientBillToAddress(this.state.data), AddressType.billTo)}
                {(this.state.data.showClientBillToAddress && this.state.data.showClientShipToAddress) && <div style={{ width: getVW(30), height: getVW(10) }}></div>}
                {this.state.data.showClientShipToAddress && this.getAddressAndDocDetailView(this.state.data.shipToTitle, Utility.getClientShipToAddress(this.state.data), AddressType.shipTo)}

            </div>
        )
    }

    render() {
        return (
            <div className='DocumentHolder ColumnDiv' style={{ justifyContent: 'space-between', minHeight: getPageHeight(this.state.data.printScale) }}>
                <div className='parent-width' style={{ width: '100%', paddingBottom: getVW(30)  }}>
                    <DKEInvoiceSection
                        data={this.state.data}
                        position={eInvoiceSectionPosition.top}
                    />
                    {/* section 0 */}
                    {this.addHeaderSection()}
                    {/* section 1 */}
                    {ComponentManager.addVerticalSpace(30)}
                    {/* section 2 */}
                    {this.addAddresses()}
                    {/* section 3 */}
                    {(Utility.getIsShowSourceOfSupply(this.state.data) || Utility.getIsShowDestinationOfSupply(this.state.data)) && ComponentManager.addVerticalSpace(20)}
                    {(Utility.getIsShowSourceOfSupply(this.state.data) || Utility.getIsShowDestinationOfSupply(this.state.data)) && this.getSourceOfSupplyInfoSection()}
                    {Utility.getIsShowPlaceOfSupply(this.state.data) && ComponentManager.addVerticalSpace(20)}
                    {Utility.getIsShowPlaceOfSupply(this.state.data) && this.getPlaceOfSupplyInfoSection()}
                    {Utility.getIsContainsCustomField('top') && ComponentManager.addVerticalSpace(25)}
                    <DKCustomFieldSection data={this.state.data} onCustomFieldClick={this.props.onCustomFieldClick} style={this.getCustomFieldTableStyle()} customFieldPosition={'top'} />
                    {ComponentManager.addVerticalSpace(25)}
                    {
                        TopSectionAboveTableUtility.getIsTopSectionAboveTableIsVisible() &&
                        <>
                            <div style={{ ...TopSectionAboveTableUtility.getTopSectionWithTableFontStyleProp(undefined, this.state.data.printScale) }}
                                dangerouslySetInnerHTML={{ __html: TopSectionAboveTableUtility.getHtmlString(this.state.data) }} />
                            {ComponentManager.addVerticalSpace(5)}
                        </>
                    }
                    {this.getProductTable()}
                    {Utility.getIsShowAccountProductTable(this.state.data) && ComponentManager.addVerticalSpace(25)}
                    {this.getEditableDocumentTable()}
                    {Utility.getIsShowExchangeRate(this.state.data) && ComponentManager.addVerticalSpace(20)}
                    {Utility.getIsShowExchangeRate(this.state.data) && this.getExchangeRateTable(this.getTableStyle(), this.state.data)}
                    {Utility.getIsContainsCustomField('bottom') && ComponentManager.addVerticalSpace(25)}
                    <DKCustomFieldSection data={this.state.data} onCustomFieldClick={this.props.onCustomFieldClick} style={this.getCustomFieldTableStyle()} customFieldPosition={'bottom'} />
                    {/*{(this.state.data.showNotes && this.state.data.notes) && this.addBottomCommentSection()}*/}
                    {Utility.getIsIncludesBomProductTable() && <DKComponentListTable data={this.state.data} />}
                    <div id={HTMLTag.SIGNATURE}>
                        {ComponentManager.addVerticalSpace(20)}
                        {this.state.data.showSignature && ComponentManager.getSignature(this.state.data.signature, this.state.data.printScale)}
                    </div>
                </div>
                <div className='parent-width' style={{ width: '100%' }}>
                    {(getIsFulfillment(this.state.data.type) || getIsGoodsReceipt(this.state.data.type)) && <DKSignatureSection data={this.state.data} onSignatureSectionClicked={this.props.tableClicked} />}
                    {((this.state.data.showTermsAndCondition && this.state.data.termsAndCondition) || (this.state.data.showNotes && this.state.data.notes)) && this.addFooterView()}
                    {Utility.getIsShowApprovalSection() && <DKApprovalSection data={this.state.data} style={this.getTableStyle()} isThemeColor />}
                </div>
                <DKEInvoiceSection
                    data={this.state.data}
                    position={eInvoiceSectionPosition.bottom}
                />
            </div>
        );
    }

    getEditableDocumentTable() {
        return ComponentManager.getDKDocumentTable(
            this.props.tableColumn,
            this.getTableStyle(),
            this.props.data,
            this.props.tableClicked,
            this.props.stockTableClicked,
            this.props.onUpdateDataCallback,
            this.props.paymentTableClicked,
            this.props.additionalTableCallback,
        )
    }

    getProductTable() {
        if(Utility.getIsShowAccountProductTable(this.state.data)) {
            return ComponentManager.getDKProductTable(
                Utility.getTableColumnForAccountProduct(),
                this.getTableStyle(),
                this.props.data,
                this.props.tableClicked,
                this.props.stockTableClicked,
                this.props.onUpdateDataCallback,
                this.props.paymentTableClicked,
                this.props.additionalTableCallback,
            )
        }
    }

    getTableStyle() {
        let style = DocumentManager.getTableDefaultStyle();
        style.headerBackgroundColor = this.state.data.themeColor;
        style.headerTextColor = "#ffffff";
        style.headerBorderColor = this.state.data.themeColor;
        style.itemBorderColor = "black";
        style.footerBackgroundColor = this.state.data.themeColor + '4d';
        style.footerTextColor = "black";
        style.footerBorderColor = "transparent";
        style.subTotalBorderColor = "transparent";
        return style;
    }

    getCustomFieldTableStyle() {
        let style = {}
        style.columnCount = 3
        style.columnWidths = Utility.getIsArabicLang() ? ['30%', '34%', '36%'] : ['33.75%', '32.25%', '33%']
        style.marginLeft = getVW(5)
        return style;
    }

    getPlaceOfSupplyInfoSection() {
        return (
            <div
                className="RowDiv"
                style={{
                    alignItems: "flex-start",
                    justifyContent: 'space-between',
                    backgroundColor: "white",
                }}
            >
                {this.getPlaceOfSupply()}

            </div>
        )
    }
    getPlaceOfSupply() {
        return <div className='RowDiv' style={{ width: '70%' }}>
            <div className='ColumnDiv' style={{
                width: '40%'
            }}>
                {Utility.getIsShowPlaceOfSupply(this.state.data) && ComponentManager.getDescriptionSection(
                    getLocalisedText('place_of_supply'),
                    this.state.data.placeOfSupply,
                    "100%",
                    'black',
                    false,
                    '#000000',
                    getVW(6),
                    true,
                    this.state.data.printScale
                )}
            </div>

        </div>;

    }


    getSourceOfSupplyInfoSection() {
        return (
            <div
                className="RowDiv"
                style={{
                    alignItems: "flex-start",
                    justifyContent: 'space-between',
                    backgroundColor: "white",
                }}
            >
                {this.getSourceOfSupply()}

            </div>
        )
    }

     getSourceOfSupply() {
        return <div className='RowDiv' style={{ width: '70%' }}>
            <div className='ColumnDiv' style={{
                width: '49%'
            }}>
                {Utility.getIsShowSourceOfSupply(this.state.data) && ComponentManager.getDescriptionSection(
                    getLocalisedText('source_of_supply'),
                    this.state.data.sourceOfSupply,
                    "100%",
                    'black',
                    false,
                    '#000000',
                    getVW(6),
                    true,
                    this.state.data.printScale
                )}
            </div>
            <div className='ColumnDiv' style={{
                width: '40%'
            }}>
                {Utility.getIsShowDestinationOfSupply(this.state.data) && ComponentManager.getDescriptionSection(
                    getLocalisedText('destination_of_supply'),
                    this.state.data.destinationOfSupply,
                    "100%",
                    'black',
                    false,
                    '#000000',
                    getVW(6),
                    true,
                    this.state.data.printScale
                )}
          </div>

        </div>;
    }

    getExchangeRateTable(tableStyle, data) {
        return (
            <div id={HTMLTag.EXCHANGE_RATE} className="RowDiv" style={{ justifyContent: "flex-end" }}>
                <div style={{ width: "45%" }}>
                    {ComponentManager.getExchangeRateTable(tableStyle, data)}                        
                </div>
            </div>
        );
    }
}
