import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { CommonDraftPropsContext } from '../../Utilities/DocContext';
import {
  DKButton,
  DKIcon,
  DKIcons,
  DKLabel,
  DKListPicker2,
  DKTooltipWrapper
} from 'deskera-ui-library';
import Utility from '../../../../Utility/Utility';
import NumberFormatService from '../../../../Services/NumberFormat';
import { useAppDispatch, useAppSelector } from '../../../../Redux/Hooks';
import {
  selectDocumentFormDataByKeys,
  updateMultipleKeysInDocument
} from '../../../../Redux/Slices/DocumentSlice';
import { DOCUMENT_KEYS } from '../../Utilities/DocConstants';
import {
  DOC_TYPE,
  DOCUMENT_MODE,
  SETUP_PAYMENT
} from '../../../../Constants/Constant';
import AppManager from '../../../../Managers/AppManager';
import InvoiceService from '../../../../Services/Invoice';
import { activeTenantInfo } from '../../../../Redux/Slices/AuthSlice';
import isEqual from 'lodash/isEqual';
import { getTenantTaxSystem } from '../../../../SharedComponents/DocumentForm/NewDocumentHelper';
import GetEmailForPayment from '../../../../SharedComponents/DocumentForm/GetEmailForPayment';

const DocBalance = () => {
  const { draftId, documentMode } = useContext(CommonDraftPropsContext);
  const [documentType, dueAmount, contact, currency, paymentInformation] =
    useAppSelector(
      selectDocumentFormDataByKeys(draftId, [
        DOCUMENT_KEYS.DOCUMENT_TYPE,
        DOCUMENT_KEYS.DUE_AMOUNT,
        DOCUMENT_KEYS.CONTACT,
        DOCUMENT_KEYS.CURRENCY,
        DOCUMENT_KEYS.PAYMENT_INFORMATION
      ])
    );

  const dispatch = useAppDispatch();
  const tenantInfo = useAppSelector(activeTenantInfo);
  const [isPaymentListOpen, setIsPaymentListOpen] = useState<boolean>(false);
  const [showEmailPopupForPayment, setShowEmailPopupForPayment] =
    useState<boolean>(false);
  const multiPaymentOptions = useRef<any>();

  const loadMultiPaymentOptions = useCallback(() => {
    if (documentType !== DOC_TYPE.INVOICE) return;

    InvoiceService.fetchPaymentConfiguredOptions(tenantInfo.currency)
      .then((data: any) => {
        if (!isEqual(multiPaymentOptions.current, data)) {
          multiPaymentOptions.current = data;
        }
      })
      .catch((err: any) => {
        console.error('Error loading multipayment options: ', err);
      });
  }, [documentType, tenantInfo.currency]);

  useEffect(() => {
    loadMultiPaymentOptions();
    AppManager.handleWindowFocusListeners(loadMultiPaymentOptions, true);
    return () => {
      AppManager.handleWindowFocusListeners(loadMultiPaymentOptions, false);
    };
  }, [loadMultiPaymentOptions]);

  const getInfoIcon = () => {
    return (
      <DKTooltipWrapper
        content="<p>Receive Payments via Stripe, Wise, Paypal, Veem, Paytm, Razorpay and Opennode. And get paid faster.</p><p class='mt-s'>Your customers can pay you via cards, netbanking, wallets and UPI.</p><p class='mt-s'>All you have to do is add your Stripe, Wise, Paypal, Veem Paytm, Razorpay or Opennode account to start receiving payments.</p>"
        tooltipClassName="bg-deskera-secondary width-auto z-index-10"
        tooltipPositionAbsolute={true}
        tooltipStyle={{ top: 16, left: 'unset', right: 0, minWidth: 250 }}
      >
        <DKIcon
          src={DKIcons.ic_info}
          className="ic-xs opacity-40 p-xs"
          onClick={() => {}}
        />
      </DKTooltipWrapper>
    );
  };

  const getPaymentOptionsPicker = () => {
    const updatedPaymentOptions = SETUP_PAYMENT[getTenantTaxSystem()].map(
      (item: any) => {
        if (
          !Utility.isEmpty(multiPaymentOptions.current) &&
          !Utility.isEmpty(multiPaymentOptions.current?.[item.key])
        ) {
          item.isConnected =
            multiPaymentOptions.current?.[item.key]?.[0]?.connected;
        }

        if (paymentInformation?.connectionType.toLowerCase() === item.key) {
          item.paymentInfo = paymentInformation;
        }

        return item;
      }
    );

    return (
      <DKListPicker2
        title="Payment Options"
        data={updatedPaymentOptions}
        className="position-absolute z-index-3 shadow-m ml-l border-s"
        style={{ right: -3, top: 32, width: 220 }}
        onSelect={(index: any, value: any) => {
          if (value.key === 'notApplicable') {
            dispatch(
              updateMultipleKeysInDocument({
                draftId,
                keysToUpdate: { [DOCUMENT_KEYS.PAYMENT_INFORMATION]: null }
              })
            );
          }

          if (!value.isConnected) {
            return;
          }
          if (
            !Utility.isEmpty(multiPaymentOptions.current) &&
            !Utility.isEmpty(multiPaymentOptions.current?.[value.key])
          ) {
            const po = multiPaymentOptions.current?.[value.key]?.[0];
            if (
              documentMode === DOCUMENT_MODE.EDIT ||
              documentMode === DOCUMENT_MODE.COPY
            ) {
              if (Utility.isEmpty(contact?.emailId) && value.isConnected) {
                setShowEmailPopupForPayment(true);
              }

              if (
                !Utility.isEmpty(contact?.emailId) &&
                value.isConnected &&
                value.key === 'stripe'
              ) {
                setShowEmailPopupForPayment(true);
              }

              if (value.isConnected) {
                dispatch(
                  updateMultipleKeysInDocument({
                    draftId,
                    keysToUpdate: {
                      [DOCUMENT_KEYS.PAYMENT_INFORMATION]: {
                        ...paymentInformation,
                        connectionType: value.key.toUpperCase(),
                        paymentAccountDto: {
                          ...paymentInformation?.paymentAccountDto,
                          modules: po.modules,
                          connected: po.connected,
                          connectionName: po.connectionName,
                          connectionId: po.connectionId
                        }
                      }
                    }
                  })
                );
                setIsPaymentListOpen(!isPaymentListOpen);
              }
            }

            if (documentMode === DOCUMENT_MODE.NEW) {
              if (Utility.isEmpty(contact?.emailId) && value.isConnected) {
                setShowEmailPopupForPayment(true);
              }

              if (
                !Utility.isEmpty(contact?.emailId) &&
                value.isConnected &&
                value.key === 'stripe'
              ) {
                setShowEmailPopupForPayment(true);
              }

              dispatch(
                updateMultipleKeysInDocument({
                  draftId,
                  keysToUpdate: {
                    [DOCUMENT_KEYS.PAYMENT_INFORMATION]: {
                      ...paymentInformation,
                      paymentAccountDto: {
                        ...paymentInformation?.paymentAccountDto,
                        modules: [DOC_TYPE.RECEIVE_PAYMENT],
                        connected: po.connected,
                        connectionName: po.connectionName,
                        connectionId: po.connectionId,
                        key: value.key,
                        logo: ''
                      },
                      paymentLink: '',
                      customerPhone: '',
                      connectionType: value.key.toUpperCase()
                    }
                  }
                })
              );
              setIsPaymentListOpen(!isPaymentListOpen);
            }
          }
        }}
        onClose={() => {
          setIsPaymentListOpen(false);
        }}
        renderer={(index: number, obj: any) => {
          return (
            <div
              className="row justify-content-between p-v-xs"
              onClick={() => {
                if (!obj.isConnected && obj.key !== 'notApplicable') {
                  window.open(obj.link);
                }
              }}
            >
              {obj.key !== 'notApplicable' && (
                <DKIcon src={obj.icon} className="ic-s" />
              )}
              {obj.key === 'notApplicable' && <DKLabel text={obj.label} />}
              {
                <DKLabel
                  className={`${obj.isConnected ? 'text-green' : 'text-gray'}`}
                  text={`${
                    obj.key !== 'notApplicable'
                      ? obj.isConnected
                        ? 'Connected'
                        : 'Connect'
                      : ''
                  }`}
                  onClick={() => {
                    window.open(obj.link);
                  }}
                />
              }
            </div>
          );
        }}
      />
    );
  };

  const getPaymentContainer = () => {
    let buttonTitle = '';
    if (Utility.isEmpty(paymentInformation?.connectionType)) {
      buttonTitle = 'Pay';
    } else {
      buttonTitle = Utility.convertInTitleCase(
        paymentInformation?.connectionType
      );
    }

    if (Utility.isEmpty(contact) || documentMode === DOCUMENT_MODE.VIEW) {
      return null;
    }

    return (
      <div className="column align-items-end position-relative mr-l">
        <div className="row width-auto p-v-s">
          {getInfoIcon()}
          <DKButton
            title={buttonTitle}
            className="p-0 text-bg-button-color fw-m"
            style={{ paddingLeft: 2 }}
            onClick={() => setIsPaymentListOpen(!isPaymentListOpen)}
          />
        </div>
        {isPaymentListOpen && getPaymentOptionsPicker()}
        {showEmailPopupForPayment && (
          <GetEmailForPayment
            contact={contact}
            documentMode={documentMode}
            paymentInformationDetials={paymentInformation}
            onSave={(data: any) => {
              dispatch(
                updateMultipleKeysInDocument({
                  draftId,
                  keysToUpdate: {
                    [DOCUMENT_KEYS.CONTACT]: {
                      ...contact,
                      emailId: data?.email
                    },
                    [DOCUMENT_KEYS.PAYMENT_INFORMATION]: {
                      ...paymentInformation,
                      customerEmail: data?.email,
                      autoCharge: data?.isAutoCharged
                    }
                  }
                })
              );
              setShowEmailPopupForPayment(false);
            }}
            onCancel={() => {
              setShowEmailPopupForPayment(false);
              dispatch(
                updateMultipleKeysInDocument({
                  draftId,
                  keysToUpdate: {
                    [DOCUMENT_KEYS.PAYMENT_INFORMATION]: null
                  }
                })
              );
            }}
          />
        )}
      </div>
    );
  };

  const isSetupPaymentVisible =
    documentType === DOC_TYPE.INVOICE &&
    SETUP_PAYMENT[getTenantTaxSystem()] &&
    SETUP_PAYMENT[getTenantTaxSystem()].length > 0;

  if (documentType !== DOC_TYPE.INVOICE && documentType !== DOC_TYPE.BILL) {
    return null;
  }

  return (
    <div className="row width-auto align-items-end">
      {isSetupPaymentVisible && getPaymentContainer()}

      <div className="column align-items-end">
        <DKLabel
          text={
            documentType === DOC_TYPE.INVOICE
              ? 'Balance Due'
              : 'Balance Payable'
          }
          className="fs-m"
        />
        <DKLabel
          text={`${Utility.getCurrencySymbolFromCode(
            currency
          )} ${NumberFormatService.getNumber(dueAmount)}`}
          className="fw-m fs-xxl"
        />
      </div>
    </div>
  );
};

export default DocBalance;
