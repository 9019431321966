import { useEffect, useRef, useState } from 'react';
import {
  DKButton,
  DKInput,
  DKLabel,
  INPUT_TYPE,
  DKTooltipWrapper,
  DKIcon,
  DKIcons,
  DKCheckMark,
  DKSpinner,
  showAlert
} from 'deskera-ui-library';
import {
  COA_OPTIONS,
  COUNTRY_CODES,
  CUSTOM_NUMBER_INPUT_MODULES,
  INPUT_VIEW_DIRECTION,
  MODULES_NAME,
  POPUP_CALLBACKS_TYPE,
  POPUP_CLICK_TYPE,
  POPUP_TYPE,
  TAX_SYSTEM
} from '../../../Constants/Constant';
import {
  fetchNatureOfIncomeAccounts,
  selectAccountsType,
  selectCurrencyListWithExchangeRate,
  selectNatureOfIncomeAccounts,
  selectTaxes
} from '../../../Redux/Slices/CommonDataSlice';
import { useAppDispatch, useAppSelector } from '../../../Redux/Hooks';
import Utility from '../../../Utility/Utility';
import CurrencyDropdown from '../../../SharedComponents/CurrencyDropdown/CurrencyDropdown';
import { formUtilComponentProductStyle } from '../../../SharedComponents/FormUtil/FormUtil';
import { activeTenantInfo } from '../../../Redux/Slices/AuthSlice';
import PopupWrapper, {
  DynamicPopupWrapper
} from '../../../SharedComponents/PopupWrapper';
import AddNatureIncomePayment from '../../../SharedComponents/FormUtil/AddNatureIncomePayment';
import {
  BtnType,
  CallBackPayloadType,
  PopupClickActionType,
  UpdateCallBacksRefType
} from '../../../Models/Interfaces';
import { CustomFieldsHolder } from '../../../SharedComponents/CustomFieldsHolder/CustomFieldsHolder';
import {
  fetchCoa,
  selectCoaColumnConfig,
  selectCOAColumnConfigTableId
} from '../../../Redux/Slices/CoaSlice';
import { selectedAccounts } from '../../../Redux/Slices/AccountsSlice';
import CoaService from '../../../Services/COA';
import { fetchAccoutnsList } from '../../../Redux/Slices/AccountsSlice';
import ApiConstants from '../../../Constants/ApiConstants';
import CustomNumberFormatInput from '../../../SharedComponents/CustomNumberFormat/CustomNumberFormatInput';
import { CoaConfigUtility } from './CoaConfigUtility';
import {
  customFieldsContainsErrors,
  getTenantTaxSystem
} from '../../../SharedComponents/DocumentForm/NewDocumentHelper';
import { GranularPermissionsHelper } from '../../Settings/GranularPermissions/GranularPermissionsHelper';
import { PERMISSIONS_BY_MODULE } from '../../../Constants/Permission';
import TransactionLog from '../../../SharedComponents/TransactionLogs';
import AccountsService, { AccountAPIConfig } from '../../../Services/Accounts';
import {
  fetchLiability,
  fetchPaymentTerm,
  fetchAsset
} from '../../../Redux/Slices/ContactsSlice';

const itcAdjustmentTypes = [
  {
    value: 'NA',
    key: 'NA'
  },
  {
    value: 'ITC_IS_BLOCKED',
    key: 'ITC as Blocked'
  },
  {
    value: 'ITC_IS_REVERSED',
    key: 'ITC reversal'
  }
];

const refInitialState: UpdateCallBacksRefType = {
  pushDataToParent: { type: POPUP_CALLBACKS_TYPE.NONE },
  storeCallbacksRef: { updateOrder: 'click' }
};

const AddSubTypeView = (props: any) => {
  //states
  const [parentSubtype, setParentSubtype] = useState<any>();
  const [isParentSubtype, setIsParentSubtype] = useState(false);
  const parentSubtypeData = props.parentSubtypeData;
  const [subTypeValue, setSubTypeValue] = useState(props?.currentSubtype?.name);
  const [isSubtypeFormSubmitted, setIsSubtypeFormSubmitted] = useState(false);
  const isFormEdit = !Utility.isEmpty(props?.currentSubtype) ? true : false;
  //effects
  useEffect(() => {
    if (isFormEdit) {
      const parentSubtypeDataCopy = [...parentSubtypeData];
      const parentSubtpeWhileEdit = parentSubtypeDataCopy.find(
        (item: any) => item.id === props?.currentSubtype?.parentId
      );
      if (!Utility.isEmpty(parentSubtpeWhileEdit)) {
        setParentSubtype(parentSubtpeWhileEdit);
        setIsParentSubtype(true);
      }
    }
  }, []);

  //methods
  const showCheckbox = () => {
    let show = false;
    if (!isFormEdit) {
      show = parentSubtypeData?.length > 0;
    } else {
      show = parentSubtypeData?.length > 1;
    }

    return show;
  };

  return (
    <DynamicPopupWrapper>
      <div className="transparent-background">
        <div
          className="popup-window"
          style={{
            maxWidth: 1000,
            width: '90%',
            // height: "85%",
            maxHeight: '95%',
            padding: 0,
            paddingBottom: 0,
            overflow: 'visible'
          }}
        >
          {/* header */}
          <div className="row justify-content-between p-h-r p-v-s bg-gray1">
            <div className="row pop-header-drag-handle">
              <DKLabel
                text={`${!isFormEdit ? 'Add' : 'Update'} sub type`}
                className="fw-m fs-l"
              />
            </div>
            <div className="row width-auto">
              <DKButton
                title={'Cancel'}
                className="bg-white border-m mr-r"
                onClick={() => {
                  props.onCancel();
                }}
              />
              <DKButton
                title={!isFormEdit ? 'Save' : 'Update'}
                className="bg-button text-white"
                onClick={() => {
                  setIsSubtypeFormSubmitted(true);
                  if (!isFormEdit) {
                    // create
                    if (
                      !Utility.isEmpty(subTypeValue) &&
                      subTypeValue?.trim('')?.length > 0
                    ) {
                      const payload = {
                        subTypeValue,
                        isParentSubtype,
                        parentSubtype
                      };
                      props.onSave(payload);
                    }
                  } else {
                    // edit
                    const payload = {
                      subTypeValue,
                      isParentSubtype,
                      parentSubtype
                    };
                    props.onEdit(payload);
                  }
                }}
              />
            </div>
          </div>
          {/* form */}
          <div className="parent-width p-l">
            {/* subtype name */}
            <DKInput
              title="Please enter name of the subtype"
              direction={INPUT_VIEW_DIRECTION.VERTICAL}
              type={INPUT_TYPE.TEXT}
              value={subTypeValue}
              className=""
              onChange={(val: any) => {
                setSubTypeValue(val);
              }}
              canValidate={isSubtypeFormSubmitted}
            />
            {/* checkbox */}
            {showCheckbox() && (
              <div className="row mt-l mb-l">
                <DKCheckMark
                  title="Choose parent subtype"
                  className=""
                  isSelected={isParentSubtype}
                  onClick={() => {
                    setIsParentSubtype(!isParentSubtype);
                    if (isParentSubtype) {
                      setParentSubtype(null);
                    }
                  }}
                  style={{ width: 150 }}
                />
              </div>
            )}
            {/* parent subtype */}
            {isParentSubtype && (
              <DKInput
                type={INPUT_TYPE.DROPDOWN}
                title={'Parent Subtype'}
                direction={INPUT_VIEW_DIRECTION.VERTICAL}
                value={parentSubtype}
                canValidate={isSubtypeFormSubmitted && isParentSubtype}
                formatter={(obj: any) => {
                  return obj?.name;
                }}
                required={true}
                onChange={(obj: any) => {
                  setParentSubtype(obj);
                }}
                className="mb-l"
                dropdownConfig={{
                  title: '',
                  allowSearch: false,
                  searchableKey: '',
                  canEdit: false,
                  canDelete: false,
                  style: { minWidth: 150 },
                  className: 'shadow-m width-auto',
                  searchApiConfig: null,
                  data: isFormEdit
                    ? parentSubtypeData?.filter(
                        (item: any) => item.id !== props?.currentSubtype?.id
                      )
                    : parentSubtypeData,
                  renderer: (index: any, obj: any) => {
                    return <DKLabel text={`${obj?.name}`} />;
                  }
                }}
              />
            )}
          </div>
        </div>
      </div>
    </DynamicPopupWrapper>
  );
};

const AddCoaPopup = (props: any) => {
  //states
  const [loading, setLoading] = useState(false); // for saving or updating
  const [formState, setFormState] = useState<any>();
  const [createListPicker, setCreateListPicker] = useState<boolean>(false);
  const [canValidate, setCanValidate] = useState(false);
  const [showAddSubTypePopup, setShowAddSubTypePopup] =
    useState<boolean>(false);
  const [multiLevelSubTypes, setMultiLevelSubTypes] = useState<any>([]);
  const [parentAccounts, setParentAccounts] = useState([]);
  const [currentSubtypeToEditOrDelete, setCurrentSubtypeToEditOrDelete] =
    useState<any>();
  const [updating, setUpdating] = useState(false); // for calling apis and rendering fields
  const [parentSubtypeData, setParentSubtypeData] = useState([]);
  const [taxOptions, setTaxOptions] = useState<any[]>([]);
  const [subTypeResponseReceived, setSubTypeResponseReceived] = useState(false);
  const [subTypeFound, setSubTypeFound] = useState(false);

  //selectors
  const coaTypes: any[] = useAppSelector(selectAccountsType);
  const selectCoas = useAppSelector(selectedAccounts);
  const taxes = useAppSelector(selectTaxes);
  const allCurrencies = useAppSelector(selectCurrencyListWithExchangeRate);
  const tenantInfo = useAppSelector(activeTenantInfo);
  const nipData = useAppSelector(selectNatureOfIncomeAccounts);
  const accountColumnConfig = useAppSelector(selectCoaColumnConfig);
  const accountConfigTableId = useAppSelector(selectCOAColumnConfigTableId);
  // const multiLevelSubTypes = useAppSelector(selectSubTypes);
  // const multiLevelParentAccounts = useAppSelector(selectParentAccounts);

  const [showTransactionLog, setShowTransactionLog] = useState(false);
  const [transactionData, setTransactionData] = useState<any>();

  const dispatch = useAppDispatch();
  const [accountsData, setAccountData] = useState<any>(
    !Utility.isEmpty(selectCoas?.content) ? selectCoas?.content : []
  );
  //refs
  const addOrderRef = useRef<UpdateCallBacksRefType>(refInitialState);

  //contants
  const currentLiablityVal: any = 'current liabilities';
  let nipOptions: any = [];
  nipData.forEach((data: any) => {
    nipOptions.push(data.natureOfPayment);
  });

  useEffect(() => {
    let updatedFormState = { ...formState };
    let tempTaxOptions = [...taxes];
    if (tenantInfo.country === COUNTRY_CODES.SG) {
      tempTaxOptions = Utility.getCurrentYearTaxes([...taxes]);
    }

    if (!Utility.isEmpty(props.populateFormData)) {
      updatedFormState = { ...updatedFormState, ...props.populateFormData };

      updatedFormState.type = coaTypes?.find(
        (type: any) => type.id === Number(props.populateFormData.accountGroupId)
      );

      updatedFormState.name = props.populateFormData.name;

      updatedFormState.tax = taxes.find(
        (tax: any) => tax.code === props.populateFormData.taxCode
      );

      updatedFormState.currency = props.populateFormData.currency;

      updatedFormState.autoNumberingFormat = {
        text: props?.populateFormData?.accountCode
      };

      updatedFormState.isCreditCardAccount =
        props.populateFormData.isCreditCard;

      updatedFormState.description = props.populateFormData.description;

      updatedFormState.isTdsApplicable = props.populateFormData.isTdsApplicable;

      updatedFormState.nip = props.populateFormData.natureOfIncomePayment;

      if (Utility.isEmpty(props.populateFormData?.accountSubGroupName)) {
        updatedFormState.isSubAccount = props.populateFormData?.isSubAccount;
      }
      if (!Utility.isEmpty(props.populateFormData?.itcAdjustment)) {
        const itcAdjustment = itcAdjustmentTypes.filter(
          (type: any) => type?.value === props.populateFormData?.itcAdjustment
        );
        updatedFormState.itcAdjustment = itcAdjustment?.[0];
      }
      let glForItcBlock = props.populateFormData?.glAccountCode;
      if (Utility.isNotEmpty(accountsData)) {
        updatedFormState.glItcBlockAccount = accountsData.filter(
          (data: any) => data?.code === glForItcBlock
        )?.[0];
      }
    }
    if (Utility.isEmpty(props.populateFormData)) {
      updatedFormState.currency = tenantInfo.currency;
      updatedFormState.type = coaTypes[0]; // select a type by default
      if (getTenantTaxSystem() === TAX_SYSTEM.INDIA_GST) {
        updatedFormState.itcAdjustment = {
          value: 'NA',
          key: 'NA'
        };
      }
    }
    setTaxOptions(tempTaxOptions);
    setFormState({ ...updatedFormState });
  }, []);

  useEffect(() => {
    if (
      Utility.isEmpty(formState?.type?.code) ||
      Utility.isEmpty(props.populateFormData)
    ) {
      return;
    }
    //fetch subtype api
    setUpdating(true);
    CoaService.getMultiLevelSubTypes(formState?.type?.code)
      .then(
        (res: any) => {
          setSubTypeResponseReceived(true);
          setUpdating(false);
          setMultiLevelSubTypes(res);
        },
        (err) => {
          setUpdating(false);
          console.log(err);
        }
      )
      .catch((err: any) => {
        setUpdating(false);
        console.log(err);
      });
  }, [formState?.type]);

  useEffect(() => {
    CoaConfigUtility.setStructuredSubtypeArray([]);
  }, [formState?.subType]);

  useEffect(() => {
    if (
      !Utility.isEmpty(props.populateFormData) &&
      multiLevelSubTypes?.length > 0
    ) {
      const updatedFormState = { ...formState };
      updatedFormState.subType = multiLevelSubTypes?.find(
        (type: any) =>
          type.id === Number(props.populateFormData.accountSubGroup)
      );
      updatedFormState.isSubAccount = props.populateFormData.isSubAccount;

      setSubTypeFound(true);
      setFormState({ ...updatedFormState });
    }
  }, [multiLevelSubTypes]);

  useEffect(() => {
    if (formState?.isSubAccount && subTypeResponseReceived && !subTypeFound) {
      if (Utility.isEmpty(formState?.type?.code)) {
        return;
      }
      let codes = {};

      if (!Utility.isEmpty(formState?.type?.code)) {
        codes = {
          accountGroupCode: formState?.type?.code
        };
      }
      if (!Utility.isEmpty(formState?.subType?.code)) {
        codes = {
          accountGroupCode: formState?.type?.code,
          accountSubGroupCode: formState?.subType?.code
        };
      }

      getParentTypesApiCall(codes);
    }

    if (formState?.isSubAccount && subTypeResponseReceived && subTypeFound) {
      if (Utility.isEmpty(formState?.type?.code)) {
        return;
      }
      let codes = {};

      if (!Utility.isEmpty(formState?.type?.code)) {
        codes = {
          accountGroupCode: formState?.type?.code
        };
      }
      if (!Utility.isEmpty(formState?.subType?.code)) {
        codes = {
          accountGroupCode: formState?.type?.code,
          accountSubGroupCode: formState?.subType?.code
        };
      }

      getParentTypesApiCall(codes);
    }

    //this api call is only for non edit mode
    if (formState?.isSubAccount && Utility.isEmpty(props.populateFormData)) {
      if (Utility.isEmpty(formState?.type?.code)) {
        return;
      }
      let codes = {};

      if (!Utility.isEmpty(formState?.type?.code)) {
        codes = {
          accountGroupCode: formState?.type?.code
        };
      }
      if (!Utility.isEmpty(formState?.subType?.code)) {
        codes = {
          accountGroupCode: formState?.type?.code,
          accountSubGroupCode: formState?.subType?.code
        };
      }

      getParentTypesApiCall(codes);
      return;
    }
  }, [formState?.isSubAccount, subTypeResponseReceived, subTypeFound]);

  const getParentTypesApiCall = (codes: any) => {
    setUpdating(true);
    CoaService.getParentTypes(codes)
      .then((res) => {
        setUpdating(false);
        const resWithoutSameName = res?.filter((item: any) => {
          return item.id !== props.populateFormData?.id;
        });
        setParentAccounts(resWithoutSameName);
        const updatedFormState = { ...formState };
        updatedFormState.parentAccount = res?.find((item: any) => {
          return item.id === props.populateFormData?.parent;
        });
        setFormState({ ...updatedFormState });

        !updating &&
          Utility.isEmpty(resWithoutSameName) &&
          showAlert(
            'Please note!',
            `Parent account with same type or sub-type is not available.`,
            [
              {
                title: 'Ok',
                className: 'bg-button text-white border-m ',
                onClick: () => {
                  const updatedFormState = { ...formState };
                  updatedFormState.isSubAccount = false;
                  if (formState?.isSubAccount) {
                    updatedFormState.parentAccount = null;
                  }
                  setFormState({ ...updatedFormState });
                }
              }
            ]
          );
      })
      .catch((err) => {
        setUpdating(false);
        console.log(err);
      });
  };

  const validate = () => {
    if (Utility.isEmpty(formState?.type?.name?.trim())) {
      return false;
    }

    if (Utility.isEmpty(formState?.name?.trim())) {
      return false;
    }

    if (
      Utility.isEmpty(formState?.autoNumberingFormat?.text?.trim()) &&
      Utility.isEmpty(formState?.autoNumberingFormat?.id)
    ) {
      return false;
    }

    // if (isTaxVisible() && Utility.isEmpty(formState?.tax?.name)) {
    //   return false;
    // }

    if (isNipViewVisible() && Utility.isEmpty(formState?.nip)) {
      return false;
    }

    // Custom fields validation
    const customFieldHasErrors = customFieldsContainsErrors(
      formState?.customField
    );
    if (customFieldHasErrors) {
      return false;
    }
    // Custom fields validation ends

    return true;
  };

  const getPayload = () => {
    let payload: any = {
      name: formState?.name,
      currency: formState?.currency,
      description: formState?.description,
      isCreditCard: formState?.isCreditCardAccount,
      isTdsApplicable: formState?.isTdsApplicable,
      natureOfIncomePayment: formState?.nip,
      taxCode: formState?.tax?.code,
      customField: formState?.customField,
      accountGroupId: Number(formState?.type?.id),
      accountGroupName: formState?.type?.name,
      accountSubGroup: formState?.subType?.id,
      accountSubGroupName:
        formState?.subType?.name?.toLowerCase() !== 'none'
          ? formState?.subType?.name
          : null,
      isSubAccount: formState?.isSubAccount,
      parent: formState?.parentAccount?.id,
      parentName: formState?.parentAccount?.name
    };

    if (Utility.isEmpty(props.populateFormData)) {
      payload.openingBalance = 0;
      payload.openingBalanceCdType = 'CREDIT';
    }

    if (formState?.autoNumberingFormat?.id) {
      payload.sequenceFormat = formState?.autoNumberingFormat?.id;
    } else {
      payload.accountCode = formState?.autoNumberingFormat?.text?.trim();
    }

    if (!Utility.isEmpty(formState.itcAdjustment)) {
      payload.itcAdjustment = formState?.itcAdjustment?.value;
    } else {
      delete formState?.itcAdjustment;
      delete payload?.itcAdjustment;
    }
    if (!Utility.isEmpty(formState?.glItcBlockAccount)) {
      payload.glAccountCode = formState?.glItcBlockAccount?.code;
    } else {
      delete payload?.glAccountCode;
    }

    payload = {
      ...formState,
      ...payload
    };
    return payload;
  };

  const createCoa = () => {
    setCanValidate(true);

    if (validate()) {
      setLoading(true);
      const payload = getPayload();
      CoaService.createCoa(payload)
        .then((res) => {
          setLoading(false);
          dispatch(fetchPaymentTerm());
          dispatch(fetchAsset());
          dispatch(fetchLiability());
          if (props.refreshGrid) {
            props.refreshGrid();
          } else {
            dispatch(fetchCoa());
            dispatch(fetchAccoutnsList());
          }
          props.onCancel(); //for closing the popup
        })
        .catch((err) => {
          setLoading(false);
          console.log('Error creating COA', err);
        });
    }
  };

  const updateCoa = () => {
    setCanValidate(true);

    if (validate()) {
      setLoading(true);
      const payload = getPayload();
      CoaService.updateCoa(payload, props.populateFormData.id)
        .then((res) => {
          if (props.refreshGrid) {
            props.refreshGrid();
          } else {
            dispatch(fetchCoa());
            dispatch(fetchAccoutnsList());
          }
          props.onCancel(); //for closing the popup
        })
        .catch((err) => {
          setLoading(false);
          console.log('Error updating COA', err);
        });
    }
  };

  const getHeader = () => {
    const getTitle = () => {
      if (Utility.isEmpty(props.populateFormData)) {
        return loading ? 'Creating ...' : 'Create';
      }

      if (!Utility.isEmpty(props.populateFormData)) {
        return loading ? 'Updating ...' : 'Update';
      }
    };
    return (
      <div className="row justify-content-between p-h-r p-v-s bg-gray1">
        <div className="row pop-header-drag-handle">
          <DKLabel
            text={`${
              Utility.isEmpty(props.populateFormData) ? 'Create' : 'Update'
            } Account`}
            className="fw-m fs-l"
          />
          {updating && <DKSpinner iconClassName="ic-s ml-s" />}
        </div>
        <div className="row width-auto">
          <DKButton
            title={'Logs'}
            className="bg-white border-m mr-r"
            onClick={() => {
              setTransactionData({
                documentType: 'ACCOUNT',
                documentCode: props?.populateFormData?.accountCode
              });
              setShowTransactionLog(true);
            }}
          />
          <DKButton
            title={'Cancel'}
            className="bg-white border-m mr-r"
            onClick={() => {
              !loading && props.onCancel();
            }}
          />
          <DKButton
            title={getTitle()}
            className="bg-button text-white"
            onClick={() => {
              if (Utility.isEmpty(props.populateFormData)) {
                !loading && createCoa();
              }

              if (!Utility.isEmpty(props.populateFormData)) {
                !loading && updateCoa();
              }
            }}
          />
        </div>
      </div>
    );
  };

  const getType = () => {
    return (
      <DKInput
        type={INPUT_TYPE.DROPDOWN}
        title={'Type'}
        direction={INPUT_VIEW_DIRECTION.VERTICAL}
        readOnly={props.populateFormData && props.populateFormData.accountGroup}
        value={formState?.type?.name}
        canValidate={canValidate}
        className="mt-l"
        onChange={(obj: any) => {
          const updatedFormState = { ...formState };
          updatedFormState.type = obj;
          updatedFormState.subType = null;
          updatedFormState.isSubAccount = false;

          CoaConfigUtility.setStructuredSubtypeArray([]);
          setFormState({ ...updatedFormState });
        }}
        dropdownConfig={{
          title: '',
          allowSearch: true,
          searchableKey: 'name',
          style: { minWidth: 150 },
          className: 'shadow-m width-auto',
          searchApiConfig: null,
          data: coaTypes && coaTypes.length > 0 ? coaTypes : [],
          renderer: (index: any, obj: any) => {
            return <DKLabel text={`${obj.name}`} />;
          }
        }}
      />
    );
  };

  const getItcAdjustment = () => {
    return (
      <DKInput
        type={INPUT_TYPE.DROPDOWN}
        title={'ITC Adjustment'}
        direction={INPUT_VIEW_DIRECTION.VERTICAL}
        readOnly={false}
        required={false}
        value={formState?.itcAdjustment?.key}
        canValidate={false}
        className="mt-l"
        options={itcAdjustmentTypes}
        onChange={(obj: any) => {
          const updatedFormState = { ...formState };
          updatedFormState.itcAdjustment = obj;
          CoaConfigUtility.setStructuredSubtypeArray([]);
          setFormState({ ...updatedFormState });
        }}
        dropdownConfig={{
          title: '',
          allowSearch: false,
          searchableKey: 'name',
          style: { minWidth: 150 },
          className: 'shadow-m width-auto',
          searchApiConfig: null,
          data: itcAdjustmentTypes,
          renderer: (index: any, obj: any) => {
            return <DKLabel text={`${obj.key}`} />;
          }
        }}
      />
    );
  };

  const getGlForItcBlock = () => {
    return (
      <DKInput
        type={INPUT_TYPE.DROPDOWN}
        title={'GL For ITC Block'}
        direction={INPUT_VIEW_DIRECTION.VERTICAL}
        readOnly={false}
        value={formState?.glItcBlockAccount?.name}
        canValidate={canValidate}
        required={false}
        className="mt-l"
        onChange={(obj: any) => {
          const updatedFormState = { ...formState };
          updatedFormState.glItcBlockAccount = obj;
          setFormState({ ...updatedFormState });
        }}
        dropdownConfig={{
          title: '',
          allowSearch: true,
          searchableKey: 'name',
          style: { minWidth: 150 },
          className: 'shadow-m width-auto',
          data: accountsData && accountsData.length > 0 ? accountsData : [],
          renderer: (index: any, obj: any) => {
            return <DKLabel text={`${obj.name}`} />;
          },
          searchApiConfig: {
            getUrl: (searchValue: string) => {
              const config: AccountAPIConfig = {
                ...AccountsService.apiConfig,
                Page: 0,
                SearchTerm: searchValue,
                Limit: 10,
                AccountGroupsString: '',
                Query: 'status=ACTIVE'
              };
              AccountsService.apiConfig = config;
              return (
                ApiConstants.URL.BASE + AccountsService.getAccountEndPoint()
              );
            },
            dataParser: (response: any) => {
              return response?.content;
            },
            debounceTime: 300
          }
        }}
      />
    );
  };

  const getSubType = () => {
    let addSubtypeBtn = null;
    if (
      GranularPermissionsHelper.hasPermissionFor(
        PERMISSIONS_BY_MODULE.COA.CREATE_SUBTYPE
      )
    ) {
      addSubtypeBtn = {
        title: '+ Add Sub Type',
        className: 'bg-button text-white',
        onClick: () => {
          setShowAddSubTypePopup(true);
        }
      };
    }

    return (
      <DKInput
        type={INPUT_TYPE.DROPDOWN}
        title={'Sub Type'}
        direction={INPUT_VIEW_DIRECTION.VERTICAL}
        readOnly={Utility.isEmpty(formState?.type?.name)}
        value={formState?.subType?.name}
        required={false}
        onChange={(obj: any) => {
          const updatedFormState = { ...formState };
          updatedFormState.subType = obj;
          updatedFormState.isSubAccount = false;
          setFormState({ ...updatedFormState });
        }}
        className="mt-l"
        dropdownConfig={{
          title: '',
          allowSearch: false,
          searchableKey: '',
          canEdit: true,
          canDelete: true,
          style: { minWidth: 150 },
          className: 'shadow-m width-auto',
          searchApiConfig: {
            getUrl: (searchValue: string) => {
              const url =
                ApiConstants.URL.BASE +
                `account/group/subgroupbytype/${formState?.type?.id}`;
              return url;
            },
            dataParser: (response: any) => {
              const newData = Utility.getSubtypeStructured(
                response,
                formState?.type?.id
              );
              CoaConfigUtility.fillStructuredSubtypeArray(newData);
              let newStructuredData = CoaConfigUtility.structuredSubtypeArr;
              setParentSubtypeData(newStructuredData);
              return newStructuredData;
            },
            debounceTime: 300
          },
          data: undefined,
          renderer: (index: any, obj: any) => {
            return CoaConfigUtility.getSubtypeRow(obj);
          },
          button: addSubtypeBtn,
          onEdit: (index: any, value: any) => {
            if (value?.name?.toLowerCase() === 'none') {
              return;
            }
            setCurrentSubtypeToEditOrDelete(value);
            setShowAddSubTypePopup(true);
          },
          onDelete: (index: any, value: any) => {
            if (value?.name?.toLowerCase() === 'none') {
              return;
            }
            let buttons = [
              {
                title: 'No',
                className: 'bg-gray2 border-m ',
                onClick: () => {}
              },
              {
                title: 'Yes',
                className: 'bg-red text-white ml-r',
                onClick: () => {
                  const accId = props?.populateFormData
                    ? props?.populateFormData?.id
                    : '';
                  CoaService.deleteSubtType(value, accId)
                    .then((res) => {
                      CoaConfigUtility.setStructuredSubtypeArray([]);
                      if (res) {
                        showAlert('Please Note!', `Subtype was deleted.`, [
                          {
                            title: 'Ok',
                            className: 'bg-button text-white',
                            onClick: () => {
                              const updatedFormState = { ...formState };
                              updatedFormState.subType = null;
                              updatedFormState.isSubAccount = false;
                              if (formState?.isSubAccount) {
                                updatedFormState.parentAccount = null;
                              }
                              setFormState({ ...updatedFormState });
                            }
                          }
                        ]);
                      } else {
                        showAlert(
                          'Please Note!',
                          `This subtype cannot be deleted.`,
                          [
                            {
                              title: 'Ok',
                              className: 'bg-button text-white',
                              onClick: () => {}
                            }
                          ]
                        );
                      }
                    })
                    .catch((err) => {
                      console.log(err);
                    });
                }
              }
            ];
            showAlert(
              'Confirm Delete',
              `Are you sure you want to delete  <b>${value?.name}</b>.`,
              buttons
            );
          }
        }}
      />
    );
  };

  const getAddSubTypeView = () => {
    return (
      <AddSubTypeView
        currentSubtype={currentSubtypeToEditOrDelete}
        typeCode={formState?.type?.code}
        parentSubtypeData={parentSubtypeData}
        onSave={(newPayload: any) => {
          // call save sub type api here
          const payload = {
            accountMaster: formState?.type?.accountMaster,
            accountNatureId: formState?.type?.accountNatureId,
            isDeleted: formState?.type?.isDeleted,
            name: newPayload?.subTypeValue,
            parentId: newPayload.isParentSubtype
              ? newPayload.parentSubtype.id
              : formState?.type?.id,
            parentName: newPayload.isParentSubtype
              ? newPayload.parentSubtype.name
              : formState?.type?.name
          };
          CoaService.addNewSubType(payload)
            .then((res: any) => {
              const updatedFormState = { ...formState };
              updatedFormState.subType = res;
              updatedFormState.isSubAccount = false;
              if (formState?.isSubAccount) {
                updatedFormState.parentAccount = null;
              }
              CoaConfigUtility.setStructuredSubtypeArray([]);
              setFormState({ ...updatedFormState });
              setMultiLevelSubTypes([...multiLevelSubTypes, res]);
              setShowAddSubTypePopup(false);
            })
            .catch((err: any) => {
              console.log(err);
              setShowAddSubTypePopup(false);
            });
        }}
        onEdit={(newPayload: any) => {
          const payload = {
            accountMaster: formState?.type?.accountMaster,
            accountNatureId: formState?.type?.accountNatureId,
            isDeleted: formState?.type?.isDeleted,
            name: newPayload?.subTypeValue,
            parentId: newPayload.isParentSubtype
              ? newPayload.parentSubtype.id
              : formState?.type?.id,
            parentName: newPayload.isParentSubtype
              ? newPayload.parentSubtype.name
              : formState?.type?.name
          };
          const id = currentSubtypeToEditOrDelete?.id;
          CoaService.editSubtType(payload, id)
            .then((res) => {
              const updatedSubTypes = [...multiLevelSubTypes];
              const updatedFormState = { ...formState };
              const indexValuesTobeUpdated = updatedSubTypes.findIndex(
                (item: any) => item.id === res.id
              );
              if (indexValuesTobeUpdated !== -1) {
                updatedSubTypes[indexValuesTobeUpdated] = res;
              }

              updatedFormState.subType = null;
              CoaConfigUtility.setStructuredSubtypeArray([]);
              setMultiLevelSubTypes(updatedSubTypes);
              setFormState({ ...updatedFormState });
              setCurrentSubtypeToEditOrDelete(null);
              setShowAddSubTypePopup(false);
            })
            .catch((err) => {
              console.log(err);
            });
        }}
        onCancel={() => {
          setCurrentSubtypeToEditOrDelete(null);
          setShowAddSubTypePopup(false);
        }}
      />
    );
  };

  const getIsSubAccCheckboxView = () => {
    return (
      <div className="row mt-l">
        <DKCheckMark
          title="Is sub-account"
          className=""
          isSelected={formState?.isSubAccount}
          onClick={() => {
            const updatedFormState = { ...formState };
            updatedFormState.isSubAccount = !formState?.isSubAccount;
            if (formState?.isSubAccount) {
              updatedFormState.parentAccount = null;
            }
            setFormState({ ...updatedFormState });
          }}
          style={{ width: 150 }}
        />
      </div>
    );
  };

  const getParentAccountView = () => {
    return (
      <DKInput
        type={INPUT_TYPE.DROPDOWN}
        title={'Parent Account'}
        direction={INPUT_VIEW_DIRECTION.VERTICAL}
        readOnly={Utility.isEmpty(parentAccounts)}
        value={formState?.parentAccount?.name}
        canValidate={canValidate}
        required={false}
        className="mt-l"
        onChange={(obj: any) => {
          const updatedFormState = { ...formState };
          updatedFormState.parentAccount = obj;
          setFormState({ ...updatedFormState });
        }}
        dropdownConfig={{
          title: '',
          allowSearch: true,
          searchableKey: 'name',
          style: { minWidth: 150 },
          className: 'shadow-m width-auto',
          searchApiConfig: null,
          data:
            parentAccounts && parentAccounts.length > 0 ? parentAccounts : [],
          renderer: (index: any, obj: any) => {
            return <DKLabel text={`${obj.name}`} />;
          }
        }}
      />
    );
  };

  const getName = () => {
    return (
      <div className="">
        <div className="mb-s" style={{ width: 100 }}>
          <DKTooltipWrapper
            content="Account name in Chart of Accounts"
            tooltipClassName=""
          >
            <div className="row">
              <DKLabel className=" cursor-hand" text={'Name *'} />
              <DKIcon
                src={DKIcons.ic_info}
                className="ic-xs opacity-40 ml-s"
                onClick={() => {}}
              />
            </div>
          </DKTooltipWrapper>
        </div>

        <DKInput
          required={true}
          canValidate={canValidate}
          direction={INPUT_VIEW_DIRECTION.VERTICAL}
          value={formState?.name}
          onChange={(text: any) => {
            const updatedFormState = { ...formState };
            updatedFormState.name = text;
            setFormState({ ...updatedFormState });
          }}
        />
      </div>
    );
  };

  const getTax = () => {
    return (
      <DKInput
        type={INPUT_TYPE.DROPDOWN}
        title={'Tax'}
        direction={INPUT_VIEW_DIRECTION.VERTICAL}
        // canValidate={isTaxVisible() && canValidate}
        // readOnly={props.populateFormData && props.populateFormData.accountGroup}
        required={false}
        value={formState?.tax?.name}
        onChange={(obj: any) => {
          const updatedFormState = { ...formState };
          updatedFormState.tax = obj;
          setFormState({ ...updatedFormState });
        }}
        className="mt-l"
        dropdownConfig={{
          title: '',
          allowSearch: false,
          searchableKey: '',
          style: { minWidth: 150 },
          className: 'shadow-m width-auto',
          searchApiConfig: null,
          data: taxOptions && taxOptions.length > 0 ? taxOptions : [],
          renderer: (index: any, obj: any) => {
            return <DKLabel text={`${obj.name}`} />;
          }
        }}
      />
    );
  };

  const isTaxVisible = () => {
    if (
      formState?.type?.name === COA_OPTIONS.BANK ||
      formState?.type?.name === COA_OPTIONS.CASH
    ) {
      return false;
    } else {
      return true;
    }
  };

  const getCurrencyView = () => {
    return (
      <div className="parent-width mt-l mb-2">
        <div className="mb-s">
          <DKLabel text={'Currency'} />
        </div>
        {
          <CurrencyDropdown
            style={{
              ...formUtilComponentProductStyle,
              indicatorsContainer: (provided: any, state: any) => ({})
            }}
            getInfo={(obj: any) => {
              const updatedFormState = { ...formState };
              updatedFormState.currency = obj.selectedCurrencyCode;
              setFormState({ ...updatedFormState });
            }}
            options={allCurrencies.filter((item: any) => {
              return item.currencyStatus === 'ACTIVE';
            })}
            isDisabled={allCurrencies.length === 1 ? true : false}
            currencyCode={formState.currency}
            width={'100%'}
          ></CurrencyDropdown>
        }
        {/* {!formState.currency && (
          <CurrencyDropdown
            getInfo={(obj: any) => {
              const updatedFormState = { ...formState };
              updatedFormState.currency = obj.selectedCurrencyCode;
              setFormState({ ...updatedFormState });
            }}
            options={activeCurrencies}
            width={'100%'}
            isDisabled={activeCurrencies.length === 1 ? true : false}
            currencyCode={formState.currency}
          ></CurrencyDropdown>
        )} */}
      </div>
    );
  };

  const getCodeView = () => {
    return (
      <div className="mt-l">
        {!props?.populateFormData?.accountCode ? (
          <CustomNumberFormatInput
            module={CUSTOM_NUMBER_INPUT_MODULES.ACCOUNT}
            selectedFormat={(selected: any) => {
              const updatedFormState = { ...formState };
              updatedFormState.autoNumberingFormat = selected;
              setFormState({ ...updatedFormState });
            }}
            extraClass={'mt-s top-10 right-1'}
            isRow={false}
            autoNumberingFormat={formState?.autoNumberingFormat}
          />
        ) : (
          <DKInput
            title="Code"
            direction={INPUT_VIEW_DIRECTION.VERTICAL}
            required={true}
            canValidate={true}
            value={formState?.autoNumberingFormat?.text}
            onChange={(text: any) => {
              const updatedFormState = { ...formState };
              updatedFormState.autoNumberingFormat = {
                text: text
              };
              setFormState({ ...updatedFormState });
            }}
          />
        )}
      </div>
    );
  };

  const getCCAView = () => {
    const allowEdit = !Utility.isEmpty(props.populateFormData)
      ? props.populateFormData?.totalCOABalance === 0 ||
        props.populateFormData?.totalCOABalance === null
      : true;
    return (
      <DKTooltipWrapper
        content="Use this as a Credit Card to make and receive payment. You can pay the outstanding balance by using a Fund transfer in Accounting - JE"
        tooltipClassName=""
      >
        <div className="row mb-3 mt-3">
          <DKCheckMark
            title="Credit Card Account"
            isSelected={formState?.isCreditCardAccount}
            onClick={() => {
              if (!allowEdit) {
                showAlert(
                  `Can't change`,
                  `This account is already in use, you cant't change this value.`
                );
              }
              if (allowEdit) {
                const updatedFormState = { ...formState };
                updatedFormState.isCreditCardAccount =
                  !updatedFormState.isCreditCardAccount;
                setFormState({ ...updatedFormState });
              }
            }}
          />
          <DKIcon
            src={DKIcons.ic_info}
            className="ic-xs opacity-40 ml-s"
            onClick={() => {}}
          />
        </div>
      </DKTooltipWrapper>
    );
  };

  const isCCAVisible = () => {
    return formState?.type?.name.toLowerCase() === currentLiablityVal;
  };

  const getDescriptionView = () => {
    return (
      <DKInput
        className="mt-l"
        title="Description"
        type={INPUT_TYPE.LONG_TEXT}
        value={formState?.description}
        required={false}
        onChange={(value: any) => {
          const updatedFormState = { ...formState };
          updatedFormState.description = value;
          setFormState({ ...updatedFormState });
        }}
        direction={INPUT_VIEW_DIRECTION.VERTICAL}
        readOnly={false}
      />
    );
  };

  const getTdsApplicableView = () => {
    return (
      <div style={{ width: 150 }}>
        <DKCheckMark
          title={'TDS Applicable'}
          isSelected={formState?.isTdsApplicable}
          className="text-grey mt-l"
          onClick={() => {
            const updatedFormState = { ...formState };
            updatedFormState.isTdsApplicable =
              !updatedFormState.isTdsApplicable;
            setFormState({ ...updatedFormState });
          }}
        />
      </div>
    );
  };

  const isTdsViewVisible = () => {
    return tenantInfo.country === 'IN';
  };

  const isNipViewVisible = () => {
    return formState?.isTdsApplicable;
  };

  const getNipView = () => {
    return (
      <DKInput
        type={INPUT_TYPE.DROPDOWN}
        title={'Nature of Income Payments'}
        direction={INPUT_VIEW_DIRECTION.VERTICAL}
        value={formState?.nip}
        canValidate={isNipViewVisible() && canValidate}
        onChange={(obj: any) => {
          const updatedFormState = { ...formState };
          updatedFormState.nip = obj;
          setFormState({ ...updatedFormState });
        }}
        className="mt-l"
        dropdownConfig={{
          title: '',
          allowSearch: true,
          searchableKey: '',
          style: { minWidth: 150 },
          className: 'shadow-m width-auto',
          searchApiConfig: null,
          data: nipOptions && nipOptions.length > 0 ? nipOptions : [],
          renderer: (index: any, obj: any) => {
            return <DKLabel text={`${obj}`} />;
          },
          button: {
            title: '+ Add Nature of Income Payment',
            className: 'bg-button text-white',
            onClick: () => {
              setCreateListPicker(true);
            }
          }
        }}
      />
    );
  };

  const getNipFormPopup = () => {
    const catchClicks = (data: PopupClickActionType) => {
      switch (data.type) {
        case POPUP_CLICK_TYPE.CLOSE_POPUP:
          dispatch(fetchNatureOfIncomeAccounts());
          setCreateListPicker(false);
          setCreateListPicker(false);
          break;
        case POPUP_CLICK_TYPE.SAVE_NATURE_OF_INCOME_PAYMENT:
          addOrderRef.current?.storeCallbacksRef.saveNatureOfIncomePayment();
          break;
      }
    };

    const natureOfIncomePaymentpopupBtnConfig: BtnType[] = [
      {
        title: 'Cancel',
        class: 'bg-gray1 border-m mr-s',
        clickAction: POPUP_CLICK_TYPE.CLOSE_POPUP
      },
      {
        title: 'Save',
        class: 'bg-app text-white mr-s',
        clickAction: POPUP_CLICK_TYPE.SAVE_NATURE_OF_INCOME_PAYMENT
      }
    ];

    const parentChildInteraction = (passingData: CallBackPayloadType) => {
      switch (passingData.type) {
        case POPUP_CALLBACKS_TYPE.SAVE_NATURE_INCOME_PAYMENT:
          addOrderRef.current.storeCallbacksRef.saveNatureOfIncomePayment =
            passingData.data;
          break;
        case POPUP_CALLBACKS_TYPE.CLOSE_POPUP:
          dispatch(fetchNatureOfIncomeAccounts());
          setCreateListPicker(false);
          break;
      }
    };

    return (
      <PopupWrapper
        clickAction={catchClicks}
        type={POPUP_TYPE.POPUP}
        title={`Add Nature of Income Payment`}
        btnList={natureOfIncomePaymentpopupBtnConfig}
        height={'24%'}
        width={'30%'}
      >
        <AddNatureIncomePayment
          passingInteraction={(callback: CallBackPayloadType) => {
            parentChildInteraction(callback);
          }}
        />
      </PopupWrapper>
    );
  };

  const showCustomFieldView = () => {
    const doc = !Utility.isEmpty(props.populateFormData)
      ? props.populateFormData
      : formState;
    return (
      <div className="column parent-width mt-l">
        <DKLabel text="Custom Fields" className="fw-m" />
        <CustomFieldsHolder
          moduleName={MODULES_NAME.ACCOUNT}
          customFieldsList={
            !Utility.isEmpty(props.populateFormData) ? doc.customField : []
          }
          columnConfig={accountColumnConfig}
          columnConfigTableId={accountConfigTableId ? accountConfigTableId : ''}
          onUpdate={(list: any) => {
            setFormState({
              ...formState,
              customField: list
            });
          }}
        />
      </div>
    );
  };

  return (
    <DynamicPopupWrapper>
      <div className="transparent-background">
        <div
          className="popup-window"
          style={{
            maxWidth: 450,
            width: '90%',
            maxHeight: '90%',
            padding: 0,
            paddingBottom: 20,
            overflowY: 'hidden'
          }}
        >
          {getHeader()}
          <div className="parent-width p-l overflow-y-auto show-scroll-bar">
            {getName()}
            {getType()}
            {getSubType()}
            {showAddSubTypePopup && getAddSubTypeView()}
            {getIsSubAccCheckboxView()}
            {formState?.isSubAccount &&
              !Utility.isEmpty(parentAccounts) &&
              getParentAccountView()}
            {isTaxVisible() && getTax()}
            {!isTaxVisible() && getCurrencyView()}
            {getCodeView()}
            {isCCAVisible() && getCCAView()}
            {getDescriptionView()}
            {isTdsViewVisible() && getTdsApplicableView()}
            {isNipViewVisible() && getNipView()}
            {createListPicker && getNipFormPopup()}
            {getTenantTaxSystem() === TAX_SYSTEM.INDIA_GST &&
              getItcAdjustment()}
            {getTenantTaxSystem() === TAX_SYSTEM.INDIA_GST &&
              getGlForItcBlock()}
            {showCustomFieldView()}
            {showTransactionLog && (
              <TransactionLog
                data={transactionData}
                onCancel={() => {
                  setShowTransactionLog(false);
                }}
              />
            )}
          </div>
        </div>
      </div>
    </DynamicPopupWrapper>
  );
};

export default AddCoaPopup;
