import { useContext, useRef, useState } from 'react';
import { shallowEqual } from 'react-redux';
import { DKButton, DKLabel, showAlert } from 'deskera-ui-library';
import { CommonDraftPropsContext } from '../../Utilities/DocContext';
import { useAppDispatch, useAppSelector } from '../../../../Redux/Hooks';
import { DOCUMENT_KEYS } from '../../Utilities/DocConstants';
import {
  onDocumentClose,
  selectDocumentFormDataByKeys,
  updateMultipleKeysInDocument
} from '../../../../Redux/Slices/DocumentSlice';
import {
  activeTenantInfo,
  eInvoiceAuthInfo,
  fetchEInvoiceAuthInfo
} from '../../../../Redux/Slices/AuthSlice';
import {
  COUNTRY_CODES,
  DOC_TYPE,
  DOCUMENT_MODE,
  POPUP_CALLBACKS_TYPE,
  POPUP_CLICK_TYPE,
  POPUP_TYPE
} from '../../../../Constants/Constant';
import {
  getInvoiceNowStatus,
  getUpdatedInvoiceObject,
  INVOICE_NOW_TYPES
} from '../../../Invoices/InvoiceHelper';
import { getInvoiceNowStatusInfo } from '../../Utilities/DocCommonUtils';
import { isTabletView } from '../../../../Utility/ViewportSizeUtils';
import Utility from '../../../../Utility/Utility';
import EInvoiceService from '../../../../Services/EInvoice';
import InvoiceService from '../../../../Services/Invoice';
import RouteManager, { PAGE_ROUTES } from '../../../../Managers/RouteManager';
import CancelEInvoice from '../../../Settings/OrganisationProfile/CancelEInvoice';
import { selectIsLoadingPopupWrapper } from '../../../../Redux/Slices/CommonDataSlice';
import {
  BtnType,
  CallBackPayloadType,
  LoadingBtnType,
  PopupClickActionType,
  UpdateCallBacksRefType
} from '../../../../Models/Interfaces';
import PopupWrapper from '../../../../SharedComponents/PopupWrapper';
import { IRPCredPopup } from '../../../Settings/OrganisationProfile/IRPCredPopup';

/**
 * Component to handle e-Invoice interactions for Indian Organisations
 */
const DocEInvoice = () => {
  const { draftId, documentMode } = useContext(CommonDraftPropsContext);
  const [
    documentType,
    documentCode,
    einvoiceInfoIndia,
    einvoiceInfoIndiaCancel,
    isCancelEinvoice
  ] = useAppSelector(
    selectDocumentFormDataByKeys(draftId, [
      DOCUMENT_KEYS.DOCUMENT_TYPE,
      DOCUMENT_KEYS.DOCUMENT_CODE,
      DOCUMENT_KEYS.E_INVOICE_INFO_INDIA,
      DOCUMENT_KEYS.E_INVOICE_INFO_INDIA_CANCEL,
      DOCUMENT_KEYS.IS_CANCEL_E_INVOICE
    ]),
    shallowEqual
  );

  const dispatch = useAppDispatch();
  const tenantInfo = useAppSelector(activeTenantInfo);
  const eInvoiceAuthInfoData = useAppSelector(eInvoiceAuthInfo);
  const popupLoading = useAppSelector(selectIsLoadingPopupWrapper);
  const [showIRPCredPopup, setShowIRPCredPopup] = useState(false);
  const [showCancelEInvoice, setShowCancelEInvoice] = useState(false);
  const refInitialState: UpdateCallBacksRefType = {
    pushDataToParent: { type: POPUP_CALLBACKS_TYPE.NONE },
    storeCallbacksRef: {}
  };
  const orgProfileRef = useRef<UpdateCallBacksRefType>(refInitialState);

  const popupBtnConfigForEInvoice: BtnType[] = [
    {
      title: 'Cancel',
      class: 'border-m mr-s',
      clickAction: POPUP_CLICK_TYPE.CLOSE_POPUP
    },
    {
      title: 'Save',
      class: 'bg-app text-white mr-ss',
      clickAction: POPUP_CLICK_TYPE.IRP_CREDENTIAL
    }
  ];

  const loadingIRPCredPopupBtnConfig: LoadingBtnType[] = [
    {
      title: 'Cancel',
      class: 'border-m mr-s',
      type: 'CLOSE'
    },
    {
      title: 'Saving',
      class: 'bg-app text-white mr-ss',
      type: 'ACTION'
    }
  ];

  const catchClicks = (data: PopupClickActionType) => {
    switch (data.type) {
      case POPUP_CLICK_TYPE.CLOSE_POPUP:
        setShowIRPCredPopup(false);
        break;
      case POPUP_CLICK_TYPE.IRP_CREDENTIAL:
        orgProfileRef.current?.storeCallbacksRef.createIRPCredentials();
        break;
    }
  };

  const parentChildInteraction = (passingData: CallBackPayloadType) => {
    switch (passingData.type) {
      case POPUP_CALLBACKS_TYPE.IRP_CREDENTIAL:
        orgProfileRef.current.storeCallbacksRef.createIRPCredentials =
          passingData.data;
        break;
    }
  };

  const invoiceNowStatus = getInvoiceNowStatus({
    einvoiceInfoIndia,
    einvoiceInfoIndiaCancel,
    isCancelEinvoice
  });

  const statusInfo = getInvoiceNowStatusInfo(invoiceNowStatus || '');

  const generateEInvoiceIndia = () => {
    const generateEInvoice = (authToken: any) => {
      try {
        let payload = { ...authToken };
        payload.gstin = tenantInfo.gstin;
        payload.id = draftId;

        EInvoiceService.generateEInvoice(payload).then(
          (res: any) => {
            if (!Utility.isEmpty(res?.errorDetails)) {
              let errors = res?.errorDetails;
              if (errors?.length) {
                let HTML = `<div>
                        <ul>
                          ${errors.map((error: any) => {
                            return `<li>${error?.ErrorMessage}</li>`;
                          })}
                        </ul>
                      </div>`;
                showAlert('eInvoice portal reported errors below', HTML);
              }
            }

            if (documentCode) {
              InvoiceService.getInvoiceByCode(documentCode).then(
                (data: any) => {
                  let invoiceDetailsData: any = getUpdatedInvoiceObject(data);
                  dispatch(
                    updateMultipleKeysInDocument({
                      draftId,
                      keysToUpdate: invoiceDetailsData
                    })
                  );
                },
                (err) => {
                  console.error('Error loading detailed invoice: ', err);
                }
              );
            }
          },
          (err) => {
            console.error('Error generating eInvoice: ', err);
          }
        );
      } catch (error: any) {}
    };

    if (!Utility.isEmpty(tenantInfo)) {
      if (
        Utility.isEmpty(eInvoiceAuthInfoData) ||
        eInvoiceAuthInfoData.status !== '1'
      ) {
        dispatch(fetchEInvoiceAuthInfo({})).then((authToken: any) => {
          if (Utility.isEmpty(authToken) || authToken.payload.status !== '1') {
            setShowIRPCredPopup(true);
            if (
              authToken?.payload?.status === '0' &&
              authToken?.payload?.errors?.[0]?.errorMessage
            ) {
              showAlert(
                'Error!',
                authToken?.payload?.errors?.[0]?.errorMessage
              );
            }
          } else {
            generateEInvoice(authToken.payload);
          }
        });
      } else {
        generateEInvoice(eInvoiceAuthInfoData);
      }
    }
  };

  const redirectEInvoiceFailureLog = () => {
    dispatch(onDocumentClose({ draftId }));
    RouteManager.navigateToPage(PAGE_ROUTES.FAILED_INVOICES);
  };

  const cancelEInvoiceIndia = () => {
    if (!Utility.isEmpty(tenantInfo)) {
      if (
        Utility.isEmpty(eInvoiceAuthInfoData) ||
        eInvoiceAuthInfoData.status !== '1'
      ) {
        dispatch(fetchEInvoiceAuthInfo({})).then((authToken: any) => {
          if (Utility.isEmpty(authToken) || authToken.payload.status !== '1') {
            setShowIRPCredPopup(true);
          } else {
            setShowCancelEInvoice(true);
          }
        });
      } else {
        setShowCancelEInvoice(true);
      }
    }
  };

  if (
    isTabletView() ||
    !tenantInfo?.indiaEinvoice ||
    documentType !== DOC_TYPE.INVOICE ||
    tenantInfo?.country !== COUNTRY_CODES.IN ||
    documentMode === DOCUMENT_MODE.NEW ||
    documentMode === DOCUMENT_MODE.COPY
  ) {
    return null;
  }

  return (
    <div className="row bg-gray-50 border-radius-m justify-content-between align-items-center mb-m p-v-r p-h-s">
      <div className="column width-auto align-items-center">
        <div className="row">
          <DKLabel className="fw-m" text="e-Invoice Status: " />
          <DKLabel
            text={Utility.convertInTitleCase(statusInfo?.title || '')}
            style={{
              textTransform: 'capitalize'
            }}
            className={`text-dark-gray ${statusInfo?.color} border-radius-r ml-r p-h-s p-v-xs fw-m mr-r`}
          />
          {statusInfo?.title === INVOICE_NOW_TYPES.PENDING && (
            <DKLabel
              className="ml-3"
              text={`Push your invoice to the e-invoicing portal(IRP) to genrate an IRN`}
            />
          )}
          {statusInfo?.title === INVOICE_NOW_TYPES.FAILED &&
            einvoiceInfoIndia.errorDetails && (
              <DKLabel
                className="ml-3"
                text={`${einvoiceInfoIndia.errorDetails?.[0]?.errorMessage}`}
              />
            )}
        </div>
      </div>
      <div className="column width-auto pointer-events-auto">
        {statusInfo?.title === INVOICE_NOW_TYPES.PENDING && (
          <DKButton
            title={'Generate e-Invoice'}
            className="text-green fw-m p-0"
            onClick={() => {
              generateEInvoiceIndia();
            }}
          />
        )}
        {(statusInfo?.title === INVOICE_NOW_TYPES.CANCELLED ||
          statusInfo?.title === INVOICE_NOW_TYPES.FAILED) && (
          <DKButton
            title={'View Failure Log'}
            className="text-blue fw-m p-0"
            onClick={() => {
              redirectEInvoiceFailureLog();
            }}
          />
        )}
        {statusInfo?.title === INVOICE_NOW_TYPES.GENERATED && (
          <DKButton
            title={'Cancel e-Invoice'}
            className="text-red fw-m p-0"
            onClick={() => {
              cancelEInvoiceIndia();
            }}
          />
        )}
      </div>
      {showIRPCredPopup && (
        <PopupWrapper
          clickAction={catchClicks}
          type={POPUP_TYPE.POPUP}
          title={'Enter your IRP Credentials'}
          btnList={popupBtnConfigForEInvoice}
          isLoading={popupLoading}
          loadingBtnList={loadingIRPCredPopupBtnConfig}
          width={'35%'}
          maxHeight={'95%'}
          disableClickOutside={true}
        >
          <IRPCredPopup
            passingInteraction={(callback: CallBackPayloadType) => {
              parentChildInteraction(callback);
            }}
            sessionOutMessage={true}
            gstIn={tenantInfo.gstin}
            onCancel={() => {
              setShowIRPCredPopup(false);
              generateEInvoiceIndia();
            }}
          />
        </PopupWrapper>
      )}
      {showCancelEInvoice && (
        <CancelEInvoice
          data={{
            id: draftId,
            irn: einvoiceInfoIndia.irn
          }}
          onCancel={() => {
            setShowCancelEInvoice(false);
            if (documentCode) {
              InvoiceService.getInvoiceByCode(documentCode).then(
                (data: any) => {
                  let invoiceDetailsData: any = getUpdatedInvoiceObject(data);
                  dispatch(
                    updateMultipleKeysInDocument({
                      draftId,
                      keysToUpdate: invoiceDetailsData
                    })
                  );
                },
                (err) => {
                  console.error('Error loading detailed invoice: ', err);
                }
              );
            }
          }}
        />
      )}
    </div>
  );
};

export default DocEInvoice;
