import React, { useState } from 'react';
import {
  DKListPicker2,
  showAlert,
  DKTooltipWrapper,
  showLoader,
  removeLoader
} from 'deskera-ui-library';
import Utility from '../../../../Utility/Utility';
import { isDependentOnFieldReadOnly } from '../../JobCard/AddJobCard/AddJobCardPresenter';
import { TIME_TYPES } from '../../../../Constants/Constant';
import DateFormatService from '../../../../Services/DateFormat';
import { JobCardHelper } from '../../JobCard/JobCardHelper';
import JobCardService from '../../../../Services/MRP/JobCard';
import { JOB_CARD_STATUS } from '../../Constants/MRPColumnConfigs';
export interface IJCDependencyProps {
  row: any;
  jobCardMapping: any;
  isReadOnlyMode: boolean;
  isLinkedInventory: boolean;
  onRefreshGrid: () => void;
}
const JCDependency: React.FC<IJCDependencyProps> = (props) => {
  const {
    row,
    jobCardMapping,
    isReadOnlyMode,
    isLinkedInventory,
    onRefreshGrid
  } = props;

  const [showDependencyPicker, setShowDependencyPicker] =
    useState<boolean>(false);

  const saveDependency = (newDependency: any, currentUpdatedJobCard: any) => {
    let currentUpdatedJC = { ...currentUpdatedJobCard };
    let currentUpdatedJCDependencyCopy = {
      ...currentUpdatedJC.jobcardDependency
    };
    if (newDependency.operationName?.toLowerCase() === 'none') {
      currentUpdatedJCDependencyCopy = {
        jobcardDependencyList: []
      };
    } else {
      currentUpdatedJCDependencyCopy = {
        jobcardDependencyList: [newDependency?.jobCardCode]
      };
    }
    currentUpdatedJC = {
      ...currentUpdatedJC,
      jobcardDependency: currentUpdatedJCDependencyCopy
    };

    const payload = jobCardMapping?.allJCDetails?.map((item: any) => {
      if (item.jobCardCode === currentUpdatedJC?.jobCardCode) {
        item = currentUpdatedJC;
      }
      return item;
    });

    showLoader();
    JobCardService.bulkUpdateJobCard(payload)
      .then((res: any) => {
        removeLoader();
        onRefreshGrid();
      })
      .catch((err: any) => {
        removeLoader();
      });
  };

  const onSelectDependency = (newDependency: any) => {
    const updatedFormData = { ...jobCardMapping?.currentJCDetails };
    if (newDependency.operationName?.toLowerCase() === 'none') {
      let start_date = new Date(updatedFormData?.oldPlannedStartDate);
      const plannedTimeInMins =
        Utility.calculateTimeInMinutes(
          updatedFormData?.plannedTime,
          !Utility.isEmpty(updatedFormData?.operationTimeType?.value)
            ? updatedFormData?.operationTimeType?.value
            : TIME_TYPES[1]?.value
        ) ?? 0;
      let end_date = DateFormatService.addMinutes(
        start_date,
        plannedTimeInMins
      );
      updatedFormData.plannedStartDate = start_date;
      updatedFormData.plannedEndDate = end_date;
      saveDependency(newDependency, updatedFormData);
    } else {
      const isCyclicDependency = JobCardHelper.isCyclicDependency(
        jobCardMapping?.currentJCDetails,
        jobCardMapping?.allJCDetails,
        newDependency
      );
      const isSingularCyclicDependency =
        JobCardHelper.isSingularCyclicDependency(
          jobCardMapping?.currentJCDetails,
          jobCardMapping?.allJCDetails
        );
      if (isCyclicDependency || isSingularCyclicDependency) {
        showAlert(
          'Cyclic dependency found!',
          `You can't select this operation as its creating cyclic dependency on one of the operation.`
        );
        return;
      }
      let start_date = new Date(newDependency?.plannedEndDate);
      const plannedTimeInMins =
        Utility.calculateTimeInMinutes(
          updatedFormData?.plannedTime,
          !Utility.isEmpty(updatedFormData?.operationTimeType?.value)
            ? updatedFormData?.operationTimeType?.value
            : TIME_TYPES[1]?.value
        ) ?? 0;
      let end_date = DateFormatService.addMinutes(
        start_date,
        plannedTimeInMins
      );
      updatedFormData.plannedStartDate = start_date;
      updatedFormData.plannedEndDate = end_date;
      saveDependency(newDependency, updatedFormData);
    }
  };

  const dependencyComp = () => {
    return (
      <span className="text-align-left white-space-nowrap">
        {dependencyFound?.operationName || '-'}
      </span>
    );
  };

  const dependencyFound = jobCardMapping?.allJCDetails?.find((jcItem: any) => {
    return (
      jcItem.jobCardCode === row?.jobcardDependency?.jobcardDependencyList?.[0]
    );
  });

  const isStatusCompletedOrCancelled = [
    JOB_CARD_STATUS.COMPLETED,
    JOB_CARD_STATUS.CANCELLED
  ].includes(row.status);

  let dependentOnData =
    jobCardMapping?.allJCDetails.filter((woJC: any) => woJC.id !== row?.id) ??
    [];
  dependentOnData.unshift({ operationName: 'None' });

  return (
    <div
      className=""
      onClick={(e: any) => {
        e.preventDefault();
        e.stopPropagation();
        if (
          isStatusCompletedOrCancelled ||
          isDependentOnFieldReadOnly(
            isReadOnlyMode,
            jobCardMapping,
            isLinkedInventory
          )
        ) {
          return;
        }
        setShowDependencyPicker(!showDependencyPicker);
      }}
    >
      {dependencyFound?.operationName ? (
        <DKTooltipWrapper
          content={dependencyFound?.operationName || '-'}
          tooltipClassName="bg-deskera-secondary width-auto"
          className={'wo-job-card-grid-dependency-wrapper'}
        >
          {dependencyComp()}
        </DKTooltipWrapper>
      ) : (
        dependencyComp()
      )}

      {showDependencyPicker && (
        <DKListPicker2
          data={dependentOnData ?? []}
          allowSearch={false}
          className={`position-absolute z-index-3 bg-white border-m`}
          renderer={(index: number, item: any) => (
            <div
              className="parent-width"
              onClick={(e: any) => {
                e.preventDefault();
                e.stopPropagation();
                setShowDependencyPicker(false);
                onSelectDependency(item);
              }}
            >
              {item.operationName}
            </div>
          )}
          onSelect={() => {}}
          onClose={() => {
            setShowDependencyPicker(false);
          }}
        />
      )}
    </div>
  );
};

export default JCDependency;
