import { showAlert } from 'deskera-ui-library';
import {
  APPROVAL_STATUS,
  BOOKS_DATE_FORMAT,
  DOC_TYPE,
  DOCUMENT_MODE,
  LABELS,
  PRODUCT_TYPE
} from '../../../../Constants/Constant';
import RouteManager, { PAGE_ROUTES } from '../../../../Managers/RouteManager';
import { AnyDocument, DraftTypes, IDocument } from '../../../../Models/Drafts';
import {
  deleteDrafts,
  fetchDrafts
} from '../../../../Redux/Slices/DraftsSlice';
import { fetchSalesOrders } from '../../../../Redux/Slices/SalesOrderSlice';
import { Store } from '../../../../Redux/Store';
import DraftService from '../../../../Services/Drafts';
import {
  cascadingDiscountsInvalidMessage,
  checkCreditLimit,
  checkGSTINPresentForSelectedContact,
  checkIfTotalDiscountInvalid,
  customFieldsContainsErrors,
  getDocumentAlert,
  handleReservedQuantityDataObject,
  inactiveContactMessage,
  isDocContactInactive,
  rebuildCascadingDiscountsForSaving,
  removeUnwantedPayloadKeysForDocument,
  showAlertOnDocAPIError,
  updateAddressAsPerLocationCF
} from '../../../../SharedComponents/DocumentForm/NewDocumentHelper';
import Utility, { deepClone } from '../../../../Utility/Utility';
import {
  DOC_SAVE_OPTION,
  setCanValidateDocument,
  setIsSavingDocument
} from '../View/ActionBarHelper';
import { DocSaver } from './DocSaver';
import {
  onDocumentClose,
  updateMultipleKeysInDocument
} from '../../../../Redux/Slices/DocumentSlice';
import NumberFormatService from '../../../../Services/NumberFormat';
import AuthService from '../../../../Services/Auth';
import SalesOrderService from '../../../../Services/SalesOrder';
import DateFormatService from '../../../../Services/DateFormat';
import { CREDIT_LIMIT_TYPE } from '../../../../Constants/Enum';
import RateAnalysisService from '../../../../Services/RateAnalysis';
import { fetchQuotes } from '../../../../Redux/Slices/QuotesSlice';

export class SalesOrderSaver extends DocSaver {
  draftId: number;
  documentMode: DOCUMENT_MODE;
  tenantInfo: any;
  docToSave: AnyDocument;
  saveOption: DOC_SAVE_OPTION;
  draftType: DraftTypes;
  replaceURLCallback: (() => void) | undefined;

  constructor(data: {
    draftToSave: IDocument<AnyDocument>;
    documentMode: DOCUMENT_MODE;
    saveOption: DOC_SAVE_OPTION;
    tenantInfo: any;
  }) {
    super(data);
    this.draftId = data.draftToSave.id;
    this.draftType = data.draftToSave.draftType;
    this.documentMode = data.documentMode;
    this.saveOption = data.saveOption;
    this.tenantInfo = data.tenantInfo;
    this.docToSave = this.updatedDoc({ ...data.draftToSave?.populateFormData });
  }

  private updatedDoc(doc: AnyDocument) {
    if (this.tenantInfo.additionalSettings?.CASCADING_DISCOUNTS?.enable) {
      doc = {
        ...doc,
        items: rebuildCascadingDiscountsForSaving(doc)
      };
    }
    const extraKeys = {
      salesOrderItems: doc.items,
      salesOrderDueDate: doc.validTillDate,
      salesOrderDate: doc.documentDate,
      shipByDate: doc.fulfillmentDate,
      currency: doc.currency,
      currencyCode: doc.currencyCode,
      documentType: DOC_TYPE.SALES_ORDER,
      priceListId: doc?.priceListId,
      priceListName: doc?.priceListName,
      entityId: this.documentMode === DOCUMENT_MODE.EDIT ? doc?.id : undefined,
      documentCode:
        this.documentMode === DOCUMENT_MODE.EDIT
          ? doc?.salesOrderCode
          : undefined,
      totalAmount: doc.totalAmount
    };

    doc = { ...doc, ...extraKeys };
    return doc;
  }

  private sendTriggerOnApproval(payload: any) {
    let emails = Utility.getApproverEmail(payload);
    let sum =
      payload &&
      payload.salesOrderItems
        .map((item: any) => item.totalAmount)
        .reduce((prev: any, curr: any) => prev + curr, 0);
    let payloadObj = {
      contactCode: payload.contactCode,
      totalAmount: NumberFormatService.getNumber(sum),
      userName: AuthService.getUserName(),
      currency: Utility.getCurrencySymbolFromCode(payload.currency),
      approverMap: Object.fromEntries(emails),
      currentLevel: 1,
      approvalHistory: payload['multiApprovalDetails']?.approvalHistory || [],
      draftCode: payload?.draftDocumentSequenceCode
        ? payload?.draftDocumentSequenceCode
        : '',
      contactName: payload?.contact?.name ? payload?.contact?.name : '',
      contactDocCode: payload?.contact?.documentSequenceCode
        ? payload?.contact?.documentSequenceCode
        : '',
      linkedDocument: !Utility.isEmpty(payload.linkedDocuments)
        ? payload?.linkedDocuments?.[0]?.documentType
        : '',
      linkedDocumentCode: !Utility.isEmpty(payload.linkedDocuments)
        ? payload?.linkedDocuments?.[0]?.documentSequenceCode
        : ''
    };
    SalesOrderService.sendTriggerOnApproval(payloadObj).then(
      (response: any) => {},
      (err) => {
        console.error('Error while creating draft: ', err);
      }
    );
  }

  async saveAsDraft(approvalRequired?: boolean) {
    try {
      const approvalData = await super.updatePayloadWithApprovalData(
        this.docToSave,
        approvalRequired
      );
      const isApprovalRequired = approvalData?.isApprovalRequired;
      this.docToSave = approvalData?.payload || this.docToSave;
      this.docToSave['dueAmount'] = 0;

      setIsSavingDocument(this.draftId, true);

      // here we explicitly remove few keys, which is not needed to be saved in component list feature
      this.docToSave = removeUnwantedPayloadKeysForDocument(this.docToSave);

      if (
        isApprovalRequired &&
        !Utility.isEmpty(this.docToSave) &&
        !this.draftToSave?.isSaved
      ) {
        let draftsData: any;
        if (this.docToSave.isConverting == true) {
          draftsData = {
            data: {
              type: LABELS.SALES_ORDER,
              tableId: this.draftTableId,
              columnConfig: this.draftColumnConfig
            }
          };
        } else {
          draftsData = { ...this.draftToSave, draftType: 'NEW' };
        }

        try {
          const newRecord = await DraftService.createRecord(
            this.docToSave,
            draftsData
          );
          if (!Utility.isEmpty(newRecord)) {
            let triggerPayload = {
              ...this.docToSave,
              draftDocumentSequenceCode: newRecord?.data?.cells
                ?.documentSequenceCode
                ? newRecord?.data?.cells?.documentSequenceCode
                : ''
            };
            this.sendTriggerOnApproval(triggerPayload);
          }
          Store.dispatch(
            fetchDrafts({
              tableId: this.draftTableId,
              isSaveColumnId: this.isSaveColumnId,
              draftTypeColId: this.draftTypeColId,
              draftTypeColValue: LABELS.SALES_ORDER
            })
          );

          if (!!this.docToSave?.isConverting) {
            Store.dispatch(fetchSalesOrders());
            const buttons = [
              {
                title: 'Ok',
                className: 'bg-button, border-m',
                onClick: () => {}
              },
              {
                title: 'Goto Sales Order',
                className: ' bg-blue text-white ml-r',
                onClick: () => {
                  RouteManager.navigateToPage(PAGE_ROUTES.SALES_ORDERS);
                }
              }
            ];
            showAlert(
              'Sales Order created!',
              'Document has been created successfully.',
              buttons
            );
          }

          Store.dispatch(onDocumentClose({ draftId: this.draftId }));
          this.replaceURLCallback?.();
        } catch (err: any) {
          console.error('SalesOrderSaver -> Error while creating draft: ', err);
          this.replaceURLCallback?.();
        }
      } else {
        await super.saveDraft(this.docToSave);
        if (isApprovalRequired) {
          this.sendTriggerOnApproval(this.docToSave);
        }
        this.replaceURLCallback?.();
      }
    } catch (err: any) {}
  }

  /**
   * Handles create and update SO calls
   */
  save(closeOnUpdate = true) {
    if (this.draftType === DraftTypes.UPDATE) {
      this.updateSalesOrder(closeOnUpdate);
    } else {
      this.createSalesOrder(true);
    }
  }

  private updateForm(
    docResp: any,
    payload: any,
    isUpdate = false,
    closeOnUpdate = true
  ) {
    SalesOrderService.getSalesOrderByCode(docResp.salesOrderCode).then(
      (doc: any) => {
        const formData = {
          ...doc,
          documentType: DOC_TYPE.SALES_ORDER,
          items: [...doc.salesOrderItems].map((savedItem: any) => {
            const itemFromDocToSave = this.docToSave?.items?.find(
              (item: any) => item.lineNumber === savedItem.lineNumber
            );
            if (!Utility.isEmptyObject(itemFromDocToSave)) {
              return {
                ...itemFromDocToSave,
                ...savedItem
              };
            }
            return { ...savedItem };
          }),
          contact: {
            ...doc.contact,
            ...doc.contactDto
          },
          isDocumentTouched: false,
          documentDate: payload.documentDate,
          fulfillmentDate: payload.fulfillmentDate,
          validTillDate: payload.validTillDate
        };
        if (!closeOnUpdate) {
          Store.dispatch(
            updateMultipleKeysInDocument({
              draftId: this.draftId,
              keysToUpdate: formData
            })
          );
        } else {
          Store.dispatch(onDocumentClose({ draftId: this.draftId }));
          if (!isUpdate) {
            getDocumentAlert(
              'Sales order created!',
              'Sales order has been created successfully.',
              formData,
              this.draftToSave,
              DOCUMENT_MODE.EDIT,
              PAGE_ROUTES.SALES_ORDERS
            );
            setIsSavingDocument(this.draftId, false);
          }
        }
      },
      (err) => {
        console.error('Error loading updated doc: ', err);
        setIsSavingDocument(this.draftId, false);
      }
    );
  }

  private async createSalesOrder(closeOnCreate: boolean) {
    setIsSavingDocument(this.draftId, true);
    setCanValidateDocument(this.draftId, true);

    let payload: any = deepClone(this.docToSave);
    payload = updateAddressAsPerLocationCF(payload);
    if (this.tenantInfo.additionalSettings?.CASCADING_DISCOUNTS?.enable) {
      payload = {
        ...payload,
        salesOrderItems: rebuildCascadingDiscountsForSaving(payload)
      };
    }
    delete payload?.items;

    if (
      (this.documentMode === DOCUMENT_MODE.COPY ||
        this.documentMode === DOCUMENT_MODE.NEW) &&
      (payload?.duplicate || payload?.isConverting)
    ) {
      const isContactInactive = isDocContactInactive(payload?.contact);
      if (isContactInactive) {
        showAlert('Error', inactiveContactMessage);
        setIsSavingDocument(this.draftId, false);
        return;
      }
    }

    if (payload.attachmentIds?.length) {
      payload.attachments = payload.attachmentIds.map(
        (attachmentId: any) => `${attachmentId}`
      );
    }

    if (payload.reservedStock) {
      const isTracked = payload.salesOrderItems.some(
        (obj: any) =>
          obj.product.type === PRODUCT_TYPE.TRACKED ||
          obj.product.type === PRODUCT_TYPE.BILL_OF_MATERIALS
      );
      if (!isTracked) {
        payload = {
          ...payload,
          reservedStock: false
        };
      } else {
        payload = handleReservedQuantityDataObject(payload, 'salesOrderItems');
      }
    }

    if (!this.isDocValid(payload)) {
      setIsSavingDocument(this.draftId, false);
      return;
    }
    let isApproval = await Utility.isApprovalRequired(payload);
    if (isApproval === null) {
      setIsSavingDocument(this.draftId, false);
      return;
    } else if (isApproval) {
      this.saveAsDraft(isApproval);
      return;
    } else {
      payload['approvalStatus'] = APPROVAL_STATUS['NOT_REQUIRED'];
    }

    if (this.draftType === DraftTypes.DRAFT) {
      payload[
        'draftReferenceId'
      ] = `${this.draftTableId}/record/${this.draftId}`;
    }

    const processedCreditLimitObj = await checkCreditLimit(payload);
    if (
      processedCreditLimitObj &&
      processedCreditLimitObj.showAlertPopup &&
      processedCreditLimitObj.settings.creditLimitType !==
        CREDIT_LIMIT_TYPE.IGNORE
    ) {
      this.showCreditLimitAlert(
        processedCreditLimitObj.settings,
        payload,
        false,
        true,
        closeOnCreate
      );
    } else {
      this.makeCreateAPICall(payload, closeOnCreate);
    }
  }

  private makeCreateAPICall(payload: any, closeDoc: boolean) {
    payload = removeUnwantedPayloadKeysForDocument(payload);

    SalesOrderService.createSalesOrder(payload).then(
      (response: any) => {
        if (this.draftToSave) {
          if (closeDoc) {
            // Remove draft popup
            Store.dispatch(onDocumentClose({ draftId: this.draftId }));
          }
          if (this.draftType === DraftTypes.DRAFT) {
            Store.dispatch(
              deleteDrafts({
                recordId: this.draftId,
                tableId: this.draftTableId
              })
            );
            RateAnalysisService.updateRateAnalysis({
              documentCode: response.salesOrderCode,
              documentType: 'SALES_ORDER',
              productPriceMethod:
                this.tenantInfo?.additionalSettings?.RATE_ANALYSIS
                  ?.PRODUCT_PRICE_METHOD,
              isDraft: this.draftType === DraftTypes.DRAFT ? true : false,
              draftId: this.draftId,
              tableId: this.draftTableId
            })
              .then(() => {})
              .catch((err: any) => {
                console.error('Error updating rate analysis: ', err);
              });
          }
          this.updateForm(response, payload, false);
        } else {
          setIsSavingDocument(this.draftId, false);
        }
        Store.dispatch(
          fetchDrafts({
            tableId: this.draftTableId,
            isSaveColumnId: this.isSaveColumnId,
            draftTypeColId: this.draftTypeColId,
            draftTypeColValue: LABELS.SALES_ORDER
          })
        );
        Store.dispatch(fetchSalesOrders());
        if (payload.isPartialSalesOrder) {
          Store.dispatch(fetchQuotes());
        }
        this.replaceURLCallback?.();
      },
      (err) => {
        console.error('Error while creating sales order: ', err);
        setIsSavingDocument(this.draftId, false);
        showAlertOnDocAPIError(err);
        this.replaceURLCallback?.();
      }
    );
  }

  private async updateSalesOrder(closeOnUpdate = true) {
    setIsSavingDocument(this.draftId, true);
    setCanValidateDocument(this.draftId, true);

    let payload: any = deepClone(this.docToSave);
    payload = { ...payload, contact: payload.contactDto };
    payload = updateAddressAsPerLocationCF(payload);

    if (this.tenantInfo?.additionalSettings?.CASCADING_DISCOUNTS?.enable) {
      payload = {
        ...payload,
        salesOrderItems: rebuildCascadingDiscountsForSaving(payload)
      };
    }

    delete payload?.items;
    delete payload?.contactDto;

    if (payload.attachmentIds?.length) {
      payload.attachments = payload.attachmentIds.map(
        (attachmentId: any) => `${attachmentId}`
      );
    }

    if (payload.reservedStock) {
      const isTracked = payload.salesOrderItems.some(
        (obj: any) =>
          obj.product.type === PRODUCT_TYPE.TRACKED ||
          obj.product.type === PRODUCT_TYPE.BILL_OF_MATERIALS
      );
      if (!isTracked) {
        payload = {
          ...payload,
          reservedStock: false
        };
      } else {
        payload = handleReservedQuantityDataObject(payload, 'salesOrderItems');
      }
    }

    if (!this.isDocValid(payload)) {
      setIsSavingDocument(this.draftId, false);
      return;
    }

    const processedCreditLimitObj = await checkCreditLimit(payload);
    if (
      processedCreditLimitObj &&
      processedCreditLimitObj.showAlertPopup &&
      processedCreditLimitObj.settings.creditLimitType !==
        CREDIT_LIMIT_TYPE.IGNORE
    ) {
      this.showCreditLimitAlert(
        processedCreditLimitObj.settings,
        payload,
        true,
        closeOnUpdate
      );
    } else {
      this.makeUpdateAPICall(payload, closeOnUpdate);
    }
  }

  private makeUpdateAPICall(payload: any, closeOnUpdate: boolean) {
    payload.salesOrderItems.map((salesOrderItem: any) => {
      salesOrderItem.pendingQuantity = salesOrderItem.productQuantity;
      salesOrderItem.pendingQtyToConvert = salesOrderItem.productQuantity;
    });

    payload = removeUnwantedPayloadKeysForDocument(payload);

    SalesOrderService.updateSalesOrder(
      payload,
      this.docToSave?.id as number
    ).then(
      (response) => {
        if (this.draftToSave) {
          if (closeOnUpdate) {
            Store.dispatch(onDocumentClose({ draftId: this.draftId }));
          }
          setIsSavingDocument(this.draftId, false);
          this.updateForm(response, payload, true, closeOnUpdate);
        }
        Store.dispatch(
          fetchDrafts({
            tableId: this.draftTableId,
            isSaveColumnId: this.isSaveColumnId,
            draftTypeColId: this.draftTypeColId,
            draftTypeColValue: LABELS.SALES_ORDER
          })
        );
        Store.dispatch(fetchSalesOrders());
        setIsSavingDocument(this.draftId, false);
        this.replaceURLCallback?.();
      },
      (err) => {
        console.error(
          'SalesOrderSaver -> Error while updating sales order: ',
          err
        );
        setIsSavingDocument(this.draftId, false);
        showAlertOnDocAPIError(err);
        this.replaceURLCallback?.();
      }
    );
  }

  private showCreditLimitAlert(
    creditLimitSettings: any,
    payload: any,
    isUpdate = false,
    closeOnUpdate = false,
    closeOnCreate = false
  ) {
    let buttons = [];

    buttons.push({
      title:
        creditLimitSettings.creditLimitType === CREDIT_LIMIT_TYPE.WARN
          ? 'Cancel'
          : 'Ok',
      className: 'border-m',
      onClick: () => {
        setIsSavingDocument(this.draftId, false);
      }
    });

    if (creditLimitSettings.creditLimitType === CREDIT_LIMIT_TYPE.WARN) {
      buttons.push({
        title: 'Save',
        className: 'bg-blue text-white ml-r',
        onClick: () => {
          if (isUpdate) {
            this.makeUpdateAPICall(payload, closeOnUpdate);
          } else {
            this.makeCreateAPICall(payload, closeOnCreate);
          }
        }
      });
    }

    const baseCurrencyCode = this.tenantInfo?.currency;
    const currencySymbol = Utility.getCurrencySymbolFromCode(baseCurrencyCode);

    let message = '';
    if (creditLimitSettings.creditLimitType === CREDIT_LIMIT_TYPE.WARN) {
      message = `Credit Limit for ${payload?.contact?.name} has reached. Do you wish to proceed?`;
    }

    if (creditLimitSettings.creditLimitType === CREDIT_LIMIT_TYPE.BLOCK) {
      message += `Credit Limit for ${payload?.contact?.name} has reached. You cannot proceed.<br/><br/>`;
      message += '<ul>';
      message += `<li>Credit Limit: ${currencySymbol}${NumberFormatService.getNumber(
        creditLimitSettings.creditLimitInBaseCurrency
      )}</li>`;
      message += `<li>Amount Due: ${currencySymbol}${NumberFormatService.getNumber(
        creditLimitSettings.totalDueAmount
      )}</li>`;
      message += creditLimitSettings.includeCurrentDoc
        ? '<li class="text-gray" style="font-size: 12px;">(Incl. current sales order)</li>'
        : '';
      message += '</ul>';
    }

    showAlert('Credit Limit Reached', message, buttons);
  }

  private isDocValid(docToValidate: any) {
    // Validate Manual Document Sequence Code
    if (
      Utility.isEmpty(docToValidate.documentSequenceCode) &&
      Utility.isEmpty(docToValidate.sequenceFormat) &&
      docToValidate.manualMode
    ) {
      return false;
    }

    if (Utility.isEmpty(docToValidate.contact)) {
      return false;
    }

    if (Utility.isEmpty(docToValidate.salesOrderItems)) {
      return false;
    }

    if (!Utility.isEmpty(docToValidate.documentDate)) {
      const label = 'Order date';
      const docDate = DateFormatService.getDateFromStr(
        docToValidate.documentDate,
        BOOKS_DATE_FORMAT['DD-MM-YYYY']
      );
      if (
        !Utility.checkActiveDateRangeValidation(
          docDate,
          this.tenantInfo,
          label,
          docToValidate.documentType
        )
      ) {
        return false;
      }
      if (!Utility.checkClosingDate(docDate, label)) {
        return false;
      }
    }

    // Custom fields validation
    const customFieldHasErrors = customFieldsContainsErrors(
      docToValidate.customField
    );
    if (customFieldHasErrors) {
      return false;
    }
    // Custom fields validation ends

    // Line item errors
    let lineItemsHasErrors = false;
    for (let i = 0; i < docToValidate.salesOrderItems.length; i++) {
      const item = docToValidate.salesOrderItems[i];
      if (item.hasError || item.invalidFields?.length) {
        lineItemsHasErrors = true;
        break;
      }
    }

    if (lineItemsHasErrors) {
      return false;
    }
    // Line item errors ends

    // Contact GSTIN check
    if (!checkGSTINPresentForSelectedContact(docToValidate)) {
      return false;
    }
    // Contact GSTIN check ends

    // Check for -ve total amount
    const totalBeforeTax = docToValidate?.salesOrderItems?.length
      ? docToValidate?.salesOrderItems?.reduce((total: number, item: any) => {
          return total + item?.totalWithDiscount;
        }, 0)
      : 0;
    if (totalBeforeTax < 0) {
      showAlert(
        'Invalid amount!',
        'Sales order amount can not be less than 0.'
      );
      return false;
    }
    // Check for -ve total amount ends

    // Cascading discounts validation
    const cascadingDiscountSettings =
      this.tenantInfo.additionalSettings?.CASCADING_DISCOUNTS;
    if (cascadingDiscountSettings?.enable) {
      const isTotalDiscountInvalid = checkIfTotalDiscountInvalid(
        docToValidate,
        'salesOrderItems'
      );
      if (isTotalDiscountInvalid) {
        showAlert('Error!', cascadingDiscountsInvalidMessage);
        return false;
      }
    }
    // Cascading discounts validation ends

    // Additional charges check
    let additionalChargesHasErrors = false;
    const additionalCharges =
      docToValidate.additionalCharges?.additionalChargesDetails;
    if (!Utility.isEmpty(additionalCharges)) {
      for (let i = 0; i < additionalCharges.length; i++) {
        const item = additionalCharges[i];
        if (item.hasError) {
          additionalChargesHasErrors = true;
          break;
        }
      }
    }
    if (additionalChargesHasErrors) {
      return false;
    }
    // Additional charges check ends

    // Discount check
    const discountHasErrors =
      docToValidate.additionalCharges?.globalDiscount?.hasError;
    if (discountHasErrors) {
      return false;
    }
    // Discount check ends

    // Validation for reserved stock
    if (docToValidate.reservedStock) {
      const isStockInvalid = docToValidate.salesOrderItems.some(
        (obj: any) =>
          (obj.product?.type === PRODUCT_TYPE.TRACKED ||
            obj.product?.type === PRODUCT_TYPE.BILL_OF_MATERIALS) &&
          Utility.isEmpty(obj.reservedQuantitiesData)
      );
      if (isStockInvalid) {
        showAlert('Error!', 'Insufficient Quantity to reserved');
        return false;
      }
    }
    // Validation for reserved stock ends

    //validation product having create Revenue plan on REVENUE_ARRANGEMENT

    const revRecPlansWithoutDates = docToValidate.salesOrderItems.filter(
      (itemData: any) => {
        return (
          itemData?.product?.revenueRecognitionInfo?.isDirectRevenuePositing ===
            false &&
          itemData?.product?.revenueRecognitionInfo?.allocationType !==
            'EXCLUDE' &&
          itemData?.product?.revenueRecognitionInfo?.createRevenuePlanOn ===
            'REVENUE_ARRANGEMENT' &&
          (itemData?.revRecEndDate === null ||
            itemData?.revRecStartDate === null)
        );
      }
    );
    if (!Utility.isEmpty(revRecPlansWithoutDates)) {
      showAlert('Error!', 'Rev Rec Start Date or End Date should not be empty');
      return false;
    }
    return true;
  }
}
