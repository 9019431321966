import { useRef } from 'react';
import { ORGANISATION_FORM_TAB } from '../../../../Constants/Enum';
import {
  POPUP_CALLBACKS_TYPE,
  POPUP_CLICK_TYPE,
  POPUP_TYPE
} from '../../../../Constants/Constant';
import { localizedText } from '../../../../Services/Localization/Localization';
import { isViewportLarge } from '../../../../Utility/ViewportSizeUtils';
import {
  BtnType,
  CallBackPayloadType,
  PopupClickActionType
} from '../../../../Models/Interfaces';
import OrganisationProfileForm from '../../../Settings/OrganisationProfile/OrganisationProfileForm';
import PopupWrapper from '../../../../SharedComponents/PopupWrapper';
import { getCapitalized } from '../../../../Utility/Utility';

interface IDocOrgUpdatePopupProps {
  activeOrgProfileTab: ORGANISATION_FORM_TAB;
  onClosePopup: () => void;
}

const DocOrgUpdatePopup = (props: IDocOrgUpdatePopupProps) => {
  const editAddressRef = useRef({
    storeCallbacksRef: { updateOrganisationProfile: () => {} }
  });

  const isDesktop = isViewportLarge();

  const popupConfigForOrganisationProfile: BtnType[] = [
    {
      title: 'Cancel',
      class: 'border-m mr-s',
      clickAction: POPUP_CLICK_TYPE.CLOSE_POPUP
    },
    {
      title: 'Update',
      class: 'bg-app text-white mr-ss',
      clickAction: POPUP_CLICK_TYPE.UPDATE_ORGANISATION
    }
  ];

  const catchClicks = (data: PopupClickActionType) => {
    try {
      switch (data.type) {
        case POPUP_CLICK_TYPE.CLOSE_POPUP:
          props.onClosePopup();
          break;
        case POPUP_CLICK_TYPE.UPDATE_ORGANISATION:
          editAddressRef.current?.storeCallbacksRef.updateOrganisationProfile();
          break;
      }
    } catch (err) {}
  };

  const parentChildInteraction = (passingData: CallBackPayloadType) => {
    switch (passingData.type) {
      case POPUP_CALLBACKS_TYPE.UPDATE_ORGANISATION:
        editAddressRef.current.storeCallbacksRef.updateOrganisationProfile =
          passingData.data;
        break;
      case POPUP_CALLBACKS_TYPE.CLOSE_POPUP:
        props.onClosePopup();
        break;
      default:
        break;
    }
  };

  return (
    <PopupWrapper
      clickAction={catchClicks}
      type={POPUP_TYPE.POPUP}
      title={`Update ${getCapitalized(localizedText('organisation'))} Profile`}
      btnList={popupConfigForOrganisationProfile}
      width={'40%'}
      height={!isDesktop ? '75%' : '95%'}
      disableClickOutside
    >
      <OrganisationProfileForm
        passingInteraction={(callback: CallBackPayloadType) => {
          parentChildInteraction(callback);
        }}
        activeTab={props.activeOrgProfileTab}
      />
    </PopupWrapper>
  );
};

export default DocOrgUpdatePopup;
