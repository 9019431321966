import { useContext, useEffect, useState } from 'react';
import {
  DOC_TYPE,
  POPUP_CALLBACKS_TYPE,
  POPUP_CLICK_TYPE,
  POPUP_TYPE
} from '../../../../../Constants/Constant';
import {
  BtnType,
  CallBackPayloadType,
  PopupClickActionType
} from '../../../../../Models/Interfaces';
import PopupWrapper from '../../../../../SharedComponents/PopupWrapper';
import { isViewportLarge } from '../../../../../Utility/ViewportSizeUtils';
import AppManager from '../../../../../Managers/AppManager';
import PaymentRecords from '../../../../../SharedComponents/PaymentRecords/PaymentRecords';
import { useAppDispatch, useAppSelector } from '../../../../../Redux/Hooks';
import { CommonDraftPropsContext } from '../../../Utilities/DocContext';
import {
  selectCurrentActiveDocument,
  selectDocumentFormDataByKeys
} from '../../../../../Redux/Slices/DocumentSlice';
import { DOCUMENT_KEYS } from '../../../Utilities/DocConstants';
import InvoiceService from '../../../../../Services/Invoice';
import { getUpdatedInvoiceObject } from '../../../../Invoices/InvoiceHelper';
import {
  FULFILLMENT_STATUS,
  RECEIVED_GOODS_STATUS
} from '../../../../Automation/AutomationConstants';
import { DraftTypes } from '../../../../../Models/Drafts';
import { updatePopulateFormData } from '../../../../../Redux/Slices/DraftsSlice';
import BillService from '../../../../../Services/Bill';
import { getUpdatedBillObject } from '../../../../Bills/BillHelper';
import QuotationService from '../../../../../Services/Quotation';
import { getUpdatedQuoteObject } from '../../../../Quotations/QuoteHelper';
import PurchaseOrderService from '../../../../../Services/PurchaseOrder';
import { getUpdatedPurchaseOrderObject } from '../../../../PurchaseOrders/PurchaseOrderHelper';
import RequisitionService from '../../../../../Services/Requisition';
import { getUpdatedRequisitonOrderObject } from '../../../../Requisitions/RequisitionHelper';
import SalesOrderService from '../../../../../Services/SalesOrder';
import { getUpdatedSalesOrderObject } from '../../../../SalesOrders/SalesOrderHelper';
import WorkoutService from '../../../../../Services/Workout';
import { getJobWorkOutObject } from '../../../../Mrp/Workout/WorkOutHelper';
import { useTranslation } from 'react-i18next';

export const PaymentRecordsActionPopup = (props: any) => {
  const dispatch = useAppDispatch();
  const { draftId, documentMode, draftType } = useContext(
    CommonDraftPropsContext
  );
  const documentObject = useAppSelector(selectCurrentActiveDocument(draftId));
  const [documentType] = useAppSelector(
    selectDocumentFormDataByKeys(draftId, [DOCUMENT_KEYS.DOCUMENT_TYPE])
  );
  const [isDesktop, setIsDesktop] = useState(isViewportLarge());
  const { t, i18n } = useTranslation();

  useEffect(() => {
    AppManager.handleWindowResizeListener(onWindowResize, true);
    return () => {
      AppManager.handleWindowResizeListener(onWindowResize, false);
    };
  }, []);

  const onWindowResize = () => {
    setIsDesktop(isViewportLarge);
  };

  const catchClicks = (data: PopupClickActionType) => {
    switch (data.type) {
      case POPUP_CLICK_TYPE.CLOSE_POPUP:
        props.removePopup();
    }
  };

  const paymentRecordsBtnConfig: BtnType[] = [
    {
      title: t(`Close`),
      class: 'border-m mr-s',
      clickAction: POPUP_CLICK_TYPE.CLOSE_POPUP
    }
  ];

  const updateDocument = (doc: any, paymentUpdated: boolean) => {
    let draftType;

    if (paymentUpdated) {
      if (doc.knockoffInfo) {
        if (doc.knockoffInfo.length) {
          draftType = DraftTypes.READONLY;
        } else {
          draftType = DraftTypes.UPDATE;
        }
      } else {
        draftType = DraftTypes.UPDATE;
      }
    } else {
      if (
        doc.documentType === DOC_TYPE.INVOICE ||
        doc.documentType === DOC_TYPE.QUOTE ||
        doc.documentType === DOC_TYPE.SALES_ORDER ||
        doc.documentType === DOC_TYPE.REQUISITION ||
        doc.documentType === DOC_TYPE.JOB_WORK_OUT_ORDER
      ) {
        if (doc.fulfillmentStatus === FULFILLMENT_STATUS.UNFULFILLED) {
          draftType = DraftTypes.UPDATE;
        } else {
          draftType = DraftTypes.READONLY;
        }
      }

      if (doc.documentType === DOC_TYPE.BILL) {
        if (doc.receiveGoodsStatus === RECEIVED_GOODS_STATUS.NOT_RECEIVED) {
          draftType = DraftTypes.UPDATE;
        } else {
          draftType = DraftTypes.READONLY;
        }
      }

      if (doc.documentType === DOC_TYPE.ORDER) {
        if (doc.receiptStatus === RECEIVED_GOODS_STATUS.NOT_RECEIVED) {
          draftType = DraftTypes.UPDATE;
        } else {
          draftType = DraftTypes.READONLY;
        }
      }
    }

    dispatch(
      updatePopulateFormData({
        id: props.draftId,
        formdata: doc,
        draftType: draftType,
        actionFromDocument: true
      })
    );
  };

  const parentChildInteraction = (passingData: CallBackPayloadType) => {
    const paymentUpdated = props.showPaymentPopup || props.showPaymentRecord;
    switch (passingData.type) {
      case POPUP_CALLBACKS_TYPE.CLOSE_POPUP:
        if (documentType === DOC_TYPE.INVOICE) {
          InvoiceService.getInvoiceByCode(
            props.booksDocument.salesInvoiceCode
          ).then(
            (invoice: any) => {
              let invoiceDetailsData: any = getUpdatedInvoiceObject(invoice);
              updateDocument(invoiceDetailsData, paymentUpdated);
              props.removePopup();
            },
            (err) => {
              console.error('Error loading updated invoice: ', err);
            }
          );
        }
        if (documentType === DOC_TYPE.BILL) {
          BillService.fetchBillDetails(
            props.booksDocument.purchaseInvoiceCode
          ).then(
            (bill: any) => {
              const billDetailsData: any = getUpdatedBillObject(bill);
              updateDocument(billDetailsData, paymentUpdated);
              props.removePopup();
            },
            (err) => {
              console.error('Error loading updated bill: ', err);
            }
          );
        }
        if (documentType === DOC_TYPE.QUOTE) {
          QuotationService.getQuoteByCode(
            props.booksDocument.quotationCode
          ).then(
            (data: any) => {
              const quoteDetailsData: any = getUpdatedQuoteObject(data);
              updateDocument(quoteDetailsData, paymentUpdated);
              props.removePopup();
            },
            (err) => {
              console.error('Error loading updated quotation: ', err);
            }
          );
        }
        if (documentType === DOC_TYPE.ORDER) {
          PurchaseOrderService.fetchOrderDetails(
            props.booksDocument.poCode
          ).then(
            (data: any) => {
              const poDetailsData: any = getUpdatedPurchaseOrderObject(data);
              updateDocument(poDetailsData, paymentUpdated);
              props.removePopup();
            },
            (err) => {
              console.error('Error loading Purchase order: ', err);
            }
          );
        }
        if (documentType === DOC_TYPE.REQUISITION) {
          RequisitionService.fetchOrderDetails(
            props.booksDocument.purchaseRequestCode
          ).then(
            (data: any) => {
              const poDetailsData: any = getUpdatedRequisitonOrderObject(data);
              updateDocument(poDetailsData, paymentUpdated);
              props.removePopup();
            },
            (err) => {
              console.error('Error loading requisition order: ', err);
            }
          );
        }

        if (documentType === DOC_TYPE.SALES_ORDER) {
          SalesOrderService.getSalesOrderByCode(
            props.booksDocument.salesOrderCode
          ).then(
            (data: any) => {
              const salesOrderDetailsData: any =
                getUpdatedSalesOrderObject(data);
              updateDocument(salesOrderDetailsData, paymentUpdated);
              props.removePopup();
            },
            (err) => {
              console.error('Error loading updated order: ', err);
            }
          );
        }

        if (documentType === DOC_TYPE.JOB_WORK_OUT_ORDER) {
          WorkoutService.fetchJobWorkOutDetails(
            props.booksDocument.jwoCode
          ).then(
            (data: any) => {
              const jobWorkOutDetailsData: any = getJobWorkOutObject(data);
              updateDocument(jobWorkOutDetailsData, paymentUpdated);
              props.removePopup();
            },
            (err) => {
              console.error('Error loading updated Job Work Out: ', err);
            }
          );
        }

        break;
    }
  };

  return (
    <PopupWrapper
      clickAction={catchClicks}
      type={POPUP_TYPE.POPUP}
      title={t(`INVOICES.DIALOG.PAYMENT_RECORDS.TITLE`)}
      btnList={paymentRecordsBtnConfig}
      width={!isDesktop ? '90%' : '50%'}
      height="400px"
      disableClickOutside={true}
      maxHeight={'90%'}
    >
      <PaymentRecords
        documentSequenceCode={documentObject?.documentSequenceCode}
        paymentRecordList={documentObject?.knockoffInfo}
        documentType={documentType}
        documentCode={
          documentType === DOC_TYPE.INVOICE
            ? documentObject?.salesInvoiceCode
            : documentObject?.purchaseInvoiceCode
        }
        contactCode={documentObject?.contactCode ?? ''}
        closePopup={() => {
          props.removePopup();
        }}
        passingInteraction={(callback: CallBackPayloadType) => {
          parentChildInteraction(callback);
        }}
      />
    </PopupWrapper>
  );
};
