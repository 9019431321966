import {
  DKButton,
  DKDataGrid,
  DKIcons,
  DKInput,
  DKLabel,
  DKSpinner,
  INPUT_TYPE,
  showAlert,
  showLoader,
  removeLoader
} from 'deskera-ui-library';
import React, { useEffect, useRef, useState } from 'react';
import ic_bom_allocate_green from '../../../Assets/Icons/ic_bom_allocate_green.svg';
import ic_bom_allocate_red from '../../../Assets/Icons/ic_bom_allocate_red.svg';
import ApiConstants from '../../../Constants/ApiConstants';
import {
  BOOKS_DATE_FORMAT,
  CLASS_ASSIGNMENT,
  CUSTOM_FIELD_TYPE,
  DOCUMENT_MODE,
  INPUT_VIEW_DIRECTION,
  LOCATION_CLASS_ENUM,
  MODULES_NAME,
  PRODUCT_TYPE,
  QTY_ROUNDOFF_PRECISION,
  STATUS_TYPE,
  TRACKING_TYPE
} from '../../../Constants/Constant';
import useQCConfirm from '../../../Hooks/useQCConfirm';
import RouteManager, { PAGE_ROUTES } from '../../../Managers/RouteManager';
import { CustomField } from '../../../Models/CustomField';
import { useAppDispatch, useAppSelector } from '../../../Redux/Hooks';
import { selectedAccounts } from '../../../Redux/Slices/AccountsSlice';
import { activeTenantInfo } from '../../../Redux/Slices/AuthSlice';

import {
  fetchClassesByDimensionId,
  fetchCustomFields,
  selectCustomFields,
  selectProductCustomFields,
  selectShowMainDocsInFullScreen,
  selectUOMs,
  setShowMainDocsInFullScreen
} from '../../../Redux/Slices/CommonDataSlice';
import {
  fetchWarehouseByCode,
  selectStockAdjustmentAllProductBrief,
  selectStockAdjustmentColumnConfig,
  selectStockAdjustmentColumnConfigTableId
} from '../../../Redux/Slices/StockAdjustmentSlice';
import {
  selectedWarehouseWithRRBCombination,
  selectWarehouse
} from '../../../Redux/Slices/WarehouseSlice';
import AccountsService, { AccountAPIConfig } from '../../../Services/Accounts';
import DateFormatService from '../../../Services/DateFormat';
import ProductService from '../../../Services/Product';
import CommonQualityCheckService from '../../../Services/QualityCheck';
import StockAdjustmentService from '../../../Services/StockAdjustment';
import StockTransferService, {
  StockTransferAPIConfig
} from '../../../Services/StockTransfer';
import { CustomFieldsHolder } from '../../../SharedComponents/CustomFieldsHolder/CustomFieldsHolder';
import { customFieldsContainsErrors } from '../../../SharedComponents/DocumentForm/NewDocumentHelper';
import ReturnWarehouseManagementRRB from '../../../SharedComponents/WarehouseManagement/ReturnWarehouseManagementRRB';
import WarehouseManagementHelper from '../../../SharedComponents/WarehouseManagement/WarehouseManagementHelper';
import { DocumentConfigUtility } from '../../../Utility/DocumentConfigUtility';
import Utility, {
  convertBooksDateFormatToUILibraryFormat,
  deepClone,
  getRandomAlphaNumericString
} from '../../../Utility/Utility';
import { QC_FLOW_MODULES } from '../../Settings/QcFlow/QCFlowConstant';
import StockAdjustmentAdvancedStockTracking from './StockAdjustmentAdvancedStockTracking';
import {
  STOCK_ADJUSTMENT_ACTION_TYPE,
  StockAdjustment,
  StockAdjustmentItems,
  StockAdjustmentType,
  StockInReasons,
  StockOutReasons,
  STOCK_ADJUSTMENT_TYPE,
  IStockAdjustmentItem
} from './StockAdjustmentConstant';
import { checkDateValidation, createPayload } from './StockAdjustmentHelper';
import {
  isTabletView,
  isViewportLarge
} from '../../../Utility/ViewportSizeUtils';
import { fetchCategoryDimensions } from '../../../Redux/Slices/LocationSlice';
import { getNewColumn } from '../../Accounting/JournalEntry/JEHelper';
import AddClass from '../../Settings/Classes/AddClass';
import { map } from 'lodash';
// import { selectWarehouseProducts } from '../../../Redux/Slices/WarehouseProductSlice';
import { IColumn } from '../../../Models/Table';
import { DynamicPopupWrapper } from '../../../SharedComponents/PopupWrapper';
import { amountFormatter } from '../../Mrp/WorkOrder/WorkOrderHelper';
import { isAfter } from 'date-fns';
import AddAdjustmentReason from './AddAdjustmentReason';
import { ADVANCE_TRACKING } from '../../../Constants/Enum';
import ic_expand from '../../../Assets/Icons/ic_expand.svg';
import ic_shrink from '../../../Assets/Icons/ic_shrink.svg';
import WarehouseService from '../../../Services/Warehouse';

const CreateStockAdjustment = (props: any) => {
  /**
   * STATES & REFS
   */
  const [isLoading, setIsLoading] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(-1);
  const selectedProductUOMs = useRef<any>([]);
  const [columnConfigForGrid, setColumnConfigForGrid] = useState<IColumn[]>([]);
  const [stockAdjustmentState, setStockAdjustmentState] =
    useState<StockAdjustment>({
      autoNumberingFormat: '',
      warehouseCode: '',
      rowCode: null,
      rackCode: null,
      binCode: null,
      notes: '',
      stockAdjustmentItems: [],
      adjustmentDate: new Date(),
      adjustmentType: StockAdjustmentType[0],
      adjustmentReason: '',
      totalValue: 0,
      customField: []
    });
  const [showAdvancedStockTrackingPopup, setShowAdvancedStockTrackingPopup] =
    useState<boolean>(false);
  const [
    selectedAdvancedStockTrackingProduct,
    setSelectedAdvancedStockTrackingProduct
  ] = useState<any>('');
  const [isEdit, setIsEdit] = useState<boolean>(
    Utility.isNotEmpty(props?.data)
  );
  const [originalProductList, setOriginalProductList] = useState<any[]>([]);
  const [selectedWarehouse, setSelectedWarehouse] = useState<any>('');

  const [showAddReasonPopup, setShowAddReasonPopup] = useState<boolean>(false);
  const showMainDocsInFullscreen = useAppSelector(
    selectShowMainDocsInFullScreen
  );
  const isFullScreenMode = !!props.allowFullScreen && showMainDocsInFullscreen;

  /**
   * DISPATCH
   */
  const dispatch = useAppDispatch();
  const { qcConfirm } = useQCConfirm();

  /**
   * APP SELECTORS
   */
  const warehousesData: any = useAppSelector(
    selectedWarehouseWithRRBCombination
  );
  const uomData = useAppSelector(selectUOMs);
  const tenantInfo = useAppSelector(activeTenantInfo);
  // const sourceWarehouses: any = useAppSelector(
  //   selectSourceWarehousesWithRejectedWh
  // );
  const sourceWarehouses: any = warehousesData;
  const stockAdjustmentProducts: any = useAppSelector(
    selectStockAdjustmentAllProductBrief
  );
  const columnConfig = useAppSelector(selectStockAdjustmentColumnConfig);
  const columnConfigTableId: any = useAppSelector(
    selectStockAdjustmentColumnConfigTableId
  );
  const accountList = useAppSelector(selectedAccounts);
  const [accounts, setAccounts] = useState<any[]>([]);
  const [currentReasonToEditOrDelete, setCurrentReasonToEditOrDelete] =
    useState<any>();
  const [reasonsArray, setReasonsArray] = useState<any>([]);
  const [saveButtonTapped, setSaveButtonTapped] = useState(false);
  const selectCustomFieldsData: any = useAppSelector(selectCustomFields);
  const [showAddClassPopup, setShowAddClassPopup] = useState(false);
  // const WarehouseProductsData = useAppSelector(selectWarehouseProducts);
  const [productCustomFields, setProductCustomFields] = useState<any[]>([]);
  const productCFfromStore = useAppSelector(selectProductCustomFields);

  // Tablet Attributes
  const [tempProduct, setTempProduct] = useState<any>(props.tempProduct);

  /**
   * USE EFFECTS
   */

  useEffect(() => {
    setColumnConfigForGrid(getColumns());
    if (Utility.isEmpty(props?.data)) {
      let primaryWarehouse = getActiveWarehouses().find(
        (warehouse: any) => warehouse.primary
      );
      if (Utility.isNotEmpty(primaryWarehouse)) {
        updateStockAdjustmentFormValue('warehouseCode', primaryWarehouse);
      }
    }
    if (Utility.isNotEmpty(props?.data)) {
      dispatch(fetchWarehouseByCode(props?.data?.warehouseCode));
    }
  }, []);

  useEffect(() => {
    const getStockAdjustmentItem = (
      stockAdjustmentItem: any
    ): StockAdjustmentItems => {
      const item: StockAdjustmentItems = {
        stockItemNumber: getRandomAlphaNumericString(8),
        productVariantCode: stockAdjustmentItem?.productVariantCode,
        documentSequenceCode: stockAdjustmentItem?.productSequenceCode,
        productType: stockAdjustmentItem?.advancedTracking,
        quantity: stockAdjustmentItem?.uomQuantity,
        perUnitValue: stockAdjustmentItem.uomUnitPrice,
        productName: stockAdjustmentItem?.productName,
        batchDetails: stockAdjustmentItem?.batchDetails,
        serialNumbers: stockAdjustmentItem?.serialNumbers,
        documentUom: stockAdjustmentItem?.documentUom,
        documentUOMSchemaDefinition:
          stockAdjustmentItem?.documentUOMSchemaDefinition,
        uomSchema: stockAdjustmentItem?.documentUOMSchemaDefinition,
        uomQuantity: stockAdjustmentItem?.uomQuantity,
        uomUnitPrice: stockAdjustmentItem?.uomUnitPrice,
        totalAmount:
          stockAdjustmentItem.uomUnitPrice * stockAdjustmentItem?.uomQuantity,
        stockAdjustmentAccountCode:
          stockAdjustmentItem?.stockAdjustmentAccountCode,
        stockAdjustmentAccountName:
          stockAdjustmentItem?.stockAdjustmentAccountName,
        // availableQuantity:
        //   Utility.isEmpty(stockAdjustmentItem?.documentUOMSchemaDefinition) ||
        //   !stockAdjustmentItem?.documentUOMSchemaDefinition?.schemaId
        //     ? getProductAvailableQuantity(
        //         stockAdjustmentItem?.productVariantCode
        //       )
        //     : Utility.getUomQuantity(
        //         getProductAvailableQuantity(
        //           stockAdjustmentItem?.productVariantCode
        //         ),
        //         stockAdjustmentItem?.documentUOMSchemaDefinition
        //       ),
        ...stockAdjustmentItem?.customField?.reduce(
          (acc: any, item: any) => getCustomFields(acc, item),
          {}
        )
      };
      item['advancedTracking'] = TRACKING_TYPE.NONE;
      if (Utility.isNotEmpty(item?.batchDetails)) {
        item['advancedTracking'] = TRACKING_TYPE.BATCH;
      } else {
        if (Utility.isNotEmpty(item?.serialNumbers)) {
          item['advancedTracking'] = TRACKING_TYPE.SERIAL;
        }
      }
      item['productType'] = item['advancedTracking'];
      item['rowContextMenu'] = getButtonsForRow(item);
      assignTrackingIcon(item);
      return item;
    };
    const getCustomFields = (acc: any, item: any) => {
      let filteredCF: any = selectCustomFieldsData?.content?.find(
        (cf: any) => cf.code === item.code
      );
      if (!Utility.isEmpty(filteredCF)) {
        let cfValue;
        if (
          filteredCF.fieldType.toLowerCase() === INPUT_TYPE.DATE.toLowerCase()
        ) {
          cfValue = DateFormatService.getDateFromStr(
            item.value,
            BOOKS_DATE_FORMAT['MM/DD/YYYY']
          );
        } else if (filteredCF.fieldType.toLowerCase() === 'user') {
          const tempCF = filteredCF?.attributes?.find(
            (attr: any) => attr.code === item.value
          );
          if (tempCF) {
            cfValue = tempCF ? tempCF : '';
          }
        } else if (
          filteredCF.fieldType.toLowerCase() ===
          CUSTOM_FIELD_TYPE.DROPDOWN.toLowerCase()
        ) {
          cfValue = item ? item : '';
        } else {
          cfValue = item.value ? item.value : '';
        }
        acc[item.id] = cfValue;
      }
      return acc;
    };
    const checkForEditCase = async () => {
      const stockAdjustmentEntry = deepClone(props.data);
      const updatedState = { ...stockAdjustmentState };
      const selectedWarehouse = getActiveWarehouses()?.find(
        (warehouse: any) =>
          warehouse.code === stockAdjustmentEntry?.warehouseCode
      );
      updatedState['autoNumberingFormat'] = stockAdjustmentEntry.code;
      updatedState['warehouseCode'] = selectedWarehouse;
      const adjustmentType = StockAdjustmentType.find(
        (stockType: any) =>
          stockType.value === stockAdjustmentEntry?.adjustmentType[0]
      );
      updatedState['adjustmentType'] = adjustmentType;
      let adjustmentReason = null;
      let adjustmentReasons = StockInReasons;
      if (adjustmentType?.value === STOCK_ADJUSTMENT_TYPE.STOCK_OUT) {
        adjustmentReasons = StockOutReasons;
      }
      adjustmentReason = adjustmentReasons.find(
        (reason: any) =>
          reason.value === stockAdjustmentEntry?.stockAdjustmentReason?.code
      );
      if (Utility.isEmpty(adjustmentReason)) {
        const reasons = await getAdjustmentReasonOptions(adjustmentType?.value);
        adjustmentReason = reasons?.find(
          (reason: any) =>
            reason.code === stockAdjustmentEntry?.stockAdjustmentReason?.code
        );
      }
      updatedState['adjustmentReason'] = adjustmentReason;
      const { stockAdjustmentItems } = stockAdjustmentEntry;
      const newStockAdjustmentItems = stockAdjustmentItems?.map(
        getStockAdjustmentItem
      );
      try {
        updatedState['adjustmentDate'] = stockAdjustmentEntry?.adjustmentDate
          ? new Date(stockAdjustmentEntry?.adjustmentDate)
          : new Date();
      } catch (error) {}
      updatedState['stockAdjustmentItems'] = newStockAdjustmentItems;
      updatedState['notes'] = stockAdjustmentEntry?.notes;

      setStockAdjustmentState({ ...updatedState });
    };
    if (Utility.isNotEmpty(props?.data)) {
      setIsEdit(true);
      getSelectedWarehouseOnEditMode(props?.data?.warehouseCode);
      checkForEditCase();
    }
  }, [props.data]);

  useEffect(() => {
    if (
      !Utility.isEmpty(productCFfromStore) &&
      productCFfromStore?.content?.length
    ) {
      let prodCFs = [...productCFfromStore?.content];
      prodCFs.sort(
        (field1: any, field2: any) =>
          field1.customFieldIndex - field2.customFieldIndex
      );
      setProductCustomFields(prodCFs);
    }
  }, [productCFfromStore]);

  useEffect(() => {
    const whData = sourceWarehouses?.content;
    if (!Utility.isEmpty(whData)) {
      if (!isTabletView()) {
        setSelectedWarehouse(whData?.[0]);
        setWarehouseValues(whData?.[0]);
      } else if (tempProduct) {
        let warehouseId = tempProduct.inventory.warehouseCode;
        let warehouse = whData?.find((item: any) => item.code === warehouseId);
        setSelectedWarehouse(warehouse ? warehouse : whData?.[0]);
        setWarehouseValues(warehouse ? warehouse : whData?.[0]);
      } else {
        setSelectedWarehouse(whData?.[0]);
        setWarehouseValues(whData?.[0]);
      }
    }
  }, [sourceWarehouses]);

  useEffect(() => {
    if (!Utility.isEmpty(sourceWarehouses?.content)) {
      setWarehouseValues(selectedWarehouse);
    }
  }, [selectedWarehouse]);

  useEffect(() => {
    if (!Utility.isEmpty(stockAdjustmentProducts)) {
      setOriginalProductList(stockAdjustmentProducts);
    }

    if (isTabletView() && tempProduct && stockAdjustmentProducts) {
      let newState = { ...stockAdjustmentState };
      let rows = [];
      let rowData = stockAdjustmentProducts.find(
        (item: any) => item.pid === tempProduct.productId
      );
      let newRow = getEmptyNewRow();
      rows.push(newRow);
      newState.stockAdjustmentItems = [...rows];
      setStockAdjustmentState({ ...newState });
      setTimeout(() => {
        let data = { ...newRow, product: rowData };
        onRowUpdate({ columnKey: 'product', rowData: data, rowIndex: 0 });
      }, 1500);
    }
  }, [stockAdjustmentProducts]);

  useEffect(() => {
    if (!Utility.isEmpty(originalProductList)) {
      let tempProductsLookupById: any = {};
      originalProductList.forEach((product: any) => {
        tempProductsLookupById[product.pid] = product;
      });
    }
  }, []);

  useEffect(() => {
    if (Utility.isNotEmpty(accountList?.content)) {
      let activeAccountList = accountList?.content.filter(
        (account: any) => account.status === STATUS_TYPE.ACTIVE
      );
      let tempAccounts = activeAccountList.map((account: any) => {
        return {
          ...account,
          label: account.name,
          value: account.code
        };
      });
      setAccounts(tempAccounts);
    }
  }, []);

  useEffect(() => {
    updateColumnConfig(true);
  }, [selectedProductUOMs.current]);

  useEffect(() => {
    updateColumnConfig(false);
  }, [stockAdjustmentState.adjustmentType]);

  /**
   * Functions Declaration
   */
  const updateColumnConfig = (updateExisting = false) => {
    let updatedColumnConfig = getColumns();
    if (updateExisting && Utility.isNotEmpty(columnConfigForGrid)) {
      updatedColumnConfig = [...columnConfigForGrid];
    }
    updatedColumnConfig?.forEach((column: IColumn) => {
      if (column.key === 'uomSchema') {
        if (column?.dropdownConfig?.data) {
          column.dropdownConfig.data = selectedProductUOMs.current || [];
        }
      }
      if ('perUnitValue' === column.key || 'totalAmount' === column.key) {
        column['formatter'] = (data: any) =>
          amountFormatter(data.value, tenantInfo.currency);
      }
    });

    if (isEdit) {
      updatedColumnConfig = updatedColumnConfig?.filter(
        (columnConfig: IColumn) => {
          if (
            stockAdjustmentState?.adjustmentType?.value ===
              STOCK_ADJUSTMENT_TYPE.STOCK_OUT &&
            columnConfig.key === 'availableQuantity'
          ) {
            return false;
          }
          return true;
        }
      );
    }
    setColumnConfigForGrid(updatedColumnConfig);
  };

  const getActiveWarehouses = () => {
    let activeWarehouses =
      warehousesData &&
      warehousesData?.content &&
      warehousesData?.content.filter(
        (item: any) =>
          item.active &&
          item.warehouseType !== 'QA' &&
          item.warehouseType !== 'REJECTED'
      );
    if (!Utility.isEmpty(activeWarehouses)) {
      return activeWarehouses;
    } else {
      return [];
    }
  };

  const setWarehouseValues = (warehouse: any) => {
    if (!isEdit) {
      let newState = { ...stockAdjustmentState };
      newState.warehouseCode = warehouse;
      newState.stockAdjustmentItems = [getEmptyNewRow()];
      setStockAdjustmentState({ ...newState });
    }
  };

  const getHeaderTitle = () => {
    let title = 'Stock Adjustment';
    let actionTitle = '';
    actionTitle = !props.readOnly ? (!isEdit ? 'New ' : 'Edit ') : '';
    return actionTitle + title;
  };

  const getShrinkExpandButton = () => {
    return (
      <DKButton
        icon={showMainDocsInFullscreen ? ic_shrink : ic_expand}
        onClick={() => {
          dispatch(setShowMainDocsInFullScreen(!showMainDocsInFullscreen));
        }}
        disabled={isLoading}
        className="border-m mr-r"
      />
    );
  };

  const getHeader = () => {
    return (
      <div
        style={{ zIndex: 1 }}
        className="row justify-content-between p-h-r p-v-s bg-gray1 sticky top-0"
      >
        <div className="row pop-header-drag-handle">
          <DKLabel text={getHeaderTitle()} className="fw-m fs-l" />
        </div>
        <div className="row justify-content-end width-auto">
          {props.allowFullScreen && getShrinkExpandButton()}
          <DKButton
            title={'Cancel'}
            className={
              isLoading ? 'bg-gray1 border-m mr-r' : 'bg-white border-m mr-r'
            }
            onClick={() => {
              if (!isLoading) {
                props.onCancel();
              }
            }}
          />
          {!props.readOnly && (
            <div
              className={`row width-auto border-radius-m ${
                isLoading
                  ? ' bg-gray1 border-m pr-2 text-gray'
                  : ' bg-button text-white'
              }`}
            >
              <DKButton
                disabled={isLoading}
                title={isLoading ? 'Saving' : 'Save'}
                onClick={() => {
                  if (!isLoading) {
                    saveStockAdjustment();
                  }
                }}
              />
              {isLoading && <DKSpinner iconClassName="ic-s" />}
            </div>
          )}
        </div>
      </div>
    );
  };

  /**
   * @description - this method validated stock Adjustment form
   */
  const isFormValid = (warehouses: any[]): boolean => {
    const {
      warehouseCode,
      rackCode,
      rowCode,
      binCode,
      adjustmentType,
      adjustmentReason,
      adjustmentDate,
      stockAdjustmentItems,
      customField
    } = stockAdjustmentState;
    if (Utility.isEmptyObject(adjustmentType?.value)) {
      return false;
    }
    if (
      Utility.isEmptyObject(adjustmentReason?.value) &&
      Utility.isEmptyObject(adjustmentReason?.code)
    ) {
      return false;
    }
    try {
      if (Utility.isEmptyObject(new Date(adjustmentDate).toDateString())) {
        return false;
      }
    } catch (error) {
      return false;
    }
    if (Utility.isEmptyObject(warehouseCode?.code)) {
      return false;
    }
    if (WarehouseManagementHelper.isRRBEnabledForWarehouse(warehouseCode)) {
      const { warehouseRowInfos, warehouseRackInfos, warehouseBinInfos } =
        warehouseCode;
      if (
        Utility.isNotEmpty(warehouseRowInfos) &&
        Utility.isEmptyObject(rowCode)
      ) {
        return false;
      }
      if (
        Utility.isNotEmpty(warehouseRackInfos) &&
        Utility.isEmptyObject(rackCode)
      ) {
        return false;
      }
      if (
        Utility.isNotEmpty(warehouseBinInfos) &&
        Utility.isEmptyObject(binCode)
      ) {
        return false;
      }
    }
    if (Utility.isEmptyObject(stockAdjustmentItems)) {
      addNewItem();
      return false;
    }
    if (!stockAdjustmentItems?.every((item: any) => !!item.isAllocated)) {
      showAlert(
        'Error',
        'Please fill advanced tracking information for product.'
      );
      return false;
    }
    if (customFieldsContainsErrors(customField)) {
      return false;
    }
    if (
      stockAdjustmentState?.stockAdjustmentItems?.some((item) =>
        Utility.isNotEmpty(item?.invalidFields)
      )
    ) {
      showAlert('Error!', 'Please fill all required data.');
      return false;
    }
    if (
      adjustmentType?.value === STOCK_ADJUSTMENT_TYPE.STOCK_OUT &&
      Utility.isNotEmpty(warehouses)
    ) {
      const productAvailableQuantityMap = new Map<string, number>();
      const stockItemWithInSufficientQuantity: any[] = [];
      stockAdjustmentState?.stockAdjustmentItems.forEach((record: any) => {
        const isNegativeInventoryOn =
          record.type === PRODUCT_TYPE.TRACKED &&
          record.productType === ADVANCE_TRACKING.NONE &&
          tenantInfo.allowNegativeInventory;
        if (!isNegativeInventoryOn) {
          let quantityToAssign = Number(record.quantity);
          if (!productAvailableQuantityMap.has(record.productVariantCode)) {
            productAvailableQuantityMap.set(
              record.productVariantCode,
              getProductAvailableQty(record.productVariantCode, warehouses)
            );
          }
          let availableQuantity =
            productAvailableQuantityMap.get(record.productVariantCode) ?? 0;
          if (Utility.isNotEmpty(record?.documentUOMSchemaDefinition)) {
            quantityToAssign = Utility.getUomWarehouseQuantity(
              quantityToAssign,
              record.documentUOMSchemaDefinition
            );
          }
          if (availableQuantity < quantityToAssign) {
            stockItemWithInSufficientQuantity.push(record);
          }
          productAvailableQuantityMap.set(
            record.productVariantCode,
            availableQuantity - quantityToAssign
          );
        }
      });

      if (Utility.isNotEmpty(stockItemWithInSufficientQuantity)) {
        const productNames = stockItemWithInSufficientQuantity?.map(
          (item) => item.productName
        );
        showAlert(
          'Error',
          `Products <b>${productNames?.toString()}</b> ${
            productNames?.length > 1 ? 'have' : 'has'
          } insufficient quantity.`
        );
        return false;
      }
    }
    return true;
  };

  const getLineItemCFs = (lineItem: any) => {
    let oldColConfigs = [...(columnConfigForGrid || [])];
    let colConfigsWithOnlyCF = oldColConfigs.filter(
      (item: any) => item.isCustomField
    );
    let newCfs: any[] = [];
    if (!Utility.isEmpty(selectCustomFieldsData?.content)) {
      colConfigsWithOnlyCF.forEach((colConfigItem: any) => {
        const cf: any = selectCustomFieldsData?.content.find(
          (cfItem: any) => colConfigItem.id === cfItem.id
        );
        if (typeof cf !== 'undefined' && cf !== null) {
          let cfValue;
          if (cf.fieldType.toLowerCase() === INPUT_TYPE.DATE.toLowerCase()) {
            cfValue = DateFormatService.getDateStrFromDate(
              new Date(lineItem[cf.id]),
              BOOKS_DATE_FORMAT['MM/DD/YYYY']
            );
          } else if (cf.fieldType.toLowerCase() === 'user') {
            const tempCF = cf?.attributes?.find(
              (attr: any) => attr.code === lineItem[cf.id]?.code
            );
            if (tempCF) {
              cfValue = tempCF.code;
            }
          } else if (
            cf.fieldType.toLowerCase() === INPUT_TYPE.DROPDOWN.toLowerCase()
          ) {
            cfValue = lineItem[cf.id] ? lineItem[cf.id].value : '';
          } else {
            cfValue = lineItem[cf.id] ? lineItem[cf.id] : '';
          }

          newCfs.push({
            id: cf.id,
            code: cf.code,
            label: cf.label,
            module: 'STOCKADJUSTMENT',
            shortName: cf.shortName,
            value: cfValue
          });
        }
      });
    }

    return newCfs;
  };

  const checkIfQCRequired = async () => {
    let productCodes = stockAdjustmentState.stockAdjustmentItems?.map(
      (item: any) => item?.productVariantCode
    );
    let response: any =
      await CommonQualityCheckService.getTemplateUsageByProduct(productCodes);
    let isQualityCheck = response?.reduce(
      (prev: boolean, current: any) => prev || current.used,
      false
    );
    return isQualityCheck;
  };

  const saveStockAdjustment = async () => {
    setIsLoading(true);
    setSaveButtonTapped(true);
    let payload = createPayload(stockAdjustmentState, isEdit);
    // TODO - This CF logic needs to be merged in createPayload function
    if (!isEdit) {
      payload.stockAdjustmentItems = payload.stockAdjustmentItems?.map(
        (item: IStockAdjustmentItem) => {
          const stateStockItem =
            stockAdjustmentState?.stockAdjustmentItems?.find(
              (stock) => stock.stockItemNumber === item.stockItemNumber
            );
          return {
            ...item,
            customField: getLineItemCFs(stateStockItem)
          };
        }
      );
    }
    if (isEdit) {
      setIsLoading(true);
      StockAdjustmentService.updateStockAdjustments(payload).then(
        (res: any) => {
          setIsLoading(false);
          props.onSave();
        },
        (err) => {
          setIsLoading(false);
          if (err?.data?.status === 500) {
            showAlert('Error!', err?.data?.message);
          } else {
            showAlert('Error', 'Unable to save stock adjustment');
          }
          console.error('Error updating stock adjustment: ', err);
        }
      );
    } else {
      let ids = map(
        stockAdjustmentState?.stockAdjustmentItems,
        'productVariantCode'
      );
      if (Utility.isNotEmpty(ids)) {
        ProductService.fetchWarehouseProductsByIDWithRejectedWh(ids)
          .then((response: any) => {
            createStockAdjustment(response?.warehouses, payload);
          })
          .catch((err: any) => {
            // createStockAdjustment(WarehouseProductsData, payload);
            console.log(err, 'product fetch failed');
          });
      } else {
        addNewItem();
        setIsLoading(false);
      }
    }
  };

  const createStockAdjustment = async (warehouses: any, payload: any) => {
    if (isFormValid(warehouses)) {
      setIsLoading(true);
      let isQCCheckProductAvailable = await checkIfQCRequired();

      if (
        Utility.checkQCEnabledForModule(QC_FLOW_MODULES.STOCK_ADJUSTMENT) &&
        isQCCheckProductAvailable &&
        Utility.isMRP()
      ) {
        const isConfirmed = await qcConfirm(
          `Would you like to perform a quality check on this stock transfer?`,
          `QC Flow`
        );

        if (isConfirmed === null) {
          setIsLoading(false);
          return;
        }

        if (isConfirmed) {
          payload.isQcEnabled = true;
        }
      }

      StockAdjustmentService.saveStockAdjustments(payload).then(
        (res: any) => {
          const stockAdjustmentCode = res.code;
          let successMessage = `Stock Adjustment of ${stockAdjustmentCode} is saved successfully${
            payload.isQcEnabled
              ? `, please go through the quality check list.`
              : `.`
          }`;

          let buttons = [
            {
              title: 'Ok',
              className: `${
                payload.isQcEnabled ? `bg-gray2` : `bg-blue text-white`
              } border-m`,
              onClick: () => {
                if (tempProduct) {
                  setTempProduct(undefined);
                }
              }
            }
          ];

          if (payload.isQcEnabled) {
            buttons.push({
              title: 'Go to Quality Check',
              className: 'bg-blue text-white ml-r',
              onClick: () => {
                RouteManager.navigateToPage(PAGE_ROUTES.QUALITY_CHECK);
              }
            });
          }

          showAlert('Success!', successMessage, buttons);
          setIsLoading(false);
          props.onSave();
        },
        (err) => {
          setIsLoading(false);
          if (err?.data?.status === 500) {
            showAlert('Error!', err?.data?.message);
          } else {
            showAlert('Error', 'Unable to save stock adjustment');
          }
          console.error('Error saving stock adjustment: ', err);
        }
      );
    } else {
      setIsLoading(false);
    }
  };

  const hideClassColumn = () => {
    let hideClassCol = false;
    const classSettings = tenantInfo.additionalSettings?.CLASS;
    if (
      !classSettings?.trackClasses ||
      classSettings?.assignClasses === CLASS_ASSIGNMENT.TRANSACTION
    ) {
      hideClassCol = true;
    }
    return hideClassCol;
  };

  const getStockInDataGridColumns = () => {
    let columns: any = [...StockInColumnConfig];

    let accCustomFields = selectCustomFieldsData?.content?.filter(
      (item: any) => {
        return (
          item.modules?.includes('STOCKADJUSTMENT') &&
          item.status === STATUS_TYPE.ACTIVE
        );
      }
    );
    accCustomFields = accCustomFields?.filter(
      (ele: any) => ele.label === LOCATION_CLASS_ENUM.CLASS
    );

    accCustomFields?.forEach((accCF: any) => {
      let newItem: any = getNewColumn(accCF);
      const newItemInExistingColConfig = columns.find(
        (config: any) => config.code === accCF.code
      );
      if (Utility.isEmpty(newItemInExistingColConfig)) {
        newItem['dropdownConfig']['button'] = {
          title: '+ Add New',
          className: 'bg-button text-white',
          onClick: () => setShowAddClassPopup(true)
        };

        columns.push({ ...newItem, hidden: hideClassColumn() });
      }
    });
    let productCustomFields = selectCustomFieldsData?.content?.filter(
      (item: any) => {
        return (
          item.modules?.includes('PRODUCT') &&
          item.status === STATUS_TYPE.ACTIVE
        );
      }
    );

    productCustomFields?.forEach((accCF: any) => {
      let newItem: any = getNewColumn(accCF);
      const newItemInExistingColConfig = columns.find(
        (config: any) => config.code === accCF.code
      );
      if (Utility.isEmpty(newItemInExistingColConfig)) {
        columns.push({ ...newItem });
      }
    });

    columns.push({
      id: 'action',
      key: 'action',
      name: '',
      type: INPUT_TYPE.BUTTON,
      width: 100,
      options: []
    });
    columns = columns.filter((col: any) => !col.hidden);

    return columns;
  };

  const getStockOutDataGridColumns = () => {
    let columns: any = [...StockOutColumnConfig];

    let accCustomFields = selectCustomFieldsData?.content?.filter(
      (item: any) => {
        return (
          item.modules?.includes('STOCKADJUSTMENT') &&
          item.status === STATUS_TYPE.ACTIVE
        );
      }
    );
    accCustomFields = accCustomFields?.filter(
      (ele: any) => ele.label === LOCATION_CLASS_ENUM.CLASS
    );

    accCustomFields?.forEach((accCF: any) => {
      let newItem: any = getNewColumn(accCF);
      const newItemInExistingColConfig = columns.find(
        (config: any) => config.code === accCF.code
      );
      if (Utility.isEmpty(newItemInExistingColConfig)) {
        newItem['dropdownConfig']['button'] = {
          title: '+ Add New',
          className: 'bg-button text-white',
          onClick: () => setShowAddClassPopup(true)
        };

        columns.push({ ...newItem, hidden: hideClassColumn() });
      }
    });
    let productCustomFields = selectCustomFieldsData?.content?.filter(
      (item: any) => {
        return (
          item.modules?.includes('PRODUCT') &&
          item.status === STATUS_TYPE.ACTIVE
        );
      }
    );

    productCustomFields?.forEach((accCF: any) => {
      let newItem: any = getNewColumn(accCF);
      const newItemInExistingColConfig = columns.find(
        (config: any) => config.code === accCF.code
      );
      if (Utility.isEmpty(newItemInExistingColConfig)) {
        columns.push({ ...newItem });
      }
    });
    columns.push({
      id: 'action',
      key: 'action',
      name: '',
      type: INPUT_TYPE.BUTTON,
      width: 100,
      options: []
    });
    columns = columns.filter((col: any) => !col.hidden);
    return columns;
  };

  const setUOMsForSelectedProduct = (product: any) => {
    const selectedProduct = deepClone(product);
    let UOMs = [];
    let stockUOM = uomData.find(
      (uom: any) => uom.id === selectedProduct.stockUom
    );
    if (Utility.isNotEmpty(stockUOM)) {
      UOMs.push({ ...stockUOM, isBaseUom: true });
    }
    if (Utility.isNotEmpty(selectedProduct.uomSchemaDto)) {
      let processedUOMs = selectedProduct.uomSchemaDto.uomSchemaDefinitions.map(
        (uomSchema: any) => {
          let sinkUOM = uomData.find(
            (uom: any) => uom.id === uomSchema.sinkUOM
          );
          let name = '';
          if (Utility.isNotEmpty(sinkUOM)) {
            name = sinkUOM.name;
          }
          return {
            ...uomSchema,
            name: name,
            isBaseUom: false
          };
        }
      );
      UOMs = UOMs.concat(processedUOMs);
    }

    selectedProductUOMs.current = UOMs;
  };

  const onRowClick = ({ rowIndex }: any) => {
    let lineItem = { ...stockAdjustmentState?.stockAdjustmentItems[rowIndex] };
    setUOMsForSelectedProduct(lineItem);
    setCurrentIndex(rowIndex);
  };

  const uomFormatter = (obj: any) => {
    const value = obj.value;
    if (!Utility.isEmpty(value) && obj?.rowData?.quantity) {
      return `${
        value.name
      }<br><span style='font-size: 10px; color:gray; margin-top: 3px;'>${
        value.sourceConversionFactor
          ? `${Utility.roundOff(
              (value.sourceConversionFactor / value.sinkConversionFactor) *
                obj.rowData.quantity
            )} ${DocumentConfigUtility.getBaseUomName(value).name}`
          : ''
      }</span>`;
    } else {
      return value.name;
    }
  };

  const getEmptyNewRow = (): StockAdjustmentItems => {
    return {
      stockItemNumber: getRandomAlphaNumericString(8),
      productName: '',
      productType: '',
      type: '',
      productQuantity: '',
      documentUom: '',
      documentUOMSchemaDefinition: {},
      uomQuantity: 1,
      uomUnitPrice: 0,
      advancedTracking: '',
      pid: '',
      advancedTrackingMetaData: '',
      batchNumber: '',
      serialNumbers: '',
      perUnitValue: 0,
      availableQuantity: 0,
      availableQuantityInBaseUOM: '',
      totalAmount: 0,
      productVariantCode: '',
      quantity: 0,
      batchDetails: [],
      stockAdjustmentAccountCode: '',
      rowContextMenu: getButtonsForRow(),
      invalidFields: ['product']
    };
  };

  const addNewItem = () => {
    let tmpStockAdjustmentState = { ...stockAdjustmentState };
    let rows = tmpStockAdjustmentState?.stockAdjustmentItems;
    rows.push(getEmptyNewRow());
    tmpStockAdjustmentState.stockAdjustmentItems = rows;
    setStockAdjustmentState(tmpStockAdjustmentState);
  };

  const getProductApi = (search: string) => {
    const config: StockTransferAPIConfig = {
      ...StockTransferService.apiConfig,
      SearchTerm: search
    };
    StockTransferService.apiConfig = config;
    return StockTransferService.getProductEndpoint();
  };

  const getProductData = (data: any) => {
    return data
      ? data?.filter(
          (ele: any) =>
            ele.type !== PRODUCT_TYPE.NON_TRACKED && !ele.hasVariants
        )
      : [];
  };

  const getProductRenderer = (index: any, obj: any) => {
    return (
      <div className="flex flex-row w-full justify-content-between">
        <DKLabel
          style={{ fontSize: '13px' }}
          className="text-base border-radius-s"
          text={obj.name}
        />
      </div>
    );
  };

  const StockInColumnConfig = [
    {
      name: 'Product Name',
      index: 0,
      options: null,
      required: false,
      editable: true,
      hidden: false,
      uiVisible: true,
      systemField: true,
      columnCode: 'product',
      id: 'product',
      datasource: [],
      key: 'product',
      lineItem: false,
      type: INPUT_TYPE.DROPDOWN,
      formatter: (value: any) => {
        return value?.rowData?.productName;
      },
      width: 200,
      dropdownConfig: {
        title: 'Select Product',
        allowSearch: true,
        searchableKey: 'name',
        style: { minWidth: 250 },
        className: 'shadow-m width-auto',
        searchApiConfig: {
          getUrl: getProductApi,
          dataParser: getProductData,
          debounceTime: 300
        },
        data: [],
        renderer: DocumentConfigUtility.productRenderer,
        // renderer: getProductRenderer,
        onSelect: (index: any, obj: any, rowIndex: any) => {}
      }
    },
    {
      id: 'documentSequenceCode',
      key: 'documentSequenceCode',
      name: 'Product Code',
      type: INPUT_TYPE.TEXT,
      width: 160,
      systemField: true,
      editable: false,
      hidden: false,
      uiVisible: true
    },
    {
      name: 'Account',
      index: 0,
      options: null,
      required: false,
      editable: true,
      hidden: false,
      uiVisible: true,
      systemField: true,
      columnCode: 'stockAdjustmentAccount',
      id: 'stockAdjustmentAccount',
      datasource: [],
      key: 'stockAdjustmentAccount',
      lineItem: false,
      type: INPUT_TYPE.DROPDOWN,
      formatter: (value: any) => {
        return (
          value?.rowData?.stockAdjustmentAccount?.label ||
          value?.rowData?.stockAdjustmentAccountName
        );
      },
      width: 200,
      dropdownConfig: {
        style: { minWidth: 280 },
        className: 'shadow-m',
        title: 'Select Account',
        allowSearch: true,
        searchableKey: 'name',
        canEdit: false,
        canDelete: false,
        data: accounts ? accounts : [],
        renderer: (index: any, account: any) => {
          return account.label;
        },
        onSelect: (index: any, obj: any, rowIndex: any) => {},
        searchApiConfig: {
          getUrl: (searchValue: string) => {
            const config: AccountAPIConfig = {
              ...AccountsService.apiConfig,
              Page: 0,
              SearchTerm: searchValue,
              Limit: 10,
              Query: `status=ACTIVE`
            };
            AccountsService.apiConfig = config;
            return ApiConstants.URL.BASE + AccountsService.getAccountEndPoint();
          },
          dataParser: (response: any) => {
            let mappedAccounts = response?.content?.map((acc: any) => {
              return {
                ...acc,
                label: acc.name,
                value: acc.code
              };
            });
            return mappedAccounts;
          },
          debounceTime: 300
        }
      }
    },
    {
      id: 'quantity',
      key: 'quantity',
      columnCode: 'quantity',
      name: 'Qty',
      type: INPUT_TYPE.NUMBER,
      width: 120,
      systemField: true,
      editable: true,
      hidden: false,
      uiVisible: true,
      textAlign: 'right'
    },
    {
      id: 'uom',
      key: 'uomSchema',
      columnCode: 'uomSchema',
      name: 'UOM',
      type: INPUT_TYPE.DROPDOWN,
      width: 103,
      systemField: true,
      editable: true,
      hidden: false,
      uiVisible: true,
      formatter: uomFormatter,
      dropdownConfig: {
        title: 'Select UOM',
        allowSearch: true,
        searchableKey: 'name',
        style: { minWidth: 150 },
        className: 'shadow-m width-auto',
        data: selectedProductUOMs.current || [],
        renderer: DocumentConfigUtility.uomOptionRenderer,
        onSelect: (index: any, obj: any, rowIndex: any) => {}
      }
    },
    {
      id: 'price',
      key: 'perUnitValue',
      columnCode: 'perUnitValue',
      name: 'Price',
      type: INPUT_TYPE.NUMBER,
      width: 150,
      systemField: true,
      editable: true,
      hidden: false,
      uiVisible: true,
      textAlign: 'right'
    },
    {
      id: 'amount',
      key: 'totalAmount',
      columnCode: 'totalAmount',
      name: 'Amount',
      type: INPUT_TYPE.NUMBER,
      width: 150,
      systemField: true,
      editable: false,
      hidden: false,
      uiVisible: true,
      textAlign: 'right'
    }
  ];

  const StockOutColumnConfig = [
    {
      name: 'Product Name',
      index: 0,
      options: null,
      required: false,
      editable: true,
      hidden: false,
      uiVisible: true,
      systemField: true,
      columnCode: 'product',
      id: 'product',
      datasource: [],
      key: 'product',
      lineItem: false,
      type: INPUT_TYPE.DROPDOWN,
      formatter: (value: any) => {
        return value?.rowData?.productName;
      },
      width: isEdit ? 300 : 200,
      dropdownConfig: {
        title: 'Select Product',
        allowSearch: true,
        searchableKey: 'name',
        style: { minWidth: 280 },
        className: 'shadow-m width-auto',
        searchApiConfig: {
          getUrl: getProductApi,
          dataParser: getProductData,
          debounceTime: 300
        },
        data: [],
        renderer: DocumentConfigUtility.productRenderer,
        // renderer: getProductRenderer,
        onSelect: (index: any, obj: any, rowIndex: any) => {}
      }
    },
    {
      id: 'documentSequenceCode',
      key: 'documentSequenceCode',
      name: 'Product Code',
      type: INPUT_TYPE.TEXT,
      width: 160,
      systemField: true,
      editable: false,
      hidden: false,
      uiVisible: true
    },
    {
      name: 'Account',
      index: 0,
      options: null,
      required: false,
      editable: true,
      hidden: false,
      uiVisible: true,
      systemField: true,
      columnCode: 'stockAdjustmentAccount',
      id: 'stockAdjustmentAccount',
      datasource: [],
      key: 'stockAdjustmentAccount',
      lineItem: false,
      type: INPUT_TYPE.DROPDOWN,
      formatter: (value: any) => {
        return (
          value?.rowData?.stockAdjustmentAccount?.label ||
          value?.rowData?.stockAdjustmentAccountName
        );
      },
      width: isEdit ? 230 : 200,
      dropdownConfig: {
        style: { minWidth: 250 },
        className: 'shadow-m',
        title: 'Select Account',
        allowSearch: true,
        searchableKey: 'name',
        canEdit: false,
        canDelete: false,
        data: accounts ? accounts : [],
        renderer: (index: any, account: any) => {
          return account.label;
        },
        onSelect: (index: any, obj: any, rowIndex: any) => {},
        searchApiConfig: {
          getUrl: (searchValue: string) => {
            const config: AccountAPIConfig = {
              ...AccountsService.apiConfig,
              Page: 0,
              SearchTerm: searchValue,
              Limit: 10,
              Query: `status=ACTIVE`
            };
            AccountsService.apiConfig = config;
            return ApiConstants.URL.BASE + AccountsService.getAccountEndPoint();
          },
          dataParser: (response: any) => {
            let mappedAccounts = response?.content?.map((acc: any) => {
              return {
                ...acc,
                label: acc.name,
                value: acc.code
              };
            });
            return mappedAccounts;
          },
          debounceTime: 300
        }
      }
    },
    {
      id: 'availableQuantity',
      key: 'availableQuantity',
      columnCode: 'availableQuantity',
      name: 'Available Quantity',
      type: INPUT_TYPE.NUMBER,
      width: 150,
      systemField: true,
      editable: false,
      hidden: false,
      uiVisible: true,
      textAlign: 'right',
      renderer: (data: any) => {
        return (
          <div className="row parent-size justify-content-end opacity-5 cursor-not-allowed">
            {data.value}
          </div>
        );
      }
    },
    {
      id: 'uom',
      key: 'uomSchema',
      columnCode: 'uomSchema',
      name: 'UOM',
      type: INPUT_TYPE.DROPDOWN,
      width: 103,
      systemField: true,
      editable: true,
      hidden: false,
      uiVisible: true,
      formatter: uomFormatter,
      dropdownConfig: {
        title: 'Select UOM',
        allowSearch: true,
        searchableKey: 'name',
        style: { minWidth: 150 },
        className: 'shadow-m width-auto',
        data: selectedProductUOMs.current || [],
        renderer: DocumentConfigUtility.uomOptionRenderer,
        onSelect: (index: any, obj: any, rowIndex: any) => {}
      }
    },
    {
      id: 'quantity',
      key: 'quantity',
      columnCode: 'quantity',
      name: 'Qty',
      type: INPUT_TYPE.NUMBER,
      width: 120,
      systemField: true,
      editable: true,
      hidden: false,
      uiVisible: true,
      textAlign: 'right'
    }
  ];

  const buttonAction = (data: any, rowIndex: number, rowData: any) => {
    let product = {
      ...data,
      productName: data.name || data?.productName,
      availableProductQuantity: data.availableQuantity,
      quantityRequired: +rowData.uomQuantity,
      fulfilledQuantity: +rowData.uomQuantity
    };
    let newState = { ...stockAdjustmentState };
    newState.stockAdjustmentItems[rowIndex] = rowData;
    let payload = {
      product,
      document: null,
      adjustmentType: stockAdjustmentState?.adjustmentType,
      formValue: newState,
      stockItemNumber: rowData.stockItemNumber,
      fulfillmentIndex: rowIndex
    };
    setSelectedAdvancedStockTrackingProduct(payload);
    setShowAdvancedStockTrackingPopup(true);
  };

  const stockItemDelete = ({ rowIndex, rowData }: any) => {
    const updateStockState = { ...stockAdjustmentState };
    updateStockState.stockAdjustmentItems?.splice(rowIndex, 1);
    setStockAdjustmentState(updateStockState);
  };

  const getButtonsForRow = (data?: any) => {
    let buttons: any[] = [];
    if (!isEdit) {
      buttons.push({
        title: 'Delete',
        type: STOCK_ADJUSTMENT_ACTION_TYPE.DELETE,
        icon: DKIcons.ic_delete,
        className: 'p-v-0 text-blue underline grid-action-link-h-padding',
        onClick: stockItemDelete
      });
    }
    if (
      [TRACKING_TYPE.BATCH, TRACKING_TYPE.SERIAL].includes(
        data?.advancedTracking
      )
    ) {
      let allowToAllocate = true;
      if (!isEdit) {
        if (
          stockAdjustmentState?.adjustmentType?.value ===
          STOCK_ADJUSTMENT_TYPE.STOCK_OUT
        ) {
          allowToAllocate = data.availableQuantity > 0;
        }
      }

      if (allowToAllocate) {
        buttons.push({
          title: 'Allocate',
          type: STOCK_ADJUSTMENT_ACTION_TYPE.ALLOCATE,
          icon: ic_bom_allocate_red,
          className: 'p-v-0 text-blue underline grid-action-link-h-padding',
          onClick: ({ rowIndex, rowData }: any) => {
            buttonAction(data, rowIndex, rowData);
          }
        });
      }
    }

    return buttons;
  };

  const getAdjustmentReasonOptions = (type?: any) => {
    return StockAdjustmentService.getStockAdjustmentReason(
      type || stockAdjustmentState?.adjustmentType?.value
    )
      .then((response: any) => {
        setReasonsArray(response);
        return Promise.resolve(response);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  };

  const getAdjustmentReason = () => {
    let addSubtypeBtn = null;
    addSubtypeBtn = {
      title: '+ Add Adjustment Reason',
      className: 'bg-button text-white',
      onClick: () => {
        setShowAddReasonPopup(true);
      }
    };
    const onReasonDelete = (value: any) => {
      StockAdjustmentService.deleteStockAdjustmentsReason(value.code)
        .then((res) => {
          getAdjustmentReasonOptions();
          if (value.code === stockAdjustmentState.adjustmentReason.code) {
            updateStockAdjustmentFormValue('adjustmentReason', null);
          }
          showAlert('Please Note!', `Stock Adjustment reason was deleted.`, [
            {
              title: 'Ok',
              className: 'bg-button text-white',
              onClick: () => {}
            }
          ]);
        })
        .catch((err) => {
          console.error(err);
          if (err?.data?.code === 400) {
            showAlert('Error', err?.data?.errorMessage, [
              {
                title: 'Ok',
                className: 'bg-button text-white',
                onClick: () => {}
              }
            ]);
          }
        });
    };

    return (
      <DKInput
        type={INPUT_TYPE.DROPDOWN}
        title={'Reason'}
        canValidate={saveButtonTapped}
        direction={INPUT_VIEW_DIRECTION.VERTICAL}
        value={
          stockAdjustmentState.adjustmentReason?.reason ||
          stockAdjustmentState.adjustmentReason?.name
        }
        required={true}
        onChange={(obj: any) => {
          updateStockAdjustmentFormValue('adjustmentReason', obj);
        }}
        className="mb-l"
        readOnly={props.readOnly}
        dropdownConfig={{
          title: '',
          allowSearch: false,
          searchableKey: '',
          canEdit: true,
          canDelete: true,
          style: { minWidth: 150 },
          className: 'shadow-m width-auto',
          searchApiConfig: {
            getUrl: (searchValue: string) => {
              const url =
                ApiConstants.URL.BASE +
                `products/inventories/stockAdjustment/reason/search?type=${stockAdjustmentState?.adjustmentType?.value}`;
              return url;
            },
            dataParser: (response: any) => {
              setReasonsArray(response);
              return response;
            },
            debounceTime: 300
          },
          data: reasonsArray,
          renderer: (index: any, obj: any) => {
            return (
              <DKLabel
                text={obj.reason}
                className={`align-item-center pt-0.5`}
              />
            );
          },
          button: addSubtypeBtn,
          onEdit: (index: any, value: any) => {
            if (value?.system) {
              showAlert('Warning', 'System defined reason can not be edit.');
              return;
            }
            setCurrentReasonToEditOrDelete(value);
            setShowAddReasonPopup(true);
          },
          onDelete: (index: any, value: any) => {
            if (value?.system) {
              showAlert('Warning', 'System defined reason can not be delete.');
              return;
            }
            let buttons = [
              {
                title: 'No',
                className: 'bg-gray2 border-m ',
                onClick: () => {}
              },
              {
                title: 'Yes',
                className: 'bg-red text-white ml-r',
                onClick: () => onReasonDelete(value)
              }
            ];
            showAlert(
              'Confirm Delete',
              `Are you sure you want to delete  <b>${value?.reason}</b>.`,
              buttons
            );
          }
        }}
      />
    );
  };

  const getAddAdjustmentReasonView = (type: any) => {
    return (
      <AddAdjustmentReason
        currentReason={currentReasonToEditOrDelete}
        onSave={(newPayload: any) => {
          // call save sub type api here
          const payload = {
            reason: newPayload.adjReason,
            type: newPayload.adjType.value
          };
          StockAdjustmentService.saveStockAdjustmentsReason(payload)
            .then((res: any) => {
              if (type.value === newPayload.adjType.value) {
                updateStockAdjustmentFormValue('adjustmentReason', res);
              }
              setShowAddReasonPopup(false);
              getAdjustmentReasonOptions();
            })
            .catch((err: any) => {
              showAlert('Error', err?.data?.errorMessage);
              setShowAddReasonPopup(false);
            });
        }}
        onEdit={(newPayload: any) => {
          const payload = {
            reason: newPayload.adjReason,
            type: newPayload.adjType.value,
            code: newPayload.code
          };
          StockAdjustmentService.updateStockAdjustmentsReason(payload)
            .then((res: any) => {
              if (type.value === newPayload.adjType.value) {
                updateStockAdjustmentFormValue('adjustmentReason', res);
              }
              setShowAddReasonPopup(false);
              getAdjustmentReasonOptions();
            })
            .catch((err: any) => {
              console.error(err);
              setShowAddReasonPopup(false);
            });
        }}
        onCancel={() => {
          setCurrentReasonToEditOrDelete(null);
          setShowAddReasonPopup(false);
        }}
      />
    );
  };
  //unused and commented code will remove after verification
  const getProductAvailableQuantity = (productCode: any) => {
    const availableQuantity = selectedWarehouse
      ? selectedWarehouse.productAvailableQuantity[productCode]
        ? selectedWarehouse.productAvailableQuantity[productCode] -
          selectedWarehouse.productReservedQuantity[productCode]
        : 0
      : 0;
    return Utility.roundingOff(availableQuantity, QTY_ROUNDOFF_PRECISION);
  };
  const getProductAvailableQty = (productCode: any, warehouses: any) => {
    let adjWarehouse = warehouses?.find(
      (wh: any) => wh.code === selectedWarehouse.code
    );
    const availableQuantity = adjWarehouse
      ? adjWarehouse.productAvailableQuantity[productCode]
        ? adjWarehouse.productAvailableQuantity[productCode] -
          adjWarehouse.productReservedQuantity[productCode]
        : 0
      : 0;
    return Utility.roundingOff(availableQuantity, QTY_ROUNDOFF_PRECISION);
  };

  const getSelectedWarehouseOnEditMode = (warehouse: any) => {
    // StockAdjustmentService.getSourceWarehouses(warehouse).then(
    //   (response: any) => {
    //     setSelectedWarehouse(response?.warehouses?.[0]);
    //   }
    // );
    WarehouseService.getWarehouseByCode(warehouse).then((response: any) => {
      setSelectedWarehouse(response?.content?.[0] || {});
    });
  };

  const getStockItem = (productData: any, rowData: any, UOM: any) => {
    return {
      stockItemNumber: rowData.stockItemNumber,
      productVariantCode: rowData.product.pid,
      documentSequenceCode: productData.documentSequenceCode,
      productType: productData?.advancedTracking,
      type: productData?.type,
      quantity: 1,
      perUnitValue: productData?.purchasePrice,
      productName: rowData.product.name,
      batchDetails: rowData?.batchDetails ? rowData.batchDetails : [],
      serialNumbers: rowData?.serialNumbers ? rowData.serialNumbers : [],
      documentUom: productData.stockUom,
      uomQuantity: 1,
      uomUnitPrice: productData?.purchasePrice,
      rowContextMenu: getButtonsForRow(),
      totalAmount: productData?.purchasePrice,
      stockAdjustmentAccount:
        accounts.filter(
          (ele: any) => ele.code === productData.stockAdjustmentAccountCode
        )?.[0] || '',
      availableQuantity: 0,
      uomSchemaDto: productData.uomSchemaDto,
      stockUom: productData.stockUom,
      uomSchema: UOM
    };
  };

  const onNoneTrackedInfoSuccess = (
    res: any,
    stockItem: StockAdjustmentItems,
    productData: any
  ) => {
    const { warehouses } = res;
    const { warehouseCode, rackCode, rowCode, binCode } = stockAdjustmentState;
    const selectedWarehouse = warehouses?.find(
      (warehouse: any) => warehouseCode.code === warehouse.code
    );
    let availableQuantity = 0;
    let reservedQuantity = 0;
    let roundedAvailableQty = 0;
    if (Utility.isNotEmpty(selectedWarehouse)) {
      const { rowRackBinProductAvailableQuantityDtos } = selectedWarehouse;
      if (Utility.isNotEmpty(rowRackBinProductAvailableQuantityDtos)) {
        const productRRBDetails = rowRackBinProductAvailableQuantityDtos?.find(
          (RRBItem: any) =>
            RRBItem.rowCode === rowCode &&
            RRBItem.rackCode === rackCode &&
            RRBItem.binCode === binCode
        );
        if (Utility.isNotEmpty(productRRBDetails)) {
          availableQuantity = Utility.roundingOff(
            productRRBDetails.availableQuantity || 0,
            QTY_ROUNDOFF_PRECISION
          );
          reservedQuantity = Utility.roundingOff(
            productRRBDetails.reservedQuantity || 0,
            QTY_ROUNDOFF_PRECISION
          );
        }
        roundedAvailableQty = Utility.roundingOff(
          availableQuantity - reservedQuantity,
          QTY_ROUNDOFF_PRECISION
        );
      } else {
        // availableQuantity =
        //   selectedWarehouse?.productAvailableQuantity[
        //     stockItem.productVariantCode
        //   ] || 0;
        // reservedQuantity =
        //   selectedWarehouse?.productReservedQuantity[
        //     stockItem.productVariantCode
        //   ] || 0;
        roundedAvailableQty = getProductAvailableQty(
          stockItem.productVariantCode,
          warehouses
        );
      }
      const { stockAdjustmentItems } = stockAdjustmentState;
      const index = stockAdjustmentItems.findIndex(
        (item: StockAdjustmentItems) =>
          item.stockItemNumber === stockItem.stockItemNumber
      );
      // const roundedAvailableQty = Utility.roundingOff(
      //   availableQuantity - reservedQuantity,
      //   QTY_ROUNDOFF_PRECISION
      // );

      stockAdjustmentItems[index].availableQuantity = roundedAvailableQty;
      stockAdjustmentItems[index].availableQuantityInBaseUOM =
        roundedAvailableQty;
      stockAdjustmentItems[index].availableQuantityInBaseUOM =
        roundedAvailableQty;
      stockAdjustmentItems[index].rowContextMenu = getButtonsForRow({
        ...productData,
        availableQuantity: roundedAvailableQty
      });
      stockAdjustmentItems[index].invalidFields = getInvalidFields(
        stockAdjustmentItems[index]
      );
      assignTrackingIcon(stockAdjustmentItems[index]);
      setStockAdjustmentState((prev) => ({ ...prev, stockAdjustmentItems }));
    }
  };

  const onTrackedInfoSuccess = (
    warehouses: any,
    stockItem: StockAdjustmentItems,
    productData: any
  ) => {
    const { warehouseCode, rackCode, rowCode, binCode } = stockAdjustmentState;
    const selectedWarehouse = warehouses?.find(
      (warehouse: any) => warehouseCode.code === warehouse.code
    );
    let availableQuantity = 0;
    let reservedQuantity = 0;
    let roundedAvailableQty = 0;
    if (Utility.isNotEmpty(selectedWarehouse)) {
      const { advancedTrackingMeta } = selectedWarehouse;
      if (Utility.isNotEmpty(advancedTrackingMeta)) {
        const productRRBDetails: any[] = advancedTrackingMeta?.filter(
          (RRBItem: any) =>
            RRBItem.rowCode === rowCode &&
            RRBItem.rackCode === rackCode &&
            RRBItem.binCode === binCode
        );
        if (Utility.isNotEmpty(productRRBDetails)) {
          productRRBDetails.forEach((trackingInfo: any) => {
            const {
              batchSizeFulfilled,
              batchSize,
              reservedQuantity: rQuantity
            } = trackingInfo;
            availableQuantity = availableQuantity + (batchSize || 0);
            reservedQuantity =
              reservedQuantity + (batchSizeFulfilled || 0) + (rQuantity || 0);
          });
          roundedAvailableQty = Utility.roundingOff(
            availableQuantity - reservedQuantity,
            QTY_ROUNDOFF_PRECISION
          );
        }
      } else {
        // availableQuantity =
        //   selectedWarehouse?.productAvailableQuantity[
        //     stockItem.productVariantCode
        //   ] || 0;
        // reservedQuantity =
        //   selectedWarehouse?.productReservedQuantity[
        //     stockItem.productVariantCode
        //   ] || 0;
        roundedAvailableQty = getProductAvailableQty(
          stockItem.productVariantCode,
          warehouses
        );
      }
      const { stockAdjustmentItems } = stockAdjustmentState;
      const index = stockAdjustmentItems.findIndex(
        (item: StockAdjustmentItems) =>
          item.stockItemNumber === stockItem.stockItemNumber
      );
      // const roundedAvailableQty = Utility.roundingOff(
      //   availableQuantity - reservedQuantity,
      //   QTY_ROUNDOFF_PRECISION
      // );
      stockAdjustmentItems[index].availableQuantity = roundedAvailableQty;
      stockAdjustmentItems[index].availableQuantityInBaseUOM =
        roundedAvailableQty;
      stockAdjustmentItems[index].availableQuantityInBaseUOM =
        roundedAvailableQty;
      stockAdjustmentItems[index].rowContextMenu = getButtonsForRow({
        ...productData,
        availableQuantity: roundedAvailableQty
      });
      stockAdjustmentItems[index].invalidFields = getInvalidFields(
        stockAdjustmentItems[index]
      );
      assignTrackingIcon(stockAdjustmentItems[index]);
      setStockAdjustmentState((prev) => ({ ...prev, stockAdjustmentItems }));
    }
  };

  const getInvalidFields = (row: StockAdjustmentItems) => {
    const invalidFields = [];
    const adjustmentType = stockAdjustmentState.adjustmentType?.value;
    if (Utility.isEmptyObject(row?.productVariantCode)) {
      invalidFields.push('product');
    }
    if (adjustmentType === STOCK_ADJUSTMENT_TYPE.STOCK_OUT) {
      const isNegativeInventoryOn =
        row.type === PRODUCT_TYPE.TRACKED &&
        row.productType === ADVANCE_TRACKING.NONE &&
        tenantInfo.allowNegativeInventory;

      if (!isNegativeInventoryOn) {
        if (row.availableQuantity <= 0) {
          invalidFields.push('availableQuantity');
        } else {
          if (row.availableQuantity < row.quantity) {
            invalidFields.push('quantity');
          }
        }
      }
    }
    if (Utility.isEmptyObject(row.stockAdjustmentAccount)) {
      invalidFields.push('stockAdjustmentAccount');
    }
    if (Utility.isEmptyObject(row.uomSchema)) {
      invalidFields.push('uomSchema');
    }

    return invalidFields;
  };

  const addProductCustomFieldsToLineItem = (lineItem: any, product: any) => {
    let cfs: any[] = [];
    if (
      !Utility.isEmpty(productCustomFields) &&
      !Utility.isEmpty(product.customField)
    ) {
      // Set default values of CFs when new line is added
      product.customField.forEach((productCF: any) => {
        const filteredCF = productCustomFields?.find(
          (field: any) =>
            field.id === productCF.id && field.status === STATUS_TYPE.ACTIVE
        );
        if (filteredCF) {
          let cfToUpdate = {
            id: filteredCF.id,
            shortName: filteredCF.shortName,
            module: filteredCF.module,
            code: filteredCF.code,
            label: filteredCF.label,
            value: ''
          };
          let valueOfCF = '';
          if (
            typeof productCF.value !== 'undefined' &&
            productCF.value !== null &&
            productCF.value !== ''
          ) {
            if (
              filteredCF.fieldType.toLowerCase() ===
              INPUT_TYPE.DATE.toLowerCase()
            ) {
              lineItem[productCF.id] = DateFormatService.getDateFromStr(
                productCF.value,
                BOOKS_DATE_FORMAT['MM/DD/YYYY']
              );
            } else if (filteredCF.fieldType.toLowerCase() === 'user') {
              const tempCF = filteredCF?.attributes?.find(
                (attr: any) => attr.code === productCF.value
              );
              if (tempCF) {
                lineItem[productCF.id] = tempCF;
              }
            } else if (
              filteredCF.fieldType.toLowerCase() ===
              INPUT_TYPE.DROPDOWN.toLowerCase()
            ) {
              const tempCF = filteredCF?.attributes?.find(
                (attr: any) => attr.value === productCF.value
              );
              if (tempCF) {
                lineItem[productCF.id] = tempCF;
              }
            } else {
              lineItem[productCF.id] = productCF.value;
            }
            valueOfCF = productCF.value;
          } else {
            lineItem[productCF.id] = '';
          }
          cfToUpdate.value = valueOfCF;
          cfs.push(cfToUpdate);
        }
      });
    }
    return { ...lineItem, customField: cfs };
  };

  const onRowUpdate = async ({ columnKey, rowData, rowIndex }: any) => {
    let tmpStockAdjustmentState = { ...stockAdjustmentState };
    let selectedRow: any = {
      ...tmpStockAdjustmentState.stockAdjustmentItems[rowIndex]
    };
    switch (columnKey) {
      case 'product':
        showLoader();
        StockTransferService.fetchProductDocSeqCode([
          rowData.product.documentSequenceCode
        ])
          .then(async (res: any) => {
            let productData: any = res[0];
            if (uomData && uomData.length > 0) {
              let UOM = uomData.find((u: any) => u.id === res[0].stockUom);
              if (Utility.isNotEmpty(UOM)) {
                let stockItem: StockAdjustmentItems = getStockItem(
                  productData,
                  rowData,
                  UOM
                );
                if (Utility.isEmpty(stockItem?.stockAdjustmentAccount)) {
                  await AccountsService.getAccountbyCode(
                    productData.stockAdjustmentAccountCode
                  )
                    .then((res: any) => {
                      if (res && res.content && res.content.length > 0) {
                        let accountObj = {
                          ...res.content[0],
                          label: res.content[0].name,
                          value: res.content[0].code
                        };
                        stockItem['stockAdjustmentAccount'] = accountObj;
                      }
                    })
                    .catch((err: any) => {
                      console.log(
                        'failed to load account details for',
                        productData.stockAdjustmentAccountCode
                      );
                      stockItem['stockAdjustmentAccount'] = '';
                    });
                }
                // productData.availableQuantity =
                //   getProductAvailableQuantity(productData?.pid) ?? 0;
                // stockItem.availableQuantity = getProductAvailableQuantity(
                //   productData?.pid
                // );
                // stockItem.availableQuantityInBaseUOM =
                //   getProductAvailableQuantity(productData?.pid) ?? 0;
                if (productData?.advancedTracking === TRACKING_TYPE.NONE) {
                  ProductService.fetchWarehouseProductsByIDWithRejectedWh([
                    productData?.pid
                  ]).then((res: any) =>
                    onNoneTrackedInfoSuccess(res, stockItem, productData)
                  );
                } else {
                  ProductService.fetchProductAdvancedTrackingWarehouse(
                    [productData?.pid],
                    false,
                    true
                  ).then((res: any) =>
                    onTrackedInfoSuccess(res, stockItem, productData)
                  );
                }
                stockItem = addProductCustomFieldsToLineItem(
                  { ...stockItem },
                  productData
                );
                stockItem.rowContextMenu = getButtonsForRow(productData);
                assignTrackingIcon(stockItem);
                selectedRow = stockItem;
                setUOMsForSelectedProduct(stockItem);
                selectedRow['invalidFields'] = getInvalidFields(selectedRow);
                selectedRow.batchDetails = [];
                selectedRow.serialNumbers = [];
                tmpStockAdjustmentState.stockAdjustmentItems[rowIndex] =
                  selectedRow;
                setStockAdjustmentState(tmpStockAdjustmentState);
              }
            }
          })
          .catch((error: any) => {})
          .finally(() => {
            removeLoader();
          });
        break;
      case 'quantity':
        const quantity = isNaN(rowData.quantity)
          ? 1
          : Number(rowData.quantity) || 1;
        selectedRow.quantity = Utility.roundingOff(
          quantity,
          QTY_ROUNDOFF_PRECISION
        );
        selectedRow.uomQuantity = selectedRow.quantity;
        selectedRow.uomUnitPrice = rowData?.perUnitValue;
        selectedRow.batchDetails = [];
        selectedRow.serialNumbers = [];
        selectedRow.totalAmount = selectedRow.quantity * rowData?.perUnitValue;
        selectedRow['invalidFields'] = getInvalidFields(selectedRow);
        assignTrackingIcon(selectedRow);
        tmpStockAdjustmentState.stockAdjustmentItems[rowIndex] = selectedRow;
        setStockAdjustmentState(tmpStockAdjustmentState);
        break;
      case 'perUnitValue':
        let perUnitValue = Utility.roundOffToTenantDecimalScale(
          rowData?.perUnitValue
        );
        selectedRow.uomUnitPrice = perUnitValue;
        selectedRow.totalAmount = Utility.roundOffToTenantDecimalScale(
          rowData?.quantity * perUnitValue
        );
        selectedRow.perUnitValue = perUnitValue;
        selectedRow['invalidFields'] = getInvalidFields(selectedRow);
        assignTrackingIcon(selectedRow);
        tmpStockAdjustmentState.stockAdjustmentItems[rowIndex] = selectedRow;
        setStockAdjustmentState(tmpStockAdjustmentState);
        break;
      case 'uomSchema':
        selectedRow = updateUomSchemaForLineItem(
          tmpStockAdjustmentState.stockAdjustmentItems,
          rowIndex,
          rowData[columnKey]
        );
        selectedRow.uomSchema = rowData[columnKey];
        selectedRow.batchDetails = [];
        selectedRow.serialNumbers = [];
        selectedRow['invalidFields'] = getInvalidFields(selectedRow);
        assignTrackingIcon(selectedRow);
        tmpStockAdjustmentState.stockAdjustmentItems[rowIndex] = selectedRow;
        setStockAdjustmentState(tmpStockAdjustmentState);
        break;
    }
  };

  const assignTrackingIcon = (selectedRow: any) => {
    let allocateActionButtonIndex = selectedRow.rowContextMenu.findIndex(
      (button: any) => button.type === STOCK_ADJUSTMENT_ACTION_TYPE.ALLOCATE
    );
    if (
      allocateActionButtonIndex !== -1 &&
      [TRACKING_TYPE.BATCH, TRACKING_TYPE.SERIAL].includes(
        selectedRow.productType as TRACKING_TYPE
      )
    ) {
      const isAllocated = isProductAllocated(selectedRow);
      selectedRow['isAllocated'] = isAllocated;
      selectedRow.rowContextMenu[allocateActionButtonIndex].icon = isAllocated
        ? ic_bom_allocate_green
        : ic_bom_allocate_red;
    } else {
      selectedRow['isAllocated'] = true;
    }
  };

  const isProductAllocated = (row: StockAdjustmentItems) => {
    let isAllocated = false;
    let quantity = Number(isNaN(row.quantity) ? 0 : row.quantity);
    if (row.productType === TRACKING_TYPE.BATCH) {
      let allocateQuantity = row.batchDetails?.reduce(
        (acc: number, item: any) =>
          acc + Number(isNaN(item.quantity) ? 0 : item.quantity),
        0
      );
      isAllocated =
        quantity !== 0 &&
        quantity ===
          Utility.roundingOff(allocateQuantity, QTY_ROUNDOFF_PRECISION);
    } else {
      if (row.productType === TRACKING_TYPE.SERIAL) {
        if (Utility.isNotEmpty(row?.uomSchema)) {
          let uomQuantity = Utility.getUomWarehouseQuantity(
            quantity,
            row.uomSchema
          );
          if (!isNaN(uomQuantity)) {
            quantity = uomQuantity;
          }
        }

        isAllocated = quantity !== 0 && quantity === row?.serialNumbers?.length;
      }
    }
    return isAllocated;
  };

  const updateUomSchemaForLineItem = (
    rows: any,
    index: number,
    uomData: any
  ) => {
    let selectedRow = { ...rows[index] };
    let unitPrice = selectedRow.perUnitValue ? selectedRow.perUnitValue : 0;
    const documentUOMSchemaDefinition = selectedRow.uomSchema;
    if (unitPrice) {
      if (documentUOMSchemaDefinition) {
        unitPrice =
          (unitPrice * documentUOMSchemaDefinition.sinkConversionFactor) /
            documentUOMSchemaDefinition.sourceConversionFactor || 0;
      }
      if (!uomData.isBaseUom) {
        unitPrice =
          (unitPrice * uomData.sourceConversionFactor) /
          uomData.sinkConversionFactor;
      }
    }

    if (unitPrice) {
      selectedRow.uomUnitPrice = Utility.roundOff(unitPrice);
      selectedRow.perUnitValue = Utility.roundOff(unitPrice);
    }
    let availableQuantity = selectedRow.availableQuantityInBaseUOM;
    if (
      selectedRow.uomSchema &&
      !selectedRow.uomSchema.isBaseUom &&
      stockAdjustmentState?.adjustmentType?.value === 'STOCK_OUT'
    ) {
      availableQuantity = Utility.getUomQuantity(
        selectedRow.availableQuantityInBaseUOM,
        selectedRow.uomSchema
      );
    }

    selectedRow.totalAmount = selectedRow?.quantity * unitPrice;
    if (uomData.isBaseUom) {
      selectedRow.documentUOMSchemaDefinition = null;
      selectedRow.documentUom = uomData.id;
    } else {
      selectedRow.documentUOMSchemaDefinition = {
        uid: uomData.uid,
        sourceUOM: uomData.sourceUOM,
        sourceConversionFactor: uomData.sourceConversionFactor,
        sinkUOM: uomData.sinkUOM,
        sinkConversionFactor: uomData.sinkConversionFactor,
        id: uomData.sinkUOM,
        name: uomData.name,
        isBaseUom: false,
        schemaId: selectedRow.uomSchemaDto.id
      };

      selectedRow.documentUom = uomData.sinkUOM;
    }
    selectedRow.availableQuantity = Utility.roundingOff(
      availableQuantity,
      QTY_ROUNDOFF_PRECISION
    );
    return selectedRow;
  };

  const getColumns = () => {
    let columns = [];
    if (
      stockAdjustmentState?.adjustmentType?.value ===
      STOCK_ADJUSTMENT_TYPE.STOCK_IN
    ) {
      columns = getStockInDataGridColumns();
    } else if (
      stockAdjustmentState?.adjustmentType?.value ===
      STOCK_ADJUSTMENT_TYPE.STOCK_OUT
    ) {
      columns = getStockOutDataGridColumns();
    }
    return columns;
  };

  const getDataGrid = () => (
    <DKDataGrid
      needShadow={false}
      needBorder={true}
      needColumnIcons={false}
      needTrailingColumn={true}
      allowColumnSort={false}
      allowColumnAdd={false}
      allowColumnEdit={false}
      allowRowEdit={!isEdit}
      columns={columnConfigForGrid}
      rows={stockAdjustmentState.stockAdjustmentItems}
      onRowUpdate={onRowUpdate}
      currentPage={1}
      totalPageCount={1}
      title={''}
      allowBulkOperation={false}
      onRowClick={onRowClick}
    />
  );

  const updateStockAdjustmentFormValue = (fieldName: string, value: any) => {
    let newState = { ...stockAdjustmentState };
    switch (fieldName) {
      case 'adjustmentDate':
        newState.adjustmentDate = value;
        setStockAdjustmentState({ ...newState });
        break;
      case 'warehouseCode':
        newState.warehouseCode = value;
        setStockAdjustmentState({ ...newState });
        setSelectedWarehouse(value);
        // dispatch(fetchSourceWarehousesWithRejectedWh(value?.code));
        break;
      case 'warehouseRRB':
        // Warehouse State
        newState.warehouseCode = value?.warehouse;

        // RRB State
        newState.rowCode = value?.rowCode ?? null;
        newState.rackCode = value?.rackCode ?? null;
        newState.binCode = value?.binCode ?? null;

        setStockAdjustmentState({ ...newState });
        setSelectedWarehouse(value?.warehouse);
        // dispatch(fetchSourceWarehousesWithRejectedWh(value?.warehouse?.code));
        break;
      case 'adjustmentType':
        getAdjustmentReasonOptions(value.value);
        newState.adjustmentType = value;
        newState.adjustmentReason =
          newState.adjustmentType === StockAdjustmentType[0]
            ? StockInReasons[0]
            : StockOutReasons[0];
        newState.stockAdjustmentItems = [getEmptyNewRow()];
        setStockAdjustmentState({ ...newState });
        break;
      case 'adjustmentReason':
        newState.adjustmentReason = value;
        setStockAdjustmentState({ ...newState });
        break;
      case 'notes':
        newState.notes = value;
        setStockAdjustmentState({ ...newState });
        break;
    }
  };

  const adjustmentDateChange = (inpDate: any) => {
    if (
      validateAndUpdateDate(
        inpDate,
        DateFormatService.getDateFromStr(
          tenantInfo.bookBeginningStartDate,
          BOOKS_DATE_FORMAT['YYYY-MM-DD']
        )
      )
    ) {
      showAlert(
        'Invalid Date',
        `Adjustment date cannot be before books beginning date.`
      );
      updateStockAdjustmentFormValue('adjustmentDate', new Date());
      return;
    }
    if (!Utility.checkClosingDate(inpDate, 'Stock Adjustment Date')) {
      updateStockAdjustmentFormValue('adjustmentDate', new Date());
      return;
    }
    if (!checkDateValidation(inpDate, tenantInfo)) {
      updateStockAdjustmentFormValue('adjustmentDate', new Date());
    } else {
      updateStockAdjustmentFormValue('adjustmentDate', inpDate);
    }
  };

  const validateAndUpdateDate = (newDate: Date, minAcceptedDate: Date) => {
    if (newDate.getTime() < minAcceptedDate.getTime()) {
      return true;
    } else {
      return false;
    }
  };

  const customFieldUpdated = (cfList: CustomField[]) => {
    const newState = { ...stockAdjustmentState };
    newState.customField = cfList;
    setStockAdjustmentState(newState);
  };

  const getAddClassForm = () => (
    <AddClass
      data={null}
      onSuccess={() => {
        dispatch(fetchCategoryDimensions());
        dispatch(fetchClassesByDimensionId());
        dispatch(fetchCustomFields({ status: 'ACTIVE', limit: '1000' }));
      }}
      onCancel={() => {
        setShowAddClassPopup(false);
      }}
    />
  );

  const getAutoNumberingInput = (
    <DKInput
      type={INPUT_TYPE.TEXT}
      canValidate={false}
      readOnly={true}
      title={'Auto Numbering Format'}
      className="mb-l text-gray"
      direction={INPUT_VIEW_DIRECTION.VERTICAL}
      value={''}
      placeholder={
        !Utility.isEmpty(stockAdjustmentState?.autoNumberingFormat) && isEdit
          ? stockAdjustmentState?.autoNumberingFormat
          : 'ADJ-000000'
      }
      required={false}
    />
  );

  const getDateInput = (
    <DKInput
      readOnly={isEdit ? true : false}
      canValidate={false}
      title={'Date'}
      className="mb-l"
      direction={INPUT_VIEW_DIRECTION.VERTICAL}
      type={INPUT_TYPE.DATE}
      value={stockAdjustmentState?.adjustmentDate}
      required={true}
      onChange={(value: any) => {
        if (isAfter(value, new Date())) {
          showAlert('Error', 'Stock adjustment date cannot be a future date.');
          adjustmentDateChange(new Date());
        } else {
          adjustmentDateChange(value);
        }
      }}
      dateFormat={convertBooksDateFormatToUILibraryFormat(
        tenantInfo.dateFormat
      )}
    />
  );

  const getTypeInput = (
    <DKInput
      readOnly={isEdit ? true : false}
      type={INPUT_TYPE.DROPDOWN}
      title={`Type`}
      direction={INPUT_VIEW_DIRECTION.VERTICAL}
      value={stockAdjustmentState?.adjustmentType}
      canValidate={false}
      formatter={(obj: any) => {
        return obj?.name;
      }}
      required={true}
      onChange={(obj: any) => {
        updateStockAdjustmentFormValue('adjustmentType', obj);
      }}
      className="mb-l"
      dropdownConfig={{
        title: '',
        allowSearch: false,
        searchableKey: '',
        canEdit: false,
        canDelete: false,
        style: { minWidth: 150 },
        className: 'shadow-m width-auto',
        searchApiConfig: null,
        data: StockAdjustmentType,
        renderer: (index: any, obj: any) => {
          return <DKLabel text={`${obj?.name}`} />;
        }
      }}
    />
  );

  const stockAdjustmentForm = () => {
    return (
      <div className="column parent-width p-r">
        {!isFullScreenMode && (
          <div className="column parent-width">
            <div className="row" style={{ gap: 12 }}>
              <div className="w-1/2">{getAutoNumberingInput}</div>
              <div className="w-1/2">{getDateInput}</div>
            </div>
            <div className="row" style={{ gap: 12 }}>
              <div className="w-1/2">{getTypeInput}</div>
              <div className="w-1/2">
                {getAdjustmentReason()}
                {showAddReasonPopup &&
                  getAddAdjustmentReasonView(
                    stockAdjustmentState?.adjustmentType
                  )}
              </div>
            </div>
          </div>
        )}
        {isFullScreenMode && (
          <div className="row align-items-end flex-wrap" style={{ gap: 12 }}>
            <div
              style={{
                width: 170,
                maxWidth: 200
              }}
            >
              {getAutoNumberingInput}
            </div>
            <div
              style={{
                width: 170,
                maxWidth: 200
              }}
            >
              {getDateInput}
            </div>
            <div
              style={{
                width: 170,
                maxWidth: 200
              }}
            >
              {getTypeInput}
            </div>
            <div
              style={{
                width: 215
              }}
            >
              {getAdjustmentReason()}
              {showAddReasonPopup &&
                getAddAdjustmentReasonView(
                  stockAdjustmentState?.adjustmentType
                )}
            </div>
          </div>
        )}

        <div
          className="row"
          style={{
            width: WarehouseManagementHelper.isRRBEnabledForWarehouse(
              stockAdjustmentState?.warehouseCode
            )
              ? isFullScreenMode && isViewportLarge()
                ? '70%'
                : '100%'
              : 280
          }}
        >
          <div className="parent-width mb-l">
            <ReturnWarehouseManagementRRB
              editMode={!Utility.isEmpty(props.data) ? true : false}
              editData={!Utility.isEmpty(props.data) ? props?.data : {}}
              onSave={(data: any) => {
                updateStockAdjustmentFormValue('warehouseRRB', data);
              }}
              saveButtonTapped={saveButtonTapped}
              isIncludeRejectedWh={
                stockAdjustmentState?.adjustmentType === StockAdjustmentType[0]
                  ? false
                  : true
              }
            />
          </div>
        </div>
        <div className="row">
          <div className="column parent-width mb-l">
            <CustomFieldsHolder
              moduleName={MODULES_NAME.STOCK_ADJUSTMENT}
              customFieldsList={
                props.data?.customField ? props.data.customField : []
              }
              columnConfig={columnConfig}
              columnConfigTableId={columnConfigTableId}
              documentMode={isEdit ? DOCUMENT_MODE.VIEW : DOCUMENT_MODE.NEW}
              onUpdate={(list) => customFieldUpdated(list)}
            />
          </div>
        </div>
        {Utility.isNotEmpty(columnConfigForGrid) && (
          <div className="row">
            <div className="parent-width">{getDataGrid()}</div>
          </div>
        )}
        {!isEdit && (
          <div className="row">
            <DKButton
              disabled={
                isEdit || Utility.isEmpty(stockAdjustmentState?.warehouseCode)
                  ? true
                  : false
              }
              title={`+ Add Item`}
              onClick={() => addNewItem()}
              className={`${
                isEdit || Utility.isEmpty(stockAdjustmentState?.warehouseCode)
                  ? 'text-gray'
                  : 'text-blue'
              } fw-m p-0`}
              style={{ marginTop: -10, zIndex: 1, paddingLeft: 0 }}
            />
          </div>
        )}
        <div className="row">
          <div className="mobile-full-grid-width">
            <textarea
              className="resize-none p-2 border rounded outline-none border-gray-200 hover:border-gray-300 focus:border-gray-400 overflow-auto mt-5"
              style={{
                width: '100%',
                height: 130,
                backgroundColor: 'rgb(250, 250, 250)',
                border: '1px dashed rgb(200, 200, 200)'
              }}
              readOnly={props.readOnly}
              placeholder={`Notes`}
              value={stockAdjustmentState?.notes}
              onChange={(e: any) => {
                updateStockAdjustmentFormValue('notes', e.target.value);
              }}
            ></textarea>
          </div>
        </div>
      </div>
    );
  };

  const saveAdvancedStockTracking = (currentRow: any) => {
    let updatedState = { ...stockAdjustmentState };
    const selectedRow =
      updatedState.stockAdjustmentItems[
        selectedAdvancedStockTrackingProduct.fulfillmentIndex
      ];
    if (currentRow?.productType === TRACKING_TYPE.BATCH) {
      selectedRow.batchDetails = currentRow.batchDetails;
    } else if (currentRow?.productType === TRACKING_TYPE.SERIAL) {
      selectedRow.serialNumbers = currentRow.serialNumbers;
    }
    assignTrackingIcon(selectedRow);
    updatedState.stockAdjustmentItems[
      selectedAdvancedStockTrackingProduct.fulfillmentIndex
    ] = selectedRow;
    setStockAdjustmentState(updatedState);
  };

  const getAssignedSerialBatchNumber = (stockItem: any) => {
    if (Utility.isNotEmpty(stockItem)) {
      const { product } = stockItem;
      const { stockAdjustmentItems } = stockAdjustmentState;
      const otherStockItems = stockAdjustmentItems.filter(
        (item: StockAdjustmentItems) =>
          item.stockItemNumber !== stockItem.stockItemNumber &&
          item.productVariantCode === product.pid
      );

      if (Utility.isNotEmpty(otherStockItems)) {
        let serialBatchNumbers: any[] = [];
        otherStockItems.reduce(
          (numbers: string[], item: StockAdjustmentItems) => {
            if (product.advancedTracking === TRACKING_TYPE.SERIAL) {
              numbers.push(...item.serialNumbers);
            } else if (product.advancedTracking === TRACKING_TYPE.BATCH) {
              numbers.push(...item.batchDetails);
            }
            return numbers;
          },
          serialBatchNumbers
        );

        return serialBatchNumbers;
      }
    }
    return [];
  };

  return (
    <DynamicPopupWrapper>
      <div className="transparent-background">
        <div
          className="popup-window"
          style={{
            maxWidth: isFullScreenMode ? '100%' : 850,
            width: isFullScreenMode ? '100%' : '90%',
            maxHeight: isFullScreenMode ? '100%' : '95%',
            height: isFullScreenMode ? '100%' : '95%',
            padding: 0,
            paddingBottom: 30,
            borderRadius: isFullScreenMode ? 0 : 4
          }}
        >
          {getHeader()}
          <div
            id="popup-container"
            className="column parent-width parent-height"
            style={{
              pointerEvents: 'auto',
              paddingBottom: 20
            }}
          >
            {stockAdjustmentForm()}
          </div>
          {showAdvancedStockTrackingPopup && (
            <StockAdjustmentAdvancedStockTracking
              existingSerialBatchNumbers={getAssignedSerialBatchNumber(
                selectedAdvancedStockTrackingProduct
              )}
              isReadOnly={isEdit}
              data={selectedAdvancedStockTrackingProduct}
              onCancel={() => setShowAdvancedStockTrackingPopup(false)}
              onSave={(data: any) => {
                saveAdvancedStockTracking(data);
                setShowAdvancedStockTrackingPopup(false);
              }}
            />
          )}
          {showAddClassPopup && getAddClassForm()}
        </div>
      </div>
    </DynamicPopupWrapper>
  );
};

export default CreateStockAdjustment;
