import CustomFieldManager from "../../Manager/CustomFieldManager"
import TemplateSettingsManager from "../../Manager/TemplateSettingsManager"
import { convertDateFromERPFormatToDocFormat, getCapitalized, getConvertedDate } from "../../Utilities/Utility"
import Address from "../Address"
import AddressParser, { AddressType, OwnerType } from "../AddressParser"
import DataParser from "../DataParser"
import LineItemsParser from "../LineItemsParser"
import NewDataParser from "../NewDataParser"
import TenantParser from "../TenantParser"

export default class FulfillmentParser {
    static parseData(dataFromAPI, templateDataToReturn) {

        //custom field
        templateDataToReturn.customFields = dataFromAPI.fulfillment.customField
        CustomFieldManager.setCustomFields(dataFromAPI.fulfillment.customField)
        templateDataToReturn = DataParser.assignCustomFieldValue(templateDataToReturn)

         //company name
        var name = ''
        if (dataFromAPI.tenantInfo !== undefined && dataFromAPI.tenantInfo !== null) {
            if (dataFromAPI.tenantInfo.name !== undefined && dataFromAPI.tenantInfo.name !== null) {
                name = getCapitalized(dataFromAPI.tenantInfo.name)
            }
        }
        templateDataToReturn.companyName = name
        templateDataToReturn.tenantInfo = dataFromAPI.tenantInfo;

        //company address
        var fromAddressObj = undefined
        if (dataFromAPI.tenantInfo !== undefined && dataFromAPI.tenantInfo !== null) {
            if (dataFromAPI.tenantInfo.address !== undefined && dataFromAPI.tenantInfo.address !== null) {
                fromAddressObj = new Address(undefined, AddressParser.getTenantAddressFromApi(dataFromAPI.tenantInfo.address, dataFromAPI.tenantInfo), dataFromAPI.pan, dataFromAPI.gstin, dataFromAPI.tenantInfo.contactNumber, undefined, OwnerType.tenant)
            }
        }
        templateDataToReturn.fromObj = fromAddressObj
        templateDataToReturn = AddressParser.assignAddressObjToStringForNonArabic(undefined, AddressType.from, templateDataToReturn)

        // billing to address
        var clientBillToAddressObj = undefined
        var clientName = undefined
        templateDataToReturn.taxRegistrationNumber = ""
        templateDataToReturn.taxPayerId = ""

        if (dataFromAPI.parentDocumentData !== undefined || dataFromAPI.parentDocumentData !== null) {
            if (dataFromAPI.parentDocumentData.contactInformation !== undefined && dataFromAPI.parentDocumentData.contactInformation !== null) {
                if (dataFromAPI.parentDocumentData.contactInformation.name !== null) {
                    clientName = dataFromAPI.parentDocumentData.contactInformation.name
                }
            }
            if (dataFromAPI.parentDocumentData.shipTo !== undefined && dataFromAPI.parentDocumentData.shipTo !== null) {
                clientBillToAddressObj = new Address(clientName, dataFromAPI.parentDocumentData.shipTo, dataFromAPI.pan, dataFromAPI.gstin, dataFromAPI.parentDocumentData.contactInformation.contactNumber, dataFromAPI.parentDocumentData.contactInformation.documentSequenceCode, OwnerType.contact)
            }

            templateDataToReturn.taxRegistrationNumber = dataFromAPI.parentDocumentData.contactInformation.taxNumber ?? ''
            templateDataToReturn.taxPayerId = dataFromAPI.parentDocumentData.contactInformation.taxPayerIdIsrael ?? ''
        }

        if (dataFromAPI.fulfillment !== undefined && dataFromAPI.fulfillment !== null) {
            if (dataFromAPI.fulfillment.shipTo !== undefined && dataFromAPI.fulfillment.shipTo !== null) {
                clientBillToAddressObj = new Address(clientName, dataFromAPI.fulfillment.shipTo, dataFromAPI.pan, dataFromAPI.gstin, dataFromAPI.parentDocumentData.contactInformation.contactNumber, dataFromAPI.parentDocumentData.contactInformation.documentSequenceCode, OwnerType.contact)
            }
        }
        
        templateDataToReturn.clientName = clientName
        templateDataToReturn.clientBillToAddressObj = clientBillToAddressObj
        templateDataToReturn = AddressParser.assignAddressObjToStringForNonArabic(undefined, AddressType.billTo, templateDataToReturn)

        // shipping to address
        var clientShipToAddressObj = undefined
        if (dataFromAPI.parentDocumentData !== undefined || dataFromAPI.parentDocumentData !== null) {
            if (dataFromAPI.parentDocumentData.shipFrom !== undefined && dataFromAPI.parentDocumentData.shipFrom !== null) {
                clientShipToAddressObj = new Address(undefined, dataFromAPI.parentDocumentData.shipFrom, dataFromAPI.pan, dataFromAPI.gstin, dataFromAPI.tenantInfo.contactNumber, undefined, OwnerType.tenant)
            }
        }
        if (dataFromAPI.fulfillment !== undefined && dataFromAPI.fulfillment !== null) {
            if (dataFromAPI.fulfillment.shipFrom !== undefined && dataFromAPI.fulfillment.shipFrom !== null) {
                clientShipToAddressObj = new Address(undefined, dataFromAPI.fulfillment.shipFrom, dataFromAPI.pan, dataFromAPI.gstin, dataFromAPI.tenantInfo.contactNumber, undefined, OwnerType.tenant)
            }
        }
        templateDataToReturn.clientShipToAddressObj = clientShipToAddressObj
        templateDataToReturn = AddressParser.assignAddressObjToStringForNonArabic(undefined, AddressType.shipTo, templateDataToReturn)

        //ref number
        var linkDocumentNumber = ''
        var linkDocumentType = ''
        var fulfillmentCode = ''
        if (dataFromAPI.fulfillment !== undefined && dataFromAPI.fulfillment !== null) {
            fulfillmentCode = NewDataParser.getDefaultValue(dataFromAPI.fulfillment.fulfillment_code)
            if (dataFromAPI.parentDocumentData !== undefined && dataFromAPI.parentDocumentData !== null) {
                linkDocumentNumber = NewDataParser.getDefaultValue(dataFromAPI.parentDocumentData.documentCode)
                linkDocumentType = NewDataParser.getDefaultValue(dataFromAPI.parentDocumentData.documentType)
            }
        }
        templateDataToReturn.refNumber = fulfillmentCode
        templateDataToReturn.linkDocumentNumber = linkDocumentNumber
        templateDataToReturn.linkDocumentType = linkDocumentType
        templateDataToReturn.showLinkDocumentNumber = TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails.linkDocumentNumber.isVisible

        //start date
        var fulfillmentDate = ''
        if (dataFromAPI.fulfillment !== undefined && dataFromAPI.fulfillment !== null) {
            fulfillmentDate = NewDataParser.getDefaultValue(dataFromAPI.fulfillment.fulfillmentDate)
            templateDataToReturn.customerOrderNumber = dataFromAPI.fulfillment.customerOrderNumber
        }
        templateDataToReturn.date = getConvertedDate(convertDateFromERPFormatToDocFormat(fulfillmentDate), templateDataToReturn.dateFormat)

        //due date
        templateDataToReturn.dueDate = ''
        templateDataToReturn.showDueDate = false

        //line item
        templateDataToReturn.lineItems = LineItemsParser.getFulfillmentItem(dataFromAPI, templateDataToReturn.dateFormat)

        //location
        templateDataToReturn = TenantParser.parseLocationDetail(dataFromAPI, templateDataToReturn)

        return templateDataToReturn
    }
}
