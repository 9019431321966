import { CountryInfo } from '../Models/Interfaces';
import { ReactSelectOptionsType } from '../Models/Interfaces';
import { KeyAndValue } from '../Models/NewContact';
import { COMMON_CONSTANT, PpsStatus } from './Constant';
import { COMPLIANCE_SPECIFIC_FIELD_NAME, DASHBOARD_PERIODS } from './Enum';

export interface IndianStates {
  code: string;
  name: string;
  stateCode: string;
  cities: string[];
}

export interface VatSchemes {
  code: string;
  name: string;
}

export interface NewAccountAdd {
  name: string;
}
export const TYPE_OPTION_TAX: ReactSelectOptionsType<string, string>[] = [
  {
    label: 'PURCHASE',
    value: 'PURCHASE'
  },
  {
    label: 'SALES',
    value: 'SALES'
  },
  {
    label: 'BOTH',
    value: 'BOTH'
  }
];

export const APPLY_TO_TAX_GROUP: ReactSelectOptionsType<string, string>[] = [
  {
    label: 'Pre-tax Amount',
    value: 'Pre-tax Amount'
  },
  {
    label: 'After-tax Amount',
    value: 'After-tax Amount'
  }
];
export const NEW_ACCOUNT_MOCK_DATA: NewAccountAdd[] = [
  {
    name: 'Bank'
  },
  {
    name: 'Cash'
  },
  {
    name: 'Current Assets'
  },
  {
    name: 'Current Liabilities'
  },
  {
    name: 'Long Term Assets'
  },
  {
    name: 'Long Term Liabilities'
  },
  {
    name: 'Other Current Assets'
  },
  {
    name: 'Other Current Liabilities'
  }
];

export const NEW_TAX_MOCK_DATA: NewAccountAdd[] = [
  {
    name: 'Purchase Tax'
  },
  {
    name: 'GST 0%'
  },
  {
    name: 'GST 5%'
  },
  {
    name: 'GST 12%'
  },
  {
    name: 'GST 18%'
  },
  {
    name: 'GST 28%'
  }
];
export const PRICE_LIST_TYPE_MOCK_DATA: any[] = [
  {
    name: 'Buy',
    value: 'BUY'
  },
  {
    name: 'Sell',
    value: 'SELL'
  }
];
export const NEW_NIP_MOCK_DATA: NewAccountAdd[] = [
  {
    name: 'Nature of Income Payment'
  },
  {
    name: 'Payment / credit of Income from Securities to Foreign Institutional Investors'
  },
  {
    name: 'Payment / Credit of Interest of Foreign Currency Bonds or GDR (including LTCG on Transfer of such Bonds)'
  },
  {
    name: 'Payment/credit of Income from Units(Including LTCG on Transfer of such units) to an offshore fund'
  },
  {
    name: 'Income by way of Interest payable by Government / Indian Concern on Money Borrowed or Debt Incurred in Foreign currency'
  },
  {
    name: 'Any other LTCG not covered u/s 10(33)'
  },
  {
    name: 'Fees for Technical services'
  },
  {
    name: 'STCG u/s 111A'
  },
  {
    name: 'Royalty'
  },
  {
    name: 'Income of Foreign exchange assets payable to an Indian Citizen'
  },
  {
    name: 'Income by way of LTCG referred to u/s 115E or 112(1)(c ) (iii)'
  },
  {
    name: 'Interest on Rupee denominated bond of an Indian company / Government security'
  },
  {
    name: 'Payment / Credit of Interest by an Indian Specified Company on Foreign Currency approved loan /Long Term Bonds from outside India'
  },
  {
    name: 'Payment in respect of Investment in Securitisation Trust specified u/s 115TCA'
  },
  {
    name: 'Income in respect of investment in Securitisation Fund : Payment or Credit to Individual / HUF'
  },
  {
    name: 'Income in respect of investment in Securitisation Fund : Payment or Credit to any person other than an Individual / HUF'
  },
  {
    name: 'Payment in respect of units of Investment Fund specified u/s 115UB'
  },
  {
    name: 'Payment of the nature referred to u/s 10(23FCA) by Business Trust to Unit Holders'
  },
  {
    name: 'Payment of the nature referred to u/s 10(23FC) by Business Trust to Unit Holders'
  },
  {
    name: 'Payment of the nature specified u/s 10(23FC) / 10(23FCA) by Business Trust to Resident  Unit Holders'
  },
  {
    name: 'Payment or Credit by way of Interest by Infrastructure Debt Fund'
  },
  {
    name: 'Compensation on Acquisition of certain Immovable Property'
  },
  {
    name: 'Professional Fees'
  },
  {
    name: 'Payment / Credit of Consideration to Resident Transferer forTransfer of Immovable Property other than Agricultural Land'
  },
  {
    name: 'Rent - Rent on Plant & Machinery'
  },
  {
    name: 'Rent - Rent on Land /Building /Furniture / Fitting'
  },
  {
    name: 'Commission or Brokerage'
  },
  {
    name: 'Commission on Sale of Lottery Tickets'
  },
  {
    name: 'Payment on account of Re-purchase of Unit by Mutual Fund or UTI'
  },
  {
    name: 'Payment in respect of Deposit under NSS'
  },
  {
    name: 'Payment to Non Resident Foreign Citizen Sportsman/Entertainer or Non Resident Sports Association'
  },
  {
    name: 'Payment in respect of Life Insurance Policy'
  },
  {
    name: 'Insurance Commission'
  },
  {
    name: 'Payment or Credit to Contractor / Sub-Contractor'
  },
  {
    name: 'Winnings from Horse Races'
  },
  {
    name: 'Winnings from Lotteries'
  },
  {
    name: 'Interest other than "Interest on Securities"'
  },
  {
    name: 'Deemed Dividend u/s 2(22)(e )'
  },
  {
    name: 'Interest on Securities'
  },
  {
    name: 'Payment of  taxable Accumulated balance of PF'
  }
];
export const INDIAN_STATES_MOCK_DATA: IndianStates[] = [
  {
    code: 'Andaman & Nicobar Islands',
    name: 'Andaman & Nicobar Islands',
    stateCode: 'AN',
    cities: [
      'Bakultala',
      'Bambooflat',
      'Car Nicobar',
      'Garacharma ',
      'Hut Bay',
      'Kamorta',
      'Malacca',
      'Mayabunder',
      'Port Blair',
      'Prothrapur',
      'Rangat',
      'Tarasa Island'
    ]
  },
  {
    code: 'Andhra Pradesh',
    name: 'Andhra Pradesh',
    stateCode: 'AP',
    cities: [
      'Achanta ',
      'Adoni ',
      'Allur ',
      'Amadalavalasa ',
      'Amalapuram ',
      'Anakapalle ',
      'Anantapalli ',
      'Anantapur ',
      'Anaparthy ',
      'Annavaram ',
      'Araku ',
      'Atchutapuram APSEZ ',
      'Atmakur ',
      'Atmakur Sri Potti Sriramulu Nellore District ',
      'Attilli ',
      'Badampudi ',
      'Badvel ',
      'Banaganapalle ',
      'Bandarulanka',
      'Banumukkala ',
      'Bapatla ',
      'Bestawaripeta ',
      'Bethamcherla ',
      'Bheemunipatnam ',
      'Bhimadole ',
      'Bhimavaram ',
      'Bobbili ',
      'Bowluvada ',
      'Bugganipalle ',
      'Chagallu ',
      'Chebrole Khandrika ',
      'Chebrolu ',
      'Chennamukkapalle ',
      'Chennur ',
      'Chilakaluripet ',
      'Chimakurthy ',
      'Chintalavalasa ',
      'Chintapalle ',
      'Chippada Pharma SEZ ',
      'Chirala ',
      'Chittoor ',
      'Chodavaram ',
      'Cumbum ',
      'Denduluru ',
      'Devarapalli ',
      'Dharmavaram ',
      'Dhone ',
      'Dommara Nandyala ',
      'Dondapadu Kakathiya Cement Factory ',
      'Dondapadu KCP Cement Factory ',
      'Dowleswaram ',
      'Draksharamam ',
      'Dwaraka Tirumala ',
      'Edlapadu ',
      'Ekambarakuppam',
      'Eluru',
      'Gajapathinagaram ',
      'Gajulamandhyam',
      'Industrial Area ',
      'Gajularega ',
      'Ganapavaram ',
      'Gangapatnam ',
      'Gavaravaram ',
      'Giddaluru ',
      'Golugonda ',
      'Gooty ',
      'Gopalapuram ',
      'Gopavaram ',
      'Gudilova ',
      'Gudivada ',
      'Gudur ',
      'Gundugolanu ',
      'Guntakal ',
      'Guntur ',
      'Gurzala ',
      'Hanuman Junction ',
      'Hindupur ',
      'Hiramandalam ',
      'Horseley Hills ',
      'Hukumpeta ',
      'Ibrahimpatnam ',
      'Ichchapuram ',
      'India Cement Factory Chilamakuru ',
      'Indukurpet ',
      'Industrial Growth Centre Thimmanapalem ',
      'Jaggaiahpet ',
      'Jammalamadugu ',
      'Jangareddigudem ',
      'Jarjapupeta ',
      'Jaypee Cement Factory Budawada',
      'Kadapa ',
      'Kadiri ',
      'Kaikaram ',
      'Kakinada ',
      'Kakkalapalle ',
      'Kallur ',
      'Kallur Anantapur',
      'District ',
      'Kaluvaya ',
      'Kalyandurg ',
      'Kanapaka ',
      'Kandukur ',
      'Kanigiri ',
      'Kankipadu ',
      'Kantabamsuguda ',
      'Kathipudi ',
      'Kavali ',
      'Kavali Industrial Area ',
      'Kodumur ',
      'Kodur ',
      'Koikuntla ',
      'Komarolu ',
      'Kondapalle ',
      'Kota ',
      'Kotabammal ',
      'Kothapatnam ',
      'Kothavalasa ',
      'Kovurpalle ',
      'Kovvur ',
      'Kudithipalem ',
      'Kuppam ',
      'Kurnool ',
      'Macherla ',
      'Machilipatnam ',
      'Madanapalle ',
      'Madhurawada',
      'Mandapeta ',
      'Mangalagiri ',
      'Mangampeta ',
      'Mangasamudram ',
      'Markapur ',
      'Maruteru ',
      'Medarametla ',
      'Medikonduru ',
      'Meliaputti ',
      'Moragudi ',
      'Morampudi ',
      'Muddanur ',
      'Mulaguntapadu ',
      'Mulakuddu ',
      'Mummidivaram ',
      'Murakambattu ',
      'Muttukuru ',
      'Mydukur ',
      'Mylavaram ',
      'Nadim Tiruvuru ',
      'Nagaram ',
      'Nagari ',
      'Nagireddipalle ',
      'Nakkapalle ',
      'Nallajerla ',
      'Nandigama ',
      'Nandyal ',
      'Narasannapeta ',
      'Narasapur ',
      'Narasapuram ',
      'Narasaraopet ',
      'Narayanapuram Vizianagaram District ',
      'Narayanavanam ',
      'Narsipatnam ',
      'Nayudupeta ',
      'Nellimarla',
      'Nellore ',
      'Nidadavole ',
      'NTPC Simhadri ',
      'Nuzvid ',
      'Ongole ',
      'Paderu ',
      'Palacole ',
      'Palakonda ',
      'Palamaner ',
      'Palasa Kasibugga ',
      'Pamidi ',
      'Pamur ',
      'Paravada Industrial Area ',
      'Parvathipuram ',
      'Pathapatnam ',
      'Payakaraopeta ',
      'Peda Boddepalle ',
      'Pedana ',
      'Pedatadepalli ',
      'Peddapuram ',
      'Pentapadu ',
      'Perecherla ',
      'Phirangipuram ',
      'Piduguralla ',
      'Pileru ',
      'Pippara ',
      'Pithapuram ',
      'Podalakur ',
      'Podili ',
      'Pondura ',
      'Ponnekallu ',
      'Ponnur ',
      'Porumamilla ',
      'Prattipadu ',
      'Proddatur ',
      'Pulivendula ',
      'Punganur ',
      'Tadepalle ',
      'Puttaparthi ',
      'Tadepalligudem ',
      'Puttur ',
      'Tadpatri ',
      'Rajahmundry ',
      'Tangellamudi ',
      'Rajam ',
      'Tanuku ',
      'Rajampet ',
      'Tekkali ',
      'Ramabhadrapuram ',
      'Tenali ',
      'Ramachandrapuram ',
      'Thagarapuvalasa ',
      'Ramapuram ',
      'Thatipaka ',
      'Ramathirtham ',
      'Thullur ',
      'RAMCO Cement Factory',
      'Thumakunta Industrial Area',
      'Dharmavarappadu ',
      'Tiruchanur ',
      'Rameswaram ',
      'Tirumala ',
      'Rampachodavaram ',
      'Tirupati ',
      'Rayachoti ',
      'Tummikapalle ',
      'Rayadurg ',
      'Tuni ',
      'Razole ',
      'Unguturu ',
      'Repalle ',
      'Uppada ',
      'Rushikonda APIIC ',
      'Upper Sileru ',
      'Salur Samalkot Project Site Camp ',
      'Sanivarapupeta ',
      'Uravakonda ',
      'Santhanuthalapadu ',
      'Vaddeswaram ',
      'Satrampadu ',
      'Vavilla ',
      'Sattenapalle ',
      'Velvadam ',
      'Singarayakonda ',
      'Venkatachalam ',
      'Somandepalle Industrial Area ',
      'Sompeta ',
      'Venkatagiri ',
      'Sricity ',
      'Veparala ',
      'Srikakulam ',
      'Vetapalem ',
      'Srikalahasti ',
      'Vijayapuri South ',
      'Sriramnagar ',
      'Vijayawada ',
      'Srisailam ',
      'Vinnamala ',
      'Srungavarapukota ',
      'Vinukonda ',
      'Sulluru ',
      'Visakhapatnam ',
      'Sunnipenta ',
      'Vissannapet ',
      'Tada ',
      'Vizianagaram',
      'VSP Township ',
      'Vuyyuru ',
      'Yanam ',
      'Yelamanchili ',
      'Yemmiganur ',
      'Yenumalapalle ',
      'Yernagudem ',
      'Yerraguntla'
    ]
  },
  {
    code: 'Arunachal Pradesh',
    name: 'Arunachal Pradesh',
    stateCode: 'AR',
    cities: [
      'Along',
      'AninI',
      'Basar',
      'Boleng',
      'Bomdila',
      'Changlang',
      'Daporijo',
      'Deomali',
      'Dirang',
      'Hawai',
      'Itanagar',
      'Jairampur',
      'Khonsa',
      'Koloriang',
      'Longding',
      'Miao',
      'Naharlagun',
      'Namsai',
      'Pasighat',
      'Roing',
      'Rupa',
      'Sagalee',
      'Seppa',
      'Tawang',
      'Tezu',
      'Yingkiong',
      'Ziro'
    ]
  },
  {
    code: 'Assam',
    name: 'Assam',
    stateCode: 'AS',
    cities: [
      'Abhayapuri',
      'Amguri',
      'Anand Nagar',
      'Asudubi',
      'Azara',
      'Badarpur',
      'Badarpur Rly Town',
      'Bamun Sualkuchi',
      'Bandardewa IID',
      'Bangaon',
      'Barpathar',
      'Barpeta',
      'Barpeta Road',
      'Basugaon',
      'BCPL Township',
      'Behiating AIDC',
      'Belsor',
      'Bhomoraguri AIDC',
      'Bhuragaon',
      'Bihpuria',
      'Bijni',
      'Bilasipara',
      'Biswanath Chariali',
      'Bohari',
      'Bokajan',
      'Bokakhat',
      'Bongaigaon',
      'Bongaigaon RPCL Township',
      'Borgolai Gt 11',
      'Borpukhuri',
      'Chabua',
      'Chalantapara Pt IV',
      'Chandrapur Bagicha',
      'Changsari',
      'Chapakhowa Town',
      'Chapar',
      'Charingia Gaon',
      'Chekonidhara',
      'Dahali',
      'Dalgaon',
      'Damara Patpara',
      'Dergaon',
      'Dhakuakhana',
      'Dharapur',
      'Dhekiajuli',
      'Dhekorgorha',
      'Dhemaji',
      'Dhing',
      'Dhubri',
      'Dibrugarh',
      'Digaru Gaon',
      'Digboi',
      'Diphu',
      'Dispur',
      'Doboka',
      'Dokmoka',
      'Donkamokam',
      'Doom Dooma',
      'Dudhpatil Pt V',
      'Dudhpatil Pt VI',
      'Duliajan',
      'Duliajan No 1',
      'Durga Nagar Part-V',
      'Garal',
      'Gauripur',
      'Goalpara',
      'Gohpur',
      'Golaghat',
      'Golokganj',
      'Goreswar',
      'Gossaigaon',
      'Guwahati',
      'Haflong',
      'Hailakandi',
      'Hamren',
      'Hindustan Paper Corporation Ltd Township',
      'Panchgram',
      'Hojai',
      'Howli',
      'Howraghat',
      'Irongmara',
      'Jagiroad',
      'Jamunamukh',
      'Jhagra Pt III',
      'Jonai Bazar',
      'Jorhat',
      'Kakaya',
      'Kalaigaon Town Part',
      'Kamalabaria NC',
      'Kampur Town',
      'Kanisail Pt I',
      'Karimganj',
      'Khaira Bari',
      'Kharijapikon',
      'Kharupatia',
      'Kochpara',
      'Kokrajhar',
      'Kumar Kaibarta Gaon',
      'Lakhipur',
      'Lakhipur, Cachar District',
      'Lala',
      'Lanka',
      'Lido Tikok',
      'Lido Town',
      'Lumding',
      'Lumding Rly Colony',
      'Mahur',
      'Maibong',
      'Majgaon',
      'Makum',
      'Mangaldoi',
      'Mankachar',
      'Margherita',
      'Mariani',
      'Marigaon',
      'Marowa',
      'Moran Town',
      'Moranhat',
      'Mornoi Industrial Area',
      'Mushalpur',
      'Nagaon',
      'Naharkatiya',
      'Nahira',
      'Nalbari',
      'Namrup',
      'Narayanpur',
      'Naubaisa Gaon',
      'Nazira',
      'New Bongaigaon Railway Colony',
      'Niz Bahjani',
      'Niz Hajo',
      'Niz Katigorah Pt III',
      'Niz Mankata',
      'North Guwahati',
      'North Lakhimpur',
      'Numaligarh Refinery Township',
      'Palasbari',
      'Pathsala',
      'Pub Dhaniram Pather',
      'Raha',
      'Rangapara',
      'Rangia',
      'Rangia IIDC',
      'Rani',
      'Rupahi Town',
      'Rupiabathan',
      'Salakati',
      'Salpara Molandubi Pt I',
      'Sanpara',
      'Sapatgram',
      'Sarbhog',
      'Sarthebari',
      'Sarupathar',
      'Sarupathar Bengali',
      'Sepon',
      'Sibsagar',
      'Silapathar',
      'Silchar',
      'Sonapur Gaon',
      'Sonari',
      'Sualkuchi',
      'Takhlibilar Pathar',
      'Tangla',
      'Tegheria',
      'Teok',
      'Tezpur',
      'Thekashu Pt I',
      'Thekashu Pt II',
      'Tihu',
      'Tinsukia',
      'Titabor Town',
      'Udalguri',
      'Umrangso'
    ]
  },
  {
    code: 'Bihar',
    name: 'Bihar',
    stateCode: 'BR',
    cities: [
      'Amarpur',
      'Anwari',
      'Areraj',
      'Arrah',
      'Arwal',
      'Asarganj',
      'Aurangabad',
      'Bagaha',
      'Bahadurganj',
      'Bahadurpur',
      'Bairgania',
      'Bakhri',
      'Bakhtiarpur',
      'Balia',
      'Banka',
      'Banmankhi Bazar',
      'Bara',
      'Barahiya',
      'Barauli',
      'Barauni IOC Township',
      'Barbigha',
      'Barh',
      'Baruni',
      'Baruni Thremal Power Station Township',
      'Begusarai',
      'Behea',
      'Belsand',
      'Benipur',
      'Bettiah',
      'Bhabua',
      'Bhagalpur',
      'Bhagirathpur',
      'Biharsharif',
      'Bihta',
      'Bihta Patna District',
      'Bikram',
      'Bikramganj',
      'Bodh Gaya',
      'Buxar',
      'Chakia',
      'Chanari',
      'Chand Chaur',
      'Chanpatia',
      'Chapra',
      'Dalsinghsarai',
      'Darbhanga',
      'Dariapur',
      'Daudnagar',
      'Dehri',
      'Dhaka',
      'Dighwara',
      'Dumari',
      'Dumraon',
      'Ekangar Sarai',
      'Fatwah',
      'Forbesganj',
      'Gaya',
      'Gazipur',
      'Ghoghardiha',
      'Gogri Jamalpur',
      'Gopalganj',
      'Habibpur',
      'Hajipur',
      'Hanspura',
      'Harnaut',
      'Hathua',
      'Hilsa',
      'Hisua',
      'Islampur',
      'Jagdishpur',
      'Jainagar',
      'Jamalpur',
      'Jamhaur',
      'Janakpur Road',
      'Janpur',
      'Jehanabad',
      'Jhajha',
      'Jhanjharpur',
      'Jogabani',
      'Kahalgaon',
      'Kanti',
      'Kargahia Purab',
      'Kasba',
      'Kataiya',
      'Katihar',
      'Kesaria',
      'Khagaria',
      'Kharagpur',
      'Khusrupur',
      'Kishanganj',
      'Koath',
      'Koilwar',
      'Kumarbagh',
      'Lakhisarai',
      'Lalganj',
      'Laruara',
      'Madhepura',
      'Madhuban',
      'Madhubani',
      'Maharajganj',
      'Mahnar Bazar',
      'Mairwa',
      'Makhdumpur',
      'Malhipur',
      'Maner',
      'Manihari',
      'Mansur Chak',
      'Marhaura',
      'Masaurhi',
      'Mathurapur',
      'Mehsi',
      'Mirganj',
      'Mohiuddinagar',
      'Mokameh',
      'Motihari',
      'Motipur',
      'Munger',
      'Murliganj',
      'Muzaffarpur',
      'Nabinagar',
      'Narkatiaganj',
      'Nasrigagnj',
      'Naubatpur',
      'Naugachhia',
      'Nawada',
      'Nirmali',
      'Nokha',
      'NTPC Barh',
      'Obra',
      'Padri',
      'Pakri Dayal',
      'Pandual',
      'Pareo',
      'Paria',
      'Patna',
      'Piro',
      'Puraini',
      'Purnia',
      'Rafiganj',
      'Raghunathpur',
      'Rajauli',
      'Rajgir',
      'Ramgarh',
      'Ramnagar',
      'Raxaul Bazar',
      'Revelganj',
      'Saharsa',
      'Sahebganj',
      'Sahebganj Banka District',
      'Saidpura',
      'Samastipur',
      'Saraiya',
      'Sasaram',
      'Satghara',
      'Shahpur',
      'Sheikhpura',
      'Sheohar',
      'Sherghati',
      'Silao',
      'Singhesar Asthan',
      'Sitamarhi',
      'Siwan',
      'Sonepur',
      'Sugauli',
      'Sultanganj',
      'Supaul',
      'Teghra',
      'Telkap',
      'Thakurganj',
      'Tikari',
      'Tola Baliadih',
      'Tola Chain',
      'Tola Mansaraut',
      'Tola Pairamatihana',
      'Vaishali',
      'Warisaliganj'
    ]
  },
  {
    code: 'Chandigarh',
    name: 'Chandigarh',
    stateCode: 'CG',
    cities: ['Chandigarh', 'Mohali', 'Panchkula']
  },
  {
    code: 'Chhattisgarh',
    name: 'Chhattisgarh',
    stateCode: 'CH',
    cities: [
      'Aamdi',
      'Adawal',
      'Adbhar',
      'Ahiwara',
      'Akaltara',
      'Ambagarh Chowki',
      'Ambikapur',
      'Antagarh',
      'Arang',
      'Arasmeta',
      'Arjunda',
      'BadeBacheli',
      'Badla',
      'Bagbahara',
      'Bagicha',
      'Baikunthpur',
      'Balod',
      'Baloda',
      'BalodaBazar',
      'Balrampur',
      'Banarsi',
      'BankiMongra',
      'Baramkela',
      'Barsur',
      'Basna',
      'Bastar',
      'Berla',
      'Bhairamgarh',
      'Bhakhara',
      'Bhanupratappur',
      'Bhatapara',
      'Bhatgaon',
      'Bhilai',
      'Bhopalpattanam',
      'Bijapur',
      'Bilaigarh',
      'Bilaspur',
      'Bilha',
      'Cement Plant',
      'Champa District',
      'Dongargaon',
      'Dornapal',
      'Doundi',
      'Durg',
      'Farasgaon',
      'Fingeshwar',
      'Gandai',
      'Gariaband',
      'Gaurela',
      'Gharghoda',
      'Gidam',
      'Girwarganj IIDC',
      'GobraNawapara',
      'Govindapur',
      'Gunderdehi',
      'Hatkachora',
      'Hirmi',
      'Industrial Area Anjani',
      'Jagdalpur',
      'Jaijepur',
      'Janjgir',
      'Jarhi',
      'Jarwe',
      'Jashpurnagar',
      'Jhagrakhand',
      'Kanker',
      'Kasdol',
      'Katghora',
      'Katkona',
      'Kawardha',
      'Keskal',
      'Khairagarh',
      'Khamharia',
      'Kharod',
      'Kharora',
      'Kharsia',
      'Khongapani',
      'Kirandul',
      'Kondagaon',
      'Koni',
      'Konta',
      'Korba',
      'Kota',
      'Kotba',
      'Kumhari',
      'Kunkuri',
      'Kurasia',
      'Kurud',
      'Kusmi',
      'Rajpur',
      'Lailunga',
      'Lakhnapur',
      'Lawan',
      'Lormi',
      'Magarlod',
      'Mahasamund',
      'Mahendragarh',
      'Malhar',
      'MandharIndustrial Area',
      'MandirHasaud',
      'Maro',
      'Mehmand',
      'Mungeli',
      'Nagari',
      'NaiLedri',
      'NamnaKalan',
      'Narayanpur',
      'Narharpur',
      'Nawagarh',
      'Nawagarh Janjgir',
      'NayaBaradwar',
      'NewRaipur',
      'NTPCSipat',
      'Pakhanjur',
      'Palari',
      'Pali',
      'Pandaria',
      'Pandatarai',
      'Paratappur',
      'Parpodi',
      'Patan',
      'Pathalgaon',
      'Pathariya',
      'Phunderdihari',
      'Pipariya',
      'Pithora',
      'Pusaur',
      'Rahaud',
      'Raigarh',
      'Raipur',
      'Rajgamar',
      'Rajnandgaon',
      'Ramanujganj',
      'RaniDurgavati',
      'Ratanpur',
      'RawanAmbuja',
      'Sahaspur Lohara',
      'Abhanpur',
      'Saja',
      'Sakari',
      'Sakti',
      'Saraipali',
      'Sarangarh',
      'Sargaon',
      'Sariya',
      'Sheorinarayan',
      'SilpahariIndustrial Area',
      'Simga',
      'Sirgitti',
      'Sitapur',
      'Sonadhi',
      'Sukma',
      'Bemetra',
      'Saragaon',
      'Surajpur',
      'Takhatpur',
      'Telgaon',
      'TenduaIID',
      'Tifra',
      'Tilda',
      'Tumgaon',
      'Tundra',
      'Ultratech Cement Plant',
      'Rawan',
      'Utai',
      'Vishrampur',
      'Vishrampuri',
      'Wadrafnagar'
    ]
  },
  {
    code: 'Dadra And Nagar Haveli And Daman And Diu',
    name: 'Dadra And Nagar Haveli And Daman And Diu',
    stateCode: 'DD,DN',
    cities: [
      'Alok City',
      'Amli',
      'Dadhel',
      'Daman',
      'Diu',
      'Dungra',
      'Ghoghla',
      'Kachigam',
      'Masat Industrial Area',
      'Naroli',
      'Silvassa'
    ]
  },
  {
    code: 'Delhi',
    name: 'Delhi',
    stateCode: 'DL',
    cities: [
      'Central Delhi',
      'North Delhi',
      'South Delhi',
      'East Delhi',
      'North East Delhi',
      'South West Delhi',
      'New Delhi',
      'North West Delhi',
      'West Delhi',
      'Shahdara',
      'South East Delhi'
    ]
  },
  {
    code: 'Goa',
    name: 'Goa',
    stateCode: 'GA',
    cities: [
      'Acoi',
      'Aldona',
      'Anjuna',
      'Aquem',
      'Arambol',
      'Arpora',
      'Assagao',
      'Badem',
      'Baga',
      'Bambolim',
      'Bandora',
      'Bastora',
      'Benaulim',
      'Betim',
      'Bicholim',
      'Borim',
      'Britona',
      'Calangute',
      'Calapor',
      'Calvim',
      'Camurlim',
      'Canacona',
      'Canca',
      'Candola',
      'Candolim',
      'Carapur',
      'Carona',
      'Chapora',
      'Chicalim',
      'Chimbel',
      'Chinchinim',
      'Chopdem',
      'Chorão',
      'Colva',
      'Colvale',
      'Corjuem',
      'Cumbarjua',
      'Cunchelim',
      'Cuncolim',
      'Curchorem',
      'Curti',
      'Curtorim',
      'Divar',
      'Davorlim',
      'Guirim',
      'Jua',
      'Karaswada',
      'Mandrem',
      'Mapusa',
      'Marcaim',
      'Margao',
      'Marna',
      'Mayem',
      'Mercurim',
      'Moira',
      'Morjim',
      'Nachinola',
      'Nagoa',
      'Navelim',
      'Nerul',
      'Nuvem',
      'OldGoa',
      'Onda',
      'Orgao',
      'Oxel',
      'Pale',
      'Paliem',
      'Panaji',
      'Parcem',
      'Parra',
      'Penha De Franca',
      'Pernem',
      'Pilerne',
      'Pomburpa',
      'Ponda',
      'Porvorim',
      'Priol',
      'Quepem',
      'Queula',
      'Quitla',
      'Raia',
      'Reis Magos',
      'Saligao',
      'Salvador do Mundo',
      'Sancoale',
      'Sangolda',
      'Sanguem',
      'Sanquelim',
      'Sanvordem',
      'SaoJose de Areal',
      'Sinquerim',
      'Siolim',
      'Sirsaim',
      'Socorro',
      'Tivim',
      'Ucassaim',
      'Usgao',
      'Vagator',
      'Valpoi',
      'Varca',
      'VascoDa Gama',
      'Verla',
      'Verem',
      'Xeldem'
    ]
  },
  {
    code: 'Gujarat',
    name: 'Gujarat',
    stateCode: 'GJ',
    cities: [
      'Abrama',
      'Adalaj',
      'Adityana',
      'Ahmedabad',
      'Ahwa',
      'Alang',
      'Alikherva',
      'Ambaji',
      'Ambaliyasan',
      'Amboli',
      'Amod',
      'Amreli',
      'Anand',
      'Anandpar',
      'Anandpar Jamnagar',
      'Andada',
      'Anjar',
      'Anklav',
      'Ankleshwar',
      'Antaliya',
      'Arambhada',
      'Arsodiya',
      'Atul',
      'Baben',
      'Babra',
      'Bagasara',
      'Balasinor',
      'Baliyasan',
      'Bansda',
      'Banaskantha',
      'Bantwa',
      'Bardoli',
      'Bareja',
      'Barwala',
      'Bavla',
      'Bayad',
      'BecharAlias Becharaji',
      'Bedi',
      'Bhabhar',
      'Bhachau',
      'Bhadkodara',
      'Bhagal',
      'BhagalValsad District',
      'Bhalpara',
      'Bhanvad',
      'Bharuch',
      'Bhat',
      'Bhavnagar',
      'Bhayavadar',
      'Bhilad',
      'Bhiloda',
      'Bhuj',
      'Bhurivel',
      'Bilimora',
      'Bodeli',
      'Boriavi',
      'Borsad',
      'Botad',
      'Chaklasi',
      'Chalala',
      'Chalthan',
      'Chanasma',
      'Changodar GIDC',
      'Chanod',
      'Chhapi',
      'Chhapra',
      'Chhatral',
      'Chhiri',
      'ChhotaUdaipur',
      'Chikhli',
      'Chorwad',
      'Chotila',
      'Dabhoi',
      'DahejSIR',
      'Dakor',
      'Damnagar',
      'Dediapada',
      'Deesa',
      'Dehari',
      'Dehgam',
      'Deodar',
      'Devgadbaria',
      'Devsar',
      'Dhandhuka',
      'Dhanera',
      'Dharampur',
      'DhasaVishi',
      'Dhola',
      'Dholka',
      'Dhoraji',
      'Dhrangadhra',
      'Dhrol',
      'Digvijaygram',
      'District',
      'District',
      'District',
      'Rankuva',
      'Umargam',
      'Dohad',
      'Dungarpur',
      'Dungarpur Bhavnagar     Jetalsar',
      'Dwarka',
      'Gadhada',
      'Gamdi',
      'Gandevi',
      'Gandhidham',
      'Gandhinagar',
      'Gariadhar',
      'Ghogha',
      'GIDCAntaliya',
      'GIDCPanoli',
      'GIDCPor',
      'GIDCSavli',
      'Godhra',
      'Gondal',
      'GSFCMotikhavdi',
      'GSFCReliance Complex  Karjan',
      'GWCTownship Kovaya   Kathlal',
      'Hajira',
      'Halol',
      'Halvad',
      'Hansalpur',
      'Harij',
      'Himmatnagar',
      'Ichchhapor',
      'Ichhapor',
      'Idar',
      'Jafrabad',
      'Jalalpore',
      'Jamavala',
      'Jambusar',
      'Jamjodhpur',
      'Jamnagar',
      'Jarod',
      'Jasdan',
      'Jetpur',
      'JetpurNavagadh',
      'Jhalod',
      'Joshipura',
      'Junagadh',
      'Kadi',
      'Kadodara',
      'Kakoshi',
      'Kalavad',
      'Kalol',
      'Kalol,Panch',
      'Kandla',
      'Kanjari',
      'Kanodar',
      'Kapadvanj',
      'Karamsad',
      'Katpar',
      'Kavant',
      'Keshod',
      'Kevadiya',
      'Khambhalia',
      'Khambhat',
      'khapat',
      'Kharach',
      'Kharaghoda',
      'Kheda',
      'Khedbrahma',
      'Kheralu',
      'Kim',
      'Kodinar',
      'Kosamba',
      'Kothria',
      'Kutiyana',
      'Lathi',
      'Lavachha',
      'Lilia',
      'Limbdi',
      'Limkheda',
      'Limla',
      'Lodhikas',
      'Lunawada',
      'Madhapar',
      'Mahals District',
      'Mahesana',
      'Mahudha',
      'Mahuva',
      'Mahuvar',
      'Malanka',
      'Maliya',
      'Malpur',
      'Manavadar',
      'Mandvi',
      'MandviSurat District',
      'Mangrol',
      'Mankuva',
      'Mansa',
      'Meghraj',
      'Mehmedabad',
      'Mehsana',
      'Mithapur',
      'Modasa',
      'Modhera',
      'Morbi',
      'Mundra',
      'Nadiad',
      'Nalsarovar',
      'Nandej',
      'Nari',
      'Nasvadi',
      'NavaBhildi',
      'NavlakhiSIR',
      'Navsari',
      'Ode',
      'OkhaPort',
      'Orvad',
      'Paddhari',
      'Padra',
      'Palaj',
      'Palanpur',
      'Palej',
      'Palitana',
      'PanoliINA',
      'Parabada',
      'Pardi',
      'Parnera',
      'Patan',
      'Patdi',
      'Pethapur',
      'Petlad',
      'Porbandar',
      'Prantij',
      'Radhanpur',
      'Rajkot',
      'Rajpipla',
      'Rajula',
      'Ranavav',
      'Ranpur',
      'Rapar',
      'Raval',
      'Ravapara',
      'Sagbara',
      'Salaya',
      'Salvav',
      'Sanand',
      'SanandGIDC',
      'Sanjan',
      'Sanjeli',
      'Santrampur',
      'Saputara',
      'Sarigam',
      'Sathamba',
      'Savarkundla',
      'Savli',
      'Sayan',
      'Selamba',
      'Shapur',
      'ShapurRajkot District     Vaghodia',
      'Shehera',
      'Sidhpur',
      'Sidsar',
      'Sihor',
      'Sikka',
      'Sikka INA',
      'SimarSIR',
      'Sojitra',
      'Somnath',
      'Songadh',
      'Songadh Bhavnagar',
      'Sukhpar',
      'Surajkaradi',
      'Surat',
      'Surendranagar',
      'Sutrapada',
      'Talaja',
      'Talala',
      'Talod',
      'Tarsadi',
      'Thangadh',
      'Thara',
      'Tharad',
      'Thasra',
      'Ukai',
      'Umbergaon',
      'Umrala',
      'Umreth',
      'Una',
      'Undach',
      'Unjha',
      'Upleta',
      'Vadali',
      'Vadia',
      'Vadnagar',
      'Vadodara',
      'Valia',
      'Vallabhipur',
      'Valsad',
      'Vanthali',
      'Vapi',
      'Vartej',
      'Vasna',
      'Veraval',
      'VeravalRajkot District',
      'Vijapur',
      'Vijaynagar',
      'VilayatGIDC',
      'Viramgam',
      'Virpur',
      'VirpurRajkot District',
      'Visavadar',
      'Visnagar',
      'Vyara',
      'Wadhwan',
      'Waghai',
      'Wankaner',
      'Vijalpor'
    ]
  },
  {
    code: 'Haryana',
    name: 'Haryana',
    stateCode: 'HR',
    cities: [
      'Adampur',
      'Ambala',
      'Area',
      'Asankhurd',
      'Assandh',
      'Ateli',
      'BadhMalak',
      'Badli',
      'Baghola',
      'Bahadurgarh',
      'Barara',
      'BarhiIndustrial Area',
      'Barwala',
      'Bawal',
      'BawaniKhera',
      'Beri',
      'Bhakali',
      'Bhiwani',
      'Bhondsi',
      'Bhuran',
      'Bilaspur',
      'BinolaIndustrial Area',
      'Boh',
      'Buria',
      'ChandiMandir',
      'Chandimandir Bir',
      'CharkhiDadri',
      'Cheeka',
      'Chhachhrauli',
      'Dharuhera',
      'Ellenabad',
      'Farakhpur',
      'Faridabad',
      'Farrukhnagar',
      'Fatehabad',
      'Ferozepur Jhirka',
      'Ganaur',
      'Gangwa',
      'GarhiHarsaru',
      'Ghaggar',
      'Gharaunda',
      'Gohana',
      'Gurgaon',
      'Haileymandi',
      'Hansi',
      'Hassanpur',
      'Hathin',
      'Hisar',
      'Hodal',
      'HSIIDCDudhola Industrial NagalChaudhry',
      'HSIIDCManakpur         Naraingarh',
      'Indri',
      'Industrial Area',
      'Industrial Area',
      'Industrial Estate Barwala  Narwana',
      'Ismailabad',
      'Jakhalmandi',
      'Jhajjar',
      'Jind',
      'Julana',
      'Kabri',
      'Kachrauli',
      'Kaithal',
      'KakarMajra',
      'Kalanaur',
      'Kalanwali',
      'Kalayat',
      'Kalka',
      'Kanina',
      'Karnal',
      'Kharkhoda',
      'KhoriKalan',
      'Kundli',
      'KundliIndustrial Area      RamGarh',
      'Kurukshetra',
      'Ladrawan',
      'Ladwa',
      'Loharu',
      'Maham',
      'Mahendragarh',
      'Majra',
      'MandiDabwali',
      'Manesar',
      'Manethi',
      'Mayyer',
      'Mustafabad',
      'Nagina',
      'Narnaul',
      'Narnaund',
      'Nilokheri',
      'Nissing',
      'Nuh',
      'Palwal',
      'Panchkula',
      'Panipat',
      'PanipatRefinery',
      'Pataudi',
      'Pehowa',
      'Piala',
      'Pinagwan',
      'Pinjore',
      'Punahana',
      'Pundri',
      'Radaur',
      'RaiIndustrial Area',
      'RaipurRani',
      'Rania',
      'Ratia',
      'Rewari',
      'Rohtak',
      'RozKaMeo',
      'Sadaura',
      'Safidon',
      'Saha',
      'Salamba',
      'Samalkha',
      'Sampla',
      'Sankhol',
      'Shahbad',
      'SikriIndustrial Area',
      'Sirsa',
      'Siwani',
      'Sohna',
      'Sonipat',
      'SunariKalan',
      'Taoru',
      'Taraori',
      'Tohana',
      'Tosham',
      'Township',
      'Uchana',
      'UgraKheri',
      'Uklanamandi',
      'UnchaSiwana',
      'Yamunanagar'
    ]
  },
  {
    code: 'Himachal Pradesh',
    name: 'Himachal Pradesh',
    stateCode: 'HP',
    cities: [
      'Amb Industrial Area',
      'Area',
      'Area',
      'Area',
      'Arki',
      'Baddi',
      'Baddi Industrial Area',
      'Badhal Industrial Area',
      'Bagbania Industrial Area',
      'Bain Attarian Industrial',
      'Bakloh',
      'Banjar',
      'Barotiwala Industrial Area',
      'Bhambla Industrial Area',
      'Bhota',
      'Bhuntar',
      'Bilaspur',
      'Centre Industrial Area',
      'Chail',
      'Chamba',
      'Chaupal',
      'ChuariKhas',
      'Dagshai',
      'Dalhousie',
      'Daulatpur',
      'Dera Gopipur',
      'Dharampur Industrial',
      'Dharmsala',
      'Gagret',
      'Gagret Industry Area',
      'Garnota Industry Area',
      'Ghumarwin',
      'Golthai Industry Area',
      'Gondpur Industry Area',
      'Hamirpur',
      'Hatil Industry Area',
      'Indora',
      'Jais Industial Area',
      'Jawalamukhi',
      'Jhakhri',
      'Jharmajri EPIP Phase 1',
      'Jogindarnagar',
      'Jubbal',
      'Jutogh',
      'KalaAmb Industrial Area',
      'Kangra',
      'Kasauli',
      'Kasol',
      'Keylong',
      'Keylong Industial Estate',
      'Khajjiar',
      'Kinnaur',
      'Kotkhai',
      'Kullu',
      'Lodhi Majra Industrial',
      'Manali',
      'Mandi',
      'Manikaran',
      'Mehatpur',
      'Moginand Industrial Area',
      'Nadaun',
      'Nagri Industial Area',
      'NagrotaBagwan',
      'Nahan',
      'NainaDevi',
      'Nalagarh',
      'Narkanda',
      'Nurpur',
      'Palampur',
      'PaontaSahib',
      'Parel Industial Estate',
      'Parwanoo',
      'Pragpur',
      'RajaKaBagh Industrial',
      'Rajgarh',
      'Rampur',
      'Ratti Industial Area',
      'Rawalsar',
      'ReckongPeo',
      'Renuka',
      'Rohru',
      'Sabathu',
      'Sansarpur Terrace Growth',
      'Santokhgarh',
      'Sarkaghat',
      'SauliKhad Industrial Area',
      'Seoni',
      'Shamshi Industial Area',
      'Shimla',
      'Shivnagari Holi Industrial',
      'Shoghi',
      'Shoghi Industial Area',
      'Solan',
      'Solang',
      'Sundarnagar',
      'Tahliwala Industrial Area',
      'Talai',
      'Theog',
      'TiraSujanpur',
      'Una'
    ]
  },
  {
    code: 'Jammu & Kashmir',
    name: 'Jammu & Kashmir',
    stateCode: 'JK',
    cities: [
      'Achabal',
      'Akhnoor',
      'Anantnag',
      'Arnia',
      'Arwani',
      'Ashmuji Khalsa',
      'Awantipora',
      'Bandipore',
      'Banihal',
      'Baramula ',
      'Bari Brahmana EPIP Industrial Estate',
      'Bashohli',
      'Batote',
      'Beerwah',
      'Bhaderwah',
      'Bijbehara',
      'Billawar',
      'Bishna',
      'Chenani',
      'Choglamsar',
      'Dachigam',
      'Doda',
      'Dras',
      'Duru Verinag',
      'Ganderbal',
      'Gho Manhasan',
      'Gorah Salathian',
      'Gulmarg',
      'Hajan',
      'Handwara',
      'Hanle',
      'Hiranagar',
      'Jammu',
      'Jourian',
      'Kargil',
      'Kathua',
      'Katra',
      'Khan Sahib',
      'Khilanmarg',
      'Khour',
      'Khrew',
      'Kishtwar',
      'Kud',
      'Kukernag',
      'Kulgam',
      'Kupwara',
      'Leh',
      'Magam',
      'Mattan',
      'Mohmmad Pora',
      'Nowgam',
      'Nowshehra',
      'Pahalgam',
      'Parole',
      'Patnitop',
      'Pattan',
      'Pulwama',
      'Punch',
      'Qaimoh',
      'Qazigund',
      'Rajauri',
      'Ramban',
      'Ramgarh',
      'Ramnagar',
      'Ranbirsinghpora',
      'Reasi',
      'Rehambal',
      'Samba',
      'Seer Hamdan',
      'Shangus',
      'Shopian',
      'Soolkoot',
      'Sopore',
      'Srinagar',
      'Sumbal',
      'Sunderbani',
      'Talwara',
      'Thanamandi',
      'Tral',
      'Udhampur',
      'Uri',
      'Vijay Pore'
    ]
  },
  {
    code: 'Jharkhand',
    name: 'Jharkhand',
    stateCode: 'JH',
    cities: [
      'AEC Complex',
      'Akdoni Khurd',
      'Alagdiha',
      'Amlabad',
      'Angarpathar',
      'Ara',
      'Bachra',
      'Baharagora',
      'Baliapur',
      'Baliari',
      'Balkundra',
      'Barajamda',
      'Bardubhi',
      'Barharwa',
      'Barhi',
      'Barkakana',
      'Barki Saraiya',
      'Barora',
      'Barughutu',
      'Barwadih',
      'Basaria',
      'Basukinath',
      'Baua Kalan',
      'Bekobar',
      'Berhait Bazar',
      'Bermo',
      'Bhagatdih',
      'Bhandra',
      'Bhandra Bokaro District',
      'Bhim Kanari',
      'Bhojudih',
      'Bhowrah',
      'Bhuli',
      'Bishnugarh',
      'Bishrampur',
      'Bishrampur Latehar District',
      'Bokaro Steel City',
      'Bokaro Thermal',
      'Bongabar',
      'Borio',
      'Bundu',
      'Bursera',
      'Chaibasa',
      'Chain Pur',
      'Chakradharpur',
      'Chakulia',
      'Chandaur',
      'Chandil',
      'Chandrapura',
      'Chandwa',
      'Charhi',
      'Chatra',
      'Chauparan',
      'Chhatatanr',
      'Chhotaputki',
      'Chiria',
      'Chirkunda',
      'Churi',
      'Daltonganj',
      'Danguwapasi',
      'Dari',
      'Deoghar',
      'Deorikalan',
      'Dhanbad',
      'Dhanwar',
      'Dhaunsar',
      'Domchanch',
      'Dugda',
      'Dumarkunda',
      'Dumka',
      'Egarkunr',
      'Garhwa',
      'Ghagra',
      'Ghatshila',
      'Gidi',
      'Giridih',
      'Gobindpur',
      'Godda',
      'Godhar',
      'Gomoh',
      'Gua',
      'Gumia',
      'Gumla',
      'Haludpukhur',
      'Hariharpur',
      'Harina',
      'Hasir',
      'Hazaribagh',
      'Hesla',
      'Hussainabad',
      'Irba',
      'Isri',
      'Jadugora',
      'Jagannathpur',
      'Jai Nagar',
      'Jamadoba',
      'Jamshedpur',
      'Jamtara',
      'Jangalpur',
      'Jaridih Bazar',
      'Jasidih',
      'Jena',
      'Jharia',
      'Jharia Khas',
      'Jhinghipahari',
      'Jhinkpani',
      'Jhumri Tilaiya',
      'Jorapokhar',
      'Kailudih',
      'Kalikapur',
      'Kandra',
      'Kanke',
      'Karma',
      'Karma Chatra District',
      'Karma Tanr',
      'Karmatanr',
      'Kathhara',
      'Katras',
      'Kedla',
      'Kenduadih',
      'Kharkhari',
      'Kharsawan',
      'Khelari',
      'Khunti',
      'Kiriburu',
      'Kodarma',
      'Kuju',
      'Kumarpur',
      'Kumarpur Sahibganj District',
      'Kurpania',
      'Kustai',
      'Lakarka',
      'Lalpania',
      'Lapanga',
      'Latehar',
      'Lohardaga',
      'Loyabad',
      'Madhuban',
      'Madhupur',
      'Mahagma',
      'Mahesh Mundi',
      'Mahuda',
      'Maithon',
      'Majhion',
      'Makoli',
      'Malkera',
      'Mandu',
      'Manohar Pur',
      'Marar',
      'Marma',
      'Matigara',
      'Mera',
      'Meru',
      'Mihijam',
      'Mugma',
      'Muraidih',
      'Muri',
      'Musabani',
      'Nagri Kalan',
      'Narra',
      'Netarhat',
      'Nirsa',
      'Noamundi',
      'Okni No 2',
      'Orla',
      'Pakaur',
      'Palawa',
      'Panchet',
      'Panrra',
      'Paratdih',
      'Pathardih',
      'Patra',
      'Patratu',
      'Phusro',
      'Pondarkanali',
      'Rajbhita',
      'Rajmahal',
      'Ramgarh Cantonment',
      'Ranchi',
      'Ratu',
      'Rehla',
      'Religara',
      '4Rohraband',
      'Sahibganj',
      'Sahnidih',
      'Sanri',
      'Saraidhela',
      'Saram',
      'Sarauni',
      'Satgawan',
      'Saunda',
      'Seraikela',
      'Sewai',
      'Shahpur',
      'Sijhua',
      'Sijua',
      'Simdega',
      'Sindri',
      'Sinduria',
      'Sini',
      'Sirka',
      'Siuliban',
      'Suranga',
      'Tapin',
      'Tati',
      'Telo',
      'Telodih',
      'Tenu',
      'Tin Pahar',
      'Tisra',
      'Topa',
      'Topchanchi',
      'Torpa',
      'Toto',
      'Tundiul'
    ]
  },
  {
    code: 'Karnataka',
    name: 'Karnataka',
    stateCode: 'KA',
    cities: [
      'Addur',
      'Adityapatna',
      'Bellary',
      'Adyar',
      'Beltangadi',
      'Afzalpur',
      'Belur',
      'Agumbe',
      'Belur Industrial Area',
      'Aland',
      'Benakanahalli',
      'Alevoor',
      'Bengaluru',
      'Allipura',
      'Bethamangala',
      'Alnavar',
      'Bhadravati',
      'Alur',
      'Bhalki',
      'Amaravathi',
      'Bhatkal',
      'Ambikanagara',
      'Bhimarayanagudi',
      'Amble Industrial Area',
      'Bidadi',
      'Aminagad',
      'Bidadi Industrial Area',
      'Ankola',
      'Bidar',
      'Annigeri',
      'Bijapur',
      'Arasinakunte',
      'Bilgi',
      'Arkalgud',
      'Birur',
      'Arkula',
      'Bobruwada',
      'Arsikere',
      'Byadgi',
      'Athni',
      'Challakere',
      'Aurad',
      'Chamarajanagar',
      'Aversa',
      'Channagiri',
      'Bada',
      'Channapatna',
      'Badagaulipady',
      'Channarayapatna',
      'Badami',
      'Chelur',
      'Bagalkot',
      'Chik Ballapur',
      'Bagepalli',
      'Chikballapur Industrial',
      'Bail Hongal Area',
      'Bandipur',
      'Chikkajajur',
      'Bangarapet',
      'Chikmagalur',
      'Bangarpet Industrial Area',
      'Chiknayakanhalli',
      'Bankapura',
      'Chikodi',
      'Bannur',
      'Chincholi',
      'Bantval',
      'Chintamani',
      'Basavakalyan',
      'Chitapur',
      'Basavana Bagevadi',
      'Chitgoppa',
      'Belgaum',
      'Chitradurga',
      'Dandeli',
      'Haralahalli',
      'Jog Falls',
      'Dargajogihalli',
      'Haralapura',
      'JSW Steel Plant',
      'Davanagere',
      'Harapanahalli',
      'Kadakola',
      'Devadurga',
      'Harihar',
      'Kadur',
      'Devanahalli',
      'Harohalli Industrial Area',
      'Kadwad',
      'Dharwad',
      'Hassan',
      'Kairangala',
      'Dobaspet Industrial Area',
      'Hatti',
      'Kakati Industrial Area',
      'Dod Ballapur Doddaballapur Industrial Area',
      'Hatti Gold Mines',
      'Kalghatgi',
      'Haveri',
      'Kamalapuram',
      'HD Kote',
      'Kamalnagar',
      'Doddaballapura Apparel Park',
      'Heroor',
      'Kamatgi',
      'Hirehally Industrial Area',
      'Kampli',
      'Donimalai Township',
      'Hirekerur',
      'Kanakapura',
      'Elwala',
      'Hiriyur',
      'Kariyangala',
      'Gadag Betigeri',
      'Holalkere',
      'Karkal',
      'Gajendragarh',
      'Hole Narsipur',
      'Karwar',
      'Gangawati',
      'Homnabad',
      'Kemmannugundi',
      'Ganjimutt',
      'Honaga Industrial Area',
      'Kerur',
      'Gargeswari',
      'Honavar',
      'Khanapur',
      'Gauribidanur',
      'Honnali',
      'Khangaon',
      'Gejjalagere Industrial Area',
      'Hoovina Hadagalli',
      'Kodiyal',
      'Gogipeth',
      'Hosanagara',
      'Kolambe',
      'Gokak',
      'Hosdurga',
      'Kolar',
      'Gokak Falls',
      'Hoskote',
      'Kollegal',
      'Gonikoppal',
      'Hoskote Industrial Area',
      'Kollur',
      'Gubbi',
      'Hospet',
      'Konnur',
      'Gudibanda',
      'Hubli',
      'Koppa',
      'Gudur',
      'Hukeri',
      'Koppal',
      'Gulbarga',
      'Huliyar',
      'Koratagere',
      'Guledgudda',
      'Humnabad Industrial Area',
      'Koteshwar',
      'Gundlupet',
      'Hungund',
      'Kotturu',
      'Gurmatkal',
      'Hunsur',
      'Krishnaraja Sagara',
      'Halga',
      'Ilkal',
      'Krishnarajanagara',
      'Haliyal',
      'Indi',
      'Krishnarajpet',
      'Hampi',
      'Jagalur',
      'Kudchi',
      'Hangal',
      'Jamkhandi',
      'Kudligi',
      'Hanur',
      'Jevargi',
      'Kudremukh',
      'Kudur',
      'Kumta',
      'Kundapura',
      'Kundgol',
      'Kunigal',
      'Kunigal Industrial Area',
      'Kurekuppa',
      'Kurgunta',
      'Kushalanagara',
      'Kushtagi',
      'Kuvettu',
      'Lakshmeshwar',
      'Lingsugur',
      'Londa',
      'Machche',
      'Maddur',
      'Madhugiri',
      'Madikeri',
      'Magadi',
      'Mahalingpur',
      'Malavalli',
      'Mallar',
      'Malur',
      'Malur Industrial Area',
      'Mandya',
      'Mandya Industrial Area',
      'Mangalore',
      'Manipura',
      'Manjanady',
      'Manjunath Township Basavkalyan',
      'Manvi',
      'Manvi Industrial Area',
      'Mellahalli',
      'Molakalmuru',
      'Moodabettu',
      'Mouje Nandgad',
      'Mudalgi',
      'Pavagada',
      'Mudbidri',
      'Periyapatna',
      'Muddebihal',
      'Pudu',
      'Mudgal',
      'Puttur',
      'Mudhol',
      'Rabkavi-Banhatti',
      'Mudigere',
      'Raichur',
      'Muduperar',
      'Raichur Growth Centre',
      'Mulbagal Industrial Area',
      'Mulgund',
      'Ramanagara',
      'Mulki',
      'Ramdurg',
      'Mulur',
      'Ranebennur',
      'Mundargi',
      'Raybag',
      'Mundargi Industrial Area',
      'Robertsonpet',
      'Mundgod',
      'Ron',
      'Munirabad Project Area',
      'Sadalgi',
      'Munnuru',
      'Sagagur',
      'Mutga',
      'Sagar',
      'Mutga Gulbarga District',
      'Sajipanadu',
      'Mysore',
      'Sakleshpur',
      'Nadsal',
      'Saligram',
      'Nagamangala',
      'Sambra',
      'Nandi',
      'Sandur',
      'Nandur Kesartigi Industrial Area',
      'Sankeshwar',
      'Sanoor',
      'Nanjangud',
      'Sathyamangala',
      'Narasapura Industrial Area',
      'Sattur',
      'Saundatti-Yellamma',
      'Narasimharajapura',
      'Savanur',
      'Naregal',
      'Sedam',
      'Nargund',
      'Shahabad',
      'Narikombu',
      'Shahabad ACC',
      'Navalgund',
      'Shahpur',
      'Navoor',
      'Shaktinagar',
      'Nelamangala',
      'Shiggaon',
      'Nipani',
      'Shikarpur',
      'Pandavapura',
      'Shimoga',
      'Pattadakal',
      'Shirawad Industrial Area',
      'Shirhatti',
      'Ullal',
      'Shorapur',
      'Uppinangady',
      'Shravanabelgola',
      'Vaddu',
      'Siddapur',
      'Varamballi',
      'Sidlaghatta',
      'Vasantha Narasapura',
      'Sindgi Industrial Area',
      'Sindhnur',
      'Venkatapura',
      'Sira',
      'Vijayapura',
      'Siralkoppa',
      'Virajpete',
      'Sirsi',
      'Vittal',
      'Siruguppa',
      'Wadi',
      'Solebhavi',
      'Yadgir',
      'Somanahalli Industrial Area',
      'Yelandur',
      'Yelbarga',
      'Somvarpet',
      'Yellapur',
      'Sorab',
      'Yellur',
      'Sringeri',
      'Srinivaspur Kerala',
      'Srirampura',
      'Srirangapatna',
      'Sulya',
      'T Narsipura',
      'Talikota',
      'Talipady',
      'Tarikere',
      'Tattilli',
      'Tekkalakote',
      'Terdal',
      'Thandya Industrial Estate',
      'Thimmenahalli Industrial Area',
      'Thumbe',
      'Tiptur',
      'Tirthahalli',
      'Tumkur',
      'Turuvekere',
      'Udupi',
      'Ullal',
      'Uppinangady',
      'Vaddu',
      'Varamballi',
      'Vasantha Narasapura Industrial Area',
      'Venkatapura',
      'Vijayapura',
      'Virajpete',
      'Vittal',
      'Wadi',
      'Yadgir',
      'Yelandur',
      'Yelbarga',
      'Yellapur',
      'Yellur'
    ]
  },
  {
    code: 'Kerala',
    name: 'Kerala',
    stateCode: 'KL',
    cities: [
      'Abdu Rahiman Nagar',
      'Adat',
      'Adichanalloor',
      'Adinad',
      'Adoor',
      'Aimanam',
      'Ajanur',
      'Akathiyoor',
      'Ala',
      'Alamcode',
      'Alamcode',
      'Alappuzha',
      'Alathur',
      'Alur',
      'Aluva',
      'Amballur',
      'Ancharakandy',
      'Angadipuram',
      'Angamaly',
      'Anthicad',
      'Ariyallur',
      'Arookutty',
      'Aroor',
      'Athirampuzha',
      'Athiyannur',
      'Atholi',
      'Attingal',
      'Avanur',
      'Avinissery',
      'Avittathur',
      'Ayancheri',
      'Ayanivelikulangara',
      'Azhikode',
      'Azhiyur',
      'Azhoor',
      'Balusseri',
      'Bangramanjeshwar',
      'Beypore',
      'Bharanikkavu',
      'Brahmakulam',
      'Chala',
      'Chalakudy',
      'Changanacherry',
      'Chavakkad',
      'Chavara',
      'Chekkiad',
      'Chelakkara',
      'Chelannur',
      'Cheleri',
      'Chelora',
      'Chemancheri',
      'Chendamangalam',
      'Chendrappini',
      'Chengala',
      'Chengamanad',
      'Chengannur',
      'Chennithala',
      'Cheppad',
      'Cheranallur',
      'Cheriyamundam',
      'Cherthala',
      'Cherukavu',
      'Cheruthazham',
      'Cheruthuruthi',
      'Chevvoor',
      'Chingoli',
      'Chirakkal',
      'Chiramanangad',
      'Chiranellur',
      'Chittanda',
      'Chittur-Thathamangalam',
      'Chockli',
      'Choolissery',
      'Choondal',
      'Choornikkara',
      'Chowwara',
      'Desamangalam',
      'Dharmadom',
      'Edacheri',
      'Edakkode',
      'Edappal',
      'Edathala',
      'Edathirinji',
      'Edathiruthy',
      'Edavilangu',
      'Elampalloor',
      'Elayavoor',
      'Eloor',
      'Enkakkad',
      'Eramala',
      'Eramalloor',
      'Eranellur',
      'Kalpetta',
      'Eranholi',
      'Kandamkunnu',
      'Erattupetta',
      'Kandanassery',
      'Eravattur',
      'Kanhangad',
      'Eravu',
      'Kanhirode',
      'Ernakulam',
      'Kaniyarkode',
      'Eruvatti',
      'Kanjikkuzhi',
      'Ettumanoor',
      'Kanjikode Industrial Area',
      'Eyyal',
      'Kanjiramkulam',
      'Feroke',
      'Kannadiparamba',
      'Guruvayur',
      'Kannamangalam',
      'Haripad',
      'Kannamangalam',
      'Hosabettu Alappuzha District',
      'Idukki Township',
      'Kannapuram',
      'Irikkur',
      'Kannur',
      'Irimbiliyam',
      'Karamuck',
      'Iringal',
      'Karikkad',
      'Iringaprom',
      'Karivellur',
      'Irinjalakuda',
      'Kariyannur',
      'Iriveri',
      'Karthikappally',
      'Iroopara',
      'Karunagappally',
      'Kadachira',
      'Karuvanthuruthy',
      'Kadamakkudy',
      'Kasaragod',
      'Kadannappalli',
      'Kattakampal',
      'Kadavallur',
      'Kattanam',
      'Kadikkad',
      'Kattappana',
      'Kadirur',
      'Kattipparuthi',
      'Kadungalloor',
      'Kattur',
      'Kaipamangalam',
      'Kayamkulam',
      'Kalady',
      'Keekan',
      'Kalady Ernakulam District',
      'Keerikkad',
      'Kalamassery',
      'Keezhallur',
      'Kallelibhagom',
      'Keezhariyur',
      'Kallettumkara',
      'Kinfra Small Industries Park',
      'Kalliasseri ',
      'Kalliyoor',
      'Kizhakkummuri',
      'Kallur- Thekkummuri',
      'Kizhuparamba',
      'Kizhuppillikkara',
      'Kumarapuram',
      'Kizhuvalam -Koonthalloor',
      'Kumbalangy',
      'Kochi',
      'Kumily',
      'Kodungallur',
      'Kunjathur',
      'Kodur',
      'Kunnamkulam',
      'Koipady',
      'Kunnathunad',
      'Kolacherry',
      'Kunnummal',
      'Kolavelloor',
      'Kurattissery',
      'Kolazhy',
      'Kureekkad',
      'Kollam',
      'Kurumathur',
      'Komalapuram',
      'Kurumpilavu',
      'Kondotty',
      'Kuruvattur',
      'Koodali',
      'Kuthiathode',
      'Koothali',
      'Kuttiattoor',
      'Koothuparamba',
      'Kuttippuram',
      'Koottilangadi',
      'Madhur',
      'Koratty',
      'Malappuram',
      'Kothamangalam',
      'Manakkody',
      'Kottakal',
      'Manakunnam',
      'Kottamkara',
      'Manalur',
      'Kottappally',
      'Manantheri',
      'Kottappuram',
      'Manavalassery',
      'Kottarakkara',
      'Mangalpady',
      'Kottayam',
      'Mangattidam',
      'Kottayam-Malabar',
      'Maniyat',
      'Kottuvally',
      'Maniyoor',
      'Kovalam',
      'Maniyur',
      'Kozhenchery',
      'Manjeri',
      'Kozhikode',
      'Manjeshwar',
      'Kozhukkallur',
      'Mannanchery',
      'Kozhukkully',
      'Mannar',
      'Krishnapuram',
      'Mannarkad-I',
      'Kudlu',
      'Marampilly',
      'Kulasekharapuram',
      'Marancheri',
      'Kulathummal',
      'Marathakkara',
      'Kumarakom',
      'Mattannur',
      'Kumaranellur',
      'Mavelikkara',
      'Mavilayi',
      'Mavoor',
      'Mayyanad',
      'Mayyil',
      'Meenad',
      'Menhaniam',
      'Meppayyur',
      'Mokeri',
      'Moonniyur',
      'Muhamma',
      'Mulamthuruthy',
      'Mulavukad',
      'Mullassery',
      'Mundathikode',
      'Munderi',
      'Munnar',
      'Muthukulam',
      'Muthuthala',
      'Muvattupuzha',
      'Muzhappilangad',
      'Nadapuram',
      'Nadathara',
      'Naduvannur',
      'Naduvattom',
      'Nanmanda',
      'Nannambra',
      'Narath',
      'Nattakam',
      'Nedumangad',
      'Nedumpana',
      'Nedumpura',
      'Neduva',
      'Nelluwaya',
      'Nenmenikkara',
      'New Mahe',
      'Neyyattinkara',
      'Nilambur',
      '4Njarackal',
      'North -Thrikkaripur',
      'Oachira',
      'Olavanna',
      'Ongallur- I',
      'Ongallur- II',
      'Othukkungal',
      'Ottappalam',
      'Padiyam',
      'Padiyur',
      'Paduvilayi',
      'Palai',
      'Palakkad',
      'Palayad',
      'Palissery',
      'Pallichal',
      'Pallikkal',
      'Pallikkara',
      'Pallikkunnu',
      'Pallippuram',
      'Pallippuram Thiruvananthapuram District',
      'Pallippuram Thrissur District',
      'Paluvai',
      'Panachikkad',
      'Panangad',
      'Panangad Kozhikode District',
      'Panayam',
      'Panmana',
      'Panniyannur',
      'Panoor',
      'Pantheeramkavu',
      'Pappinisseri',
      'Pappinivattom',
      'Parakkad',
      'Parappukkara',
      'Parappur',
      'Parassala',
      'Parasuvaikkal',
      'Paravoor',
      'Paravur',
      'Pariyaram',
      'Pathanamthitta',
      'Pathirappally',
      'Pathiriyad',
      'Pathiyoor',
      'Pattiom',
      'Pavaratty',
      'Payyannur',
      'Pazhanji',
      'Peermade',
      'Perakam',
      'Peralasseri',
      'Perinad',
      'Peringathur',
      'Perinjanam',
      'Perinthalmanna',
      'Perole',
      'Perumanna',
      'Perumbaikad',
      'Perumbavoor',
      'Peruvallur',
      'Pilicode',
      'Pinarayi',
      'Ponmundam',
      'Ponnani',
      'Poomangalam',
      'Poothakkulam',
      'Porathissery',
      'Pottore',
      'Pudussery Central',
      'Pudussery West',
      'Pullur',
      'Punalur',
      'Punnayur',
      'Punnayurkulam',
      'Puranattukara',
      'Puthencruz',
      'Puthukkad',
      'Puthunagaram',
      'Puthuppally',
      'Puthur',
      'Puzhathi',
      'Quilandy',
      'Ramanattukara',
      'Shiriya',
      'Shoranur',
      'South - Thrikkaripur',
      'Talakkad',
      'Talikkulam',
      'Taliparamba',
      'Tanalur',
      'Thaikattussery',
      'Thaikkad',
      'Thalakkulathur',
      'Thalassery',
      'Thangalur',
      'Thanniyam',
      'Thazhakara',
      'Thazhecode',
      'Thazhuthala',
      'Thekkumkara',
      'Thennala',
      'Thikkody',
      'Thirumittacode-II',
      'Thirunavaya',
      'Thiruvalla',
      'Thiruvananthapuram',
      'Thiruvankulam',
      'Thodiyoor',
      'Thodupuzha',
      'Thottada',
      'Thrikkadavoor',
      'Thrikkaruva',
      'Thrikkovilvattom',
      'Thrippunithura',
      'Thrissur',
      'Thrithala',
      'Thuneri',
      'Thurayur',
      'Tirur',
      'Tirurangadi',
      'Trikkur',
      'Triprangode',
      'Udma',
      'Uliyazhathura',
      'Ulliyeri',
      'Uppala',
      'Urakam',
      'Vadakara',
      'Vadakkekad',
      'Vadakkumkara',
      'Vadakkummuri',
      'Vadakkumthala',
      'Vadama',
      'Vadanappally',
      'Vaikom',
      'Vakkom',
      'Valapattanam',
      'Valayam',
      'Vallachira',
      'Vandiperiyar',
      'Vaniyamkulam-II',
      'Varam',
      'Varappuzha',
      'Varkala',
      'Vattappara',
      'Vayalar',
      'Vazhakkala',
      'Vazhakulam',
      'Vazhayur',
      'Veiloor',
      'Vellanikkara',
      'Velur',
      'Veluthur',
      'Venganoor',
      'Vengara',
      'Vengola',
      'Venkitangu',
      'Venmanad',
      'Vilappil',
      'Villiappally',
      'Vylathur',
      'Vythiri',
      'Wadakkanchery'
    ]
  },
  {
    code: 'Ladakh',
    name: 'Ladakh',
    stateCode: 'LA',
    cities: ['Kargil', 'Leh']
  },
  {
    code: 'Lakshadweep',
    name: 'Lakshadweep',
    stateCode: 'LD',
    cities: ['Agatti', 'Amini', 'Kavaratti']
  },
  {
    code: 'Madhya Pradesh',
    name: 'Madhya Pradesh',
    stateCode: 'MP',
    cities: [
      'Agar',
      'Ahirkhedi',
      'Ahirkhedi, Indore District',
      'Ajaygarh',
      'Akoda',
      'Akodia',
      'Alampur',
      'Alirajpur',
      'Amanganj',
      'Amarkantak',
      'Amarpatan',
      'Amarwara',
      'Ambada',
      'Ambah',
      'Amkhera',
      'Amla',
      'Amlai',
      'Anjad',
      'Antari',
      'Anuppur',
      'Aron',
      'Ashoknagar',
      'Ashta',
      'Athner',
      'Babai',
      'Bada-Malhera',
      'Badarwas',
      'Badi',
      'Badkuhi',
      'Badnagar',
      'Badnawar',
      'Badod',
      'Badoni',
      'Badra',
      'Bagh',
      'Bagli',
      'Baihar',
      'Baikunthpur',
      'Balaghat',
      'Baldeogarh',
      'Bamhani',
      'Bamor',
      'Bamor Morena',
      'Bamora',
      'Banda',
      'Bandhawagarh',
      'Bangarda Bada',
      'Bangawan',
      'Bank',
      'Bansatar Kheda',
      'Baragaon',
      'Baragaon Shajapur',
      'Barauda',
      'Barela',
      'Bareli',
      'Barghat',
      'Bargi',
      'Barhi',
      'Barigarh',
      'Barman Kalan',
      'Baroda',
      'Barwaha',
      'Barwaha Kasba',
      'Barwani',
      'Basoda',
      'Begamganj',
      'Beohari',
      'Berasia',
      'Betma',
      'Betul',
      'Betul Bazar',
      'Bhainsa',
      'Bhainsdehi',
      'Bhander',
      'Bhanpura',
      'Bharveli',
      'Bhavra',
      'Bhedaghat',
      'Bhicholi Hapsi',
      'Bhikangaon',
      'Bhilakhedi',
      'Bhind',
      'Bhitarwar',
      'Bhonrasa',
      'Bhopal',
      'Biaora',
      'Bichhiya',
      'Bichhiya Vidisha District',
      'Bijawar',
      'Bijuri',
      'Bilaua',
      'Bina',
      'Birsinghpur',
      'Boda',
      'Borgaon',
      'Budni',
      'Burhanpur',
      'Burhar',
      'Buxwaha',
      'Chachaura-Binaganj',
      'Chakghat',
      'Chandameta Butaria',
      'Chanderi',
      'Chandia',
      'Chandla',
      'Chaurai Khas',
      'Chhapiheda',
      'Chhatarpur',
      'Chhattarpur',
      'Chhindwara',
      'Chhipabad',
      'Chhota Chhindwara',
      'Chichli',
      'Chicholi',
      'Chitrakoot',
      'Churhat',
      'Daboh',
      'Dabra',
      'Dahi',
      'Damoh',
      'Damua',
      'Datia',
      'Dehrisaray',
      'Deodara',
      'Deohara',
      'Deori',
      'Deori, Shahdol District',
      'Depalpur',
      'Devendranagar',
      'Dewas',
      'Dhamnod',
      'Dhamnod Ratlam District',
      'Dhana',
      'Dhanpuri Nargada Hari Dafai',
      'Dhar',
      'Dharampuri',
      'Dhodaramohar Alias',
      'Bhoura',
      'Dighawani',
      'Diken',
      'Dindori',
      'Dola',
      'Dongar Parasia',
      'Dumar Kachhar',
      'Dungariya Chhapara',
      'Emlikheda Industrial Area',
      'Gadarwara',
      'Gairatganj',
      'Gamiria Sagar',
      'Gandhi Sagar Haidel',
      'Garhakota',
      'Garhi -Malhara',
      'Garoth',
      'Ghansaur',
      'Jaitwara',
      'Ghoda Dongri Ryt',
      'Jamai',
      'Ghuwara',
      'Jaora',
      'Gogapur',
      'Jata Chhapar',
      'Gohad',
      'Jatara',
      'Gormi',
      'Jawad',
      'Govindgarh',
      'Jawar',
      'Guna',
      'Jaypee Nagar',
      'Gurh',
      'Jeron Khalsa',
      'Gwalior',
      'Jhabua',
      'Hanumana',
      'Jhundpura',
      'Harda',
      'Jiran',
      'Harduli',
      'Jirapur',
      'Harpalpur',
      'Jobat',
      'Harrai',
      'Joura',
      'Harsud',
      'Kailaras',
      'Hat Piplia',
      'Kakarhati',
      'Hatod',
      'Kali Chhapar',
      'Hatpiplya',
      'Kanad',
      'Hatta',
      'Kannod',
      'Hindoria',
      'Kantaphod',
      'Hirapur',
      'Kareli',
      'Hirdepur',
      'Karera',
      'Hoshangabad',
      'Kari',
      'Ichhawar',
      'Karnawad',
      'Iklehra',
      'Karrapur',
      'Indergarh',
      'Kasrawad',
      'Indore',
      'Katangi',
      'Industrial Area Jagakhedi',
      'Katangi, Jabalpur District',
      'Isagarh',
      'Kelhauri',
      'Itarsi',
      'Keolari',
      'Jabalpur',
      'Khacharod',
      'Jabera',
      'Khairi',
      'Jaisinghnagar',
      'Khajuraho',
      'Jaithari',
      'Khand',
      'Jaitpur',
      'Khandwa',
      'Jaitpur Chhatarpur District',
      'Khaniyadhana',
      'Khargapur',
      'Khargone',
      'Khategaon',
      'Khetia',
      'Khilchipur',
      'Khirkiya',
      'Khor',
      'Khujner',
      'Khurai',
      'Kirnapur',
      'Kolar',
      'Kolaras',
      'Kosmi',
      'Kotar',
      'Kothi',
      'Kothri',
      'Kotma',
      'Kukdeshwar',
      'Kukshi',
      'Kumbhraj',
      'Kundam',
      'Kurwai',
      'Kymore',
      'Ladhaura',
      'Lahar',
      'Lakhnadon',
      'Lanji',
      'Lateri',
      'Laundi',
      'Lodhikheda',
      'Loharda',
      'Machalpur',
      'Madai',
      'Madhawgdha',
      'Maharajpur',
      'Maheshwar',
      'Mahidpur',
      'Mahura',
      'Maihar',
      'Maihar Cement Factory',
      'Sarlanagar',
      'Majhgawan',
      'Majhgawan Jabalpur District',
      'Majhgawan Township',
      'Majholi',
      'Makdon',
      'Makronia Buzurg',
      'Maksi',
      'Malajkhand',
      'Malanpur Industrial Area',
      'Malhargarh',
      'Maliya Guda',
      'Manasa',
      'Manawar',
      'Mandideep',
      'Mandla',
      'Mandleshwar',
      'Mandsaur',
      'Mandu',
      'Maneri Industrial Growth Centre',
      'Mangalya',
      'Mangawan',
      'Manpur',
      'Mau',
      'Mauganj',
      'Meghnagar',
      'Mehgaon',
      'Mhow',
      'Mihona',
      'Mohgaon',
      'Morar Cantt',
      'Morena',
      'Multai',
      'Mundi',
      'Mungaoli',
      'Murwara Katni',
      'Nagda',
      'Nagod',
      'Nagri',
      'Nai-Garhi',
      'Nainpur',
      'Nalkheda',
      'Namli',
      'Nanpur',
      'Narayangarh',
      'Narsimhapur',
      'Narsingarh',
      'Narsinghgarh',
      'Narwar',
      'Nasrullaganj',
      'Naudhia',
      'Neemuch',
      'Nepanagar',
      'New Harsud',
      'Newton Chikhli Kalan',
      'Niwari',
      'Niwas',
      'Nowgong',
      'Nowrozabad',
      'Omkareshwar',
      'Orchha',
      'Ordnance Factory Itarsi',
      'Pachmarhi',
      'Pachore',
      'Pal Chaurai',
      'Palera',
      'Palsud',
      'Panagar',
      'Panara',
      'Pandhana',
      'Rampur',
      'Pandhurna',
      'Rampur Baghelan',
      'Pandhurna Industrial Area',
      'Rampura',
      'Pankhedi',
      'Ranapur',
      'Panna',
      'Ranipur',
      'Pansemal',
      'Ratangarh',
      'Pasan',
      'Ratlam',
      'Patan',
      'Rau',
      'Patharia',
      'Raymond Chhindwara',
      'Pawai',
      'Raymond Colony Vijay Gram',
      'Petlawad',
      'Phuphkalan',
      'Rehli',
      'Pichhore',
      'Rehti',
      'Pichhore, Gwalior District',
      'Rewa',
      'Pindrai',
      'Rosera',
      'Pipalrawan',
      'Sabalgarh',
      'Pipariya',
      'Sabo',
      'Pipariya Jabalpur District',
      'Sagar',
      'Pipariya, Jabalpur District',
      'Sailana',
      'Piplanarayanwar',
      'Sanawad',
      'Piploda',
      'Sanchi',
      'Piplya Mandi',
      'Sangvi',
      'Pipri',
      'Sanjay Gandhi Thermal  Power Station',
      'Pithampur',
      'Pithampur Industrial Hub',
      'Sanwer',
      'Polaykalan',
      'Sarangpur',
      'Porsa',
      'Sardarpur',
      'Prithvipur',
      'Sarni',
      'Purena',
      'Satai',
      'Raghogarh',
      'Satna',
      'Rahatgarh',
      'Satwas',
      'Raisen',
      'Sausar',
      'Rajakhedi',
      'Sehore',
      'Rajgarh',
      'Semaria',
      'Rajgarh, Dhar District',
      'Sendhwa',
      'Rajnagar',
      'Seondha',
      'Rajpur',
      'Seoni',
      'Seoni-Malwa',
      'Sethiya',
      'Shahdol',
      'Shahgarh',
      'Shahpur',
      'Shahpur, Betul District',
      'Shahpur, Sagar District',
      'Shahpura',
      'Shahpura, Jabalpur District',
      'Shajapur',
      'Shamgarh',
      'Shamshabad',
      'Sheopur',
      'Shivpuri',
      'Shujalpur',
      'Sidgunwa Industrial Area',
      'Sidhi',
      'Sihora',
      'Silwani',
      'Singoli',
      'Singrauli',
      'Sinhasa',
      'Sirgora',
      'Sirmaur',
      'Sironj',
      'Sitamau',
      'Siya Industrial Area',
      'Sohagi',
      'Sohagpur',
      'Sonkatch',
      'Soyatkalan',
      'Sultanpur',
      'Susner',
      'Suswasara',
      'Suthaliya',
      'Tal',
      'Talen',
      'Tarana',
      'Taricharkalan',
      'Tekanpur',
      'Tendukheda',
      'Tendukheda Damoh District',
      'Teonthar',
      'Thandla',
      'Tikamgarh',
      'Timarni',
      'Tirodi',
      'Tonk Khurd',
      'Ubaidullahganj',
      'Udaipura',
      'Udaypur',
      'Ujjain',
      'Ukwa',
      'Umaria',
      'Unchahara',
      'Unhel',
      'Vidisha',
      'Vijaypur Sheopur District',
      'Vijayraghavgarh',
      'Vindhya Nagar NTPC',
      'Waraseoni'
    ]
  },
  {
    code: 'Maharashtra',
    name: 'Maharashtra',
    stateCode: 'MH',
    cities: [
      'Aamby Valley',
      'Achalpur',
      'Achalpur MIDC',
      'Additional Chandrapur MIDC',
      'Aheri',
      'Ahmadnagar',
      'Ahmadpur',
      'Ahmadpur MIDC',
      'Ajanta',
      'Badlapur',
      'Ajara',
      'Balapur',
      'Ajara MIDC',
      'Balapur Akola District',
      'Akkalkot',
      'Balapur MIDC',
      'Akkalkuwa',
      'Ballarpur',
      'Akluj',
      'Banda',
      'Akola',
      'Baramati',
      'Akot',
      'Barshi',
      'Alandi',
      'Basmat MIDC',
      'Alibag',
      'Basmath',
      'Allapalli',
      'Bela',
      'Amalner',
      'Bhabale MIDC',
      'Amalner MIDC',
      'Bhadgaon',
      'Ambad',
      'Bhadravati',
      'Ambarnath',
      'Bhadravati MIDC',
      'Ambejogai',
      'Bhalar Township',
      'Ambepur',
      'Bhandara',
      'Ambivali Tarf Wankhal',
      'Bhandara MIDC',
      'Amboli',
      'Bhatkuli',
      'Amgaon',
      'Bhatkuli MIDC',
      'Amravati',
      'Bhiwandi',
      'Anjangaon',
      'Bhiwapur',
      'Anjarle',
      'Bhiwapur MIDC',
      'Arvi',
      'Bhokar',
      'Asangaon',
      'Bhokardan',
      'Asangaon Industrial Area',
      'Bhor',
      'Ashta',
      'Bhum',
      'Ashti',
      'Bhusawal',
      'Ashti Gadchiroli District',
      'Bhusawal MIDC',
      'Ashti MIDC',
      'Bid',
      'Ashti Wardha District',
      'Biloli',
      'Atgaon Industrial Complex',
      'Birwadi',
      'Aurangabad',
      'Bisur',
      'Ausa',
      'Boisar',
      'Awalpur',
      'Borgaon',
      'Babhaleshwar',
      'Bori',
      'Babhulgaon',
      'Borivali Tarf Rahur',
      'Borkhedi',
      'Borli Panchtan',
      'Brahmanvel MIDC',
      'Brahmapuri',
      'Budhagon',
      'Budhgaon',
      'Buldana',
      'Butibori',
      'Butibori MIDC',
      'Chakan',
      'Chalisgaon',
      'Chandgad',
      'Chandrapur',
      'Chandrapur CGGC',
      'Chandrapur MIDC',
      'Chandur',
      'Chandur Amravati District',
      'Chandurbazar',
      'Chandvad',
      'Chicholi',
      'Chikhala',
      'Chikhaldara',
      'Chikhli',
      'Chimur',
      'Chinchani',
      'Chincholi MIDC',
      'Chiplun',
      'Chitegaon',
      'Chopda',
      'Chowk',
      'Dabhol',
      'Dadar',
      'Dahanu',
      'Dahivadi',
      'Dandi',
      'Dapoli',
      'Dapoli Camp',
      'Darwha',
      'Daryapur Banosa',
      'Dattapur Dhamangaon',
      'Daund',
      'Deglur',
      'Deglur MIDC',
      'Dehu',
      'Dehu Cantt',
      'Deogiri',
      'Deola',
      'Deolali Pravara',
      'Deoli',
      'Deoni',
      'Deori',
      'Desaiganj',
      'Deulgaon Raja',
      'Deulgaon Raja MIDC',
      'Devgad',
      'Devrukh',
      'Dharangaon',
      'Dharmabad',
      'Dharni',
      'Dharni MIDC',
      'Dharur',
      'Dharur MIDC',
      'Dhopatala',
      'Dhule',
      'Digras',
      'Dindori',
      'Dindori MIDC',
      'Dodamarg',
      'Dondaicha Warwade',
      'Dudhani',
      'Durgapur',
      'Eklahare',
      'Elephanta Caves',
      'Ellora',
      '',
      'Erandol',
      'Hinganghat MIDC',
      'Etapalli',
      'Hingna',
      'Faizpur',
      'Hingoli',
      'Gadchiroli',
      'Hingoli MIDC',
      'Gadhinglaj',
      'Honad Industrial Area',
      'Gadhinglaj MIDC',
      'Hudkeshwar',
      'Gane MIDC',
      'Hupari',
      'Ganeshpur',
      'Ichalkaranji',
      'Gangakhed',
      'Igatpuri',
      'Gangapur',
      'Indapur',
      'Ganpatipule',
      'Indapur MIDC',
      'Gargoti',
      'Jafrabad',
      'Genesis Industrial',
      'Jafrabad MIDC Township',
      'Jalgaon',
      'Georai',
      'Jalgaon Jamod',
      'Ghansawangi',
      'Jalkot',
      'Ghatanji',
      'Jalna',
      'Ghoti',
      'Jalochi',
      'Ghugus',
      'Jamkhed',
      'Ghulewadi',
      'Jamner',
      'Gokunda',
      'Jath',
      'Gondia MIDC',
      'Jawhar',
      'Gondiya',
      'Jawharnagar',
      'Gondpipri',
      'Jaysingpur',
      'Goregaon',
      'Jejuri',
      'Goregaon Gondiya District ',
      'Jejuri MIDC',
      'Goregaon MIDC',
      'Jintur',
      'Guhagar',
      'Jintur MIDC',
      'Hadgaon',
      'Jiwati',
      'Hajarmachi',
      'Junnar',
      'Halkarni MIDC',
      'Kabnur',
      'Harihareshwar',
      'Kadegaon',
      'Harsul',
      'Kadegaon MIDC',
      'Hatkanangle',
      'Kagal',
      'Himayatnagar',
      'Kagal MIDC',
      'Hindnagar',
      'Kaij',
      'Hinganghat',
      'Kalamb',
      'Kalameshwar',
      'Kalameshwar MIDC',
      'Kalamnuri',
      'Kallam',
      'Kalmath',
      'Kalwan',
      'Kalyan',
      'Kamalapur MIDC',
      'Kambe',
      'Kamptee',
      'Kamptee Cantt',
      'Kamshet',
      'Kandari',
      'Kandhar',
      'Kandhar MIDC',
      'Kandri',
      'Kandri Mines',
      'Kandri Nagpur',
      'Kanhan',
      'Kanhe Industrial Area',
      'Kanholibara',
      'Kankavli',
      'Kannad',
      'Karad',
      'Karalewadi',
      'Karanja',
      'Karjat',
      'Karjat Ahmadnagar District',
      'Karmala',
      'Kasara',
      'Katangi Kala',
      'Katol',
      'Katol MIDC',
      'Kavalapur',
      'Kavathe Mahankal',
      'Kelapur',
      'Khamari',
      'Khamgaon',
      'Khanapur',
      'Khandala',
      'Khandala Satara',
      'Khandbara',
      'Khapa',
      'Khapar',
      'Kharbav',
      'Khardi',
      'Khatav',
      'Khed',
      'Kherdi',
      'Khopoli',
      'Kinwat',
      'Kolhapur',
      'Kolki',
      'Kondumal',
      'Kopargaon',
      'Koradi',
      'Korchi',
      'Koregaon',
      'Koregaon Bhima',
      'Korochi',
      'Krushnoor MIDC',
      'Kudal',
      'Kudal MIDC',
      'Kudus',
      'Kuhi',
      'Kumbhari',
      'Kundalwadi',
      'Kurduwadi',
      'Kurduwadi MIDC',
      'Kurkheda',
      'Kurkumbh MIDC',
      'Kurul',
      'Kurundvad',
      'Kusgaon Budruk',
      'Lakhamapur MIDC',
      'Lakhandhur',
      'Lakshmi Cooperative',
      'Industrial Estate',
      'Lanja',
      'Lasalgaon',
      'Lat',
      'Latur',
      'Latur Industrial Area',
      'Additional',
      'Lavasa',
      'Loha',
      'Lohara',
      'Lohara Osmanabad',
      'Lonand',
      'Lonar',
      'Lonavala',
      'Loni',
      'Loni kalbhor',
      'Lote Parshuram MIDC',
      'Madangad',
      'Madha',
      'Madhavnagar',
      'Mahabaleshwar',
      'Mahad',
      'Mahad MIDC',
      'Mahad MIDC Additional',
      'Mahadula',
      'Mahagaon',
      'Mahagaon MIDC',
      'Mahapoli',
      'Mahoor',
      'Maindargi',
      'Majgaon',
      'Makranifali',
      'Malde',
      'Malegaon',
      'Morshi MIDC',
      'Malegaon Jahangir',
      'Motala',
      'Malegaon MIDC',
      'Mouje Anjanvel',
      'Malkapur',
      'Mowad',
      'Malkapur Buldana District',
      'Mudkhed',
      'Malkapur MIDC',
      'Mukhed',
      'Malshiras',
      'Muktainagar',
      'Malwan',
      'Mul',
      'Manchar',
      'Mul MIDC',
      'Mandur',
      'Mulchera',
      'Mangalvedha',
      'Mumbai',
      'Mangrulpir',
      'Murbad',
      'Mangrulpir MIDC',
      'Murbad MIDC',
      'Manjlegaon',
      'Murgud',
      'Manmad',
      'Murmadi',
      'Manor',
      'Murtajapur',
      'Manora',
      'Murud',
      'Mansar',
      'Murum',
      'Mantha',
      'Musalgaon MIDC',
      'Manwath',
      'Nagbhid MIDC',
      'Maregaon',
      'Nagbhind',
      'Matheran',
      'Nagothana',
      'Mauda',
      'Nagpur',
      'Medha',
      'Naigaon',
      'Mehkar',
      'Nakoda',
      'Mehkar MIDC',
      'Naldurg',
      'Mhasla',
      'Nampur',
      'Mhaswad',
      'Nanda',
      'MIHAN',
      'Nanded Waghala',
      'Mohadi',
      'Nandgaon',
      'Mohadi MIDC',
      'Nandgaon Khandeshwar',
      'Mohol',
      'Nandgaon Pode',
      'Mohol MIDC',
      'Nandgaon Tarf Birwadi',
      'Mohpa',
      'Nandgaonpeth MIDC',
      'Mohpada Alias Wasambe',
      'Nandura',
      'Mokhada',
      'Nandurbar',
      'Morshi',
      'Narkher',
      'Nashik',
      'Natepute',
      'Navapur',
      'Navi Mumbai',
      'Ner',
      'Neral',
      'Newasa',
      'Nijampur',
      'Nilanga',
      'Nimbhore Budruk',
      'Niphad',
      'Nira',
      'Ordnance Factory Bhadravati',
      'Osmanabad',
      'Ozar',
      'Pachora',
      'Padagha',
      'Padoli',
      'Paithan',
      'Palam',
      'Palghar',
      'Pali',
      'Palus',
      'Palus MIDC',
      'Pampalner',
      'Panchgani',
      'Pandare MIDC',
      'Pandharkaoda',
      'Pandharpur',
      'Panhala',
      'Paranda',
      'Paratwada',
      'Parbhani',
      'Parli',
      'Parner',
      'Parola',
      'Parshioni',
      'Ralegaon',
      'Partur',
      'Ramtek',
      'Partur MIDC',
      'Ranjangaon',
      'Patalganga MIDC',
      'Raigad',
      'Ranjangaon MIDC',
      'Patan',
      'Rasal Industrial Area',
      'Patan MIDC',
      'Ratnagiri',
      'Pathan MIDC',
      'Raver',
      'Pathardi',
      'Rees',
      'Pathri',
      'Renapur',
      'Patoda',
      'RIL Township',
      'Patur',
      'Risod',
      'Pauni',
      'Roha Ashtami',
      'Peint',
      'Roha MIDC',
      'Peint MIDC',
      'Sadak Arjuni',
      'Pen',
      'Sadavali MIDC',
      'Phaltan',
      'Saidapur',
      'Pimpalgaon Baswant',
      'Sakoli',
      'Pimpri Chinchwad',
      'Sakri',
      'Pipri',
      'Salekasa',
      'Pirangut',
      'Samudrapur',
      'Poladpur',
      'Samudrapur MIDC',
      'Pombhurna',
      'Sanaswadi',
      'Pulgaon',
      'Sangameshwar',
      'Pune',
      'Sangamner',
      'Purna',
      'Sangamner MIDC',
      'Purushottamnagar',
      'Sangli Miraj Kupwad',
      'Pusad',
      'Sangole',
      'Pusad MIDC',
      'Saoner MIDC',
      'Radhanagari',
      'Sasti',
      'Rahata',
      'Sasvad',
      'Rahimatpur',
      'Satana',
      'Rahuri',
      'Satara',
      'Rajapur',
      'Savda',
      'Rajgurunagar',
      'Savner',
      'Rajur',
      'Sawali',
      'Rajur Ahmadnagar District',
      'Sawangi Megh',
      'Rajura',
      'Sawantwadi',
      'Seloo',
      'Selu',
      'Sengaon',
      'Shahada',
      'Shahapur',
      'Shahapur Bhandara District',
      'Shani Shingnapur',
      'Shegaon',
      'Shendra MIDC',
      'Shendurjana',
      'Shevgaon',
      'Shikrapur',
      'Shinde MIDC',
      'Shirala',
      'Shirala MIDC',
      'Shirdi',
      'Shirgaon',
      'Shirgaon Industrial Area',
      'Shirol',
      'Shirpur',
      'Shirur',
      'Shirur Anantpal',
      'Shirur Kasar',
      'Shirwal',
      'Shivaji Nagar',
      'Shrigonda',
      'Shrirampur',
      'Shrivardhan',
      'Sillewara',
      'Sillod',
      'Sindewahi',
      'Sindewahi MIDC',
      'Sindi',
      'Sindkhed Raja',
      'Sindkheda',
      'Singnapur Kopargaon',
      'Sinnar',
      'Sinnar MIDC',
      'Sironcha',
      'Solapur',
      'Sonai',
      'Sonegaon',
      'Songirwadi',
      'Sonpeth',
      'Supa MIDC',
      'Surgana',
      'Tadali',
      'Takalghat',
      'Takwe Industrial Area',
      'Tala',
      'Talasari',
      'Talegaon Dabhade',
      'Talegaon MIDC',
      'Taloda',
      'Tarapur',
      'Tarapur Textile Park',
      'Tasawade MIDC',
      'Tasgaon',
      'Tekadi',
      'Telhara',
      'Tembhurni MIDC',
      'Thana',
      'Thane',
      'Tirora',
      'Tirora MIDC',
      'Toranmal',
      'Totaladoh',
      'Trimbak',
      'Tuljapur',
      'Tumsar',
      'Tumsar Road',
      'Udgir',
      'Ulhasnagar',
      'Umarga',
      'Umarga MIDC',
      'Umarkhed',
      'Umarsara',
      'Umbar Pada Nandade',
      'Umred',
      'Umred MIDC',
      'Umri',
      'Uran Islampur',
      'Urjagram',
      'Usar MIDC',
      'Utekhol',
      'Vada',
      'Vadgaon',
      'Vaduj',
      'Vaibhawadi',
      'Vaijapur',
      'Vaijapur MIDC',
      'Vangani',
      'Vanvadi',
      'Varangaon',
      'Vasai Virar',
      'Vasantnagar',
      'Vashind',
      'Velhe',
      'Vengurla',
      'Vijay Durg',
      'Vikramgad',
      'Vile Bhagad MIDC',
      'Vinchur',
      'Vinchur MIDC',
      'Visapur',
      'Vita',
      'Vita MIDC',
      'Wada',
      'Wadgaon',
      'Wadi Ratnagiri',
      'Wadwani',
      'Waghapur',
      'Waghoda',
      'Wai',
      'Wai MIDC',
      'Walani',
      'Waluj MIDC',
      'Walwa',
      'Wani',
      'Wardha',
      'Warora',
      'Warora MIDC',
      'Warthi',
      'Warud',
      'Warud MIDC',
      'Washi',
      'Washim',
      'WCL Umred',
      'Yavatmal',
      'Yavatmal Industrial Area',
      'Yawal',
      'Yeola',
      'Yerkheda'
    ]
  },
  {
    code: 'Manipur',
    name: 'Manipur',
    stateCode: 'MN',
    cities: [
      'Andro',
      'Bishnupur',
      'Heirok',
      'Imphal',
      'Jiribam',
      'Kakching',
      'Kakching Khunou',
      'Kangpokpi',
      'Kumbi',
      'Kwakta',
      'Lamlai',
      'Lamsang',
      'Liong',
      'Lilong Imphal West',
      'Lilong Thoubal',
      'Mayang Imphal',
      'Moirang',
      'Moreh',
      'Nambol',
      'Ningthoukhong',
      'Oinam',
      'Samurou',
      'Samurou, Imphal West District',
      'Sekmai Bazar',
      'Senapati',
      'Sikhong Sekmai',
      'Sugnu',
      'Tamenglong',
      'Thongkhong Laxmi Bazar',
      'Thoubal',
      'Torban',
      'Ukhrul',
      'Wangjing',
      'Wangoi',
      'Yairipok',
      'Zenhang Lamka'
    ]
  },
  {
    code: 'Meghalaya',
    name: 'Meghalaya',
    stateCode: 'ML',
    cities: [
      'Baghmara',
      'Cherrapunjee',
      'Jowai',
      'Mairang',
      'Mawlai',
      'Nongpoh',
      'Nongstoin',
      'Resubelpara',
      'Shillong',
      'Tura',
      'Umroi',
      ' Williamnagar'
    ]
  },
  {
    code: 'Mizoram',
    name: 'Mizoram',
    stateCode: 'MZ',
    cities: [
      'Aizawl',
      'Bairabi',
      'Biate',
      'Champhai',
      'Darlawn',
      'Hnahthial',
      'Khawhai',
      'Khawzawl',
      'Kolasib',
      'Lawngtlai',
      'Lengpui',
      'Lunglei',
      'Mamit',
      'N Kawnpui',
      'N Vanlaiphai',
      'Saiha',
      'Sairang',
      'Saitual',
      'Serchhip',
      'Thenzawl',
      'Tlabung',
      'Vairengte',
      'Zawlnuam '
    ]
  },
  {
    code: 'Nagaland',
    name: 'Nagaland',
    stateCode: 'NL',
    cities: [
      'Changtongya',
      'Chumukedima',
      'Dimapur',
      'Jalukie',
      'Kiphire',
      'Kohima',
      'Longleng',
      'Medziphema',
      'Mokokchung',
      'Mon Town',
      'Naginimora',
      'Peren',
      'Pfutsero',
      'Phek',
      'Satakha',
      'Tseminyu',
      'Tuensang',
      'Tuli',
      'Tzudikong',
      'Wokha',
      'Zunheboto'
    ]
  },
  {
    code: 'Odisha',
    name: 'Odisha',
    stateCode: 'OR',
    cities: [
      'Agastinuagan',
      'Anandapur',
      'Anandpur',
      'Anjira',
      'Anjira Jagatsinghapur District',
      'Anugul',
      'Arjyapalli',
      'Asika',
      'Athagad',
      'Athmallik',
      'Badagada',
      'Badakodanda',
      'Badamba',
      'Badmal',
      'Balagoda',
      'Balakati',
      'Balangir',
      'Baleshwar',
      'Baliguda',
      'Balimela',
      'Balipatapur',
      'Banapur',
      'Bandhbahal',
      'Bangomunda',
      'Bangura',
      'Banki',
      'Barapali',
      'Barbil',
      'Bardol',
      'Bargarh',
      'Baripada',
      'Basudebpur',
      'Baudhgarh',
      'Belagachhia',
      'Bellaguntha',
      'Belpahar',
      'Berhampur',
      'Bhadrak',
      'Bhakarsahi',
      'Bhanjanagar',
      'Bhapur',
      'Bhatli',
      'Bhawanipatna',
      'Bhuban',
      'Bhubaneswar',
      'Bhushan Steel Plant',
      'Jharsuguda',
      'Bhushan Steel Plant',
      'Meramandali',
      'Bijepur',
      'Binika',
      'Biramitrapur',
      'Birapratappur',
      'Bishama Katak',
      'Bonaigarh',
      'Borigam',
      'Boriguma',
      'Brahmabarada',
      'Brajarajnagar',
      'Budhapanka',
      'Buguda',
      'Bundia',
      'Burla',
      'Byasanagar',
      'Champua',
      'Chandapur',
      'Chandili',
      'Charibatia',
      'Chhatrapur',
      'Chikiti',
      'Chitrakonda',
      'Choudwar',
      'Cuttack',
      'Dadhapatna',
      'Daitari',
      'Damanjodi',
      'Danara',
      'Daringbadi',
      'Debagarh',
      'Dera Colliery Township',
      'Dhamanagar',
      'Dhenkanal',
      'Digapahandi',
      'Dungamal',
      'FCI Township',
      'G Udayagiri',
      'Ganjam',
      'Ghantapada',
      'Ghatagaon',
      'GMR Power Plant Kmalanga',
      'Godiputamatiapara',
      'Golabandha',
      'Gopalpur',
      'Gotamara',
      'Gourdanda',
      'Gudari',
      'Gunupur',
      'Hinjilicut',
      'Hirakud',
      'IB Themal Power Plant Banharpali',
      'IMFA Therubali',
      'Indipur',
      'Itamati',
      'Jagatsinghapur',
      'Jajanga',
      'Jajapur',
      'Jaleswar',
      'Jashipur',
      'Jatani',
      'Jeypur',
      'Jharsuguda',
      'Jhumpura',
      'Joda',
      'JSPL Town Anugul',
      'Junagarh',
      'Kabatabandha',
      'Kaipadar',
      'Kalarangiata',
      'Kaliapani',
      'Kalinga Nagar Industrial Area',
      'Kalyanasingpur',
      'Kamakshyanagar',
      'Kanheipur',
      'Kansbahal',
      'Kantabanji',
      'Kantilo',
      'Karanjia',
      'Kashinagara',
      'Kavisurjyanagar',
      'Kendrapara',
      'Kendujhar',
      'Kesinga',
      'Khaliapali',
      'Khalikote',
      'Khandapada',
      'Khariar',
      'Khariar Road',
      'Khatiguda',
      'Khordha',
      'Kochinda',
      'Kodala',
      'Koida',
      'Konark',
      'Koraput',
      'Kotpad',
      'Krushnanandapur',
      'Kuanrmunda',
      'Kukudakhandi',
      'Kullada',
      'Kunjabangarh',
      'L And T Cement Plant Jharsuguda',
      'Lanjigarh',
      'Lathikata',
      'Lochapada',
      'Loisinga',
      'Madanpur Rampur',
      'Majhihara',
      'Makundapur',
      'Malkangiri',
      'Meghahatuburu',
      'Mohana',
      'Mukhiguda',
      'Mundamarai',
      'Nabarangapur',
      'Nalco',
      'Nayagarh',
      'New Lalsingi',
      'Nilagiri',
      'Nimapada',
      'NTPC Kaniha',
      'Nuahata',
      'Nuapatna',
      'OCL Industrial Township',
      'Odagaon',
      'Padmapur',
      'Palalahada',
      'Palurgada',
      'Papadahandi',
      'Paradip',
      'Parlakhemundi',
      'Pathar',
      'Patnagarh',
      'Patrapur',
      'Pattamundai',
      'Phulabani',
      'Pipili',
      'Pitala',
      'Polasara',
      'Puri',
      'Purusottampur',
      'R Udayagiri',
      'Raighar',
      'Rairangpur',
      'Rajagangapur',
      'Rajasunakhala',
      'Rambha',
      'Ranapurgada',
      'Rayagada',
      'Redhakhol',
      'Remuna',
      'Rengali',
      'Rengali Dam Project Township',
      'Rourkela',
      'Sambalpur',
      'Saranga',
      'Sayadpur',
      'Sheragada',
      'Sohela',
      'Sonapur',
      'Soro',
      'Subalaya',
      'Sunabeda',
      'Sundargarh',
      'Surada',
      'Surala',
      'Suvani',
      'Talcher',
      'Talcher Thermal Power Station Township',
      'Tangi',
      'Tarbha',
      'Tensa',
      'Tikarpada',
      'Tikarpada Ganjam District',
      'Tipo',
      'Titlagarh',
      'Tusura',
      'Udala',
      'Umarkote',
      'Vedanta Jharsuguda',
      'Venketraipur'
    ]
  },
  {
    code: 'Puducherry',
    name: 'Puducherry',
    stateCode: 'PY',
    cities: [
      'Karaikal',
      'Mahe',
      'Puducherry',
      'Thirumalairayan Pattinam',
      'Yanam'
    ]
  },
  {
    code: 'Punjab',
    name: 'Punjab',
    stateCode: 'PB',
    cities: [
      'Abohar',
      'Adampur',
      'Ahmedgarh',
      'Ajnala',
      'Akalgarh',
      'Alawalpur',
      'Amargarh',
      'Amloh',
      'Amritsar',
      'Anandpur Sahib',
      'Apra',
      'Aur',
      'Baba Bakala',
      'Baddowal',
      'Badhni Kalan',
      'Balachaur',
      'Banga',
      'Banur',
      'Bareta',
      'Bariwala',
      'Barnala',
      'Baryar',
      'Bassi Pathana',
      'Batala',
      'Bathinda',
      'Beas',
      'Begowal',
      'Beharnpur',
      'Behrampur',
      'Bhabat',
      'Bhadaur',
      'Bhadson',
      'Bhagha Purana',
      'Bhagta BhaiKa',
      'Bhankarpur',
      'Bhattian ',
      'Bhawanigarh',
      'Goindwal',
      'Bhikhi',
      'Goniana',
      'Bhikhiwind',
      'Goraya',
      'Bhisiana',
      'Gurdaspur',
      'Bhogpur',
      'Guru Har Sahai',
      'Bhucho Mandi',
      'Hajipur',
      'Bhulath',
      'Halwara',
      'Budha Theh',
      'Handiaya',
      'Budhlada',
      'Hariana',
      'Bungal',
      'Hoshiarpur',
      'Chamkaur Sahib',
      'Hussainpur',
      'Cheema',
      'Jagraon',
      'Chogawan',
      'Jaitu',
      'Chohal',
      'Jalalabad',
      'Daper',
      'Jalandhar',
      'Dasuya',
      'Jandiala',
      'Dera Baba Nanak',
      'Jandiala, Jalandhar',
      'Dera Bassi District',
      'Dera Bassi Industrial Area',
      'Jodhan',
      'Dhanaula',
      'Jugial',
      'Dharamkot',
      'Kalan',
      'Dhariwal',
      'Kalanaur',
      'Dhilwan',
      'Kapurthala',
      'Dhuri',
      'Karoran',
      'Dinanagar',
      'Kartarpur',
      'Dirba',
      'Kathanian',
      'Doraha',
      'Khamanon',
      'Faridkot',
      'Khambra',
      'Fatehgarh Churian',
      'Khanauri',
      'Fazilka',
      'Khanna',
      'Firozpur',
      'Kharar',
      'Gardhiwala',
      'Khemkaran',
      'Garhshankar',
      'Khilchian',
      'Ghagga',
      'Korianwali',
      'Ghanaur',
      'Kot Fatta',
      'Ghoh',
      'Kot Ise Khan',
      'Giddarbaha',
      'Kotkapura',
      'Kotla Nihang',
      'Kurali',
      'Lalru',
      'Leather Complex Industrial Area',
      'Lehragaga',
      'Lohian Khas',
      'Longowal',
      'Ludhiana',
      'Machhiwara',
      'Mahilpur',
      'Majitha',
      'Makhu',
      'Malaut',
      'Malerkotla',
      'Mallanwala Khass',
      'Maloud',
      'Mamun',
      'Mandi Govindgarh',
      'Mansa',
      'Manwal',
      'Maur',
      'Mirpur',
      'Moga',
      'Mohali',
      'Moonak',
      'Morinda',
      'Mubarakpur',
      'Mudal',
      'Mudki',
      'Mukerian',
      'Muktsar',
      'Mullanpur Dakha',
      'Mullanpur Garib Dass',
      'Nabha',
      'Nag Kalan Industrial Area',
      'Nakodar',
      'Nangal',
      'Nangli',
      'Narot Mehra',
      'Nawanshahr',
      'Nehon',
      'Nurmahal',
      'Pathankot',
      'Patiala',
      'Patran',
      'Patti',
      'Payal',
      'Phagwara',
      'Phillaur',
      'Qadian',
      'Rahon',
      'Raikot',
      'Rail',
      'Raipur Rasulpur',
      'Raja Sansi',
      'Rajpura',
      'Rakri',
      'Raman',
      'Ramdas',
      'Rampura Phul',
      'Rayya',
      'Rupnagar',
      'Rurki Kasba',
      'Sahnewal',
      'Saloh',
      'Samana',
      'Samrala',
      'Sanaur',
      'Sangat',
      'Sangrur',
      'Sansarpur',
      'Sarai Khas',
      'Sardulgarh',
      'Satyewala',
      'Shahkot',
      'Sham ChaurasI',
      'Shikar',
      'Sirhind Fatehgarh Sahib',
      'Sri Hargobindpur',
      'Sujanpur',
      'Sultanpur',
      'Sunam',
      'Talwandi Bhai',
      'Talwandi Sabo',
      'Talwara',
      'Tapa',
      'Tarantaran',
      'Tharial',
      'Tibri',
      'Tungaheri',
      'Urmar Tanda',
      'Zira',
      'Zirakpur'
    ]
  },
  {
    code: 'Rajasthan',
    name: 'Rajasthan',
    stateCode: 'RJ',
    cities: [
      '1SGM',
      '3STR',
      'Abu Road',
      'Ahore',
      'Ajeetgarh',
      'Ajmer',
      'Ajolion Ka Khera Industrial Area',
      'Aklera',
      'Aligarh',
      'Alwar',
      'Amet',
      'Antah',
      'Anupgarh',
      'Asind',
      'Atru',
      'Babai',
      'Baggar',
      'Bagru',
      'Bakani',
      'Bali',
      'Balotra',
      'Banasthali',
      'Bandikui',
      'Banera',
      'Bangur Nagar',
      'Banswara',
      'Bar',
      'Baral',
      'Baran',
      'Bari',
      'Bari Sadri',
      'Barmer',
      'Baskhoh',
      'Basni Belima',
      'Bassi',
      'Bay',
      'Bayana',
      'Beawar',
      'Beejoliya Kalan',
      'Begun',
      'Behror',
      'Bewanja Industrial Area',
      'Bhadra',
      'Bhalariya',
      'Bharatpur',
      'Bhavri',
      'Bhawani Mandi',
      'Bhilwara',
      'Bhim',
      'Bhinder',
      'Bhinmal',
      'Bhiwadi',
      'Bhusawar',
      'Bicciwara Industrial Area',
      'Bichhiwara',
      'Bichhri',
      'Bidasar',
      'Bigod',
      'Bikaner',
      'Bilara',
      'Bishangarh',
      'Bissau',
      'BITS Pilani Campus',
      'Boranada Special Economic Zone',
      'Borawar',
      'Budhpura',
      'Bundi',
      'Chaksu',
      'Chawand',
      'Chechat',
      'Chhabra',
      'Chhapar',
      'Chhipabarod',
      'Chhoti Sadri',
      'Chirawa',
      'Chittaurgarh',
      'Chomu',
      'Churu',
      'Danta',
      'Dariba',
      'Dausa',
      'Deeg',
      'Delwara',
      'Deogarh',
      'Deoli',
      'Deshnoke',
      'Dewrighata Industrial Area',
      'Dhariawad',
      'Industrial Area Bigod',
      'Dhaulpur',
      'Industrial Area Deoli',
      'Dhorimanna',
      'Industrial Area Gegal',
      'Didwana',
      'Industrial Area Hathipura',
      'Dudu',
      'Industrial Area Kaladera',
      'Dungargarh',
      'Industrial Area Kanya Kheri',
      'Dungarpur ',
      'Emri',
      'Industrial Area Karauli',
      'Falna',
      'Industrial Area Mathania',
      'Fatehnagar',
      'Industrial Area Napasar',
      'Fatehpur',
      'Industrial Area Narbad Khera',
      'Gajsinghpur ',
      'Galiakot',
      'Industrial Area Sangaria',
      'Gangapur',
      'Industrial Area Swaroopganj',
      'Gangapur City ',
      'Garhi',
      'Islampur',
      'Gharsana',
      'Jahazpur',
      'Gogunda',
      'Jahazpur Industrial Area',
      'Goredi Chancha',
      'Jaipur',
      'Gothan',
      'Jaisalmer',
      'Gothra',
      'Jaitaran',
      'Govindgarh, Alwar District',
      'Jalor',
      'Govindgarh, Jaipur District',
      'Jalore Industrial Area',
      'Govindpur Baori Industrial Area',
      'Jamwa Ramgarh',
      'Jhagarwas',
      'Goyli',
      'Jhalawar',
      'Guhala',
      'Jhalrapatan',
      'Gulabpura',
      'Jhunjhunun',
      'Hamirgarh',
      'Hanumangarh',
      'Jobner',
      'Hamirgarh Growth Centre RIICO',
      'Jodhpur',
      'Jodhpur Stone Park',
      'Hanumangarh Industrial Area',
      'Hindaun',
      'Kaithoon',
      'Hindaun City Industrial Area',
      'Kaladwas Industrial Area',
      'Kaman',
      'Indragarh',
      'Kanor',
      'Industrial Area Bidiyad',
      'Kanwat',
      'Kapasan',
      'Lakheri',
      'Kaprain',
      'Lalsot',
      'Karanpur',
      'Losal',
      'Karauli',
      'Lunkaransar',
      'Kasba Bonli',
      'Mahu Kalan',
      'Kawai',
      'Mahwa',
      'Kekri',
      'Makrana',
      'Kelwa',
      'Malpura',
      'Keshoraipatan',
      'Malsisar',
      'Kesrisinghpur',
      'Mandalgarh',
      'Khairabad',
      'Mandawa',
      'Khairthal',
      'Mandawar',
      'Khajuwala',
      'Mandpiya',
      'Khandela, Baran District',
      'Mangrol',
      'Khandela, Sikar District',
      'Manoharpur',
      'Khanpur',
      'Manoharthana',
      'Khara',
      'Marwar Junction',
      'Khatu',
      'Mathania',
      'Kherli',
      'Mavli',
      'Kherliganj',
      'Mehandipur',
      'Kherwara Chhaoni',
      'Merta City',
      'Khetri',
      'Merta Road',
      'Kinchan',
      'Modak',
      'Kishangarh',
      'Mokalsar',
      'Kishangarh Renwal',
      'Mount Abu',
      'Kishangarh, Alwar District',
      'Mukandgarh',
      'Kolayat',
      'Mundwa',
      'Kolvi Mandi Rajendrapur',
      'Nadbai',
      'Kota',
      'Nagar',
      'Kotputli',
      'Nagaur',
      'Kuchaman City',
      'Nagaur Industrial Area',
      'Kuchera',
      'Nainwa',
      'Kumbhkot',
      'Nandri',
      'Kumher',
      'Napasar',
      'Kushalgarh',
      'Nasirabad',
      'Lachhmangarh',
      'Nathdwara',
      'Ladnu',
      'Nawalgarh',
      'Ramgarh, Alwar District',
      'Neem Ka Thana Industrial Area',
      'Ramgarh, Sikar District ',
      'Rani',
      'Neem-Ka-Thana',
      'Rani Sagar RIICO',
      'Neemrana',
      'Ranthambore',
      'Newa Talai',
      'Ratangarh',
      'Nimbahera',
      'Ratannagar',
      'Niwai',
      'Rawatbhata',
      'Niwai Industrial Area',
      'Rawatsar',
      'Nohar',
      'Reengus',
      'Nokha',
      'Renwal',
      'Nooan',
      'Reodar',
      'Padampur',
      'RIICO Industrial Area Bassi',
      'Pali ',
      'Parbatsar',
      'RIICO Industrial Area Bidasar',
      'Partapur ',
      'Partapur Industrial Area',
      'RIICO Industrial Area Bikasar',
      'Phalodi',
      'Phalodi Industrial Area',
      'RIICO Industrial Area Chopanki',
      'Phulera',
      'Pilani Town',
      'RIICO Industrial Area Gudli',
      'Pilibanga',
      'Pindwara',
      'RIICO Industrial Area Kharani',
      'Pipar City',
      'Pirawa',
      'RIICO Industrial Area Khushkhera',
      'Pokaran',
      'Pratapgarh',
      'RIICO Industrial Area Naya Gaon',
      'Pushkar',
      'Raila',
      'RIICO Industrial Area Neemrana',
      'Raisinghnagar ',
      'Rajakhera',
      'RIICO Industrial Area Palsana',
      'Rajaldesar ',
      'Rajgarh',
      'RIICO Industrial Area Rajgarh',
      'Rajgarh, Churu District',
      'Rajsamand',
      'RIICO Industrial Area Sanchore',
      'Ramganj Mandi',
      'Ramgarh',
      'RIICO Institutional Area Ranpur',
      'Sikar',
      'RIICO Industrial Area',
      'Silora Industrial Area',
      'Rupangarh',
      'Singhana',
      'Rikhabdeo',
      'Sirohi',
      'Sadri',
      'Sirohi Industrial Area',
      'Sadulshahar',
      'Siwana',
      'Sagwara',
      'Sojat',
      'Salasar',
      'Sojat Road',
      'Salumbar',
      'Sri Ganganagar',
      'Sambhar',
      'Sri Madhopur',
      'Samdari',
      'Sri Madhopur Indusrtial',
      'Sanchore Area',
      'Sanderao',
      'Sudarshanpura RIICO',
      'Sangaria',
      'Sujangarh',
      'Sangod',
      'Suket',
      'Sapotra',
      'Suket Industrail Area',
      'Saradhana RIICO',
      'Sumerganj Mandi',
      'Sardargarh',
      'Sumerpur',
      'Sardarshahar',
      'Sumerpur Industrial Area',
      'Sarekhurd Industrial Area RIICO',
      'Sundlak RIICO Baran',
      'Surajgarh',
      'Sariska',
      'Suratgarh',
      'Sarmathura',
      'Swaroopganj',
      'Sarwar',
      'Takhatgarh',
      'Satalkheri',
      'Talera',
      'Sawa',
      'Tapookra',
      'Sawai Madhopur',
      'Taranagar',
      'Seemalwara',
      'Thana Gazi RIICO',
      'Semari',
      'Tijara',
      'Shahjahanpur',
      'Todabhim',
      'Shahjahanpur Industrial Area',
      'Todaraisingh',
      'Todra',
      'Shahpura',
      'Tonk',
      'Shahpura, Bhilwara District',
      'Tonk Industrial Area',
      'Udaipur',
      'Sheoganj',
      'Udaipurwati',
      'Shiwar',
      'Udpura',
      'Uniara',
      'Utarlai',
      'Vijainagar',
      'Vijainagar, Ganganagar District',
      'Viratnagar',
      'Weir'
    ]
  },
  {
    code: 'Sikkim',
    name: 'Sikkim',
    stateCode: 'SK',
    cities: [
      'Gangtok',
      'Gyalshing',
      'Jorethang',
      'Mangan',
      'Meli',
      'Namchi',
      'Nayabazar',
      'Pelling',
      'Rangpo',
      'Rhenock',
      'Singtam',
      'Upper Tadong'
    ]
  },
  {
    code: 'Tamil Nadu',
    name: 'Tamil Nadu',
    stateCode: 'TN',
    cities: [
      'VA Vellalapatti',
      'Abiramam',
      'Achampudur',
      'Acharapakkam',
      'Achipatti',
      'Adikaratti',
      'Adiramapattinam',
      'Adiyanuthu',
      'Aduthurai',
      'Agaram',
      'Agastheeswaram',
      'Alagappapuram',
      'Alanganallur',
      'Alangayam',
      'Alangudi',
      'Arakandanallur',
      'Alangulam',
      'Arakonam',
      'Alangulam, Virudhunagar District',
      'Aralvaimozhi',
      'Arani',
      'Alanthurai',
      'Arani, Thiruvallur District',
      'Alathur, Chengalpattu Taluka',
      'Aranthangi',
      'Arasiramani',
      'Aliyar',
      'Arasur',
      'Alur',
      'Aravakurichi',
      'Alwarkurichi',
      'Arcot',
      'Alwarthirunagiri',
      'Arimalam',
      'Amathur',
      'Ariyalur',
      'Ambasamudram',
      'Ariyappampalayam',
      'Ambur',
      'Ariyur',
      'Ammainaickanur',
      'Arumanai',
      'Ammanur',
      'Arumbanur',
      'Ammapattinam',
      'Arumbavur',
      'Ammapettai',
      'Arumuganeri',
      'Ammapettai, Erode District',
      'Aruppukkottai',
      'Asaripallam',
      'Ammavarikuppam',
      'Athani',
      'Ammoor',
      'Athanur',
      'AMRL Sez Vaigaikulam',
      'Athimarapatti',
      'Anaimalai',
      'Athipatti',
      'Anaiyur',
      'Athur',
      'Ananthapuram',
      'Athur, Thoothukkudi',
      'Andipalayam District',
      'Andipatti Jakkampatti',
      'Attayampatti',
      'Anjugrammam',
      'Attur',
      'Annamalai Nagar',
      'Avadattur',
      'Annanji',
      'Avalapalli',
      'Annavasal',
      'Avalpoondurai',
      'Annur',
      'Avanashi',
      'Anthiyur',
      'Avaniapuram',
      'Anuppankulam',
      'Ayakudi',
      'Appakudal',
      'Aygudi',
      'Arachalur',
      'Ayothiapattinam',
      'Ayyalur',
      'Ayyampalayam',
      'Ayyampettai',
      'Ayyampettai, Thanjavur District',
      'Azhagiapandipuram',
      'B Mallapuram',
      'B Meenakshipuram',
      'Balakrishnampatti',
      'Balakrishnapuram',
      'Balapallam',
      'Balasamudram',
      'Bargur',
      'Batlagundu',
      'Belur',
      'Bharathi Nagar',
      'Bhavani',
      'Bhavanisagar',
      'Bhuvanagiri',
      'Bikketti',
      'Bodinayakanur',
      'Boothapandi',
      'Boothipuram',
      'Chakkarapalli',
      'Chathirareddipatti',
      'Chatrapatti',
      'Chenbagaramanputhur',
      'Chengalpattu',
      'Chengam',
      'Chengappalli',
      'Chennagiri',
      'Chennai',
      'Chennasamudram',
      'Chennimalai',
      'Cheranmadevi',
      'Chetpet',
      'Chettiarpatti',
      'Chettipalayam',
      'Dharapadavedu',
      'Chettipalayam,',
      'Dharapuram',
      'Coimbatore District',
      'Dharmapuri',
      'Chettithangal',
      'Dindigul',
      'Chidambaram',
      'Doramangalam',
      'Chinnakalayamputhur',
      'Dusi',
      'Chinnakkampalayam',
      'Edaganasalai',
      'Chinnalapatti',
      'Edaikodu',
      'Chinnamanur',
      'Edakalinadu',
      'Chinnampalayam',
      'Edappadi',
      'Chinnasalem',
      'Elathur',
      'Chinnathadagam',
      'Elayirampannai',
      'Chinniampalayam, Erode District',
      'ELCOT Gangaikodan IT Park',
      'Chinthamani',
      'Ellandaikuttai',
      'Chithode',
      'Elumalai',
      'Chockampatti',
      'Eral',
      'Cholapuram',
      'Eranapuram',
      'Coimbatore',
      'Eraniel',
      'Coonoor',
      'Eriodu',
      'Courtalam',
      'Erode',
      'Cuddalore',
      'Erumaipatti',
      'Damalerimuthur',
      'Eruvadi',
      'Dasanaickenpatti',
      'Ethapur',
      'Denkanikottai',
      'Ettayapuram',
      'Desur',
      'Ettimadai',
      'Devadanapatti',
      'Ezhudesam',
      'Devakottai',
      'Frontier Mediville SEZ',
      'Devanangurichi',
      'Ganapathipuram',
      'Devarshola',
      'Gandhi Nagar',
      'Devasthanam',
      'Gangaikondan',
      'Devikapuram',
      'Gangavalli',
      'Devipattinam',
      'Ganguvarpatti',
      'Dhalavoipuram',
      'Gingee',
      'Dhali',
      'GK Industrial Park',
      'Dhaliyur',
      'Gobichettipalayam',
      'Dharamapuram',
      'Gopalasamudram',
      'Gudalur',
      'Gudalur, The Nilgiris District',
      'Gudalur, Theni District',
      'Gudiyatham',
      'Gummidipoondi',
      'Hale Dharmapuri',
      'Hanumanthampatti',
      'Harur',
      'Harveypatti',
      'Highways',
      'Hosur',
      'Hubbathala',
      'Huligal',
      'Idikarai',
      'Iduvai',
      'Ilampillai',
      'Ilanji',
      'Ilayangudi',
      'Iluppaiyurani',
      'Iluppur',
      'Irugur',
      'Jaffrabad',
      'Jalakandapuram',
      'Jambai',
      'Jayankondam',
      'Jeyamangalam',
      'Jolarpet',
      'K Madapur',
      'Kadambathur',
      'Kadambur',
      'Kadathur',
      'Kadayal',
      'Kadayam',
      'Kadayampatti',
      'Kadayanallur',
      'Kailasagiri',
      'Kakkalur',
      'Kalakad',
      'Kalakattupular',
      'Kalambur',
      'Kalappanaickenpatti',
      'Kalavai',
      'Kaliyakkavilai',
      'Kaliyapuram',
      'Kalladaikurichi',
      'Kallakkurichi',
      'Kallakudi',
      'Kallangudy',
      'Kallipalaym',
      'Kallukoottam',
      'Kalparapatti',
      'Kalugumalai',
      'Kamalakannanji SIDCO Industrial Estate',
      'Kamayagoundanpatti',
      'Kambainallur',
      'Kambam',
      'Kamuthi',
      'Kanadukathan',
      'Kanakkampalayam',
      'Kanakkanpatti',
      'Kanam',
      'Kancheepuram',
      'Kandanur',
      'Kangayampalayam',
      'Kangeyam',
      'Kaniyambadi',
      'Kaniyampoondl',
      'Kaniyur',
      'Kaniyur,Coimbatore District',
      'Kanjikoil',
      'Kannamangalam',
      'Kannampalayam',
      'Keelakarai',
      'Kannanendal',
      'Keeramangalam',
      'Kannanoor',
      'Keeranur',
      'Kannivadi',
      'Keeranur, Pudukkottai District',
      'Kannivadi, Dindigul District',
      'Keeripatti',
      'Kanniyakumari',
      'Keezhkulam',
      'Kappalur SIDCO Industrial Estate',
      'Kelamangalam',
      'Kembainaickenpalayam',
      'Kappiyarai',
      'Kethi',
      'Karadipatti',
      'Kila Ambur',
      'Karaikkudi',
      'Kilampadi',
      'Karaikudi SIDCO Industrial Estate',
      'Kilapavoor',
      'Kilkunda',
      'Karamadai',
      'Killai',
      'Karambakkudi',
      'Killiyoor',
      'Kariamangalam',
      'Kilpennathur',
      'Kariapatti',
      'Kilvaithinankuppam',
      'Karugampattur',
      'Kilvelur',
      'Karukkalvadi',
      'Kinathukadavu',
      'Karumandi Chellipalayam',
      'Kodaikanal',
      'Karumathampatti',
      'Kodavasal',
      'Karungal',
      'Kodivalasa',
      'Karunguzhi',
      'Kodumudi',
      'Karuppur',
      'Koil Palayam',
      'Karur',
      'Kolachal',
      'Kasipalayam',
      'Kolappalur',
      'Kathujuganapalli',
      'Kolathupalayam',
      'Kattathurai',
      'Kolathur',
      'Kattumannarkoil',
      'Kollancode',
      'Kattupakkam',
      'Kollankoil',
      'Kattuputhur',
      'Komaralingam',
      'Kaveripakkam',
      'Kombai',
      'Kaveripattinam',
      'Kondur',
      'Kavindapadi',
      'Konganapuram',
      'Kayalpattinam',
      'Koonavelampatti',
      'Kayatharu',
      'Kooraikundu',
      'Koothappar',
      'Koradacheri',
      'Kotagiri',
      'Kothanallur',
      'Kottaiyur',
      'Kottakuppam',
      'Kottaram',
      'Kottur',
      'Kovalam',
      'Kovilpatti',
      'Krishnagiri',
      'Krishnarayapuram',
      'Kuchanur',
      'Kuchipulayam',
      'Kuhalur',
      'Kulappuram',
      'Kulasekaram',
      'Kulasekarapuram',
      'Kulathur',
      'Kulithalai',
      'Kullursandai',
      'Kumbakonam',
      'Kumaragiri',
      'Kumarapalayam',
      'Kumarapuram',
      'Kumbakonam',
      'Kunnathur',
      'Kurinjipadi',
      'Kurudampalayam',
      'Kurukkupatti',
      'Kurumbalur',
      'Kurumbapatti',
      'Kuruppanaickenpalayam',
      'Kuthalam',
      'Kuthanallur',
      'Kuzhithurai',
      'Labbaikudikadu',
      'Lakkampatti ',
      'Lakshmi Puram',
      'Lalgudi',
      'Lalpet',
      'Lampalayam',
      'Madaharpakkam',
      'Madathukulam',
      'Madukkarai',
      'Madukkur',
      'Madurai',
      'Maduranthakam',
      'Mahindra World City',
      'Makkinampatti',
      'Mallankinaru',
      'Mallasamudram',
      'Mallur',
      'Malumichampatti',
      'Mamallapuram',
      'Mamsapuram',
      'Manachanallur',
      'Manalikarai',
      'Manalmedu',
      'Manalurpet',
      'Manamadurai',
      'Manapparai',
      'Manavalakurichi',
      'Mancad',
      'Mandaikadu',
      'Mandapam',
      'Mangalam',
      'Mangalampet',
      'Manimutharu',
      'Mannargudi',
      'Manthithoppu',
      'Maraimalainagar',
      'Marakkanam',
      'Maramangalathupatti',
      'Marandahalli',
      'Markayankottai',
      'Marthandam',
      'Marudur',
      'Marungur',
      'Maruthancode',
      'Masinaickenpatty',
      'Mathicode',
      'Mathigiri',
      'Mathur',
      'Mayiladuthurai',
      'Mecheri',
      'Melacheval',
      'Melachokkanathapuram',
      'Melagaram',
      'Melaparthibanur',
      'Melathiruppanthuruthi',
      'Melattur',
      'Melmangalam',
      'Melpattampakkam',
      'Melur',
      'Melvisharam',
      'Methukummal',
      'Mettamalai',
      'Mettunasuvanpalayam',
      'Mettupalayam',
      'Mettupalayam, Tiruchirappalli District',
      'Mettur',
      'Mevalurkuppam',
      'Milavittan',
      'Minampalli-Pachamadevi',
      'Modakurichi',
      'Mohamed Bundur',
      'Mohanur',
      'Moolakaraipatti',
      'Mopperipalayam',
      'Morattupalayam',
      'Mudalipalayam SIDCO',
      'Mudukulathur',
      'Mudumalai',
      'Mukasipidariyur',
      'Mukkudal',
      'Mulagumudu',
      'Mulanur',
      'Mullipattu',
      'Muruganpalayam',
      'Musiri',
      'Muthanampalayam',
      'Muthukadu',
      'Muthupet',
      'Muthur',
      'Muttayyapuram',
      'Muzhucode',
      'Mylaudy',
      'Nadaikavu',
      'Naduvaneri',
      'Naduvattam',
      'Nagamalaipudukottai',
      'Nagamangalam',
      'Nagapattinam',
      'Nagercoil',
      'Nagojanahalli',
      'Nallampatti',
      'Nallipalayam',
      'Nallur',
      'Namagiripettai',
      'Namakkal',
      'Nambiyur',
      'NandivaramGuduvancheri',
      'Nangavalli',
      'Nangavaram',
      'Nanguneri',
      'Nanjaiuthukuli Industrial Estate',
      'Nanjikottai',
      'Nannilam',
      'Naranammalpuram',
      'Naranapuram',
      'Narasimhanaickenpalayam',
      'Narasingapuram',
      'Narasingapuram, Salem District',
      'Nasiyanur',
      'Natchiarkoil',
      'Natham',
      'Nathampannai',
      'Natrampalli',
      'Nattalam',
      'Nattapettai',
      'Nattarasankottai',
      'Nazerath',
      'Needamangalam',
      'Neelagiri',
      'Neikkarapatti',
      'Nellikuppam',
      'Nelliyalam',
      'Nemili',
      'Neripperichal',
      'Nerkuppai',
      'Nerunjipettai',
      'Neyveli',
      'Neyyoor',
      'Nilaiyur',
      'Nilakkottai',
      "O' Valley",
      'Odaipatti',
      'Odaiyakulam',
      'Oddanchatram',
      'Odugathur',
      'Olagadam',
      'Omalur',
      'Ooty',
      'Perambalur',
      'Periyamanali',
      'Puliyur',
      'Periyanaickenpalayam',
      'Pullampadi',
      'Pernampattu',
      'Punjai Thottakurichi',
      'Perumagalur',
      'Punjaipugalur',
      'Perumagoundampatti',
      'Punjaipuliampatti',
      'Perumanallur',
      'Puthalam',
      'Perumandi',
      'Putheri',
      'Perundurai',
      'Puthukkadai',
      'Perungulam',
      'Puvalur',
      'Pethampalayam',
      'R Pudupatti',
      'Pethanaickenpalayam',
      'Rajapalayam',
      'Pettai',
      'Rajapalayam, Salem District',
      'Pillanallur',
      'PJ Cholapuram',
      'Rakkiya Palayam',
      'Pollachi',
      'Ramalingapuram',
      'Polur',
      'Ramanathapuram',
      'Pongaliyur',
      'Rameswaram',
      'Ponmanai',
      'Ranipettai',
      'Ponnamaravathi',
      'Rasipuram',
      'Ponnampatti',
      'Rayagiri',
      'Ponneri',
      'RS Mangalam',
      'Poolambadi',
      'Rudravathi',
      'Poolampatti',
      'S Kannanur',
      'Poolankinar',
      'S Kodikulam',
      'Pooluvapatti',
      'S Nallur',
      'Porayar',
      'Sakkimangalam',
      'Pothanur',
      'Salangapalayam',
      'Pothatturpettai',
      'Salem',
      'Pudiyamputhur',
      'Samalapuram',
      'Pudukkottai',
      'Samanatham',
      'Pudupalayam',
      'Samathur',
      'Pudupalayam Agraharam',
      'Sambavar Vadagarai',
      'Pudupatti',
      'Sankaramanallur',
      'Pudupattinam',
      'Sankarankoil',
      'Pudur',
      'Sankaraperi',
      'Puduvayal',
      'Sankarapuram',
      'Puliankudi',
      'Sankarnagar',
      'Sirugamani',
      'Sarcarsamakulam',
      'Sirumugai',
      'Sathankulam',
      'Sithayankottai',
      'Sathiyavijayanagaram',
      'Sithurajapuram',
      'Sathyamangalam',
      'Sivaganga',
      'Sattur',
      'Sivagiri',
      'Sayalgudi',
      'Sivagiri, Erode District',
      'Sayapuram',
      'Sivagiripatti',
      'Seerapalli',
      'Sivagnanapuram',
      'Seevur',
      'Sivakasi',
      'Seithur',
      'Sivanthipatti',
      'Semmipalayam',
      'Sivanthipuram',
      'Senthamangalam',
      'Srikalikapuram',
      'Sentharapatti',
      'Srimushnam',
      'Senur',
      'Sriperumbudur',
      'Sethiathoppu',
      'Sriramapuram',
      'Sevilimedu',
      'Srivaikuntam',
      'Sevugampatti',
      'Srivilliputhur',
      'Sevur',
      'Suchindrum',
      'Shenkottai',
      'Suleeswaranpatti',
      'Sholavandan',
      'Sulur',
      'Sholingur',
      'Sundarapandiam',
      'Sholur',
      'Sundarapandianpattinam',
      'SIDCO Bargur',
      'Sundarapandiapuram',
      'Singampuneri',
      'Surandai',
      'Singaperumalkoil',
      'Suriyampalayam',
      'Singilipatti',
      'Swamimalai',
      'SIPCOT Cheyyar',
      'T Kallipatti',
      'SIPCOT Industrial Complex Pillaipakkam',
      'T Kallupatti',
      'Tajpura',
      'SIPCOT Industrial Park Sriprumbudur',
      'Tayilupatti',
      'Tenkasi',
      'SIPCOT Perundurai',
      'Terkukallikulam',
      'SIPCOT Thervoy Kandigal',
      'Thadikarankonam',
      'SIPCOT Thoothukudi',
      'Thadikombu',
      'Sircar Periapalayam',
      'Thakkolam',
      'Sirkali',
      'Thalainayar',
      'Thamaraikulam',
      'Thammampatti',
      'Thanjavur',
      'Thanthoni',
      'Thappakuttai',
      'Tharamangalam',
      'Tharangambadi',
      'Thathaiyangarpet',
      'Thathankuttai',
      'Thazhakudy',
      'Thedavur',
      'Thenambakkam',
      'Thengampudur',
      'Theni Allinagaram',
      'Thenkarai',
      'Thenkarai, Coimbatore District',
      'Thenthamaraikulam',
      'Thenthiruperai',
      'Therur',
      'Thevaram',
      'Thevarappan Patti',
      'Thevur',
      'Thiagadurgam',
      'Thikkanamcode',
      'Thimmaiyanpettai',
      'Thingalnagar',
      'Thirikoodapuram',
      'Thirukarungavur',
      'Thirukarungudi',
      'Thirukkadaiyur',
      'Thirukkattupalli',
      'Thirumalayampalayam',
      'Thirumalpur',
      'Thirumangalam',
      'Thirumuruganpoondi',
      'Thirunageswaram',
      'Thiruparankundram',
      'Tirukkoyilur',
      'Thiruparappu',
      'Tirumalaigiri',
      'Thiruporur',
      'Tirunelveli',
      'Thiruppalai',
      'Tirupathur',
      'Thiruppanandal',
      'Tirupathur, Vellore District',
      'Thirupuvanam',
      'Tiruppur',
      'Thirupuvanam, Thanjavur District',
      'Tiruttani',
      'Tiruvannamalai',
      'Thiruthangal',
      'Tiruvethipuram',
      'Thiruthuraipoondi',
      'Tittacheri',
      'Thiruvaiyaru',
      'Tittakudi',
      'Thiruvalam',
      'TNPL Pugalur',
      'Thiruvallur',
      'Tuticorin',
      'Thiruvarur',
      'TVS Plant Hosur',
      'Thiruvattar',
      'Udangudi',
      'Thiruvenkadam',
      'Udayarpalayam',
      'Thiruvennainallur',
      'Udumalaipettai',
      'Thiruverumbur',
      'Ullur',
      'Thiruvidaimarudur',
      'Ulundurpet',
      'Thiruvithancode',
      'Unjalur',
      'Thisayanvilai',
      'Unnamalaikadai',
      'Thondamuthur',
      'Uppidamangalam',
      'Thondi',
      'Uppiliapuram',
      'Thookkanaikampalayam',
      'Usilampatti',
      'Thorapadi',
      'Uthamapalayam',
      'Thorapadi, Cuddalore District',
      'Uthangarai',
      'Uthayendram',
      'Thottiyam',
      'Uthiramerur',
      'Thuraiyur',
      'Uthukkottai',
      'Thuthipattu',
      'Uthukuli',
      'Thuvakudi',
      'V Pudupatti',
      'Timiri',
      'V Pudur',
      'Tindivanam',
      'Vadakarai Keezhpadugai',
      'Tiruchendur',
      'Vadakkanandal',
      'Tiruchengode',
      'Vadakkuvalliyur',
      'Tiruchirappalli',
      'Vadalur',
      'Tirukalukundram',
      'Vadamadurai',
      'Vadapudupatti',
      'Vaddakkankulam',
      'Yercaud',
      'Zamin Uthukuli'
    ]
  },
  {
    code: 'Telangana',
    name: 'Telangana',
    stateCode: 'TS',
    cities: [
      'Achampet',
      'Adilabad',
      'Alampur',
      'Allipur',
      'Ananthapuram',
      'Anjani Portland Cement Factory',
      'APIIC Polepally SEZ',
      'Armur',
      'Ashwaraopet',
      'Asifabad',
      'Atmakur Mahbubnagar District',
      'Ballepalle',
      'Banswada ',
      'Bellampalle',
      'Bhadrachalam',
      'Bhainsa',
      'Bheema Cements Limited',
      'Bhimaram',
      'Bhimaram Warangal District',
      'Bhongir',
      'Bhupalpalle Area',
      'Bibinagar',
      'Bodhan',
      'Boyapalle',
      'Chandur',
      'Chanukya Cement  Factory',
      'Chatakonda',
      'Chegunta',
      'Chelpur',
      'Chennur Adilabad District',
      'Chinnachitakunta',
      'Chitkul',
      'Chityala',
      'Choppadandi',
      'Choutuppal',
      'Chunchupalle',
      'Dasnapur',
      'Deccan Cement',
      'Devapur',
      'Devarakonda',
      'Devarkadra',
      'Dharmapuri',
      'Dharmaram',
      'Dharmaram Karimnagar District',
      'Dharmaram Warangal District',
      'Dornakal',
      'Eddumailaram',
      'Factory',
      'Farooqnagar',
      'Gadwal',
      'Gajwel',
      'Ghanpur',
      'Ghanpur Warangal District',
      'Gorrekunta',
      'Gurralapadu Industrial area',
      'Haliya',
      'Hanwada',
      'Husnabad',
      'Huzur Nagar',
      'Huzurabad',
      'Hyderabad',
      'Ibrahimpatnam  Rangareddy District',
      'Ichoda',
      'Ieeja',
      'Isnapur',
      'Jadcherla',
      'Jagtial',
      'Jainoor ',
      'Jallaram',
      'Jammikunta',
      'Jangaon',
      'Jannaram',
      'Jogipet',
      'Kaddam Peddur',
      'Kadipikonda',
      'Kagaznagar',
      'Kalwakurthy',
      'Kamalapuram',
      'Kamalapuram Karimnagar District',
      'Kamareddy',
      'Karimnagar',
      'Kasipet',
      'Khammam',
      'Khanapur',
      'Kisan Nagar Industrial  Area',
      'Kodad',
      'Kodangal',
      'Kondamallapalle',
      'Koratla',
      'Kosigi',
      'Kothagudem',
      'Kothakota',
      'Kothur',
      'Kyathampalle',
      'Laxmidevipalle',
      'Luxettipet',
      'Madaram',
      'Madhira',
      'Madikonda',
      'Mahabubabad ',
      'Mahbubnagar',
      'Maheswaram Industrial Area',
      'Mamda',
      'Mamnoor',
      'Mancherial',
      'Mandamarri',
      'Manugur',
      'Medak',
      'Medipalle',
      'Metapally',
      'Miryalaguda',
      'Muhammadabad',
      'Mulugu',
      'Nagarjuna Sagar',
      'Nagarkurnool',
      'Nakrekal',
      'Nalgonda',
      'Narayanapuram',
      'Narayankhed',
      'Narayanpet',
      'Narsampet',
      'Narsapur',
      'Naspur',
      'Navandgi',
      'Nirmal',
      'Nizamabad',
      'Palakurthy',
      'Palwancha',
      'Pargi',
      'Peddapalle',
      'Perur',
      'Pochampalle',
      'Pothreddipalle',
      'Raasi Cement Factory Wazirabad',
      'Raghunathpur',
      'Ramagundam',
      'Ramannapeta',
      'Ramapuram',
      'Ratnapur',
      'Ratnapur Medak District',
      'Rudraram',
      'Rudraram Industrial Area',
      'Sadasivpet',
      'Sangareddy',
      'Sarangapur',
      'Sarapaka',
      'Sathupally',
      'Secunderabad',
      'Shamshabad',
      'Shankarampet',
      'Shivunipalle',
      'Siddipet',
      'Singapuram',
      'Sircilla',
      'Soanpet',
      'Sri Vishnu Cement Limited Dondapadu',
      'Suryapet',
      'Tallada',
      'Tandur',
      'Teegalpahad',
      'Thallapalle',
      'Thimmapur',
      'Thorrur',
      'Turkapally',
      'Utnur',
      'Vatwarlapalle',
      'Vemulawada',
      'Vicarabad',
      'Wanaparthy',
      'Warangal',
      'Warangal Industrial Area',
      'Yadagirigutta',
      'Yellandu',
      'Yellareddy',
      'Yenugonda',
      'Yerrabalem',
      'Zahirabad'
    ]
  },
  {
    code: 'Tripura',
    name: 'Tripura',
    stateCode: 'TR',
    cities: [
      'Agartala',
      'Amarpur',
      'Ambassa',
      'Anandanagar',
      'Badharghat',
      'Belonia',
      'Briddhanagar',
      'Dharmanagar',
      'Fatikroy',
      'Gakulnagar',
      'Gakulpur',
      'Gandhigram',
      'Indranagar',
      'Jogendranagar',
      'Kailasahar',
      'Kalachhari',
      'Kamalpur',
      'Kanchanpur',
      'Khowai',
      'Kumarghat',
      'Kunjaban',
      'Madhupur',
      'Manu',
      'Matarbari',
      'Narsingarh',
      'Panisagar',
      'Ranirbazar',
      'Sabroom',
      'Santirbazar',
      'Sonamura',
      'Taranagar',
      'Teliamura',
      'Udaipur'
    ]
  },
  {
    code: 'Uttar Pradesh',
    name: 'Uttar Pradesh',
    stateCode: 'UP',
    cities: [
      'Abupur',
      'Achalganj',
      'Achhalda',
      'Achhnera',
      'Adari',
      'Afzalgarh',
      'Agarwal Mandi',
      'Agra',
      'Agro Park Karkhiyon',
      'Ahraura',
      'Ailum',
      'Air Force Area',
      'Ajhuwa',
      'Akbarpur',
      'Akbarpur Near Kanpur',
      'Aliganj',
      'Aligarh',
      'Allahabad',
      'Allahganj',
      'Allapur',
      'Amanpur',
      'Ambehta',
      'Amethi',
      'Amethi Sultanpur District',
      'Amila',
      'Amilo',
      'Aminagar Sarai',
      'Amraudha',
      'Anandnagar',
      'Anpara',
      'Antu',
      'Anupshahr',
      'Anurudhpur Purab Patti',
      'Aonla',
      'Artauni',
      'Ashrafpur Kichhauchha',
      'Atarra',
      'Atasu',
      'Atrauli',
      'Atraulia',
      'Auraiya',
      'Aurangabad',
      'Auras',
      'Awagarh',
      'Ayodhya',
      'Azamgarh',
      'Azmatgarh',
      'Babarpur Ajitmal',
      'Baberu',
      'Babina',
      'Babrala',
      'Babugarh',
      'Bachhgaon',
      'Bachhraon',
      'Bachhrawan',
      'Bad',
      'Badaun Industrial Area',
      'Baghpat',
      'Bah',
      'Bahadurganj',
      'Baheri',
      'Bahjoi',
      'Bahraich',
      'Bahsuma',
      'Bahuwa',
      'Bajna',
      'Bakewar',
      'Bakiabad',
      'Baksar',
      'Bakshi Ka Talab',
      'Baldeo',
      'Ballia',
      'Balrampur',
      'Banat',
      'Banda',
      'Bangarmau',
      'Banguwan Kalan',
      'Banjarepur',
      'Bansdih',
      'Bansgaon',
      'Bansi',
      'Bara Gaon',
      'Barabanki',
      'Barabanki Industrial Area',
      'Baragaon',
      'Barahatir Jagdishpur',
      'Baraut',
      'Bareilly',
      'Barhalganj',
      'Barhani Bazar',
      'Barkhera',
      'Baroun',
      'Barsana',
      'Barua Sagar',
      'Barwar',
      'Barwara Mazra',
      'Basantpur Saitli',
      'Basta',
      'Basti',
      'Beelna',
      'Behat',
      'Behta Hajipur',
      'Bela Pratapgarh',
      'Belthara Road',
      'Beniganj',
      'Benipur',
      'Beswan',
      'Bewar',
      'Bhabnan Bazar',
      'Bhadarsa',
      'Bhadohi',
      'Bhadohi Industrial Area',
      'Bhagwant Nagar',
      'Bharatganj',
      'Bhargain',
      'Bharthana',
      'Bharuhana',
      'Bharwari',
      'Bhatni Bazar',
      'Bhatpar Rani',
      'Bhawan Bahadur Nagar',
      'Bhinga',
      'Bhogaon',
      'Bhojpur Dharampur',
      'Bhokarhedi',
      'Bhulepur',
      'Bidhuna',
      'Bighapur',
      'Bihka',
      'Bijauli Industrial Area',
      'Bijnor',
      'Bijpur',
      'Bikapur',
      'Bilari',
      'Bilariaganj',
      'Bilaspur',
      'Bilaspur, Gautam Buddha Nagar District',
      'Bilgram',
      'Bilhaur',
      'Bilram',
      'Bilsanda',
      'Bilsi',
      'Bindki',
      'Birbhanpur',
      'Bisalpur',
      'Bisanda Buzurg',
      'Bisauli',
      'Bisharatganj',
      'Bishunipur',
      'Biswan',
      'Bithoor',
      'Budaun',
      'Budhana',
      'Bugrasi',
      'Bulandshahr',
      'Chail',
      'Chakia',
      'Chakmano',
      'Chandauli',
      'Chandausi',
      'Chandpur',
      'Charkhari',
      'Charthaval',
      'Chaumuhan',
      'Chaurhat',
      'Chhaprauli',
      'Chharra Rafatpur',
      'Chhata',
      'Chhatari',
      'Chhibramau',
      'Chhitauni',
      'Chhutmalpur',
      'Chilkana Sultanpur',
      'Chirgaon',
      'Chitbara Gaon',
      'Chitrakoot',
      'Chopan',
      'Choubepur Kalan',
      'Chunar',
      'Churk Ghurma',
      'Colonelganj',
      'Dadri',
      'Dalmau',
      'Dankaur',
      'Dariyabad',
      'Dasna',
      'Dataganj',
      'Daurala',
      'Debai',
      'Deoband',
      'Deoranian',
      'Deori Singhpura',
      'Deoria',
      'Deosaini',
      'Derapur',
      'Devinagar',
      'Dewa',
      'Dhakauli',
      'Dhampur',
      'Dhanauha',
      'Dhanaura',
      'Dharoti Khurd',
      'Dhaura Tanda',
      'Dhaurahara',
      'Dibiyapur',
      'Dildarnagar Fatehpur Bazar',
      'Dindaspur',
      'Dindaspur Chandauli District',
      'Doghat',
      'Dohrighat',
      'Domariyaganj',
      'Dostpur',
      'Dudhi',
      'Dulhipur',
      'Ekdil',
      'Erich',
      'Etah',
      'Etah Indutrial Area',
      'Etawah',
      'Etmadpur',
      'Faizabad',
      'Faizganj',
      'Farah',
      'Faridnagar',
      'Faridpur, Bareilly District',
      'Fariha',
      'Farrukhabad',
      'Fatehabad',
      'Fatehganj Pashchimi',
      'Fatehganj Purvi',
      'Fatehgarh',
      'Fatehpur',
      'Fatehpur Chaurasi',
      'Fatehpur Sikri Fatehpur, Barabanki District',
      'Fazi Nagar',
      'Firozabad',
      'Firozabad UPSIDC',
      'Gabhana',
      'Gadhi',
      'Gagalhedi Must',
      'Gajraula',
      'Gangapur',
      'Gangiri',
      'Gangoh',
      'Ganj Dundwara',
      'Ganj Muradabad',
      'Ganwaria Tulsipur',
      'Garauri',
      'Garautha',
      'Garhi Pukhta',
      'Garhmukteshwar',
      'Gaura',
      'Gaura Barhaj',
      'Gaura Kala',
      'Gauri Bazar',
      'Gausganj',
      'Gawan',
      'Ghatampur',
      'Ghaziabad',
      'Ghazipur',
      'Ghiraur',
      'Ghorawal',
      'Ghosi',
      'Ghosia Bazar',
      'Ghughuli',
      'GIDA Gorakhpur',
      'Gird Baragaon',
      'Gohand',
      'Gokul',
      'Gola Bazar',
      'Gola Gokarannath',
      'Gonda',
      'Gopamau',
      'Gopi Ganj',
      'Gorakhpur',
      'Gosainganj',
      'Gosainganj, Lucknow District',
      'Got',
      'Govardhan',
      'Greater Noida',
      'Gulariya',
      'Gunnaur',
      'Gursahaiganj',
      'Gursarai',
      'Gyanpur',
      'Hafiz Ganj',
      'Haidergarh',
      'Haldaur',
      'Hallaur',
      'Hamirpur',
      'Handia',
      'Hapur',
      'Haqiqatpur',
      'Hardoi',
      'Harduaganj',
      'Hargaon',
      'Hariharpur',
      'Hariyawan',
      'Harraiya',
      'Hasanpur',
      'Hasayan',
      'Hastinapur',
      'Hata',
      'Hathgram',
      'Hathras',
      'Hyderabad',
      'Ibrahimpur',
      'Iglas',
      'Ikauna',
      'Iltifatganj Bazar',
      'Indian Tehephone Industry Mankapur',
      'Indian Telephone Industry',
      'Islamnagar',
      'Itaunja',
      'Itwa',
      'Jafarabad',
      'Jagner',
      'Jahanabad',
      'Jahangirabad',
      'Jahangirpur',
      'Jais',
      'Jaithara',
      'Jalalabad',
      'Jalalabad, Bijnor District',
      'Jalalabad, Muzaffarnagar District',
      'Jalali',
      'Jalalpur',
      'Jalaun',
      'Jalesar',
      'Jamshila',
      'Jamuhan',
      'Jangipur',
      'Jansath',
      'Jarwal',
      'Jasra',
      'Jasrana',
      'Jaswantnagar',
      'Jatari',
      'Jaunpur',
      'Jewar',
      'Jhalu',
      'Jhansi',
      'Jhinjhak',
      'Jhinjhana',
      'Jiyanpur',
      'Joya',
      'Jugauli',
      'Jyoti Khuria',
      'Kabrai',
      'Kachhauna Patseni',
      'Kachhla',
      'Kachhwa',
      'Kachnar',
      'Kadaura',
      'Kadipur',
      'Kailashpur',
      'Kaimganj',
      'Kairana',
      'Kakari',
      'Kakod',
      'Kakori',
      'Kakrala',
      'Kalinagar',
      'Kalpi',
      'Kamalganj',
      'Kampil',
      'Kanaudia Chamical & Industries Ltd',
      'Kandharpur',
      'Kandhla',
      'Kannauj',
      'Kanpur',
      'Kanth',
      'Kanth, Shahjahanpur District',
      'Kaptanganj',
      'Karari',
      'Karhal',
      'Karnawal',
      'Karwi',
      'Kasba Khanpur',
      'Kasganj',
      'Kasiya',
      'Kataka',
      'Kataria',
      'Katghar Lalganj',
      'Kathaura',
      'Kathera',
      'Katra',
      'Katra Medniganj',
      'Katra, Gonda District',
      'Kaulakha',
      'Kauria ganj',
      'Kemri',
      'Kerakat',
      'Kewalpur',
      'Khadda',
      'Khaga',
      'Khailar',
      'Khair',
      'Khairabad',
      'Khairabad, Mau District',
      'Khalilabad',
      'Khamaria',
      'Khandauli',
      'Khanpur',
      'Kharela',
      'Khargupur',
      'Khariya',
      'Kharkhoda',
      'Khatauli',
      'Khekada',
      'Kheragarh',
      'Kheri',
      'Kheta Sarai',
      'Khudaganj',
      'Khurja',
      'Khutar',
      'Kiraoli',
      'Kiratpur',
      'Kishni',
      'Kishunpur',
      'Kithaur',
      'Koeripur',
      'Konch',
      'Kopaganj',
      'Kora Jahanabad',
      'Koraon',
      'Korwa',
      'Kosi Kalan',
      'Kota',
      'Kota, Sonbhadra District',
      'Kotra',
      'Kotwa',
      'Kotwali',
      'Kulpahar',
      'Kunda District',
      'Kundarki',
      'Kunwargaon',
      'Kuraoli',
      'Kurara',
      'Kursath',
      'Kursath, Hardoi District',
      'Kursi Road Industrial Area',
      'Kurthi Jafarpur',
      'Kushinagar',
      'Kusmara',
      'Laharpur',
      'Lakhimpur',
      'Lakhna',
      'Lal Gopalganj Nindaura',
      'Lalganj',
      'Lalitpur',
      'Lar',
      'Lawar',
      'Leather Park Banthar',
      'Ledwa Mahua',
      'Loni',
      'Lucknow',
      'Machhlishahr',
      'Madhoganj',
      'Madhogarh',
      'Madiya',
      'Maghar',
      'Mahaban',
      'Maharajganj',
      'Mahimapur',
      'Mahmudabad',
      'Mahoba',
      'Maholi',
      'Mahona',
      'Mahrajganj',
      'Mahrajganj, Azamgarh',
      'Mahroni',
      'Mahul Khas',
      'Maigal Ganj',
      'Mailani',
      'Maina Maujpur',
      'Mainpuri',
      'Majhauliraj',
      'Makhanpur',
      'Malhipur',
      'Malihabad',
      'Mallawan',
      'Mandawar',
      'Manikpur',
      'Manikpur Sarhat',
      'Maniyar',
      'Manjhanpur',
      'Mankapur',
      'Marehra',
      'Mariahu',
      'Maswasi',
      'Mataundh',
      'Mathura',
      'Mau Aima',
      'Maudaha',
      'Maunath Bhanjan',
      'Maurawan',
      'Mawana',
      'Meerut',
      'Mehdawal',
      'Mehnagar',
      'Mendu',
      'Middha',
      'Milak',
      'Miranpur',
      'Mirganj',
      'Mirzapur cum Vindhyachal',
      'Misrikh Cum Neemsar',
      'Modinagar',
      'Mogra Badshahpur',
      'Mohammadabad',
      'Mohammadabad, Farrukhabad District',
      'Mohammadi',
      'Mohan',
      'Mohanpur',
      'Mohiuddinpur',
      'Moradabad',
      'Moth',
      'Mubarakpur',
      'Mughalsarai',
      'Muhammadabad',
      'Mundera Bazar',
      'Mundia',
      'Muradnagar',
      'Mursan',
      'Musafirkhana',
      'Muzaffarnagar',
      'Nadigaon',
      'Nagina',
      'Nagram',
      'Nai Bazar',
      'Naini Industrial Area',
      'Najibabad',
      'Nakur',
      'Nanauta',
      'Nandgaon',
      'Nanpara',
      'Naraini',
      'Narauli',
      'Naraura',
      'Naugarh',
      'Naugawan Sadat',
      'Nautanwa',
      'Nawabganj',
      'Nawabganj, Bareilly District',
      'Nawabganj, Unnao District',
      'Nehtaur',
      'Nichlaul',
      'Nidhauli Kalan',
      'Nihal Garh Chak Jangla',
      'Nivi',
      'Niwari',
      'Nizamabad',
      'Noida',
      'Noorpur',
      'Nyoria Husainpur',
      'Nyotini',
      'Obra',
      'Oel Dhakwa',
      'Orai',
      'Orai Industrial Area',
      'Orai Jalaun District',
      'Oran',
      'Pachperwa',
      'Padarathpur',
      'Padrauna',
      'Pahar Ganj',
      'Pahasu',
      'Paintepur',
      'Pakbara',
      'Pali',
      'Pali, Hardoi District',
      'Palia Kalan',
      'Palpur',
      'Parasi',
      'Parichha',
      'Parikshitgarh',
      'Parsadepur',
      'Parsakhera Industrial Area',
      'Parsona',
      'Patadi',
      'Patala',
      'Patiyali',
      'Patti',
      'Pavi Sadakpur',
      'Phalauda',
      'Phaphund',
      'Phulpur',
      'Phulpur, Allahabad District',
      'Pihani',
      'Pilibhit',
      'Pilkhana',
      'Pilkhuwa',
      'Pinahat',
      'Pipalsana Chaudhari',
      'Pipara Dand ',
      'Pipiganj',
      'Pipraich',
      'Piprayli Bujurg',
      'Pipri',
      'Powayan',
      'Pratapgarh City',
      'Pukhrayan',
      'Puranpur',
      'Purdilnagar',
      'Pure Tiwari',
      'Purquazi',
      'Purwa',
      'Qasimpur Power House Colony',
      'Rabupura',
      'Radhakund',
      'Rae Bareli',
      'Raja ka Rampur',
      'Rajapur',
      'Ram Nagar Industrial Area',
      'Ramdaspur Urf Nagal',
      'Ramkola',
      'Ramnagar',
      'Rampur',
      'Rampur Bhawanipur',
      'Rampur Karkhana',
      'Rampur Maniharan',
      'Rampura',
      'Ranipur',
      'Raniyan',
      'Rasra',
      'Rasulabad',
      'Rasulabad Unnao District',
      'Ratanpura',
      'Rath',
      'Rati Ka Nagla Industrial Area',
      'Raya',
      'Renukoot',
      'Reoti',
      'Richha',
      'Risia Bazar',
      'Rithora',
      'Robertsganj',
      'Rori',
      'Rudayan',
      'Rudrapur',
      'Runkata',
      'Rura',
      'Rustamnagar Sahaspur',
      'Sadabad',
      'Sadat',
      'Sadatmasaura',
      'Sadruddin Nagar',
      'Safipur',
      'Sahanpur',
      'Saharanpur',
      'Sahaspur',
      'Sahaswan',
      'Sahatwar',
      'Sahawar',
      'Sahjanwan',
      'Sahpau',
      'Saidpur',
      'Saidpur Khajuria',
      'Saidpur, Budaun District',
      'Sainthal',
      'Saiyad Raja',
      'Sakaldiha',
      'Sakhanu',
      'Sakit',
      'Salarpur',
      'Salempur',
      'Salon',
      'Sambhal',
      'Samdhan',
      'Samthar',
      'Sandi',
      'Sandila',
      'Sandilla',
      'Sapur Banger',
      'Sarai Abdulmalik',
      'Sarai Aquil',
      'Sarai Lahur',
      'Sarai mir',
      'Sarai Mohana',
      'Saraon',
      'Sardhana',
      'Sarila ',
      'Sarnath',
      'Sarsawan',
      'Sarsual District',
      'Sasni',
      'Satiyava',
      'Satrikh',
      'Saunkh',
      'Saurikh',
      'Seohara',
      'Sewalkhas',
      'Sewarhi',
      'Shahabad',
      'Shahabad, Rampur District',
      'Shahganj',
      'Shahi',
      'Shahjahanpur',
      'Shahpur',
      'Shamli',
      'Shamsabad',
      'Shamsabad, Agra District',
      'Shankargarh',
      'Shaoron',
      'Shergarh',
      'Sherkot',
      'Shikarpur',
      'Shikohabad',
      'Shishgarh',
      'Shivli',
      'Shivrajpur',
      'Shohratgarh',
      'Siana',
      'Siddhaur',
      'Sidhauli',
      'Sidhpura',
      'Sikandarabad Industrial Area',
      'Sikanderpur',
      'Sikanderpur, Kannauj',
      'Sikandra',
      'Sikandra Rao',
      'Sikandrabad',
      'Sikindra',
      'Sikri Kalan',
      'Singahi Bhiraura',
      'Sirathu',
      'Sirauli',
      'Sirsa',
      'Sirsaganj ',
      'Sirsi',
      'Sisauli',
      'Siswa Bazar',
      'Sitapur',
      'Soron',
      'Suar',
      'Subeha',
      'Sultanpur',
      'Sumerpur',
      'Suriyawan',
      'Suthana Barsola',
      'Suzabad',
      'Talbehat',
      'Talgram',
      'Tambaur Cum  Ahmadabad',
      'Tanda',
      'Tanda, Rampur District',
      'Tatarpur Lallu',
      'Thakurdwara',
      'Thana Bhawan',
      'Thiriya Nizamat Khan',
      'Tikait Nagar',
      'Tikri',
      'Tikuniya',
      'Tilhar',
      'Tilthi',
      'Tindwari',
      'Tirwaganj',
      'Titron',
      'Tondi Fatehpur',
      'Tronica City',
      'Tulsipur',
      'Tundla',
      'Tundla Kham',
      'Tundla Rly Colony',
      'Ugu',
      'Ujhani',
      'Ujhari',
      'Umarha',
      'Umri',
      'Umri Kalan',
      'Un',
      'Unchahar',
      'Unnao',
      'Usawan',
      'Usehat',
      'Uska Bazar',
      'Utraula',
      'Varanasi',
      'Vijaigarh',
      'Vrindavan',
      'Walidpur',
      'Warhapur',
      'Wazirganj',
      'Zaidpur',
      'Zamania'
    ]
  },
  {
    code: 'Uttarakhand',
    name: 'Uttarakhand',
    stateCode: 'UK',
    cities: [
      'Almora',
      'Auli',
      'Badrinath',
      'Bageshwar',
      'Bahadarabad',
      'Banbasa',
      'Bandia',
      'Bandiya',
      'Barkot',
      'Bazpur',
      'Begumpur',
      'Berinag',
      'Bhagwanpur',
      'Bhimtaal',
      'Bhowali',
      'Chakrata',
      'Chamba',
      'Chamoli',
      'Champawat',
      'Chaukori',
      'Corbett',
      'Dehradun',
      'Devaprayag',
      'Devaprayag, Garhwal District',
      'Dhanaulti',
      'Dharchula',
      'Dharchula Dehat',
      'Didihat',
      'Dineshpur',
      'Doiwala',
      'Dwarahat',
      'Gadarpur',
      'Gangolihat',
      'Gangotri',
      'Gochar',
      'Gopeshwar',
      'Haldwani',
      'Haridwar',
      'Herbertpur',
      'Jaspur',
      'Jhabrera',
      'Joshimath',
      'Kachnal Gosain',
      'Kaladhungi',
      'Karnaprayag',
      'Kashipur',
      'Kathgodam',
      'Kausani',
      'Kela Khera',
      'Khatima',
      'Kichha',
      'Kirtinagar',
      'Kotdwara',
      'Laksar',
      'Lalkuan',
      'Landaur',
      'Landhaura',
      'Lansdowne',
      'Lohaghat',
      'Mahua Dabra Haripura',
      'Mahua Kheraganj',
      'Manglaur',
      'Mehwar Kalan',
      'Mohanpur Mohammadpur',
      'Mukteshwar',
      'Mussoorie',
      'Nagala Imarti',
      'Nagla',
      'Nainital',
      'Nandprayag',
      'Narendranagar',
      'Natthuwa Wala',
      'New Tehri',
      'Pantnagar',
      'Pauri',
      'Piran Kaliyar',
      'Pithoragarh',
      'Pratitnagar',
      'Raipur',
      'Ramnagar',
      'Ranikhet',
      'Rishikesh',
      'Roorkee',
      'Rudraprayag',
      'Rudrapur',
      'Saidpura',
      'Sara Industrial Estate',
      'Selaqui',
      'Shaktigarh',
      'SIDCUL Haridwar',
      'SIDCUL Kotadwara',
      'Sitarganj',
      'Srinagar',
      'Sultanpur',
      'Tanakpur',
      'Tehri',
      'Uttarkashi',
      'Vikasnagar'
    ]
  },
  {
    code: 'West Bengal',
    name: 'West Bengal',
    stateCode: 'WB',
    cities: [
      'Adra',
      'Ahmadpur',
      'Aiho',
      'Ajodhyanagar',
      'Alipukur',
      'Alipurduar',
      'Alipurduar Rly Jnc',
      'Amarshi Kasba',
      'Ambhua',
      'Amlagora',
      'Amlajora',
      'Amta',
      'Amtala',
      'Anantabati',
      'Andal',
      'Andul',
      'Ankurhati',
      'Anup Nagar',
      'Arambag',
      'Argari',
      'Arjunpur',
      'Arra',
      'Asansol',
      'Ashadtalya',
      'Aurangabad',
      'Badamtam Tea Garden',
      'Badkulla',
      'Baduria',
      'Bagnan',
      'Bagula',
      'Bahirgram',
      'Bahula',
      'Baidyabati',
      'Bakreshwar Thermal Power Township',
      'Baksa',
      'Balarampota',
      'Balarampur',
      'Balarampur, South Twenty Four Parganas District',
      'Balichak',
      'Balihati',
      'Bally',
      'Baluhati',
      'Balurghat',
      'Bamangram',
      'Bamanpukur',
      'Bamna',
      'Bamunari',
      'Ban Harishpur',
      'Banarhat Tea Garden',
      'Bandhail',
      'Bandipur',
      'Bandoan',
      'Baneshwarpur',
      'Baneshwarpur Haora District',
      'Baneswar',
      'Bangalpur',
      'Bangaon',
      'Baniban',
      'Baniban Jagadishpur',
      'Bankra',
      'Bankra North Twenty Four',
      'Bankul',
      'Bankura',
      'Bansberia',
      'Banshra',
      'Banupur',
      'Bara',
      'Bara Jumla',
      'Bara Suzapur',
      'Barabazar',
      'Barasat',
      'Barda',
      'Bargachhia',
      'Bargachhia Hugli District',
      'Barijhati',
      'Barijpur',
      'Barjora',
      'Barkalikapur',
      'Barrackpur',
      'Barrackpur Cantonment',
      'Barua Gopalpur',
      'Baruipara',
      'Baruipur',
      'Barunda',
      'Basantapur',
      'Basanti',
      'Basantia',
      'Basirhat',
      'Baska ',
      'Basudebpur',
      'Basudebpur Purba  Medinipur District',
      'Batika',
      'Batul',
      'Bayarsing',
      'Begampur',
      'Begun Kodar Parganas District',
      'Beldanga',
      'Beldubi',
      'Beliatore',
      'Belumilki',
      'Benudia',
      'Berhampore',
      'Betpuli',
      'Bhabki',
      'Bhadreswar',
      'Bhagabatipur',
      'Bhandar Gachha',
      'Bhandardaha',
      'Bhangar Raghunathpur',
      'Bhangri Pratham Khanda',
      'Bhasa',
      'Bhasaipaikar',
      'Bhatpara',
      'Bhimram',
      'Bholar Dabri',
      'Bidhan Nagar',
      'Bikihakola',
      'Bilandapur',
      'Bilpahari',
      'Bipra Noapara',
      'Bira',
      'Birlapur',
      'Birnagar',
      'Birodhi',
      'Birpara Champagachhi',
      'Birpara, Jalpaiguri District',
      'Bishnupur',
      'Bishnupur Birbhum District',
      'Bishnupur Industrial Growth Centre',
      'Bishnupur, South Twenty Four Parganas District',
      'Bolpur',
      'Bora Gagangohalia',
      'Borai',
      'Bowali',
      'Brindabanpur',
      'Budge Budge',
      'Buita',
      'Burdwan',
      'Cart Road',
      'Chachanda',
      'Chak Alampur',
      'Chak Bankola',
      'Chak Baria',
      'Chak Bhrigu',
      'Chak Enayetnagar',
      'Chak Kanthalia',
      'Chak Kashipur',
      'Chakapara',
      'Chakdaha',
      'Chakiabhita',
      'Chakmeghoan',
      'Chalsa Mahabari',
      'Champahati',
      'Champdani',
      'Chamrail',
      'Chanchal',
      'Chandannagar',
      'Chandapur',
      'Chanddandaha',
      'Chandipur',
      'Chandipur Haora District',
      'Chandpala Anantapathpur',
      'Chandpur',
      'Chandpur South Twenty Four Parganas District',
      'Chandrakona',
      'Chandrapur',
      'Chapari',
      'Chapra',
      'Chaspara',
      'Chaulia',
      'Chechakhata',
      'Chekya',
      'Chhekati',
      'Chhora',
      'Chhota Laukuthi',
      'Chhota Suzapur',
      'Chikanpara',
      'Chikrand',
      'Chinchuria',
      'Chittaranjan',
      'Chong Ghurali',
      'Chongtong Tea Garden',
      'Chopra',
      'Contai',
      'Dafahat',
      'Dakhin Rampur',
      'Dakshin Baguan',
      'Dakshin Chatra',
      'Dakshin Jhapardaha',
      'Dakshin Odlabari',
      'Dakshin Rajyadharpur',
      'Dakshin Raypur',
      'Dakshin Santoshpur',
      'Dalkhola',
      'Dalurband',
      'Danga',
      'Dankuni',
      'Darappur',
      'Darjeeling',
      'Daulatpur',
      'Deara',
      'Debipur',
      'Deora',
      'Deulgram',
      'Deuli',
      'Deulia',
      'Dhakuria',
      'Dhaliabari',
      'Dhamua',
      'Dhandadihi',
      'Dhania',
      'Dhanyakuria',
      'Dharmapur',
      'Dhatrigram',
      'Dhola',
      'Dhuilya',
      'Dhulasimla',
      'Dhulian',
      'Dhunki',
      'Dhupguri',
      'Dhusaripara',
      'Diamond Harbour',
      'Digha',
      'Dighirpar',
      'Dignala',
      'Dihimandalghat',
      'Dinga Khola',
      'Dinhata',
      'Dogachhia',
      'Dogachhia Barddhaman District',
      'Domjur',
      'Dubra',
      'Dubrajpur',
      'Dudhkalmi',
      'Durgapur',
      'Durllabhganj',
      'Egra',
      'Ekabbarpur',
      'Eksara',
      'Erashal',
      'Falakata',
      'Falta Industrial Growth Centre',
      'Farakka Barrage Township',
      'Farakka PTS Township',
      'Fatehpur',
      'Fatellapur',
      'Fatepur',
      'Gabberia',
      'Gairkata',
      'Gangadharpur',
      'Gangarampur',
      'Gangi',
      'Gangnapur',
      'Gangni',
      'Garalgachha',
      'Garbeta',
      'Garden',
      'Garh Kamalpur',
      'Garshyamnagar',
      'Garulia',
      'Gaur Daha',
      'Geni',
      'Ghatal',
      'Ghola Noapara',
      'Ghoraberia',
      'Ghutgarya',
      'Ging Tea Garden',
      'Giria',
      'Goasafat',
      'Gobardanga',
      'Gobindapur',
      'Gopalpur',
      'Guskara',
      'Habra',
      'Haldia',
      'Haldibari',
      'Halisahar',
      'Halyan',
      'Hanskunda',
      'Hanspukuria',
      'Haora',
      'Harharia Chak',
      'Harinadibhastsala',
      'Harindanga',
      'Haringhata Farm',
      'Haripur',
      'Harirampur',
      'Harishpur',
      'Hasimnagar',
      'Hatgachha',
      'Hatsimla',
      'Hijuli',
      'Hincha Gerya',
      'Hindusthan Cables Town',
      'Hingalganj',
      'Hirapur',
      'Hugli-Chinsurah',
      'Hutmura',
      'Ichhapur Defence Estate',
      'Ichhlampur',
      'Ilambazar',
      'Islampur',
      'Itahar',
      'Itinda',
      'Jadupur',
      'Jafarpur',
      'Jagadanandapur',
      'Jagadishpur',
      'Jagatballavpur',
      'Jagatnagar',
      'Jagijhora Barabak',
      'Jagtaj',
      'Jala Kendua',
      'Jallabad',
      'Jalpaiguri',
      'Jaluidanga',
      'Jamuria',
      'Janai',
      'Jangalpara',
      'Jangalpara, Hugli District',
      'Jangipur',
      'Jateshwar',
      'Jaygaon',
      'Jaykrishnapur',
      'Jaynagar Mazilpur',
      'Jaypur',
      'Jemari',
      'Jetia',
      'Jhalda',
      'Jhanti Pahari',
      'Jhargram',
      'Jhorhat',
      'Jiaganj Azimganj',
      'Jirat',
      'Joka',
      'Joypul',
      'Jujarsaha',
      'Kachu Pukur',
      'Kaijuri',
      'Kajora',
      'Kakdihi',
      'Kakramari',
      'Kalara',
      'Kalaria',
      'Kalas North',
      'Kaliaganj',
      'Kalikapota',
      'Kalikapur',
      'Kalimpong',
      'Kalipur',
      'Kalipur Barddhaman District',
      'Kalna',
      'Kalyani',
      'Kalyanpur',
      'Kamalapur',
      'Kamat Phulbari',
      'Kanaipur',
      'Kanaipur Haora District',
      'Kanchrapara',
      'Kanganbaria',
      'Kanki',
      'Kankuria',
      'Kanpur',
      'Kantaberia',
      'Kantlia',
      'Kanyanagar',
      'Kapashanria',
      'Karari Chandpur',
      'Karia',
      'Karidhya',
      'Karimpur',
      'Kashimnagar',
      'Katwa',
      'Kaugachhi',
      'Kenda',
      'Kendra Khottamdi',
      'Kendua',
      'Kesabpur',
      'Khajutti',
      'Khalia',
      'Khalor',
      'Khandra',
      'Khanpur',
      'Khantora',
      'Kharagpur',
      'Kharar',
      'Khardaha',
      'Khardaha Haora District',
      'Kharibari',
      'Kharsarai',
      'Khatra',
      'Khidirpur',
      'Khidirpur, Murshidabad District',
      'Khorddabamonia',
      'Koch Bihar',
      'Kodalia',
      'Kokapur',
      'Kolaghat',
      'Kolkata',
      'Komarhat',
      'Konardihi',
      'Konnagar',
      'Kotbar',
      'Kotulpur',
      'Koyra',
      'Kriparampur',
      'Krishna Sali',
      'Krishnanagar',
      'Krishnapur',
      'Krishnapur Maldah District',
      'Kshidirpur',
      'Kshirpai',
      'Kulia',
      'Kulihanda',
      'Kulitapara',
      'Kulti',
      'Kurseong',
      'Labhpur',
      'Lagda',
      'Lalpur',
      'Lapara',
      'Laskarpara',
      'Lataguri',
      'Madhyamgram',
      'Madna',
      'Mahadeb Nagar',
      'Mahal',
      'Mahiari',
      'Mahira',
      'Mahishrekha',
      'Mainaguri',
      'Makardaha',
      'Makhal Tala',
      'Malbazar',
      'Malda',
      'Mamrejpur',
      'Manbazar',
      'Mandarbani',
      'Mangalbari',
      'Mangarjung Tea Garden',
      'Manikpur',
      'Manirampur',
      'Mansinhapur',
      'Manushpur',
      'Masat',
      'Masat South Twenty Four',
      'Maslandapur',
      'Mathabhanga',
      'Matialihat',
      'Matiari',
      'Matla',
      'Medinipur',
      'Mejia Thermal Power Township',
      'Mekliganj',
      'Memari',
      'Milki',
      'Minakhan',
      'Mira',
      'Mirdhanga',
      'Mirik',
      'Mirzapur',
      'Mohanpur',
      'Mugkalyan',
      'Muragachha',
      'Murarai',
      'Murshidabad',
      'Murulia',
      'Nababpur',
      'Nabadwip',
      'Nabaghanapur',
      'Nabagram',
      'Nabagram Colony',
      'Nabgram',
      'Nadabhanga',
      'Nagar Changrabandha',
      'Nagdaha',
      'Nahazari',
      'Naihati',
      'Nainan',
      'Naiti',
      'Nalahati',
      'Naldanga',
      'Nalpur',
      'Nandigram',
      'Naridana',
      'Nasaratpur',
      'Nasibpur',
      'Natibpur',
      'Naul',
      'Naupala',
      'Nawapara',
      'Nayabahadurpur',
      'Nebadhai Duttapukur',
      'New Barrackpur',
      'New Town',
      'Nibra',
      'Nischintapur',
      'Nischintapur Paschim Medinipur District',
      'Nokpul',
      'North Barrackpur',
      'Nrisinghapur',
      'Odlabari',
      'Pairagachha',
      'Palashban',
      'Palashi',
      'Panchghara',
      'Panchla',
      'Panchpara',
      'Pandua',
      'Paniara',
      'Panihati',
      'Panuria',
      'Par Patiram',
      'Para',
      'Parangarpar',
      'Paranpara',
      'Parashkol',
      'Parasia',
      'Parganas District',
      'Parota',
      'Paschim Bainan',
      'Paschim Gazipur',
      'Paschim Jitpur',
      'Paschim Panchla',
      'Paschim Punropara',
      'Pashchim Khalna',
      'Patdaha',
      'Patharberia',
      'Patihal',
      'Patuli',
      'Patulia',
      'Phulia',
      'Poali',
      'Podara',
      'Prayagpur',
      'Pujali',
      'Purba Bishnupur',
      'Purbba Narayanpur',
      'Purbba Tajpur',
      'Puruliya',
      'Radhapur',
      'Raghudebbati',
      'Raghunathpur',
      'Raghunathpur, Puruliya District',
      'Raichak',
      'Raigachhi',
      'Raiganj',
      'Raipur',
      'Rajbalhat',
      'Rajnagar',
      'Rajpur Bazar',
      'Rajpur Sonarpur',
      'Ramakantapur',
      'Ramanathpur',
      'Ramchandrapur',
      'Rameswarpur',
      'Ramjibanpur',
      'Ramkrishnapur',
      'Ramnagar',
      'Rampurhat',
      'Ranaghat',
      'Rangabhita',
      'Raniganj',
      'Raninagar Industrial Growth Centre',
      'Raynagar',
      'Rishra',
      'Rongmook Ceder Tea',
      'Ruiya',
      'Sadigachhi',
      'Sahapur',
      'Sahebganj',
      'Sahebpur',
      'Sainthia',
      'Salap',
      'Salar',
      'Samali',
      'Samudragarh',
      'Samuktola',
      'Sangrampur',
      'Sankarpur',
      'Sankrail',
      'Santaldih',
      'Santipur',
      'Santoshpur',
      'Sarenga',
      'Sarpi',
      'Sehara',
      'Serampore',
      'Serpur',
      'Shankara',
      'Shashati',
      'Shilda',
      'Shimulpur',
      'Shyamdhan',
      'Shyampur',
      'Sibnagar',
      'Siduli',
      'Silampur',
      'Siliguri',
      'Simhat',
      'Simla',
      'Simlapal',
      'Singtam Tea Garden',
      'Singur',
      'Sirakol',
      'Sirsha',
      'Sisha-Jumrha',
      'Sobhaganj',
      'Solgohalia',
      'Sonada Khasmahal',
      'Sonamukhi',
      'Sonatikiri',
      'Srimantapur',
      'Sripur',
      'Subarnapur',
      'Sukdal',
      'Sukhiapokhri',
      'Suri',
      'Taki',
      'Talbandha',
      'Taldi',
      'Tamluk',
      'Tarakeswar',
      'Tehatta',
      'Tehatta Nadia District',
      'Teleni Para',
      'Telipara Tea Garden',
      'Tentulkuli',
      'Tisa',
      'Titagarh',
      'Tufanganj',
      'Udang',
      'Ukhra',
      'Uluberia',
      'Uluberia Near Kolkata',
      'Usthi',
      'Uttar Bagdogra',
      'Uttar Bishnupur',
      'Uttar Jhapardaha',
      'Uttar Kamakhyaguri',
      'Uttar Kusum',
      'Uttar Latabari',
      'Uttar Madarihat',
      'Uttar Mahammadpur',
      'Uttar Pirpur',
      'Uttar Raypur',
      'Uttar Satali',
      'Uttarpara Kotrung',
      'WBIIDC Growth Centre Uluberia'
    ]
  },
  { code: 'Overseas', name: 'Overseas', stateCode: 'OV', cities: ['Overseas'] }
];

export const UK_VAT_SCHEME: VatSchemes[] = [
  {
    code: 'Standard Scheme',
    name: 'Standard Scheme'
  },
  {
    code: 'Flat Rate Scheme',
    name: 'Flat Rate Scheme'
  }
];

export const GST_TREATMENT_VALUES: KeyAndValue[] = [
  {
    key: 'REGISTERED_BUSINESS_REGULAR',
    value: 'Registered Business - Regular'
  },
  {
    key: 'REGISTERED_BUSINESS_COMPOSITION',
    value: 'Registered Business - Composition'
  },
  { key: 'UNREGISTERED_BUSINESS', value: 'Unregistered Business' },
  { key: 'CUSTOMER', value: 'Consumer' },
  { key: 'OVERSEAS', value: 'Overseas' },
  { key: 'SPECIAL_ECONOMIC_ZONE', value: 'Special Economic Zone' },
  { key: 'DEEMED_EXPORT', value: 'Deemed Export' }
];

export const CUSTOMER_TYPES_REGISTERED_BUSINESS: KeyAndValue[] = [
  { key: 'NA', value: 'NA' },
  { key: 'SEZ_WO_PAY', value: 'SEZ (WOPAY)' },
  { key: 'SEZ_W_PAY', value: 'SEZ (WPAY)' }
];

export const VENDOR_TYPES_REGISTERED_BUSINESS: KeyAndValue[] = [
  { key: 'NA', value: 'NA' },
  { key: 'SEZ_W_PAY', value: 'SEZ (WPAY)' },
  { key: 'SEZ_WO_PAY', value: 'SEZ (WOPAY)' }
];

export const CUSTOMER_TYPES_REGISTERED_BUSINESS_COMP: KeyAndValue[] = [
  { key: 'NA', value: 'NA' }
];

export const VENDOR_TYPES_REGISTERED_BUSINESS_COMP: KeyAndValue[] = [
  { key: 'NA', value: 'NA' }
];

export const customerTypesSEZ: KeyAndValue[] = [
  { key: 'SEZ_WO_PAY', value: 'SEZ (WOPAY)' },
  { key: 'SEZ_W_PAY', value: 'SEZ (WPAY)' }
];
export const vendorTypesSEZ: KeyAndValue[] = [
  { key: 'SEZ_W_PAY', value: 'SEZ (WPAY)' },
  { key: 'SEZ_WO_PAY', value: 'SEZ (WOPAY)' }
];

export const customerTypesOverseas: KeyAndValue[] = [
  { key: 'EXPORT_WO_PAY', value: 'Export (WOPAY)' },
  { key: 'EXPORT_W_PAY', value: 'Export (WPAY)' }
];
export const vendorTypesOverseas: KeyAndValue[] = [
  { key: 'IMPORT', value: 'Import' }
];

export const CUSTOMER_TAX_TYPES: KeyAndValue[] = [
  { key: 'To Non-VAT Parties', value: 'TAX_CODE_CUSTOMER_01' },
  { key: 'To the Treasurer Collector', value: 'TAX_CODE_CUSTOMER_02' },
  {
    key: 'To The Collector In addition to the Treasurer',
    value: 'TAX_CODE_CUSTOMER_03'
  },
  { key: 'DPP Other Values', value: 'TAX_CODE_CUSTOMER_04' },
  {
    key: 'Other Submissions, including submission to foreign tourists for VAT refunds',
    value: 'TAX_CODE_CUSTOMER_05'
  },
  {
    key: 'Submissions of VAT not collected',
    value: 'TAX_CODE_CUSTOMER_06'
  },
  {
    key: 'Its VAT Transfers are Delivered',
    value: 'TAX_CODE_CUSTOMER_07'
  },
  {
    key: 'Assets Submissions (Article 16D of the VA.....',
    value: 'TAX_CODE_CUSTOMER_08'
  }
];

export const VENDOR_TAX_TYPES: KeyAndValue[] = [
  { key: 'To Parties Not Collecting VAT', value: 'TAX_CODE_VENDOR_01' },
  { key: 'Treasurer Collectorr', value: 'TAX_CODE_VENDOR_02' },
  {
    key: 'To Collectors Other Than Treasurers',
    value: 'TAX_CODE_VENDOR_03'
  },
  { key: 'Other Value DPP', value: 'TAX_CODE_VENDOR_04' },
  {
    key: 'Other Submissions, including submission to foreign tourists in the context of a VAT refund',
    value: 'TAX_CODE_VENDOR_05'
  },
  {
    key: 'Submissions for which VAT is Not Charged',
    value: 'TAX_CODE_VENDOR_06'
  },
  {
    key: 'VAT exempted',
    value: 'TAX_CODE_VENDOR_07'
  },
  {
    key: 'Transfer of Assets (Article 16D of the VAT A...',
    value: 'TAX_CODE_VENDOR_08'
  }
];

export const CUSTOMER_TYPE_MALAYSIA: KeyAndValue[] = [
  {
    key: 'NONE',
    value: 'None'
  },
  { key: 'SPECIAL_AREA', value: 'Special area' },
  {
    key: 'EXPORT',
    value: 'Export'
  },

  {
    key: 'DESIGNATED_AREA',
    value: 'Designated area'
  }
];

export const CUSTOMER_TYPE_MALAYSIA_EXEMPTED: KeyAndValue[] = [
  { key: 'EXEMPTED_UNDER_SCHEDULE_A', value: 'Exempted under Schedule A' },
  { key: 'EXEMPTED_UNDER_SCHEDULE_B', value: 'Exempted under Schedule B' },
  {
    key: 'EXEMPTED_UNDER_SCHEDULE_C1_C2',
    value: 'Exempted under Schedule C1 and C2'
  },
  {
    key: 'EXEMPTED_UNDER_SCHEDULE_C3_C4',
    value: 'Exempted under Schedule C3 and C4'
  },
  { key: 'EXEMPTED_UNDER_SCHEDULE_C5', value: 'Exempted under Schedule C5' }
];

export enum EXEMPTION_CRITERIAL_MALAYSIA_KEY {
  NONE = 'NONE',
  EXEMPTED_ZERO = 'EXEMPTED_ZERO',
  ZERO_RATED = 'ZERO_RATED'
}
export const EXEMPTION_CRITERIAL_MALAYSIA: KeyAndValue[] = [
  {
    key: EXEMPTION_CRITERIAL_MALAYSIA_KEY.NONE,
    value: 'None'
  },
  {
    key: EXEMPTION_CRITERIAL_MALAYSIA_KEY.EXEMPTED_ZERO,
    value: 'Exempted 0%'
  },
  {
    key: EXEMPTION_CRITERIAL_MALAYSIA_KEY.ZERO_RATED,
    value: 'Zero rated 0%'
  }
];

export const VENDOR_TYPE_MALAYSIA: KeyAndValue[] = [
  {
    key: 'NONE',
    value: 'None'
  },
  {
    key: 'SALES_TAX_EXEMPTION',
    value:
      'Purchase / Importation of Raw Material Exempted from Sales Tax- Exemption in Schedule'
  },
  {
    key: 'MANUFACTURED_EXEMPTION',
    value:
      'Purchase / Importation of Raw Material on behalf Registered Manufacturer Exempted from Sales Tax'
  },
  {
    key: 'PERFORMED_EXEMPTION',
    value: 'Value of work performed exempted from Sales'
  }
];
export const TYPE_OF_COMPANY: KeyAndValue[] = [
  {
    key: 'INDEPENDENT',
    value: 'Independent'
  },
  {
    key: 'COMPANY',
    value: 'Company'
  },
  {
    key: 'A_RESIDENT_OF_THE_PALESTINIAN_AUTHORITY',
    value: 'A resident of the Palestinian Authority'
  },
  {
    key: 'AUTHORIZED_DEALER',
    value: 'Authorized Dealer'
  },
  {
    key: 'FOREIGN_RESIDENT',
    value: 'Foreign Resident'
  },
  {
    key: 'INTERNATIONAL_COMPANY',
    value: 'International Company'
  }
];
export const ASSESSING_OFFICER_TYPES: KeyAndValue[] = [
  {
    key: 'ASSESSING_OFFICER_NUMBER_01',
    value: 'Tiberias'
  },
  {
    key: 'ASSESSING_OFFICER_NUMBER_02',
    value: 'Afula'
  },
  { key: 'ASSESSING_OFFICER_NUMBER_04', value: 'Safed' },
  { key: 'ASSESSING_OFFICER_NUMBER_05', value: 'Nazareth' },
  { key: 'ASSESSING_OFFICER_NUMBER_06', value: 'Nahariya' },
  { key: 'ASSESSING_OFFICER_NUMBER_07', value: 'Acre' },
  { key: 'ASSESSING_OFFICER_NUMBER_10', value: 'Haifa' },
  { key: 'ASSESSING_OFFICER_NUMBER_17', value: 'Hadera' },
  { key: 'ASSESSING_OFFICER_NUMBER_21', value: 'Netanya' },
  { key: 'ASSESSING_OFFICER_NUMBER_23', value: 'Kfar Saba' },
  { key: 'ASSESSING_OFFICER_NUMBER_24', value: 'Petah Tikva' },
  { key: 'ASSESSING_OFFICER_NUMBER_25', value: 'Ramla' },
  { key: 'ASSESSING_OFFICER_NUMBER_26', value: 'Streets' },
  { key: 'ASSESSING_OFFICER_NUMBER_30', value: 'Tel Aviv 5' },
  { key: 'ASSESSING_OFFICER_NUMBER_31', value: 'Tel Aviv 1' },
  { key: 'ASSESSING_OFFICER_NUMBER_22', value: 'Holon' },
  { key: 'ASSESSING_OFFICER_NUMBER_34', value: 'Tel Aviv 4' },
  { key: 'ASSESSING_OFFICER_NUMBER_35', value: 'Tel Aviv 5' },
  { key: 'ASSESSING_OFFICER_NUMBER_37', value: 'Pashmg' },
  { key: 'ASSESSING_OFFICER_NUMBER_38', value: 'Tel Aviv 3' },
  { key: 'ASSESSING_OFFICER_NUMBER_39', value: 'Gush Dan' },
  { key: 'ASSESSING_OFFICER_NUMBER_41', value: 'Jerusalem 1' },
  { key: 'ASSESSING_OFFICER_NUMBER_50', value: 'Eilat' },
  { key: 'ASSESSING_OFFICER_NUMBER_51', value: 'Ashkelon' },
  { key: 'ASSESSING_OFFICER_NUMBER_52', value: 'Beer Sheva' },
  { key: 'ASSESSING_OFFICER_NUMBER_45', value: 'Jerusalem 2' },
  { key: 'ASSESSING_OFFICER_NUMBER_43', value: 'Jerusalem 3' },
  { key: 'ASSESSING_OFFICER_NUMBER_18', value: 'Ra’anana' }
];

export const TAX_TREATMENT_UAE: KeyAndValue[] = [
  {
    key: 'VAT Registered',
    value: 'VAT Registered'
  },
  {
    key: 'Non VAT Registered',
    value: 'Non VAT Registered'
  },
  {
    key: 'GCC VAT Registered',
    value: 'GCC VAT Registered'
  },
  {
    key: 'GCC Non VAT Registered',
    value: 'GCC Non VAT Registered'
  },
  {
    key: 'Non GCC',
    value: 'Non GCC'
  },
  {
    key: 'VAT Registered-Designated Zone',
    value: 'VAT Registered-Designated Zone'
  },
  {
    key: 'Non VAT Registered-Designated Zone',
    value: 'Non VAT Registered-Designated Zone'
  }
];

export const ACCOUNT_TYPES_COA: KeyAndValue[] = [
  {
    value: 'Bank',
    key: '407255'
  },
  {
    value: 'Cash',
    key: '407254'
  },
  {
    value: 'Current Assets',
    key: '407253'
  },
  {
    value: 'Current Liabilities',
    key: '407258'
  },
  {
    value: 'Long Term Assets',
    key: '407256'
  },
  {
    value: 'Long Term Liabilities',
    key: '407259'
  },
  {
    value: 'Other Current Assets',
    key: '407257'
  },
  {
    value: 'Other Current Liabilities',
    key: '407260'
  }
];

export const TAX_TYPES_COA: KeyAndValue[] = [
  {
    value: 'Purchase Tax',
    key: 'TX-0000006'
  },
  {
    value: 'GST 0%',
    key: 'TX-0000001'
  },
  {
    value: 'GST 5%',
    key: 'TX-0000002'
  },
  {
    value: 'GST 12%',
    key: 'TX-0000003'
  },
  {
    value: 'GST 18%',
    key: 'TX-0000004'
  },
  {
    value: 'GST 28%',
    key: 'TX-0000005'
  }
];
export const NIP_TYPES_COA: KeyAndValue[] = [
  {
    key: 'Nature of Income Payment',
    value: 'Nature of Income Payment'
  },
  {
    key: 'Payment / credit of Income from Securities to Foreign Institutional Investors',
    value:
      'Payment / credit of Income from Securities to Foreign Institutional Investors'
  },
  {
    key: 'Payment / Credit of Interest of Foreign Currency Bonds or GDR (including LTCG on Transfer of such Bonds)',
    value:
      'Payment / Credit of Interest of Foreign Currency Bonds or GDR (including LTCG on Transfer of such Bonds)'
  },
  {
    key: 'Payment/credit of Income from Units(Including LTCG on Transfer of such units) to an offshore fund',
    value:
      'Payment/credit of Income from Units(Including LTCG on Transfer of such units) to an offshore fund'
  },
  {
    key: 'Income by way of Interest payable by Government / Indian Concern on Money Borrowed or Debt Incurred in Foreign currency',
    value:
      'Income by way of Interest payable by Government / Indian Concern on Money Borrowed or Debt Incurred in Foreign currency'
  },
  {
    key: 'Any other LTCG not covered u/s 10(33)',
    value: 'Any other LTCG not covered u/s 10(33)'
  },
  {
    key: 'Fees for Technical services',
    value: 'Fees for Technical services'
  },
  {
    key: 'STCG u/s 111A',
    value: 'STCG u/s 111A'
  },
  {
    key: 'Royalty',
    value: 'Royalty'
  },
  {
    key: 'Income of Foreign exchange assets payable to an Indian Citizen',
    value: 'Income of Foreign exchange assets payable to an Indian Citizen'
  },
  {
    key: 'Income by way of LTCG referred to u/s 115E or 112(1)(c ) (iii)',
    value: 'Income by way of LTCG referred to u/s 115E or 112(1)(c ) (iii)'
  },
  {
    key: 'Interest on Rupee denominated bond of an Indian company / Government security',
    value:
      'Interest on Rupee denominated bond of an Indian company / Government security'
  },
  {
    key: 'Payment / Credit of Interest by an Indian Specified Company on Foreign Currency approved loan /Long Term Bonds from outside India',
    value:
      'Payment / Credit of Interest by an Indian Specified Company on Foreign Currency approved loan /Long Term Bonds from outside India'
  },
  {
    key: 'Payment in respect of Investment in Securitisation Trust specified u/s 115TCA',
    value:
      'Payment in respect of Investment in Securitisation Trust specified u/s 115TCA'
  },
  {
    key: 'Income in respect of investment in Securitisation Fund : Payment or Credit to Individual / HUF',
    value:
      'Income in respect of investment in Securitisation Fund : Payment or Credit to Individual / HUF'
  },
  {
    key: 'Income in respect of investment in Securitisation Fund : Payment or Credit to any person other than an Individual / HUF',
    value:
      'Income in respect of investment in Securitisation Fund : Payment or Credit to any person other than an Individual / HUF'
  },
  {
    key: 'Payment in respect of units of Investment Fund specified u/s 115UB',
    value: 'Payment in respect of units of Investment Fund specified u/s 115UB'
  },
  {
    key: 'Payment of the nature referred to u/s 10(23FCA) by Business Trust to Unit Holders',
    value:
      'Payment of the nature referred to u/s 10(23FCA) by Business Trust to Unit Holders'
  },
  {
    key: 'Payment of the nature referred to u/s 10(23FC) by Business Trust to Unit Holders',
    value:
      'Payment of the nature referred to u/s 10(23FC) by Business Trust to Unit Holders'
  },
  {
    key: 'Payment of the nature specified u/s 10(23FC) / 10(23FCA) by Business Trust to Resident  Unit Holders',
    value:
      'Payment of the nature specified u/s 10(23FC) / 10(23FCA) by Business Trust to Resident  Unit Holders'
  },
  {
    key: 'Payment or Credit by way of Interest by Infrastructure Debt Fund',
    value: 'Payment or Credit by way of Interest by Infrastructure Debt Fund'
  },
  {
    key: 'Compensation on Acquisition of certain Immovable Property',
    value: 'Compensation on Acquisition of certain Immovable Property'
  },
  {
    key: 'Professional Fees',
    value: 'Professional Fees'
  },
  {
    key: 'Payment / Credit of Consideration to Resident Transferer forTransfer of Immovable Property other than Agricultural Land',
    value:
      'Payment / Credit of Consideration to Resident Transferer forTransfer of Immovable Property other than Agricultural Land'
  },
  {
    key: 'Rent - Rent on Plant & Machinery',
    value: 'Rent - Rent on Plant & Machinery'
  },
  {
    key: 'Rent - Rent on Land /Building /Furniture / Fitting',
    value: 'Rent - Rent on Land /Building /Furniture / Fitting'
  },
  {
    key: 'Commission or Brokerage',
    value: 'Commission or Brokerage'
  },
  {
    key: 'Commission on Sale of Lottery Tickets',
    value: 'Commission on Sale of Lottery Tickets'
  },
  {
    key: 'Payment on account of Re-purchase of Unit by Mutual Fund or UTI',
    value: 'Payment on account of Re-purchase of Unit by Mutual Fund or UTI'
  },
  {
    key: 'Payment in respect of Deposit under NSS',
    value: 'Payment in respect of Deposit under NSS'
  },
  {
    key: 'Payment to Non Resident Foreign Citizen Sportsman/Entertainer or Non Resident Sports Association',
    value:
      'Payment to Non Resident Foreign Citizen Sportsman/Entertainer or Non Resident Sports Association'
  },
  {
    key: 'Payment in respect of Life Insurance Policy',
    value: 'Payment in respect of Life Insurance Policy'
  },
  {
    key: 'Insurance Commission',
    value: 'Insurance Commission'
  },
  {
    key: 'Payment or Credit to Contractor / Sub-Contractor',
    value: 'Payment or Credit to Contractor / Sub-Contractor'
  },
  {
    key: 'Winnings from Horse Races',
    value: 'Winnings from Horse Races'
  },
  {
    key: 'Winnings from Lotteries',
    value: 'Winnings from Lotteries'
  },
  {
    key: 'Interest other than "Interest on Securities"',
    value: 'Interest other than "Interest on Securities"'
  },
  {
    key: 'Deemed Dividend u/s 2(22)(e )',
    value: 'Deemed Dividend u/s 2(22)(e )'
  },
  {
    key: 'Interest on Securities',
    value: 'Interest on Securities'
  },
  {
    key: 'Payment of  taxable Accumulated balance of PF',
    value: 'Payment of  taxable Accumulated balance of PF'
  }
];

export const PAYMENT_TYPE: KeyAndValue[] = [
  { key: 'CHEQUE', value: COMMON_CONSTANT.CHEQUE_VALUE },
  { key: 'BANK_TRANSFER', value: COMMON_CONSTANT.BANK_TRANSFER_VALUE },
  { key: 'CARD', value: COMMON_CONSTANT.CARD_VALUE },
  { key: 'OTHERS', value: COMMON_CONSTANT.OTHERS }
];

export const countries: KeyAndValue[] = [
  { key: 'ABU DHABI', value: '1' },
  { key: 'ADMIRALTY ISLANDS', value: '2' },
  { key: 'AFGHANISTAN', value: '3' },
  { key: 'AJMAN', value: '4' },
  { key: 'AL AIN', value: '5' },
  { key: 'ALBANIA', value: '6' },
  { key: 'ALGERIA', value: '7' },
  { key: 'ANDORRA', value: '8' },
  { key: 'ANGOLA', value: '9' },
  { key: 'ANGUILLA', value: '10' },
  { key: 'ANTARTICA', value: '11' },
  { key: 'ANTIGUA', value: '12' },
  { key: 'ARGENTINA', value: '13' },
  { key: 'ARMENIA', value: '14' },
  { key: 'ARUBA', value: '15' },
  { key: 'ASCENSION ISLAND', value: '16' },
  { key: 'AUSTRALIA', value: '17' },
  { key: 'AUSTRIA', value: '18' },
  { key: 'AZERBAIJAN', value: '19' },
  { key: 'BAHAMAS', value: '20' },
  { key: 'BAHRAIN', value: '21' },
  { key: 'BANGLADESH', value: '22' },
  { key: 'BARBADOS', value: '23' },
  { key: 'BELGIUM', value: '24' },
  { key: 'BELIZE', value: '25' },
  { key: 'BENIN', value: '26' },
  { key: 'BERMUDA', value: '27' },
  { key: 'BHUTAN', value: '28' },
  { key: 'BOLIVIA', value: '29' },
  { key: 'BOSNIA/HERZEGOVINA', value: '30' },
  { key: 'BOTSWANA', value: '31' },
  { key: 'BOUVET ISLAND', value: '32' },
  { key: 'BRAZIL', value: '33' },
  { key: 'BRITISH INDIAN OCEAN TERRITORY', value: '34' },
  { key: 'BRUNEI', value: '35' },
  { key: 'BULGARIA', value: '36' },
  { key: 'BURKINA/FASO', value: '37' },
  { key: 'BURUNDI', value: '38' },
  { key: 'BYELORUSSIA', value: '39' },
  { key: 'CAMBODIA', value: '117' },
  { key: 'CAMEROON', value: '40' },
  { key: 'CANADA', value: '41' },
  { key: 'CAPE VERDE', value: '42' },
  { key: 'CAROLINE ISLANDS', value: '43' },
  { key: 'CAYMAN ISLANDS', value: '44' },
  { key: 'CENTRAL AFRICAN REP', value: '45' },
  { key: 'CHAD', value: '46' },
  { key: 'CHILE', value: '47' },
  { key: 'CHINA PEOPLES REP', value: '48' },
  { key: 'CHRISTMAS ISLANDS', value: '49' },
  { key: 'COCOS ISLANDS', value: '50' },
  { key: 'COLOMBIA', value: '51' },
  { key: 'COMORO ISLANDS', value: '53' },
  { key: 'CONGO', value: '54' },
  { key: 'COOK ISLANDS', value: '55' },
  { key: 'COSTA RICA', value: '56' },
  { key: 'CROTIA', value: '57' },
  { key: 'CUBA', value: '58' },
  { key: 'CYPRUS', value: '59' },
  { key: 'CZECH REPUBLIC', value: '60' },
  { key: 'DENMARK', value: '61' },
  { key: 'DJIBOUTI', value: '62' },
  { key: 'DOMINICA', value: '63' },
  { key: 'DOMINICAN REPUBLIC', value: '64' },
  { key: 'DUBAI', value: '65' },
  { key: 'ECUADOR', value: '67' },
  { key: 'EGYPT', value: '68' },
  { key: 'EL SALVADOR', value: '69' },
  { key: 'ENGLAND', value: '70' },
  { key: 'EQUATORIAL GUINEA', value: '71' },
  { key: 'ERITREA', value: '72' },
  { key: 'ESTONIA', value: '73' },
  { key: 'ETHIOPIA', value: '74' },
  { key: 'FAEROE ISLANDS', value: '75' },
  { key: 'FALKLAND ISLANDS', value: '76' },
  { key: 'FIJI', value: '77' },
  { key: 'FINLAND', value: '78' },
  { key: 'FRANCE', value: '79' },
  { key: 'FRENCH GUIANA', value: '80' },
  { key: 'FRENCH POLYNESIA', value: '81' },
  { key: 'FRENCH SOUTHERN TERRITORIES', value: '82' },
  { key: 'FUJAIRAH', value: '83' },
  { key: 'GABON', value: '84' },
  { key: 'GAMBIA', value: '85' },
  { key: 'GEORGIA', value: '86' },
  { key: 'GERMANY', value: '87' },
  { key: 'GHANA', value: '88' },
  { key: 'GIBRALTAR', value: '89' },
  { key: 'GREECE', value: '90' },
  { key: 'GREENLAND', value: '91' },
  { key: 'GRENADA', value: '92' },
  { key: 'GUADELOUPE', value: '93' },
  { key: 'GUAM', value: '94' },
  { key: 'GUATEMALA', value: '95' },
  { key: 'GUINEA', value: '96' },
  { key: 'GUINEA-BISSAU', value: '97' },
  { key: 'GUYANA', value: '98' },
  { key: 'HAITI', value: '99' },
  { key: 'HEARD & MCDONALD ISLANDS', value: '100' },
  { key: 'HONDURAS', value: '101' },
  { key: 'HONG KONG', value: '102' },
  { key: 'HUNGARY', value: '103' },
  { key: 'ICELAND', value: '104' },
  { key: 'INDIA', value: '105' },
  { key: 'INDONESIA', value: '106' },
  { key: 'IRAN', value: '108' },
  { key: 'IRAQ', value: '109' },
  { key: 'IRELAND', value: '110' },
  { key: 'ISRAEL', value: '111' },
  { key: 'ITALY', value: '112' },
  { key: 'IVORY COAST', value: '113' },
  { key: 'JAMAICA', value: '114' },
  { key: 'JAPAN', value: '115' },
  { key: 'JORDAN', value: '116' },
  { key: 'KAZAKHSTAN', value: '118' },
  { key: 'KENYA', value: '119' },
  { key: 'KIRGHIZIA', value: '120' },
  { key: 'KIRIBATI', value: '121' },
  { key: 'KOREA NORTH', value: '122' },
  { key: 'KOREA SOUTH', value: '123' },
  { key: 'KUWAIT', value: '124' },
  { key: 'LAOS', value: '125' },
  { key: 'LATVIA', value: '126' },
  { key: 'LEBANON', value: '127' },
  { key: 'LESOTHO', value: '128' },
  { key: 'LIBERIA', value: '129' },
  { key: 'LIBYA', value: '130' },
  { key: 'LIECHTENSTEIN', value: '131' },
  { key: 'LITHUANIA', value: '132' },
  { key: 'LUXEMBOURG', value: '133' },
  { key: 'MACAO', value: '134' },
  { key: 'MADAGASCAR/MALAGASY', value: '135' },
  { key: 'MALAWI', value: '136' },
  { key: 'MALAYSIA', value: '137' },
  { key: 'MALDIVES', value: '138' },
  { key: 'MALI', value: '139' },
  { key: 'MALTA', value: '140' },
  { key: 'MARSHALL ISLANDS', value: '141' },
  { key: 'MARTINIQUE', value: '142' },
  { key: 'MAURITANIA', value: '143' },
  { key: 'MAURITIUS', value: '144' },
  { key: 'MAYOTTE', value: '145' },
  { key: 'MEXICO', value: '146' },
  { key: 'MICRONESIA', value: '147' },
  { key: 'MvalueWAY ISLAND', value: '148' },
  { key: 'MOLDAVIA', value: '149' },
  { key: 'MONACO', value: '150' },
  { key: 'MONGOLIA', value: '151' },
  { key: 'MONTSERRAT', value: '152' },
  { key: 'MOROCCO', value: '153' },
  { key: 'MOZAMBIQUE', value: '154' },
  { key: 'MYANMAR (BURMA)', value: '155' },
  { key: 'NAMIBIA', value: '156' },
  { key: 'NAURU', value: '158' },
  { key: 'NEPAL', value: '159' },
  { key: 'NETHERLANDS', value: '160' },
  { key: 'NETHERLANDS ANTILLES', value: '161' },
  { key: 'NEW CALEDONIA', value: '163' },
  { key: 'NEW ZEALAND', value: '164' },
  { key: 'NICARAGUA', value: '165' },
  { key: 'NIGER', value: '166' },
  { key: 'NIGERIA', value: '167' },
  { key: 'NIUE', value: '168' },
  { key: 'NORFOLK ISLAND', value: '170' },
  { key: 'NORTHERN IRELAND', value: '171' },
  { key: 'NORTHERN MARIANA ISLANDS', value: '172' },
  { key: 'NORWAY', value: '173' },
  { key: 'OMAN', value: '175' },
  { key: 'PAKISTAN', value: '176' },
  { key: 'PALASTINE', value: '266' },
  { key: 'PALAU', value: '177' },
  { key: 'PANAMA', value: '178' },
  { key: 'PAPUA NEW GUINEA', value: '179' },
  { key: 'PARAGUAY', value: '180' },
  { key: 'PERU', value: '181' },
  { key: 'PHILIPPINES', value: '182' },
  { key: 'PITCAIRN', value: '183' },
  { key: 'POLAND', value: '184' },
  { key: 'PORTUGAL', value: '185' },
  { key: 'PUERTO RICO', value: '186' },
  { key: 'QATAR', value: '187' },
  { key: 'RAS AL KHAIMAH', value: '188' },
  { key: 'REUNION ISLAND', value: '189' },
  { key: 'ROMANIA', value: '190' },
  { key: 'RUSSION FEDERATION', value: '191' },
  { key: 'RWANDA', value: '192' },
  { key: 'SAMOA AMERICAN', value: '193' },
  { key: 'SAMOA WESTERN', value: '194' },
  { key: 'SAN MARINO', value: '195' },
  { key: 'SAO TOME & PRINCIPE', value: '196' },
  { key: 'SAUDI ARABIA', value: '197' },
  { key: 'SCOTLAND', value: '198' },
  { key: 'SENEGAL', value: '199' },
  { key: 'SEYCHELLES', value: '200' },
  { key: 'SHARJAH', value: '201' },
  { key: 'SIERRA LEONE', value: '202' },
  { key: 'SINGAPORE', value: 'SG' },
  { key: 'SLOVAKIA', value: '204' },
  { key: 'SLOVENIA', value: '205' },
  { key: 'SOLOMON ISLANDS', value: '206' },
  { key: 'SOMALIA', value: '207' },
  { key: 'SOUTH AFRICA', value: '208' },
  { key: 'SOUTH GEORGIA', value: '209' },
  { key: 'SOUTH SANDWICH ISLANDS', value: '210' },
  { key: 'SOVIET UNION', value: '211' },
  { key: 'SPAIN', value: '212' },
  { key: 'SRI LANKA', value: '213' },
  { key: 'ST HELENA', value: '214' },
  { key: 'ST KITTS/NEVIS', value: '215' },
  { key: 'ST LUCIA', value: '216' },
  { key: 'ST PIERRE', value: '217' },
  { key: 'ST VINCENT', value: '218' },
  { key: 'SUDAN', value: '219' },
  { key: 'SURINAME', value: '220' },
  { key: 'SVALBARD & JAN MAYEN ISLANDS', value: '221' },
  { key: 'SWAZILAND', value: '222' },
  { key: 'SWEDEN', value: '223' },
  { key: 'SWITZERLAND', value: '224' },
  { key: 'SYRIA', value: '225' },
  { key: 'TAIWAN', value: '226' },
  { key: 'TAJIKSTAN', value: '227' },
  { key: 'TANZANIA', value: '228' },
  { key: 'THAILAND', value: '229' },
  { key: 'TIMOR-LESTE', value: '66' },
  { key: 'TOGO', value: '230' },
  { key: 'TOKELAU ISLANDS', value: '231' },
  { key: 'TONGA', value: '232' },
  { key: 'TRINvalueAD & TOBAGO', value: '233' },
  { key: 'TUNISIA', value: '234' },
  { key: 'TURKEY', value: '235' },
  { key: 'TURKMENISTAN', value: '236' },
  { key: 'TURKS & CAICOS ISLANDS', value: '237' },
  { key: 'TUVALU', value: '238' },
  { key: 'UGANDA', value: '239' },
  { key: 'UKRAINE', value: '240' },
  { key: 'UMM AL QAIWAIN', value: '241' },
  { key: 'UNITED ARAB EMIRATES', value: '242' },
  { key: 'UNITED KINGDOM', value: '243' },
  { key: 'UNITED STATES', value: '244' },
  { key: 'UNITED STATES MINOR OUTLYING ISLANDS', value: '245' },
  { key: 'URUGUAY', value: '246' },
  { key: 'UZBEKISTAN', value: '251' },
  { key: 'VANUATU', value: '252' },
  { key: 'VATICAN CITY', value: '253' },
  { key: 'VENEZUELA', value: '254' },
  { key: 'VIETNAM', value: '255' },
  { key: 'VIRGIN ISLANDS UK', value: '256' },
  { key: 'WAKE ISLAND', value: '257' },
  { key: 'WALES', value: '258' },
  { key: 'WALLIS & FUTUNA ISLAND', value: '259' },
  { key: 'WEST AFRICA', value: '267' },
  { key: 'WESTERN SAHARA', value: '260' },
  { key: 'YEMEN ARAB REPUBLIC', value: '261' },
  { key: 'YUGOSLAVIA', value: '262' },
  { key: 'ZAIRE', value: '263' },
  { key: 'ZAMBIA', value: '264' },
  { key: 'ZIMBABWE', value: '265' }
];

// Additional keys can be added to COUNTRIES_LIST const instead of redeclaring new one

export const COUNTRIES_LIST: CountryInfo[] = [
  {
    name: 'Afghanistan',
    currencyCode: 'AFN',
    code: 'AF',
    dial_code: '+93',
    timezone: 'UTC+04:30'
  },
  {
    name: 'Åland Islands',
    currencyCode: 'EUR',
    code: 'AX',
    dial_code: '+358',
    timezone: 'UTC+02:00'
  },
  {
    name: 'Albania',
    currencyCode: 'ALL',
    code: 'AL',
    dial_code: '+355',
    timezone: 'UTC+01:00'
  },
  {
    name: 'Algeria',
    currencyCode: 'DZD',
    code: 'DZ',
    dial_code: '+213',
    timezone: 'UTC+01:00'
  },
  {
    name: 'American Samoa',
    currencyCode: 'USD',
    code: 'AS',
    dial_code: '+1684',
    timezone: 'GMT-11:00'
  },
  {
    name: 'Andorra',
    currencyCode: 'EUR',
    code: 'AD',
    dial_code: '+376',
    timezone: 'UTC+01:00'
  },
  {
    name: 'Angola',
    currencyCode: 'AOA',
    code: 'AO',
    dial_code: '+244',
    timezone: 'UTC+01:00'
  },
  {
    name: 'Anguilla',
    currencyCode: 'XCD',
    code: 'AI',
    dial_code: '+1264',
    timezone: 'GMT-04:00'
  },
  {
    name: 'Antarctica',
    currencyCode: 'AUD',
    code: 'AQ',
    dial_code: '+672',
    timezone: 'GMT-03:00'
  },
  {
    name: 'Antigua and Barbuda',
    currencyCode: 'XCD',
    code: 'AG',
    dial_code: '+1268',
    timezone: 'GMT-04:00'
  },
  {
    name: 'Argentina',
    currencyCode: 'ARS',
    code: 'AR',
    dial_code: '+54',
    timezone: 'UTC-03:00'
  },
  {
    name: 'Armenia',
    currencyCode: 'AMD',
    code: 'AM',
    dial_code: '+374',
    timezone: 'UTC+04:00'
  },
  {
    name: 'Aruba',
    currencyCode: 'AWG',
    code: 'AW',
    dial_code: '+43',
    timezone: 'UTC-04:00'
  },
  {
    name: 'Australia',
    currencyCode: 'AUD',
    code: 'AU',
    dial_code: '+61',
    timezone: 'UTC+05:00'
  },
  {
    name: 'Austria',
    currencyCode: 'EUR',
    code: 'AT',
    dial_code: '+43',
    timezone: 'UTC+01:00'
  },
  {
    name: 'Azerbaijan',
    currencyCode: 'AZN',
    code: 'AZ',
    dial_code: '+994',
    timezone: 'UTC+04:00'
  },
  {
    name: 'Bahamas',
    currencyCode: 'BSD',
    code: 'BS',
    dial_code: '+1242',
    timezone: 'GMT-05:00'
  },
  {
    name: 'Bahrain',
    currencyCode: 'BHD',
    code: 'BH',
    dial_code: '+973',
    timezone: 'UTC+03:00'
  },
  {
    name: 'Bangladesh',
    currencyCode: 'BDT',
    code: 'BD',
    dial_code: '+880',
    timezone: 'UTC+06:00'
  },
  {
    name: 'Barbados',
    currencyCode: 'BBD',
    code: 'BB',
    dial_code: '+1246',
    timezone: 'GMT-04:00'
  },
  {
    name: 'Belarus',
    currencyCode: 'BYR',
    code: 'BY',
    dial_code: '+238',
    timezone: 'UTC+03:00'
  },
  {
    name: 'Belgium',
    currencyCode: 'EUR',
    code: 'BE',
    dial_code: '+32',
    timezone: 'UTC+01:00'
  },
  {
    name: 'Belize',
    currencyCode: 'BZD',
    code: 'BZ',
    dial_code: '+501',
    timezone: 'GMT-06:00'
  },
  {
    name: 'Benin',
    currencyCode: 'XOF',
    code: 'BJ',
    dial_code: '+229',
    timezone: 'UTC+01:00'
  },
  {
    name: 'Bermuda',
    currencyCode: 'BMD',
    code: 'BM',
    dial_code: '+267',
    timezone: 'UTC-04:00'
  },
  {
    name: 'Bhutan',
    currencyCode: 'BTN',
    code: 'BT',
    dial_code: '+975',
    timezone: 'UTC+06:00'
  },
  {
    name: 'Bolivia',
    currencyCode: 'BOB',
    code: 'BO',
    dial_code: '+246',
    timezone: 'UTC-04:00'
  },
  {
    name: 'Bonaire, Sint Eustatius and Saba',
    currencyCode: 'USD',
    code: 'BQ',
    dial_code: '+5997',
    timezone: 'GMT-04:00'
  },
  {
    name: 'Bosnia and Herzegovina',
    currencyCode: 'BAM',
    code: 'BA',
    dial_code: '+387',
    timezone: 'UTC+01:00'
  },
  {
    name: 'Botswana',
    currencyCode: 'BWP',
    code: 'BW',
    dial_code: '+267',
    timezone: 'UTC+02:00'
  },
  {
    name: 'Bouvet Island',
    currencyCode: 'NOK',
    code: 'BV',
    dial_code: '+',
    timezone: 'UTC+01:00'
  },
  {
    name: 'Brazil',
    currencyCode: 'BRL',
    code: 'BR',
    dial_code: '+55',
    timezone: 'UTC-03:00'
  },
  {
    name: 'British Indian Ocean Territory',
    currencyCode: 'USD',
    code: 'IO',
    dial_code: '+246',
    timezone: 'UTC+06:00'
  },
  {
    name: 'United States Minor Outlying Islands',
    currencyCode: 'USD',
    code: 'UM',
    dial_code: '+',
    timezone: 'GMT-11:00'
  },
  {
    name: 'Uruguay',
    currencyCode: 'UYU',
    code: 'UY',
    dial_code: '+47',
    timezone: 'UTC-03:00'
  },
  {
    name: 'Virgin Islands (U.S.)',
    currencyCode: 'USD',
    code: 'VI',
    dial_code: '+1 340',
    timezone: 'GMT-04:00'
  },
  {
    name: 'Brunei Darussalam',
    currencyCode: 'BND',
    code: 'BN',
    dial_code: '+673',
    timezone: 'UTC+08:00'
  },
  {
    name: 'Bulgaria',
    currencyCode: 'BGN',
    code: 'BG',
    dial_code: '+359',
    timezone: 'UTC+02:00'
  },
  {
    name: 'Burkina Faso',
    currencyCode: 'XOF',
    code: 'BF',
    dial_code: '+226',
    timezone: 'GMT'
  },
  {
    name: 'Burundi',
    currencyCode: 'BIF',
    code: 'BI',
    dial_code: '+257',
    timezone: 'UTC+02:00'
  },
  {
    name: 'Cambodia',
    currencyCode: 'KHR',
    code: 'KH',
    dial_code: '+855',
    timezone: 'UTC+07:00'
  },
  {
    name: 'Cameroon',
    currencyCode: 'XAF',
    code: 'CM',
    dial_code: '+237',
    timezone: 'UTC+01:00'
  },
  {
    name: 'Canada',
    currencyCode: 'CAD',
    code: 'CA',
    dial_code: '+1',
    timezone: 'GMT-08:00'
  },
  {
    name: 'Cabo Verde',
    currencyCode: 'CVE',
    code: 'CV',
    dial_code: '+238',
    timezone: 'GMT-01:00'
  },
  {
    name: 'Cayman Islands',
    currencyCode: 'KYD',
    code: 'KY',
    dial_code: '+1664',
    timezone: 'UTC-05:00'
  },
  {
    name: 'Central African Republic',
    currencyCode: 'XAF',
    code: 'CF',
    dial_code: '+236',
    timezone: 'UTC+01:00'
  },
  {
    name: 'Chad',
    currencyCode: 'XAF',
    code: 'TD',
    dial_code: '+235',
    timezone: 'UTC+01:00'
  },
  {
    name: 'Chile',
    code: 'CL',
    currencyCode: 'CLP',
    dial_code: '+682',
    timezone: 'UTC-04:00'
  },
  {
    name: 'China',
    currencyCode: 'CNY',
    code: 'CN',
    dial_code: '+86',
    timezone: 'UTC+08:00'
  },
  {
    name: 'Christmas Island',
    currencyCode: 'AUD',
    code: 'CX',
    dial_code: '+61',
    timezone: 'UTC+07:00'
  },
  {
    name: 'Cocos (Keeling) Islands',
    currencyCode: 'AUD',
    code: 'CC',
    dial_code: '+61',
    timezone: 'UTC+06:30'
  },
  {
    name: 'Colombia',
    currencyCode: 'COP',
    code: 'CO',
    dial_code: '+57',
    timezone: 'UTC-05:00'
  },
  {
    name: 'Comoros',
    currencyCode: 'KMF',
    code: 'KM',
    dial_code: '+269',
    timezone: 'UTC+03:00'
  },
  {
    name: 'Congo',
    currencyCode: 'XAF',
    code: 'CG',
    dial_code: '+242',
    timezone: 'UTC+01:00'
  },
  {
    name: 'Congo (Democratic Republic of the)',
    currencyCode: 'CDF',
    code: 'CD',
    dial_code: '+243',
    timezone: 'UTC+01:00'
  },
  {
    name: 'Cook Islands',
    currencyCode: 'NZD',
    code: 'CK',
    dial_code: '+242',
    timezone: 'UTC-10:00'
  },
  {
    name: 'Costa Rica',
    currencyCode: 'CRC',
    code: 'CR',
    dial_code: '+506',
    timezone: 'GMT-06:00'
  },
  {
    name: 'Croatia',
    currencyCode: 'HRK',
    code: 'HR',
    dial_code: '+385',
    timezone: 'UTC+01:00'
  },
  {
    name: 'Cuba',
    currencyCode: 'CUC',
    code: 'CU',
    dial_code: '+53',
    timezone: 'GMT-05:00'
  },
  {
    name: 'Curacao',
    currencyCode: 'ANG',
    code: 'CW',
    dial_code: '+253',
    timezone: 'UTC-04:00'
  },
  {
    name: 'Cyprus',
    currencyCode: 'EUR',
    code: 'CY',
    dial_code: '+357',
    timezone: 'UTC+02:00'
  },
  {
    name: 'Czech Republic',
    currencyCode: 'CZK',
    code: 'CZ',
    dial_code: '+420',
    timezone: 'UTC+01:00'
  },
  {
    name: 'Denmark',
    currencyCode: 'DKK',
    code: 'DK',
    dial_code: '+45',
    timezone: 'GMT-04:00'
  },
  {
    name: 'Djibouti',
    currencyCode: 'DJF',
    code: 'DJ',
    dial_code: '+253',
    timezone: 'UTC+03:00'
  },
  {
    name: 'Dominica',
    currencyCode: 'XCD',
    code: 'DM',
    dial_code: '+1767',
    timezone: 'GMT-04:00'
  },
  {
    name: 'Dominican Republic',
    currencyCode: 'DOP',
    code: 'DO',
    dial_code: '+1809',
    timezone: 'GMT-04:00'
  },
  {
    name: 'Ecuador',
    currencyCode: 'USD',
    code: 'EC',
    dial_code: '+593',
    timezone: 'GMT-06:00'
  },
  {
    name: 'Egypt',
    currencyCode: 'EGP',
    code: 'EG',
    dial_code: '+20',
    timezone: 'UTC+02:00'
  },
  {
    name: 'El Salvador',
    currencyCode: 'USD',
    code: 'SV',
    dial_code: '+503',
    timezone: 'GMT-06:00'
  },
  {
    name: 'Equatorial Guinea',
    currencyCode: 'XAF',
    code: 'GQ',
    dial_code: '+240',
    timezone: 'UTC+01:00'
  },
  {
    name: 'Eritrea',
    currencyCode: 'ERN',
    code: 'ER',
    dial_code: '+291',
    timezone: 'UTC+03:00'
  },
  {
    name: 'Estonia',
    currencyCode: 'EUR',
    code: 'EE',
    dial_code: '+372',
    timezone: 'UTC+02:00'
  },
  {
    name: 'Ethiopia',
    currencyCode: 'ETB',
    code: 'ET',
    dial_code: '+251',
    timezone: 'UTC+03:00'
  },
  {
    name: 'Falkland Islands',
    currencyCode: 'FKP',
    code: 'FK',
    dial_code: '+49',
    timezone: 'UTC-03:00'
  },
  {
    name: 'Faroe Islands',
    currencyCode: 'DKK',
    code: 'FO',
    dial_code: '+298',
    timezone: 'UTC+00:00'
  },
  {
    name: 'Fiji',
    currencyCode: 'FJD',
    code: 'FJ',
    dial_code: '+995',
    timezone: 'UTC+12:00'
  },
  {
    name: 'Finland',
    currencyCode: 'EUR',
    code: 'FI',
    dial_code: '+358',
    timezone: 'UTC+02:00'
  },
  {
    name: 'France',
    currencyCode: 'EUR',
    code: 'FR',
    dial_code: '+33',
    timezone: 'GMT-10:00'
  },
  {
    name: 'French Guiana',
    currencyCode: 'EUR',
    code: 'GF',
    dial_code: '+594',
    timezone: 'GMT-03:00'
  },
  {
    name: 'French Polynesia',
    currencyCode: 'XPF',
    code: 'PF',
    dial_code: '+992',
    timezone: 'UTC-10:00'
  },
  {
    name: 'French Southern Territories',
    currencyCode: 'EUR',
    code: 'TF',
    dial_code: '+',
    timezone: 'UTC+05:00'
  },
  {
    name: 'Gabon',
    currencyCode: 'XAF',
    code: 'GA',
    dial_code: '+241',
    timezone: 'UTC+01:00'
  },
  {
    name: 'Gambia',
    currencyCode: 'GMD',
    code: 'GM',
    dial_code: '+220',
    timezone: 'UTC+00:00'
  },
  {
    name: 'Georgia',
    currencyCode: 'GEL',
    code: 'GE',
    dial_code: '+995',
    timezone: 'GMT-05:00'
  },
  {
    name: 'Germany',
    currencyCode: 'EUR',
    code: 'DE',
    dial_code: '+49',
    timezone: 'UTC+01:00'
  },
  {
    name: 'Ghana',
    currencyCode: 'GHS',
    code: 'GH',
    dial_code: '+233',
    timezone: 'UTC+00:00'
  },
  {
    name: 'Gibraltar',
    currencyCode: 'GIP',
    code: 'GI',
    dial_code: '+595',
    timezone: 'UTC+01:00'
  },
  {
    name: 'Greece',
    currencyCode: 'EUR',
    code: 'GR',
    dial_code: '+30',
    timezone: 'UTC+02:00'
  },
  {
    name: 'Greenland',
    currencyCode: 'DKK',
    code: 'GL',
    dial_code: '+299',
    timezone: 'GMT-04:00'
  },
  {
    name: 'Grenada',
    currencyCode: 'XCD',
    code: 'GD',
    dial_code: '+1473',
    timezone: 'GMT-04:00'
  },
  {
    name: 'Guadeloupe',
    currencyCode: 'EUR',
    code: 'GP',
    dial_code: '+590',
    timezone: 'GMT-04:00'
  },
  {
    name: 'Guam',
    currencyCode: 'USD',
    code: 'GU',
    dial_code: '+1671',
    timezone: 'UTC+10:00'
  },
  {
    name: 'Guatemala',
    currencyCode: 'GTQ',
    code: 'GT',
    dial_code: '+502',
    timezone: 'GMT-06:00'
  },
  {
    name: 'Guernsey',
    currencyCode: 'GBP',
    code: 'GG',
    dial_code: '+44',
    timezone: 'UTC+00:00'
  },
  {
    name: 'Guinea',
    currencyCode: 'GNF',
    code: 'GN',
    dial_code: '+224',
    timezone: 'GMT'
  },
  {
    name: 'Guinea-Bissau',
    currencyCode: 'XOF',
    code: 'GW',
    dial_code: '+245',
    timezone: 'GMT'
  },
  {
    name: 'Guyana',
    currencyCode: 'GYD',
    code: 'GY',
    dial_code: '+1 876',
    timezone: 'UTC-04:00'
  },
  {
    name: 'Haiti',
    currencyCode: 'HTG',
    code: 'HT',
    dial_code: '+509',
    timezone: 'GMT-05:00'
  },
  {
    name: 'Heard Island and McDonald Islands',
    currencyCode: 'AUD',
    code: 'HM',
    dial_code: '+',
    timezone: 'UTC+05:00'
  },
  {
    name: 'Holy See',
    currencyCode: 'EUR',
    code: 'VA',
    dial_code: '+379',
    timezone: 'UTC+01:00'
  },
  {
    name: 'Honduras',
    currencyCode: 'HNL',
    code: 'HN',
    dial_code: '+504',
    timezone: 'GMT-06:00'
  },
  {
    name: 'Hong kong',
    currencyCode: 'HKD',
    code: 'HK',
    dial_code: '+852',
    timezone: 'UTC+08:00'
  },
  {
    name: 'Hungary',
    currencyCode: 'HUF',
    code: 'HU',
    dial_code: '+36',
    timezone: 'UTC+01:00'
  },
  {
    name: 'Iceland',
    currencyCode: 'ISK',
    code: 'IS',
    dial_code: '+354',
    timezone: 'GMT'
  },
  {
    name: 'India',
    currencyCode: 'INR',
    code: 'IN',
    dial_code: '+91',
    timezone: 'UTC+05:30'
  },
  {
    name: 'Indonesia',
    currencyCode: 'IDR',
    code: 'ID',
    dial_code: '+62',
    timezone: 'UTC+07:00'
  },
  {
    name: "Côte d'Ivoire",
    currencyCode: 'XOF',
    code: 'CI',
    dial_code: '+225',
    timezone: 'GMT'
  },
  {
    name: 'Iran (Islamic Republic of)',
    currencyCode: 'IRR',
    code: 'IR',
    dial_code: '+98',
    timezone: 'UTC+03:30'
  },
  {
    name: 'Iraq',
    currencyCode: 'IQD',
    code: 'IQ',
    dial_code: '+964',
    timezone: 'UTC+03:00'
  },
  {
    name: 'Ireland',
    currencyCode: 'EUR',
    code: 'IE',
    dial_code: '+353',
    timezone: 'GMT'
  },
  {
    name: 'Isle of Man',
    currencyCode: 'GBP',
    code: 'IM',
    dial_code: '+44',
    timezone: 'UTC+00:00'
  },
  {
    name: 'Israel',
    currencyCode: 'ILS',
    code: 'IL',
    dial_code: '+972',
    timezone: 'UTC+02:00'
  },
  {
    name: 'Italy',
    currencyCode: 'EUR',
    code: 'IT',
    dial_code: '+39',
    timezone: 'UTC+01:00'
  },
  {
    name: 'Jamaica',
    currencyCode: 'JMD',
    code: 'JM',
    dial_code: '+1876',
    timezone: 'GMT-05:00'
  },
  {
    name: 'Japan',
    currencyCode: 'JPY',
    code: 'JP',
    dial_code: '+81',
    timezone: 'UTC+09:00'
  },
  {
    name: 'Jersey',
    currencyCode: 'GBP',
    code: 'JE',
    dial_code: '+44',
    timezone: 'UTC+01:00'
  },
  {
    name: 'Jordan',
    currencyCode: 'JOD',
    code: 'JO',
    dial_code: '+962',
    timezone: 'UTC+03:00'
  },
  {
    name: 'Kazakhstan',
    currencyCode: 'KZT',
    code: 'KZ',
    dial_code: '+76',
    timezone: 'UTC+05:00'
  },
  {
    name: 'Kenya',
    currencyCode: 'KES',
    code: 'KE',
    dial_code: '+254',
    timezone: 'UTC+03:00'
  },
  {
    name: 'Kiribati',
    currencyCode: 'AUD',
    code: 'KI',
    dial_code: '+686',
    timezone: 'UTC+12:00'
  },
  {
    name: 'Kuwait',
    currencyCode: 'KWD',
    code: 'KW',
    dial_code: '+965',
    timezone: 'UTC+03:00'
  },
  {
    name: 'Kyrgyzstan',
    currencyCode: 'KGS',
    code: 'KG',
    dial_code: '+996',
    timezone: 'UTC+06:00'
  },
  {
    name: "Lao People's Democratic Republic",
    currencyCode: 'LAK',
    code: 'LA',
    dial_code: '+856',
    timezone: 'UTC+07:00'
  },
  {
    name: 'Latvia',
    currencyCode: 'EUR',
    code: 'LV',
    dial_code: '+371',
    timezone: 'UTC+02:00'
  },
  {
    name: 'Lebanon',
    currencyCode: 'LBP',
    code: 'LB',
    dial_code: '+961',
    timezone: 'UTC+02:00'
  },
  {
    name: 'Lesotho',
    currencyCode: 'LSL',
    code: 'LS',
    dial_code: '+266',
    timezone: 'UTC+02:00'
  },
  {
    name: 'Liberia',
    currencyCode: 'LRD',
    code: 'LR',
    dial_code: '+231',
    timezone: 'GMT'
  },
  {
    name: 'Libya',
    currencyCode: 'LYD',
    code: 'LY',
    dial_code: '+218',
    timezone: 'UTC+01:00'
  },
  {
    name: 'Liechtenstein',
    currencyCode: 'CHF',
    code: 'LI',
    dial_code: '+423',
    timezone: 'UTC+01:00'
  },
  {
    name: 'Lithuania',
    currencyCode: 'EUR',
    code: 'LT',
    dial_code: '+370',
    timezone: 'UTC+02:00'
  },
  {
    name: 'Luxembourg',
    currencyCode: 'EUR',
    code: 'LU',
    dial_code: '+352',
    timezone: 'UTC+01:00'
  },
  {
    name: 'Macao',
    currencyCode: 'MOP',
    code: 'MO',
    dial_code: '+63',
    timezone: 'UTC+08:00'
  },
  {
    name: 'Macedonia (the former Yugoslav Republic of)',
    currencyCode: 'MKD',
    code: 'MK',
    dial_code: '+389',
    timezone: 'UTC+01:00'
  },
  {
    name: 'Madagascar',
    currencyCode: 'MGA',
    code: 'MG',
    dial_code: '+261',
    timezone: 'UTC+03:00'
  },
  {
    name: 'Malawi',
    currencyCode: 'MWK',
    code: 'MW',
    dial_code: '+265',
    timezone: 'UTC+02:00'
  },
  {
    name: 'Malaysia',
    currencyCode: 'MYR',
    code: 'MY',
    dial_code: '+60',
    timezone: 'UTC+08:00'
  },
  {
    name: 'Maldives',
    currencyCode: 'MVR',
    code: 'MV',
    dial_code: '+960',
    timezone: 'UTC+05:00'
  },
  {
    name: 'Mali',
    currencyCode: 'XOF',
    code: 'ML',
    dial_code: '+223',
    timezone: 'GMT'
  },
  {
    name: 'Malta',
    currencyCode: 'EUR',
    code: 'MT',
    dial_code: '+356',
    timezone: 'UTC+01:00'
  },
  {
    name: 'Marshall Islands',
    currencyCode: 'USD',
    code: 'MH',
    dial_code: '+692',
    timezone: 'UTC+12:00'
  },
  {
    name: 'Martinique',
    currencyCode: 'EUR',
    code: 'MQ',
    dial_code: '+596',
    timezone: 'GMT-04:00'
  },
  {
    name: 'Mauritania',
    currencyCode: 'MRO',
    code: 'MR',
    dial_code: '+222',
    timezone: 'GMT'
  },
  {
    name: 'Mauritius',
    currencyCode: 'MUR',
    code: 'MU',
    dial_code: '+230',
    timezone: 'UTC+04:00'
  },
  {
    name: 'Mayotte',
    currencyCode: 'EUR',
    code: 'YT',
    dial_code: '+262',
    timezone: 'UTC+03:00'
  },
  {
    name: 'Mexico',
    currencyCode: 'MXN',
    code: 'MX',
    dial_code: '+52',
    timezone: 'GMT-08:00'
  },
  {
    name: 'Micronesia',
    currencyCode: 'USD',
    code: 'FM',
    dial_code: '+233',
    timezone: 'UTC+11:00'
  },
  {
    name: 'Moldova (Republic of)',
    currencyCode: 'MDL',
    code: 'MD',
    dial_code: '+373',
    timezone: 'UTC+02:00'
  },
  {
    name: 'Monaco',
    currencyCode: 'EUR',
    code: 'MC',
    dial_code: '+377',
    timezone: 'UTC+01:00'
  },
  {
    name: 'Mongolia',
    currencyCode: 'MNT',
    code: 'MN',
    dial_code: '+976',
    timezone: 'UTC+07:00'
  },
  {
    name: 'Montenegro',
    currencyCode: 'EUR',
    code: 'ME',
    dial_code: '+382',
    timezone: 'UTC+01:00'
  },
  {
    name: 'Montserrat',
    currencyCode: 'XCD',
    code: 'MS',
    dial_code: '+1664',
    timezone: 'GMT-04:00'
  },
  {
    name: 'Morocco',
    currencyCode: 'MAD',
    code: 'MA',
    dial_code: '+212',
    timezone: 'GMT'
  },
  {
    name: 'Mozambique',
    currencyCode: 'MZN',
    code: 'MZ',
    dial_code: '+258',
    timezone: 'UTC+02:00'
  },
  {
    name: 'Myanmar',
    currencyCode: 'MMK',
    code: 'MM',
    dial_code: '+95',
    timezone: 'UTC+06:30'
  },
  {
    name: 'Namibia',
    currencyCode: 'NAD',
    code: 'NA',
    dial_code: '+264',
    timezone: 'UTC+01:00'
  },
  {
    name: 'Nauru',
    currencyCode: 'AUD',
    code: 'NR',
    dial_code: '+674',
    timezone: 'UTC+12:00'
  },
  {
    name: 'Nepal',
    currencyCode: 'NPR',
    code: 'NP',
    dial_code: '+977',
    timezone: 'UTC+05:45'
  },
  {
    name: 'Netherlands',
    currencyCode: 'EUR',
    code: 'NL',
    dial_code: '+31',
    timezone: 'GMT-04:00'
  },
  {
    name: 'New Caledonia',
    currencyCode: 'XPF',
    code: 'NC',
    dial_code: '+232',
    timezone: 'UTC+11:00'
  },
  {
    name: 'New Zealand',
    currencyCode: 'NZD',
    dial_code: '+64',
    code: 'NZ',
    timezone: 'UTC+13:00'
  },
  {
    name: 'Nicaragua',
    currencyCode: 'NIO',
    code: 'NI',
    dial_code: '+505',
    timezone: 'GMT-06:00'
  },
  {
    name: 'Niger',
    currencyCode: 'XOF',
    code: 'NE',
    dial_code: '+227',
    timezone: 'UTC+01:00'
  },
  {
    name: 'Nigeria',
    currencyCode: 'NGN',
    code: 'NG',
    dial_code: '+234',
    timezone: 'UTC+01:00'
  },
  {
    name: 'Niue',
    currencyCode: 'NZD',
    code: 'NU',
    dial_code: '+683',
    timezone: 'UTC-11:00'
  },
  {
    name: 'Norfolk Island',
    currencyCode: 'AUD',
    code: 'NF',
    dial_code: '+672',
    timezone: 'UTC+11:30'
  },
  {
    name: "Korea (Democratic People's Republic of)",
    currencyCode: 'KPW',
    code: 'KP',
    dial_code: '+850',
    timezone: 'UTC+09:00'
  },
  {
    name: 'Northern Mariana Islands',
    currencyCode: 'USD',
    code: 'MP',
    dial_code: '+1670',
    timezone: 'UTC+10:00'
  },
  {
    name: 'Norway',
    currencyCode: 'NOK',
    code: 'NO',
    dial_code: '+47',
    timezone: 'UTC+01:00'
  },
  {
    name: 'Oman',
    currencyCode: 'OMR',
    code: 'OM',
    dial_code: '+968',
    timezone: 'UTC+04:00'
  },
  {
    name: 'Pakistan',
    currencyCode: 'PKR',
    code: 'PK',
    dial_code: '+92',
    timezone: 'UTC+05:00'
  },
  {
    name: 'Palau',
    currencyCode: 'USD',
    code: 'PW',
    dial_code: '+688',
    timezone: 'UTC+09:00'
  },
  {
    name: 'Palestine, State of',
    currencyCode: 'ILS',
    code: 'PS',
    dial_code: '+970',
    timezone: 'UTC+02:00'
  },
  {
    name: 'Panama',
    currencyCode: 'PAB',
    code: 'PA',
    dial_code: '+507',
    timezone: 'GMT-05:00'
  },
  {
    name: 'Papua New Guinea',
    currencyCode: 'PGK',
    code: 'PG',
    dial_code: '+66',
    timezone: 'UTC+10:00'
  },
  {
    name: 'Paraguay',
    currencyCode: 'PYG',
    code: 'PY',
    dial_code: '+256',
    timezone: 'UTC-04:00'
  },
  {
    name: 'Peru',
    currencyCode: 'PEN',
    code: 'PE',
    dial_code: '+41',
    timezone: 'UTC-05:00'
  },
  {
    name: 'Philippines',
    currencyCode: 'PHP',
    code: 'PH',
    dial_code: '+63',
    timezone: 'UTC+08:00'
  },
  {
    name: 'Pitcairn',
    currencyCode: 'NZD',
    code: 'PN',
    dial_code: '+216',
    timezone: 'UTC-08:00'
  },
  {
    name: 'Poland',
    currencyCode: 'PLN',
    code: 'PL',
    dial_code: '+48',
    timezone: 'UTC+01:00'
  },
  {
    name: 'Portugal',
    currencyCode: 'EUR',
    code: 'PT',
    dial_code: '+351',
    timezone: 'GMT-01:00'
  },
  {
    name: 'Puerto Rico',
    currencyCode: 'USD',
    code: 'PR',
    dial_code: '+1787',
    timezone: 'GMT-04:00'
  },
  {
    name: 'Qatar',
    currencyCode: 'QAR',
    code: 'QA',
    dial_code: '+974',
    timezone: 'UTC+03:00'
  },
  {
    name: 'Republic of Kosovo',
    currencyCode: 'EUR',
    code: 'XK',
    dial_code: '+383',
    timezone: 'UTC+01:00'
  },
  {
    name: 'Réunion',
    currencyCode: 'EUR',
    code: 'RE',
    dial_code: '+262',
    timezone: 'UTC+04:00'
  },
  {
    name: 'Romania',
    currencyCode: 'RON',
    code: 'RO',
    dial_code: '+40',
    timezone: 'UTC+02:00'
  },
  {
    name: 'Russian Federation',
    currencyCode: 'RUB',
    code: 'RU',
    dial_code: '+7',
    timezone: 'UTC+03:00'
  },
  {
    name: 'Rwanda',
    currencyCode: 'RWF',
    code: 'RW',
    dial_code: '+250',
    timezone: 'UTC+02:00'
  },
  {
    name: 'Saint Helena, Ascension and Tristan da Cunha',
    currencyCode: 'SHP',
    code: 'SH',
    dial_code: '+290',
    timezone: 'UTC+00:00'
  },
  {
    name: 'Saint Barthélemy',
    currencyCode: 'EUR',
    code: 'BL',
    dial_code: '+590',
    timezone: 'GMT-04:00'
  },
  {
    name: 'Saint Kitts and Nevis',
    currencyCode: 'XCD',
    code: 'KN',
    dial_code: '+1869',
    timezone: 'GMT-04:00'
  },
  {
    name: 'Saint Lucia',
    currencyCode: 'XCD',
    code: 'LC',
    dial_code: '+1758',
    timezone: 'GMT-04:00'
  },
  {
    name: 'Saint Martin (French part)',
    currencyCode: 'EUR',
    code: 'MF',
    dial_code: '+590',
    timezone: 'GMT-04:00'
  },
  {
    name: 'Saint Pierre and Miquelon',
    currencyCode: 'EUR',
    code: 'PM',
    dial_code: '+508',
    timezone: 'GMT-03:00'
  },
  {
    name: 'Saint Vincent and the Grenadines',
    currencyCode: 'XCD',
    code: 'VC',
    dial_code: '+1784',
    timezone: 'GMT-04:00'
  },
  {
    name: 'Samoa',
    currencyCode: 'WST',
    code: 'WS',
    dial_code: '+685',
    timezone: 'UTC+13:00'
  },
  {
    name: 'San Marino',
    currencyCode: 'EUR',
    code: 'SM',
    dial_code: '+378',
    timezone: 'UTC+01:00'
  },
  {
    name: 'Sao Tome and Principe',
    currencyCode: 'STD',
    code: 'ST',
    dial_code: '+239',
    timezone: 'GMT'
  },
  {
    name: 'Saudi Arabia',
    currencyCode: 'SAR',
    code: 'SA',
    dial_code: '+966',
    timezone: 'UTC+03:00'
  },
  {
    name: 'Senegal',
    currencyCode: 'XOF',
    code: 'SN',
    dial_code: '+221',
    timezone: 'GMT'
  },
  {
    name: 'Serbia',
    currencyCode: 'RSD',
    code: 'RS',
    dial_code: '+381',
    timezone: 'UTC+01:00'
  },
  {
    name: 'Seychelles',
    currencyCode: 'SCR',
    code: 'SC',
    dial_code: '+248',
    timezone: 'UTC+04:00'
  },
  {
    name: 'Sierra Leone',
    currencyCode: 'SLL',
    code: 'SL',
    dial_code: '+232',
    timezone: 'GMT'
  },
  {
    name: 'Singapore',
    currencyCode: 'SGD',
    code: 'SG',
    dial_code: '+65',
    timezone: 'UTC+08:00'
  },
  {
    name: 'Sint Maarten',
    currencyCode: 'ANG',
    code: 'SX',
    dial_code: '+44',
    timezone: 'UTC-04:00'
  },
  {
    name: 'Slovakia',
    currencyCode: 'EUR',
    code: 'SK',
    dial_code: '+421',
    timezone: 'UTC+01:00'
  },
  {
    name: 'Slovenia',
    currencyCode: 'EUR',
    code: 'SI',
    dial_code: '+386',
    timezone: 'UTC+01:00'
  },
  {
    name: 'Solomon Islands',
    currencyCode: 'SBD',
    code: 'SB',
    dial_code: '+681',
    timezone: 'UTC+11:00'
  },
  {
    name: 'Somalia',
    currencyCode: 'SOS',
    code: 'SO',
    dial_code: '+252',
    timezone: 'UTC+03:00'
  },
  {
    name: 'South Africa',
    currencyCode: 'ZAR',
    code: 'ZA',
    dial_code: '+27',
    timezone: 'UTC+02:00'
  },
  {
    name: 'South Georgia and the South Sandwich Islands',
    currencyCode: 'GBP',
    code: 'GS',
    dial_code: '+500',
    timezone: 'GMT-02:00'
  },
  {
    name: 'Korea (Republic of)',
    currencyCode: 'KRW',
    code: 'KR',
    dial_code: '+82',
    timezone: 'UTC+09:00'
  },
  {
    name: 'South Sudan',
    currencyCode: 'SSP',
    code: 'SS',
    dial_code: '+211',
    timezone: 'UTC+03:00'
  },
  {
    name: 'Spain',
    currencyCode: 'EUR',
    code: 'ES',
    dial_code: '+34',
    timezone: 'GMT'
  },
  {
    name: 'Sri Lanka',
    currencyCode: 'LKR',
    code: 'LK',
    dial_code: '+94',
    timezone: 'UTC+05:30'
  },
  {
    name: 'Sudan',
    currencyCode: 'SDG',
    code: 'SD',
    dial_code: '+249',
    timezone: 'UTC+03:00'
  },
  {
    name: 'Suriname',
    currencyCode: 'SRD',
    dial_code: '+597',
    code: 'SR',
    timezone: 'SR UTC-03:00'
  },
  {
    name: 'Svalbard and Jan Mayen',
    currencyCode: 'NOK',
    code: 'SJ',
    dial_code: '+4779',
    timezone: 'UTC+01:00'
  },
  {
    name: 'Swaziland',
    currencyCode: 'SZL',
    code: 'SZ',
    dial_code: '+268',
    timezone: 'UTC+02:00'
  },
  {
    name: 'Sweden',
    currencyCode: 'SEK',
    code: 'SE',
    dial_code: '+46',
    timezone: 'UTC+01:00'
  },
  {
    name: 'Switzerland',
    currencyCode: 'CHF',
    code: 'CH',
    dial_code: '+41',
    timezone: 'UTC+01:00'
  },
  {
    name: 'Syrian Arab Republic',
    currencyCode: 'SYP',
    code: 'SY',
    dial_code: '+963',
    timezone: 'UTC+02:00'
  },
  {
    name: 'Taiwan',
    currencyCode: 'TWD',
    code: 'TW',
    dial_code: '+886',
    timezone: 'UTC+08:00'
  },
  {
    name: 'Tajikistan',
    currencyCode: 'TJS',
    code: 'TJ',
    dial_code: '+992',
    timezone: 'UTC+05:00'
  },
  {
    name: 'Tanzania, United Republic of',
    currencyCode: 'TZS',
    code: 'TZ',
    dial_code: '+255',
    timezone: 'UTC+03:00'
  },
  {
    name: 'Thailand',
    currencyCode: 'THB',
    code: 'TH',
    dial_code: '+66',
    timezone: 'UTC+07:00'
  },
  {
    name: 'Timor-Leste',
    currencyCode: 'USD',
    code: 'TL',
    dial_code: '+670',
    timezone: 'UTC+09:00'
  },
  {
    name: 'Togo',
    currencyCode: 'XOF',
    code: 'TG',
    dial_code: '+228',
    timezone: 'GMT'
  },
  {
    name: 'Tokelau',
    currencyCode: 'NZD',
    code: 'TK',
    dial_code: '+258',
    timezone: 'UTC+13:00'
  },
  {
    name: 'Tonga',
    currencyCode: 'TOP',
    code: 'TO',
    dial_code: '+7',
    timezone: 'UTC+13:00'
  },
  {
    name: 'Trinidad and Tobago',
    currencyCode: 'TTD',
    code: 'TT',
    dial_code: '+1868',
    timezone: 'GMT-04:00'
  },
  {
    name: 'Tunisia',
    currencyCode: 'TND',
    code: 'TN',
    dial_code: '+216',
    timezone: 'UTC+01:00'
  },
  {
    name: 'Turkey',
    currencyCode: 'TRY',
    code: 'TR',
    dial_code: '+90',
    timezone: 'UTC+03:00'
  },
  {
    name: 'Turkmenistan',
    currencyCode: 'TMT',
    code: 'TM',
    dial_code: '+872',
    timezone: 'UTC+05:00'
  },
  {
    name: 'Turks and Caicos Islands',
    currencyCode: 'USD',
    code: 'TC',
    dial_code: '+1649',
    timezone: 'GMT-04:00'
  },
  {
    name: 'Tuvalu',
    currencyCode: 'AUD',
    code: 'TV',
    dial_code: '+688',
    timezone: 'UTC+12:00'
  },
  {
    name: 'Uganda',
    currencyCode: 'UGX',
    code: 'UG',
    dial_code: '+256',
    timezone: 'UTC+03:00'
  },
  {
    name: 'Ukraine',
    currencyCode: 'UAH',
    code: 'UA',
    dial_code: '+380',
    timezone: 'UTC+02:00'
  },
  {
    name: 'United Arab Emirates',
    currencyCode: 'AED',
    code: 'AE',
    dial_code: '+971',
    timezone: 'UTC+04'
  },
  {
    name: 'United Kingdom',
    currencyCode: 'GBP',
    code: 'UK',
    dial_code: '+44',
    timezone: 'GMT-08:00'
  },
  {
    name: 'United States of America',
    currencyCode: 'USD',
    code: 'US',
    dial_code: '+1',
    timezone: 'GMT-12:00'
  },
  {
    name: 'Uzbekistan',
    currencyCode: 'UZS',
    code: 'UZ',
    dial_code: '+998',
    timezone: 'UTC+05:00'
  },
  {
    name: 'Vanuatu',
    currencyCode: 'VUV',
    code: 'VU',
    dial_code: '+1 340',
    timezone: 'UTC+11:00'
  },
  {
    name: 'Venezuela',
    currencyCode: 'VEF',
    code: 'VE',
    dial_code: '+670',
    timezone: 'UTC-04:00'
  },
  {
    name: 'Virgin Islands (UK)',
    currencyCode: 'USD',
    code: 'VG',
    dial_code: '+58',
    timezone: 'UTC-04:00'
  },
  {
    name: 'Viet Nam',
    currencyCode: 'VND',
    code: 'VN',
    dial_code: '+84',
    timezone: 'UTC+07:00'
  },
  {
    name: 'Wallis and Futuna',
    currencyCode: 'XPF',
    code: 'WF',
    dial_code: '+681',
    timezone: 'UTC+12:00'
  },
  {
    name: 'Western Sahara',
    currencyCode: 'MAD',
    code: 'EH',
    dial_code: '+212',
    timezone: 'UTC+00:00'
  },
  {
    name: 'Yemen',
    currencyCode: 'YER',
    code: 'YE',
    dial_code: '+967',
    timezone: 'UTC+03:00'
  },
  {
    name: 'Zambia',
    currencyCode: 'ZMW',
    code: 'ZM',
    dial_code: '+260',
    timezone: 'UTC+02:00'
  },
  {
    name: 'Zimbabwe',
    currencyCode: 'BWP',
    code: 'ZW',
    dial_code: '+263',
    timezone: 'UTC+02:00'
  }
];

export const US_TIMEZONE = [
  'Hawaii Standard Time',
  'Hawaii-Aleutian Daylight Time',
  'Alaska Daylight Time',
  'Pacific Daylight Time',
  'Mountain Daylight Time',
  'Central Daylight Time',
  'Eastern Daylight Time',
  'Alaska Standard Time',
  'Pacific Standard Time',
  'Mountain Standard Time',
  'Central Standard Time',
  'Eastern Standard Time',
  'Mountain Time',
  'Central Time',
  'Eastern Time',
  'Samoa Standard Time',
  'Atlantic Standard Time',
  'Chamorro Standard Time',
  'Wake Time',
  'Hawaii Time',
  'Alaska Time',
  'Pacific Time'
];

export const DASHBOARD_PERIOD_OPTIONS = [
  {
    label: 'PRODUCTS.PRODUCT_DASHBOARD.PERIOD.SIX_MONTHS',
    value: DASHBOARD_PERIODS.SIX_MONTHS
  },
  {
    label: 'PRODUCTS.PRODUCT_DASHBOARD.PERIOD.THREE_MONTHS',
    value: DASHBOARD_PERIODS.THREE_MONTHS
  },
  {
    label: 'PRODUCTS.PRODUCT_DASHBOARD.PERIOD.FOUR_WEEKS',
    value: DASHBOARD_PERIODS.FOUR_WEEKS
  }
];

export const DOCUMENT_OPTIONS: string[] = [
  'Create Order',
  'Create Invoice',
  'Create Quote',
  'Create Bill'
];

export const USstateMockData = [
  {
    code: 'AL',
    name: 'Alabama'
  },
  {
    code: 'AK',
    name: 'Alaska'
  },
  {
    code: 'AS',
    name: 'American Samoa'
  },
  {
    code: 'AZ',
    name: 'Arizona'
  },
  {
    code: 'AR',
    name: 'Arkansas'
  },
  {
    code: 'CA',
    name: 'California'
  },
  {
    code: 'CO',
    name: 'Colorado'
  },
  {
    code: 'CT',
    name: 'Connecticut'
  },
  {
    code: 'DE',
    name: 'Delaware'
  },
  {
    code: 'DC',
    name: 'District of Columbia'
  },
  {
    code: 'FL',
    name: 'Florida'
  },
  {
    code: 'GA',
    name: 'Georgia'
  },
  {
    code: 'GU',
    name: 'Guam'
  },
  {
    code: 'HI',
    name: 'Hawaii'
  },
  {
    code: 'ID',
    name: 'Idaho'
  },
  {
    code: 'IL',
    name: 'Illinois'
  },
  {
    code: 'IN',
    name: 'Indiana'
  },
  {
    code: 'IA',
    name: 'Iowa'
  },
  {
    code: 'KS',
    name: 'Kansas'
  },
  {
    code: 'KY',
    name: 'Kentucky'
  },
  {
    code: 'LA',
    name: 'Louisiana'
  },
  {
    code: 'ME',
    name: 'Maine'
  },
  {
    code: 'MD',
    name: 'Maryland'
  },
  {
    code: 'MA',
    name: 'Massachusetts'
  },
  {
    code: 'MI',
    name: 'Michigan'
  },
  {
    code: 'MN',
    name: 'Minnesota'
  },
  {
    code: 'MS',
    name: 'Mississippi'
  },
  {
    code: 'MO',
    name: 'Missouri'
  },
  {
    code: 'MT',
    name: 'Montana'
  },
  {
    code: 'NE',
    name: 'Nebraska'
  },
  {
    code: 'NV',
    name: 'Nevada'
  },
  {
    code: 'NH',
    name: 'New Hampshire'
  },
  {
    code: 'NJ',
    name: 'New Jersey'
  },
  {
    code: 'NM',
    name: 'New Mexico'
  },
  {
    code: 'NY',
    name: 'New York'
  },
  {
    code: 'NC',
    name: 'North Carolina'
  },
  {
    code: 'ND',
    name: 'North Dakota'
  },
  {
    code: 'MP',
    name: 'Northern Mariana Islands'
  },
  {
    code: 'OH',
    name: 'Ohio'
  },
  {
    code: 'OK',
    name: 'Oklahoma'
  },
  {
    code: 'OR',
    name: 'Oregon'
  },
  {
    code: 'PA',
    name: 'Pennsylvania'
  },
  {
    code: 'PR',
    name: 'Puerto Rico'
  },
  {
    code: 'RI',
    name: 'Rhode Island'
  },
  {
    code: 'SC',
    name: 'South Carolina'
  },
  {
    code: 'SD',
    name: 'South Dakota'
  },
  {
    code: 'TN',
    name: 'Tennessee'
  },
  {
    code: 'TX',
    name: 'Texas'
  },
  {
    code: 'UM',
    name: 'United States Minor Outlying Islands'
  },
  {
    code: 'UT',
    name: 'Utah'
  },
  {
    code: 'VT',
    name: 'Vermont'
  },
  {
    code: 'VI',
    name: 'Virgin Islands, U.S.'
  },
  {
    code: 'VA',
    name: 'Virginia'
  },
  {
    code: 'WA',
    name: 'Washington'
  },
  {
    code: 'WV',
    name: 'West Virginia'
  },
  {
    code: 'WI',
    name: 'Wisconsin'
  },
  {
    code: 'WY',
    name: 'Wyoming'
  }
];

export const taxTypesVendorIndonesia = [
  {
    value: 'TAX_CODE_VENDOR_01',
    label: 'To Parties Not Collecting VAT'
  },
  {
    value: 'TAX_CODE_VENDOR_02',
    label: 'Treasurer Collector'
  },
  {
    value: 'TAX_CODE_VENDOR_03',
    label: 'To Collectors Other Than Treasurers'
  },
  {
    value: 'TAX_CODE_VENDOR_04',
    label: 'Other Value DPP'
  },
  {
    value: 'TAX_CODE_VENDOR_05',
    label:
      'Other Submissions, including submission to foreign tourists in the context of a VAT refund'
  },
  {
    value: 'TAX_CODE_VENDOR_06',
    label: 'Submissions for which VAT is Not Charged'
  },
  {
    value: 'TAX_CODE_VENDOR_07',
    label: 'VAT exempted'
  },
  {
    value: 'TAX_CODE_VENDOR_08',
    label: 'Transfer of Assets (Article 16D of the VAT Act)'
  }
];

export const taxTypesCustomerIndonesia = [
  {
    value: 'TAX_CODE_CUSTOMER_01',
    label: 'To Non-VAT Parties'
  },
  {
    value: 'TAX_CODE_CUSTOMER_02',
    label: 'To the Treasurer Collector'
  },
  {
    value: 'TAX_CODE_CUSTOMER_03',
    label: 'To The Collector In addition to the Treasurer'
  },
  {
    value: 'TAX_CODE_CUSTOMER_04',
    label: 'DPP Other Values'
  },
  {
    value: 'TAX_CODE_CUSTOMER_05',
    label:
      'Other Submissions, including submission to foreign tourists for VAT refunds'
  },
  {
    value: 'TAX_CODE_CUSTOMER_06',
    label: 'Submissions of VAT not collected'
  },
  {
    value: 'TAX_CODE_CUSTOMER_07',
    label: 'Its VAT Transfers are Delivered'
  },
  {
    value: 'TAX_CODE_CUSTOMER_08',
    label: 'Assets Submissions (Article 16D of the VAT Law)'
  }
];

export const taxExemptionReasons = [
  { id: 'Federal_Government', name: 'Federal government' },
  { id: 'State_Government', name: 'State government' },
  { id: 'Tribal_Government', name: 'Tribal government' },
  { id: 'Foreign_Diplomat', name: 'Foreign diplomat' },
  { id: 'Charitable_Organization', name: 'Charitable organization' },
  { id: 'Religious_Organization', name: 'Religious organization' },
  { id: 'Resale', name: 'Resale' },
  { id: 'Agricultural_Production', name: 'Agricultural production' },
  {
    id: 'Industrial_Production_or_Manufacturers',
    name: 'Industrial production or manufacturers'
  },
  { id: 'Direct_Pay_Permit', name: 'Direct pay permit' },
  { id: 'Direct_Mail', name: 'Direct mail' },
  { id: 'Other_or_custom', name: 'Other or custom' },
  { id: 'Educational_Organization', name: 'Educational organization' },
  { id: 'Local_Government', name: 'Local government' },
  { id: 'Commercial_Aquaculture', name: 'Commercial aquaculture' },
  { id: 'Commercial_Fishery', name: 'Commercial fishery' },
  { id: 'Non_Resident', name: 'Non-resident' }
];

export const NON_DEDUCTION_REASON_INDIA = [
  {
    id: 'NON_DEDUCTION_REASON_INDIA.NON_DEDUCTION_REASON_01',
    name: 'Non Deduction or Lower Deduction is on account of a Certificate under Section 197'
  },
  {
    id: 'NON_DEDUCTION_REASON_INDIA.NON_DEDUCTION_REASON_02',
    name: 'Non Deduction is on account of a Declaration under Section 197 A'
  },
  {
    id: 'NON_DEDUCTION_REASON_INDIA.NON_DEDUCTION_REASON_03',
    name: 'Non Deduction since vendor is :Transporter owning not more than 10 goods carriages at any time during the previous year'
  },
  {
    id: 'NON_DEDUCTION_REASON_INDIA.NON_DEDUCTION_REASON_04',
    name: 'TDS not to be made till the Basic Exemption is reached'
  }
];

export const EXEMPTION_CRITERIA_MY = [
  {
    exemptionId: '1',
    name: 'Schedule A'
  },
  {
    exemptionId: '2',
    name: 'Schedule B'
  },
  {
    exemptionId: '3',
    name: 'Schedule C3 & C4 (Trader)'
  }
];

export const timezones: string[] = [
  'Africa/Abidjan',
  'Africa/Accra',
  'Africa/Addis_Ababa',
  'Africa/Algiers',
  'Africa/Asmara',
  'Africa/Asmera',
  'Africa/Bamako',
  'Africa/Bangui',
  'Africa/Banjul',
  'Africa/Bissau',
  'Africa/Blantyre',
  'Africa/Brazzaville',
  'Africa/Bujumbura',
  'Africa/Cairo',
  'Africa/Casablanca',
  'Africa/Ceuta',
  'Africa/Conakry',
  'Africa/Dakar',
  'Africa/Dar_es_Salaam',
  'Africa/Djibouti',
  'Africa/Douala',
  'Africa/El_Aaiun',
  'Africa/Freetown',
  'Africa/Gaborone',
  'Africa/Harare',
  'Africa/Johannesburg',
  'Africa/Juba',
  'Africa/Kampala',
  'Africa/Khartoum',
  'Africa/Kigali',
  'Africa/Kinshasa',
  'Africa/Lagos',
  'Africa/Libreville',
  'Africa/Lome',
  'Africa/Luanda',
  'Africa/Lubumbashi',
  'Africa/Lusaka',
  'Africa/Malabo',
  'Africa/Maputo',
  'Africa/Maseru',
  'Africa/Mbabane',
  'Africa/Mogadishu',
  'Africa/Monrovia',
  'Africa/Nairobi',
  'Africa/Ndjamena',
  'Africa/Niamey',
  'Africa/Nouakchott',
  'Africa/Ouagadougou',
  'Africa/Porto-Novo',
  'Africa/Sao_Tome',
  'Africa/Timbuktu',
  'Africa/Tripoli',
  'Africa/Tunis',
  'Africa/Windhoek',
  'America/Adak',
  'America/Anchorage',
  'America/Anguilla',
  'America/Antigua',
  'America/Araguaina',
  'America/Argentina/Buenos_Aires',
  'America/Argentina/Catamarca',
  'America/Argentina/ComodRivadavia',
  'America/Argentina/Cordoba',
  'America/Argentina/Jujuy',
  'America/Argentina/La_Rioja',
  'America/Argentina/Mendoza',
  'America/Argentina/Rio_Gallegos',
  'America/Argentina/Salta',
  'America/Argentina/San_Juan',
  'America/Argentina/San_Luis',
  'America/Argentina/Tucuman',
  'America/Argentina/Ushuaia',
  'America/Aruba',
  'America/Asuncion',
  'America/Atikokan',
  'America/Atka',
  'America/Bahia',
  'America/Bahia_Banderas',
  'America/Barbados',
  'America/Belem',
  'America/Belize',
  'America/Blanc-Sablon',
  'America/Boa_Vista',
  'America/Bogota',
  'America/Boise',
  'America/Buenos_Aires',
  'America/Cambridge_Bay',
  'America/Campo_Grande',
  'America/Cancun',
  'America/Caracas',
  'America/Catamarca',
  'America/Cayenne',
  'America/Cayman',
  'America/Chicago',
  'America/Chihuahua',
  'America/Coral_Harbour',
  'America/Cordoba',
  'America/Costa_Rica',
  'America/Creston',
  'America/Cuiaba',
  'America/Curacao',
  'America/Danmarkshavn',
  'America/Dawson',
  'America/Dawson_Creek',
  'America/Denver',
  'America/Detroit',
  'America/Dominica',
  'America/Edmonton',
  'America/Eirunepe',
  'America/El_Salvador',
  'America/Ensenada',
  'America/Fort_Nelson',
  'America/Fort_Wayne',
  'America/Fortaleza',
  'America/Glace_Bay',
  'America/Godthab',
  'America/Goose_Bay',
  'America/Grand_Turk',
  'America/Grenada',
  'America/Guadeloupe',
  'America/Guatemala',
  'America/Guayaquil',
  'America/Guyana',
  'America/Halifax',
  'America/Havana',
  'America/Hermosillo',
  'America/Indiana/Indianapolis',
  'America/Indiana/Knox',
  'America/Indiana/Marengo',
  'America/Indiana/Petersburg',
  'America/Indiana/Tell_City',
  'America/Indiana/Vevay',
  'America/Indiana/Vincennes',
  'America/Indiana/Winamac',
  'America/Indianapolis',
  'America/Inuvik',
  'America/Iqaluit',
  'America/Jamaica',
  'America/Jujuy',
  'America/Juneau',
  'America/Kentucky/Louisville',
  'America/Kentucky/Monticello',
  ' America/Knox_IN',
  ' America/Kralendijk',
  ' America/La_Paz',
  ' America/Lima',
  ' America/Los_Angeles',
  ' America/Louisville',
  ' America/Lower_Princes',
  ' America/Maceio',
  ' America/Managua',
  ' America/Manaus',
  ' America/Marigot',
  ' America/Martinique',
  ' America/Matamoros',
  ' America/Mazatlan',
  ' America/Mendoza',
  ' America/Menominee',
  ' America/Merida',
  ' America/Metlakatla',
  ' America/Mexico_City',
  ' America/Miquelon',
  ' America/Moncton',
  ' America/Monterrey',
  ' America/Montevideo',
  'America/Montreal',
  'America/Montserrat',
  'America/Nassau',
  'America/New_York',
  'America/Nipigon',
  'America/Nome',
  'America/Noronha',
  'America/North_Dakota/Beulah',
  'America/North_Dakota/Center',
  'America/North_Dakota/New_Salem',
  'America/Ojinaga',
  'America/Panama',
  'America/Pangnirtung',
  'America/Paramaribo',
  'America/Phoenix',
  'America/Port-au-Prince',
  'America/Port_of_Spain',
  'America/Porto_Acre',
  'America/Porto_Velho',
  'America/Puerto_Rico',
  'America/Punta_Arenas',
  'America/Rainy_River',
  'America/Rankin_Inlet',
  'America/Recife',
  'America/Regina',
  'America/Resolute',
  'America/Rio_Branco',
  'America/Rosario',
  'America/Santa_Isabel',
  'America/Santarem',
  'America/Santiago',
  'America/Santo_Domingo',
  'America/Sao_Paulo',
  'America/Scoresbysund',
  'America/Shiprock',
  'America/Sitka',
  'America/St_Barthelemy',
  'America/St_Johns',
  'America/St_Kitts',
  'America/St_Lucia',
  'America/St_Thomas',
  'America/St_Vincent',
  'America/Swift_Current',
  'America/Tegucigalpa',
  'America/Thule',
  'America/Thunder_Bay',
  'America/Tijuana',
  'America/Toronto',
  'America/Tortola',
  'America/Vancouver',
  'America/Virgin',
  'America/Whitehorse',
  'America/Winnipeg',
  'America/Yakutat',
  'America/Yellowknife',
  'Antarctica/Casey',
  'Antarctica/Davis',
  'Antarctica/DumontDUrville',
  'Antarctica/Macquarie',
  'Antarctica/Mawson',
  'Antarctica/McMurdo',
  'Antarctica/Palmer',
  'Antarctica/Rothera',
  'Antarctica/South_Pole',
  'Antarctica/Syowa',
  'Antarctica/Troll',
  'Antarctica/Vostok',
  'Arctic/Longyearbyen',
  'Asia/Aden',
  'Asia/Almaty',
  'Asia/Amman',
  'Asia/Anadyr',
  'Asia/Aqtau',
  'Asia/Aqtobe',
  'Asia/Ashgabat',
  'Asia/Ashkhabad',
  'Asia/Atyrau',
  'Asia/Baghdad',
  'Asia/Bahrain',
  'Asia/Baku',
  'Asia/Bangkok',
  'Asia/Barnaul',
  'Asia/Beirut',
  'Asia/Bishkek',
  'Asia/Brunei',
  'Asia/Calcutta',
  'Asia/Chita',
  'Asia/Choibalsan',
  'Asia/Chongqing',
  'Asia/Chungking',
  'Asia/Colombo',
  'Asia/Dacca',
  'Asia/Damascus',
  'Asia/Dhaka',
  'Asia/Dili',
  'Asia/Dubai',
  'Asia/Dushanbe',
  'Asia/Famagusta',
  'Asia/Gaza',
  'Asia/Harbin',
  'Asia/Hebron',
  'Asia/Ho_Chi_Minh',
  'Asia/Hong_Kong',
  'Asia/Hovd',
  'Asia/Irkutsk',
  'Asia/Istanbul',
  'Asia/Jakarta',
  'Asia/Jayapura',
  'Asia/Jerusalem',
  'Asia/Kabul',
  'Asia/Kamchatka',
  'Asia/Karachi',
  'Asia/Kashgar',
  'Asia/Kathmandu',
  'Asia/Katmandu',
  'Asia/Khandyga',
  'Asia/Kolkata',
  'Asia/Krasnoyarsk',
  'Asia/Kuala_Lumpur',
  'Asia/Kuching',
  'Asia/Kuwait',
  'Asia/Macao',
  'Asia/Macau',
  'Asia/Magadan',
  'Asia/Makassar',
  'Asia/Manila',
  'Asia/Muscat',
  'Asia/Nicosia',
  'Asia/Novokuznetsk',
  'Asia/Novosibirsk',
  'Asia/Omsk',
  'Asia/Oral',
  'Asia/Phnom_Penh',
  'Asia/Pontianak',
  'Asia/Pyongyang',
  'Asia/Qatar',
  'Asia/Qyzylorda',
  'Asia/Rangoon',
  'Asia/Riyadh',
  'Asia/Saigon',
  'Asia/Sakhalin',
  'Asia/Samarkand',
  'Asia/Seoul',
  'Asia/Shanghai',
  'Asia/Singapore',
  'Asia/Srednekolymsk',
  'Asia/Taipei',
  'Asia/Tashkent',
  'Asia/Tbilisi',
  'Asia/Tehran',
  'Asia/Tel_Aviv',
  'Asia/Thimbu',
  'Asia/Thimphu',
  'Asia/Tokyo',
  'Asia/Tomsk',
  'Asia/Ujung_Pandang',
  'Asia/Ulaanbaatar',
  'Asia/Ulan_Bator',
  'Asia/Urumqi',
  'Asia/Ust-Nera',
  'Asia/Vientiane',
  'Asia/Vladivostok',
  'Asia/Yakutsk',
  'Asia/Yangon',
  'Asia/Yekaterinburg',
  'Asia/Yerevan',
  'Atlantic/Azores',
  'Atlantic/Bermuda',
  'Atlantic/Canary',
  'Atlantic/Cape_Verde',
  'Atlantic/Faeroe',
  'Atlantic/Faroe',
  'Atlantic/Jan_Mayen',
  'Atlantic/Madeira',
  'Atlantic/Reykjavik',
  'Atlantic/South_Georgia',
  'Atlantic/St_Helena',
  'Atlantic/Stanley',
  'Australia/ACT',
  'Australia/Adelaide',
  'Australia/Brisbane',
  'Australia/Broken_Hill',
  'Australia/Canberra',
  'Australia/Currie',
  'Australia/Darwin',
  'Australia/Eucla',
  'Australia/Hobart',
  'Australia/LHI',
  'Australia/Lindeman',
  'Australia/Lord_Howe',
  'Australia/Melbourne',
  'Australia/NSW',
  'Australia/North',
  'Australia/Perth',
  'Australia/Queensland',
  'Australia/South',
  'Australia/Sydney',
  'Australia/Tasmania',
  'Australia/Victoria',
  'Australia/West',
  'Australia/Yancowinna',
  'Brazil/Acre',
  'Brazil/DeNoronha',
  'Brazil/East',
  'Brazil/West',
  'CET',
  'CST6CDT',
  'Canada/Atlantic',
  'Canada/Central',
  'Canada/Eastern',
  'Canada/Mountain',
  'Canada/Newfoundland',
  'Canada/Pacific',
  'Canada/Saskatchewan',
  'Canada/Yukon',
  'Chile/Continental',
  'Chile/EasterIsland',
  'Cuba',
  'EET',
  'EST',
  'EST5EDT',
  'Egypt',
  'Eire',
  'Etc/GMT',
  'Etc/GMT+0',
  'Etc/GMT+1',
  'Etc/GMT+10',
  'Etc/GMT+11',
  'Etc/GMT+12',
  'Etc/GMT+2',
  'Etc/GMT+3',
  'Etc/GMT+4',
  'Etc/GMT+5',
  'Etc/GMT+6',
  'Etc/GMT+7',
  'Etc/GMT+8',
  'Etc/GMT+9',
  'Etc/GMT-0',
  'Etc/GMT-1',
  'Etc/GMT-10',
  'Etc/GMT-11',
  'Etc/GMT-12',
  'Etc/GMT-13',
  'Etc/GMT-14',
  'Etc/GMT-2',
  'Etc/GMT-3',
  'Etc/GMT-4',
  'Etc/GMT-5',
  'Etc/GMT-6',
  'Etc/GMT-7',
  'Etc/GMT-8',
  'Etc/GMT-9',
  'Etc/GMT0',
  'Etc/Greenwich',
  'Etc/UCT',
  'Etc/UTC',
  'Etc/Universal',
  'Etc/Zulu',
  'Europe/Amsterdam',
  'Europe/Andorra',
  'Europe/Astrakhan',
  'Europe/Athens',
  'Europe/Belfast',
  'Europe/Belgrade',
  'Europe/Berlin',
  'Europe/Bratislava',
  'Europe/Brussels',
  'Europe/Bucharest',
  'Europe/Budapest',
  'Europe/Busingen',
  'Europe/Chisinau',
  'Europe/Copenhagen',
  'Europe/Dublin',
  'Europe/Gibraltar',
  'Europe/Guernsey',
  'Europe/Helsinki',
  'Europe/Isle_of_Man',
  'Europe/Istanbul',
  'Europe/Jersey',
  'Europe/Kaliningrad',
  'Europe/Kiev',
  'Europe/Kirov',
  'Europe/Lisbon',
  'Europe/Ljubljana',
  'Europe/London',
  'Europe/Luxembourg',
  'Europe/Madrid',
  'Europe/Malta',
  'Europe/Mariehamn',
  'Europe/Minsk',
  'Europe/Monaco',
  'Europe/Moscow',
  'Europe/Nicosia',
  'Europe/Oslo',
  'Europe/Paris',
  'Europe/Podgorica',
  'Europe/Prague',
  'Europe/Riga',
  'Europe/Rome',
  'Europe/Samara',
  'Europe/San_Marino',
  'Europe/Sarajevo',
  'Europe/Saratov',
  'Europe/Simferopol',
  'Europe/Skopje',
  'Europe/Sofia',
  'Europe/Stockholm',
  'Europe/Tallinn',
  'Europe/Tirane',
  'Europe/Tiraspol',
  'Europe/Ulyanovsk',
  'Europe/Uzhgorod',
  'Europe/Vaduz',
  'Europe/Vatican',
  'Europe/Vienna',
  'Europe/Vilnius',
  'Europe/Volgograd',
  'Europe/Warsaw',
  'Europe/Zagreb',
  'Europe/Zaporozhye',
  'Europe/Zurich',
  'GB',
  'GB-Eire',
  'GMT',
  'GMT+0',
  'GMT-0',
  'GMT0',
  'Greenwich',
  'HST',
  'Hongkong',
  'Iceland',
  'Indian/Antananarivo',
  'Indian/Chagos',
  'Indian/Christmas',
  'Indian/Cocos',
  'Indian/Comoro',
  'Indian/Kerguelen',
  'Indian/Mahe',
  'Indian/Maldives',
  'Indian/Mauritius',
  'Indian/Mayotte',
  'Indian/Reunion',
  'Iran',
  'Israel',
  'Jamaica',
  'Japan',
  'Kwajalein',
  'Libya',
  'MET',
  'MST',
  'MST7MDT',
  'Mexico/BajaNorte',
  'Mexico/BajaSur',
  'Mexico/General',
  'NZ',
  'NZ-CHAT',
  'Navajo',
  'PRC',
  'PST8PDT',
  'Pacific/Apia',
  'Pacific/Auckland',
  'Pacific/Bougainville',
  'Pacific/Chatham',
  'Pacific/Chuuk',
  'Pacific/Easter',
  'Pacific/Efate',
  'Pacific/Enderbury',
  'Pacific/Fakaofo',
  'Pacific/Fiji',
  'Pacific/Funafuti',
  'Pacific/Galapagos',
  'Pacific/Gambier',
  'Pacific/Guadalcanal',
  'Pacific/Guam',
  'Pacific/Honolulu',
  'Pacific/Johnston',
  'Pacific/Kiritimati',
  'Pacific/Kosrae',
  'Pacific/Kwajalein',
  'Pacific/Majuro',
  'Pacific/Marquesas',
  'Pacific/Midway',
  'Pacific/Nauru',
  'Pacific/Niue',
  'Pacific/Norfolk',
  'Pacific/Noumea',
  'Pacific/Pago_Pago',
  'Pacific/Palau',
  'Pacific/Pitcairn',
  'Pacific/Pohnpei',
  'Pacific/Ponape',
  'Pacific/Port_Moresby',
  'Pacific/Rarotonga',
  'Pacific/Saipan',
  'Pacific/Samoa',
  'Pacific/Tahiti',
  'Pacific/Tarawa',
  'Pacific/Tongatapu',
  'Pacific/Truk',
  'Pacific/Wake',
  'Pacific/Wallis',
  'Pacific/Yap',
  'Poland',
  'Portugal',
  'ROC',
  'ROK',
  'Singapore',
  'Turkey',
  'UCT',
  'US/Alaska',
  'US/Aleutian',
  'US/Arizona',
  'US/Central',
  'US/East-Indiana',
  'US/Eastern',
  'US/Hawaii',
  'US/Indiana-Starke',
  'US/Michigan',
  'US/Mountain',
  'US/Pacific',
  'US/Pacific-New',
  'US/Samoa',
  'UTC',
  'Universal',
  'W-SU',
  'WET',
  'Zulu'
];

export const PPS_FILTER_PICKING_STATUS = [
  {
    label: 'Ready to Pick',
    value: PpsStatus.READY_TO_PICK
  },
  {
    label: 'In Progress',
    value: PpsStatus.PICKING_IN_PROGRESS
  },
  {
    label: 'Picked',
    value: PpsStatus.PICKED
  }
];

export const PPS_FILTER_PACKING_STATUS = [
  {
    label: 'Ready to Pack',
    value: PpsStatus.READY_TO_PACK
  },
  {
    label: 'In Progress',
    value: PpsStatus.PACKING_IN_PROGRESS
  },
  {
    label: 'Packed',
    value: PpsStatus.PACKED
  }
];

export const PPS_FILTER_SHIPPING_STATUS = [
  {
    label: 'Ready to Ship',
    value: PpsStatus.READY_TO_SHIP
  },
  {
    label: 'In Progress',
    value: PpsStatus.SHIPPING_IN_PROGRESS
  },
  {
    label: 'Partially Shipped',
    value: PpsStatus.PARTIAL_SHIPPED
  },
  {
    label: 'Shipped',
    value: PpsStatus.SHIPPED
  }
];

export const currencyDenominations = [
  {
    currencyCode: 'USD',
    currencyName: 'dollar',
    currencyNamePlural: 'dollars',
    lowerCurrency: 'cent',
    lowerCurrencyPlural: 'cents'
  },
  {
    currencyCode: 'SGD',
    currencyName: 'dollar',
    currencyNamePlural: 'dollars',
    lowerCurrency: 'cent',
    lowerCurrencyPlural: 'cents'
  },
  {
    currencyCode: 'INR',
    currencyName: 'rupee',
    currencyNamePlural: 'rupees',
    lowerCurrency: 'paise',
    lowerCurrencyPlural: 'paise'
  },
  {
    currencyCode: 'GBP',
    currencyName: 'pound',
    currencyNamePlural: 'pounds',
    lowerCurrency: 'penny',
    lowerCurrencyPlural: 'pennies'
  },
  {
    currencyCode: 'RUB',
    currencyName: 'ruble',
    currencyNamePlural: 'rubles',
    lowerCurrency: 'kopek',
    lowerCurrencyPlural: 'kopeks'
  },
  {
    currencyCode: 'AFN',
    currencyName: 'afghani',
    currencyNamePlural: 'afghani',
    lowerCurrency: 'pul',
    lowerCurrencyPlural: 'puls'
  },
  {
    currencyCode: 'EUR',
    currencyName: 'euro',
    currencyNamePlural: 'euros',
    lowerCurrency: 'cent',
    lowerCurrencyPlural: 'cents'
  },
  {
    currencyCode: 'ALL',
    currencyName: 'lek',
    currencyNamePlural: 'leks',
    lowerCurrency: 'qindarkë',
    lowerCurrencyPlural: 'cents'
  },
  {
    currencyCode: 'GGP',
    currencyName: 'pound',
    currencyNamePlural: 'pounds',
    lowerCurrency: 'penny',
    lowerCurrencyPlural: 'pennies'
  },
  {
    currencyCode: 'DZD',
    currencyName: 'dinar',
    currencyNamePlural: 'dinars',
    lowerCurrency: 'santeem',
    lowerCurrencyPlural: 'santeems'
  },
  {
    currencyCode: 'AOA',
    currencyName: 'kwanza',
    currencyNamePlural: 'kwanza',
    lowerCurrency: 'cêntimo',
    lowerCurrencyPlural: 'cêntimos'
  },
  {
    currencyCode: 'XCD',
    currencyName: 'dollar',
    currencyNamePlural: 'dollars',
    lowerCurrency: 'cent',
    lowerCurrencyPlural: 'cents'
  },
  {
    currencyCode: 'ARS',
    currencyName: 'peso',
    currencyNamePlural: 'pesos',
    lowerCurrency: 'centavo',
    lowerCurrencyPlural: 'centavos'
  },
  {
    currencyCode: 'AMD',
    currencyName: 'dram',
    currencyNamePlural: 'drams',
    lowerCurrency: 'luma',
    owerCurrencyPlural: 'lumas'
  },
  {
    currencyCode: 'AWG',
    currencyName: 'florin',
    currencyNamePlural: 'florins',
    lowerCurrency: 'cent',
    lowerCurrencyPlural: 'cents'
  },
  {
    currencyCode: 'SHP',
    currencyName: 'pound',
    currencyNamePlural: 'pounds',
    lowerCurrency: 'penny',
    lowerCurrencyPlural: 'pennies'
  },
  {
    currencyCode: 'AUD',
    currencyName: 'dollar',
    currencyNamePlural: 'dollars',
    lowerCurrency: 'cent',
    lowerCurrencyPlural: 'cents'
  },
  {
    currencyCode: 'AZN',
    currencyName: 'manat',
    currencyNamePlural: 'manat',
    lowerCurrency: 'qəpik',
    lowerCurrencyPlural: 'qəpiks'
  },
  {
    currencyCode: 'BSD',
    currencyName: 'dollar',
    currencyNamePlural: 'dollars',
    lowerCurrency: 'cent',
    lowerCurrencyPlural: 'cents'
  },
  {
    currencyCode: 'BHD',
    currencyName: 'dinar',
    currencyNamePlural: 'dinars',
    lowerCurrency: 'fils',
    lowerCurrencyPlural: 'fils'
  },
  {
    currencyCode: 'BDT',
    currencyName: 'taka',
    currencyNamePlural: 'takas',
    lowerCurrency: 'poisha',
    lowerCurrencyPlural: 'poisha'
  },
  {
    currencyCode: 'BBD',
    currencyName: 'dollar',
    currencyNamePlural: 'dollars',
    lowerCurrency: 'cent',
    lowerCurrencyPlural: 'cents'
  },
  {
    currencyCode: 'BYN',
    currencyName: 'ruble',
    currencyNamePlural: 'rubles',
    lowerCurrency: 'kapyeyka',
    lowerCurrencyPlural: 'kapyeykas'
  },
  {
    currencyCode: 'BZD',
    currencyName: 'dollar',
    currencyNamePlural: 'dollars',
    lowerCurrency: 'cent',
    lowerCurrencyPlural: 'cents'
  },
  {
    currencyCode: 'XOF',
    currencyName: 'franc',
    currencyNamePlural: 'franc',
    lowerCurrency: 'centime',
    lowerCurrencyPlural: 'centimes'
  },
  {
    currencyCode: 'BMD',
    currencyName: 'dollar',
    currencyNamePlural: 'dollars',
    lowerCurrency: 'cent',
    lowerCurrencyPlural: 'cents'
  },
  {
    currencyCode: 'BTN',
    currencyName: 'ngultrum',
    currencyNamePlural: 'ngultrums',
    lowerCurrency: 'chetrum',
    lowerCurrencyPlural: 'chetrums'
  },
  {
    currencyCode: 'BOB',
    currencyName: 'boliviano',
    currencyNamePlural: 'bolivianos',
    lowerCurrency: 'centavo',
    lowerCurrencyPlural: 'centavos'
  },
  {
    currencyCode: 'BAM',
    currencyName: 'mark',
    currencyNamePlural: 'marks',
    lowerCurrency: 'fening',
    lowerCurrencyPlural: 'fenings'
  },
  {
    currencyCode: 'BWP',
    currencyName: 'pula',
    currencyNamePlural: 'pulas',
    lowerCurrency: 'thebe',
    lowerCurrencyPlural: 'thebes'
  },
  {
    currencyCode: 'BRL',
    currencyName: 'real',
    currencyNamePlural: 'reals',
    lowerCurrency: 'centavo',
    lowerCurrencyPlural: 'centavos'
  },
  {
    currencyCode: 'BND',
    currencyName: 'dollar',
    currencyNamePlural: 'dollars',
    lowerCurrency: 'sen',
    lowerCurrencyPlural: 'sens'
  },
  {
    currencyCode: 'BGN',
    currencyName: 'lev',
    currencyNamePlural: 'levs',
    lowerCurrency: 'stotinka',
    lowerCurrencyPlural: 'stotinkas'
  },
  {
    currencyCode: 'BIF',
    currencyName: 'franc',
    currencyNamePlural: 'franc',
    lowerCurrency: 'centime',
    lowerCurrencyPlural: 'centimes'
  },
  {
    currencyCode: 'KHR',
    currencyName: 'riel',
    currencyNamePlural: 'riel',
    lowerCurrency: 'sen',
    lowerCurrencyPlural: 'sens'
  },
  {
    currencyCode: 'XAF',
    currencyName: 'franc',
    currencyNamePlural: 'franc',
    lowerCurrency: 'centime',
    lowerCurrencyPlural: 'centimes'
  },
  {
    currencyCode: 'CAD',
    currencyName: 'dollar',
    currencyNamePlural: 'dollars',
    lowerCurrency: 'cent',
    lowerCurrencyPlural: 'cents'
  },
  {
    currencyCode: 'CVE',
    currencyName: 'escudo',
    currencyNamePlural: 'escudos',
    lowerCurrency: 'centavo',
    lowerCurrencyPlural: 'centavos'
  },
  {
    currencyCode: 'KYD',
    currencyName: 'dollar',
    currencyNamePlural: 'dollars',
    lowerCurrency: 'cent',
    lowerCurrencyPlural: 'cents'
  },
  {
    currencyCode: 'CLP',
    currencyName: 'peso',
    currencyNamePlural: 'pesos',
    lowerCurrency: 'centavo',
    lowerCurrencyPlural: 'centavos'
  },
  {
    currencyCode: 'CNY',
    currencyName: 'yuan',
    currencyNamePlural: 'yuans',
    lowerCurrency: 'jiao',
    lowerCurrencyPlural: 'jiao'
  },
  {
    currencyCode: 'COP',
    currencyName: 'peso',
    currencyNamePlural: 'pesos',
    lowerCurrency: 'centavo',
    lowerCurrencyPlural: 'centavos'
  },
  {
    currencyCode: 'KMF',
    currencyName: 'franc',
    currencyNamePlural: 'franc',
    lowerCurrency: 'centime',
    lowerCurrencyPlural: 'centimes'
  },
  {
    currencyCode: 'CDF',
    currencyName: 'franc',
    currencyNamePlural: 'franc',
    lowerCurrency: 'centime',
    lowerCurrencyPlural: 'centimes'
  },
  {
    currencyCode: 'CKD',
    currencyName: 'dollar',
    currencyNamePlural: 'dollars',
    lowerCurrency: 'cent',
    lowerCurrencyPlural: 'cents'
  },
  {
    currencyCode: 'NZD',
    currencyName: 'dollar',
    currencyNamePlural: 'dollars',
    lowerCurrency: 'cent',
    lowerCurrencyPlural: 'cents'
  },
  {
    currencyCode: 'CRC',
    currencyName: 'colón',
    currencyNamePlural: 'colóns',
    lowerCurrency: 'céntimo',
    lowerCurrencyPlural: 'céntimos'
  },
  {
    currencyCode: 'HRK',
    currencyName: 'kuna',
    currencyNamePlural: 'kunas',
    lowerCurrency: 'lipa',
    lowerCurrencyPlural: 'lipas'
  },
  {
    currencyCode: 'CUP',
    currencyName: 'peso',
    currencyNamePlural: 'pesos',
    lowerCurrency: 'centavo',
    lowerCurrencyPlural: 'centavos'
  },
  {
    currencyCode: 'ANG',
    currencyName: 'guilder',
    currencyNamePlural: 'guilders',
    lowerCurrency: 'cent',
    lowerCurrencyPlural: 'cents'
  },
  {
    currencyCode: 'CZK',
    currencyName: 'koruna',
    currencyNamePlural: 'korunas',
    lowerCurrency: 'haléř',
    lowerCurrencyPlural: 'haléřs'
  },
  {
    currencyCode: 'DKK',
    currencyName: 'krone',
    currencyNamePlural: 'krone',
    lowerCurrency: 'Øre',
    lowerCurrencyPlural: 'Øres'
  },
  {
    currencyCode: 'DJF',
    currencyName: 'franc',
    currencyNamePlural: 'franc',
    lowerCurrency: 'centime',
    lowerCurrencyPlural: 'centimes'
  },
  {
    currencyCode: 'XCD',
    currencyName: 'dollar',
    currencyNamePlural: 'dollars',
    lowerCurrency: 'cent',
    lowerCurrencyPlural: 'cents'
  },
  {
    currencyCode: 'DOP',
    currencyName: 'peso',
    currencyNamePlural: 'pesos',
    lowerCurrency: 'centavo',
    lowerCurrencyPlural: 'centavos'
  },
  {
    currencyCode: 'EGP',
    currencyName: 'pound',
    currencyNamePlural: 'pounds',
    lowerCurrency: 'piastre',
    lowerCurrencyPlural: 'piastres'
  },
  {
    currencyCode: 'ERN',
    currencyName: 'nakfa',
    currencyNamePlural: 'nakfas',
    lowerCurrency: 'cent',
    lowerCurrencyPlural: 'cents'
  },
  {
    currencyCode: 'SZL',
    currencyName: 'lilangeni',
    currencyNamePlural: 'lilangenis',
    lowerCurrency: 'cent',
    lowerCurrencyPlural: 'cents'
  },
  {
    currencyCode: 'ZAR',
    currencyName: 'rand',
    currencyNamePlural: 'rands',
    lowerCurrency: 'cent',
    lowerCurrencyPlural: 'cents'
  },
  {
    currencyCode: 'ETB',
    currencyName: 'birr',
    currencyNamePlural: 'birrs',
    lowerCurrency: 'santim',
    lowerCurrencyPlural: 'santims'
  },
  {
    currencyCode: 'FKP',
    currencyName: 'pound',
    currencyNamePlural: 'pounds',
    lowerCurrency: 'penny',
    lowerCurrencyPlural: 'pennies'
  },
  {
    currencyCode: 'FJD',
    currencyName: 'dollar',
    currencyNamePlural: 'dollars',
    lowerCurrency: 'cent',
    lowerCurrencyPlural: 'cents'
  },
  {
    currencyCode: 'XPF',
    currencyName: 'franc',
    currencyNamePlural: 'franc',
    lowerCurrency: 'centime',
    lowerCurrencyPlural: 'centimes'
  },
  {
    currencyCode: 'GMD',
    currencyName: 'dalasi',
    currencyNamePlural: 'dalasi',
    lowerCurrency: 'butut',
    lowerCurrencyPlural: 'butut'
  },
  {
    currencyCode: 'GEL',
    currencyName: 'lari',
    currencyNamePlural: 'laris',
    lowerCurrency: 'tetri',
    lowerCurrencyPlural: 'tetris'
  },
  {
    currencyCode: 'GHS',
    currencyName: 'cedi',
    currencyNamePlural: 'cedis',
    lowerCurrency: 'pesewa',
    lowerCurrencyPlural: 'pesewas'
  },
  {
    currencyCode: 'GIP',
    currencyName: 'pound',
    currencyNamePlural: 'pounds',
    lowerCurrency: 'penny',
    lowerCurrencyPlural: 'pennies'
  },
  {
    currencyCode: 'GTQ',
    currencyName: 'quetzal',
    currencyNamePlural: 'quetzals',
    lowerCurrency: 'centavo',
    lowerCurrencyPlural: 'centavos'
  },
  {
    currencyCode: 'GNF',
    currencyName: 'franc',
    currencyNamePlural: 'franc',
    lowerCurrency: 'centime',
    lowerCurrencyPlural: 'centimes'
  },
  {
    currencyCode: 'GYD',
    currencyName: 'dollar',
    currencyNamePlural: 'dollars',
    lowerCurrency: 'cent',
    lowerCurrencyPlural: 'cents'
  },
  {
    currencyCode: 'HTG',
    currencyName: 'gourde',
    currencyNamePlural: 'gourdes',
    lowerCurrency: 'centime',
    lowerCurrencyPlural: 'centimes'
  },
  {
    currencyCode: 'HNL',
    currencyName: 'lempira',
    currencyNamePlural: 'lempiras',
    lowerCurrency: 'centavo',
    lowerCurrencyPlural: 'centavos'
  },
  {
    currencyCode: 'HKD',
    currencyName: 'dollar',
    currencyNamePlural: 'dollars',
    lowerCurrency: 'cent',
    lowerCurrencyPlural: 'cents'
  },
  {
    currencyCode: 'HUF',
    currencyName: 'forint',
    currencyNamePlural: 'forints',
    lowerCurrency: 'Fillér',
    lowerCurrencyPlural: 'Fillérs'
  },
  {
    currencyCode: 'ISK',
    currencyName: 'króna',
    currencyNamePlural: 'krónas',
    lowerCurrency: 'eyrir',
    lowerCurrencyPlural: 'eyrirs'
  },
  {
    currencyCode: 'IDR',
    currencyName: 'rupiah',
    currencyNamePlural: 'rupiahs',
    lowerCurrency: 'sen',
    lowerCurrencyPlural: 'sens'
  },
  {
    currencyCode: 'IRR',
    currencyName: 'rial',
    currencyNamePlural: 'rials',
    lowerCurrency: 'rial',
    lowerCurrencyPlural: 'rials'
  },
  {
    currencyCode: 'IMP',
    currencyName: 'pound',
    currencyNamePlural: 'pounds',
    lowerCurrency: 'penny',
    lowerCurrencyPlural: 'pennies'
  },
  {
    currencyCode: 'IQD',
    currencyName: 'dinar',
    currencyNamePlural: 'dinars',
    lowerCurrency: 'fils',
    lowerCurrencyPlural: 'fils'
  },
  {
    currencyCode: 'ILS',
    currencyName: 'shekel',
    currencyNamePlural: 'shekels',
    lowerCurrency: 'agora',
    lowerCurrencyPlural: 'agoras'
  },
  {
    currencyCode: 'JMD',
    currencyName: 'dollar',
    currencyNamePlural: 'dollars',
    lowerCurrency: 'cent',
    lowerCurrencyPlural: 'cents'
  },
  {
    currencyCode: 'JPY',
    currencyName: 'yen',
    currencyNamePlural: 'yens',
    lowerCurrency: 'sen',
    lowerCurrencyPlural: 'sens'
  },
  {
    currencyCode: 'JEP',
    currencyName: 'pound',
    currencyNamePlural: 'pounds',
    lowerCurrency: 'penny',
    lowerCurrencyPlural: 'pennies'
  },
  {
    currencyCode: 'JOD',
    currencyName: 'dinar',
    currencyNamePlural: 'dinars',
    lowerCurrency: 'piastre',
    lowerCurrencyPlural: 'piastres'
  },
  {
    currencyCode: 'KZT',
    currencyName: 'tenge',
    currencyNamePlural: 'tenges',
    lowerCurrency: 'tıyn',
    lowerCurrencyPlural: 'tıyns'
  },
  {
    currencyCode: 'KES',
    currencyName: 'shilling',
    currencyNamePlural: 'shillings',
    lowerCurrency: 'cent',
    lowerCurrencyPlural: 'cents'
  },
  {
    currencyCode: 'KID',
    currencyName: 'dollar',
    currencyNamePlural: 'dollars',
    lowerCurrency: 'cent',
    lowerCurrencyPlural: 'cents'
  },
  {
    currencyCode: 'KPW',
    currencyName: 'won',
    currencyNamePlural: 'wons',
    lowerCurrency: 'chon',
    lowerCurrencyPlural: 'chons'
  },
  {
    currencyCode: 'KRW',
    currencyName: 'won',
    currencyNamePlural: 'wons',
    lowerCurrency: 'jeon',
    lowerCurrencyPlural: 'jeons'
  },
  {
    currencyCode: 'KWD',
    currencyName: 'dinar',
    currencyNamePlural: 'dinars',
    lowerCurrency: 'fils',
    lowerCurrencyPlural: 'fils'
  },
  {
    currencyCode: 'KGS',
    currencyName: 'som',
    currencyNamePlural: 'soms',
    lowerCurrency: 'tyiyn',
    lowerCurrencyPlural: 'tyiyns'
  },
  {
    currencyCode: 'LAK',
    currencyName: 'kip',
    currencyNamePlural: 'kips',
    lowerCurrency: 'att',
    lowerCurrencyPlural: 'atts'
  },
  {
    currencyCode: 'LBP',
    currencyName: 'pound',
    currencyNamePlural: 'pounds',
    lowerCurrency: 'piastre',
    lowerCurrencyPlural: 'piastres'
  },
  {
    currencyCode: 'LSL',
    currencyName: 'loti',
    currencyNamePlural: 'lotis',
    lowerCurrency: 'sente',
    lowerCurrencyPlural: 'sentes'
  },
  {
    currencyCode: 'LRD',
    currencyName: 'dollar',
    currencyNamePlural: 'dollars',
    lowerCurrency: 'cent',
    lowerCurrencyPlural: 'cents'
  },
  {
    currencyCode: 'LYD',
    currencyName: 'dinar',
    currencyNamePlural: 'dinars',
    lowerCurrency: 'dirham',
    lowerCurrencyPlural: 'dirham'
  },
  {
    currencyCode: 'MOP',
    currencyName: 'pataca',
    currencyNamePlural: 'patacas',
    lowerCurrency: 'avo',
    lowerCurrencyPlural: 'avos'
  },
  {
    currencyCode: 'MGA',
    currencyName: 'ariary',
    currencyNamePlural: 'ariaries',
    lowerCurrency: 'iraimbilanja',
    lowerCurrencyPlural: 'iraimbilanjas'
  },
  {
    currencyCode: 'MWK',
    currencyName: 'kwacha',
    currencyNamePlural: 'kwachas',
    lowerCurrency: 'tambala',
    lowerCurrencyPlural: 'tambalas'
  },
  {
    currencyCode: 'MYR',
    currencyName: 'ringgit',
    currencyNamePlural: 'ringgits',
    lowerCurrency: 'cent',
    lowerCurrencyPlural: 'cents'
  },
  {
    currencyCode: 'MVR',
    currencyName: 'rufiyaa',
    currencyNamePlural: 'rufiyaas',
    lowerCurrency: 'laari',
    lowerCurrencyPlural: 'laaris'
  },
  {
    currencyCode: 'MRU',
    currencyName: 'ouguiya',
    currencyNamePlural: 'ouguiyas',
    lowerCurrency: 'khoums',
    lowerCurrencyPlural: 'khoums'
  },
  {
    currencyCode: 'MUR',
    currencyName: 'rupee',
    currencyNamePlural: 'rupees',
    lowerCurrency: 'cent',
    lowerCurrencyPlural: 'cents'
  },
  {
    currencyCode: 'MXN',
    currencyName: 'peso',
    currencyNamePlural: 'pesos',
    lowerCurrency: 'centavo',
    lowerCurrencyPlural: 'centavos'
  },
  {
    currencyCode: 'MDL',
    currencyName: 'leu',
    currencyNamePlural: 'leus',
    lowerCurrency: 'ban',
    lowerCurrencyPlural: 'bans'
  },
  {
    currencyCode: 'MNT',
    currencyName: 'tögrög',
    currencyNamePlural: 'tögrögs',
    lowerCurrency: 'möngö',
    lowerCurrencyPlural: 'möngös'
  },
  {
    currencyCode: 'MAD',
    currencyName: 'dirham',
    currencyNamePlural: 'dirhams',
    lowerCurrency: 'centime',
    lowerCurrencyPlural: 'centimes'
  },
  {
    currencyCode: 'MZN',
    currencyName: 'metical',
    currencyNamePlural: 'meticals',
    lowerCurrency: 'centavo',
    lowerCurrencyPlural: 'centavos'
  },
  {
    currencyCode: 'MMK',
    currencyName: 'kyat',
    currencyNamePlural: 'kyats',
    lowerCurrency: 'pya',
    lowerCurrencyPlural: 'pyas'
  },
  {
    currencyCode: 'NAD',
    currencyName: 'dollar',
    currencyNamePlural: 'dollars',
    lowerCurrency: 'cent',
    lowerCurrencyPlural: 'cents'
  },
  {
    currencyCode: 'NPR',
    currencyName: 'rupee',
    currencyNamePlural: 'rupees',
    lowerCurrency: 'paise',
    lowerCurrencyPlural: 'paise'
  },
  {
    currencyCode: 'NIO',
    currencyName: 'córdoba',
    currencyNamePlural: 'córdobas',
    lowerCurrency: 'centavo',
    lowerCurrencyPlural: 'centavos'
  },
  {
    currencyCode: 'NGN',
    currencyName: 'naira',
    currencyNamePlural: 'nairas',
    lowerCurrency: 'kobo',
    lowerCurrencyPlural: 'kobos'
  },
  {
    currencyCode: 'MKD',
    currencyName: 'denar',
    currencyNamePlural: 'denars',
    lowerCurrency: 'deni',
    lowerCurrencyPlural: 'denis'
  },
  {
    currencyCode: 'TRY',
    currencyName: 'lira',
    currencyNamePlural: 'liras',
    lowerCurrency: 'kuruş',
    lowerCurrencyPlural: 'kuruş'
  },
  {
    currencyCode: 'NOK',
    currencyName: 'krone',
    currencyNamePlural: 'krone',
    lowerCurrency: 'Øre',
    lowerCurrencyPlural: 'Øres'
  },
  {
    currencyCode: 'OMR',
    currencyName: 'rial',
    currencyNamePlural: 'rials',
    lowerCurrency: 'baisa',
    lowerCurrencyPlural: 'baisa'
  },
  {
    currencyCode: 'PKR',
    currencyName: 'rupee',
    currencyNamePlural: 'rupees',
    lowerCurrency: 'paise',
    lowerCurrencyPlural: 'paise'
  },
  {
    currencyCode: 'ILS',
    currencyName: 'shekel',
    currencyNamePlural: 'shekels',
    lowerCurrency: 'agora',
    lowerCurrencyPlural: 'agoras'
  },
  {
    currencyCode: 'JOD',
    currencyName: 'dinar',
    currencyNamePlural: 'dinars',
    lowerCurrency: 'piastre',
    lowerCurrencyPlural: 'piastres'
  },
  {
    currencyCode: 'PGK',
    currencyName: 'kina',
    currencyNamePlural: 'kinas',
    lowerCurrency: 'toea',
    lowerCurrencyPlural: 'toeas'
  },
  {
    currencyCode: 'PYG',
    currencyName: 'guaraní',
    currencyNamePlural: 'guaraní',
    lowerCurrency: 'céntimo',
    lowerCurrencyPlural: 'céntimos'
  },
  {
    currencyCode: 'PEN',
    currencyName: 'sol',
    currencyNamePlural: 'sols',
    lowerCurrency: 'céntimo',
    lowerCurrencyPlural: 'céntimos'
  },
  {
    currencyCode: 'PHP',
    currencyName: 'peso',
    currencyNamePlural: 'pesos',
    lowerCurrency: 'sentimo',
    lowerCurrencyPlural: 'sentimos'
  },
  {
    currencyCode: 'PND',
    currencyName: 'dollar',
    currencyNamePlural: 'dollars',
    lowerCurrency: 'cent',
    lowerCurrencyPlural: 'cents'
  },
  {
    currencyCode: 'PLN',
    currencyName: 'złoty',
    currencyNamePlural: 'złoties',
    lowerCurrency: 'grosz',
    lowerCurrencyPlural: 'grosz'
  },
  {
    currencyCode: 'QAR',
    currencyName: 'riyal',
    currencyNamePlural: 'riyals',
    lowerCurrency: 'dirham',
    lowerCurrencyPlural: 'dirhams'
  },
  {
    currencyCode: 'RON',
    currencyName: 'leu',
    currencyNamePlural: 'leus',
    lowerCurrency: 'ban',
    lowerCurrencyPlural: 'bans'
  },
  {
    currencyCode: 'RWF',
    currencyName: 'franc',
    currencyNamePlural: 'franc',
    lowerCurrency: 'centime',
    lowerCurrencyPlural: 'centimes'
  },
  {
    currencyCode: 'WST',
    currencyName: 'tālā',
    currencyNamePlural: 'tālās',
    lowerCurrency: 'sene',
    lowerCurrencyPlural: 'senes'
  },
  {
    currencyCode: 'STN',
    currencyName: 'dobra',
    currencyNamePlural: 'dobra',
    lowerCurrency: 'céntimo',
    lowerCurrencyPlural: 'céntimos'
  },
  {
    currencyCode: 'SAR',
    currencyName: 'riyal',
    currencyNamePlural: 'riyals',
    lowerCurrency: 'halala',
    lowerCurrencyPlural: 'halalas'
  },
  {
    currencyCode: 'RSD',
    currencyName: 'dinar',
    currencyNamePlural: 'dinars',
    lowerCurrency: 'para',
    lowerCurrencyPlural: 'paras'
  },
  {
    currencyCode: 'SCR',
    currencyName: 'rupee',
    currencyNamePlural: 'rupees',
    lowerCurrency: 'cent',
    lowerCurrencyPlural: 'cents'
  },
  {
    currencyCode: 'SLL',
    currencyName: 'leone',
    currencyNamePlural: 'leones',
    lowerCurrency: 'cent',
    lowerCurrencyPlural: 'cents'
  },
  {
    currencyCode: 'SBD',
    currencyName: 'dollar',
    currencyNamePlural: 'dollars',
    lowerCurrency: 'cent',
    lowerCurrencyPlural: 'cents'
  },
  {
    currencyCode: 'SOS',
    currencyName: 'shilling',
    currencyNamePlural: 'shillings',
    lowerCurrency: 'cent',
    lowerCurrencyPlural: 'cents'
  },
  {
    currencyCode: 'SLS',
    currencyName: 'shilling',
    currencyNamePlural: 'shillings',
    lowerCurrency: 'cent',
    lowerCurrencyPlural: 'cents'
  },
  {
    currencyCode: 'SSP',
    currencyName: 'pound',
    currencyNamePlural: 'pounds',
    lowerCurrency: 'piaster',
    lowerCurrencyPlural: 'piaster'
  },
  {
    currencyCode: 'LKR',
    currencyName: 'rupee',
    currencyNamePlural: 'rupees',
    lowerCurrency: 'cent',
    lowerCurrencyPlural: 'cents'
  },
  {
    currencyCode: 'SDG',
    currencyName: 'pound',
    currencyNamePlural: 'pounds',
    lowerCurrency: 'piaster',
    lowerCurrencyPlural: 'piaster'
  },
  {
    currencyCode: 'SRD',
    currencyName: 'dollar',
    currencyNamePlural: 'dollars',
    lowerCurrency: 'cent',
    lowerCurrencyPlural: 'cents'
  },
  {
    currencyCode: 'SEK',
    currencyName: 'krona',
    currencyNamePlural: 'kronas',
    lowerCurrency: 'Öre',
    lowerCurrencyPlural: 'Öres'
  },
  {
    currencyCode: 'CHF',
    currencyName: 'franc',
    currencyNamePlural: 'franc',
    lowerCurrency: 'centime',
    lowerCurrencyPlural: 'centimes'
  },
  {
    currencyCode: 'SYP',
    currencyName: 'pound',
    currencyNamePlural: 'pounds',
    lowerCurrency: 'piaster',
    lowerCurrencyPlural: 'piaster'
  },
  {
    currencyCode: 'TWD',
    currencyName: 'dollar',
    currencyNamePlural: 'dollars',
    lowerCurrency: 'cent',
    lowerCurrencyPlural: 'cents'
  },
  {
    currencyCode: 'TJS',
    currencyName: 'somoni',
    currencyNamePlural: 'somonis',
    lowerCurrency: 'diram',
    lowerCurrencyPlural: 'diram'
  },
  {
    currencyCode: 'TZS',
    currencyName: 'shilling',
    currencyNamePlural: 'shillings',
    lowerCurrency: 'cent',
    lowerCurrencyPlural: 'cents'
  },
  {
    currencyCode: 'THB',
    currencyName: 'baht',
    currencyNamePlural: 'bahts',
    lowerCurrency: 'satang',
    lowerCurrencyPlural: 'satangs'
  },
  {
    currencyCode: 'TOP',
    currencyName: 'paʻanga',
    currencyNamePlural: 'paʻangas',
    lowerCurrency: 'seniti',
    lowerCurrencyPlural: 'senitis'
  },
  {
    currencyCode: 'PRB',
    currencyName: 'ruble',
    currencyNamePlural: 'rubles',
    lowerCurrency: 'kopek',
    lowerCurrencyPlural: 'kopeks'
  },
  {
    currencyCode: 'TTD',
    currencyName: 'dollar',
    currencyNamePlural: 'dollars',
    lowerCurrency: 'cent',
    lowerCurrencyPlural: 'cents'
  },
  {
    currencyCode: 'TND',
    currencyName: 'dinar',
    currencyNamePlural: 'dinars',
    lowerCurrency: 'millime',
    lowerCurrencyPlural: 'millimes'
  },
  {
    currencyCode: 'TMT',
    currencyName: 'manat',
    currencyNamePlural: 'manats',
    lowerCurrency: 'tennesi',
    lowerCurrencyPlural: 'tennesis'
  },
  {
    currencyCode: 'UAH',
    currencyName: 'hryvnia',
    currencyNamePlural: 'hryvnias',
    lowerCurrency: 'kopiyka',
    lowerCurrencyPlural: 'kopiykas'
  },
  {
    currencyCode: 'AED',
    currencyName: 'dirham',
    currencyNamePlural: 'dirhams',
    lowerCurrency: 'fils',
    lowerCurrencyPlural: 'fils'
  },
  {
    currencyCode: 'UZS',
    currencyName: 'soʻm',
    currencyNamePlural: 'soʻms',
    lowerCurrency: 'tiyin',
    lowerCurrencyPlural: 'tiyins'
  },
  {
    currencyCode: 'VUV',
    currencyName: 'vatu',
    currencyNamePlural: 'vatu',
    lowerCurrency: '',
    lowerCurrencyPlural: ''
  },
  {
    currencyCode: 'VES',
    currencyName: 'soberano',
    currencyNamePlural: 'soberanos',
    lowerCurrency: 'céntimo',
    lowerCurrencyPlural: 'céntimos'
  },
  {
    currencyCode: 'VND',
    currencyName: 'đồng',
    currencyNamePlural: 'đồngs',
    lowerCurrency: 'hào',
    lowerCurrencyPlural: 'hàos'
  },
  {
    currencyCode: 'YER',
    currencyName: 'rial',
    currencyNamePlural: 'rials',
    lowerCurrency: 'fils',
    lowerCurrencyPlural: 'fils'
  },
  {
    currencyCode: 'ZMW',
    currencyName: 'kwacha',
    currencyNamePlural: 'kwachas',
    lowerCurrency: 'ngwee',
    lowerCurrencyPlural: 'ngwees'
  },
  {
    currencyCode: 'ZWB',
    currencyName: 'dollar',
    currencyNamePlural: 'dollars',
    lowerCurrency: '',
    lowerCurrencyPlural: ''
  }
];

export const UCQ_OPTIONS: KeyAndValue[] = [
  { key: 'NOT-APPLICABLE', value: 'NOT' },
  { key: 'BAG-BAGS', value: 'BAG' },
  { key: 'BAL-BALE', value: 'BAL' },
  { key: 'BDL-BUNDLES', value: 'BDL' },
  { key: 'BGS-BAGS', value: 'BGS' },
  { key: 'BKL-BUCKLES', value: 'BKL' },
  { key: 'BND-BUNDLES', value: 'BND' },
  { key: 'BOU-BILLION OF UNITS', value: 'BOU' },
  { key: 'BOX-BOX', value: 'BOX' },
  { key: 'BTL-BOTTLES', value: 'BTL' },
  { key: 'BUN-BUNCHES', value: 'BUN' },
  { key: 'CAN-CANS', value: 'CAN' },
  { key: 'CBM-CUBIC METERS', value: 'CBM' },
  { key: 'CCM-CUBIC CENTIMETERS', value: 'CCM' },
  { key: 'CMS-CENTI METERS', value: 'CMS' },
  { key: 'CTN-CARTONS', value: 'CTN' },
  { key: 'DOZ-DOZENS', value: 'DOZ' },
  { key: 'DRM-DRUMS', value: 'DRM' },
  { key: 'DZN-DOZENS', value: 'DZN' },
  { key: 'GGK-GREAT GROSS', value: 'GGK' },
  { key: 'GMS-GRAMMES', value: 'GMS' },
  { key: 'GRS-GROSS', value: 'GRS' },
  { key: 'GYD-GROSS YARDS', value: 'GYD' },
  { key: 'KGS-KILOGRAMS', value: 'KGS' },
  { key: 'KLR-KILOLITRE', value: 'KLR' },
  { key: 'KME-KILOMETRE', value: 'KME' },
  { key: 'KMS-KILO METERS', value: 'KMS' },
  { key: 'LTR-LITER', value: 'LTR' },
  { key: 'MLS-MILLI LITRES', value: 'MLS' },
  { key: 'MLT-MILILITRE', value: 'MLT' },
  { key: 'MTR-METERS', value: 'MTR' },
  { key: 'MTS-METRIC TON', value: 'MTS' },
  { key: 'NOS-NUMBERS', value: 'NOS' },
  { key: 'OTH-OTHERS', value: 'OTH' },
  { key: 'PAC-PACKS', value: 'PAC' },
  { key: 'PAR-PAIRS', value: 'PAR' },
  { key: 'PCS-PIECES', value: 'PCS' },
  { key: 'PRS-PAIRS', value: 'PRS' },
  { key: 'QTL-QUINTAL', value: 'QTL' },
  { key: 'QTS-QUINTALS', value: 'QTS' },
  { key: 'ROL-ROLLS', value: 'ROL' },
  { key: 'SET-SETS', value: 'SET' },
  { key: 'SNO-THOUSAND NUMBERS/UNITS', value: 'SNO' },
  { key: 'SQF-SQUARE FEET', value: 'SQF' },
  { key: 'SQM-SQUARE METERS', value: 'SQM' },
  { key: 'SQY-SQUARE YARDS', value: 'SQY' },
  { key: 'TBS-TABLETS', value: 'TBS' },
  { key: 'TGM-TEN GROSS', value: 'TGM' },
  { key: 'THD-THOUSANDS', value: 'THD' },
  { key: 'TON-TONNES', value: 'TON' },
  { key: 'TUB-TUBES', value: 'TUB' },
  { key: 'UGS-US GALLONS', value: 'UGS' },
  { key: 'UNT-UNITS', value: 'UNT' },
  { key: 'YDS-YARDS', value: 'YDS' }
];
