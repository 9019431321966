import { useContext, useEffect, useRef, useState } from 'react';
import { DKDataGrid } from 'deskera-ui-library';
import { CommonDraftPropsContext } from '../../Utilities/DocContext';
import { useAppDispatch, useAppSelector } from '../../../../Redux/Hooks';
import {
  addNewItemToDocument,
  onDocumentFormFieldUpdate,
  onRowDragEnd,
  selectDocumentFormDataByKeys,
  selectShouldUpdateColumnConfig,
  setDocColumns,
  setIsDocumentTouched,
  updateMultipleKeysInDocument
} from '../../../../Redux/Slices/DocumentSlice';
import { DocumentConfigManager } from '../../../../Managers/DocumentConfigManger';
import {
  DOCUMENT_MODE,
  DOC_TYPE,
  PRODUCT_TYPE,
  TAX_SYSTEM
} from '../../../../Constants/Constant';
import useScreenResize from '../../../../Hooks/useScreenResize';
import {
  fetchUOMs,
  selectProductCustomFields,
  selectUOMs
} from '../../../../Redux/Slices/CommonDataSlice';
import {
  CASCADING_DISCOUNT_PREFIX,
  getComponentDetailsForDocumentLineItems,
  getTenantTaxSystem,
  updateColumnConfigOnRowClick
} from '../../../../SharedComponents/DocumentForm/NewDocumentHelper';
import Utility, { deepClone } from '../../../../Utility/Utility';
import { DataGridCallBackParam, IColumn } from '../../../../Models/Table';
import {
  canUpdateDocument,
  getBlankRowItem,
  isDropShipDocument,
  isPriceBookEnabled,
  isPriceListEnabled
} from '../../Utilities/DocCommonUtils';
import { DraftTypes } from '../../../../Models/Drafts';
import {
  DOCUMENT_KEYS,
  DOC_LINE_ITEM_KEYS
} from '../../Utilities/DocConstants';
import { updateColumnConfig } from '../../Utilities/DocColumnConfig';
import { selectProductsWithVariants } from '../../../../Redux/Slices/ProductsSlice';
import {
  getUOMByProduct,
  getUnitPriceListByProduct
} from '../../Helper/Common/DocDataHelper';
import { selectDimensions } from '../../../../Redux/Slices/LocationSlice';
import {
  getRowButtons,
  getRowContextMenu
} from '../../Helper/View/DocRowButtons';
import {
  calculateTaxesForUS,
  calculateLineItemTaxesAndAmount,
  onRowUpdate,
  getAdditionalFieldsForDoc
} from '../../Helper/DocRowHelper';
import PriceListService from '../../../../Services/PriceList';
import {
  CHANGE_TYPE,
  updatePriceFromPriceList
} from '../../Helper/DocumentUpdates/PriceListHelper';
import PriceBookSelector from '../Common/PriceBookSelector';
import { selectContactsColumnConfig } from '../../../../Redux/Slices/ContactsSlice';
import { Store } from '../../../../Redux/Store';
import {
  activeTenantInfo,
  featurePermissions
} from '../../../../Redux/Slices/AuthSlice';
import { FEATURE_PERMISSIONS } from '../../../../Constants/Permission';
import { getDocumentByIDFromStore } from '../../Helper/DocumentHelper';
import {
  COMMON_EVENTS,
  DOC_POPUP_TYPE,
  commonCustomEvent
} from '../../../../Services/event/commonEvents';
import { cloneDeep } from 'lodash';

export interface IDocGridProps {
  amortizationTemplates?: any[];
  copyOfDocument?: any;
}
/**
 *
 * @desc
 */
const DocGrid = (props: IDocGridProps) => {
  const { draftId, documentMode, draftType } = useContext(
    CommonDraftPropsContext
  );

  const dispatch = useAppDispatch();
  const [
    items,
    contact,
    contactDto,
    contactCode,
    documentType,
    dropShip,
    fulfillmentType,
    isDocumentTouched,
    fulfillmentStatus,
    paymentStatus,
    receiveGoodsStatus,
    totalAmount,
    memo,
    applyRcmCheck
  ] = useAppSelector(
    selectDocumentFormDataByKeys(draftId, [
      DOCUMENT_KEYS.ITEMS,
      DOCUMENT_KEYS.CONTACT,
      DOCUMENT_KEYS.CONTACT_DTO,
      DOCUMENT_KEYS.CONTACT_CODE,
      DOCUMENT_KEYS.DOCUMENT_TYPE,
      DOCUMENT_KEYS.DROPSHIP,
      DOCUMENT_KEYS.FULFILLMENT_TYPE,
      DOCUMENT_KEYS.IS_DOC_TOUCHED,
      DOCUMENT_KEYS.FULFILLMENT_STATUS,
      DOCUMENT_KEYS.PAYMENT_STATUS,
      DOCUMENT_KEYS.RECEIVE_GOODS_STATUS,
      DOCUMENT_KEYS.TOTAL_AMOUNT,
      DOCUMENT_KEYS.MEMO,
      DOCUMENT_KEYS.APPLY_RCM_CHECK
    ])
  );
  const columnConfig = DocumentConfigManager.get(documentType);
  const productsData = useAppSelector(selectProductsWithVariants);
  const productCFData = useAppSelector(selectProductCustomFields);
  const uomsData = useAppSelector(selectUOMs);
  const dimensionData = useAppSelector(selectDimensions);
  const contactColumnConfig = useAppSelector(selectContactsColumnConfig);
  const tenantInfo = useAppSelector(activeTenantInfo);
  const featurePermissionsInfo = useAppSelector(featurePermissions);

  const [columns, setColumns] = useState(columnConfig);
  const [productUOMs, setProductUOMs] = useState<any[]>([]);
  const [productPriceLists, setProductPriceList] = useState<any[]>([]);
  const gridContainerRef = useRef<HTMLDivElement | null>(null);
  const [gridWidth] = useScreenResize(gridContainerRef);
  const [productCustomFields, setProductCustomFields] = useState<any[]>([]);
  const shouldUpdateColumnConfig = useAppSelector(
    selectShouldUpdateColumnConfig()
  );
  const [priceList, setPriceList] = useState<any>({ unitPriceList: [] });
  const [amortizationTemplates, setAmortizationTemplates] = useState<any[]>([]);

  const isViewMode = documentMode === DOCUMENT_MODE.VIEW;
  const isReadOnly = draftType === DraftTypes.READONLY;
  const isBill = documentType === DOC_TYPE.BILL;
  const amortizationEnabled =
    tenantInfo?.additionalSettings?.AMORTIZATION &&
    featurePermissionsInfo?.Supported?.includes(
      FEATURE_PERMISSIONS.AMORTIZATION
    );
  const canUpdateDocumentData = canUpdateDocument({
    documentType,
    fulfillmentStatus,
    paymentStatus,
    draftType,
    receiveGoodsStatus,
    totalAmount
  });

  useEffect(() => {
    if (Utility.isEmptyObject(uomsData)) {
      dispatch(fetchUOMs);
    }
    if (isBill && amortizationEnabled) {
      return;
    } else {
      setInitialData();
    }
  }, []);

  useEffect(() => {
    setAmortizationTemplates(props.amortizationTemplates || []);
    if (isBill && amortizationEnabled) {
      setInitialData(props.amortizationTemplates || []);
    }
  }, [props.amortizationTemplates]);

  const setInitialData = (amortizationTemplates: any[] = []) => {
    const keysToUpdate = getAdditionalFieldsForDoc({
      draftId,
      documentMode,
      UOMData: uomsData ?? [],
      productCustomFields: productCFData?.content ?? [],
      draftType,
      amortizationTemplates
    });
    dispatch(updateMultipleKeysInDocument({ draftId, keysToUpdate }));
    if (!Utility.isNotEmpty(items)) {
      const document: any = getDocumentByIDFromStore(draftId) ?? {};
      if (
        !tenantInfo?.additionalSettings?.PRODUCT_GROUP_ENABLED &&
        documentType !== DOC_TYPE.JOB_WORK_OUT_ORDER
      ) {
        dispatch(
          addNewItemToDocument({
            draftId,
            item: getBlankRowItem(
              document,
              columns,
              documentMode,
              draftType,
              productCFData?.content ?? []
            )
          })
        );
      }
    }
  };

  useEffect(() => {
    updateColumns();
  }, [
    items,
    shouldUpdateColumnConfig,
    productUOMs,
    productPriceLists,
    dimensionData,
    productCustomFields
  ]);

  useEffect(() => {
    if (!Utility.isEmpty(productCFData) && productCFData?.content?.length) {
      let prodCFs = [...productCFData?.content];
      prodCFs.sort(
        (field1: any, field2: any) =>
          field1.customFieldIndex - field2.customFieldIndex
      );
      setProductCustomFields(prodCFs);
    }
  }, [productCFData]);

  useEffect(() => {
    if (Utility.isNotEmpty(items) && Utility.isNotEmpty(contact)) {
      const docContactCode =
        contactCode || contactDto?.code || contact?.code || '';
      let productCodes: string[] = [];
      items.forEach((item: any) => {
        if (!Utility.isEmpty(item.product)) {
          const code = item.product.productId || item.productCode;
          productCodes.push(code);
        }
      });

      if (
        Utility.isNotEmpty(productCodes) &&
        documentType !== DOC_TYPE.JOB_WORK_OUT_ORDER
      ) {
        PriceListService.fetchLastPricesOfProduct(
          productCodes,
          documentType,
          docContactCode
        ).then((res: any) => {
          if (res) {
            setPriceList(res);
          }
        });
      }
    }
  }, [items, contact]);

  const updateColumns = () => {
    const updatedColumnConf = updateColumnConfig({
      columnConfig,
      draftId,
      draftType,
      documentMode,
      productCustomFields,
      dimensionData,
      productUOMs,
      amortizationTemplates,
      products: productsData?.content ?? [],
      priceList: productPriceLists
    });
    dispatch(setDocColumns(JSON.stringify(updatedColumnConf)));
    setColumns(updatedColumnConf.filter((column) => !column.hidden));
  };

  const getUpdatedMemoKeysOnProductChange = (lineUpdates: any) => {
    let memoText: string = memo ?? '';
    if (getTenantTaxSystem() === TAX_SYSTEM.UK) {
      if (
        lineUpdates.tax &&
        lineUpdates.tax.defaultMemoUk &&
        lineUpdates.tax.defaultMemoUk !== ''
      ) {
        memoText = lineUpdates?.tax?.defaultMemoUk;
      }
      return {
        [DOCUMENT_KEYS.MEMO]: memoText.trim(),
        [DOCUMENT_KEYS.PREVIOUS_MEMO]: memo
      };
    }
    return {
      [DOCUMENT_KEYS.MEMO]: memo || '',
      [DOCUMENT_KEYS.PREVIOUS_MEMO]: memo || ''
    };
  };

  const handleProductQuantityChangeForComponentList = async (
    updatedItem: any
  ) => {
    let newUpdatedLineItem: any;
    try {
      if (updatedItem?.type === PRODUCT_TYPE.BILL_OF_MATERIALS) {
        newUpdatedLineItem = await getComponentDetailsForDocumentLineItems(
          [{ ...updatedItem }],
          contact,
          documentType
        );
        let updatedLineItem: any = newUpdatedLineItem?.[0]; // due to single dropdown selection
        return updatedLineItem;
      }
      return updatedItem;
    } catch (err: any) {
      console.error('Error fetching component list details: ', err);
      return updatedItem;
    }
  };

  /**
   *
   * @description This method is side effect of onRowUpdate method which does tax calculation, price books updated
   * @param {DataGridCallBackParam} updatedData {rowIndex, rowData, columnKey}
   * @param {Array} updatedRowItems response from onRowUpdate()
   */
  const afterRowUpdated = async (
    updatedData: DataGridCallBackParam<any>,
    updatedRowItems: any[]
  ) => {
    updatedRowItems = cloneDeep(updatedRowItems);
    const calculationTriggeringColumnChanged = [
      DOC_LINE_ITEM_KEYS.DISCOUNT,
      DOC_LINE_ITEM_KEYS.PRODUCT,
      DOC_LINE_ITEM_KEYS.PRODUCT_QUANTITY,
      DOC_LINE_ITEM_KEYS.TAX,
      DOC_LINE_ITEM_KEYS.TAX_AMOUNT,
      DOC_LINE_ITEM_KEYS.UNIT_PRICE,
      DOC_LINE_ITEM_KEYS.UOM
    ].includes(updatedData.columnKey as DOC_LINE_ITEM_KEYS);
    const priceListTriggeringColumnChanged = [
      DOC_LINE_ITEM_KEYS.PRODUCT,
      DOC_LINE_ITEM_KEYS.PRODUCT_QUANTITY,
      DOC_LINE_ITEM_KEYS.UOM
    ].includes(updatedData.columnKey as DOC_LINE_ITEM_KEYS);
    const cascadingDiscountUpdated = (updatedData.columnKey ?? '')
      ?.toString()
      .startsWith(CASCADING_DISCOUNT_PREFIX);

    if (
      calculationTriggeringColumnChanged &&
      updatedData.columnKey === DOC_LINE_ITEM_KEYS.PRODUCT
    ) {
      if (Utility.isComponentDetailsForFGOnInvoiceSOQuote()) {
        const updatedItem = await handleProductQuantityChangeForComponentList(
          updatedRowItems?.[updatedData.rowIndex]
        );
        if (!Utility.isEmptyObject(updatedItem)) {
          updatedRowItems[updatedData.rowIndex] = { ...updatedItem };
        }
      }
      // Set document touched
      if (!isDocumentTouched) {
        Store.dispatch(
          setIsDocumentTouched({
            draftId: draftId,
            isDocumentTouched: true
          })
        );
      }
    }

    if (updatedData.columnKey === DOC_LINE_ITEM_KEYS.PRODUCT_QUANTITY) {
      // Update component list on product qty change
      if (
        Utility.isComponentDetailsForFGOnInvoiceSOQuote() &&
        Utility.isNotEmpty(
          updatedRowItems?.[updatedData.rowIndex]?.['bomComponentGroupDetails']
        )
      ) {
        const updatedItem = await handleProductQuantityChangeForComponentList(
          updatedRowItems?.[updatedData.rowIndex]
        );
        if (!Utility.isEmptyObject(updatedItem)) {
          updatedRowItems[updatedData.rowIndex] = { ...updatedItem };
        }
      }
    }

    if (calculationTriggeringColumnChanged || cascadingDiscountUpdated) {
      let updatedRow = { ...updatedRowItems[updatedData.rowIndex] };
      if (
        getTenantTaxSystem() === TAX_SYSTEM.US &&
        updatedData.columnKey !== DOC_LINE_ITEM_KEYS.TAX_AMOUNT
      ) {
        calculateTaxesForUS({
          draftId,
          indexToUpdate: updatedData.rowIndex,
          updatedItems: updatedRowItems
        });
      } else {
        updatedRow = calculateLineItemTaxesAndAmount(
          updatedRow,
          documentType,
          contact,
          updatedRow.gstType,
          applyRcmCheck
        );
        let items = [...updatedRowItems];
        items[updatedData.rowIndex] = { ...updatedRow };

        let objectToUpdateOnStore: any = { items };

        // Update Memo for UK
        if (
          getTenantTaxSystem() === TAX_SYSTEM.UK &&
          (updatedData.columnKey === DOC_LINE_ITEM_KEYS.PRODUCT ||
            updatedData.columnKey === DOC_LINE_ITEM_KEYS.TAX)
        ) {
          const memoObj = getUpdatedMemoKeysOnProductChange(updatedRow);
          objectToUpdateOnStore = {
            ...objectToUpdateOnStore,
            ...memoObj
          };
        }

        dispatch(
          updateMultipleKeysInDocument({
            draftId,
            keysToUpdate: { ...objectToUpdateOnStore }
          })
        );
      }
    }

    if (
      (isPriceListEnabled() || isPriceBookEnabled()) &&
      priceListTriggeringColumnChanged
    ) {
      // make price list API call
      switch (updatedData.columnKey) {
        case DOC_LINE_ITEM_KEYS.PRODUCT:
          // Price List Call: for product change
          updatePriceFromPriceList({
            draftId,
            documentMode,
            change: {
              type: CHANGE_TYPE.PRODUCT_CHANGED,
              rowIndex: updatedData.rowIndex
            },
            contactColumnConfig
          });
          break;

        case DOC_LINE_ITEM_KEYS.PRODUCT_QUANTITY:
          // Price List Call: for product quantity
          updatePriceFromPriceList({
            draftId,
            documentMode,
            change: {
              type: CHANGE_TYPE.QUANTITY_CHANGED,
              rowIndex: updatedData.rowIndex
            },
            contactColumnConfig
          });
          break;

        case DOC_LINE_ITEM_KEYS.UOM:
          // Price List Call: for UOM
          updatePriceFromPriceList({
            draftId,
            documentMode,
            change: {
              type: CHANGE_TYPE.UOM_CHANGED,
              rowIndex: updatedData.rowIndex
            },
            contactColumnConfig
          });
          break;
      }
    }
  };

  const handleRowUpdate = (updatedData: DataGridCallBackParam<any>) => {
    const updatedRowItems = onRowUpdate({
      ...updatedData,
      draftId,
      documentMode,
      productCustomFields,
      amortizationTemplates,
      columnConfig: columns
    });
    dispatch(
      onDocumentFormFieldUpdate({
        draftId,
        dataKey: DOCUMENT_KEYS.ITEMS,
        value: updatedRowItems
      })
    ).then((res: any) => afterRowUpdated(updatedData, updatedRowItems));
  };

  const handleRowClick = (updatedData: DataGridCallBackParam<any>) => {
    const currentProduct = updatedData.rowData?.product;
    setProductUOMs(getUOMByProduct(currentProduct, uomsData));
    setProductPriceList(
      getUnitPriceListByProduct(currentProduct, priceList ?? [])
    );
    try {
      const copyOfColumns = [...columns];
      const updatedColumnData = updateColumnConfigOnRowClick(
        updatedData.columnData,
        updatedData.rowData,
        copyOfColumns,
        productCFData?.content || [],
        {}
      );
      const columnIndexToUpdate = copyOfColumns?.findIndex(
        (column: any) => column?.key === updatedColumnData?.key
      );
      if (columnIndexToUpdate > -1) {
        copyOfColumns[columnIndexToUpdate] = updatedColumnData;
        dispatch(setDocColumns(JSON.stringify(copyOfColumns)));
        setColumns(copyOfColumns.filter((column: IColumn) => !column.hidden));
      }
    } catch (error) {
      console.log(error);
    }

    if (Utility.isUKOrg() && updatedData?.columnData?.key === 'taxAmount') {
      commonCustomEvent.dispatch(COMMON_EVENTS.DOC_POPUP_SHOW, {
        type: DOC_POPUP_TYPE.SHOW_TAX_GROUP_DETAILS_POPUP_UK,
        ...updatedData
      });
    }
  };

  const handleRowDragEnd = (fromIndex: number, toIndex: number) => {
    dispatch(onRowDragEnd({ draftId, fromIndex, toIndex }));
  };

  /**
   * @description - this function takes original rows and put additional fields like rowButtons, rowContextMenu...
   * @param rows - actual rows needs to be updated
   */
  const putAdditionalFieldsInRowData: any = (rows: any) => {
    let updatedRows = deepClone(rows);

    return updatedRows.map((row: any, index: number) => {
      if (
        !isDropShipDocument({ dropShip, fulfillmentType }) &&
        canUpdateDocumentData &&
        (!props?.copyOfDocument?.reservedStock ||
          draftType === DraftTypes.DRAFT)
      ) {
        row = {
          ...row,
          rowContextMenu:
            isViewMode || isReadOnly
              ? []
              : getRowContextMenu({
                  draftId,
                  draftType,
                  row,
                  productCustomFields,
                  documentMode,
                  columns
                }),
          rowButtons:
            isViewMode || isReadOnly
              ? []
              : getRowButtons({
                  draftId,
                  row
                })
        };
      }
      return row;
    });
  };

  return (
    <div
      ref={gridContainerRef}
      className={`column parent-width mt-l ${
        isViewMode ? 'pointer-events-auto' : ''
      }`}
    >
      {isPriceBookEnabled() && <PriceBookSelector className="mb-r" />}
      <DKDataGrid
        needShadow={false}
        needColumnIcons={false}
        needBorder={true}
        needTrailingColumn={true}
        allowBulkOperation={false}
        allowColumnSort={false}
        allowColumnShift={false}
        filterData={[]}
        allowColumnDelete={false}
        allowRowEdit={true}
        allowColumnEdit={false}
        allowFilter={false}
        allowColumnAdd={false}
        allowBottomRowAdd={false}
        allowSearch={false}
        allowShare={false}
        allowRowDrag={!isViewMode}
        onDragRowEnd={handleRowDragEnd}
        rows={putAdditionalFieldsInRowData(items)}
        columns={columns}
        onRowUpdate={handleRowUpdate}
        onRowClick={handleRowClick}
        width={gridWidth}
      />
    </div>
  );
};

export default DocGrid;
