import { useEffect, useState } from 'react';
import { showAlert } from 'deskera-ui-library';
import Utility, { deepClone } from '../../Utility/Utility';
import {
  APPROVAL_STATUS,
  BOOKS_DATE_FORMAT,
  DOCUMENT_MODE,
  DOC_PATH_WITH_ID_REGEX,
  DOC_TYPE,
  LABELS,
  POPUP_CALLBACKS_TYPE,
  RECORD_SAVED_EVENT_DOC_TYPE
} from '../../Constants/Constant';
import NewDocument2 from '../../SharedComponents/DocumentForm/NewDocument2';
import { Document } from '../../Models/Document';
import {
  deleteDrafts,
  draftTableId,
  draftTypeColumnId,
  fetchDrafts,
  isSaveColumnId,
  removeDraft,
  selectDrafts,
  selectDraftsColumnConfig,
  setDraftActionAvailibility,
  setDraftValidationDisplayStatus,
  updatePopulateFormData
} from '../../Redux/Slices/DraftsSlice';
import { useAppDispatch, useAppSelector } from '../../Redux/Hooks';
import {
  PurchaseOrder,
  OrderInitialState,
  OrderProps
} from '../../Models/Order';
import OrderService from '../../Services/PurchaseOrder';
import {
  fetchOrders,
  updateRefreshCallback
} from '../../Redux/Slices/PurchaseOrdersSlice';
import { setItemsFromPurchaseOrderItems } from './PurchaseOrderHelper';
import { activeTenantInfo, userInfo } from '../../Redux/Slices/AuthSlice';
import { COMPLIANCE_SPECIFIC_FIELD_NAME } from '../../Constants/Enum';
import {
  cascadingDiscountsInvalidMessage,
  checkGSTINPresentForSelectedContact,
  checkIfTotalDiscountInvalid,
  convertExpectedDeliveryDateInString,
  customFieldsContainsErrors,
  getDocumentAlert,
  inactiveContactMessage,
  isDocContactInactive,
  rebuildCascadingDiscountsForSaving,
  removeUnwantedPayloadKeysForDocument,
  showAlertOnDocAPIError,
  updateAddressAsPerLocationCF
} from '../../SharedComponents/DocumentForm/NewDocumentHelper';
import { fetchInvoices } from '../../Redux/Slices/InvoicesSlice';
import { fetchQuotes } from '../../Redux/Slices/QuotesSlice';
import { fetchapprovalConditionList } from '../../Redux/Slices/AutomationSlice';
import AuthService from '../../Services/Auth';
import PurchaseOrderService from '../../Services/PurchaseOrder';
import NumberFormatService from '../../Services/NumberFormat';
import { DraftTypes } from '../../Models/Drafts';
import RouteManager, { PAGE_ROUTES } from '../../Managers/RouteManager';
import DateFormatService from '../../Services/DateFormat';
import { PERMISSIONS_BY_MODULE } from '../../Constants/Permission';
import { fetchSalesOrders } from '../../Redux/Slices/SalesOrderSlice';
import { useHistory } from 'react-router-dom';
import DraftService from '../../Services/Drafts';
import { ADDITIONAL_CHARGE_METHODS } from '../../SharedComponents/AdditionalCharges/AdditionalCharges';
import { documentUpdated, selectTaxes } from '../../Redux/Slices/CommonDataSlice';
import { updatePOPRAssociation } from '../../Redux/Slices/MRP/WorkOrderSlice';
import {
  COMMON_EVENTS,
  IRecordSavedEventData,
  commonCustomEvent
} from '../../Services/event/commonEvents';
import MachineMaterialShortFallService from '../../Services/MachineMaterialShortFallService';
import { handleMachineShortFallPOCreate } from '../Mrp/MachineMaster/MachineMasterHelper';

const NewPurchaseOrder: React.FC<OrderProps> = (props) => {
  const [isCenterAlign, setIsCenterAlign] = useState<boolean>(
    props.isCenterAlign
  );
  const [order, setOrder] = useState<PurchaseOrder>(
    !Utility.isEmpty(props.populateFormData)
      ? setItemsFromPurchaseOrderItems(props.populateFormData)
      : { ...OrderInitialState }
  );
  const [updatedOrder, setUpdatedOrder] = useState<PurchaseOrder>(order);
  const draftsTableId = useAppSelector(draftTableId);
  const isSavedColumnId = useAppSelector(isSaveColumnId);
  const draftTypeColId = useAppSelector(draftTypeColumnId);
  const tenantInfo = useAppSelector(activeTenantInfo);
  const dispatch = useAppDispatch();
  let history = useHistory();
  const userDetails = useAppSelector(userInfo);
  const draftsColumnConfig = useAppSelector(selectDraftsColumnConfig);
  const taxes = useAppSelector(selectTaxes);

  const registerInteractions = () => {
    /*
     * register parents calls to child methods
     */

    if (props.passingInteraction)
      props.passingInteraction({
        type: POPUP_CALLBACKS_TYPE.SAVE_AS_DRAFT,
        data: async () => {
          const isApprovalRequired = await Utility.isApprovalRequired(
            updatedOrder
          );
          onSaveAsDraft(isApprovalRequired);
        }
      });

    if (props.passingInteraction)
      props.passingInteraction({
        type: POPUP_CALLBACKS_TYPE.CLOSE_DRAFT_POPUP,
        data: () => {
          closeDraftPopup();
        }
      });

    if (props.passingInteraction) {
      props.passingInteraction({
        type: POPUP_CALLBACKS_TYPE.UPDATE_ORDER,
        data: () => {
          updateOrderDetails();
        }
      });
    }

    if (props.passingInteraction) {
      props.passingInteraction({
        type: POPUP_CALLBACKS_TYPE.CREATE_ORDER,
        data: (closeDoc: boolean) => {
          createOrder(closeDoc);
        }
      });
    }
  };

  useEffect(() => {
    if (order && order.dropShip && props.documentMode === DOCUMENT_MODE.EDIT) {
      showAlert(
        'Dropship Purchase Order',
        'This is a Dropship document and has only limited edit functionality'
      );
    }
    setIsCenterAlign(props.isCenterAlign);
    loadApprovalConditionList();
    return () => {
      console.log('cleanup..');
    };
  }, []);

  useEffect(() => {
    setIsCenterAlign(props.isCenterAlign);
  }, [props]);

  useEffect(() => {
    registerInteractions();
  });

  useEffect(() => {
    if (!Utility.isEmpty(props.populateFormData)) {
      setOrder({ ...props.populateFormData });
    }
  }, [props.populateFormData]);

  useEffect(() => {
    const onLinkedRecordUpdated = (data: { detail: IRecordSavedEventData }) => {
      if (
        !data?.detail ||
        data.detail.linkedDocType !== DOC_TYPE.ORDER ||
        data.detail.linkedDocId !== updatedOrder.poCode
      )
        return;

      updateForm(updatedOrder, updatedOrder, true);
    };

    commonCustomEvent.on(
      COMMON_EVENTS.RECORD_SAVED,
      onLinkedRecordUpdated,
      true
    );

    return () =>
      commonCustomEvent.remove(
        COMMON_EVENTS.RECORD_SAVED,
        onLinkedRecordUpdated
      );
  }, [updatedOrder]);

  const loadApprovalConditionList = () => {
    dispatch(fetchapprovalConditionList());
  };

  const onSaveAsDraft = (isApprovalRequired: boolean) => {
    let payload = { ...updatedOrder };

    if (isApprovalRequired) {
      payload['approvalStatus'] = APPROVAL_STATUS['PENDING_FOR_APPROVAL'];
      payload['multiApprovalDetails'] = {
        currentLevel: payload?.['multiApprovalDetails']?.currentLevel || 1,
        approvalHistory:
          payload?.['multiApprovalDetails']?.approvalHistory || [],
        approvalRequiredFor: userDetails?.[0]?.email || ''
      };
    } else {
      payload['approvalStatus'] = payload['approvalStatus']
        ? payload['approvalStatus']
        : APPROVAL_STATUS['NOT_REQUIRED'];
    }
    payload['createdUserName'] = AuthService.getUserName();
    let parsedItems = payload?.items;
    parsedItems = parsedItems?.map((poItem: any) => {
      return {
        ...poItem,
        expectedDeliveryDt: DateFormatService.getDateStrFromDate(
          poItem.expectedDeliveryDt,
          BOOKS_DATE_FORMAT['DD-MM-YYYY']
        )
      };
    });
    payload.items = parsedItems;
    payload.purchaseOrderItems = parsedItems;

    // here we explicitly remove few keys, which is not needed to be saved in component list feature
    payload = removeUnwantedPayloadKeysForDocument(payload);

    setButtonStatus(true);
    props.formData(payload);

    if (isApprovalRequired) {
      sendTriggerOnApproval(payload);
      // if (props.documentMode === DOCUMENT_MODE.COPY) {
      //   let draftsData: any = {
      //     data: {
      //       type: LABELS.PURCHASE_ORDERS,
      //       tableId: draftsTableId,
      //       columnConfig: draftsColumnConfig
      //     }
      //   };
      //   DraftService.createRecord(payload, draftsData).then((response: any) => {
      //     dispatch(
      //       fetchDrafts({
      //         tableId: draftsTableId,
      //         isSaveColumnId: isSavedColumnId,
      //         draftTypeColId: draftTypeColId,
      //         draftTypeColValue: LABELS.PURCHASE_ORDERS
      //       })
      //     );
      //   });
      // }
    }
    replaceURLToModuleURL();
  };

  // Change URL to base module URL
  const replaceURLToModuleURL = () => {
    if (
      DOC_PATH_WITH_ID_REGEX.test(history.location?.pathname) &&
      history.location?.pathname?.includes(PAGE_ROUTES.ORDERS)
    ) {
      history.replace(PAGE_ROUTES.ORDERS);
    }
  };

  const closeDraftPopup = () => {
    dispatch(removeDraft(props.draftData.id));
    replaceURLToModuleURL();
  };

  const isDocValid = (docToValidate: any) => {
    // Validate Manual Document Sequence Code
    if (
      Utility.isEmpty(docToValidate.documentSequenceCode) &&
      Utility.isEmpty(docToValidate.sequenceFormat) &&
      docToValidate.manualMode
    ) {
      return false;
    }

    if (Utility.isEmpty(docToValidate.contact)) {
      return false;
    }

    if (Utility.isEmpty(docToValidate.purchaseOrderItems)) {
      return false;
    }

    if (!Utility.isEmpty(docToValidate.documentDate)) {
      const docDate = DateFormatService.getDateFromStr(
        docToValidate.documentDate,
        BOOKS_DATE_FORMAT['DD-MM-YYYY']
      );
      if (
        !Utility.checkActiveDateRangeValidation(
          docDate,
          tenantInfo,
          'Order date',
          docToValidate.documentType
        )
      ) {
        return false;
      }
      if (!Utility.checkClosingDate(docDate, 'Order date')) {
        return false;
      }
    }

    // Custom fields validation
    const customFieldHasErrors = customFieldsContainsErrors(
      docToValidate.customField
    );
    if (customFieldHasErrors) {
      return false;
    }
    // Custom fields validation ends

    // Line item errors
    let lineItemsHasErrors = false;
    for (let i = 0; i < docToValidate.purchaseOrderItems.length; i++) {
      const item = docToValidate.purchaseOrderItems[i];
      if (item.hasError || item.invalidFields?.length) {
        lineItemsHasErrors = true;
        break;
      }
    }

    if (lineItemsHasErrors) {
      return false;
    }
    // Line item errors ends

    // Contact GSTIN check
    if (!checkGSTINPresentForSelectedContact(docToValidate)) {
      return false;
    }
    // Contact GSTIN check ends

    // Check for -ve total amount
    const totalBeforeTax = docToValidate?.purchaseOrderItems?.length
      ? docToValidate?.purchaseOrderItems?.reduce(
          (total: number, item: any) => {
            return total + item?.totalWithDiscount;
          },
          0
        )
      : 0;
    if (totalBeforeTax < 0) {
      showAlert(
        'Invalid amount!',
        'Purchase order amount can not be less than 0.'
      );
      return false;
    }
    // Check for -ve total amount ends

    // Cascading discounts validation
    const cascadingDiscountSettings =
      tenantInfo.additionalSettings?.CASCADING_DISCOUNTS;
    if (cascadingDiscountSettings?.enable) {
      const isTotalDiscountInvalid = checkIfTotalDiscountInvalid(
        docToValidate,
        'purchaseOrderItems'
      );
      if (isTotalDiscountInvalid) {
        showAlert('Error!', cascadingDiscountsInvalidMessage);
        return false;
      }
    }
    // Cascading discounts validation ends

    // Additional charges check
    let additionalChargesHasErrors = false;
    const additionalChargesDetails =
      docToValidate.additionalCharges?.additionalChargesDetails;
    if (!Utility.isEmpty(additionalChargesDetails)) {
      for (let i = 0; i < additionalChargesDetails.length; i++) {
        const item = additionalChargesDetails[i];
        if (item.hasError) {
          additionalChargesHasErrors = true;
          break;
        }
      }
    }
    if (additionalChargesHasErrors) {
      return false;
    }

    let manualApportionCharges = additionalChargesDetails.filter(
      (item: any) =>
        item.apportionValue &&
        item.apportionValue === ADDITIONAL_CHARGE_METHODS.APPORTION_MANUAL
    );
    let manualApportionError = false;
    for (let charge of manualApportionCharges) {
      let manualApportionChargesInItems: any[] = [];

      docToValidate.purchaseOrderItems.forEach((item: any) => {
        const additionalCharge =
          item.additionalCharges.additionalChargesDetails.find(
            (c: any) => c.additionalCharge === charge.additionalCharge
          );
        if (!Utility.isEmpty(additionalCharge)) {
          manualApportionChargesInItems.push(additionalCharge);
        }
      });
      if (manualApportionChargesInItems.length) {
        const manualApportionChargesTotal =
          manualApportionChargesInItems.reduce(
            (total: number, detail: any) =>
              total + Number(detail?.chargeAmount || 0),
            0
          );
        if (manualApportionChargesTotal !== +charge.chargeAmount) {
          manualApportionError = true;
          break;
        }
      }
    }
    if (manualApportionError) {
      showAlert(
        'Oops!',
        `One or more line items contains incorrect allocation for additional charges`
      );
      return false;
    }

    // Additional charges check ends

    // Discount check
    const discountHasErrors =
      docToValidate.additionalCharges?.globalDiscount?.hasError;
    if (discountHasErrors) {
      return false;
    }
    // Discount check ends

    return true;
  };

  const isTaxValid = (payload:any) => {
    if(payload && payload.purchaseOrderItems && Array.isArray(payload.purchaseOrderItems)) {
      payload.purchaseOrderItems.forEach((item:any)=>{
        if(!(item.tax && taxes && Array.isArray(taxes) && taxes.find((tx:any)=>tx.code === item.tax))) {
          item.tax = null;
        }
      })
    }
    return payload
  }
  const setButtonStatus = (status: boolean) => {
    dispatch(
      setDraftActionAvailibility({ id: props.draftData.id, status: status })
    );
  };

  const setValidationDisplayStatus = (status: boolean) => {
    dispatch(
      setDraftValidationDisplayStatus({
        id: props.draftData.id,
        status: status
      })
    );
  };

  const updateForm = (
    docResp: any,
    payload: any,
    isUpdate = false,
    closeOnUpdate = true
  ) => {
    PurchaseOrderService.fetchOrderDetails(docResp.poCode).then(
      (doc: any) => {
        const formData = {
          ...doc,
          documentType: DOC_TYPE.ORDER,
          items: [...doc.purchaseOrderItems],
          documentDate: payload.documentDate,
          fulfillmentDate: payload.fulfillmentDate,
          validTillDate: payload.validTillDate,
          contactCode: payload.contactCode
        };
        if (!closeOnUpdate) {
          dispatch(
            updatePopulateFormData({
              id: props.draftData.id,
              formdata: formData,
              draftType: DraftTypes.UPDATE,
              actionFromDocument: true
            })
          );
        } else {
          dispatch(removeDraft(props.draftData.id));
          if (!isUpdate) {
            if (payload.showCustomAlert) {
              showAlert(
                'Purchase Order Created!',
                `Purchase Order has been created successfully. ${
                  props.populateFormData?.isCreateFromMRP
                    ? 'Please save current WO to link the created PO.'
                    : ''
                }`
              );
              dispatch(updateRefreshCallback(new Date()));
              return;
            }
            getDocumentAlert(
              'Purchase Order created!',
              'Purchase Order has been created successfully.',
              formData,
              props.draftData,
              DOCUMENT_MODE.EDIT,
              PAGE_ROUTES.ORDERS
            );
            setButtonStatus(false);
          }
        }
      },
      (err) => {
        console.error('Error loading updated doc: ', err);
      }
    );
  };

  // Create Order
  const createOrder = async (closeDoc: boolean) => {
    setButtonStatus(true);
    setValidationDisplayStatus(true);

    let payload: any = deepClone(updatedOrder);
    payload = updateAddressAsPerLocationCF(payload);
    if (tenantInfo.additionalSettings?.CASCADING_DISCOUNTS?.enable) {
      payload = {
        ...payload,
        purchaseOrderItems: rebuildCascadingDiscountsForSaving(payload)
      };
    }
    if (
      payload.documentType === DOC_TYPE.BILL ||
      payload.documentType === DOC_TYPE.ORDER
    ) {
      payload = {
        ...payload,
        purchaseOrderItems: convertExpectedDeliveryDateInString(payload)
      };
    }
    delete payload?.items;
    if (
      (props.documentMode === DOCUMENT_MODE.COPY ||
        props.documentMode === DOCUMENT_MODE.NEW) &&
      payload?.duplicate
    ) {
      const isContactInactive = isDocContactInactive(payload?.contact);
      if (isContactInactive) {
        showAlert('Error', inactiveContactMessage);
        setButtonStatus(false);
        return;
      }
    }

    if (payload.attachmentIds?.length) {
      payload.attachments = payload.attachmentIds.map(
        (attachmentId: any) => `${attachmentId}`
      );
    }

    if (!isDocValid(payload)) {
      setButtonStatus(false);
      return;
    }

    let isApproval = await Utility.isApprovalRequired(payload);
    if (isApproval === null) {
      setButtonStatus(false);
      return;
    } else if (isApproval) {
      onSaveAsDraft(isApproval);
      return;
    } else {
      payload['approvalStatus'] = APPROVAL_STATUS['NOT_REQUIRED'];
    }

    if (props.draftData?.draftType === DraftTypes.DRAFT) {
      payload[
        'draftReferenceId'
      ] = `${props.draftData?.data?.tableId}/record/${props.draftData?.data?.id}`;
    }
    payload = isTaxValid(payload);

    OrderService.createOrder(
      payload,
      Utility.getTenantSpecificApiCode(
        COMPLIANCE_SPECIFIC_FIELD_NAME.PURCHASE_ORDER
      )
    ).then(
      (response: any) => {
        console.log('PAYLOAD');
        console.log(payload);
        if (props.draftData) {
          if (closeDoc) {
            // Remove draft popup
            dispatch(removeDraft(props.draftData.id));
          }
          if (props.draftData.draftType === DraftTypes.DRAFT) {
            dispatch(
              deleteDrafts({
                recordId: props.draftData.id,
                tableId: draftsTableId
              })
            );
          }
          updateForm(response, payload, false);
        } else {
          setButtonStatus(false);
        }
        dispatch(
          fetchDrafts({
            tableId: draftsTableId,
            isSaveColumnId: isSavedColumnId,
            draftTypeColId: draftTypeColId,
            draftTypeColValue: LABELS.PURCHASE_ORDERS
          })
        );

        handleMachineShortFallPOCreate(payload, response);

        if (response?.dropShip || response?.backOrder) {
          switch (response?.linkedDocuments?.[0]?.documentType) {
            case DOC_TYPE.INVOICE:
              dispatch(fetchInvoices());
              break;
            case DOC_TYPE.QUOTE:
              dispatch(fetchQuotes());
              break;
            case DOC_TYPE.SALES_ORDER:
              dispatch(fetchSalesOrders());
              break;
            default:
              break;
          }
        } else {
          dispatch(fetchOrders());
        }

        // for MRP
        if (payload?.updateResponseInStore) {
          let updatedRes: any = { ...response };
          if (payload?.isNonShortFallSettingFlow) {
            updatedRes = {
              ...updatedRes,
              isNonShortFallSettingFlow: payload?.isNonShortFallSettingFlow
            };
          }
          dispatch(updatePOPRAssociation(updatedRes));
        }

        replaceURLToModuleURL();
      },
      (err) => {
        console.error('Error while creating Order: ', err);
        setButtonStatus(false);
        showAlertOnDocAPIError(err);
        replaceURLToModuleURL();
      }
    );
  };

  const updateOrderDetails = (closeOnUpdate = true) => {
    setButtonStatus(true);
    setValidationDisplayStatus(true);

    let payload: any = deepClone(updatedOrder);
    payload = { ...payload, contact: payload.contactDto };
    payload = updateAddressAsPerLocationCF(payload);
    if (tenantInfo.additionalSettings?.CASCADING_DISCOUNTS?.enable) {
      payload = {
        ...payload,
        purchaseOrderItems: rebuildCascadingDiscountsForSaving(payload)
      };
    }
    if (
      payload.documentType === DOC_TYPE.BILL ||
      payload.documentType === DOC_TYPE.ORDER
    ) {
      payload = {
        ...payload,
        purchaseOrderItems: convertExpectedDeliveryDateInString(payload)
      };
    }
    delete payload?.items;
    delete payload?.contactDto;

    if (payload.attachmentIds?.length) {
      payload.attachments = payload.attachmentIds.map(
        (attachmentId: any) => `${attachmentId}`
      );
    }

    if (!isDocValid(payload)) {
      setButtonStatus(false);
      return;
    }
    payload = isTaxValid(payload)
    OrderService.updateOrder(
      payload,
      Utility.getTenantSpecificApiCode(
        COMPLIANCE_SPECIFIC_FIELD_NAME.PURCHASE_ORDER
      )
    ).then(
      (res: any) => {
        if (props.draftData) {
          if (closeOnUpdate) {
            dispatch(removeDraft(props.draftData.id));
          }
          setButtonStatus(false);
          updateForm(res, payload, true, closeOnUpdate);
        }

        commonCustomEvent.dispatch(COMMON_EVENTS.RECORD_SAVED, {
          id: res?.poCode,
          type: RECORD_SAVED_EVENT_DOC_TYPE.PURCHASE_ORDER,
          linkedDocId: res?.linkedDocuments?.[0]?.documentCode,
          linkedDocType:
            res?.linkedDocuments?.[0]?.documentType ||
            RECORD_SAVED_EVENT_DOC_TYPE.BILL,
          isEdit: true
        });

        dispatch(
          fetchDrafts({
            tableId: draftsTableId,
            isSaveColumnId: isSavedColumnId,
            draftTypeColId: draftTypeColId,
            draftTypeColValue: LABELS.PURCHASE_ORDERS
          })
        );
        dispatch(fetchOrders());
        setButtonStatus(false);
        replaceURLToModuleURL();
        if (payload?.paymentMilestoneFlag) {
          dispatch(
            documentUpdated({
              newData: payload,
              oldData: props.populateFormData
            })
          );
        }
      },
      (err) => {
        console.error('Error updating PO: ', err);
        setButtonStatus(false);
        showAlertOnDocAPIError(err);
        replaceURLToModuleURL();
      }
    );
  };

  const handleOrderUpdate = (doc: Document) => {
    if (tenantInfo.additionalSettings?.CASCADING_DISCOUNTS?.enable) {
      doc = {
        ...doc,
        items: rebuildCascadingDiscountsForSaving(doc)
      };
    }
    let orderWithUpdate: PurchaseOrder = {
      ...order,
      ...doc,
      purchaseOrderItems: doc.items,
      orderDueDate: doc.validTillDate,
      dueDate: doc.validTillDate,
      receiveByDate: doc.fulfillmentDate,
      vendorCode: doc.contactCode,
      currencyCode: doc.currencyCode,
      currency: doc.currency,
      documentDate: doc.documentDate,
      totalAmount: doc.totalAmount,
      dropShipName: doc.dropShipName || ''
    };

    let editBillWithExtraKeys = {
      documentCode: '',
      documentType: DOC_TYPE.ORDER,
      currencyCode: doc.currencyCode,
      currency: doc.currency,
      priceListId: doc?.priceListId,
      priceListName: doc?.priceListName,
      entityId:
        props.documentMode === DOCUMENT_MODE.EDIT ? order?.id : undefined,
      purchaseOrderItems: orderWithUpdate.purchaseOrderItems?.map((billDoc) => {
        return {
          ...billDoc,
          documentItemCode: billDoc.purchaseOrderItemCode
        };
      })
    };

    if (props.documentMode === DOCUMENT_MODE.EDIT) {
      orderWithUpdate = { ...orderWithUpdate, ...editBillWithExtraKeys };
    }
    setUpdatedOrder(orderWithUpdate);
  };

  const sendTriggerOnApproval = (payload: any) => {
    let emails = Utility.getApproverEmail(payload);
    let sum =
      payload &&
      payload.purchaseOrderItems
        .map((item: any) => item.totalAmount)
        .reduce((prev: any, curr: any) => prev + curr, 0);
    let payloadObj = {
      contactCode: payload.contactCode,
      totalAmount: NumberFormatService.getNumber(sum),
      userName: AuthService.getUserName(),
      currency: Utility.getCurrencySymbolFromCode(payload.currency),
      approverMap: Object.fromEntries(emails),
      currentLevel: 1,
      approvalHistory: payload['multiApprovalDetails']?.approvalHistory || []
    };
    PurchaseOrderService.sendTriggerOnApproval(payloadObj).then(
      (response: any) => {},
      (err: any) => {
        console.error('Error while creating draft: ', err);
      }
    );
  };

  return (
    <NewDocument2
      permissionKeys={PERMISSIONS_BY_MODULE.PURCHASE_ORDER}
      booksDocument={order}
      documentMode={props.documentMode ? props.documentMode : DOCUMENT_MODE.NEW}
      draftData={props.draftData}
      draftType={props.draftData.draftType}
      canValidate={props.draftData.canValidate}
      onDocumentUpdate={(x: Document) => handleOrderUpdate(x)}
      isCenterAlign={isCenterAlign}
      updateDocWithoutClosing={() => updateOrderDetails(false)}
      auditLogView={props.auditLogView}
    />
  );
};

export default NewPurchaseOrder;
