import { FC } from 'react';
import { DKIcon, DKLabel } from 'deskera-ui-library';
import NumberFormatService from '../../../../../Services/NumberFormat';
import { useAppSelector } from '../../../../../Redux/Hooks';
import { activeTenantInfo } from '../../../../../Redux/Slices/AuthSlice';
import { Utility } from 'deskera-doc-builder-lib';
import DocCurrencySelector from './DocCurrencySelector';

interface ITitleAndAmountProps {
  title: string;
  amount: number;
  icon?: any;
  titleClassName?: string;
  amountClassName?: string;
  currencyCode?: string;
  needCurrencySelector?: boolean;
}

const TitleAndAmount: FC<ITitleAndAmountProps> = ({
  amount,
  title,
  icon,
  titleClassName,
  amountClassName,
  currencyCode,
  needCurrencySelector
}) => {
  const { multicurrencyEnabled } = useAppSelector(activeTenantInfo);

  const formattedAmount = `${
    amount < 0 ? '(' : ''
  }${NumberFormatService.getNumber(Math.abs(amount))}${amount < 0 ? ')' : ''}`;

  const showCurrencySelector = multicurrencyEnabled && needCurrencySelector;

  return (
    <>
      <div
        className="row parent-width mb-m justify-content-between align-items-start"
        style={{ width: '100%' }}
      >
        <div
          className="row width-auto"
          style={{
            minWidth: 100
          }}
        >
          {icon}
          <DKLabel text={title} className={'ml-r ' + titleClassName} />
          {showCurrencySelector && <DocCurrencySelector />}
        </div>
        <DKLabel
          text={`${Utility.getCurrencySymbolFromCode(
            currencyCode
          )} ${formattedAmount}`}
          style={{
            wordBreak: 'break-all'
          }}
          className={`ml-r text-wrap ${amountClassName || ''}`}
        />
      </div>
    </>
  );
};

export default TitleAndAmount;
