import React, { useEffect, useRef, useState } from 'react';
import i18n from '../../i18n/i18n';
import { LanguageMenuList } from './LabelList/LanguageMenuList';
import ic_profile_pic from './../../Assets/menu/ic_profile_pic.png';
import ic_language_selection from './../../Assets/menu/ic_language_selection.png';
import {
  DKButton,
  DKLabel,
  DKIcon,
  DKIcons,
  showAlert
} from 'deskera-ui-library';
import ProfileOptionsSection from './ProfileOptionsSection';
import AddNewList from './AddNewList';
import LanguageList from './LanguageList';
import AuthService from '../../Services/Auth';
import {
  BtnType,
  CallBackPayloadType,
  PopupClickActionType,
  UpdateCallBacksRefType
} from '../../Models/Interfaces';
import {
  BOOKS_DATE_FORMAT,
  DOCUMENT_MODE,
  DOC_TYPE,
  LABELS,
  MOBILE_APP_ACTIONS,
  POPUP_CALLBACKS_TYPE,
  POPUP_CLICK_TYPE,
  POPUP_TYPE,
  WALKTHROUGH_STATUS_KEY
} from '../../Constants/Constant';
import PopupWrapper from '../../SharedComponents/PopupWrapper';
import CreateProductView from '../Product/CreateProductView';
import { useTranslation } from 'react-i18next';
import AddContact from '../Contacts/AddContact';
import { Store } from '../../Redux/Store';
import Utility from '../../Utility/Utility';
import { useAppDispatch, useAppSelector } from '../../Redux/Hooks';
import {
  activeTenantInfo,
  fetchSubscriptionInfo,
  permissionInfo,
  subscriptionInfo,
  userInfo
} from '../../Redux/Slices/AuthSlice';
import ApiConstants from '../../Constants/ApiConstants';
import {
  getPlanBadgeStatus,
  getPlanIcon,
  getPlanName,
  getTrialDays,
  getWelcomeName,
  isPlanActive,
  isUserInTrial
} from './MenuHelper';
import AppManager from '../../Managers/AppManager';
import {
  commonCustomEvent,
  COMMON_EVENTS
} from '../../Services/event/commonEvents';
import { AUTHORIZATION_STATUS, WALKTHROUGH_EVENT } from '../../Constants/Enum';
import {
  hideSubscriptinAlert,
  showSubscriptionAlert
} from '../Subscription/SubscriptionAlert';
import { fetchAllUserRoles } from '../../Redux/Slices/PermissionsSlice';
import SideBarService from '../../Services/sidebar';
import {
  isMobileWebView,
  isTabletView,
  isViewportLarge
} from '../../Utility/ViewportSizeUtils';
import { localizedText } from '../../Services/Localization/Localization';
import {
  currentWalkthroughEvents,
  selectCreateWOfromSI,
  selectCreateWOfromSO,
  selectShowSettings,
  selectTopbarExpansion,
  setCreateWOFromSalesInvoice,
  setCreateWOFromSalesOrder,
  setSelectedSetting,
  setShowSettings
} from '../../Redux/Slices/CommonDataSlice';
import AddNewWorkOrder from '../Mrp/WorkOrder/AddWorkOrder';
import RouteManager, { PAGE_ROUTES } from '../../Managers/RouteManager';
import DateFormatService from '../../Services/DateFormat';
import {
  fetchWorkOrderList,
  selectWorkOrderAssociatedSRData,
  updateStockRequestAssociation
} from '../../Redux/Slices/MRP/WorkOrderSlice';
import AddStockRequestPopup from '../StockManagement/StockRequest/AddStockRequest';
import WorkOrderService, {
  WorkOrderAPIConfig
} from '../../Services/MRP/WorkOrder';
import SettingsNew from '../Settings/SettingsNew';

const TopMenu: React.FC<any> = (props) => {
  const store = Store;
  const { t, i18n } = useTranslation();
  let defaultLanguage = LanguageMenuList.find(
    (lan) => lan.code === i18n.language
  );
  const tenantPermissions = useAppSelector(permissionInfo);
  const subscriptionDetails = useAppSelector(subscriptionInfo);
  const userDetails = useAppSelector(userInfo);
  const tenantDetails = useAppSelector(activeTenantInfo);
  const walkthroughEvents = useAppSelector(currentWalkthroughEvents);
  const createWOfromSOSelector = useAppSelector(selectCreateWOfromSO);
  const createWOfromSISelector = useAppSelector(selectCreateWOfromSI);
  const topbarExpanded = useAppSelector(selectTopbarExpansion);

  const [needAlert, setNeedAlert] = useState(false);
  const [needAddNewList, setNeedAddNewList] = useState<boolean>(false);
  const [needLanguageList, setNeedLanguageList] = useState<boolean>(false);
  const [needProfileOptionsSection, setNeedProfileOptionsSection] =
    useState<boolean>(false);
  const [selectedFlag, setSelectedFlag] = useState<any>(defaultLanguage);
  const [showProductPopup, setShowProductPopup] = useState(false);
  const [userModules, setUserModules] = useState<any>([]);
  const [rolePermissions, setRolesPermission] = useState<any>([]);
  const [showContactPopup, setShowContactPopup] = useState(false);
  const [isDesktop, setIsDesktop] = useState(isViewportLarge());
  const [showTopbar, setShowTopbar] = useState(true);
  const [showAddStockRequestPopup, setShowAddStockRequestPopup] =
    useState(false);
  const [woForSalesOrderFlow, setWOForSalesOrderFlow] = useState<any>(null);
  const [woForSalesInvoiceFlow, setWOForSalesInvoiceFlow] = useState<any>(null);
  const [selectedWO, setSelectedWO] = useState(null);
  const stockRequestCreationData = useAppSelector(
    selectWorkOrderAssociatedSRData
  );
  const [showGlobalSettings, setShowGlobalSettings] = useState(false);
  const showSettingsPopup = useAppSelector(selectShowSettings);

  const refInitialState: UpdateCallBacksRefType = {
    pushDataToParent: { type: POPUP_CALLBACKS_TYPE.NONE },
    storeCallbacksRef: {
      createProduct: 'click'
    }
  };

  const popupBtnConfig: BtnType[] = [
    {
      title: 'Cancel',
      class: 'border-m mr-s',
      clickAction: POPUP_CLICK_TYPE.CLOSE_POPUP
    },
    {
      title: 'Create',
      class: 'bg-app text-white mr-ss',
      clickAction: POPUP_CLICK_TYPE.CREATE_CONTACT
    }
  ];

  useEffect(() => {
    const isDemoOrg = tenantDetails?.isDemoOrg;
    AppManager.handleWindowResizeListener(onWindowResize, true);

    if (!isDemoOrg) {
      onWindowFocussed(null);
      AppManager.handleWindowFocusListeners(onWindowFocussed, true);
    }

    return () => {
      AppManager.handleWindowResizeListener(onWindowResize, false);

      if (!isDemoOrg) {
        AppManager.handleWindowFocusListeners(onWindowFocussed, false);
      }
    };
  }, []);

  useEffect(() => {
    if (!Utility.isEmpty(tenantPermissions)) {
      setUserModules(tenantPermissions);
    }
  }, [tenantPermissions]);

  useEffect(() => {
    if (!Utility.isEmpty(userModules)) {
      setRolesPermission(userModules);
    }
  }, [rolePermissions, userModules]);

  useEffect(() => {
    setShowTopbar(topbarExpanded);
  }, [topbarExpanded]);

  useEffect(() => {
    if (!Utility.isEmpty(stockRequestCreationData)) {
      setShowAddStockRequestPopup(true);
    }
  }, [stockRequestCreationData]);

  useEffect(() => {
    if (!Utility.isEmpty(createWOfromSOSelector)) {
      let doc = {
        ...createWOfromSOSelector.salesOrderData,
        salesOrderDate: DateFormatService.getDateFromStr(
          createWOfromSOSelector.salesOrderData?.salesOrderDate,
          BOOKS_DATE_FORMAT['DD-MM-YYYY']
        ),
        salesOrderDueDate: DateFormatService.getDateFromStr(
          createWOfromSOSelector.salesOrderData?.salesOrderDueDate,
          BOOKS_DATE_FORMAT['DD-MM-YYYY']
        ),
        shipByDate: DateFormatService.getDateFromStr(
          createWOfromSOSelector.salesOrderData?.shipByDate,
          BOOKS_DATE_FORMAT['DD-MM-YYYY']
        )
      };
      setWOForSalesOrderFlow(doc);
    }
  }, [createWOfromSOSelector]);

  useEffect(() => {
    if (!Utility.isEmpty(createWOfromSISelector)) {
      let doc = {
        ...createWOfromSISelector.salesInvoiceData,
        salesInvoiceDate: DateFormatService.getDateFromStr(
          createWOfromSISelector.salesInvoiceData?.salesInvoiceDate,
          BOOKS_DATE_FORMAT['DD-MM-YYYY']
        ),
        salesInvoiceDueDate: DateFormatService.getDateFromStr(
          createWOfromSISelector.salesInvoiceData?.salesInvoiceDueDate,
          BOOKS_DATE_FORMAT['DD-MM-YYYY']
        ),
        shipByDate: DateFormatService.getDateFromStr(
          createWOfromSISelector.salesInvoiceData?.shipByDate,
          BOOKS_DATE_FORMAT['DD-MM-YYYY']
        )
      };
      setWOForSalesInvoiceFlow(doc);
    }
  }, [createWOfromSISelector]);

  const isLanguageSelectorVisible = tenantDetails?.additionalSettings?.LANGUAGE_SELECTOR_VISIBLE;
  useEffect(() => {
    if (isLanguageSelectorVisible)
      loadGoogleTranslateScripts();
  }, [isLanguageSelectorVisible]);

  const topMenuRef = useRef<UpdateCallBacksRefType>(refInitialState);
  const dispatch = useAppDispatch();

  const toggleAddNewList = () => {
    setNeedAddNewList(!needAddNewList);
    setNeedLanguageList(false);
  };

  const toggleLanguageList = () => {
    setNeedLanguageList(!needLanguageList);
    setNeedAddNewList(false);
  };

  const hideAddNewList = () => {
    setNeedAddNewList(false);
  };

  const hideLanguageList = () => {
    setNeedLanguageList(false);
  };

  const permissionInfoData = useAppSelector(permissionInfo);
  const [showNoPermissionView, setShowNoPermissionView] = useState(false);
  const [shouldCheckPermission, setShouldCheckPermission] = useState(false);
  //No Permission alert related//
  useEffect(() => {
    if (shouldCheckPermission) {
      checkIfUserHasBooksPermission();
    }
  }, [permissionInfoData]);

  useEffect(() => {
    if (!Utility.isEmpty(walkthroughEvents)) {
      if (walkthroughEvents.includes(WALKTHROUGH_EVENT.OPEN_ADD_NEW_MENU)) {
        setNeedAddNewList(true);
      }
      if (walkthroughEvents.includes(WALKTHROUGH_EVENT.CLOSE_ADD_NEW_MENU)) {
        setNeedAddNewList(false);
      }
    }
  }, [walkthroughEvents]);

  const checkIfUserHasBooksPermission = () => {
    if (Utility.isEmpty(permissionInfoData)) {
      return;
    } else {
      let first = permissionInfoData[0];
      let apps = first.apps;
      let filtered = apps.filter((item: any) => item.appName === 'ERP');
      if (Utility.isEmpty(filtered)) {
        setShowNoPermissionView(true);
      } else {
        setShowNoPermissionView(false);
      }
    }
  };
  const getNoPermissionForBooksView = () => {
    return (
      showNoPermissionView && (
        <div className="transparent-background">
          <div
            className="popup-window"
            style={{
              width: 350,
              height: 160,
              alignItems: 'center',
              justifyContent: 'center',
              borderRadius: 12,
              marginBottom: 500
            }}
          >
            <DKLabel className="alert-title" text="No Permission" />
            <DKLabel
              className="alert-message mt-4"
              text={`You don't have permission to access to Books, please contact your ${localizedText(
                'organisation'
              )}'s owner.`}
            />
          </div>
        </div>
      )
    );
  }; //No Permission alert related done ////

  const onWindowFocussed = (e?: any) => {
    if (document.hidden || !AppManager.needSubscriptionApiCall) return;

    commonCustomEvent.dispatch(COMMON_EVENTS.SUBSCRIPTION_CHECK, {
      status: AUTHORIZATION_STATUS.PENDING
    });

    const loadSubscriptionInfo = async () => {
      try {
        const newRes = await dispatch(fetchSubscriptionInfo());
        return newRes;
      } catch (err) {
        console.error('Error loading subscription: ', err);
        return Promise.reject(err);
      }
    };

    const loadSubscription = loadSubscriptionInfo();
    loadSubscription
      .then(
        (res: any) => {
          if (isPlanActive(res.payload)) {
            setShouldCheckPermission(true);

            /**
             * if subscription api passed on first check
             * ( i.e. function invoked without window focus event )
             * After successful new subscription, it is necessary to call role-permissions api
             */
            const isUserAlreadySubscribed = e === null;
            !isUserAlreadySubscribed && dispatch(fetchAllUserRoles());

            AppManager.needSubscriptionApiCall = false;
            if (!needAlert) {
              setNeedAlert(false);
            }

            /* Tenant permissions, already getting called from App.tsx: loadTenantPermissionUserInfo */
            // dispatch(fetchPermissionInfo());
            hideSubscriptinAlert();
          } else {
            AppManager.needSubscriptionApiCall = true;
            if (!needAlert) {
              setNeedAlert(true);
              showSubscriptionAlert(null);
            }
            commonCustomEvent.dispatch(COMMON_EVENTS.SUBSCRIPTION_CHECK, {
              status: AUTHORIZATION_STATUS.DENIED
            });
          }
        },
        () => {
          // console.log('fail');
          // AppManager.needSubscriptionApiCall = true;
          // setNeedAlert(true);
          // commonCustomEvent.dispatch(COMMON_EVENTS.SUBSCRIPTION_CHECK, {
          //   status: AUTHORIZATION_STATUS.DENIED
          // });
        }
      )
      .catch((err: any) => {
        AppManager.needSubscriptionApiCall = true;
        commonCustomEvent.dispatch(COMMON_EVENTS.SUBSCRIPTION_CHECK, {
          status: AUTHORIZATION_STATUS.DENIED
        });
      });
  };

  const getNoCardView = () => {
    // let helloKeywords = [
    //   'Hi',
    //   'Hello',
    //   'Hey',
    //   'Hola',
    //   'Bonjour',
    //   'Salve',
    //   'Nǐn hǎo',
    //   'Namaste'
    // ];
    // let hey = helloKeywords[Math.floor(Math.random() * helloKeywords.length)];

    let message = getWelcomeName(userDetails);
    const isInTrial = isUserInTrial(subscriptionDetails);
    const getTrialDaysForUser: any = getTrialDays(true, subscriptionDetails);
    return (
      <div className=" display-only-web border-radius-m">
        <div className="row align-items-center">
          <DKLabel text={message} />
          {isInTrial && (
            <>
              <DKLabel
                text={`, Your trial will end ${
                  getTrialDaysForUser
                    ? `in ${getTrialDaysForUser} days`
                    : `today`
                }, `}
                className="fw-m text-red"
              />
              <DKButton
                title={`subscribe now`}
                className="fw-m text-blue text-underline"
                style={{ paddingLeft: 3, paddingRight: 5 }}
                onClick={() => {
                  AppManager.needSubscriptionApiCall = true;
                  window.open(ApiConstants.PRODUCT_URL_GO + 'billing');
                }}
              />
            </>
          )}

          {!Utility.isEmpty(subscriptionDetails?.PlanName) && !isInTrial && (
            <>
              <DKLabel text=", you are on " />
              <DKButton
                title={`${getPlanName(subscriptionDetails?.PlanName)} Plan`}
                className="fw-m text-blue text-underline"
                style={{ paddingLeft: 3, paddingRight: 5 }}
                onClick={() => {
                  AppManager.needSubscriptionApiCall = true;
                  window.open(ApiConstants.PRODUCT_URL_GO + 'billing');
                }}
              />
              <DKIcon
                src={getPlanIcon(subscriptionDetails?.PlanName)}
                style={{ height: 20 }}
              />
              <DKLabel
                text={getPlanBadgeStatus(isPlanActive(subscriptionDetails))}
                className={`${
                  isPlanActive(subscriptionDetails)
                    ? 'bg-chip-green border-green text-green'
                    : 'bg-chip-red border-red text-red'
                } fw-m border-radius-r ml-s p-h-s`}
              />
            </>
          )}
        </div>
      </div>
    );
  };

  const getProfileSection = () => {
    return (
      <div className=" position-relative ml-m">
        <div
          className="cursor-hand"
          onClick={() => {
            setNeedProfileOptionsSection(!needProfileOptionsSection);
          }}
        >
          <DKIcon
            src={ic_profile_pic}
            className="circle "
            style={{
              width: 32,
              height: 32,
              opacity: '0.6'
            }}
          />
        </div>

        {needProfileOptionsSection && getProfileOptionsSection()}
      </div>
    );
  };

  const getProfileOptionsSection = () => {
    return (
      <ProfileOptionsSection
        onClose={() => {
          setNeedProfileOptionsSection(false);
        }}
        openSettings={() => dispatch(setShowSettings(true))}
      />
    );
  };

  const getAddNewList = () => {
    return (
      <AddNewList
        onClose={() => {
          let persistedWalkthroughStatus =
            localStorage.getItem(WALKTHROUGH_STATUS_KEY) !== null
              ? JSON.parse(
                  localStorage.getItem(WALKTHROUGH_STATUS_KEY) as string
                )
              : null;
          if (persistedWalkthroughStatus) {
            if (!persistedWalkthroughStatus?.display) {
              hideAddNewList();
            }
          } else {
            hideAddNewList();
          }
        }}
        hasRoles={rolePermissions}
        setSelectedItem={(itemLabel: any) => {
          if (itemLabel === LABELS.PRODUCTS) {
            setShowProductPopup(true);
          } else if (itemLabel === LABELS.CONTACT) {
            setShowContactPopup(true);
          }
        }}
      />
    );
  };

  const getLanguageList = () => {
    return (
      <LanguageList
        onSelect={changeLanguageSelection}
        onClose={() => hideLanguageList()}
      />
    );
  };

  const changeLanguageSelection = (selected: any) => {
    setSelectedFlag(selected);
    setNeedLanguageList(false);
  };

  const catchClicks = (data: PopupClickActionType) => {
    switch (data.type) {
      case POPUP_CLICK_TYPE.CLOSE_POPUP:
        setShowProductPopup(false);
        setShowContactPopup(false);
        break;
      case POPUP_CLICK_TYPE.CREATE_PRODUCT:
        topMenuRef.current?.storeCallbacksRef.createProduct();
        break;

      case POPUP_CLICK_TYPE.CREATE_CONTACT:
        topMenuRef.current?.storeCallbacksRef.createContact();
        break;
    }
  };

  const parentChildInteraction = (passingData: CallBackPayloadType) => {
    switch (passingData.type) {
      case POPUP_CALLBACKS_TYPE.CREATE_PRODUCT:
        topMenuRef.current.storeCallbacksRef.createProduct = passingData.data;
        break;
      case POPUP_CALLBACKS_TYPE.CREATE_CONTACT:
        topMenuRef.current.storeCallbacksRef.createContact = passingData.data;
        break;

      case POPUP_CALLBACKS_TYPE.CLOSE_POPUP:
        setShowProductPopup(false);
        setShowContactPopup(false);
        break;
    }
  };

  const getGlobalButton = () => {
    return (
      <DKButton
        title={isDesktop ? t(`TOP_MENU.BUTTON.CREATE_NEW`) : ''}
        icon={DKIcons.white.ic_add}
        className={`bg-button text-white walkthrough-step-1 ${
          isDesktop ? '' : 'circle'
        }`}
        onClick={toggleAddNewList}
      />
    );
  };

  const getTenantNameView = () => {
    return (
      <DKLabel
        className="fw-m fs-l p-h-m"
        style={{
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          maxWidth: '160px'
        }}
        text={tenantDetails?.name || ''}
      />
    );
  };

  const onWindowResize = () => {
    setIsDesktop(isViewportLarge);
  };

  const mobileAppActions = () => {
    return (
      <div className="row width-auto">
        <DKButton
          icon={DKIcons.white.ic_home}
          className={'bg-button text-white circle'}
          onClick={() => Utility.postMobileAppActions(MOBILE_APP_ACTIONS.HOME)}
        />
      </div>
    );
  };

  const resetWOFromSOState = () => {
    let payload: any = { create: false, salesOrderData: null };
    dispatch(setCreateWOFromSalesOrder(payload));
    setSelectedWO(null);
  };

  const resetWOFromSIState = () => {
    let payload: any = { create: false, salesInvoiceData: null };
    dispatch(setCreateWOFromSalesInvoice(payload));
    setSelectedWO(null);
  };

  const getAllWorkOrders = async (config: any) => {
    try {
      WorkOrderService.apiConfig = config;
      const data = await dispatch(fetchWorkOrderList());
    } catch (err: any) {
      console.error('Error fetching all WO: ', err);
    }
  };

  // Add Stock Request details in WO linked documents
  const updateLinkedStockRequestInWO = (woDetails: any, stockRequest: any) => {
    const woSequenceCode = woDetails?.documentSequenceCode;
    const woId = woDetails?.id;
    WorkOrderService.getWorkOrderByCode(woSequenceCode).then(
      (response: any) => {
        if (!Utility.isEmpty(response?.content)) {
          let woObject = response?.content?.find(
            (item: any) => item.documentSequenceCode === woSequenceCode
          );
          if (!Utility.isEmpty(woObject)) {
            let linkedDocuments = woObject.linkedDocuments
              ? [...woObject.linkedDocuments]
              : [];
            const productCodesArr = stockRequest?.stockRequestItems?.map(
              (srItem: any) => srItem?.productCode
            );
            const stockReqDetails = {
              documentType: DOC_TYPE.STOCK_REQUEST,
              documentCode: stockRequest.stockRequestCode,
              documentSequenceCode: stockRequest.documentSequenceCode,
              productCodes: productCodesArr
            };
            linkedDocuments.push(stockReqDetails);

            const payload = {
              ...woObject,
              linkedDocuments: linkedDocuments
            };
            WorkOrderService.updateWorkOrder(payload, woId)
              .then(() => {
                const config: WorkOrderAPIConfig = {
                  ...WorkOrderService.apiConfig
                };
                getAllWorkOrders(config);
              })
              .catch((err: any) => {
                console.error(
                  'Error updating workorder with stock request details: ',
                  err
                );
              });
          }
        }
      }
    );
  };

  const getCreateWOPopup = () => {
    return (
      <AddNewWorkOrder
        salesOrder={
          Utility.isValidDate(woForSalesOrderFlow?.salesOrderDate)
            ? woForSalesOrderFlow
            : null
        }
        salesInvoice={
          Utility.isValidDate(woForSalesInvoiceFlow?.salesInvoiceDate)
            ? woForSalesInvoiceFlow
            : null
        }
        workOrder={selectedWO ?? null}
        continueInEditMode={(res: any, selectedTabIndex = 0) => {
          setWOForSalesOrderFlow(null);
          setWOForSalesInvoiceFlow(null);
          let selectedWorkOrder = Array.isArray(res)
            ? res?.[selectedTabIndex]
            : res;
          setWOForSalesOrderFlow(null);
          setWOForSalesInvoiceFlow(null);
          setSelectedWO(selectedWorkOrder); // because in edit mode, WO document is no longer a sales order created document.
        }}
        onClose={() => {
          resetWOFromSOState();
          resetWOFromSIState();
        }}
        onSave={(response: any) => {
          resetWOFromSOState();
          resetWOFromSIState();
        }}
        onGotoClick={() => {
          resetWOFromSOState();
          resetWOFromSIState();
          RouteManager.navigateToPage(PAGE_ROUTES.MRP_WORK_ORDER);
        }}
      />
    );
  };

  const loadGoogleTranslateScripts = () => {
    const script1 = document.createElement('script');
    script1.type = 'text/javascript';
    script1.textContent = `
        function googleTranslateElementInit() {
          new google.translate.TranslateElement(
                {pageLanguage: 'en', includedLanguages: 'en,zh-CN', layout: google.translate.TranslateElement.InlineLayout.HORIZONTAL},
                'google_translate_element'
            );
          
          setTimeout(() => {
            const googlebutton = document.getElementsByClassName('goog-te-combo');
            if (googlebutton)
              googlebutton[0].className = 'goog-te-combo btn btn-success';        
            
            const poweredby = document.getElementsByClassName('skiptranslate goog-te-gadget');
            poweredby?.[0]?.firstChild.nextSibling.remove();
            
            const googlelogo = document.getElementsByClassName('VIpgJd-ZVi9od-l4eHX-hSRGPd');
            googlelogo?.[0]?.remove();

            const googleTranslatorTooltip = document.getElementById('goog-gt-tt');
            if (googleTranslatorTooltip)
              googleTranslatorTooltip.style.display = 'none';
          }, 100);
        }
    `;

    const script2 = document.createElement('script');
    script2.type = 'text/javascript';
    script2.src =
      'https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit';

    // Ensure the scripts load in the correct order
    script2.onload = () => {
      document.head.appendChild(script1);
    };

    document.head.appendChild(script2);
  };

  return (
    <div
      className={`parent-width bg-white row justify-content-between mob-p-h-xl p-v-s shadow-s border-b-gray z-index-3 `}
      style={{
        display: showTopbar ? 'flex' : 'none'
      }}
    >
      <div
        className="dk-sidebar-toggle p-v-s expandable-button cursor-hand z-index-4 column justify-content-center display-only-mobile"
        onClick={() => SideBarService.toggleSideBar()}
      >
        <div className="row">
          <DKIcon src={DKIcons.ic_menu} className={`ic-s shadow-s-2`} />
          {(!isDesktop || isTabletView()) && getTenantNameView()}
        </div>
      </div>
      {!isMobileWebView() && (
        <div className="row width-auto">{getNoCardView()}</div>
      )}
      {isMobileWebView() && mobileAppActions()}
      {!isMobileWebView() && (
        <div className="row width-auto">
          <div className="position-relative" id="add-new-doc-popup-btn">
            {rolePermissions &&
              rolePermissions.length > 0 &&
              rolePermissions[0].apps &&
              getGlobalButton()}
            {needAddNewList && getAddNewList()}
          </div>
          {/* Hiding Language Switcher for now */}
          {/* <div className="position-relative ml-m">
          <div
            onClick={toggleLanguageList}
            className="flex items-center cursor-pointer"
            style={{ width: 30, height: 30 }}
            id="language-list-popup-btn"
          >
            <DKIcon
              style={{
                width: 32,
                height: 32,
                opacity: '0.6'
              }}
              src={ic_language_selection}
              className="circle ic-r-3"
            />{' '}
            <p
              className="mr-m font-semibold"
              style={{
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                overflow: 'hidden'
              }}
            ></p>
          </div>
          {needLanguageList && getLanguageList()}
        </div> */}
          <div className="ml-r" id="google_translate_element"></div>
          {getProfileSection()}
        </div>
      )}
      {showProductPopup && (
        <CreateProductView
          product={null}
          passingInteraction={(callback: CallBackPayloadType) => {
            parentChildInteraction(callback);
          }}
          onCancel={() => {
            setShowProductPopup(false);
          }}
        />
      )}
      {showContactPopup && (
        <PopupWrapper
          clickAction={catchClicks}
          type={POPUP_TYPE.POPUP}
          title={'Create Contact'}
          btnList={popupBtnConfig}
          width={!isDesktop ? '95%' : '40%'}
          minWidth={!isDesktop ? '' : '550px'}
          height={'95%'}
        >
          <AddContact
            contactMode={DOCUMENT_MODE.NEW}
            populateFormData={null}
            passingInteraction={(callback: CallBackPayloadType) => {
              parentChildInteraction(callback);
            }}
          />
        </PopupWrapper>
      )}
      {getNoPermissionForBooksView()}
      {!Utility.isEmpty(createWOfromSOSelector) &&
        createWOfromSOSelector?.create &&
        !Utility.isEmpty(createWOfromSOSelector?.salesOrderData) &&
        (!Utility.isEmpty(woForSalesOrderFlow?.contactCode) ||
          !Utility.isEmpty(selectedWO)) &&
        getCreateWOPopup()}
      {!Utility.isEmpty(createWOfromSISelector) &&
        createWOfromSISelector?.create &&
        !Utility.isEmpty(createWOfromSISelector?.salesInvoiceData) &&
        (!Utility.isEmpty(woForSalesInvoiceFlow?.contactCode) ||
          !Utility.isEmpty(selectedWO)) &&
        getCreateWOPopup()}
      {showAddStockRequestPopup && stockRequestCreationData && (
        <AddStockRequestPopup
          readOnly={false}
          records={[]}
          woDetails={stockRequestCreationData?.woDetails}
          rowItemsForNewDoc={stockRequestCreationData?.rowItems}
          selectedRecord={null}
          onSave={(res: any) => {
            // Update WO with stock request details
            updateLinkedStockRequestInWO(
              { ...stockRequestCreationData?.woDetails },
              res
            );
            dispatch(updateStockRequestAssociation(null));
            setShowAddStockRequestPopup(false);
            showAlert(
              'Stock request created!',
              `Stock request ${res?.documentSequenceCode} created successfully.`
            );
          }}
          onCancel={() => {
            dispatch(updateStockRequestAssociation(null));
            setShowAddStockRequestPopup(false);
          }}
        />
      )}
      {showSettingsPopup && (
        <SettingsNew
          onClose={() => {
            dispatch(setShowSettings(false));
            dispatch(setSelectedSetting(null));
          }}
        />
      )}
    </div>
  );
};
export default TopMenu;
