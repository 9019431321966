import { useContext, useEffect, useState } from 'react';
import { shallowEqual } from 'react-redux';
import {
  DKIcons,
  DKIconText,
  DKInput,
  DKLabel,
  INPUT_VIEW_DIRECTION,
  INPUT_TYPE
} from 'deskera-ui-library';
import { CommonDraftPropsContext } from '../../../Utilities/DocContext';
import { useAppDispatch, useAppSelector } from '../../../../../Redux/Hooks';
import {
  selectDocumentFormDataByKeys,
  updateMultipleKeysInDocument
} from '../../../../../Redux/Slices/DocumentSlice';
import { DOCUMENT_KEYS } from '../../../Utilities/DocConstants';
import DateFormatService from '../../../../../Services/DateFormat';
import { activeTenantInfo } from '../../../../../Redux/Slices/AuthSlice';
import { BOOKS_DATE_FORMAT } from '../../../../../Constants/Constant';
import {
  getCalendarView,
  getDocDateLabel,
  getShipByDateLabel,
  validateAndUpdateDate
} from '../../../Helper/View/DocDatesHelper';
import { convertBooksDateFormatToUILibraryFormat } from '../../../../../Utility/Utility';

interface IDocDatesProps {
  showFullscreenLayout: boolean;
}

/**
 * Component to show and handle document "ship by/ receive by" date interactions
 */
const ShipByDate = (props: IDocDatesProps) => {
  const { draftId, draftType, documentMode } = useContext(
    CommonDraftPropsContext
  );
  const [documentType, documentDate, fulfillmentDate] = useAppSelector(
    selectDocumentFormDataByKeys(draftId, [
      DOCUMENT_KEYS.DOCUMENT_TYPE,
      DOCUMENT_KEYS.DOCUMENT_DATE,
      DOCUMENT_KEYS.FULFILLMENT_DATE
    ]),
    shallowEqual
  );
  const currentDate = new Date();
  const docDateLabel = getDocDateLabel(documentType);
  const shipByDateLabel = getShipByDateLabel(documentType);

  const tenantInfo = useAppSelector(activeTenantInfo);
  const dispatch = useAppDispatch();

  const [shipByDateOpen, setShipByDateOpen] = useState(false);
  const [shipByDate, setShipByDate] = useState(
    fulfillmentDate
      ? DateFormatService.getDateFromStr(
          fulfillmentDate,
          BOOKS_DATE_FORMAT['DD-MM-YYYY']
        )
      : currentDate
  );

  useEffect(() => {
    setShipByDate(
      DateFormatService.getDateFromStr(
        fulfillmentDate,
        BOOKS_DATE_FORMAT['DD-MM-YYYY']
      )
    );
  }, [fulfillmentDate]);

  const updateShipByDate = (date: Date) => {
    setShipByDate(date);
    dispatch(
      updateMultipleKeysInDocument({
        draftId,
        keysToUpdate: {
          [DOCUMENT_KEYS.FULFILLMENT_DATE]:
            DateFormatService.getDateStrFromDate(
              date,
              BOOKS_DATE_FORMAT['DD-MM-YYYY']
            )
        }
      })
    );
  };

  const getFullScreenView = () => {
    return (
      <div
        style={{
          width: 150,
          maxWidth: 200
        }}
      >
        <DKInput
          className="parent-width"
          title={shipByDateLabel}
          value={shipByDate}
          titleStyle={{ color: 'gray' }}
          valueStyle={{ minHeight: 33 }}
          type={INPUT_TYPE.DATE}
          onChange={(newDate: any) => {
            validateAndUpdateDate(
              newDate,
              DateFormatService.getDateFromStr(
                documentDate,
                BOOKS_DATE_FORMAT['DD-MM-YYYY']
              ),
              (date: any) => {
                updateShipByDate(date);
              },
              `${shipByDateLabel} date cannot be before ${docDateLabel}.`,
              false,
              documentType,
              () => {
                setShipByDate(new Date(shipByDate));
              }
            );
          }}
          direction={INPUT_VIEW_DIRECTION.VERTICAL}
          required={false}
          dateFormat={convertBooksDateFormatToUILibraryFormat(
            tenantInfo.dateFormat
          )}
        />
      </div>
    );
  };

  const getNonFullscreenView = () => {
    return (
      <div className="row position-relative p-v-xs listPickerBG gap-2">
        <div
          className="row justify-content-between cursor-hand"
          onClick={() => setShipByDateOpen((value) => !value)}
        >
          <DKIconText
            icon={DKIcons.data_type.ic_date}
            iconClassName="ic-with-text-height opacity-60"
            text={shipByDateLabel}
            textClassName="fw-m"
          />
          <DKLabel text={DateFormatService.getDateStrFromDate(shipByDate)} />
        </div>
        {shipByDateOpen &&
          getCalendarView(
            shipByDate,
            (newDate: any) => {
              validateAndUpdateDate(
                newDate,
                DateFormatService.getDateFromStr(
                  documentDate,
                  BOOKS_DATE_FORMAT['DD-MM-YYYY']
                ),
                (date: any) => {
                  updateShipByDate(date);
                },
                `${shipByDateLabel} date cannot be before ${docDateLabel}.`,
                false,
                documentType
              );
            },
            setShipByDateOpen
          )}
      </div>
    );
  };

  return props.showFullscreenLayout
    ? getFullScreenView()
    : getNonFullscreenView();
};

export default ShipByDate;
