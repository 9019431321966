import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import { AccountSlice } from './Slices/AccountsSlice';
import { AuthSlice } from './Slices/AuthSlice';
import { BillsSlice } from './Slices/BillsSlice';
import { CommonDataSlice } from './Slices/CommonDataSlice';
import { ContactsSlice } from './Slices/ContactsSlice';
import { CoaSlice } from './Slices/CoaSlice';
import { CreditNoteSlice } from './Slices/CreditNoteSlice';
import { DepositSlice } from './Slices/DepositSlice';
import { DraftsSlice } from './Slices/DraftsSlice';
import { DocumentSlice } from './Slices/DocumentSlice';
import { CustomNumberFormat } from './Slices/CustomNumberFormat';
import { ExpenseSlice } from './Slices/ExpenseSlice';
import { InvoicesSlice } from './Slices/InvoicesSlice';
import { ProductsSlice } from './Slices/ProductsSlice';
import { PurchaseOrdersSlice } from './Slices/PurchaseOrdersSlice';
import { QuotesSlice } from './Slices/QuotesSlice';
import { SalesOrderSlice } from './Slices/SalesOrderSlice';
import { JournalSlice } from './Slices/Journal';
import { FinancialYearClosingSlice } from './Slices/FinancialYearClosingSlice';
import { DebitNoteSlice } from './Slices/DebitNoteSlice';
import { InventorySlice } from './Slices/InventorySlice';
import { WarehouseSlice } from './Slices/WarehouseSlice';
import OBInvoicesSlice from './Slices/OBInvoicesSlice';
import { WarehouseProductsSlice } from './Slices/WarehouseProductSlice';
import { PriceListSlice } from './Slices/PriceList';
import { BanksSlice } from './Slices/Banks';
import { PaymentTermsSlice } from './Slices/PaymentTermsSlice';
import { BankRulesSlice } from './Slices/BankRules';
import { AuditLogSlice } from './Slices/AuditLogSlice';
import { CurrencySlice } from './Slices/CurrencySlice';
import { TaxSlice } from './Slices/TaxSlice';
import { DashboardSlice } from './Slices/DashboardSlice';
import { ImportSlice } from './Slices/ImportSlice';
import { UomSlice } from './Slices/UomSlice';
import { GenericSlice } from './Slices/GenericSlice';
import { DeskeraTransactions } from './Slices/DeskeraTransactions';
import { BankTransactionsSlice } from './Slices/BankTransactions';
import { ImportLogSlice } from './Slices/ImportLogSlice';
import { SerialTrackingProductsSlice } from './Slices/SerialTrackingSlice';
import { UomSchemaSlice } from './Slices/UomSchemaSlice';
import { TDSSetupSlice } from './Slices/TDSSetupSlice';
import { TCSSetupSlice } from './Slices/TCSSetupSlice';
import { BOOLEAN_TYPE } from '../Constants/Constant';
import { EwayBillSlice } from './Slices/EwayBillSlice';
import { BatchTrackingProductsSlice } from './Slices/BatchTrackingSlice';
import { AdvancedTrackingDataSlice } from './Slices/AdvancedTrackingDataSlice';
import { AutomationSlice } from './Slices/AutomationSlice';
import TaxReportSlice from './Slices/ReportSlice';
import { CommonWarehouseProductsSlice } from './Slices/CommonWarehouseProductSlice';
import { CustomReportsSlice } from './Slices/CustomReportsSlice';
import { LocationSlice } from './Slices/LocationSlice';
import { BookKeeperTeamSlice } from './Slices/BookKeeperTeamSlice';
import { BookKeeperClientSlice } from './Slices/BookKeeperClientSlice';
import { AgenciesSlice } from './Slices/AgenciesSlice';
import { AdditionalChargesSlice } from './Slices/AdditionalChargesSlice';
import { BookFilterSlice } from './Slices/BookFilterSlice';
import { BudgetingSlice } from './Slices/BudgetingSlice';
import { PermissionsSlice } from './Slices/PermissionsSlice';
import { Gstr2bSlice } from './Slices/Gstr2bSlice';
import { OperationSlice } from './Slices/MRP/OperationSlice';
import { WorkstationSlice } from './Slices/MRP/WorkstationSlice';
import { MRPProductSlice } from './Slices/MRP/MRPProductSlice';
import { MRPHolidayListSlice } from './Slices/MRP/HolidayListSlice';
import { WorkOrderSlice } from './Slices/MRP/WorkOrderSlice';
import { MRPProductionPlanSlice } from './Slices/MRP/ProductionPlanSlice';
import { JobCardSlice } from './Slices/MRP/JobCardSlice';
import { MRPSettingsSlice } from './Slices/MRP/SettingsSlice';
import { StockTransferSlice } from './Slices/StockTransferSlice';
import { StockRequestSlice } from './Slices/StockRequestSlice';
import { StockAdjustmentSlice } from './Slices/StockAdjustmentSlice';
import { OperatorSlice } from './Slices/MRP/OperatorSlice';
import { MRPQualityCheckSlice } from './Slices/MRP/QualityCheckSlice';
import { RequisitionSlice } from './Slices/RequisitionSlice';
import { SupplierQuotesSlice } from './Slices/SupplierQuotes';
import { StockIssueSlice } from './Slices/StockIssueSlice';
import { MRPForecastSlice } from './Slices/MRP/ForecastSlice';
import { WorkOutSlice } from './Slices/WorkOutSlice';
import { RequestForQuotationSlice } from './Slices/RequestForQuotationSlice';
import { QualityChecksSlice } from './Slices/QualityCheckSlice';
import { AssetSlice } from './Slices/AssetSlice';
import { RevenueRecognitionSlice } from './Slices/RevenueRecognitionSlice';
import { FixedAssetSlice } from './Slices/FixedAssetSlice';
import { AssetGroupSlice } from './Slices/AssetGroupSlice';
import { DepreciationScheduleSlice } from './Slices/DepreciationScheduleSlice';
import { AmortizationSlice } from './Slices/AmortizationSlice';
import { WithHoldTaxSlice } from './Slices/WHTSlice';
import { ExpenseAllocationSlice } from './Slices/ExpenseAllocationSlice';
import { ImportDraftSlice } from './Slices/ImportDraftSlice';
import { MRPMasterForecastSlice } from './Slices/MRP/MasterForecastSlice';
import { ReportsSalesOrderSlice } from './Slices/ReportSalesOrderSlice';
import { PriceBookSlice } from './Slices/PriceBookSlice';
import { ClientPortalSlice } from './Slices/ClientPortalSlice';
import { BOMExplosionSlice } from './Slices/MRP/BOMExplosionSlice';
import { MRPProductSubstituteSlice } from './Slices/MRP/ProductSubstituteSlice';
import { MRPReportsSKUOrderTrackingSlice } from './Slices/MRP/SKUOrderTrackingSlice';
import { MachineToolMasterSlice } from './Slices/MRP/MachineToolMasterSlice';
import { GoodsReceiptSlice } from './Slices/GoodsReceiptMasterSlice';
import { goodsReceiptApprovalSlice } from './Slices/GoodsReceiptApprovalSlice';
import { FufillmentMasterSlice } from './Slices/FulfillmentMasterSlice';
import { FulfillmentApprovalSlice } from './Slices/FulfillmentApprovalSlice';
import { MachineSchedulerSlice } from './Slices/MRP/MachineSchedulerSlice';
import { GRNSlice } from './Slices/GRNSlice';
import { FulfillmentReportSlice } from './Slices/FufillmentReportSlice';
import { PaymentLogsSlice } from './Slices/PaymentLogsSlice';
import { UploadToFTPLogsSlice } from './Slices/UploadToFTPLogsSlice';
import { GenerateBankFileSlice } from './Slices/GenerateBankFileSlice';

export const Store = configureStore({
  reducer: {
    accountsInfo: AccountSlice.reducer,
    additionalCharges: AdditionalChargesSlice.reducer,
    advancedTrackingData: AdvancedTrackingDataSlice.reducer,
    agencies: AgenciesSlice.reducer,
    amortization: AmortizationSlice.reducer,
    AssetGroup: AssetGroupSlice.reducer,
    assets: AssetSlice.reducer,
    auditLog: AuditLogSlice.reducer,
    authInfo: AuthSlice.reducer,
    automation: AutomationSlice.reducer,
    bankDetails: DeskeraTransactions.reducer,
    bankMatches: BanksSlice.reducer,
    bankRules: BankRulesSlice.reducer,
    banks: BanksSlice.reducer,
    bankTransactions: BankTransactionsSlice.reducer,
    batchTrackingProducts: BatchTrackingProductsSlice.reducer,
    bills: BillsSlice.reducer,
    bkClient: BookKeeperClientSlice.reducer,
    bkTeam: BookKeeperTeamSlice.reducer,
    bookFilter: BookFilterSlice.reducer,
    budgeting: BudgetingSlice.reducer,
    coa: CoaSlice.reducer,
    commonData: CommonDataSlice.reducer,
    commonWarehouseProducts: CommonWarehouseProductsSlice.reducer,
    contacts: ContactsSlice.reducer,
    clientPortal: ClientPortalSlice.reducer,
    creditNotes: CreditNoteSlice.reducer,
    currency: CurrencySlice.reducer,
    customNumberFormat: CustomNumberFormat.reducer,
    customReports: CustomReportsSlice.reducer,
    dashboards: DashboardSlice.reducer,
    debitNotes: DebitNoteSlice.reducer,
    deposits: DepositSlice.reducer,
    DepreciationSchedule: DepreciationScheduleSlice.reducer,
    /** for new DraftDocBuilder */
    documentsData: DocumentSlice.reducer,
    drafts: DraftsSlice.reducer,
    ewaybill: EwayBillSlice.reducer,
    expenseAllocation: ExpenseAllocationSlice.reducer,
    expenses: ExpenseSlice.reducer,
    financialYearClosings: FinancialYearClosingSlice.reducer,
    FixedAsset: FixedAssetSlice.reducer,
    fulfillmentMaster: FufillmentMasterSlice.reducer,
    fulfillmentApproval: FulfillmentApprovalSlice.reducer,
    generics: GenericSlice.reducer,
    gstr2b: Gstr2bSlice.reducer,
    goodsReceipt: GoodsReceiptSlice.reducer,
    goodsReceiptApproval: goodsReceiptApprovalSlice.reducer,
    importDraft: ImportDraftSlice.reducer,
    importLog: ImportLogSlice.reducer,
    imports: ImportSlice.reducer,
    inventory: InventorySlice.reducer,
    invoices: InvoicesSlice.reducer,
    journal: JournalSlice.reducer,
    location: LocationSlice.reducer,
    mrpForecast: MRPForecastSlice.reducer,
    mrpHolidayList: MRPHolidayListSlice.reducer,
    mrpJobCard: JobCardSlice.reducer,
    mrpMasterForecast: MRPMasterForecastSlice.reducer,
    mrpOperations: OperationSlice.reducer,
    mrpOperators: OperatorSlice.reducer,
    mrpMachineToolsMaintaince: MachineToolMasterSlice.reducer,
    mrpMachineScheduler: MachineSchedulerSlice.reducer,
    mrpProductionPlan: MRPProductionPlanSlice.reducer,
    mrpProducts: MRPProductSlice.reducer,
    mrpProductSubstitute: MRPProductSubstituteSlice.reducer,
    mrpQualityChecks: MRPQualityCheckSlice.reducer,
    mrpSettings: MRPSettingsSlice.reducer,
    mrpWorkOrder: WorkOrderSlice.reducer,
    mrpBOMExplosion: BOMExplosionSlice.reducer,
    mrpWorkstation: WorkstationSlice.reducer,
    obInvoices: OBInvoicesSlice.reducer,
    paymentTerms: PaymentTermsSlice.reducer,
    permissions: PermissionsSlice.reducer,
    priceBook: PriceBookSlice.reducer,
    priceList: PriceListSlice.reducer,
    products: ProductsSlice.reducer,
    productSummary: ProductsSlice.reducer,
    productWarehouseCodes: WarehouseProductsSlice.reducer,
    purchaseOrders: PurchaseOrdersSlice.reducer,
    qualityChecks: QualityChecksSlice.reducer,
    quotes: QuotesSlice.reducer,
    RequestForQuotation: RequestForQuotationSlice.reducer,
    requisition: RequisitionSlice.reducer,
    revenueRecognitions: RevenueRecognitionSlice.reducer,
    salesOrder: SalesOrderSlice.reducer,
    serialTrackingProducts: SerialTrackingProductsSlice.reducer,
    stockAdjustments: StockAdjustmentSlice.reducer,
    stockIssue: StockIssueSlice.reducer,
    stockRequest: StockRequestSlice.reducer,
    stockTransfers: StockTransferSlice.reducer,
    supplierQuote: SupplierQuotesSlice.reducer,
    taxTable: TaxSlice.reducer,
    tcsSetup: TCSSetupSlice.reducer,
    tdsSetup: TDSSetupSlice.reducer,
    uom: UomSlice.reducer,
    uomSchema: UomSchemaSlice.reducer,
    warehouse: WarehouseSlice.reducer,
    warehouseProductByIDWithRejectedWh: WarehouseProductsSlice.reducer,
    warehouseProducts: WarehouseProductsSlice.reducer,
    warehouseProductsByID: WarehouseProductsSlice.reducer,
    withHoldTax: WithHoldTaxSlice.reducer,
    workOut: WorkOutSlice.reducer,
    reportsSalesOrder: ReportsSalesOrderSlice.reducer,
    mrpReportsSKUOrderTrackingDetails: MRPReportsSKUOrderTrackingSlice.reducer,
    grn: GRNSlice.reducer,
    fulfillment_report: FulfillmentReportSlice.reducer,
    paymentLogs: PaymentLogsSlice.reducer,
    uploadToFTPLogs: UploadToFTPLogsSlice.reducer,
    generateBankFiles: GenerateBankFileSlice.reducer
  },

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ['drafts/setPopulateFormData'],
        ignoredPaths: ['drafts.data', 'payload.formdata']
      }
    }),
  devTools: BOOLEAN_TYPE.true === process.env.REACT_APP_REDUX_DEVTOOLS
});

export type AppDispatch = typeof Store.dispatch;
export type RootState = ReturnType<typeof Store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
