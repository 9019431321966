import {
  COUNTRY_CODES,
  DOC_TYPE,
  LABELS,
  TAX_SYSTEM
} from '../../../Constants/Constant';
import { PERMISSIONS_BY_MODULE } from '../../../Constants/Permission';

export const DRAFT_DOC_SUPPORTED_MODULE_LABELS = [
  LABELS.INVOICES,
  LABELS.ESTIMATE,
  LABELS.QUOTES,
  LABELS.BILLS,
  LABELS.PURCHASE_ORDERS,
  LABELS.SALES_ORDER
];

export const RIGHT_PANEL_INPUT_TYPE = {
  SEQUENCE_CODE: 'SEQUENCE_CODE',
  DATE: 'DATE',
  TEXT_INPUT: 'TEXT_INPUT',
  TEXT: 'TEXT'
};

export enum DOCUMENT_META_KEYS {
  TITLE = 'title',
  DRAFT_TYPE = 'draftType',
  TYPE = 'type',
  IS_CENTER_ALIGN = 'isCenterAlign',
  IS_LOADING = 'isLoading',
  CAN_VALIDATE = 'canValidate',
  HIDE_MINIMIZER = 'hideMinimizer'
}

export enum DOCUMENT_KEYS {
  ID = 'id',
  ENTITY_ID = 'entityId',
  ITEMS = 'items',
  CONTACT = 'contact',
  CONTACT_DTO = 'contactDto',
  SEQUENCE_FORMAT = 'sequenceFormat',
  ATTACHMENTS = 'attachments',
  DROP_SHIP_NAME = 'dropShipName',
  ATTACHMENT_IDS = 'attachmentIds',
  DOCUMENT_SEQUENCE_CODE = 'documentSequenceCode',
  BILL_TO = 'billTo',
  SHIP_TO = 'shipTo',
  SHIP_FROM = 'shipFrom',
  DOCUMENT_DATE = 'documentDate',
  UNIT_PRICE_GST_INCLUSIVE = 'unitPriceGstInclusive',
  MEMO = 'memo',
  PREVIOUS_MEMO = 'previousMemo',
  DOCUMENT_TYPE = 'documentType',
  DOCUMENT_CODE = 'documentCode',
  DROPSHIP = 'dropship',
  FULFILLMENT_TYPE = 'fulfillmentType',
  FULFILLMENT_DATE = 'fulfillmentDate',
  RESERVED_STOCK = 'reservedStock',
  DRAFT_TYPE = 'draftType',
  SOURCE_FULFILLMENT_STATUS = 'sourceFulfillmentStatus',
  IS_DOC_TOUCHED = 'isDocumentTouched',
  VALID_TILL_DATE = 'validTillDate',
  SALES_INVOICE_DATE = 'salesInvoiceDate',
  SALES_INVOICE_DUE_DATE = 'salesInvoiceDueDate',
  SHIP_BY_DATE = 'shipByDate',
  SALES_ORDER_DATE = 'salesOrderDate',
  SALES_ORDER_DUE_DATE = 'salesOrderDueDate',
  RECEIVE_BY_DATE = 'receiveByDate',
  PURCHASE_INVOICE_DATE = 'purchaseInvoiceDate',
  PURCHASE_INVOICE_DUE_DATE = 'purchaseInvoiceDueDate',
  CUSTOM_LOCATION_CF_VALUE = 'customLocationCFValue',
  PAYMENT_MILESTONE_FLAG = 'paymentMilestoneFlag',
  ADDITIONAL_CHARGES = 'additionalCharges',
  ROUND_OFF_AMOUNT_IN_DOCUMENT_CURRENCY = 'roundOffAmountInDocumentCurrency',
  EXCHANGE_RATE = 'exchangeRate',
  PREVIOUS_EXCHANGE_RATE = 'previousExchangeRate',
  TCS_RATE_ID = 'tcsRateId',
  CONTACT_CODE = 'contactCode',
  GST_TYPE = 'gstType',
  TCS_PERCENTAGE = 'tcsPercentage',
  CURRENCY = 'currency',
  CURRENCY_CODE = 'currencyCode',
  TAX_SYSTEM = 'taxSystem',
  GST_EXCHANGE_RATE = 'gstExchangeRate',
  KNOCK_OFF_INFO = 'knockoffInfo',
  TDS_INFO = 'tdsInfo',
  TCS_AMOUNT = 'tcsAmount',
  RECEIPT_STATUS = 'receiptStatus',
  RECEIVE_GOODS_STATUS = 'receiveGoodsStatus',
  PAYMENT_STATUS = 'paymentStatus',
  FULFILLMENT_STATUS = 'fulfillmentStatus',
  TOTAL_AMOUNT = 'totalAmount',
  PRICE_LIST_ID = 'priceListId',
  APPROVAL_STATUS = 'approvalStatus',
  IS_CONVERTING = 'isConverting',
  CUSTOM_FIELDS = 'customField',
  AUTO_CREATE_PO_FROM_WO = 'autoCreatePOFromWO',
  LINKED_WORK_ORDERS = 'linkedWorkOrders',
  LINKED_DOCUMENTS = 'linkedDocuments',
  LANDED_COST = 'landedCost',
  LINKED_PURCHASE_INVOICES = 'linkedPurchaseInvoices',
  LINKED_SALES_INVOICES = 'linkedSalesInvoices',
  LINKED_QUOTATION_ITEMS = 'linkedQuotationDocuments',
  LINKED_SALES_ORDERS = 'linkedSalesOrders',
  BACK_ORDER = 'backOrder',
  CUSTOM_ORDER_NUMBER = 'customerOrderNumber',
  SUPPLIER_INVOICE_NUMBER = 'supplierInvoiceNo',
  BUYER_REFERENCE = 'buyerReference',
  ORDER_REFERENCE = 'orderReference',
  PAYMENT_TERM = 'paymentTerm',
  AMOUNT_TO_RECEIVE_OR_PAY = 'amountToReceiveOrPay',
  DUE_AMOUNT = 'dueAmount',
  PAYMENT_TYPE = 'paymentType',
  ACCOUNT_GROUP_FOR_PAYMENT = 'accountGroupForPayment',
  PAYMENT_ACCOUNT_GROUP_OPTIONS = 'paymentAccountGroupsOptions',
  IS_PARTIAL_INVOICE = 'isPartialInvoice',
  IS_PARTIAL_BILL = 'isPartialBill',
  IS_PARTIAL_SALES_ORDER = 'isPartialSalesOrder',
  LANDED_PRODUCTS = 'landedProducts',
  SALES_INVOICE_CODE = 'salesInvoiceCode',
  E_INVOICE_INFO_INDIA = 'einvoiceInfoIndia',
  IS_CANCEL_E_INVOICE = 'isCancelEinvoice',
  SOURCE_DOCTYPE_FOR_CONVERSION = 'sourceDocTypeForConversion',
  E_INVOICE_INFO_INDIA_CANCEL = 'einvoiceInfoIndiaCancel',
  PAYMENT_INFORMATION = 'paymentInformation',
  SHOW_COMPONENT_LIST = 'showComponentList',
  APPLY_RCM_CHECK = 'applyRcmCheck'
}

export enum DOC_LINE_ITEM_KEYS {
  DOCUMENT_SEQUENCE_CODE = 'documentSequenceCode',
  PRODUCT_DESCRIPTION = 'productDescription',
  GST_TYPE = 'gstType',
  IS_TAXABLE = 'isTaxable',
  TYPE = 'type',
  LINKED_QUOTE_ITEM = 'linkedQuoteItem',
  LINKED_ORDER_ITEM = 'linkedOrderItem',
  NON_EDITABLE_COLUMNS = 'nonEditableColumns',
  IS_RCM_APPLIED = 'isRcmApplied',
  PRODUCT = 'product',
  TAX = 'tax',
  TAX_DETAILS = 'taxDetails',
  UOM = 'uom',
  DOCUMENT_UOM_SCHEMA_DEFINITION = 'documentUOMSchemaDefinition',
  UOM_QUANTITY = 'uomQuantity',
  TAX_AMOUNT = 'taxAmount',
  TOTAL_AMOUNT = 'totalAmount',
  TOTAL = 'total',
  SUBTOTAL = 'subTotal',
  DISCOUNT = 'discount',
  DISCOUNT_IN_PERCENT = 'discountInPercent',
  TOTAL_DISCOUNT_AMOUNT = 'totalDiscountAmount',
  UNIT_PRICE = 'unitPrice',
  UNIT_PRICE_GST_INCLUSIVE = 'unitPriceGstInclusive',
  PENDING_AMOUNT = 'pendingAmount',
  UOM_UNIT_PRICE = 'uomUnitPrice',
  PRODUCT_QUANTITY = 'productQuantity',
  QUANTITY_ORDERED = 'qtyOrdered',
  AVAILABLE_QUANTITY = 'availableQuantity',
  AVAILABLE_QTY = 'availableQty',
  AMORTIZATION_DOC_ITEM_DETAILS = 'amortizationDocumentItemDetails',
  AMORTIZATION_TEMPLATE = 'amortizationTemplate',
  AMORTIZATION_START_DATE = 'amortizationStartDate',
  AMORTIZATION_END_DATE = 'amortizationEndDate',
  REV_REC_START_DATE = 'revRecStartDate',
  REV_REC_END_DATE = 'revRecEndDate',
  EXPECTED_DELIVERY_DT = 'expectedDeliveryDt',
  RESERVED_QUANTITIES_DATA = 'reservedQuantitiesData',
  TDS_INFO_INDIA = 'tdsInfoIndia',
  BASE_PRICE = 'basePrice',
  LINE_NUMBER = 'lineNumber',
  ITEM_DISCOUNT_METHOD = 'itemDiscountMethod',
  ALLOCATION_TYPE = 'allocationType',
  OPTIONAL = 'optional',
  HSN_OR_SAC = 'hsnOrSacCode',
  IS_TDS_APPLICABLE_CONTACT = 'isTdsApplicableContact',
  IS_TDS_APPLICABLE_PRODUCT = 'isTdsApplicableProduct',
  IS_TDS_APPLICABLE_ACCOUNT = 'isTdsApplicableAccount',
  IS_DEDUCTED_TDS = 'isDeductedTds',
  ITC_INELIGIBLE_TYPE = 'itcIneligibleType',
  IGST_AMOUNT = 'igstAmount',
  SGST_AMOUNT = 'sgstAmount',
  CGST_AMOUNT = 'cgstAmount',
  CESS_AMOUNT = 'cessAmount',
  USER_SET_TAXES = 'userSetTaxes'
}
export const READONLY_TAX_AMOUNT_COUNTRIES = [COUNTRY_CODES.UK];
export const CONTACT_DEPENDENT_TAX_COUNTRIES = [COUNTRY_CODES.UK];
export const SALE_ORDER_QTY_COLUMNS_IN_DOC = [
  'qtyOrdered',
  'qtyCommitted',
  'pickedQty',
  'packedQty',
  'shippedFulfilledQty',
  'backorderQty',
  'availableQty',
  'invoicedQty'
];
export const BILL_QTY_COLUMNS_IN_DOC = [
  'receivedQuantity',
  'balanceReceivedQuantity',
  'billedQuantity',
  'balanceBilledQuantity'
];

export const SALE_ORDER_TRACKING_COLUMN_IN_DOC = ['availableQuantity'];
export const MRP_SALE_ORDER_TRACKING_COLUMN_IN_DOC = ['qtyConvertedToWo'];
export const DOC_TYPE_TO_PERMISSION_MATRIX: any = {
  [DOC_TYPE.INVOICE]: PERMISSIONS_BY_MODULE.INVOICE,
  [DOC_TYPE.QUOTE]: PERMISSIONS_BY_MODULE.QUOTATION,
  [DOC_TYPE.REQUISITION]: PERMISSIONS_BY_MODULE.REQUISITION,
  [DOC_TYPE.BILL]: PERMISSIONS_BY_MODULE.BILL,
  [DOC_TYPE.ORDER]: PERMISSIONS_BY_MODULE.PURCHASE_ORDER,
  [DOC_TYPE.SALES_ORDER]: PERMISSIONS_BY_MODULE.SALES_ORDER,
  [DOC_TYPE.JOB_WORK_OUT_ORDER]: PERMISSIONS_BY_MODULE.JOB_WORKOUTS
};
export const SG_PEPPOL_ENABLE_MODULE = [
  DOC_TYPE.BILL,
  DOC_TYPE.INVOICE,
  DOC_TYPE.SALES_ORDER
];
export const SG_PEPPOL_COLUMNS = ['lineRefNo'];

export const countriesWithTaxDependantOnContact = [COUNTRY_CODES.UK];
export const coutriesWithTaxAmountNonEditable = [COUNTRY_CODES.UK];

export interface IDocSummaryAmount {
  subTotal: number;
  totalWithDiscount: number;
  tax: number;
  discount: number;
  cascadingDiscountAmount: number;
  tdsAmount: number;
  tcsAmount: number;
  total: number;
  GST: {
    sgst: number;
    cgst: number;
    igst: number;
    cess: number;
  };
}
export const SUMMARY_AMOUNT_INITIAL_STATE = {
  subTotal: 0,
  cascadingDiscountAmount: 0,
  discount: 0,
  tax: 0,
  tcsAmount: 0,
  tdsAmount: 0,
  total: 0,
  GST: {
    cess: 0,
    cgst: 0,
    igst: 0,
    sgst: 0
  },
  totalWithDiscount: 0
};

export enum CHARGE_TYPE {
  ADDITIONAL_CHARGE = 'additionalChargesDetails',
  DISCOUNT = 'globalDiscounts'
}

export const COUNTRIES_WITH_NO_MANUAL_TAX_UPDATE = [
  TAX_SYSTEM.INDIA_GST,
  TAX_SYSTEM.US,
  TAX_SYSTEM.UK
];
