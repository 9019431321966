import {
  DOCUMENT_MODE,
  POPUP_CALLBACKS_TYPE,
  POPUP_CLICK_TYPE,
  POPUP_TYPE
} from '../../../../Constants/Constant';
import {
  BtnType,
  CallBackPayloadType,
  PopupClickActionType
} from '../../../../Models/Interfaces';
import PopupWrapper from '../../../../SharedComponents/PopupWrapper';
import { CONTACT_FORM_TAB } from '../../../../Constants/Enum';
import AddContact from '../../../Contacts/AddContact';
import { useTranslation } from 'react-i18next';
import { isViewportLarge } from '../../../../Utility/ViewportSizeUtils';
import { useContext, useRef } from 'react';
import { useAppDispatch } from '../../../../Redux/Hooks';
import { CommonDraftPropsContext } from '../../Utilities/DocContext';
import { onDocContactChange } from '../../Helper/DocumentUpdates/ContactChangeHelper';

interface IDocAddContactPopupProps {
  contactMode?: DOCUMENT_MODE;
  activeContactTab?: CONTACT_FORM_TAB;
  contact?: any;
  onClosePopup: () => void;
}

const DocAddContactPopup = (props: IDocAddContactPopupProps) => {
  const { draftId, draftType, documentMode, isCashInvoice } = useContext(
    CommonDraftPropsContext
  );
  const {
    contactMode = DOCUMENT_MODE.NEW,
    activeContactTab = CONTACT_FORM_TAB.GENERAL_INFO,
    contact = {},
    onClosePopup = () => {}
  } = props;
  const { t } = useTranslation();
  const contactPopupCallbackRef = useRef({
    storeCallbacksRef: {
      createContact: () => {},
      updateContact: () => {}
    }
  });
  const dispatch = useAppDispatch();

  const isDesktop = isViewportLarge();

  const buttonConfig: BtnType[] = [
    {
      title: t(`DOCUMENT.BUTTON.CANCEL`),
      class: 'border-m mr-s',
      clickAction: POPUP_CLICK_TYPE.CLOSE_POPUP
    },
    {
      title: contactMode === DOCUMENT_MODE.NEW ? 'Create' : 'Update',
      class: 'bg-button text-white mr-ss',
      clickAction:
        contactMode === DOCUMENT_MODE.NEW
          ? POPUP_CLICK_TYPE.CREATE_CONTACT
          : POPUP_CLICK_TYPE.UPDATE_CONTACT
    }
  ];

  const catchClicks = (data: PopupClickActionType) => {
    try {
      switch (data.type) {
        case POPUP_CLICK_TYPE.CLOSE_POPUP:
          onClosePopup();
          break;
        case POPUP_CLICK_TYPE.CREATE_CONTACT:
          contactPopupCallbackRef.current.storeCallbacksRef.createContact();
          break;
        case POPUP_CLICK_TYPE.UPDATE_CONTACT:
          contactPopupCallbackRef.current.storeCallbacksRef.updateContact();
          break;
      }
    } catch (err) {}
  };

  const onSaveOrUpdateContact = (contactData: any) => {
    onDocContactChange({
      draftId,
      isContactChanged: true,
      newContact: contactData,
      documentMode,
      draftType,
      isCashInvoice: !!isCashInvoice
    });
  };

  const parentChildInteraction = (passingData: CallBackPayloadType) => {
    try {
      switch (passingData.type) {
        case POPUP_CALLBACKS_TYPE.CREATE_CONTACT:
          contactPopupCallbackRef.current.storeCallbacksRef.createContact =
            passingData.data;
          break;
        case POPUP_CALLBACKS_TYPE.UPDATE_CONTACT:
          contactPopupCallbackRef.current.storeCallbacksRef.updateContact =
            passingData.data;
          break;
        case POPUP_CALLBACKS_TYPE.CREATE_CONTACT_SUCCESS:
          onClosePopup();
          onSaveOrUpdateContact(passingData.data);
          break;
        case POPUP_CALLBACKS_TYPE.CLOSE_POPUP:
          onClosePopup();
          break;
      }
    } catch (err) {}
  };

  return (
    <PopupWrapper
      clickAction={catchClicks}
      type={POPUP_TYPE.POPUP}
      title={
        contactMode === DOCUMENT_MODE.NEW ? 'Create Contact' : 'Update Contact'
      }
      btnList={buttonConfig}
      disableClickOutside={true}
      width={!isDesktop ? '95%' : '40%'}
      minWidth={!isDesktop ? '' : '550px'}
      height={'95%'}
    >
      <AddContact
        activeTab={activeContactTab}
        contactMode={contactMode}
        populateFormData={
          contactMode === DOCUMENT_MODE.NEW ? null : { ...contact }
        }
        passingInteraction={(callback: CallBackPayloadType) => {
          parentChildInteraction(callback);
        }}
        onSuccess={(res: any) => onSaveOrUpdateContact(res)}
      />
    </PopupWrapper>
  );
};

export default DocAddContactPopup;
