import { DKCalendar, showAlert } from 'deskera-ui-library';
import { BOOKS_DATE_FORMAT, DOC_TYPE } from '../../../../Constants/Constant';
import { localizedText } from '../../../../Services/Localization/Localization';
import Utility, { getCapitalized } from '../../../../Utility/Utility';
import AuthService from '../../../../Services/Auth';
import { subDays } from 'date-fns';
import DateFormatService from '../../../../Services/DateFormat';
import { isSalesDocument } from '../../Utilities/DocCommonUtils';

export const getDocDateLabel = (documentType: DOC_TYPE): string => {
  switch (documentType) {
    case DOC_TYPE.QUOTE:
      return `${getCapitalized(localizedText('quote'))} Date`;
    case DOC_TYPE.INVOICE:
      return 'Invoice Date';
    case DOC_TYPE.SALES_ORDER:
    case DOC_TYPE.ORDER:
    case DOC_TYPE.JOB_WORK_OUT_ORDER:
      return 'Order Date';
    case DOC_TYPE.BILL:
      return 'Bill Date';
    default:
      return '';
  }
};

export const getShipByDateLabel = (documentType: DOC_TYPE) => {
  if (isSalesDocument(documentType)) {
    return 'Ship By (expected)';
  }
  return 'Receive By';
};

export const getCalendarView = (
  selectedDate: any,
  onSelect: (date: Date) => void,
  toggleView: (toggle: boolean) => void
) => {
  return (
    <DKCalendar
      className="position-absolute bg-white border-m z-index-3 p-xs border-radius-s shadow-m border-box"
      style={{ right: 0, top: 30 }}
      selectedDate={selectedDate}
      onSelectDate={(newDate: Date) => {
        onSelect(newDate);
        toggleView(false);
      }}
      onClose={() => setTimeout(() => toggleView(false))}
    />
  );
};

export /**
 * Validate Document date with active date range settings
 * @param newDate
 * @param tenantInfo
 * @param callback
 * @param warningMessage
 * @returns
 */
const activeDateRangeValidation = (
  newDate: Date,
  tenantInfo: any,
  callback: (date: Date) => void,
  warningMessage: string,
  documentType: DOC_TYPE
) => {
  let checkActiveRange: boolean = true;
  const isActiveDateRange =
    tenantInfo?.additionalSettings?.ACTIVE_DATE_RANGE_SETTING
      ?.isActiveDateRange || false;
  let fromDate =
    tenantInfo?.additionalSettings?.ACTIVE_DATE_RANGE_SETTING?.activeFromDate;
  let toDate =
    tenantInfo?.additionalSettings?.ACTIVE_DATE_RANGE_SETTING?.activeToDate;
  const isBackDatedEnable =
    tenantInfo?.additionalSettings?.BACK_DATE_RESTRICTION_SETTING
      ?.isBackDateRestrictionEnabled || false;
  const configDetails =
    tenantInfo?.additionalSettings?.BACK_DATE_RESTRICTION_SETTING
      ?.dateRestrictionConfigs || [];

  if (isBackDatedEnable && !Utility.isEmpty(configDetails)) {
    let documentConfig = configDetails.find(
      (ele: any) => ele.documentType === documentType
    );
    if (documentConfig && documentConfig.restrictType === 'Fully_Restrict') {
      let backDate = subDays(
        new Date(new Date().setHours(0, 0, 0, 0)),
        Number(documentConfig.noOfDays)
      );
      let formatedDate = DateFormatService.getDateStrFromDate(backDate);
      if (newDate.getTime() >= backDate.getTime()) {
        checkActiveRange = true;
      } else {
        showAlert(
          'Invalid Date',
          `${getDocDateLabel(
            documentType
          )} should not be less than back date : ${formatedDate}.`
        );
        return;
      }
    }
  }
  if (
    checkActiveRange &&
    isActiveDateRange &&
    !Utility.isEmpty(fromDate) &&
    !Utility.isEmpty(toDate)
  ) {
    let minAcceptedDate = DateFormatService.getDateFromStr(
      fromDate,
      BOOKS_DATE_FORMAT['YYYY-MM-DD']
    );
    let maxAcceptedDate = DateFormatService.getDateFromStr(
      toDate,
      BOOKS_DATE_FORMAT['YYYY-MM-DD']
    );
    const startDate = DateFormatService.getFormattedDateString(
      fromDate,
      BOOKS_DATE_FORMAT['YYYY-MM-DD']
    );
    const endDate = DateFormatService.getFormattedDateString(
      toDate,
      BOOKS_DATE_FORMAT['YYYY-MM-DD']
    );
    if (
      newDate.getTime() >= minAcceptedDate.getTime() &&
      newDate.getTime() <= maxAcceptedDate.getTime()
    ) {
      callback(newDate);
      // TODO: set document touched
    } else {
      showAlert(
        'Invalid Date',
        ` ${warningMessage} - From Date : ${startDate} To Date : ${endDate}.`
      );
    }
  } else {
    callback(newDate);
    // TODO: set document touched
  }
};

export const validateAndUpdateDate = (
  newDate: Date,
  minAcceptedDate: Date,
  callback: (date: Date) => void,
  warningMessage: string,
  isDocDate: boolean,
  documentType: DOC_TYPE,
  resetDate?: () => void
) => {
  const tenantInfo = AuthService.currentTenantInfo;
  if (newDate.getTime() >= minAcceptedDate.getTime()) {
    let closeDateFY: Date = Utility.getCloseDateFY();
    const tenantCloseDateFY = tenantInfo.fyClosingPeriodEndDate;
    if (tenantCloseDateFY && closeDateFY.getTime() > newDate.getTime()) {
      showAlert(
        'Invalid Date',
        `${getDocDateLabel(
          documentType
        )} should not before financial year close date`
      );
      return;
    }
    if (isDocDate) {
      activeDateRangeValidation(
        newDate,
        tenantInfo,
        (date: Date) => {
          callback(date);
        },
        `${getDocDateLabel(documentType)} should be in active date range`,
        documentType
      );
      return;
    }
    callback(newDate);
    // TODO: Set is doc touched
  } else {
    showAlert('Invalid Date', getCapitalized(warningMessage.toLowerCase()));
    if (resetDate) {
      resetDate();
    }
  }
};
