import { FC, useContext, useEffect, useState } from 'react';
import { DKButton, DKIcons, DKLabel } from 'deskera-ui-library';
import { useAppDispatch, useAppSelector } from '../../../../../Redux/Hooks';
import { CommonDraftPropsContext } from '../../../Utilities/DocContext';
import {
  selectDocumentFormDataByKeys,
  updateMultipleKeysInDocument
} from '../../../../../Redux/Slices/DocumentSlice';
import {
  CHARGE_TYPE,
  DOCUMENT_KEYS,
  IDocSummaryAmount
} from '../../../Utilities/DocConstants';
import {
  selectAdditionalBuyCharges,
  selectAddtionalSellCharges
} from '../../../../../Redux/Slices/AdditionalChargesSlice';
import {
  buildAdditionalCharges,
  buildAdditionalChargesToSaveInStore,
  buildAdditionalDiscount,
  calculateChargeAmountAndTax,
  getAdditionalChargesTotalAndTax
} from '../../../Helper/Common/DocDataHelper';
import { isSalesDocument } from '../../../Utilities/DocCommonUtils';
import {
  DOCUMENT_MODE,
  DOC_TYPE,
  PRODUCT_TYPE
} from '../../../../../Constants/Constant';
import { PRODUCT_OFFERING_TYPE } from '../../../../../Constants/Enum';
import ExtraChargeInput from '../../../../../SharedComponents/DocumentForm/ExtraChargeInput';
import NumberFormatService from '../../../../../Services/NumberFormat';
import Utility from '../../../../../Utility/Utility';
import { activeTenantInfo } from '../../../../../Redux/Slices/AuthSlice';
import cloneDeep from 'lodash/cloneDeep';
import AddChargeButton from './DocAddChargeButton';
import AdditionalCharges from '../../../../../SharedComponents/AdditionalCharges/AdditionalCharges';

interface IAdditionalChargesProps {
  summaryAmount: IDocSummaryAmount;
}

const DocAdditionalChargesDiscounts: FC<IAdditionalChargesProps> = ({
  summaryAmount
}) => {
  const dispatch = useAppDispatch();
  const { draftId, documentMode } = useContext(CommonDraftPropsContext);
  const [additionalCharges, documentType, currency, items, gstType] =
    useAppSelector(
      selectDocumentFormDataByKeys(draftId, [
        DOCUMENT_KEYS.ADDITIONAL_CHARGES,
        DOCUMENT_KEYS.DOCUMENT_TYPE,
        DOCUMENT_KEYS.CURRENCY,
        DOCUMENT_KEYS.ITEMS,
        DOCUMENT_KEYS.GST_TYPE
      ])
    );

  const additionalBuyCharges = useAppSelector(selectAdditionalBuyCharges);
  const additionalSellCharges = useAppSelector(selectAddtionalSellCharges);
  const [charges, setCharges] = useState<any[]>();
  const [discounts, setDiscounts] = useState<any[]>([]);
  const [chargeToAllocate, setChargeToAllocate] = useState<any>(null);
  const [showAllocationPopup, setShowAllocationPopup] =
    useState<boolean>(false);
  const isReadOnly = documentMode === DOCUMENT_MODE.VIEW;
  const billOrOrder =
    documentType === DOC_TYPE.BILL || documentType === DOC_TYPE.ORDER;
  const isUSOrg = Utility.isUSorg();
  const { additionalSettings } = useAppSelector(activeTenantInfo);

  useEffect(() => {
    const chargesToPass = isSalesDocument(documentType)
      ? additionalSellCharges
      : additionalBuyCharges;

    const filteredCharges = buildAdditionalCharges(
      additionalCharges?.additionalChargesDetails,
      chargesToPass
    );
    setCharges(filteredCharges);
    const filteredDiscount = buildAdditionalDiscount(
      additionalCharges?.globalDiscounts,
      chargesToPass
    );
    setDiscounts(filteredDiscount);
  }, [additionalCharges]);

  const itemHasTrackedBOM = items?.some(
    (item: any) =>
      item?.product?.type === PRODUCT_TYPE.TRACKED ||
      item?.product?.type === PRODUCT_TYPE.BILL_OF_MATERIALS
  );

  const onChargeChange = ({
    type,
    value,
    isPercent,
    hasError,
    charge,
    index
  }: any) => {
    value = isPercent ? Number(value.replace('%', '')) : value;
    const chargesToUse = cloneDeep(
      (type === CHARGE_TYPE.ADDITIONAL_CHARGE ? charges : discounts) || []
    );
    if (Utility.isNotEmpty(charge)) {
      calculateChargeAmountAndTax({
        draftId,
        charge,
        isPercent,
        index,
        chargesToUse,
        value,
        summaryAmount
      }).then((data) => {
        chargesToUse[index] = {
          ...charge,
          isPercent,
          percentageValue: isPercent
            ? value
              ? value
              : charge.percentageValue
            : charge.percentageValue,
          hasError,
          chargeValue: data.chargeAmount,
          taxAmount: data.taxOnChargeAmount,
          isManualChange: data.isManualChange
        };
        const updatedAdditionalCharges = { ...additionalCharges };
        if (type === CHARGE_TYPE.ADDITIONAL_CHARGE) {
          updatedAdditionalCharges[CHARGE_TYPE.ADDITIONAL_CHARGE] =
            chargesToUse;
          updatedAdditionalCharges[CHARGE_TYPE.DISCOUNT] = discounts;
        } else {
          updatedAdditionalCharges[CHARGE_TYPE.ADDITIONAL_CHARGE] = charges;
          updatedAdditionalCharges[CHARGE_TYPE.DISCOUNT] = chargesToUse;
        }
        const addCharges = buildAdditionalChargesToSaveInStore({
          gstType,
          documentType,
          additionalCharges: updatedAdditionalCharges,
          hasBOMOrTracked: itemHasTrackedBOM
        });

        dispatch(
          updateMultipleKeysInDocument({
            draftId,
            keysToUpdate: {
              [DOCUMENT_KEYS.ADDITIONAL_CHARGES]: addCharges
            }
          })
        );
      });
    }
  };

  const onChargeDelete = (charge: any, type: CHARGE_TYPE) => {
    const copyOfCharges = cloneDeep(
      type === CHARGE_TYPE.ADDITIONAL_CHARGE
        ? additionalCharges?.additionalChargesDetails
        : additionalCharges?.globalDiscounts
    );
    let indexOfCharge = -1;
    if (type === CHARGE_TYPE.ADDITIONAL_CHARGE) {
      indexOfCharge = copyOfCharges?.findIndex?.(
        (addCharge: any) => addCharge.additionalCharge === charge.name
      );
    } else {
      indexOfCharge = copyOfCharges?.findIndex?.(
        (addCharge: any) => addCharge.name === charge.name
      );
    }

    if (indexOfCharge > -1) {
      copyOfCharges.splice(indexOfCharge, 1);
      const value = { ...additionalCharges };
      if (type === CHARGE_TYPE.ADDITIONAL_CHARGE) {
        value['additionalChargesDetails'] = copyOfCharges;
      } else {
        value['globalDiscounts'] = copyOfCharges;
      }
      if (type === CHARGE_TYPE.ADDITIONAL_CHARGE) {
        const additionalChargesAmountInfo = getAdditionalChargesTotalAndTax(
          value.additionalChargesDetails
        );
        value['additionalChargeAmount'] = additionalChargesAmountInfo.total;
        value['additionalChargeTaxAmount'] = additionalChargesAmountInfo.tax;
      }

      dispatch(
        updateMultipleKeysInDocument({
          draftId,
          keysToUpdate: {
            [DOCUMENT_KEYS.ADDITIONAL_CHARGES]: value
          }
        })
      );
    }
  };

  const getChargeField = (charge: any, index: number, type: CHARGE_TYPE) => {
    return (
      <div
        className="row parent-width mb-m justify-content-between align-items-start position-relative parent-block"
        style={{ width: '100%' }}
      >
        {!isReadOnly && (
          <DKButton
            title=""
            icon={DKIcons.ic_delete}
            className="position-absolute child-block"
            style={{ left: -30, top: -7, opacity: 0.5 }}
            onClick={() => onChargeDelete(charge, type)}
          />
        )}
        <div
          className="row width-auto"
          style={{
            maxWidth: 200
          }}
        >
          <div className="column parent-width">
            <DKLabel
              text={charge?.name}
              className="fs-r text-align-left ml-r fw-m"
              style={{ overflowWrap: 'break-word', whiteSpace: 'pre-wrap' }}
            />
            <DKLabel
              text={
                charge?.indiaProperties
                  ? charge.offeringType === PRODUCT_OFFERING_TYPE.SERVICES
                    ? charge?.indiaProperties?.SAC
                      ? 'SAC: ' + charge?.indiaProperties?.SAC
                      : ''
                    : charge?.indiaProperties?.HSN
                    ? 'HSN: ' + charge?.indiaProperties?.HSN
                    : ''
                  : ''
              }
              className="ml-r text-gray fs-s"
            />
          </div>
        </div>
        <div className="pb-l">
          <ExtraChargeInput
            charge={charge}
            disabled={isReadOnly}
            onValueChange={(data) => {
              onChargeChange({
                ...data,
                charge,
                index,
                type
              });
            }}
          />
          {!charge.hasError && charge.isPercent && (
            <DKLabel
              text={`${Utility.getCurrencySymbolFromCode(
                currency
              )} ${NumberFormatService.getNumber(charge.chargeValue)}`}
              className="text-align-right"
            />
          )}
        </div>
      </div>
    );
  };

  const getAdditionalChargeAllocationPopup = () => {
    if (Utility.isEmptyObject(chargeToAllocate?.charge)) return null;
    return (
      <AdditionalCharges
        booksDocument={cloneDeep({ currency, items, additionalCharges })}
        documentMode={documentMode ? documentMode : DOCUMENT_MODE.VIEW}
        chargeDetails={[chargeToAllocate?.charge]}
        onCancel={() => {
          setChargeToAllocate(null);
          setShowAllocationPopup(false);
        }}
        onSave={(data: any) => {
          let chargesToSelectFrom =
            chargeToAllocate?.chargeType === CHARGE_TYPE.ADDITIONAL_CHARGE
              ? charges
              : discounts;
          let selectedChargeIndex = chargesToSelectFrom?.findIndex(
            (charge: any) => charge?.name === data?.chargeDetails[0]?.name
          );
          if (selectedChargeIndex && selectedChargeIndex > -1) {
            onChargeChange({
              type: chargeToAllocate?.chargeType,
              charge: data?.chargeDetails[0],
              index: selectedChargeIndex
            });
            dispatch(
              updateMultipleKeysInDocument({
                draftId,
                keysToUpdate: {
                  [DOCUMENT_KEYS.ITEMS]: data?.booksDocument?.items
                }
              })
            );
          }
          setChargeToAllocate(null);
          setShowAllocationPopup(false);
        }}
      />
    );
  };

  const hasItems = items?.length === 0;
  const showAllocationButton =
    itemHasTrackedBOM &&
    billOrOrder &&
    isUSOrg &&
    additionalSettings?.ADDITIONAL_CHARGE?.apportionFlag;

  return (
    <>
      {charges?.map((charge, index) => {
        return (
          <>
            <div className="parent-width">
              {getChargeField(charge, index, CHARGE_TYPE.ADDITIONAL_CHARGE)}
              {showAllocationButton && (
                <DKButton
                  title={`Allocate Additional Cost`}
                  onClick={() => {
                    if (Utility.isEmpty(charge.apportionValue)) {
                      charge = {
                        ...charge,
                        ...additionalSettings?.ADDITIONAL_CHARGE
                      };
                    }
                    setShowAllocationPopup(true);
                    setChargeToAllocate({
                      charge,
                      chargeType: CHARGE_TYPE.ADDITIONAL_CHARGE
                    });
                  }}
                  className={`${
                    hasItems ? 'text-gray' : 'text-blue'
                  } fw-m p-0 justify-content-end mb-5 mt-2 ${
                    isReadOnly ? 'text-gray pointer-events-auto' : ''
                  }`}
                  style={{ marginTop: -10, zIndex: 1, paddingRight: 0 }}
                  disabled={hasItems}
                />
              )}
            </div>
          </>
        );
      })}

      <AddChargeButton
        charges={charges}
        discounts={discounts}
        summaryAmount={summaryAmount}
      />

      {discounts?.map((discount, index) => {
        return (
          <>
            <div className="parent-width">
              {getChargeField(discount, index, CHARGE_TYPE.DISCOUNT)}
              {showAllocationButton && (
                <DKButton
                  title={`Allocate Additional Cost`}
                  onClick={() => {
                    if (Utility.isEmpty(discount.apportionValue)) {
                      discount = {
                        ...discount,
                        ...additionalSettings?.ADDITIONAL_CHARGE
                      };
                    }
                    setShowAllocationPopup(true);
                    setChargeToAllocate({
                      charge: discount,
                      chargeType: CHARGE_TYPE.DISCOUNT
                    });
                  }}
                  className={`${
                    hasItems ? 'text-gray' : 'text-blue'
                  } fw-m p-0 justify-content-end mb-5 mt-2 ${
                    isReadOnly ? 'text-gray pointer-events-auto' : ''
                  }`}
                  style={{ marginTop: -10, zIndex: 1, paddingRight: 0 }}
                  disabled={hasItems}
                />
              )}
            </div>
          </>
        );
      })}
      {showAllocationPopup && getAdditionalChargeAllocationPopup()}
    </>
  );
};

export default DocAdditionalChargesDiscounts;
