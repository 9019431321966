import React, { Component } from "react";
import "../../index.css";
import { COLOR_LIGHTGRAY, EditorInfoType, FONT_SIZE, PAGE_PADDING } from '../../Constants/Constants';
import DKCustomFieldSection from "../../DKUILibrary/DKCustomFieldSection";
import DKEInvoiceSection, { eInvoiceSectionPosition } from "../../DKUILibrary/DKEInvoiceSection";
import DKIndiaTaxBreakdown from "../../DKUILibrary/DKIndiaTaxBreakdown";
import DKSignatureSection from "../../DKUILibrary/DKSignatureSection";
import { AddressType, OwnerType } from "../../Helper/AddressParser";
import ComponentManager from "../../Manager/ComponentManager";
import DocumentManager from "../../Manager/DocumentManager";
import { getLocalisedText } from '../../Translate/LanguageManager';
import { HTMLTag } from "../../Utilities/HTMLTag";
import Utility, { getFontSizeClass, getIsBill, getIsFulfillment, getIsGoodsReceipt, getIsOrder, getIsProductDocumentForBuySell, getPageHeight, getVW } from "../../Utilities/Utility";
import TopSectionAboveTableUtility from "../../Utilities/TopSectionAboveTableUtility";
import "./css/Templates.css";
import DKComponentListTable from "../../DKUILibrary/DKComponentListTable";
import DKApprovalSection from "../../DKUILibrary/DKApprovalSection";

export default class IndiaTemplate7 extends Component { //based on template 1

    arabicHeaderWidth = '85%'

    constructor(props) {
        super(props);
        this.state = {
            data: this.props.data,
        };
    }

    componentWillReceiveProps(nextProp) {
        this.setState({
            data: nextProp.data,
        });
    }

    render() {
        return (
            <div className='DocumentHolder ColumnDiv' style={{ justifyContent: 'space-between', minHeight: getPageHeight(this.state.data.printScale)}}>
                <div className='parent-width' style={{ width: '100%', paddingBottom: getVW(30) }}>
                    <DKEInvoiceSection
                        data={this.state.data}
                        position={eInvoiceSectionPosition.top}
                    />
                    {this.getDocumentType()}
                    {this.getCompanyLogo()}
                    {this.getAddressSection()}
                    {/* {this.state.data.showFrom && this.this.getCompanyAddress()} */}
                    {ComponentManager.addVerticalSpace(10)}
                    {/* section 2 */}
                    {this.getDocumentDetailsSection()}
                    {/* {this.getBillingShippingAndDocumentInfoSection()} */}
                    {/* section 3 */}
                    {(Utility.getIsShowSourceOfSupply(this.state.data) || Utility.getIsShowDestinationOfSupply(this.state.data) || Utility.getIsShowPlaceOfSupply(this.state.data)) && ComponentManager.addVerticalSpace(10)}
                    {Utility.getIsShowPlaceOfSupply(this.state.data) && this.getPlaceOfSupplyInfoSection()}
                    {(Utility.getIsShowSourceOfSupply(this.state.data) || Utility.getIsShowDestinationOfSupply(this.state.data)) && this.getSourceOfSupplyInfoSection()}
                    {Utility.getIsContainsCustomField('top') && ComponentManager.addVerticalSpace(10)}
                    <DKCustomFieldSection data={this.state.data} onCustomFieldClick={this.props.onCustomFieldClick} style={this.getCustomFieldTableStyle()} customFieldPosition={'top'} isThemeColor isShowGrid />
                    {ComponentManager.addVerticalSpace(20)}
                    {
                        TopSectionAboveTableUtility.getIsTopSectionAboveTableIsVisible() &&
                        <>
                            <div style={{ ...TopSectionAboveTableUtility.getTopSectionWithTableFontStyleProp(undefined, this.state.data.printScale) }}
                                dangerouslySetInnerHTML={{ __html: TopSectionAboveTableUtility.getHtmlString(this.state.data) }} />
                            {ComponentManager.addVerticalSpace(5)}
                        </>
                    }
                    {this.getProductTable()}
                    {Utility.getIsShowAccountProductTable(this.state.data) && ComponentManager.addVerticalSpace(20)}
                    {this.getEditableDocumentTable()}
                    {Utility.getIsShowExchangeRate(this.state.data) && ComponentManager.addVerticalSpace(15)}
                    {Utility.getIsShowExchangeRate(this.state.data) && this.getExchangeRateTable(this.getTableStyle(), this.state.data)}
                    {Utility.getIsContainsCustomField('bottom') && ComponentManager.addVerticalSpace(20)}
                    <DKCustomFieldSection data={this.state.data} onCustomFieldClick={this.props.onCustomFieldClick} style={this.getCustomFieldTableStyle()} customFieldPosition={'bottom'} isThemeColor isShowGrid />
                    {ComponentManager.addVerticalSpace(15)}
                    {getIsProductDocumentForBuySell(this.state.data) &&
                        Utility.getIsShowHsnSacTaxTable() &&
                        <DKIndiaTaxBreakdown
                            data={this.state.data}
                        />
                    }
                    {Utility.getIsIncludesBomProductTable() && <DKComponentListTable data={this.state.data} />}
                    {this.state.data.showSignature && ComponentManager.addVerticalSpace(Utility.convertToPrintScale(15, this.state.data.printScale))}
                    {this.state.data.showSignature && ComponentManager.getSignature(this.state.data.signature,this.state.data.printScale)}
                </div>
                <div className='parent-width'>
                    {(getIsFulfillment(this.state.data.type) || getIsGoodsReceipt(this.state.data.type)) && <DKSignatureSection data={this.state.data} onSignatureSectionClicked={this.props.tableClicked} isThemeColor/>}
                    {this.addTermsNConditionsView()}
                    {Utility.getIsShowApprovalSection() && <DKApprovalSection data={this.state.data} style={this.getTableStyle()} isThemeColor />}
                </div>
                <DKEInvoiceSection
                    data={this.state.data}
                    position={eInvoiceSectionPosition.bottom}
                />
                {Utility.getGeneratedVisibility() && this.getGenerateDocumentSection()}
            </div>
        );
    }


    getDocumentType() {
        return <div className="RowDiv"
            style={{
                alignItems: 'center',
                justifyContent: 'center',
                marginBottom: getVW(20),
                flexDirection: Utility.getIsArabicLang() ? 'row-reverse' : 'row'
            }}>
            <text className={"docFontStyleBold" + getFontSizeClass()}
                style={{
                    color: "rgb(100,100,100)",
                    ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.xxLarge },this.state.data.printScale)
                }}>
                {getLocalisedText(this.state.data.documentType)}
            </text>
        </div>;
    }

    getCompanyLogo() {
        return <div className='ColumnDiv'>
            {ComponentManager.getLogoVisibility(this.state.data.showCompanyLogo, this.state.data.isReadOnlyMode, this.state.data.logo) &&
                ComponentManager.getLogo(this.state.data.logo, Utility.convertToPrintScale(80, this.state.data.printScale), {
                    position: 'relative',
                    justifyContent: 'left',
                })
            }
        </div>
    }


    addTermsNConditionsView() {
        return (
            <div id={HTMLTag.TERMS_NOTE_PREFIX} style={{}}>
                {(this.state.data.showTermsAndCondition && this.state.data.termsAndCondition) &&
                    <>
                    <div className="RowDiv" style={{ width: '100%' }}>
                        <text className={"docFontStyleBold" + getFontSizeClass()} style={{
                            color: this.state.data.themeColor,
                            ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular, ownerType: undefined },this.state.data.printScale)
                        }}>
                            {getLocalisedText('terms_&_condition').toUpperCase()}
                        </text>
                    </div>
                    {ComponentManager.addDivider({ backgroundColor: 'black', height: getVW(2), width: '100%' })}
                    {ComponentManager.getTermsAndConditionOrNotesSection(
                        '',
                        getLocalisedText(this.state.data.termsAndCondition),
                        "100%",
                        this.state.data.themeColor,
                        undefined,
                        undefined,
                        undefined,
                        this.state.data.printScale,
                        Utility.getTnCOrNotesFontSettingsByType(EditorInfoType.termsAndCondition)
                    )}
                    </>
                }
                {((this.state.data.showTermsAndCondition && this.state.data.termsAndCondition) && (this.state.data.showNotes && this.state.data.notes)) && ComponentManager.addVerticalSpace(Utility.convertToPrintScale(20, this.state.data.printScale))}
                {(this.state.data.showNotes && this.state.data.notes) &&
                    <>
                        <div className="RowDiv" style={{ width: '100%' }}>
                            <text className={"docFontStyleBold" + getFontSizeClass()} style={{
                                color: this.state.data.themeColor,
                                ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular, ownerType: undefined },this.state.data.printScale)
                            }}>
                            {getLocalisedText('notes').toUpperCase()}
                            </text>
                        </div>
                        {ComponentManager.addDivider({ backgroundColor: 'black', height: getVW(2), width: '100%' })}
                        {ComponentManager.getTermsAndConditionOrNotesSection(
                            '',
                            getLocalisedText(this.state.data.notes),
                            "100%",
                            this.state.data.themeColor,
                            undefined,
                            undefined,
                            undefined,
                            this.state.data.printScale,
                            Utility.getTnCOrNotesFontSettingsByType(EditorInfoType.notes)
                        )}
                    </>
                }
            </div>
        )
    }

    getGenerateDocumentSection() {
        return <div style={{
            paddingBottom: getVW(PAGE_PADDING, true),
            paddingTop: getVW(5),
            width: '100%'
        }}>
            <text className={'docFontStyleBold fontSizeNormal ' + getFontSizeClass()}
                style={{
                    ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular },this.state.data.printScale)
                }}>
                {Utility.getGeneratedMessage(this.state.data.type)}
            </text>
        </div>
    }

    getAddressSection() {
        return <>
            <div
                className="RowDiv parent-width"
                style={{
                    alignItems: "flex-start",
                    justifyContent: 'space-between',
                    backgroundColor: "white",
                }}
            >
                <div className="ColumnDiv pt-s pr-s parent-width">
                    {this.state.data.showCompanyName && this.getCompanyName()}
                    {this.state.data.showFrom && this.getCompanyAddress()}
                </div>
                <div className="ColumnDiv pt-s pl-s parent-width">
                    {this.state.data.showClientShipFromAddress && this.getAddressContainer(this.state.data.shipFromTitle, this.getShipFromSection())}
                </div>
            </div>
            <div
                className="RowDiv parent-width"
                style={{
                    alignItems: "flex-start",
                    justifyContent: 'space-between',
                    backgroundColor: "white",
                }}
            >
                <div className="ColumnDiv pt-s pr-s parent-width">
                    {this.state.data.showClientBillToAddress && this.getAddressContainer(this.state.data.billToTitle, this.getBillToSection())}
                </div>
                <div className="ColumnDiv pt-s pl-s parent-width">
                    {this.state.data.showClientShipToAddress && this.getAddressContainer(this.state.data.shipToTitle, this.getShipToSection())}
                </div>
            </div>
        </>
    }


    getCompanyName() {
        return (
            <div className="RowDiv"
                style={{
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    width: Utility.getIsArabicLang() ? '100%' : '90%',
                    flexDirection: Utility.getIsArabicLang() ? 'row-reverse' : 'row'
                }}>
                <text className={"docFontStyleBold" + getFontSizeClass()}
                    style={{
                        ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.large },this.state.data.printScale)
                    }}>
                    {getLocalisedText(this.state.data.tenantInfo ? this.state.data.tenantInfo.name : Utility.getCompanyName(this.state.data))}
                </text>
            </div>
        )
    }

    getCompanyAddress() {
        return <>
            {ComponentManager.getDescriptionSection(
                "",
                this.state.data.tenantAddress,
                '90%',
                this.state.data.themeColor,
                false,
                '#000000',
                getVW(6),
                OwnerType.tenant,  
                this.state.data.printScale
            )}
            {/* {ComponentManager.getDescriptionSection(
                "",
                this.state.data.companyPhoneNumber,
                Utility.getIsArabicLang() ? this.arabicHeaderWidth : '25%',
                this.state.data.themeColor
            )}
            {ComponentManager.getDescriptionSection(
                "",
                this.state.data.companyGSTIN,
                Utility.getIsArabicLang() ? this.arabicHeaderWidth : '25%',
                this.state.data.themeColor
            )} */}
            {ComponentManager.getDescriptionSection(
                "",
                this.state.data.customerStateNameAndCode,
                '90%',
                this.state.data.themeColor,
                false,
                undefined,
                0,
                undefined,  
                this.state.data.printScale
            )}
        </>
    }

    getAddressContainer(title, value) {
        return <>
            <div className="Column parent-width">
                <div className="RowDiv"
                    style={{
                        backgroundColor: this.state.data.themeColor,
                        justifyContent: 'center',
                        width: '100%',
                        border: '1px solid black',
                    }}>
                    <text className={"docFontStyleBold" + getFontSizeClass()}
                        style={{
                            ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.medium, color: 'white' },this.state.data.printScale)
                        }}>
                        {getLocalisedText(title)}
                    </text>
                </div>
                <div className="RowDiv"
                    style={{
                        width: '100%',
                        border: '1px solid black',
                    }}>
                    <div className="RowDiv pl-s pr-s pt-s pb-s">
                        {value}
                    </div>
                </div>
            </div>
        </>
    }

    getBillToSection() {
        return <>
            <div className='ColumnDiv'>
                {ComponentManager.getDescriptionSection(
                    '',
                    Utility.getClientBillToAddress(this.state.data),
                    "100%",
                    this.state.data.themeColor,
                    false,
                    '#000000',
                    getVW(6),
                    Utility.getOwnerType(this.state.data, AddressType.billTo),
                    this.state.data.printScale
                )}
                {/* {ComponentManager.getDescriptionSection(
                    "",
                    this.state.data.customerPhoneNumber,
                    "100%",
                    this.state.data.themeColor
                )}
                {ComponentManager.getDescriptionSection(
                    "",
                    this.state.data.customerGSTIN,
                    "100%",
                    this.state.data.themeColor
                )} */}
                {ComponentManager.getDescriptionSection(
                    "",
                    this.state.data.billToAddressStateCode,
                    "100%",
                    this.state.data.themeColor,
                    false,
                    undefined,
                    0,
                    undefined,
                    this.state.data.printScale
                )}
            </div>
        </>
    }


    getShipToSection() {
        return <>
            <div className='ColumnDiv'>
                {this.state.data.showClientShipToAddress && ComponentManager.getDescriptionSection(
                    '',
                    Utility.getClientShipToAddress(this.state.data),
                    "100%",
                    this.state.data.themeColor,
                    false,
                    '#000000',
                    getVW(6),
                    Utility.getOwnerType(this.state.data, AddressType.shipTo),
                    this.state.data.printScale
                )}
                {/* {ComponentManager.getDescriptionSection(
                    "",
                    this.state.data.customerPhoneNumber,
                    "100%",
                    this.state.data.themeColor
                )}
                {ComponentManager.getDescriptionSection(
                    "",
                    this.state.data.customerGSTIN,
                    "100%",
                    this.state.data.themeColor
                )} */}
                {this.state.data.showClientShipToAddress && ComponentManager.getDescriptionSection(
                    "",
                    this.state.data.shipToAddressStateCode,
                    "100%",
                    this.state.data.themeColor,
                    false,
                    undefined,
                    0,
                    undefined,
                    this.state.data.printScale
                )}
            </div>
        </>
    }

    getShipFromSection() {
        return <>
            <div className="ColumnDiv">
                {ComponentManager.getDescriptionSection(
                    getLocalisedText(Utility.getCompanyName(this.state.data)),
                    Utility.getClientShipFromAddress(this.state.data),
                    '100%',
                    this.state.data.themeColor,
                    false,
                    '#000000',
                    getVW(6),
                    Utility.getOwnerType(this.state.data, AddressType.from),
                    this.state.data.printScale
                )}
                {/* {ComponentManager.getDescriptionSection(
                "",
                this.state.data.companyPhoneNumber,
                Utility.getIsArabicLang() ? this.arabicHeaderWidth : '25%',
                this.state.data.themeColor
            )}
            {ComponentManager.getDescriptionSection(
                "",
                this.state.data.companyGSTIN,
                Utility.getIsArabicLang() ? this.arabicHeaderWidth : '25%',
                this.state.data.themeColor
            )} */}
                {ComponentManager.getDescriptionSection(
                    "",
                    (getIsOrder(this.state.data.type) || getIsBill(this.state.data.type)) ? this.state.data.billOrderCompanyStateNameCode : this.state.data.companyStateNameAndCode,
                    '100%',
                    this.state.data.themeColor,
                    false,
                    undefined,
                    0,
                    undefined,
                    this.state.data.printScale
                )}
            </div>
        </>
    }

    getBillingShippingAndDocumentInfoSection() {
        return (
            <div
                className="RowDiv"
                style={{
                    alignItems: "flex-start",
                    justifyContent: 'space-between',
                    backgroundColor: "white",
                }}
            >
                {this.getAddressSection_old()}
                {this.getDocumentInfoSection()}
            </div>
        )
    }
    getPlaceOfSupplyInfoSection() {
        return (
            <div className="RowDiv" style={{ justifyContent: 'flex-start', alignItems: 'flex-start' }}>
            <div
                className="RowDiv"
                style={{
                    alignItems: "flex-start",
                    justifyContent: 'space-between',
                    backgroundColor: "white",
                }}
            >
                {this.getPlaceOfSupply()}

            </div> </div>
        )
    }
    getPlaceOfSupply() {
        return <div className='RowDiv' style={{ width: '100%' }}>
            <div className='ColumnDiv' style={{
                width: '50%'
            }}>
                {Utility.getIsShowPlaceOfSupply(this.state.data) && ComponentManager.getDescriptionSection(
                    getLocalisedText('place_of_supply'),
                    this.state.data.placeOfSupply,
                    "100%",
                    this.state.data.themeColor,
                    false,
                    '#000000',
                    getVW(6),
                    true,
                    this.state.data.printScale
                )}
            </div>

        </div>;

    }

    getSourceOfSupplyInfoSection() {
        return (
            <div
                className="RowDiv"
                style={{
                    alignItems: "flex-start",
                    justifyContent: 'space-between',
                    backgroundColor: "white",
                }}
            >
                {this.getSourceOfSupply()}

            </div>
        )
    }
    getSourceOfSupply() {
        return <div className='RowDiv'>
            <div className='ColumnDiv pl-s' style={{ flex: 1 }}>
                {Utility.getIsShowSourceOfSupply(this.state.data) && ComponentManager.getDescriptionSection(
                    getLocalisedText('source_of_supply'),
                    this.state.data.sourceOfSupply,
                    "100%",
                    this.state.data.themeColor,
                    false,
                    '#000000',
                    getVW(6),
                    true,
                    this.state.data.printScale
                )}
            </div>
            <div className='ColumnDiv pl-l' style={{ flex: 1 }}>
                {Utility.getIsShowDestinationOfSupply(this.state.data) && ComponentManager.getDescriptionSection(
                    getLocalisedText('destination_of_supply'),
                    this.state.data.destinationOfSupply,
                    "100%",
                    this.state.data.themeColor,
                    false,
                    '#000000',
                    getVW(6),
                    true,
                    this.state.data.printScale
                )}
            </div>
        </div>;
    }

    getDocumentInfoSection() {
        return <div className="ColumnDiv">
            {this.getDocumentDetailsSection()}
        </div>;
    }

    getDocumentDetailsSection() {
        var rowSize = 3
        var documentDetailList = Utility.getDocumentDetailsList(this.state.data)
        var newDocumentDetailList = documentDetailList
        var newArr = []
        var newArrWithFill = []
        while (newDocumentDetailList.length > 0) {
            newArr.push(newDocumentDetailList.splice(0, rowSize))
        };

        newArr.forEach((rowData, index) => {
            if (rowData.length !== rowSize) {
                var newRowArray = Array(rowSize).fill('undefined')
                for (let index = 0; index < rowSize; index++) {
                    if (rowData[index] !== undefined) {
                        newRowArray[index] = rowData[index]
                    }
                }
                newArrWithFill.push(newRowArray)
            }
            else {
                newArrWithFill.push(rowData)
            }
        });

        var rowData = newArrWithFill.map((row) => {
            var columns = row.map((item, index) => {
                var value = ''
                var label = ''

                if(item.label !== undefined) {
                    label = item.label
                }
                if (item.key !== undefined) {
                    value = this.state.data[item.key]
                }
                else if (item.customFieldValue !== undefined) {
                    value = item.customFieldValue
                }
                return <>
                    <td className="pl-s pr-s pt-s pb-s" style={{
                        border: '1px solid black',
                    }}>
                        {label}
                    </td>
                    <td className="pl-s pr-s pt-s pb-s" style={{
                        border: '1px solid black',
                    }}>
                        {value}
                    </td>
                </>
            });

            return <tr style={{
                width: '50%',
                verticalAlign: 'top',
                paddingTop: getVW(10),
                paddingBottom: getVW(10),
            }}>
                {columns}
            </tr>
        });


        return <table style={{
            width: '100%',
            borderCollapse: 'collapse',
            margin: 'auto',
            border: '1px solid black',
            textAlign: 'left',
            ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular },this.state.data.printScale)
        }}>
            <tbody>
                {rowData}
            </tbody>
        </table>
    }

    getAddressSection_old() {
        return <div className='RowDiv' style={{ width: '70%' }}>
            <div className='ColumnDiv'>
                {this.state.data.showClientBillToAddress && ComponentManager.getDescriptionSection(
                    this.state.data.billToTitle,
                    Utility.getClientBillToAddress(this.state.data),
                    "100%",
                    this.state.data.themeColor,
                    false,
                    '#000000',
                    getVW(6),
                    Utility.getOwnerType(this.state.data, AddressType.billTo),
                    this.state.data.printScale
                )}
                {/* {ComponentManager.getDescriptionSection(
                    "",
                    this.state.data.customerPhoneNumber,
                    "100%",
                    this.state.data.themeColor
                )}
                {ComponentManager.getDescriptionSection(
                    "",
                    this.state.data.customerGSTIN,
                    "100%",
                    this.state.data.themeColor
                )} */}
                {this.state.data.showClientBillToAddress && ComponentManager.getDescriptionSection(
                    "",
                    this.state.data.billToAddressStateCode,
                    "100%",
                    this.state.data.themeColor,
                    false,
                    undefined,
                    0,
                    undefined,
                    this.state.data.printScale
                )}
            </div>
            {(this.state.data.showClientBillToAddress && this.state.data.showClientShipToAddress) && <div style={{ width: "12%" }} />}
            <div className='ColumnDiv'>
                {this.state.data.showClientShipToAddress && ComponentManager.getDescriptionSection(
                    this.state.data.shipToTitle,
                    Utility.getClientShipToAddress(this.state.data),
                    "100%",
                    this.state.data.themeColor,
                    false,
                    '#000000',
                    getVW(6),
                    Utility.getOwnerType(this.state.data, AddressType.shipTo)
                )}
                {/* {ComponentManager.getDescriptionSection(
                    "",
                    this.state.data.customerPhoneNumber,
                    "100%",
                    this.state.data.themeColor
                )}
                {ComponentManager.getDescriptionSection(
                    "",
                    this.state.data.customerGSTIN,
                    "100%",
                    this.state.data.themeColor
                )} */}
                {this.state.data.showClientShipToAddress && ComponentManager.getDescriptionSection(
                    "",
                    this.state.data.shipToAddressStateCode,
                    "100%",
                    this.state.data.themeColor,
                    false,
                    undefined,
                    0,
                    undefined,
                    this.state.data.printScale
                )}
            </div>
        </div>;
    }

    getEditableDocumentTable() {
        return ComponentManager.getDKDocumentTable(
            this.props.tableColumn,
            this.getTableStyle(),
            this.props.data,
            this.props.tableClicked,
            this.props.stockTableClicked,
            this.props.onUpdateDataCallback,
            this.props.paymentTableClicked,
            this.props.additionalTableCallback,
        )
    }

    getProductTable() {
        if(Utility.getIsShowAccountProductTable(this.state.data)) {
            return ComponentManager.getDKProductTable(
                Utility.getTableColumnForAccountProduct(),
                this.getTableStyle(),
                this.props.data,
                this.props.tableClicked,
                this.props.stockTableClicked,
                this.props.onUpdateDataCallback,
                this.props.paymentTableClicked,
                this.props.additionalTableCallback,
            )
        }
    }

    getTableStyle() {
        let style = DocumentManager.getTableDefaultStyle();
        style.headerTextColor = this.state.data.themeColor;
        style.footerTextColor = this.state.data.themeColor;
        style.headerBorderColor = "#BBBABA";
        style.itemBorderColor = "#BBBABA";
        style.footerBackgroundColor = COLOR_LIGHTGRAY;
        style.footerBorderColor = "#BBBABA";
        return style;
    }

    getCustomFieldTableStyle() {
        let style = {}
        style.columnCount = 3
        style.columnWidths = Utility.getIsArabicLang() ? ['33.5%', '34.5%', '33.5%'] : ['33.5%', '34.5%', '33.5%']
        return style;
    }
    
    getExchangeRateTable(tableStyle, data) {
        return (
            <div id={HTMLTag.EXCHANGE_RATE} className="RowDiv" style={{ justifyContent: "flex-end" }}>
                <div style={{ width: "45%" }}>
                    {ComponentManager.getExchangeRateTable(tableStyle, data)}
                </div>
            </div>
        );
    }
}
