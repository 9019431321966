import ApiConstants from "../API/ApiConstants";
import Utility, { getIsBill, getIsCheque, getIsInvoice, getIsOrder, getIsPayslip, getIsPayrollCheck, getIsQuotation, getIsShipList, getIsSalesOrder, getIsEwayBillDetails, getIsEwayBillSummary, getIsWorkOrder, getIsJobCard, getIsPurchaseRequisition, getIsQCDocument, getIsJobWorkOutOrder, getIsMachine, getIsStockTransfer, getIsStockIssue, getIsStockRequest  } from "../Utilities/Utility";
import { Asset } from "../assets";

export default class TemplateManager {

  static getAllTemplates(type, data) {
    if(this.getIsLabelType(type)) {
      return this.getAllLabelTemplates()
    }
    else if (getIsPayslip(type)) {
      return this.getAllPayslipTemplates(data)
    }
    else if (getIsCheque(type)) {
      return this.getAllChequeTemplates()
    }
    else if (getIsPayrollCheck(type)) {
      return this.getAllPayrollChequeTemplates(data)
    }
    else if (Utility.getIsReceiptPaperSize()) {
      return this.getAllReceiptTemplates()
    }
    else if (getIsEwayBillDetails(type)) {
      return this.getAllEWayBillDetailsTemplates()
    }
    else if (getIsEwayBillSummary(type)) {
      return this.getAllEWayBillSummaryTemplates()
    }
    else if (getIsWorkOrder(type)) {
      return this.getAllWorkOrderTemplates()
    }
    // else if (getIsJobWorkOutOrder(type)) {
    //   return this.getAllJobWorkOutOrderTemplates()
    // }
    else if (getIsJobCard(type)) {
      return this.getAllJobCardTemplates()
    }
    else if (getIsPurchaseRequisition(type)) {
      return this.getAllPurchaseRequisitionTemplates()
    }
    else if (getIsQCDocument(type)) {
      return this.getAllQCTemplates()
    }
    else if (getIsMachine(type)) {
      return this.getAllMachineSchedulerTemplates()
    }
    return this.getAllDocumentTemplates(type, data)
  }

  static getInitTemplate(type) {
    if (getIsPayslip(type)) {
        return this.getPeopleInitTemplate()
    }

    return 1//36 // should be 1
  }

  static getPeopleInitTemplate(country) {
    var countryCode = '';
    if (country !== undefined) {
      countryCode = country;
    } else {
      countryCode = ApiConstants.TENANT_COUNTRY;
    }

    switch(countryCode) {
        case "IN":
            return 1;
        case "US":
            return 2;
        case "SG":
            return 3
        case "MY":
            return 4;
        default:
            return 5;
    }
  }

  static getAllDocumentTemplates(type, data) {
    var defaultTemplate = this.getDefaultDocumentTemplates()
    if (Utility.getIsIndiaCountry(data)) {
      if (getIsQuotation(type)
        || getIsSalesOrder(type)
        || getIsInvoice(type)
        || getIsBill(type)
        || getIsOrder(type)
        || getIsJobWorkOutOrder(type)) {
        var newTemplates = this.getIndiaDocumentTemplates(type)
        defaultTemplate.forEach(template => {
          newTemplates.push(template)
        });
        return newTemplates
      }
    }
    if (getIsStockTransfer(type)) {
      return [...defaultTemplate, ...this.getStockTransferTemplates()]
    }

    if (getIsStockIssue(type)) {
      return [...defaultTemplate, ...this.getStockIssuesTemplates()]
    }

    if (getIsStockRequest(type)) {
      return [...defaultTemplate, ...this.getStockRequestTemplates()]
    }

    return defaultTemplate
  }

  static getIndiaDocumentTemplates(type) {
    var newTemplates = []
    newTemplates.push({
      id: 36,
      thumbnailImage: Asset.template.Template_36
    })
    newTemplates.push({
      id: 38,
      thumbnailImage: Asset.template.Template_38
    })
    newTemplates.push({
      id: 40,
      thumbnailImage: Asset.template.Template_40
    })
    newTemplates.push({
      id: 42,
      thumbnailImage: Asset.template.Template_42
    })
    newTemplates.push({
      id: 43,
      thumbnailImage: Asset.template.Template_43
    })
    newTemplates.push({
      id: 46,
      thumbnailImage: Asset.template.Template_36
    })
    newTemplates.push({
      id: 47,
      thumbnailImage: Asset.template.Template_40
    })
    newTemplates.push({
      id: 48,
      thumbnailImage: Asset.template.Template_43
    })
    newTemplates.push({
      id: 51,
      thumbnailImage: Asset.template.Template_43
    })

    if (getIsBill(type) || getIsOrder(type)) {
        //check if purchase order or bill module
      newTemplates.push({
        id: 44,
        thumbnailImage: Asset.template.Template_44
      })
      newTemplates.push({
        id: 45,
        thumbnailImage: Asset.template.Template_45
      })
    }

    return newTemplates
  }

  static getStockTransferTemplates() {
    var newTemplates = []
    newTemplates.push({
      id: 52,
      thumbnailImage: Asset.template.Stock_Transfer_Template_1
    })

    return newTemplates
  }

  static getStockIssuesTemplates() {
    var newTemplates = []
    newTemplates.push({
      id: 53,
      thumbnailImage: Asset.template.Stock_Issues_Template_1
    })

    return newTemplates
  }

  static getStockRequestTemplates() {
    var newTemplates = []
    newTemplates.push({
      id: 54,
      thumbnailImage: Asset.template.Stock_Request_Template_1
    })

    return newTemplates
  }

  static getDefaultDocumentTemplates() {
    return [
      {
        id: 1,
        thumbnailImage: Asset.template.Template_1
      },
      {
        id: 2,
        thumbnailImage: Asset.template.Template_2
      },
      {
        id: 3,
        thumbnailImage: Asset.template.Template_3
      },
      {
        id: 4,
        thumbnailImage: Asset.template.Template_4
      },
      {
        id: 5,
        thumbnailImage: Asset.template.Template_5
      },
      {
        id: 6,
        thumbnailImage: Asset.template.Template_6
      },
      {
        id: 7,
        thumbnailImage: Asset.template.Template_7
      },
      {
        id: 8,
        thumbnailImage: Asset.template.Template_8
      },
      {
        id: 9,
        thumbnailImage: Asset.template.Template_9
      },
      {
        id: 10,
        thumbnailImage: Asset.template.Template_10
      },
      {
        id: 11,
        thumbnailImage: Asset.template.Template_11
      },
      {
        id: 12,
        thumbnailImage: Asset.template.Template_12
      },
      {
        id: 13,
        thumbnailImage: Asset.template.Template_13
      },
      {
        id: 14,
        thumbnailImage: Asset.template.Template_14
      },
      {
        id: 15,
        thumbnailImage: Asset.template.Template_15
      },
      {
        id: 16,
        thumbnailImage: Asset.template.Template_16
      },
      {
        id: 17,
        thumbnailImage: Asset.template.Template_17
      },
      {
        id: 18,
        thumbnailImage: Asset.template.Template_18
      },
      {
        id: 19,
        thumbnailImage: Asset.template.Template_19
      },
      {
        id: 20,
        thumbnailImage: Asset.template.Template_20
      },
      {
        id: 21,
        thumbnailImage: Asset.template.Template_21
      },
      {
        id: 22,
        thumbnailImage: Asset.template.Template_22
      },
      {
        id: 23,
        thumbnailImage: Asset.template.Template_23
      },
      {
        id: 24,
        thumbnailImage: Asset.template.Template_24
      },
      {
        id: 25,
        thumbnailImage: Asset.template.Template_25
      },
      {
        id: 26,
        thumbnailImage: Asset.template.Template_26
      },
      {
        id: 27,
        thumbnailImage: Asset.template.Template_27
      },
      {
        id: 28,
        thumbnailImage: Asset.template.Template_28
      },
      {
        id: 29,
        thumbnailImage: Asset.template.Template_29
      },
      {
        id: 30,
        thumbnailImage: Asset.template.Template_30
      },
      {
        id: 31,
        thumbnailImage: Asset.template.Template_31
      },
      {
        id: 32,
        thumbnailImage: Asset.template.Template_32
      },
      {
        id: 33,
        thumbnailImage: Asset.template.Template_33
      },
      {
        id: 34,
        thumbnailImage: Asset.template.Template_34
      },
      {
        id: 35,
        thumbnailImage: Asset.template.Template_35
      },
      {
        id: 37,
        thumbnailImage: Asset.template.Template_37
      },
      {
        id: 39,
        thumbnailImage: Asset.template.Template_39
      },
      {
        id: 41,
        thumbnailImage: Asset.template.Template_41
      },
      {
        id: 49,
        thumbnailImage: Asset.template.Template_2
      },
      {
        id: 50,
        thumbnailImage: Asset.template.Template_3
      },
    ];
  }


  static getAllLabelTemplates() {
    return [
      {
        id: 1,
        thumbnailImage: Asset.template.Label_Template_1,
      },
    ];
  }

  static getAllEWayBillDetailsTemplates() {
    return [
      {
        id: 1,
        thumbnailImage: Asset.template.EWayBill_Template_1,
      },
    ];
  }

  static getAllEWayBillSummaryTemplates() {
    return [
      {
        id: 1,
        thumbnailImage: Asset.template.EWayBill_Template_2,
      },
    ];
  }

  static getAllChequeTemplates() {
    const country = ApiConstants.TENANT_COUNTRY;
    switch(country) {
      case 'US':
        return [
          { id: 1, thumbnailImage: Asset.template.Cheque_Template_1 },
          { id: 2, thumbnailImage: Asset.template.Cheque_Template_1 },
          { id: 3, thumbnailImage: Asset.template.Cheque_Template_1 },
          { id: 4, thumbnailImage: Asset.template.Cheque_Template_1 },
          { id: 5, thumbnailImage: Asset.template.Cheque_Template_1 },
          { id: 6, thumbnailImage: Asset.template.Cheque_Template_1 },
          { id: 7, thumbnailImage: Asset.template.Cheque_Template_1 }
        ]
      default:
        return [
          { id: 1, thumbnailImage: Asset.template.Cheque_Template_1 },
          { id: 2, thumbnailImage: Asset.template.Cheque_Template_1 },
          { id: 3, thumbnailImage: Asset.template.Cheque_Template_1 }
        ]
    }
  }

  static getAllReceiptTemplates() {
    return [
      {
        id: 1,
        thumbnailImage: Asset.template.Receipt_Template_1,
      },
      {
        id: 2,
        thumbnailImage: Asset.template.Receipt_Template_2,
      },
    ];
  }

  static getAllPayrollChequeTemplates(data) {
    const country = data.country || ApiConstants.TENANT_COUNTRY;
    switch (country) {
        case "US":
            return [
                {
                  id: 1,
                  thumbnailImage: Asset.template.USPayrollChequeSample_1,
                }
            ];
        default:
            return [];
    }
  }

  static getAllPayslipTemplates(data) {
    // refer to 'getPayslipTemplateWith'
    // As of now supporting only 1 template per country
    const country = data.country || ApiConstants.TENANT_COUNTRY;
    switch (country) {
      case "IN":
        return [
          { id: 1, thumbnailImage: Asset.template.IN_Payslip_1 },
          { id: 6, thumbnailImage: Asset.template.IN_Payslip_2 },
          { id: 7, thumbnailImage: Asset.template.IN_Payslip_3 },
          { id: 8, thumbnailImage: Asset.template.IN_Payslip_4 },
          { id: 9, thumbnailImage: Asset.template.IN_Payslip_5 },
          { id: 10, thumbnailImage: Asset.template.IN_Payslip_6 },
          { id: 11, thumbnailImage: Asset.template.IN_Payslip_7 },
          { id: 12, thumbnailImage: Asset.template.IN_Payslip_1 }
        ];
      case "US":
        return [
          { id: 2, thumbnailImage: Asset.template.US_Payslip_1, },
          { id: 13, thumbnailImage: Asset.template.IN_Payslip_2 },
          { id: 14, thumbnailImage: Asset.template.IN_Payslip_3 },
          { id: 15, thumbnailImage: Asset.template.IN_Payslip_4 },
          { id: 16, thumbnailImage: Asset.template.IN_Payslip_5 },
          { id: 17, thumbnailImage: Asset.template.IN_Payslip_6 },
          { id: 18, thumbnailImage: Asset.template.IN_Payslip_7 }
        ];
      case "SG":
        return [
          { id: 3, thumbnailImage: Asset.template.SG_Payslip_1, },
          { id: 20, thumbnailImage: Asset.template.SG_Payslip_2, },
          { id: 21, thumbnailImage: Asset.template.SG_Payslip_3, },
          { id: 22, thumbnailImage: Asset.template.SG_Payslip_4, },
          { id: 23, thumbnailImage: Asset.template.SG_Payslip_5, }
        ];
      case "MY":
        return [
          { id: 4, thumbnailImage: Asset.template.MY_Payslip_1, },
          { id: 19, thumbnailImage: Asset.template.MY_Payslip_2, }
        ];
      default:
        return [
          { id: 5, thumbnailImage: Asset.template.Payslip_Template_1 }
        ];
    }
  }


  static getAllWorkOrderTemplates() {
    return [
      {
        id: 1,
        thumbnailImage: Asset.template.Work_Order_Template_1,
      },
    ];
  }

  static getAllJobWorkOutOrderTemplates() {
    return [
      {
        id: 1,
        thumbnailImage: Asset.template.Work_Order_Template_1,
      },
    ];
  }

  static getAllJobCardTemplates() {
    return [
      {
        id: 1,
        thumbnailImage: Asset.template.Cheque_Template_1,
      },
    ];
  }

  static getAllPurchaseRequisitionTemplates() {
    return [
      {
        id: 1,
        thumbnailImage: Asset.template.Cheque_Template_1,
      },
    ];
  }

  static getAllQCTemplates() {
    return [
      {
        id: 1,
        thumbnailImage: Asset.template.QC_Document_Template_1,
      },
    ];
  }

  static getAllMachineSchedulerTemplates(){
    return [
      {
        id: 1, 
        thumbnailImage: Asset.template.Machine_Scheduler_Template_1,
      }
    ]
  }

  static getIsLabelType(type) {
    return getIsShipList(type)
  }
}
