import React, { Component } from "react";
import "../../index.css";
import "../../CommonStyles/FontStyle.css";
import { COLOR_LIGHTGRAY, EditorInfoType, FONT_SIZE, LINE_ITEM_HEIGHT, LINE_ITEM_HEIGHT_2 } from '../../Constants/Constants';
import DKCustomFieldSection from "../../DKUILibrary/DKCustomFieldSection";
import DKEInvoiceSection, { eInvoiceSectionPosition } from "../../DKUILibrary/DKEInvoiceSection";
import DKSignatureSection from "../../DKUILibrary/DKSignatureSection";
import { AddressType } from "../../Helper/AddressParser";
import ComponentManager from "../../Manager/ComponentManager";
import DocumentManager from '../../Manager/DocumentManager';
import { getLocalisedText } from '../../Translate/LanguageManager';
import { HTMLTag } from "../../Utilities/HTMLTag";
import Utility, { getFontSizeClass, getIsFulfillment, getIsGoodsReceipt, getIsJournalEntry, getPageHeight, getVW } from '../../Utilities/Utility';
import TopSectionAboveTableUtility from "../../Utilities/TopSectionAboveTableUtility";
import DKComponentListTable from "../../DKUILibrary/DKComponentListTable";
import DKApprovalSection from "../../DKUILibrary/DKApprovalSection";

// import {StyleSheet} from "react";

export default class DocumentTemplate30 extends Component {
    arabicHeaderWidth = '82.5%'

    constructor(props) {
        super(props);
        this.state = {
            data: this.props.data,
        };
    }

    componentWillReceiveProps(nextProp) {
        this.setState({
            data: nextProp.data,
        });
    }

    getDocumentTitleLabel() {
        return (
            <div className="RowDiv"
                style={{
                    justifyContent: Utility.getIsArabicLang() ? 'flex-end' : 'center',
                    top: 0,
                    backgroundColor: this.state.data.themeColor
                }}>
                <text
                    style={{
                        fontWeight: "bold",
                        textAlign: "center",
                        color: "white",
                        paddingRight: Utility.getIsArabicLang() ? getVW(Utility.convertToPrintScale(10, this.state.data.printScale)) : 0,
                        ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.xLarge }, this.state.data.printScale),
                    }}
                >
                    {getLocalisedText(this.state.data.documentType)}
                </text>
            </div>
        )
    }

    getRowValueSection(title, value) {
        return (
            <div
                className="RowDiv"
                style={{
                    justifyContent: "space-evenly",
                    flex: 1,
                }}
            >
                <div
                    className="RowDiv"
                    style={{
                        flexDirection: 'row',
                        border: 'solid',
                        borderColor: 'lightgray',
                        borderWidth: getVW(1),
                        width: '50%'
                    }}>
                    <text className={"RowDiv docFontStyleBold" + getFontSizeClass()}
                        style={{
                            paddingLeft: getVW(Utility.convertToPrintScale(10, this.state.data.printScale)),
                            height: getVW(`${Utility.convertToPrintScale(Number(LINE_ITEM_HEIGHT_2.replace('vw', '')), this.state.data.printScale)}vw`, true),
                            justifyContent: Utility.getIsArabicLang() ? 'flex-end' : 'left',
                            paddingRight: Utility.getIsArabicLang() ? getVW(Utility.convertToPrintScale(10, this.state.data.printScale)) : 0,
                            ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular }, this.state.data.printScale),
                        }}>
                        {title.toUpperCase()}
                    </text>
                </div>
                <div
                    className="RowDiv"
                    style={{
                        flexDirection: 'row',
                        border: 'solid',
                        borderColor: 'lightgray',
                        borderWidth: getVW(1),
                        width: '50%'
                    }}>
                    <text className={"RowDiv docFontStyle" + getFontSizeClass()}
                        style={{
                            paddingLeft: getVW(Utility.convertToPrintScale(10, this.state.data.printScale)),
                            height: getVW(`${Utility.convertToPrintScale(Number(LINE_ITEM_HEIGHT_2.replace('vw', '')), this.state.data.printScale)}vw`, true),
                            justifyContent: 'center',
                            ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular }, this.state.data.printScale),
                        }}>
                        {value}
                    </text>
                </div>
            </div>
        );
    }

    getInvoiceDetailsView() {
        return (
            <div className="RowDiv" style={{ justifyContent: 'flex-start', width: '100%' }}>
                <div className="ColumnDiv" style={{ width: '100%', height: '100%' }}>
                    {this.getDocumentDetailsSection()}
                </div>
            </div>
        )
    }

    getDocumentDetailsSection() {
        var documentDetailList = Utility.getDocumentDetailsList(this.state.data)
        return documentDetailList.map(element => {
            var value = ''
            if (element.key !== undefined) {
                value = this.state.data[element.key]
            }
            else if (element.customFieldValue !== undefined) {
                value = element.customFieldValue
            }
            return this.getRowValueSection(
                element.label,
                value,
            )
        });
    }

    getCompanyNameLabel() {
        return (
            <div className="RowDiv"
                style={{
                    width: Utility.getIsArabicLang() ? this.arabicHeaderWidth : '100%',
                    flexDirection: Utility.getIsArabicLang() ? 'row-reverse' : 'row',
                }}>
                <text className={"docFontStyleBold" + getFontSizeClass()}
                    style={{
                        ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.xLarge }, this.state.data.printScale),
                    }}
                >
                    {getLocalisedText(Utility.getCompanyName(this.state.data))}
                </text>
            </div>
        )
    }

    getCompanyInformationView() {
        return (
            <div className="ColumnDiv"
                style={{
                    width: Utility.getIsArabicLang() ? this.arabicHeaderWidth : '100%',
                    flexDirection: Utility.getIsArabicLang() ? 'row-reverse' : 'row',
                }}>
                <div className={"docFontStyle" + getFontSizeClass()}
                    style={{
                        whiteSpace: 'pre-wrap',
                        ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.medium, ownerType: Utility.getOwnerType(this.state.data, AddressType.from) }, this.state.data.printScale),
                        }}
                    dangerouslySetInnerHTML={{__html: getLocalisedText(Utility.getCompanyAddress(this.state.data))}}>
                </div>
            </div>
        )
    }

    addVerticalSpace(height) {
        return (
            <div style={{ height: height }} />
        )
    }

    getAddressAndDocDetailView() {
        return (
            <div className="ColumnDiv" style={{ width: '50%' }}>

                <div className="RowDiv"
                    style={{
                        paddingLeft: Utility.getIsArabicLang() ? 0 : getVW(Utility.convertToPrintScale(10, this.state.data.printScale)),
                        backgroundColor: this.state.data.themeColor,
                        height: getVW(`${Utility.convertToPrintScale(Number(LINE_ITEM_HEIGHT.replace('vw', '')), this.state.data.printScale)}vw`, true),
                        width: '100%',
                        alignItems: 'center',
                        justifyContent: Utility.getIsArabicLang() ? 'flex-end' : 'flex-start',
                    }}>
                    <text className={"docFontStyleBold" + getFontSizeClass()}
                        style={{
                            color: 'white',
                            paddingRight: Utility.getIsArabicLang() ? getVW(Utility.convertToPrintScale(10, this.state.data.printScale)) : 0,
                            ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular }, this.state.data.printScale),
                        }}>
                        {getLocalisedText(this.state.data.billToTitle).toUpperCase()}
                    </text>
                </div>

                <div className={"RowDiv docFontStyle" + getFontSizeClass()}
                    style={{
                        display: 'block',
                        marginTop: getVW(Utility.convertToPrintScale(6, this.state.data.printScale)),
                        flexDirection: Utility.getIsArabicLang() ? 'row-reverse' : 'row',
                        ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular, ownerType: Utility.getOwnerType(this.state.data, AddressType.billTo) }, this.state.data.printScale),
                    }}
                    dangerouslySetInnerHTML={{__html: getLocalisedText(Utility.getClientBillToAddress(this.state.data))}}>

                </div>

            </div>
        )
    }

    addFooterView() {
        return (
            <div id={HTMLTag.TERMS_NOTE_PREFIX + HTMLTag.TERMS} style={{ bottom: getVW(5) }}>
                {this.state.data.termsAndCondition &&
                    ComponentManager.getTermsAndConditionOrNotesSection(
                        Utility.getTermsAndConditionTitle(getLocalisedText('terms_&_condition')),
                        this.state.data.termsAndCondition,
                        "100%",
                        this.state.data.themeColor,
                        undefined,
                        undefined,
                        getVW(Utility.convertToPrintScale(6, this.state.data.printScale)),
                        this.state.data.printScale,
                        Utility.getTnCOrNotesFontSettingsByType(EditorInfoType.termsAndCondition)
                    )}
            </div>
        )
    }

    addHeaderSection() {
        return (
            <div className="RowDiv" style={{ width: '100%', alignItems: 'flex-start' }}>
                <div className="ColumnDiv" style={{ width: '70%' }}>
                    {this.state.data.showCompanyName && this.getCompanyNameLabel()}
                    {this.state.data.showCompanyName && ComponentManager.addVerticalSpace(10)}
                    {this.state.data.showFrom && this.getCompanyInformationView()}
                </div>
                <div className="ColumnDiv" style={{ width: '40%' }}>
                    {this.getDocumentTitleLabel()}
                    {ComponentManager.addVerticalSpace(10)}
                    {this.getInvoiceDetailsView()}
                </div>

            </div>
        )
    }

    addBottomCommentSection() {
        return (
            <div className="ColumnDiv" style={{
                marginTop: getVW(0),
                // width: '57%',
                border: 'solid',
                borderColor: COLOR_LIGHTGRAY,
                borderWidth: getVW(Utility.convertToPrintScale(2, this.state.data.printScale)),
            }}>
                <div
                    className="RowDiv"
                    style=
                    {{
                        backgroundColor: this.state.data.themeColor,
                        height: getVW(`${Utility.convertToPrintScale(Number(LINE_ITEM_HEIGHT.replace('vw', '')), this.state.data.printScale)}vw`, true),
                        justifyContent: Utility.getIsArabicLang() ? 'flex-end' : 'flex-start',
                    }}>
                    <text className={"docFontStyleBold" + getFontSizeClass()}
                        style={{
                            paddingLeft: getVW(Utility.convertToPrintScale(10, this.state.data.printScale)),
                            color: 'white',
                            paddingRight: Utility.getIsArabicLang() ? getVW(Utility.convertToPrintScale(10, this.state.data.printScale)) : 0,
                            ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular, tncOrNoteFontSettings: Utility.getTnCOrNotesFontSettingsByType(EditorInfoType.notes), headerTitle: true }, this.state.data.printScale),
                        }}>
                        {Utility.getNotesTitle(getIsJournalEntry(this.state.data.type) ? getLocalisedText('memo').toUpperCase() : getLocalisedText('other_comments').toUpperCase())}
                    </text>
                </div>

                <div
                    className="RowDiv"
                    style=
                    {{
                        width: '100%',
                        paddingBottom: getVW(Utility.convertToPrintScale(20, this.state.data.printScale)),
                        paddingTop: getVW(Utility.convertToPrintScale(8, this.state.data.printScale)),
                        flexDirection: Utility.getIsArabicLang() ? 'row-reverse' : 'row'
                    }}>
                    <text className={"docFontStyle" + getFontSizeClass()}
                        style={{
                            paddingLeft: getVW(Utility.convertToPrintScale(10, this.state.data.printScale)),
                            paddingRight: Utility.getIsArabicLang() ? getVW(Utility.convertToPrintScale(10, this.state.data.printScale)) : 0,
                            ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular, tncOrNoteFontSettings: Utility.getTnCOrNotesFontSettingsByType(EditorInfoType.notes) }, this.state.data.printScale)
                        }}>
                        {getLocalisedText(this.state.data.notes)}
                    </text>
                </div>
            </div>
        )
    }

    render() {
        return (
            <div className='DocumentHolder ColumnDiv' style={{ justifyContent: 'space-between', minHeight: getPageHeight(this.state.data.printScale) }}>
                <div className='parent-width' style={{ width: '100%', paddingBottom: getVW(30)  }}>
                    {/* section 0 */}
                    <DKEInvoiceSection
                        data={this.state.data}
                        position={eInvoiceSectionPosition.top}
                    />
                    {this.addHeaderSection()}
                    {/* section 1 */}
                    {this.state.data.showClientBillToAddress && ComponentManager.addVerticalSpace(30)}
                    {/* section 2 */}
                    {this.state.data.showClientBillToAddress && this.getAddressAndDocDetailView()}
                    {(Utility.getIsShowSourceOfSupply(this.state.data) || Utility.getIsShowDestinationOfSupply(this.state.data)) && ComponentManager.addVerticalSpace(20)}
                    {(Utility.getIsShowSourceOfSupply(this.state.data) || Utility.getIsShowDestinationOfSupply(this.state.data)) && this.getSourceOfSupplyInfoSection()}
                    {/* section 3 */}
                    {Utility.getIsShowPlaceOfSupply(this.state.data) && ComponentManager.addVerticalSpace(20)}
                    {Utility.getIsShowPlaceOfSupply(this.state.data) && this.getPlaceOfSupplyInfoSection()}
                    {Utility.getIsContainsCustomField('top') && ComponentManager.addVerticalSpace(25)}
                    <DKCustomFieldSection data={this.state.data} onCustomFieldClick={this.props.onCustomFieldClick} customFieldPosition={'top'} />
                    {ComponentManager.addVerticalSpace(25)}
                    {
                        TopSectionAboveTableUtility.getIsTopSectionAboveTableIsVisible() &&
                        <>
                            <div style={{ ...TopSectionAboveTableUtility.getTopSectionWithTableFontStyleProp(undefined, this.state.data.printScale) }}
                                dangerouslySetInnerHTML={{ __html: TopSectionAboveTableUtility.getHtmlString(this.state.data) }} />
                            {ComponentManager.addVerticalSpace(5)}
                        </>
                    }
                    {this.getProductTable()}
                    {Utility.getIsShowAccountProductTable(this.state.data) && ComponentManager.addVerticalSpace(20)}
                    {this.getEditableDocumentTable()}
                    {Utility.getIsShowExchangeRate(this.state.data) && ComponentManager.addVerticalSpace(20)}
                    {Utility.getIsShowExchangeRate(this.state.data) && this.getExchangeRateTable(this.getTableStyle(), this.state.data)}
                    {Utility.getIsContainsCustomField('bottom') && ComponentManager.addVerticalSpace(25)}
                    <DKCustomFieldSection data={this.state.data} onCustomFieldClick={this.props.onCustomFieldClick} customFieldPosition={'bottom'} />
                    {Utility.getIsIncludesBomProductTable() && <DKComponentListTable data={this.state.data} />}
                    {this.getNotesAndSignatureSection()}
                    {Utility.getIsShowApprovalSection() && <DKApprovalSection data={this.state.data} style={this.getTableStyle()} />}
                    {/*{(this.state.data.showNotes && this.state.data.notes) && this.addBottomCommentSection()}*/}
                    {/*{this.state.data.showSignature && ComponentManager.getSignature(this.state.data.signature)}*/}
                </div>
                <div className='parent-width' style={{ width: '100%' }}>
                    {(getIsFulfillment(this.state.data.type) || getIsGoodsReceipt(this.state.data.type)) && <DKSignatureSection data={this.state.data} onSignatureSectionClicked={this.props.tableClicked} />}
                    {(this.state.data.showTermsAndCondition && this.state.data.termsAndCondition) && this.addFooterView()}
                </div>
                <DKEInvoiceSection
                    data={this.state.data}
                    position={eInvoiceSectionPosition.bottom}
                />
            </div>
        );
    }

    getEditableDocumentTable() {
        return ComponentManager.getDKDocumentTable(
            this.props.tableColumn,
            this.getTableStyle(),
            this.props.data,
            this.props.tableClicked,
            this.props.stockTableClicked,
            this.props.onUpdateDataCallback,
            this.props.paymentTableClicked,
            this.props.additionalTableCallback,
        )
    }

    getProductTable() {
        if(Utility.getIsShowAccountProductTable(this.state.data)) {
            return ComponentManager.getDKProductTable(
                Utility.getTableColumnForAccountProduct(),
                this.getTableStyle(),
                this.props.data,
                this.props.tableClicked,
                this.props.stockTableClicked,
                this.props.onUpdateDataCallback,
                this.props.paymentTableClicked,
                this.props.additionalTableCallback,
            )
        }
    }

    getNotesAndSignatureSection() {
        return <div className="RowDiv" style={{alignItems: 'flex-start', marginTop: getVW(Utility.convertToPrintScale(10, this.state.data.printScale))}}>
            <div style={{width: '100%'}}>
                {(this.state.data.showNotes && this.state.data.notes) && this.addBottomCommentSection()}
            </div>
            <div id={HTMLTag.SIGNATURE} className="RowDiv" style={{width: '67%'}}>
                {this.state.data.showSignature && ComponentManager.getSignature(this.state.data.signature, this.state.data.printScale)}
            </div>
        </div>;
    }

    getTableStyle() {
        let style = DocumentManager.getTableDefaultStyle();
        style.headerBackgroundColor = this.state.data.themeColor;
        style.headerTextColor = "#ffffff";
        style.headerBorderColor = this.state.data.themeColor;
        style.itemBorderColor = COLOR_LIGHTGRAY;
        style.footerBackgroundColor = this.state.data.themeColor
        style.footerTextColor = "white";
        style.footerBorderColor = "transparent";
        style.subTotalBorderColor = COLOR_LIGHTGRAY;
        return style;
    }

    getPlaceOfSupplyInfoSection() {
        return (
            <div
                className="RowDiv"
                style={{
                    alignItems: "flex-start",
                    justifyContent: 'space-between',
                    backgroundColor: "white",
                }}
            >
                {this.getPlaceOfSupply()}

            </div>
        )
    }
    getPlaceOfSupply() {
        // return <div className='RowDiv' style={{ width: '70%' }}>
        //     <div className='ColumnDiv' style={{
        //         width: '40%'
        //     }}>
        //         {Utility.getIsShowPlaceOfSupply(this.state.data) && ComponentManager.getDescriptionSection(
        //             getLocalisedText('place_of_supply'),
        //             this.state.data.placeOfSupply,
        //             "100%",
        //             this.state.data.themeColor,
        //             false,
        //             '#000000',
        //             getVW(6),
        //             true
        //         )}
        //     </div>

        // </div>;

        return (
            <div className="ColumnDiv" style={{ width: '50%' }}>

                <div className="RowDiv"
                    style={{
                        paddingLeft: Utility.getIsArabicLang() ? 0 : getVW(10),
                        backgroundColor: this.state.data.themeColor,
                        height: getVW(LINE_ITEM_HEIGHT, true),
                        width: '100%',
                        alignItems: 'center',
                        justifyContent: Utility.getIsArabicLang() ? 'flex-end' : 'flex-start',
                    }}>
                    <text className={"docFontStyleBold" + getFontSizeClass()}
                        style={{
                            color: 'white',
                            paddingRight: Utility.getIsArabicLang() ? getVW(10) : 0,
                            ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular }, this.state.data.printScale),
                        }}>
                        {getLocalisedText('place_of_supply').toUpperCase()}
                    </text>
                </div>

                <div className={"RowDiv docFontStyle" + getFontSizeClass()}
                    style={{
                        display: 'block',
                        marginTop: getVW(6),
                        flexDirection: Utility.getIsArabicLang() ? 'row-reverse' : 'row',
                        ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular, ownerType: true }, this.state.data.printScale),
                    }}
                    dangerouslySetInnerHTML={{__html: this.state.data.placeOfSupply}}>

                </div>

            </div>
        )

    }

    getSourceOfSupplyInfoSection() {
        return (
            <div
                className="RowDiv"
                style={{
                    alignItems: "flex-start",
                    justifyContent: 'space-between',
                    backgroundColor: "white",
                }}
            >
                {this.getSourceOfSupply()}

            </div>
        )
    }

     getSourceOfSupply() {
        return (
            <div className="RowDiv"  style={{ width: '100%' }}>
            <div className="ColumnDiv" style={{ width: '20%' ,marginRight:'20px'}}>
                {Utility.getIsShowSourceOfSupply(this.state.data) &&
                    <>
                        <div className="RowDiv"
                            style={{
                                paddingLeft: Utility.getIsArabicLang() ? 0 : getVW(10),
                                backgroundColor: this.state.data.themeColor,
                                height: getVW(LINE_ITEM_HEIGHT, true),
                                width: '100%',
                                alignItems: 'center',
                                justifyContent: Utility.getIsArabicLang() ? 'flex-end' : 'flex-start',
                            }}>
                            <text className={"docFontStyleBold" + getFontSizeClass()}
                                style={{
                                    color: 'white',
                                    paddingRight: Utility.getIsArabicLang() ? getVW(10) : 0,
                                    ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular }, this.state.data.printScale),
                                }}>
                                {getLocalisedText('source_of_supply').toUpperCase()}
                            </text>
                        </div>

                        <div className={"RowDiv docFontStyle" + getFontSizeClass()}
                            style={{
                                display: 'block',
                                marginTop: getVW(6),
                                flexDirection: Utility.getIsArabicLang() ? 'row-reverse' : 'row',
                                ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular, ownerType: true }, this.state.data.printScale),
                            }}
                            dangerouslySetInnerHTML={{ __html: this.state.data.sourceOfSupply }}>
                        </div>
                    </>
                }
            </div>
            <div className="ColumnDiv" style={{ width: '20%' }}>
                {Utility.getIsShowDestinationOfSupply(this.state.data) &&
                    <>
                        <div className="RowDiv"
                            style={{
                                paddingLeft: Utility.getIsArabicLang() ? 0 : getVW(10),
                                backgroundColor: this.state.data.themeColor,
                                height: getVW(LINE_ITEM_HEIGHT, true),
                                width: '100%',
                                alignItems: 'center',
                                justifyContent: Utility.getIsArabicLang() ? 'flex-end' : 'flex-start',
                            }}>
                            <text className={"docFontStyleBold" + getFontSizeClass()}
                                style={{
                                    color: 'white',
                                    paddingRight: Utility.getIsArabicLang() ? getVW(10) : 0,
                                    ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular }, this.state.data.printScale),
                                }}>
                                {getLocalisedText('destination_of_supply').toUpperCase()}
                            </text>
                        </div>

                        <div className={"RowDiv docFontStyle" + getFontSizeClass()}
                            style={{
                                display: 'block',
                                marginTop: getVW(6),
                                flexDirection: Utility.getIsArabicLang() ? 'row-reverse' : 'row',
                                ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular, ownerType: true }, this.state.data.printScale),
                            }}
                            dangerouslySetInnerHTML={{ __html: this.state.data.destinationOfSupply }}>
                        </div>
                    </>
                    }

                </div>
            </div>
        )
    }

    getExchangeRateTable(tableStyle, data) {
        return (
            <div id={HTMLTag.EXCHANGE_RATE} className="RowDiv" style={{ justifyContent: "flex-end" }}>
                <div style={{ width: "45%" }}>
                    {ComponentManager.getExchangeRateTable(tableStyle, data)}
                </div>
            </div>
        );
    }
}
