import {
  DKLabel,
  showAlert,
  DKInput,
  INPUT_TYPE,
  DKIcons,
  INPUT_VIEW_DIRECTION,
  DKDataGrid,
  DKCheckMark,
  DKIcon,
  Toggle,
  DKCalendar,
  DKRadioButton,
  DKButton
} from 'deskera-ui-library';
import {
  BOOKS_DATE_FORMAT,
  COMMON_CONSTANT,
  COUNTRY_CODES,
  DATE_FORMAT,
  DECIMAL_SCALE,
  DOC_TYPE,
  FORM_ELEMENTS,
  MODULES_NAME,
  POPUP_CALLBACKS_TYPE,
  STATUS_TYPE,
  PAYMENT_TYPES,
  REQUEST_PRINT_STATUS
} from '../../Constants/Constant';
import { ReactSelectOptionsType } from '../../Models/Interfaces';
import Calendar from '../../SharedComponents/Calender/Calendar';
import { useTranslation } from 'react-i18next';
import ic_calender from '../../Assets/Icons/ic_calendar.png';
import CurrencyDropdown from '../CurrencyDropdown/CurrencyDropdown';
import { useEffect, useState } from 'react';
import {
  PaymentPayload,
  BillPaymentPopupProps,
  Information,
  paymentInitialState,
  PaymentItemDto,
  PaymentPopupState,
  PAYMENT_CALENDAR_TYPE,
  PAYMENT_DROPDOWN_TYPE,
  PAYMENT_INPUT_TYPE,
  NoteItem,
  PAYMENT_SECTION,
  NoteItemPayload
} from '../../Models/PaymentPopup';
import Utility, {
  convertBooksDateFormatToUILibraryFormat,
  deepClone,
  getCapitalized
} from '../../Utility/Utility';
import Select from 'react-select';
import { KeyAndValue } from '../../Models/NewContact';
import { PAYMENT_TYPE } from '../../Constants/StaticData';
import CustomInput from '../CustomInput/CustomInput';
import { makePaymentMethod } from '../../Models/PaymentMethod';
import { useAppDispatch, useAppSelector } from '../../Redux/Hooks';
import {
  activeTenantInfo,
  currentTenantInfo
} from '../../Redux/Slices/AuthSlice';
import {
  fetchAccountGroupExpenses,
  selectAccountGroupExpenses,
  selectCurrencyListWithExchangeRate,
  setIsLoadingPopupWrapper
} from '../../Redux/Slices/CommonDataSlice';
import PaymentService from '../../Services/Payment';
import {
  fetchBills,
  selectBillsColumnConfig,
  selectBillsColumnConfigTableId
} from '../../Redux/Slices/BillsSlice';
import {
  fetchInvoices,
  selectInvoicesColumnConfig,
  selectInvoicesColumnConfigTableId
} from '../../Redux/Slices/InvoicesSlice';
import AccountsService, { AccountAPIConfig } from '../../Services/Accounts';
import ExtraPaymentItem from './ExtraPaymentItem';
import InvoiceService, { InvoiceAPIConfig } from '../../Services/Invoice';
import FormatAmount from '../FormatAmount';
import DateFormatService from '../../Services/DateFormat';
import NumberFormatService from '../../Services/NumberFormat';
import { getFormattedAddressObj } from '../DocumentForm/NewDocumentHelper';
import {
  selectedAccountGroups,
  selectedAccounts
} from '../../Redux/Slices/AccountsSlice';
import AuthService from '../../Services/Auth';
import {
  getAgedReceivable,
  getCashFlow,
  getSellDashboard,
  selectAgedFilterConfig,
  selectCashflowConfig,
  selectSellFilterConfig
} from '../../Redux/Slices/DashboardSlice';
import { fetchBanksList } from '../../Redux/Slices/Banks';
import DashboardService from '../../Services/Dashboard';
import ApiConstants from '../../Constants/ApiConstants';
import { localizedText } from '../../Services/Localization/Localization';
import { CustomFieldsHolder } from '../CustomFieldsHolder/CustomFieldsHolder';
import ContactService from '../../Services/Contact';
import { DATE_COMPARISON } from '../../Constants/Enum';
import { IWHTaxGroup } from '../../Models/WHTax';
import {
  fetchWHTaxGroup,
  selectWithHoldTaxGroup
} from '../../Redux/Slices/WHTSlice';
import { GranularPermissionsHelper } from '../../Components/Settings/GranularPermissions/GranularPermissionsHelper';
import { PERMISSIONS_BY_MODULE } from '../../Constants/Permission';

type CurrencyConversionResults = {
  amount: number;
  exchangeRate: number;
  baseExchangeRate: number;
};
const Payment: React.FC<BillPaymentPopupProps> = (props) => {
  const { t, i18n } = useTranslation();
  const [paymentPopupState, setPaymentPopupState] = useState<PaymentPopupState>(
    deepClone(paymentInitialState)
  );
  const [documentDetails, setDocumentDetails] = useState(props.documentDetails);
  const [paymentType, setPaymentType] = useState<KeyAndValue[]>(PAYMENT_TYPE);
  const accountGroups = useAppSelector(selectedAccountGroups);
  const [gridObject, setGridObject] = useState<any>({});
  const exchangeRateList = useAppSelector(selectCurrencyListWithExchangeRate);
  const tenantDetails = useAppSelector(activeTenantInfo);
  const feeAccounts: any = useAppSelector(selectAccountGroupExpenses);
  const accountList = useAppSelector(selectedAccounts);
  const columnConfigForInvoice = useAppSelector(selectInvoicesColumnConfig);
  const columnConfigForBills = useAppSelector(selectBillsColumnConfig);
  const billsConfigTableId = useAppSelector(selectBillsColumnConfigTableId);
  const invoicesConfigTableId = useAppSelector(
    selectInvoicesColumnConfigTableId
  );
  const tenantDecimalScale: number = tenantDetails.decimalScale;
  let showMulticurrencyStepper: boolean =
    documentDetails.currency !== tenantDetails.currency;
  let baseCurrencyExchangeRate: number = 1;
  const dispatch = useAppDispatch();
  const [showBankTransfer, setShowBankTransfer] = useState<boolean>(true);
  const [showChequeNumber, setShowChequeNumber] = useState<boolean>(false);
  const [contactDetails, setContactDetails] = useState<any>(null);
  const whTaxesGroups = useAppSelector<IWHTaxGroup[]>(selectWithHoldTaxGroup);
  const [showAdvancedPayment, setShowAdvancedPayment] =
    useState<boolean>(false);
  const [showPrePayment, setShowPrePayment] = useState<boolean>(false);
  const [showCreditNote, setShowCreditNote] = useState<boolean>(false);
  const [showDebitNote, setShowDebitNote] = useState<boolean>(false);
  const [showPaymentDetails, setShowPaymentDetails] = useState<boolean>(true);
  const [paymentDateOpen, setPaymentDateOpen] = useState<boolean>(false);
  const [paymentDate, setPaymentDate] = useState<Date>(new Date());
  const [accounts, setAccounts] = useState<any[]>([]);
  const [transactionFee, setShowTransactinoFee] = useState<boolean>(false);
  const [saveButtonTapped, setSaveButtonTapped] = useState(false);
  const [customField, setCustomField] = useState<any>([]);
  const [erroMessage, setErrorMessage] = useState<any>(null);
  const SellDashboardConfig = useAppSelector(selectSellFilterConfig);
  const AgedReceivableDashboardConfig = useAppSelector(selectAgedFilterConfig);
  const getCashFlowDashboardConfig = useAppSelector(selectCashflowConfig);
  let module = MODULES_NAME.BILL;
  let columnConfig = columnConfigForBills;
  let columnConfigTableId = billsConfigTableId;
  if (props.documentType === DOC_TYPE.INVOICE) {
    module = MODULES_NAME.INVOICE;
    columnConfig = columnConfigForInvoice;
    columnConfigTableId = invoicesConfigTableId;
  }

  const localConfig = [
    {
      name: 'Code',
      index: 0,
      options: null,
      required: false,
      editable: false,
      hidden: false,
      uiVisible: true,
      systemField: true,
      columnCode: 'code',
      id: 'code',
      datasource: [],
      key: 'code',
      lineItem: false,
      type: INPUT_TYPE.TEXT,
      width: 130
    },
    {
      name: 'Date Created',
      index: 0,
      options: null,
      required: false,
      editable: false,
      hidden: false,
      uiVisible: true,
      systemField: true,
      columnCode: 'createdDate',
      id: 'createdDate',
      datasource: [],
      key: 'createdDate',
      lineItem: false,
      type: INPUT_TYPE.TEXT,
      width: 150
    },
    {
      name: 'Available',
      index: 0,
      options: null,
      required: false,
      editable: false,
      hidden: false,
      uiVisible: true,
      systemField: true,
      columnCode: 'availableAmount',
      id: 'availableAmount',
      datasource: [],
      key: 'availableAmount',
      lineItem: false,
      type: INPUT_TYPE.TEXT,
      width: 150,
      textAlign: 'right'
    },
    {
      name: 'Memo',
      type: INPUT_TYPE.TEXT,
      index: 0,
      options: null,
      required: false,
      width: 150,
      editable: false,
      hidden: false,
      uiVisible: true,
      systemField: true,
      columnCode: 'memo',
      id: 'memo',
      datasource: [],
      key: 'memo',
      lineItem: false
    },
    {
      name: 'Amount',
      type: INPUT_TYPE.NUMBER,
      index: 0,
      options: null,
      required: false,
      width: 170,
      editable: true,
      hidden: false,
      uiVisible: true,
      systemField: true,
      columnCode: 'amount',
      id: 'amount',
      datasource: [],
      key: 'amount',
      lineItem: false,
      textAlign: 'right',
      formatter: (obj: any) => {
        let value = 0;
        if (obj && obj.value) {
          value = obj.value;
        }
        return `${Utility.getCurrencySymbolFromCode(
          paymentPopupState.paymentInfo.selectedCurrency.currencyCode
        )} ${Utility.roundOffToTenantDecimalScale(Number(value))}`;
      }
    }
  ];

  const getFormattedAddress = (address: any) => {
    const { contactName, line1, line2, cityAndState, countryAndPostalCode } =
      getFormattedAddressObj(address);

    let formattedAddress = '';
    if (!Utility.isEmpty(contactName)) {
      formattedAddress += contactName + ', ';
    }
    if (!Utility.isEmpty(line1)) {
      formattedAddress += line1 + ',<br>';
    }
    if (!Utility.isEmpty(line2)) {
      formattedAddress += line2 + ',<br>';
    }
    if (!Utility.isEmpty(cityAndState)) {
      formattedAddress += cityAndState + ',<br>';
    }
    if (!Utility.isEmpty(countryAndPostalCode)) {
      formattedAddress += countryAndPostalCode;
    }

    return formattedAddress;
  };

  const setPaymentList = () => {
    fetchAccountGroup(documentDetails.currency);
  };

  useEffect(() => {
    dateSelected(PAYMENT_CALENDAR_TYPE.PAYMENT_DATE, paymentDate);
  }, [paymentDate]);

  useEffect(() => {
    if (showPrePayment || showAdvancedPayment) {
      setShowPaymentDetails(false);
      setShowDebitNote(false);
      setShowCreditNote(false);
    }
  }, [showPrePayment, showAdvancedPayment]);

  useEffect(() => {
    if (showCreditNote || showDebitNote) {
      setShowPaymentDetails(false);
      setShowPrePayment(false);
      setShowAdvancedPayment(false);
    }
  }, [showCreditNote, showDebitNote]);

  useEffect(() => {
    if (showPaymentDetails) {
      setShowPrePayment(false);
      setShowAdvancedPayment(false);
      setShowDebitNote(false);
      setShowCreditNote(false);
    }
  }, [showPaymentDetails]);

  useEffect(() => {
    if (accountList && accountList.content && accountList.content.length > 0) {
      let activeAccountList = accountList.content.filter(
        (account: any) => account.status === STATUS_TYPE.ACTIVE
      );
      let result = activeAccountList.map((account: any) => {
        {
          return {
            label: account.name,
            value: account.code
          };
        }
      });
      const newState = paymentPopupState;
      let _selectedAccount = activeAccountList.find(
        (account: any) => account.name === 'TDS Payable'
      );

      if (!Utility.isEmpty(_selectedAccount)) {
        newState.tdsDetails.tdsAccountCode.value = {
          label: _selectedAccount.name,
          value: _selectedAccount.code
        };
      }
      setPaymentPopupState({ ...newState });
      setAccounts(result);
    }
  }, [accountList]);

  useEffect(() => {
    /* API Calls */

    setPaymentList();
    dispatch(fetchAccountGroupExpenses());
    dispatch(fetchWHTaxGroup());
    if (tenantDetails.country === COUNTRY_CODES.IL) {
      fetchContactInfo();
    }
    if (props.documentType === DOC_TYPE.BILL) {
      fetchAdditionalPaymentInfo(
        documentDetails.currency,
        documentDetails.contactCode,
        PAYMENT_SECTION.DEBIT_NOTE
      );
      fetchAdditionalPaymentInfo(
        documentDetails.currency,
        documentDetails.contactCode,
        PAYMENT_SECTION.PRE_PAYMENT
      );
    } else if (props.documentType === DOC_TYPE.INVOICE) {
      fetchAdditionalPaymentInfo(
        documentDetails.currency,
        documentDetails.contactCode,
        PAYMENT_SECTION.CREDIT_NOTE
      );
      fetchAdditionalPaymentInfo(
        documentDetails.currency,
        documentDetails.contactCode,
        PAYMENT_SECTION.ADVANCED_PAYMENT
      );
    }

    const newState = { ...paymentPopupState };
    let paymentTypeOptions: ReactSelectOptionsType<string, string>[] = [];
    paymentType.forEach((payment) => {
      let option: ReactSelectOptionsType<string, string> = {
        label: (Utility.isUSorg() &&
        payment.value === COMMON_CONSTANT.CHEQUE_VALUE
          ? COMMON_CONSTANT.CHEQUE_VALUE_US
          : payment.value) as string,
        value: payment.key as string
      };
      paymentTypeOptions.push(option);
    });

    if (documentDetails.contact && documentDetails.contact.name) {
      newState.paymentInfo.contactName = documentDetails.contact.name;
    } else if (props.auditLogView && documentDetails.contactName) {
      newState.paymentInfo.contactName = documentDetails.contactName;
    }

    if (documentDetails.dueAmount && documentDetails.currency) {
      newState.paymentInfo.outstandingAmount = documentDetails.dueAmount;
      newState.paymentDetails.paymentAmount = {
        ...newState.paymentDetails.paymentAmount,
        defaultValue: documentDetails.dueAmount,
        value: documentDetails.dueAmount
      };
      newState.summaryDetails.actualAmountToBePaid = documentDetails.dueAmount;
    }
    if (props.auditLogView) {
      newState.paymentInfo.outstandingAmount = '0';
      newState.paymentDetails.paymentAmount = {
        ...newState.paymentDetails.paymentAmount,
        value: documentDetails.amount
      };
    }

    if (documentDetails.currency) {
      newState.paymentInfo.selectedCurrency.currencyCode =
        documentDetails.currency;
    }

    newState.paymentInfo.paymentDate.date =
      DateFormatService.getDateStrFromDate(
        new Date(),
        BOOKS_DATE_FORMAT['YYYY-MM-DD']
      );

    newState.paymentDetails.additionalPaymentDetails.referenceDate.date =
      DateFormatService.getDateStrFromDate(
        new Date(),
        BOOKS_DATE_FORMAT['YYYY-MM-DD']
      );

    newState.paymentDetails.paymentType = {
      ...newState.paymentDetails.paymentType,
      options: paymentTypeOptions
    };
    newState.summaryDetails.exchangeRate = documentDetails.exchangeRate;
    setPaymentPopupState({ ...newState });
    return () => {
      // null
    };
  }, []);

  const registerInteractions = () => {
    /*
     * register parents calls to child methods
     */

    if (props.passingInteraction) {
      props.passingInteraction({
        type: POPUP_CALLBACKS_TYPE.BILL_PAYMENT,
        data: () => {
          makePayment();
        }
      });

      props.passingInteraction({
        type: POPUP_CALLBACKS_TYPE.RECEIVE_PAYMENT,
        data: () => {
          receivePayment();
        }
      });
    }
  };

  useEffect(() => {
    let updatedGridData: any = { ...gridObject };
    switch (props.documentType) {
      case DOC_TYPE.INVOICE:
        if (PAYMENT_SECTION.ADVANCED_PAYMENT) {
          if (
            paymentPopupState.advancedPayment &&
            paymentPopupState.advancedPayment.advancedPaymentItem &&
            paymentPopupState.advancedPayment.advancedPaymentItem.length > 0
          ) {
            let data: any = [];
            paymentPopupState.advancedPayment.advancedPaymentItem.forEach(
              (item: any) => {
                let obj: any = {};
                obj['code'] = item['code'];
                obj['createdDate'] = DateFormatService.getFormattedDateString(
                  item['dateCreated'],
                  BOOKS_DATE_FORMAT['YYYY-MM-DD']
                );
                obj['availableAmount'] = Utility.getAmoutBlockForGrid(
                  paymentPopupState.paymentInfo.selectedCurrency.currencyCode,
                  item['dueAmount']
                );
                obj['dueAmount'] = item['dueAmount'];
                obj['memo'] = item['memo'];
                obj['amount'] = item['amount'] ? item['amount'] : 0;
                data.push(obj);
              }
            );
            updatedGridData[PAYMENT_SECTION.ADVANCED_PAYMENT] = data;
          }
        }
        if (PAYMENT_SECTION.CREDIT_NOTE) {
          if (
            paymentPopupState.creditNote &&
            paymentPopupState.creditNote.creditItem &&
            paymentPopupState.creditNote.creditItem.length > 0
          ) {
            let data: any = [];
            paymentPopupState.creditNote.creditItem.forEach((item: any) => {
              let obj: any = {};
              obj['code'] = item['documentSequenceCode'];
              obj['createdDate'] = item['dateCreated'];
              obj['availableAmount'] = Utility.getAmoutBlockForGrid(
                paymentPopupState.paymentInfo.selectedCurrency.currencyCode,
                item['dueAmount']
              );
              obj['dueAmount'] = item['dueAmount'];
              obj['memo'] = item['memo'];
              obj['amount'] = item['amount'] ? item['amount'] : 0;
              data.push(obj);
            });
            updatedGridData[PAYMENT_SECTION.CREDIT_NOTE] = data;
          }
        }
        setGridObject(updatedGridData);
        break;
      case DOC_TYPE.BILL:
        if (PAYMENT_SECTION.PRE_PAYMENT) {
          if (
            paymentPopupState.prePayment &&
            paymentPopupState.prePayment.prePaymentItem &&
            paymentPopupState.prePayment.prePaymentItem.length > 0
          ) {
            let data: any = [];
            paymentPopupState.prePayment.prePaymentItem.forEach((item: any) => {
              let obj: any = {};
              obj['code'] = item['code'];
              obj['createdDate'] = DateFormatService.getFormattedDateString(
                item['dateCreated'],
                BOOKS_DATE_FORMAT['YYYY-MM-DD']
              );
              obj['availableAmount'] = Utility.getAmoutBlockForGrid(
                paymentPopupState.paymentInfo.selectedCurrency.currencyCode,
                item['dueAmount']
              );
              obj['dueAmount'] = item['dueAmount'];
              obj['memo'] = item['memo'];
              obj['amount'] = item['amount'] ? item['amount'] : 0;
              data.push(obj);
            });
            updatedGridData[PAYMENT_SECTION.PRE_PAYMENT] = data;
          }
        }
        if (PAYMENT_SECTION.DEBIT_NOTE) {
          if (
            paymentPopupState.debitNote &&
            paymentPopupState.debitNote.debitItem &&
            paymentPopupState.debitNote.debitItem.length > 0
          ) {
            let data: any = [];
            paymentPopupState.debitNote.debitItem.forEach((item: any) => {
              let obj: any = {};
              obj['code'] = item['documentSequenceCode'];
              obj['createdDate'] = item['dateCreated'];
              obj['availableAmount'] = Utility.getAmoutBlockForGrid(
                paymentPopupState.paymentInfo.selectedCurrency.currencyCode,
                item['dueAmount']
              );
              obj['dueAmount'] = item['dueAmount'];
              obj['memo'] = item['memo'];
              obj['amount'] = item['amount'] ? item['amount'] : 0;
              data.push(obj);
            });
            updatedGridData[PAYMENT_SECTION.DEBIT_NOTE] = data;
          }
        }
        setGridObject(updatedGridData);
        break;

      default:
        break;
    }
  }, [paymentPopupState]);

  useEffect(() => {
    if (paymentPopupState.isWHTApplicable) {
      calculateWHTax();
    }
  }, [
    paymentPopupState.paymentInfo.outstandingAmount,
    paymentPopupState.isWHTApplicable,
    paymentPopupState.paymentInfo.paymentDate.date
  ]);
  const fetchAdditionalPaymentInfo = (
    currencyCode: string,
    contactCode: string,
    paymentSection: PAYMENT_SECTION
  ) => {
    const newState = paymentPopupState;
    PaymentService.getAdditionalPaymentInfo(
      contactCode,
      currencyCode,
      paymentSection
    ).then(
      (res: any) => {
        const content: any[] = res.content;
        let paymentItemList: any[] = [];
        if (content.length > 0) {
          content.forEach((item) => {
            const paymentItem: NoteItem = {
              id: item.id,
              code: item.documentSequenceCode
                ? item.documentSequenceCode
                : item.code,
              documentSequenceCode: item.documentSequenceCode,
              available: `${Utility.getCurrencySymbolFromCode(
                item.currency
              )} ${NumberFormatService.getNumber(item.amountDue)}`,
              memo: item.memo,
              amount: 0,
              dueAmount: Number(item.amountDue),
              isSelected: false,
              currencyCode: item.currency,
              exchangeRate:
                paymentSection === PAYMENT_SECTION.CREDIT_NOTE ||
                paymentSection === PAYMENT_SECTION.DEBIT_NOTE
                  ? 1 / item.currencyExchangeRate
                  : item.exchangeRate
            };

            switch (paymentSection) {
              case PAYMENT_SECTION.ADVANCED_PAYMENT:
                paymentItem.dateCreated = item.documentDate;
                break;
              case PAYMENT_SECTION.PRE_PAYMENT:
                paymentItem.dateCreated = item.documentDate;
                break;
              case PAYMENT_SECTION.CREDIT_NOTE:
                paymentItem.dateCreated = item.cnDate;
                paymentItem.code = item.code
                  ? item.code
                  : item.documentSequenceCode;
                break;
              case PAYMENT_SECTION.DEBIT_NOTE:
                paymentItem.dateCreated = item.dnDate;
                paymentItem.code = item.code
                  ? item.code
                  : item.documentSequenceCode;
                break;
            }

            if (paymentItem.dueAmount > 0) {
              paymentItemList.push(paymentItem);
            }
          });
        }
        switch (paymentSection) {
          case PAYMENT_SECTION.ADVANCED_PAYMENT:
            if (paymentItemList.length > 0) {
              newState.advancedPayment.advancedPaymentItem = paymentItemList;
            } else {
              newState.advancedPayment = {
                advancedPaymentItem: null,
                isActive: false
              };
            }
            break;
          case PAYMENT_SECTION.PRE_PAYMENT:
            if (paymentItemList.length > 0) {
              newState.prePayment.prePaymentItem = paymentItemList;
            } else {
              newState.prePayment = { prePaymentItem: null, isActive: false };
            }
            break;
          case PAYMENT_SECTION.CREDIT_NOTE:
            if (paymentItemList.length > 0) {
              newState.creditNote.creditItem = paymentItemList;
            } else {
              newState.creditNote = { creditItem: null, isActive: false };
            }
            break;
          case PAYMENT_SECTION.DEBIT_NOTE:
            if (paymentItemList.length > 0) {
              newState.debitNote.debitItem = paymentItemList;
            } else {
              newState.debitNote = { debitItem: null, isActive: false };
            }
            break;
          default:
            break;
        }
        newState.summaryDetails.additionalPaymentAmount = 0;
        newState.paymentDetails.paymentAmount.isDisabled = false;
        setPaymentPopupState({ ...newState });
      },
      (err) => {
        console.error('Error fetching additional payment info: ', err);
      }
    );
  };

  const fetchAccountGroup = (currencyCode: string) => {
    const newState = paymentPopupState;
    newState.paymentDetails.payFrom.value = { label: '', value: '' };
    newState.paymentDetails.payFrom.options = [];
    AccountsService.fetchAccountGroupByRecentUsed().then(
      (res: any) => {
        let accountList: any[] = res?.content ?? [];
        let paymentMethodOption: any[] = [];
        if (accountList.length > 0) {
          // if additional Settings from tenant for show Card / Cash / Bank Accounts
          let filterAccounts =
            tenantDetails?.additionalSettings?.ACCOUNT
              ?.showCardCashBankAccountsOnly;

          let paymentArray = accountList;
          const paymentMethod = paymentArray.map((paymentMethod) =>
            makePaymentMethod(paymentMethod)
          );

          if (paymentMethod.length > 0) {
            paymentMethodOption = paymentMethod;
          }

          let paymentOptions: ReactSelectOptionsType<string, string>[] = [];
          paymentMethodOption.forEach((payOption) => {
            let option: any = {
              label: `${payOption.name}(${Utility.convertInTitleCase(
                payOption.accountGroup
              )})`,
              value: payOption.code,
              isUndepositedFundAccount: payOption.isUndepositedFundAccount
                ? true
                : false
            };
            let found = false;
            paymentOptions.forEach((options) => {
              if (options.label === option.label) {
                found = true;
              }
            });
            if (!found) {
              paymentOptions.push(option);
            }
          });
          newState.paymentDetails.payFrom.options = paymentOptions;
          newState.paymentDetails.payFrom.value =
            
              newState.paymentDetails.payFrom.options[0] || {
                label: '',
                value: ''
              };
          let index1 = newState.paymentDetails.payFrom.value.label.indexOf('(');
          let index2 = newState.paymentDetails.payFrom.value.label.indexOf(')');
          let obj2 =
            newState.paymentDetails.payFrom.value.label.substring(
              index1 + 1,
              index2
            ) ||
            { accountGroup: '', ...newState?.paymentDetails?.payFrom?.value }
              ?.accountGroup;
          newState.paymentDetails.paymentAccountGroup = obj2;

          setPaymentPopupState({ ...newState });
        } else {
          newState.paymentDetails.payFrom.options = [];
          newState.paymentDetails.payFrom.value = { label: '', value: '' };
          newState.paymentDetails.paymentAccountGroup = '';
          setPaymentPopupState({ ...newState });
        }
      },
      (err) => {
        console.error('Error fetching account group: ', err);
      }
    );
  };

  const fetchContactInfo = () => {
    ContactService.getContactDetailsById(
      props.documentDetails?.contactDto?.id
    ).then((res) => {
      if (res) {
        setContactDetails(res);
      }
    });
  };
  useEffect(() => {
    registerInteractions();
  });

  const getCalendarView = (
    selectedDate: any,
    onSelect: any,
    toggleView: any
  ) => {
    return (
      <DKCalendar
        className="position-absolute bg-white border-m z-index-3 p-s border-radius-s shadow-m border-box"
        style={{ right: 20, top: 20, zIndex: 700 }}
        selectedDate={selectedDate}
        onSelectDate={(newDate: Date) => {
          onSelect(newDate);
          toggleView(false);
        }}
        onClose={() => setTimeout(() => toggleView(false))}
      />
    );
  };

  const getIsraelTaxRow = () => {
    return (
      <>
        {' '}
        {
          <>
          <div style={{marginTop: "40px"}}>
            <div
              style={{
                width: 180,
                maxWidth: 200,
                wordBreak: 'break-word'
              }}
            >
              <DKInput
                className="parent-width"
                title="Tax Payer Id"
                value={contactDetails?.taxPayerIdIsrael || ''}
                titleStyle={{ color: 'gray' }}
                valueStyle={{ minHeight: 33 }}
                textAlign="left"
                type={INPUT_TYPE.TEXT}
                onChange={(value: any) => {}}
                canValidate={false}
                direction={INPUT_VIEW_DIRECTION.VERTICAL}
                required={false}
                readOnly={true}
              />
            </div>
            <div
              style={{
                width: 180,
                maxWidth: 200,
                wordBreak: 'break-word'
              }}
            >
              <DKInput
                className="parent-width"
                title="Tax Registration Number"
                value={contactDetails?.taxNumber || ''}
                titleStyle={{ color: 'gray' }}
                valueStyle={{ minHeight: 33 }}
                textAlign="left"
                type={INPUT_TYPE.TEXT}
                onChange={(value: any) => {}}
                canValidate={false}
                direction={INPUT_VIEW_DIRECTION.VERTICAL}
                required={false}
                readOnly={true}
              />
            </div>
            </div>
          </>
        }
      </>
    );
  };
  const getBasicDetails = () => {
    let { outstandingAmount, withHoldTaxDetails } =
      paymentPopupState?.paymentInfo;
    let withHoldTaxAmount = withHoldTaxDetails?.amount || 0;
    if (!paymentPopupState?.isWHTApplicable) {
      withHoldTaxAmount = 0;
    }
    const finalOutStandingAmount =
      Number(outstandingAmount) - withHoldTaxAmount;
    return (
      <div className="row row-responsive align-items-start justify-content-between">
        {/* Contact details */}
        <div className="column mt-s">
          <div className="row width-auto">
            <DKIcon src={DKIcons.ic_user} />
            <DKLabel text="Contact details" className="ml-r" />
          </div>
          <DKLabel
            text={paymentPopupState.paymentInfo.contactName}
            className="mt-s fw-b fs-m ml-xxl"
          />
          <DKLabel
            text={
              !Utility.isEmpty(props.documentDetails.billTo)
                ? getFormattedAddress(props.documentDetails.billTo)
                : ''
            }
            className="ml-xxl text-gray mt-s"
          />
          {tenantDetails.country === COUNTRY_CODES.IL && getIsraelTaxRow()}
        </div>
        {/* Payment & date */}
        <div className="column mobile-align-items-start mobile-mt-m parent-width">
          {!props.auditLogView && (
            <>
              <DKLabel text="Outstanding Amount" className="" />
              <DKLabel
                text={getFormatAmount(finalOutStandingAmount, true)}
                className="fw-b fs-xxl mt-s text-blue"
              />
            </>
          )}
          {withHoldTaxAmount ? (
            <DKLabel
              text={`Withhold tax amount ${getFormatAmount(
                withHoldTaxAmount,
                true
              )}`}
              className="fs-s mt-s text-gray"
            />
          ) : null}
          <div className="column mt-l pay-web-width-270">
            <div
              className="row mb-l justify-content-between cursor-pointer"
              style={{}}
              onClick={() => {
                setPaymentDateOpen(!paymentDateOpen);
              }}
            >
              <div className="row width-auto">
                <DKIcon
                  src={DKIcons.data_type.ic_date}
                  className="ic-s"
                  style={{ opacity: 0.6 }}
                />
                <DKLabel text={'Payment Date'} className={'fw-m ml-r'} />
              </div>
              {paymentPopupState.paymentInfo.paymentDate.date && (
                <DKLabel
                  text={DateFormatService.getFormattedDateString(
                    paymentPopupState.paymentInfo.paymentDate.date,
                    BOOKS_DATE_FORMAT['YYYY-MM-DD']
                  )}
                  className={'ml-r '}
                />
              )}
            </div>
            {paymentDateOpen &&
              getCalendarView(
                DateFormatService.getDateFromStr(
                  paymentPopupState.paymentInfo.paymentDate.date,
                  BOOKS_DATE_FORMAT['YYYY-MM-DD']
                ),
                setPaymentDate,
                setPaymentDateOpen
              )}

            {paymentPopupState.paymentInfo.selectedCurrency.currencyCode
              .length > 0 && (
              <div className="mt-2 row-reverse">
                <div className="row mb-l justify-content-between" style={{}}>
                  <div className="row width-auto">
                    <DKIcon
                      src={DKIcons.ic_currency}
                      className="ic-s"
                      style={{ opacity: 0.6 }}
                    />
                    <DKLabel text={'Currency'} className={'fw-m ml-r'} />
                  </div>
                  <div className="">
                    <CurrencyDropdown
                      currencyCode={
                        paymentPopupState.paymentInfo.selectedCurrency
                          .currencyCode
                      }
                      width="150px"
                      getInfo={grabInfo}
                    />
                  </div>
                </div>
              </div>
            )}
            {/*
            <DKInput
              className="mt-r"
              title="Currency"
              placeholder=""
              required={false}
              value={
                paymentPopupState.paymentInfo.selectedCurrency.currencyCode
              }
              formatter={(obj: any) => {
                return obj;
              }}
              type={INPUT_TYPE.DROPDOWN}
              icon={DKIcons.ic_getPaid}
              onChange={(data: any) => {}}
              dropdownConfig={{
                className: '',
                style: {},
                allowSearch: true,
                searchableKey: 'currency',
                data: [
                ],
                renderer: (index: any, obj: any) => {
                  return (
                    <></>
                  );
                },
                onSelect: (index: any, value: any) => {}
              }}
            /> */}
          </div>
        </div>
      </div>
    );
  };

  const getChequeDetails = () => {
    return (
      <div className="column  parent-width  pl-xxl mt-s">
        <div className="row row-responsive">
          <div className="pay-web-width-32">
            <DKInput
              className=""
              value={DateFormatService.getDateFromStr(
                paymentPopupState.paymentDetails.additionalPaymentDetails
                  .referenceDate.date,
                BOOKS_DATE_FORMAT['YYYY-MM-DD']
              )}
              dateFormat={convertBooksDateFormatToUILibraryFormat(
                tenantDetails.dateFormat
              )}
              title="Reference Date"
              placeholder=""
              required={false}
              type={INPUT_TYPE.DATE}
              direction={INPUT_VIEW_DIRECTION.VERTICAL}
              onChange={(value: any) =>
                dateSelected(PAYMENT_CALENDAR_TYPE.REFERENCE_DATE, value)
              }
            />
          </div>
          <div className="pay-web-width-32 pay-web-ml-l mobile-mt-s">
            <DKInput
              hasError={
                paymentPopupState.paymentDetails.additionalPaymentDetails
                  .referenceNumber.hasError
              }
              value={paymentPopupState.paymentDetails.additionalPaymentDetails.referenceNumber.value?.toString()}
              title={
                showChequeNumber
                  ? `${getCapitalized(localizedText('cheque'))} Number`
                  : 'Reference Number'
              }
              placeholder=""
              required={false}
              type={INPUT_TYPE.TEXT}
              direction={INPUT_VIEW_DIRECTION.VERTICAL}
              onChange={(event: any) => {
                inputChanged(event, PAYMENT_INPUT_TYPE.REFERENCE_NUMBER);
              }}
            />
          </div>
        </div>
      </div>
    );
  };

  const getFormattedAmountValue = () => {
    let amount = paymentPopupState.paymentDetails.paymentAmount.value;
    if (typeof amount === 'number') {
      return NumberFormatService.getNumber(amount);
    } else if (typeof amount === 'string') {
      return NumberFormatService.getNumber(parseFloat(amount));
    }
    return amount;
  };

  const getPaymentAmountField = () => {
    return (
      <div className="pay-web-width-32 pay-web-ml-l mobile-mt-s">
        <div className="col parent-width">
          <DKLabel className="app-font" text="Amount" />
          <div
            className="row mt-1 border border-2 border-gray-300 text-sm font-normal text-black w-auto justify-content-between p-2 bg-gray1"
            onClick={() => {}}
            style={{
              borderColor: 'rgb(235, 235,235)',
              borderRadius: 4
            }}
          >
            <input
              onFocus={onFocus}
              onBlur={() => {
                onBlur();
                let inputValue: any =
                  paymentPopupState?.paymentDetails?.paymentAmount?.value;
                inputChanged(
                  Utility.roundingOff(inputValue, tenantDetails.decimalScale),
                  PAYMENT_INPUT_TYPE.PAY_AMOUNT
                );
              }}
              className={`parent-width text-align-right outline-none bg-gray1 ${
                Number(paymentPopupState.paymentDetails.paymentAmount.value) < 0
                  ? 'text-red'
                  : ''
              }`}
              value={
                focused
                  ? paymentPopupState.paymentDetails.paymentAmount.value
                    ? paymentPopupState.paymentDetails.paymentAmount.value
                    : 0
                  : getFormattedAmountValue()
              }
              type={'text'}
              onChange={(e: any) => {
                let inputValue = e.target.value;
                if (isNaN(inputValue)) {
                  return;
                }
                if (Utility.isEmpty(inputValue)) {
                  inputValue = 0;
                }
                inputChanged(inputValue, PAYMENT_INPUT_TYPE.PAY_AMOUNT);
              }}
            />
          </div>
        </div>
      </div>
    );
  };

  const getPaymentTypeField = () => {
    return (
      <div className="pay-web-width-32 pay-web-ml-l mobile-mt-s">
        <DKInput
          className=""
          value={paymentPopupState.paymentDetails.paymentType.value.label}
          formatter={(obj: any) => {
            return obj;
          }}
          title="Payment Type"
          placeholder=""
          required={true}
          type={INPUT_TYPE.DROPDOWN}
          canValidate={saveButtonTapped}
          direction={INPUT_VIEW_DIRECTION.VERTICAL}
          onChange={(value: any) => {
            optionSelected(value, PAYMENT_DROPDOWN_TYPE.PAY_TYPE);
            //alert(JSON.stringify(value));
          }}
          dropdownConfig={{
            className: '',
            style: {},
            allowSearch: false,
            searchableKey: 'label',
            data: getOptionForPaymentType(
              paymentPopupState.paymentDetails.paymentType.options
                ? paymentPopupState.paymentDetails.paymentType.options
                : []
            ),
            renderer: (index: any, obj: any) => {
              return (
                <div className="flex flex-row w-full justify-content-between">
                  <DKLabel
                    style={{ fontSize: '13px' }}
                    className="text-base border-radius-s"
                    text={obj.label}
                  />
                </div>
              );
            },
            onSelect: (index: any, value: any) => {}
          }}
        />
      </div>
    );
  };

  const getPaymentDetailsContainer = () => {
    return (
      <>
        {showBankTransfer &&
          !(
            paymentPopupState.paymentDetails.paymentType.value.label ===
              'Cash' ||
            paymentPopupState.paymentDetails.paymentAccountGroup === 'Cash'
          ) && <>{getPaymentTypeField()}</>}
        {getPaymentAmountField()}
      </>
    );
  };

  const changeTaxDeductedOption = (taxDeductionOption: string) => {
    const newState = paymentPopupState;
    newState.tdsDetails.taxDeducted =
      taxDeductionOption === 'YES' ? true : false;
    if (newState.tdsDetails.taxDeducted) {
      onTDSFeeAmount(0);
    }
    setPaymentPopupState({ ...newState });
  };

  const getTDSDetails = () => {
    return (
      <div className="column parent-width mt-9">
        <div className="row width-auto">
          <DKIcon src={DKIcons.ic_cards} />
          <DKLabel text="Tax deducted?" className="ml-r fw-m mr-r" />
          <DKRadioButton
            color="bg-app"
            className="text-black align-items-center ml-r"
            title={'No Tax deducted'}
            isSelected={!paymentPopupState.tdsDetails.taxDeducted}
            onClick={() => {
              changeTaxDeductedOption('NO');
            }}
          />
          <DKRadioButton
            color="bg-app"
            className="text-black align-items-center ml-m"
            title={'Yes, TDS (Income Tax)'}
            isSelected={paymentPopupState.tdsDetails.taxDeducted}
            onClick={() => {
              changeTaxDeductedOption('YES');
            }}
          />
        </div>
        {paymentPopupState.tdsDetails.taxDeducted && (
          <div className="row row-responsive mt-ms pl-xxl mt-3 mobile-mb">
            <div className="pay-web-width-32">
              <DKInput
                value={paymentPopupState.tdsDetails.tdsAccountCode.value}
                direction={INPUT_VIEW_DIRECTION.VERTICAL}
                title={'TDS Tax Account'}
                formatter={(obj: any) => {
                  return obj.label;
                }}
                type={INPUT_TYPE.DROPDOWN}
                required={false}
                onChange={(value: any) => {
                  setPaymentPopupState({
                    ...paymentPopupState,
                    tdsDetails: {
                      ...paymentPopupState.tdsDetails,
                      tdsAccountCode: {
                        ...paymentPopupState.tdsDetails.tdsAccountCode,
                        value
                      }
                    }
                  });
                }}
                dropdownConfig={{
                  className: '',
                  style: {},
                  allowSearch: true,
                  searchableKey: 'label',
                  searchApiConfig: {
                    getUrl: (searchValue: string) => {
                      let endPoint =
                        ApiConstants.URL.ACCOUNTS.FETCH_ACCOUNTS +
                        `?search=${searchValue}&limit=25&status=ACTIVE`;
                      return ApiConstants.URL.BASE + endPoint;
                    },
                    dataParser: (response: any) => {
                      return (
                        response?.content.map((account: any) => {
                          {
                            return {
                              label: account.name,
                              value: account.code
                            };
                          }
                        }) || []
                      );
                      // return response?.content || [];
                    },
                    debounceTime: 500
                  },
                  data: accounts,
                  renderer: (index: any, obj: any) => {
                    return (
                      <div className="flex flex-row w-full justify-content-between">
                        <DKLabel
                          style={{ fontSize: '13px' }}
                          className="text-base border-radius-s"
                          text={obj.label}
                        />
                      </div>
                    );
                  },
                  onSelect: (index: any, value: any) => {}
                }}
              />
            </div>
            <div className="pay-web-width-32 mobile-mt-s pay-web-ml-l">
              <DKInput
                direction={INPUT_VIEW_DIRECTION.VERTICAL}
                type={INPUT_TYPE.NUMBER}
                title={'Amount Withheld'}
                value={paymentPopupState.tdsDetails.tdsAmount.value}
                onChange={(value: number) => {
                  onTDSFeeAmount(value);
                }}
                canValidate={paymentPopupState.tdsDetails.tdsAmount.hasError}
                validator={(value: string) => {
                  return !paymentPopupState.tdsDetails.tdsAmount.hasError;
                }}
                errorMessage={paymentPopupState.tdsDetails.tdsAmount.errorMsg}
              />
            </div>
          </div>
        )}
      </div>
    );
  };

  const [focused, setFocused] = useState(false);
  const onFocus = () => setFocused(true);
  const onBlur = () => setFocused(false);

  const changeCurrencyOutstandingRate = (value: number) => {
    const newState = paymentPopupState;
    if (!showMulticurrencyStepper) {
      newState.paymentDetails.conversionRate.hidden = true;
    } else {
      newState.paymentDetails.conversionRate.hidden = false;
    }
    newState.paymentDetails.conversionRate.defaultValue = value;
    newState.summaryDetails.exchangeRate = value;
    newState.summaryDetails.actualAmountToBePaid =
      Number(documentDetails.dueAmount) / value;
    newState.paymentDetails.paymentAmount.value =
      Number(documentDetails.dueAmount) / value;
    setPaymentPopupState({ ...newState });
  };

  const changeCurrencyrReportingRate = (value: number) => {
    let _tenantBaseCurrency = tenantDetails.currency;
    let _documentCurrency = props.documentDetails.currency;
    let _selectedCurrency =
      paymentPopupState.paymentInfo.selectedCurrency.currencyCode;

    const newState = paymentPopupState;
    if (
      _documentCurrency !== _tenantBaseCurrency &&
      _documentCurrency === _selectedCurrency
    ) {
      newState.summaryDetails.baseExchangeRate = value;
      newState.summaryDetails.exchangeRate = 1;
    } else {
      newState.summaryDetails.baseExchangeRate = value;
    }
    setPaymentPopupState({ ...newState });
  };

  const getCurrencyOutstandingRateField = () => {
    return (
      <div className="parent-width">
        <div
          style={{ height: '35px' }}
          className={
            'row  border rounded w-full text-sm font-normal tracking-normal placeholder-gray-600::placeholder p-1 focus:outline-none focus:ring-2 focus:ring-blue-500 bg-gray1'
          }
        >
          <div style={{ width: '27%' }} className="row currency-select-trigger">
            <div className="ml-1">{`1 ${paymentPopupState.paymentInfo.selectedCurrency.currencyCode}`}</div>
            <div className="currency-dropdown-flag ml-2">
              <span
                className={`currency-dropdown-selector-flag flag ${paymentPopupState.paymentInfo.selectedCurrency.currencyCode}`}
              ></span>
            </div>
          </div>
          <DKIcon
            style={{
              transform: 'rotate(90deg)'
            }}
            src={DKIcons.ic_sort}
          />
          <input
            style={{ width: '50%' }}
            onFocus={onFocus}
            onBlur={onBlur}
            className={`text-align-right outline-none bg-gray1 truncate mx-1.5`}
            value={_getPayloadExchangeRate().dtoExchangeRate}
            type={'s'}
            disabled={_getPayloadExchangeRate().dtoExchangeRate === 1}
            onChange={(e: any) => {
              changeCurrencyOutstandingRate(e.target.value);
            }}
          />
          <div className="ml-1">{`${props.documentDetails.currency}`}</div>
          <div className="currency-dropdown-flag ml-2">
            <span
              className={`currency-dropdown-selector-flag flag ${props.documentDetails.currency}`}
            ></span>
          </div>
        </div>
      </div>
    );
  };

  const getCurrencyrReportingRateField = () => {
    return (
      <div className="parent-width">
        <div
          style={{ height: '35px' }}
          className={
            'row  border rounded w-full text-sm font-normal tracking-normal placeholder-gray-600::placeholder p-1 focus:outline-none focus:ring-2 focus:ring-blue-500 bg-gray1'
          }
        >
          <div style={{ width: '27%' }} className="row currency-select-trigger">
            <div className="ml-1">{`1 ${paymentPopupState.paymentInfo.selectedCurrency.currencyCode}`}</div>
            <div className="currency-dropdown-flag ml-2">
              <span
                className={`currency-dropdown-selector-flag flag ${paymentPopupState.paymentInfo.selectedCurrency.currencyCode}`}
              ></span>
            </div>
          </div>
          <DKIcon
            style={{
              transform: 'rotate(90deg)'
            }}
            src={DKIcons.ic_sort}
          />
          <input
            style={{ width: '50%' }}
            onFocus={onFocus}
            onBlur={onBlur}
            className={`text-align-right outline-none bg-gray1 truncate mx-1.5`}
            value={_getPayloadExchangeRate().exchangeRate}
            type={'number'}
            onChange={(e: any) => {
              changeCurrencyrReportingRate(e.target.value);
            }}
          />
          <div className="ml-1">{`${tenantDetails.currency}`}</div>
          <div className="currency-dropdown-flag ml-2">
            <span
              className={`currency-dropdown-selector-flag flag ${tenantDetails.currency}`}
            ></span>
          </div>
        </div>
      </div>
    );
  };

  const _showCurrencyRateField = () => {
    let _showRateFields: any = {};
    let _tenantBaseCurrency = tenantDetails.currency;
    let _documentCurrency = props.documentDetails.currency;
    let _selectedCurrency =
      paymentPopupState.paymentInfo.selectedCurrency.currencyCode;

    if (
      _documentCurrency === _tenantBaseCurrency &&
      _documentCurrency === _selectedCurrency
    ) {
      _showRateFields = {
        outstandingField: false,
        reportingFields: false
      };
      return _showRateFields;
    } else if (
      _documentCurrency === _tenantBaseCurrency &&
      _documentCurrency !== _selectedCurrency
    ) {
      _showRateFields = {
        outstandingField: true,
        reportingFields: false
      };
      return _showRateFields;
    } else if (
      _documentCurrency !== _tenantBaseCurrency &&
      _documentCurrency === _selectedCurrency
    ) {
      _showRateFields = {
        outstandingField: true,
        reportingFields: true
      };
      return _showRateFields;
    } else if (
      _documentCurrency !== _tenantBaseCurrency &&
      _documentCurrency !== _selectedCurrency
    ) {
      if (_selectedCurrency === _tenantBaseCurrency) {
        _showRateFields = {
          outstandingField: true,
          reportingFields: false
        };
        return _showRateFields;
      } else if (_selectedCurrency !== _tenantBaseCurrency) {
        _showRateFields = {
          outstandingField: true,
          reportingFields: true
        };
        return _showRateFields;
      } else {
        return _showRateFields;
      }
    }
  };

  const getCurrencyConvertionDetails = () => {
    return (
      <>
        {(_showCurrencyRateField().outstandingField ||
          _showCurrencyRateField().reportingFields) && (
          <div className="column parent-width">
            <div className="row width-auto ">
              <DKIcon src={DKIcons.ic_currency} />
              <DKLabel text="Currency Rate" className="ml-r fw-m mr-r" />
            </div>
            <div className="row width-auto "></div>
            <div className="row parent-width">
              <div className="ml-r fw-m ml-xxl mt-2 parent-width flex">
                {_showCurrencyRateField().outstandingField && (
                  <div style={{ width: '50%' }} className="column mr-r">
                    <DKLabel
                      text="Convert to settle outstanding"
                      className="fw-m mb-1"
                    />
                    {getCurrencyOutstandingRateField()}
                  </div>
                )}
                {_showCurrencyRateField().reportingFields && (
                  <div style={{ width: '50%' }} className="column ml-r">
                    <DKLabel
                      text="Convert for reporting"
                      className="fw-m mb-1"
                    />
                    {getCurrencyrReportingRateField()}
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </>
    );
  };

  const getTransactionFeeDetails = () => {
    const onChangeFeeAccount = (accountObj: any) => {
      const newState = paymentPopupState;
      newState.paymentDetails.transactionFee.accountCode = accountObj;
      setPaymentPopupState({ ...newState });
    };

    const onChangeFeeAmount = (amount: any) => {
      const newState = paymentPopupState;
      newState.paymentDetails.transactionFee.amount = amount;

      if (
        transactionFee &&
        !Utility.isEmpty(
          paymentPopupState.paymentDetails.transactionFee.accountCode.code
        ) &&
        paymentPopupState.paymentDetails.transactionFee.amount <= 0
      ) {
        paymentPopupState.paymentDetails.transactionFee.hasError = true;
        paymentPopupState.paymentDetails.transactionFee.errorMsg =
          'Fee Amount should be greater than the zero';
        return;
      } else {
        paymentPopupState.paymentDetails.transactionFee.hasError = false;
        paymentPopupState.paymentDetails.transactionFee.errorMsg = '';
      }

      if (
        transactionFee &&
        paymentPopupState.paymentDetails.transactionFee.amount >
          paymentPopupState.paymentDetails.paymentAmount.value
      ) {
        newState.paymentDetails.transactionFee.hasError = true;
        newState.paymentDetails.transactionFee.errorMsg =
          'Fee Amount should be lesser than the Payment Amount';
      } else {
        newState.paymentDetails.transactionFee.hasError = false;
        newState.paymentDetails.transactionFee.errorMsg = '';
      }
      setPaymentPopupState({ ...newState });
    };

    return (
      <>
        {!transactionFee && (
          <div className="column parent-width pl-xl mt-m">
            <div className="row">
              <DKButton
                title={`+ Add Transaction Fee`}
                onClick={() => setShowTransactinoFee(!transactionFee)}
                className={`text-blue fw-m p-0`}
              />
            </div>
          </div>
        )}
        {transactionFee && (
          <div className="column parent-width pl-xxl mt-s">
            <div className="flex row-responsive items-start parent-width">
              <div className="pay-web-width-32">
                <DKInput
                  type={INPUT_TYPE.DROPDOWN}
                  title={'Fee Account (optional)'}
                  direction={INPUT_VIEW_DIRECTION.VERTICAL}
                  value={
                    paymentPopupState.paymentDetails.transactionFee?.accountCode
                      .name
                  }
                  onChange={(accountObj: any) => {
                    onChangeFeeAccount(accountObj);
                  }}
                  canValidate={saveButtonTapped}
                  dropdownConfig={{
                    title: 'Fee Account',
                    allowSearch: true,
                    searchableKey: 'name',
                    className: 'shadow-m width-auto',
                    searchApiConfig: {
                      getUrl: (searchValue: string) => {
                        const config: AccountAPIConfig = {
                          ...AccountsService.apiConfig,
                          Page: 0,
                          SearchTerm: searchValue,
                          Limit: 10,
                          Query: 'status=ACTIVE,accountGroup.name=Expenses'
                        };
                        AccountsService.apiConfig = config;
                        return (
                          ApiConstants.URL.BASE +
                          AccountsService.getAccountEndPoint()
                        );
                      },
                      dataParser: (response: any) => {
                        return response?.content || [];
                      },
                      debounceTime: 300
                    },
                    data:
                      feeAccounts && feeAccounts.length > 0 ? feeAccounts : [],
                    renderer: (index: any, obj: any) => {
                      return <DKLabel text={`${obj.name}`} />;
                    },
                    onSelect: (index: any, obj: any, rowIndex: any) => {}
                  }}
                />
              </div>
              <div className="pay-web-width-32 pay-web-ml-l mobile-mt-s">
                <DKInput
                  value={
                    paymentPopupState.paymentDetails.transactionFee?.amount
                  }
                  title={`Fee Amount (optional)`}
                  placeholder="0.00"
                  type={INPUT_TYPE.NUMBER}
                  direction={INPUT_VIEW_DIRECTION.VERTICAL}
                  onChange={(value: any) => {
                    onChangeFeeAmount(value);
                  }}
                  readOnly={Utility.isEmpty(
                    paymentPopupState.paymentDetails?.transactionFee
                      ?.accountCode
                  )}
                  canValidate={
                    paymentPopupState.paymentDetails.transactionFee.hasError
                  }
                  validator={(value: string) => {
                    return !paymentPopupState.paymentDetails.transactionFee
                      .hasError;
                  }}
                  errorMessage={
                    paymentPopupState.paymentDetails.transactionFee.errorMsg
                  }
                />
                <small>
                  <div
                    className={`flex ${
                      paymentPopupState.paymentDetails.transactionFee.hasError
                        ? `mt-10`
                        : `mt-2`
                    } items-start`}
                  >
                    <DKIcon
                      src={DKIcons.ic_info}
                      style={{ marginTop: '2px' }}
                      className="ic-xs opacity-40 mr-xs"
                      onClick={() => {}}
                    />
                    <DKLabel
                      style={{ fontSize: '9px' }}
                      text="This is deducted fee from payment’s account"
                    />
                  </div>
                </small>
              </div>
            </div>
          </div>
        )}
      </>
    );
  };

  const onTDSFeeAmount = (amount: any) => {
    const newState = paymentPopupState;
    newState.tdsDetails.tdsAmount.value = amount;

    if (
      (newState.tdsDetails.taxDeducted &&
        Number(newState.tdsDetails.tdsAmount.value) <= 0) ||
      Utility.isEmpty(newState.tdsDetails.tdsAmount.value)
    ) {
      newState.tdsDetails.tdsAmount.hasError = true;
      newState.tdsDetails.tdsAmount.errorMsg =
        'Please enter Amount Withheld greater than 0';
    } else {
      newState.tdsDetails.tdsAmount.hasError = false;
      newState.tdsDetails.tdsAmount.errorMsg = '';
    }
    setPaymentPopupState({ ...newState });
  };

  const getPaymentDetails = () => {
    return (
      <div className="column parent-width">
        <div className="row width-auto">
          <DKIcon src={DKIcons.ic_cards} />
          <DKLabel text="Payment Details" className="ml-r fw-m mr-r" />
          <Toggle
            className="box-content"
            isOn={showPaymentDetails}
            onChange={(checked: any) => {
              setShowPaymentDetails(!showPaymentDetails);
            }}
          />
        </div>
        {showPaymentDetails && (
          <>
            <div className="column parent-width pl-xxl mt-s">
              <div className="row row-responsive mb-3">
                <div className="pay-web-width-32">
                  <DKInput
                    className=""
                    value={getValueForDkInput(
                      paymentPopupState?.paymentDetails?.payFrom?.value?.label
                    )}
                    formatter={(obj: any) => {
                      return obj;
                    }}
                    title={
                      props.documentType === DOC_TYPE.INVOICE
                        ? 'Deposited To'
                        : 'Pay From'
                    }
                    placeholder=""
                    canValidate={saveButtonTapped}
                    required={true}
                    type={INPUT_TYPE.DROPDOWN}
                    direction={INPUT_VIEW_DIRECTION.VERTICAL}
                    onChange={(value: any) => {
                      optionSelected(value, PAYMENT_DROPDOWN_TYPE.PAY_FROM);
                      //alert(JSON.stringify(value));
                    }}
                    //   dropdownConfig={{
                    //     className: '',
                    //     style: {},
                    //     allowSearch: false,
                    //     data: [
                    //       { title: 'Bank' },
                    //       { title: 'Cash' },
                    //       { title: 'Undeposited funds' }
                    //     ],
                    //     renderer: (index: any, obj: any) => {
                    //       return <DKLabel text={`${obj.title}`} />;
                    //     },
                    //     onSelect: (index: any, value: any) => {}
                    //   }}
                    dropdownConfig={{
                      className: '',
                      style: {},
                      allowSearch: tenantDetails?.additionalSettings?.ACCOUNT
                        ?.showCardCashBankAccountsOnly
                        ? paymentPopupState.paymentDetails.payFrom?.options &&
                          paymentPopupState.paymentDetails.payFrom?.options
                            .length > 5
                        : true,
                      searchableKey: 'label',
                      data: tenantDetails?.additionalSettings?.ACCOUNT
                        ?.showCardCashBankAccountsOnly
                        ? paymentPopupState?.paymentDetails?.payFrom?.options
                        : [],
                      searchApiConfig: tenantDetails?.additionalSettings
                        ?.ACCOUNT?.showCardCashBankAccountsOnly
                        ? null
                        : {
                            getUrl: (searchValue: string) => {
                              const config: AccountAPIConfig = {
                                ...AccountsService.apiConfig,
                                Page: 0,
                                SearchTerm: searchValue,
                                Limit: 10,
                                Query: 'status=ACTIVE'
                              };
                              AccountsService.apiConfig = config;
                              return (
                                ApiConstants.URL.BASE +
                                AccountsService.getRecentUsedAccountsEndPoint()
                              );
                            },
                            dataParser: (response: any) => {
                              return (
                                response?.content?.map((account: any) => ({
                                  label: account.name,
                                  value: account.code,
                                  accountGroup: account.accountGroup,
                                  isUndepositedFundAccount:
                                    account.isUndepositedFundAccount
                                })) || []
                              );
                            },
                            debounceTime: 300
                          },
                      renderer: (index: any, obj: any) => {
                        let index1 = obj.label?.indexOf('(');
                        let index2 = obj.label?.indexOf(')');
                        let obj1 = obj.label?.substring(0, index1) || obj.label;
                        let obj2 =
                          obj.label?.substring(index1 + 1, index2) ||
                          obj.accountGroup;
                        return (
                          <div className="flex flex-row w-full justify-content-between align-items-center">
                            <DKLabel
                              style={{ fontSize: '13px' }}
                              className="text-base border-radius-s"
                              text={obj1}
                            />
                            <DKLabel
                              style={{ fontSize: '10px' }}
                              className="border-radius-s"
                              text={obj2}
                            />
                          </div>
                        );
                      },
                      onSelect: (index: any, value: any) => {}
                    }}
                  />
                </div>
                {getPaymentDetailsContainer()}
              </div>
            </div>
            {showBankTransfer &&
              !(
                paymentPopupState.paymentDetails.paymentType.value.label ===
                  'Cash' ||
                paymentPopupState.paymentDetails.paymentAccountGroup === 'Cash'
              ) && (
                <>
                  {getChequeDetails()}
                  {!Utility.isEmpty(
                    paymentPopupState.paymentDetails.paymentType.value.label
                  ) &&
                    props.documentType !== DOC_TYPE.BILL &&
                    getTransactionFeeDetails()}
                </>
              )}
          </>
        )}
      </div>
    );
  };

  const hideCalendar = () => {
    const newState = paymentPopupState;
    newState.paymentInfo.paymentDate.isOpen = false;
    newState.paymentDetails.additionalPaymentDetails.referenceDate.isOpen =
      false;
    setPaymentPopupState({ ...newState });
  };

  const showCalendar = (calendarType: PAYMENT_CALENDAR_TYPE) => {
    const newState = paymentPopupState;
    switch (calendarType) {
      case PAYMENT_CALENDAR_TYPE.PAYMENT_DATE:
        newState.paymentInfo.paymentDate.isOpen = true;
        break;
      case PAYMENT_CALENDAR_TYPE.REFERENCE_DATE:
        newState.paymentDetails.additionalPaymentDetails.referenceDate.isOpen =
          true;
        break;
    }
    setPaymentPopupState({ ...newState });
  };

  const dateSelected = (calendarType: PAYMENT_CALENDAR_TYPE, date: Date) => {
    const newState = paymentPopupState;
    let bookBegingingDate = new Date(tenantDetails.bookBeginningStartDate);
    bookBegingingDate.setHours(0, 0, 0, 0); // Time value should be midnight for comparision with selected date from Calendar.
    let docType =
      props.documentType === DOC_TYPE.INVOICE
        ? 'RECEIVE_PAYMENT'
        : 'MAKE_PAYMENT';
    switch (calendarType) {
      case PAYMENT_CALENDAR_TYPE.PAYMENT_DATE:
        if (bookBegingingDate > date) {
          // new Date(Utility.formatDate(date, DATE_FORMAT.YYYY_MM_DD))
          showAlert(
            'Invalid Date',
            `${'Payment Date must be after the Books Beginning Date'} ${
              tenantDetails.bookBeginningStartDate
            }`
          );
          return;
        } else if (
          !Utility.checkActiveDateRangeValidation(
            date,
            tenantDetails,
            'Payment date',
            docType
          )
        ) {
          return;
        } else if (!Utility.checkClosingDate(date, 'Payment date')) {
          return;
        } else {
          newState.paymentInfo.paymentDate = {
            isOpen: false,
            date: DateFormatService.getDateStrFromDate(
              date,
              BOOKS_DATE_FORMAT['YYYY-MM-DD']
            )
          };
        }
        break;
      case PAYMENT_CALENDAR_TYPE.REFERENCE_DATE:
        newState.paymentDetails.additionalPaymentDetails.referenceDate = {
          isOpen: false,
          date: DateFormatService.getDateStrFromDate(
            date,
            BOOKS_DATE_FORMAT['YYYY-MM-DD']
          )
        };
        break;
    }
    setPaymentPopupState({ ...newState });
  };

  const grabInfo = (data: Information) => {
    if (props.documentType === DOC_TYPE.BILL) {
      fetchAdditionalPaymentInfo(
        data.selectedCurrencyCode,
        documentDetails.contactCode,
        PAYMENT_SECTION.DEBIT_NOTE
      );
      fetchAdditionalPaymentInfo(
        data.selectedCurrencyCode,
        documentDetails.contactCode,
        PAYMENT_SECTION.PRE_PAYMENT
      );
    } else if (props.documentType === DOC_TYPE.INVOICE) {
      fetchAdditionalPaymentInfo(
        data.selectedCurrencyCode,
        documentDetails.contactCode,
        PAYMENT_SECTION.CREDIT_NOTE
      );
      fetchAdditionalPaymentInfo(
        data.selectedCurrencyCode,
        documentDetails.contactCode,
        PAYMENT_SECTION.ADVANCED_PAYMENT
      );
    }
    fetchAccountGroup(data.selectedCurrencyCode);
    baseCurrencyExchangeRate = reverseExchangeRate();
    showMulticurrencyStepper =
      data.selectedCurrencyCode !== documentDetails.currency;

    const filteredCurrency = exchangeRateList.filter(
      (currency) => currency.currencyCode === data.selectedCurrencyCode
    );

    if (filteredCurrency.length > 0) {
      const exchangeRate = filteredCurrency[0].currencyExchangeRate;
      const results: CurrencyConversionResults = convertCurrency(
        documentDetails.dueAmount,
        exchangeRate
      );
      const newState = paymentPopupState;

      if (!showMulticurrencyStepper) {
        newState.paymentDetails.conversionRate.hidden = true;
      } else {
        newState.paymentDetails.conversionRate.hidden = false;
      }
      newState.paymentInfo.selectedCurrency.currencyCode =
        data.selectedCurrencyCode;
      newState.paymentInfo.selectedCurrency.currencyName =
        data.selectedCurrencyName;
      newState.paymentDetails.paymentAmount.defaultValue = results.amount;
      newState.paymentDetails.conversionRate.defaultValue =
        results.exchangeRate;
      newState.summaryDetails.exchangeRate = results.exchangeRate;
      newState.summaryDetails.actualAmountToBePaid =
        Number(documentDetails.dueAmount) / Number(results.exchangeRate);
      newState.paymentDetails.paymentAmount.value =
        Number(documentDetails.dueAmount) / Number(results.exchangeRate);
      newState.summaryDetails.baseExchangeRate = 1 / exchangeRate;
      setPaymentPopupState({ ...newState });
    }
  };

  const reverseExchangeRate = () => {
    const doc = documentDetails;
    if (doc.totalAmount && doc.totalAmountInBaseCurrency) {
      return doc.totalAmount / doc.totalAmountInBaseCurrency;
    } else {
      return doc.exchangeRate;
    }
  };

  const calculateCurrentExchangeRate = (
    currencyExchangeRate: number,
    baseCurrencyExchangeRate: number
  ) => {
    return (1 * baseCurrencyExchangeRate) / currencyExchangeRate;
  };

  const getValidCertificate = (certificate: any, paymentDate: any) => {
    let validFrom, validTo;
    try {
      paymentDate = DateFormatService.getDateFromStr(
        paymentDate,
        BOOKS_DATE_FORMAT['YYYY-MM-DD']
      );
      validFrom = DateFormatService.getDateFromStr(
        certificate?.validFrom,
        BOOKS_DATE_FORMAT['DD-MM-YYYY']
      );
      validTo = DateFormatService.getDateFromStr(
        certificate?.validTo,
        BOOKS_DATE_FORMAT['DD-MM-YYYY']
      );
      const data = [
        DATE_COMPARISON.BOTH_DATES_ARE_EQUAL,
        DATE_COMPARISON.LEFT_DATE_IS_AFTER_RIGHT_DATE
      ];
      return (
        data.includes(DateFormatService.compareDates(paymentDate, validFrom)) &&
        data.includes(DateFormatService.compareDates(validTo, paymentDate))
      );
    } catch (error) {}
    return false;
  };
  /**
   * @description - this function calculate WithHold Tax for Israel Compliance based on Tax Certificates,
   *  if not found then from tax group rate
   */
  const calculateWHTax = async () => {
    const {
      withHoldingCertificatesIsrael = [],
      withHoldingTaxPaymentGroupIsraelId,
      withHoldingTaxTypeIsraelId
    } = contactDetails;
    let paymentDate = paymentPopupState?.paymentInfo?.paymentDate?.date;
    let rate = 0;
    let certificate;
    if (withHoldingCertificatesIsrael.length) {
      certificate = withHoldingCertificatesIsrael?.find((certificate: any) =>
        getValidCertificate(certificate, paymentDate)
      );
      rate = certificate?.withHoldingTaxPercentage || 0;
    }
    if (Utility.isEmptyObject(certificate)) {
      let withHoldTaxGroups = whTaxesGroups;
      if (withHoldTaxGroups.length === 0) {
        await dispatch(fetchWHTaxGroup()).then((res) => {
          withHoldTaxGroups = whTaxesGroups;
          return Promise.resolve();
        });
      }
      const withHoldTaxGroup = withHoldTaxGroups?.find(
        (group: IWHTaxGroup) => group.id === withHoldingTaxPaymentGroupIsraelId
      );
      rate = withHoldTaxGroup?.percentage || 0;
    }
    let { paymentInfo } = paymentPopupState;
    const outstandingAmount = Number(paymentInfo.outstandingAmount);
    const withHoldTaxAmount = outstandingAmount * (rate / 100);
    paymentInfo = {
      ...paymentInfo,
      withHoldTaxDetails: { amount: withHoldTaxAmount, rate }
    };
    setPaymentPopupState((prev) => ({ ...prev, paymentInfo }));
  };

  const targetToBaseConversion = (rate: number, targetCurrencyRate: number) => {
    return baseCurrencyExchangeRate
      ? (rate / baseCurrencyExchangeRate) * targetCurrencyRate
      : 0;
  };

  const convertCurrency = (
    baseAmount: number,
    currencyExchangeRate: number
  ): CurrencyConversionResults => {
    const dueAmount = Utility.roundOffToTenantDecimalScale(baseAmount);
    const baseExchangeRate = 1 / currencyExchangeRate;
    const rawPaymentAmount = Utility.roundOffToTenantDecimalScale(
      targetToBaseConversion(dueAmount, currencyExchangeRate)
    );

    const amount = Utility.roundOffToTenantDecimalScale(rawPaymentAmount);
    let exchangeRate = calculateCurrentExchangeRate(
      currencyExchangeRate,
      baseCurrencyExchangeRate
    );
    let exchAmount =
      Math.trunc(rawPaymentAmount * Math.pow(10, 2)) / Math.pow(10, 2);
    exchAmount = exchAmount == 0 ? rawPaymentAmount : exchAmount;
    let invCurrencyAmount = Utility.roundOffToTenantDecimalScale(
      exchAmount * baseExchangeRate,
      tenantDecimalScale
    );
    let diff = dueAmount - invCurrencyAmount;
    if (showMulticurrencyStepper && diff !== 0) {
      let temporaryFx = dueAmount / exchAmount;
      exchangeRate = temporaryFx;
    }
    return { amount, exchangeRate, baseExchangeRate };
  };

  const optionSelected = (
    option: any | null,
    dropdownType: PAYMENT_DROPDOWN_TYPE
  ) => {
    const newState = paymentPopupState;
    switch (dropdownType) {
      case PAYMENT_DROPDOWN_TYPE.PAY_FROM:
        if (option) {
          let index1 = option.label.indexOf('(');
          let obj1 = option.label.substring(0, index1) || option.label;
          let index2 = option.label.indexOf(')');
          let obj2 =
            option.label.substring(index1 + 1, index2) || option.accountGroup;
          if (obj2 === 'Bank') {
            newState.paymentDetails.paymentType.hidden = false;
            newState.paymentDetails.paymentType.value = {
              label: 'Bank Transfer',
              value: 'BANK_TRANSFER'
            };
            setShowBankTransfer(true);
          } else if (obj2 === 'Cash') {
            newState.paymentDetails.paymentType.hidden = true;
            newState.paymentDetails.paymentType.value = {
              label: 'CASH',
              value: 'CASH'
            };
            setShowBankTransfer(false);
          } else {
            newState.paymentDetails.paymentType.hidden = false;
            newState.paymentDetails.paymentType.value = {
              label: 'Others',
              value: 'OTHERS'
            };
            setShowBankTransfer(true);
          }
          newState.paymentDetails.paymentAccountGroup = obj2;
          newState.paymentDetails.payFrom.value = option;
        }
        break;
      case PAYMENT_DROPDOWN_TYPE.PAY_TYPE:
        if (option) {
          newState.paymentDetails.paymentType.value = option;
          if (option.label === COMMON_CONSTANT.CHEQUE_VALUE) {
            setShowChequeNumber(true);
          } else {
            setShowChequeNumber(false);
          }
        }
        break;
      default:
        break;
    }
    setPaymentPopupState({ ...newState });
  };

  const inputChanged = (event: any, inputType: PAYMENT_INPUT_TYPE) => {
    const inputValue = event;
    const newState = paymentPopupState;
    switch (inputType) {
      case PAYMENT_INPUT_TYPE.PAY_AMOUNT:
        console.log('inputValue', inputValue);
        if (Number(inputValue) <= 0) {
          newState.paymentDetails.paymentAmount.hasError = true;
          newState.paymentDetails.paymentAmount.errorMsg =
            'Amount must be greater than 0';
        } else if (
          Number(inputValue) > newState.summaryDetails.actualAmountToBePaid ||
          isNaN(Number(inputValue))
        ) {
          newState.paymentDetails.paymentAmount.hasError = true;
          newState.paymentDetails.paymentAmount.errorMsg =
            'Amount is greater than Due Amount';
        } else if (Number(inputValue) < 0) {
          newState.paymentDetails.paymentAmount.hasError = true;
          newState.paymentDetails.paymentAmount.errorMsg =
            'Please enter non negative amount';
        } else {
          newState.paymentDetails.paymentAmount.hasError = false;
        }
        newState.paymentDetails.paymentAmount.defaultValue = inputValue;
        newState.paymentDetails.paymentAmount.value = inputValue;
        const { isWHTApplicable } = paymentPopupState;
        const { totalAmount } = props.documentDetails;
        if (isWHTApplicable && Number(inputValue) < Number(totalAmount)) {
          newState.isWHTApplicable = false;
        }
        console.log('newState', newState);
        break;
      case PAYMENT_INPUT_TYPE.CONVERSION_RATE:
        if (Number(inputValue) === 0 || isNaN(Number(inputValue))) {
          newState.paymentDetails.conversionRate.hasError = true;
          newState.paymentDetails.conversionRate.errorMsg =
            'Not valid Conversion Rate';
          newState.paymentDetails.conversionRate.defaultValue = inputValue;
          break;
        } else {
          newState.paymentDetails.conversionRate.hasError = false;
        }
        const dueAmount = documentDetails.dueAmount;
        newState.paymentDetails.conversionRate.value = inputValue;
        newState.paymentDetails.paymentAmount.defaultValue =
          Utility.roundOffToTenantDecimalScale(
            Number(dueAmount) / Number(inputValue)
          );
        newState.paymentDetails.paymentAmount.value =
          Utility.roundOffToTenantDecimalScale(
            Number(dueAmount) / Number(inputValue)
          );
        newState.summaryDetails.actualAmountToBePaid =
          Utility.roundOffToTenantDecimalScale(
            Number(dueAmount) / Number(inputValue)
          );
        break;
      case PAYMENT_INPUT_TYPE.REFERENCE_NUMBER:
        newState.paymentDetails.additionalPaymentDetails.referenceNumber.value =
          inputValue;
        break;
    }
    setPaymentPopupState({ ...newState });
  };

  const _getPayloadExchangeRate = () => {
    let _payloadExchangeRate: any = {};
    let _tenantBaseCurrency = tenantDetails.currency;
    let _documentCurrency = props.documentDetails.currency;
    let _selectedCurrency =
      paymentPopupState.paymentInfo.selectedCurrency.currencyCode;

    let _baseExchangeRate = paymentPopupState.summaryDetails.baseExchangeRate;
    let _exchangeRate = paymentPopupState.summaryDetails.exchangeRate;
    let _convertedExchangeRate =
      paymentPopupState.summaryDetails.baseExchangeRate /
      paymentPopupState.summaryDetails.exchangeRate;

    if (
      _documentCurrency === _tenantBaseCurrency &&
      _documentCurrency === _selectedCurrency
    ) {
      _payloadExchangeRate = {
        exchangeRate: _baseExchangeRate,
        dtoExchangeRate: _baseExchangeRate
      };
      return _payloadExchangeRate;
    } else if (
      _documentCurrency === _tenantBaseCurrency &&
      _documentCurrency !== _selectedCurrency
    ) {
      _payloadExchangeRate = {
        exchangeRate: _exchangeRate,
        dtoExchangeRate: _exchangeRate
      };
      return _payloadExchangeRate;
    } else if (
      _documentCurrency !== _tenantBaseCurrency &&
      _documentCurrency === _selectedCurrency
    ) {
      _payloadExchangeRate = {
        exchangeRate: _convertedExchangeRate,
        dtoExchangeRate: 1
      };
      return _payloadExchangeRate;
    } else if (
      _documentCurrency !== _tenantBaseCurrency &&
      _documentCurrency !== _selectedCurrency
    ) {
      if (_selectedCurrency === _tenantBaseCurrency) {
        _payloadExchangeRate = {
          exchangeRate: _baseExchangeRate,
          dtoExchangeRate: _exchangeRate
        };
        return _payloadExchangeRate;
      } else if (_selectedCurrency !== _tenantBaseCurrency) {
        _payloadExchangeRate = {
          exchangeRate: _baseExchangeRate,
          dtoExchangeRate: _exchangeRate
        };
        return _payloadExchangeRate;
      } else {
        return _payloadExchangeRate;
      }
    }
  };

  const _getKnockOffExchangeRate = () => {
    let _payloadCreditNoteExchangeRate: any = '';
    let _tenantBaseCurrency = tenantDetails.currency;
    let _documentCurrency = props.documentDetails.currency;
    let _selectedCurrency =
      paymentPopupState.paymentInfo.selectedCurrency.currencyCode;

    let _baseExchangeRate = paymentPopupState.summaryDetails.baseExchangeRate;
    let _exchangeRate = paymentPopupState.summaryDetails.exchangeRate;
    let _convertedExchangeRate =
      paymentPopupState.summaryDetails.baseExchangeRate /
      paymentPopupState.summaryDetails.exchangeRate;

    if (
      _documentCurrency === _tenantBaseCurrency &&
      _documentCurrency === _selectedCurrency
    ) {
      _payloadCreditNoteExchangeRate = _exchangeRate;
      return _payloadCreditNoteExchangeRate;
    } else if (
      _documentCurrency === _tenantBaseCurrency &&
      _documentCurrency !== _selectedCurrency
    ) {
      _payloadCreditNoteExchangeRate = _exchangeRate;
      return _payloadCreditNoteExchangeRate;
    } else if (
      _documentCurrency !== _tenantBaseCurrency &&
      _documentCurrency === _selectedCurrency
    ) {
      _payloadCreditNoteExchangeRate = _convertedExchangeRate;
      return _payloadCreditNoteExchangeRate;
    } else if (
      _documentCurrency !== _tenantBaseCurrency &&
      _documentCurrency !== _selectedCurrency
    ) {
      if (_selectedCurrency === _tenantBaseCurrency) {
        _payloadCreditNoteExchangeRate = _exchangeRate;
        return _payloadCreditNoteExchangeRate;
      } else if (_selectedCurrency !== _tenantBaseCurrency) {
        _payloadCreditNoteExchangeRate = _exchangeRate;
        return _payloadCreditNoteExchangeRate;
      } else {
        return _payloadCreditNoteExchangeRate;
      }
    }
  };

  const makePayload = (): PaymentPayload => {
    let paymentType = paymentPopupState.paymentDetails.paymentType.value.value;
    let index1 =
      paymentPopupState.paymentDetails.payFrom.value.label.indexOf('(');
    let accountName =
      paymentPopupState.paymentDetails.payFrom.value.label.substring(
        0,
        index1
      ) || paymentPopupState.paymentDetails.payFrom.value.label;
    if (paymentPopupState.paymentDetails.paymentAccountGroup === 'Cash') {
      paymentType = 'CASH';
    }
    let _payFrom: any = paymentPopupState.paymentDetails.payFrom.value;
    const Payload: PaymentPayload = {
      documentDate: paymentPopupState.paymentInfo.paymentDate.date,
      closeDate: paymentPopupState.paymentInfo.paymentDate.date,
      amount: Utility.roundOffToTenantDecimalScale(
        Number(paymentPopupState.paymentDetails.paymentAmount.value)
      ),
      currency: paymentPopupState.paymentInfo.selectedCurrency.currencyCode,
      exchangeRate: _getPayloadExchangeRate().exchangeRate,
      contactCode: documentDetails.contactCode,
      isUnDepositedPayment: _payFrom.isUndepositedFundAccount ? true : null,
      accountName: accountName,
      contactName: paymentPopupState.paymentInfo.contactName,
      referenceNumber:
        paymentPopupState.paymentDetails.additionalPaymentDetails.referenceNumber.value.toString(),
      referenceDate:
        paymentPopupState.paymentDetails.additionalPaymentDetails.referenceDate
          .date,
      paymentType: paymentType,
      customField: customField,
      customerTypeIndia: props?.documentDetails?.customerType,
      vendorTypeIndia: props?.documentDetails?.vendorType,
      gstTreatmentIndia: props?.documentDetails?.gstTreatment,
      gstIn: props?.documentDetails?.gstin,
      status:
        paymentType === PAYMENT_TYPES.CHEQUE
          ? REQUEST_PRINT_STATUS.NOT_PRINTED
          : undefined
    };

    if (tenantDetails.country === COUNTRY_CODES.IN) {
      let accountCode: any =
        paymentPopupState.tdsDetails.tdsAccountCode.value.value;
      Payload.tdsAccountCode = accountCode;
      Payload.tdsAmount =
        paymentPopupState.tdsDetails.tdsAmount.value.toString();
    }
    if (
      tenantDetails.country === COUNTRY_CODES.IN &&
      AuthService.userDetails.complianceEnabled
    ) {
      Payload.whtApplicable = false;
      Payload.whtRate = 0;
    }

    if (
      transactionFee &&
      !Utility.isEmpty(
        paymentPopupState.paymentDetails.transactionFee.accountCode.code
      )
    ) {
      let _transactionFees: any = [];
      let _fee = {
        accountCode:
          paymentPopupState.paymentDetails.transactionFee.accountCode.code,
        amount: paymentPopupState.paymentDetails.transactionFee.amount
      };
      _transactionFees.push(_fee);
      Payload.receivePaymentFeeDtoList = _transactionFees;
    }
    Payload.whtApplicable = !!paymentPopupState.isWHTApplicable;
    if (
      tenantDetails.country === COUNTRY_CODES.IL &&
      paymentPopupState.isWHTApplicable
    ) {
      const { withHoldTaxDetails } = paymentPopupState?.paymentInfo;
      const ilWhtAccountCode = accountList?.content?.find(
        (account: any) =>
          account.accountCode === tenantDetails.ilWithholdingTaxAccount
      )?.code;
      Payload['whtInfoIsrael'] = {
        ilWhtAccountCode: ilWhtAccountCode,
        ilWhtAmount: withHoldTaxDetails.amount,
        ilWhtRate: withHoldTaxDetails?.rate
      };
    }
    return Payload;
  };

  const makePayment = () => {
    if (getRemainingAmount() < 0) {
      showAlert(
        'Error',
        'Payment Amount cannot be more than Outstanding Amount'
      );
      return;
    }
    setSaveButtonTapped(true);
    if (!Utility.isEmpty(paymentPopupState?.paymentInfo?.paymentDate?.date)) {
      const docDate = DateFormatService.getDateFromStr(
        paymentPopupState.paymentInfo.paymentDate.date,
        BOOKS_DATE_FORMAT['YYYY-MM-DD']
      );
      if (
        !Utility.checkActiveDateRangeValidation(
          docDate,
          tenantDetails,
          'Payment date',
          'MAKE_PAYMENT'
        )
      ) {
        return;
      }
      if (!Utility.checkClosingDate(docDate, 'Payment date')) {
        return;
      }
    }
    let hasData = 0;

    let Payload = makePayload();
    const makePaymentItem: PaymentItemDto = {
      customerOrderNumber: '',
      documentCode: documentDetails.purchaseInvoiceCode,
      documentType: props.documentType,
      exchangeRate: _getPayloadExchangeRate().dtoExchangeRate,
      paymentAmount: Utility.roundOffToTenantDecimalScale(
        Number(paymentPopupState.paymentDetails.paymentAmount.value)
      ),
      documentSequenceCode: documentDetails.documentSequenceCode
    };

    Payload = {
      ...Payload,
      accountCodePayFrom: paymentPopupState.paymentDetails.payFrom.value.value,
      makePaymentItemDtoList: [makePaymentItem]
    };

    if (
      showPaymentDetails &&
      typeof paymentPopupState.paymentDetails.paymentAmount.value ===
        'number' &&
      paymentPopupState.paymentDetails.paymentAmount.value > 0 &&
      (Utility.isEmpty(Payload.accountName) ||
        Utility.isEmpty(Payload.accountCodePayFrom))
    ) {
      return;
    }

    if (
      showPaymentDetails &&
      +paymentPopupState.paymentDetails.paymentAmount.value > 0 &&
      Payload.paymentType === ''
    ) {
      setSaveButtonTapped(true);
      return;
    }

    if (customField && customField.length > 0) {
      let mandatoryCf = customField.filter(
        (cf: any) =>
          cf.mandatory === true &&
          (cf.value === '' || cf.value === null || cf.value === undefined)
      );
      if (mandatoryCf && mandatoryCf.length > 0) {
        setSaveButtonTapped(true);
        return;
      }
    }

    let prePaymentPayload: NoteItemPayload[] = [];

    if (showPrePayment) {
      paymentPopupState.prePayment.prePaymentItem?.forEach((item) => {
        if (item.amount > 0) {
          hasData++;
          const itemPayload: NoteItemPayload = {
            amount: Number(item.amount),
            currency: item.currencyCode,
            documentCode: documentDetails.purchaseInvoiceCode,
            documentType: DOC_TYPE.BILL,
            knockOffDate: DateFormatService.getFormattedDateString(
              paymentPopupState.paymentInfo.paymentDate.date,
              BOOKS_DATE_FORMAT['YYYY-MM-DD'],
              BOOKS_DATE_FORMAT['DD-MM-YYYY']
            ),
            paymentCode: item.code,
            exchangeRate: _getKnockOffExchangeRate(),
            linkedDocumentSequence: documentDetails.documentSequenceCode
          };
          prePaymentPayload.push(itemPayload);
        }
      }); //payments/make/knock-off
    }

    let debitNotePayload: NoteItemPayload[] = [];
    if (showDebitNote) {
      paymentPopupState.debitNote.debitItem?.forEach((item) => {
        if (item.amount > 0) {
          hasData++;
          const itemPayload: NoteItemPayload = {
            amount: Number(item.amount),
            currency: item.currencyCode,
            documentCode: documentDetails.purchaseInvoiceCode,
            documentType: DOC_TYPE.BILL,
            knockOffDate: DateFormatService.getFormattedDateString(
              paymentPopupState.paymentInfo.paymentDate.date,
              BOOKS_DATE_FORMAT['YYYY-MM-DD'],
              BOOKS_DATE_FORMAT['DD-MM-YYYY']
            ),
            paymentCode: item.documentSequenceCode,
            exchangeRate: _getKnockOffExchangeRate(),
            noteCode: item.code,
            noteSeqCode: item.documentSequenceCode,
            documentSeqCode: documentDetails.documentSequenceCode
          };
          debitNotePayload.push(itemPayload);
        }
      }); //notes/debit/knock-off
    }

    dispatch(setIsLoadingPopupWrapper(true));
    if (prePaymentPayload.length > 0) {
      PaymentService.makeAdditionalPayment(
        prePaymentPayload,
        PAYMENT_SECTION.PRE_PAYMENT
      )
        .then((res) => {
          closePopup(DOC_TYPE.BILL);
        })
        .catch((err: any) => {
          dispatch(setIsLoadingPopupWrapper(false));
        });
    }

    if (debitNotePayload.length > 0) {
      PaymentService.makeAdditionalPayment(
        debitNotePayload,
        PAYMENT_SECTION.DEBIT_NOTE
      )
        .then((res) => {
          closePopup(DOC_TYPE.BILL);
        })
        .catch((err: any) => {
          dispatch(setIsLoadingPopupWrapper(false));
        });
    }

    if (
      showPaymentDetails &&
      +paymentPopupState.paymentDetails.paymentAmount.value > 0
    ) {
      hasData++;
      if (getRemainingAmount() >= 0) {
        PaymentService.makePayment(Payload).then((res) => {
          closePopup(DOC_TYPE.BILL);
        });
      } else {
        setSaveButtonTapped(true);
      }
    }
    if (hasData === 0) {
      dispatch(setIsLoadingPopupWrapper(false));
      showAlert('Payment Details', t(`INVOICES.NO_PAYMENT_RECORD_TOAST_ERROR`));
      return;
    }
  };

  const receivePayment = () => {
    if (getRemainingAmount() < 0) {
      setSaveButtonTapped(true);
      return;
    }
    if (!Utility.isEmpty(paymentPopupState?.paymentInfo?.paymentDate?.date)) {
      const docDate = DateFormatService.getDateFromStr(
        paymentPopupState.paymentInfo.paymentDate.date,
        BOOKS_DATE_FORMAT['YYYY-MM-DD']
      );
      if (
        !Utility.checkActiveDateRangeValidation(
          docDate,
          tenantDetails,
          'Payment date',
          'RECEIVE_PAYMENT'
        )
      ) {
        return;
      }
      if (!Utility.checkClosingDate(docDate, 'Payment date')) {
        return;
      }
    }
    let hasData = 0;
    let Payload = makePayload();
    const receivePaymentItem: PaymentItemDto = {
      customerOrderNumber: documentDetails.customerOrderNumber || '',
      documentCode: documentDetails.salesInvoiceCode,
      documentType: props.documentType,
      exchangeRate: _getPayloadExchangeRate().dtoExchangeRate,
      paymentAmount: Utility.roundOffToTenantDecimalScale(
        Number(paymentPopupState.paymentDetails.paymentAmount.value)
      ),
      documentSequenceCode: documentDetails.documentSequenceCode
    };

    Payload = {
      ...Payload,
      accountCodePayTo: paymentPopupState.paymentDetails.payFrom.value.value,
      receivePaymentItemDtoList: [receivePaymentItem]
    };

    if (
      showPaymentDetails &&
      +paymentPopupState.paymentDetails.paymentAmount.value > 0 &&
      (Utility.isEmpty(Payload.accountName) ||
        Utility.isEmpty(Payload.accountCodePayTo))
    ) {
      setSaveButtonTapped(true);
      return;
    }

    if (
      showPaymentDetails &&
      +paymentPopupState.paymentDetails.paymentAmount.value > 0 &&
      Payload.paymentType === ''
    ) {
      setSaveButtonTapped(true);
      return;
    }

    if (
      transactionFee &&
      !Utility.isEmpty(
        paymentPopupState.paymentDetails.transactionFee.accountCode.code
      ) &&
      paymentPopupState.paymentDetails.transactionFee.amount <= 0
    ) {
      paymentPopupState.paymentDetails.transactionFee.hasError = true;
      paymentPopupState.paymentDetails.transactionFee.errorMsg =
        'Fee Amount should be greater than the zero';
      setPaymentPopupState({ ...paymentPopupState });

      return;
    } else if (
      transactionFee &&
      paymentPopupState.paymentDetails.transactionFee.amount >
        paymentPopupState.paymentDetails.paymentAmount.value
    ) {
      paymentPopupState.paymentDetails.transactionFee.hasError = true;
      paymentPopupState.paymentDetails.transactionFee.errorMsg =
        'Fee Amount should be lesser than the Payment Amount';
      setPaymentPopupState({ ...paymentPopupState });
      return;
    } else {
      paymentPopupState.paymentDetails.transactionFee.hasError = false;
      paymentPopupState.paymentDetails.transactionFee.errorMsg = '';
      setPaymentPopupState({ ...paymentPopupState });
    }

    if (paymentPopupState.tdsDetails.taxDeducted) {
      if (Number(Payload.tdsAmount) <= 0) {
        setSaveButtonTapped(true);
        return;
      }
    }
    if (customField && customField.length > 0) {
      let mandatoryCf = customField.filter(
        (cf: any) =>
          cf.mandatory === true &&
          (cf.value === '' || cf.value === null || cf.value === undefined)
      );
      if (mandatoryCf && mandatoryCf.length > 0) {
        setSaveButtonTapped(true);
        return;
      }
    }

    dispatch(setIsLoadingPopupWrapper(true));
    let advancedPaymentPayload: NoteItemPayload[] = [];
    if (showAdvancedPayment) {
      paymentPopupState.advancedPayment.advancedPaymentItem?.forEach((item) => {
        if (item.amount > 0) {
          hasData++;
          const itemPayload: NoteItemPayload = {
            amount: Number(item.amount),
            currency: item.currencyCode,
            documentCode: documentDetails.salesInvoiceCode,
            documentType: DOC_TYPE.INVOICE,
            knockOffDate: DateFormatService.getFormattedDateString(
              paymentPopupState.paymentInfo.paymentDate.date,
              BOOKS_DATE_FORMAT['YYYY-MM-DD'],
              BOOKS_DATE_FORMAT['DD-MM-YYYY']
            ),
            paymentCode: item.code,
            exchangeRate: _getKnockOffExchangeRate(),
            linkedDocumentSequence: documentDetails.documentSequenceCode
          };
          advancedPaymentPayload.push(itemPayload);
        }
      }); //payments/receive/knock-off
    }

    let creditNotePayload: NoteItemPayload[] = [];
    if (showCreditNote) {
      paymentPopupState.creditNote.creditItem?.forEach((item) => {
        if (item.amount > 0) {
          hasData++;
          const itemPayload: NoteItemPayload = {
            amount: Number(item.amount),
            currency: item.currencyCode,
            documentCode: documentDetails.salesInvoiceCode,
            documentType: DOC_TYPE.INVOICE,
            knockOffDate: DateFormatService.getFormattedDateString(
              paymentPopupState.paymentInfo.paymentDate.date,
              BOOKS_DATE_FORMAT['YYYY-MM-DD'],
              BOOKS_DATE_FORMAT['DD-MM-YYYY']
            ),
            paymentCode: item.documentSequenceCode,
            exchangeRate: _getKnockOffExchangeRate(),
            noteCode: item.code,
            noteSeqCode: item.documentSequenceCode,
            documentSeqCode: documentDetails.documentSequenceCode
          };
          creditNotePayload.push(itemPayload);
        }
      }); //notes/credit/knock-off
    }

    if (advancedPaymentPayload.length > 0) {
      PaymentService.makeAdditionalPayment(
        advancedPaymentPayload,
        PAYMENT_SECTION.ADVANCED_PAYMENT
      )
        .then((res) => {
          closePopup(DOC_TYPE.INVOICE);
        })
        .catch((err: any) => {
          dispatch(setIsLoadingPopupWrapper(false));
        });
    }

    if (creditNotePayload.length > 0) {
      PaymentService.makeAdditionalPayment(
        creditNotePayload,
        PAYMENT_SECTION.CREDIT_NOTE
      )
        .then((res) => {
          closePopup(DOC_TYPE.INVOICE);
        })
        .catch((err: any) => {
          dispatch(setIsLoadingPopupWrapper(false));
        });
    }
    if (
      showPaymentDetails &&
      Number(paymentPopupState.paymentDetails.paymentAmount.value) > 0
    ) {
      hasData++;
      if (getRemainingAmount() >= 0) {
        PaymentService.receivePayment(Payload)
          .then((res) => {
            closePopup(DOC_TYPE.INVOICE);
          })
          .catch((err) => {
            dispatch(setIsLoadingPopupWrapper(false));
          });
      } else {
        setSaveButtonTapped(true);
      }
    }
    if (hasData === 0) {
      dispatch(setIsLoadingPopupWrapper(false));
      showAlert('Payment Details', t(`INVOICES.NO_PAYMENT_RECORD_TOAST_ERROR`));
      return;
    }
  };

  const loadInvoices = async (config: InvoiceAPIConfig) => {
    try {
      if (!Utility.isEmpty(config)) {
        InvoiceService.apiConfig = config;
      }
      await dispatch(fetchInvoices());
    } catch (err) {
      console.error('Error loading invoices: ', err);
    }
  };

  const closePopup = (docType: DOC_TYPE) => {
    console.log('inindad');
    if (props.passingInteraction) {
      props.passingInteraction({
        type: POPUP_CALLBACKS_TYPE.CLOSE_POPUP
      });
    }
    if (docType === DOC_TYPE.BILL) {
      dispatch(fetchBills());
    } else {
      loadInvoices({});
    }
    DashboardService.apiConfig = SellDashboardConfig.config;
    dispatch(getSellDashboard());
    DashboardService.apiConfig = AgedReceivableDashboardConfig.config;
    dispatch(getAgedReceivable());
    DashboardService.apiConfig = getCashFlowDashboardConfig.config;
    dispatch(getCashFlow());

    dispatch(setIsLoadingPopupWrapper(false));
    showAlert('Success!', '1 payment completed successfully.');
  };

  const refreshDashboardChart = () => {
    dispatch(fetchBanksList());
    getCashFlowReport();
    getAgeReceivableReport();
  };

  const getCashFlowReport = () => {
    let date = new Date();
    var config = {
      toDate: Utility.formatDate(date, DATE_FORMAT.DD_MM_YYYY),
      duration: props.extraData.cashFlowFilter
    };
    DashboardService.apiConfig = config;
    dispatch(getCashFlow());
  };

  const getAgeReceivableReport = async () => {
    try {
      var date = new Date();
      var config = {
        type: 'RECEIVABLE',
        fromDate: Utility.formatDate(date, DATE_FORMAT.DD_MM_YYYY)
      };
      DashboardService.apiConfig = config;
      const data = await dispatch(getAgedReceivable());
    } catch (err) {
      console.error('Error loading products: ', err);
    }
  };

  const checkboxChanged = (
    event: React.MouseEvent<HTMLInputElement, MouseEvent>,
    checkboxIndex: number,
    paymentSection: PAYMENT_SECTION
  ) => {
    const newState = paymentPopupState;

    if (
      paymentSection === PAYMENT_SECTION.DEBIT_NOTE &&
      newState.debitNote.debitItem
    ) {
      newState.debitNote.debitItem[checkboxIndex].isSelected =
        event.currentTarget.checked;
      newState.debitNote.debitItem[checkboxIndex].amount =
        newState.debitNote.debitItem[checkboxIndex].dueAmount;
    }

    if (
      paymentSection === PAYMENT_SECTION.CREDIT_NOTE &&
      newState.creditNote.creditItem
    ) {
      newState.creditNote.creditItem[checkboxIndex].isSelected =
        event.currentTarget.checked;
      newState.creditNote.creditItem[checkboxIndex].amount =
        newState.creditNote.creditItem[checkboxIndex].dueAmount;
    }

    if (
      paymentSection === PAYMENT_SECTION.ADVANCED_PAYMENT &&
      newState.advancedPayment.advancedPaymentItem
    ) {
      newState.advancedPayment.advancedPaymentItem[checkboxIndex].isSelected =
        event.currentTarget.checked;
      newState.advancedPayment.advancedPaymentItem[checkboxIndex].amount =
        newState.advancedPayment.advancedPaymentItem[checkboxIndex].dueAmount;
    }

    if (
      paymentSection === PAYMENT_SECTION.PRE_PAYMENT &&
      newState.prePayment.prePaymentItem
    ) {
      newState.prePayment.prePaymentItem[checkboxIndex].isSelected =
        event.currentTarget.checked;
      newState.prePayment.prePaymentItem[checkboxIndex].amount =
        newState.prePayment.prePaymentItem[checkboxIndex].dueAmount;
    }
    const totalAdditionalAmount = calculateAdditionalCharge();

    newState.summaryDetails.additionalPaymentAmount = totalAdditionalAmount;
    setPaymentPopupState({ ...newState });
  };

  const calculateAdditionalCharge = () => {
    const newState = paymentPopupState;
    let checkboxChecked: boolean = false;
    let totalAdditionalAmount: number = 0;

    if (showDebitNote) {
      newState.debitNote.debitItem &&
        newState.debitNote.debitItem.forEach((item) => {
          if (item.amount > 0) {
            checkboxChecked = true;
            totalAdditionalAmount = totalAdditionalAmount + Number(item.amount);
          }
        });
    }

    if (showPrePayment) {
      newState.prePayment.prePaymentItem &&
        newState.prePayment.prePaymentItem.forEach((item) => {
          if (item.amount > 0) {
            checkboxChecked = true;
            totalAdditionalAmount = totalAdditionalAmount + Number(item.amount);
          }
        });
    }
    if (showAdvancedPayment) {
      newState.advancedPayment.advancedPaymentItem &&
        newState.advancedPayment.advancedPaymentItem.forEach((item) => {
          if (item.amount > 0) {
            checkboxChecked = true;
            totalAdditionalAmount = totalAdditionalAmount + Number(item.amount);
          }
        });
    }
    if (showCreditNote) {
      newState.creditNote.creditItem &&
        newState.creditNote.creditItem.forEach((item) => {
          if (item.amount > 0) {
            checkboxChecked = true;
            totalAdditionalAmount = totalAdditionalAmount + Number(item.amount);
          }
        });
    }

    if (paymentPopupState.tdsDetails.taxDeducted) {
      if (paymentPopupState.tdsDetails.taxDeducted) {
        if (paymentPopupState.tdsDetails.tdsAmount.value > 0) {
          checkboxChecked = true;
          totalAdditionalAmount =
            totalAdditionalAmount +
            Number(paymentPopupState.tdsDetails.tdsAmount.value);
        }
      }
    }

    return totalAdditionalAmount;
  };

  const payMethodAmountChanged = (
    event: any,
    itemIndex: number,
    paymentSection: PAYMENT_SECTION
  ) => {
    const newState = paymentPopupState;
    const inputValue = event;
    switch (paymentSection) {
      case PAYMENT_SECTION.ADVANCED_PAYMENT:
        if (newState.advancedPayment.advancedPaymentItem) {
          newState.advancedPayment.advancedPaymentItem[itemIndex].amount =
            inputValue;
        }
        break;
      case PAYMENT_SECTION.CREDIT_NOTE:
        if (newState.creditNote.creditItem) {
          newState.creditNote.creditItem[itemIndex].amount = inputValue;
        }
        break;
      case PAYMENT_SECTION.PRE_PAYMENT:
        if (newState.prePayment.prePaymentItem) {
          newState.prePayment.prePaymentItem[itemIndex].amount = inputValue;
        }
        break;
      case PAYMENT_SECTION.DEBIT_NOTE:
        if (newState.debitNote.debitItem) {
          newState.debitNote.debitItem[itemIndex].amount = inputValue;
        }
        break;
      default:
        break;
    }
    const totalAdditionalAmount = calculateAdditionalCharge();
    newState.summaryDetails.additionalPaymentAmount = totalAdditionalAmount;
    setPaymentPopupState({ ...newState });
  };

  const getValueForDkInput = (value: any) => {
    if (!Utility.isEmpty(value)) {
      let index1 = value.indexOf('(');
      let index2 = value.indexOf(')');
      let obj1 = value.substring(0, index1) || value;
      return obj1;
    }
    return '';
  };

  const getOptionForPaymentType = (value: any[]) => {
    switch (paymentPopupState.paymentDetails.paymentAccountGroup) {
      case "Current Assets":
        return [...value, {
          label: 'Cash',
          value: 'CASH'
        }];
      case "Bank":
        return [...value, {
          label: 'ACH',
          value: 'ACH'
        }];
      default:
        return value;
    }
  };

  const updateGridData = (val: any, key: any) => {
    let tmpData = { ...gridObject };
    let data: any = { ...paymentPopupState };
    if (!val['rowData']['amount']) {
      val['rowData']['amount'] = 0;
    }
    if (
      parseFloat(val['rowData']['amount']) >
      parseFloat(val['rowData']['dueAmount'])
    ) {
      tmpData[key][val['rowIndex']]['amount'] = val['rowData']['dueAmount'];
      setGridObject(tmpData);
    } else {
      let invalidData: any = { ...tmpData[key][val['rowIndex']] };
      invalidData['invalidFields'] = [];
      tmpData[key][val['rowIndex']] = invalidData;
      setGridObject(tmpData);
    }

    switch (key) {
      case PAYMENT_SECTION.ADVANCED_PAYMENT:
        data[key]['advancedPaymentItem'][val['rowIndex']]['amount'] =
          parseFloat(val['rowData']['amount']);
        break;
      case PAYMENT_SECTION.CREDIT_NOTE:
        data[key]['creditItem'][val['rowIndex']]['amount'] = parseFloat(
          val['rowData']['amount']
        );
        break;
      case PAYMENT_SECTION.PRE_PAYMENT:
        data[key]['prePaymentItem'][val['rowIndex']]['amount'] = parseFloat(
          val['rowData']['amount']
        );
        break;
      case PAYMENT_SECTION.DEBIT_NOTE:
        data[key]['debitItem'][val['rowIndex']]['amount'] = parseFloat(
          val['rowData']['amount']
        );
        break;
      default:
        break;
    }
    setPaymentPopupState((prevValue: any) => {
      return { ...prevValue, ...data };
    });
    // inputChanged(parseFloat(val['rowData']['offsetAmount']), val['rowIndex']);
  };

  const updateAdvancedPayment = (val: any) => {
    updateGridData(val, PAYMENT_SECTION.ADVANCED_PAYMENT);
  };

  const updatePrepayment = (val: any) => {
    updateGridData(val, PAYMENT_SECTION.PRE_PAYMENT);
  };
  const updateCreditNote = (val: any) => {
    updateGridData(val, PAYMENT_SECTION.CREDIT_NOTE);
  };

  const updateDebitNote = (val: any) => {
    updateGridData(val, PAYMENT_SECTION.DEBIT_NOTE);
  };

  const getRemainingAmount = () => {
    let _actualAmountToBePaid: any = getRemainingFormatAmount(
      paymentPopupState.summaryDetails.actualAmountToBePaid
    );

    let _paymentAmount: any = getRemainingFormatAmount(
      paymentPopupState.paymentDetails.paymentAmount.value
    );

    let _remainingAmount: number =
      Utility.roundOffToTenantDecimalScale(_actualAmountToBePaid) -
      Utility.roundOffToTenantDecimalScale(calculateAdditionalCharge()) -
      (showPaymentDetails
        ? Utility.roundOffToTenantDecimalScale(_paymentAmount)
        : 0);

    return Utility.roundOffToTenantDecimalScale(_remainingAmount);
  };

  const getFormatAmount = (val: any, isDocumentCurrency: boolean = false) => {
    return `${Utility.getCurrencySymbolFromCode(
      isDocumentCurrency
        ? documentDetails.currency
        : paymentPopupState.paymentInfo.selectedCurrency.currencyCode
    )} ${NumberFormatService.getNumber(Number(val))}`;
  };
  const getRemainingFormatAmount = (val: any) => {
    return Utility.roundOffToTenantDecimalScale(Number(val));
  };

  const getPaidAmount = () => {
    let amount = Number(paymentPopupState.paymentDetails.paymentAmount.value);
    return showPaymentDetails ? amount : 0;
  };

  const getFooter = () => {
    return (
      <div className="column parent-width align-items-end  mt-s">
        {getFooterRow(
          'Due Amount',
          getFormatAmount(
            Number(paymentPopupState.summaryDetails.actualAmountToBePaid)
          )
        )}
        {getFooterRow('Paid Amount', getFormatAmount(getPaidAmount()))}
        {getFooterRow(
          'Knock Off Amount',
          getFormatAmount(calculateAdditionalCharge())
        )}
        {!props.auditLogView &&
          getFooterRow(
            'Remaining Amount',
            getFormatAmount(getRemainingAmount()),
            true
          )}
      </div>
    );
  };
  const getFooterRow = (title: any, value: any, isBig = false) => {
    return (
      <div className="row width-auto mb-m">
        <DKLabel text={title} className={isBig ? 'fs-l' : ''} />
        <DKLabel
          text={value}
          className={isBig ? 'fs-l fw-b ml-xl' : 'fw-m ml-xl'}
        />
      </div>
    );
  };

  const showCustomFieldView = () => {
    return (
      <div className="column parent-width mt-l">
        <DKLabel text="Custom Fields" className="fw-m" />
        <CustomFieldsHolder
          moduleName={module}
          customFieldsList={props.documentDetails.customField}
          columnConfig={columnConfig}
          columnConfigTableId={columnConfigTableId ? columnConfigTableId : ''}
          onUpdate={(list: any) => {
            setCustomField([...list]);
          }}
        />
      </div>
    );
  };
  const onWHTApply = () => {
    const { paymentDetails, isWHTApplicable } = paymentPopupState;
    const { totalAmount } = props.documentDetails;

    if (
      !isWHTApplicable &&
      Number(paymentDetails.paymentAmount?.value) < Number(totalAmount)
    ) {
      showAlert(
        'Partial Payments',
        'WHT not can not be applied on partial payment'
      );
      return;
    }
    setPaymentPopupState((prev) => {
      return {
        ...prev,
        isWHTApplicable: !prev.isWHTApplicable
      };
    });
  };

  const getWHTCheck = () => {
    return (
      <div className="row p-h-xxl mt-l">
        <DKCheckMark
          title="Is WHT applicable?"
          className="mr-s"
          isSelected={paymentPopupState.isWHTApplicable}
          onClick={onWHTApply}
        />
      </div>
    );
  };
  return (
    <div className="p-4">
      <div className="flex flex-row justify-between">{getBasicDetails()}</div>
      <div>{showCustomFieldView()}</div>
      <div className="mb-6">{getCurrencyConvertionDetails()}</div>
      <div className="">{getPaymentDetails()}</div>
      {tenantDetails?.country === COUNTRY_CODES.IL &&
        !!contactDetails?.whtApplicableIsrael &&
        getWHTCheck()}
      {showPaymentDetails && (
        <div className="">
          {tenantDetails.country === COUNTRY_CODES.IN &&
            props.documentType !== DOC_TYPE.BILL &&
            getTDSDetails()}
        </div>
      )}
      {props.documentType === DOC_TYPE.INVOICE &&
        gridObject[PAYMENT_SECTION.ADVANCED_PAYMENT] &&
        paymentPopupState.advancedPayment.advancedPaymentItem && (
          <>
            <div className="column mt-l parent-width">
              <div className="row width-auto">
                <DKIcon src={DKIcons.ic_paymentRecord} />
                <DKLabel text="Advanced Payment" className="ml-r fw-m mr-r" />
                <Toggle
                  className="box-content"
                  isOn={showAdvancedPayment}
                  onChange={(checked: any) => {
                    setShowAdvancedPayment(!showAdvancedPayment);
                  }}
                />
              </div>
              {showAdvancedPayment && (
                <DKDataGrid
                  width={763}
                  needShadow={false}
                  needBorder={true}
                  needColumnIcons={false}
                  needTrailingColumn={false}
                  allowColumnSort={false}
                  allowColumnAdd={false}
                  allowColumnEdit={false}
                  columns={localConfig}
                  rows={gridObject[PAYMENT_SECTION.ADVANCED_PAYMENT]}
                  allowRowEdit={true}
                  onRowUpdate={updateAdvancedPayment}
                  currentPage={1}
                  totalPageCount={1}
                  title={''}
                  allowBulkOperation={false}
                />
              )}
            </div>
          </>
        )}

      {props.documentType === DOC_TYPE.BILL &&
        gridObject[PAYMENT_SECTION.PRE_PAYMENT] &&
        paymentPopupState.prePayment.prePaymentItem && (
          <>
            <div className="column mt-l parent-width">
              <div className="row width-auto">
                <DKIcon src={DKIcons.ic_paymentRecord} />
                <DKLabel text="Pre Payment" className="ml-r fw-m mr-r" />
                <Toggle
                  className="box-content"
                  isOn={showPrePayment}
                  onChange={(checked: any) =>
                    setShowPrePayment(!showPrePayment)
                  }
                />
              </div>
              {showPrePayment && (
                <DKDataGrid
                  width={763}
                  needShadow={false}
                  needBorder={true}
                  needColumnIcons={false}
                  needTrailingColumn={false}
                  allowColumnSort={false}
                  allowColumnAdd={false}
                  allowColumnEdit={false}
                  columns={localConfig}
                  rows={gridObject[PAYMENT_SECTION.PRE_PAYMENT]}
                  allowRowEdit={true}
                  onRowUpdate={updatePrepayment}
                  currentPage={1}
                  totalPageCount={1}
                  title={''}
                  allowBulkOperation={false}
                />
              )}
            </div>
          </>
        )}
      {props.documentType === DOC_TYPE.INVOICE &&
        gridObject[PAYMENT_SECTION.CREDIT_NOTE] &&
        paymentPopupState.creditNote.creditItem &&
        GranularPermissionsHelper.hasPermissionFor(
          PERMISSIONS_BY_MODULE.CREDIT_NOTES.APPLY_INV_BILL
        ) && (
          <>
            <div className="column mt-l parent-width">
              <div className="row width-auto">
                <DKIcon src={DKIcons.ic_paymentRecord} />
                <DKLabel text="Credit Note" className="ml-r fw-m mr-r" />
                <Toggle
                  className="box-content"
                  isOn={showCreditNote}
                  onChange={() => setShowCreditNote(!showCreditNote)}
                />
              </div>
              {showCreditNote && (
                <DKDataGrid
                  width={763}
                  needShadow={false}
                  needBorder={true}
                  needColumnIcons={false}
                  needTrailingColumn={false}
                  allowColumnSort={false}
                  allowColumnAdd={false}
                  allowColumnEdit={false}
                  columns={localConfig}
                  rows={gridObject[PAYMENT_SECTION.CREDIT_NOTE]}
                  allowRowEdit={true}
                  onRowUpdate={updateCreditNote}
                  currentPage={1}
                  totalPageCount={1}
                  title={''}
                  allowBulkOperation={false}
                />
              )}
            </div>
          </>
        )}
      {props.documentType === DOC_TYPE.BILL &&
        gridObject[PAYMENT_SECTION.DEBIT_NOTE] &&
        paymentPopupState.debitNote.debitItem && (
          <>
            <div className="column mt-l parent-width">
              <div className="row width-auto">
                <DKIcon src={DKIcons.ic_paymentRecord} />
                <DKLabel text="Debit Note" className="ml-r fw-m mr-r" />
                <Toggle
                  className="box-content"
                  isOn={showDebitNote}
                  onChange={(checked: any) => setShowDebitNote(!showDebitNote)}
                />
              </div>
              {showDebitNote && (
                <DKDataGrid
                  width={763}
                  needShadow={false}
                  needBorder={true}
                  needColumnIcons={false}
                  needTrailingColumn={false}
                  allowColumnSort={false}
                  allowColumnAdd={false}
                  allowColumnEdit={false}
                  columns={localConfig}
                  rows={gridObject[PAYMENT_SECTION.DEBIT_NOTE]}
                  allowRowEdit={true}
                  onRowUpdate={updateDebitNote}
                  currentPage={1}
                  totalPageCount={1}
                  title={''}
                  allowBulkOperation={false}
                />
              )}
            </div>
          </>
        )}
      <div>{getFooter()}</div>
    </div>
  );
};

export default Payment;
