import { removeLoader, showAlert, showLoader } from 'deskera-ui-library';
import {
  APPROVAL_STATUS,
  BOOKS_DATE_FORMAT,
  DOC_TYPE,
  DOCUMENT_MODE,
  FULFILLMENT_STATUS,
  FULFILLMENT_TYPE,
  LABELS,
  PAYMENT_STATUS,
  PRODUCT_TYPE,
  REGEX,
  STATUS_TYPE,
  TRACKING_TYPE
} from '../../../../Constants/Constant';
import RouteManager, { PAGE_ROUTES } from '../../../../Managers/RouteManager';
import { AnyDocument, DraftTypes, IDocument } from '../../../../Models/Drafts';
import {
  deleteDrafts,
  fetchDrafts
} from '../../../../Redux/Slices/DraftsSlice';
import { fetchQuotes } from '../../../../Redux/Slices/QuotesSlice';
import { Store } from '../../../../Redux/Store';
import AuthService from '../../../../Services/Auth';
import DraftService from '../../../../Services/Drafts';
import InvoiceService from '../../../../Services/Invoice';
import NumberFormatService from '../../../../Services/NumberFormat';
import {
  cascadingDiscountsInvalidMessage,
  checkCreditLimit,
  checkGSTINPresentForSelectedContact,
  checkIfTotalDiscountInvalid,
  customFieldsContainsErrors,
  getDocumentAlert,
  handleReservedQuantityDataObject,
  inactiveContactMessage,
  isDocContactInactive,
  rebuildCascadingDiscountsForSaving,
  removeUnwantedPayloadKeysForDocument,
  showAlertOnDocAPIError,
  updateAddressAsPerLocationCF
} from '../../../../SharedComponents/DocumentForm/NewDocumentHelper';
import Utility, { deepClone } from '../../../../Utility/Utility';
import {
  DOC_SAVE_OPTION,
  setCanValidateDocument,
  setIsErrorOnSaveDocument,
  setIsSavingDocument
} from '../View/ActionBarHelper';
import { DocSaver } from './DocSaver';
import {
  onDocumentClose,
  updateMultipleKeysInDocument
} from '../../../../Redux/Slices/DocumentSlice';
import DateFormatService from '../../../../Services/DateFormat';
import { CREDIT_LIMIT_TYPE } from '../../../../Constants/Enum';
import { fetchInvoices } from '../../../../Redux/Slices/InvoicesSlice';
import { documentUpdated } from '../../../../Redux/Slices/CommonDataSlice';
import RateAnalysisService from '../../../../Services/RateAnalysis';
import { fetchSalesOrders } from '../../../../Redux/Slices/SalesOrderSlice';
import DashboardService from '../../../../Services/Dashboard';
import {
  getAgedReceivable,
  getPNL,
  getSellDashboard
} from '../../../../Redux/Slices/DashboardSlice';
import { PaymentItemDto } from '../../../../Models/PaymentPopup';
import PaymentService from '../../../../Services/Payment';
import { localizedText } from '../../../../Services/Localization/Localization';
import QuotationService from '../../../../Services/Quotation';
import SalesOrderService from '../../../../Services/SalesOrder';
import ProductService from '../../../../Services/Product';
import FulfillmentService from '../../../../Services/FulfillmentService';
import { FulfillmentPayload } from '../../../../Models/Fulfillment';

export class InvoiceSaver extends DocSaver {
  draftId: number;
  documentMode: DOCUMENT_MODE;
  tenantInfo: any;
  docToSave: AnyDocument;
  saveOption: DOC_SAVE_OPTION;
  draftType: DraftTypes;
  isCashInvoice: boolean;
  replaceURLCallback: (() => void) | undefined;
  openFulfillmentPopup: ((payload: any) => void) | undefined;

  private docForFulfillment: any = null;
  private quotationItems: any[] = [];
  private salesOrderItems: any[] = [];

  constructor(data: {
    draftToSave: IDocument<AnyDocument>;
    isCashInvoice: boolean;
    documentMode: DOCUMENT_MODE;
    saveOption: DOC_SAVE_OPTION;
    tenantInfo: any;
  }) {
    super(data);
    this.draftId = data.draftToSave.id;
    this.draftType = data.draftToSave.draftType;
    this.documentMode = data.documentMode;
    this.isCashInvoice = data.isCashInvoice;
    this.saveOption = data.saveOption;
    this.tenantInfo = data.tenantInfo;
    this.docToSave = this.updatedDoc({ ...data.draftToSave?.populateFormData });
  }

  private updatedDoc = (doc: AnyDocument) => {
    if (this.tenantInfo.additionalSettings?.CASCADING_DISCOUNTS?.enable) {
      doc = {
        ...doc,
        items: rebuildCascadingDiscountsForSaving(doc)
      };
    }

    const extraKeys = {
      salesInvoiceItems: doc.items,
      salesInvoiceDueDate: doc.validTillDate,
      salesInvoiceDate: doc.documentDate,
      shipByDate: doc.fulfillmentDate,
      priceListId: doc?.priceListId,
      priceListName: doc?.priceListName,
      documentType: DOC_TYPE.INVOICE,
      currency: doc.currency,
      currencyCode: doc.currencyCode,
      entityId: this.documentMode === DOCUMENT_MODE.EDIT ? doc?.id : undefined,
      documentCode:
        this.documentMode === DOCUMENT_MODE.EDIT
          ? doc?.salesInvoiceCode
          : undefined,
      totalAmount: doc.totalAmount
    };

    doc = { ...doc, ...extraKeys };

    return doc;
  };

  private sendTriggerOnApproval(payload: AnyDocument) {
    let emails = Utility.getApproverEmail(payload);
    let sum =
      payload &&
      payload.salesInvoiceItems
        .map((item: any) => item.totalAmount)
        .reduce((prev: any, curr: any) => prev + curr, 0);
    let payloadObj: any = {
      contactCode: payload.contactCode,
      totalAmount: NumberFormatService.getNumber(sum),
      userName: AuthService.getUserName(),
      currency: Utility.getCurrencySymbolFromCode(payload.currency),
      approverMap: Object.fromEntries(emails),
      currentLevel: 1,
      approvalHistory: payload['multiApprovalDetails']?.approvalHistory || [],
      draftCode: payload?.draftDocumentSequenceCode
        ? payload?.draftDocumentSequenceCode
        : '',
      contactName: payload?.contact?.name ? payload?.contact?.name : '',
      contactDocCode: payload?.contact?.documentSequenceCode
        ? payload?.contact?.documentSequenceCode
        : '',
      linkedDocument: !Utility.isEmpty(payload.linkedDocuments)
        ? payload?.linkedDocuments?.[0]?.documentType.replace('_', ' ')
        : '',
      linkedDocumentCode: !Utility.isEmpty(payload.linkedDocuments)
        ? payload?.linkedDocuments?.[0]?.documentSequenceCode
        : ''
    };
    InvoiceService.sendTriggerOnApproval(payloadObj).then(
      (response: any) => {},
      (err: any) => {
        console.error('Error while creating draft: ', err);
      }
    );
  }

  async saveAsDraft(approvalRequired?: boolean) {
    try {
      const approvalData = await super.updatePayloadWithApprovalData(
        this.docToSave,
        approvalRequired
      );
      const isApprovalRequired = approvalData?.isApprovalRequired;
      this.docToSave = approvalData?.payload || this.docToSave;
      this.docToSave['dueAmount'] = 0;
      // here we explicitly remove few keys, which is not needed to be saved in component list feature
      this.docToSave = removeUnwantedPayloadKeysForDocument(this.docToSave);

      if (
        isApprovalRequired &&
        !Utility.isEmpty(this.docToSave) &&
        (!this.draftToSave?.isSaved || this.documentMode === DOCUMENT_MODE.COPY)
      ) {
        let draftsData: any;
        if (this.docToSave.isConverting === true) {
          draftsData = {
            data: {
              type: LABELS.INVOICES,
              tableId: this.draftTableId,
              columnConfig: this.draftColumnConfig
            }
          };
        } else {
          draftsData = { ...this.draftToSave, draftType: DraftTypes.NEW };
        }
        try {
          const newRecord = await DraftService.createRecord(
            this.docToSave,
            draftsData
          );
          if (!Utility.isEmpty(newRecord)) {
            let triggerPayload = {
              ...this.docToSave,
              draftDocumentSequenceCode: newRecord?.data?.cells
                ?.documentSequenceCode
                ? newRecord?.data?.cells?.documentSequenceCode
                : ''
            };
            this.sendTriggerOnApproval(triggerPayload);
          }
          Store.dispatch(
            fetchDrafts({
              tableId: this.draftTableId,
              isSaveColumnId: this.isSaveColumnId,
              draftTypeColId: this.draftTypeColId,
              draftTypeColValue: LABELS.INVOICES
            })
          );
          if (!!this.docToSave.isConverting) {
            Store.dispatch(fetchQuotes());
            const buttons = [
              {
                title: 'Ok',
                className: 'bg-button, border-m',
                onClick: () => {}
              },
              {
                title: 'Goto Invoices',
                className: ' bg-blue text-white ml-r',
                onClick: () => {
                  RouteManager.navigateToPage(PAGE_ROUTES.INVOICES);
                }
              }
            ];
            showAlert(
              'Invoice created!',
              'Document has been created successfully.',
              buttons
            );
          }
          setIsSavingDocument(this.draftId, false);
          Store.dispatch(onDocumentClose({ draftId: this.draftToSave?.id }));
          this.replaceURLCallback?.();
        } catch (err: any) {
          setIsSavingDocument(this.draftId, false);
          this.replaceURLCallback?.();
          console.error('InvoiceSaver -> Error creating draft record: ', err);
        }
      } else {
        await super.saveDraft(this.docToSave);
        if (isApprovalRequired) {
          this.sendTriggerOnApproval(this.docToSave);
        }
        this.replaceURLCallback?.();
      }
    } catch (err: any) {
      console.error('Error while saving draft (Invoice -> saveAsDraft): ', err);
    }
  }

  /**
   * Handles create and update invoice calls
   */
  save(closeOnUpdate = true) {
    if (this.draftType === DraftTypes.UPDATE) {
      this.updateInvoice(closeOnUpdate);
    } else {
      this.createInvoice();
    }
  }

  // Create invoice
  async createInvoice(closeOnCreate = true) {
    setIsSavingDocument(this.draftId, true);
    setCanValidateDocument(this.draftId, true);

    let payload: any = deepClone(this.docToSave);
    payload = updateAddressAsPerLocationCF(payload);
    if (this.tenantInfo?.additionalSettings?.CASCADING_DISCOUNTS?.enable) {
      payload = {
        ...payload,
        salesInvoiceItems: rebuildCascadingDiscountsForSaving(payload)
      };
    }
    delete payload?.items;
    delete payload['sourceDocTypeForConversion'];

    if (
      (this.documentMode === DOCUMENT_MODE.COPY ||
        this.documentMode === DOCUMENT_MODE.NEW) &&
      (payload?.duplicate || payload?.isConverting)
    ) {
      const isContactInactive = isDocContactInactive(payload?.contact);
      if (isContactInactive) {
        showAlert('Error', inactiveContactMessage);
        setIsSavingDocument(this.draftId, false);
        return;
      }
    }

    if (payload.attachmentIds?.length) {
      payload.attachments = payload.attachmentIds.map(
        (attachmentId: any) => `${attachmentId}`
      );
    }

    if (payload.reservedStock) {
      const isTrackedOrBOM = payload.salesInvoiceItems.some(
        (obj: any) =>
          obj.product.type === PRODUCT_TYPE.TRACKED ||
          obj.product.type === PRODUCT_TYPE.BILL_OF_MATERIALS
      );
      if (!isTrackedOrBOM) {
        payload = {
          ...payload,
          reservedStock: false
        };
      } else {
        payload = handleReservedQuantityDataObject(
          payload,
          'salesInvoiceItems'
        );
      }
    }

    if (!this.isDocValid(payload)) {
      setIsSavingDocument(this.draftId, false);
      return;
    }
    let isApproval = await Utility.isApprovalRequired(payload);
    if (isApproval === null) {
      setIsSavingDocument(this.draftId, false);
      return;
    } else if (isApproval) {
      this.saveAsDraft(isApproval);
      return;
    } else {
      payload['approvalStatus'] = APPROVAL_STATUS['NOT_REQUIRED'];
    }

    if (this.draftType === DraftTypes.DRAFT) {
      payload[
        'draftReferenceId'
      ] = `${this.draftTableId}/record/${this.draftId}`;
    }

    const processedCreditLimitObj = await checkCreditLimit(payload);
    if (
      processedCreditLimitObj &&
      processedCreditLimitObj.showAlertPopup &&
      processedCreditLimitObj.settings.creditLimitType !==
        CREDIT_LIMIT_TYPE.IGNORE
    ) {
      this.showCreditLimitAlert(
        processedCreditLimitObj.settings,
        payload,
        false,
        true,
        closeOnCreate,
        this.isCashInvoice
      );
    } else {
      this.makeCreateAPICall(payload, closeOnCreate, this.isCashInvoice);
    }
  }

  private makeCreateAPICall(
    payload: any,
    closeDoc: boolean,
    processDoc: boolean
  ) {
    payload = removeUnwantedPayloadKeysForDocument(payload);
    InvoiceService.createInvoice(payload).then(
      (response: any) => {
        if (this.draftToSave) {
          if (closeDoc && !processDoc) {
            // Remove draft popup
            Store.dispatch(onDocumentClose({ draftId: this.draftId }));
          }

          if (this.draftType === DraftTypes.DRAFT) {
            Store.dispatch(
              deleteDrafts({
                recordId: this.draftId,
                tableId: this.draftTableId
              })
            );
            RateAnalysisService.updateRateAnalysis({
              documentCode: response.salesInvoiceCode,
              documentType: 'SALES_INVOICE',
              productPriceMethod:
                this.tenantInfo?.additionalSettings?.RATE_ANALYSIS
                  ?.PRODUCT_PRICE_METHOD,
              isDraft: this.draftType === DraftTypes.DRAFT ? true : false,
              draftId: this.draftId,
              tableId: this.draftTableId
            })
              .then(() => {})
              .catch((err: any) => {
                console.error('Error updating rate analysis: ', err);
              });
          }
          this.updateForm(response, false, true, processDoc);
        } else {
          setIsSavingDocument(this.draftId, false);
        }
        Store.dispatch(
          fetchDrafts({
            tableId: this.draftTableId,
            isSaveColumnId: this.isSaveColumnId,
            draftTypeColId: this.draftTypeColId,
            draftTypeColValue: LABELS.INVOICES
          })
        );
        Store.dispatch(fetchInvoices());
        if (payload.isPartialInvoice) {
          if (payload.linkedDocuments?.[0]?.documentType === DOC_TYPE.QUOTE) {
            Store.dispatch(fetchQuotes());
          }
          if (
            payload.linkedDocuments?.[0]?.documentType === DOC_TYPE.SALES_ORDER
          ) {
            Store.dispatch(fetchSalesOrders());
          }
        }
        this.replaceURLCallback?.();

        // Update dashboard data
        const SellDashboardConfig =
          Store.getState()?.dashboards?.sellDashboardFilter;
        const AgedReceivableDashboardConfig =
          Store.getState()?.dashboards?.agedReceivableDashboardFilter;
        const pnlDashboardConfig =
          Store.getState()?.dashboards?.pnlDashboardFilter;
        DashboardService.apiConfig = SellDashboardConfig.config;
        Store.dispatch(getSellDashboard());
        DashboardService.apiConfig = AgedReceivableDashboardConfig.config;
        Store.dispatch(getAgedReceivable());
        DashboardService.apiConfig = pnlDashboardConfig.config;
        Store.dispatch(getPNL());
      },
      (err) => {
        console.error('Error while creating invoice: ', err);
        setIsSavingDocument(this.draftId, false);
        if (err?.data?.errorMessage) {
          let error = 'Fulfillment can not be done due to insufficient stock.';
          if (err?.data?.errorMessage === error) {
            var newError = deepClone(err);
            if (newError) {
              if (newError.data) {
                newError.data.errorMessage =
                  'Invoice is created, but fulfillment can not be done due to insufficient stock. Please go to invoice section to fulfil it.';
              }
            }
            showAlertOnDocAPIError(newError);
            setIsErrorOnSaveDocument(this.draftId, true);
            Store.dispatch(onDocumentClose({ draftId: this.draftId }));
          } else {
            showAlertOnDocAPIError(err);
            setIsErrorOnSaveDocument(this.draftId, true);
            Store.dispatch(onDocumentClose({ draftId: this.draftId }));
          }
        }
        this.replaceURLCallback?.();
      }
    );
  }

  /* Cash Invoice auto payment and fulfillment blocks */

  private paymentAlertBtns(formData: any) {
    return [
      {
        title: 'Cancel',
        className: 'border-m mr-r',
        onClick: () => {
          Store.dispatch(onDocumentClose({ draftId: this.draftId }));
        }
      },
      {
        title: 'Continue',
        className: 'bg-button text-white',
        onClick: () => {
          this.initiateFulfillment(formData);
        }
      }
    ];
  }

  private fulfillmentAlertBtns = [
    {
      title: 'Cancel',
      className: 'border-m mr-r',
      onClick: () => {
        Store.dispatch(onDocumentClose({ draftId: this.draftId }));
        Store.dispatch(fetchInvoices());
        this.updateForm(this.docForFulfillment);
      }
    },
    {
      title: `Create ${localizedText('Fulfillment')}`,
      className: 'bg-button text-white',
      onClick: () => {
        this.openFulfillmentPopup?.(this.docForFulfillment);
      }
    }
  ];

  // Auto receive payment for cash invoice
  private receivePayment = (formData: any) => {
    showLoader('Receiving payment...');
    let tdsAccountCode = '';
    if (!Utility.isEmpty(formData.accountGroupForPayment)) {
      const accountList = Store.getState()?.accountsInfo?.data;
      // TDS account details
      if (accountList?.content?.length > 0) {
        const tdsAccount = accountList.content?.find(
          (acc: any) =>
            acc.status === STATUS_TYPE.ACTIVE && acc.name === 'TDS Payable'
        );
        if (!Utility.isEmpty(tdsAccount)) {
          tdsAccountCode = tdsAccount.code;
        }
      }

      const receivePaymentItem: PaymentItemDto = {
        customerOrderNumber: '',
        documentCode: formData.salesInvoiceCode,
        documentType: formData.documentType,
        exchangeRate: 1,
        paymentAmount: Utility.roundOffToTenantDecimalScale(
          +formData.amountToReceiveOrPay
        ),
        documentSequenceCode: formData.documentSequenceCode
      };

      const formattedDate = DateFormatService.getFormattedDateString(
        formData.salesInvoiceDate,
        BOOKS_DATE_FORMAT['DD-MM-YYYY'],
        BOOKS_DATE_FORMAT['YYYY-MM-DD']
      );

      let paymentPayload: any = {
        accountCodePayTo: formData?.accountGroupForPayment?.value,
        accountName: formData?.accountGroupForPayment?.label,
        amount: Utility.roundOffToTenantDecimalScale(
          +formData.amountToReceiveOrPay
        ),
        closeDate: formattedDate,
        contactCode: formData.contactCode,
        contactName: formData.contact ? formData.contact.name : '',
        currency: formData.currency,
        documentDate: formattedDate,
        exchangeRate: 1 / formData.exchangeRate,
        isUnDepositedPayment:
          formData?.accountGroupForPayment?.isUndepositedFundAccount,
        paymentType:
          formData?.accountGroupForPayment?.label === 'Cash'
            ? 'CASH'
            : formData.paymentType,
        receivePaymentItemDtoList: [receivePaymentItem],
        referenceDate: formattedDate,
        referenceNumber: '',
        tdsAccountCode: tdsAccountCode,
        tdsAmount: 0,
        whtApplicable: false,
        whtRate: 0
      };

      PaymentService.receivePayment(paymentPayload).then(
        (res: any) => {
          // Initiate fulfillment
          this.initiateFulfillment(formData);
        },
        (err: any) => {
          console.error('Error receiving payment: ', err);
          removeLoader();
          showAlert(
            'Error!',
            `Error while receiving payment. Do you want to continue with creating ${localizedText(
              'Fulfillment'
            )}?`,
            this.paymentAlertBtns(formData)
          );
        }
      );
    } else {
      removeLoader();
      showAlert(
        'No account selected!',
        `No account has been selected for receiving payment. Do you want to continue with creating ${localizedText(
          'Fulfillment'
        )}?`,
        this.paymentAlertBtns(formData)
      );
    }
  };

  // Initiate auto fulfillment flow for cash invoice
  private initiateFulfillment(formData: any) {
    formData = {
      ...formData,
      documentCode: formData.salesInvoiceCode
    };
    delete formData.sequenceFormat;

    this.docForFulfillment = { ...formData };

    showLoader(`Creating ${localizedText('Fulfillment')}...`);
    if (formData.fulfillmentStatus !== FULFILLMENT_STATUS.FULLY_FULFILLED) {
      this.checkLinkedDocsAndLoadInventoryDetails(formData);
    } else {
      Store.dispatch(onDocumentClose({ draftId: this.draftId }));
      removeLoader();
      Store.dispatch(fetchInvoices());
    }
  }

  // Get linked documents info from Invoice and continue auto fulfillment flow
  private checkLinkedDocsAndLoadInventoryDetails(doc: any) {
    let ids = doc.salesInvoiceItems?.map((item: any) => item.productCode);

    const partialInvoiceFlag =
      doc.isPartialInvoice || doc.hasPartialInvoice || false;
    if (partialInvoiceFlag) {
      if (doc?.linkedDocuments?.[0]?.documentType === DOC_TYPE.QUOTE) {
        const quotationDocumentCode = doc.linkedDocuments[0].documentCode;

        QuotationService.getQuoteByCode(quotationDocumentCode).then(
          (data: any) => {
            this.quotationItems = data['quotationItemDtoList'];
            this.loadProductInventoryById(ids);
          },
          (err: any) => {
            removeLoader();
            showAlert(
              'Error!',
              `Error while loading quote details. Please try again.`,
              this.fulfillmentAlertBtns
            );
            console.error('Error loading linked quote details: ', err);
          }
        );
      } else if (
        doc?.linkedDocuments?.[0]?.documentType === DOC_TYPE.SALES_ORDER
      ) {
        const salesOrderDocumentCode = doc.linkedDocuments[0].documentCode;

        SalesOrderService.getSalesOrderByCode(salesOrderDocumentCode).then(
          (data: any) => {
            this.salesOrderItems = data['salesOrderItems'];
            this.loadProductInventoryById(ids);
          },
          (err: any) => {
            removeLoader();
            showAlert(
              'Error!',
              `Error while loading linked sales order details. Please try again.`,
              this.fulfillmentAlertBtns
            );
            console.error('Error loading linked SO details: ', err);
          }
        );
      }
    } else {
      this.loadProductInventoryById(ids);
    }
  }

  private async loadProductInventoryById(ids: any[]) {
    try {
      const warehouseProductsData =
        await ProductService.fetchWarehouseProductsByID(ids);

      if (
        !Utility.isEmpty(this.docForFulfillment) &&
        !Utility.isEmpty(warehouseProductsData)
      ) {
        const warehouseProductsByID: any[] =
          warehouseProductsData?.warehouses || [];
        const defaultWH =
          warehouseProductsByID.find((warehouse: any) => warehouse.primary) ||
          warehouseProductsByID[0];

        if (defaultWH?.code) {
          this.updateInventoryAndFulfill(defaultWH, warehouseProductsByID);
        }
      }
    } catch (err: any) {
      removeLoader();
      showAlert(
        'Error!',
        'Error loading inventory information for products',
        this.fulfillmentAlertBtns
      );
      console.error('Error fetching ProductInventoryByID: ', err);
    }
  }

  // Get default product warehouse
  private getWarehouseForItem(data: any, warehouseProductsByID: any[]) {
    const warehouses = warehouseProductsByID;
    if (!Utility.isEmpty(warehouses)) {
      let itemWarehouse = warehouses.filter(
        (warehouse: any) =>
          warehouse['productAvailableQuantity']?.[data.productCode]
      );
      let primaryWH: any = [];
      if (!itemWarehouse || itemWarehouse.length > 1) {
        primaryWH = warehouses.filter((warehouse: any) => warehouse.primary);
      } else {
        primaryWH = itemWarehouse;
      }
      if (primaryWH.length === 0) {
        primaryWH[0] = warehouses[0];
      }
      return primaryWH[0].code;
    } else {
      const defaultWH =
        warehouseProductsByID.find((warehouse: any) => warehouse.primary) ||
        warehouseProductsByID[0];
      return defaultWH.code;
    }
  }

  // Create fulfillment items from document items and inventory details
  private updateInventoryAndFulfill(
    defaultWH: any,
    warehouseProductsByID: any[]
  ) {
    let filteredProduct = warehouseProductsByID.find(
      (warehouse: any) => warehouse.code === defaultWH.code
    );
    let backOrderItem: any = {};
    let newFulfillmentItems: any[] = [];
    let isBOMItemInsufficient = false;
    let fulfillmentItems = deepClone(this.docForFulfillment?.salesInvoiceItems);
    fulfillmentItems.forEach((item: any, index: number) => {
      const pendingQuantity = Utility.getPendingQuantity(item);
      if (item.type === PRODUCT_TYPE.NON_TRACKED) {
        item.availableProductQuantity = 0;
      } else {
        item.availableProductQuantity =
          (!Utility.isEmpty(filteredProduct?.productAvailableQuantity) &&
            filteredProduct?.productAvailableQuantity[item.productCode]) ||
          item.product.availableQuantity ||
          0;
      }

      if (
        this.docForFulfillment &&
        (this.docForFulfillment.isPartialInvoice ||
          this.docForFulfillment.hasPartialInvoice)
      ) {
        item.quantityToBeFulfilled =
          item.productQuantity - (item.quantityFulfilled || 0);
        item.uomQuantityToBeFulfilled =
          item.uomPendingQuantity - (item.uomQuantityFulfilled || 0);
      }
      item.fulfilledQuantity =
        pendingQuantity > item.availableProductQuantity &&
        item.type !== PRODUCT_TYPE.NON_TRACKED
          ? item.availableProductQuantity
          : pendingQuantity;
      item.pendingQuantity = pendingQuantity;

      if (item.documentUOMSchemaDefinition) {
        item.uomQuantity =
          item.uomQuantity ||
          Utility.getUomQuantity(
            item.productQuantity,
            item.documentUOMSchemaDefinition
          );
        item.uomUnitPrice =
          item.uomUnitPrice ||
          Utility.getUomPrice(item.unitPrice, item.documentUOMSchemaDefinition);
        item.uomAvailableQuantity =
          item.uomAvailableQuantity ||
          Utility.getUomQuantity(
            item.availableQuantity,
            item.documentUOMSchemaDefinition
          );
        item.uomFulfilledQuantity =
          item.uomFulfilledQuantity ||
          Utility.getUomQuantity(
            item.fulfilledQuantity,
            item.documentUOMSchemaDefinition
          );
        item.uomQuantityFulfilled =
          item.uomQuantityFulfilled ||
          Utility.getUomQuantity(
            item.quantityFulfilled,
            item.documentUOMSchemaDefinition
          );
        item.uomPendingQuantity =
          item.uomPendingQuantity ||
          Utility.getUomQuantity(
            item.pendingQuantity,
            item.documentUOMSchemaDefinition
          );
      }
      item.warehouseCode = this.getWarehouseForItem(
        item,
        warehouseProductsByID
      );

      if (
        (item.type === PRODUCT_TYPE.TRACKED ||
          item.type === PRODUCT_TYPE.BILL_OF_MATERIALS) &&
        item.availableQuantity < pendingQuantity
      ) {
        backOrderItem[item.productCode] =
          pendingQuantity - item.availableQuantity;
        if (item.type === PRODUCT_TYPE.BILL_OF_MATERIALS) {
          isBOMItemInsufficient = true;
        }
      }
      newFulfillmentItems.push(item);
    });
    if (
      (!this.tenantInfo?.allowNegativeInventory || isBOMItemInsufficient) &&
      Object.keys(backOrderItem).length > 0
    ) {
      removeLoader();
      showAlert(
        'Insufficient quantity!',
        'One or more products have insufficient quantity. Please try again.',
        this.fulfillmentAlertBtns
      );
      return;
    }
    this.saveFulfillment(newFulfillmentItems, defaultWH, warehouseProductsByID);
  }

  // Update Serial Tracked Item
  private async updateSerialTrackedItem(item: any) {
    let quantityToFulfill = 0;
    let requiredQuantity = 0;
    // Set partialInvoice flag to false
    const availableQuantity = Utility.getAvailableQuantity(item);

    try {
      let wareHouseBatchProduct =
        await ProductService.fetchProductAdvancedTrackingWarehouse(
          item?.productCode,
          false,
          false
        );
      if (Utility.isEmpty(wareHouseBatchProduct)) {
        item.errorMessage =
          'One or more serial tracked products has insufficient stock.';
      }

      if (item.pendingQuantity > availableQuantity) {
        item.errorMessage =
          'One or more serial tracked products has insufficient stock.';
        requiredQuantity = availableQuantity;
      } else {
        requiredQuantity = item.pendingQuantity;
      }

      if (!Number.isInteger(item.productQuantity)) {
        item.errorMessage =
          'One or more serial tracked products have required quantity in decimal.';
      }

      if (item?.documentUOMSchemaDefinition) {
        quantityToFulfill = item?.productQuantity;
      } else {
        quantityToFulfill = requiredQuantity;
      }

      const fulfilledQuantity = item.documentUOMSchemaDefinition
        ? Utility.getUomQuantity(
            parseFloat(quantityToFulfill.toString()),
            item.documentUOMSchemaDefinition
          )
        : quantityToFulfill;
      item = {
        ...item,
        advancedTracking: TRACKING_TYPE.SERIAL,
        isQuickCommit: true,
        quantityFulfilled: quantityToFulfill,
        fulfilledQuantity: quantityToFulfill,
        uomFulfilledQuantity: item.documentUOMSchemaDefinition
          ? fulfilledQuantity
          : item.uomFulfilledQuantity
      };
    } catch (err: any) {
      console.error('Error while fethcing advanced tracking details: ', err);
      item.errorMessage = `Error while fetching advanced tracking details of Product: ${item.name}`;
    }

    return item;
  }

  updateBatchTrackedItem = async (batchTrackedItem: any) => {
    let requiredQuantity = 0;
    let availableQuantity = Utility.getAvailableQuantity(batchTrackedItem);

    if (batchTrackedItem.pendingQuantity > availableQuantity) {
      batchTrackedItem.errorMessage =
        'One or more batch tracked products has insufficient stock.';
      requiredQuantity = availableQuantity;
    } else {
      requiredQuantity = batchTrackedItem.pendingQuantity;
    }

    let wareHouseBatchProduct: any[] = [];
    try {
      wareHouseBatchProduct =
        await ProductService.fetchProductAdvancedTrackingWarehouse(
          batchTrackedItem?.productCode,
          false,
          false
        );
      if (Utility.isEmpty(wareHouseBatchProduct)) {
        // This will happen when the product has not tracking details assigned.
        batchTrackedItem.errorMessage =
          'One or more batch tracked products has insufficient stock.';
      }

      // Auto allocate
      let productAvailableQuantity = wareHouseBatchProduct.reduce(
        (prev: any[], current: any) => {
          let advTracking = current?.advancedTrackingMeta?.map((item: any) => {
            return {
              ...item,
              warehouseName: current.name
            };
          });
          return [...prev, ...advTracking];
        },
        []
      );
      let allocated: any = 0;
      let batchTrackingData: any = [];
      let totalFulfilledQty: any = 0;
      productAvailableQuantity?.every((element: any) => {
        let availableQtyToAssign = batchTrackedItem.documentUOMSchemaDefinition
          ? Utility.getUomQuantity(
              parseFloat(element.batchSize) -
                parseFloat(element.batchSizeFulfilled) -
                parseFloat(element.reservedQuantity),
              batchTrackedItem.documentUOMSchemaDefinition
            )
          : parseFloat(element.batchSize) -
            parseFloat(element.batchSizeFulfilled) -
            parseFloat(element.reservedQuantity);
        let lineItem = {
          qtyToFulfil: 0,
          serialBatchNumber: element?.serialBatchNumber,
          warehouseCode: element?.warehouseCode,
          warehouseName: element?.warehouseName
        };
        if (allocated < requiredQuantity) {
          if (availableQtyToAssign < requiredQuantity - allocated) {
            lineItem.qtyToFulfil = batchTrackedItem?.documentUOMSchemaDefinition
              ? Utility.getUomWarehouseQuantity(
                  availableQtyToAssign,
                  batchTrackedItem.documentUOMSchemaDefinition
                )
              : availableQtyToAssign;
            allocated = allocated + availableQtyToAssign;
            totalFulfilledQty += availableQtyToAssign;
          } else {
            lineItem.qtyToFulfil = batchTrackedItem?.documentUOMSchemaDefinition
              ? Utility.getUomWarehouseQuantity(
                  requiredQuantity - allocated,
                  batchTrackedItem.documentUOMSchemaDefinition
                )
              : requiredQuantity - allocated;
            totalFulfilledQty += requiredQuantity - allocated;
            batchTrackingData.push(lineItem);
            return false; //breaks the loop
          }
        }

        batchTrackingData.push(lineItem);
        return true;
      });

      const fulfilledQuantity = batchTrackedItem.documentUOMSchemaDefinition
        ? Utility.getUomWarehouseQuantity(
            parseFloat(totalFulfilledQty),
            batchTrackedItem.documentUOMSchemaDefinition
          )
        : totalFulfilledQty;

      // Check whether totalFulfilledQty < requiredQuantity
      // This will probably happen in case of reserved stocks.
      if (totalFulfilledQty < requiredQuantity) {
        batchTrackedItem.errorMessage =
          'One or more serial tracked products has insufficient stock.';
      }

      batchTrackedItem = {
        ...batchTrackedItem,
        advancedTracking: TRACKING_TYPE.BATCH,
        advancedTrackingFulfilmentData: batchTrackingData,
        isQuickCommit: false,
        quantityFulfilled: fulfilledQuantity,
        fulfilledQuantity: fulfilledQuantity
      };
      if (batchTrackedItem.documentUOMSchemaDefinition) {
        batchTrackedItem.uomFulfilledQuantity = totalFulfilledQty;
      }
    } catch (err: any) {
      console.error('Error: ', err);
      batchTrackedItem.errorMessage = `Error while fetching advanced tracking details of Product: ${batchTrackedItem.name}`;
    }

    return batchTrackedItem;
  };

  // Create fulfillment payload
  private async fulfillmentPayload(fulfillmentItems: any[], defaultWH: any) {
    const isPartialInvoice =
      this.docForFulfillment?.isPartialInvoice ||
      this.docForFulfillment?.hasPartialInvoice ||
      false;

    fulfillmentItems = await Promise.all(
      fulfillmentItems.map(async (item: any) => {
        // Set fulfilledQuantity to pendingQuantity if negative inventory is ON
        if (this.tenantInfo?.allowNegativeInventory) {
          if (item.documentUOMSchemaDefinition) {
            item.uomFulfilledQuantity = item.pendingQuantity;
          } else {
            item.fulfilledQuantity = item.pendingQuantity;
          }
        }
        if (item?.product?.advancedTracking === TRACKING_TYPE.SERIAL) {
          item = await this.updateSerialTrackedItem(item);
        }

        if (item?.product?.advancedTracking === TRACKING_TYPE.BATCH) {
          item = await this.updateBatchTrackedItem(item);
        }
        return item;
      })
    );

    const updatedFulfillmentItems: any[] = fulfillmentItems.filter(
      (item: any) =>
        (item.pendingQuantity > 0 && item.fulfilledQuantity > 0) ||
        !Utility.isEmpty(item.errorMessage)
    );

    let linkedDocType =
      this.docForFulfillment?.linkedDocuments?.[0]?.documentType;
    let docItems: any[] = [];
    switch (linkedDocType) {
      case DOC_TYPE.QUOTE:
        docItems = this.quotationItems;
        break;
      case DOC_TYPE.SALES_ORDER:
        docItems = this.salesOrderItems;
        break;
    }

    const fulfillmentPayload = {
      ...this.docForFulfillment,
      fulfillmentDate: DateFormatService.getDateStrFromDate(
        new Date(),
        BOOKS_DATE_FORMAT['DD-MM-YYYY']
      ),
      fulfillmentType: FULFILLMENT_TYPE.DEFAULT,
      warehouseCode: defaultWH.code,
      documentCode: this.docForFulfillment.salesInvoiceCode,
      documentType: DOC_TYPE.INVOICE,
      isPartialInvoice: isPartialInvoice,
      isPartialSalesOrder: false,
      reservedStock: this.docForFulfillment.reservedStock,
      linkedPIDocument:
        this.docForFulfillment.linkedDocuments &&
        this.docForFulfillment.fulfillmentType !== FULFILLMENT_TYPE.DROP_SHIP &&
        !this.docForFulfillment.backOrder
          ? this.docForFulfillment.linkedDocuments[0]
          : null,
      fulfillmentItems: updatedFulfillmentItems.map((item: any) => {
        const documentItem = docItems.find(
          (docItem) => docItem.productCode === item.productCode
        );

        return {
          ...item,
          advancedTracking: item.product.advancedTracking,
          uomQuantity: item.pendingQuantity,
          linkedPIItemCode:
            documentItem && linkedDocType === DOC_TYPE.QUOTE
              ? documentItem.quotationItemCode
              : documentItem && linkedDocType === DOC_TYPE.SALES_ORDER
              ? documentItem.salesOrderItemCode
              : null
        };
      })
    };

    // console.log('fulfillmentPayload: ', fulfillmentPayload);
    const payload = new FulfillmentPayload(fulfillmentPayload);
    return payload;
  }

  // Get payload and save fulfillment data
  private async saveFulfillment(
    fulFillmentItems: any[],
    defaultWH: any,
    warehouseProductsByID: any[]
  ) {
    let payload = await this.fulfillmentPayload(fulFillmentItems, defaultWH);

    const itemsWithErrors = payload.fulfillmentItems?.filter(
      (item: any) => !Utility.isEmpty(item.errorMessage)
    );
    if (itemsWithErrors?.length) {
      removeLoader();
      showAlert(
        'Error!',
        itemsWithErrors[0]?.errorMessage,
        this.fulfillmentAlertBtns
      );
      return;
    }

    if (!this.tenantInfo?.allowNegativeInventory) {
      let invalidItems: any = [];
      let invalidItemsProductCodes: any = [];
      let warehouseQtyList = deepClone(warehouseProductsByID);
      payload.fulfillmentItems?.forEach((record: any) => {
        if (
          record.warehouseInventoryData &&
          record.warehouseInventoryData?.length > 0
        ) {
          record.warehouseInventoryData.forEach((ele: any) => {
            warehouseQtyList.forEach((warehouse: any) => {
              if (
                warehouse.code === ele.warehouseCode &&
                warehouse?.productAvailableQuantity?.hasOwnProperty(
                  record.productCode
                )
              ) {
                if (
                  ele.quantity <=
                  warehouse.productAvailableQuantity[record.productCode]
                ) {
                  warehouse.productAvailableQuantity[record.productCode] =
                    warehouse.productAvailableQuantity[record.productCode] -
                    ele.quantity;
                } else {
                  if (!invalidItemsProductCodes.includes(record.productCode)) {
                    invalidItemsProductCodes.push(record.productCode);
                    invalidItems.push(record.productName);
                  }
                }
              }
            });
          });
        }
      });
      if (invalidItems?.length > 0) {
        removeLoader();
        showAlert(
          'Insufficient quantity!',
          invalidItems.toString() +
            ' have insufficient quantity. Please try again.',
          this.fulfillmentAlertBtns
        );
        return;
      }
    }

    FulfillmentService.saveFulfillment(payload)
      .then((res: any) => {
        Store.dispatch(onDocumentClose({ draftId: this.draftId }));
        removeLoader();
        Store.dispatch(fetchInvoices());
        this.updateForm(this.docForFulfillment);
      })
      .catch((err: any) => {
        console.error('Error creating fulfillment: ', err);
        Store.dispatch(fetchInvoices());
        removeLoader();
        showAlert(
          'Error!',
          `Error while creating ${localizedText(
            'Fulfillment'
          )}. Please try again.`,
          this.fulfillmentAlertBtns
        );
      });
  }

  /* Cash Invoice auto payment and fulfillment blocks end*/

  // Update Invoice
  async updateInvoice(closeOnUpdate = true) {
    setIsSavingDocument(this.draftId, true);
    setCanValidateDocument(this.draftId, true);

    let payload: any = deepClone(this.docToSave);
    payload = { ...payload, contact: payload.contactDto };
    payload = updateAddressAsPerLocationCF(payload);

    if (this.tenantInfo?.additionalSettings?.CASCADING_DISCOUNTS?.enable) {
      payload = {
        ...payload,
        salesInvoiceItems: rebuildCascadingDiscountsForSaving(payload)
      };
    }

    if (payload.attachmentIds?.length) {
      payload.attachments = payload.attachmentIds.map(
        (attachmentId: any) => `${attachmentId}`
      );
    }

    const zeroAmountDocument = payload.totalAmount === 0;
    if (zeroAmountDocument) {
      payload.dueAmount = payload.totalAmount;
      payload.totalAmountInBaseCurrency = payload.totalAmount;
      payload.paymentStatus = PAYMENT_STATUS.PENDING;
    }

    if (payload.reservedStock) {
      const isTrackedOrBOM = payload.salesInvoiceItems.some(
        (obj: any) =>
          obj.product.type === PRODUCT_TYPE.TRACKED ||
          obj.product.type === PRODUCT_TYPE.BILL_OF_MATERIALS
      );
      if (!isTrackedOrBOM) {
        payload = {
          ...payload,
          reservedStock: false
        };
      } else {
        payload = handleReservedQuantityDataObject(
          payload,
          'salesInvoiceItems'
        );
      }
    }

    if (!this.isDocValid(payload)) {
      setIsSavingDocument(this.draftId, false);
      return;
    }

    const processedCreditLimitObj = await checkCreditLimit(payload);
    if (
      processedCreditLimitObj &&
      processedCreditLimitObj.showAlertPopup &&
      processedCreditLimitObj.settings.creditLimitType !==
        CREDIT_LIMIT_TYPE.IGNORE
    ) {
      this.showCreditLimitAlert(
        processedCreditLimitObj.settings,
        payload,
        true,
        closeOnUpdate,
        true
      );
    } else {
      this.makeUpdateAPICall(payload, closeOnUpdate);
    }
  }

  /**
   * Initiate update invoice API call
   * @param payload
   * @param closeOnUpdate
   */
  private makeUpdateAPICall(payload: any, closeOnUpdate: boolean) {
    delete payload?.items;
    delete payload?.contactDto;

    payload = removeUnwantedPayloadKeysForDocument(payload);

    InvoiceService.updateInvoice(payload, this.docToSave?.id as number).then(
      (response: any) => {
        if (this.draftToSave) {
          if (closeOnUpdate) {
            Store.dispatch(onDocumentClose({ draftId: this.draftId }));
            this.replaceURLCallback?.();
          }
          setIsSavingDocument(this.draftId, false);
          this.updateForm(response, true, closeOnUpdate);
        }
        Store.dispatch(
          fetchDrafts({
            tableId: this.draftTableId,
            isSaveColumnId: this.isSaveColumnId,
            draftTypeColId: this.draftTypeColId,
            draftTypeColValue: LABELS.INVOICES
          })
        );
        Store.dispatch(fetchInvoices());
        setIsSavingDocument(this.draftId, false);
        this.replaceURLCallback?.();
        if (payload?.paymentMilestoneFlag) {
          Store.dispatch(
            documentUpdated({
              newData: payload,
              oldData: this.draftToSave?.populateFormData
            })
          );
        }
      },
      (err) => {
        console.error('Error while updating invoice: ', err);
        setIsSavingDocument(this.draftId, false);
        showAlertOnDocAPIError(err);
        this.replaceURLCallback?.();
      }
    );
  }

  private updateForm(
    invoiceResp: any,
    isUpdate = false,
    closeOnUpdate = true,
    processDoc = false
  ) {
    InvoiceService.getInvoiceByCode(invoiceResp.salesInvoiceCode).then(
      (doc: any) => {
        const formData = {
          ...doc,
          documentType: DOC_TYPE.INVOICE,
          items: [...doc.salesInvoiceItems].map((savedItem: any) => {
            const itemFromDocToSave = this.docToSave?.items?.find(
              (item: any) => item.lineNumber === savedItem.lineNumber
            );
            if (!Utility.isEmptyObject(itemFromDocToSave)) {
              return {
                ...itemFromDocToSave,
                ...savedItem
              };
            }
            return { ...savedItem };
          }),
          isDocumentTouched: false,
          contact: {
            ...doc.contact,
            ...doc.contactDto
          },
          documentDate: doc.salesInvoiceDate,
          fulfillmentDate: doc.shipByDate,
          validTillDate: doc.salesInvoiceDueDate,
          amountToReceiveOrPay: this.docToSave?.amountToReceiveOrPay,
          paymentType: this.docToSave?.paymentType,
          accountGroupForPayment: this.docToSave?.accountGroupForPayment
        };
        if (!closeOnUpdate) {
          Store.dispatch(
            updateMultipleKeysInDocument({
              draftId: this.draftId,
              keysToUpdate: formData
            })
          );
        } else {
          if (!isUpdate && !processDoc) {
            Store.dispatch(onDocumentClose({ draftId: this.draftId }));
            getDocumentAlert(
              'Invoice created!',
              'Document has been created successfully.',
              formData,
              this.draftToSave,
              DOCUMENT_MODE.EDIT,
              PAGE_ROUTES.INVOICES
            );
            setIsSavingDocument(this.draftId, false);
          }
          if (processDoc) {
            if (doc.paymentStatus !== PAYMENT_STATUS.RECEIVED) {
              // Initiate receive paymant flow
              this.receivePayment(formData);
            } else {
              this.initiateFulfillment(formData);
            }
          }
        }
      },
      (err) => {
        console.error('Error loading updated doc: ', err);
        setIsSavingDocument(this.draftId, false);
      }
    );
  }

  private showCreditLimitAlert(
    creditLimitSettings: any,
    payload: any,
    isUpdate = false,
    closeOnUpdate = false,
    closeOnCreate = false,
    processDoc = false
  ) {
    let buttons = [];

    buttons.push({
      title:
        creditLimitSettings.creditLimitType === CREDIT_LIMIT_TYPE.WARN
          ? 'Cancel'
          : 'Ok',
      className: 'border-m',
      onClick: () => {
        setIsSavingDocument(this.draftId, false);
      }
    });

    if (creditLimitSettings.creditLimitType === CREDIT_LIMIT_TYPE.WARN) {
      buttons.push({
        title: 'Save',
        className: 'bg-blue text-white ml-r',
        onClick: () => {
          if (isUpdate) {
            this.makeUpdateAPICall(payload, closeOnUpdate);
          } else {
            this.makeCreateAPICall(payload, closeOnCreate, processDoc);
          }
        }
      });
    }

    const baseCurrencyCode = this.tenantInfo?.currency;
    const currencySymbol = Utility.getCurrencySymbolFromCode(baseCurrencyCode);

    let message = '';
    if (creditLimitSettings.creditLimitType === CREDIT_LIMIT_TYPE.WARN) {
      message = `Credit Limit for ${payload?.contact?.name} has reached. Do you wish to proceed?`;
    }

    if (creditLimitSettings.creditLimitType === CREDIT_LIMIT_TYPE.BLOCK) {
      message += `Credit Limit for ${payload?.contact?.name} has reached. You cannot proceed.<br/><br/>`;
      message += '<ul>';
      message += `<li>Credit Limit: ${currencySymbol}${NumberFormatService.getNumber(
        creditLimitSettings.creditLimitInBaseCurrency
      )}</li>`;
      message += `<li>Amount Due: ${currencySymbol}${NumberFormatService.getNumber(
        creditLimitSettings.totalDueAmount
      )}</li>`;
      message += creditLimitSettings.includeCurrentDoc
        ? '<li class="text-gray" style="font-size: 12px;">(Incl. current invoice)</li>'
        : '';
      message += '</ul>';
    }

    showAlert('Credit Limit Reached', message, buttons);
  }

  private isDocValid(docToValidate: any, isDraft?: boolean) {
    // Validate Manual Document Sequence Code
    if (
      Utility.isEmpty(docToValidate.documentSequenceCode) &&
      Utility.isEmpty(docToValidate.sequenceFormat) &&
      docToValidate.manualMode
    ) {
      return false;
    }

    if (Utility.isEmpty(docToValidate.contact)) {
      return false;
    }

    if (Utility.isEmpty(docToValidate.salesInvoiceItems)) {
      return false;
    }

    if (!Utility.isEmpty(docToValidate.documentDate)) {
      const docDate = DateFormatService.getDateFromStr(
        docToValidate.documentDate,
        BOOKS_DATE_FORMAT['DD-MM-YYYY']
      );
      if (
        !Utility.checkActiveDateRangeValidation(
          docDate,
          this.tenantInfo,
          'Invoice date',
          docToValidate.documentType
        )
      ) {
        return false;
      }
      if (!Utility.checkClosingDate(docDate, 'Invoice date')) {
        return false;
      }
    }

    // Custom fields validation
    const customFieldHasErrors = customFieldsContainsErrors(
      docToValidate.customField
    );
    if (customFieldHasErrors) {
      return false;
    }
    // Custom fields validation ends

    // Line item errors
    let lineItemsHasErrors = false;
    for (let i = 0; i < docToValidate.salesInvoiceItems.length; i++) {
      const item = docToValidate.salesInvoiceItems[i];
      if (item.hasError || item.invalidFields?.length) {
        lineItemsHasErrors = true;
        break;
      }
    }

    if (lineItemsHasErrors) {
      return false;
    }
    // Line item errors ends

    // Contact GSTIN check
    if (!checkGSTINPresentForSelectedContact(docToValidate)) {
      return false;
    }
    // Contact GSTIN check ends

    // Check for -ve total amount
    const totalBeforeTax = docToValidate?.salesInvoiceItems?.length
      ? docToValidate?.salesInvoiceItems?.reduce((total: number, item: any) => {
          return total + item?.totalWithDiscount;
        }, 0)
      : 0;
    if (totalBeforeTax < 0) {
      showAlert('Invalid amount!', 'Invoice amount can not be less than 0.');
      return false;
    }
    // Check for -ve total amount ends

    // amountToReceiveOrPay check
    let paidAmount =
      docToValidate.knockoffInfo?.reduce(
        (total: number, paymentInfo: any) =>
          total +
          Number(paymentInfo?.amount || 0) *
            Number(paymentInfo?.exchangeRate || 1),
        0
      ) || 0;

    if (
      this.isCashInvoice &&
      !REGEX.DECIMAL_NUMBER.test(docToValidate.amountToReceiveOrPay)
    ) {
      showAlert('Error!', `<b>Amt. to Receive</b> is not valid.`);
      return false;
    }
    // amountToReceiveOrPay ends

    // Cascading discounts validation
    const cascadingDiscountSettings =
      this.tenantInfo?.additionalSettings?.CASCADING_DISCOUNTS;
    if (cascadingDiscountSettings?.enable) {
      const isTotalDiscountInvalid = checkIfTotalDiscountInvalid(
        docToValidate,
        'salesInvoiceItems'
      );
      if (isTotalDiscountInvalid) {
        showAlert('Error!', cascadingDiscountsInvalidMessage);
        return false;
      }
    }
    // Cascading discounts validation ends

    // Additional charges check
    let additionalChargesHasErrors = false;
    const additionalCharges =
      docToValidate.additionalCharges?.additionalChargesDetails;
    if (!Utility.isEmpty(additionalCharges)) {
      for (let i = 0; i < additionalCharges.length; i++) {
        const item = additionalCharges[i];
        if (item.hasError) {
          additionalChargesHasErrors = true;
          break;
        }
      }
    }
    if (additionalChargesHasErrors) {
      return false;
    }
    // Additional charges check ends

    // Discount check
    const discountHasErrors =
      docToValidate.additionalCharges?.globalDiscount?.hasError;
    if (discountHasErrors) {
      return false;
    }
    // Discount check ends

    // Validation for reserved stock
    if (docToValidate.reservedStock) {
      const isStockInvalid = docToValidate.salesInvoiceItems.some(
        (obj: any) =>
          (obj.product?.type === PRODUCT_TYPE.TRACKED ||
            obj.product?.type === PRODUCT_TYPE.BILL_OF_MATERIALS) &&
          Utility.isEmpty(obj.reservedQuantitiesData)
      );
      if (isStockInvalid) {
        showAlert(
          'Error!',
          'Reserve Stock details are not added for one or more products'
        );
        return false;
      }
    }
    // Validation for reserved stock ends

    //validation product having create Revenue plan on REVENUE_ARRANGEMENT

    const revRecPlansWithoutDates = docToValidate.salesInvoiceItems.filter(
      (itemData: any) => {
        return (
          itemData?.product?.revenueRecognitionInfo?.isDirectRevenuePositing ===
            false &&
          itemData?.product?.revenueRecognitionInfo?.allocationType !==
            'EXCLUDE' &&
          itemData?.product?.revenueRecognitionInfo?.createRevenuePlanOn ===
            'REVENUE_ARRANGEMENT' &&
          (itemData?.revRecEndDate === null ||
            itemData?.revRecStartDate === null)
        );
      }
    );
    if (!Utility.isEmpty(revRecPlansWithoutDates)) {
      showAlert('Error!', 'Rev Rec Start Date or End Date should not be empty');
      return false;
    }
    return true;
  }
}
