import { TableColumnPopupType } from "../../../Constants/Constants";
import { getIsShowTableColumn } from "../../../Utilities/Utility";

export class WorkOrderTableWidth {
    isLineNumberVisible: boolean;
    isCodeVisible: boolean;
    isNameVisible: boolean;
    isDescriptionVisible: boolean;
    isImageVisible: boolean;
    isQuantityVisible: boolean;
    isSerialBatchVisible: boolean;
    isOrderPrepCompletedVisible: boolean;
    isProductCustomFieldVisible: boolean;

    lineNumber: number;
    code: number;
    image: number;
    name: number;
    description: number;
    quantity: number;
    serialBatch: number;
    orderPrepCompleted: number;
    productCustomField: number;

    originalLineNumber: number;
    originalCode: number;
    originalImage: number;
    originalName: number;
    originalQuantity: number;
    originalDescription: number;
    originalSerialBatch: number;
    originalOrderPrepCompleted: number;
    originalProductCustomField: number;
    
    productCustomFieldList: any;

    constructor() {
        this.isLineNumberVisible = false;
        this.isCodeVisible = false;
        this.isNameVisible = true;
        this.isDescriptionVisible = false;
        this.isImageVisible = false;
        this.isQuantityVisible = false
        this.isSerialBatchVisible = false
        this.isProductCustomFieldVisible = false
        this.isOrderPrepCompletedVisible = false

        this.lineNumber = 10;
        this.code = 20;
        this.image = 10;
        this.name = 20;
        this.description = 20;
        this.quantity = 10;
        this.serialBatch = 10
        this.orderPrepCompleted = 20;
        this.productCustomField = 20;

        this.originalLineNumber = 10;
        this.originalCode = 20;
        this.originalImage = 10;
        this.originalName = 20;
        this.originalDescription = 20;
        this.originalQuantity = 10;
        this.originalSerialBatch = 10
        this.originalOrderPrepCompleted = 20;
        this.originalProductCustomField = 20;
    }

        setIsShowWidth(columnData: any) {
        this.isLineNumberVisible = getIsShowTableColumn(columnData, TableColumnPopupType.lineNumber)
        this.isCodeVisible = getIsShowTableColumn(columnData, TableColumnPopupType.code)
        this.isNameVisible = getIsShowTableColumn(columnData, TableColumnPopupType.productName)
        this.isDescriptionVisible = getIsShowTableColumn(columnData, TableColumnPopupType.description)
        this.isImageVisible = getIsShowTableColumn(columnData, TableColumnPopupType.image)
        this.isQuantityVisible = getIsShowTableColumn(columnData, TableColumnPopupType.quantity)
        this.isSerialBatchVisible = getIsShowTableColumn(columnData, TableColumnPopupType.serialBatch)
        this.isOrderPrepCompletedVisible = getIsShowTableColumn(columnData, TableColumnPopupType.orderPrepCompleted)
        this.isProductCustomFieldVisible = getIsShowTableColumn(columnData, TableColumnPopupType.productCustomField)

        var productCustomFieldList = columnData.filter((x: any) => x.code !== undefined)
        if (productCustomFieldList.length > 0 ) {
            this.isProductCustomFieldVisible = false
            if(this.productCustomFieldList === undefined) {
                //assign product custom list with corresponding flag
                this.productCustomFieldList = []
                var width = 5
                var maxWidth = 20
                if (productCustomFieldList.length > 4) {
                    width = maxWidth / productCustomFieldList.length
                }
                productCustomFieldList.forEach((element: any) => {
                    var item = {} as any
                    item.code = element.code
                    item.isVisible = element.columnOption.isShowColumn
                    item.originalWidth = width
                    item.width = width
                    item.type = element.name
                    item.label = element.label
                    this.productCustomFieldList.push(item)
                });
            }
            else {
                this.productCustomFieldList.forEach((element: any) => {
                    var item = columnData.find((x: any) => x.code === element.code)
                    if(item !== undefined) {
                        element.isVisible = item.columnOption.isShowColumn
                    }
                });
            }
        }
    }

    getTableHeaderWidths() {
        var totalWidth = 0;
        totalWidth = this.getTotalWidth(totalWidth);

        if (totalWidth === 100) {
            this.resetElementOriginalWidth();
        } else if (totalWidth === 0) {
            this.resetElementWidth();
        } else {
            var totalSelectedElement = this.getTotalSelectedElement();
            if (totalSelectedElement !== 0) {
                var totalOffset = (100 - totalWidth) / totalSelectedElement;
                this.updateElementWidth(totalOffset);
            }
        }
    }
    private resetElementOriginalWidth() {
        this.code = this.originalCode;
        this.name = this.originalName;
        this.description = this.originalDescription;
        this.productCustomField = this.originalProductCustomField;
        this.lineNumber = this.originalLineNumber;
        this.image = this.originalImage;
        this.quantity = this.originalQuantity;
        this.serialBatch = this.originalSerialBatch
        this.orderPrepCompleted = this.originalOrderPrepCompleted

        if (this.productCustomFieldList !== undefined) {
            this.productCustomFieldList.forEach((element: any) => {
                element.width = element.originalWidth;
            });
        }
    }

    private resetElementWidth() {
        this.code = 0;
        this.name = 0;
        this.description = 0;
        this.lineNumber = 0;
        this.image = 0;
        this.quantity = 0;
        this.serialBatch = 0
        this.orderPrepCompleted = 0;

        if (this.productCustomFieldList !== undefined) {
            this.productCustomFieldList.forEach((element: any) => {
                element.width = 0;
            });
        }
    }

    private getTotalWidth(totalWidth: number) {
        if (this.isLineNumberVisible) {
            totalWidth += this.lineNumber;
        }
        if (this.isCodeVisible) {
            totalWidth += this.code;
        }
        if (this.isImageVisible) {
            totalWidth += this.image;
        }
        if (this.isNameVisible) {
            totalWidth += this.name;
        }
        if (this.isDescriptionVisible) {
            totalWidth += this.description;
        }
        if (this.isQuantityVisible) {
            totalWidth += this.quantity;
        }
        if (this.isSerialBatchVisible) {
            totalWidth += this.serialBatch;
        }
        if (this.isOrderPrepCompletedVisible) {
            totalWidth += this.orderPrepCompleted
        }
        if (this.isProductCustomFieldVisible) {
            totalWidth += this.productCustomField;
        }

        if (this.productCustomFieldList !== undefined) {
            this.productCustomFieldList.forEach((element: any) => {
                if (element.isVisible) {
                    totalWidth += element.width;
                }
            });
        }

        return totalWidth;
    }

    private getTotalSelectedElement() {
        var totalSelectedElement = 0;
        if (this.isLineNumberVisible) {
            totalSelectedElement += 1
        }
        if (this.isCodeVisible) {
            totalSelectedElement += 1
        }
        if (this.isImageVisible) {
            totalSelectedElement += 1
        }
        if (this.isNameVisible) {
            totalSelectedElement += 1
        }
        if (this.isDescriptionVisible) {
            totalSelectedElement += 1
        }
        if (this.isQuantityVisible) {
            totalSelectedElement += 1
        }
        if (this.isSerialBatchVisible) {
            totalSelectedElement += 1
        }
        if (this.isOrderPrepCompletedVisible) {
            totalSelectedElement += 1
        }
        if (this.isProductCustomFieldVisible) {
            totalSelectedElement += 1;
        }

        if (this.productCustomFieldList !== undefined) {
            this.productCustomFieldList.forEach((element: any) => {
                if (element.isVisible) {
                    totalSelectedElement += 1;
                }
            });
        }

        return totalSelectedElement;
    }

    private updateElementWidth(additionalWidth: number) {
        if (this.isLineNumberVisible) {
            this.lineNumber = this.originalLineNumber + additionalWidth;
        }
        if (this.isCodeVisible) {
            this.code = this.originalCode + additionalWidth;
        }
        if (this.isImageVisible) {
            this.image = this.originalImage + additionalWidth;
        }
        if (this.isNameVisible) {
            this.name = this.originalName + additionalWidth;
        }
        if (this.isDescriptionVisible) {
            this.description = this.originalDescription + additionalWidth;
        }
        if (this.isQuantityVisible) {
            this.quantity = this.originalQuantity + additionalWidth;
        }
        if (this.isSerialBatchVisible) {
            this.serialBatch = this.originalSerialBatch + additionalWidth
        }
        if(this.isOrderPrepCompletedVisible) {
            this.orderPrepCompleted = this.originalOrderPrepCompleted + additionalWidth
        }

        if (this.isProductCustomFieldVisible) {
            this.productCustomField = this.originalProductCustomField + additionalWidth
        }
        if (this.productCustomFieldList !== undefined) {
            this.productCustomFieldList.forEach((element: any) => {
                if (element.isVisible) {
                    element.width = element.originalWidth + additionalWidth;
                }
            });
        }
    }

    getProductCustomFieldWidthByCode(code: string) {
        var item = this.productCustomFieldList.find((x: any) => x.code === code);
        if (item !== undefined) {
            return item.width;
        }
        return undefined;
    }
}
