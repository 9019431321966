import {
  MouseEvent,
  ReactElement,
  ReactNode,
  useEffect,
  useRef,
  useState
} from 'react';
import PopupWrapperService from '../Services/PopupWrapper';
import { createPortal } from 'react-dom';
import Utility from '../Utility/Utility';

export interface IDynamicPopupWrapperProps {
  popupIdPrefix?: string;
  popupWrapperElement?: HTMLDivElement | null;
}

export default function useDynamicPopupWrapper(
  children?: ReactElement | ReactNode | null,
  wrapperProps?: IDynamicPopupWrapperProps
) {
  const [popupWrapperElement, setPopupWrapperElement] =
    useState<HTMLDivElement | null>(null);
  // const draggingStateRef = useRef(false);
  const popupContainerElementRef = useRef<any>(null);
  const popupElementRef = useRef<any>(null);
  const dragHandleRef = useRef<any>(null);

  useEffect(() => {
    if (wrapperProps?.popupWrapperElement) return () => {};

    const overlayContainer = document.createElement('div');
    overlayContainer.classList.add(...['position-relative']);
    overlayContainer.id = `${
      wrapperProps?.popupIdPrefix || 'overlay-container'
    }-${new Date().getTime()}`;
    overlayContainer.style.zIndex = PopupWrapperService.zIndex.toString();

    document.body.appendChild(overlayContainer);
    setPopupWrapperElement(overlayContainer);
    PopupWrapperService.increaseZIndex();
    return () => {
      overlayContainer && document.body.removeChild(overlayContainer);
      PopupWrapperService.decreaseZIndex();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const draggingStateRef = { current: false };

    const onMouseDown = (e: MouseEvent) => {
      document.body.classList.add('unselectable');
      dragHandleRef.current.style.cursor = 'grabbing';
      dragHandleRef.current.style.userSelect = 'auto';
      draggingStateRef.current = true;
    };

    const stopDragging = () => {
      if (draggingStateRef.current) {
        document.body.classList.remove('unselectable');
        dragHandleRef.current.style.cursor = 'grab';
        draggingStateRef.current = false;
      }
    };

    const onMouseUp = (e: MouseEvent) => stopDragging();

    const onMouseEnter = (e: MouseEvent) => e.buttons === 0 && stopDragging();

    const onMouseMove = (e: MouseEvent) => {
      if (draggingStateRef.current) {
        const { innerHeight: outerHeight, innerWidth: outerWidth } = window;
        const { clientHeight, clientWidth } = popupElementRef.current;
        const { top, left } = popupElementRef.current?.getBoundingClientRect();
        let dX = left + e.movementX;
        let dY = top + e.movementY;

        if (dX < 0 || dX > outerWidth - clientWidth) {
          //* This is to restrict horizontal movement inside the viewport
          dX = left;
        }
        if (dY < 0 || dY > outerHeight - clientHeight) {
          //* This is to restrict vertical movement inside the viewport
          dY = top;
        }
        if (popupElementRef?.current) {
          popupElementRef.current.style.left = dX?.toString()?.concat('px');
          popupElementRef.current.style.top = dY?.toString()?.concat('px');
        }
      }
    };

    const wrapperElement =
      wrapperProps?.popupWrapperElement || popupWrapperElement;
    const overlayElement = wrapperElement?.querySelector(
      '.transparent-background'
    );
    const popupElement: any = wrapperElement?.querySelector('.popup-window');

    if (
      Utility.isNotEmpty(overlayElement) &&
      Utility.isNotEmpty(popupElement)
    ) {
      popupContainerElementRef.current = overlayElement;
      popupContainerElementRef.current.classList.add('popup-parent');

      popupContainerElementRef.current.addEventListener(
        'mousemove',
        onMouseMove
      );
      popupContainerElementRef.current.addEventListener('mouseup', onMouseUp);
      /* When mouse leaves the transparent background, and re enters with not pressed state, then stopping drag.. */
      popupContainerElementRef.current.addEventListener(
        'mouseenter',
        onMouseEnter
      );

      popupElementRef.current = popupElement;
      popupElementRef.current.classList.add('draggable-popup');
      dragHandleRef.current = popupElement?.querySelector('.pop-header-drag-handle');
      if (dragHandleRef.current) {
        dragHandleRef.current.addEventListener('mousedown', onMouseDown);
      }
    }

    return () => {
      popupContainerElementRef.current?.removeEventListener(
        'mousemove',
        onMouseMove
      );
      popupContainerElementRef.current?.removeEventListener(
        'mouseup',
        onMouseUp
      );
      popupContainerElementRef.current?.removeEventListener(
        'mouseenter',
        onMouseEnter
      );

      dragHandleRef.current?.removeEventListener('mousedown', onMouseDown);
    };
  }, [popupWrapperElement, wrapperProps?.popupWrapperElement]);

  return wrapperProps?.popupWrapperElement
    ? (children as ReactElement)
    : popupWrapperElement
    ? createPortal(children, popupWrapperElement)
    : null;
}
