
import Utility from '../Utility/Utility';
import http from './Interceptor';

export interface PaymentLogsAPIConfig {
  Limit?: number;
  Page?: number;
  Sort?: string;
  SortDir?: string;
  Search?: string;
  Query?: string;
}

const defaultConfig: PaymentLogsAPIConfig = {
  Limit: 25,
  Page: 0,
  Sort: "createdAt",
  SortDir: "desc"
};

class PaymentLogsService {
  static apiConfig: PaymentLogsAPIConfig;

  static getApiConfig() {
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    }

    return this.apiConfig;
  }

  static getPaymentLogs() {
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    }

    const params: any = {
      query: "type=GENERATED",
      limit: this.apiConfig.Limit,
      page: this.apiConfig.Page,
      sort: this.apiConfig.Sort,
      sortDir: this.apiConfig.SortDir
    };

    if (this.apiConfig.Search) {
      params.search = this.apiConfig.Search;
    }

    return http.get("/exim/payment-bank-file/search", { params: params }).catch((err: any) => {
      console.error('Error loading payment logs: ', err);
      return Promise.reject(err);
    });
  }

  static uploadToFTP(body: number[]) {
    return http.post("/exim/ftp/upload", body).catch((err: any) => {
      console.error('Error uploading to ftp: ', err);
      return Promise.reject(err);
    });
  }
}

export default PaymentLogsService;
