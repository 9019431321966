import { DKButton, DKLabel, INPUT_TYPE, DKDataGrid } from 'deskera-ui-library';
import { useEffect, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../Redux/Hooks';
import Utility, {
  convertBooksDateFormatToUILibraryFormat,
  deepClone
} from '../../../Utility/Utility';

const BATCH_CONFIG = [
  {
    name: 'Quantity Transferred',
    index: 2,
    options: null,
    required: false,
    editable: false,
    hidden: false,
    uiVisible: true,
    systemField: true,
    columnCode: 'qtyToTransfer',
    id: 'qtyToTransfer',
    datasource: [],
    key: 'qtyToTransfer',
    lineItem: false,
    textAlign: 'right',
    type: INPUT_TYPE.TEXT,
    width: 120
  },
  {
    name: 'Source Batch Number',
    index: 0,
    options: null,
    required: false,
    editable: false,
    hidden: false,
    uiVisible: true,
    systemField: true,
    columnCode: 'sourceSerialBatchNumber',
    id: 'sourceSerialBatchNumber',
    datasource: [],
    key: 'sourceSerialBatchNumber',
    lineItem: false,
    type: INPUT_TYPE.TEXT,
    width: 200
  },
  {
    name: 'Target Batch Number',
    index: 1,
    options: null,
    required: false,
    editable: false,
    hidden: false,
    uiVisible: true,
    systemField: true,
    columnCode: 'destSerialBatchNumber',
    id: 'destSerialBatchNumber',
    datasource: [],
    key: 'destSerialBatchNumber',
    lineItem: false,
    type: INPUT_TYPE.TEXT,
    width: 200
  },
  {
    name: 'Source Warehouse',
    index: 1,
    options: null,
    required: false,
    editable: false,
    hidden: false,
    uiVisible: true,
    systemField: true,
    columnCode: 'srcWarehouseName',
    id: 'srcWarehouseName',
    datasource: [],
    key: 'srcWarehouseName',
    lineItem: false,
    type: INPUT_TYPE.TEXT,
    width: 220
  },
  {
    name: 'Target Warehouse',
    index: 3,
    options: null,
    required: false,
    editable: false,
    hidden: false,
    uiVisible: true,
    systemField: true,
    columnCode: 'destWarehouseName',
    id: 'destWarehouseName',
    datasource: [],
    key: 'destWarehouseName',
    lineItem: false,
    type: INPUT_TYPE.TEXT,
    width: 200
  }
];
const SERIAL_CONFIG = [
  {
    name: 'Quantity Transferred',
    index: 2,
    options: null,
    required: false,
    editable: false,
    hidden: false,
    uiVisible: true,
    systemField: true,
    columnCode: 'qtyToTransfer',
    id: 'qtyToTransfer',
    datasource: [],
    key: 'qtyToTransfer',
    lineItem: false,
    type: INPUT_TYPE.TEXT,
    width: 220
  },
  {
    name: 'Serial Number',
    index: 0,
    options: null,
    required: false,
    editable: false,
    hidden: false,
    uiVisible: true,
    systemField: true,
    columnCode: 'sourceSerialBatchNumber',
    id: 'sourceSerialBatchNumber',
    datasource: [],
    key: 'sourceSerialBatchNumber',
    lineItem: false,
    type: INPUT_TYPE.TEXT,
    width: 240
  },
  {
    name: 'Source Warehouse',
    index: 1,
    options: null,
    required: false,
    editable: false,
    hidden: false,
    uiVisible: true,
    systemField: true,
    columnCode: 'srcWarehouseName',
    id: 'srcWarehouseName',
    datasource: [],
    key: 'srcWarehouseName',
    lineItem: false,
    type: INPUT_TYPE.TEXT,
    width: 220
  },
  {
    name: 'Target Warehouse',
    index: 1,
    options: null,
    required: false,
    editable: false,
    hidden: false,
    uiVisible: true,
    systemField: true,
    columnCode: 'destWarehouseName',
    id: 'destWarehouseName',
    datasource: [],
    key: 'destWarehouseName',
    lineItem: false,
    type: INPUT_TYPE.TEXT,
    width: 240
  }
];
const NORMAL_CONFIG = [
  {
    name: 'Quantity',
    index: 0,
    options: null,
    required: false,
    editable: false,
    hidden: false,
    uiVisible: true,
    systemField: true,
    columnCode: 'quantity',
    id: 'quantity',
    datasource: [],
    key: 'quantity',
    lineItem: false,
    type: INPUT_TYPE.TEXT,
    width: 220
  },
  {
    name: 'Source Warehouse',
    index: 1,
    options: null,
    required: false,
    editable: false,
    hidden: false,
    uiVisible: true,
    systemField: true,
    columnCode: 'srcWarehouseName',
    id: 'srcWarehouseName',
    datasource: [],
    key: 'srcWarehouseName',
    lineItem: false,
    type: INPUT_TYPE.TEXT,
    width: 220
  },
  {
    name: 'Target Warehouse',
    index: 1,
    options: null,
    required: false,
    editable: false,
    hidden: false,
    uiVisible: true,
    systemField: true,
    columnCode: 'destWarehouseName',
    id: 'destWarehouseName',
    datasource: [],
    key: 'destWarehouseName',
    lineItem: false,
    type: INPUT_TYPE.TEXT,
    width: 220
  }
];
export default function StockTransferDetailPopup(props: any) {
  const [detailState, setDetailState] = useState<any[]>([]);

  useEffect(() => {
    if (props.detail && props.detail.length > 0) {
      let x = deepClone(props.detail);
      setDetailState(x);
    } else if (!Utility.isEmpty(props.normalDetail)) {
      let x = deepClone(props.normalDetail);
      setDetailState([x]);
    }
  }, [props.detail, props.normalDetail]);

  const getColumnConfig = () => {
    if (props.isBatch) {
      return BATCH_CONFIG;
    } else if (props.isNormal) {
      return NORMAL_CONFIG;
    } else {
      return SERIAL_CONFIG;
    }
  };

  const getLineItemGrid = () => {
    return (
      <>
        <DKDataGrid
          needShadow={false}
          needBorder={true}
          needColumnIcons={false}
          needTrailingColumn={false}
          allowColumnSort={false}
          allowColumnAdd={false}
          allowColumnEdit={false}
          allowRowEdit={false}
          columns={getColumnConfig()}
          rows={detailState}
          onRowUpdate={() => {}}
          currentPage={1}
          totalPageCount={1}
          title={''}
          allowBulkOperation={false}
        />
      </>
    );
  };

  const getHeader = () => {
    return (
      <div className="row justify-content-between p-h-r p-v-s bg-gray1">
        <div className="row width-auto">
          <DKLabel
            text={
              props.state === 'STOCK_ISSUE'
                ? 'Stock Issue Detail'
                : 'Stock Transfer Detail'
            }
            className="fw-m fs-l"
          />
        </div>
        <div className="row width-auto">
          <DKButton
            title={'Cancel'}
            className="bg-white border-m mr-r"
            onClick={props.onClose}
          />
          <div className={`row ${'border-radius-m border-m'}`}></div>
        </div>
      </div>
    );
  };

  return (
    <div className="transparent-background">
      <div
        className="popup-window"
        style={{
          maxWidth: 910,
          width: '100%',
          maxHeight: '95%',
          height: 'auto',
          padding: 0,
          paddingBottom: 60,
          overflowY: 'visible'
        }}
      >
        {getHeader()}
        <div
          className="column p-h-xl parent-width parent-height"
          style={{
            overflowY: 'scroll',
            overflowX: 'hidden',
            scrollbarWidth: 'none'
          }}
        >
          <div
            id="popup-container"
            className="column parent-width parent-height pt-xl"
            style={{
              pointerEvents: 'auto'
            }}
          >
            <div className="m-v-r parent-width">
              {!props.isBatch && !props.isNormal && (
                <div className="row">
                  <DKLabel
                    className="font-medium fs-r "
                    text={'Total Serial Transferred:'}
                  ></DKLabel>{' '}
                  <DKLabel
                    className="ml-2 font-medium fs-r "
                    text={props?.detail?.length || 0}
                  ></DKLabel>
                </div>
              )}
              <div className="m-v-r parent-width">{getLineItemGrid()}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
