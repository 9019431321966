import { useEffect, useState } from 'react';
import {
  DKInput,
  INPUT_TYPE,
  INPUT_VIEW_DIRECTION,
  DKIcons,
  showAlert,
  DKSpinner,
  showLoader,
  removeLoader
} from 'deskera-ui-library';
import ic_convert_fulfill from '../../Assets/Icons/ic_convert_fulfill.svg';
import ic_convert_only from '../../Assets/Icons/ic_convert_only.svg';
import ic_convert_partially from '../../Assets/Icons/ic_convert_partially.svg';
import {
  APPROVAL_STATUS,
  BOOKS_DATE_FORMAT,
  DOCUMENT_STATUS,
  DOC_TYPE,
  FULFILLMENT_STATUS,
  FULFILLMENT_TYPE,
  LABELS,
  MODULES_NAME
} from '../../Constants/Constant';
import Utility, {
  convertBooksDateFormatToUILibraryFormat
} from '../../Utility/Utility';
import { InvoiceInitialState } from '../../Models/Invoice';
import { useAppDispatch, useAppSelector } from '../../Redux/Hooks';
import { activeTenantInfo } from '../../Redux/Slices/AuthSlice';
import InvoiceService from '../../Services/Invoice';
import {
  createBlankDraft,
  draftTableId,
  fetchDrafts,
  isSaveColumnId,
  selectDraftsColumnConfig
} from '../../Redux/Slices/DraftsSlice';
import { DraftTypes } from '../../Models/Drafts';
import DateFormatService from '../../Services/DateFormat';
import { CREDIT_LIMIT_TYPE } from '../../Constants/Enum';
import RouteManager, { PAGE_ROUTES } from '../../Managers/RouteManager';
import { getUpdatedSalesOrderObject } from './SalesOrderHelper';
import { checkCreditLimit } from '../../SharedComponents/DocumentForm/NewDocumentHelper';
import NumberFormatService from '../../Services/NumberFormat';
import ContactService from '../../Services/Contact';
import AuthService from '../../Services/Auth';
import DraftService from '../../Services/Drafts';
import { SalesOrder } from '../../Models/SalesOrder';
import SalesOrderService from '../../Services/SalesOrder';
import { fetchSalesOrders } from '../../Redux/Slices/SalesOrderSlice';
import { localizedText } from '../../Services/Localization/Localization';
import { selectFullInvoiceCustomNumbersFormats } from '../../Redux/Slices/CustomNumberFormat';
import AttachmentService from '../../Services/Attachment';
import { differenceInDays, addDays } from 'date-fns';
import { checkUserPermission } from '../Settings/GranularPermissions/GranularPermissionsHelper';
import { PERMISSIONS_BY_MODULE } from '../../Constants/Permission';

interface SalesOrderToInvoiceProps {
  salesOrderDocument: SalesOrder;
  closePopup: () => void;
  closeParentDoc?: () => void;
}

enum SOConversionAction {
  PARTIAL_INVOICE = 'partial_invoice',
  CONVERT_ONLY = 'convert_only',
  CONVERT_AND_FULFILL = 'convert_and_fulfill'
}

export default function SalesOrderToInvoicePopup(
  props: SalesOrderToInvoiceProps
) {
  const [salesOrder, setSalesOrder] = useState(props.salesOrderDocument);
  const [isOpeningForm, setIsOpeningForm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isApiLoading, setIsApiLoading] = useState(false);
  const [type, setType] = useState<SOConversionAction>();
  const [contact, setContact] = useState<any>(null);
  const [attachmentIds, setAttachmentsIds] = useState<any>([]);
  const [attachments, setAttachments] = useState<any>([]);
  const isSavedColumnId = useAppSelector(isSaveColumnId);

  const [salesOrderDate, setSalesOrderDate] = useState(
    DateFormatService.getDateFromStr(
      props.salesOrderDocument?.salesOrderDate || '',
      BOOKS_DATE_FORMAT['DD-MM-YYYY']
    )
  );

  const [invoiceDate, setInvoiceDate] = useState(
    DateFormatService.getDateFromStr(
      props.salesOrderDocument?.salesOrderDate || '',
      BOOKS_DATE_FORMAT['DD-MM-YYYY']
    )
  );

  const dispatch = useAppDispatch();
  const tenantInfo = useAppSelector(activeTenantInfo);
  const draftsTableId = useAppSelector(draftTableId);
  const draftsColumnConfig = useAppSelector(selectDraftsColumnConfig);
  const invoiceSequenceFormatsInStore = useAppSelector(
    selectFullInvoiceCustomNumbersFormats
  );

  useEffect(() => {
    const fetchContact = async () => {
      try {
        const detailedContact = await ContactService.getContactDetailsById(
          props.salesOrderDocument?.contactDto?.id
        );
        setContact(detailedContact);
      } catch (err) {
        console.error('Error loading detailed contact: ', err);
      }
    };
    AttachmentService.attachmentConfig = {
      ...AttachmentService.attachmentConfig,
      Module: MODULES_NAME.SALES_ORDER,
      EntityId: props.salesOrderDocument.id
    };
    AttachmentService.getAllAttachments()
      .then((res: any) => {
        let attachmentIds: any[] = [];
        res &&
          res.length &&
          res.forEach((attachment: any) => {
            attachmentIds.push(attachment.attachmentId);
          });
        setAttachmentsIds(attachmentIds);
        setAttachments(attachmentIds.map(String));
      })
      .catch((err: any) => {
        console.error(err);
      });
    fetchContact();

    fetchContact();
  }, []);

  // Load Quote Details
  const loadSalesOrderDetails = async (
    doc: any,
    convertType: SOConversionAction
  ) => {
    const documentCode = doc.salesOrderCode;
    SalesOrderService.getSalesOrderByCode(documentCode).then(
      (data: any) => {
        let getSalesOrderDetailsData: any = getUpdatedSalesOrderObject(data);

        if (convertType === SOConversionAction.CONVERT_AND_FULFILL) {
          if (
            !Utility.checkStockAvailability(
              getSalesOrderDetailsData.salesOrderItems || []
            )
          ) {
            setIsApiLoading(false);
            setIsOpeningForm(false);
            showAlert(
              'Error',
              'Fulfillment can not be done due to insufficient stock.'
            );
            return;
          }
        }

        const salesOrderDetailsData = {
          ...getSalesOrderDetailsData,
          linkedSalesInvoices: doc.linkedSalesInvoices,
          poNumber: doc?.poNumber,
          sourceDocTypeForConversion: MODULES_NAME.SALES_ORDER
        };
        setSalesOrder(salesOrderDetailsData);
        if (!Utility.isEmpty(salesOrderDetailsData)) {
          setIsApiLoading(false);

          switch (convertType) {
            case SOConversionAction.CONVERT_AND_FULFILL:
              prepareSOForConversion(
                salesOrderDetailsData,
                SOConversionAction.CONVERT_AND_FULFILL
              );
              break;
            case SOConversionAction.CONVERT_ONLY:
              prepareSOForConversion(
                salesOrderDetailsData,
                SOConversionAction.CONVERT_ONLY
              );
              break;
            case SOConversionAction.PARTIAL_INVOICE:
              prepareSOForConversion(
                salesOrderDetailsData,
                SOConversionAction.PARTIAL_INVOICE
              );
              break;

            default:
              break;
          }
        }
      },
      (err) => {
        setIsApiLoading(false);
        console.error('Error while fetching sales order details: ', err);
      }
    );
  };

  const convertToPartialInvoice = async (selectedSO: SalesOrder) => {
    let partialInvoice: any = {
      ...InvoiceInitialState,
      ...selectedSO
    };
    if (!Utility.isEmpty(contact)) {
      partialInvoice = {
        ...partialInvoice,
        contactDto: contact
      };
    }
    partialInvoice = {
      ...partialInvoice,
      id: null,
      documentType: DOC_TYPE.INVOICE,
      isPartialInvoice: true,
      backOrder: false,
      openingInvoice: partialInvoice.openingInvoice || false,
      fulfillmentStatus: FULFILLMENT_STATUS.UNFULFILLED,
      fulfillmentType: partialInvoice.fulfillmentType,
      documentDate: partialInvoice.documentDate,
      documentSequenceCode: null,
      sequenceFormat: null,
      validTillDate: partialInvoice.salesOrderDueDate,
      fulfillmentDate: partialInvoice.fulfillmentDate,
      attachments: partialInvoice.entityId ? partialInvoice.attachments : [],
      attachmentIds: partialInvoice.entityId
        ? partialInvoice.attachments?.map(Number)
        : [],
      isConverting: true,
      status: !partialInvoice.id ? DOCUMENT_STATUS.OPEN : partialInvoice.status,
      paymentStatus: partialInvoice.paymentStatus,
      taxInvoiceNo: partialInvoice.taxInvoiceNo,
      paymentInformation: partialInvoice.paymentInformation
        ? partialInvoice.paymentInformation
        : null,
      whtRate:
        partialInvoice.whtRate && partialInvoice.whtRate !== null
          ? partialInvoice.whtRate
          : 0,
      einvoiceInfoIndia: partialInvoice.einvoiceInfoIndia,
      einvoiceInfoIndiaCancel: partialInvoice.einvoiceInfoIndiaCancel,
      isCancelEinvoice: partialInvoice.isCancelEinvoice,
      reservedStock: partialInvoice.reservedStock
        ? partialInvoice.reservedStock
        : false,
      linkedDocuments: [
        {
          documentCode: selectedSO.salesOrderCode as string,
          documentType: selectedSO.documentType,
          documentSequenceCode: selectedSO.documentSequenceCode as string
        }
      ],
      contact: {
        ...partialInvoice.contact,
        ...partialInvoice.contactDto,
        address: Utility.getStringAddress(partialInvoice.contactDto)
      },
      salesInvoiceDate: partialInvoice.documentDate,
      salesInvoiceDueDate: partialInvoice.validTillDate
    };

    if (
      !Utility.isEmpty(selectedSO.linkedQuotationDocuments) &&
      !Utility.isEmpty(partialInvoice.linkedDocuments) &&
      selectedSO?.linkedQuotationDocuments != undefined
    ) {
      partialInvoice.linkedDocuments = [
        ...partialInvoice.linkedDocuments,
        {
          documentCode: selectedSO?.linkedQuotationDocuments?.[0]
            ?.documentCode as string,
          documentType: selectedSO?.linkedQuotationDocuments?.[0]?.documentType,
          documentSequenceCode: selectedSO?.linkedQuotationDocuments?.[0]
            ?.documentSequenceCode as string
        }
      ];
    }

    if (
      !Utility.isEmpty(selectedSO.linkedWorkOrderDocuments) &&
      !Utility.isEmpty(partialInvoice.linkedDocuments) &&
      selectedSO?.linkedWorkOrderDocuments != undefined
    ) {
      partialInvoice.linkedDocuments = [
        ...partialInvoice.linkedDocuments,
        ...selectedSO.linkedWorkOrderDocuments
      ];
    }

    const {
      contactDto,
      items,
      quotationCode,
      quotationItemDtoList,
      ...invoiceToForward
    } = partialInvoice;

    let payloadData: any = {
      type: LABELS.INVOICES,
      title: LABELS.INVOICES,
      isMaximized: true,
      isCenterAlign: true,
      populateFormData: invoiceToForward,
      tableId: draftsTableId,
      columnConfig: draftsColumnConfig
    };
    if (!Utility.isEmpty(invoiceToForward)) {
      dispatch(createBlankDraft({ payloadData, draftType: DraftTypes.NEW }));
      if (typeof props.closePopup !== 'undefined') {
        props.closePopup();
      }
      if (props.closeParentDoc) {
        props.closeParentDoc();
      }
    }
  };

  const showCreditLimitAlert = (creditLimitSettings: any, payload: any) => {
    let buttons = [];

    buttons.push({
      title:
        creditLimitSettings.creditLimitType === CREDIT_LIMIT_TYPE.WARN
          ? 'Cancel'
          : 'Ok',
      className: 'border-m',
      onClick: () => {
        removeLoader();
        setIsOpeningForm(false);
        if (typeof props.closePopup !== 'undefined') {
          props.closePopup();
        }
      }
    });

    if (creditLimitSettings.creditLimitType === CREDIT_LIMIT_TYPE.WARN) {
      buttons.push({
        title: 'Save',
        className: 'bg-blue text-white ml-r',
        onClick: () => {
          createInvoice(payload);
        }
      });
    }

    const baseCurrencyCode = tenantInfo.currency;
    const currencySymbol = Utility.getCurrencySymbolFromCode(baseCurrencyCode);

    let message = '';
    if (creditLimitSettings.creditLimitType === CREDIT_LIMIT_TYPE.WARN) {
      message = `Credit Limit for ${payload?.contact?.name} has reached. Do you wish to proceed?`;
    }

    if (creditLimitSettings.creditLimitType === CREDIT_LIMIT_TYPE.BLOCK) {
      message += `Credit Limit for ${payload?.contact?.name} has reached. You cannot proceed.<br/><br/>`;
      message += '<ul>';
      message += `<li>Credit Limit: ${currencySymbol}${NumberFormatService.getNumber(
        creditLimitSettings.creditLimitInBaseCurrency
      )}</li>`;
      message += `<li>Amount Due: ${currencySymbol}${NumberFormatService.getNumber(
        creditLimitSettings.totalDueAmount
      )}</li>`;
      message += creditLimitSettings.includeCurrentDoc
        ? '<li class="text-gray" style="font-size: 12px;">(Incl. current invoice)</li>'
        : '';
      message += '</ul>';
    }

    showAlert('Credit Limit Reached', message, buttons);
  };

  const convertToInvoice = async (
    selectedSO: SalesOrder,
    autoFulfill: boolean
  ) => {
    let invoice = {
      ...InvoiceInitialState,
      ...selectedSO
    };

    if (!Utility.isEmpty(contact)) {
      invoice = {
        ...invoice,
        contactDto: contact
      };
    }

    let linkedDocuments = invoice.linkedDocuments;
    invoice = {
      ...invoice,
      documentType: DOC_TYPE.INVOICE,
      openingInvoice: invoice.openingInvoice || false,
      fulfillmentStatus:
        invoice.fulfillmentStatus || FULFILLMENT_STATUS.UNFULFILLED,
      fulfillmentType: invoice.fulfillmentType,
      documentDate: invoice.documentDate,
      validTillDate: invoice.validTillDate,
      fulfillmentDate: invoice.fulfillmentDate,
      status: !invoice.id ? DOCUMENT_STATUS.OPEN : invoice.status,
      backOrder: invoice.backOrder ? invoice.backOrder : false,
      paymentStatus: invoice.paymentStatus,
      taxInvoiceNo: invoice.taxInvoiceNo,
      paymentInformation: invoice.paymentInformation
        ? invoice.paymentInformation
        : null,
      whtRate:
        invoice.whtRate && invoice.whtRate !== null ? invoice.whtRate : 0,
      einvoiceInfoIndia: invoice.einvoiceInfoIndia,
      einvoiceInfoIndiaCancel: invoice.einvoiceInfoIndiaCancel,
      isCancelEinvoice: invoice.isCancelEinvoice,
      reservedStock: invoice.reservedStock ? invoice.reservedStock : false,
      salesInvoiceCode: '',
      salesInvoiceDueDate: invoice.validTillDate,
      salesInvoiceDate: invoice.documentDate,
      shipByDate: invoice.fulfillmentDate,
      autoFulfill: autoFulfill,
      attachments: invoice.entityId ? invoice.attachments : [],
      attachmentIds: invoice.entityId ? invoice.attachments?.map(Number) : [],
      contact: {
        ...invoice.contact,
        ...invoice.contactDto,
        address: Utility.getStringAddress(invoice.contactDto)
      },
      salesInvoiceItems: invoice.items?.map((item) => {
        return {
          ...item,
          id: undefined,
          documentItemCode: undefined,
          documentSequenceCode: null,
          linkedQuoteItem: item.id,
          unitPriceGstInclusive: invoice.unitPriceGstInclusive,
          exchangeRate: invoice.exchangeRate,
          quantityFulfilled: item.quantityFulfilled
            ? item.quantityFulfilled
            : item.fulfilledQuantity,
          quantityInParent: item.quantityInParent
            ? item.quantityInParent
            : item.productQuantity,
          taxDetails: item.taxDetails.map((tax: any) => {
            const taxItem = { ...tax };
            delete taxItem.id;
            return taxItem;
          }),
          totalAmount: item.amount
        };
      }),
      fulfillmentComplete: autoFulfill
    };

    if (
      selectedSO.fulfillmentType === FULFILLMENT_TYPE.DROP_SHIP &&
      linkedDocuments &&
      linkedDocuments.length > 0
    ) {
      invoice.linkedDocuments = [
        ...linkedDocuments,
        {
          documentCode: selectedSO.salesOrderCode as string,
          documentType: selectedSO.documentType,
          documentSequenceCode: selectedSO.documentSequenceCode as string
        }
      ];
    } else {
      invoice.linkedDocuments = [
        {
          documentCode: selectedSO.salesOrderCode as string,
          documentType: selectedSO.documentType,
          documentSequenceCode: selectedSO.documentSequenceCode as string
        }
      ];
    }

    if (
      !Utility.isEmpty(selectedSO.linkedQuotationDocuments) &&
      !Utility.isEmpty(invoice.linkedDocuments) &&
      selectedSO?.linkedQuotationDocuments != undefined
    ) {
      invoice.linkedDocuments = [
        ...invoice.linkedDocuments,
        {
          documentCode: selectedSO?.linkedQuotationDocuments?.[0]
            ?.documentCode as string,
          documentType: selectedSO?.linkedQuotationDocuments?.[0]?.documentType,
          documentSequenceCode: selectedSO?.linkedQuotationDocuments?.[0]
            ?.documentSequenceCode as string
        }
      ];
    }

    const {
      contactDto,
      items,
      salesOrderCode,
      salesOrderItems,
      documentSequenceCode,
      sequenceFormat,
      salesOrderDate,
      salesOrderDueDate,
      documentCode,
      linkedQuotationDocuments,
      linkedSalesInvoiceDocuments,
      linkedSalesInvoices,
      isPartialSalesOrder,
      ...invoiceToSave
    } = invoice;

    // console.log('INVOICE to save: ', invoiceToSave);
    if (autoFulfill) {
      if (!Utility.isEmpty(invoice.fulfillmentDate)) {
        const docDate = DateFormatService.getDateFromStr(
          invoice.fulfillmentDate,
          BOOKS_DATE_FORMAT['DD-MM-YYYY']
        );
        if (
          !Utility.checkActiveDateRangeValidation(
            docDate,
            tenantInfo,
            'Fulfillment date',
            'FULFILLMENT'
          )
        ) {
          removeLoader();
          return;
        }
      }
    }
    if (!Utility.isEmpty(invoiceToSave.documentDate)) {
      const docDate = DateFormatService.getDateFromStr(
        invoiceToSave.documentDate,
        BOOKS_DATE_FORMAT['DD-MM-YYYY']
      );
      if (
        !Utility.checkActiveDateRangeValidation(
          docDate,
          tenantInfo,
          'Invoice date',
          DOC_TYPE.INVOICE
        )
      ) {
        removeLoader();
        return;
      }
    }
    if (!Utility.isEmpty(invoiceToSave)) {
      const processedCreditLimitObj = await checkCreditLimit(invoiceToSave);
      if (
        processedCreditLimitObj.showAlertPopup &&
        processedCreditLimitObj.settings.creditLimitType !==
          CREDIT_LIMIT_TYPE.IGNORE
      ) {
        showCreditLimitAlert(processedCreditLimitObj.settings, invoiceToSave);
      } else {
        createInvoice(invoiceToSave);
      }
    }
  };

  const createInvoice = async (payload: any) => {
    let isApproval = await Utility.isApprovalRequired(payload);
    if (isApproval === null) {
      removeLoader();
      setIsOpeningForm(false);
      setIsLoading(false);
      return;
    } else if (isApproval) {
      onSaveAsDraft(isApproval, payload);
      return;
    } else {
      payload['approvalStatus'] = APPROVAL_STATUS['NOT_REQUIRED'];
    }
    InvoiceService.createInvoice(payload)
      .then(
        (response: any) => {
          removeLoader();
          dispatch(fetchSalesOrders());
          setIsOpeningForm(false);
          setIsLoading(false);
          const buttons = [
            {
              title: 'Ok',
              className: 'bg-button, border-m',
              onClick: () => {}
            },
            {
              title: 'Goto Invoices',
              className: ' bg-blue text-white ml-r',
              onClick: () => {
                RouteManager.navigateToPage(PAGE_ROUTES.INVOICES);
              }
            }
          ];
          showAlert(
            'Sales order converted!',
            'Sales order has been converted to invoice successfully.',
            buttons
          );
          if (typeof props.closePopup !== 'undefined') {
            props.closePopup();
          }
        },
        (err: any) => {
          console.error('Error converting sales order: ', err);
          if (err?.data?.errorMessage?.includes('insufficient stock')) {
            showAlert('Error!', err?.data?.errorMessage);
          }
          removeLoader();
          setIsOpeningForm(false);
          if (typeof props.closePopup !== 'undefined') {
            props.closePopup();
          }
        }
      )
      .catch((e) => {
        console.error('Error converting sales order: ', e);

        removeLoader();
        setIsOpeningForm(false);
      });
  };

  const onSaveAsDraft = (isApprovalRequired: boolean, payload: any) => {
    if (isApprovalRequired) {
      payload['approvalStatus'] = APPROVAL_STATUS['PENDING_FOR_APPROVAL'];
    } else {
      payload['approvalStatus'] = APPROVAL_STATUS['NOT_REQUIRED'];
    }
    payload['createdUserName'] = AuthService.getUserName();
    if (isApprovalRequired) {
      sendTriggerOnApproval(payload);
      if (!Utility.isEmpty(payload)) {
        let draftsData: any = {
          data: {
            type: LABELS.INVOICES,
            tableId: draftsTableId,
            columnConfig: draftsColumnConfig
          }
        };
        DraftService.createRecord(payload, draftsData)
          .then((response: any) => {
            removeLoader();
            dispatch(fetchSalesOrders());
            dispatch(
              fetchDrafts({
                tableId: draftsTableId,
                isSaveColumnId: isSavedColumnId
              })
            );
            setIsOpeningForm(false);
            setIsLoading(false);
            const buttons = [
              {
                title: 'Ok',
                className: 'bg-button, border-m',
                onClick: () => {}
              },
              {
                title: 'Goto Invoices',
                className: ' bg-blue text-white ml-r',
                onClick: () => {
                  RouteManager.navigateToPage(PAGE_ROUTES.INVOICES);
                }
              }
            ];
            showAlert(
              'Sales order converted!',
              'Sales order has been converted to invoice successfully.',
              buttons
            );
          })
          .catch((err) => {
            console.error('Error while creating draft: ', err);
          });
      }
    }
  };

  const sendTriggerOnApproval = (payload: any) => {
    let emails = Utility.getApproverEmail(payload);
    let sum =
      payload &&
      payload.salesInvoiceItems
        .map((item: any) => item.totalAmount)
        .reduce((prev: any, curr: any) => prev + curr, 0);
    let payloadObj = {
      contactCode: payload.contactCode,
      totalAmount: NumberFormatService.getNumber(sum),
      userName: AuthService.getUserName(),
      currency: Utility.getCurrencySymbolFromCode(payload.currency),
      approverMap: Object.fromEntries(emails)
    };
    InvoiceService.sendTriggerOnApproval(payloadObj).then(
      (response: any) => {
        if (typeof props.closePopup !== 'undefined') {
          props.closePopup();
        }
      },
      (err) => {
        console.error('Error while creating draft: ', err);
      }
    );
  };

  const prepareSOForConversion = async (
    data: SalesOrder,
    action: SOConversionAction
  ) => {
    let invoiceSequenceFormat: any;
    if (!Utility.isEmpty(invoiceSequenceFormatsInStore)) {
      invoiceSequenceFormat = invoiceSequenceFormatsInStore?.find(
        (seqFormat: any) => seqFormat?.isDefault
      );
      if (!Utility.isEmpty(invoiceSequenceFormat)) {
        invoiceSequenceFormat = invoiceSequenceFormat?.id;
      } else {
        invoiceSequenceFormat = '';
      }
    }
    let itemsPendingToConvert = 0;
    let selectedSO = {
      ...data,
      entityId: data.id ? data.id : undefined,
      documentType: DOC_TYPE.SALES_ORDER,
      recurring: false,
      recurringActivated: false,
      backOrder: false,
      documentCode: data.salesOrderCode,
      documentDate: data.salesOrderDate
        ? data.salesOrderDate
        : data.documentDate,
      validTillDate: data.salesOrderDueDate
        ? data.salesOrderDueDate
        : data.documentDate,
      currency: data.currency,
      currencyCode: data.currency,
      sourceFulfillmentStatus:
          data.fulfillmentType === 'PICK_PACK_SHIP' &&
        data.fulfillmentStatus === 'PARTIAL_FULFILLED'
          ? FULFILLMENT_STATUS.UNFULFILLED
          : data.fulfillmentStatus || FULFILLMENT_STATUS.UNFULFILLED,
      fulfillmentStatus: data.fulfillmentType === 'PICK_PACK_SHIP' &&
        data.fulfillmentStatus === 'PARTIAL_FULFILLED'
          ? FULFILLMENT_STATUS.UNFULFILLED
          : data.fulfillmentStatus || FULFILLMENT_STATUS.UNFULFILLED,
      fulfillmentType: data.fulfillmentType,
      fulfillmentDate: data.salesOrderDueDate
        ? data.salesOrderDueDate
        : data.fulfillmentDate,
      status: !data.id ? DOCUMENT_STATUS.OPEN : data.status,
      salesInvoiceItems: data.salesOrderItems
        ?.filter((item) => Utility.getPendingQtyForConversion(item) > 0)
        .map((item) => {
          const pendingQty = Utility.getPendingQtyForConversion(item);
          if (pendingQty > 0) {
            ++itemsPendingToConvert;
          }

          const qtyToConvert =
            action === SOConversionAction.PARTIAL_INVOICE
              ? Utility.getFulfilledQtyForConversion(item)
              : pendingQty;

          return {
            ...item,
            id: undefined,
            isPartialInvoice: true,
            documentItemCode: undefined,
            fulfillmentByDoc: null,
            linkedQuoteItem: item.id,
            pendingQtyToConvert: pendingQty,
            productQuantity: qtyToConvert,
            uomQuantity: Utility.getUomQuantity(
              qtyToConvert,
              item.documentUOMSchemaDefinition
            ),
            taxDetails: item.taxDetails.map((taxDetail: any) => {
              return {
                ...taxDetail,
                id: null,
                additionalTaxIn: null
              };
            }),
            nonEditableColumns: data.reservedStock
              ? ['productQuantity', 'uom']
              : [],
            reservedQuantitiesData: item.reservedQuantitiesData?.map(
              (reservedQtyItem: any) => ({
                ...reservedQtyItem,
                availableQuantity: Utility.getUomQuantity(
                  reservedQtyItem.availableQuantity,
                  item.documentUOMSchemaDefinition
                ),
                reservedQuantity: Utility.getUomQuantity(
                  reservedQtyItem.reservedQuantity,
                  item.documentUOMSchemaDefinition
                ),
                advancedTrackingMetaDtos: reservedQtyItem
                  .advancedTrackingMetaDtos?.length
                  ? reservedQtyItem.advancedTrackingMetaDtos?.map(
                      (advTrackingDto: any) => {
                        return {
                          ...advTrackingDto,
                          batchSize: Utility.getUomQuantity(
                            advTrackingDto.batchSize,
                            item.documentUOMSchemaDefinition
                          ),
                          reservedQuantity: Utility.getUomQuantity(
                            advTrackingDto.reservedQuantity,
                            item.documentUOMSchemaDefinition
                          ),
                          reservedQuantityFulfilled: Utility.getUomQuantity(
                            advTrackingDto.reservedQuantityFulfilled,
                            item.documentUOMSchemaDefinition
                          ),
                          batchSizeFulfilled: Utility.getUomQuantity(
                            advTrackingDto.batchSizeFulfilled,
                            item.documentUOMSchemaDefinition
                          )
                        };
                      }
                    )
                  : []
              })
            )
          };
        }),
      linkedSalesInvoices: data.linkedSalesInvoices,
      processedInPPS: data.processedInPPS,
      reservedStock: data.reservedStock,
      sequenceFormat: invoiceSequenceFormat,
      isDocumentTouched: true
    };

    if (itemsPendingToConvert === 0) {
      showAlert(
        '',
        'Sorry, all products in this Sales Order have been invoiced in full. You cannot convert this Sales Order to Invoice anymore'
      );
    } else {
      // to set default dates for invoice
      const startDate = DateFormatService.getDateFromStr(
        selectedSO.documentDate,
        BOOKS_DATE_FORMAT['DD-MM-YYYY']
      );
      const endDate = DateFormatService.getDateFromStr(
        selectedSO.validTillDate,
        BOOKS_DATE_FORMAT['DD-MM-YYYY']
      );
      var differentDays = differenceInDays(endDate, startDate);
      const currentDate = new Date();
      const laterDate = addDays(currentDate, differentDays);

      const currentDateStr = DateFormatService.getDateStrFromDate(
        currentDate,
        BOOKS_DATE_FORMAT['DD-MM-YYYY']
      );
      const laterDateStr = DateFormatService.getDateStrFromDate(
        laterDate,
        BOOKS_DATE_FORMAT['DD-MM-YYYY']
      );

      selectedSO = {
        ...selectedSO,
        documentDate: currentDateStr,
        fulfillmentDate: currentDateStr,
        shipByDate: currentDateStr,
        validTillDate: laterDateStr
      };
      switch (action) {
        case SOConversionAction.CONVERT_AND_FULFILL:
          selectedSO = {
            ...selectedSO,
            autoFulfill: true,
            fulfillmentComplete: true
          };
          convertToPartialInvoice(selectedSO);
          break;
        case SOConversionAction.CONVERT_ONLY:
        case SOConversionAction.PARTIAL_INVOICE:
          convertToPartialInvoice(selectedSO);
          break;
        default:
          break;
      }
    }
  };

  return (
    <>
      <div className="flex flex-col w-full px-1 pb-3 text-sm p-1">
        <div className="flex flex-row w-full">
          {`You are about to convert this Sales Order to Invoice, please select an
          action below:`}
        </div>
        <div
          className="flex flex-col mt-3 w-full"
          style={{
            pointerEvents: isApiLoading ? 'none' : 'auto',
            cursor: isApiLoading ? 'no-drop' : 'pointer'
          }}
        >
          {!(
            salesOrder.linkedSalesInvoices &&
            salesOrder.linkedSalesInvoices?.length > 0
          ) && (
            <>
              {!Utility.isAdvancedTracking(salesOrder) &&
                checkUserPermission(
                  PERMISSIONS_BY_MODULE.SALES_ORDER.FULFILL
                ) &&
                !salesOrder.reservedStock &&
                salesOrder.fulfillmentType !==
                  FULFILLMENT_TYPE.PICK_PACK_SHIP &&
                Utility.getDocumentFulfilledQty(
                  salesOrder.salesOrderItems || []
                ) === 0 && (
                  <div
                    className={
                      'flex items-center border-radius-m listPickerBG cursor-hand p-h-l border-m'
                    }
                    onClick={() => {
                      if (!isOpeningForm) {
                        setIsOpeningForm(true);

                        setType(SOConversionAction.CONVERT_AND_FULFILL);
                        setIsApiLoading(true);
                        loadSalesOrderDetails(
                          salesOrder,
                          SOConversionAction.CONVERT_AND_FULFILL
                        );
                      }
                    }}
                  >
                    <div
                      className="flex rounded-l rounded-bl"
                      style={{ height: 80 }}
                    >
                      <img
                        src={ic_convert_fulfill}
                        alt="fulfill and convert"
                        style={{ width: 60 }}
                      />
                    </div>
                    <div
                      className="flex flex-col items-start px-4 leading-5 text-left"
                      style={{ width: '90%' }}
                    >
                      <span className="fw-m text-blue">
                        {localizedText('Fulfill and Convert')}
                      </span>
                      <span className="text-gray">
                        {localizedText(
                          'Fulfill this Sales Order in full before converting in to a Full Invoice.'
                        )}
                      </span>
                    </div>
                    {isApiLoading &&
                      type === SOConversionAction.CONVERT_AND_FULFILL && (
                        <div>
                          <DKSpinner
                            iconClassName="ic-r"
                            className="column pl-0 pr-s"
                          />
                        </div>
                      )}
                  </div>
                )}
              <div
                className={
                  'flex items-center border-radius-m listPickerBG cursor-hand p-h-l border-m mt-3'
                }
                onClick={() => {
                  if (!isOpeningForm) {
                    setIsOpeningForm(true);
                    setType(SOConversionAction.CONVERT_ONLY);
                    setIsApiLoading(true);

                    loadSalesOrderDetails(
                      salesOrder,
                      SOConversionAction.CONVERT_ONLY
                    );
                  }
                }}
              >
                <div
                  className="flex rounded-l rounded-bl"
                  style={{ height: 60 }}
                >
                  <img
                    src={ic_convert_only}
                    alt="convert fully"
                    style={{ width: 60 }}
                  />
                </div>
                <div
                  className="flex flex-col items-start px-4 leading-5 text-left"
                  style={{ width: '90%' }}
                >
                  <span className="fw-m text-blue">Convert Fully</span>
                  <span className="text-gray">
                    {`Convert this Sales Order into a Full Invoice.`}
                  </span>
                </div>
                {isApiLoading && type === SOConversionAction.CONVERT_ONLY && (
                  <div>
                    <DKSpinner
                      iconClassName="ic-r"
                      className="column pl-0 pr-s"
                    />
                  </div>
                )}
              </div>
            </>
          )}
          {!salesOrder.reservedStock && (
            <div
              className={
                'flex items-center border-radius-m listPickerBG cursor-hand p-h-l border-m mt-3'
              }
              onClick={() => {
                if (!isOpeningForm) {
                  setIsOpeningForm(true);

                  setIsApiLoading(true);
                  setType(SOConversionAction.PARTIAL_INVOICE);

                  loadSalesOrderDetails(
                    salesOrder,
                    SOConversionAction.PARTIAL_INVOICE
                  );
                }
              }}
            >
              <div className="flex rounded-l rounded-bl" style={{ height: 60 }}>
                <img
                  src={ic_convert_partially}
                  alt="convert partially"
                  style={{ width: 60 }}
                />
              </div>
              <div
                className="flex flex-col items-start px-4 leading-5 text-left"
                style={{ width: '90%' }}
              >
                <span className="fw-m text-blue">Convert Partially</span>
                <span className="text-gray">
                  {`Create a Partial Invoice from this Sales Order.`}
                </span>
              </div>
              {isApiLoading && type === SOConversionAction.PARTIAL_INVOICE && (
                <div>
                  <DKSpinner
                    iconClassName="ic-r"
                    className="column pl-0 pr-s"
                  />
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
}
