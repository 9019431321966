/* eslint-disable react-hooks/exhaustive-deps */
import { DKLabel, DKListPicker2 } from 'deskera-ui-library';
import { Address } from '../../../../Models/Address';
import {
  getFormattedAddress,
  getTenantTaxSystem
} from '../../../../SharedComponents/DocumentForm/NewDocumentHelper';
import Utility from '../../../../Utility/Utility';
import { useContext, useState } from 'react';
import DocAddContactPopup from './DocAddContactPopup';
import { DOCUMENT_MODE, TAX_SYSTEM } from '../../../../Constants/Constant';
import {
  CONTACT_FORM_TAB,
  ORGANISATION_FORM_TAB
} from '../../../../Constants/Enum';
import {
  calculateTaxesAndAmountsForAllLineItems,
  onDocumentFormFieldUpdate
} from '../../../../Redux/Slices/DocumentSlice';
import { CommonDraftPropsContext } from '../../Utilities/DocContext';
import { useAppDispatch, useAppSelector } from '../../../../Redux/Hooks';
import { PERMISSIONS_BY_MODULE } from '../../../../Constants/Permission';
import { checkUserPermission } from '../../../Settings/GranularPermissions/GranularPermissionsHelper';
import DocOrgUpdatePopup from './DocOrgUpdatePopup';
import { DOCUMENT_KEYS } from '../../Utilities/DocConstants';
import {
  updatePlaceOfSupplyOnAddress,
  updatePlaceOfSupplyOnAddressBuy
} from '../../Helper/DocumentUpdates/PlaceAndDestinationOfSupplyHelper';
import {
  isPriceBookEnabled,
  isPriceListEnabled
} from '../../Utilities/DocCommonUtils';
import {
  CHANGE_TYPE,
  updatePriceFromPriceList
} from '../../Helper/DocumentUpdates/PriceListHelper';
import { calculateTaxesForUS } from '../../Helper/DocRowHelper';
import { Store } from '../../../../Redux/Store';
import { selectContactsColumnConfig } from '../../../../Redux/Slices/ContactsSlice';

interface IDocAddressProps {
  title?: string;
  address: Address | null;
  placeholder?: string;
  isPurchaseDocument?: boolean;
  isSellerInfo?: boolean;
  contact?: any;
  canEdit?: boolean;
  addressList?: Address[];
  dataKey?: 'shipFrom' | 'shipTo' | 'billTo';
  onSelect?: (address: Address) => void;
  preferCustomLocationDetails?: boolean;
  locationDetails?: boolean;
}

const DocAddress = (props: IDocAddressProps) => {
  const { draftId, documentMode } = useContext(CommonDraftPropsContext);
  const {
    title,
    address,
    isPurchaseDocument,
    isSellerInfo,
    canEdit,
    addressList,
    dataKey,
    onSelect,
    preferCustomLocationDetails,
    locationDetails
  } = props;
  const [needAddressPicker, setNeedAddressPicker] = useState(false);
  const [needAddContactPopup, setNeedAddContactPopup] = useState(false);
  const [needUpdateOrgPopup, setNeedUpdateOrgPopup] = useState(false);
  const dispatch = useAppDispatch();
  const contactColumnConfig = useAppSelector(selectContactsColumnConfig);

  let canManageAddress = true;
  if (!isPurchaseDocument && !isSellerInfo) {
    canManageAddress = checkUserPermission(PERMISSIONS_BY_MODULE.CONTACTS.EDIT);
  }

  const customLocationDetails: any = preferCustomLocationDetails
    ? locationDetails
    : null;

  const formattedAddress = getFormattedAddress(
    customLocationDetails?.address || address
  );

  return customLocationDetails ? (
    <div className={`column`}>
      <DKLabel text={title} className="row fw-b fs-r text-gray" />
      {customLocationDetails.orgName ? (
        <DKLabel
          text={customLocationDetails.orgName}
          className="row fw-m fs-r"
        />
      ) : null}
      <DKLabel text={formattedAddress} className="parent-width" />
      {customLocationDetails.phone ? (
        <DKLabel text={customLocationDetails.phone} className="parent-width" />
      ) : null}
      {customLocationDetails.email ? (
        <DKLabel text={customLocationDetails.email} className="parent-width" />
      ) : null}
    </div>
  ) : (
    <div className="column position-relative">
      <div
        className={`row ${canEdit ? 'listPickerBG cursor-pointer' : ''}`}
        onClick={() => canEdit && setNeedAddressPicker(true)}
      >
        <DKLabel
          text={
            Utility.isEmpty(formattedAddress)
              ? props.placeholder || '-'
              : formattedAddress
          }
          className="parent-width"
        />
      </div>
      {needAddressPicker && (
        <DKListPicker2
          data={addressList}
          className="position-absolute z-index-3 bg-white border-m shadow-m"
          style={{ minWidth: 250, top: 0, left: 0 }}
          renderer={(index: number, addressObj: any) => (
            <DKLabel
              style={{
                width: 200,
                whiteSpace: 'pre-wrap'
              }}
              text={getFormattedAddress(addressObj)}
            />
          )}
          button={
            canManageAddress
              ? {
                  /********
                   * @todo tbd, in prev doc, condition was on CREATE contact permission?..
                   */
                  title: 'Manage address',
                  className: 'text-white fw-m bg-button justify-content-center',
                  onClick: () => {
                    if (isPurchaseDocument) {
                      if (isSellerInfo) {
                        setNeedAddContactPopup(true);
                      } else {
                        setNeedUpdateOrgPopup(true);
                      }
                    } else {
                      // Sales document
                      if (isSellerInfo) {
                        setNeedUpdateOrgPopup(true);
                      } else {
                        setNeedAddContactPopup(true);
                      }
                    }
                    setNeedAddressPicker(false);
                  }
                }
              : null
          }
          onSelect={(index: number, addressObj: any) => {
            if (dataKey) {
              dispatch(
                onDocumentFormFieldUpdate({
                  draftId,
                  dataKey: dataKey,
                  value: addressObj
                })
              )
                .then((data: any) => {
                  if (getTenantTaxSystem() === TAX_SYSTEM.INDIA_GST) {
                    if (isPurchaseDocument) {
                      if (dataKey === DOCUMENT_KEYS.SHIP_FROM) {
                        updatePlaceOfSupplyOnAddressBuy(draftId, addressObj);
                      }
                      if (dataKey === DOCUMENT_KEYS.SHIP_TO) {
                        updatePlaceOfSupplyOnAddress(draftId, addressObj, true);
                      }
                    } else {
                      // Sales document
                      if (dataKey === DOCUMENT_KEYS.SHIP_TO) {
                        updatePlaceOfSupplyOnAddress(draftId, addressObj, true);
                      }
                    }
                  }
                  // Update taxes and amount
                  if (
                    dataKey === DOCUMENT_KEYS.SHIP_FROM ||
                    dataKey === DOCUMENT_KEYS.SHIP_TO
                  ) {
                    if (isPriceListEnabled() || isPriceBookEnabled()) {
                      // Price List Call: for contact address change
                      updatePriceFromPriceList({
                        draftId,
                        documentMode,
                        change: {
                          type: CHANGE_TYPE.CONTACT_ADDRESS_CHANGED,
                          rowIndex: null
                        },
                        contactColumnConfig
                      });
                    } else {
                      if (getTenantTaxSystem() === TAX_SYSTEM.US) {
                        calculateTaxesForUS({
                          draftId,
                          indexToUpdate: undefined
                        });
                      } else {
                        Store.dispatch(
                          calculateTaxesAndAmountsForAllLineItems({ draftId })
                        );
                      }
                    }
                  }
                })
                .catch((err) =>
                  console.error('Error updating dataKey: ', dataKey, ', ', err)
                );
            }

            onSelect?.(addressObj);
            setNeedAddressPicker(false);
          }}
          onClose={() => {
            setNeedAddressPicker(false);
          }}
        />
      )}
      {needAddContactPopup && (
        <DocAddContactPopup
          contact={props.contact || {}}
          activeContactTab={CONTACT_FORM_TAB.ADDRESS_INFO}
          contactMode={DOCUMENT_MODE.EDIT}
          onClosePopup={() => setNeedAddContactPopup(false)}
        />
      )}
      {needUpdateOrgPopup && (
        <DocOrgUpdatePopup
          activeOrgProfileTab={ORGANISATION_FORM_TAB.ADDRESS_INFO}
          onClosePopup={() => setNeedUpdateOrgPopup(false)}
        />
      )}
    </div>
  );
};

export default DocAddress;
