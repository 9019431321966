import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../../Redux/Hooks';
import {
  selectDocumentFormDataByKeys,
  updateMultipleKeysInDocument
} from '../../../../Redux/Slices/DocumentSlice';
import { CommonDraftPropsContext } from '../../Utilities/DocContext';
import { DOCUMENT_KEYS } from '../../Utilities/DocConstants';
import { DraftTypes } from '../../../../Models/Drafts';
import { DOC_TYPE } from '../../../../Constants/Constant';

const DocMemoInput = () => {
  const dispatch = useAppDispatch();
  const { draftId, draftType } = useContext(CommonDraftPropsContext);
  const [memo, documentType] = useAppSelector(
    selectDocumentFormDataByKeys(draftId, [
      DOCUMENT_KEYS.MEMO,
      DOCUMENT_KEYS.DOCUMENT_TYPE
    ])
  );
  const [memoVal, setMemoVal] = useState(memo);
  const { t } = useTranslation();

  useEffect(() => {
    if (memo !== memoVal) {
      setMemoVal(memo);
    }
  }, [memo]);

  return (
    <>
      <div
        className={`column ${
          draftType === DraftTypes.READONLY && documentType === DOC_TYPE.INVOICE
            ? 'pointer-events-auto'
            : 'mt-xl'
        }`}
      >
        <textarea
          className="resize-none p-2 border rounded outline-none border-gray-200 hover:border-gray-300 focus:border-gray-400 overflow-auto"
          style={{
            width: 500,
            height: 130,
            backgroundColor: 'rgb(250, 250, 250)',
            border: '1px dashed rgb(200, 200, 200)'
          }}
          placeholder={t(`DOCUMENT.MEMO_OPTIONAL`)}
          value={memoVal}
          onChange={(e: any) => {
            setMemoVal(e.target.value);
          }}
          onBlur={(e: any) => {
            dispatch(
              updateMultipleKeysInDocument({
                draftId,
                keysToUpdate: {
                  [DOCUMENT_KEYS.IS_DOC_TOUCHED]: true,
                  [DOCUMENT_KEYS.MEMO]: e.target.value
                }
              })
            );
          }}
        ></textarea>
      </div>
    </>
  );
};

export default DocMemoInput;
