import { useContext, useEffect, useRef, useState } from 'react';
import { CommonDraftPropsContext } from './Utilities/DocContext';
import DocSellerInfo from './Views/Header/DocSellerInfo';
import DocBalance from './Views/Header/DocBalance';
import DocBuyerInfo from './Views/Header/DocBuyerInfo';
import DocRightInfoPanel from './Views/Header/DocRightInfoPanel';
import DocCustomFieldHolder from './Views/Header/DocCustomFieldHolder';
import DocGrid from './Views/Grid/DocGrid';
import DocGridActions from './Views/Grid/DocGridActions';
import DocMemoInput from './Views/Footer/DocMemoInput';
import DocAttachment from './Views/Footer/DocAttachment';
import DocActions from './Views/Footer/DocActions';
import DocSummary from './Views/Footer/DocSummary';
import { DraftTypes } from '../../Models/Drafts';
import { isDocumentFullScreenON } from '../../SharedComponents/DocumentForm/NewDocumentHelper';
import { useAppDispatch, useAppSelector } from '../../Redux/Hooks';
import { shallowEqual } from 'react-redux';
import {
  onDocumentClose,
  selectDocumentFormDataByKeys
} from '../../Redux/Slices/DocumentSlice';
import DocCommonInputs from './Views/Header/DocCommonInputs';
import {
  COUNTRY_CODES,
  DOCUMENT_MODE,
  DOC_TYPE
} from '../../Constants/Constant';
import { useHistory } from 'react-router-dom';
import { removeDraft } from '../../Redux/Slices/DraftsSlice';
import { DOCUMENT_KEYS } from './Utilities/DocConstants';
import DocPopUpHolder from './Views/Common/DocPopupHolder';
import DocEInvoice from './Views/Header/DocEInvoice';
import DocEInvoiceDetails from './Views/Footer/DocEInvoiceDetails';
import AmortizationService from '../../Services/Amortization';
import {
  activeTenantInfo,
  featurePermissions,
  tenantSetupStauts
} from '../../Redux/Slices/AuthSlice';
import { FEATURE_PERMISSIONS } from '../../Constants/Permission';
import {
  COMMON_EVENTS,
  commonCustomEvent,
  DOC_POPUP_TYPE
} from '../../Services/event/commonEvents';
import { docReplaceURLToModuleURL } from './Utilities/DocCommonUtils';
import Utility from '../../Utility/Utility';
import ComponentDetailsForFG from '../../SharedComponents/DocumentForm/ComponentDetailsForFG';
import useScreenResize from '../../Hooks/useScreenResize';
import { DocumentConfigUtility } from '../../Utility/DocumentConfigUtility';

interface CanvasProps {
  updateDocWithoutClosing?: () => void;
}

const Canvas = (props: CanvasProps) => {
  const dispatch = useAppDispatch();
  let history = useHistory();
  const { draftId, documentMode, draftType } = useContext(
    CommonDraftPropsContext
  );
  const [documentType, reservedStock, showComponentList, items] =
    useAppSelector(
      selectDocumentFormDataByKeys(draftId, [
        DOCUMENT_KEYS.DOCUMENT_TYPE,
        DOCUMENT_KEYS.RESERVED_STOCK,
        DOCUMENT_KEYS.SHOW_COMPONENT_LIST,
        DOCUMENT_KEYS.ITEMS
      ]),
      shallowEqual
    );
  const copyOfDocument = useRef<any>({ reservedStock: reservedStock });

  const isDocumentInFullScreen = isDocumentFullScreenON(documentType);

  const tenantInfo = useAppSelector(activeTenantInfo);
  const tenantSetupStatus = useAppSelector(tenantSetupStauts);
  const featurePermissionsInfo = useAppSelector(featurePermissions);
  const [amortizationTemplates, setAmortizationTemplates] = useState<any[]>([]);

  const formBodyWidthRef = useRef<HTMLDivElement | null>(null);
  const [formBodyWidth] = useScreenResize(formBodyWidthRef);

  useEffect(() => {
    DocumentConfigUtility.documentMode = documentMode;
    DocumentConfigUtility.documentType = documentType;
  }, []);

  useEffect(() => {
    const loadAllAmortizationTemplates = async () => {
      let limit = 100;
      try {
        const templates = await AmortizationService.getAllAmortizationTemplates(
          limit
        );
        setAmortizationTemplates(
          templates?.content?.length ? templates.content : []
        );
      } catch (err: any) {
        console.error('Error loading amortization templates: ', err);
      }
    };
    if (
      documentType === DOC_TYPE.BILL &&
      featurePermissionsInfo?.Supported?.includes(
        FEATURE_PERMISSIONS.AMORTIZATION
      ) &&
      tenantInfo?.additionalSettings?.AMORTIZATION
    ) {
      loadAllAmortizationTemplates();
    }
    // Show PEPPOL banner for SG
    if (
      documentType === DOC_TYPE.INVOICE &&
      tenantInfo.country === COUNTRY_CODES.SG &&
      !tenantInfo?.peppolOptIn &&
      !tenantSetupStatus?.onboardingStatusInfo?.sgEInvoiceChecked
    ) {
      commonCustomEvent.dispatch(COMMON_EVENTS.DOC_POPUP_SHOW, {
        type: DOC_POPUP_TYPE.SHOW_PEPPOL_INVOICENOW_POPUP
      });
    }
  }, []);

  return (
    <div className="document-form dk-input-holder parent-width parent-height overflow-y-scroll show-scroll-bar">
      <div
        className={`column parent-width flex-1 pb-xxl pt-r overflow-y-auto position-relative ${
          isDocumentInFullScreen ? 'p-h-xl' : 'p-h-r'
        } ${
          draftType === DraftTypes.READONLY
            ? 'pointer-events-none'
            : 'pointer-events-auto'
        }`}
      >
        {/*************************************
         ******* DOCUMENT HEADER LAYOUT *******
         *************************************/}
        <DocEInvoice />
        <div className="row justify-content-between align-items-start">
          <DocSellerInfo />
          <DocBalance />
        </div>

        {/*************************************
         ***** DOCUMENT SUB HEADER LAYOUT *****
         *************************************/}
        <div className="column parent-width">
          <div className="row justify-content-between align-items-stretch mt-l gap-2">
            <div className="column flex-1 gap-4">
              <DocBuyerInfo />
              {!isDocumentInFullScreen && (
                <>
                  <DocCommonInputs />
                  <DocCustomFieldHolder />
                </>
              )}
            </div>

            {/* <DocBalanceView /> */}
            <DocRightInfoPanel />
          </div>
          {isDocumentInFullScreen && (
            <div className="column parent-width mt-l gap-4">
              <DocCommonInputs />
              <DocCustomFieldHolder />
            </div>
          )}
        </div>

        {/*************************************
         ******* DOCUMENT CENTER LAYOUT *******
         *************************************/}
        <DocGrid
          amortizationTemplates={amortizationTemplates}
          copyOfDocument={copyOfDocument.current}
        />
        <div className="row">
          <DocGridActions copyOfDocument={copyOfDocument.current} />
        </div>

        {/*************************************
         ******* DOCUMENT FOOTER LAYOUT *******
         *************************************/}
        <div
          ref={formBodyWidthRef}
          className="row align-items-start justify-content-between box-border mt-m"
        >
          {/* <div className="row justify-content-between"> */}
          {/* Document Footer Left Panel */}
          <div className="column flex-1">
            {Utility.isComponentDetailsForFGOnInvoiceSOQuote() &&
              showComponentList && (
                <div className="mt-l">
                  <ComponentDetailsForFG
                    componentGridWidth={formBodyWidth / 2 + 100}
                    booksDocument={{ items }}
                  />
                </div>
              )}

            <DocMemoInput />
            <DocAttachment />
            {(documentMode === DOCUMENT_MODE.EDIT ||
              documentMode === DOCUMENT_MODE.VIEW) && (
              <DocActions
                position="bottom"
                updateWithoutClosing={() => {
                  commonCustomEvent.dispatch(
                    COMMON_EVENTS.UPDATE_DOC_WITHOUT_CLOSING,
                    {}
                  );
                }}
                // auditLogView={props.auditLogView}
                closeMenuContainer={() => {
                  dispatch(onDocumentClose({ draftId }));
                  dispatch(removeDraft(draftId));
                  docReplaceURLToModuleURL(history);
                }}
              />
            )}
            <DocEInvoiceDetails />
          </div>
          {/* Document Footer Right Panel */}
          <div
            className={documentMode === DOCUMENT_MODE.VIEW ? 'mt-r' : ''}
            style={{ minWidth: 330 }}
          >
            <DocSummary />
          </div>
        </div>
        <DocPopUpHolder amortizationTemplates={amortizationTemplates} />
      </div>
    </div>
  );
};

export default Canvas;
