import ApiConstants from '../Constants/ApiConstants';
import Utility from '../Utility/Utility';
import http from './Interceptor';
import http2 from './Interceptor2';

export interface AdditionalChargeAPIConfig {
  SearchTerm?: string;
  Limit?: number;
  Page?: number;
  SortDir?: string;
  Sort?: string;
  Dir?: string;
  Query?: string;
  QueryParam?: any;
}

const defaultConfig: AdditionalChargeAPIConfig = {
  SearchTerm: '',
  Limit: 25,
  Page: 0
};

export default class AdditionalChargeService {
  static apiConfig: AdditionalChargeAPIConfig;

  static fetchAllAdditionalCharge() {
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    }
    return http.get(ApiConstants.URL.ADDITIONAL_COST.GET).catch((err: any) => {
      return Promise.reject(err);
    });
  }
  static createAdditionalCharge(req: any) {
    return http
      .post(ApiConstants.URL.ADDITIONAL_COST.POST, req)
      .catch((err: any) => {
        return Promise.reject(err);
      });
  }
  static updateAdditionalCharge(req: any) {
    return http
      .put(ApiConstants.URL.ADDITIONAL_COST.PUT(req.id), req)
      .catch((err: any) => {
        return Promise.reject(err);
      });
  }
  static deleteAdditionalCharge(req: any) {
    return http2
      .delete(ApiConstants.URL.ADDITIONAL_COST.DELETE(req.id))
      .catch((err: any) => {
        return Promise.reject(err);
      });
  }
  static getDiscountUrl() {
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    }
    const queryString: string = `?search=${this.apiConfig.SearchTerm}`;
    const finalEndpoint: string = 'products/additional-charge' + queryString;
    return finalEndpoint;
  }
}
