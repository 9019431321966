import { showAlert } from 'deskera-doc-builder-lib';
import { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  COMPLAINCE_CURRENCY,
  DOCUMENT_MODE,
  DOC_TYPE,
  MODULES_NAME,
  POPUP_CALLBACKS_TYPE,
  POPUP_CLICK_TYPE,
  POPUP_TYPE,
  PRODUCT_TYPE,
  TAX_SYSTEM,
  TAX_TYPES
} from '../../../../Constants/Constant';
import {
  BtnType,
  CallBackPayloadType,
  LoadingBtnType,
  PopupClickActionType,
  UpdateCallBacksRefType
} from '../../../../Models/Interfaces';
import { useAppDispatch, useAppSelector } from '../../../../Redux/Hooks';
import {
  calculateTaxesAndAmountsForAllLineItems,
  selectColumns,
  selectDocumentFormDataByKeys,
  updateMultipleKeysInDocument
} from '../../../../Redux/Slices/DocumentSlice';
import {
  COMMON_EVENTS,
  DOC_POPUP_TYPE,
  commonCustomEvent
} from '../../../../Services/event/commonEvents';
import DocProductSelectionPopup from '../../../../SharedComponents/DocumentForm/DocProductSelectionPopup';
import HSNForm from '../../../../SharedComponents/DocumentForm/HSNForm';
import ItcOptions from '../../../../SharedComponents/DocumentForm/ItcOptions';
import TDSCalculation from '../../../../SharedComponents/DocumentForm/TDSCalculation';
import PopupWrapper from '../../../../SharedComponents/PopupWrapper';
import Utility from '../../../../Utility/Utility';
import BarcodeSearchPopup from '../../../BarcodeSerach/BarcodeSearchPopUp';
import CreateProductView from '../../../Product/CreateProductView';
import AddTax from '../../../Settings/Tax/AddTax';
import TaxGroupDistribution from '../../../Tax/TaxGroupDistribution';
import {
  calculateLineItemTaxesAndAmount,
  calculateTaxesForUS,
  onRowUpdate
} from '../../Helper/DocRowHelper';
import {
  DOCUMENT_KEYS,
  DOC_LINE_ITEM_KEYS
} from '../../Utilities/DocConstants';
import { CommonDraftPropsContext } from '../../Utilities/DocContext';
import { DraftTypes } from '../../../../Models/Drafts';
import { PaymentMileStone } from '../../../Common/PaymentMileStone';
import { getDataForMilestonePopup } from '../../../../Services/PaymentMilestone';
import ReserveStockPopUp from '../../../Invoices/ReserveStockPopUp';
import CustomFieldSettings from '../../../../SharedComponents/CustomFieldsHolder/CustomFieldSettings';
import {
  fetchClassesByDimensionId,
  fetchProductCustomFields,
  selectIsLoadingPopupWrapper,
  selectProductCustomFields
} from '../../../../Redux/Slices/CommonDataSlice';
import { GSTExchangeRateDialog } from '../../../../SharedComponents/DocumentForm/GSTExchangeRateDialog';
import AuthService from '../../../../Services/Auth';
import AddClass from '../../../Settings/Classes/AddClass';
import { fetchCategoryDimensions } from '../../../../Redux/Slices/LocationSlice';
import AllocateLandedCost from '../../../../SharedComponents/DocumentForm/AllocateLandedCost';
import CancelEInvoice from '../../../Settings/OrganisationProfile/CancelEInvoice';
import InvoiceService from '../../../../Services/Invoice';
import { getUpdatedInvoiceObject } from '../../../Invoices/InvoiceHelper';
import { IRPCredPopup } from '../../../Settings/OrganisationProfile/IRPCredPopup';
import {
  activeTenantInfo,
  eInvoiceAuthInfo,
  fetchEInvoiceAuthInfo
} from '../../../../Redux/Slices/AuthSlice';
import EInvoiceService from '../../../../Services/EInvoice';
import GSTValueForm from '../../../../SharedComponents/DocumentForm/GSTValueEdit';
import { cloneDeep } from 'lodash';
import {
  getComponentDetailsForDocumentLineItems,
  getTenantTaxSystem
} from '../../../../SharedComponents/DocumentForm/NewDocumentHelper';
import {
  getBlankRowItem,
  isPriceListEnabled,
  isSalesDocument
} from '../../Utilities/DocCommonUtils';
import { getDocumentByIDFromStore } from '../../Helper/DocumentHelper';
import {
  CHANGE_TYPE,
  updatePriceFromPriceList
} from '../../Helper/DocumentUpdates/PriceListHelper';
import { selectContactsColumnConfig } from '../../../../Redux/Slices/ContactsSlice';
import DocAddContactPopup from './DocAddContactPopup';
import { CONTACT_FORM_TAB } from '../../../../Constants/Enum';
import Peppol from '../../../../SharedComponents/Peppol';
import TaxValueEditForm from '../../../../SharedComponents/DocumentForm/TaxValueEditForm';

interface IDocPopUpHolderProps {
  amortizationTemplates?: any[];
}

const DocPopUpHolder: React.FC<IDocPopUpHolderProps> = (props) => {
  const [showPopup, setShowPopup] = useState<DOC_POPUP_TYPE>(
    DOC_POPUP_TYPE.NONE
  );
  const [eventData, setEventData] = useState<any>(null);
  const { draftId, documentMode, draftType } = useContext(
    CommonDraftPropsContext
  );
  const dispatch = useAppDispatch();
  const [
    id,
    items,
    documentType,
    currency,
    gstExchangeRate,
    landedProducts,
    salesInvoiceCode,
    contact,
    applyRcmCheck
  ] = useAppSelector(
    selectDocumentFormDataByKeys(draftId, [
      DOCUMENT_KEYS.ID,
      DOCUMENT_KEYS.ITEMS,
      DOCUMENT_KEYS.DOCUMENT_TYPE,
      DOCUMENT_KEYS.CURRENCY,
      DOCUMENT_KEYS.GST_EXCHANGE_RATE,
      DOCUMENT_KEYS.LANDED_PRODUCTS,
      DOCUMENT_KEYS.SALES_INVOICE_CODE,
      DOCUMENT_KEYS.CONTACT,
      DOCUMENT_KEYS.APPLY_RCM_CHECK
    ])
  );
  const document: any = getDocumentByIDFromStore(draftId) ?? {};
  const booksDocument = !Utility.isEmpty(document)
    ? { ...document?.populateFormData }
    : {};

  const refInitialState: UpdateCallBacksRefType = {
    pushDataToParent: { type: POPUP_CALLBACKS_TYPE.NONE },
    storeCallbacksRef: { updateContact: 'click' }
  };

  const popupWrapperRefObject = useRef<UpdateCallBacksRefType>(refInitialState);
  const { t } = useTranslation();
  const currentEventData = useRef<any>();
  const popupLoading = useAppSelector(selectIsLoadingPopupWrapper);
  const [popupIsLoading, setPopupIsLoading] = useState<boolean>(false);
  const tenantInfo = useAppSelector(activeTenantInfo);
  const eInvoiceAuthInfoData = useAppSelector(eInvoiceAuthInfo);
  const columns = useAppSelector(selectColumns());
  const productCFData = useAppSelector(selectProductCustomFields);
  const contactColumnConfig = useAppSelector(selectContactsColumnConfig);

  useEffect(() => {
    commonCustomEvent.on(COMMON_EVENTS.DOC_POPUP_SHOW, handlePopUpShow, true);
    return () => {
      commonCustomEvent.remove(COMMON_EVENTS.DOC_POPUP_SHOW, handlePopUpShow);
    };
  }, []);

  const hideDocPopupHolder = () => {
    setPopupIsLoading(false);
    setShowPopup(DOC_POPUP_TYPE.NONE);
  };

  const handlePopUpShow = (eventData: { detail: any }) => {
    const data = eventData?.detail;
    if (!data) return;
    setEventData(data);
    // implement switch if required
    switch (data.type) {
    }
    currentEventData.current = data;
    setShowPopup(data.type);
  };

  // ** Catch Clicks
  const catchClicks = (data: PopupClickActionType) => {
    switch (data.type) {
      case POPUP_CLICK_TYPE.CLOSE_POPUP:
        hideDocPopupHolder();
        break;
      case POPUP_CLICK_TYPE.CREATE_TAX:
        popupWrapperRefObject.current?.storeCallbacksRef.createTax();
        break;
      case POPUP_CLICK_TYPE.SUBMIT_TDS_AMOUNT:
        popupWrapperRefObject.current.storeCallbacksRef.submitTDSAmount();
        break;
      case POPUP_CLICK_TYPE.IRP_CREDENTIAL:
        popupWrapperRefObject.current?.storeCallbacksRef.createIRPCredentials();
        break;
      case POPUP_CLICK_TYPE.SAVE_PAYMENT_MILESTONE:
        setPopupIsLoading(true);
        popupWrapperRefObject.current?.storeCallbacksRef?.savePaymentMileStone();
        break;
    }
  };

  // ** parentChildInteraction
  const parentChildInteraction = (passingData: CallBackPayloadType) => {
    switch (passingData.type) {
      case POPUP_CALLBACKS_TYPE.CREATE_TAX:
        popupWrapperRefObject.current.storeCallbacksRef.createTax =
          passingData.data;
        break;
      case POPUP_CALLBACKS_TYPE.CREATE_TAX_SUCCESS:
        if (
          typeof eventData?.rowIndex !== 'undefined' &&
          eventData?.rowIndex !== null
        ) {
          const allowedTaxType = [TAX_TYPES.BOTH];
          if (isSalesDocument(documentType)) {
            allowedTaxType.push(TAX_TYPES.SALES);
          } else {
            allowedTaxType.push(TAX_TYPES.PURCHASE);
          }
          if (allowedTaxType.includes(passingData.data?.type)) {
            let rows = onRowUpdate({
              draftId,
              documentMode,
              ...eventData,
              rowData: {
                ...eventData?.rowData,
                [DOC_LINE_ITEM_KEYS.TAX]: passingData.data
              }
            });
            rows[eventData.rowIndex] = calculateLineItemTaxesAndAmount(
              rows[eventData.rowIndex],
              documentType,
              contact,
              rows[eventData.rowIndex]?.gstType,
              applyRcmCheck
            );
            dispatch(
              updateMultipleKeysInDocument({
                draftId,
                keysToUpdate: {
                  [DOCUMENT_KEYS.ITEMS]: rows
                }
              })
            );
          }
        }
        break;
      case POPUP_CALLBACKS_TYPE.DEDUCT_TDS_SUBMIT:
        popupWrapperRefObject.current.storeCallbacksRef.submitTDSAmount =
          passingData.data;
        break;
      case POPUP_CALLBACKS_TYPE.DEDUCT_TDS_SUCCESS:
        if (passingData?.data?.incomePayment) {
          onTDSDeduct(passingData.data);
          hideDocPopupHolder();
        }
        break;
      case POPUP_CALLBACKS_TYPE.CLOSE_POPUP:
        hideDocPopupHolder();
        break;
      case POPUP_CALLBACKS_TYPE.IRP_CREDENTIAL:
        popupWrapperRefObject.current.storeCallbacksRef.createIRPCredentials =
          passingData.data;
        break;
      case POPUP_CALLBACKS_TYPE.SAVE_PAYMENT_MILESTONE:
        popupWrapperRefObject.current.storeCallbacksRef.savePaymentMileStone =
          passingData.data;
        break;
    }
  };

  // **  Create Product Popup
  const getCreateProductPopUp = () => {
    return (
      <CreateProductView
        product={eventData?.product}
        needAdvanceTrackPopupAfterSave={true}
        passingInteraction={(callback: CallBackPayloadType) => {}}
        onCancel={() => {
          hideDocPopupHolder();
        }}
        selectedTab={Utility.isNotEmpty(eventData?.product) ? 3 : 0}
        onSuccess={(product: any) => {
          hideDocPopupHolder();
          if (Utility.isNotEmpty(product)) {
            const items = onRowUpdate({
              draftId,
              documentMode,
              ...eventData,
              rowData: {
                ...eventData?.rowData,
                [DOC_LINE_ITEM_KEYS.PRODUCT]: product
              }
            });
            dispatch(
              updateMultipleKeysInDocument({
                draftId,
                keysToUpdate: { [DOCUMENT_KEYS.ITEMS]: items }
              })
            );
          }
        }}
      />
    );
  };

  const getCreateTaxPopup = () => {
    return (
      <PopupWrapper
        clickAction={catchClicks}
        type={POPUP_TYPE.POPUP}
        title={t(`SETTINGS.TAX.ADD_TAX_RATE`)}
        btnList={[
          {
            title: t(`SETTINGS.TAX.BUTTON.CANCEL`),
            class: 'border-m mr-s',
            clickAction: POPUP_CLICK_TYPE.CLOSE_POPUP
          },
          {
            title: t(`SETTINGS.TAX.BUTTON.CREATE`),
            class: 'bg-app text-white mr-ss',
            clickAction: POPUP_CLICK_TYPE.CREATE_TAX
          }
        ]}
        width="45%"
        height="75%"
      >
        <AddTax
          populateFormData={null}
          passingInteraction={(callback: CallBackPayloadType) => {
            parentChildInteraction(callback);
          }}
        />
      </PopupWrapper>
    );
  };

  const onHSNChange = (hsnOrSacCode: string) => {
    if (Utility.isNotEmpty(hsnOrSacCode)) {
      let copyItems = cloneDeep(items);
      copyItems[eventData.rowIndex][DOC_LINE_ITEM_KEYS.HSN_OR_SAC] =
        hsnOrSacCode;
      dispatch(
        updateMultipleKeysInDocument({
          draftId,
          keysToUpdate: {
            [DOCUMENT_KEYS.ITEMS]: copyItems
          }
        })
      );
    }
  };

  const getHSNForm = () => {
    return (
      <HSNForm
        HSNData={{
          hsnVal: currentEventData.current?.rowData?.hsnOrSacCode,
          rowIndex: currentEventData.current?.rowIndex
        }}
        setHSNChangeValue={(data: any) => {
          onHSNChange(data?.hsnValue);
          hideDocPopupHolder();
        }}
        onClose={() => {
          hideDocPopupHolder();
        }}
      />
    );
  };

  /**
   * Update/Add tdsInfoIndia in line item
   */
  const onTDSDeduct = (tdsData: any) => {
    let copyOfItems = [...items];
    if (copyOfItems[eventData.rowIndex]) {
      const product = copyOfItems[eventData.rowIndex]?.product;
      copyOfItems[eventData.rowIndex] = {
        ...copyOfItems[eventData.rowIndex],
        [DOC_LINE_ITEM_KEYS.IS_DEDUCTED_TDS]: true,
        [DOC_LINE_ITEM_KEYS.TDS_INFO_INDIA]: tdsData,
        [DOC_LINE_ITEM_KEYS.IS_TDS_APPLICABLE_CONTACT]:
          contact?.tdsInfoIndia?.deductionApplicable,
        [DOC_LINE_ITEM_KEYS.IS_TDS_APPLICABLE_PRODUCT]:
          product?.tdsApplicableIndia,
        [DOC_LINE_ITEM_KEYS.IS_TDS_APPLICABLE_ACCOUNT]: false
      };
      dispatch(
        updateMultipleKeysInDocument({
          draftId,
          keysToUpdate: {
            [DOCUMENT_KEYS.ITEMS]: copyOfItems
          }
        })
      );
    }
  };

  const getGetTDSCalculationPopup = () => {
    return (
      <PopupWrapper
        clickAction={catchClicks}
        type={POPUP_TYPE.POPUP}
        title={`TDS Calculation`}
        btnList={[
          {
            title: `Cancel`,
            class: 'border-m mr-s',
            clickAction: POPUP_CLICK_TYPE.CLOSE_POPUP
          },
          {
            title: `Submit`,
            class: 'bg-app text-white mr-ss',
            clickAction: POPUP_CLICK_TYPE.SUBMIT_TDS_AMOUNT
          }
        ]}
        width="40%"
        minHeight={200}
        maxHeight="90%"
        disableClickOutside={true}
      >
        <TDSCalculation
          passingInteraction={(callback: CallBackPayloadType) => {
            parentChildInteraction(callback);
          }}
          tdsData={eventData?.tdsInfoData}
        />
      </PopupWrapper>
    );
  };

  const onITCOptionSelected = (itcData: any) => {
    let copyOfItems = [...items];
    if (!Utility.isEmpty(copyOfItems[eventData.rowIndex])) {
      copyOfItems[eventData.rowIndex] = {
        ...copyOfItems[eventData.rowIndex],
        [DOC_LINE_ITEM_KEYS.ITC_INELIGIBLE_TYPE]: itcData?.itcValue
      };
      dispatch(
        updateMultipleKeysInDocument({
          draftId,
          keysToUpdate: {
            [DOCUMENT_KEYS.ITEMS]: copyOfItems
          }
        })
      );
    }
  };

  const getITCPopup = () => {
    return (
      <ItcOptions
        itcData={eventData?.itcValue}
        setITCIneligibleOption={(data: any) => {
          onITCOptionSelected(data);
          hideDocPopupHolder();
        }}
        onClose={() => {
          hideDocPopupHolder();
        }}
      />
    );
  };

  const updateRowItemsForProductGroupOrBarcodeData = async (
    copyOfItems: any[],
    updateFromIndex: number,
    forBarcode = false
  ) => {
    const isPriceBookEnabled = tenantInfo.isAdvancePriceListEnabled;
    if (isPriceListEnabled() || isPriceBookEnabled) {
      const priceListResponse = await updatePriceFromPriceList({
        draftId,
        documentMode,
        change: {
          type: forBarcode
            ? CHANGE_TYPE.PRODUCTS_ADDED_FROM_BARCODE_POPUP
            : CHANGE_TYPE.PRODUCTS_ADDED_FROM_PRODUCT_GROUP_POPUP,
          updateFromIndex
        },
        contactColumnConfig
      });

      copyOfItems = copyOfItems?.map((copyOfItem: any, index: number) => {
        if (index >= updateFromIndex) {
          let priceListForProduct = priceListResponse?.find(
            (item: any) => item.productId === copyOfItem?.productCode
          );
          const priceToCompare = copyOfItem.unitPrice;
          if (
            priceListForProduct &&
            priceListForProduct?.priceListShortInfos?.length > 0 &&
            priceListForProduct?.price !== priceToCompare &&
            priceListForProduct?.price > 0
          ) {
            copyOfItem.unitPrice = priceListForProduct?.price;
          }
        }
        return copyOfItem;
      });

      if (Utility.isComponentDetailsForFGOnInvoiceSOQuote()) {
        if (
          copyOfItems?.some(
            (rowObj: any) =>
              rowObj?.product?.type === PRODUCT_TYPE.BILL_OF_MATERIALS
          )
        ) {
          copyOfItems = await getComponentDetailsForDocumentLineItems(
            copyOfItems,
            contact,
            documentType
          );
        }
      }

      dispatch(
        updateMultipleKeysInDocument({
          draftId,
          keysToUpdate: { [DOCUMENT_KEYS.ITEMS]: copyOfItems }
        })
      );
    }

    if (getTenantTaxSystem() === TAX_SYSTEM.US) {
      calculateTaxesForUS({
        draftId,
        indexToUpdate: undefined,
        updatedItems: copyOfItems
      });
    } else {
      dispatch(
        calculateTaxesAndAmountsForAllLineItems({
          draftId,
          [DOCUMENT_KEYS.ITEMS]: copyOfItems
        })
      );
    }
    hideDocPopupHolder();
  };

  const onRowsSelectionFromProductGroupPopup = (selectedProducts: any[]) => {
    let copyOfItems = cloneDeep(items);
    let length = copyOfItems.length;

    selectedProducts.forEach((element: any, index: number) => {
      const blankItem = getBlankRowItem(
        document,
        JSON.parse(columns),
        documentMode,
        draftType,
        productCFData?.content ?? [],
        length + index
      );
      copyOfItems.push(blankItem);
    });
    dispatch(
      updateMultipleKeysInDocument({
        draftId,
        keysToUpdate: { [DOCUMENT_KEYS.ITEMS]: copyOfItems }
      })
    );
    selectedProducts.forEach((element: any, index: number) => {
      const updatedItems = onRowUpdate({
        draftId,
        columnKey: DOC_LINE_ITEM_KEYS.PRODUCT,
        documentMode,
        rowIndex: length + index,
        rowData: {
          ...copyOfItems[length + index],
          [DOC_LINE_ITEM_KEYS.PRODUCT]: element
        },
        amortizationTemplates: props.amortizationTemplates || [],
        columnConfig: JSON.parse(columns),
        productCustomFields: productCFData?.content ?? []
      });
      copyOfItems[length + index] = updatedItems[length + index];
    });
    dispatch(
      updateMultipleKeysInDocument({
        draftId,
        keysToUpdate: { [DOCUMENT_KEYS.ITEMS]: copyOfItems }
      })
    );

    updateRowItemsForProductGroupOrBarcodeData([...copyOfItems], length);
  };

  const getProductSelectionPopup = () => {
    return (
      <DocProductSelectionPopup
        module={documentType}
        onSave={onRowsSelectionFromProductGroupPopup}
        onCancel={() => hideDocPopupHolder()}
      />
    );
  };

  const onRowsSelectionFromBarcodePopup = (selectedProducts: any[]) => {
    let copyOfItems = cloneDeep(items);
    let length = copyOfItems.length;

    selectedProducts.forEach((element: any, index: number) => {
      const blankItem = getBlankRowItem(
        document,
        JSON.parse(columns),
        documentMode,
        draftType,
        productCFData?.content ?? [],
        length + index
      );
      copyOfItems.push(blankItem);
    });
    dispatch(
      updateMultipleKeysInDocument({
        draftId,
        keysToUpdate: { [DOCUMENT_KEYS.ITEMS]: copyOfItems }
      })
    );
    selectedProducts.forEach((element: any, index: number) => {
      const updatedItems = onRowUpdate({
        draftId,
        columnKey: DOC_LINE_ITEM_KEYS.PRODUCT,
        documentMode,
        rowIndex: length + index,
        rowData: {
          ...copyOfItems[length + index],
          [DOC_LINE_ITEM_KEYS.PRODUCT]: element
        },
        amortizationTemplates: props.amortizationTemplates || [],
        columnConfig: JSON.parse(columns),
        productCustomFields: productCFData?.content ?? [],
        isAddedFromBarcode: true
      });
      copyOfItems[length + index] = updatedItems[length + index];
    });
    dispatch(
      updateMultipleKeysInDocument({
        draftId,
        keysToUpdate: { [DOCUMENT_KEYS.ITEMS]: copyOfItems }
      })
    );

    updateRowItemsForProductGroupOrBarcodeData([...copyOfItems], length, true);
  };

  const getBarcodePopup = () => {
    return (
      <BarcodeSearchPopup
        isSellModule={
          documentType === DOC_TYPE.INVOICE ||
          documentType === DOC_TYPE.SALES_ORDER ||
          documentType === DOC_TYPE.QUOTE
        }
        onCancel={() => {
          hideDocPopupHolder();
        }}
        onDone={(data: any) => {
          onRowsSelectionFromBarcodePopup(data);
        }}
      />
    );
  };

  const getTaxGroupDistributionPopup = () => {
    const rowIndex = eventData.rowIndex;
    let lineItem = { ...items?.[rowIndex] };
    const isTaxGroup = lineItem?.tax?.isTaxGroup;
    if (isTaxGroup) {
      const taxDetails =
        !Utility.isEmpty(lineItem?.taxDetails) && lineItem?.taxDetails?.length
          ? [...lineItem?.taxDetails]
          : [];
      return (
        <TaxGroupDistribution
          taxList={taxDetails}
          onCancel={() => {
            hideDocPopupHolder();
          }}
          onApply={(taxList: any[]) => {
            const totalTaxAmount = taxList?.reduce(
              (prev: number, current: any) => prev + Number(current.taxAmount),
              0
            );
            const totalAmount =
              lineItem.subTotal - lineItem.discountAmount + totalTaxAmount;

            lineItem = {
              ...lineItem,
              taxAmount: totalTaxAmount,
              totalAmount: totalAmount,
              total: totalAmount,
              taxDetails: taxList
            };

            let copyOfItems = cloneDeep(items);
            copyOfItems[rowIndex] = lineItem;

            dispatch(
              updateMultipleKeysInDocument({
                draftId,
                keysToUpdate: {
                  [DOCUMENT_KEYS.ITEMS]: copyOfItems
                }
              })
            );
            hideDocPopupHolder();
          }}
        />
      );
    } else {
      hideDocPopupHolder();
    }
  };

  const getPaymentMilestoneFormPopup = () => {
    const loadingPaymentMileStoneBtnConfig: LoadingBtnType[] = [
      {
        title: 'Cancel',
        class: 'border-m mr-s',
        type: 'CLOSE'
      },
      {
        title: 'Saving',
        class: 'bg-app text-white mr-ss',
        type: 'ACTION'
      }
    ];
    const paymentMilestonePopupBtnConfig: BtnType[] = [
      {
        title: 'Cancel',
        class: 'bg-gray1 border-m mr-s',
        clickAction: POPUP_CLICK_TYPE.CLOSE_POPUP
      },
      {
        title: 'Save',
        class: 'bg-app text-white mr-s',
        clickAction: POPUP_CLICK_TYPE.SAVE_PAYMENT_MILESTONE
      }
    ];
    const isReadOnly = draftType === DraftTypes.READONLY;
    return (
      <PopupWrapper
        disableClickOutside={true}
        clickAction={catchClicks}
        btnList={
          !isReadOnly
            ? paymentMilestonePopupBtnConfig
            : [paymentMilestonePopupBtnConfig[0]]
        }
        loadingBtnList={loadingPaymentMileStoneBtnConfig}
        isLoading={popupIsLoading}
        type={POPUP_TYPE.POPUP}
        title={`${isReadOnly ? 'View' : 'Add'} payment Milestones`}
        height={'60%'}
        width={'60%'}
      >
        <PaymentMileStone
          document={{
            ...getDataForMilestonePopup(documentType, booksDocument)
          }}
          allowEdit={!isReadOnly}
          onCancel={() => {
            hideDocPopupHolder();
          }}
          onSave={() => {
            hideDocPopupHolder();
          }}
          onError={() => {
            setPopupIsLoading(false);
          }}
          passingInteraction={(callback: CallBackPayloadType) => {
            parentChildInteraction(callback);
          }}
        />
      </PopupWrapper>
    );
  };

  const handleStockReservation = (data: any) => {
    const lineNumber = data.lineNumber;
    let copyOfItems = cloneDeep(items);
    copyOfItems = copyOfItems.map((item: any) => {
      if (item.lineNumber === lineNumber) {
        item = {
          ...item,
          reservedQuantitiesData: data.records
        };
      }
      return item;
    });
    dispatch(
      updateMultipleKeysInDocument({
        draftId,
        keysToUpdate: {
          [DOCUMENT_KEYS.ITEMS]: copyOfItems
        }
      })
    );
    hideDocPopupHolder();
  };

  const getReserveStockPopup = () => {
    return (
      <ReserveStockPopUp
        reservedQuantitiesDataOriginal={eventData?.selectedProductInfo} //check the key in event data
        selectedProduct={eventData?.lineItem}
        itemsData={booksDocument?.items || []}
        reservedQuantitiesData={eventData?.lineItem?.reservedQuantitiesData}
        docType={booksDocument?.documentType}
        onSave={handleStockReservation}
        onCancel={() => {
          hideDocPopupHolder();
        }}
      />
    );
  };

  const getProductCustomFieldsPopUp = () => {
    return (
      <CustomFieldSettings
        fields={eventData?.productCustomFields}
        moduleName={MODULES_NAME.PRODUCT}
        onSave={async () => {
          try {
            await dispatch(
              fetchProductCustomFields({
                status: 'ACTIVE',
                limit: '1000',
                module: MODULES_NAME.PRODUCT
              })
            );
            hideDocPopupHolder();
          } catch (err: any) {
            console.error('Error fetching product CFs: ', err);
          }
        }}
        onClose={() => {
          hideDocPopupHolder();
        }}
      />
    );
  };

  const getTaxExchangeRatePopup = () => {
    return (
      <GSTExchangeRateDialog
        TaxResidencyCurrency={
          COMPLAINCE_CURRENCY[AuthService.userDetails.country]
        }
        TransferCurrency={currency}
        GstExchangeRate={gstExchangeRate}
        onClose={() => hideDocPopupHolder()}
        onUpdateTaxRate={(rate: number) => {
          dispatch(
            updateMultipleKeysInDocument({
              draftId,
              keysToUpdate: {
                [DOCUMENT_KEYS.GST_EXCHANGE_RATE]: rate
              }
            })
          );
          hideDocPopupHolder();
        }}
      />
    );
  };

  const getAddClassForm = () => {
    return (
      <AddClass
        data={null}
        onSuccess={() => {
          dispatch(fetchCategoryDimensions());
          dispatch(fetchClassesByDimensionId());
        }}
        onCancel={() => {
          hideDocPopupHolder();
        }}
      />
    );
  };

  const onLandedCostDataUpdate = (data: any) => {
    let copyOfItems = cloneDeep(items);
    copyOfItems[data.rowIndex]['landedCostDetails'] = data.formData;
    copyOfItems[data.rowIndex] = calculateLineItemTaxesAndAmount(
      copyOfItems[data.rowIndex],
      documentType,
      contact,
      copyOfItems[data.rowIndex]?.gstType,
      applyRcmCheck
    );

    const linkedDocuments: any[] = [];
    if (documentMode === DOCUMENT_MODE.NEW) {
      landedProducts?.forEach((element: any) => {
        linkedDocuments.push({
          documentSequenceCode: element.documentSequenceCode,
          documentCode: element.purchaseInvoiceCode,
          documentType: DOC_TYPE.BILL
        });
      });
    }
    dispatch(
      updateMultipleKeysInDocument({
        draftId,
        keysToUpdate: {
          [DOCUMENT_KEYS.ITEMS]: copyOfItems,
          [DOCUMENT_KEYS.LANDED_COST]: true,
          [DOCUMENT_KEYS.LINKED_DOCUMENTS]: linkedDocuments
        }
      })
    );
    hideDocPopupHolder();
  };

  const getLandedCostPopup = () => {
    return (
      <AllocateLandedCost
        setLandedCostData={onLandedCostDataUpdate}
        bill={booksDocument}
        documentMode={documentMode}
        rowIndex={eventData.rowIndex}
        landedProducts={landedProducts}
        onClose={() => {
          hideDocPopupHolder();
        }}
      />
    );
  };

  const getShowEInvoicePopup = () => {
    return (
      <CancelEInvoice
        data={
          {
            // id: eInvoiceData.id,
            // irn: eInvoiceData.einvoiceInfoIndia.irn
          }
        }
        onCancel={() => {
          hideDocPopupHolder();
          if (salesInvoiceCode) {
            InvoiceService.getInvoiceByCode(salesInvoiceCode).then(
              (data: any) => {
                let invoiceDetailsData: any = getUpdatedInvoiceObject(data);
                // seteInvoiceData(invoiceDetailsData);
              },
              (err) => {
                console.error('Error loading detailed invoice: ', err);
              }
            );
          }
        }}
      />
    );
  };

  const generateEInvoiceIndia = () => {
    const generateEInvoice = (authToken: any) => {
      try {
        let payload = { ...authToken };
        payload.gstin = tenantInfo.gstin;
        payload.id = id;

        EInvoiceService.generateEInvoice(payload).then(
          (res: any) => {
            if (!Utility.isEmpty(res?.errorDetails)) {
              let errors = res?.errorDetails;
              if (errors?.length) {
                let HTML = `<div>
                        <ul>
                          ${errors.map((error: any) => {
                            return `<li>${error?.ErrorMessage}</li>`;
                          })}
                        </ul>
                      </div>`;
                showAlert('eInvoice portal reported errors below', HTML);
              }
            }

            if (salesInvoiceCode) {
              InvoiceService.getInvoiceByCode(salesInvoiceCode).then(
                (data: any) => {
                  let invoiceDetailsData: any = getUpdatedInvoiceObject(data);
                  // after this is set - invoice now is called
                  // seteInvoiceData(invoiceDetailsData);
                },
                (err) => {
                  console.error('Error loading detailed invoice: ', err);
                }
              );
            }
          },
          (err) => {
            console.error('Error generating eInvoice: ', err);
          }
        );
      } catch (error: any) {}
    };
    if (booksDocument && tenantInfo) {
      if (
        Utility.isEmpty(eInvoiceAuthInfoData) ||
        eInvoiceAuthInfoData.status !== '1'
      ) {
        dispatch(fetchEInvoiceAuthInfo({})).then((authToken: any) => {
          if (Utility.isEmpty(authToken) || authToken.payload.status !== '1') {
            // setShowIRPCredPopup(true);
            if (
              authToken?.payload?.status === '0' &&
              authToken?.payload?.errors?.[0]?.errorMessage
            ) {
              showAlert(
                'Error!',
                authToken?.payload?.errors?.[0]?.errorMessage
              );
            }
          } else {
            generateEInvoice(authToken.payload);
          }
        });
      } else {
        generateEInvoice(eInvoiceAuthInfoData);
      }
    }
  };

  const getIRPCredentialPopup = () => {
    const popupBtnConfigForEInvoice: BtnType[] = [
      {
        title: 'Cancel',
        class: 'border-m mr-s',
        clickAction: POPUP_CLICK_TYPE.CLOSE_POPUP
      },
      {
        title: 'Save',
        class: 'bg-app text-white mr-ss',
        clickAction: POPUP_CLICK_TYPE.IRP_CREDENTIAL
      }
    ];

    const loadingIRPCredPopupBtnConfig: LoadingBtnType[] = [
      {
        title: t(`PRICE_LIST.BUTTON.CANCEL`),
        class: 'border-m mr-s',
        type: 'CLOSE'
      },
      {
        title: 'Saving',
        class: 'bg-app text-white mr-ss',
        type: 'ACTION'
      }
    ];

    return (
      <PopupWrapper
        clickAction={catchClicks}
        type={POPUP_TYPE.POPUP}
        title={'Enter your IRP Credentials'}
        btnList={popupBtnConfigForEInvoice}
        isLoading={popupLoading}
        loadingBtnList={loadingIRPCredPopupBtnConfig}
        width={'35%'}
        maxHeight={'95%'}
        disableClickOutside={true}
      >
        <IRPCredPopup
          passingInteraction={(callback: CallBackPayloadType) => {
            parentChildInteraction(callback);
          }}
          sessionOutMessage={true}
          w
          gstIn={tenantInfo.gstin}
          onCancel={() => {
            hideDocPopupHolder();
            generateEInvoiceIndia();
          }}
        />
      </PopupWrapper>
    );
  };

  const onGSTValueChange = (gstData: any) => {
    let copyOfItems = cloneDeep(items);
    let rowData = { ...copyOfItems[gstData.rowIndex] };
    rowData = {
      ...rowData,
      [DOC_LINE_ITEM_KEYS.IGST_AMOUNT]: Number(gstData.igstValue),
      [DOC_LINE_ITEM_KEYS.CGST_AMOUNT]: Number(gstData.cgstValue),
      [DOC_LINE_ITEM_KEYS.SGST_AMOUNT]: Number(gstData.sgstValue),
      [DOC_LINE_ITEM_KEYS.CESS_AMOUNT]: Number(gstData.cessValue),
      [DOC_LINE_ITEM_KEYS.USER_SET_TAXES]: true
    };
    rowData = calculateLineItemTaxesAndAmount(
      rowData,
      documentType,
      contact,
      rowData?.gstType,
      applyRcmCheck
    );
    copyOfItems[gstData.rowIndex] = { ...rowData };
    dispatch(
      updateMultipleKeysInDocument({
        draftId,
        keysToUpdate: { [DOCUMENT_KEYS.ITEMS]: copyOfItems }
      })
    );

    hideDocPopupHolder();
  };

  const getGSTValueForm = () => {
    return (
      <GSTValueForm
        GSTData={{ rowIndex: eventData?.rowIndex, rowData: eventData?.rowData }}
        setGSTChangeValue={(data: any) => {
          onGSTValueChange(data);
        }}
        onClose={() => {
          hideDocPopupHolder();
        }}
      />
    );
  };

  const onTaxValueChange = (taxData: any) => {
    let copyOfItems = cloneDeep(items);
    let rowData = { ...copyOfItems[taxData.rowIndex] };
    let currentRowTaxDetails: any[] = rowData?.taxDetails
      ? rowData?.taxDetails
      : null;
    if (taxData?.isTaxGroup) {
      currentRowTaxDetails = currentRowTaxDetails?.map((taxDetailsRow: any) => {
        let tempTaxDetailsRow = { ...taxDetailsRow };
        const taxAmount =
          taxData?.taxDetailGroup?.find(
            (taxRow: any) => taxRow.taxId === tempTaxDetailsRow.taxId
          )?.taxAmount || 0;
        tempTaxDetailsRow.taxAmount =
          Utility.roundOffToTenantDecimalScale(taxAmount);

        return tempTaxDetailsRow;
      });
    }
    rowData = {
      ...rowData,
      [DOC_LINE_ITEM_KEYS.TAX_DETAILS]: currentRowTaxDetails,
      [DOC_LINE_ITEM_KEYS.TAX_AMOUNT]: Number(taxData.taxAmount),
      [DOC_LINE_ITEM_KEYS.USER_SET_TAXES]: true
    };
    rowData = calculateLineItemTaxesAndAmount(
      rowData,
      documentType,
      contact,
      rowData?.gstType,
      applyRcmCheck
    );
    copyOfItems[taxData.rowIndex] = { ...rowData };
    dispatch(
      updateMultipleKeysInDocument({
        draftId,
        keysToUpdate: { [DOCUMENT_KEYS.ITEMS]: copyOfItems }
      })
    );

    hideDocPopupHolder();
  };

  const getTaxValueEditForm = () => {
    return (
      <TaxValueEditForm
        taxData={{ rowIndex: eventData?.rowIndex, rowData: eventData?.rowData }}
        setTaxChangeValue={(data: any) => {
          onTaxValueChange(data);
        }}
        onClose={() => {
          hideDocPopupHolder();
        }}
      />
    );
  };

  const getAddContactPopup = () => {
    return (
      <DocAddContactPopup
        contact={eventData?.contact || {}}
        activeContactTab={
          eventData?.activeContactTab || CONTACT_FORM_TAB.GENERAL_INFO
        }
        contactMode={DOCUMENT_MODE.EDIT}
        onClosePopup={() => hideDocPopupHolder()}
      />
    );
  };

  const getSGPeppolPopup = () => {
    return <Peppol onCancel={() => hideDocPopupHolder()} draftId={draftId} />;
  };

  const renderPopup = () => {
    switch (showPopup) {
      case DOC_POPUP_TYPE.SHOW_CREATE_PRODUCT_POPUP:
        return getCreateProductPopUp();
      case DOC_POPUP_TYPE.SHOW_CREATE_TAX_POPUP:
        return getCreateTaxPopup();
      case DOC_POPUP_TYPE.SHOW_HSN_SAC_POPUP:
        return getHSNForm();
      case DOC_POPUP_TYPE.SHOW_TDS_CALCULATION_POPUP:
        return getGetTDSCalculationPopup();
      case DOC_POPUP_TYPE.SHOW_ITC_INFO_POPUP:
        return getITCPopup();
      case DOC_POPUP_TYPE.SHOW_PRODUCT_GROUPING_POPUP:
        return getProductSelectionPopup();
      case DOC_POPUP_TYPE.SHOW_TAX_GROUP_DETAILS_POPUP_UK:
        return getTaxGroupDistributionPopup();
      case DOC_POPUP_TYPE.SHOW_BARCODE_SEARCH_POPUP:
        return getBarcodePopup();
      case DOC_POPUP_TYPE.SHOW_PAYMENT_MILESTONE_POPUP:
        return getPaymentMilestoneFormPopup();
      case DOC_POPUP_TYPE.SHOW_RESERVE_STOCK_POPUP:
        return getReserveStockPopup();
      case DOC_POPUP_TYPE.SHOW_PRODUCT_CUSTOM_FIELD:
        return getProductCustomFieldsPopUp();
      case DOC_POPUP_TYPE.SHOW_TAX_EXCHANGE_RATE_POPUP:
        return getTaxExchangeRatePopup();
      case DOC_POPUP_TYPE.SHOW_ADD_CLASS_POPUP:
        return getAddClassForm();
      case DOC_POPUP_TYPE.SHOW_LANDED_COST_POPUP:
        return getLandedCostPopup();
      case DOC_POPUP_TYPE.SHOW_E_INVOICE_POPUP:
        return getShowEInvoicePopup();
      case DOC_POPUP_TYPE.SHOW_IRP_CRED_POPUP:
        return getIRPCredentialPopup();
      case DOC_POPUP_TYPE.SHOW_GST_VALUE_FORM_POPUP:
        return getGSTValueForm();
      case DOC_POPUP_TYPE.SHOW_TAX_AMOUNT_VALUE_FORM_POPUP:
        return getTaxValueEditForm();
      case DOC_POPUP_TYPE.SHOW_ADD_CONTACT_POPUP:
        return getAddContactPopup();
      case DOC_POPUP_TYPE.SHOW_PEPPOL_INVOICENOW_POPUP:
        return getSGPeppolPopup();
      case DOC_POPUP_TYPE.NONE:
      default:
        return null;
    }
  };

  return <>{renderPopup()}</>;
};

export default DocPopUpHolder;
