import { Store } from '../../../../Redux/Store';

export enum JC_PROCESS_TYPE {
  CONSUMPTION = 'CONSUMPTION',
  PRODUCTION = 'PRODUCTION',
  PROCESSING = 'PROCESSING'
}

export enum Job_Card_Keys {
  RESOURCES = 'resources',
  ITEMS = 'items',
  QC_STATUS = 'qcStatus',
  QC_NEEDED = 'qcNeeded',
  PROCESS_TYPE = 'processType',
  TOTAL_ASSIGNED_QTY = 'totalAssignedQtys',
  TOTAL_PRODUCED_QTY = 'totalProducedQtys',
  DEPENDENCY = 'jobCardDependency',
  PLANNED_TIME = 'plannedTime',
  JOB_CARD_DEPENDENCY = 'jobCardDependency',
  OPERATION_ID = 'operationId',
  JOB_CARD_CODE = 'jobCardCode',
  STATUS = 'status',
  WORKSTATION_ID = 'workstationId',
  FINISHED_QUANTITY = 'finishedQuantity',
  JOB_CARD_DATE = 'jobCardDate',
  PLANNED_START_DATE = 'plannedStartDate',
  PLANNED_END_DATE = 'plannedEndDate'
}

export class JobCardListPresenter {
  isLinkedInventory =
    Store.getState().authInfo.currentTenantInfo.data.additionalSettings
      .LINK_INVENTORY_JOB_CARDS;
  JobCardListColumns = [
    {
      name: 'Sr. No.',
      key: 'jobCardCode',
      width: 100
    },
    {
      name: 'Operation',
      key: 'operationId',
      width: 110
    },
    {
      name: 'Dependent On',
      key: 'jobCardDependency',
      width: 110
    },
    {
      name: 'Job Status',
      key: 'status',
      width: 120
    },
    {
      name: 'Duration',
      key: 'plannedTime',
      width: 100
    },
    {
      name: 'Start Date',
      key: 'plannedStartDate',
      width: 100
    },
    {
      name: 'End Date',
      key: 'plannedEndDate',
      width: 100
    }
  ];
  LinkedInventoryColumns = [
    {
      name: 'Process type',
      key: 'processType',
      width: 100,
      textAlign: 'left'
    },
    {
      name: 'Items',
      key: 'items',
      width: 100,
      textAlign: 'left'
    },
    {
      name: 'Qc Needed',
      key: 'qcNeeded',
      width: 100,
      textAlign: 'center'
    },
    {
      name: 'QC Status',
      key: 'qcStatus',
      width: 100,
      textAlign: 'left'
    },
    {
      name: 'Total Assigned',
      key: 'totalAssignedQtys',
      width: 100,
      textAlign: 'right'
    },
    {
      name: 'Total Produced',
      key: 'totalProducedQtys',
      width: 100,
      textAlign: 'right'
    }
  ];
  constructor() {}

  getColumnConfigs() {
    let result = [...this.JobCardListColumns];
    if (this.isLinkedInventory) {
      result.splice(4, 0, ...this.LinkedInventoryColumns);
    }
    return result;
  }

  getJobCardMappingForRow = (row: any, queriedJobCards: any) => {
    const currentJCFound = queriedJobCards?.find((resItem: any) => {
      return row?.id === resItem?.id;
    });
    const jcDependentCode =
      currentJCFound?.jobcardDependency?.jobcardDependencyList?.[0];
    const dependentOnJCFound = queriedJobCards?.find((resItem: any) => {
      return resItem?.jobCardCode === jcDependentCode;
    });

    return {
      allJCDetails: queriedJobCards,
      currentJCDependentOn: dependentOnJCFound,
      currentJCDetails: currentJCFound
    };
  };
}
