import { useContext, useRef } from 'react';
import {
  DOC_TYPE,
  FULFILLMENT_STATUS,
  POPUP_CALLBACKS_TYPE,
  POPUP_CLICK_TYPE,
  POPUP_TYPE,
  RECEIVED_GOODS_STATUS
} from '../../../../../Constants/Constant';
import {
  BtnType,
  CallBackPayloadType,
  PopupClickActionType,
  UpdateCallBacksRefType
} from '../../../../../Models/Interfaces';
import PopupWrapper from '../../../../../SharedComponents/PopupWrapper';
import DocActionsHelper from '../DocActionsHelper';
import { useAppDispatch, useAppSelector } from '../../../../../Redux/Hooks';
import { CommonDraftPropsContext } from '../../../Utilities/DocContext';
import {
  selectCurrentActiveDocument,
  selectDocumentFormDataByKeys
} from '../../../../../Redux/Slices/DocumentSlice';
import { DOCUMENT_KEYS } from '../../../Utilities/DocConstants';
import { useTranslation } from 'react-i18next';
import ReceivedGoods from '../../../../../SharedComponents/ReceivedGoodsPopup/ReceivedGoods';
import { deepClone } from '../../../../../Utility/Utility';
import InvoiceService from '../../../../../Services/Invoice';
import { getUpdatedInvoiceObject } from '../../../../Invoices/InvoiceHelper';
import BillService from '../../../../../Services/Bill';
import { getUpdatedBillObject } from '../../../../Bills/BillHelper';
import QuotationService from '../../../../../Services/Quotation';
import { getUpdatedQuoteObject } from '../../../../Quotations/QuoteHelper';
import PurchaseOrderService from '../../../../../Services/PurchaseOrder';
import { getUpdatedPurchaseOrderObject } from '../../../../PurchaseOrders/PurchaseOrderHelper';
import RequisitionService from '../../../../../Services/Requisition';
import { getUpdatedRequisitonOrderObject } from '../../../../Requisitions/RequisitionHelper';
import SalesOrderService from '../../../../../Services/SalesOrder';
import { getUpdatedSalesOrderObject } from '../../../../SalesOrders/SalesOrderHelper';
import WorkoutService from '../../../../../Services/Workout';
import { getJobWorkOutObject } from '../../../../Mrp/Workout/WorkOutHelper';
import { updatePopulateFormData } from '../../../../../Redux/Slices/DraftsSlice';
import { DraftTypes } from '../../../../../Models/Drafts';

export const ReceiveGoodsActionPopup = (props: any) => {
  const dispatch = useAppDispatch();
  const { draftId, documentMode, draftType } = useContext(
    CommonDraftPropsContext
  );
  const [documentType] = useAppSelector(
    selectDocumentFormDataByKeys(draftId, [DOCUMENT_KEYS.DOCUMENT_TYPE])
  );

  const documentObject = useAppSelector(selectCurrentActiveDocument(draftId));
  const { t, i18n } = useTranslation();
  const refInitialState: UpdateCallBacksRefType = {
    pushDataToParent: { type: POPUP_CALLBACKS_TYPE.NONE },
    storeCallbacksRef: { updateInvoice: 'click' }
  };
  const addDocRef = useRef<UpdateCallBacksRefType>(refInitialState);
  const catchClicks = (data: PopupClickActionType) => {
    switch (data.type) {
      case POPUP_CLICK_TYPE.CLOSE_POPUP:
        props.removePopup();
        break;
      case POPUP_CLICK_TYPE.RECEIVED_GOODS:
        addDocRef.current?.storeCallbacksRef.receivedGoods();
        break;
    }
  };
  const receivedGoodsPopupBtnConfig: BtnType[] = [
    {
      title: t(`BILLS.BULK_RECEIVE_GOODS_OF_BILL.BUTTON.CANCEL`),
      class: 'border-m mr-s',
      clickAction: POPUP_CLICK_TYPE.CLOSE_POPUP
    },
    {
      title: t(`BILLS.BULK_RECEIVE_GOODS_OF_BILL.BUTTON.RECEIVE`),
      class: 'bg-app text-white mr-ss',
      clickAction: POPUP_CLICK_TYPE.RECEIVED_GOODS
    }
  ];

  const updateDocument = (doc: any, paymentUpdated: boolean) => {
    let draftType;
    if (paymentUpdated) {
      if (doc.knockoffInfo) {
        if (doc.knockoffInfo.length) {
          draftType = DraftTypes.READONLY;
        } else {
          draftType = DraftTypes.UPDATE;
        }
      } else {
        draftType = DraftTypes.UPDATE;
      }
    } else {
      if (
        doc.documentType === DOC_TYPE.INVOICE ||
        doc.documentType === DOC_TYPE.QUOTE ||
        doc.documentType === DOC_TYPE.SALES_ORDER ||
        doc.documentType === DOC_TYPE.REQUISITION ||
        doc.documentType === DOC_TYPE.JOB_WORK_OUT_ORDER
      ) {
        if (doc.fulfillmentStatus === FULFILLMENT_STATUS.UNFULFILLED) {
          draftType = DraftTypes.UPDATE;
        } else {
          draftType = DraftTypes.READONLY;
        }
      }

      if (doc.documentType === DOC_TYPE.BILL) {
        if (doc.receiveGoodsStatus === RECEIVED_GOODS_STATUS.NOT_RECEIVED) {
          draftType = DraftTypes.UPDATE;
        } else {
          draftType = DraftTypes.READONLY;
        }
      }

      if (doc.documentType === DOC_TYPE.ORDER) {
        if (doc.receiptStatus === RECEIVED_GOODS_STATUS.NOT_RECEIVED) {
          draftType = DraftTypes.UPDATE;
        } else {
          draftType = DraftTypes.READONLY;
        }
      }
    }

    dispatch(
      updatePopulateFormData({
        id: draftId,
        formdata: doc,
        draftType: draftType,
        actionFromDocument: true
      })
    );
  };

  const parentChildInteraction = (passingData: CallBackPayloadType) => {
    const paymentUpdated = props.showPaymentPopup || props.showPaymentRecord;
    switch (passingData.type) {
      case POPUP_CALLBACKS_TYPE.CLOSE_POPUP:
        if (documentType === DOC_TYPE.INVOICE) {
          documentObject?.salesInvoiceCode &&
            InvoiceService.getInvoiceByCode(
              documentObject?.salesInvoiceCode
            ).then(
              (invoice: any) => {
                let invoiceDetailsData: any = getUpdatedInvoiceObject(invoice);
                updateDocument(invoiceDetailsData, paymentUpdated);
                props.removePopup();
              },
              (err) => {
                console.error('Error loading updated invoice: ', err);
              }
            );
        }
        if (documentType === DOC_TYPE.BILL) {
          BillService.fetchBillDetails(
            documentObject?.purchaseInvoiceCode
          ).then(
            (bill: any) => {
              const billDetailsData: any = getUpdatedBillObject(bill);
              updateDocument(billDetailsData, paymentUpdated);
              props.removePopup();
            },
            (err) => {
              console.error('Error loading updated bill: ', err);
            }
          );
        }
        if (documentType === DOC_TYPE.QUOTE) {
          QuotationService.getQuoteByCode(documentObject?.quotationCode).then(
            (data: any) => {
              const quoteDetailsData: any = getUpdatedQuoteObject(data);
              updateDocument(quoteDetailsData, paymentUpdated);
              props.removePopup();
            },
            (err) => {
              console.error('Error loading updated quotation: ', err);
            }
          );
        }
        if (documentType === DOC_TYPE.ORDER) {
          PurchaseOrderService.fetchOrderDetails(documentObject?.poCode).then(
            (data: any) => {
              const poDetailsData: any = getUpdatedPurchaseOrderObject(data);
              updateDocument(poDetailsData, paymentUpdated);
              props.removePopup();
            },
            (err) => {
              console.error('Error loading Purchase order: ', err);
            }
          );
        }
        if (documentType === DOC_TYPE.REQUISITION) {
          RequisitionService.fetchOrderDetails(
            documentObject?.purchaseRequestCode
          ).then(
            (data: any) => {
              const poDetailsData: any = getUpdatedRequisitonOrderObject(data);
              updateDocument(poDetailsData, paymentUpdated);
              props.removePopup();
            },
            (err: any) => {
              console.error('Error loading requisition order: ', err);
            }
          );
        }

        if (documentType === DOC_TYPE.SALES_ORDER) {
          SalesOrderService.getSalesOrderByCode(
            documentObject?.salesOrderCode
          ).then(
            (data: any) => {
              const salesOrderDetailsData: any =
                getUpdatedSalesOrderObject(data);
              updateDocument(salesOrderDetailsData, paymentUpdated);
              props.removePopup();
            },
            (err) => {
              console.error('Error loading updated order: ', err);
            }
          );
        }

        if (documentType === DOC_TYPE.JOB_WORK_OUT_ORDER) {
          WorkoutService.fetchJobWorkOutDetails(documentObject?.jwoCode).then(
            (data: any) => {
              const jobWorkOutDetailsData: any = getJobWorkOutObject(data);
              updateDocument(jobWorkOutDetailsData, paymentUpdated);
              props.removePopup();
            },
            (err: any) => {
              console.error('Error loading updated Job Work Out: ', err);
            }
          );
        }

        break;
      case POPUP_CALLBACKS_TYPE.RECEIVED_GOODS:
        addDocRef.current.storeCallbacksRef.receivedGoods = passingData.data;
        break;
    }
  };

  return (
    <PopupWrapper
      clickAction={catchClicks}
      type={POPUP_TYPE.POPUP}
      title={`Received Goods of ${DocActionsHelper.setPopupTitle(
        documentType
      )}`}
      height={'auto'}
      minHeight={'50%'}
      width={'100%'}
      btnList={receivedGoodsPopupBtnConfig}
      disableClickOutside={true}
      showScrollBar={true}
    >
      <ReceivedGoods
        documentDetails={deepClone(documentObject)}
        passingInteraction={(callback: CallBackPayloadType) => {
          parentChildInteraction(callback);
        }}
        documentType={documentType}
      />
    </PopupWrapper>
  );
};
