import React, { Component } from "react";
import "../../index.css";
import "./css/Templates.css";
import ComponentManager from "../../Manager/ComponentManager";
import { EditorInfoType, FONT_SIZE, PAGE_PADDING } from '../../Constants/Constants';
import DocumentManager from "../../Manager/DocumentManager";
import Utility, { getFontSizeClass, getPageHeight, getVW, getIsFulfillment, getIsGoodsReceipt, getIsJournalEntry, getIsProductDocumentForBuySell } from "../../Utilities/Utility";
import { getLocalisedText } from '../../Translate/LanguageManager';
import DKEInvoiceSection, { eInvoiceSectionPosition } from "../../DKUILibrary/DKEInvoiceSection";
import DKCustomFieldSection from "../../DKUILibrary/DKCustomFieldSection";
import DKSignatureSection from "../../DKUILibrary/DKSignatureSection";
import { AddressType } from "../../Helper/AddressParser";
import { HTMLTag } from "../../Utilities/HTMLTag";
import DKIndiaTaxBreakdown from "../../DKUILibrary/DKIndiaTaxBreakdown";
import TopSectionAboveTableUtility from "../../Utilities/TopSectionAboveTableUtility";
import DKComponentListTable from "../../DKUILibrary/DKComponentListTable";
import DKApprovalSection from "../../DKUILibrary/DKApprovalSection";

export default class IndiaTemplate10 extends Component {//based on tempalte 43 - India Template 5 for more spacing  https://deskera.atlassian.net/browse/BOOK-3182
    spacingRatio = 0.25

    constructor(props) {
        super(props);
        this.state = {
            data: this.props.data,
        };
    }

    componentWillReceiveProps(nextProp) {
        this.setState({
            data: nextProp.data,
        });
    }

    render() {
        return (
            <div className='DocumentHolder ColumnDiv'
                style={{
                    justifyContent: 'space-between',
                    minHeight: getPageHeight(this.state.data.printScale),
                    border: 'black solid 1px'
                }}>
                <div className='parent-width' style={{ width: '100%', paddingBottom: getVW(30 * this.spacingRatio) }}>
                    <DKEInvoiceSection
                        data={this.state.data}
                        position={eInvoiceSectionPosition.top}
                    />
                    <table style={{
                        width: '100%',
                        borderCollapse: 'collapse',
                        margin: 'auto'
                    }}>
                        <tbody>
                            <tr>
                                <td colSpan={2}
                                    style={{
                                        borderTop: this.getIsTopEInvoice() ? '1px solid' : undefined,
                                        width: '100%',
                                        verticalAlign: 'top'
                                    }}>
                                    {this.getDocumentType()}
                                </td>
                            </tr>
                            <tr>
                                <td style={{ border: '1px solid', width: '50%', verticalAlign: 'top' }}>
                                    {this.getLeftPanelSection()}
                                </td>
                                <td style={{ border: '1px solid', width: '50%', verticalAlign: 'top' }}>
                                    {this.getDocumentDetailsSection()}
                                    {(this.state.data.showTermsAndCondition && this.state.data.termsAndCondition)
                                        && <div className="RowDiv"
                                            style={{
                                                width: '-webkit-fill-available',
                                                paddingTop: getVW(5 * this.spacingRatio),
                                                paddingBottom: getVW(5 * this.spacingRatio),
                                                borderBottom: (this.state.data.showNotes && this.state.data.notes) ? '1px solid' : undefined,
                                            }}>
                                            {ComponentManager.getTermsAndConditionOrNotesSection(
                                                Utility.getTermsAndConditionTitle(getLocalisedText('terms_&_condition')),
                                                getLocalisedText(this.state.data.termsAndCondition),
                                                "100%",
                                                this.state.data.themeColor,
                                                undefined,
                                                undefined,
                                                undefined,
                                                this.state.data.printScale,
                                                Utility.getTnCOrNotesFontSettingsByType(EditorInfoType.termsAndCondition)
                                            )}
                                        </div>}
                                    {(this.state.data.showNotes && this.state.data.notes)
                                        && <div className="RowDiv"
                                            style={{
                                                width: '-webkit-fill-available',
                                                paddingTop: getVW(5),
                                                paddingBottom: getVW(5),
                                            }}>
                                            {ComponentManager.getTermsAndConditionOrNotesSection(
                                                Utility.getNotesTitle(getIsJournalEntry(this.state.data.type) ? getLocalisedText('memo') : getLocalisedText('notes')),
                                                getLocalisedText(this.state.data.notes),
                                                "100%",
                                                this.state.data.themeColor,
                                                undefined,
                                                undefined,
                                                undefined,
                                                this.state.data.printScale,
                                                Utility.getTnCOrNotesFontSettingsByType(EditorInfoType.notes)
                                            )}
                                        </div>}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    {/* <div className="RowDiv"
                        style={{
                            justifyContent: 'space-between',
                            alignItems: 'flex-start',
                            borderTop: (Utility.getIsShowEInvoiceCorrectPosition('top')
                                && Utility.getIsIndiaCountry(this.state.data)
                                && this.state.data.showEInvoiceSection) ? 'solid 1px' : undefined,
                            borderBottom: (Utility.getIsShowEInvoiceCorrectPosition('top')
                                && Utility.getIsIndiaCountry(this.state.data)
                                && this.state.data.showEInvoiceSection) ? 'solid 1px' : undefined
                        }}>
                        <div className="ColumnDiv" style={{
                            width: '50%',
                            borderRight: 'solid 1px',
                        }}>
                            {this.getLeftPanelSection()}
                        </div>
                        <div className="ColumnDiv" style={{
                            width: '50%'
                        }}>
                            {this.getDocumentDetailsSection()}
                            {(this.state.data.showTermsAndCondition && this.state.data.termsAndCondition)
                                && <div className="RowDiv"
                                    style={{
                                        width: '-webkit-fill-available',
                                        borderBottom: '1px solid',
                                        paddingTop: getVW(5),
                                        paddingBottom: getVW(5),
                                    }}>
                                    {ComponentManager.getDescriptionSection(
                                        Utility.getTermsAndConditionTitle(getLocalisedText('terms_&_condition')),
                                        getLocalisedText(this.state.data.termsAndCondition),
                                        "100%",
                                        this.state.data.themeColor
                                    )}
                                </div>}
                            {(this.state.data.showNotes && this.state.data.notes)
                                && <div className="RowDiv"
                                    style={{
                                        width: '-webkit-fill-available',
                                        borderBottom: '1px solid',
                                        paddingTop: getVW(5),
                                        paddingBottom: getVW(5),
                                    }}>
                                    {ComponentManager.getDescriptionSection(
                                        Utility.getNotesTitle(getIsJournalEntry(this.state.data.type) ? getLocalisedText('memo') : getLocalisedText('notes')),
                                        getLocalisedText(this.state.data.notes),
                                        "100%",
                                        this.state.data.themeColor
                                    )}
                                </div>}
                        </div>
                    </div> */}
                    {/* section 2 */}
                    {/* {(Utility.getIsShowSourceOfSupply(this.state.data) || Utility.getIsShowDestinationOfSupply(this.state.data) || Utility.getIsShowPlaceOfSupply(this.state.data)) && ComponentManager.addVerticalSpace(20)} */}
                    {Utility.getIsShowPlaceOfSupply(this.state.data) && this.getPlaceOfSupplyInfoSection()}
                    {(Utility.getIsShowSourceOfSupply(this.state.data) || Utility.getIsShowDestinationOfSupply(this.state.data)) && this.getSourceOfSupplyInfoSection()}
                    {/* {(Utility.getIsShowSourceOfSupply(this.state.data) || Utility.getIsShowDestinationOfSupply(this.state.data) || Utility.getIsShowPlaceOfSupply(this.state.data)) && ComponentManager.addVerticalSpace(20)} */}
                    {/* {Utility.getIsContainsCustomField('top') && ComponentManager.addVerticalSpace(10)} */}
                    <DKCustomFieldSection data={this.state.data} onCustomFieldClick={this.props.onCustomFieldClick} style={this.getCustomFieldTableStyle()} customFieldPosition={'top'} />
                    {/* {ComponentManager.addVerticalSpace(20)} */}
                    {
                        TopSectionAboveTableUtility.getIsTopSectionAboveTableIsVisible() &&
                        <>
                            <div style={{ ...TopSectionAboveTableUtility.getTopSectionWithTableFontStyleProp(undefined, this.state.data.printScale) }}
                                dangerouslySetInnerHTML={{ __html: TopSectionAboveTableUtility.getHtmlString(this.state.data) }} />
                            {ComponentManager.addVerticalSpace(5 * this.spacingRatio)}
                        </>
                    }
                    {this.getProductTable()}
                    {Utility.getIsShowAccountProductTable(this.state.data) && ComponentManager.addVerticalSpace(30 * this.spacingRatio)}
                    {this.getEditableDocumentTable()}
                    {Utility.getIsShowExchangeRate(this.state.data) && ComponentManager.addVerticalSpace(15 * this.spacingRatio)}
                    {Utility.getIsShowExchangeRate(this.state.data) && this.getExchangeRateTable(this.getTableStyle(), this.state.data)}
                    {/* {Utility.getIsContainsCustomField('bottom') && ComponentManager.addVerticalSpace(10)} */}
                    <DKCustomFieldSection data={this.state.data} onCustomFieldClick={this.props.onCustomFieldClick} style={this.getCustomFieldTableStyle()} customFieldPosition={'bottom'} />
                    {ComponentManager.addVerticalSpace(15 * this.spacingRatio)}
                    {getIsProductDocumentForBuySell(this.state.data) &&
                        Utility.getIsShowHsnSacTaxTable() &&
                        <DKIndiaTaxBreakdown
                            data={this.state.data}
                        />
                    }
                    {Utility.getIsIncludesBomProductTable() && <DKComponentListTable data={this.state.data} />}
                    <div id={HTMLTag.SIGNATURE}>
                        {this.state.data.showSignature && ComponentManager.addVerticalSpace(Utility.convertToPrintScale(30, this.state.data.printScale) * this.spacingRatio)}
                        {this.state.data.showSignature && ComponentManager.getSignature(this.state.data.signature,this.state.data.printScale)}
                    </div>
                </div>
                <div className='parent-width' style={{ width: '100%' }}>
                    {(getIsFulfillment(this.state.data.type) || getIsGoodsReceipt(this.state.data.type)) && <DKSignatureSection data={this.state.data} onSignatureSectionClicked={this.props.tableClicked} />}
                    {Utility.getIsShowApprovalSection() && <DKApprovalSection data={this.state.data} style={this.getTableStyle()} isThemeColor />}
                </div>
                <DKEInvoiceSection
                    data={this.state.data}
                    position={eInvoiceSectionPosition.bottom}
                />
                {Utility.getGeneratedVisibility() && this.getGenerateDocumentSection()}
            </div>
        );
    }

    getIsTopEInvoice() {
        if (Utility.getIsShowEInvoiceCorrectPosition('top')
            && Utility.getIsIndiaCountry(this.state.data)
            && this.state.data.showEInvoiceSection) {
            return true
        }
        return false
    }

    getDocumentType() {
        return <div className="RowDiv pl-s pt-s pb-s parent-width"
            style={{
                alignItems: 'center',
                flexDirection: Utility.getIsArabicLang() ? 'row-reverse' : 'row',
                width: '100%',
            }}>
            <text className={"docFontStyleBold" + getFontSizeClass()}
                style={{
                    ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.large },this.state.data.printScale)
                }}>
                {getLocalisedText(this.state.data.documentType)}
            </text>
        </div>;
    }

    getLeftPanelSection() {
        return <di className="RowDiv">
            <div className="ColumnDiv" style={{ width: '100%', paddingTop: getVW(10 * this.spacingRatio), paddingBottom: getVW(10 * this.spacingRatio) }}>
                <div className="RowDiv parent-width" style={{
                    paddingLeft: getVW(10 * this.spacingRatio),
                }}>
                    {ComponentManager.getLogoVisibility(this.state.data.showCompanyLogo, this.state.data.isReadOnlyMode, this.state.data.logo) && ComponentManager.getLogo(this.state.data.logo, Utility.convertToPrintScale(80, this.state.data.printScale), { position: 'relative' })}
                    {this.state.data.showCompanyLogo && ComponentManager.addHorizontalSpace(10)}
                    {this.state.data.showCompanyName && <div className="ColumnDiv parent-width">
                        {ComponentManager.getDescriptionSection(
                            getLocalisedText(Utility.getCompanyName(this.state.data)),
                            getLocalisedText(Utility.getCompanyAddress(this.state.data)),
                            "100%",
                            this.state.data.themeColor,
                            false,
                            '#000000',
                            getVW(0),
                            Utility.getOwnerType(this.state.data, AddressType.from),
                            this.state.data.printScale
                        )}
                    </div>}
                </div>
                {(this.state.data.showFrom && this.state.data.showClientBillToAddress) && ComponentManager.addVerticalSpace(10 * this.spacingRatio)}
                {(this.state.data.showClientBillToAddress)
                    && <div className="RowDiv"
                        style={{
                            width: '-webkit-fill-available',
                            borderTop: '1px solid',
                            borderBottom: '1px solid',
                            paddingTop: getVW(5 * this.spacingRatio),
                            paddingBottom: getVW(5 * this.spacingRatio),
                        }}>
                        <div className="ColumnDiv parent-width">
                            {ComponentManager.getDescriptionSection(
                                this.state.data.billToTitle,
                                Utility.getClientBillToAddress(this.state.data),
                                "100%",
                                this.state.data.themeColor,
                                false,
                                '#000000',
                                getVW(0),
                                Utility.getOwnerType(this.state.data, AddressType.billTo),
                                this.state.data.printScale
                            )}
                            {ComponentManager.getDescriptionSection(
                                "",
                                this.state.data.billToAddressStateCode,
                                "100%",
                                this.state.data.themeColor,
                                false,
                                undefined,
                                0,
                                undefined,
                                this.state.data.printScale
                            )}
                        </div>
                    </div>}
                {(this.state.data.showClientBillToAddress && this.state.data.showClientShipToAddress) && ComponentManager.addVerticalSpace(10 * this.spacingRatio)}
                {this.state.data.showClientShipToAddress && <div className="ColumnDiv parent-width">
                    {ComponentManager.getDescriptionSection(
                        this.state.data.shipToTitle,
                        Utility.getClientShipToAddress(this.state.data),
                        "100%",
                        this.state.data.themeColor,
                        false,
                        '#000000',
                        getVW(0),
                        Utility.getOwnerType(this.state.data, AddressType.shipTo),
                        this.state.data.printScale
                    )}
                    {ComponentManager.getDescriptionSection(
                        "",
                        this.state.data.shipToAddressStateCode,
                        "100%",
                        this.state.data.themeColor,
                        false,
                        undefined,
                        0,
                        undefined,
                        this.state.data.printScale
                    )}
                </div>}
            </div>
        </di>
    }

    getDocumentDetailsSection() {
        var documentDetailList = Utility.getDocumentDetailsList(this.state.data)
        return documentDetailList.map(element => {
            var value = ''
            if (element.key !== undefined) {
                value = this.state.data[element.key]
            }
            else if (element.customFieldValue !== undefined) {
                value = element.customFieldValue
            }
            return ComponentManager.getRowValueSection(
                element.label,
                value,
                this.state.data.themeColor,
                undefined,
                undefined,
                undefined,
                this.state.data.printScale
            )
        });
    }


    getGenerateDocumentSection() {
        return <div style={{
            paddingBottom: getVW(PAGE_PADDING, true),// Utility.getGeneratedMessagePaddingBottom(this.spacingRatio),
            paddingTop: getVW(5 * this.spacingRatio),
            width: '100%'
        }}>
            <text className={'docFontStyleBold fontSizeNormal ' + getFontSizeClass()}
                style={{
                    ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular },this.state.data.printScale)
                }}>
                {Utility.getGeneratedMessage(this.state.data.type)}
            </text>
        </div>
    }

    getEditableDocumentTable() {
        return ComponentManager.getDKDocumentTable(
            this.props.tableColumn,
            this.getTableStyle(),
            this.props.data,
            this.props.tableClicked,
            this.props.stockTableClicked,
            this.props.onUpdateDataCallback,
            this.props.paymentTableClicked,
            this.props.additionalTableCallback,
        )
    }

    getProductTable() {
        if(Utility.getIsShowAccountProductTable(this.state.data)) {
            return ComponentManager.getDKProductTable(
                Utility.getTableColumnForAccountProduct(),
                this.getTableStyle(),
                this.props.data,
                this.props.tableClicked,
                this.props.stockTableClicked,
                this.props.onUpdateDataCallback,
                this.props.paymentTableClicked,
                this.props.additionalTableCallback,
            )
        }
    }

    getTableStyle() {
        let style = DocumentManager.getTableDefaultStyle();
        style.headerTextColor = this.state.data.themeColor;
        style.footerTextColor = this.state.data.themeColor;
        // style.headerBorderColor = "#BBBABA";
        // style.itemBorderColor = "#BBBABA";
        style.headerBorderColor = "#000000";
        style.itemBorderColor = "#000000";
        style.footerBackgroundColor = "white";
        // style.footerBorderColor = "#BBBABA";
        style.footerBorderColor = "#000000";
        style.headerBackgroundColor = "transparent";
        return style;
    }


    getCustomFieldTableStyle() {
        let style = {}
        style.columnCount = 2
        style.columnWidths = Utility.getIsArabicLang() ? ['50%', '50%'] : ['50%', '50%']
        return style;
    }

    getPlaceOfSupplyInfoSection() {
        return (
            <table
                style={{
                    alignItems: "flex-start",
                    justifyContent: 'space-between',
                    backgroundColor: "white",
                    border: '1px solid',
                    paddingTop: getVW(5 * this.spacingRatio),
                    paddingBottom: getVW(5 * this.spacingRatio),
                    width: '100%'
                }}
            >
                <tbody>
                    <tr>
                        {this.getPlaceOfSupply()}
                    </tr>
                </tbody>
            </table>
        )
    }
    getPlaceOfSupply() {
        return <td>
            {Utility.getIsShowPlaceOfSupply(this.state.data) && ComponentManager.getDescriptionSection(
                getLocalisedText('place_of_supply'),
                this.state.data.placeOfSupply,
                "100%",
                'black',
                false,
                '#000000',
                getVW(6 * this.spacingRatio),
                true,
                this.state.data.printScale
            )}
        </td>

    }

    getSourceOfSupplyInfoSection() {
        return (
            <table
                style={{
                    alignItems: "flex-start",
                    justifyContent: 'space-between',
                    backgroundColor: "white",
                    border: '1px solid',
                    paddingTop: getVW(5 * this.spacingRatio),
                    paddingBottom: getVW(5 * this.spacingRatio),
                    width: '100%'
                }}
            >
                <tbody>
                    <tr>
                        {this.getSourceOfSupply()}
                    </tr>
                </tbody>
            </table>
        )
    }

    getSourceOfSupply() {
        return <div className='RowDiv' style={{ width: '100%' }}>
            <div className='ColumnDiv' style={{
                width: '41%'
            }}>
                {Utility.getIsShowSourceOfSupply(this.state.data) && ComponentManager.getDescriptionSection(
                    getLocalisedText('source_of_supply'),
                    this.state.data.sourceOfSupply,
                    "100%",
                    this.state.data.themeColor,
                    false,
                    '#000000',
                    getVW(6 * this.spacingRatio),
                    true,
                    this.state.data.printScale
                )}
            </div>
            <div className='ColumnDiv' style={{
                width: '40%'
            }}>
                {Utility.getIsShowDestinationOfSupply(this.state.data) && ComponentManager.getDescriptionSection(
                    getLocalisedText('destination_of_supply'),
                    this.state.data.destinationOfSupply,
                    "100%",
                    'black',
                    false,
                    '#000000',
                    getVW(6 * this.spacingRatio),
                    true,
                    this.state.data.printScale
                )}
            </div>
        </div>;
    }

    getExchangeRateTable(tableStyle, data) {
        const style = {
            ...tableStyle,
            paddingTop: 4,
            paddingBottom: 4,
            paddingLeft: 6,
            paddingRight: 13,
        };

        return (
            <div id={HTMLTag.EXCHANGE_RATE} className="RowDiv" style={{ justifyContent: "flex-end" }}>
                <div style={{ width: "45%" }}>
                    {ComponentManager.getExchangeRateTable(style, data)}
                </div>
            </div>
        );
    }
}
