import { useContext, useState } from 'react';
import { DKLabel, DKListPicker2, DKIcons } from 'deskera-ui-library';
import { CommonDraftPropsContext } from '../../Utilities/DocContext';
import { useAppDispatch, useAppSelector } from '../../../../Redux/Hooks';
import { selectContacts } from '../../../../Redux/Slices/ContactsSlice';

import { STATUS_TYPE } from '../../../../Constants/Constant';
import { PERMISSIONS_BY_MODULE } from '../../../../Constants/Permission';
import ContactService, { ContactAPIConfig } from '../../../../Services/Contact';
import { GranularPermissionsHelper } from '../../../Settings/GranularPermissions/GranularPermissionsHelper';

import DocAddContactPopup from './DocAddContactPopup';
import { getIndicatorForInactiveContact } from '../../Helper/View/DocRenderer';
import { onDocContactChange } from '../../Helper/DocumentUpdates/ContactChangeHelper';

interface IDocContactSelectorProps {
  title?: string;
  contactName?: string;
  needSelectorButton: boolean;
  canValidate: boolean;
  canEdit?: boolean;
  showContactSelector?: boolean;
  isVendor?: boolean;
  showInactiveIndicator?: boolean;
}

const DocContactSelector = (props: IDocContactSelectorProps) => {
  const { draftId, documentMode, draftType, isCashInvoice } = useContext(
    CommonDraftPropsContext
  );
  const [needContactSelector, setNeedContactSelector] = useState(false);
  const [needAddContactPopup, setNeedAddContactPopup] = useState(false);
  const contactsData = useAppSelector(selectContacts);
  const contactList = (contactsData?.content || []).filter(
    (contact: any) => contact?.status?.toLowerCase() === STATUS_TYPE.active
  );

  // GranularPermissionsHelper.hasPermissionFor(
  //   PERMISSIONS_BY_MODULE.CONTACTS.CREATE
  // );

  return (
    <div className="column position-relative gap-2">
      {props.title ? (
        <div
          className={`row width-auto ${
            props.canEdit ? 'cursor-pointer listPickerBG' : ''
          }`}
          onClick={() => props.canEdit && setNeedContactSelector(true)}
        >
          <DKLabel text={props.title} className="fw-b fs-r text-gray" />
        </div>
      ) : null}
      {props.contactName ? (
        <div
          className={`row width-auto ${
            props.canEdit ? 'cursor-pointer listPickerBG' : ''
          }`}
          onClick={() => props.canEdit && setNeedContactSelector(true)}
        >
          <DKLabel text={props.contactName} className="fw-m fs-r" />
          {props.showInactiveIndicator && getIndicatorForInactiveContact()}
        </div>
      ) : null}
      {props.needSelectorButton && (
        <div
          className={`${
            props.canValidate
              ? 'bg-chip-red border-red text-red'
              : 'bg-chip-blue border-blue text-blue'
          } p-v-s p-h-r border-radius-s mt-s cursor-pointer`}
          style={{ border: 'dashed', borderWidth: 1 }}
          onClick={() => setNeedContactSelector((prevValue) => !prevValue)}
        >
          <DKLabel text={`+ Add a Contact`} />
        </div>
      )}
      {needContactSelector && (
        <DKListPicker2
          title={props.title || ''}
          data={contactList}
          className="position-absolute z-index-3 bg-white border-m"
          style={{
            top: 0,
            left: 0,
            minWidth: 200
          }}
          renderer={(index: number, contactObj: any) => contactObj.name}
          onSelect={(index: number, contactObj: any) => {
            setNeedContactSelector(false);
            onDocContactChange({
              draftId,
              isContactChanged: true,
              newContact: contactObj,
              documentMode,
              draftType,
              isCashInvoice: !!isCashInvoice
            });
          }}
          onClose={() => setNeedContactSelector(false)}
          allowSearch={true}
          searchApiConfig={{
            getUrl: (searchValue: string) => {
              const config: ContactAPIConfig = {
                ...ContactService.apiConfig,
                Page: 0,
                SearchTerm: searchValue,
                Limit: 20,
                IncludeOpeningAmounts: false,
                IncludeOweAmounts: false,
                Query: props.isVendor
                  ? 'status=active,vendor=true'
                  : 'status=active'
              };

              ContactService.apiConfig = config;

              return ContactService.getContactsApiUrl();
            },
            dataParser: (response: any) => {
              return response?.content || [];
            },
            debounceTime: 300
          }}
          button={
            GranularPermissionsHelper.hasPermissionFor(
              PERMISSIONS_BY_MODULE.CONTACTS.CREATE
            )
              ? {
                  title: '+ Add New',
                  icon: DKIcons.ic_contact,
                  className: 'bg-button text-white',
                  style: {},
                  onClick: () => setNeedAddContactPopup(true)
                }
              : null
          }
        />
      )}
      {needAddContactPopup && (
        <DocAddContactPopup
          onClosePopup={() => setNeedAddContactPopup(false)}
        />
      )}
    </div>
  );
};

export default DocContactSelector;
