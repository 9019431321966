import { useCallback, useContext, useEffect, useState } from 'react';
import { shallowEqual } from 'react-redux';
import {
  DKIcons,
  DKIconText,
  DKInput,
  DKLabel,
  INPUT_TYPE,
  INPUT_VIEW_DIRECTION
} from 'deskera-ui-library';
import { CommonDraftPropsContext } from '../../Utilities/DocContext';
import { useAppDispatch, useAppSelector } from '../../../../Redux/Hooks';
import {
  onDocumentFormFieldUpdate,
  selectDocumentFormDataByKeys,
  updateMultipleKeysInDocument
} from '../../../../Redux/Slices/DocumentSlice';
import {
  getTenantTaxSystem,
  isDocumentFullScreenON,
  isSGAndPeppolOptInEnabled
} from '../../../../SharedComponents/DocumentForm/NewDocumentHelper';
import DocDates from '../Common/DocDates';
import {
  COMMON_CONSTANT,
  DOC_TYPE,
  DOCUMENT_MODE,
  REGEX,
  TAX_SYSTEM
} from '../../../../Constants/Constant';
import Utility, { getCapitalized } from '../../../../Utility/Utility';
import CommonStateListPicker from '../../../../SharedComponents/DocumentForm/CommonStateListPicker';
import {
  isDropShipDocument,
  isPurchaseDocument,
  isSalesDocument
} from '../../Utilities/DocCommonUtils';
import { localizedText } from '../../../../Services/Localization/Localization';
import { DraftTypes } from '../../../../Models/Drafts';
import { loadAccountGroupsForCashInvoice } from '../../Helper/Common/CashInvoiceHelper';
import { activeTenantInfo } from '../../../../Redux/Slices/AuthSlice';
import { getIndianStateOptions } from '../../Helper/Common/DocDataHelper';
import {
  onDestinationOfSupplyChange,
  onPlaceOfSupplyChange
} from '../../Helper/DocumentUpdates/PlaceAndDestinationOfSupplyHelper';
import { DOCUMENT_KEYS } from '../../Utilities/DocConstants';

interface DocCommonInputsProps {
  inRightPanel?: boolean;
}
/**
 * Components returned when Fullscreen is ON:
 *  - Document Date
 *  - Due Date
 *  - ShipBy / Receive By Date
 *  - Place of supply
 *  - Source of Destination
 *  - Customer Order No.
 *  - Payment fields (in case of cash invoice)
 *
 * Components returned when Fullscreen is OFF:
 *  - Place of supply
 *  - Source of Destination
 *  - Payment fields (in case of cash invoice)
 */
const DocCommonInputs = (props: DocCommonInputsProps) => {
  const { draftId, draftType, documentMode, isCashInvoice } = useContext(
    CommonDraftPropsContext
  );
  const [
    contact,
    documentType,
    currency,
    backOrder,
    dropShip,
    fulfillmentType,
    customerOrderNumber,
    supplierInvoiceNo,
    buyerReference,
    paymentTerm,
    amountToReceiveOrPay,
    totalAmount,
    dueAmount,
    paymentType,
    accountGroupForPayment,
    paymentAccountGroupsOptions,
    orderReference,
    shipTo,
    shipFrom
  ] = useAppSelector(
    selectDocumentFormDataByKeys(draftId, [
      DOCUMENT_KEYS.CONTACT,
      DOCUMENT_KEYS.DOCUMENT_TYPE,
      DOCUMENT_KEYS.CURRENCY,
      DOCUMENT_KEYS.BACK_ORDER,
      DOCUMENT_KEYS.DROPSHIP,
      DOCUMENT_KEYS.FULFILLMENT_TYPE,
      DOCUMENT_KEYS.CUSTOM_ORDER_NUMBER,
      DOCUMENT_KEYS.SUPPLIER_INVOICE_NUMBER,
      DOCUMENT_KEYS.BUYER_REFERENCE,
      DOCUMENT_KEYS.PAYMENT_TERM,
      DOCUMENT_KEYS.AMOUNT_TO_RECEIVE_OR_PAY,
      DOCUMENT_KEYS.TOTAL_AMOUNT,
      DOCUMENT_KEYS.DUE_AMOUNT,
      DOCUMENT_KEYS.PAYMENT_TYPE,
      DOCUMENT_KEYS.ACCOUNT_GROUP_FOR_PAYMENT,
      DOCUMENT_KEYS.PAYMENT_ACCOUNT_GROUP_OPTIONS,
      DOCUMENT_KEYS.ORDER_REFERENCE,
      DOCUMENT_KEYS.SHIP_TO,
      DOCUMENT_KEYS.SHIP_FROM
    ]),
    shallowEqual
  );

  /**
   * TODO: Create separate methods for returning fullscreen and non fullscreen  layouts
   */

  const isDocumentInFullScreen = isDocumentFullScreenON(documentType);

  const dispatch = useAppDispatch();
  const tenantInfo = useAppSelector(activeTenantInfo);

  const [placeOfSupply, setPlaceOfSupply] = useState<any>();
  const [destinationOfSupply, setDestinationOfSupply] = useState<any>();
  const [localCustomerOrderNo, setLocalCustomerOrderNo] =
    useState<string>(customerOrderNumber);
  const [localSupplierInvNo, setLocalSupplierInvNo] =
    useState<string>(supplierInvoiceNo);
  const [localBuyerReference, setLocalBuyerReference] =
    useState<string>(buyerReference);
  const [localAmountToReceiveOrPay, setLocalAmountToReceiveOrPay] =
    useState<any>(+amountToReceiveOrPay || 0);

  useEffect(() => {
    const getLocalPOS = () => {
      let pos = '';
      if (isSalesDocument(documentType)) {
        pos = shipTo?.placeOfSupply;
      } else if (isPurchaseDocument(documentType)) {
        pos = shipFrom?.placeOfSupply;
      }
      return pos;
    };

    const getLocalDOS = () => {
      let dos = '';
      if (isPurchaseDocument(documentType)) {
        dos = shipTo?.destinationOfSupply;
      }
      return dos;
    };

    if (placeOfSupply !== getLocalPOS()) {
      setPlaceOfSupply(getLocalPOS() + '');
    }
    if (destinationOfSupply !== getLocalDOS()) {
      setDestinationOfSupply(getLocalDOS() + '');
    }
  }, [shipTo, shipFrom]);

  useEffect(() => {
    if (isCashInvoice && !Utility.isEmptyObject(tenantInfo)) {
      getPaymentAccountGroupOptionsAndDefaultOption();
    }
  }, [currency]);

  useEffect(() => {
    if (buyerReference !== localBuyerReference) {
      setLocalBuyerReference(buyerReference || '');
    }
  }, [buyerReference]);

  const getPaymentAccountGroupOptionsAndDefaultOption =
    useCallback(async () => {
      try {
        const response = await loadAccountGroupsForCashInvoice(
          currency || tenantInfo?.currency,
          documentType
        );
        dispatch(
          updateMultipleKeysInDocument({
            draftId,
            keysToUpdate: {
              [DOCUMENT_KEYS.PAYMENT_ACCOUNT_GROUP_OPTIONS]:
                response?.paymentMethodOptions || [],
              [DOCUMENT_KEYS.ACCOUNT_GROUP_FOR_PAYMENT]:
                response?.defaultAccountGroupOption || undefined
            }
          })
        );
      } catch (err: any) {
        console.error('Error loading account groups for payment: ', err);
      }
    }, [currency, dispatch, documentType, draftId, tenantInfo?.currency]);

  const getPlaceOfSupply = () => {
    const stateOptions = getIndianStateOptions(contact) || [];
    const posObj = stateOptions.find((state) => state.label === placeOfSupply);
    return (
      <div
        className={isDocumentInFullScreen ? '' : 'mt-r'}
        style={{ width: 250 }}
      >
        <CommonStateListPicker
          value={posObj}
          valueStyle={{ minHeight: 33 }}
          title={
            isSalesDocument(documentType)
              ? 'Place of Supply'
              : 'Source of Supply'
          }
          className="parent-width"
          dropdownOptions={stateOptions}
          direction={INPUT_VIEW_DIRECTION.VERTICAL}
          readOnly={/*!checkForCustomField*/ false}
          formatterProperty={'label'}
          isSearchable={true}
          searchableKey={'searchableKey'}
          onChange={(value: any) => {
            onPlaceOfSupplyChange({ draftId, posValue: value?.value });
          }}
        />
      </div>
    );
  };

  const getSourceOfDestination = () => {
    const stateOptions = getIndianStateOptions(contact) || [];
    const dosObj = stateOptions.find(
      (state) => state.label === destinationOfSupply
    );
    return (
      <div
        className={isDocumentInFullScreen ? '' : 'mt-r'}
        style={{ width: 250 }}
      >
        <CommonStateListPicker
          value={dosObj}
          valueStyle={{ minHeight: 33 }}
          title={'Destination of Supply'}
          className="parent-width"
          dropdownOptions={stateOptions}
          direction={INPUT_VIEW_DIRECTION.VERTICAL}
          readOnly={/*!checkForCustomField*/ false}
          formatterProperty={'label'}
          isSearchable={true}
          searchableKey={'searchableKey'}
          onChange={(value: any) => {
            onDestinationOfSupplyChange({ draftId, dosValue: value?.value });
          }}
        />
      </div>
    );
  };

  const getInputField = (config: {
    type?: string;
    title?: string;
    value?: any;
    required?: boolean;
    readOnly?: boolean;
    textAlign?: 'left' | 'center' | 'right';
    canValidate?: boolean;
    errorMessage?: string;
    formatter?: (value: any) => any;
    validator?: (value: any) => boolean;
    onChange?: (value: any) => void;
    onBlur?: (e: any) => void;
    dropdownConfig?: any;
  }) => {
    return (
      <DKInput
        className="parent-width"
        title={isDocumentInFullScreen ? config.title : ''}
        value={config.value}
        titleStyle={{ color: 'gray' }}
        valueStyle={{ minHeight: 33 }}
        type={config.type || INPUT_TYPE.TEXT}
        direction={INPUT_VIEW_DIRECTION.VERTICAL}
        textAlign={config.textAlign || 'left'}
        required={config.required || false}
        readOnly={config.readOnly || false}
        canValidate={config.canValidate || false}
        errorMessage={config.errorMessage || ''}
        formatter={config.formatter}
        onChange={(value: any) => {
          if (config.onChange) {
            config.onChange(value);
          }
        }}
        onBlur={(e: any) => {
          if (config.onBlur) {
            config.onBlur(e.target?.value?.trim());
          }
        }}
        dropdownConfig={config.dropdownConfig || null}
      />
    );
  };

  const getIconWithTitle = (icon: any, title: string) => {
    return (
      <DKIconText
        icon={icon}
        iconClassName="ic-with-text-height opacity-60"
        text={title}
        textClassName="fw-m"
      />
    );
  };

  const getLabelWithValue = (config: {
    icon: any;
    title: string;
    value: any;
  }) => {
    return (
      <>
        {getIconWithTitle(config.icon, config.title)}
        <div
          style={{
            overflowWrap: 'break-word',
            whiteSpace: 'pre-wrap',
            minWidth: 75,
            maxWidth: 120,
            marginLeft: isDocumentInFullScreen ? 5 : 0
          }}
        >
          <DKLabel className="text-align-right" text={config.value} />
        </div>
      </>
    );
  };

  const renderOrderReferenceNumber = () => {
    let sgSupportedDocuments = [
      DOC_TYPE.SALES_ORDER,
      DOC_TYPE.INVOICE,
      DOC_TYPE.BILL
    ];
    const isSGPeppol = isSGAndPeppolOptInEnabled();
    if (isSGPeppol && sgSupportedDocuments.includes(documentType)) {
      return (
        <div
          className={`row width-auto justify-content-between`}
          style={{
            minWidth: isDocumentInFullScreen ? 150 : 250
          }}
        >
          {!isDocumentInFullScreen &&
            getLabelWithValue({
              icon: DKIcons.data_type.ic_number,
              title: 'Order Reference',
              value: orderReference
            })}

          {isDocumentInFullScreen &&
            getInputField({
              title: 'Order Reference',
              value: orderReference,
              required: false,
              readOnly: true
            })}
        </div>
      );
    }
  };

  const renderSIBuyerReference = () => {
    let doesBuyerReferenceExist = false;
    if (contact?.customField) {
      contact?.customField?.forEach((item: any) => {
        if (item?.label === 'Buyer Reference') {
          doesBuyerReferenceExist = true;
        }
      });
    }
    const isSGPeppol = isSGAndPeppolOptInEnabled();
    const sgSupportedDocuments = [
      DOC_TYPE.BILL,
      DOC_TYPE.INVOICE,
      DOC_TYPE.SALES_ORDER,
      DOC_TYPE.ORDER
    ];
    if (
      isSGPeppol &&
      sgSupportedDocuments.includes(documentType) &&
      doesBuyerReferenceExist
    ) {
      return (
        <div
          className={`row width-auto justify-content-between`}
          style={{
            minWidth: isDocumentInFullScreen ? 150 : 250
          }}
        >
          {!isDocumentInFullScreen &&
            getIconWithTitle(DKIcons.data_type.ic_number, 'Buyer Reference')}
          <div
            style={{
              width: isDocumentInFullScreen ? 150 : 100
            }}
          >
            {getInputField({
              title: 'Buyer Reference',
              value: localBuyerReference,
              required: false,
              readOnly: false,
              textAlign: isDocumentInFullScreen ? 'left' : 'right',
              onChange: (value: any) => {
                setLocalBuyerReference(value);
              },
              onBlur: (value: any) => {
                if (value !== buyerReference) {
                  dispatch(
                    updateMultipleKeysInDocument({
                      draftId,
                      keysToUpdate: {
                        [DOCUMENT_KEYS.BUYER_REFERENCE]: value || ''
                      }
                    })
                  );
                }
              }
            })}
          </div>
        </div>
      );
    } else {
      return <></>;
    }
  };

  const renderPEPPOLPaymentTerm = () => {
    const isSGPeppol = isSGAndPeppolOptInEnabled();
    const sgSupportedDocuments = [
      DOC_TYPE.BILL,
      DOC_TYPE.INVOICE,
      DOC_TYPE.SALES_ORDER,
      DOC_TYPE.ORDER
    ];
    if (isSGPeppol && sgSupportedDocuments.includes(documentType)) {
      return (
        <div
          className={`row width-auto justify-content-between`}
          style={{
            minWidth: isDocumentInFullScreen ? 150 : 250
          }}
        >
          {!isDocumentInFullScreen &&
            getLabelWithValue({
              title: 'Payment Term',
              icon: DKIcons.data_type.ic_number,
              value: paymentTerm
            })}
          {isDocumentInFullScreen &&
            getInputField({
              title: 'Payment Term',
              value: paymentTerm,
              type: INPUT_TYPE.TEXT,
              readOnly: true
            })}
        </div>
      );
    } else {
      return <></>;
    }
  };

  const getCustomerOrderNumberField = () => {
    return (
      <div
        className={`row width-auto justify-content-between`}
        style={{
          minWidth: isDocumentInFullScreen ? 150 : 250
        }}
      >
        {!isDocumentInFullScreen &&
          getIconWithTitle(DKIcons.data_type.ic_number, 'Customer Order No.')}
        <div
          style={{
            width: isDocumentInFullScreen ? 150 : 100
          }}
        >
          {getInputField({
            title: 'Customer Order No.',
            value: localCustomerOrderNo,
            textAlign: isDocumentInFullScreen ? 'left' : 'right',
            required: false,
            readOnly:
              documentMode === DOCUMENT_MODE.VIEW ||
              (documentType === DOC_TYPE.ORDER && (dropShip || backOrder)),
            onChange: (value: any) => {
              setLocalCustomerOrderNo(value);
            },
            onBlur: (value: any) => {
              if (value !== customerOrderNumber) {
                dispatch(
                  updateMultipleKeysInDocument({
                    draftId,
                    keysToUpdate: {
                      [DOCUMENT_KEYS.CUSTOM_ORDER_NUMBER]: value || ''
                    }
                  })
                );
              }
            }
          })}
        </div>
      </div>
    );
  };

  const getSupplierInvoiceNumberField = () => {
    return (
      <div
        className={`row width-auto justify-content-between`}
        style={{
          minWidth: isDocumentInFullScreen ? 150 : 250
        }}
      >
        {!isDocumentInFullScreen &&
          getIconWithTitle(DKIcons.data_type.ic_number, 'Supplier Inv No.')}
        <div
          style={{
            width: isDocumentInFullScreen ? 150 : 100
          }}
        >
          {getInputField({
            title: 'Supplier Inv No.',
            value: localSupplierInvNo,
            required: false,
            readOnly:
              documentMode === DOCUMENT_MODE.VIEW &&
              draftType === DraftTypes.READONLY,
            // TODO: && checkForCustomField,
            onChange: (value: any) => {
              setLocalSupplierInvNo(value);
            },
            onBlur: (value: any) => {
              if (value !== supplierInvoiceNo) {
                dispatch(
                  updateMultipleKeysInDocument({
                    draftId,
                    keysToUpdate: {
                      [DOCUMENT_KEYS.SUPPLIER_INVOICE_NUMBER]: value || ''
                    }
                  })
                );
              }
            }
          })}
        </div>
      </div>
    );
  };

  const getOptionForPaymentType = (options: any[]) => {
    if (accountGroupForPayment?.accountGroupName === 'Current Assets') {
      let updatedvalue = [...options];
      updatedvalue.push({
        label: 'Cash',
        value: 'CASH'
      });
      return updatedvalue;
    } else {
      return options;
    }
  };

  const getPaymentRelatedFields = () => {
    const PAYMENT_TYPE: any[] = [
      { value: 'CHEQUE', label: getCapitalized(localizedText('cheque')) },
      { value: 'BANK_TRANSFER', label: COMMON_CONSTANT.BANK_TRANSFER_VALUE },
      { value: 'CARD', label: COMMON_CONSTANT.CARD_VALUE }
    ];

    return (
      <>
        <div className="position-relative">
          <div
            className={`row width-auto justify-content-between`}
            style={{
              minWidth: isDocumentInFullScreen ? 150 : 250
            }}
          >
            {!isDocumentInFullScreen &&
              getIconWithTitle(DKIcons.data_type.ic_number, 'Amt. to Receive')}
            <div style={{ width: isDocumentInFullScreen ? 150 : 100 }}>
              {getInputField({
                title: 'Amt. to Receive',
                value: localAmountToReceiveOrPay,
                validator: (value: number) => {
                  return (
                    REGEX.DECIMAL_NUMBER.test(value?.toString()) &&
                    (+value >= 0 || totalAmount === 0)
                  );
                },
                canValidate:
                  !REGEX.DECIMAL_NUMBER.test(
                    localAmountToReceiveOrPay?.toString()
                  ) ||
                  (totalAmount > 0 && +localAmountToReceiveOrPay < 1) ||
                  +localAmountToReceiveOrPay > +dueAmount,
                errorMessage: 'Invalid',
                textAlign: 'right',
                type: INPUT_TYPE.NUMBER,
                readOnly:
                  documentMode === DOCUMENT_MODE.VIEW &&
                  draftType === DraftTypes.READONLY,
                onChange: (value: number) => {
                  setLocalAmountToReceiveOrPay(value);
                },
                onBlur: (value: string) => {
                  if (+value !== amountToReceiveOrPay) {
                    dispatch(
                      updateMultipleKeysInDocument({
                        draftId,
                        keysToUpdate: {
                          [DOCUMENT_KEYS.AMOUNT_TO_RECEIVE_OR_PAY]: +value || ''
                        }
                      })
                    );
                  }
                }
              })}
            </div>
          </div>
        </div>
        <div className="position-relative">
          <div
            className={`row width-auto justify-content-between`}
            style={{
              minWidth: isDocumentInFullScreen ? 150 : 250
            }}
          >
            {!isDocumentInFullScreen &&
              getIconWithTitle(DKIcons.data_type.ic_select, 'Deposit To')}
            <div style={{ width: isDocumentInFullScreen ? 150 : 120 }}>
              {getInputField({
                title: 'Deposit To',
                value: paymentAccountGroupsOptions?.find(
                  (aGroup: any) =>
                    aGroup.value === accountGroupForPayment?.value
                )?.label,
                type: INPUT_TYPE.DROPDOWN,
                readOnly:
                  documentMode === DOCUMENT_MODE.VIEW &&
                  draftType === DraftTypes.READONLY,
                onChange: (obj: any) => {
                  dispatch(
                    updateMultipleKeysInDocument({
                      draftId,
                      keysToUpdate: {
                        [DOCUMENT_KEYS.ACCOUNT_GROUP_FOR_PAYMENT]: { ...obj },
                        [DOCUMENT_KEYS.PAYMENT_TYPE]:
                          obj?.accountGroupName === 'Cash'
                            ? 'CASH'
                            : paymentType
                      }
                    })
                  );
                },
                dropdownConfig: {
                  className: '',
                  style: {},
                  allowSearch: false,
                  searchableKey: 'label',
                  data: paymentAccountGroupsOptions || [],
                  renderer: (index: any, obj: any) => {
                    return (
                      <DKLabel
                        text={obj.label}
                        style={{
                          whiteSpace: 'pre-wrap',
                          wordBreak: 'break-word'
                        }}
                      />
                    );
                  },
                  onSelect: (index: any, value: any) => {}
                }
              })}
            </div>
          </div>
        </div>
        {accountGroupForPayment?.accountGroupName !== 'Cash' && (
          <div className="position-relative">
            <div
              className={`row width-auto justify-content-between`}
              style={{
                minWidth: isDocumentInFullScreen ? 150 : 250
              }}
            >
              {!isDocumentInFullScreen &&
                getIconWithTitle(DKIcons.data_type.ic_select, 'Payment Type')}
              <div style={{ width: isDocumentInFullScreen ? 150 : 120 }}>
                {getInputField({
                  title: 'Payment Type',
                  value: getOptionForPaymentType(PAYMENT_TYPE).find(
                    (pType: any) => pType.value === paymentType
                  )?.label,
                  type: INPUT_TYPE.DROPDOWN,
                  readOnly:
                    documentMode === DOCUMENT_MODE.VIEW &&
                    draftType === DraftTypes.READONLY,
                  onChange: (obj: any) => {},
                  formatter: (value) => {
                    if (typeof value === 'string') {
                      return value;
                    } else {
                      return value.label;
                    }
                  },
                  dropdownConfig: {
                    className: '',
                    style: {},
                    allowSearch: false,
                    searchableKey: 'label',
                    data: getOptionForPaymentType(PAYMENT_TYPE),
                    renderer: (index: any, obj: any) => {
                      return (
                        <div className="flex flex-row w-full justify-content-between">
                          <DKLabel
                            style={{ fontSize: '13px' }}
                            className="text-base border-radius-s"
                            text={obj.label}
                          />
                        </div>
                      );
                    },
                    onSelect: (index: any, obj: any) => {
                      dispatch(
                        updateMultipleKeysInDocument({
                          draftId,
                          keysToUpdate: {
                            [DOCUMENT_KEYS.PAYMENT_TYPE]: obj?.value
                          }
                        })
                      );
                    }
                  }
                })}
              </div>
            </div>
          </div>
        )}
      </>
    );
  };

  return (
    <div
      className="row align-items-end flex-wrap"
      style={{ gap: isDocumentInFullScreen ? 12 : 10 }}
    >
      {/* Fullscreen Dates */}
      {isDocumentInFullScreen && <DocDates showFullscreenLayout={true} />}

      {/* Place of supply */}
      {getTenantTaxSystem() === TAX_SYSTEM.INDIA_GST &&
        !Utility.isEmpty(contact) &&
        !props.inRightPanel &&
        getPlaceOfSupply()}

      {/* Source of destination */}
      {(isPurchaseDocument(documentType) ||
        isDropShipDocument({ dropShip, fulfillmentType })) &&
        getTenantTaxSystem() === TAX_SYSTEM.INDIA_GST &&
        !Utility.isEmpty(contact) &&
        !props.inRightPanel &&
        getSourceOfDestination()}

      {/* Customer order no. */}
      {(isDocumentInFullScreen ||
        (!isDocumentInFullScreen && props.inRightPanel)) &&
        (isSalesDocument(documentType) ||
          (documentType === DOC_TYPE.ORDER && (dropShip || backOrder))) &&
        getCustomerOrderNumberField()}

      {/* Buyer reference number */}
      {(isDocumentInFullScreen ||
        (!isDocumentInFullScreen && props.inRightPanel)) &&
        renderSIBuyerReference()}

      {/* PEPPOL payment term */}
      {(isDocumentInFullScreen ||
        (!isDocumentInFullScreen && props.inRightPanel)) &&
        renderPEPPOLPaymentTerm()}

      {/* Order reference number */}
      {(isDocumentInFullScreen ||
        (!isDocumentInFullScreen && props.inRightPanel)) &&
        renderOrderReferenceNumber()}

      {/* Supplier invoice no. */}
      {(isDocumentInFullScreen ||
        (!isDocumentInFullScreen && props.inRightPanel)) &&
        documentType === DOC_TYPE.BILL &&
        getSupplierInvoiceNumberField()}

      {/* Cash Invoice payment related fields */}
      {/* TODO: check autoProcessDoc usage */}
      {isCashInvoice &&
        (documentMode === DOCUMENT_MODE.NEW ||
          documentMode === DOCUMENT_MODE.COPY) &&
        documentType === DOC_TYPE.INVOICE /* || autoProcessDoc*/ &&
        (isDocumentInFullScreen ||
          (!isDocumentInFullScreen && props.inRightPanel)) &&
        getPaymentRelatedFields()}
    </div>
  );
};

export default DocCommonInputs;
