import { showAlert } from 'deskera-ui-library';
import {
  APPROVAL_STATUS,
  BOOKS_DATE_FORMAT,
  DOC_TYPE,
  DOCUMENT_MODE,
  LABELS,
  PRODUCT_TYPE
} from '../../../../Constants/Constant';
import { AnyDocument, DraftTypes, IDocument } from '../../../../Models/Drafts';
import {
  onDocumentClose,
  updateMultipleKeysInDocument
} from '../../../../Redux/Slices/DocumentSlice';
import { Store } from '../../../../Redux/Store';
import AuthService from '../../../../Services/Auth';
import NumberFormatService from '../../../../Services/NumberFormat';
import QuotationService from '../../../../Services/Quotation';
import {
  cascadingDiscountsInvalidMessage,
  checkCreditLimit,
  checkGSTINPresentForSelectedContact,
  checkIfTotalDiscountInvalid,
  customFieldsContainsErrors,
  getDocumentAlert,
  handleReservedQuantityDataObject,
  inactiveContactMessage,
  isDocContactInactive,
  rebuildCascadingDiscountsForSaving,
  removeUnwantedPayloadKeysForDocument,
  showAlertOnDocAPIError,
  updateAddressAsPerLocationCF
} from '../../../../SharedComponents/DocumentForm/NewDocumentHelper';
import Utility, {
  deepClone,
  getCapitalized
} from '../../../../Utility/Utility';
import {
  DOC_SAVE_OPTION,
  setCanValidateDocument,
  setIsSavingDocument
} from '../View/ActionBarHelper';
import { DocSaver } from './DocSaver';
import { CREDIT_LIMIT_TYPE } from '../../../../Constants/Enum';
import DateFormatService from '../../../../Services/DateFormat';
import { localizedText } from '../../../../Services/Localization/Localization';
import {
  deleteDrafts,
  fetchDrafts
} from '../../../../Redux/Slices/DraftsSlice';
import RateAnalysisService from '../../../../Services/RateAnalysis';
import { fetchQuotes } from '../../../../Redux/Slices/QuotesSlice';
import { PAGE_ROUTES } from '../../../../Managers/RouteManager';

export class QuoteSaver extends DocSaver {
  draftId: number;
  documentMode: DOCUMENT_MODE;
  tenantInfo: any;
  docToSave: AnyDocument;
  saveOption: DOC_SAVE_OPTION;
  draftType: DraftTypes;
  replaceURLCallback: (() => void) | undefined;

  constructor(data: {
    draftToSave: IDocument<AnyDocument>;
    documentMode: DOCUMENT_MODE;
    saveOption: DOC_SAVE_OPTION;
    tenantInfo: any;
  }) {
    super(data);
    this.draftId = data.draftToSave.id;
    this.draftType = data.draftToSave.draftType;
    this.documentMode = data.documentMode;
    this.saveOption = data.saveOption;
    this.tenantInfo = data.tenantInfo;
    this.docToSave = this.updatedDoc({ ...data.draftToSave?.populateFormData });
  }

  private updatedDoc = (doc: AnyDocument) => {
    if (this.tenantInfo?.additionalSettings?.CASCADING_DISCOUNTS?.enable) {
      doc = {
        ...doc,
        items: rebuildCascadingDiscountsForSaving(doc)
      };
    }

    const extraKeys = {
      quotationItemDtoList: doc.items,
      shipByDate: doc.fulfillmentDate,
      currency: doc.currency,
      currencyCode: doc.currency || doc.currencyCode,
      documentType: DOC_TYPE.QUOTE,
      priceListId: doc?.priceListId,
      priceListName: doc?.priceListName,
      entityId: this.documentMode === DOCUMENT_MODE.EDIT ? doc?.id : undefined,
      documentCode:
        this.documentMode === DOCUMENT_MODE.EDIT
          ? doc?.quotationCode
          : undefined,
      totalAmount: doc.totalAmount
    };

    doc = {
      ...doc,
      ...extraKeys
    };
    return doc;
  };

  sendTriggerOnApproval(payload: AnyDocument, isUpdateCall?: boolean) {
    let emails = Utility.getApproverEmail(payload);
    let sum =
      payload &&
      payload.quotationItemDtoList
        .map((item: any) => item.totalAmount)
        .reduce((prev: any, curr: any) => prev + curr, 0);
    let payloadObj = {
      contactCode: payload.contactCode,
      totalAmount: NumberFormatService.getNumber(sum),
      userName: AuthService.getUserName(),
      currency: Utility.getCurrencySymbolFromCode(payload.currency),
      approverMap: Object.fromEntries(emails),
      currentLevel: 1,
      approvalHistory: payload['multiApprovalDetails']?.approvalHistory || [],
      opName: isUpdateCall ? 'u' : 'c'
    };
    QuotationService.sendTriggerOnApproval(payloadObj).then(
      (response: any) => {},
      (err) => {
        console.error(
          'Error while sending approval trigger (Quote -> sendTriggerOnApproval): ',
          err
        );
      }
    );
  }

  async saveAsDraft(isUpdateCall: boolean, approvalRequired?: boolean) {
    try {
      const approvalData = await super.updatePayloadWithApprovalData(
        this.docToSave,
        approvalRequired
      );
      const isApprovalRequired = approvalData?.isApprovalRequired;
      this.docToSave = approvalData?.payload || this.docToSave;
      // here we explicitly remove few keys, which is not needed to be saved in component list feature
      this.docToSave = removeUnwantedPayloadKeysForDocument(this.docToSave);

      await super.saveDraft(this.docToSave);
      if (isApprovalRequired) {
        this.sendTriggerOnApproval(this.docToSave, isUpdateCall);
      }
      this.replaceURLCallback?.();
    } catch (err: any) {
      console.error('Error while saving draft (Quote -> saveAsDraft): ', err);
    }
  }

  /**
   * Handles create and update quote calls
   */
  save(closeOnUpdate = true) {
    if (this.draftType === DraftTypes.UPDATE) {
      this.updateQuote(closeOnUpdate);
    } else {
      this.createQuote();
    }
  }

  private updateForm(
    docResp: any,
    payload: any,
    isUpdate = false,
    closeOnUpdate = true
  ) {
    QuotationService.getQuoteByCode(docResp.quotationCode).then(
      (doc: any) => {
        const formData = {
          ...doc,
          documentType: DOC_TYPE.QUOTE,
          items: [...doc.quotationItemDtoList].map((savedItem: any) => {
            const itemFromDocToSave = this.docToSave?.items?.find(
              (item: any) => item.lineNumber === savedItem.lineNumber
            );
            if (!Utility.isEmptyObject(itemFromDocToSave)) {
              return {
                ...itemFromDocToSave,
                ...savedItem
              };
            }
            return { ...savedItem };
          }),
          contact: {
            ...doc.contact,
            ...doc.contactDto
          },
          isDocumentTouched: false,
          documentDate: payload.documentDate,
          fulfillmentDate: payload.fulfillmentDate,
          validTillDate: payload.validTillDate
        };
        if (!closeOnUpdate) {
          Store.dispatch(
            updateMultipleKeysInDocument({
              draftId: this.draftId,
              keysToUpdate: formData
            })
          );
        } else {
          Store.dispatch(onDocumentClose({ draftId: this.draftId }));
          if (!isUpdate) {
            getDocumentAlert(
              `${Utility.isUSorg() ? 'Estimate' : 'Quote'} created!`,
              `${
                Utility.isUSorg() ? 'Estimate' : 'Quote'
              } has been created successfully.`,
              formData,
              this.draftToSave,
              DOCUMENT_MODE.EDIT,
              PAGE_ROUTES.QUOTES
            );
            setIsSavingDocument(this.draftId, false);
          }
        }
      },
      (err) => {
        console.error('Error loading updated doc: ', err);
        setIsSavingDocument(this.draftId, false);
      }
    );
  }

  // Create Quote
  async createQuote(closeOnCreate = true) {
    setIsSavingDocument(this.draftId, true);
    setCanValidateDocument(this.draftId, true);

    let payload: any = deepClone(this.docToSave);
    payload = updateAddressAsPerLocationCF(payload);
    if (this.tenantInfo?.additionalSettings?.CASCADING_DISCOUNTS?.enable) {
      payload = {
        ...payload,
        quotationItemDtoList: rebuildCascadingDiscountsForSaving(payload)
      };
    }
    delete payload?.items;
    if (
      (this.documentMode === DOCUMENT_MODE.COPY ||
        this.documentMode === DOCUMENT_MODE.NEW) &&
      payload?.duplicate
    ) {
      const isContactInactive = isDocContactInactive(payload?.contact);
      if (isContactInactive) {
        showAlert('Error', inactiveContactMessage);
        setIsSavingDocument(this.draftId, false);
        return;
      }
    }

    if (payload.attachmentIds?.length) {
      payload.attachments = payload.attachmentIds.map(
        (attachmentId: any) => `${attachmentId}`
      );
    }

    if (payload.reservedStock) {
      const isTrackedOrBOM = payload.quotationItemDtoList.some(
        (obj: any) =>
          obj.product.type === PRODUCT_TYPE.TRACKED ||
          obj.product.type === PRODUCT_TYPE.BILL_OF_MATERIALS
      );
      if (!isTrackedOrBOM) {
        payload = {
          ...payload,
          reservedStock: false
        };
      } else {
        payload = handleReservedQuantityDataObject(
          payload,
          'quotationItemDtoList'
        );
      }
    }

    if (!this.isDocValid(payload)) {
      setIsSavingDocument(this.draftId, false);
      return;
    }

    payload = removeUnwantedPayloadKeysForDocument(payload);

    let isApproval = await Utility.isApprovalRequired(payload);
    if (isApproval === null) {
      setIsSavingDocument(this.draftId, false);
      return;
    } else if (isApproval) {
      this.saveAsDraft(false, isApproval);
      return;
    } else {
      payload['approvalStatus'] = APPROVAL_STATUS['NOT_REQUIRED'];
    }

    if (this.draftType === DraftTypes.DRAFT) {
      payload[
        'draftReferenceId'
      ] = `${this.draftTableId}/record/${this.draftId}`;
    }

    const processedCreditLimitObj = await checkCreditLimit(payload);
    if (
      processedCreditLimitObj &&
      processedCreditLimitObj.showAlertPopup &&
      processedCreditLimitObj.settings.creditLimitType !==
        CREDIT_LIMIT_TYPE.IGNORE
    ) {
      this.showCreditLimitAlert(
        processedCreditLimitObj.settings,
        payload,
        false,
        true,
        closeOnCreate
      );
    } else {
      this.makeCreateAPICall(payload, closeOnCreate);
    }
  }

  private updateRateAnalysis(quote: AnyDocument) {
    RateAnalysisService.updateRateAnalysis({
      documentCode: quote.quotationCode,
      documentType: DOC_TYPE.QUOTE,
      productPriceMethod:
        this.tenantInfo?.additionalSettings?.RATE_ANALYSIS
          ?.PRODUCT_PRICE_METHOD,
      isDraft: this.draftType === DraftTypes.DRAFT ? true : false,
      draftId: this.draftId,
      tableId: this.draftTableId
    })
      .then(() => {})
      .catch((err: any) => {
        console.error('Error updating rate analysis: ', err);
      });
  }

  /**
   * Initiate create quote API call
   * @param payload
   * @param closeDoc `optional` `boolean`
   */
  makeCreateAPICall(payload: any, closeDoc: boolean) {
    QuotationService.createQuote(payload).then(
      (response: any) => {
        if (this.draftToSave) {
          if (closeDoc) {
            // Remove draft popup
            Store.dispatch(onDocumentClose({ draftId: this.draftId }));
          }
          if (this.draftType === DraftTypes.DRAFT) {
            Store.dispatch(
              deleteDrafts({
                recordId: this.draftId,
                tableId: this.draftTableId
              })
            );
            this.updateRateAnalysis(response);
          }
          this.updateForm(response, payload, false);
        } else {
          setIsSavingDocument(this.draftId, false);
        }
        Store.dispatch(
          fetchDrafts({
            tableId: this.draftTableId,
            isSaveColumnId: this.isSaveColumnId,
            draftTypeColId: this.draftTypeColId,
            draftTypeColValue: LABELS.QUOTES
          })
        );
        Store.dispatch(fetchQuotes());
        this.replaceURLCallback?.();
      },
      (err) => {
        console.error('Error while creating quote: ', err);
        setIsSavingDocument(this.draftId, false);
        showAlertOnDocAPIError(err);
        this.replaceURLCallback?.();
      }
    );
  }

  // Update Quote
  async updateQuote(closeOnUpdate = true) {
    setIsSavingDocument(this.draftId, true);
    setCanValidateDocument(this.draftId, true);

    let payload: any = deepClone(this.docToSave);
    payload = { ...payload, contact: payload.contactDto };
    payload = updateAddressAsPerLocationCF(payload);
    if (this.tenantInfo.additionalSettings?.CASCADING_DISCOUNTS?.enable) {
      payload = {
        ...payload,
        quotationItemDtoList: rebuildCascadingDiscountsForSaving(payload)
      };
    }

    // TODO: Need to add latest update for approval
    // TODO: Reference https://code.deskera.com/infinity-stones/reality/erp-v2-ui/-/merge_requests/11628

    delete payload?.items;
    delete payload?.contactDto;

    if (payload.attachmentIds?.length) {
      payload.attachments = payload.attachmentIds.map(
        (attachmentId: any) => `${attachmentId}`
      );
    }

    if (payload.reservedStock) {
      const isTrackedOrBOM = payload.quotationItemDtoList.some(
        (obj: any) =>
          obj.product.type === PRODUCT_TYPE.TRACKED ||
          obj.product.type === PRODUCT_TYPE.BILL_OF_MATERIALS
      );
      if (!isTrackedOrBOM) {
        payload = {
          ...payload,
          reservedStock: false
        };
      } else {
        payload = handleReservedQuantityDataObject(
          payload,
          'quotationItemDtoList'
        );
      }
    }

    if (!this.isDocValid(payload)) {
      setIsSavingDocument(this.draftId, false);
      return;
    }

    const processedCreditLimitObj = await checkCreditLimit(payload);
    if (
      processedCreditLimitObj &&
      processedCreditLimitObj.showAlertPopup &&
      processedCreditLimitObj.settings.creditLimitType !==
        CREDIT_LIMIT_TYPE.IGNORE
    ) {
      this.showCreditLimitAlert(
        processedCreditLimitObj.settings,
        payload,
        true,
        closeOnUpdate
      );
    } else {
      this.makeUpdateAPICall(payload, closeOnUpdate);
    }
  }

  private makeUpdateAPICall(payload: any, closeOnUpdate: boolean) {
    payload.quotationItemDtoList.map((quotationItem: any) => {
      quotationItem.pendingQuantity = quotationItem.productQuantity;
      quotationItem.pendingQtyToConvert = quotationItem.productQuantity;
    });

    payload = removeUnwantedPayloadKeysForDocument(payload);

    QuotationService.updateQuote(payload, payload?.id as number).then(
      (response) => {
        if (this.draftToSave) {
          if (closeOnUpdate) {
            Store.dispatch(onDocumentClose({ draftId: this.draftId }));
            this.replaceURLCallback?.();
          }
          setIsSavingDocument(this.draftId, false);
          this.updateForm(response, payload, true, closeOnUpdate);
        }
        Store.dispatch(
          fetchDrafts({
            tableId: this.draftTableId,
            isSaveColumnId: this.isSaveColumnId,
            draftTypeColId: this.draftTypeColId,
            draftTypeColValue: LABELS.QUOTES
          })
        );
        Store.dispatch(fetchQuotes());
        setIsSavingDocument(this.draftId, false);
      },
      (err) => {
        console.error('Error while updating quote: ', err);
        setIsSavingDocument(this.draftId, false);
        showAlertOnDocAPIError(err);
        this.replaceURLCallback?.();
      }
    );
  }

  /**
   *
   * @param creditLimitSettings Credit limit check for quote
   * @param payload
   * @param isUpdate
   * @param closeOnUpdate
   * @param closeOnCreate
   */
  showCreditLimitAlert(
    creditLimitSettings: any,
    payload: any,
    isUpdate = false,
    closeOnUpdate = false,
    closeOnCreate = false
  ) {
    let buttons = [];

    buttons.push({
      title:
        creditLimitSettings.creditLimitType === CREDIT_LIMIT_TYPE.WARN
          ? 'Cancel'
          : 'Ok',
      className: 'border-m',
      onClick: () => {
        setIsSavingDocument(this.draftId, false);
      }
    });

    if (creditLimitSettings.creditLimitType === CREDIT_LIMIT_TYPE.WARN) {
      buttons.push({
        title: 'Save',
        className: 'bg-blue text-white ml-r',
        onClick: () => {
          if (isUpdate) {
            this.makeUpdateAPICall(payload, closeOnUpdate);
          } else {
            this.makeCreateAPICall(payload, closeOnCreate);
          }
        }
      });
    }

    const baseCurrencyCode = this.tenantInfo.currency;
    const currencySymbol = Utility.getCurrencySymbolFromCode(baseCurrencyCode);

    let message = '';
    if (creditLimitSettings.creditLimitType === CREDIT_LIMIT_TYPE.WARN) {
      message = `Credit Limit for ${payload?.contact?.name} has reached. Do you wish to proceed?`;
    }

    if (creditLimitSettings.creditLimitType === CREDIT_LIMIT_TYPE.BLOCK) {
      message += `Credit Limit for ${payload?.contact?.name} has reached. You cannot proceed.<br/><br/>`;
      message += '<ul>';
      message += `<li>Credit Limit: ${currencySymbol}${NumberFormatService.getNumber(
        creditLimitSettings.creditLimitInBaseCurrency
      )}</li>`;
      message += `<li>Amount Due: ${currencySymbol}${NumberFormatService.getNumber(
        creditLimitSettings.totalDueAmount
      )}</li>`;
      message += creditLimitSettings.includeCurrentDoc
        ? '<li class="text-gray" style="font-size: 12px;">(Incl. current estimate)</li>'
        : '';
      message += '</ul>';
    }

    showAlert('Credit Limit Reached', message, buttons);
  }

  /**
   * Validate document
   * @param docToValidate
   * @returns `boolean`
   */
  isDocValid(docToValidate: AnyDocument) {
    // Validate Manual Document Sequence Code
    if (
      Utility.isEmpty(docToValidate.documentSequenceCode) &&
      Utility.isEmpty(docToValidate.sequenceFormat) &&
      docToValidate.manualMode
    ) {
      return false;
    }

    if (Utility.isEmpty(docToValidate.contact)) {
      return false;
    }

    if (Utility.isEmpty(docToValidate.quotationItemDtoList)) {
      return false;
    }

    if (!Utility.isEmpty(docToValidate.documentDate)) {
      const label = Utility.isUSorg() ? 'Estimate Date' : 'Quote Date';
      const docDate = DateFormatService.getDateFromStr(
        docToValidate.documentDate,
        BOOKS_DATE_FORMAT['DD-MM-YYYY']
      );
      if (
        !Utility.checkActiveDateRangeValidation(
          docDate,
          this.tenantInfo,
          label,
          docToValidate.documentType
        )
      ) {
        return false;
      }
      if (!Utility.checkClosingDate(docDate, label)) {
        return false;
      }
    }

    // Custom fields validation
    const customFieldHasErrors = customFieldsContainsErrors(
      docToValidate.customField
    );
    if (customFieldHasErrors) {
      return false;
    }
    // Custom fields validation ends

    // Line item errors
    let lineItemsHasErrors = false;
    for (let i = 0; i < docToValidate.quotationItemDtoList.length; i++) {
      const item = docToValidate.quotationItemDtoList[i];
      if (item.hasError || item.invalidFields?.length) {
        lineItemsHasErrors = true;
        break;
      }
    }

    if (lineItemsHasErrors) {
      return false;
    }
    // Line item errors ends

    // Contact GSTIN check
    if (!checkGSTINPresentForSelectedContact(docToValidate)) {
      return false;
    }
    // Contact GSTIN check ends

    // Check for -ve total amount
    const totalBeforeTax = docToValidate?.quotationItemDtoList?.length
      ? docToValidate?.quotationItemDtoList?.reduce(
          (total: number, item: any) => {
            return total + item?.totalWithDiscount;
          },
          0
        )
      : 0;
    if (totalBeforeTax < 0) {
      showAlert(
        'Invalid amount!',
        `${getCapitalized(
          localizedText('quote')
        )} amount can not be less than 0.`
      );
      return false;
    }
    // Check for -ve total amount ends

    // Cascading discounts validation
    const cascadingDiscountSettings =
      this.tenantInfo.additionalSettings?.CASCADING_DISCOUNTS;
    if (cascadingDiscountSettings?.enable) {
      const isTotalDiscountInvalid = checkIfTotalDiscountInvalid(
        docToValidate,
        'quotationItemDtoList'
      );
      if (isTotalDiscountInvalid) {
        showAlert('Error!', cascadingDiscountsInvalidMessage);
        return false;
      }
    }
    // Cascading discounts validation ends

    // Additional charges check
    let additionalChargesHasErrors = false;
    const additionalCharges =
      docToValidate.additionalCharges?.additionalChargesDetails || [];
    if (!Utility.isEmpty(additionalCharges)) {
      for (let i = 0; i < additionalCharges.length; i++) {
        const item = additionalCharges[i];
        if ((item as any).hasError) {
          additionalChargesHasErrors = true;
          break;
        }
      }
    }
    if (additionalChargesHasErrors) {
      return false;
    }
    // Additional charges check ends

    // Discount check
    const discountHasErrors = (
      docToValidate.additionalCharges?.globalDiscount as any
    )?.hasError;
    if (discountHasErrors) {
      return false;
    }
    // Discount check ends

    // Validation for reserved stock
    if (docToValidate.reservedStock) {
      const isStockInvalid = docToValidate.quotationItemDtoList.some(
        (obj: any) =>
          (obj.product?.type === PRODUCT_TYPE.TRACKED ||
            obj.product?.type === PRODUCT_TYPE.BILL_OF_MATERIALS) &&
          Utility.isEmpty(obj.reservedQuantitiesData)
      );
      if (isStockInvalid) {
        showAlert(
          'Error!',
          'Reserve Stock details are not added for one or more products'
        );
        return false;
      }
    }
    // Validation for reserved stock ends

    return true;
  }
}
