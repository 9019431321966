import {
  DKButton,
  DKDataGrid,
  DKIcon,
  DKIcons,
  DKInput,
  DKLabel,
  DKLine,
  DKTooltipWrapper,
  INPUT_TYPE,
  showAlert,
  DKSegmentControl,
  showLoader,
  removeLoader
} from 'deskera-ui-library';
import { Fragment, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  BOOKS_DATE_FORMAT,
  INPUT_VIEW_DIRECTION,
  QTY_ROUNDOFF_PRECISION,
  TRACKING_TYPE
} from '../../../Constants/Constant';
import { useAppDispatch, useAppSelector } from '../../../Redux/Hooks';
import { activeTenantInfo } from '../../../Redux/Slices/AuthSlice';
import {
  fetchSerialBatchCount,
  selectSerialBatchCount
} from '../../../Redux/Slices/StockAdjustmentSlice';
import DateFormatService from '../../../Services/DateFormat';
import ProductService from '../../../Services/Product';
import Utility, {
  convertBooksDateFormatToUILibraryFormat,
  deepClone
} from '../../../Utility/Utility';
import {
  STOCK_ADJUSTMENT_TYPE,
  StockAdjustmentType
} from './StockAdjustmentConstant';
import { DynamicPopupWrapper } from '../../../SharedComponents/PopupWrapper';

interface StockAdjustmentFormState {
  product: any;
  adjustmentType: any;
  fulfillmentDocument: any;
  mode: any;
  document: any;
  index: any;
  documentUOMSchemaDefinition: any;
}

const advancedStockTrackingInitialState: StockAdjustmentFormState = {
  product: '',
  adjustmentType: '',
  fulfillmentDocument: '',
  mode: '',
  document: '',
  index: '',
  documentUOMSchemaDefinition: ''
};

const initialSerialFormData = {
  acquiredCost: 0,
  batchSize: 0,
  expiryDate: '',
  manufacturingDate: '',
  selected: false,
  serialBatchNumber: '',
  batchData: [],
  batchString: '',
  selectedBatchNumber: 0
};

export default function StockAdjustmentAdvancedStockTracking(props: any) {
  const { t, i18n } = useTranslation();

  const dispatch: any = useAppDispatch();
  /**
   * STATES & REFS
   */
  const [isLoading, setIsLoading] = useState(false);
  const [advancedStockTrackingFormData, setAdvancedStockTrackingFormData] =
    useState<StockAdjustmentFormState>(advancedStockTrackingInitialState);
  const formDataRef = useRef<any>(advancedStockTrackingInitialState);
  const [batchSerialNumber, setBatchSerialNumber] = useState<any[]>([]);
  const trackingDataForCurrentProduct = useRef<any[]>([]);
  const [openingQuantity, setOpeningQuantity] = useState<number>(0);
  const [totalAllocatedItem, setTotalAllocatedItem] = useState<number>(0);
  const [batchFormData, setBatchFormData] = useState<any>([]);
  const [serialFormData, setSerialFormData] = useState<any>(
    initialSerialFormData
  );
  const [serialCommittedData, setSerialCommittedData] = useState<any[]>([]);
  const [selectedViewIndex, setSelectedViewIndex] = useState<number>(0);
  /**
   * SELECTORS
   */

  const tenantDetails = useAppSelector(activeTenantInfo);
  const serialBatchCount = useAppSelector(selectSerialBatchCount);

  /**
   * USE EFFECTS
   */

  useEffect(() => {
    if (!Utility.isEmpty(props?.data)) {
      let newState: any = {
        ...advancedStockTrackingFormData
      };
      newState.product = props?.data?.product;
      newState.adjustmentType = props?.data?.adjustmentType;
      newState.fulfillmentDocument = props?.data?.document;
      newState.mode = props?.data?.mode;
      newState.document = props?.data?.formValue;
      newState.index = props?.data?.fulfillmentIndex;
      newState.documentUOMSchemaDefinition = props?.data?.formValue
        ?.stockAdjustmentItems?.[props?.data?.fulfillmentIndex]
        ?.documentUOMSchemaDefinition?.isBaseUom
        ? []
        : props?.data?.formValue?.stockAdjustmentItems?.[
            props?.data?.fulfillmentIndex
          ]?.documentUOMSchemaDefinition;
      formDataRef.current = { ...newState };
      setAdvancedStockTrackingFormData({ ...newState });
      fetchTrackingInfo(props?.data?.product?.pid);
      if (newState?.product?.advancedTracking === TRACKING_TYPE.SERIAL) {
        const qty = Utility.isEmpty(newState.documentUOMSchemaDefinition)
          ? props?.data?.product?.fulfilledQuantity
          : Utility.getUomWarehouseQuantity(
              props?.data?.product?.fulfilledQuantity,
              newState.documentUOMSchemaDefinition
            );
        setOpeningQuantity(qty);
      } else {
        setOpeningQuantity(props?.data?.product?.fulfilledQuantity);
      }
      const serialNumbers =
        props?.data?.formValue?.stockAdjustmentItems?.[
          props?.data?.fulfillmentIndex
        ]?.serialNumbers;
      if (Utility.isNotEmpty(serialNumbers)) {
        setSerialCommittedData(serialNumbers);
      }

      let batchDetails =
        props?.data?.formValue?.stockAdjustmentItems?.[
          props?.data?.fulfillmentIndex
        ]?.batchDetails;
      if (Utility.isNotEmpty(batchDetails)) {
        batchDetails = deepClone(batchDetails).map((batch: any) => ({
          serialBatchNumber: batch?.batch,
          selectedBatchNumber: 0,
          manufacturingDate: batch?.manufacturingDate ?? '',
          expiryDate: batch?.expiryDate ?? '',
          batchSize: batch?.quantity,
          selected: (Number(batch?.quantity) || 0) > 0
        }));
        setBatchFormData(batchDetails);
      }
    }
  }, [props?.data]);

  useEffect(() => {
    if (Utility.isNotEmpty(batchSerialNumber)) {
      if (
        !props?.isReadOnly &&
        props?.data?.product?.advancedTracking === TRACKING_TYPE.BATCH &&
        Utility.isEmptyObject(batchFormData)
      ) {
        const batchDetails = batchSerialNumber.map((item) => {
          return {
            selectedBatchNumber: getAvailableQuantity(item),
            expiryDate: Utility.isNotEmpty(item?.expiryDate)
              ? DateFormatService.getDateFromStr(
                  item.expiryDate,
                  BOOKS_DATE_FORMAT['DD-MM-YYYY']
                )?.toISOString()
              : '',
            manufacturingDate: Utility.isNotEmpty(item?.manufacturingDate)
              ? DateFormatService.getDateFromStr(
                  item.manufacturingDate,
                  BOOKS_DATE_FORMAT['DD-MM-YYYY']
                )?.toISOString()
              : '',
            selected: false,
            serialBatchNumber: item?.serialBatchNumber ?? '',
            batchSize: 0
          };
        });
        setBatchFormData(batchDetails);
      }
    }
  }, [batchSerialNumber]);

  const fetchTrackingInfo = (productCode: string) => {
    if (Utility.isNotEmpty(productCode)) {
      fetchAdvancedTrackingCount(productCode);
      fetchAdvancedTrackingData(productCode);
    }
  };
  const fetchAdvancedTrackingData = (productCode: string) => {
    const onSuccessAdvancedTracking = (data: any) => {
      if (data) {
        const { warehouseCode, rackCode, rowCode, binCode } =
          props?.data?.formValue;
        let currentWareHouseData = data.find(
          (element: any) => element.code === warehouseCode?.code
        );
        let batchOrSerialData = deepClone(
          currentWareHouseData?.advancedTrackingMeta || []
        );
        if (
          Utility.isNotEmpty(rowCode) ||
          Utility.isNotEmpty(rackCode) ||
          Utility.isNotEmpty(binCode)
        ) {
          batchOrSerialData = batchOrSerialData?.filter(
            (trackingObject: any) =>
              trackingObject.rowCode === rowCode &&
              trackingObject.rackCode === rackCode &&
              trackingObject.binCode === binCode
          );
        }
        trackingDataForCurrentProduct.current = batchOrSerialData;
        const trackingType = formDataRef?.current?.product?.advancedTracking;
        switch (trackingType) {
          case TRACKING_TYPE.BATCH:
          case TRACKING_TYPE.SERIAL:
            batchOrSerialData = batchOrSerialData?.filter(
              (serial: any) =>
                serial.batchSizeFulfilled < serial.batchSize &&
                serial.reservedQuantity < serial.batchSize
            );
            break;
          default:
            break;
        }
        if (trackingType === TRACKING_TYPE.BATCH && !props.isReadOnly) {
          setDataForBatch(batchOrSerialData);
        }
        setBatchSerialNumber(batchOrSerialData);
      }
    };
    showLoader('Fetching Advanced Tracking Data...');
    ProductService.fetchProductAdvancedTrackingWarehouse(
      productCode,
      false,
      true
    )
      .then(onSuccessAdvancedTracking)
      .catch((err: any) => {
        console.error('fetchAdvancedTrackingData :: ERR', err);
      })
      .finally(() => {
        removeLoader();
      });
  };
  const setDataForBatch = (batches: any) => {
    let batchData: any = [];
    props?.data?.formValue?.stockAdjustmentItems?.[
      props?.data?.fulfillmentIndex
    ]?.batchDetails.forEach((data: any) => {
      let batch = batches?.find(
        (ele: any) => ele.serialBatchNumber === data?.batch
      );
      if (
        props?.data?.formValue &&
        formDataRef?.current?.product?.advancedTracking === TRACKING_TYPE.BATCH
      ) {
        let batchObj = {
          batchSize: data.batch ? data?.quantity : 0,
          expiryDate: !Utility.isEmpty(data?.expiryDate)
            ? DateFormatService.getDateFromStr(
                data?.expiryDate,
                BOOKS_DATE_FORMAT['DD-MM-YYYY']
              )?.toISOString()
            : '',
          manufacturingDate: !Utility.isEmpty(data?.manufacturingDate)
            ? DateFormatService.getDateFromStr(
                data?.manufacturingDate,
                BOOKS_DATE_FORMAT['DD-MM-YYYY']
              )?.toISOString()
            : '',
          selected: Number(data?.quantity || 0) > 0,
          serialBatchNumber: data?.batch,
          selectedBatchNumber: getAvailableQuantity(batch)
        };
        batchData.push(batchObj);
      }
    });
    if (!Utility.isEmpty(batchData)) {
      let totalItem = batchData.reduce(
        (a: any, b: any) => +a + +parseFloat(b.batchSize || 0),
        0
      );
      setTotalAllocatedItem(totalItem);

      setBatchFormData(batchData);
    }
  };
  const validateAdvancedBatchStockTracking = () => {
    let totalSize = batchFormData.reduce(
      (a: number, b: any) => Number(a) + Number(b?.batchSize ?? 0),
      0
    );
    totalSize = Utility.roundingOff(totalSize, QTY_ROUNDOFF_PRECISION);

    const emptyBatch = batchFormData.filter((ele: any) =>
      Utility.isEmpty(ele.serialBatchNumber)
    );

    const isManufacturingDateEmpty = batchFormData.filter((ele: any) =>
      Utility.isEmpty(ele.manufacturingDate)
    );
    const isExpiryDateEmpty = batchFormData.filter((ele: any) =>
      Utility.isEmpty(ele.expiryDate)
    );
    const isExpiryDateSmall = batchFormData.filter(
      (ele: any) =>
        !Utility.isEmpty(ele.expiryDate) &&
        new Date(ele.expiryDate).getTime() <=
          new Date(ele.manufacturingDate).getTime() &&
        formDataRef?.current?.adjustmentType === StockAdjustmentType[0]
    );

    const isManufacturingDateGreater = batchFormData.filter(
      (ele: any) =>
        !Utility.isEmpty(ele.manufacturingDate) &&
        new Date(ele.manufacturingDate).getTime() >=
          new Date(ele.expiryDate).getTime() &&
        formDataRef?.current?.adjustmentType === StockAdjustmentType[0]
    );

    if (emptyBatch?.length > 0) {
      showAlert(
        'Error',
        `Total assigned quantity should be ${openingQuantity}, Please select appropriate batch for this stock adjustment.`
      );
      return false;
    } else if (+totalSize > +openingQuantity) {
      showAlert(
        'Error',
        'You are not allowed to assign batch quantity more than the receiving quantity.'
      );
      return false;
    } else if (totalSize <= 0) {
      showAlert('Error', 'Quantity cannot be less than or equal to zero');
      return false;
    } else if (+totalSize !== +openingQuantity) {
      showAlert(
        'Error',
        `Total assigned quantity should be ${openingQuantity}, Please select appropriate batch for this stock adjustment.`
      );
      return false;
    } else if (
      isManufacturingDateEmpty?.length > 0 &&
      formDataRef?.current?.adjustmentType === StockAdjustmentType[0]
    ) {
      showAlert('Error', 'Manufacturing date cannot be empty');
      return false;
    } else if (
      isExpiryDateEmpty?.length > 0 &&
      formDataRef?.current?.adjustmentType === StockAdjustmentType[0]
    ) {
      showAlert('Error', 'Expiry date cannot be empty');
      return false;
    } else if (isManufacturingDateGreater?.length > 0) {
      showAlert(
        'Invalid Date',
        'Manufacturing date should be less than or equal to expiry date'
      );
      return false;
    } else if (isExpiryDateSmall?.length > 0) {
      showAlert(
        'Invalid Date',
        'Expiry date should be greater than or equal to manufacturing date'
      );
      return false;
    }
    return true;
  };
  const validateAdvancedSerialStockTracking = () => {
    let totalSerials = serialCommittedData?.length;
    if (+totalSerials !== +openingQuantity) {
      showAlert(
        'Error',
        `Total assigned serials should be ${openingQuantity}, Please select appropriate serial for this stock adjustment.`
      );
      return false;
    }
    return true;
  };
  const saveAdvancedStockTracking = () => {
    if (
      advancedStockTrackingFormData?.product?.advancedTracking ===
      TRACKING_TYPE.BATCH
    ) {
      const isEmptyBatch = batchFormData.filter(
        (ele: any) =>
          (
            ele?.serialBatchNumber?.serialBatchNumber || ele?.serialBatchNumber
          )?.trim() === ''
      );
      if (isEmptyBatch?.length > 0) {
        showAlert('Error', 'Empty batch not allowed');
        return;
      }
      let newState = { ...advancedStockTrackingFormData };
      let stockAdjustmentItems: any = [
        ...newState?.document?.stockAdjustmentItems
      ];
      stockAdjustmentItems[advancedStockTrackingFormData?.index] = {
        ...stockAdjustmentItems[advancedStockTrackingFormData?.index],
        batchDetails: batchFormData.map((ele: any) => {
          return {
            batch:
              ele?.serialBatchNumber?.serialBatchNumber ||
              ele?.serialBatchNumber,
            expiryDate: DateFormatService.getDateStrFromDate(
              new Date(ele?.expiryDate),
              BOOKS_DATE_FORMAT['DD-MM-YYYY']
            ),
            manufacturingDate: DateFormatService.getDateStrFromDate(
              new Date(ele?.manufacturingDate),
              BOOKS_DATE_FORMAT['DD-MM-YYYY']
            ),
            quantity: ele?.batchSize
          };
        })
      };
      newState.document = {
        ...newState.document,
        stockAdjustmentItems
      };
      setAdvancedStockTrackingFormData({ ...newState });

      if (validateAdvancedBatchStockTracking()) {
        props.onSave(
          stockAdjustmentItems[advancedStockTrackingFormData?.index]
        );
      }
    } else if (
      advancedStockTrackingFormData?.product?.advancedTracking ===
      TRACKING_TYPE.SERIAL
    ) {
      let newState = { ...advancedStockTrackingFormData };
      let stockAdjustmentItems: any = [
        ...newState?.document?.stockAdjustmentItems
      ];
      stockAdjustmentItems[advancedStockTrackingFormData?.index] = {
        ...stockAdjustmentItems[advancedStockTrackingFormData?.index],
        batchDetails: [
          {
            batch: null,
            expiryDate: DateFormatService.getDateStrFromDate(
              new Date(),
              BOOKS_DATE_FORMAT['DD-MM-YYYY']
            ),
            manufacturingDate: DateFormatService.getDateStrFromDate(
              new Date(),
              BOOKS_DATE_FORMAT['DD-MM-YYYY']
            ),
            quantity: stockAdjustmentItems?.[0]?.batchDetails?.quantity
          }
        ],
        serialNumbers: serialCommittedData
      };
      newState.document = {
        ...newState.document,
        stockAdjustmentItems
      };
      setAdvancedStockTrackingFormData({ ...newState });
      if (validateAdvancedSerialStockTracking()) {
        props.onSave(
          stockAdjustmentItems[advancedStockTrackingFormData?.index]
        );
      }
    }
  };
  const fetchAdvancedTrackingCount = (productCode: string) => {
    dispatch(fetchSerialBatchCount(productCode));
  };
  const getHeader = () => {
    return (
      <div
        style={{ zIndex: 1 }}
        className="row justify-content-between p-h-r p-v-s bg-gray1 sticky top-0"
      >
        <div className="row pop-header-drag-handle">
          <DKLabel text={`Advanced Stock Tracking`} className="fw-m fs-l" />
        </div>
        <div className="row justify-content-end">
          <DKButton
            title={'Cancel'}
            className={
              isLoading ? 'bg-gray1 border-m mr-r' : 'bg-white border-m mr-r'
            }
            onClick={() => {
              if (!isLoading) {
                props.onCancel();
              }
            }}
          />
          {!props?.isReadOnly && (
            <DKButton
              title={isLoading ? 'Save...' : 'Save'}
              className={
                isLoading
                  ? 'bg-gray1 text-black border-m'
                  : 'bg-button text-white'
              }
              onClick={() => {
                if (!isLoading) {
                  saveAdvancedStockTracking();
                }
              }}
            />
          )}
        </div>
      </div>
    );
  };
  const getQuantityToAssign = () => {
    let quantityToAssign = openingQuantity - totalAllocatedItem;
    const { advancedTracking = null } = advancedStockTrackingFormData?.product;
    if (advancedTracking === TRACKING_TYPE.SERIAL) {
      quantityToAssign = openingQuantity - serialCommittedData.length;
    }
    return Utility.roundingOff(quantityToAssign, QTY_ROUNDOFF_PRECISION);
  };
  const productDetailSection = () => {
    const stockAvailable = !Utility.isEmpty(
      advancedStockTrackingFormData.documentUOMSchemaDefinition
    )
      ? Utility.getUomQuantity(
          advancedStockTrackingFormData?.product?.availableProductQuantity,
          advancedStockTrackingFormData.documentUOMSchemaDefinition
        )
      : advancedStockTrackingFormData?.product?.availableProductQuantity;

    const batchSerialAssigned = Utility.isEmpty(
      advancedStockTrackingFormData.documentUOMSchemaDefinition
    )
      ? serialBatchCount?.totalQtyAvailable
        ? serialBatchCount?.totalQtyAvailable
        : 0
      : Utility.getUomQuantity(
          serialBatchCount?.totalQtyAvailable || 0,
          advancedStockTrackingFormData.documentUOMSchemaDefinition
        );
    return (
      <div className="column parent-width py-3 border-b">
        <div className="items-start flex w-full justify-between">
          <div className="w-2/3 flex">
            <div className="w-1/3 text-left mx-4">
              <div className="font-bold">Product Name</div>
              <DKTooltipWrapper
                content={
                  advancedStockTrackingFormData?.product?.productName || '-'
                }
                tooltipClassName="break-text bg-deskera-secondary"
              >
                <div className="my-2 truncate">
                  {advancedStockTrackingFormData?.product?.productName || '-'}
                </div>
              </DKTooltipWrapper>
            </div>
            {!props?.isReadOnly && (
              <div className="w-2/3 text-left mx-4">
                <div className="font-bold">Inventory Details</div>
                <div className="row mt-2">
                  <div className="w-1/2 text-left">Tracking Method</div>
                  <div className="w-1/2 text-right">
                    {advancedStockTrackingFormData?.product
                      ?.advancedTracking === TRACKING_TYPE.BATCH
                      ? 'Batch Number'
                      : advancedStockTrackingFormData?.product
                          ?.advancedTracking === TRACKING_TYPE.SERIAL
                      ? 'Serial Number'
                      : ''}
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="w-1/2 text-left">Stock Available</div>
                  <div className="w-1/2 text-right">
                    {Utility.roundingOff(
                      stockAvailable,
                      QTY_ROUNDOFF_PRECISION
                    )}
                    {!Utility.isEmpty(
                      advancedStockTrackingFormData.documentUOMSchemaDefinition
                    ) && (
                      <span>
                        {' '}
                        {
                          advancedStockTrackingFormData
                            .documentUOMSchemaDefinition.name
                        }
                      </span>
                    )}
                  </div>
                </div>
                {!Utility.isEmpty(
                  advancedStockTrackingFormData.documentUOMSchemaDefinition
                ) && (
                  <div className="row mt-2">
                    <div className="w-1/2 text-left">
                      Stock Available in Base UOM
                    </div>
                    <div className="w-1/2 text-right">
                      {Utility.roundingOff(
                        advancedStockTrackingFormData?.product
                          ?.availableProductQuantity,
                        QTY_ROUNDOFF_PRECISION
                      )}
                    </div>
                  </div>
                )}
                <div className="row mt-2">
                  <div className="w-1/2 text-left">
                    {advancedStockTrackingFormData?.product
                      ?.advancedTracking === TRACKING_TYPE.SERIAL
                      ? 'Serial Assigned'
                      : advancedStockTrackingFormData?.product
                          ?.advancedTracking === TRACKING_TYPE.BATCH
                      ? 'Batch Assigned'
                      : ''}
                  </div>
                  <div
                    className={`${
                      serialBatchCount?.totalQtyAvailable >
                      advancedStockTrackingFormData?.product
                        ?.availableProductQuantity
                        ? 'text-red'
                        : ''
                    } w-1/2 text-right`}
                  >
                    {Utility.roundingOff(
                      batchSerialAssigned,
                      QTY_ROUNDOFF_PRECISION
                    )}
                    {!Utility.isEmpty(
                      advancedStockTrackingFormData.documentUOMSchemaDefinition
                    ) && (
                      <span>
                        {' '}
                        {
                          advancedStockTrackingFormData
                            .documentUOMSchemaDefinition.name
                        }
                      </span>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
          {!props?.isReadOnly && (
            <div className="w-1/3 text-right mx-4">
              <div className="font-bold">Adjustment Details</div>
              <div className="row-reverse mt-2">
                <div className="w-4/5 flex items-end justify-between">
                  <div>Stock to adjust</div>
                  <div>
                    {Utility.roundingOff(
                      advancedStockTrackingFormData?.product?.quantityRequired,
                      QTY_ROUNDOFF_PRECISION
                    )}
                  </div>
                </div>
              </div>
              <div className="row-reverse mt-2">
                <div className="w-4/5 flex items-end justify-between">
                  <div>To assign</div>
                  <div
                    className={`${
                      openingQuantity - totalAllocatedItem > 0 ? 'text-red' : ''
                    }`}
                  >
                    {getQuantityToAssign()}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  };
  const onBatchRowUpdate = (data: any, fieldName: string) => {
    let newState: any = [...batchFormData];
    const rowIndex = data['rowIndex'];
    const item = data['rowData'];
    switch (data?.columnKey) {
      case 'serialBatchNumber':
        if (data.rowData.serialBatchNumber) {
          let x = batchSerialNumber.filter(
            (record: any) =>
              record.serialBatchNumber === data.rowData.serialBatchNumber
          );
          if (x && x.length > 0) {
            data.rowData.serialBatchNumber = x[0];
          }
        }
        let manufacturingDate = Utility.isEmpty(
          data.rowData.serialBatchNumber['manufacturingDate']
        )
          ? ''
          : DateFormatService.getDateFromStr(
              data.rowData.serialBatchNumber['manufacturingDate'],
              BOOKS_DATE_FORMAT['DD-MM-YYYY']
            );
        let expiryDate = Utility.isEmpty(
          data.rowData.serialBatchNumber['expiryDate']
        )
          ? ''
          : DateFormatService.getDateFromStr(
              data.rowData.serialBatchNumber['expiryDate'],
              BOOKS_DATE_FORMAT['DD-MM-YYYY']
            );
        const batchSize = Utility.isNotEmpty(
          advancedStockTrackingFormData.documentUOMSchemaDefinition
        )
          ? Utility.getUomQuantity(
              item?.serialBatchNumber?.batchSize || 0,
              advancedStockTrackingFormData.documentUOMSchemaDefinition
            )
          : item?.serialBatchNumber?.batchSize || 0;

        const batchSizeFulfilled = Utility.isNotEmpty(
          advancedStockTrackingFormData.documentUOMSchemaDefinition
        )
          ? Utility.getUomQuantity(
              item?.serialBatchNumber?.batchSizeFulfilled || 0,
              advancedStockTrackingFormData.documentUOMSchemaDefinition
            )
          : item?.serialBatchNumber?.batchSizeFulfilled || 0;

        const reservedQuantity = Utility.isNotEmpty(
          advancedStockTrackingFormData.documentUOMSchemaDefinition
        )
          ? Utility.getUomQuantity(
              item?.serialBatchNumber?.reservedQuantity || 0,
              advancedStockTrackingFormData.documentUOMSchemaDefinition
            )
          : item?.serialBatchNumber?.reservedQuantity || 0;
        let existingAllocatedQuantities = 0;
        if (Utility.isNotEmpty(props?.existingSerialBatchNumbers)) {
          existingAllocatedQuantities =
            props?.existingSerialBatchNumbers.reduce(
              (acc: number, curr: any) => {
                if (curr.batch === item.serialBatchNumber?.serialBatchNumber) {
                  acc = acc + Number(curr.quantity);
                }
                return acc;
              },
              0
            );

          if (existingAllocatedQuantities > 0) {
            existingAllocatedQuantities = Utility.isNotEmpty(
              advancedStockTrackingFormData.documentUOMSchemaDefinition
            )
              ? Utility.getUomQuantity(
                  existingAllocatedQuantities,
                  advancedStockTrackingFormData.documentUOMSchemaDefinition
                )
              : existingAllocatedQuantities;
          }
        }
        newState[rowIndex].selectedBatchNumber =
          batchSize -
          (batchSizeFulfilled + reservedQuantity + existingAllocatedQuantities);

        newState[rowIndex].manufacturingDate =
          formDataRef?.current?.adjustmentType.value === 'STOCK_IN'
            ? Utility.isEmpty(manufacturingDate?.toString())
              ? manufacturingDate
              : DateFormatService.getDateFromStr(
                  data.rowData.serialBatchNumber['manufacturingDate'],
                  BOOKS_DATE_FORMAT['DD-MM-YYYY']
                )?.toISOString()
            : manufacturingDate;
        newState[rowIndex].expiryDate =
          formDataRef?.current?.adjustmentType.value === 'STOCK_IN'
            ? Utility.isEmpty(expiryDate?.toString())
              ? expiryDate
              : DateFormatService.getDateFromStr(
                  data.rowData.serialBatchNumber['expiryDate'],
                  BOOKS_DATE_FORMAT['DD-MM-YYYY']
                )?.toISOString()
            : expiryDate;
        newState[rowIndex].serialBatchNumber =
          data.rowData.serialBatchNumber?.serialBatchNumber ||
          data.rowData.serialBatchNumber;
        setBatchFormData(newState);
        break;
      case 'manufacturingDate':
        newState[rowIndex].manufacturingDate =
          data.rowData['manufacturingDate'];
        if (
          Utility.isEmpty(data.rowData['expiryDate']) ||
          new Date(data.rowData['manufacturingDate']).getTime() <=
            new Date(data.rowData['expiryDate']).getTime()
        ) {
          newState[rowIndex].manufacturingDate =
            data.rowData['manufacturingDate'];
        }
        if (
          formDataRef?.current?.adjustmentType.value === 'STOCK_IN' &&
          !Utility.isEmpty(data.rowData['expiryDate']) &&
          new Date(data.rowData['manufacturingDate']).getTime() >=
            new Date(data.rowData['expiryDate']).getTime()
        ) {
          newState[rowIndex].manufacturingDate = '';
          showAlert(
            'Invalid Date',
            'Manufacturing date should be less than or equal to expiry date'
          );
        }
        setBatchFormData(newState);
        break;
      case 'expiryDate':
        if (
          Utility.isEmpty(data.rowData['manufacturingDate']) ||
          new Date(data.rowData['manufacturingDate']).getTime() <=
            new Date(data.rowData['expiryDate']).getTime()
        ) {
          newState[rowIndex].expiryDate = data.rowData['expiryDate'];
        }
        if (
          formDataRef?.current?.adjustmentType.value === 'STOCK_IN' &&
          !Utility.isEmpty(data.rowData['manufacturingDate']) &&
          new Date(data.rowData['manufacturingDate']).getTime() >=
            new Date(data.rowData['expiryDate']).getTime()
        ) {
          newState[rowIndex].expiryDate = '';
          showAlert(
            'Invalid Date',
            'Expiry date should be greater than or equal to manufacturing date'
          );
        }
        setBatchFormData(newState);
        break;
      case 'batchSize':
        if (
          formDataRef?.current?.adjustmentType.value === 'STOCK_OUT' &&
          data.rowData['batchSize'] > data.rowData['selectedBatchNumber']
        ) {
          showAlert(
            'Error',
            'Assigned quantity should  be less than or equal to  quantity in batch'
          );
          newState[rowIndex].batchSize = 0;
        } else {
          newState[rowIndex].batchSize = Utility.isNotEmpty(
            data.rowData['batchSize']
          )
            ? data.rowData['batchSize']
            : 0;
        }
        newState[rowIndex].selected = newState[rowIndex].batchSize > 0;
        newState[rowIndex].batchSize = Utility.roundingOff(
          newState[rowIndex].batchSize,
          QTY_ROUNDOFF_PRECISION
        );
        setBatchFormData(newState);
        let totalItem = newState.reduce(
          (a: any, b: any) =>
            +a + +Utility.roundingOff(b.batchSize ?? 0, QTY_ROUNDOFF_PRECISION),
          0
        );
        setTotalAllocatedItem(totalItem);

        break;
    }
  };
  const addNewItem = () => {
    let rows = [...batchFormData];
    const nextYearDate = new Date();
    nextYearDate.setDate(nextYearDate.getDay() + 365);
    let newRow = {};
    if (formDataRef?.current?.adjustmentType.value === 'STOCK_IN') {
      newRow = {
        serialBatchNumber: '',
        selectedBatchNumber: 0,
        manufacturingDate: new Date(),
        expiryDate: nextYearDate,
        batchSize: 0
      };
    }
    rows.push(newRow);
    setBatchFormData(rows);
  };
  const onDelete = ({ rowIndex }: any) => {
    let rows = [...batchFormData];
    rows.splice(rowIndex, 1);

    let totalItem = rows.reduce(
      (a: any, b: any) =>
        +a + +Utility.roundingOff(b.batchSize ?? 0, QTY_ROUNDOFF_PRECISION),
      0
    );
    setTotalAllocatedItem(totalItem);
    setBatchFormData(rows);
  };
  const getDataGridColumns = () => {
    const columns = [];
    if (formDataRef?.current?.adjustmentType.value === 'STOCK_IN') {
      columns.push({
        key: 'serialBatchNumber',
        name: 'Batch Number',
        type: INPUT_TYPE.TEXT,
        textAlign: 'left',
        width: 145,
        systemField: true,
        editable: !props.isReadOnly,
        hidden: false,
        uiVisible: true,
        formatter: (obj: any, index: any) => {
          return Utility.isObject(obj?.value.serialBatchNumber)
            ? obj?.value?.serialBatchNumber?.serialBatchNumber
            : obj?.value?.serialBatchNumber || obj?.value || '';
        },
        renderer: (obj: any) => {
          return (
            <DKLabel text={obj?.value?.serialBatchNumber || obj?.value || ''} />
          );
        },
        dropdownConfig: {
          data: batchSerialNumber,
          renderer: (obj: any) => {
            return Utility.isObject(obj?.serialBatchNumber)
              ? obj?.serialBatchNumber?.serialBatchNumber
              : obj?.serialBatchNumber ||
                  obj.value?.serialBatchNumber ||
                  obj.value ||
                  obj.serialBatchNumber;
          },
          onSelect: (index: any, value: any) => {}
        }
      });
      if (!props?.isReadOnly) {
        columns.push({
          key: 'selectedBatchNumber',
          name: 'Qty in Batch',
          type: INPUT_TYPE.TEXT,
          textAlign: 'right',
          width: 140,
          systemField: true,
          editable: false,
          hidden: false,
          uiVisible: true
        });
      }
      columns.push({
        key: 'manufacturingDate',
        name: 'Manufactured Date',
        textAlign: 'left',
        type: props?.isReadOnly ? INPUT_TYPE.TEXT : INPUT_TYPE.DATE,
        width: 150,
        systemField: true,
        editable: !props.isReadOnly,
        hidden: false,
        uiVisible: true
      });
      columns.push({
        key: 'expiryDate',
        name: 'Expiry Date',
        type: props?.isReadOnly ? INPUT_TYPE.TEXT : INPUT_TYPE.DATE,
        width: 150,
        textAlign: 'left',
        systemField: true,
        editable: !props.isReadOnly,
        hidden: false,
        uiVisible: true
      });
      columns.push({
        key: 'batchSize',
        name: 'Qty Assigned',
        type: INPUT_TYPE.NUMBER,
        textAlign: 'right',
        width: 140,
        systemField: true,
        editable: !props.isReadOnly,
        hidden: false,
        uiVisible: true
      });
      if (!props?.isReadOnly) {
        columns.push({
          key: 'action',
          name: '',
          type: INPUT_TYPE.BUTTON,
          width: 80,
          options:
            batchFormData && batchFormData.length > 1
              ? [
                  {
                    icon: DKIcons.ic_delete,
                    className: ' p-0',
                    onClick: (data: any) => onDelete(data)
                  }
                ]
              : []
        });
      }
    } else {
      columns.push({
        key: 'serialBatchNumber',
        name: 'Batch Number',
        type: INPUT_TYPE.DROPDOWN,
        textAlign: 'left',
        width: 150,
        systemField: true,
        editable: !props.isReadOnly,
        hidden: false,
        uiVisible: true,
        formatter: (obj: any, index: any) => {
          return Utility.isObject(obj?.value.serialBatchNumber)
            ? obj?.value?.serialBatchNumber?.serialBatchNumber
            : obj?.value?.serialBatchNumber || obj?.value || '';
        },
        renderer: (obj: any) => {
          return (
            <DKLabel
              text={
                Utility.isObject(obj?.value.serialBatchNumber)
                  ? obj?.value?.serialBatchNumber?.serialBatchNumber
                  : obj?.value?.serialBatchNumber || obj?.value || ''
              }
            />
          );
        },
        dropdownConfig: {
          data: batchSerialNumber,
          renderer: (index: any, obj: any) => {
            return Utility.isObject(obj?.serialBatchNumber)
              ? obj?.serialBatchNumber?.serialBatchNumber
              : obj?.serialBatchNumber ||
                  obj.value?.serialBatchNumber ||
                  obj.value ||
                  obj.serialBatchNumber;
          },
          onSelect: (index: any, value: any) => {}
        }
      });
      if (!props?.isReadOnly) {
        columns.push({
          key: 'selectedBatchNumber',
          name: 'Qty in Batch',
          type: INPUT_TYPE.TEXT,
          textAlign: 'right',
          width: 150,
          systemField: true,
          editable: false,
          hidden: false,
          uiVisible: true
        });
      }

      columns.push({
        key: 'manufacturingDate',
        name: 'Manufactured Date',
        textAlign: 'left',
        type: props?.isReadOnly ? INPUT_TYPE.TEXT : INPUT_TYPE.DATE,
        width: 140,
        systemField: true,
        editable: false,
        hidden: false,
        uiVisible: true
      });
      columns.push({
        key: 'expiryDate',
        name: 'Expiry Date',
        type: props?.isReadOnly ? INPUT_TYPE.TEXT : INPUT_TYPE.DATE,
        width: 140,
        textAlign: 'left',
        systemField: true,
        editable: false,
        hidden: false,
        uiVisible: true
      });
      columns.push({
        key: 'batchSize',
        name: 'Qty Assigned',
        type: INPUT_TYPE.NUMBER,
        textAlign: 'right',
        width: 140,
        systemField: true,
        editable: !props.isReadOnly,
        hidden: false,
        uiVisible: true
      });
      // if (!props?.isReadOnly) {
      //   columns.push({
      //     id: 'action',
      //     key: 'action',
      //     name: '',
      //     type: INPUT_TYPE.BUTTON,
      //     width: 80,
      //     options:
      //       batchFormData && batchFormData.length > 1
      //         ? [
      //             {
      //               icon: DKIcons.ic_delete,
      //               className: ' p-0',
      //               onClick: (data: any) => onDelete(data)
      //             }
      //           ]
      //         : []
      //   });
      // }
    }
    return columns;
  };
  const getBatchTrackingGrid = () => {
    const selectedRows =
      batchFormData?.filter((item: any) => item.selected)?.length || 0;
    const isAllRowSelected = (batchFormData?.length || 0) === selectedRows;

    const onRowSelect = ({ rowIndex, rowData }: any) => {
      // const copyOfBatchFormData = [...batchFormData];
      // copyOfBatchFormData[rowIndex] = rowData;
      // setBatchFormData(copyOfBatchFormData);
    };

    const onAllRowSelected = ({ selected }: any) => {
      // const copyOfBatchFormData = [...batchFormData];
      // copyOfBatchFormData.forEach((item: any) => {
      //   item.selected = selected;
      // });
      // setBatchFormData(copyOfBatchFormData);
    };
    const rows =
      selectedViewIndex === 0
        ? batchFormData
        : batchFormData.filter((item: any) => item.selected);

    const columns = getDataGridColumns();

    let width = 500;
    columns?.reduce((acc, curr) => {
      width = acc + curr.width;
      return width;
    }, 0);

    return (
      <>
        {!props?.isReadOnly && (
          <div className="row">
            <div style={{ width: 200 }} className="">
              <DKSegmentControl
                segments={['Available', `Selected (${selectedRows})`]}
                backgroundColor="bg-gray2"
                selectedColor=""
                barColor="bg-white"
                selectedIndex={selectedViewIndex}
                onSelect={(index: number) => {
                  setSelectedViewIndex(index);
                }}
              />
            </div>
          </div>
        )}
        <DKDataGrid
          isAllRowSelected={isAllRowSelected}
          onAllRowSelect={onAllRowSelected}
          needShadow={true}
          needBorder={true}
          needColumnIcons={false}
          needTrailingColumn={true}
          allowBulkOperation={!props?.isReadOnly}
          allowColumnSort={false}
          allowColumnAdd={false}
          allowColumnEdit={false}
          allowRowEdit={!props?.isReadOnly}
          onRowUpdate={onBatchRowUpdate}
          currentPage={1}
          totalPageCount={1}
          title={''}
          dateFormat={convertBooksDateFormatToUILibraryFormat(
            tenantDetails.dateFormat
          )}
          columns={columns}
          rows={[...rows]}
          onRowSelect={onRowSelect}
          width={width}
        />
      </>
    );
  };
  const changeSerialFormData = (data: any, fieldName: string) => {
    let newState = { ...serialFormData };
    switch (fieldName) {
      case 'batchString':
        newState.batchString = data;
        setSerialFormData({ ...newState });
        break;
      case 'batchAssign':
        let committedUnits: any = [];
        if (
          advancedStockTrackingFormData?.adjustmentType ===
          StockAdjustmentType[1]
        ) {
          if ([...serialCommittedData].length < openingQuantity) {
            let serialData: any = [];
            if (Array.isArray(newState?.batchString)) {
              newState.batchString.forEach((ele: any) => {
                serialData.push(batchSerialNumber[ele]);
              });
            }
            let committedSerial: any = [];
            serialData.forEach((serial: any) => {
              if (
                ![...serialCommittedData].includes(serial?.serialBatchNumber)
              ) {
                committedUnits.push(serial?.serialBatchNumber);
                const serials = serial?.serialBatchNumber;
                committedSerial.push(serials);
              } else {
                showAlert('Error', 'Duplicate Serial Number');
              }
            });
            setSerialCommittedData((prev) => [...prev, ...committedSerial]);
          } else {
            showAlert(
              'Error',
              'You are not allowed to assign more serial number than the committing quantity.'
            );
          }
        } else {
          if ([...serialCommittedData].length < openingQuantity) {
            if (
              ![...serialCommittedData].includes(
                newState?.batchString?.serialBatchNumber
              )
            ) {
              committedUnits = newState.batchString
                .split(',')
                .map((serialNumber: string) => serialNumber.trim());
              committedUnits = Array.from(
                new Set([...serialCommittedData, ...committedUnits])
              ).filter((item: any) => Utility.isNotEmpty(item?.trim()));
              let existingSerialBatchNumbers =
                trackingDataForCurrentProduct?.current?.map(
                  (batchItem) => batchItem?.serialBatchNumber
                );
              if (Utility.isNotEmpty(props?.existingSerialBatchNumbers)) {
                existingSerialBatchNumbers = [
                  ...existingSerialBatchNumbers,
                  ...props.existingSerialBatchNumbers
                ];
              }
              if (existingSerialBatchNumbers?.length > 0) {
                let isDuplicateSerialExist = false;
                committedUnits = committedUnits.filter((item: string) => {
                  if (existingSerialBatchNumbers.includes(item)) {
                    isDuplicateSerialExist = true;
                    return false;
                  }
                  return true;
                });
                if (isDuplicateSerialExist) {
                  showAlert(
                    'Duplicate Serial Number Found!',
                    'Duplicate Serial Numbers are not allowed, we have filtered them out.'
                  );
                }
              }
              if (committedUnits.length <= openingQuantity) {
                setSerialCommittedData(committedUnits);
              } else {
                showAlert(
                  'Error',
                  'You are not allowed to assign more serial number than the committing quantity.'
                );
              }
            } else {
              showAlert('Error', 'Duplicate Serial Number');
            }
          } else {
            showAlert(
              'Error',
              'You are not allowed to assign more serial number than the committing quantity.'
            );
          }
        }

        let committedBatchDetails: any = [];
        committedUnits?.forEach((unit: any) => {
          let newBatchDetail = {
            batchSize: 1,
            expiryDate: DateFormatService.getDateStrFromDate(
              new Date(),
              BOOKS_DATE_FORMAT['DD-MM-YYYY']
            ),
            manufacturingDate: DateFormatService.getDateStrFromDate(
              new Date(),
              BOOKS_DATE_FORMAT['DD-MM-YYYY']
            ),
            serialBatchNumber: unit
          };
          committedBatchDetails.push(newBatchDetail);
        });
        newState.batchString = '';
        newState.batchData = [...newState.batchData, ...committedBatchDetails];
        setSerialFormData({ ...newState });
        break;
      default:
        break;
    }
  };
  const removeCommittedUnits = (index: number) => {
    if (serialCommittedData.length) {
      let tempSerialCommittedData = [...serialCommittedData];
      tempSerialCommittedData.splice(index, 1);
      setSerialCommittedData([...tempSerialCommittedData]);
    }
  };
  const serialProductAdjustment = () => {
    return (
      <>
        {!props?.isReadOnly && (
          <>
            <div className="column parent-width mx-4 mt-2">
              <DKLabel text="Input Serial Numbers to commit them to this Adjustment." />
              {!Number.isInteger(
                advancedStockTrackingFormData?.product?.quantityRequired
              ) && (
                <DKLabel
                  text="Stock in is not allowed for this product as assign quantity in decimal."
                  className="text-red mt-2"
                />
              )}
            </div>
            <div className="parent-width row justify-content-between align-items-center mt-m px-4">
              <div style={{ width: '85%' }}>
                {advancedStockTrackingFormData?.adjustmentType ===
                  StockAdjustmentType[1] && (
                  <DKInput
                    type={INPUT_TYPE.DROPDOWN}
                    title={''}
                    renderer={(value: any) => {
                      return (
                        <div className="row flex-wrap" style={{ gap: 5 }}>
                          {value?.map((index: any) => (
                            <DKLabel
                              className="fs-r text-align-left bg-white border-radius-s p-h-xs shadow-s-2"
                              style={{ overflowWrap: 'anywhere' }}
                              text={`${batchSerialNumber?.[index]?.serialBatchNumber}`}
                            />
                          ))}
                        </div>
                      );
                    }}
                    direction={INPUT_VIEW_DIRECTION.VERTICAL}
                    value={serialFormData?.batchString}
                    displayKey="serialBatchNumber"
                    canValidate={false}
                    required={true}
                    onChange={(obj: any) => {
                      changeSerialFormData(obj, 'batchString');
                    }}
                    className=""
                    dropdownConfig={{
                      title: '',
                      allowSearch: true,
                      searchableKey: 'serialBatchNumber',
                      canEdit: false,
                      checkMarkColor: 'bg-blue',
                      canDelete: false,
                      multiSelect: true,
                      style: { minWidth: 150 },
                      className: 'shadow-m width-auto',
                      searchApiConfig: null,
                      data: batchSerialNumber,
                      renderer: (index: any, obj: any) => {
                        return <DKLabel text={`${obj?.serialBatchNumber}`} />;
                      }
                    }}
                  />
                )}
                {advancedStockTrackingFormData?.adjustmentType ===
                  StockAdjustmentType[0] && (
                  <DKInput
                    title=""
                    required={true}
                    value={serialFormData?.batchString}
                    onChange={(value: string) => {
                      changeSerialFormData(value, 'batchString');
                    }}
                    direction={INPUT_VIEW_DIRECTION.VERTICAL}
                    canValidate={false}
                    errorMessage={''}
                  />
                )}
              </div>
              <DKButton
                title={'Assign'}
                className={`${
                  !Number.isInteger(
                    advancedStockTrackingFormData?.product?.quantityRequired
                  )
                    ? `bg-gray1 text-black`
                    : `bg-button text-white `
                } fs-xm`}
                onClick={() => {
                  changeSerialFormData('', 'batchAssign');
                }}
                disabled={
                  !Number.isInteger(
                    advancedStockTrackingFormData?.product?.quantityRequired
                  )
                }
              />
            </div>
            {advancedStockTrackingFormData?.adjustmentType ===
              StockAdjustmentType[0] && (
              <div className="parent-width mt-l px-4">
                <DKLabel
                  text="Use comma separator for multiple entries (e.g. Serial1,Serial2)"
                  className="fs-s"
                />
              </div>
            )}
          </>
        )}
        <div className="px-4 parent-width ">
          <DKLine className="parent-width mt-m bg-gray2" />
          {!props?.isReadOnly && (
            <>
              <div className="parent-width row justify-content-between align-items-center mt-m">
                <DKLabel text="Committed units" className="fw-m fs-m" />
                <DKLabel
                  text={`Total (${serialCommittedData.length})`}
                  className="fw-m fs-m"
                />
              </div>
              <DKLine className="parent-width mt-m bg-gray2" />
            </>
          )}
          <div className=" parent-width">
            {serialCommittedData.map((committedData: any, index: number) => {
              return (
                <Fragment>
                  <div className="row justify-content-between align-items-center my-2">
                    <div style={{ width: '60%' }}>
                      <DKLabel text={committedData} className="fw-s fs-m" />
                    </div>
                    {!props?.isReadOnly && (
                      <div className="flex align-items-center">
                        <DKIcon
                          src={DKIcons.ic_delete}
                          className="ic-s cursor-pointer ml-r"
                          onClick={() => {
                            removeCommittedUnits(index);
                          }}
                        />
                      </div>
                    )}
                  </div>
                  <DKLine className="parent-width bg-gray2" />
                </Fragment>
              );
            })}
          </div>
        </div>
      </>
    );
  };
  const getAvailableQuantity = (batch: any) => {
    const availableQuantity =
      (Utility.isNotEmpty(
        advancedStockTrackingFormData.documentUOMSchemaDefinition
      )
        ? Utility.getUomQuantity(
            batch?.batchSize || 0,
            advancedStockTrackingFormData.documentUOMSchemaDefinition
          )
        : batch?.batchSize || 0) -
      (Utility.isNotEmpty(
        advancedStockTrackingFormData.documentUOMSchemaDefinition
      )
        ? Utility.getUomQuantity(
            batch?.batchSizeFulfilled || 0,
            advancedStockTrackingFormData.documentUOMSchemaDefinition
          )
        : batch?.batchSizeFulfilled || 0) -
      (Utility.isNotEmpty(
        advancedStockTrackingFormData.documentUOMSchemaDefinition
      )
        ? Utility.getUomQuantity(
            batch?.reservedQuantity || 0,
            advancedStockTrackingFormData.documentUOMSchemaDefinition
          )
        : batch?.reservedQuantity || 0);
    return Utility.roundingOff(availableQuantity, QTY_ROUNDOFF_PRECISION);
  };
  const advancedTracking =
    advancedStockTrackingFormData?.product?.advancedTracking;
  const isStockIn =
    advancedStockTrackingFormData.adjustmentType?.value ===
    STOCK_ADJUSTMENT_TYPE.STOCK_IN;
  return (
    <DynamicPopupWrapper>
      <div className="transparent-background">
        <div
          className="popup-window"
          style={{
            maxWidth: 850,
            width: '100%',
            maxHeight: '95%',
            height: 565,
            padding: 0,
            paddingBottom: 10,
            overflow: 'hidden'
          }}
        >
          {getHeader()}
          <div className="column parent-width p-h-r p-v-s overflow-y-scroll overflow-x-hide hide-scroll-bar parent-height">
            {productDetailSection()}
            <div className="row my-4 font-bold">
              <DKLabel text="Commit Stock to Adjustment" />
            </div>
            {advancedTracking === TRACKING_TYPE.SERIAL &&
              serialProductAdjustment()}
            {advancedTracking === TRACKING_TYPE.BATCH && (
              <>
                {getBatchTrackingGrid()}
                {isStockIn && !props?.isReadOnly && (
                  <div className="">
                    <DKButton
                      title={`+ Add Batch`}
                      onClick={() => addNewItem()}
                      className={` fw-m p-0 text-blue`}
                      style={{ zIndex: 1, paddingLeft: 0, paddingTop: 0 }}
                    />
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </DynamicPopupWrapper>
  );
}
