import {
  BOOKS_DATE_FORMAT,
  COUNTRY_CODES,
  CURRENCY_PRECISION,
  DOC_TYPE,
  DOCUMENT_MODE,
  GST_TYPE,
  LABELS,
  TAX_SYSTEM
} from '../../../../Constants/Constant';
import { Bill, billInitialState } from '../../../../Models/Bill';
import { IDocument } from '../../../../Models/Drafts';
import { Invoice, InvoiceInitialState } from '../../../../Models/Invoice';
import { PurchaseOrder, OrderInitialState } from '../../../../Models/Order';
import { Quote, QuoteInitialState } from '../../../../Models/Quote';
import {
  SalesOrder,
  SalesOrderInitialState
} from '../../../../Models/SalesOrder';
import { Store } from '../../../../Redux/Store';
import DateFormatService from '../../../../Services/DateFormat';
import { INDIAN_STATES_MOCK_DATA } from '../../../../Constants/StaticData';
import AuthService from '../../../../Services/Auth';
import InvoiceService from '../../../../Services/Invoice';
import PurchaseOrderService from '../../../../Services/PurchaseOrder';
import QuotationService from '../../../../Services/Quotation';
import SalesOrderService from '../../../../Services/SalesOrder';
import {
  CASCADING_DISCOUNT_PREFIX,
  convertToCurrenctExchangeRate,
  getTenantTaxSystem,
  roundingOffStr,
  updateGstType
} from '../../../../SharedComponents/DocumentForm/NewDocumentHelper';
import Utility, { deepClone } from '../../../../Utility/Utility';
import { setItemsFromPurchaseInvoiceItems } from '../../../Bills/BillHelper';
import { setItemsFromSalesInvoiceItems } from '../../../Invoices/InvoiceHelper';
import { setItemsFromPurchaseOrderItems } from '../../../PurchaseOrders/PurchaseOrderHelper';
import { setItemsFromQuotationItemDtoList } from '../../../Quotations/QuoteHelper';
import { setItemsFromSalesOrderItems } from '../../../SalesOrders/SalesOrderHelper';
import {
  isPriceBookEnabled,
  isPriceListEnabled,
  isSalesDocument
} from '../../Utilities/DocCommonUtils';
import {
  calculateTaxesAndAmountsForAllLineItems,
  updateMultipleKeysInDocument
} from '../../../../Redux/Slices/DocumentSlice';
import {
  CHANGE_TYPE,
  updatePriceFromPriceList
} from '../DocumentUpdates/PriceListHelper';
import { calculateTaxesForUS } from '../DocRowHelper';
import { addDays } from 'date-fns';
import {
  CHARGE_TYPE,
  DOC_LINE_ITEM_KEYS,
  DOCUMENT_KEYS,
  IDocSummaryAmount,
  SUMMARY_AMOUNT_INITIAL_STATE
} from '../../Utilities/DocConstants';
import {
  AdditionalChargeDetails,
  AdditionalChargeForDoc,
  AdditionalDiscountDetails
} from '../../../../Models/Document';
import { getDocumentByIDFromStore } from '../DocumentHelper';
import TaxService from '../../../../Services/Tax';
import cloneDeep from 'lodash/cloneDeep';
import { reCalculateAdditionalCharges } from '../../Views/Footer/DocSummaryHelper';

export const getUOMByProduct = (product: any, allUOM: any[]): any[] => {
  let filteredUOM: any[] = [];
  const stockUOM = allUOM.find((uom: any) => uom.id === product?.stockUom);
  if (Utility.isNotEmpty(stockUOM)) {
    filteredUOM.push({ ...stockUOM, isBaseUom: true });
  }
  if (Utility.isNotEmpty(product?.uomSchemaDto)) {
    product.uomSchemaDto.uomSchemaDefinitions.forEach((uomSchema: any) => {
      const sinkUOM = allUOM.find((uom: any) => uom.id === uomSchema?.sinkUOM);
      filteredUOM.push({
        ...uomSchema,
        name: sinkUOM?.name ?? '',
        isBaseUom: false
      });
    });
  }
  return filteredUOM;
};

export const getUnitPriceListByProduct = (product: any, unitPriceList: any) => {
  const priceList =
    unitPriceList?.unitPrices?.find(
      (list: any) => list?.productCode === product?.productId
    )?.lastUnitPricesDetails ?? [];
  return priceList;
};

export const getTaxFromProduct = (
  product: any,
  documentType: any,
  contact: any,
  documentDate: any
) => {
  const { sell = [], purchase = [] }: any =
    Store.getState()?.commonData?.data?.tax;

  const getUKTax = (product: any) => {
    let tax: any = null;
    let taxCode: any = null;
    if (isSalesDocument(documentType)) {
      taxCode = contact?.ukDefaultSalesTaxRate
        ? contact?.ukDefaultSalesTaxRate
        : product.salesTaxCode;
      tax = sell.find((tax: any) => tax.code === taxCode);
      return tax ? tax : taxCode === '' ? null : taxCode;
    } else if (
      documentType === DOC_TYPE.BILL ||
      documentType === DOC_TYPE.ORDER ||
      documentType === DOC_TYPE.JOB_WORK_OUT_ORDER
    ) {
      taxCode = contact?.ukDefaultPurchaseTaxRate
        ? contact?.ukDefaultPurchaseTaxRate
        : product.purchaseTaxCode;
      tax = purchase.find((tax: any) => tax.code === taxCode);
      return tax ? tax : taxCode === '' ? null : taxCode;
    }
  };

  const getILTax = (product: any) => {
    let tax: any = null;
    let taxCode: any = null;
    if (isSalesDocument(documentType)) {
      taxCode = contact?.salesTaxCodeIsrael
        ? contact?.salesTaxCodeIsrael
        : product.salesTaxCode;
      tax = sell.find((tax: any) => tax.code === taxCode);
      return tax ? tax : taxCode === '' ? null : taxCode;
    } else if (
      documentType === DOC_TYPE.BILL ||
      documentType === DOC_TYPE.ORDER ||
      documentType === DOC_TYPE.JOB_WORK_OUT_ORDER
    ) {
      taxCode = contact?.purchaseTaxCodeIsrael
        ? contact?.purchaseTaxCodeIsrael
        : product.purchaseTaxCode;
      tax = purchase.find((tax: any) => tax.code === taxCode);
      return tax ? tax : taxCode === '' ? null : taxCode;
    }
  };

  const getDefaultTax = (tax: any) => {
    const taxes = isSalesDocument(documentType) ? sell : purchase;
    let taxList = filterTaxByEffectiveDates(taxes, documentDate);
    const taxSystem = getTenantTaxSystem();
    if (taxSystem === TAX_SYSTEM.SG || taxSystem === TAX_SYSTEM.MALAYSIA) {
      if (taxList.length > 0) {
        let newTax = taxList.filter((item: any) => {
          if (
            item.description.replace(/[0-9]/g, '') ===
            tax?.description.replace(/[0-9]/g, '')
          ) {
            return item;
          }
        });
        tax = newTax[0];
      }
    }
    return tax;
  };

  let tax: any = null;
  const taxSystem = getTenantTaxSystem();
  if (taxSystem === TAX_SYSTEM.INDIA_GST && product.taxPreference === false) {
    return null;
  } else if (
    taxSystem === TAX_SYSTEM.MALAYSIA &&
    product.exemptedMalaysia === false
  ) {
    return null;
  } else if (taxSystem === TAX_SYSTEM.US) {
    return null;
  } else if (taxSystem === TAX_SYSTEM.UK) {
    tax = getUKTax(product);
  } else if (taxSystem === TAX_SYSTEM.IL) {
    tax = getILTax(product);
  } else {
    // Tax System for Malaysia(Tax not exempted) and Singapore
    let taxCode: any = null;
    if (isSalesDocument(documentType)) {
      taxCode = product.salesTaxCode;
      tax = sell.find((tax: any) => tax.code === taxCode);
      tax = getDefaultTax(tax);
      return tax ? tax : taxCode === '' ? null : taxCode;
    } else if (
      documentType === DOC_TYPE.BILL ||
      documentType === DOC_TYPE.ORDER ||
      documentType === DOC_TYPE.JOB_WORK_OUT_ORDER
    ) {
      taxCode = product.purchaseTaxCode;
      tax = purchase.find((tax: any) => tax.code === taxCode);
      tax = getDefaultTax(tax);
      return tax ? tax : taxCode === '' ? null : taxCode;
    }
  }
  return tax;
};

/**
 *
 * @param taxes - all the taxes that needs to be filtered
 * @returns - sub array of taxes filtered out based on effective date
 */
export const filterTaxByEffectiveDates = (
  taxes: any[],
  documentDate: any
): any[] => {
  const copyOfTaxes = deepClone(taxes);
  const dateOfDocument =
    typeof documentDate === 'string'
      ? DateFormatService.getDateFromStr(
          documentDate,
          BOOKS_DATE_FORMAT['DD-MM-YYYY']
        )
      : new Date(documentDate);
  const startOfDocumentDate = new Date(dateOfDocument.setHours(0, 0, 0, 0));
  return copyOfTaxes.filter((tax: any) => {
    const effectiveStartDate = DateFormatService.getDateFromStr(
      tax.effectiveStartDate,
      BOOKS_DATE_FORMAT['YYYY-MM-DD']
    );
    if (Utility.isNotEmpty(tax.effectiveEndDate)) {
      const effectiveEndDate = DateFormatService.getDateFromStr(
        tax.effectiveEndDate,
        BOOKS_DATE_FORMAT['YYYY-MM-DD']
      );
      return (
        startOfDocumentDate >= effectiveStartDate &&
        startOfDocumentDate <= effectiveEndDate
      );
    } else {
      return startOfDocumentDate >= effectiveStartDate;
    }
  });
};
export const getLinkedDocumentDetailsForDropship = async (
  linkedDocuments: any[],
  contact: any
) => {
  const code = linkedDocuments ? linkedDocuments?.[0]?.documentCode : '';
  if (code) {
    let docType = linkedDocuments ? linkedDocuments?.[0]?.documentType : '';
    switch (docType) {
      case DOC_TYPE.QUOTE:
        try {
          const quoteData = await QuotationService.getQuoteByCode(code);
          if (quoteData) {
            const dropShipContactName =
              quoteData?.contact?.name || contact.name;
            // setDropshipShipToContact(contactName);
            return dropShipContactName;
          }
        } catch (err: any) {
          console.error('Error loading linked quote data for dropship: ', err);
        }
        break;

      case DOC_TYPE.SALES_ORDER:
        try {
          const soData = await SalesOrderService.getSalesOrderByCode(code);
          if (soData) {
            const dropShipContactName = soData?.contact?.name || contact.name;
            // setDropshipShipToContact(contactName);
            return dropShipContactName;
          }
        } catch (err: any) {
          console.error('Error loading linked SO data for dropship: ', err);
        }
        break;

      case DOC_TYPE.ORDER:
        try {
          const poData = await PurchaseOrderService.fetchOrderDetails(code);
          if (poData) {
            if (!Utility.isEmpty(poData.linkedDocuments)) {
              await getLinkedDocumentDetailsForDropship(
                poData.linkedDocuments,
                contact
              );
            } else {
              const dropShipContactName = poData?.contact?.name || contact.name;
              // setDropshipShipToContact(contactName);
              return dropShipContactName;
            }
          }
        } catch (err: any) {
          console.error('Error loading linked SO data for dropship: ', err);
        }
        break;

      case DOC_TYPE.INVOICE:
        try {
          const invoiceData = await InvoiceService.getInvoiceByCode(code);
          if (invoiceData) {
            const dropShipContactName =
              invoiceData?.contact?.name || contact.name;
            // setDropshipShipToContact(contactName);
            return dropShipContactName;
          }
        } catch (err: any) {
          console.error('Error loading linked SO data for dropship: ', err);
        }
        break;
    }
  }
};

const getUpdatedInitialDataForDocType = (type: string, data: any) => {
  const currentDate = new Date();
  const laterDate = addDays(currentDate, 30);
  // Set default gstType on document level, for a new document
  let gstType = GST_TYPE.DEFAULT;
  if (getTenantTaxSystem() === TAX_SYSTEM.INDIA_GST) {
    gstType = Utility.getIndiaDefaultTaxType(
      data?.shipFrom?.state,
      data?.shipTo?.state
    );
    if (Utility.isNotEmpty(data?.contact)) {
      gstType = updateGstType(data) as GST_TYPE;
    }
  }
  data = {
    ...data,
    [DOCUMENT_KEYS.CURRENCY]: AuthService.currentTenantInfo?.currency,
    documentDate: DateFormatService.getDateStrFromDate(
      currentDate,
      BOOKS_DATE_FORMAT['DD-MM-YYYY']
    ),
    validTillDate: DateFormatService.getDateStrFromDate(
      laterDate,
      BOOKS_DATE_FORMAT['DD-MM-YYYY']
    ),
    fulfillmentDate: DateFormatService.getDateStrFromDate(
      currentDate,
      BOOKS_DATE_FORMAT['DD-MM-YYYY']
    ),
    gstType
  };
  const tenantShippingAddresses: any[] =
    AuthService.currentTenantInfo?.shippingAddresses || [];

  let tenantPreferredShippingAddress = tenantShippingAddresses.find(
    (address: any) => address.preferred
  );
  if (Utility.isEmptyObject(tenantPreferredShippingAddress)) {
    tenantPreferredShippingAddress = tenantShippingAddresses?.[0];
  }

  switch (type) {
    case LABELS.QUOTES:
    case LABELS.SALES_ORDER:
    case LABELS.INVOICES:
      data = {
        ...data,
        [DOCUMENT_KEYS.SHIP_FROM]: tenantPreferredShippingAddress
      };
      break;
    case LABELS.PURCHASE_ORDERS:
    case LABELS.BILLS:
      data = {
        ...data,
        [DOCUMENT_KEYS.BILL_TO]: tenantPreferredShippingAddress,
        [DOCUMENT_KEYS.SHIP_TO]: tenantPreferredShippingAddress
      };
      break;
    default:
      break;
  }
  return data;
};

export function getParsedDocumentDataToDisplay(documentData: IDocument) {
  switch (documentData.type) {
    case LABELS.QUOTES:
      const quoteDraftData = { ...documentData } as IDocument<Quote>;
      quoteDraftData.populateFormData = Utility.isEmpty(
        quoteDraftData.populateFormData
      )
        ? getUpdatedInitialDataForDocType(LABELS.QUOTES, QuoteInitialState)
        : setItemsFromQuotationItemDtoList(quoteDraftData.populateFormData);
      return quoteDraftData;
    case LABELS.INVOICES:
      const invoiceDraftData = { ...documentData } as IDocument<Invoice>;
      invoiceDraftData.populateFormData = Utility.isEmpty(
        invoiceDraftData.populateFormData
      )
        ? getUpdatedInitialDataForDocType(LABELS.INVOICES, InvoiceInitialState)
        : setItemsFromSalesInvoiceItems(invoiceDraftData.populateFormData);
      return invoiceDraftData;
    case LABELS.SALES_ORDER:
      const soDraftData = { ...documentData } as IDocument<SalesOrder>;
      soDraftData.populateFormData = Utility.isEmpty(
        soDraftData.populateFormData
      )
        ? getUpdatedInitialDataForDocType(
            LABELS.SALES_ORDER,
            SalesOrderInitialState
          )
        : setItemsFromSalesOrderItems(soDraftData.populateFormData);
      return soDraftData;
    case LABELS.BILLS:
      const billDraftData = { ...documentData } as IDocument<Bill>;
      billDraftData.populateFormData = Utility.isEmpty(
        billDraftData.populateFormData
      )
        ? getUpdatedInitialDataForDocType(LABELS.BILLS, billInitialState)
        : setItemsFromPurchaseInvoiceItems(billDraftData.populateFormData);
      return billDraftData;
    case LABELS.PURCHASE_ORDERS:
      const poDraftData = { ...documentData } as IDocument<PurchaseOrder>;
      poDraftData.populateFormData = Utility.isEmpty(
        poDraftData.populateFormData
      )
        ? getUpdatedInitialDataForDocType(
            LABELS.PURCHASE_ORDERS,
            OrderInitialState
          )
        : setItemsFromPurchaseOrderItems(poDraftData.populateFormData);
      return poDraftData;
    default:
      break;
  }

  return documentData;
}

/**
 * Build Indian state options for dropdown
 * @returns array of object containing state details
 */
export const getIndianStateOptions = (contact?: any) => {
  const indianStates = INDIAN_STATES_MOCK_DATA;
  let states: {
    label: string;
    value: string;
    code: string;
    searchableKey: string;
  }[] = [];

  indianStates.forEach((state) => {
    if (
      !Utility.isEmpty(contact) &&
      contact?.gstTreatment !== 'OVERSEAS' &&
      state.code === 'Overseas'
    ) {
      return;
    }
    states.push({
      label: state.name,
      value: state.code,
      code: state.stateCode,
      searchableKey: `${state.name} ${state.stateCode}`
    });
  });
  return states || [];
};

/**
 * Handle currency/exchange rate changes
 * @param draftId
 * @param documentMode
 * @param tempDocument
 * @param currencyCode
 * @param exchangeRate
 * @param updateState (optional)
 */
export const updateCurrencyAndExchangeRate = (
  draftId: number,
  documentMode: DOCUMENT_MODE,
  tempDocument: any,
  currencyCode: string,
  exchangeRate: number,
  updateState = true
) => {
  const preciseCurrencyExchangeRate = roundingOffStr(
    1 / exchangeRate,
    CURRENCY_PRECISION
  );
  return updateCurrency({
    draftId,
    documentMode,
    tempDocument,
    currencyCode: currencyCode,
    exchangeRate: 1 / parseFloat(preciseCurrencyExchangeRate),
    gstExchangeRate: tempDocument.gstExchangeRate,
    updateState
  });
};

const updateCurrency = ({
  draftId,
  documentMode,
  tempDocument,
  currencyCode,
  exchangeRate,
  gstExchangeRate,
  updateState = true
}: any) => {
  const oldCurrency = tempDocument.currency;
  const currencyChanged = oldCurrency !== currencyCode;
  const previousExchangeRate = tempDocument.exchangeRate;

  let calculatedGSTExchangeRate = 1;
  if (currencyChanged && gstExchangeRate) {
    calculatedGSTExchangeRate =
      (gstExchangeRate * exchangeRate) / tempDocument.previousExchangeRate;
  }
  tempDocument = {
    ...tempDocument,
    currencyCode: currencyCode,
    currency: currencyCode,
    exchangeRate: exchangeRate,
    previousExchangeRate: previousExchangeRate,
    gstExchangeRate: calculatedGSTExchangeRate
  };

  if (currencyChanged || exchangeRate) {
    if (!updateState) {
      return updateBasedOnCurrency(
        draftId,
        documentMode,
        tempDocument,
        exchangeRate,
        previousExchangeRate,
        updateState
      );
    } else {
      updateBasedOnCurrency(
        draftId,
        documentMode,
        tempDocument,
        exchangeRate,
        previousExchangeRate,
        updateState
      );
    }
  }
};

const updateBasedOnCurrency = (
  draftId: number,
  documentMode: DOCUMENT_MODE,
  tempDocument: any,
  exchangeRate: number,
  previousExchangeRate: number,
  updateState = true
) => {
  if (!tempDocument.items) {
    return;
  }
  let documentItems = [...(tempDocument.items as Array<any>)];
  documentItems = documentItems.map((item, index) => {
    const discount = convertToCurrenctExchangeRate(
      exchangeRate,
      previousExchangeRate,
      item.discountAmount
    );
    if (item.discountInPercent) {
      item = {
        ...item,
        discountAmount: discount
      };
    } else {
      item = {
        ...item,
        discountAmount: discount,
        discount: discount
      };
    }

    // CASCADING_DISCOUNT: Handle conversion of cascasing discounts
    const cascadingDiscountSettings =
      AuthService.currentTenantInfo?.additionalSettings?.CASCADING_DISCOUNTS;
    if (cascadingDiscountSettings?.enable) {
      const allCascadingDiscountKeys = Object.keys(item).filter(
        (itemKey: string) =>
          itemKey?.toString()?.startsWith(CASCADING_DISCOUNT_PREFIX) &&
          !itemKey?.toString()?.endsWith('_details')
      );
      allCascadingDiscountKeys.forEach((itemKey: string) => {
        const detailsKey = itemKey + '_details';
        const isPercent = item[detailsKey].isPercent;
        // const discountAmount = item[detailsKey].discount;
        const cDiscountAmount = convertToCurrenctExchangeRate(
          exchangeRate,
          previousExchangeRate,
          +item[detailsKey].discount
        );
        if (!isPercent) {
          item = {
            ...item,
            [itemKey]: cDiscountAmount,
            [detailsKey]: {
              ...item[detailsKey],
              discount: cDiscountAmount
            }
          };
        }
      });
    }

    return {
      ...item,
      unitPrice: convertToCurrenctExchangeRate(
        exchangeRate,
        previousExchangeRate,
        item.unitPrice
      ),
      taxAmount: convertToCurrenctExchangeRate(
        exchangeRate,
        previousExchangeRate,
        item.taxAmount
      )
    };
  });

  let tcsAmount = 0;
  let totalTdsAmount = 0;

  if (getTenantTaxSystem() === TAX_SYSTEM.INDIA_GST) {
    tcsAmount = convertToCurrenctExchangeRate(
      exchangeRate,
      previousExchangeRate,
      tempDocument.tcsAmount
    );
    totalTdsAmount = convertToCurrenctExchangeRate(
      exchangeRate,
      previousExchangeRate,
      tempDocument.totalTdsAmount
    );
  }

  tempDocument = {
    ...tempDocument,
    items: documentItems,
    tcsAmount,
    totalTdsAmount
  };

  reCalculateAdditionalCharges({ document: tempDocument, draftId });

  if (tempDocument.items && tempDocument.items.length > 0 && updateState) {
    Store.dispatch(
      updateMultipleKeysInDocument({
        draftId,
        keysToUpdate: {
          currencyCode: tempDocument.currencyCode,
          currency: tempDocument.currencyCode,
          exchangeRate: tempDocument.exchangeRate,
          previousExchangeRate: tempDocument.previousExchangeRate,
          gstExchangeRate: tempDocument.calculatedGSTExchangeRate,
          items: tempDocument.items,
          tcsAmount: tempDocument.tcsAmount,
          totalTdsAmount: tempDocument.totalTdsAmount
        }
      })
    );
    if (getTenantTaxSystem() === TAX_SYSTEM.US) {
      calculateTaxesForUS({ draftId, indexToUpdate: undefined });
    } else {
      Store.dispatch(calculateTaxesAndAmountsForAllLineItems({ draftId }));
    }
    const contactColumnConfig = Store.getState().contacts.columnConfig || [];
    // if pricelist is enabled, then calculate taxes after pricelist prices are applied
    if (isPriceListEnabled() || isPriceBookEnabled()) {
      // Price List Call: for exchange rate change
      updatePriceFromPriceList({
        draftId,
        documentMode,
        change: {
          type: CHANGE_TYPE.EXCHANGE_RATE_CHANGED,
          rowIndex: null
        },
        contactColumnConfig
      });
    }
  } else {
    return tempDocument;
  }
};

const getChargeObject = (charge: any) => ({
  purchaseTaxCode: charge.addtionalChargeTaxCode,
  salesTaxCode: charge.addtionalChargeTaxCode,
  isPercent: charge.isPercent,
  percentageValue: charge.percent,
  taxAmount: charge.taxAmount,
  apportionFlag: charge?.apportionFlag || null,
  apportionValue: charge?.apportionValue || null
});
/**
 *
 * @param additionalCharges - Charges from API response object to be converted
 * @param charges - list of charges
 * @returns arrays of additional charges converted as per {AdditionalChargeDetails}
 */
export const buildAdditionalCharges = (
  additionalCharges: any[],
  charges: any[]
): AdditionalChargeDetails[] => {
  return additionalCharges?.map((charge) => {
    const currentCharge =
      charges?.find((item) => charge.additionalCharge === item?.name) || {};

    return {
      ...currentCharge,
      ...getChargeObject(charge),
      chargeValue: charge.chargeAmount ? +charge.chargeAmount : 0,
      includeInReport: currentCharge?.includeInReport
    };
  });
};

export const buildAdditionalDiscount = (
  additionalDiscount: AdditionalDiscountDetails[],
  charges: any[]
) => {
  const additionalDiscounts = additionalDiscount?.filter(
    (charge: any) => !charge.isItemDiscount
  );
  return additionalDiscounts?.map((discount) => {
    const currentDiscount =
      charges?.find((item) => discount.name === item?.name) || {};

    return {
      ...currentDiscount,
      ...getChargeObject(discount),
      chargeValue: discount.amount ? +discount.amount : 0,
      includeInReport: currentDiscount?.includeInReport
    };
  });
};

export const buildAdditionalChargesToSaveInStore = ({
  additionalCharges,
  hasBOMOrTracked,
  gstType,
  documentType
}: any): AdditionalChargeForDoc => {
  const { additionalSettings, country } = AuthService.currentTenantInfo;

  const getGlobalDiscounts = () => {
    return additionalCharges?.globalDiscounts?.map((obj: any) => {
      let discount: any = { ...obj };
      const accountCode = isSalesDocument(documentType)
        ? discount.incomeAccountCode
        : discount.expenseAccountCode;
      let taxCode = '';
      let taxName = '';

      const breakUpForIndia = getTaxBreakUpForIndia(
        discount.taxAmount,
        gstType,
        getTenantTaxSystem()
      );

      if (discount.isTaxable) {
        const tax = getTaxFromCharge({
          charge: discount,
          documentType,
          country
        });
        if (tax) {
          taxCode = tax.code;
          taxName = tax.name;
        }
      }

      let defaultApportionFlag = additionalSettings?.ADDITIONAL_CHARGE
        ?.independentCharge
        ? null
        : additionalSettings?.ADDITIONAL_CHARGE?.apportionFlag;
      let defaultApportionValue = additionalSettings?.ADDITIONAL_CHARGE
        ?.independentCharge
        ? null
        : additionalSettings?.ADDITIONAL_CHARGE?.apportionValue;
      let apportionFlag = discount?.apportionFlag || defaultApportionFlag;
      let apportionValue = discount?.apportionValue || defaultApportionValue;

      return {
        ...discount,
        id: discount.id,
        additionalCharge: discount.name,
        additionalChargeAccountCode: accountCode,
        addtionalChargeTax: taxName,
        addtionalChargeTaxCode: taxCode,
        chargeAmount: discount.chargeValue,
        taxAmount: discount.taxAmount,
        igst: breakUpForIndia.igst,
        cgst: breakUpForIndia.cgst,
        sgst: breakUpForIndia.sgst,
        hasError: discount.hasError,
        includeInReport: discount.includeInReport,
        apportionFlag: hasBOMOrTracked ? apportionFlag || null : false,
        apportionValue: hasBOMOrTracked ? apportionValue || null : null,
        isDiscount: discount.isDiscount,
        amount: discount.chargeValue,
        name: discount.name,
        isSubTotalOnly: false,
        isPreCharge: discount.chargeApplicableOn === 'SUBTOTAL',
        isPercent: discount.isPercent,
        percent: discount.percentageValue,
        accountCode: accountCode
      };
    });
  };

  const getAdditionalCharges = () => {
    let addCharges = {};
    addCharges = additionalCharges?.additionalChargesDetails.map((obj: any) => {
      let charge: any = { ...obj };

      const accountCode = isSalesDocument(documentType)
        ? charge.incomeAccountCode
        : charge.expenseAccountCode;
      let taxCode = '';
      let taxName = '';

      const breakUpForIndia = getTaxBreakUpForIndia(
        charge.taxAmount,
        gstType,
        getTenantTaxSystem()
      );

      if (charge.isTaxable) {
        const tax = getTaxFromCharge({ charge, documentType, country });
        if (tax) {
          taxCode = tax.code;
          taxName = tax.name;
        }
      }

      let defaultApportionFlag = additionalSettings?.ADDITIONAL_CHARGE
        ?.independentCharge
        ? null
        : additionalSettings?.ADDITIONAL_CHARGE?.apportionFlag;
      let defaultApportionValue = additionalSettings?.ADDITIONAL_CHARGE
        ?.independentCharge
        ? null
        : additionalSettings?.ADDITIONAL_CHARGE?.apportionValue;
      let apportionFlag = charge?.apportionFlag || defaultApportionFlag;
      let apportionValue = charge?.apportionValue || defaultApportionValue;

      return {
        ...charge,
        id: charge.id,
        additionalCharge: charge.name,
        additionalChargeAccountCode: accountCode,
        addtionalChargeTax: taxName,
        addtionalChargeTaxCode: taxCode,
        isPreCharge: charge.chargeApplicableOn === 'SUBTOTAL',
        isPercent: charge.isPercent,
        percent: charge.percentageValue,
        chargeAmount: charge.chargeValue,
        taxAmount: charge.taxAmount,
        igst: breakUpForIndia.igst,
        cgst: breakUpForIndia.cgst,
        sgst: breakUpForIndia.sgst,
        hasError: charge.hasError,
        includeInReport: charge.includeInReport,
        apportionFlag: hasBOMOrTracked ? apportionFlag || null : false,
        apportionValue: hasBOMOrTracked ? apportionValue || null : null,
        isDiscount: charge.isDiscount
      };
    });
    return addCharges;
  };

  const additionalChargesDetails = getAdditionalCharges();
  const globalDiscounts = getGlobalDiscounts();
  const additionalChargesAmountInfo = getAdditionalChargesTotalAndTax(
    additionalChargesDetails
  );

  return {
    globalDiscount: { ...(additionalCharges?.globalDiscount ?? {}) },
    additionalChargesDetails,
    globalDiscounts,
    additionalChargeAmount: additionalChargesAmountInfo.total,
    additionalChargeTaxAmount: additionalChargesAmountInfo.tax
  } as AdditionalChargeForDoc;
};

export const getAdditionalChargesTotalAndTax = (
  charges: any,
  chargeType = CHARGE_TYPE.ADDITIONAL_CHARGE
) => {
  let extraChargesTotal = 0;
  let extraChargesTotalTax = 0;
  const amountKey =
    chargeType === CHARGE_TYPE.ADDITIONAL_CHARGE ? 'chargeAmount' : 'amount';
  if (charges) {
    charges?.forEach((charge: any) => {
      extraChargesTotal += charge[amountKey] ? Number(charge[amountKey]) : 0;
      extraChargesTotalTax += charge.taxAmount ? Number(charge.taxAmount) : 0;
    });
  }
  return {
    total: extraChargesTotal,
    tax: extraChargesTotalTax
  };
};

/**
 *
 * @param param object containing charge information like charge, isManualChange
 */
export const calculateChargeAmountAndTax = async ({
  draftId,
  charge,
  isPercent,
  index,
  value,
  summaryAmount,
  charges,
  isManualChange = true,
  document = {}
}: any): Promise<any> => {
  const {
    exchangeRate,
    previousExchangeRate,
    gstType,
    documentType,
    shipTo,
    shipFrom,
    documentDate,
    avalaraCode
  } = draftId ? getDocumentByIDFromStore(draftId)?.populateFormData : document;

  const { decimalScale, country } = AuthService.currentTenantInfo;
  const isChargeInPercent = isPercent ?? charge.isPercent;
  const isPreCharge = charge.chargeApplicableOn === 'SUBTOTAL';
  const subTotalBeforeTax = summaryAmount.subTotal;
  const totalBeforeTax =
    summaryAmount.subTotal -
    summaryAmount.discount -
    (summaryAmount.cascadingDiscountAmount ?? 0) +
    summaryAmount.tax;
  let totalAdditionChargeValueApplied = 0;
  let chargeAmount: any = 0;
  let taxOnChargeAmount = 0;
  if (!charge.isDiscount) {
    if (
      Utility.isNotEmpty(charges) &&
      index > 0 &&
      charge.chargeValue !== 0 &&
      isPercent
    ) {
      charges.forEach((sCharge: any, sIndex: any) => {
        if (sIndex < index && sIndex < charges.length - 1) {
          totalAdditionChargeValueApplied += sCharge.chargeValue;
          if (charge.isTaxable && sCharge?.taxAmount && !isPreCharge) {
            totalAdditionChargeValueApplied += sCharge?.taxAmount;
          }
        }
      });
    }
  }

  const totalWithAdditionalCharge =
    totalBeforeTax + Number(totalAdditionChargeValueApplied);

  if (isChargeInPercent && !isNaN(charge.percentageValue)) {
    let percentageValue = charge.percentageValue;
    if (typeof value !== 'undefined' && value !== null) {
      percentageValue = value;
    }
    if (isPreCharge) {
      chargeAmount = (subTotalBeforeTax * percentageValue) / 100;
    } else {
      chargeAmount = (totalWithAdditionalCharge * percentageValue) / 100;
    }
    // Round off the calculated chargeAmount
    chargeAmount = Utility.roundOff(chargeAmount, decimalScale);
  } else {
    if (typeof value !== 'undefined' && value !== null) {
      chargeAmount = value;
    } else {
      if (isManualChange || charge.isManualChange) {
        chargeAmount = charge.chargeValue ? charge.chargeValue : 0;
      } else {
        if (exchangeRate) {
          chargeAmount = charge.chargeValue
            ? convertToCurrenctExchangeRate(
                exchangeRate,
                previousExchangeRate ? previousExchangeRate : 1,
                charge.chargeValue
              )
            : 0;
          isManualChange = true;
        } else {
          chargeAmount = charge.chargeValue;
        }
      }
    }
  }

  if (charge.isTaxable) {
    const isBillOrOrder = [DOC_TYPE.BILL, DOC_TYPE.ORDER].includes(
      documentType
    );

    if (
      (country === COUNTRY_CODES.IN || country === COUNTRY_CODES.SG) &&
      gstType !== GST_TYPE.EXEMPT
    ) {
      const tax = getTaxFromCharge({ charge, documentType, country });
      if (tax && !isNaN(chargeAmount)) {
        taxOnChargeAmount = (chargeAmount * tax.percent) / 100;
      }
    }

    if (country === COUNTRY_CODES.US && !isBillOrOrder) {
      let payload: any = {
        companyCode: avalaraCode,
        shipTo: shipTo,
        shipFrom: shipFrom,
        lines: [
          {
            amount: chargeAmount,
            description: '',
            quantity: 1,
            taxAmount: null
          }
        ],
        docDate: DateFormatService.getDateStrFromDate(
          DateFormatService.getDateFromStr(
            documentDate,
            BOOKS_DATE_FORMAT['DD-MM-YYYY']
          ),
          BOOKS_DATE_FORMAT['YYYY-MM-DD']
        )
      };
      try {
        const taxDetails = await TaxService.calculateUsTax(payload);
        taxOnChargeAmount = taxDetails.lines[0] ? taxDetails.lines[0].tax : 0;
      } catch (err) {
        console.error('Error fetching tax details: ', err);
      }
    }
  }

  return new Promise<any>((resolve) => {
    resolve({
      chargeAmount,
      taxOnChargeAmount,
      isManualChange: isManualChange || charge.isManualChange
    });
  });
};

export const getTaxFromCharge = ({ charge, documentType, country }: any) => {
  let tax: any = null;
  const { sell = [], purchase = [] }: any =
    Store.getState()?.commonData?.data?.tax;
  const isSaleDocument = isSalesDocument(documentType);
  const taxes = isSaleDocument ? sell : purchase;
  if (
    (country === COUNTRY_CODES.IN || country === COUNTRY_CODES.SG) &&
    charge.isTaxable
  ) {
    let taxCode: any = null;
    if (isSaleDocument) {
      taxCode = charge.salesTaxCode;
    } else if (
      documentType === DOC_TYPE.BILL ||
      documentType === DOC_TYPE.ORDER
    ) {
      taxCode = charge.purchaseTaxCode;
    }
    tax = taxes.find((tax: any) => tax.code === taxCode);
    return tax ? tax : taxCode === '' ? null : taxCode;
  }
  return tax;
};

export const getTaxBreakUpForIndia = (
  taxAmount: number,
  gstType: GST_TYPE,
  taxSystem: any
) => {
  let taxes = {
    igst: 0,
    sgst: 0,
    cgst: 0
  };
  if (taxSystem === TAX_SYSTEM.INDIA_GST) {
    if (gstType === GST_TYPE.INTER) {
      taxes = {
        ...taxes,
        igst: taxAmount
      };
    }
    if (gstType === GST_TYPE.INTRA) {
      taxes = {
        ...taxes,
        cgst: taxAmount / 2,
        sgst: taxAmount / 2
      };
    }
  }
  return taxes;
};

export const calculateAmountsForSummary = ({
  items,
  gstType,
  decimalScale,
  tcsPercentage,
  taxSystem
}: any): IDocSummaryAmount => {
  const summaryAmount: IDocSummaryAmount = cloneDeep(
    SUMMARY_AMOUNT_INITIAL_STATE
  );

  items?.forEach((item: any) => {
    if (!item[DOC_LINE_ITEM_KEYS.OPTIONAL]) {
      if (Utility.isNotEmpty(item?.[DOC_LINE_ITEM_KEYS.PRODUCT])) {
        summaryAmount.subTotal += !isNaN(item[DOC_LINE_ITEM_KEYS.SUBTOTAL])
          ? Utility.roundOff(item[DOC_LINE_ITEM_KEYS.SUBTOTAL], decimalScale)
          : 0;
      }
      if (
        taxSystem === TAX_SYSTEM.INDIA_GST &&
        item[DOC_LINE_ITEM_KEYS.UNIT_PRICE_GST_INCLUSIVE] &&
        gstType !== GST_TYPE.EXEMPT
      ) {
        let baseAmount = Utility.roundOff(
          item.subTotal ? item.subTotal : 0,
          decimalScale
        );
        summaryAmount.totalWithDiscount += Utility.roundOff(
          baseAmount,
          decimalScale
        );
      } else {
        summaryAmount.totalWithDiscount = summaryAmount.subTotal;
      }
      summaryAmount.tax +=
        item.taxAmount && !item[DOC_LINE_ITEM_KEYS.IS_RCM_APPLIED]
          ? Number(item.taxAmount)
          : 0;
      summaryAmount.discount += item.discountAmount
        ? Number(item.discountAmount)
        : 0;
      summaryAmount.cascadingDiscountAmount += item.cascadingDiscountAmount
        ? Number(item.cascadingDiscountAmount)
        : 0;
      summaryAmount.tdsAmount += item.tdsInfoIndia
        ? Number(item.tdsInfoIndia.tdsAmount)
        : 0;
      summaryAmount.total += item.totalAmount ? Number(item.totalAmount) : 0;
      if (getTenantTaxSystem() === TAX_SYSTEM.INDIA_GST) {
        summaryAmount.GST.igst +=
          item.igstAmount && !item.isRcmApplied
            ? Utility.roundingOff(Number(item.igstAmount), decimalScale)
            : 0;
        summaryAmount.GST.cess += item.cessAmount ? Number(item.cessAmount) : 0;
        summaryAmount.GST.sgst +=
          item.sgstAmount && !item.isRcmApplied
            ? Utility.roundingOff(Number(item.sgstAmount), decimalScale)
            : 0;
        summaryAmount.GST.cgst +=
          item.cgstAmount && !item.isRcmApplied
            ? Utility.roundingOff(Number(item.cgstAmount), decimalScale)
            : 0;
      }
    }
  });
  summaryAmount.tcsAmount = tcsPercentage
    ? (Number(summaryAmount.total) * Number(tcsPercentage)) / 100
    : 0;
  summaryAmount.tax =
    summaryAmount?.GST.cgst > 0
      ? summaryAmount.GST.cess + summaryAmount.GST.cgst + summaryAmount.GST.sgst
      : summaryAmount.tax;

  summaryAmount.tdsAmount = Utility.roundingOff(
    Number(summaryAmount.tdsAmount),
    decimalScale
  );
  return summaryAmount;
};

export const updateExpectedDeliveryDateForLineItem = (
  leadTime: number,
  newDocDate: Date
) => {
  let duedocumentDate = newDocDate || new Date();
  let leadTimeDate = addDays(duedocumentDate, leadTime);
  return leadTimeDate;
};

/**
 * Helps set initial value of amortization related keys in edit case
 */
export const getInitialAmortizationDataForEdit = (data: {
  templates: any[];
  documentDate: string;
  items: any[];
}) => {
  const { templates, documentDate, items } = data;
  if (!Utility.isEmpty(templates)) {
    let lineItems = [...items].map((lineItem: any) => {
      const docDate = DateFormatService.getDateFromStr(
        documentDate,
        BOOKS_DATE_FORMAT['DD-MM-YYYY']
      );
      // const originalAmortizationDetails = deepClone(
      //   lineItem?.amortizationDocumentItemDetails
      // );
      let startDate = new Date(docDate.valueOf());
      let endDate = new Date(docDate.valueOf());
      endDate.setMonth(
        endDate.getMonth() + lineItem?.product?.amortizationPeriod || 1
      );
      if (
        lineItem?.amortizationDocumentItemDetails?.startDate &&
        lineItem?.amortizationDocumentItemDetails?.endDate
      ) {
        startDate = DateFormatService.convertDDMMYYYToDate(
          lineItem?.amortizationDocumentItemDetails.startDate
        );
        endDate = DateFormatService.convertDDMMYYYToDate(
          lineItem?.amortizationDocumentItemDetails.endDate
        );
      }
      // Note: No need for this else block for now, as it introduces a bug during edit
      // Amortization details are being set when user open a document and clicks on save (in original implementation).
      // Will re-visit if any bugs are logged

      //  else {
      //   let amortizationItemDetails = {
      //     startDate: DateFormatService.getDateStrFromDate(
      //       startDate,
      //       BOOKS_DATE_FORMAT['DD-MM-YYYY']
      //     ),
      //     endDate: DateFormatService.getDateStrFromDate(
      //       endDate,
      //       BOOKS_DATE_FORMAT['DD-MM-YYYY']
      //     ),
      //     deferralAccountCode: lineItem.product?.deferredExpenseAccountCode,
      //     templateCode: lineItem?.product?.amortizationTemplateCode
      //   };
      //   lineItem = {
      //     ...lineItem,
      //     amortizationDocumentItemDetails: {
      //       ...amortizationItemDetails
      //     }
      //   };
      // }
      let amortizationTemplate = templates.find(
        (template: any) =>
          template.documentSeqCode ===
          lineItem?.amortizationDocumentItemDetails?.templateCode
      );
      if (!Utility.isEmpty(amortizationTemplate)) {
        lineItem = {
          ...lineItem,
          amortizationTemplate: amortizationTemplate,
          amortizationStartDate: startDate.valueOf(),
          amortizationEndDate: endDate.valueOf()
        };
      }
      return lineItem;
    });
    return lineItems;
  }
  return items;
};

export const getUpdatedMemoOnProductDelete = (
  updatedLineItems: any[],
  memo: string,
  previousMemo: string
) => {
  let memoText = '';
  let checkCurrentMemo = checkMemoExist(updatedLineItems, memo);
  let checkPreviousMemo = checkMemoExist(updatedLineItems, previousMemo);
  if (!checkCurrentMemo && checkPreviousMemo) {
    memoText = previousMemo;
  }
  if (checkCurrentMemo && !checkPreviousMemo) {
    memoText = checkCurrentMemo;
  }
  if (checkCurrentMemo && checkPreviousMemo) {
    memoText = checkCurrentMemo;
  }
  return { memo: memoText ? memoText.trim() : '', previousMemo: memo || '' };
};

const getItemsTaxObjects = (items: any[]) => {
  let itemsTax: any[] = [];
  items.forEach((item: any) => {
    itemsTax.push(item?.tax);
  });
  return itemsTax;
};

const checkMemoExist = (items: any[], memo: string) => {
  let itemsTax = getItemsTaxObjects(items);
  let taxMemo = null;
  if (itemsTax && itemsTax?.length > 0) {
    if (getTenantTaxSystem() === TAX_SYSTEM.UK) {
      taxMemo = itemsTax.find(
        (t: any) => t?.defaultMemoUk === memo.trim()
      )?.defaultMemoUk;
    }
  }
  return taxMemo;
};
