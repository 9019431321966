import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../Store';
import TenantService from '../../Services/Tenant';
import { AuthInfoState } from '../../Models/ReduxStore';
import { API_STATUS, ROW_RACK_BIN_CONSTANT } from '../../Constants/Constant';
import NumberFormatService from '../../Services/NumberFormat';
import DateFormatService from '../../Services/DateFormat';
import AuthService from '../../Services/Auth';
import EwayBillService from '../../Services/EwayBill';
import EInvoiceService from '../../Services/EInvoice';
import {
  fetchAllUsersList,
  fetchTenantsUserPermissionInfo
} from './PermissionsSlice';
import { ITenantInfo } from '../../Models/Common';

const initialState: AuthInfoState = {
  allTenantsInfo: { data: [], status: API_STATUS.IDLE },
  subscriptionInfo: { data: {}, status: API_STATUS.IDLE },
  userInfo: { data: [], status: API_STATUS.IDLE },
  permissionInfo: { data: [], status: API_STATUS.IDLE },
  featurePermissions: { data: {}, status: API_STATUS.IDLE },
  currentTenantInfo: { data: {}, status: API_STATUS.IDLE },
  currentTenantInfoForBookkeeper: { data: {}, status: API_STATUS.IDLE },
  currentTenantSetupStatus: { data: {}, status: API_STATUS.IDLE },
  currentTenantSettings: { data: {}, status: API_STATUS.IDLE },
  appCustomization: { data: {}, status: API_STATUS.IDLE },
  usersList: { data: {}, status: API_STATUS.IDLE },
  usersListShortInfo: { data: {}, status: API_STATUS.IDLE },
  status: API_STATUS.IDLE,
  isBookKeeper: false,
  contactInfo: {},
  eWayAuthData: { data: {}, status: API_STATUS.IDLE },
  eInvoiceAuthData: { data: {}, status: API_STATUS.IDLE },
  GSTEfilingAuthInfo: { data: {}, status: API_STATUS.IDLE },
  userPreferences: { data: {}, status: API_STATUS.IDLE }
};

export const fetchAllTenantsInfo = createAsyncThunk(
  'allTenantsInfo',
  async () => {
    const response = await TenantService.getAllTenants();
    return response;
  }
);

export const fetchAppCustomizationInfo = createAsyncThunk(
  'appCustomization',
  async () => {
    const response = await TenantService.getAppCustomization();
    return response;
  }
);

export const fetchSubscriptionInfo = createAsyncThunk(
  'subscriptionInfo',
  async () => {
    const response = await TenantService.getBooksSubscription();
    return response;
  }
);

export const fetchFeaturePermissions = createAsyncThunk(
  'featurePermissions',
  async () => {
    const response = await TenantService.getFeaturePermissions();
    return response;
  }
);

export const fetchCurrentTenantInfo = createAsyncThunk(
  'currentTenantInfo',
  async () => {
    const response = await TenantService.getTenantDetails();
    return response;
  }
);

export const fetchCurrentTenantInfoWithoutCountryCode = createAsyncThunk(
  'currentTenantInfoWithoutCountryCode',
  async () => {
    const response = await TenantService.getTenantDetailsWithoutCountryCOde();
    return response;
  }
);

export const fetchCurrentTenantSetupStatus = createAsyncThunk(
  'currentTenantSetupStatus',
  async () => {
    const response = await TenantService.getTenantSetupStatus();
    return response;
  }
);

export const fetchTenantSettings = createAsyncThunk(
  'currentTenantSettings',
  async (tenantId: any) => {
    const response = await TenantService.getTenantSettings(tenantId);
    return response;
  }
);

export const fetchContactInfo = createAsyncThunk(
  'fetchContactInfo',
  async () => {
    const response = await TenantService.getContactInfo();
    return response;
  }
);

export const fetchEWayBillAuthInfo = createAsyncThunk(
  'eWayBillAuthInfo',
  async (request: any) => {
    const response = await EwayBillService.getEWayAuthToken(request);
    return response;
  }
);

export const fetchEInvoiceAuthInfo = createAsyncThunk(
  'eInvoiceAuthInfo',
  async (request: any) => {
    const response = await EInvoiceService.getEInvoiceAuthToken(request);
    return response;
  }
);

export const fetchGSTEFilingAuthInfo = createAsyncThunk(
  'GSTEfilingAuthInfo',
  async (request: any) => {
    const response = await EInvoiceService.getGSTEFilingAuthToken(request);
    return response;
  }
);

export const fetchUserPreferences = createAsyncThunk(
  'userPreferences',
  async () => {
    const response = await TenantService.getUserPreferences();
    return response;
  }
);

export const AuthSlice = createSlice({
  name: 'Authentication',
  initialState,
  reducers: {
    addAllTenantsInfo: (state, action: PayloadAction<any[]>) => {
      const tenantData: any[] = action.payload;
      state.allTenantsInfo.data = tenantData;
    },
    addSubscriptionInfo: (state, action: PayloadAction<object>) => {
      const subscriptionData: object = action.payload;
      state.subscriptionInfo.data = { ...subscriptionData };
    },
    addUserInfo: (state, action: PayloadAction<any[]>) => {
      const userData: any[] = action.payload;
      state.userInfo.data = userData;
    },
    updateBarCodeSettings: (state, action: PayloadAction<any>) => {
      const barCodeSettings: any = action.payload;
      state.currentTenantSettings.data.barcodeSettings = barCodeSettings;
    },
    addFeaturePermissions: (state, action: PayloadAction<any[]>) => {
      const featurePermissionData: any[] = action.payload;
      state.featurePermissions.data = featurePermissionData;
    },
    currentTenantInfo: (state, action: PayloadAction<any[]>) => {
      var activeTenantDetails: any = { ...action.payload };
      let rrbData: any = {};
      activeTenantDetails?.additionalSettings?.ROW_RACK_BIN?.forEach(
        (element: any) => {
          if (element.name === ROW_RACK_BIN_CONSTANT.ROW) {
            rrbData.rowData = element;
          }
          if (element.name === ROW_RACK_BIN_CONSTANT.RACK) {
            rrbData.rackData = element;
          }
          if (element.name === ROW_RACK_BIN_CONSTANT.BIN) {
            rrbData.binData = element;
          }
          // once rrb enable is set to true it won't be changed
          // we only want to set rrb enabled to true when any of the values is enabled
          if (!rrbData?.isRRBAvailable) {
            rrbData.isRRBAvailable = element?.enable ?? false;
          }
        }
      );
      activeTenantDetails = { ...activeTenantDetails, rowRackBinData: rrbData };
      state.currentTenantInfo.data = activeTenantDetails;
    },
    currentTenantSetupStatus: (state, action: PayloadAction<any[]>) => {
      const tenanatSetupStatus: any = action.payload;
      state.currentTenantSetupStatus.data = tenanatSetupStatus;
    },
    updateIsBookKeeperFlag: (state, action: PayloadAction<boolean>) => {
      const isBookKepper = action.payload;
      state.isBookKeeper = isBookKepper;
    },
    updateUserPreferences: (state, action: PayloadAction<any>) => {
      state.userPreferences.data = {
        ...state.userPreferences.data,
        ...(action.payload || {})
      };
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllTenantsInfo.pending, (state) => {
        state.allTenantsInfo.status = API_STATUS.LOADING;
      })
      .addCase(fetchAllTenantsInfo.fulfilled, (state, action) => {
        state.allTenantsInfo.status = API_STATUS.IDLE;
        state.allTenantsInfo.data = action.payload;
      })
      .addCase(fetchSubscriptionInfo.pending, (state) => {
        state.subscriptionInfo.status = API_STATUS.LOADING;
      })
      .addCase(fetchSubscriptionInfo.fulfilled, (state, action) => {
        state.subscriptionInfo.status = API_STATUS.IDLE;
        state.subscriptionInfo.data = action.payload;
        AuthService.subscriptionInfo = action.payload;
      })
      .addCase(fetchTenantsUserPermissionInfo.pending, (state) => {
        state.permissionInfo.status = API_STATUS.LOADING;
      })
      .addCase(fetchTenantsUserPermissionInfo.fulfilled, (state, action) => {
        state.permissionInfo.status = API_STATUS.IDLE;
        state.permissionInfo.data = action.payload;
      })
      .addCase(fetchFeaturePermissions.pending, (state) => {
        state.featurePermissions.status = API_STATUS.LOADING;
      })
      .addCase(fetchFeaturePermissions.fulfilled, (state, action) => {
        state.featurePermissions.status = API_STATUS.IDLE;
        state.featurePermissions.data = action.payload;
      })
      .addCase(fetchCurrentTenantInfo.pending, (state) => {
        state.currentTenantInfo.status = API_STATUS.LOADING;
      })
      .addCase(fetchCurrentTenantInfo.fulfilled, (state, action) => {
        state.currentTenantInfo.status = API_STATUS.IDLE;
        if (!action.payload) return;

        if (action.payload.numberFormat && action.payload.decimalScale) {
          NumberFormatService.setNumberFormatter(
            action.payload.numberFormat,
            action.payload.decimalScale
          );
        }

        if (action.payload?.dateFormat) {
          DateFormatService.setTenantDateFormat(action.payload.dateFormat);
        }

        state.currentTenantInfo.data = action.payload;
        AuthService.currentTenantInfo = action.payload;
      })
      .addCase(fetchCurrentTenantInfoWithoutCountryCode.pending, (state) => {
        state.currentTenantInfoForBookkeeper.status = API_STATUS.LOADING;
      })
      .addCase(
        fetchCurrentTenantInfoWithoutCountryCode.fulfilled,
        (state, action) => {
          state.currentTenantInfoForBookkeeper.status = API_STATUS.IDLE;
          state.currentTenantInfoForBookkeeper.data = action.payload;
        }
      )
      .addCase(fetchCurrentTenantSetupStatus.pending, (state) => {
        state.currentTenantInfo.status = API_STATUS.LOADING;
      })
      .addCase(fetchCurrentTenantSetupStatus.fulfilled, (state, action) => {
        state.currentTenantSetupStatus.status = API_STATUS.IDLE;
        state.currentTenantSetupStatus.data = action.payload;
      })
      .addCase(fetchTenantSettings.pending, (state) => {
        state.currentTenantSettings.status = API_STATUS.LOADING;
      })
      .addCase(fetchTenantSettings.fulfilled, (state, action) => {
        state.currentTenantSettings.status = API_STATUS.IDLE;
        state.currentTenantSettings.data = action.payload;
      })
      .addCase(fetchTenantSettings.rejected, (state, action) => {
        state.currentTenantSettings.status = API_STATUS.FAILED;
      })
      .addCase(fetchAppCustomizationInfo.fulfilled, (state, action) => {
        state.appCustomization.status = API_STATUS.IDLE;
        state.appCustomization.data = action.payload;
        // Add this key in AuthService too, to avoid accesing store while a dispatch is in progress
        AuthService.appCustomization = action.payload;
      })
      .addCase(fetchAppCustomizationInfo.rejected, (state, action) => {
        state.appCustomization.status = API_STATUS.FAILED;
      })
      .addCase(fetchAllUsersList.pending, (state) => {
        state.usersListShortInfo.status = API_STATUS.LOADING;
        state.usersList.status = API_STATUS.LOADING;
      })
      .addCase(fetchAllUsersList.rejected, (state, action) => {
        state.usersListShortInfo.status = API_STATUS.FAILED;
        state.usersList.status = API_STATUS.FAILED;
      })
      .addCase(fetchAllUsersList.fulfilled, (state, action) => {
        state.usersListShortInfo.status = API_STATUS.IDLE;
        state.usersListShortInfo.data = action.payload;

        state.usersList.status = API_STATUS.IDLE;
        state.usersList.data = action.payload;
      })
      .addCase(fetchContactInfo.fulfilled, (state, action) => {
        state.status = API_STATUS.IDLE;
        state.contactInfo = action.payload;
      })
      .addCase(fetchContactInfo.rejected, (state, action) => {
        state.status = API_STATUS.FAILED;
      })
      .addCase(fetchEWayBillAuthInfo.fulfilled, (state, action) => {
        state.eWayAuthData.status = API_STATUS.IDLE;
        if (action.payload.status === '1') {
          state.eWayAuthData.data = action.payload;
        }
      })
      .addCase(fetchEWayBillAuthInfo.rejected, (state, action) => {
        state.eWayAuthData.status = API_STATUS.FAILED;
      })
      .addCase(fetchEInvoiceAuthInfo.fulfilled, (state, action) => {
        state.eInvoiceAuthData.status = API_STATUS.IDLE;
        if (action.payload.status === '1') {
          state.eInvoiceAuthData.data = action.payload;
        }
      })
      .addCase(fetchEInvoiceAuthInfo.rejected, (state, action) => {
        state.eInvoiceAuthData.status = API_STATUS.FAILED;
      })
      .addCase(fetchGSTEFilingAuthInfo.fulfilled, (state, action) => {
        state.GSTEfilingAuthInfo.status = API_STATUS.IDLE;
        if (action.payload.status_cd === '1') {
          state.GSTEfilingAuthInfo.data = action.payload;
        }
      })
      .addCase(fetchGSTEFilingAuthInfo.rejected, (state, action) => {
        state.GSTEfilingAuthInfo.status = API_STATUS.FAILED;
      })
      .addCase(fetchUserPreferences.pending, (state, action) => {
        state.userPreferences.status = API_STATUS.LOADING;
      })
      .addCase(fetchUserPreferences.fulfilled, (state, action) => {
        state.userPreferences.status = API_STATUS.IDLE;
        state.userPreferences.data = action.payload;
      })
      .addCase(fetchUserPreferences.rejected, (state, action) => {
        state.userPreferences.status = API_STATUS.FAILED;
      });
  }
});
export const {
  addAllTenantsInfo,
  addSubscriptionInfo,
  addUserInfo,
  addFeaturePermissions,
  updateBarCodeSettings,
  currentTenantInfo,
  updateIsBookKeeperFlag,
  updateUserPreferences
} = AuthSlice.actions;

export const isBookKeeper = (state: RootState) => state.authInfo.isBookKeeper;
export const currentTenantSettingStatus = (state: RootState) =>
  state.authInfo.currentTenantSettings.status;
export const tenantSettings = (state: RootState) =>
  state.authInfo.currentTenantSettings.data;
export const allTenantsInfo = (state: RootState) =>
  state.authInfo.allTenantsInfo.data;
export const subscriptionInfo = (state: RootState) =>
  state.authInfo.subscriptionInfo.data;
export const userInfo = (state: RootState) => state.authInfo.userInfo.data;
export const permissionInfo = (state: RootState) =>
  state.authInfo.permissionInfo.data;
export const featurePermissions = (state: RootState) =>
  state.authInfo.featurePermissions.data;
export const activeTenantInfo = (state: RootState): ITenantInfo =>
  state.authInfo.currentTenantInfo.data;
export const activeTenantInfoForBookkeeper = (state: RootState) =>
  state.authInfo.currentTenantInfoForBookkeeper.data;
export const tenantSetupStauts = (state: RootState) =>
  state.authInfo.currentTenantSetupStatus.data;
export const selectContactInfo = (state: RootState) =>
  state.authInfo.contactInfo;

export const appCustomizationInfo = (state: RootState) =>
  state.authInfo.appCustomization.data;

/** @deprecated, use usersListShortInfo / selectUsersList (permissionSlice) */
export const usersList = (state: RootState) => state.authInfo.usersList.data;

export const usersListShortInfo = (state: RootState) =>
  state.authInfo.usersListShortInfo.data;

export const statusData = () => AuthService.getUserDetails();
export const eWayAuthInfo = (state: RootState) =>
  state.authInfo.eWayAuthData.data;
export const eInvoiceAuthInfo = (state: RootState) =>
  state.authInfo.eInvoiceAuthData.data;
export const GSTEfilingAuthInfo = (state: RootState) =>
  state.authInfo.GSTEfilingAuthInfo.data;
export const selectBarcodeSettings = (state: RootState) =>
  state.authInfo.currentTenantSettings.data?.barcodeSettings || {};
