import { DOCUMENT_MODE } from '../../../Constants/Constant';
import { IColumn } from '../../../Models/Table';
import { findDocumentById } from '../../../Redux/Slices/DocumentSlice';
import { Store } from '../../../Redux/Store';
import { CASCADING_DISCOUNT_PREFIX } from '../../../SharedComponents/DocumentForm/NewDocumentHelper';
import Utility from '../../../Utility/Utility';
/**
 *
 * @returns get cascading discounts ordered by level from global settings
 */
export const getOrderedCascadingDiscountsFromSettings = () => {
  const { additionalSettings } =
    Store.getState().authInfo.currentTenantInfo.data;
  const cascadingDiscountSettings = additionalSettings?.CASCADING_DISCOUNTS;
  if (cascadingDiscountSettings?.enable) {
    let orderedDiscounts = Utility.isNotEmpty(
      cascadingDiscountSettings?.discountDetails
    )
      ? [...cascadingDiscountSettings.discountDetails]
      : [];
    orderedDiscounts.sort(
      (discount1: any, discount2: any) => discount1.level - discount2.level
    );
    return orderedDiscounts;
  }
  return [];
};

export const getCascadingDiscountsFromStore = (doc: any) => {
  let discountsFromStore: any[] = [];
  let cascadingDiscountsInStore =
    Store.getState()?.additionalCharges?.data?.all;
  cascadingDiscountsInStore = !Utility.isEmpty(cascadingDiscountsInStore)
    ? cascadingDiscountsInStore
    : [];
  cascadingDiscountsInStore = cascadingDiscountsInStore?.filter(
    (disc: any) => disc.isDiscount && disc.isItemDiscount
  );
  if (!Utility.isEmpty(cascadingDiscountsInStore)) {
    if (Utility.isSalesDocument(doc)) {
      discountsFromStore = cascadingDiscountsInStore.filter(
        (discount: any) =>
          discount.applyTo === 'SELL' || discount.applyTo === 'BOTH'
      );
    } else {
      discountsFromStore = cascadingDiscountsInStore.filter(
        (discount: any) =>
          discount.applyTo === 'BUY' || discount.applyTo === 'BOTH'
      );
    }
  }
  return discountsFromStore;
};

export const getDocumentByIDFromStore = (documentId: any): any => {
  const documents = Store.getState().documentsData.documents ?? [];
  return findDocumentById(documents, documentId);
};

export const getCascadingDiscountKeyValue = (selectedRow: any) => {
  // Copied from getAllCascadingDiscountKeyValuesForLineItem() in NewDocument2
  const keys = Object.keys(selectedRow);
  const hasCascadingDiscount = keys?.some((key: string) =>
    key?.toString()?.startsWith(CASCADING_DISCOUNT_PREFIX)
  );
  let updatedObj: any = {};
  if (hasCascadingDiscount) {
    keys.forEach((key: string) => {
      if (key?.toString()?.startsWith(CASCADING_DISCOUNT_PREFIX)) {
        updatedObj[key] = selectedRow[key];
      }
    });
  }
  return updatedObj;
};

/**
 * CASCADING_DISCOUNT: check if column config exists for a discount
 * @param key
 * @param updatedConfigs
 * @returns `true/false` `boolean`
 */
export const discountConfigPresent = (
  key: string,
  updatedConfigs: IColumn[]
) => {
  return (
    updatedConfigs?.filter((config: any) => config.key === key)?.length === 1
  );
};

/**
 * CASCADING_DISCOUNT: check if totalDiscountAmount column config exists for a discount
 * @param updatedConfigs
 * @returns `true/false` `boolean`
 */
export const totalDiscountConfigPresent = (updatedConfigs: IColumn[]) => {
  return (
    updatedConfigs?.filter(
      (config: any) => config.key === 'totalDiscountAmount'
    )?.length === 1
  );
};

// CASCADING_DISCOUNT: Get cascading discounts total column visibility
export const getCascadingDiscountTotalColumnVisibility = (
  additionalSettings: any,
  documentMode: DOCUMENT_MODE,
  tempDocument: any,
  columnConfig: any[]
) => {
  let showCascadingDiscountTotal = false;
  const cascadingDiscountSettings = additionalSettings?.CASCADING_DISCOUNTS;
  let cascadingDiscountsDetails: any[] = [];
  if (documentMode === DOCUMENT_MODE.NEW) {
    cascadingDiscountsDetails = !Utility.isEmpty(
      cascadingDiscountSettings?.discountDetails
    )
      ? cascadingDiscountSettings?.discountDetails
      : [];
    let discountsFromStore: any[] = [];
    cascadingDiscountsDetails.forEach((disc: any) => {
      const discountFromStore = getCascadingDiscountsFromStore(
        tempDocument
      )?.find((dis: any) => dis.id === disc.discountId);
      if (!Utility.isEmpty(discountFromStore)) {
        discountsFromStore.push(discountFromStore);
      }
    });
    showCascadingDiscountTotal = discountsFromStore.length > 0;
  } else {
    const columnConfigContainsCascadingDiscounts = columnConfig?.some(
      (config: any) =>
        config?.key?.toString()?.startsWith(CASCADING_DISCOUNT_PREFIX)
    );
    showCascadingDiscountTotal = columnConfigContainsCascadingDiscounts;
  }
  return showCascadingDiscountTotal;
};
