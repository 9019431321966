import { DKLabel } from 'deskera-ui-library';
import { DraftTypes } from '../../../../Models/Drafts';
import {
  FULFILLMENT_STATUS,
  LABELS,
  PAYMENT_STATUS,
  RECEIVED_GOODS_STATUS
} from '../../../../Constants/Constant';
import { localizedText } from '../../../../Services/Localization/Localization';
import { isSalesOrderVisible } from '../../../../SharedComponents/DocumentForm/NewDocumentHelper';
import { Store } from '../../../../Redux/Store';
import { setDraftMetaDataKeys } from '../../../../Redux/Slices/DocumentSlice';

export enum DOC_SAVE_OPTION {
  SAVE = 'SAVE',
  SAVE_AS_DRAFT = 'SAVE_AS_DRAFT',
  SAVE_AND_CLOSE = 'SAVE_AND_CLOSE'
}

/**
 * Set `isLoading` key of a draft, for showing "saving" status
 * @param draftId
 * @param status
 */
export const setIsSavingDocument = (draftId: number, status: boolean) => {
  Store.dispatch(
    setDraftMetaDataKeys({ draftId, keysToUpdate: { isLoading: status } })
  );
};

/**
 * Set `canValidate` key of a draft
 * @param draftId
 * @param status
 */
export const setCanValidateDocument = (draftId: number, status: boolean) => {
  Store.dispatch(
    setDraftMetaDataKeys({ draftId, keysToUpdate: { canValidate: status } })
  );
};

/**
 * Set `isErrorOnSave` key of a draft, used for invoice
 * @param draftId
 * @param status
 */
export const setIsErrorOnSaveDocument = (draftId: number, status: boolean) => {
  Store.dispatch(
    setDraftMetaDataKeys({ draftId, keysToUpdate: { isErrorOnSave: status } })
  );
};

const getBadgeDataByStatus = (
  status: RECEIVED_GOODS_STATUS | FULFILLMENT_STATUS | PAYMENT_STATUS,
  statusType: 'receivedGoods' | 'fulfillment' | 'payment'
) => {
  if (statusType === 'receivedGoods') {
    switch (status) {
      case RECEIVED_GOODS_STATUS.FULLY_RECEIVED:
        return {
          title: 'Received',
          color: 'bg-chip-green text-green'
        };
      case RECEIVED_GOODS_STATUS.PARTIAL_RECEIVED:
        return {
          title: 'Partially Received',
          color: 'bg-chip-blue text-blue'
        };
      case RECEIVED_GOODS_STATUS.NOT_RECEIVED:
        return {
          title: 'Not Received',
          color: 'bg-chip-red text-red'
        };
      default:
        return null;
    }
  } else if (statusType === 'fulfillment') {
    switch (status) {
      case FULFILLMENT_STATUS.FULLY_FULFILLED:
        return {
          title: localizedText('Fulfilled'),
          color: 'bg-chip-green text-green'
        };
      case FULFILLMENT_STATUS.PARTIAL_FULFILLED:
        return {
          title: `Partially ${localizedText('Fulfilled')}`,
          color: 'bg-chip-blue text-blue'
        };
      case FULFILLMENT_STATUS.UNFULFILLED:
      default:
        return {
          title: localizedText('unfulfilled'),
          color: 'bg-chip-red text-red'
        };
    }
  } else if (statusType === 'payment') {
    switch (status) {
      case PAYMENT_STATUS.RECEIVED:
        return {
          title: 'paid',
          color: 'bg-chip-green text-green'
        };
      case PAYMENT_STATUS.PARTIAL:
        return {
          title: 'Partially Paid',
          color: 'bg-chip-blue text-blue'
        };
      case PAYMENT_STATUS.PENDING:
      default:
        return {
          title: 'unpaid',
          color: 'bg-chip-red text-red'
        };
    }
  } else {
    return null;
  }
};

const getPaymentBadgeDataByDocType = (
  type: string,
  paymentStatus: PAYMENT_STATUS
) => {
  switch (type) {
    case LABELS.BILLS:
    case LABELS.INVOICES:
    case LABELS.EXPENSE_BILL:
    case LABELS.FA_BILL:
      return getBadgeDataByStatus(paymentStatus, 'payment');
    default:
      return null;
  }
};

const getFulfillmentBadgeDataByDocType = (data: {
  type: string;
  fulfillmentStatus: FULFILLMENT_STATUS;
  receiptStatus: RECEIVED_GOODS_STATUS;
  receiveGoodsStatus: RECEIVED_GOODS_STATUS;
}) => {
  const { type, fulfillmentStatus, receiptStatus, receiveGoodsStatus } = data;
  switch (type) {
    case LABELS.QUOTES:
    case LABELS.SALES_ORDER:
    case LABELS.INVOICES:
      if (type === LABELS.QUOTES && isSalesOrderVisible()) {
        return null;
      }

      return getBadgeDataByStatus(fulfillmentStatus, 'fulfillment');
    case LABELS.PURCHASE_ORDERS:
    case LABELS.FA_ORDER:
    case LABELS.FA_BILL:
    case LABELS.WORK_OUT:
      return getBadgeDataByStatus(receiptStatus, 'receivedGoods');
    case LABELS.BILLS:
      return getBadgeDataByStatus(receiveGoodsStatus, 'receivedGoods');
    default:
      return null;
  }
};

export const getDraftBadges = (data: {
  draftType: DraftTypes;
  type: string;
  paymentStatus: PAYMENT_STATUS;
  fulfillmentStatus: FULFILLMENT_STATUS;
  receiptStatus: RECEIVED_GOODS_STATUS;
  receiveGoodsStatus: RECEIVED_GOODS_STATUS;
}) => {
  const {
    draftType,
    type,
    paymentStatus,
    fulfillmentStatus,
    receiptStatus,
    receiveGoodsStatus
  } = data;
  let paymentBadge = null,
    fulfillmentBadge = null,
    badgeList = [];

  switch (draftType) {
    case DraftTypes.DRAFT:
      badgeList.push({
        title: DraftTypes.DRAFT,
        color: 'bg-chip-blue text-blue'
      });
      break;
    case DraftTypes.READONLY:
    case DraftTypes.UPDATE:
      if (draftType === DraftTypes.READONLY) {
        badgeList.push({
          title: DraftTypes.READONLY,
          color: 'bg-chip-orange text-orange'
        });
      }

      paymentBadge = getPaymentBadgeDataByDocType(type, paymentStatus);
      paymentBadge && badgeList.push(paymentBadge);
      fulfillmentBadge = getFulfillmentBadgeDataByDocType({
        type,
        fulfillmentStatus,
        receiptStatus,
        receiveGoodsStatus
      });
      fulfillmentBadge && badgeList.push(fulfillmentBadge);
      break;
    default:
  }

  return (
    <>
      {badgeList.map((badge, index: number) => (
        <DKLabel
          key={`action-bar-badge-${index}`}
          text={badge.title}
          style={{
            textTransform: 'capitalize'
          }}
          className={`text-dark-gray ${badge.color} border-radius-r ml-r p-h-s p-v-xs fw-m`}
        />
      ))}
    </>
  );
};
