import { FC, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { DKLabel } from 'deskera-ui-library';
import { useAppDispatch, useAppSelector } from '../../../../../Redux/Hooks';
import { t } from 'i18next';
import { DOCUMENT_MODE } from '../../../../../Constants/Constant';
import {
  selectDocumentFormDataByKeys,
  updateMultipleKeysInDocument
} from '../../../../../Redux/Slices/DocumentSlice';
import { DOCUMENT_KEYS } from '../../../Utilities/DocConstants';
import { CommonDraftPropsContext } from '../../../Utilities/DocContext';
import { getTotalOfItems } from '../DocSummaryHelper';
import useDebounce from '../../../../../Hooks/useDebounce';

interface IDocRoundOffInputProps {}

const DocRoundOffInput: FC<IDocRoundOffInputProps> = () => {
  const dispatch = useAppDispatch();
  const { draftId, documentMode } = useContext(CommonDraftPropsContext);
  const [roundOffAmountInDocumentCurrency, items] = useAppSelector(
    selectDocumentFormDataByKeys(draftId, [
      DOCUMENT_KEYS.ROUND_OFF_AMOUNT_IN_DOCUMENT_CURRENCY,
      DOCUMENT_KEYS.ITEMS
    ])
  );
  const [roundOffValue, setRoundOffValue] = useState<number>(
    roundOffAmountInDocumentCurrency
  );
  const debouncedValue = useDebounce(roundOffValue, 300);
  const manualChange = useRef<boolean>(false);

  const total = useMemo(() => getTotalOfItems({ items }), [items]);

  useEffect(() => {
    if (total - Math.abs(debouncedValue) >= 0 && manualChange?.current) {
      dispatch(
        updateMultipleKeysInDocument({
          draftId,
          keysToUpdate: {
            [DOCUMENT_KEYS.ROUND_OFF_AMOUNT_IN_DOCUMENT_CURRENCY]:
              debouncedValue ?? 0
          }
        })
      );
    }
  }, [debouncedValue]);

  useEffect(() => {
    if (!manualChange.current) {
      manualChange.current = false;
      setRoundOffValue(roundOffAmountInDocumentCurrency);
    }
  }, [roundOffAmountInDocumentCurrency]);

  const handleRoundOffChange = (e: any) => {
    const value = e.target.value;
    if (
      value === '' ||
      value === '-' ||
      (+value <= total && +value >= Math.sign(-1) * total)
    ) {
      manualChange.current = true;
      setRoundOffValue(value);
    }
  };

  return (
    <div
      className="row width-auto mb-m justify-content-between"
      style={{ width: '100%' }}
    >
      <div className="row width-auto">
        <DKLabel
          text={t(`DOCUMENT.DOCUMENT_SUMMARY_VIEW.ROUNDING_OFF`)}
          className={'fw-m ml-r'}
        />
      </div>

      <div className="flex flex-col items-end w-24 number-hide-arrows">
        <input
          value={roundOffValue}
          type="text"
          className="border-transparent hover:border-gray-300 focus:border-gray-400 w-10/12 text-right border rounded-sm outline-none cursor-pointer"
          onChange={handleRoundOffChange}
          disabled={documentMode === DOCUMENT_MODE.VIEW}
        />
      </div>
    </div>
  );
};

export default DocRoundOffInput;
