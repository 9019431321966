import ApiConstants from '../Constants/ApiConstants';
import Utility from '../Utility/Utility';
import http from './Interceptor';
import { Store } from '../Redux/Store';
import { ContactPayload } from '../Models/NewContact';
import { MODULES_NAME, STATUS_TYPE } from '../Constants/Constant';
import { COMPLIANCE_SPECIFIC_FIELD_NAME } from '../Constants/Enum';

export interface ContactAPIConfig {
  SearchTerm?: string;
  Limit?: number;
  Page?: number;
  Query?: string;
  IncludeOweAmounts?: boolean;
  IncludeOpeningAmounts?: boolean;
  QueryParam?: any;
  SortDir?: string;
  Sort?: string;
}
export interface PaymentTermAPIConfig {
  SearchTerm?: string;
  Limit?: number;
  Page?: number;
  Query?: string;
  IncludeOweAmounts?: boolean;
  IncludeOpeningAmounts?: boolean;
  QueryParam?: any;
  SortDir?: string;
  Sort?: string;
}

const defaultConfig: ContactAPIConfig = {
  SearchTerm: '',
  Limit: 25,
  Page: 0,
  Query: '',
  IncludeOpeningAmounts: true,
  IncludeOweAmounts: true,
  QueryParam: {},
  SortDir: 'DESC',
  Sort: 'documentSequenceCode'
};
const paymentTermDefaultConfig: PaymentTermAPIConfig = {
  SearchTerm: '',
  Limit: 25,
  Page: 0,
  Query: '',
  IncludeOpeningAmounts: true,
  IncludeOweAmounts: true,
  QueryParam: {},
  SortDir: 'DESC',
  Sort: 'documentSequenceCode'
};

class ContactService {
  static apiConfig: ContactAPIConfig;
  static paymentTermApiConfig: PaymentTermAPIConfig;
  static moduleName: COMPLIANCE_SPECIFIC_FIELD_NAME =
    COMPLIANCE_SPECIFIC_FIELD_NAME.CONTACT;
  static abortController: any = null;

  static getContactsApiUrl() {
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    }

    const { BASE, CONTACTS } = ApiConstants.URL;
    const url = `${BASE}${CONTACTS.FETCH_CONTACTS.slice(
      CONTACTS.FETCH_CONTACTS.startsWith('/') ? 1 : 0
    )}`;
    const countryCode = Utility.getTenantSpecificApiCode(
      ContactService.moduleName
    );

    const params = new URLSearchParams({
      search: this.apiConfig.SearchTerm || '',
      includeOweAmounts: this.apiConfig.IncludeOweAmounts ?? defaultConfig.IncludeOweAmounts,
      includeOpeningAmounts: this.apiConfig.IncludeOpeningAmounts ?? defaultConfig.IncludeOpeningAmounts,
      SKIP_REQUEST_INTERCEPTOR: true,
      limit: this.apiConfig.Limit ?? defaultConfig.Limit,
      page: this.apiConfig.Page ?? defaultConfig.Page,
      query: this.apiConfig.Query || '',
      sort: this.apiConfig.Sort ?? defaultConfig.Sort,
      sortDir: this.apiConfig.SortDir ?? defaultConfig.SortDir,
      ...this.apiConfig.QueryParam
    });

    return `${url}${countryCode}?${params.toString()}`;
  }

  static getContactsByPage(cancelRequest = true) {
    if (cancelRequest) {
      Utility.cancelRequest(ContactService.abortController);
    }
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    }
    const countryCode = Utility.getTenantSpecificApiCode(
      ContactService.moduleName
    );
    const url = ApiConstants.URL.CONTACTS.FETCH_CONTACTS + countryCode;
    ContactService.abortController = Utility.createAbortController();

    const queryString: string = `?fetchAttachmentDetails=true&limit=${
      this.apiConfig.Limit ?? defaultConfig.Limit
    }&page=${this.apiConfig.Page ?? defaultConfig.Page}&search=${
      this.apiConfig.SearchTerm
        ? encodeURIComponent(this.apiConfig.SearchTerm)
        : ''
    }${this.apiConfig.Query ? '&query=' + this.apiConfig.Query : ''}${
      !Utility.isEmpty(this.apiConfig.QueryParam)
        ? this.apiConfig.QueryParam
        : ''
    }&SKIP_REQUEST_INTERCEPTOR=true&includeOweAmounts=${
      this.apiConfig.IncludeOweAmounts ?? defaultConfig.IncludeOweAmounts
    }
    &includeOpeningAmounts=${this.apiConfig.IncludeOpeningAmounts ?? defaultConfig.IncludeOpeningAmounts}&sort=${
      this.apiConfig.Sort ?? defaultConfig.Sort
    }&sortDir=${this.apiConfig.SortDir ?? defaultConfig.SortDir}`;

    const fullUrl = `${url}${queryString}`;

    return http
      .get(fullUrl, { signal: ContactService.abortController.signal })
      .catch((err: any) => {
        console.error('Error loading contacts: ', err);
        return Promise.reject(err);
      });
  }

  static getContactDetailsById(id: number): Promise<any> {
    if (id) {
      const countryCode = Utility.getTenantSpecificApiCode(
        ContactService.moduleName
      );
      const url =
        ApiConstants.URL.CONTACTS.FETCH_CONTACTS + `${countryCode}/${id}`;
      return http.get(url).catch((err: any) => {
        console.error('Error loading detailed contact: ', err);
        return Promise.reject(err);
      });
    } else {
      return Promise.reject(new Error('id cannot be empty'));
    }
  }

  static getPayableAccountsInfo() {
    return http
      .get(
        `${ApiConstants.URL.CONTACTS.FETCH_PAYABLE_ACCOUNT_INFO}` +
          '?includeBalance=false'
      )
      .catch((err: any) => {
        console.error('Error loading liabilities: ', err);
        return Promise.reject(err);
      });
  }

  static getReceiveableAccountsInfo() {
    return http
      .get(
        `${ApiConstants.URL.CONTACTS.FETCH_RECEIVABLE_ACCOUNT_INFO}` +
          '?includeBalance=false'
      )
      .catch((err: any) => {
        console.error('Error loading assets: ', err);
        return Promise.reject(err);
      });
  }

  static contactOpeningSummary() {
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    }
    const queryString: string = `?search=${this.apiConfig.SearchTerm}&page=${this.apiConfig.Page}&limit=${this.apiConfig.Limit}`;
    const finalEndpoint: string =
      ApiConstants.URL.CONTACTS.GET_CONTACT_OPENING_SUMMARY + queryString;
    return http.get(`${finalEndpoint}`).catch((err: any) => {
      console.error('Error loading contacts info: ', err);
      return Promise.reject(err);
    });
  }
  static contactDetailsOpening(contactCode: any) {
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    }
    const queryString: string = `?contactCode=${contactCode}`;
    const finalEndpoint: string =
      ApiConstants.URL.CONTACTS.GET_CONTACT_OPENING_DETAILS + queryString;
    return http.get(`${finalEndpoint}`).catch((err: any) => {
      console.error('Error loading contacts info: ', err);
      return Promise.reject(err);
    });
  }

  static getPaymentTermsInfo() {
    if (Utility.isEmpty(this.paymentTermApiConfig)) {
      this.paymentTermApiConfig = paymentTermDefaultConfig;
    }
    let queryString: string = `?search=${
      this.paymentTermApiConfig.SearchTerm
    }&page=${this.paymentTermApiConfig.Page}&limit=${
      this.paymentTermApiConfig.Limit
    }${
      !Utility.isEmpty(this.paymentTermApiConfig.QueryParam)
        ? this.paymentTermApiConfig.QueryParam
        : ''
    }`;
    const finalEndpoint: string =
      ApiConstants.URL.CONTACTS.FETCH_PAYMENT_TERM_INFO + queryString;
    return http.get(`${finalEndpoint}`).catch((err: any) => {
      console.error('Error loading payment terms info: ', err);
      return Promise.reject(err);
    });
  }

  static postPaymentTerms(payload: any) {
    const finalEndPoint = ApiConstants.URL.CONTACTS.FETCH_PAYMENT_TERM_INFO;
    return http.post(`${finalEndPoint}`, payload).catch((error: any) => {
      return Promise.reject(error);
    });
  }

  static deletePaymentTerms(id: number) {
    const finalEndPoint =
      ApiConstants.URL.CONTACTS.DELETE_PAYMENT_TERM_INFO(id);
    return http.delete(`${finalEndPoint}`).catch((error: any) => {
      return Promise.reject(error);
    });
  }
  static editPaymentTerms(payload: any, id: number) {
    const finalEndPoint = ApiConstants.URL.CONTACTS.EDIT_PAYMENT_TERM_INFO(id);
    return http.put(`${finalEndPoint}`, payload).catch((error: any) => {
      return Promise.reject(error);
    });
  }

  static createContact(payload: ContactPayload) {
    const countryCode = Utility.getTenantSpecificApiCode(
      ContactService.moduleName
    );
    return http
      .post(
        `${ApiConstants.URL.CONTACTS.CREATE_CONTACT + countryCode}`,
        payload
      )
      .catch((err: any) => {
        console.error('Error while trying to create contact: ', err);
        return Promise.reject(err);
      });
  }

  static fetchActiveSequenceFormat(payload: any) {
    return http
      .post(
        `${ApiConstants.URL.CONTACTS.FETCH_ACTIVE_DOCUMENT_SEQUENCE}`,
        payload
      )
      .catch((err: any) => {
        console.error('Error loading sequence Formats: ', err);
        return Promise.reject(err);
      });
  }

  static deleteContact(id: number) {
    const countryCode = Utility.getTenantSpecificApiCode(
      ContactService.moduleName
    );
    return http
      .delete(`${ApiConstants.URL.CONTACTS.DELETE_CONTACT(id, countryCode)}`)
      .catch((err: any) => {
        console.error('Error deleting contact: ', err);
        return Promise.reject(err);
      });
  }

  static updateContact(payload: ContactPayload, contactId: number) {
    const countryCode = Utility.getTenantSpecificApiCode(
      ContactService.moduleName
    );
    return http
      .put(
        `${ApiConstants.URL.CONTACTS.UPDATE_CONTACT(contactId, countryCode)}`,
        payload
      )
      .catch((err: any) => {
        console.error('Error updating contact info: ', err);
        return Promise.reject(err);
      });
  }

  static updateAddress(payload: any, contactId: number) {
    return http
      .put(`${ApiConstants.URL.CONTACTS.UPDATE_ADDRESS(contactId)}`, payload)
      .catch((err: any) => {
        console.error('Error updating address info of contact: ', err);
        return Promise.reject(err);
      });
  }
  static getContactSummary() {
    const finalEndPoint = ApiConstants.URL.CONTACTS.FETCH_CONTACT_SUMMARY;
    return http
      .get(`${finalEndPoint}`)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static fetchContactBusinessUnit() {
    const query =
      '?limit=2000&page=0&sortDir=desc&sort=businessUnitName&search=&start=0&dir=desc';
    const finalEndPoint = ApiConstants.URL.CONTACTS.FETCH_BUSINESS_UNIT + query;
    return http
      .get(finalEndPoint)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static updateContactInvoiceRemainderStatus(payload: any) {
    return http
      .put(ApiConstants.URL.CONTACTS.UPDATE_CONTACT_REMAINDER_STATUS, payload)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static fetchContactInvoiceRemainderStatus(contactCode: string) {
    const query = `?contactCode=${contactCode}`;
    const finalEndPoint =
      ApiConstants.URL.CONTACTS.UPDATE_CONTACT_REMAINDER_STATUS + query;
    return http
      .get(finalEndPoint)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static bulkContactStatusUpdate(status: STATUS_TYPE, contactCodes: string[]) {
    const countryCode = Utility.getTenantSpecificApiCode(
      ContactService.moduleName
    );
    const query = `?status=${status}`;
    const finalEndPoint =
      ApiConstants.URL.CONTACTS.BULK_STATUS_UPDATE(countryCode) + query;
    return http
      .patch(finalEndPoint, contactCodes)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static fetchContactByContactCode(contactCode: string) {
    const countryCode = Utility.getTenantSpecificApiCode(
      ContactService.moduleName
    );
    const url = `${ApiConstants.URL.CONTACTS.FETCH_CONTACTS}/${countryCode}?query=status=ACTIVE,code=${contactCode}&limit=20`;
    return http
      .get(url)
      .then(
        (res: any) => {
          return Promise.resolve(res);
        },
        (err: any) => {
          return Promise.resolve(err);
        }
      )
      .catch((res: any) => {
        return Promise.resolve(res);
      });
  }

  static getContactsByContactCodes(codes: string[]): Promise<any[]> {
    return http
      .post(ApiConstants.URL.CONTACTS.GET_CONTACTS_BY_CODES, codes)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static bulkContactDelete(contactId: number[]) {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      },
      data: contactId
    };
    return http
      .delete(ApiConstants.URL.CONTACTS.BULK_CONTACT_DELETE, config)
      .then(
        (res: any) => {
          return Promise.resolve(res);
        },
        (err: any) => {
          return Promise.reject(err);
        }
      )
      .catch((res: any) => {
        return Promise.reject(res);
      });
  }

  static updateFinanceSettings(payload: any, tenantId: number) {
    return http
      .post(ApiConstants.URL.CONTACTS.UPDATE_FINANCE_SETTING(tenantId), payload)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }
  static sendEmail(payload: any) {
    return http.post(`${ApiConstants.URL.CORE_SEND_EMAIL}`, payload);
  }
}

export default ContactService;
