import { DKIcons, INPUT_TYPE } from 'deskera-ui-library';
import {
  BOOKS_DATE_FORMAT,
  CLASS_ASSIGNMENT,
  DOCUMENT_MODE,
  DOC_TYPE,
  LOCATION_CLASS_ENUM,
  PRODUCT_TRANSACTIONTYPE_PURCHASE,
  PRODUCT_TRANSACTIONTYPE_SALES,
  PRODUCT_TYPE,
  COLUMN_WITH_PRICES,
  TAX_SYSTEM
} from '../../../Constants/Constant';
import {
  FEATURE_PERMISSIONS,
  PERMISSIONS_BY_MODULE
} from '../../../Constants/Permission';
import {
  AMORTIZATION_END_DATE_COLUMN,
  AMORTIZATION_START_DATE_COLUMN,
  AMORTIZATION_TEMPLATE_COLUMN,
  DocumentConfigManager,
  EXPECTED_DELIVERY_DATE_COLUMN,
  REV_REC_END_DATE_COLUMN,
  REV_REC_START_DATE_COLUMN
} from '../../../Managers/DocumentConfigManger';
import { DraftTypes } from '../../../Models/Drafts';
import { DataGridCallBackParam, IColumn } from '../../../Models/Table';
import { Store } from '../../../Redux/Store';
import DateFormatService from '../../../Services/DateFormat';
import {
  CASCADING_DISCOUNT_PREFIX,
  getNewColumnForCF,
  getNewColumnForDiscount,
  getNewColumnForTotalDiscount,
  getTenantTaxSystem,
  isSGAndPeppolOptInEnabled
} from '../../../SharedComponents/DocumentForm/NewDocumentHelper';
import { DocumentConfigUtility } from '../../../Utility/DocumentConfigUtility';
import Utility, { deepClone } from '../../../Utility/Utility';
import { checkUserPermission } from '../../Settings/GranularPermissions/GranularPermissionsHelper';
import {
  discountConfigPresent,
  getCascadingDiscountsFromStore,
  getCascadingDiscountTotalColumnVisibility,
  getDocumentByIDFromStore,
  getOrderedCascadingDiscountsFromSettings,
  totalDiscountConfigPresent
} from '../Helper/DocumentHelper';
import {
  amortizationTemplateColumnRenderer,
  cascadingDiscountColumnRenderer,
  discountColumnRenderer
} from '../Helper/View/DocRenderer';
import { canUpdateDocument, isDropShipDocument } from './DocCommonUtils';
import {
  BILL_QTY_COLUMNS_IN_DOC,
  DOC_LINE_ITEM_KEYS,
  DOC_TYPE_TO_PERMISSION_MATRIX,
  MRP_SALE_ORDER_TRACKING_COLUMN_IN_DOC,
  READONLY_TAX_AMOUNT_COUNTRIES,
  SALE_ORDER_QTY_COLUMNS_IN_DOC,
  SALE_ORDER_TRACKING_COLUMN_IN_DOC,
  SG_PEPPOL_COLUMNS,
  SG_PEPPOL_ENABLE_MODULE
} from './DocConstants';

import ApiConstants from '../../../Constants/ApiConstants';
import {
  COMMON_EVENTS,
  DOC_POPUP_TYPE,
  commonCustomEvent
} from '../../../Services/event/commonEvents';
import { filterTaxByEffectiveDates } from '../Helper/Common/DocDataHelper';
import { clearAmortizationDetails } from '../../../Redux/Slices/DocumentSlice';

type updateColumnConfigFnType = {
  draftId: any;
  columnConfig: IColumn[];
  documentMode: DOCUMENT_MODE;
  draftType: DraftTypes;
  products: any[];
  productUOMs: any[];
  amortizationTemplates: any[];
  priceList: any[];
  dimensionData: any;
  productCustomFields: any[];
};
export const updateColumnConfig = ({
  draftId,
  draftType,
  columnConfig,
  documentMode,
  products,
  productUOMs,
  amortizationTemplates,
  priceList,
  dimensionData,
  productCustomFields
}: updateColumnConfigFnType): IColumn[] => {
  const document: any = getDocumentByIDFromStore(draftId) ?? {};

  const {
    documentType,
    paymentStatus,
    receiveGoodsStatus,
    fulfillmentStatus,
    fulfillmentType,
    totalAmount,
    isPartialInvoice,
    isPartialSalesOrder,
    reservedStock,
    isPartialBill,
    dropShip,
    documentDate,
    linkedDocuments,
    contact,
    items,
    currency
  } = document?.populateFormData ?? {};

  const { additionalSettings, country: tenantCountry } =
    Store.getState().authInfo.currentTenantInfo.data;
  const featurePermissionsInfo =
    Store.getState().authInfo.featurePermissions.data;
  const isReadOnly =
    documentMode === DOCUMENT_MODE.VIEW || draftType === DraftTypes.READONLY;
  const isDropShipDoc = isDropShipDocument({ dropShip, fulfillmentType });
  const isDropshipOrder = isDropShipDoc && documentType === DOC_TYPE.ORDER;
  const isSalesDocument = Utility.isSalesDocument({ documentType });

  /**
   * * Sub function goes here forming a closure to avoid passing param further down
   **/

  /**
   * @param column - column to check is editable or not
   * @returns- boolean flag indicating column editable or not
   */
  const isColumnEditable = (column: IColumn): boolean => {
    switch (column.key) {
      case DOC_LINE_ITEM_KEYS.DOCUMENT_SEQUENCE_CODE:
        return false;
      case DOC_LINE_ITEM_KEYS.PRODUCT_DESCRIPTION:
        return !isReadOnly && canUpdateProductDescription();
      case DOC_LINE_ITEM_KEYS.PRODUCT:
        return canUpdateProduct();
      case DOC_LINE_ITEM_KEYS.TAX:
        return (
          !isReadOnly &&
          canUpdateProductDescription() &&
          checkForPermission(
            DOC_TYPE_TO_PERMISSION_MATRIX[documentType]?.EDIT_TAX
          )
        );
      case DOC_LINE_ITEM_KEYS.UOM:
        return !isReadOnly && canUpdateProductDescription();
      case DOC_LINE_ITEM_KEYS.TAX_AMOUNT:
        return canUpdateTaxAmount();
      case DOC_LINE_ITEM_KEYS.DISCOUNT:
        return canUpdateDiscount();
      case DOC_LINE_ITEM_KEYS.TOTAL_DISCOUNT_AMOUNT:
        return false;
      case DOC_LINE_ITEM_KEYS.UNIT_PRICE:
        return (
          !isReadOnly &&
          canUpdateProductDescription() &&
          checkForPermission(
            DOC_TYPE_TO_PERMISSION_MATRIX[documentType]?.EDIT_PRICE
          )
        );
      case DOC_LINE_ITEM_KEYS.PRODUCT_QUANTITY:
        return canUpdateProductQuantity();
      default:
        return !isReadOnly && !!column.editable;
    }
  };

  /**
   * @returns boolean flag indicating weather product description can be updated or not
   */
  const canUpdateProductDescription = (): boolean => {
    return canUpdateDocument({
      documentType,
      fulfillmentStatus,
      paymentStatus,
      draftType,
      receiveGoodsStatus,
      totalAmount
    });
  };

  /**
   * @returns boolean flag indicating weather product can be updated or not
   */
  const canUpdateProduct = (): boolean => {
    let canEdit = true;
    const isPartialDocument =
      isPartialBill || isPartialInvoice || isPartialSalesOrder;
    const isPurchaseReqLinkedOrder =
      documentType === DOC_TYPE.ORDER &&
      linkedDocuments?.[0]?.documentType === DOC_TYPE.PURCHASE_REQUEST;
    if (
      isDropshipOrder ||
      isReadOnly ||
      isPartialDocument ||
      isPurchaseReqLinkedOrder
    ) {
      canEdit = false;
    }
    if (
      additionalSettings?.EDIT_CONVERTED_DOC &&
      isPartialDocument &&
      !isReadOnly &&
      canUpdateProductDescription()
    ) {
      canEdit = true;
    }
    return canEdit;
  };

  /**
   * @returns boolean flag indicating weather taxAmount can be updated or not
   */
  const canUpdateTaxAmount = (): boolean => {
    let canUpdate = false;
    if (
      !isReadOnly &&
      !contact?.taxExempted &&
      canUpdateProductDescription() &&
      !READONLY_TAX_AMOUNT_COUNTRIES.includes(tenantCountry) &&
      checkForPermission(DOC_TYPE_TO_PERMISSION_MATRIX[documentType]?.EDIT_TAX)
    ) {
      canUpdate = true;
    }
    return canUpdate;
  };

  const canUpdateDiscount = (): boolean => {
    let canUpdate = false;
    if (
      !isReadOnly &&
      canUpdateProductDescription() &&
      checkForPermission(
        DOC_TYPE_TO_PERMISSION_MATRIX[documentType]?.EDIT_DISCOUNT
      )
    ) {
      canUpdate = true;
    }
    return canUpdate;
  };

  const canUpdateProductQuantity = (): boolean => {
    let canUpdate = false;
    if (!isDropshipOrder && !isReadOnly && canUpdateProductDescription()) {
      canUpdate = true;
    }
    return canUpdate;
  };

  /**
   *
   * @returns - a function returning API for searching product based on type of document
   */
  const getProductSearchUrlFn = () => {
    let transactionType = '';
    let query = '';
    switch (documentType) {
      case DOC_TYPE.QUOTE:
      case DOC_TYPE.SALES_ORDER:
      case DOC_TYPE.INVOICE:
        transactionType = PRODUCT_TRANSACTIONTYPE_SALES;
        break;
      case DOC_TYPE.REQUISITION:
      case DOC_TYPE.BILL:
      case DOC_TYPE.ORDER:
      case DOC_TYPE.SUPPLIER_QUOTE:
        transactionType = PRODUCT_TRANSACTIONTYPE_PURCHASE;
        break;
      case DOC_TYPE.JOB_WORK_OUT_ORDER:
        transactionType = PRODUCT_TRANSACTIONTYPE_PURCHASE;
        query = `active=true,hasVariants=false,type='${PRODUCT_TYPE.BILL_OF_MATERIALS}'`;
        break;
      default:
        break;
    }
    return (search: any) =>
      DocumentConfigManager.getProductURL(search, null, transactionType, query);
  };

  /**
   * @description - this is a sub function that update product column config
   * @param column - product column that needs to be updated
   */
  const updateProductColumn = (column: IColumn) => {
    if (column.dropdownConfig) {
      column.dropdownConfig.data = DocumentConfigUtility.productDataParser(
        { content: products ?? [] },
        documentType
      );
      if (column.dropdownConfig.button) {
        if (checkUserPermission(PERMISSIONS_BY_MODULE.PRODUCTS.CREATE)) {
          column.dropdownConfig.button.onClick = (
            data: DataGridCallBackParam<any>
          ) => {
            commonCustomEvent.dispatch(COMMON_EVENTS.DOC_POPUP_SHOW, {
              type: DOC_POPUP_TYPE.SHOW_CREATE_PRODUCT_POPUP,
              ...data
            });
          };
        } else {
          column.dropdownConfig.button = null;
        }
      }
      if (column.dropdownConfig.searchApiConfig) {
        column.dropdownConfig.searchApiConfig.getUrl = getProductSearchUrlFn();
      }
    }
  };
  /**
   * @description - this is a sub function that update tax column config
   * @param column - tax column that needs to be updated
   */
  const updateTaxColumn = (column: IColumn) => {
    column.hidden = getTenantTaxSystem() === TAX_SYSTEM.US;
    const { sell = [], purchase = [] }: any =
      Store.getState()?.commonData?.data?.tax;
    let taxes = isSalesDocument ? sell : purchase;
    if (Utility.isNotEmpty(taxes)) {
      taxes = filterTaxByEffectiveDates(taxes, documentDate);
    }
    const dateOfDocument =
      typeof documentDate === 'string'
        ? DateFormatService.getDateFromStr(
            documentDate,
            BOOKS_DATE_FORMAT['YYYY-MM-DD']
          )
        : new Date(documentDate);
    if (column.dropdownConfig) {
      column.dropdownConfig.data = taxes ?? [];
      if (column.dropdownConfig.searchApiConfig) {
        column.dropdownConfig.searchApiConfig.getUrl = (search: any) =>
          DocumentConfigManager.getTaxURL(
            search,
            DateFormatService.getDateStrFromDate(
              dateOfDocument,
              BOOKS_DATE_FORMAT['YYYY-MM-DD']
            )
          );
        column.dropdownConfig.searchApiConfig.dataParser = (data: any) =>
          DocumentConfigUtility.taxDataParser(data, documentType);
      }
      if (column.dropdownConfig.button) {
        if (!checkUserPermission(PERMISSIONS_BY_MODULE.SETTINGS.TAX)) {
          column.dropdownConfig.button = null;
        } else {
          column.dropdownConfig.button.onClick = (
            data: DataGridCallBackParam<any>
          ) => {
            commonCustomEvent.dispatch(COMMON_EVENTS.DOC_POPUP_SHOW, {
              type: DOC_POPUP_TYPE.SHOW_CREATE_TAX_POPUP,
              ...data
            });
          };
        }
      }
    }
  };

  /**
   * @description - this is a sub function that update taxAmount column config
   * @param column - taxAmount column that needs to be updated
   */
  const updateTaxAmountColumn = (column: IColumn) => {
    column.hidden = isTaxColumnAmountColumnHidden();
    column.name = getTenantTaxSystem() === TAX_SYSTEM.US ? 'Tax' : 'Tax Amount';
    column.type =
      !column.hidden && contact?.taxExempted
        ? INPUT_TYPE.TEXT
        : INPUT_TYPE.NUMBER;
    column.formatter = (data: any) => {
      if (!column.hidden && contact?.taxExempted) {
        return 'Exempted';
      } else {
        return DocumentConfigUtility.amountFormatter(data.value, currency);
      }
    };
  };

  const updateDiscountColumn = (column: IColumn) => {
    column.hidden = showCascadingDiscountTotal;
    column.renderer = (data: any) => discountColumnRenderer({ data, currency });
  };

  const updateDiscountAmountColumn = (column: IColumn) => {
    column.hidden = !showCascadingDiscountTotal;
    column.formatter = (data: any) => {
      return DocumentConfigUtility.amountFormatter(data.value, currency);
    };
  };

  const updateUnitPriceColumn = (column: IColumn) => {
    column.formatter = (data: any) =>
      DocumentConfigUtility.amountFormatter(data.value, currency);
    if (column.dropdownConfig) {
      column.dropdownConfig.data = priceList ?? [];
    }
  };

  const updateCascadingDiscountColumn = (column: IColumn) => {
    column.editable =
      !isReadOnly &&
      canUpdateProductDescription() &&
      checkForPermission(
        DOC_TYPE_TO_PERMISSION_MATRIX[documentType]?.EDIT_DISCOUNT
      );
    column.renderer = (data: any) =>
      cascadingDiscountColumnRenderer({ data, currency, column });
  };

  const updateClassColumn = (column: IColumn) => {
    column.hidden =
      !additionalSettings?.CLASS?.trackClasses ||
      additionalSettings?.CLASS?.assignClasses === CLASS_ASSIGNMENT.TRANSACTION;

    if (column.dropdownConfig) {
      column.dropdownConfig.button = {
        title: '+ Add New',
        className: 'bg-button text-white',
        onClick: () => {
          commonCustomEvent.dispatch(COMMON_EVENTS.DOC_POPUP_SHOW, {
            type: DOC_POPUP_TYPE.SHOW_ADD_CLASS_POPUP
          });
        }
      };
    }
  };

  const updateCustomColumn = (column: IColumn) => {
    const classData = dimensionData?.content?.find(
      (data: any) => data.label === LOCATION_CLASS_ENUM.CLASS
    );
    if (classData && classData.id === column.id) {
      updateClassColumn(column);
    }
    if (column.type === INPUT_TYPE.DROPDOWN && !!column.dropdownConfig) {
      if (column.dropdownConfig?.data?.length) {
        column.dropdownConfig.allowSearch =
          column.dropdownConfig?.data?.length > 5;
      }
      column.dropdownConfig.searchableKey = 'value';
    }
    if (documentType === DOC_TYPE.SALES_ORDER) {
      if (
        typeof column.key === 'string' &&
        SALE_ORDER_QTY_COLUMNS_IN_DOC.includes(column?.key)
      ) {
        column.editable = false;
      }
    }
    if (documentType === DOC_TYPE.INVOICE || documentType === DOC_TYPE.QUOTE) {
      if (additionalSettings?.TRACKING_DETAILS_IN_SO) {
        if (
          typeof column.key === 'string' &&
          SALE_ORDER_TRACKING_COLUMN_IN_DOC.includes(column.key)
        ) {
          column.editable = false;
        }
      }
    }
  };

  const getCascadingDiscountColumns = (copyOfColumns: IColumn[]) => {
    // CASCADING_DISCOUNT: Show total discount column.
    const getTotalDiscountColumn = (updatedConfigs: any[]) => {
      let taxOrTotalColumnIndexRef = -1;
      if (Utility.isUSorg()) {
        let configKeyRef = '';
        if (isSalesDocument && !isTaxColumnAmountColumnHidden()) {
          configKeyRef = 'taxAmount';
        } else {
          configKeyRef = 'totalAmount';
        }
        taxOrTotalColumnIndexRef = updatedConfigs.findIndex(
          (config: any) => config.key === configKeyRef
        );
      } else {
        taxOrTotalColumnIndexRef = updatedConfigs.findIndex(
          (config: any) => config.key === 'tax'
        );
      }
      if (taxOrTotalColumnIndexRef !== -1) {
        const newConfig = getNewColumnForTotalDiscount();
        updatedConfigs.splice(taxOrTotalColumnIndexRef, 0, newConfig);
      }
      return updatedConfigs;
    };

    // CASCADING_DISCOUNT: get only updated configs with cascading discounts
    const getUpdatedColumnConfigsWithCascadingDiscounts = (
      updatedConfigs: IColumn[]
    ) => {
      let unitPriceColumnIndexRef = updatedConfigs.findIndex(
        (config: any) => config.key === 'unitPrice'
      );
      if (unitPriceColumnIndexRef !== -1) {
        unitPriceColumnIndexRef += 1;
      }

      const orderedDiscounts = getOrderedCascadingDiscountsFromSettings();
      orderedDiscounts.forEach((discount: any) => {
        // Need to handle buy/sell discounts
        const discountFromStore = getCascadingDiscountsFromStore(
          document?.populateFormData
        )?.find((dis: any) => dis.id === discount.discountId);
        if (!Utility.isEmpty(discountFromStore)) {
          const newDiscountConfig = getNewColumnForDiscount(
            discountFromStore
          ) as any;
          if (!discountConfigPresent(newDiscountConfig?.key, updatedConfigs)) {
            unitPriceColumnIndexRef += 1;
            updatedConfigs.splice(
              unitPriceColumnIndexRef,
              0,
              newDiscountConfig
            );
          }
        }
      });

      return updatedConfigs;
    };

    /*
      If lineItems are present then build cascading discount related columns
      using the data in the first row
    */
    const lineItems = [...items].filter(
      (item: any) => !Utility.isEmpty(item.product)
    );
    let updatedConfigs = [...copyOfColumns];
    let unitPriceColumnIndexRef = updatedConfigs.findIndex(
      (config: any) => config.key === 'unitPrice'
    );
    if (unitPriceColumnIndexRef !== -1) {
      unitPriceColumnIndexRef += 1;
    }
    const firstLineItem = lineItems?.[0];
    // Read the first line item, to create config for existing documents
    let existingLineDiscounts: any[] = [];
    if (!Utility.isEmpty(firstLineItem?.additionalCharges?.globalDiscounts)) {
      // Handle existing documents with cascading discounts
      existingLineDiscounts = firstLineItem.additionalCharges?.globalDiscounts
        ?.length
        ? firstLineItem.additionalCharges?.globalDiscounts?.filter(
            (disc: any) => disc.isItemDiscount
          )
        : [];
      existingLineDiscounts.forEach((lineDiscount: any) => {
        const newDiscountConfig = getNewColumnForDiscount(lineDiscount) as any;
        if (!discountConfigPresent(newDiscountConfig?.key, updatedConfigs)) {
          unitPriceColumnIndexRef += 1;
          updatedConfigs.splice(unitPriceColumnIndexRef, 0, newDiscountConfig);
        }
      });
      if (!totalDiscountConfigPresent(updatedConfigs)) {
        updatedConfigs = getTotalDiscountColumn(updatedConfigs);
      }
    } else {
      // Update and return columns with cascading discount configs, if any
      updatedConfigs =
        getUpdatedColumnConfigsWithCascadingDiscounts(copyOfColumns);
      if (!totalDiscountConfigPresent(updatedConfigs)) {
        updatedConfigs = getTotalDiscountColumn(updatedConfigs);
      }
    }

    return updatedConfigs;
  };

  // All amortization related column configs
  const getAmortizationColumns = () => {
    const isAmortizationSupported =
      featurePermissionsInfo?.Supported?.includes(
        FEATURE_PERMISSIONS.AMORTIZATION
      ) && additionalSettings?.AMORTIZATION;
    if (isAmortizationSupported) {
      const amortizationTemplateColumn = {
        ...AMORTIZATION_TEMPLATE_COLUMN,
        formatter: ({ rowData, index }: any) => {
          return !Utility.isEmpty(rowData.amortizationTemplate)
            ? rowData.amortizationTemplate?.name
            : '';
        },
        dropdownConfig: {
          ...AMORTIZATION_TEMPLATE_COLUMN.dropdownConfig,
          searchApiConfig: {
            getUrl: (searchValue: string) =>
              ApiConstants.URL.BASE +
              ApiConstants.URL.AMORTIZATION.GET_AMORTIZATION_TEMPLATES +
              '?limit=10&page=0&query=active=true&search=' +
              searchValue,

            dataParser: (response: any) => {
              return response?.content || [];
            },
            debounceTime: 300
          },
          data: amortizationTemplates?.length ? amortizationTemplates : [],
          renderer: amortizationTemplateColumnRenderer,
          onSelect: (index: any, obj: any, rowIndex: any) => {},
          button: {
            title: 'Clear Selection',
            className: 'bg-red text-white',
            onClick: (data: DataGridCallBackParam<any>) => {
              Store.dispatch(
                clearAmortizationDetails({ draftId, rowIndex: data.rowIndex })
              );
            }
          }
        }
      };
      return [
        amortizationTemplateColumn,
        AMORTIZATION_START_DATE_COLUMN,
        AMORTIZATION_END_DATE_COLUMN
      ];
    }
    return [];
  };

  const getRevRecColumns = () => {
    const isRevRecSupported =
      featurePermissionsInfo?.Supported?.includes(
        FEATURE_PERMISSIONS.REVENUE_RECOGNITION_ADV
      ) && additionalSettings?.REV_REC;
    if (isRevRecSupported) {
      return deepClone([REV_REC_START_DATE_COLUMN, REV_REC_END_DATE_COLUMN]);
    }
    return [];
  };

  const getActionColumn = () => {
    let options = [
      {
        icon: DKIcons.ic_delete,
        className: 'p-0 padding-top-2px',
        onClick: (data: any) => {
          // TODO - onDelete(data)
        }
      }
    ];
    if (
      isReadOnly ||
      [DOC_TYPE.BILL, DOC_TYPE.ORDER, DOC_TYPE.JOB_WORK_OUT_ORDER].includes(
        documentType
      ) ||
      isDropShipDoc
    ) {
      options = [];
    } else {
      if (tenantCountry === TAX_SYSTEM.INDIA_GST || reservedStock) {
        options = [];
      }
    }
    return {
      id: 'action',
      key: 'action',
      name: '',
      type: INPUT_TYPE.BUTTON,
      width: reservedStock ? 85 : 50,
      options
    } as any;
  };

  const hideColumns = (columns: IColumn[]) => {
    if (documentType === DOC_TYPE.ORDER) {
      let filterQTYColumns = true;
      if (
        documentMode === DOCUMENT_MODE.EDIT ||
        documentMode === DOCUMENT_MODE.VIEW
      ) {
        if (additionalSettings?.PO_SHOW_RECEIVED_BILLED_QTY_SETTING) {
          filterQTYColumns = false;
        }
      }
      if (filterQTYColumns) {
        columns = columns.filter(
          (col: any) => !BILL_QTY_COLUMNS_IN_DOC.includes(col.key)
        );
      }
    }
    if (documentType === DOC_TYPE.SALES_ORDER) {
      if (additionalSettings?.TRACKING_DETAILS_IN_SO) {
        if (
          documentMode !== DOCUMENT_MODE.EDIT &&
          documentMode !== DOCUMENT_MODE.VIEW
        ) {
          columns = columns.filter(
            (col: any) => !SALE_ORDER_QTY_COLUMNS_IN_DOC.includes(col.key)
          );
        }
      } else {
        columns = columns.filter(
          (col: any) => !SALE_ORDER_QTY_COLUMNS_IN_DOC.includes(col.key)
        );
      }
    }
    if (documentType === DOC_TYPE.QUOTE || documentType === DOC_TYPE.INVOICE) {
      if (!additionalSettings?.TRACKING_DETAILS_IN_SO) {
        columns = columns.filter(
          (col: any) => !SALE_ORDER_TRACKING_COLUMN_IN_DOC.includes(col.key)
        );
      }
    }
    if (
      !SG_PEPPOL_ENABLE_MODULE.includes(documentType) &&
      !isSGAndPeppolOptInEnabled()
    ) {
      columns = columns.filter(
        (col: any) => !SG_PEPPOL_COLUMNS.includes(col.key)
      );
    } else {
      if (items?.every((item: any) => Utility.isEmptyObject(item?.lineRefNo))) {
        columns = columns.filter(
          (col: any) => !SG_PEPPOL_COLUMNS.includes(col.key)
        );
      }
    }

    if (
      !Utility.isMRPWithURLCheck() ||
      documentType !== DOC_TYPE.SALES_ORDER ||
      (documentMode !== DOCUMENT_MODE.VIEW &&
        documentMode !== DOCUMENT_MODE.EDIT)
    ) {
      columns = columns.filter(
        (col: any) => !MRP_SALE_ORDER_TRACKING_COLUMN_IN_DOC.includes(col.key)
      );
    }

    if (
      !checkForPermission(
        DOC_TYPE_TO_PERMISSION_MATRIX[documentType]?.VIEW_PRICE
      )
    ) {
      columns = columns.filter(
        (col: any) => !COLUMN_WITH_PRICES.includes(col.key)
      );
    }
    return columns;
  };

  const putAndHideColumns = (columns: IColumn[]): IColumn[] => {
    if (documentType === DOC_TYPE.BILL) {
      columns.push(...getAmortizationColumns());
    }
    if (
      documentType === DOC_TYPE.SALES_ORDER ||
      documentType === DOC_TYPE.INVOICE
    ) {
      columns.push(...getRevRecColumns());
    }
    if (
      Utility.isMRPWithURLCheck() &&
      (documentType === DOC_TYPE.BILL || documentType === DOC_TYPE.ORDER)
    ) {
      columns.push(deepClone(EXPECTED_DELIVERY_DATE_COLUMN));
    }

    const customFields: any[] = [];
    productCustomFields?.forEach((customField: any) => {
      const newItem = getNewColumnForCF(customField, !isReadOnly);
      customFields.push(newItem);
    });
    columns.push(...customFields);
    columns.push(getActionColumn());

    columns = hideColumns(columns);

    return columns;
  };

  /** main function starts from here */
  let copyOfColConfig = [...columnConfig];
  let showCascadingDiscountTotal = false;
  // Add CASCADING DISCOUNTS columns, if the feature is ON
  if (additionalSettings?.CASCADING_DISCOUNTS?.enable) {
    copyOfColConfig = getCascadingDiscountColumns([...copyOfColConfig]);
    showCascadingDiscountTotal = getCascadingDiscountTotalColumnVisibility(
      additionalSettings,
      documentMode,
      document?.populateFormData,
      copyOfColConfig
    );
  }
  copyOfColConfig = putAndHideColumns(copyOfColConfig);

  copyOfColConfig.forEach((column) => {
    column.editable = isColumnEditable(column);
    switch (column.key) {
      case DOC_LINE_ITEM_KEYS.PRODUCT:
        updateProductColumn(column);
        break;
      case DOC_LINE_ITEM_KEYS.TAX:
        updateTaxColumn(column);
        break;
      case DOC_LINE_ITEM_KEYS.UOM:
        if (column.dropdownConfig) {
          column.dropdownConfig.data = productUOMs ?? [];
        }
        break;
      case DOC_LINE_ITEM_KEYS.TAX_AMOUNT:
        updateTaxAmountColumn(column);
        break;
      case DOC_LINE_ITEM_KEYS.TOTAL_AMOUNT:
        column.formatter = (data: any) =>
          DocumentConfigUtility.amountFormatter(data.value, currency);
        break;
      case DOC_LINE_ITEM_KEYS.DISCOUNT:
        updateDiscountColumn(column);
        break;
      case DOC_LINE_ITEM_KEYS.TOTAL_DISCOUNT_AMOUNT:
        updateDiscountAmountColumn(column);
        break;
      case DOC_LINE_ITEM_KEYS.UNIT_PRICE:
        updateUnitPriceColumn(column);
        break;
      default:
        if (column.key?.toString()?.startsWith(CASCADING_DISCOUNT_PREFIX)) {
          updateCascadingDiscountColumn(column);
        } else {
          updateCustomColumn(column);
        }
        break;
    }
  });

  return copyOfColConfig;
};

const isTaxColumnAmountColumnHidden = (): boolean => {
  let isColumnHidden = true;
  if (getTenantTaxSystem() === TAX_SYSTEM.US && Utility.isComplianceEnabled()) {
    isColumnHidden = false;
  }
  if (getTenantTaxSystem() === TAX_SYSTEM.UK) {
    isColumnHidden = false;
  }
  return isColumnHidden;
};

const checkForPermission = (key: any): boolean => {
  if (Utility.isEmptyObject(key)) return true;
  return checkUserPermission(key);
};
