import { SyntheticEvent, useContext, useEffect, useState } from 'react';
import { shallowEqual } from 'react-redux';
import {
  DKIcons,
  DKTooltipWrapper,
  DKIcon,
  DKIconText,
  DKInput,
  DKLabel,
  INPUT_VIEW_DIRECTION,
  INPUT_TYPE
} from 'deskera-ui-library';
import { CommonDraftPropsContext } from '../../../Utilities/DocContext';
import { useAppDispatch, useAppSelector } from '../../../../../Redux/Hooks';
import {
  selectDocumentFormDataByKeys,
  updateMultipleKeysInDocument
} from '../../../../../Redux/Slices/DocumentSlice';
import DateFormatService from '../../../../../Services/DateFormat';
import {
  BOOKS_DATE_FORMAT,
  PAYMENT_MILESTONE_ENABLED_DOCS
} from '../../../../../Constants/Constant';
import { addDays } from 'date-fns';
import { activeTenantInfo } from '../../../../../Redux/Slices/AuthSlice';
import {
  getCalendarView,
  getDocDateLabel,
  validateAndUpdateDate
} from '../../../Helper/View/DocDatesHelper';
import { DOCUMENT_KEYS } from '../../../Utilities/DocConstants';
import ic_milestone from '../../../../../Assets/Icons/milestone.png';

import { convertBooksDateFormatToUILibraryFormat } from '../../../../../Utility/Utility';
import {
  COMMON_EVENTS,
  DOC_POPUP_TYPE,
  commonCustomEvent
} from '../../../../../Services/event/commonEvents';

interface IDocDatesProps {
  showFullscreenLayout: boolean;
}

/**
 * Component to show and handle due date interactions
 */
const DueDate = (props: IDocDatesProps) => {
  const { draftId, draftType, documentMode } = useContext(
    CommonDraftPropsContext
  );
  const [documentType, documentDate, validTillDate, paymentMilestoneFlag] =
    useAppSelector(
      selectDocumentFormDataByKeys(draftId, [
        DOCUMENT_KEYS.DOCUMENT_TYPE,
        DOCUMENT_KEYS.DOCUMENT_DATE,
        DOCUMENT_KEYS.VALID_TILL_DATE,
        DOCUMENT_KEYS.PAYMENT_MILESTONE_FLAG
      ]),
      shallowEqual
    );
  const currentDate = new Date();
  const laterDate = addDays(currentDate, 30);
  const docDateLabel = getDocDateLabel(documentType);

  const tenantInfo = useAppSelector(activeTenantInfo);
  const dispatch = useAppDispatch();

  const [dueDateOpen, setDueDateOpen] = useState(false);
  const [dueDate, setDueDate] = useState(
    validTillDate
      ? DateFormatService.getDateFromStr(
          validTillDate,
          BOOKS_DATE_FORMAT['DD-MM-YYYY']
        )
      : laterDate
  );
  const showMilestoneBlock =
    PAYMENT_MILESTONE_ENABLED_DOCS.includes(documentType) &&
    paymentMilestoneFlag;

  useEffect(() => {
    setDueDate(
      DateFormatService.getDateFromStr(
        validTillDate,
        BOOKS_DATE_FORMAT['DD-MM-YYYY']
      )
    );
  }, [validTillDate]);

  const updateDueDate = (date: Date) => {
    setDueDate(date);
    dispatch(
      updateMultipleKeysInDocument({
        draftId,
        keysToUpdate: {
          [DOCUMENT_KEYS.VALID_TILL_DATE]: DateFormatService.getDateStrFromDate(
            date,
            BOOKS_DATE_FORMAT['DD-MM-YYYY']
          )
        }
      })
    );
  };

  const getFullScreenView = () => {
    return (
      <div
        style={{
          width: 150,
          maxWidth: 200
        }}
      >
        <DKInput
          className="parent-width"
          title="Due Date"
          value={dueDate}
          titleStyle={{ color: 'gray' }}
          valueStyle={{ minHeight: 33 }}
          type={INPUT_TYPE.DATE}
          onChange={(newDate: any) => {
            validateAndUpdateDate(
              newDate,
              DateFormatService.getDateFromStr(
                documentDate,
                BOOKS_DATE_FORMAT['DD-MM-YYYY']
              ),
              (date: any) => {
                updateDueDate(date);
              },
              `Due Date cannot be before ${docDateLabel}.`,
              false,
              documentType,
              () => {
                setDueDate(new Date(dueDate));
              }
            );
          }}
          direction={INPUT_VIEW_DIRECTION.VERTICAL}
          required={false}
          dateFormat={convertBooksDateFormatToUILibraryFormat(
            tenantInfo.dateFormat
          )}
        />
      </div>
    );
  };

  const getNonFullscreenView = () => {
    return (
      <div className="row position-relative p-v-xs listPickerBG gap-2">
        <div
          className="row justify-content-between cursor-hand"
          onClick={() => setDueDateOpen((value) => !value)}
        >
          <div className="row width-auto">
            <DKIconText
              icon={DKIcons.data_type.ic_date}
              iconClassName="ic-with-text-height opacity-60"
              text="Due Date"
              textClassName="fw-m"
            />
            {showMilestoneBlock && (
              <DKTooltipWrapper
                content={'<b>Payment milestones added,<br> click to view.</b>'}
                tooltipClassName="bg-deskera-secondary width-auto"
              >
                <div
                  style={{
                    pointerEvents: 'auto'
                  }}
                  onClick={(e: SyntheticEvent) => {
                    e.stopPropagation();
                    commonCustomEvent.dispatch(COMMON_EVENTS.DOC_POPUP_SHOW, {
                      type: DOC_POPUP_TYPE.SHOW_PAYMENT_MILESTONE_POPUP
                    });
                  }}
                >
                  <DKIcon
                    src={ic_milestone}
                    className="ic-with-text-height ml-s cursor-hand z-index-2"
                  />
                </div>
              </DKTooltipWrapper>
            )}
          </div>
          <DKLabel text={DateFormatService.getDateStrFromDate(dueDate)} />
        </div>
        {dueDateOpen &&
          getCalendarView(
            dueDate,
            (newDate: any) => {
              validateAndUpdateDate(
                newDate,
                DateFormatService.getDateFromStr(
                  documentDate,
                  BOOKS_DATE_FORMAT['DD-MM-YYYY']
                ),
                (date: any) => {
                  updateDueDate(date);
                },
                `Due date cannot be before ${docDateLabel}.`,
                false,
                documentType
              );
            },
            setDueDateOpen
          )}
      </div>
    );
  };

  return props.showFullscreenLayout
    ? getFullScreenView()
    : getNonFullscreenView();
};

export default DueDate;
