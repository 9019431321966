import { TAX_SYSTEM } from '../../../../Constants/Constant';
import {
  calculateTaxesAndAmountsForAllLineItems,
  updateMultipleKeysInDocument
} from '../../../../Redux/Slices/DocumentSlice';
import { Store } from '../../../../Redux/Store';
import {
  getTenantTaxSystem,
  updateGstType
} from '../../../../SharedComponents/DocumentForm/NewDocumentHelper';
import Utility from '../../../../Utility/Utility';
import { isSalesDocument } from '../../Utilities/DocCommonUtils';
import { getIndianStateOptions } from '../Common/DocDataHelper';
import { getDocumentByIDFromStore } from '../DocumentHelper';

export const onPlaceOfSupplyChange = (data: {
  draftId: number;
  posValue: string;
  address?: any;
}) => {
  let keysToUpdate: any = {};
  if (getTenantTaxSystem() === TAX_SYSTEM.INDIA_GST) {
    const { draftId, posValue, address } = data;
    const oldDocument = getDocumentByIDFromStore(draftId)?.populateFormData;
    let tempDocument: any = { ...oldDocument };
    if (isSalesDocument(tempDocument.documentType)) {
      let shipToAddress: any = {};
      if (!Utility.isEmpty(address)) {
        shipToAddress = {
          ...address,
          placeOfSupply: posValue
        };
      } else {
        shipToAddress = {
          ...tempDocument.shipTo,
          placeOfSupply: posValue
        };
      }
      const gstType = updateGstType({
        ...tempDocument,
        shipTo: shipToAddress
      });

      keysToUpdate = {
        shipTo: shipToAddress,
        gstType: gstType,
        placeOfSupply: posValue,
        items: tempDocument.items?.map((item: any) => {
          return { ...item, gstType: gstType };
        })
      };
    } else {
      let shipFromAddress: any = {};
      if (!Utility.isEmpty(address)) {
        shipFromAddress = {
          ...address,
          placeOfSupply: posValue
        };
      } else {
        shipFromAddress = {
          ...tempDocument.shipFrom,
          placeOfSupply: posValue
        };
      }

      // Locally evaluate DOS
      let dosState: any = !Utility.isEmpty(
        tempDocument?.shipTo?.destinationOfSupply
      )
        ? tempDocument?.shipTo?.destinationOfSupply
        : tempDocument?.shipTo?.state || '';

      let dos = getIndianStateOptions(tempDocument.contact).filter(
        (ele: any) => ele?.value?.toLowerCase() === dosState?.toLowerCase()
      );

      let shipToAddress = {
        ...tempDocument.shipTo,
        destinationOfSupply: dos ? dos?.[0]?.value : ''
      };
      const gstType = updateGstType({
        ...tempDocument,
        shipFrom: shipFromAddress,
        shipTo: shipToAddress
      });

      keysToUpdate = {
        shipFrom: shipFromAddress,
        shipTo: shipToAddress,
        gstType: gstType,
        items: tempDocument.items?.map((item: any) => {
          return { ...item, gstType: gstType };
        })
      };
    }

    // Update the store
    Store.dispatch(updateMultipleKeysInDocument({ draftId, keysToUpdate }));

    Store.dispatch(
      calculateTaxesAndAmountsForAllLineItems({
        draftId,
        items: keysToUpdate?.items
      })
    );
  }
};

export const onDestinationOfSupplyChange = (data: {
  draftId: number;
  dosValue: string;
  address?: any;
}) => {
  let keysToUpdate: any = {};
  if (getTenantTaxSystem() === TAX_SYSTEM.INDIA_GST) {
    const { draftId, dosValue, address } = data;
    const oldDocument = getDocumentByIDFromStore(draftId)?.populateFormData;
    let tempDocument: any = { ...oldDocument };
    let shipToAddress: any = {};
    if (!Utility.isEmpty(address)) {
      shipToAddress = {
        ...address,
        destinationOfSupply: dosValue
      };
    } else {
      shipToAddress = {
        ...tempDocument.shipTo,
        destinationOfSupply: dosValue
      };
    }

    let state = !Utility.isEmpty(tempDocument?.shipTo?.placeOfSupply)
      ? tempDocument?.shipTo?.placeOfSupply
      : tempDocument?.shipTo?.state || '';
    let pos = getIndianStateOptions(tempDocument.contact).filter(
      (ele: any) => ele?.value?.toLowerCase() === state?.toLowerCase()
    );

    let shipFromAddress = {
      ...tempDocument.shipFrom,
      placeOfSupply: pos ? pos?.[0]?.value : '',
      destinationOfSupply: dosValue
    };
    const gstType = updateGstType({
      ...tempDocument,
      shipTo: shipToAddress,
      shipFrom: shipFromAddress
    });
    keysToUpdate = {
      shipTo: shipToAddress,
      shipFrom: shipFromAddress,
      placeOfSupply: pos ? pos?.[0]?.value : '',
      gstType: gstType,
      items: tempDocument.items?.map((item: any) => {
        return { ...item, gstType: gstType };
      })
    };

    // Update the store
    Store.dispatch(updateMultipleKeysInDocument({ draftId, keysToUpdate }));

    Store.dispatch(
      calculateTaxesAndAmountsForAllLineItems({
        draftId,
        items: keysToUpdate?.items
      })
    );
  }
};

export const updatePlaceOfSupplyOnAddress = (
  draftId: number,
  address: any,
  isSalesDocument: boolean
) => {
  let pos = getIndianStateOptions().filter(
    (ele: any) =>
      ele.value?.toLowerCase() === (address.state?.toLowerCase() || '')
  );
  let value = pos ? pos?.[0]?.value : '';
  if (isSalesDocument) {
    onPlaceOfSupplyChange({ draftId, posValue: value, address });
  } else {
    onDestinationOfSupplyChange({ draftId, dosValue: value, address });
  }
};

export const updatePlaceOfSupplyOnAddressBuy = (
  draftId: number,
  address: any
) => {
  let pos = getIndianStateOptions().filter(
    (ele: any) =>
      ele?.value?.toLowerCase() === (address?.state?.toLowerCase() || '')
  );
  const posValue = pos ? pos?.[0]?.value : '';
  onPlaceOfSupplyChange({ draftId, posValue, address });
};
