import {
  DOC_TYPE,
  RECORD_SAVED_EVENT_DOC_TYPE
} from '../../Constants/Constant';

export const commonCustomEvent = {
  /**
   * @todo:
   * Need to use this method with direct callbacks only,
   * to avoid attaching listeners multiple times
   */
  on: (ev: string, cb: (data: any) => void, useDirectCallback?: boolean) => {
    if (useDirectCallback) {
      document.addEventListener(ev, cb);
    } else {
      document.addEventListener(ev, (data: any) => cb(data.detail));
    }
  },
  dispatch: (ev: string, data: any) => {
    document.dispatchEvent(new CustomEvent(ev, { detail: data }));
  },
  remove: (ev: string, cb: (data: any) => void) => {
    document.removeEventListener(ev, cb);
  }
};

export const COMMON_EVENTS = {
  ADD_NEW_REC: 'addNewRec',
  NAVIGATION_TRIGGERED: 'onNavigationTriggered',
  SUBSCRIPTION_CHECK: 'onAuthorizationCheckStateChanged',
  ERROR_OCCURRED: 'onErrorOccurred',
  RECORD_SAVED: 'onRecordSaved',
  DOC_POPUP_SHOW: 'onDocPopupShow',
  UPDATE_DOC_WITHOUT_CLOSING: 'updateDocWithoutClosing'
};

export enum DOC_POPUP_TYPE {
  NONE,
  SHOW_CREATE_PRODUCT_POPUP,
  SHOW_CREATE_TAX_POPUP,
  SHOW_HSN_SAC_POPUP,
  SHOW_TDS_CALCULATION_POPUP,
  SHOW_ITC_INFO_POPUP,
  SHOW_PRODUCT_GROUPING_POPUP,
  SHOW_TAX_GROUP_DETAILS_POPUP_UK,
  SHOW_BARCODE_SEARCH_POPUP,
  SHOW_PAYMENT_MILESTONE_POPUP,
  SHOW_RESERVE_STOCK_POPUP,
  SHOW_PRODUCT_CUSTOM_FIELD,
  SHOW_TAX_EXCHANGE_RATE_POPUP,
  SHOW_ADD_CLASS_POPUP,
  SHOW_LANDED_COST_POPUP,
  SHOW_E_INVOICE_POPUP,
  SHOW_IRP_CRED_POPUP,
  SHOW_ADD_CONTACT_POPUP,
  SHOW_GST_VALUE_FORM_POPUP,
  SHOW_TAX_AMOUNT_VALUE_FORM_POPUP,
  SHOW_PEPPOL_INVOICENOW_POPUP
}
export interface IRecordSavedEventData {
  id: string | null;
  type: RECORD_SAVED_EVENT_DOC_TYPE;
  linkedDocId?: string;
  linkedDocType: DOC_TYPE | RECORD_SAVED_EVENT_DOC_TYPE;
  isEdit: boolean;
}
