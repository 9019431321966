import { TableColumnPopupType } from "../../../Constants/Constants";
import { getIsShowTableColumn } from "../../../Utilities/Utility";

export class StockIssueTableWidth {
    isLineNumberVisible: boolean;
    isCodeVisible: boolean;
    isNameVisible: boolean;
    isDescriptionVisible: boolean;
    isNameWithDescriptionVisible: boolean;
    isRequiredQtyVisible: boolean;
    isIssuedQtyVisible: boolean;
    isSourceWarehouseNameVisible: boolean;
    isSourceWarehouseCodeVisible: boolean;
    isTargetWarehouseNameVisible: boolean;
    isTargetWarehouseCodeVisible: boolean;
    isSerialBatchVisible: boolean;
    isUomVisible: boolean;
    isProductCustomFieldVisible: boolean; 

    lineNumber: number;
    code: number;
    name: number;
    description: number;
    nameWithDescription: number;
    requiredQty: number;
    issuedQty: number;
    serialBatch: number;
    sourceWarehouseName: number;
    sourceWarehouseCode: number;
    targetWarehouseName: number;
    targetWarehouseCode: number;
    uom: number;
    productCustomField: number;

    originalLineNumber: number;
    originalCode: number;
    originalName: number;
    originalDescription: number;
    originalNameWithDescription: number;
    originalRequiredQty: number;
    originalIssuedQty: number;
    originalSerialBatch: number;
    originalSourceWarehouseName: number;
    originalSourceWarehouseCode: number;
    originalTargetWarehouseName: number;
    originalTargetWarehouseCode: number;
    originalUom: number;
    originalProductCustomField: number;

    productCustomFieldList: any;

    constructor() {
        this.isLineNumberVisible = true
        this.isCodeVisible = true
        this.isNameVisible = true
        this.isDescriptionVisible = true
        this.isNameWithDescriptionVisible = true 
        this.isRequiredQtyVisible = true
        this.isIssuedQtyVisible = true
        this.isSerialBatchVisible = true
        this.isSourceWarehouseNameVisible = true
        this.isSourceWarehouseCodeVisible = true
        this.isTargetWarehouseNameVisible = true
        this.isTargetWarehouseCodeVisible = true
        this.isUomVisible = true
        this.isProductCustomFieldVisible = true 


        this.lineNumber = 5
        this.code = 10
        this.name = 15
        this.description = 15
        this.nameWithDescription = this.name + this.description
        this.requiredQty = 5
        this.issuedQty = 5
        this.serialBatch = 10
        this.sourceWarehouseName = 10
        this.sourceWarehouseCode = 5
        this.targetWarehouseName = 10
        this.targetWarehouseCode = 5
        this.uom = 5
        this.productCustomField = 5

        this.originalLineNumber = 5
        this.originalCode = 10
        this.originalName = 15
        this.originalDescription = 15
        this.originalNameWithDescription = this.originalName + this.originalDescription
        this.originalRequiredQty = 5
        this.originalIssuedQty = 5
        this.originalSerialBatch = 10
        this.originalSourceWarehouseName = 10
        this.originalSourceWarehouseCode = 5
        this.originalTargetWarehouseName = 10
        this.originalTargetWarehouseCode = 5
        this.originalUom = 5
        this.originalProductCustomField = 5

        this.productCustomFieldList = undefined

    }

    setIsShowWidth(columnData: any) {
        this.isLineNumberVisible = getIsShowTableColumn(columnData, TableColumnPopupType.lineNumber)
        this.isCodeVisible = getIsShowTableColumn(columnData, TableColumnPopupType.code)
        this.isNameVisible = getIsShowTableColumn(columnData, TableColumnPopupType.productName)
        this.isDescriptionVisible = getIsShowTableColumn(columnData, TableColumnPopupType.description)
        this.isNameWithDescriptionVisible = getIsShowTableColumn(columnData, TableColumnPopupType.productNameWithDescription)
        this.isProductCustomFieldVisible = getIsShowTableColumn(columnData, TableColumnPopupType.productCustomField)
        this.isRequiredQtyVisible = getIsShowTableColumn(columnData, TableColumnPopupType.requiredQty)
        this.isIssuedQtyVisible = getIsShowTableColumn(columnData, TableColumnPopupType.issuedQty)
        this.isSerialBatchVisible = getIsShowTableColumn(columnData, TableColumnPopupType.serialBatch)
        this.isSourceWarehouseNameVisible = getIsShowTableColumn(columnData, TableColumnPopupType.sourceWarehouseName)
        this.isSourceWarehouseCodeVisible = getIsShowTableColumn(columnData, TableColumnPopupType.sourceWarehouseCode)
        this.isTargetWarehouseNameVisible = getIsShowTableColumn(columnData, TableColumnPopupType.targetWarehouseName)
        this.isTargetWarehouseCodeVisible = getIsShowTableColumn(columnData, TableColumnPopupType.targetWarehouseCode)
        this.isUomVisible = getIsShowTableColumn(columnData, TableColumnPopupType.uom)

        var width = 5
        var maxWidth = 20

        var productCustomFieldList = columnData.filter((x: any) => x.code !== undefined)
        if (productCustomFieldList.length > 0) {
            this.isProductCustomFieldVisible = false
            if (this.productCustomFieldList === undefined) {
                //assign product custom list with corresponding flag
                this.productCustomFieldList = []
                if (productCustomFieldList.length > 4) {
                    width = maxWidth / productCustomFieldList.length
                }
                productCustomFieldList.forEach((element: any) => {
                    var item = {} as any
                    item.code = element.code
                    item.isVisible = element.columnOption.isShowColumn
                    item.originalWidth = width
                    item.width = width
                    item.type = element.name
                    item.label = element.label
                    this.productCustomFieldList.push(item)
                });
            }
            else {
                this.productCustomFieldList.forEach((element: any) => {
                    var item = columnData.find((x: any) => x.code === element.code)
                    if (item !== undefined) {
                        element.isVisible = item.columnOption.isShowColumn
                    }
                });
            }
        }
    }

    getTableHeaderWidths() {
        var totalWidth = 0
        totalWidth = this.getTotalWidth(totalWidth);
        if (totalWidth === 100) {
            this.resetElementOriginalWidth();
        }
        else if (totalWidth === 0) {
            this.resetElementWidth();
        }
        else {
            var totalSelectedElement = this.getTotalSelectedElement()
            if (totalSelectedElement !== 0) {
                var totalOffset = (100 - totalWidth) / totalSelectedElement
                this.updateElementWidth(totalOffset)
            }
        }
    }

    private resetElementOriginalWidth() {
        this.lineNumber = this.originalLineNumber
        this.code = this.originalCode
        this.name = this.originalName
        this.description = this.originalDescription
        this.nameWithDescription = this.originalNameWithDescription

        this.requiredQty = this.originalRequiredQty
        this.issuedQty = this.originalIssuedQty
        this.serialBatch = this.originalSerialBatch
        this.sourceWarehouseName = this.originalSourceWarehouseName
        this.sourceWarehouseCode = this.originalSourceWarehouseCode
        this.targetWarehouseName = this.originalTargetWarehouseName
        this.targetWarehouseCode = this.originalTargetWarehouseCode
        this.uom = this.originalUom
        this.productCustomField = 5

        this.productCustomField = this.originalProductCustomField

        if (this.productCustomFieldList !== undefined) {
            this.productCustomFieldList.forEach((element: any) => {
                element.width = element.originalWidth
            });
        }
    }

    private resetElementWidth() {
        this.lineNumber = 0
        this.code = 0
        this.name = 0
        this.description = 0
        this.nameWithDescription = 0
        this.requiredQty = 0
        this.issuedQty = 0
        this.serialBatch = 0
        this.sourceWarehouseName = 0
        this.sourceWarehouseCode = 0
        this.targetWarehouseName = 0
        this.targetWarehouseCode = 0
        this.uom = 0

        if (this.productCustomFieldList !== undefined) {
            this.productCustomFieldList.forEach((element: any) => {
                element.width = 0
            });
        }
    }

    private getTotalWidth(totalWidth: number) {
        if (this.isLineNumberVisible) {
            totalWidth += this.lineNumber;
        }
        if (this.isCodeVisible) {
            totalWidth += this.code;
        }
        if (this.isNameVisible) {
            totalWidth += this.name;
        }
        if (this.isDescriptionVisible) {
            totalWidth += this.description;
        }
        if (this.isNameWithDescriptionVisible) {
            totalWidth += this.nameWithDescription;
        }
        if (this.isRequiredQtyVisible) {
            totalWidth += this.requiredQty;
        }
        if (this.isIssuedQtyVisible) {
            totalWidth += this.issuedQty;
        }
        if (this.isSerialBatchVisible) {
            totalWidth += this.serialBatch;
        }
        if (this.isSourceWarehouseNameVisible) {
            totalWidth += this.sourceWarehouseName;
        }
        if (this.isSourceWarehouseCodeVisible) {
            totalWidth += this.sourceWarehouseCode;
        }
        if (this.isTargetWarehouseNameVisible) {
            totalWidth += this.targetWarehouseName;
        }
        if (this.isTargetWarehouseCodeVisible) {
            totalWidth += this.targetWarehouseCode;
        }
        if (this.isProductCustomFieldVisible) {
            totalWidth += this.productCustomField;
        }
        if (this.isUomVisible) {
            totalWidth += this.uom;
        }

        if (this.productCustomFieldList !== undefined) {
            this.productCustomFieldList.forEach((element: any) => {
                if (element.isVisible) {
                    totalWidth += element.width;
                }
            });
        }
        return totalWidth;
    }

    private getTotalSelectedElement() {
        var totalSelectedElement = 0
        if (this.isLineNumberVisible) {
            totalSelectedElement += 1
        }
        if (this.isCodeVisible) {
            totalSelectedElement += 1
        }
        if (this.isNameVisible) {
            totalSelectedElement += 1
        }
        if (this.isDescriptionVisible) {
            totalSelectedElement += 1
        }
        if (this.isNameWithDescriptionVisible) {
            totalSelectedElement += 1
        }
        if (this.isRequiredQtyVisible) {
            totalSelectedElement += 1
        }
        if (this.isIssuedQtyVisible) {
            totalSelectedElement += 1
        }
        if (this.isSerialBatchVisible) {
            totalSelectedElement += 1
        }
        if (this.isSourceWarehouseNameVisible) {
            totalSelectedElement += 1
        }
        if (this.isSourceWarehouseCodeVisible) {
            totalSelectedElement += 1
        }
        if (this.isTargetWarehouseNameVisible) {
            totalSelectedElement += 1
        }
        if (this.isTargetWarehouseCodeVisible) {
            totalSelectedElement += 1
        }
        if (this.isProductCustomFieldVisible) {
            totalSelectedElement += 1
        }
        if (this.isUomVisible) {
            totalSelectedElement += 1
        }

        if (this.productCustomFieldList !== undefined) {
            this.productCustomFieldList.forEach((element: any) => {
                if (element.isVisible) {
                    totalSelectedElement += 1
                }
            });
        }

        return totalSelectedElement
    }

    private updateElementWidth(additionalWidth: number) {
        if (this.isLineNumberVisible) {
            this.lineNumber = this.originalLineNumber + additionalWidth
        }
        if (this.isCodeVisible) {
            this.code = this.originalCode + additionalWidth
        }
        if (this.isNameVisible) {
            this.name = this.originalName + additionalWidth
        }
        if (this.isDescriptionVisible) {
            this.description = this.originalDescription + additionalWidth
        }
        if (this.isNameWithDescriptionVisible) {
            this.nameWithDescription = this.originalNameWithDescription + additionalWidth
        }
        if (this.isRequiredQtyVisible) {
            this.requiredQty = this.originalRequiredQty + additionalWidth
        }
        if (this.isIssuedQtyVisible) {
            this.issuedQty = this.originalIssuedQty + additionalWidth
        }
        if (this.isSerialBatchVisible) {
            this.serialBatch = this.originalSerialBatch + additionalWidth
        }
        if (this.isSourceWarehouseNameVisible) {
            this.sourceWarehouseName = this.originalSourceWarehouseName + additionalWidth
        }
        if (this.isSourceWarehouseCodeVisible) {
            this.sourceWarehouseCode = this.originalSourceWarehouseCode + additionalWidth
        }
        if (this.isTargetWarehouseNameVisible) {
            this.targetWarehouseName = this.originalTargetWarehouseName + additionalWidth
        }
        if (this.isTargetWarehouseCodeVisible) {
            this.targetWarehouseCode = this.originalTargetWarehouseCode + additionalWidth
        }
        if (this.isProductCustomFieldVisible) {
            this.productCustomField = this.originalProductCustomField + additionalWidth
        }
        if (this.isUomVisible) {
            this.uom = this.originalUom + additionalWidth
        }

        if (this.productCustomFieldList !== undefined) {
            this.productCustomFieldList.forEach((element: any) => {
                if (element.isVisible) {
                    element.width = element.originalWidth + additionalWidth
                }
            });
        }
    }

    getProductCustomFieldWidthByCode(code: string) {
        var item = this.productCustomFieldList.find((x: any) => x.code === code)
        if (item !== undefined) {
            return item.width
        }
        return undefined
    }
}