import React, { Component } from 'react'
import TemplateSettingsManager from '../Manager/TemplateSettingsManager'
import { getLocalisedText } from '../Translate/LanguageManager'
import { HTMLTag } from '../Utilities/HTMLTag'
import { getTextAlign } from './dktable/TableUtility'
import { ApprovalSectionType } from '../Utilities/ApprovalSectionUtility'
import { FONT_SIZE } from '../Constants/Constants'
import Utility, { getVW } from '../Utilities/Utility'

export default class DKApprovalSection extends Component {

    render() {
        var approvalSectionSettings = TemplateSettingsManager.getApprovalSectionSettings()
        var approvalList = approvalSectionSettings?.elements ?? []
        const border = approvalSectionSettings?.tableBorder ?? 'hide'
        approvalSectionSettings.isShowBorder = border === 'hide' ? false : true
        approvalSectionSettings.width = 100

        if(approvalList.length > 0) {
            approvalList = approvalList.filter(x => x.isSelected)
            approvalSectionSettings.width = approvalSectionSettings.width / approvalList.length
        }

        if (approvalList.length === 0) {
            return <></>
        }
        else {
            return this.renderApprovalTable(approvalList, approvalSectionSettings)
        }
    }

    renderApprovalTable(approvalList, approvalSectionSettings) {
        var cells = approvalList.map(element => {
            return this.renderApprovalTableCell(element, approvalSectionSettings)
        });

        return <div id={HTMLTag.APPROVAL_TABLE} style={{
            paddingTop: this.getIsUsingPadding() ? getVW(Utility.convertToPrintScale(5, this.props.data.printScale)) : undefined,
            paddingBottom: this.getIsUsingPadding() ? getVW(Utility.convertToPrintScale(5, this.props.data.printScale)) : undefined,
        }}>
            <table className='doc-container'>
                <tbody style={{
                    border: this.getBorder(approvalSectionSettings),
                }}>
                    <tr className='row-cell'>
                        {cells}
                    </tr>
                </tbody>
            </table>
        </div>
    }

    getApprovalItemFormat(item, approvalSectionSettings) {
        let htmlString = ''
        const value = this.getItemValue(item) ?? ''

        let position = approvalSectionSettings?.position ?? 'bottom'
        const defaultLineCount = approvalSectionSettings?.lineSpace ?? 0
        let lineCount = defaultLineCount

        if (defaultLineCount > 0 && value !== '') {
            lineCount --
        }

        const newLineBreak = new Array(lineCount).fill('<br/>')

        if (position === 'top') {
            htmlString += getLocalisedText(item.label)
            htmlString += '<br/>'
            htmlString += newLineBreak.join('')
            if(value !== '') {
                htmlString += value
            }
        }
        else if (position === 'bottom') {
            if (value !== '') {
                htmlString += value + '<br/>'
            }
            htmlString += newLineBreak.join('')
            htmlString += getLocalisedText(item.label)
        }

        return htmlString
    }

    renderApprovalTableCell(item, approvalSectionSettings) {
        return (
            <td className='cell' style={{
                textAlign: getTextAlign(TemplateSettingsManager.getApprovalSectionSettings()?.textAlign),
                border: this.getBorder(approvalSectionSettings),
                color: this.getHeaderColor(),
                width: approvalSectionSettings.width.toFixed(0) + '%',
                ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular }, this.props.data.printScale),
                paddingLeft: getVW(Utility.convertToPrintScale(5, this.props.data.printScale)),
                paddingRight: getVW(Utility.convertToPrintScale(5, this.props.data.printScale)),
            }}>
                <div
                    dangerouslySetInnerHTML={{ __html: this.getApprovalItemFormat(item, approvalSectionSettings) }}
                />
            </td>
        )
    }

    getIsTransparent(color) {
        return color === 'transparent' || color === '#ffffff' || color === 'white' 
    }
    
    getBorder(approvalSectionSettings) {
        if (approvalSectionSettings.isShowBorder) {
            const itemBorderColor = this.props.style.itemBorderColor 
            const headerBorderColor = this.props.style.headerBorderColor
            const headerBackgroundColor = this.props.style.headerBackgroundColor
            let borderColor = itemBorderColor
            if (headerBorderColor === itemBorderColor && headerBorderColor === headerBackgroundColor) {
                borderColor = 'black'
            }
            if (this.getIsTransparent(itemBorderColor)) {
                borderColor = headerBorderColor

                if (this.getIsTransparent(headerBorderColor)) {
                    borderColor = headerBackgroundColor
                }
            }
            return '1px solid ' + borderColor
        }
        
        return undefined
    }

    getItemValue(item) {
        switch (item.type) {
            case ApprovalSectionType.createdBy:
                return this.props.data.createdBy ?? ''
            case ApprovalSectionType.checkedBy:
                return ''
            case ApprovalSectionType.issueBy:
                return this.props.data.stockRequestCreatedBy ?? ''
            case ApprovalSectionType.authorizedSignatory:
                let name = this.props.data.companyName ?? ''
                if(name !== '') {
                    name = getLocalisedText(name)
                }
                return name
            case ApprovalSectionType.preparedBy:
                return this.props.data.createdBy ?? ''
            case ApprovalSectionType.authorizedBy:
                return this.props.data.approvedBy ?? ''
            default:
                return ''
        }
    }

    getHeaderColor() {
        if (this.props.isThemeColor) {
            return this.props.data.themeColor
        }

        if (this.props.headerColor !== undefined) {
            return this.props.headerColor
        }

        return undefined
    }


    getIsUsingPadding() {
        return !(this.props.style?.noPadding ?? false)
    }
}
