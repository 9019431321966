import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { shallowEqual } from 'react-redux';
import { DKButton, DKLabel, DKSpinner, showAlert } from 'deskera-ui-library';
import { useAppDispatch, useAppSelector } from '../../../../Redux/Hooks';
import {
  addNewItemToDocument,
  onDocumentClose,
  onDocumentViewChanged,
  selectColumns,
  selectDocumentById,
  selectDocumentFormDataByKeys,
  selectDocumentMetaDataByKeys
} from '../../../../Redux/Slices/DocumentSlice';
import { CommonDraftPropsContext } from '../../Utilities/DocContext';
import { AnyDocument, DraftTypes, IDocument } from '../../../../Models/Drafts';
import { useTranslation } from 'react-i18next';
import {
  DOC_PATH_WITH_ID_REGEX,
  DOC_TYPE,
  DOCUMENT_MODE,
  LABELS,
  POPUP_CALLBACKS_TYPE,
  POPUP_CLICK_TYPE,
  POPUP_TYPE,
  TAX_SYSTEM
} from '../../../../Constants/Constant';
import {
  checkGSTINPresentForSelectedContact,
  getLogButton,
  getTenantTaxSystem
} from '../../../../SharedComponents/DocumentForm/NewDocumentHelper';
import ic_minus from '../../../../Assets/Icons/ic_minus.png';
import ic_expand from '../../../../Assets/Icons/ic_expand.svg';
import ic_shrink from '../../../../Assets/Icons/ic_shrink.svg';
import {
  selectProductCustomFields,
  selectShowMainDocsInFullScreen,
  setShowMainDocsInFullScreen
} from '../../../../Redux/Slices/CommonDataSlice';
import {
  DOCUMENT_KEYS,
  DOCUMENT_META_KEYS
} from '../../Utilities/DocConstants';
import { useHistory } from 'react-router-dom';
import { PAGE_ROUTES } from '../../../../Managers/RouteManager';
import ActionBarSaveButton from './ActionBarSaveButton';
import TransactionLog from '../../../../SharedComponents/TransactionLogs';
import {
  DOC_SAVE_OPTION,
  getDraftBadges,
  setCanValidateDocument,
  setIsSavingDocument
} from '../../Helper/View/ActionBarHelper';
import { activeTenantInfo } from '../../../../Redux/Slices/AuthSlice';
import { fetchapprovalConditionList } from '../../../../Redux/Slices/AutomationSlice';
import { QuoteSaver } from '../../Helper/Save/QuoteSaver';
import DraftService from '../../../../Services/Drafts';
import { draftTableId } from '../../../../Redux/Slices/DraftsSlice';
import { getDocumentByIDFromStore } from '../../Helper/DocumentHelper';
import { getBlankRowItem } from '../../Utilities/DocCommonUtils';
import { CONTACT_FORM_TAB } from '../../../../Constants/Enum';
import DocAddContactPopup from '../Common/DocAddContactPopup';
import { checkUserPermission } from '../../../Settings/GranularPermissions/GranularPermissionsHelper';
import { PERMISSIONS_BY_MODULE } from '../../../../Constants/Permission';
import { onDocContactChange } from '../../Helper/DocumentUpdates/ContactChangeHelper';
import { InvoiceSaver } from '../../Helper/Save/InvoiceSaver';
import PopupWrapper from '../../../../SharedComponents/PopupWrapper';
import { localizedText } from '../../../../Services/Localization/Localization';
import Fulfillment from '../../../../SharedComponents/FulfillmentPopup/Fulfillment';
import {
  BtnType,
  CallBackPayloadType,
  PopupClickActionType,
  UpdateCallBacksRefType
} from '../../../../Models/Interfaces';
import { fetchInvoices } from '../../../../Redux/Slices/InvoicesSlice';
import Utility from '../../../../Utility/Utility';
import { SalesOrderSaver } from '../../Helper/Save/SalesOrderSaver';
import { PurchaseOrderSaver } from '../../Helper/Save/PurchaseOrderSaver';
import { BillSaver } from '../../Helper/Save/BillSaver';
import InvoiceService from '../../../../Services/Invoice';
import {
  COMMON_EVENTS,
  commonCustomEvent
} from '../../../../Services/event/commonEvents';

const ActionBar = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const showMainDocsInFullscreen = useAppSelector(
    selectShowMainDocsInFullScreen
  );
  const { draftId, draftType, documentMode, isCashInvoice } = useContext(
    CommonDraftPropsContext
  );
  const [title, type, isCenterAlign, isLoading, canValidate, hideMinimizer] =
    useAppSelector(
      selectDocumentMetaDataByKeys(draftId, [
        DOCUMENT_META_KEYS.TITLE,
        DOCUMENT_META_KEYS.TYPE,
        DOCUMENT_META_KEYS.IS_CENTER_ALIGN,
        DOCUMENT_META_KEYS.IS_LOADING,
        DOCUMENT_META_KEYS.CAN_VALIDATE,
        DOCUMENT_META_KEYS.HIDE_MINIMIZER
      ]),
      shallowEqual
    );
  const [
    contact,
    documentType,
    documentCode,
    fulfillmentStatus,
    receiptStatus,
    receiveGoodsStatus,
    paymentStatus,
    approvalStatus,
    items,
    isConverting,
    shipTo,
    shipFrom,
    isDocumentTouched,
    autoCreatePOFromWO,
    memo
  ] = useAppSelector(
    selectDocumentFormDataByKeys(draftId, [
      DOCUMENT_KEYS.CONTACT,
      DOCUMENT_KEYS.DOCUMENT_TYPE,
      DOCUMENT_KEYS.DOCUMENT_CODE,
      DOCUMENT_KEYS.FULFILLMENT_STATUS,
      DOCUMENT_KEYS.RECEIPT_STATUS,
      DOCUMENT_KEYS.RECEIVE_GOODS_STATUS,
      DOCUMENT_KEYS.PAYMENT_STATUS,
      DOCUMENT_KEYS.APPROVAL_STATUS,
      DOCUMENT_KEYS.ITEMS,
      DOCUMENT_KEYS.IS_CONVERTING,
      DOCUMENT_KEYS.SHIP_TO,
      DOCUMENT_KEYS.SHIP_FROM,
      DOCUMENT_KEYS.IS_DOC_TOUCHED,
      DOCUMENT_KEYS.AUTO_CREATE_PO_FROM_WO,
      DOCUMENT_KEYS.MEMO
    ]),
    shallowEqual
  );

  const document: any = getDocumentByIDFromStore(draftId) ?? {};

  const dispatch = useAppDispatch();
  const tenantInfo = useAppSelector(activeTenantInfo);
  const draftToSave = useAppSelector(selectDocumentById(draftId));
  const draftsTableId = useAppSelector(draftTableId);
  const productCFData = useAppSelector(selectProductCustomFields);
  const columns = useAppSelector(selectColumns());
  const refInitialState: UpdateCallBacksRefType = {
    pushDataToParent: { type: POPUP_CALLBACKS_TYPE.NONE },
    storeCallbacksRef: {}
  };
  const fulfillmentRef = useRef<UpdateCallBacksRefType>(refInitialState);

  const [isErrorOnSave, setIsErrorOnSave] = useState(false);
  const [showTransactionLog, setShowTransactionLog] = useState(false);
  const [transactionData, setTransactionData] = useState<any>();
  const [needAddContactPopup, setNeedAddContactPopup] = useState(false);
  const [showFulfillmentPopup, setShowFulfillmentPopup] =
    useState<boolean>(false);
  const [docForFulfillment, setDocForFulfillment] = useState<any>();

  const isLogsButtonVisible = getLogButton({ draftType, type });
  const isProductGroupingEnabled =
    tenantInfo?.additionalSettings?.PRODUCT_GROUP_ENABLED;

  const approvalConditionsFetched = useRef<boolean>(false);

  /**
   * Reset the URL to module URL when draft is closed
   */
  const replaceURLToModuleURL = useCallback(() => {
    switch (documentType) {
      case DOC_TYPE.INVOICE:
        if (
          DOC_PATH_WITH_ID_REGEX.test(history.location?.pathname) &&
          history.location?.pathname?.includes(PAGE_ROUTES.INVOICES)
        ) {
          history.replace(PAGE_ROUTES.INVOICES);
        }
        break;
      case DOC_TYPE.QUOTE:
        if (
          DOC_PATH_WITH_ID_REGEX.test(history.location?.pathname) &&
          history.location?.pathname?.includes(PAGE_ROUTES.QUOTES)
        ) {
          history.replace(PAGE_ROUTES.QUOTES);
        }
        break;
      case DOC_TYPE.SALES_ORDER:
        if (
          DOC_PATH_WITH_ID_REGEX.test(history.location?.pathname) &&
          history.location?.pathname?.includes(PAGE_ROUTES.SALES_ORDERS)
        ) {
          history.replace(PAGE_ROUTES.SALES_ORDERS);
        }
        break;
      case DOC_TYPE.ORDER:
        if (
          DOC_PATH_WITH_ID_REGEX.test(history.location?.pathname) &&
          history.location?.pathname?.includes(PAGE_ROUTES.ORDERS)
        ) {
          history.replace(PAGE_ROUTES.ORDERS);
        }
        break;
      case DOC_TYPE.BILL:
        if (
          DOC_PATH_WITH_ID_REGEX.test(history.location?.pathname) &&
          history.location?.pathname?.includes(PAGE_ROUTES.BILLS)
        ) {
          history.replace(PAGE_ROUTES.BILLS);
        }
        break;
      case DOC_TYPE.JOB_WORK_OUT_ORDER:
        if (
          DOC_PATH_WITH_ID_REGEX.test(history.location?.pathname) &&
          history.location?.pathname?.includes(PAGE_ROUTES.MRP_WORK_OUT)
        ) {
          history.replace(PAGE_ROUTES.MRP_WORK_OUT);
        }
        break;

      default:
        break;
    }
  }, [documentType, history]);

  const handleSaveButtonClick = useCallback(
    (option: DOC_SAVE_OPTION, closeOnUpdate = true) => {
      setIsSavingDocument(draftId, true);
      if (draftToSave) {
        switch (draftToSave.type) {
          case LABELS.QUOTES:
            const quoteSaver = new QuoteSaver({
              draftToSave,
              documentMode,
              saveOption: option,
              tenantInfo
            });
            quoteSaver.replaceURLCallback = replaceURLToModuleURL;
            if (option === DOC_SAVE_OPTION.SAVE_AS_DRAFT) {
              quoteSaver.saveAsDraft(true);
            } else {
              quoteSaver.save(closeOnUpdate);
            }
            break;
          case LABELS.SALES_ORDER:
            const salesOrderSaver = new SalesOrderSaver({
              draftToSave,
              documentMode,
              saveOption: option,
              tenantInfo
            });
            salesOrderSaver.replaceURLCallback = replaceURLToModuleURL;
            if (option === DOC_SAVE_OPTION.SAVE_AS_DRAFT) {
              salesOrderSaver.saveAsDraft();
            } else {
              salesOrderSaver.save(closeOnUpdate);
            }
            break;
          case LABELS.INVOICES:
            const invoiceSaver = new InvoiceSaver({
              draftToSave,
              documentMode,
              isCashInvoice: !!isCashInvoice,
              saveOption: option,
              tenantInfo
            });
            invoiceSaver.replaceURLCallback = replaceURLToModuleURL;
            invoiceSaver.openFulfillmentPopup = openFulfillmentPopup;
            if (option === DOC_SAVE_OPTION.SAVE_AS_DRAFT) {
              invoiceSaver.saveAsDraft();
            } else {
              invoiceSaver.save(closeOnUpdate);
            }
            break;
          case LABELS.PURCHASE_ORDERS:
            const poSaver = new PurchaseOrderSaver({
              draftToSave,
              documentMode,
              saveOption: option,
              tenantInfo
            });
            poSaver.replaceURLCallback = replaceURLToModuleURL;
            if (option === DOC_SAVE_OPTION.SAVE_AS_DRAFT) {
              poSaver.saveAsDraft();
            } else {
              poSaver.save(closeOnUpdate);
            }
            break;
          case LABELS.BILLS:
            const billSaver = new BillSaver({
              draftToSave,
              documentMode,
              saveOption: option,
              tenantInfo
            });
            billSaver.replaceURLCallback = replaceURLToModuleURL;
            if (option === DOC_SAVE_OPTION.SAVE_AS_DRAFT) {
              billSaver.saveAsDraft();
            } else {
              billSaver.save(closeOnUpdate);
            }
            break;

          default:
            break;
        }
      }
    },
    [
      documentMode,
      draftId,
      draftToSave,
      isCashInvoice,
      replaceURLToModuleURL,
      tenantInfo
    ]
  );

  useEffect(() => {
    const handleUpdateDocWithoutClosing = () => {
      handleSaveButtonClick(DOC_SAVE_OPTION.SAVE, false);
    };

    commonCustomEvent.on(
      COMMON_EVENTS.UPDATE_DOC_WITHOUT_CLOSING,
      handleUpdateDocWithoutClosing,
      true
    );
    return () => {
      commonCustomEvent.remove(
        COMMON_EVENTS.UPDATE_DOC_WITHOUT_CLOSING,
        handleUpdateDocWithoutClosing
      );
    };
  }, [handleSaveButtonClick]);

  useEffect(() => {
    if (!approvalConditionsFetched.current) {
      dispatch(fetchapprovalConditionList());
      approvalConditionsFetched.current = true;
    }
  }, [dispatch]);

  const fulfillmentPopupBtnConfig: BtnType[] = [
    {
      title: t(`INVOICES.DIALOG.DIRECT_FULFILLMENT_OF_INVOICES.BUTTON.CANCEL`),
      class: 'border-m mr-s',
      clickAction: POPUP_CLICK_TYPE.CLOSE_POPUP
    },
    {
      title: t(
        `INVOICES.DIALOG.DIRECT_FULFILLMENT_OF_INVOICES.BUTTON.FULLFILL`
      ),
      class: 'bg-app text-white mr-ss',
      clickAction: POPUP_CLICK_TYPE.FULFILLMENT
    }
  ];

  const catchClicks = (data: PopupClickActionType) => {
    switch (data.type) {
      case POPUP_CLICK_TYPE.CLOSE_POPUP:
        if (showFulfillmentPopup) {
          setShowFulfillmentPopup(false);
          dispatch(onDocumentClose({ draftId }));
          dispatch(fetchInvoices());
        }
        break;
      case POPUP_CLICK_TYPE.FULFILLMENT:
        fulfillmentRef.current?.storeCallbacksRef.fulfillment();
        break;
    }
  };

  const parentChildInteraction = (passingData: CallBackPayloadType) => {
    switch (passingData.type) {
      case POPUP_CALLBACKS_TYPE.FULFILLMENT_SUCCESS:
        if (showFulfillmentPopup) {
          setShowFulfillmentPopup(false);
          dispatch(onDocumentClose({ draftId }));
        }
        break;
      case POPUP_CALLBACKS_TYPE.FULFILLMENT:
        fulfillmentRef.current.storeCallbacksRef.fulfillment = passingData.data;
        break;
    }
  };

  // Handle validations when save button is clicked
  if (canValidate) {
    if (
      isProductGroupingEnabled &&
      documentType !== DOC_TYPE.JOB_WORK_OUT_ORDER
    ) {
      if (!items.length) {
        showAlert(
          'No products found',
          'Please add at least one product to proceed'
        );
      }
    } else {
      if (!items.length) {
        dispatch(
          addNewItemToDocument({
            draftId,
            item: getBlankRowItem(
              document,
              JSON.parse(columns),
              documentMode,
              draftType,
              productCFData?.content ?? []
            )
          })
        );
      }
    }

    if (
      documentType === DOC_TYPE.BILL &&
      getTenantTaxSystem() === TAX_SYSTEM.INDIA_GST &&
      (!shipTo?.state || !shipFrom?.state)
    ) {
      showAlert(
        'Missing Information!',
        'Missing "State" information in "Ship To" or "Ship From" address.'
      );
    } else if (
      !checkGSTINPresentForSelectedContact(document?.populateFormData)
    ) {
      let alertButtonConfig: any[] = [
        {
          title: 'Ok',
          className: 'bg-button text-white mt-r',
          onClick: () => {
            setCanValidateDocument(draftId, false);
          }
        }
      ];
      if (checkUserPermission(PERMISSIONS_BY_MODULE.CONTACTS.EDIT)) {
        alertButtonConfig = [
          {
            title: 'Cancel',
            className: 'bg-gray2 border-m mr-s mt-r',
            onClick: () => {
              setCanValidateDocument(draftId, false);
            }
          },
          {
            title: 'Update',
            className: 'bg-button text-white mt-r',
            onClick: () => {
              setCanValidateDocument(draftId, false);
              setNeedAddContactPopup(true);
            }
          }
        ];
      }

      showAlert(
        'GSTIN required',
        'Please add GSTIN in contact form.',
        alertButtonConfig
      );
    }
  }

  const isApprovalRequired = () => {
    if (approvalStatus) {
      if (
        approvalStatus === 'PENDING_FOR_APPROVAL' ||
        approvalStatus === 'IN_PROCESS'
      ) {
        return true;
      }
    }
    return false;
  };

  // Fullscreen toggle button
  const getShrinkExpandButton = () => {
    return (
      <DKButton
        icon={showMainDocsInFullscreen ? ic_shrink : ic_expand}
        onClick={() => {
          dispatch(setShowMainDocsInFullScreen(!showMainDocsInFullscreen));
        }}
        disabled={isLoading}
        className={
          isLoading ? 'border-m text-gray ml-r' : 'bg-white border-m ml-r'
        }
      />
    );
  };

  const LogsButton = () => {
    return (
      <DKButton
        title="Logs"
        onClick={() => {
          setTransactionData({ documentType, documentCode });
          setShowTransactionLog(true);
        }}
        disabled={isLoading}
        className={
          isLoading ? 'border-m text-gray ml-r' : 'bg-white border-m ml-r'
        }
        style={{ maxHeight: 34 }}
      />
    );
  };

  const openFulfillmentPopup = (payload: any) => {
    if (!Utility.isEmpty(payload)) {
      setDocForFulfillment(payload);
      setShowFulfillmentPopup(true);
    }
  };

  const removeDrafts = async (draftdata: IDocument<AnyDocument>) => {
    if (!isLoading) {
      setIsSavingDocument(draftId, true);
      dispatch(onDocumentClose({ draftId }));
      if (draftdata && !draftdata.isSaved) {
        try {
          await DraftService.deleteDraftRecords(
            draftdata.id,
            draftsTableId
          ).catch((err) => {
            console.error('Error deleting draft: ', err);
          });
        } catch (err) {
          console.error('Error deleting draft: ', err);
        }
      }
    }
  };

  const handleCloseButtonClick = () => {
    if (
      draftType === DraftTypes.DRAFT ||
      draftType === DraftTypes.NEW ||
      draftType === DraftTypes.COPY
    ) {
      if (isConverting) {
        dispatch(onDocumentClose({ draftId }));
      } else {
        const documentTouched = autoCreatePOFromWO ?? isDocumentTouched;
        if (
          draftType === DraftTypes.NEW &&
          !documentTouched &&
          draftToSave?.isMaximized
        ) {
          dispatch(onDocumentClose({ draftId }));
        } else {
          handleSaveButtonClick(DOC_SAVE_OPTION.SAVE_AS_DRAFT);
        }
      }
    } else {
      removeDrafts(draftToSave as IDocument<AnyDocument>);
    }
    replaceURLToModuleURL();
  };

  const handeSaveMemoClick = async () => {
    setIsSavingDocument(draftId, true);
    try {
      await InvoiceService.updateMemo(memo, documentCode);
      setIsSavingDocument(draftId, false);
      dispatch(onDocumentClose({ draftId }));
      replaceURLToModuleURL();
    } catch (err: any) {
      console.error('Error updating memo: ', err);
      setIsSavingDocument(draftId, false);
      showAlert('Error!', 'Error updating memo. Please try again.');
    }
  };

  /**
   * Save Memo button only for Invoice in read only mode
   */
  const getSaveMemoButton = () => {
    if (
      draftType === DraftTypes.READONLY &&
      documentType === DOC_TYPE.INVOICE
    ) {
      return (
        <div
          className={`row width-auto border-radius-m justify-content-between ml-r ${
            isLoading ? 'border-m' : 'bg-button'
          } position-relative`}
          style={{ borderColor: isLoading ? '' : 'transparent' }}
        >
          <div className={`row`}>
            <DKButton
              title={isLoading ? 'Saving Memo' : 'Save Memo'}
              onClick={() => {
                handeSaveMemoClick();
              }}
              disabled={isLoading}
              className={`column border-radius-none ${
                isLoading ? 'text-gray' : 'text-white'
              }`}
              style={{
                maxHeight: 34,
                paddingRight: isLoading ? 0 : 12
              }}
            />
            {isLoading && (
              <DKSpinner iconClassName="ic-s" className="column pl-xs pr-m" />
            )}
          </div>
        </div>
      );
    }
    return null;
  };

  return (
    <div className="row justify-content-between p-h-r p-v-s bg-gray1">
      <div className="row pop-header-drag-handle">
        <DKLabel
          text={
            draftType === DraftTypes.READONLY
              ? `${t(`DRAFTS.TITLE.${title}`)} Details`
              : draftType === DraftTypes.UPDATE || draftType === `draft`
              ? 'Edit ' + t(`DRAFTS.TITLE.${title}`)
              : t(`DRAFTS.ADD_NEW`) + t(`DRAFTS.TITLE.${title}`)
          }
          className={`fw-m${isCenterAlign ? ' fs-l' : ' fs-r'}`}
        />
        {getDraftBadges({
          type: type as string,
          draftType: draftType as DraftTypes,
          paymentStatus,
          fulfillmentStatus,
          receiptStatus,
          receiveGoodsStatus
        })}
      </div>
      {/* <div className="row width-auto">{renderDraftViewActions(item)}</div> */}

      {!hideMinimizer ? (
        <DKButton
          icon={ic_minus}
          className={
            isLoading ? 'border-m text-gray ml-r' : 'bg-white border-m ml-r'
          }
          disabled={isLoading}
          onClick={() => {
            dispatch(onDocumentViewChanged({ draftId, isMaximized: false }));
            replaceURLToModuleURL();
          }}
        />
      ) : null}
      {getShrinkExpandButton()}
      {isLogsButtonVisible && <LogsButton />}
      <DKButton
        title="Close"
        className={
          isLoading ? 'border-m text-gray ml-r' : 'bg-white border-m ml-r'
        }
        style={{ maxHeight: 34 }}
        disabled={isLoading}
        onClick={handleCloseButtonClick}
      />
      {getSaveMemoButton()}
      {draftType !== DraftTypes.READONLY && (
        <ActionBarSaveButton
          draftType={draftType as DraftTypes}
          isSaving={!!isLoading}
          isErrorOnSave={isErrorOnSave}
          isApprovalRequired={isApprovalRequired()}
          onSave={handleSaveButtonClick}
          className="border-radius-m ml-r"
          style={{ maxHeight: 34 }}
        />
      )}
      {showTransactionLog && (
        <TransactionLog
          data={transactionData}
          onCancel={() => {
            setShowTransactionLog(false);
          }}
        />
      )}
      {needAddContactPopup && (
        <DocAddContactPopup
          contact={contact || {}}
          activeContactTab={CONTACT_FORM_TAB.COMPLIANCE}
          contactMode={DOCUMENT_MODE.EDIT}
          onClosePopup={() => setNeedAddContactPopup(false)}
        />
      )}
      {showFulfillmentPopup && (
        <PopupWrapper
          clickAction={catchClicks}
          type={POPUP_TYPE.POPUP}
          title={localizedText('Direct Fulfillment of Invoice')}
          height={'auto'}
          minHeight={'50%'}
          width={'90%'}
          disableClickOutside={true}
          btnList={fulfillmentPopupBtnConfig}
        >
          <Fulfillment
            documentDetails={docForFulfillment}
            passingInteraction={(callback: CallBackPayloadType) => {
              parentChildInteraction(callback);
            }}
            closePopup={() => {
              setShowFulfillmentPopup(false);
            }}
            closeMenuContainer={() => {
              dispatch(onDocumentClose({ draftId }));
            }}
            documentType={DOC_TYPE.INVOICE}
          />
        </PopupWrapper>
      )}
    </div>
  );
};

export default ActionBar;
