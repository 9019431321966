import {
  DKButton,
  DKInput,
  DKLabel,
  DKLine,
  INPUT_TYPE,
  DKSpinner,
  showAlert,
  DKIcons,
  showLoader,
  removeLoader
} from 'deskera-ui-library';
import { useEffect, useRef, useState } from 'react';
import {
  INPUT_VIEW_DIRECTION,
  MODULE_TYPE
} from '../../../../Constants/Constant';
import { ADVANCE_TRACKING, TRACKING_DETAILS } from '../../../../Constants/Enum';
import BatchTrackingAssignment from '../../../../SharedComponents/AdvancedTrackingPopup/BatchTrackingAssignment';
import SerialTrackedAssignment from '../../../../SharedComponents/AdvancedTrackingPopup/SerialTrackedAssignment';
import { DynamicPopupWrapper } from '../../../../SharedComponents/PopupWrapper';
import NoneTrackedAssignment from '../../../../SharedComponents/WarehouseManagement/NoneTrackedAssignment';
import { JobCardQCInitialState, JobCardQCModel } from './JobCardQCModel';
import { JobCardQcPresenter } from './JobCardQCPresenter';
import { fetchSerialTrackingProducts } from '../../../../Redux/Slices/SerialTrackingSlice';
import { useAppDispatch } from '../../../../Redux/Hooks';
import { fetchBatchTrackingProducts } from '../../../../Redux/Slices/BatchTrackingSlice';
import { fetchProductInventoryByID } from '../../../../Redux/Slices/WarehouseProductSlice';
import Utility, {
  reduceArrayByKey,
  deepClone
} from '../../../../Utility/Utility';
import ic_barcode_green from '../../../../Assets/Icons/ic_barcode_green.svg';
import ic_barcode_red from '../../../../Assets/Icons/ic_barcode_red.svg';
import { getUpdatedWarehouseInventoryDataConsideringUOM } from '../../JobCard/AddJobCard/AddJobCardPresenter';

export default function JobCardQC(props: any) {
  const dispatch = useAppDispatch();
  const [formState, setFormState] = useState<JobCardQCModel>(
    JobCardQCInitialState
  );
  const [updateTrackingDetails, setUpdateTrackingDetails] =
    useState<TRACKING_DETAILS>(TRACKING_DETAILS.EMPTY);
  const [productDetails, setProductDetails] = useState<any>();
  const [isUpdating, setIsUpdating] = useState(false);
  var presenter = new JobCardQcPresenter();

  useEffect(() => {
    callGetQCData();
    callGetProductDetails(props.productCode);
  }, []);

  const callGetQCData = async () => {
    let qcData = await presenter.fetchJobCardsQCData(
      props.productCode,
      props.jobCardCode
    );
    if (Utility.isNotEmpty(qcData)) {
      qcData[0] = {
        ...qcData[0],
        itemWarehouseInventoryData:
          getUpdatedWarehouseInventoryDataConsideringUOM(
            qcData[0]?.itemWarehouseInventoryData
          ) || [],
        passedWarehouseInventoryData:
          getUpdatedWarehouseInventoryDataConsideringUOM(
            qcData[0]?.passedWarehouseInventoryData
          ) || [],
        rejectedWarehouseInventoryData:
          getUpdatedWarehouseInventoryDataConsideringUOM(
            qcData[0]?.rejectedWarehouseInventoryData
          ) || [],
        itemQuantity: qcData[0]?.uomItemQuantity ?? qcData[0]?.quantity,
        rejectedQuantity: qcData?.rejectedQuantity ?? 0,
        passedQuantity: qcData?.passedQuantity ?? 0
      };
    }
    setFormState(qcData?.[0] ?? {});
  };

  const callGetProductDetails = async (productCode: string) => {
    let productData = await presenter.fetchProductData(productCode);
    setProductDetails(productData);
  };

  const validateForm = (): boolean => {
    const {
      itemQuantity = 0,
      passedQuantity = 0,
      rejectedQuantity = 0,
      passedWarehouseInventoryData = [],
      rejectedWarehouseInventoryData = []
    } = formState;

    if (itemQuantity !== passedQuantity + rejectedQuantity) {
      return false;
    }
    if (passedQuantity !== 0) {
      const passedQuantityAllocated = passedWarehouseInventoryData?.reduce(
        (acc: number, trackingData: any) =>
          acc + (trackingData.qtyToFulfil || 0),
        0
      );
      if (
        passedQuantity !==
        Utility.roundOffToTenantDecimalScale(passedQuantityAllocated)
      ) {
        return false;
      }
    }
    if (rejectedQuantity !== 0) {
      const rejectedQuantityAllocated = rejectedWarehouseInventoryData?.reduce(
        (acc: number, trackingData: any) =>
          acc + (trackingData.qtyToFulfil || 0),
        0
      );
      if (
        rejectedQuantity !==
        Utility.roundOffToTenantDecimalScale(rejectedQuantityAllocated)
      ) {
        return false;
      }
    }

    return true;
  };

  const getPayload = () => {
    const updatedFormState = { ...formState };
    let payload = {
      ...updatedFormState,
      uomPassedQuantity: updatedFormState?.passedQuantity,
      passedWarehouseInventoryData:
        updatedFormState?.passedWarehouseInventoryData?.map(
          (passedItem: any) => {
            let { warehouse, row, rack, bin, availableQty, ...newPassedItem } =
              passedItem;

            let updatedNewPassedItem = {
              ...newPassedItem,
              advancedTrackingType: productDetails?.advancedTracking,
              quantity: newPassedItem?.qtyToFulfil,
              uomQuantity: newPassedItem?.qtyToFulfil,
              advancedTrackingData: newPassedItem?.advancedTrackingData?.map(
                (passItem: any) => {
                  return {
                    ...passItem,
                    qtyToFulfil: passItem?.qtyToFulfil,
                    qtyToFulfilUom: passItem?.qtyToFulfil
                  };
                }
              )
            };

            return updatedNewPassedItem;
          }
        ) ?? [],
      uomRejectedQuantity: updatedFormState?.rejectedQuantity,
      rejectedWarehouseInventoryData:
        updatedFormState?.rejectedWarehouseInventoryData?.map(
          (rejectedItem: any) => {
            let {
              warehouse,
              row,
              rack,
              bin,
              availableQty,
              rowCode,
              rowName,
              rackCode,
              rackName,
              binCode,
              binName,
              ...newRejectedItem
            } = rejectedItem;
            // newRejectedItem = {
            //   ...newRejectedItem,
            //   warehouseName: rejectedWH?.name ?? '',
            //   warehouseCode: rejectedWH?.code ?? ''
            // };

            let updatedNewRejectedItem = {
              ...newRejectedItem,
              advancedTrackingType: productDetails?.advancedTracking,
              quantity: newRejectedItem?.qtyToFulfil,
              uomQuantity: newRejectedItem?.qtyToFulfil,
              advancedTrackingData: newRejectedItem?.advancedTrackingData?.map(
                (rejectItem: any) => {
                  return {
                    ...rejectItem,
                    qtyToFulfil: rejectItem?.qtyToFulfil,
                    qtyToFulfilUom: rejectItem?.qtyToFulfil
                  };
                }
              )
            };

            return updatedNewRejectedItem;
          }
        ) ?? [],
      documentUOMSchemaDefinition: props?.documentUOMSchemaDefinition,
      stockUom: props?.documentUOMSchemaDefinition?.sinkUOM
    };

    return payload;
  };

  const onQCSave = async () => {
    if (validateForm()) {
      if (formState.qcReason?.toString()?.length > 200) {
        showAlert('Error!', `Value too long for field 'Reason' (maximum size 200).`);
        return ;
      }
      setIsUpdating(true);
      let updateQCRes = await presenter.updateQCDetails(getPayload());
      if (updateQCRes !== 'QC update failed') {
        props?.onSuccess();
      } else {
        showAlert('', 'QC update failed');
      }
      setIsUpdating(false);
    }
  };

  const getHeader = () => {
    const isFormValid = validateForm();
    return (
      <div className="row justify-content-between p-h-r p-v-s bg-gray1">
        <div className="row pop-header-drag-handle">
          {/* old title "Allocate Serial-Tracked Products" */}
          <DKLabel text={'Consumption/QC'} className="fw-m fs-l" />
        </div>
        <div className="row width-auto gap-2">
          <DKButton
            title="Cancel"
            className={`${
              isUpdating ? 'text-gray border-m' : 'bg-white border-m'
            }`}
            onClick={() => {
              if (!isUpdating) {
                props.onClose();
              }
            }}
          />

          <div
            className={`row ${isUpdating ? 'border-radius-m border-m' : ''}`}
          >
            <DKButton
              title={'Save'}
              disabled={!isFormValid}
              className={`${
                isUpdating
                  ? 'border-radius-none text-gray'
                  : 'bg-app text-white'
              }`}
              onClick={onQCSave}
            />
            {isUpdating && (
              <DKSpinner iconClassName="ic-s" className="column pl-0 pr-s" />
            )}
          </div>
        </div>
      </div>
    );
  };

  const createProductItemForTracking = (type: ADVANCE_TRACKING) => {
    return {
      product: productDetails,
      productCode: productDetails?.pid,
      documentSequenceCode: productDetails?.documentSequenceCode,
      requiredQuantity:
        updateTrackingDetails === TRACKING_DETAILS.PASSED
          ? formState?.passedQuantity
          : formState?.rejectedQuantity,
      productQuantity:
        updateTrackingDetails === TRACKING_DETAILS.PASSED
          ? formState?.passedQuantity
          : formState?.rejectedQuantity,
      advancedTracking: productDetails?.advancedTracking,
      advancedTrackingFulfilmentData: getWarehouseInventoryDataForPopUp(),
      advancedTrackingMetaData: getWarehouseInventoryDataForPopUp(),
      documentUOMSchemaDefinition: props?.documentUOMSchemaDefinition
    };
  };

  const getWarehouseInventoryDataForPopUp = () => {
    return updateTrackingDetails === TRACKING_DETAILS.PASSED
      ? formState?.passedWarehouseInventoryData
      : updateTrackingDetails === TRACKING_DETAILS.REJECTED
      ? formState?.rejectedWarehouseInventoryData
      : [];
  };

  const totalPassedQuantity = Utility.roundOffToTenantDecimalScale(
    reduceArrayByKey(formState?.passedWarehouseInventoryData, 'qtyToFulfil')
  );

  const totalRejectedQuantity = Utility.roundOffToTenantDecimalScale(
    reduceArrayByKey(formState?.rejectedWarehouseInventoryData, 'qtyToFulfil')
  );

  // we will have to filter out selected passed/rejected data from the formstate itemWarehouseInventory data.
  const getFilteredWarehouseInventoryData = () => {
    let filteredData = formState.itemWarehouseInventoryData;
    if (productDetails?.advancedTracking === ADVANCE_TRACKING.NONE) {
      filteredData = presenter.getFilteredDataForNoneTrackedProduct(
        { ...formState },
        updateTrackingDetails
      );
    }
    if (productDetails?.advancedTracking === ADVANCE_TRACKING.SERIAL) {
      filteredData = presenter.getFilteredDataForSerialTrackedProduct(
        { ...formState },
        updateTrackingDetails
      );
    }
    if (productDetails?.advancedTracking === ADVANCE_TRACKING.BATCH) {
      filteredData = presenter.getFilteredDataForBatchTrackedProduct(
        deepClone(formState),
        updateTrackingDetails
      );
    }
    return filteredData;
  };

  const getValueTitleField = (title: string, value: string, className = '') => {
    return (
      <div className={`row gap-2 justify-content-between ${className}`}>
        <DKLabel className="row fw-m" text={title} />
        <DKLabel
          className="opacity-80"
          text={value}
          style={{
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            width: 220
          }}
        />
      </div>
    );
  };

  const getDescriptionView = () => {
    return (
      <div className="row justify-content-between">
        <div
          className="column parent-width align-items-start"
          style={{ width: 220 }}
        >
          {getValueTitleField('Product Name', productDetails?.name)}
          {getValueTitleField(
            'Product Code',
            productDetails?.documentSequenceCode
          )}
        </div>
        <div
          className="column parent-width align-items-end"
          style={{ width: 230 }}
        >
          {getValueTitleField(
            'Job Card',
            props?.jobCardDocumentSequenceCode ?? ''
          )}
          {getValueTitleField('Work Order', props?.woCode ?? '')}
        </div>
      </div>
    );
  };

  const loadSerialTrackingProducts = async (code: any) => {
    try {
      dispatch(
        fetchSerialTrackingProducts({
          productCode: code,
          enableQCWarehouse: false
        })
      );
    } catch (err) {
      console.error('Error fetching Advanced Tracking Products: ', err);
    }
  };

  const loadBatchTrackingProducts = async (code: any) => {
    showLoader();
    try {
      let data = await dispatch(
        fetchBatchTrackingProducts({
          productCode: code,
          enableQCWarehouse: false
        })
      );
      removeLoader();
    } catch (err) {
      console.error('Error fetching Advanced Tracking Products: ', err);
      removeLoader();
    }
  };

  const loadProductInventoryById = async (ids: any) => {
    try {
      await dispatch(fetchProductInventoryByID(ids));
    } catch (err) {
      console.error('Error fetching UOMs details: ', err);
    }
  };

  const onViewAssignButtonClick = () => {
    switch (productDetails.advancedTracking) {
      case ADVANCE_TRACKING.NONE:
        loadProductInventoryById([productDetails?.pid]);
        break;
      case ADVANCE_TRACKING.SERIAL:
        loadSerialTrackingProducts(productDetails.pid);
        break;
      case ADVANCE_TRACKING.BATCH:
        loadBatchTrackingProducts(productDetails?.pid);
    }
  };

  const getTotalQuantityField = () => {
    return (
      <div className="row parent-width" style={{ gap: 10 }}>
        <DKInput
          direction={INPUT_VIEW_DIRECTION.VERTICAL}
          value={formState?.itemQuantity}
          title={'Total Quantity'}
          readOnly={true}
        />
        <DKButton
          className={`pt-r`}
          title={''}
          icon={DKIcons.ic_blank}
          style={{ padding: 0, paddingTop: 16 }}
          onClick={() => {
            onViewAssignButtonClick();
            setUpdateTrackingDetails(TRACKING_DETAILS.PASSED);
          }}
        />
      </div>
    );
  };

  const getPassedQuantityField = () => {
    const isAllocationDone =
      totalPassedQuantity !== 0 &&
      formState?.passedQuantity === totalPassedQuantity;

    const handleOnChange = (value: number) => {
      let copyOfFormState = { ...formState };
      const { itemQuantity = 0, rejectedQuantity = 0 } = copyOfFormState;
      const maximumQty = Utility.roundOffToTenantDecimalScale(
        itemQuantity - rejectedQuantity
      );

      try {
        if (value > maximumQty) {
          value = 0;
        }
      } catch (error) {
        value = 0;
      }
      copyOfFormState.passedWarehouseInventoryData = [];
      copyOfFormState.passedQuantity = value;
      setFormState(copyOfFormState);
    };
    const onBlur = () => {
      let copyOfFormState = { ...formState };
      copyOfFormState.passedQuantity =
        Utility.roundOffToTenantDecimalScale(
          Number(copyOfFormState.passedQuantity)
        ) ?? 0;
      setFormState(copyOfFormState);
    };

    return (
      <div className="row parent-width" style={{ gap: 10 }}>
        <DKInput
          direction={INPUT_VIEW_DIRECTION.VERTICAL}
          value={formState?.passedQuantity ?? 0}
          type={INPUT_TYPE.NUMBER}
          title={'Consumed/Passed Quantity'}
          onChange={handleOnChange}
          onBlur={onBlur}
        />
        <DKButton
          className={`pt-r`}
          title={''}
          icon={isAllocationDone ? ic_barcode_green : ic_barcode_red}
          style={{ padding: 0, paddingTop: 16 }}
          onClick={() => {
            if (formState.passedQuantity > 0) {
              onViewAssignButtonClick();
              setUpdateTrackingDetails(TRACKING_DETAILS.PASSED);
            }
          }}
        />
      </div>
    );
  };

  const getRejectedQuantityField = () => {
    const isAllocationDone =
      totalRejectedQuantity !== 0 &&
      formState?.rejectedQuantity === totalRejectedQuantity;

    const handleOnChange = (value: number) => {
      let copyOfFormState = { ...formState };
      const { itemQuantity = 0, passedQuantity = 0 } = copyOfFormState;
      const maximumQty = Utility.roundOffToTenantDecimalScale(
        itemQuantity - passedQuantity
      );

      try {
        if (value > maximumQty) {
          value = 0;
        }
      } catch (error) {
        value = 0;
      }
      copyOfFormState.rejectedWarehouseInventoryData = [];
      copyOfFormState.rejectedQuantity = value;
      setFormState(copyOfFormState);
    };
    const onBlur = () => {
      let copyOfFormState = { ...formState };
      copyOfFormState.rejectedQuantity =
        Utility.roundOffToTenantDecimalScale(
          Number(copyOfFormState.rejectedQuantity)
        ) || 0;
      setFormState(copyOfFormState);
    };

    return (
      <div className="row parent-width" style={{ gap: 10 }}>
        <DKInput
          direction={INPUT_VIEW_DIRECTION.VERTICAL}
          type={INPUT_TYPE.NUMBER}
          value={formState?.rejectedQuantity ?? 0}
          title={'Wastage/Rejected Quantity'}
          onChange={handleOnChange}
          onBlur={onBlur}
        />
        <DKButton
          icon={isAllocationDone ? ic_barcode_green : ic_barcode_red}
          className={`pt-r`}
          title={''}
          style={{ padding: 0, paddingTop: 16 }}
          onClick={() => {
            if (formState.rejectedQuantity) {
              onViewAssignButtonClick();
              setUpdateTrackingDetails(TRACKING_DETAILS.REJECTED);
            }
          }}
        />
      </div>
    );
  };

  const getQCReason = () => {
    return (
      <div className="row parent-width" style={{ gap: 10 }}>
        <DKInput
          direction={INPUT_VIEW_DIRECTION.VERTICAL}
          value={formState?.qcReason}
          title={'Reason'}
          type={INPUT_TYPE.LONG_TEXT}
          onChange={(reason: string) => {
            let copyFormState = { ...formState };
            copyFormState['qcReason'] = reason;
            setFormState(copyFormState);
          }}
          required={false}
        />
        <DKButton
          className={`pt-r`}
          title={''}
          icon={DKIcons.ic_blank}
          style={{ padding: 0, paddingTop: 16 }}
          onClick={() => {}}
        />
      </div>
    );
  };

  const resetTrackingDetails = () =>
    setUpdateTrackingDetails(TRACKING_DETAILS.EMPTY);

  const showNoneTrackPopUp = () => {
    const isPassed = updateTrackingDetails === TRACKING_DETAILS.PASSED;
    const onSave = (data: any) => {
      data = data?.map((item: any) => ({
        ...item,
        qtyToFulfil: item.quantity
      }));
      let copyFormState = { ...formState };
      if (isPassed) {
        copyFormState.passedWarehouseInventoryData = data;
      } else if (updateTrackingDetails === TRACKING_DETAILS.REJECTED) {
        copyFormState.rejectedWarehouseInventoryData = data;
      }
      setFormState(copyFormState);
      resetTrackingDetails();
    };
    return (
      <>
        <NoneTrackedAssignment
          details={{
            ...createProductItemForTracking(ADVANCE_TRACKING.NONE),
            parentQuantityToFulfill: isPassed
              ? formState?.passedQuantity
              : formState?.rejectedQuantity,
            pendingQuantity: isPassed
              ? Utility.getUomWarehouseQuantityWithoutRoundOff(
                  formState?.passedQuantity,
                  createProductItemForTracking(ADVANCE_TRACKING.NONE)
                    ?.documentUOMSchemaDefinition
                )
              : Utility.getUomWarehouseQuantityWithoutRoundOff(
                  formState?.rejectedQuantity,
                  createProductItemForTracking(ADVANCE_TRACKING.NONE)
                    ?.documentUOMSchemaDefinition
                ),
            warehouseInventoryData: getWarehouseInventoryDataForPopUp()
          }}
          module={MODULE_TYPE.SELL}
          isMrpFlow={true}
          filterNormalData={true}
          mrpTitle="Material"
          filteredWarehouseInventoryData={{
            warehouseInventoryData: getFilteredWarehouseInventoryData()
          }}
          onSave={onSave}
          onCancel={() => {
            resetTrackingDetails();
          }}
        ></NoneTrackedAssignment>
      </>
    );
  };

  const showSerialTrackPopUp = () => {
    const isPassed = updateTrackingDetails === TRACKING_DETAILS.PASSED;
    const onSerialSave = (
      data: any,
      isQuickCommit: boolean,
      quantityToFulfill: any
    ) => {
      let updatedData = data?.map((itemData: any) => {
        return {
          ...itemData,
          qtyToFulfil: Utility.getUomQuantityWithoutRoundOff(
            itemData?.qtyToFulfil,
            props?.documentUOMSchemaDefinition
          )
        };
      });

      let copyFormState = { ...formState };
      if (isPassed) {
        copyFormState.passedWarehouseInventoryData = updatedData;
      } else if (updateTrackingDetails === TRACKING_DETAILS.REJECTED) {
        copyFormState.rejectedWarehouseInventoryData = updatedData;
      }
      setFormState(copyFormState);
      resetTrackingDetails();
    };

    return (
      <>
        <SerialTrackedAssignment
          isMRP={true}
          filterBatchData={true}
          selectedItem={productDetails}
          filteredWarehouseInventoryData={{
            warehouseInventoryData: getFilteredWarehouseInventoryData()
          }}
          mrpTitle="Material"
          itemDetails={createProductItemForTracking(ADVANCE_TRACKING.SERIAL)}
          onSerialSave={onSerialSave}
          onClose={() => {
            resetTrackingDetails();
          }}
        ></SerialTrackedAssignment>
      </>
    );
  };

  const showBatchTrackPopUp = () => {
    const isPassed = updateTrackingDetails === TRACKING_DETAILS.PASSED;
    const onBatchSave = (data: any, quantityToFulfill: any) => {
      let updatedData = data?.map((itemData: any) => {
        return {
          ...itemData,
          qtyToFulfil: Utility.getUomQuantity(
            itemData?.qtyToFulfil,
            props?.documentUOMSchemaDefinition
          ),
          advancedTrackingData: itemData?.advancedTrackingData?.map(
            (itemTrackingData: any) => {
              return {
                ...itemTrackingData,
                qtyToFulfil: Utility.getUomQuantity(
                  itemTrackingData?.qtyToFulfil,
                  props?.documentUOMSchemaDefinition
                )
              };
            }
          )
        };
      });
      let copyFormState = { ...formState };
      if (isPassed) {
        copyFormState.passedWarehouseInventoryData = updatedData;
      } else if (updateTrackingDetails === TRACKING_DETAILS.REJECTED) {
        copyFormState.rejectedWarehouseInventoryData = updatedData;
      }
      setFormState(copyFormState);
      resetTrackingDetails();
    };
    return (
      <>
        <BatchTrackingAssignment
          isMrpFlow={true}
          showSelectedDataOnly={true}
          filterBatchData={true}
          mrpTitle="Material"
          filteredWarehouseInventoryData={{
            warehouseInventoryData: getFilteredWarehouseInventoryData()
          }}
          itemDetails={createProductItemForTracking(ADVANCE_TRACKING.BATCH)}
          onBatchSave={onBatchSave}
          onClose={() => {
            resetTrackingDetails();
          }}
        ></BatchTrackingAssignment>
      </>
    );
  };

  return (
    <DynamicPopupWrapper>
      <div className="transparent-background" style={{ zIndex: 9998 }}>
        <div
          className="popup-window"
          style={{
            maxWidth: 500,
            width: '90%',
            maxHeight: '95%',
            height: 520,
            padding: 0,
            paddingBottom: 60,
            overflow: 'hidden'
          }}
        >
          {getHeader()}
          <div className="column parent-width p-4 gap-2">
            {getDescriptionView()}
            <DKLine className="m-v-m" />
            {getTotalQuantityField()}
            {getPassedQuantityField()}
            {getRejectedQuantityField()}
            {getQCReason()}
          </div>
        </div>
        {updateTrackingDetails !== TRACKING_DETAILS.EMPTY && (
          <>
            {productDetails?.advancedTracking === ADVANCE_TRACKING.SERIAL &&
              showSerialTrackPopUp()}
            {productDetails?.advancedTracking === ADVANCE_TRACKING.BATCH &&
              showBatchTrackPopUp()}
            {productDetails?.advancedTracking === ADVANCE_TRACKING.NONE &&
              showNoneTrackPopUp()}
          </>
        )}
      </div>
    </DynamicPopupWrapper>
  );
}
