import { showAlert } from 'deskera-ui-library';
import {
  APPROVAL_STATUS,
  BOOKS_DATE_FORMAT,
  BUY_TYPE,
  DOC_TYPE,
  DOCUMENT_MODE,
  LABELS,
  PAYMENT_STATUS,
  RECORD_SAVED_EVENT_DOC_TYPE,
  TAX_SYSTEM
} from '../../../../Constants/Constant';
import RouteManager, { PAGE_ROUTES } from '../../../../Managers/RouteManager';
import { AnyDocument, DraftTypes, IDocument } from '../../../../Models/Drafts';
import { fetchBills } from '../../../../Redux/Slices/BillsSlice';
import {
  deleteDrafts,
  fetchDrafts
} from '../../../../Redux/Slices/DraftsSlice';
import { fetchOrders } from '../../../../Redux/Slices/PurchaseOrdersSlice';
import { Store } from '../../../../Redux/Store';
import DateFormatService from '../../../../Services/DateFormat';
import DraftService from '../../../../Services/Drafts';
import {
  cascadingDiscountsInvalidMessage,
  checkGSTINPresentForSelectedContact,
  checkIfTotalDiscountInvalid,
  convertExpectedDeliveryDateInString,
  customFieldsContainsErrors,
  getAccountsAndCFForBudgetValidation,
  getDocumentAlert,
  getTenantTaxSystem,
  inactiveContactMessage,
  isDocContactInactive,
  rebuildCascadingDiscountsForSaving,
  showAlertOnDocAPIError,
  updateAddressAsPerLocationCF,
  validateBudgetForAccounts
} from '../../../../SharedComponents/DocumentForm/NewDocumentHelper';
import Utility, { deepClone } from '../../../../Utility/Utility';
import {
  DOC_SAVE_OPTION,
  setCanValidateDocument,
  setIsSavingDocument
} from '../View/ActionBarHelper';
import { DocSaver } from './DocSaver';
import {
  onDocumentClose,
  updateMultipleKeysInDocument
} from '../../../../Redux/Slices/DocumentSlice';
import NumberFormatService from '../../../../Services/NumberFormat';
import AuthService from '../../../../Services/Auth';
import BillService from '../../../../Services/Bill';
import { COMPLIANCE_SPECIFIC_FIELD_NAME } from '../../../../Constants/Enum';
import {
  COMMON_EVENTS,
  commonCustomEvent
} from '../../../../Services/event/commonEvents';
import DashboardService from '../../../../Services/Dashboard';
import { getBuyDashboard } from '../../../../Redux/Slices/DashboardSlice';
import { fetchWorkOuts } from '../../../../Redux/Slices/WorkOutSlice';
import { ADDITIONAL_CHARGE_METHODS } from '../../../../SharedComponents/AdditionalCharges/AdditionalCharges';
import { documentUpdated } from '../../../../Redux/Slices/CommonDataSlice';

export class BillSaver extends DocSaver {
  draftId: number;
  documentMode: DOCUMENT_MODE;
  tenantInfo: any;
  docToSave: AnyDocument;
  saveOption: DOC_SAVE_OPTION;
  draftType: DraftTypes;
  replaceURLCallback: (() => void) | undefined;
  skipTDS: boolean;

  constructor(data: {
    draftToSave: IDocument<AnyDocument>;
    documentMode: DOCUMENT_MODE;
    saveOption: DOC_SAVE_OPTION;
    tenantInfo: any;
  }) {
    super(data);
    this.draftId = data.draftToSave.id;
    this.draftType = data.draftToSave.draftType;
    this.documentMode = data.documentMode;
    this.saveOption = data.saveOption;
    this.tenantInfo = data.tenantInfo;
    this.docToSave = this.updatedDoc({ ...data.draftToSave?.populateFormData });
    this.skipTDS = false;
  }

  private updatedDoc(doc: AnyDocument) {
    if (this.tenantInfo.additionalSettings?.CASCADING_DISCOUNTS?.enable) {
      doc = {
        ...doc,
        items: rebuildCascadingDiscountsForSaving(doc)
      };
    }

    delete doc.customerOrderNumber;

    const landedCostKeys = {
      landedCost: (this.draftToSave?.data as any)?.landedCost,
      landedProducts: (this.draftToSave?.data as any)?.landedProducts
    };

    let billWithUpdate: any = {
      ...doc,
      ...landedCostKeys,
      purchaseInvoiceProducts: doc.items,
      purchaseInvoiceDueDate: doc.validTillDate,
      billDue: doc.validTillDate,
      purchaseInvoiceDate: doc.documentDate,
      receiveByDate: doc.fulfillmentDate,
      currency: doc.currency,
      totalAmount: doc.totalAmount
    };

    let editBillWithExtraKeys = {
      purchaseInvoiceType: BUY_TYPE.INVENTORY,
      openingInvoice: false,
      documentCode:
        this.documentMode === DOCUMENT_MODE.EDIT ? doc.purchaseInvoiceCode : '',
      documentType: DOC_TYPE.BILL,
      currency: doc.currency,
      priceListId: doc?.priceListId,
      priceListName: doc?.priceListName,
      purchaseInvoiceProducts: billWithUpdate.purchaseInvoiceProducts?.map(
        (billDoc: any) => {
          return {
            ...billDoc,
            documentItemCode: billDoc.purchaseInvoiceItemCode
          };
        }
      )
    };

    if (this.documentMode === DOCUMENT_MODE.EDIT) {
      billWithUpdate = { ...billWithUpdate, ...editBillWithExtraKeys };
    }

    return billWithUpdate;
  }

  private sendTriggerOnApproval(payload: AnyDocument) {
    let emails = Utility.getApproverEmail(payload);
    let sum =
      payload &&
      payload.purchaseInvoiceProducts
        .map((item: any) => item.totalAmount)
        .reduce((prev: any, curr: any) => prev + curr, 0);
    let payloadObj = {
      contactCode: payload.contactCode,
      totalAmount: NumberFormatService.getNumber(sum),
      userName: AuthService.getUserName(),
      currency: Utility.getCurrencySymbolFromCode(payload.currency),
      approverMap: Object.fromEntries(emails),
      currentLevel: 1,
      approvalHistory: payload['multiApprovalDetails']?.approvalHistory || []
    };
    BillService.sendTriggerOnApproval(payloadObj).then(
      (response: any) => {},
      (err: any) => {
        console.error('Error while creating draft: ', err);
      }
    );
  }

  async saveAsDraft(approvalRequired?: boolean) {
    try {
      const approvalData = await super.updatePayloadWithApprovalData(
        this.docToSave,
        approvalRequired
      );
      const isApprovalRequired = approvalData?.isApprovalRequired;
      this.docToSave = approvalData?.payload || this.docToSave;
      this.docToSave['dueAmount'] = 0;
      // Update expectedDeliveryDt in line items
      let parsedItems = this.docToSave?.items;
      parsedItems = parsedItems?.map((billItem: any) => {
        return {
          ...billItem,
          expectedDeliveryDt: DateFormatService.getDateStrFromDate(
            billItem.expectedDeliveryDt,
            BOOKS_DATE_FORMAT['DD-MM-YYYY']
          )
        };
      });

      this.docToSave = {
        ...this.docToSave,
        items: parsedItems,
        purchaseInvoiceProducts: parsedItems
      };

      if (
        isApprovalRequired &&
        !Utility.isEmpty(this.docToSave) &&
        (!this.draftToSave?.isSaved || this.documentMode === DOCUMENT_MODE.COPY)
      ) {
        let draftsData: any;
        if (this.docToSave?.isConverting === true) {
          draftsData = {
            data: {
              type: LABELS.BILLS,
              tableId: this.draftTableId,
              columnConfig: this.draftColumnConfig
            }
          };
        } else {
          draftsData = { ...this.draftToSave, draftType: DraftTypes.NEW };
        }
        try {
          const newRecord = await DraftService.createRecord(
            this.docToSave,
            draftsData
          );
          if (!Utility.isEmpty(newRecord)) {
            this.sendTriggerOnApproval(this.docToSave);
          }
          Store.dispatch(fetchOrders());
          Store.dispatch(fetchBills());
          Store.dispatch(
            fetchDrafts({
              tableId: this.draftTableId,
              isSaveColumnId: this.isSaveColumnId,
              draftTypeColId: this.draftTypeColId,
              draftTypeColValue: LABELS.BILLS
            })
          );

          if (this.docToSave?.isConverting === true) {
            const buttons = [
              {
                title: 'Ok',
                className: 'bg-button, border-m',
                onClick: () => {}
              },
              {
                title: 'Goto Bills',
                className: ' bg-blue text-white ml-r',
                onClick: () => {
                  RouteManager.navigateToPage(PAGE_ROUTES.BILLS);
                }
              }
            ];
            showAlert(
              'Purchase Order converted!',
              'Purchase Order has been converted to bill successfully.',
              buttons
            );
          }
          Store.dispatch(onDocumentClose({ draftId: this.draftId }));
          this.replaceURLCallback?.();
        } catch (err: any) {
          setIsSavingDocument(this.draftId, false);
          this.replaceURLCallback?.();
          console.error('BillSaver -> Error creating draft record: ', err);
        }
      } else {
        await super.saveDraft(this.docToSave);
        if (isApprovalRequired) {
          this.sendTriggerOnApproval(this.docToSave);
        }
        this.replaceURLCallback?.();
      }
    } catch (err: any) {
      console.error('Error while saving draft (Bill -> saveAsDraft): ', err);
    }
  }

  /**
   * Handles create and update bill calls
   */
  save(closeOnUpdate = true) {
    if (this.draftType === DraftTypes.UPDATE) {
      this.updateBill(closeOnUpdate);
    } else {
      this.createBill(true);
    }
  }

  private validateAccountsBudget(
    doc: any,
    isUpdate: boolean,
    closeDoc: boolean
  ) {
    const accountsAndCFInfo = getAccountsAndCFForBudgetValidation(
      [...doc.purchaseInvoiceProducts],
      doc.customField,
      'accountCode',
      'tax',
      doc.exchangeRate ? doc.exchangeRate : 1,
      true
    );
    const allAccountsData = accountsAndCFInfo?.accountsInfo;
    const customField = accountsAndCFInfo?.customField;

    if (allAccountsData.length) {
      validateBudgetForAccounts(
        doc.documentDate,
        allAccountsData,
        customField,
        doc.journalEntryCode ? doc.journalEntryCode : null
      ).then(
        (budgetResp: { data: any[]; limitCrossed: boolean }) => {
          if (budgetResp.limitCrossed) {
            let buttons = [
              {
                title: 'Cancel',
                className: 'bg-gray2 border-m ',
                onClick: () => {
                  setIsSavingDocument(this.draftId, false);
                }
              },
              {
                title: 'Proceed',
                className: 'bg-button text-white ml-r',
                onClick: () => {
                  isUpdate
                    ? this.makeUpdateAPICall(doc, closeDoc)
                    : this.makeCreateAPICall(doc, closeDoc);
                }
              }
            ];
            let message =
              '<ul class="text-align-left" style="list-style-type: disc; margin-left: 5px;">';
            budgetResp.data?.forEach((alertData: any) => {
              message += `<li>${alertData.message}</li>`;
            });
            message +=
              '<div class="text-align-center mt-l">Do you wish to continue?</div>';
            message += '</ul>';
            showAlert('Warning!', message, buttons);
          } else {
            isUpdate
              ? this.makeUpdateAPICall(doc, closeDoc)
              : this.makeCreateAPICall(doc, closeDoc);
          }
        },
        (err) => {
          console.error('Error validating accounts budget: ', err);
        }
      );
    }
  }

  private removeUnwantedKeysFromPayload = (payload: any) => {
    payload = {
      ...payload,
      purchaseInvoiceProducts: payload.purchaseInvoiceProducts.map(
        (item: any) => {
          delete item.amortizationTemplate;
          delete item.amortizationStartDate;
          delete item.amortizationEndDate;
          return item;
        }
      )
    };
    return payload;
  };

  /**
   * Initiate create bill flow
   * @param closeDoc
   * @returns
   */
  async createBill(closeDoc: boolean) {
    setIsSavingDocument(this.draftId, true);
    setCanValidateDocument(this.draftId, true);

    let payload: any = deepClone(this.docToSave);
    payload = updateAddressAsPerLocationCF(payload);
    if (this.tenantInfo?.additionalSettings?.CASCADING_DISCOUNTS?.enable) {
      payload = {
        ...payload,
        purchaseInvoiceProducts: rebuildCascadingDiscountsForSaving(payload)
      };
    }
    if (
      payload.documentType === DOC_TYPE.BILL ||
      payload.documentType === DOC_TYPE.ORDER
    ) {
      payload = {
        ...payload,
        purchaseInvoiceProducts: convertExpectedDeliveryDateInString(payload)
      };
    }

    delete payload?.items;

    if (
      (this.documentMode === DOCUMENT_MODE.COPY ||
        this.documentMode === DOCUMENT_MODE.NEW) &&
      (payload?.duplicate || payload?.isConverting)
    ) {
      const isContactInactive = isDocContactInactive(payload?.contact);
      if (isContactInactive) {
        showAlert('Error', inactiveContactMessage);
        setIsSavingDocument(this.draftId, false);
        return;
      }
    }

    /**
     * @todo
     * BOOK-8234 | Need to handle similar flows/conditions from BE
     * i.e. while receiving & converting a doc at the same time,
     * need to set receiptDate properly..
     */
    if (payload.documentType === DOC_TYPE.BILL && payload.receivedComplete) {
      payload.receiptDate = payload.receiveByDate;
    }

    if (payload.attachmentIds?.length) {
      payload.attachments = payload.attachmentIds.map(
        (attachmentId: any) => `${attachmentId}`
      );
    }

    if (!this.isDocValid(payload, closeDoc)) {
      setIsSavingDocument(this.draftId, false);
      return;
    }

    let isApproval = await Utility.isApprovalRequired(payload);
    if (isApproval === null) {
      setIsSavingDocument(this.draftId, false);
      return;
    } else if (isApproval) {
      this.saveAsDraft(isApproval);
      return;
    } else {
      payload['approvalStatus'] = APPROVAL_STATUS['NOT_REQUIRED'];
    }

    let linkedWorkorder = payload.linkedDocuments?.find(
      (ele: any) => ele.documentType === 'WORK_ORDER'
    );
    if (!Utility.isEmpty(linkedWorkorder?.lineItemDetails)) {
      let linkedWOIndex = payload.linkedDocuments?.findIndex(
        (ele: any) => ele.documentType === 'WORK_ORDER'
      );
      let linkedWoArray = Object.keys(linkedWorkorder?.lineItemDetails)?.map(
        (key) => {
          return linkedWorkorder?.lineItemDetails[key];
        }
      );
      let linkedWoItems: any = {};
      payload.purchaseInvoiceProducts.forEach((item: any, index: any) =>
        linkedWoArray.forEach((ele: any) => {
          if (item.productCode === ele.productCode) {
            linkedWoItems[index + 1] = { ...ele };
          }
        })
      );
      payload['linkedDocuments'][linkedWOIndex]['lineItemDetails'] =
        linkedWoItems;
    }

    if (this.draftType === DraftTypes.DRAFT) {
      payload[
        'draftReferenceId'
      ] = `${this.draftTableId}/record/${this.draftId}`;
    }

    if (payload.landedCost) {
      let showMsg = payload?.purchaseInvoiceProducts?.every((item: any) => {
        return !Utility.isEmpty(item.landedCostDetails);
      });
      if (!showMsg) {
        setIsSavingDocument(this.draftId, false);
        showAlert(
          '',
          'Landed cost details are not saved yet. Please check context menu for details.'
        );
        return;
      } else {
        this.validateAccountsBudget(payload, false, closeDoc);
      }
    } else {
      this.validateAccountsBudget(payload, false, closeDoc);
    }
  }

  /**
   * Initiate create bill API call
   * @param payload
   * @param closeDoc
   */
  private makeCreateAPICall(payload: any, closeDoc: any) {
    payload = this.removeUnwantedKeysFromPayload({ ...payload });
    BillService.createBill(
      payload,
      Utility.getTenantSpecificApiCode(COMPLIANCE_SPECIFIC_FIELD_NAME.BILL)
    ).then(
      (response: any) => {
        if (this.draftToSave) {
          if (closeDoc) {
            // Remove draft popup
            Store.dispatch(onDocumentClose({ draftId: this.draftId }));
          }
          if (this.draftType === DraftTypes.DRAFT) {
            Store.dispatch(
              deleteDrafts({
                recordId: this.draftId,
                tableId: this.draftTableId
              })
            );
          }
          this.updateForm(response, payload, false);
        } else {
          setIsSavingDocument(this.draftId, false);
        }
        if (
          payload &&
          payload.linkedDocuments &&
          payload.linkedDocuments.length > 0
        ) {
          let linkData = payload.linkedDocuments.filter(
            (doc: any) => doc.documentType === DOC_TYPE.JOB_WORK_OUT_ORDER
          );
          if (linkData && linkData.length <= 0) {
            Store.dispatch(
              fetchDrafts({
                tableId: this.draftTableId,
                isSaveColumnId: this.isSaveColumnId,
                draftTypeColId: this.draftTypeColId,
                draftTypeColValue: LABELS.BILLS
              })
            );
          }
        } else {
          Store.dispatch(
            fetchDrafts({
              tableId: this.draftTableId,
              isSaveColumnId: this.isSaveColumnId,
              draftTypeColId: this.draftTypeColId,
              draftTypeColValue: LABELS.BILLS
            })
          );
        }

        commonCustomEvent.dispatch(COMMON_EVENTS.RECORD_SAVED, {
          id: null,
          type: RECORD_SAVED_EVENT_DOC_TYPE.CREATE_BILL,
          linkedDocId: response?.linkedDocuments?.[0]?.documentCode,
          linkedDocType: RECORD_SAVED_EVENT_DOC_TYPE.JOB_WORK_OUT,
          isEdit: true
        });

        Store.dispatch(fetchBills());
        if (payload.isPartialBill) {
          Store.dispatch(fetchOrders());
        }
        this.replaceURLCallback?.();
        const SellDashboardConfig =
          Store.getState().dashboards?.sellDashboardFilter;
        DashboardService.apiConfig = SellDashboardConfig.config;
        Store.dispatch(getBuyDashboard());
      },
      (err) => {
        console.error('BillSaver -> Error while creating Bill: ', err);
        setIsSavingDocument(this.draftId, false);
        showAlertOnDocAPIError(err);
        this.replaceURLCallback?.();
      }
    );
  }

  /**
   * Initialte update bill flow
   * @param closeOnUpdate
   */
  async updateBill(closeOnUpdate: boolean) {
    setIsSavingDocument(this.draftId, true);
    setCanValidateDocument(this.draftId, true);

    let payload: any = deepClone(this.docToSave);
    payload = { ...payload, contact: payload.contactDto };
    payload = updateAddressAsPerLocationCF(payload);
    if (this.tenantInfo?.additionalSettings?.CASCADING_DISCOUNTS?.enable) {
      payload = {
        ...payload,
        purchaseInvoiceProducts: rebuildCascadingDiscountsForSaving(payload)
      };
    }
    if (
      payload.documentType === DOC_TYPE.BILL ||
      payload.documentType === DOC_TYPE.ORDER
    ) {
      payload = {
        ...payload,
        purchaseInvoiceProducts: convertExpectedDeliveryDateInString(payload)
      };
    }
    delete payload?.items;
    delete payload?.contactDto;

    if (payload.attachmentIds?.length) {
      payload.attachments = payload.attachmentIds.map(
        (attachmentId: any) => `${attachmentId}`
      );
    }

    const zeroAmountDocument = payload.totalAmount === 0;
    if (zeroAmountDocument) {
      payload.dueAmount = payload.totalAmount;
      payload.totalAmountInBaseCurrency = payload.totalAmount;
      payload.paymentStatus = PAYMENT_STATUS.PENDING;
    }

    if (!this.isDocValid(payload, closeOnUpdate)) {
      setIsSavingDocument(this.draftId, false);
      return;
    }

    this.validateAccountsBudget(payload, true, closeOnUpdate);
  }

  /**
   * Initiate update bill API call
   * @param payload
   * @param closeOnUpdate
   */
  private makeUpdateAPICall(payload: any, closeOnUpdate: boolean) {
    payload = this.removeUnwantedKeysFromPayload({ ...payload });
    BillService.updateBill(
      payload,
      Utility.getTenantSpecificApiCode(COMPLIANCE_SPECIFIC_FIELD_NAME.BILL)
    ).then(
      (res) => {
        if (this.draftToSave) {
          if (closeOnUpdate) {
            Store.dispatch(onDocumentClose({ draftId: this.draftId }));
          }
          setIsSavingDocument(this.draftId, false);
          this.updateForm(res, payload, true, closeOnUpdate);
        }
        Store.dispatch(
          fetchDrafts({
            tableId: this.draftTableId,
            isSaveColumnId: this.isSaveColumnId,
            draftTypeColId: this.draftTypeColId,
            draftTypeColValue: LABELS.BILLS
          })
        );
        Store.dispatch(fetchBills());
        setIsSavingDocument(this.draftId, false);
        this.replaceURLCallback?.();
        if (payload?.paymentMilestoneFlag) {
          Store.dispatch(
            documentUpdated({
              newData: payload,
              oldData: this.draftToSave?.populateFormData
            })
          );
        }
      },
      (err) => {
        console.error('Error while updating Bill: ', err);
        setIsSavingDocument(this.draftId, false);
        showAlertOnDocAPIError(err);
        this.replaceURLCallback?.();
      }
    );
  }

  private updateForm(
    docResp: any,
    payload: any,
    isUpdate = false,
    closeOnUpdate = true
  ) {
    BillService.getBillDetailsByCode(docResp.purchaseInvoiceCode).then(
      (doc: any) => {
        const formData = {
          ...doc,
          documentType: DOC_TYPE.BILL,
          items: [...doc.purchaseInvoiceProducts].map((savedItem: any) => {
            const itemFromDocToSave = this.docToSave?.items?.find(
              (item: any) => item.lineNumber === savedItem.lineNumber
            );
            if (!Utility.isEmptyObject(itemFromDocToSave)) {
              return {
                ...itemFromDocToSave,
                ...savedItem
              };
            }
            return { ...savedItem };
          }),
          isDocumentTouched: false,
          contact: {
            ...doc.contact,
            ...doc.contactDto
          },
          documentDate: payload.documentDate,
          fulfillmentDate: payload.fulfillmentDate,
          validTillDate: payload.validTillDate
        };
        if (!closeOnUpdate) {
          Store.dispatch(
            updateMultipleKeysInDocument({
              draftId: this.draftId,
              keysToUpdate: formData
            })
          );
        } else {
          Store.dispatch(onDocumentClose({ draftId: this.draftId }));
          Store.dispatch(fetchWorkOuts());
          if (!isUpdate) {
            if (payload.showBillCustomAlert) {
              showAlert('Bill created!', 'Bill has been created successfully.');
              // dispatch(updateRefreshCallback(new Date()));
              return;
            }
            getDocumentAlert(
              'Bill created!',
              'Bill has been created successfully.',
              formData,
              this.draftToSave,
              DOCUMENT_MODE.EDIT,
              PAGE_ROUTES.BILLS
            );
            setIsSavingDocument(this.draftId, false);
          }
        }
      },
      (err) => {
        console.error('Error loading updated doc: ', err);
        setIsSavingDocument(this.draftId, false);
      }
    );
  }

  private isDocValid(docToValidate: any, closeDoc: boolean) {
    const taxSystem = getTenantTaxSystem();

    // Validate Manual Document Sequence Code
    if (
      Utility.isEmpty(docToValidate.documentSequenceCode) &&
      Utility.isEmpty(docToValidate.sequenceFormat) &&
      docToValidate.manualMode
    ) {
      return false;
    }

    if (Utility.isEmpty(docToValidate.contact)) {
      return false;
    }

    if (Utility.isEmpty(docToValidate.purchaseInvoiceProducts)) {
      return false;
    }

    if (!Utility.isEmpty(docToValidate.documentDate)) {
      const docDate = DateFormatService.getDateFromStr(
        docToValidate.documentDate,
        BOOKS_DATE_FORMAT['DD-MM-YYYY']
      );
      if (
        !Utility.checkActiveDateRangeValidation(
          docDate,
          this.tenantInfo,
          'Bill date',
          docToValidate.documentType
        )
      ) {
        return false;
      }
      if (!Utility.checkClosingDate(docDate, 'Bill date')) {
        return false;
      }
    }

    // Custom fields validation
    const customFieldHasErrors = customFieldsContainsErrors(
      docToValidate.customField
    );
    if (customFieldHasErrors) {
      return false;
    }
    // Custom fields validation ends

    // Line item errors
    let lineItemsHasErrors = false;
    for (let i = 0; i < docToValidate.purchaseInvoiceProducts.length; i++) {
      const item = docToValidate.purchaseInvoiceProducts[i];
      if (item.hasError || item.invalidFields?.length) {
        lineItemsHasErrors = true;
        break;
      }
    }

    if (lineItemsHasErrors) {
      return false;
    }
    // Line item errors ends

    if (
      taxSystem === TAX_SYSTEM.INDIA_GST &&
      (!docToValidate.shipTo?.state || !docToValidate.shipFrom?.state)
    ) {
      return false;
    }

    // Contact GSTIN check
    if (!checkGSTINPresentForSelectedContact(docToValidate)) {
      return false;
    }
    // Contact GSTIN check ends

    // Check for -ve total amount
    const totalBeforeTax = docToValidate?.purchaseInvoiceProducts?.length
      ? docToValidate?.purchaseInvoiceProducts?.reduce(
          (total: number, item: any) => {
            return total + item?.totalWithDiscount;
          },
          0
        )
      : 0;
    if (totalBeforeTax < 0) {
      showAlert('Invalid amount!', 'Bill amount can not be less than 0.');
      return false;
    }
    // Check for -ve total amount ends

    // Cascading discounts validation
    const cascadingDiscountSettings =
      this.tenantInfo.additionalSettings?.CASCADING_DISCOUNTS;
    if (cascadingDiscountSettings?.enable) {
      const isTotalDiscountInvalid = checkIfTotalDiscountInvalid(
        docToValidate,
        'purchaseInvoiceProducts'
      );
      if (isTotalDiscountInvalid) {
        showAlert('Error!', cascadingDiscountsInvalidMessage);
        return false;
      }
    }
    // Cascading discounts validation ends

    // Additional charges check
    let additionalChargesHasErrors = false;
    const additionalChargesDetails =
      docToValidate.additionalCharges?.additionalChargesDetails;
    if (!Utility.isEmpty(additionalChargesDetails)) {
      for (let i = 0; i < additionalChargesDetails.length; i++) {
        const item = additionalChargesDetails[i];
        if (item.hasError) {
          additionalChargesHasErrors = true;
          break;
        }
      }
    }
    if (additionalChargesHasErrors) {
      return false;
    }

    let manualApportionCharges = additionalChargesDetails.filter(
      (item: any) =>
        item.apportionValue &&
        item.apportionValue === ADDITIONAL_CHARGE_METHODS.APPORTION_MANUAL
    );
    let manualApportionError = false;
    for (let charge of manualApportionCharges) {
      let manualApportionChargesInItems: any[] = [];

      docToValidate.purchaseInvoiceProducts.forEach((item: any) => {
        const additionalCharge =
          item.additionalCharges.additionalChargesDetails.find(
            (c: any) => c.additionalCharge === charge.additionalCharge
          );
        if (!Utility.isEmpty(additionalCharge)) {
          manualApportionChargesInItems.push(additionalCharge);
        }
      });
      if (manualApportionChargesInItems.length) {
        const manualApportionChargesTotal =
          manualApportionChargesInItems.reduce(
            (total: number, detail: any) =>
              total + Number(detail?.chargeAmount || 0),
            0
          );
        if (manualApportionChargesTotal !== +charge.chargeAmount) {
          manualApportionError = true;
          break;
        }
      }
    }
    if (manualApportionError) {
      showAlert(
        'Oops!',
        `One or more line items contains incorrect allocation for additional charges`
      );
      return false;
    }

    // Additional charges check ends

    // Discount check
    const discountHasErrors =
      docToValidate.additionalCharges?.globalDiscount?.hasError;
    if (discountHasErrors) {
      return false;
    }
    // Discount check ends

    // Check tds not deducted
    if (taxSystem === TAX_SYSTEM.INDIA_GST && !this.skipTDS) {
      let tdsNotDeductedArray: any[] = [];
      docToValidate.purchaseInvoiceProducts.forEach((ele: any) => {
        if (!ele.tdsInfoIndia && ele.product.tdsApplicableIndia) {
          tdsNotDeductedArray.push(ele.product.name);
        }
      });
      if (
        tdsNotDeductedArray.length > 0 &&
        (docToValidate.contact.tdsApplicableIndia ||
          (docToValidate.contact.tdsInfoIndia &&
            docToValidate.contact.tdsInfoIndia.deducteeType))
      ) {
        this.openTDSDialog(
          tdsNotDeductedArray
            .map((str: string) => {
              return `<li>${str}</li>`;
            })
            .join(''),
          closeDoc
        );
        return false;
      }
    }
    // Check tds not deducted ends

    // Check if Landed cost true but not allocated
    if (docToValidate.landedCost === true) {
      let productNames: string[] = [];
      docToValidate?.purchaseInvoiceProducts?.forEach((item: any) => {
        if (Utility.isEmpty(item.landedCostDetails)) {
          productNames.push(item.productName);
        }
      });
      if (productNames.length !== 0) {
        let productNamesStr = productNames
          .map((name) => `<li>${name}</li>`)
          .join('');
        showAlert(
          '',
          `<div>Landed cost details are not saved yet for below products. Please check context menu for details.<div><br/><ul>${productNamesStr}</ul>`
        );
        return false;
      }
    }

    const filterAmortizationTemplateWithEmptyDates =
      docToValidate?.purchaseInvoiceProducts?.filter(
        (item: any) =>
          !Utility.isEmpty(item?.amortizationTemplate) &&
          (typeof item?.amortizationStartDate === 'undefined' ||
            item?.amortizationStartDate === null ||
            typeof item?.amortizationEndDate === 'undefined' ||
            item?.amortizationEndDate === null)
      );
    if (filterAmortizationTemplateWithEmptyDates?.length > 0) {
      showAlert('Alert', 'Amortization Start or End  Date should not be empty');
      return false;
    }
    return true;
  }

  private openTDSDialog(productNames = '', closeDoc: boolean) {
    let buttons = [
      {
        title: 'Cancel',
        className: 'bg-gray2 border-m ',
        onClick: () => {
          this.skipTDS = false;
        }
      },
      {
        title: 'Yes',
        className: 'bg-button text-white ml-r',
        onClick: () => {
          this.skipTDS = true;
          if (this.documentMode === DOCUMENT_MODE.EDIT) {
            this.updateBill(closeDoc);
          }
          if (
            this.documentMode === DOCUMENT_MODE.NEW ||
            this.documentMode === DOCUMENT_MODE.COPY
          ) {
            this.createBill(closeDoc);
          }
        }
      }
    ];
    showAlert(
      '',
      `<ul class="text-align-left" style="list-style: disc; margin-left: 14px;">${productNames}</ul><div class="mt-r">TDS is applicable on the above items. Do you wish to skip deducting TDS?</div>`,
      buttons
    );
  }
}
