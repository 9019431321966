import { useState, useEffect, useRef } from 'react';
import {
  DKSidebar,
  showToast,
  TOAST_TYPE,
  showAlert,
  DKIcons
} from 'deskera-ui-library';
import IAM from '../../Services/iam';
import AppManager from '../../Managers/AppManager';

import ic_dashboard from '../../Assets/menu/white/ic_dashboard.svg';
import ic_contacts from '../../Assets/menu/white/ic_contact.svg';
import ic_product from '../../Assets/menu/white/ic_product.svg';
import ic_invoice from '../../Assets/menu/white/ic_invoice.svg';
import ic_order from '../../Assets/menu/white/ic_order.svg';
import ic_assets_management from '../../Assets/menu/white/ic_assets_management.svg';
import ic_settings from '../../Assets/Icons/ic_settings.svg';
import ic_accounting from '../../Assets/Icons/ic_accounting.svg';
import ic_MRP from '../../Assets/Icons/mrp.png';
import ic_bank from '../../Assets/Icons/white/ic_bank.svg';
import ic_warehouse from '../../Assets/Icons/white/ic_warehouse.svg';
import ic_reports from '../../Assets/menu/white/ic_report.svg';
import ic_automation from '../../Assets/menu/white/ic_automation.svg';
import ic_app from '../../Assets/menu/white/ic_app.svg';
import ic_money from '../../Assets/menu/white/ic_money.svg';
import ic_draft from '../../Assets/menu/white/ic_draft.svg';
import ic_form from '../../Assets/menu/white/ic_form.png';

import RouteManager, { PAGE_ROUTES } from '../../Managers/RouteManager';
import { TABLES, TABLE_DISPLAY_NAME } from '../../Constants/TableConstants';
import SideBarService from '../../Services/sidebar';
import TenantService, {
  isSecurityEntryGateTxnEnabled
} from '../../Services/Tenant';
import TenantManager from '../../Managers/TenantManager';
import { useAppDispatch, useAppSelector } from '../../Redux/Hooks';
import {
  activeTenantInfo,
  activeTenantInfoForBookkeeper,
  allTenantsInfo,
  appCustomizationInfo,
  featurePermissions,
  statusData,
  subscriptionInfo,
  userInfo
} from '../../Redux/Slices/AuthSlice';
import { useTranslation } from 'react-i18next';
import { BrowserRouter as Router, useHistory } from 'react-router-dom';
import PopupWrapper from '../../SharedComponents/PopupWrapper';
import {
  BtnType,
  CallBackPayloadType,
  PopupClickActionType,
  UpdateCallBacksRefType
} from '../../Models/Interfaces';
import {
  APP_NAME,
  COUNTRY_CODES,
  MOBILE_APP_SUPPORTED_FEATURES,
  MRP_APP_NAME,
  POPUP_CALLBACKS_TYPE,
  POPUP_CLICK_TYPE,
  POPUP_TYPE,
  STANDLEY_EMAIL,
  TITLE_MRP
} from '../../Constants/Constant';
import NewOrgForm from '../NewOrgForm/NewOrgForm';
import ApiConstants from '../../Constants/ApiConstants';
import Utility, { getCapitalized } from '../../Utility/Utility';
import AuthService from '../../Services/Auth';
import {
  fetchTeamUserDetails,
  teamuserDetails
} from '../../Redux/Slices/BookKeeperTeamSlice';
import { PLANS } from '../../Constants/Enum';
import {
  isRevRecVisible,
  isSalesOrderVisible,
  isFixedAssetVisible
} from '../../SharedComponents/DocumentForm/NewDocumentHelper';
import {
  GranularPermissionsHelper,
  checkUserPermission,
  showNoPermissionAlert
} from '../Settings/GranularPermissions/GranularPermissionsHelper';
import {
  FEATURE_PERMISSIONS,
  PERMISSIONS_BY_MODULE,
  ROUTE_TO_PERMISSION_MODULE_MAPPING
} from '../../Constants/Permission';
import {
  isMobileWebView,
  isTabletSize,
  isTabletView,
  isViewportLarge
} from '../../Utility/ViewportSizeUtils';
import { localizedText } from '../../Services/Localization/Localization';
import { selectPermissionMetadata } from '../../Redux/Slices/PermissionsSlice';
import { RolePermissionUtil } from '../../RolePermission/RolePermissionUtil';
import {
  selectShowSettings,
  setShowSettings
} from '../../Redux/Slices/CommonDataSlice';
import { isMachineEnabled } from '../../Services/MachineMasterService';

interface ISideBarList {
  name: string;
  onClick?: () => void;
  icon?: string;
  className?: string;
  route?: string;
  visible?: boolean;
  expandByDefault?: boolean;
  subItems?: ISideBarList[];
}

export default function LeftMenu(props: any) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [isActionBtnDisabled, setIsActionBtnDisabled] =
    useState<boolean>(false);
  const subscriptionDetails = useAppSelector(subscriptionInfo);
  const featurePermissionsInfo = useAppSelector(featurePermissions);
  // App Selectors
  const allTenantInfo = useAppSelector(allTenantsInfo);
  const tenantDetailsForBookkeeper = useAppSelector(
    activeTenantInfoForBookkeeper
  );
  const tenantDetails = useAppSelector(activeTenantInfo);
  const appCustomizationData = useAppSelector(appCustomizationInfo);
  const authDetails = useAppSelector(statusData);
  const userListData = useAppSelector(userInfo);
  const teamUserData = useAppSelector(teamuserDetails);
  const permissionMetadata = useAppSelector(selectPermissionMetadata);

  const MRP_MACHINES_ITEMS: ISideBarList = {
    name: 'Machine',
    route: PAGE_ROUTES.MRP_MACHINE_TOOL_MASTER,
    subItems: [
      {
        name: 'Machine & Tool Master',
        route: PAGE_ROUTES.MRP_MACHINE_TOOL_MASTER,
        onClick: () => {
          handleMenuItemClick(PAGE_ROUTES.MRP_MACHINE_TOOL_MASTER);
        }
      },
      {
        name: 'Scheduling',
        route: PAGE_ROUTES.MRP_MACHINE_SCHEDULING,
        onClick: () => {
          handleMenuItemClick(PAGE_ROUTES.MRP_MACHINE_SCHEDULING);
        }
      },
      {
        name: 'Maintenance Schedule',
        className: 'text-align-left',
        route: PAGE_ROUTES.MRP_ASSET_MAINTENANCE_SCHEDULE,
        onClick: () => {
          handleMenuItemClick(PAGE_ROUTES.MRP_ASSET_MAINTENANCE_SCHEDULE);
        }
      }
    ]
  };

  const mrpSideBar: ISideBarList = {
    name: TITLE_MRP,
    icon: ic_MRP,
    route: PAGE_ROUTES.MRP_OPERATIONS,
    onClick: () => {
      handleMenuItemClick(PAGE_ROUTES.ACCOUNTING);
    },
    subItems: [
      {
        name: 'Dashboard',
        route: PAGE_ROUTES.MRP_DASHBOARD,
        onClick: () => {
          handleMenuItemClick(PAGE_ROUTES.MRP_DASHBOARD);
        }
      },
      {
        name: 'Work Orders',
        route: PAGE_ROUTES.MRP_WORK_ORDER,
        onClick: () => {
          handleMenuItemClick(PAGE_ROUTES.MRP_WORK_ORDER);
        }
      },
      {
        name: 'Job Cards',
        route: PAGE_ROUTES.MRP_JOB_CARD,
        onClick: () => {
          handleMenuItemClick(PAGE_ROUTES.MRP_JOB_CARD);
        }
      },
      {
        name: 'Forecasting',
        route: PAGE_ROUTES.MRP_FORECASTING,
        onClick: () => {
          handleMenuItemClick(PAGE_ROUTES.MRP_FORECASTING);
        }
      },
      {
        name: 'Master Forecasting',
        route: PAGE_ROUTES.MRP_MASTER_FORECAST,
        onClick: () => {
          handleMenuItemClick(PAGE_ROUTES.MRP_MASTER_FORECAST);
        }
      },
      {
        name: 'Job Work Outs',
        route: PAGE_ROUTES.MRP_WORK_OUT,
        onClick: () => {
          handleMenuItemClick(PAGE_ROUTES.MRP_WORK_OUT);
        }
      },
      {
        name: 'Material Master',
        route: PAGE_ROUTES.MRP_PRODUCTS,
        onClick: () => {
          handleMenuItemClick(PAGE_ROUTES.MRP_PRODUCTS);
        }
      },
      {
        name: 'Operations',
        route: PAGE_ROUTES.MRP_OPERATIONS,
        onClick: () => {
          handleMenuItemClick(PAGE_ROUTES.MRP_OPERATIONS);
        }
      },
      {
        name: 'Workstations',
        route: PAGE_ROUTES.MRP_WORK_STATIONS,
        onClick: () => {
          handleMenuItemClick(PAGE_ROUTES.MRP_WORK_STATIONS);
        }
      },
      {
        name: 'Operators',
        route: PAGE_ROUTES.MRP_OPERATOR_LIST,
        onClick: () => {
          handleMenuItemClick(PAGE_ROUTES.MRP_OPERATOR_LIST);
        }
      },
      {
        name: 'Scheduling',
        route: PAGE_ROUTES.MRP_SCHEDULING,
        onClick: () => {
          handleMenuItemClick(PAGE_ROUTES.MRP_SCHEDULING);
        }
      },
      MRP_MACHINES_ITEMS,
      {
        name: 'Reports',
        route: PAGE_ROUTES.MRP_REPORTS_LIST,
        onClick: () => {
          handleMenuItemClick(PAGE_ROUTES.MRP_REPORTS_LIST);
        }
      },
      {
        name: 'Routing Templates',
        route: PAGE_ROUTES.MRP_ROUTING_TEMPLATES,
        onClick: () => {
          handleMenuItemClick(PAGE_ROUTES.MRP_ROUTING_TEMPLATES);
        }
      }
    ]
  };

  const qualityCheckSidebar: ISideBarList = {
    name: `Quality Check`,
    icon: ic_product,
    route: PAGE_ROUTES.QUALITY_CHECK,
    onClick: () => {
      handleMenuItemClick(PAGE_ROUTES.QUALITY_CHECK);
    }
  };

  const warehouseSideBar: ISideBarList = {
    name: t('SIDEBAR_MENU.' + TABLE_DISPLAY_NAME[TABLES.WAREHOUSE]),
    // icon: ic_warehouse,
    route: PAGE_ROUTES.WAREHOUSE,
    onClick: () => {
      if (subscriptionDetails?.PlanName?.toLowerCase() === PLANS.STARTUP) {
        showAlert(
          'Warning',
          'This feature is not available in your current plan, please upgrade to use.',
          [
            {
              title: 'Cancel',
              className: 'border-m bg-gray1',
              onClick: () => {}
            },
            {
              title: 'Upgrade Now',
              className: 'bg-button text-white ml-m',
              onClick: () => {
                window.open(process.env.REACT_APP_URL_GO + `billing`, '_blank');
              }
            }
          ]
        );
      } else {
        handleMenuItemClick(PAGE_ROUTES.WAREHOUSE);
      }
    }
  };

  const priceListSideBar: ISideBarList = {
    name: tenantDetails.isAdvancePriceListEnabled
      ? TABLE_DISPLAY_NAME[TABLES.PRICE_BOOK]
      : TABLE_DISPLAY_NAME[TABLES.PRICE_LIST],
    route: tenantDetails.isAdvancePriceListEnabled
      ? PAGE_ROUTES.PRICE_BOOK
      : PAGE_ROUTES.PRICE_LIST,
    onClick: () => {
      handleMenuItemClick(
        tenantDetails.isAdvancePriceListEnabled
          ? PAGE_ROUTES.PRICE_BOOK
          : PAGE_ROUTES.PRICE_LIST
      );
    }
  };

  const ppsSideBar: ISideBarList = {
    name: 'Pick Pack Ship',
    // icon: ic_pps,
    route: PAGE_ROUTES.PICK_PACK_SHIP,
    onClick: () => {
      if (subscriptionDetails?.PlanName?.toLowerCase() === PLANS.STARTUP) {
        showAlert(
          'Warning',
          'This feature is not available in your current plan, please upgrade to use.',
          [
            {
              title: 'Cancel',
              className: 'border-m bg-gray1',
              onClick: () => {}
            },
            {
              title: 'Upgrade Now',
              className: 'bg-button text-white ml-m',
              onClick: () => {
                window.open(process.env.REACT_APP_URL_GO + `billing`, '_blank');
              }
            }
          ]
        );
      } else {
        handleMenuItemClick(PAGE_ROUTES.PICK_PACK_SHIP);
      }
    }
  };

  const moneyTabSideBar: ISideBarList = {
    name: t('SIDEBAR_MENU.Money'),
    icon: ic_money,
    route: PAGE_ROUTES.MONEY,
    onClick: () => handleMenuItemClick(PAGE_ROUTES.MONEY)
  };

  const revaluationTabSideBar: ISideBarList = {
    name: t('ACCOUNTING.REVALUATION.TITLE'),
    route: PAGE_ROUTES.REVALUATION,
    onClick: () => {
      handleMenuItemClick(PAGE_ROUTES.REVALUATION);
    }
  };

  const getInventorySidebar = () => {
    const inventory = [
      {
        name: 'Stock Adjustment',
        route: PAGE_ROUTES.STOCK_ADJUSTMENT,
        // icon: ic_warehouse,
        onClick: () => {
          handleMenuItemClick(PAGE_ROUTES.STOCK_ADJUSTMENT);
        }
      },
      {
        name: 'Stock Transfer',
        // icon: ic_warehouse,
        route: PAGE_ROUTES.STOCK_TRANSFER,
        onClick: () => {
          handleMenuItemClick(PAGE_ROUTES.STOCK_TRANSFER);
        }
      }
    ];

    const mrpProduct = subscriptionDetails?.ProductsEnabled?.find(
      (product: any) => {
        return product === MRP_APP_NAME;
      }
    );

    if (
      window.location.origin?.includes(ApiConstants.URL.APP_MRP_URL || '') &&
      !Utility.isEmpty(mrpProduct)
    ) {
      inventory.push(
        {
          name: 'Stock Request',
          // icon: ic_warehouse,
          route: PAGE_ROUTES.STOCK_REQUEST,
          onClick: () => {
            handleMenuItemClick(PAGE_ROUTES.STOCK_REQUEST);
          }
        },
        {
          name: 'Stock Issue',
          // icon: ic_warehouse,
          route: PAGE_ROUTES.STOCK_ISSUE,
          onClick: () => {
            handleMenuItemClick(PAGE_ROUTES.STOCK_ISSUE);
          }
        }
      );
    }

    return inventory;
  };

  const getRevRecSidebar = () => {
    let revRecSideBar = [
      {
        name: 'Revenue Arrangement',
        // icon: ic_warehouse,
        route: PAGE_ROUTES.REVENUE_ARRANGEMENT,
        onClick: () => {
          handleMenuItemClick(PAGE_ROUTES.REVENUE_ARRANGEMENT);
        }
      },
      {
        name: 'Create Rev Rec JE',
        // icon: ic_warehouse,
        route: PAGE_ROUTES.REV_REC_JE,
        onClick: () => {
          handleMenuItemClick(PAGE_ROUTES.REV_REC_JE);
        }
      },
      {
        name: 'Create Reclassification JE',
        route: PAGE_ROUTES.RECLASSIFICATION_JE,
        onClick: () => {
          handleMenuItemClick(PAGE_ROUTES.RECLASSIFICATION_JE);
        }
      }
    ];

    if (
      tenantDetails?.additionalSettings?.REV_REC_CREATION_SETTING &&
      tenantDetails?.additionalSettings?.REV_REC_CREATION_SETTING === 'MANUAL'
    ) {
      revRecSideBar = [
        {
          name: 'Revenue Arrangement',
          // icon: ic_warehouse,
          route: PAGE_ROUTES.REVENUE_ARRANGEMENT,
          onClick: () => {
            handleMenuItemClick(PAGE_ROUTES.REVENUE_ARRANGEMENT);
          }
        },
        {
          name: 'Update Rev Arrangement',
          route: PAGE_ROUTES.UPDATE_REVENUE_ARRANGEMENT,
          onClick: () => {
            handleMenuItemClick(PAGE_ROUTES.UPDATE_REVENUE_ARRANGEMENT);
          }
        },
        {
          name: 'Create Rev Rec JE',
          route: PAGE_ROUTES.REV_REC_JE,
          // icon: ic_warehouse,
          onClick: () => {
            handleMenuItemClick(PAGE_ROUTES.REV_REC_JE);
          }
        },
        {
          name: 'Create Reclassification JE',
          route: PAGE_ROUTES.RECLASSIFICATION_JE,
          onClick: () => {
            handleMenuItemClick(PAGE_ROUTES.RECLASSIFICATION_JE);
          }
        }
      ];
    }
    return revRecSideBar;
  };

  const getAmortizationSidebar = () => {
    const revRecSideBar = [
      {
        name: 'Templates',
        // icon: ic_warehouse,
        route: PAGE_ROUTES.AMORTIZATION_TEMPLATES,
        onClick: () => {
          handleMenuItemClick(PAGE_ROUTES.AMORTIZATION_TEMPLATES);
        }
      },
      {
        name: 'Schedules',
        // icon: ic_warehouse,
        route: PAGE_ROUTES.AMORTIZATION_SCHEDULES,
        onClick: () => {
          handleMenuItemClick(PAGE_ROUTES.AMORTIZATION_SCHEDULES);
        }
      },
      {
        name: 'Create Amortization JE',
        // icon: ic_warehouse,
        route: PAGE_ROUTES.AMORTIZATION_JE,
        onClick: () => {
          handleMenuItemClick(PAGE_ROUTES.AMORTIZATION_JE);
        }
      }
    ];

    return revRecSideBar;
  };

  const getExpenseAllocationSidebar = () => {
    const expenseAllocationSideBar = [
      {
        name: 'Allocation',
        route: PAGE_ROUTES.EXPENSE_ALLOCATION_TEMPLATES,
        onClick: () => {
          handleMenuItemClick(PAGE_ROUTES.EXPENSE_ALLOCATION_TEMPLATES);
        }
      }
    ];

    return expenseAllocationSideBar;
  };

  const initialSideBarList: ISideBarList[] = [
    {
      name: t('SIDEBAR_MENU.Dashboard'),
      icon: ic_dashboard,
      route: PAGE_ROUTES.DASHBOARD,
      onClick: () => {
        handleMenuItemClick(PAGE_ROUTES.DASHBOARD);
      }
    },
    {
      name: 'Sales',
      icon: ic_invoice,
      className: 'left-menu-group',
      visible: true,
      expandByDefault: true,
      subItems: [
        {
          name: Utility.isUSorg()
            ? t('SIDEBAR_MENU.Estimates')
            : t('SIDEBAR_MENU.' + TABLE_DISPLAY_NAME[TABLES.QUOTATION]),
          // icon: ic_quote,
          route: PAGE_ROUTES.QUOTES,
          onClick: () => {
            handleMenuItemClick(PAGE_ROUTES.QUOTES);
          }
        },

        {
          name: t('SIDEBAR_MENU.' + TABLE_DISPLAY_NAME[TABLES.INVOICE]),
          // icon: ic_invoice,
          route: PAGE_ROUTES.INVOICES,
          onClick: () => {
            handleMenuItemClick(PAGE_ROUTES.INVOICES);
          }
        },
        {
          name: localizedText('Fulfillments'),
          // icon: ic_form,
          route: PAGE_ROUTES.FULFILLMENT_MASTER_LIST,
          onClick: () => {
            if (
              checkUserPermission(PERMISSIONS_BY_MODULE.SALES_ORDER.FULFILL) ||
              checkUserPermission(PERMISSIONS_BY_MODULE.QUOTATION.FULFILL) ||
              checkUserPermission(PERMISSIONS_BY_MODULE.INVOICE.FULFILL)
            ) {
              checkAndNavigateRoute(PAGE_ROUTES.FULFILLMENT_MASTER_LIST);
              toggleSideBarByVPSize();
            } else {
              showNoPermissionAlert();
            }
          }
        }
      ]
    },
    {
      name: 'Purchases',
      icon: ic_order,
      className: 'left-menu-group',
      visible: true,
      expandByDefault: true,
      subItems: [
        {
          name: t('SIDEBAR_MENU.' + TABLE_DISPLAY_NAME[TABLES.REQUISITION]),
          // icon: ic_bill,
          route: PAGE_ROUTES.REQUISITIONS,
          onClick: () => {
            handleMenuItemClick(PAGE_ROUTES.REQUISITIONS);
          }
        },
        {
          name: t(
            'SIDEBAR_MENU.' + TABLE_DISPLAY_NAME[TABLES.REQUEST_FOR_QUOTATION]
          ),
          // icon: ic_quote,
          route: PAGE_ROUTES.RFQ,
          onClick: () => {
            handleMenuItemClick(PAGE_ROUTES.RFQ);
          }
        },
        {
          name: t('SIDEBAR_MENU.' + TABLE_DISPLAY_NAME[TABLES.SUPPLIER_QUOTE]),
          // icon: ic_quote,
          route: PAGE_ROUTES.SUPPLIER_QUOTE,
          onClick: () => {
            handleMenuItemClick(PAGE_ROUTES.SUPPLIER_QUOTE);
          }
        },
        {
          name: t('SIDEBAR_MENU.' + TABLE_DISPLAY_NAME[TABLES.PURCHASE_ORDER]),
          // icon: ic_order,
          route: PAGE_ROUTES.ORDERS,
          onClick: () => {
            handleMenuItemClick(PAGE_ROUTES.ORDERS);
          }
        },
        {
          name: t('SIDEBAR_MENU.' + TABLE_DISPLAY_NAME[TABLES.BILL]),
          // icon: ic_bill,
          route: PAGE_ROUTES.BILLS,
          onClick: () => {
            handleMenuItemClick(PAGE_ROUTES.BILLS);
          }
        },
        {
          name: t(
            'SIDEBAR_MENU.' + TABLE_DISPLAY_NAME[TABLES.GOODS_RECEIPT_MASTER]
          ),
          // icon: ic_draft,
          route: PAGE_ROUTES.GOODS_RECEIPT_MASTER_LIST,
          onClick: () => {
            if (
              checkUserPermission(
                PERMISSIONS_BY_MODULE.PURCHASE_ORDER.RECEIVE_GOODS
              ) ||
              checkUserPermission(PERMISSIONS_BY_MODULE.BILL.RECEIVE_GOODS)
            ) {
              handleMenuItemClick(PAGE_ROUTES.GOODS_RECEIPT_MASTER_LIST, true);
            } else {
              showNoPermissionAlert();
            }
          }
        }
      ]
    },
    {
      name: t('SIDEBAR_MENU.' + TABLE_DISPLAY_NAME[TABLES.CONTACT]),
      icon: ic_contacts,
      route: PAGE_ROUTES.CONTACTS,
      onClick: () => {
        handleMenuItemClick(PAGE_ROUTES.CONTACTS);
      }
    },
    {
      name: t('SIDEBAR_MENU.' + TABLE_DISPLAY_NAME[TABLES.PRODUCT]),
      icon: ic_product,
      route: PAGE_ROUTES.PRODUCTS,
      onClick: () => {
        handleMenuItemClick(PAGE_ROUTES.PRODUCTS);
      }
    },
    {
      name: 'Inventory',
      icon: ic_warehouse,
      className: 'left-menu-group',
      visible: true,
      expandByDefault: false,
      subItems: getInventorySidebar()
    },
    {
      name: t('SIDEBAR_MENU.' + TABLE_DISPLAY_NAME[TABLES.BANK]),
      icon: ic_bank,
      route: PAGE_ROUTES.BANKS,
      onClick: () => {
        if (GranularPermissionsHelper.hasBankModulePermission()) {
          handleMenuItemClick(PAGE_ROUTES.BANKS, true);
        } else {
          showNoPermissionAlert();
          return;
        }
      }
    },
    {
      name: 'Amortization',
      icon: ic_bank,
      className: 'left-menu-group',
      visible: true,
      expandByDefault: false,
      subItems: getAmortizationSidebar()
    },
    {
      name: 'Expense Allocation',
      icon: ic_accounting,
      className: 'left-menu-group',
      visible: true,
      expandByDefault: false,
      subItems: getExpenseAllocationSidebar()
    },
    {
      name: t('SIDEBAR_MENU.' + TABLE_DISPLAY_NAME[TABLES.ACCOUNTING]),
      icon: ic_accounting,
      route: PAGE_ROUTES.ACCOUNTING,
      onClick: () => {
        handleMenuItemClick(PAGE_ROUTES.ACCOUNTING);
      },
      subItems: [
        {
          name: t('ACCOUNTING.CHART_OF_ACCOUNTS.TITLE'),
          route: PAGE_ROUTES.CHART_OF_ACCOUNTS,
          onClick: () => {
            handleMenuItemClick(PAGE_ROUTES.CHART_OF_ACCOUNTS);
          }
        },
        {
          name: t('ACCOUNTING.JOURNAL_ENTRY.TITLE'),
          route: PAGE_ROUTES.JOURNAL_ENTRY,
          onClick: () => {
            handleMenuItemClick(PAGE_ROUTES.JOURNAL_ENTRY);
          }
        },
        {
          name: t('ACCOUNTING.OPENING_BALANCES.TITLE'),
          route: PAGE_ROUTES.OPENING_BALANCE,
          onClick: () => {
            handleMenuItemClick(PAGE_ROUTES.OPENING_BALANCE);
          }
        },
        {
          name: t('ACCOUNTING.DEBIT_NOTES.TITLE'),
          route: PAGE_ROUTES.DEBIT_NOTES,
          onClick: () => {
            handleMenuItemClick(PAGE_ROUTES.DEBIT_NOTES);
          }
        },
        {
          name: t('ACCOUNTING.CREDIT_NOTES.TITLE'),
          route: PAGE_ROUTES.CREDIT_NOTES,
          onClick: () => {
            handleMenuItemClick(PAGE_ROUTES.CREDIT_NOTES);
          }
        },
        {
          name: t('ACCOUNTING.EXPENSE.TITLE'),
          route: PAGE_ROUTES.EXPENSE,
          onClick: () => {
            handleMenuItemClick(PAGE_ROUTES.EXPENSE);
          }
        },
        {
          name: t('ACCOUNTING.DEPOSIT.TITLE'),
          route: PAGE_ROUTES.DEPOSIT,
          onClick: () => {
            handleMenuItemClick(PAGE_ROUTES.DEPOSIT);
          }
        },
        {
          name: t('ACCOUNTING.FINANCIAL_YEAR_CLOSING.TITLE'),
          route: PAGE_ROUTES.FINANCIAL_YEAL_CLOSING,
          onClick: () => {
            if (
              RolePermissionUtil.checkIsAdmin() ||
              RolePermissionUtil.checkIsOwner()
            ) {
              handleMenuItemClick(PAGE_ROUTES.FINANCIAL_YEAL_CLOSING);
            } else {
              showAlert(
                'No Permissions!',
                `You don't have permission to view this module, please contact owner/admin of this ${localizedText(
                  'organisation'
                )}`
              );
            }
          }
        },
        {
          name: 'Budgeting',
          route: PAGE_ROUTES.BUDGETING,
          onClick: () => {
            handleMenuItemClick(PAGE_ROUTES.BUDGETING);
          }
        }
      ]
    },
    {
      name: t('SIDEBAR_MENU.' + TABLE_DISPLAY_NAME[TABLES.AUTOMATIONS]),
      icon: ic_automation,
      route: PAGE_ROUTES.AUTOMATIONS,
      onClick: () => {
        handleMenuItemClick(PAGE_ROUTES.AUTOMATIONS);
      }
    },
    {
      name: t('SIDEBAR_MENU.' + TABLE_DISPLAY_NAME[TABLES.REPORTS]),
      route: PAGE_ROUTES.REPORTS,
      icon: ic_reports,
      onClick: () => {
        handleMenuItemClick(PAGE_ROUTES.REPORTS);
      }
    },

    {
      name: t('SIDEBAR_MENU.' + TABLE_DISPLAY_NAME[TABLES.APPS]),
      icon: ic_app,
      route: PAGE_ROUTES.APPS,
      onClick: () => {
        window.open(ApiConstants.URL.APP_STORE_URL, '');
        toggleSideBarByVPSize();
      }
    },
    {
      name: 'Logs',
      icon: DKIcons.white.ic_document,
      visible: true,
      route: PAGE_ROUTES.SETTINGS_LOGS,
      className: 'left-menu-group',
      onClick: () => {
        handleMenuItemClick(PAGE_ROUTES.SETTINGS_LOGS);
      }
    },
    {
      name: t('SIDEBAR_MENU.' + TABLE_DISPLAY_NAME[TABLES.SETTINGS]),
      icon: ic_settings,
      onClick: () => {
        dispatch(setShowSettings(true));
      }
    }
  ];

  const initialMenuState = {
    isMenuExpanded: SideBarService.isSideBarExpanded,
    needProfileOptionsSection: false,
    needOrgListPicker: false,
    allOrgs: [] as any[],
    selectedTenantDetails: null as any,
    showAddNewOrgPopup: false
  };

  const refInitialState: UpdateCallBacksRefType = {
    pushDataToParent: { type: POPUP_CALLBACKS_TYPE.NONE },
    storeCallbacksRef: { createNewOrg: 'click' }
  };

  const paymentPopupBtnConfig: BtnType[] = [
    {
      title: 'Cancel',
      class: 'border-m mr-s',
      clickAction: POPUP_CLICK_TYPE.CLOSE_POPUP
    },
    {
      title: 'Save',
      class: 'bg-app text-white mr-ss',
      clickAction: POPUP_CLICK_TYPE.CREATE_NEW_ORG
    }
  ];

  // States
  const [menuState, setMenuState] = useState(initialMenuState);
  const [sideBarList, setSideBarList] = useState([...initialSideBarList]);
  const [showOrgFormPopup, setShowOrgFormPopup] = useState<boolean>(false);
  const leftMenuRef = useRef<UpdateCallBacksRefType>(refInitialState);

  useEffect(() => {
    window.addEventListener('onSideBarChange', onSideChange);
    window.addEventListener('resize', handleSidebarOnResize);
    return () => {
      window.removeEventListener('onSideBarChange', onSideChange);
      window.removeEventListener('resize', handleSidebarOnResize);
    };
  }, []);

  useEffect(() => {
    let updatedSideBarList = initialSideBarList.slice();

    const hiddenAppsInfo = {
      warehouse: false,
      priceList: false,
      ewayBill: false,
      pps: false
    };
    const str = appCustomizationData.hiddenApps;
    if (str) {
      const hiddenApps: string[] = str.split(',');
      hiddenApps.forEach((app) => {
        if (app === 'WAREHOUSE') {
          hiddenAppsInfo.warehouse = true;
        } else if (app === 'PRICE_LIST') {
          hiddenAppsInfo.priceList = true;
        } else if (app === 'E_WAY') {
          hiddenAppsInfo.ewayBill = true;
        } else if (app === 'PPS') {
          hiddenAppsInfo.pps = true;
        }
      });
    }
    if (
      (!hiddenAppsInfo.priceList || tenantDetails?.isAdvancePriceListEnabled) &&
      !authDetails?.isBookKeeper
    ) {
      updatedSideBarList[1]?.subItems?.splice(3, 0, priceListSideBar);
    }
    if (!hiddenAppsInfo.warehouse && !authDetails?.isBookKeeper) {
      updatedSideBarList
        .find((sb: any) => sb.name === 'Inventory')
        ?.subItems?.splice(0, 0, warehouseSideBar);
      //   updatedSideBarList[6]?.subItems?.splice(0, 0, warehouseSideBar);
    }
    if (!hiddenAppsInfo.pps && !authDetails?.isBookKeeper) {
      updatedSideBarList[5]?.subItems?.push(ppsSideBar);
    }

    // MRP Sidebar
    const mrpProduct = subscriptionDetails?.ProductsEnabled?.find(
      (product: any) => {
        return product === MRP_APP_NAME;
      }
    );
    if (
      window.location.origin?.includes(ApiConstants.URL.APP_MRP_URL || '') &&
      !Utility.isEmpty(mrpProduct)
    ) {
      let mrpList = mrpSideBar.subItems ? [...mrpSideBar.subItems] : [];
      if (!isMachineEnabled()) {
        let machineList = [PAGE_ROUTES.MRP_MACHINE_TOOL_MASTER];
        mrpList = mrpList?.filter((m) => !machineList.includes(m.route as any));
      }
      mrpSideBar.subItems = mrpList;
      updatedSideBarList.splice(7, 0, mrpSideBar);
      updatedSideBarList.splice(6, 0, qualityCheckSidebar);
    }
    if (isRevRecVisible()) {
      updatedSideBarList.splice(7, 0, {
        name: 'Revenue Recognition',
        icon: ic_bank,
        className: 'left-menu-group',
        visible: true,
        expandByDefault: false,
        subItems: getRevRecSidebar()
      });
    }

    if (isSalesOrderVisible()) {
      updatedSideBarList[1]?.subItems?.splice(1, 0, {
        name: 'Sales Orders',
        // icon: ic_so_white,
        route: PAGE_ROUTES.SALES_ORDERS,
        onClick: () => {
          handleMenuItemClick(PAGE_ROUTES.SALES_ORDERS);
        }
      });
    }
    if (Utility.isBankFileGenerationEnabled()) {
      const index = updatedSideBarList[1]?.subItems?.findIndex(
        (subMenu) =>
          subMenu.name ===
          t('SIDEBAR_MENU.' + TABLE_DISPLAY_NAME[TABLES.INVOICE])
      );
      if (index !== -1 && typeof index === 'number') {
        updatedSideBarList[1]?.subItems?.splice(index + 1, 0, {
          name: 'Generate Bank File',
          // icon: ic_so_white,
          route: PAGE_ROUTES.GENERATE_BANK_FILE,
          onClick: () => {
            handleMenuItemClick(PAGE_ROUTES.GENERATE_BANK_FILE, true);
          }
        });
      }
    }

    if (isSecurityEntryGateTxnEnabled()) {
      updatedSideBarList[2]?.subItems?.push({
        name: TABLE_DISPLAY_NAME[TABLES.SECURITY_GATE_ENTRY],
        icon: undefined,
        onClick: () => {
          handleMenuItemClick(PAGE_ROUTES.SECURITY_GATE_ENTRY_LIST);
        }
      });
    }

    if (
      AuthService.getUserEmail()?.toLowerCase() === STANDLEY_EMAIL.toLowerCase()
    ) {
      let index = updatedSideBarList.findIndex(
        (list: any) => list.name?.toLowerCase() === 'accounting'
      );
      if (index !== -1) {
        updatedSideBarList.splice(index, 1);
      }
      let index2 = updatedSideBarList.findIndex(
        (list: any) => list.name?.toLowerCase() === 'banks'
      );
      if (index2 !== -1) {
        updatedSideBarList.splice(index2, 1);
      }
    }

    if (isFixedAssetVisible()) {
      updatedSideBarList.splice(3, 0, {
        name: 'Asset Management',
        icon: ic_assets_management,
        className: 'left-menu-group',
        visible: true,
        expandByDefault: true,
        subItems: [
          {
            name: t('SIDEBAR_MENU.' + TABLE_DISPLAY_NAME[TABLES.FIXED_ASSET]),
            // icon: ic_quote,
            route: PAGE_ROUTES.FIXED_ASSET,
            onClick: () => {
              handleMenuItemClick(PAGE_ROUTES.FIXED_ASSET);
            }
          },
          {
            name: t('SIDEBAR_MENU.' + TABLE_DISPLAY_NAME[TABLES.ASSET_GROUP]),
            // icon: ic_quote,
            route: PAGE_ROUTES.ASSET_GROUP,
            onClick: () => {
              handleMenuItemClick(PAGE_ROUTES.ASSET_GROUP);
            }
          },
          {
            name: t(
              'SIDEBAR_MENU.' + TABLE_DISPLAY_NAME[TABLES.DEPRECIATION_SCHEDULE]
            ),
            // icon: ic_quote,
            route: PAGE_ROUTES.DEPRECIATION_SCHEDULE,
            onClick: () => {
              handleMenuItemClick(PAGE_ROUTES.DEPRECIATION_SCHEDULE);
            }
          }
        ]
      });
    }

    if (tenantDetails?.country === COUNTRY_CODES.IN) {
      updatedSideBarList.push(moneyTabSideBar);
    }

    if (
      tenantDetails &&
      tenantDetails?.additionalSettings?.MULTI_COMPANY !== undefined
    ) {
      var accountTabList = updatedSideBarList.find(
        (x) => x.route === PAGE_ROUTES.ACCOUNTING
      );
      if (accountTabList !== undefined) {
        if (accountTabList.subItems) {
          accountTabList.subItems?.push(revaluationTabSideBar);
        }
      }
    }

    if (
      !Utility.isEmpty(authDetails) &&
      authDetails.isBookKeeper &&
      tenantDetailsForBookkeeper.isBookKeeper
    ) {
      if (
        updatedSideBarList?.findIndex((subList: any) => {
          return subList.name?.toLowerCase() === 'bookkeeper';
        }) === -1
      ) {
        updatedSideBarList.unshift({
          name: 'BookKeeper',
          icon: ic_accounting,
          route: '',
          onClick: () => {
            handleMenuItemClick(PAGE_ROUTES.ACCOUNTING);
          },
          subItems: [
            {
              name: 'Client',
              route: PAGE_ROUTES.BK_CLIENT,
              onClick: () => handleMenuItemClick(PAGE_ROUTES.BK_CLIENT)
            }
          ]
        });
      }
    }

    if (authDetails?.isBookKeeper && tenantDetailsForBookkeeper.isBookKeeper) {
      updatedSideBarList.forEach((item: any) => {
        if (
          item.name.toLowerCase() === 'bookkeeper' &&
          teamUserData?.isDefaultUser
        ) {
          if (
            item?.subItems?.findIndex(
              (subItem: any) => subItem.name?.toLowerCase() === 'team'
            ) === -1
          ) {
            item.subItems.push({
              name: 'Team',
              route: PAGE_ROUTES.BK_TEAM,
              onClick: () => handleMenuItemClick(PAGE_ROUTES.BK_TEAM)
            });
          }
        }
      });
    }

    if (isMobileWebView() && !isTabletView()) {
      updatedSideBarList = updatedSideBarList.filter((item) =>
        MOBILE_APP_SUPPORTED_FEATURES.includes(item.name)
      );
    }

    // hide amortization if not enabled
    if (
      !featurePermissionsInfo?.Supported?.includes(
        FEATURE_PERMISSIONS.AMORTIZATION
      ) ||
      !tenantDetails?.additionalSettings?.AMORTIZATION
    ) {
      updatedSideBarList = updatedSideBarList.filter(
        (item) => item.name !== 'Amortization'
      );
    }

    // hide label print if not enabled
    if (
      !featurePermissionsInfo?.Supported?.includes(
        FEATURE_PERMISSIONS.LABEL_PRINT
      ) ||
      !tenantDetails?.additionalSettings?.LABEL_PRINT
    ) {
      updatedSideBarList = updatedSideBarList.filter(
        (item) => item.name !== 'Label Template'
      );
    }

    // hide expense allocation if not enabled
    if (
      !featurePermissionsInfo?.Supported?.includes(
        FEATURE_PERMISSIONS.EXPENSE_ALLOCATION
      ) ||
      !tenantDetails?.additionalSettings?.ENABLE_EXPENSE_ALLOCATION
    ) {
      updatedSideBarList = updatedSideBarList.filter(
        (item) => item.name !== 'Expense Allocation'
      );
    }

    setSideBarList([...updatedSideBarList]);
    setMenuState((prevState) => {
      return {
        ...prevState,
        selectedTenantDetails: {
          tenantName: tenantDetails?.name,
          tenantId: tenantDetails?.id
        }
      };
    });
    if (authDetails?.isBookKeeper) {
      localStorage.setItem('isBookKepper', 'true'); // since boolean is not accepted in local storage.
    }
  }, [
    appCustomizationData,
    tenantDetails,
    authDetails,
    teamUserData,
    tenantDetailsForBookkeeper,
    subscriptionDetails,
    permissionMetadata
  ]);

  useEffect(() => {
    RouteManager.setLeftMenus(sideBarList);
  }, [sideBarList]);

  useEffect(() => {
    window.addEventListener('onSideBarChange', onSideChange);
    return () => {
      window.removeEventListener('onSideBarChange', onSideChange);
    };
  }, []);

  useEffect(() => {
    if (Utility.isEmpty(allTenantInfo)) return;

    TenantService.getAllClientTenants()
      .then((res: any) => {
        TenantManager.setAllClientTenants(res);
        let newRes = res?.map((item: any) => {
          return {
            ...item,
            tenantName:
              item.tenantName + ' ' + '<span class="fw-b">(Client)</span>'
          };
        });
        const tenantsWithBKClients = allTenantInfo.concat(newRes);
        setMenuState((prevState) => ({
          ...prevState,
          allOrgs: tenantsWithBKClients
        }));
      })
      .catch((err: any) => {
        setMenuState((prevState) => ({
          ...prevState,
          allOrgs: allTenantInfo
        }));
      });
  }, [allTenantInfo]);

  useEffect(() => {
    if (!Utility.isEmpty(userListData) && authDetails?.isBookKeeper) {
      const user = userListData[0];
      loadTeamUserData(user?.id);
    }
  }, [userListData]);

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////

  const loadTeamUserData = async (userId: any) => {
    try {
      await dispatch(fetchTeamUserDetails(userId));
    } catch {
      console.log('team user data fetch failed');
    }
  };

  const onSideChange = () => {
    setMenuState((prevState) => ({
      ...prevState,
      isMenuExpanded: SideBarService.isSideBarExpanded
    }));
  };

  const onSelectTenant = (newSelectedTenantDetails: any) => {
    if (
      newSelectedTenantDetails.tenantId !==
      menuState.selectedTenantDetails.tenantId
    ) {
      IAM.switchTenant({ tenantId: newSelectedTenantDetails.tenantId }).then(
        (res) => {
          AppManager.refreshApp();
        },
        (err) => {}
      );
    } else {
      showToast(`Current ${localizedText('organisation')}`, TOAST_TYPE.SUCCESS);
    }
  };

  const catchClicks = (data: PopupClickActionType) => {
    switch (data.type) {
      case POPUP_CLICK_TYPE.CLOSE_POPUP:
        setShowOrgFormPopup(false);
        setIsActionBtnDisabled(false);
        break;
      case POPUP_CLICK_TYPE.CREATE_NEW_ORG:
        leftMenuRef.current?.storeCallbacksRef.createNewOrg();
        break;
      default:
        break;
    }
  };

  const parentChildInteraction = (passingData: CallBackPayloadType) => {
    switch (passingData.type) {
      case POPUP_CALLBACKS_TYPE.CREATE_NEW_ORG:
        leftMenuRef.current.storeCallbacksRef.createNewOrg = passingData.data;
        break;
      case POPUP_CALLBACKS_TYPE.CLOSE_POPUP:
        setShowOrgFormPopup(false);
        setIsActionBtnDisabled(false);
        break;
      case POPUP_CALLBACKS_TYPE.API_CALL_IN_PROGRESS:
        setIsActionBtnDisabled(true);
        break;
      default:
        break;
    }
  };

  const handleSidebarOnResize = () => {
    SideBarService.setIsSideBarExpanded();
    SideBarService.triggerSidebarChangeEvent();
  };

  const checkAndNavigateRoute = (route: string) => {
    route && RouteManager.navigateToPage(route);
  };

  const toggleSideBarByVPSize = () => {
    (!isViewportLarge() || isTabletSize()) && SideBarService.toggleSideBar();
  };

  const handleMenuItemClick = (route: any, byPassPermissionCheck = false) => {
    const permissionToCheckFor = ROUTE_TO_PERMISSION_MODULE_MAPPING[route];
    if (
      byPassPermissionCheck ||
      permissionToCheckFor === PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT
    ) {
      checkAndNavigateRoute(route);
      toggleSideBarByVPSize();
    }
    if (checkUserPermission(permissionToCheckFor)) {
      checkAndNavigateRoute(route);
      toggleSideBarByVPSize();
    } else {
      showNoPermissionAlert();
    }
  };

  return (
    <div>
      <Router>
        <div
          className={`transparent-background parent-height parent-width position-absolute ${
            menuState.isMenuExpanded
              ? 'display-only-mobile z-index-5'
              : 'display-none z-index-0'
          }`}
          onClick={() => SideBarService.toggleSideBar()}
        />
        <DKSidebar
          className="z-index-6 mobile-sidebar-pb"
          allowTenantSelection={!isMobileWebView()}
          tenantPickerTitle={`My ${getCapitalized(
            localizedText('organisation')
          )}s`}
          tenantPickerButtonTitle={`+ Add ${getCapitalized(
            localizedText('organisation')
          )}`}
          collapsedWidth={0}
          expandedWidth={230}
          menuItemList={sideBarList}
          tenantList={menuState.allOrgs}
          isMenuExpanded={menuState.isMenuExpanded}
          selectedTenantDetails={menuState.selectedTenantDetails}
          //  FOR CUSTOMIZING ADD_NEW_ORG UX
          onAddNewTenantTapped={() => {
            setShowOrgFormPopup(true);
          }}
          onTenantSelect={(newTenantDetails: any) => {
            onSelectTenant(newTenantDetails);
          }}
          onExpandCollapse={() => SideBarService.toggleSideBar()}
        />
      </Router>
      {showOrgFormPopup && (
        <PopupWrapper
          clickAction={catchClicks}
          type={POPUP_TYPE.POPUP}
          title={`New ${getCapitalized(localizedText('organisation'))}`}
          btnList={paymentPopupBtnConfig}
          width={'45%'}
          height={'auto'}
          overflowVisible={true}
          isActionBtnDisabled={isActionBtnDisabled}
        >
          <NewOrgForm
            passingInteraction={(callback: CallBackPayloadType) => {
              parentChildInteraction(callback);
            }}
            isAddNewOrgFlow={true}
          />
        </PopupWrapper>
      )}
    </div>
  );
}
