import ApiConstants from '../../Constants/ApiConstants';
import Utility from '../../Utility/Utility';
import http from '../Interceptor';

export interface JobCardAPIConfig {
  SearchTerm?: string;
  Limit?: number;
  Page?: number;
  sort?: string;
  sortBy?: string;
  Query?: string;
  QueryParam?: any;
  fields?: string;
}

export const defaultConfig: JobCardAPIConfig = {
  SearchTerm: '',
  Limit: 500,
  Page: 0,
  sort: 'DESC',
  sortBy: 'jobCardCode',
  Query: '',
  QueryParam: ''
};

class JobCardService {
  static apiConfig: JobCardAPIConfig;
  static getConfigUrl() {
    let urlString = `?limit=${
      Utility.isEmpty(this.apiConfig.Limit) ? 500 : this.apiConfig.Limit
    }&search=${this.apiConfig?.SearchTerm || ''}&page=${
      this.apiConfig?.Page || 0
    }&sort=${
      this.apiConfig.sortBy ? this.apiConfig.sortBy : 'jobCardCode'
    }&query=${this.apiConfig?.Query || ''}${
      this.apiConfig.QueryParam ? this.apiConfig.QueryParam : ''
    }&sortDir=${this.apiConfig.sort ? this.apiConfig.sort : 'DESC'}&fields=${
      this.apiConfig.fields ? this.apiConfig.fields : ''
    }`;
    return urlString;
  }

  static fetchAllJobCards() {
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    }
    let finalURL =
      ApiConstants.URL.MRP.JOB_CARD.GET_ALL_JOB_CARD + this.getConfigUrl();
    return http
      .get(finalURL)
      .then((response: any) => {
        return Promise.resolve(response);
      })
      .catch((err: any) => {
        return Promise.reject(err);
      });
  }

  static fetchAllJobCardsForWorkOrder(url: string) {
    return http
      .get(url)
      .then((response: any) => {
        return Promise.resolve(response);
      })
      .catch((err: any) => {
        return Promise.reject(err);
      });
  }

  static deleteJobCard(id: any) {
    return http
      .delete(`${ApiConstants.URL.MRP.JOB_CARD.DELETE_JOB_CARD}`, {
        data: id
      })
      .catch((err: any) => {
        console.error('Error deleting work order: ', err);
      });
  }

  static addJobCard(payload: any) {
    return http
      .post(ApiConstants.URL.MRP.JOB_CARD.ADD_WORK_ORDER, payload)
      .catch((err: any) => {
        console.error('Error while adding job card: ', err);
        return Promise.reject(err);
      });
  }

  static updateJobCard(payload: any, id: any) {
    return http
      .put(ApiConstants.URL.MRP.JOB_CARD.EDIT_JOB_CARD(id), payload)
      .catch((err: any) => {
        console.error('Error while updating job card: ', err);
        return Promise.reject(err);
      });
  }

  static updatePatchJobCard(payload: any, id: any) {
    return http
      .patch(ApiConstants.URL.MRP.JOB_CARD.EDIT_JOB_CARD(id), payload)
      .catch((err: any) => {
        console.error('Error while updating job card: ', err);
        return Promise.reject(err);
      });
  }

  static updateBulkJobCardStatus(status: any, jcIds: string[]) {
    return http
      .put(ApiConstants.URL.MRP.JOB_CARD.UPDATE_BULK_STATUS(status), jcIds)
      .catch((err: any) => {
        console.error('Error while updating job card bulk status: ', err);
        return Promise.reject(err);
      });
  }

  static fetchAllWOLinkedJobCards(WORK_ORDER_ID: string) {
    let finalURL = ApiConstants.URL.MRP.JOB_CARD.GET_WO_JOB_CARD(WORK_ORDER_ID);
    return http
      .get(finalURL)
      .then((response: any) => {
        return Promise.resolve(response);
      })
      .catch((err: any) => {
        return Promise.reject(err);
      });
  }

  static bulkUpdateJobCard(payload: any) {
    return http
      .put(ApiConstants.URL.MRP.JOB_CARD.BULK_UPDATE_JC, payload)
      .catch((err: any) => {
        console.error('Error while updating job cards: ', err);
        return Promise.reject(err);
      });
  }

  static bulkPartialDataUpdateJobCard(payload: any) {
    return http
      .post(ApiConstants.URL.MRP.JOB_CARD.UPDATE_BULK_DATA, payload)
      .catch((err: any) => {
        console.error('Error while updating job cards: ', err);
        return Promise.reject(err);
      });
  }

  // QC
  static fetchJobCardsQCData(productCode: string, jobCardCode: string) {
    let finalURL =
      ApiConstants.URL.MRP.PROCESS_MANUFACTURE.GET_JC_QC +
      `${productCode}?jobCardCode=${jobCardCode}`;
    return http
      .get(finalURL)
      .then((response: any) => {
        return Promise.resolve(response);
      })
      .catch((err: any) => {
        return Promise.reject(err);
      });
  }
  static updateJobCardQC(payload: any) {
    return http
      .put(ApiConstants.URL.MRP.JOB_CARD.UPDATE_JC_QC, payload)
      .catch((err: any) => {
        console.error('Error while updating job card: ', err);
        return Promise.reject(err);
      });
  }
}

export default JobCardService;
