import {
  CUSTOM_FIELD_TYPE,
  DOCUMENT_MODE,
  MODULES_NAME,
  STATUS_TYPE
} from '../Constants/Constant';
import { ReactSelectOptionsType } from './Interfaces';
import { DropdownTag, InputTag } from './NewContact';
import { IColumn } from './Table';

export interface CustomFieldPayload {
  attributes?: any[];
  decimalPrecision?: number;
  defaultValue: string;
  fieldType: CUSTOM_FIELD_TYPE;
  label: string;
  mandatory: boolean;
  maxLength?: number;
  modules: MODULES_NAME[];
  parent?: Object;
}

export interface CustomFieldResponse {
  id: number;
  label: string;
  description: null | string;
  shortName: string;
  maxLength: number;
  code: string;
  fieldType: CUSTOM_FIELD_TYPE;
  mandatory: boolean;
  modules: MODULES_NAME[];
  defaultValue: string;
  status: STATUS_TYPE;
  appName: string;
  customFieldIndex: number;
  isModuleMapActive: boolean;
  attributes?: Attribute[];
  decimalPrecision?: number;
  parent?: any;
}

export interface Attribute {
  id: number;
  value: string;
  code: string;
  dimensionId: number;
  dimensionName: string;
  defaultOption: boolean;
  status: STATUS_TYPE;
  orderIndex: number;
  parentIdList?: string[];
}

export interface CustomField {
  code: string;
  customFieldIndex: number | null;
  label: string;
  module: MODULES_NAME | null;
  modules?: MODULES_NAME[];
  status: STATUS_TYPE;
  value: string;
  isSelected?: boolean;
  attributes?: Attribute[];
  shortName?: string;
  maxLength?: number;
  id?: number;
  fieldType?: CUSTOM_FIELD_TYPE;
  mandatory?: boolean;
  defaultValue?: string;
  system?: boolean;
  defaultOption?: boolean;
}

export interface CustomFieldsHolderState {
  fetchedCustomFields: CustomFieldResponse[];
  existingCustomFields: CustomField[];
  moduleName: MODULES_NAME;
  customFieldsList: (InputTag<string> | DropdownTag<string>)[];
  availableCustomFields: ReactSelectOptionsType<string, string>[];
  showDropdown: boolean;
}

export interface CustomFieldsHolderProps {
  moduleName: MODULES_NAME;
  customFieldsList: CustomField[];
  columnConfigTableId?: string;
  columnConfig?: IColumn[];
  documentMode?: DOCUMENT_MODE;
  draftType?: string;
  onUpdate: (cfList: CustomField[]) => void;
  onLocationUpdate?: (location: any) => void;
  contact?: any;
  style?: any;
  valueToTriggerLocalChange?: any;
  hideAddfieldButton?: boolean;
  addressUpdate?: any;
  updateAddressField?: any;
}

export const initialCustomFieldsHolderState: CustomFieldsHolderState = {
  fetchedCustomFields: [],
  existingCustomFields: [],
  moduleName: MODULES_NAME.CONTACT,
  customFieldsList: [],
  availableCustomFields: [],
  showDropdown: false
};
