import ApiConstants from '../Constants/ApiConstants';
import Utility from '../Utility/Utility';
import http from './Interceptor';
import { saveAs } from 'file-saver';
import { showAlert } from 'deskera-ui-library';
import { EXPORT_ERROR } from '../Constants/Constant';

export interface JournalAPIConfig {
  SearchTerm?: string;
  Limit?: number;
  Page?: number;
  SKIP_REQUEST_INTERCEPTOR?: boolean;
  Query?: any;
  QueryParam?: any;
}

const defaultConfig: JournalAPIConfig = {
  SearchTerm: '',
  Limit: 25,
  Page: 0,
  SKIP_REQUEST_INTERCEPTOR: true, //2020-08-24
  Query: [],
  QueryParam: ''
};

class JournalService {
  static apiConfig: JournalAPIConfig = defaultConfig;

  static getJournal() {
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    }
    const queryString: string = `?search=${
      this.apiConfig.SearchTerm
    }&SKIP_REQUEST_INTERCEPTOR=${
      this.apiConfig.SKIP_REQUEST_INTERCEPTOR
    }&limit=${this.apiConfig.Limit}&page=${this.apiConfig.Page}${
      this.apiConfig.Query ? '&query=' + this.apiConfig.Query : ''
    }${this.apiConfig.QueryParam ? this.apiConfig.QueryParam : ''}`;
    const finalEndpoint: string =
      ApiConstants.URL.JOURNAL_ENTRY.FETCH_JOURNAL_ENTRY + queryString;
    return http.get(`${finalEndpoint}`).catch((err: any) => {
      console.error('Error loading Journal: ', err);
      return Promise.reject(err);
    });
  }

  static saveJournal(data: any) {
    return http
      .post(ApiConstants.URL.JOURNAL_ENTRY.FETCH_JOURNAL_ENTRY, data)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static getJournalByPerspective(payload:{
    amountInPrimaryPerspective: string;
    baseCurrency: string;
    documentCode: string;
    jeCode: string;
    primaryCurrency: string;
    type: string;
  }) {
    return http.post(ApiConstants.URL.JOURNAL_ENTRY.FETCH_JOURNAL_ENTRY_BY_PERSPECTIVE, payload)
  }
  static updateJournal(data: any) {
    return http
      .put(
        ApiConstants.URL.JOURNAL_ENTRY.FETCH_JOURNAL_ENTRY + `/${data.id}`,
        data
      )
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static saveJournalBankTransfer(data: any) {
    return http
      .post(ApiConstants.URL.JOURNAL_ENTRY.SAVE_JOURNAL_BANK_TRANSFER, data)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static updateJournalBankTranfer(data: any) {
    return http
      .put(
        ApiConstants.URL.JOURNAL_ENTRY.SAVE_JOURNAL_BANK_TRANSFER +
          `/${data.id}`,
        data
      )
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static deleteJournalNormal(data: any) {
    return http
      .delete(
        ApiConstants.URL.JOURNAL_ENTRY.FETCH_JOURNAL_ENTRY + `/${data.id}`
      )
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static deleteJournalBankTranfer(data: any) {
    return http
      .delete(
        ApiConstants.URL.JOURNAL_ENTRY.SAVE_JOURNAL_BANK_TRANSFER +
          `/${data.id}`
      )
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static export(
    startDate: string,
    endDate: string,
    searchText: any,
    customField: any
  ) {
    const fileName = `JOURNAL_ENTRY.xlsx`;
    const finalEndpoint = `reports/common/journal-entry?format=XLS&var-start_date=${startDate}&var-end_date=${endDate}&jeSeq=${searchText}&${customField}`;
    return http
      .get(finalEndpoint, {
        responseType: 'blob'
      })
      .then((res: any) => {
        const reader = new FileReader();
        reader.onload = function (event) {
          let blobData = String(event?.target?.result);
          try {
            showAlert('Alert', JSON.parse(blobData).exportMessage);
          } catch (e) {
            saveAs(res, fileName);
          }
        };
        reader.readAsText(res);   
      })
      .catch((err: any) => {
        console.error('Error while trying to get account: ', err);
        if (
           err?.status === EXPORT_ERROR.EXPORT_LOCK_CODE ||
           err?.code === EXPORT_ERROR.EXPORT_LOCK_CODE
        ) {
           showAlert('Error', EXPORT_ERROR.EXPORT_LOCK_WARNING_MSG);
        } else {
           return Promise.reject(err);
        }
      });
  }
  static qbExport(
    startDate: string,
    endDate: string,
    searchText: any,
    customField: any
  ) {
    const fileName = `JOURNAL_ENTRY.xlsx`;
    const finalEndpoint = `reports/common/qb/journal-entry?format=XLS&var-start_date=${startDate}&var-end_date=${endDate}&jeSeq=${searchText}&${customField}`;
    return http
      .get(finalEndpoint, {
        responseType: 'blob'
      })
      .then((res: any) => {
        const reader = new FileReader();
        reader.onload = function (event) {
          let blobData = String(event?.target?.result);
          try {
            showAlert('Alert', JSON.parse(blobData).exportMessage);
          } catch (e) {
            saveAs(res, fileName);
          }
        };
        reader.readAsText(res);   
      })
      .catch((err: any) => {
        console.error('Error while trying to get account: ', err);
        if (
          err?.status === EXPORT_ERROR.EXPORT_LOCK_CODE ||
          err?.code === EXPORT_ERROR.EXPORT_LOCK_CODE
        ) {
          showAlert('Error', EXPORT_ERROR.EXPORT_LOCK_WARNING_MSG);
        } else {
          return Promise.reject(err);
        }
      });
  }
  static getNormalJEByCode(jeCode: string) {
    const queryString: string = `?search=${jeCode}&SKIP_REQUEST_INTERCEPTOR=${this.apiConfig.SKIP_REQUEST_INTERCEPTOR}`;
    const finalEndpoint: string =
      ApiConstants.URL.JOURNAL_ENTRY.FETCH_JOURNAL_ENTRY + queryString;
    return http.get(`${finalEndpoint}`).catch((err: any) => {
      console.error('Error loading Journal: ', err);
      return Promise.reject(err);
    });
  }

  // This method is used in LinkedRecordsPopup
  static getNormalJEByCodeWithLimit(jeCode: string) {
    jeCode = encodeURIComponent(jeCode);
    const queryString: string = `?query=documentSequenceCode=${jeCode}&SKIP_REQUEST_INTERCEPTOR=${this.apiConfig.SKIP_REQUEST_INTERCEPTOR}&limit=25`;
    const finalEndpoint: string =
      ApiConstants.URL.JOURNAL_ENTRY.FETCH_JOURNAL_ENTRY + queryString;
    return http.get(`${finalEndpoint}`).catch((err: any) => {
      console.error('Error loading Journal: ', err);
      return Promise.reject(err);
    });
  }
}

export default JournalService;
