import React, { Component } from "react";
import "../../index.css";
import "./css/Templates.css";
import ComponentManager from "../../Manager/ComponentManager";
import { EditorInfoType, FONT_SIZE, PAGE_PADDING } from '../../Constants/Constants';
import DocumentManager from "../../Manager/DocumentManager";
import Utility, { getFontSizeClass, getPageHeight, getVW } from "../../Utilities/Utility";
import { getLocalisedText } from '../../Translate/LanguageManager';
import { AddressType } from "../../Helper/AddressParser";
import { HTMLTag } from "../../Utilities/HTMLTag";


export default class JobWorkOurOrderTemplates1 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // data: this.props.data,
            data: this.addDummyData(this.props.data),
        };
    }

    componentWillReceiveProps(nextProp) {
        this.setState({
            // data: nextProp.data,
            data: this.addDummyData(nextProp.data),
        });
    }

    addDummyData(data) {
        if(data && data !== null) {
            data.exciseReignNo = 'ADERD425AXM001';
            data.range = 'Bommanahalli';
            data.division = 'Division IV';
            data.commissionRate = 'Bnagalore I';
            data.range_sub = '/01';
            data.division_sub = '/04';
            data.commissionRate_sub = '/09';
            // data.billToTitle = 'Dispatch To';
            // data.shipToTitle = 'Party';
            data.STNumber = '10062699912';
            data.CSTNumber = '96822455222';
            data.amountInWord = 'INR Eighteen Lakh Only';

            // data.voucherNo = 'JWO/23-24/003';
            // data.dated = '25-Sep-23';
            // data.orderNo = 'WO/23-24/003';
            // data.termOfPayment = '30 Days';
            // data.dispatchDocNo = '';
            // data.destination = '';
            // data.dispatchedThrough = 'By Hand';
            // data.durationOfProcess = '1 Day';
            // data.natureOfProcess = 'Platting';
            // data.companyPAN = 'PAN-12345'

            // data.termsAndCondition = 'We declare that this invoice shows the actual price of the goods described and that all particulars are true and correct.'
        }


        return data
    }

    render() {
        return (
            <div className='DocumentHolder ColumnDiv'
                style={{
                    justifyContent: 'space-between',
                    minHeight: getPageHeight(this.state.data.printScale),
                }}>
                <div className='parent-width' style={{ width: '100%', paddingBottom: getVW(30) }}>
                    <table style={{
                        width: '100%',
                        borderCollapse: 'collapse',
                        margin: 'auto',
                        border: 'black solid 1px'
                    }}>
                        <tbody>
                            <tr>
                                <td colSpan={2}
                                    style={{
                                        width: '100%',
                                        verticalAlign: 'top'
                                    }}>
                                    {this.getDocumentType()}
                                </td>
                            </tr>
                            <tr>
                                <td style={{ border: '1px solid', width: '50%', verticalAlign: 'top' }}>
                                    {this.getLeftPanelSection()}
                                </td>
                                <td style={{ border: '1px solid', width: '50%', verticalAlign: 'top' }}>
                                    {this.getDocumentDetailsPanelSection()}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    {this.getEditableDocumentTable()}
                    <table style={{
                        width: '100%',
                        borderCollapse: 'collapse',
                        margin: 'auto',
                        border: 'black solid 1px'
                    }}>
                        <tbody>
                            <tr>
                                <td colSpan={2} style={{ width: '100%', verticalAlign: 'top', padding: getVW(Utility.convertToPrintScale(10, this.state.data.printScale)) }}>
                                    <div className='ColumnDiv parent-width'>
                                        <div style={{
                                            textAlign: 'left',
                                            ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular }, this.state.data.printScale)
                                        }}>
                                            Amount Chargeable (in words)
                                        </div>
                                        <div style={{
                                            fontWeight: 'bold',
                                            textAlign: 'left',
                                            ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular }, this.state.data.printScale)
                                        }}>
                                            {this.state.data.amountInWord}
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td colSpan={2} style={{ width: '100%', verticalAlign: 'top', padding: getVW(Utility.convertToPrintScale(10, this.state.data.printScale)) }}>
                                    <div className='RowDiv parent-width' style={{ width: '50%'}}>
                                        <div style={{
                                            width: '50%',
                                            textAlign: 'left',
                                            ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular }, this.state.data.printScale)
                                        }}>
                                            {getLocalisedText('company_pan')}
                                        </div>
                                        <div style={{
                                            width: '50%',
                                            fontWeight: 'bold',
                                            textAlign: 'left',
                                            ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular }, this.state.data.printScale)
                                        }}>
                                            : {this.state.data.companyPAN}
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td style={{ width: '50%', verticalAlign: 'top' }}>
                                    {(this.state.data.showTermsAndCondition && this.state.data.termsAndCondition)
                                        && ComponentManager.getTermsAndConditionOrNotesSection(
                                            Utility.getTermsAndConditionTitle(getLocalisedText('declaration')),
                                            getLocalisedText(this.state.data.termsAndCondition),
                                            "100%",
                                            this.state.data.themeColor,
                                            undefined,
                                            undefined,
                                            undefined,
                                            this.state.data.printScale,
                                            Utility.getTnCOrNotesFontSettingsByType(EditorInfoType.termsAndCondition)
                                        )
                                    }
                                </td>
                                <td style={{ width: '50%', verticalAlign: 'top', border: '1px solid' }}>
                                    <div style={{
                                        fontWeight: 'bold',
                                        textAlign: 'right',
                                        paddingRight: getVW(Utility.convertToPrintScale(10, this.state.data.printScale)),
                                        ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular }, this.state.data.printScale)
                                    }}>
                                        {Utility.getCompanyName(this.state.data)}
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        {getLocalisedText('authorised_signatory')}
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    {this.getGenerateDocumentSection()}
                </div>
                
            </div>
        );
    }

    getDocumentType() {
        return <div className="RowDiv pl-s pt-s pb-s parent-width"
            style={{
                display: 'contents',
                alignItems: 'center',
                flexDirection: Utility.getIsArabicLang() ? 'row-reverse' : 'row',
                width: '100%',
            }}>
            <text className={"docFontStyleBold" + getFontSizeClass()}
                style={{
                    ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.large }, this.state.data.printScale)
                }}>
                {getLocalisedText(this.state.data.documentType)}
            </text>
        </div>;
    }

    getCompanySection() {
        return <div className="ColumnDiv" style={{ width: '100%', paddingTop: getVW(10), paddingBottom: getVW(10), alignItems: 'center' }}>
            <div className="RowDiv" style={{ width: 'auto' }}>
                {ComponentManager.getLogoVisibility(this.state.data.showCompanyLogo, this.state.data.isReadOnlyMode, this.state.data.logo) && ComponentManager.getLogo(this.state.data.logo, Utility.convertToPrintScale(80, this.state.data.printScale), { position: 'relative' })}
                {this.state.data.showCompanyLogo && ComponentManager.addHorizontalSpace(10)}
                {this.state.data.showCompanyName && <div className="ColumnDiv parent-width">
                    {ComponentManager.getDescriptionSection(
                        getLocalisedText(Utility.getCompanyName(this.state.data)),
                        getLocalisedText(Utility.getCompanyAddress(this.state.data)),
                        "100%",
                        this.state.data.themeColor,
                        false,
                        '#000000',
                        getVW(0),
                        Utility.getOwnerType(this.state.data, AddressType.from),
                        this.state.data.printScale
                    )}
                </div>}
            </div>
        </div>
    }

    getRowText(title, value) {
        return <div className="RowDiv">
            <div style={{
                width: '40%',
                textAlign: 'left',
                ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular }, this.state.data.printScale)
            }}>
                {title} 
            </div>
            <div style={{
                width: '60%',
                fontWeight: 'bold',
                textAlign: 'left',
                ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular }, this.state.data.printScale)
            }}>
                : {value}
            </div>
        </div>
    }

    getRowBox(title, value) {
        return <div className="ColumnDiv" style={{ verticalAlign: 'top', paddingLeft: getVW(Utility.convertToPrintScale(10, this.state.data.printScale))}}>
            <div style={{
                width: '100%',
                textAlign: 'left',
                ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular }, this.state.data.printScale)
            }}>
                {title}
            </div>
            <div style={{
                width: '100%',
                fontWeight: 'bold',
                textAlign: 'left',
                ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular }, this.state.data.printScale)
            }}>
                {value === '' ? <br/> : value}
            </div>
        </div>
    }


    getProcessorPanelSection() {
        return <div className="RowDiv">
            <div className="ColumnDiv" style={{ width: '100%'}}>
                {(this.state.data.showClientBillToAddress)
                    && <div className="RowDiv"
                        style={{
                            width: '-webkit-fill-available',
                            paddingTop: getVW(Utility.convertToPrintScale(5, this.state.data.printScale)),
                            paddingBottom: getVW(Utility.convertToPrintScale(5, this.state.data.printScale)),
                        }}>
                        <div className="ColumnDiv parent-width">
                            {ComponentManager.getDescriptionSection(
                                this.state.data.billToTitle,
                                Utility.getClientBillToAddress(this.state.data),
                                "100%",
                                this.state.data.themeColor,
                                false,
                                '#000000',
                                getVW(0),
                                Utility.getOwnerType(this.state.data, AddressType.billTo),
                                this.state.data.printScale
                            )}
                            {ComponentManager.getDescriptionSection(
                                "",
                                this.state.data.billToAddressStateCode,
                                "100%",
                                this.state.data.themeColor,
                                false,
                                undefined,
                                0,
                                undefined,
                                this.state.data.printScale
                            )}
                        </div>
                    </div>}
                {this.getRowText('S.T. No.', this.state.data.STNumber)}
                {this.getRowText('CST. No.', this.state.data.CSTNumber)}
                {this.getRowText('Range', this.state.data.range)}
                {this.getRowText('Division', this.state.data.division)}
                {this.getRowText('Commission Rate', this.state.data.commissionRate)}
            </div>
        </div>
    }

    getDocumentDetailsPanelSection() {
        // const documentDetailList = [
        //     { label: "Challan No./Document No.", value: "1" },
        //     { label: "Dated.", value: "6-Dec-2010" },
        //     { label: "Issues Order No.", value: "Jib Ex - 0001" },
        //     { label: "Dated.", value: "4-Dec-2010" },
        //     { label: "Dispatch Document No.", value: "" },
        //     { label: "Dispatch through", value: "" },
        //     { label: "Motor Vehicle No.", value: "" },
        //     { label: "Destination", value: "" },
        //     { label: "Date & Time of issues", value: "6-Dec-2010 at 19:55" },
        //     { label: "Duration of Process", value: "30 Days" },
        //     { label: "Terms of Delivery", value: "" },
        //     { label: "Nature of Processing required", value: "Manufacture and Packing of 12 Litre Cookers" },
        // ]

        var documentDetailList = Utility.getDocumentDetailsList(this.state.data)

        console.log(documentDetailList)
        var leftColumnData = []
        var rightColumnData = []

        documentDetailList.forEach((element,index) => {
            var value = ''
            if (element.key !== undefined) {
                value = this.state.data[element.key]
            }
            else if (element.customFieldValue !== undefined) {
                value = element.customFieldValue
            }
            element.value = value

            if(index % 2  === 0) {
                leftColumnData.push(element)
            }
            else {
                rightColumnData.push(element)
            }
        });
        var row = []
        for (let index = 0; index < leftColumnData.length; index++) {
            const left = leftColumnData[index]
            const isMaxRow = index >= leftColumnData.length - 1 ? true : false
            if(rightColumnData.length > index) {
                let right = rightColumnData[index]
                row.push(this.getTwoColumnDiv(index, left, right, isMaxRow))
            }
            else {
                row.push(this.getTwoColumnDiv(index, left, undefined, isMaxRow))
            }
        }


        return <div className="ColumnDiv parent-width" style={{ paddingBottom: '1px solid' }}>
            {row}
        </div>
    }

    getInstructionPanelSection() {
        const instructionList = [
            { label: "1. Date & Time Dispatch of Finished Goods to Parent Factory/Another Manufacturer and Entry No. and Date of Receipt in the Account in the Processing Factory of", value: "" },
            { label: "2. Date & Time Dispatch of Finished Goods without payment of duty for export under bond or on payment of duty for export or payment of duty for home consumption. Invoice No. Quantity of duty Paid (Both Figure & Words)", value: "" },
            { label: "3. Quantity Dispatched (Nos/Weight/Litre/Meter and Entered in Account)", value: "" },
            { label: "4. Nature of Processing/Manufacturing done .", value: "" },
            { label: "5. Quantity of Waste Material Returned to Parent Factory or cleared for home consumption. Invoice No. Quantity of duty Paid (Both Figure & Words)", value: "" },
            { label: "6. Value/Amount Cleared in the Job Word/Processing. ", value: "" },
        ]

        var row = []
        instructionList.forEach((element, index) => {
            row.push(this.getTwoRowDiv(index, element))
        });

        return <div className="ColumnDiv parent-width">
            {row}
        </div>
    }

    getTwoColumnDiv(index, left, right, isMaxRow) {
        if(right) {
            return <div className="RowDiv parent-width" style={{borderTop: index === 0? undefined : '1px solid'}}>
                <div className="ColumnDiv" style={{ width: '50%', alignSelf: 'baseline', borderBottom: isMaxRow ? '1px solid' : undefined }}>
                    {this.getRowBox(left.label, left.value)}
                </div>
                <div className="ColumnDiv" style={{
                    width: '50%',
                    borderLeft: '1px solid',
                    borderBottom: isMaxRow ? '1px solid' : undefined 
                }}>
                    {this.getRowBox(right.label, right.value)}
                </div>
            </div>
        }
        return <div className="RowDiv parent-width" style={{ borderTop: index === 0 ? undefined : '1px solid' }}>
            <div className="ColumnDiv" style={{ width: '50%', alignSelf: 'baseline', borderRight: isMaxRow ? '1px solid' : undefined, borderBottom: isMaxRow ? '1px solid' : undefined }}>
                {this.getRowBox(left.label, left.value)}
            </div>
            <div className="ColumnDiv" style={{
                width: '50%',
            }}>
                {this.getRowBox('', '')}
            </div>
        </div>
    }

    getTwoRowDiv(index, left) {
        return <div className="RowDiv parent-width" style={{ 
            borderTop: index === 0 ? undefined : '1px solid',

        }}>
            <div className="ColumnDiv" 
                style={{ 
                    textAlign: 'left',
                    width: '50%', 
                    alignSelf: 'baseline',
                    borderRight: '1px solid',
                    ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular }, this.state.data.printScale) }}>
                {left.label}
            </div>
            <div className="ColumnDiv" style={{ width: '50%' }}>
                <br/>
            </div>
        </div>
    }

    getLeftPanelSection() {
        return <div className="RowDiv">
            <div className="ColumnDiv" style={{ width: '100%', paddingTop: getVW(Utility.convertToPrintScale(10, this.state.data.printScale)), paddingBottom: getVW(Utility.convertToPrintScale(10, this.state.data.printScale)) }}>
                <div className="RowDiv parent-width" style={{
                    paddingLeft: getVW(Utility.convertToPrintScale(10, this.state.data.printScale)),
                }}>
                    {ComponentManager.getLogoVisibility(this.state.data.showCompanyLogo, this.state.data.isReadOnlyMode, this.state.data.logo) && ComponentManager.getLogo(this.state.data.logo, Utility.convertToPrintScale(80, this.state.data.printScale), { position: 'relative' })}
                    {this.state.data.showCompanyLogo && ComponentManager.addHorizontalSpace(10)}
                    {this.state.data.showCompanyName && <div className="ColumnDiv parent-width pl-s">
                        {ComponentManager.getDescriptionSection(
                            getLocalisedText(Utility.getCompanyName(this.state.data)),
                            getLocalisedText(Utility.getCompanyAddress(this.state.data)),
                            "100%",
                            this.state.data.themeColor,
                            false,
                            '#000000',
                            getVW(0),
                            Utility.getOwnerType(this.state.data, AddressType.from),
                            this.state.data.printScale
                        )}
                    </div>}
                </div>
                {(this.state.data.showFrom && this.state.data.showClientBillToAddress) && ComponentManager.addVerticalSpace(10)}
                {(this.state.data.showClientBillToAddress)
                    && <div className="RowDiv pl-s"
                        style={{
                            width: '-webkit-fill-available',
                            borderTop: '1px solid',
                            borderBottom: '1px solid',
                            paddingTop: getVW(Utility.convertToPrintScale(5, this.state.data.printScale)),
                            paddingBottom: getVW(Utility.convertToPrintScale(5, this.state.data.printScale)),
                        }}>
                        <div className="ColumnDiv parent-width">
                            {ComponentManager.getDescriptionSection(
                                this.state.data.billToTitle,
                                Utility.getClientBillToAddress(this.state.data),
                                "100%",
                                this.state.data.themeColor,
                                false,
                                '#000000',
                                getVW(0),
                                Utility.getOwnerType(this.state.data, AddressType.billTo),
                                this.state.data.printScale
                            )}
                            {ComponentManager.getDescriptionSection(
                                "",
                                this.state.data.billToAddressStateCode,
                                "100%",
                                this.state.data.themeColor,
                                false,
                                undefined,
                                0,
                                undefined,
                                this.state.data.printScale
                            )}
                        </div>
                    </div>}
                {(this.state.data.showClientBillToAddress && this.state.data.showClientShipToAddress) && ComponentManager.addVerticalSpace(10)}
                {this.state.data.showClientShipToAddress && <div className="ColumnDiv parent-width pl-s">
                    {ComponentManager.getDescriptionSection(
                        this.state.data.shipToTitle,
                        Utility.getClientShipToAddress(this.state.data),
                        "100%",
                        this.state.data.themeColor,
                        false,
                        '#000000',
                        getVW(0),
                        Utility.getOwnerType(this.state.data, AddressType.shipTo),
                        this.state.data.printScale
                    )}
                    {ComponentManager.getDescriptionSection(
                        "",
                        this.state.data.shipToAddressStateCode,
                        "100%",
                        this.state.data.themeColor,
                        false,
                        undefined,
                        0,
                        undefined,
                        this.state.data.printScale
                    )}
                </div>}
            </div>
        </div>
    }

    getGenerateDocumentSection() {
        return <div style={{
            paddingBottom: getVW(PAGE_PADDING, true),
            paddingTop: getVW(Utility.convertToPrintScale(5, this.state.data.printScale)),
            width: '100%'
        }}>
            <text className={'docFontStyleBold fontSizeNormal ' + getFontSizeClass()}
                style={{
                    ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular }, this.state.data.printScale)
                }}>
                {Utility.getGeneratedMessage(this.state.data.type)}
            </text>
        </div>
    }

    getEditableDocumentTable() {
        return ComponentManager.getDKDocumentTable(
            this.props.tableColumn,
            this.getTableStyle(),
            this.props.data,
            this.props.tableClicked,
            this.props.stockTableClicked,
            this.props.onUpdateDataCallback,
            this.props.paymentTableClicked,
            this.props.additionalTableCallback,
        )
    }

    getTableStyle() {
        let style = DocumentManager.getTableDefaultStyle();
        style.headerTextColor = this.state.data.themeColor;
        style.footerTextColor = this.state.data.themeColor;
        // style.headerBorderColor = "#BBBABA";
        // style.itemBorderColor = "#BBBABA";
        style.headerBorderColor = "#000000";
        style.itemBorderColor = "#000000";
        style.footerBackgroundColor = "white";
        // style.footerBorderColor = "#BBBABA";
        style.footerBorderColor = "#000000";
        style.headerBackgroundColor = "transparent";
        return style;
    }


    getCustomFieldTableStyle() {
        let style = {}
        style.columnCount = 2
        style.columnWidths = Utility.getIsArabicLang() ? ['50%', '50%'] : ['50%', '50%']
        return style;
    }

    getExchangeRateTable(tableStyle, data) {
        return (
            <div id={HTMLTag.EXCHANGE_RATE} className="RowDiv" style={{ justifyContent: "flex-end" }}>
                <div style={{ width: "45%" }}>
                    {ComponentManager.getExchangeRateTable(tableStyle, data)}
                </div>
            </div>
        );
    }
}
