import { useContext, useEffect, useState } from 'react';
import { shallowEqual } from 'react-redux';
import { useAppDispatch, useAppSelector } from '../../../../Redux/Hooks';
import { DKIcons, DKIconText, DKLabel } from 'deskera-ui-library';
import { CommonDraftPropsContext } from '../../Utilities/DocContext';
import {
  selectDocumentFormDataByKeys,
  selectDocumentMetaDataByKey,
  updateMultipleKeysInDocument
} from '../../../../Redux/Slices/DocumentSlice';
import { activeTenantInfo } from '../../../../Redux/Slices/AuthSlice';
import {
  CUSTOM_NUMBER_INPUT_MODULES,
  DOC_TYPE,
  DOCUMENT_MODE
} from '../../../../Constants/Constant';
import CustomNumberFormatInput from '../../../../SharedComponents/CustomNumberFormat/CustomNumberFormatInput';
import { DraftTypes } from '../../../../Models/Drafts';
import { DOCUMENT_KEYS } from '../../Utilities/DocConstants';

/**
 * Component to show and update the document sequence number.
 */
const DocSerialNumberSelector = () => {
  const { draftId, draftType, documentMode } = useContext(
    CommonDraftPropsContext
  );
  const dispatch = useAppDispatch();
  const tenantInfo = useAppSelector(activeTenantInfo);

  const [customNumberFormatModule, setCustomNumberFormatModule] = useState('');
  const [openCustomNumberList, setOpenCustomNumberList] = useState(false);

  const [documentType, documentSequenceCode] = useAppSelector(
    selectDocumentFormDataByKeys(draftId, [
      'documentType',
      'documentSequenceCode'
    ]),
    shallowEqual
  );

  const draftCode = useAppSelector(
    selectDocumentMetaDataByKey(draftId, 'draftCode')
  );

  useEffect(() => {
    document.addEventListener('mouseup', onMouseUp);
    return () => {
      document.removeEventListener('mouseup', onMouseUp);
    };
  }, []);

  const onMouseUp = (e: any) => {
    const target = e.target;
    if (!(target instanceof Element)) return;

    const editingContainer = target.closest('#custom-number-list');

    if (!editingContainer) {
      setOpenCustomNumberList(false);
    }
  };

  useEffect(() => {
    switch (documentType) {
      case DOC_TYPE.INVOICE:
        setCustomNumberFormatModule(
          !tenantInfo.useSeparateSequenceFormat
            ? CUSTOM_NUMBER_INPUT_MODULES.SELL
            : CUSTOM_NUMBER_INPUT_MODULES.INVOICE
        );
        break;
      case DOC_TYPE.BILL:
        setCustomNumberFormatModule(
          !tenantInfo.useSeparateSequenceFormat
            ? CUSTOM_NUMBER_INPUT_MODULES.BUY
            : CUSTOM_NUMBER_INPUT_MODULES.BILL
        );
        break;
      case DOC_TYPE.ORDER:
        setCustomNumberFormatModule(
          !tenantInfo.useSeparateSequenceFormat
            ? CUSTOM_NUMBER_INPUT_MODULES.BUY
            : CUSTOM_NUMBER_INPUT_MODULES.PURCHASE_ORDER
        );
        break;
      case DOC_TYPE.QUOTE:
        setCustomNumberFormatModule(
          !tenantInfo.useSeparateSequenceFormat
            ? CUSTOM_NUMBER_INPUT_MODULES.SELL
            : CUSTOM_NUMBER_INPUT_MODULES.QUOTE
        );
        break;
      case DOC_TYPE.SALES_ORDER:
        setCustomNumberFormatModule(
          !tenantInfo.useSeparateSequenceFormat
            ? CUSTOM_NUMBER_INPUT_MODULES.SELL
            : CUSTOM_NUMBER_INPUT_MODULES.SALES_ORDER
        );
        break;
      case DOC_TYPE.JOB_WORK_OUT_ORDER:
        setCustomNumberFormatModule(
          !tenantInfo.useSeparateSequenceFormat
            ? CUSTOM_NUMBER_INPUT_MODULES.BUY
            : CUSTOM_NUMBER_INPUT_MODULES.JOB_WORK_OUT
        );
        break;
    }
  }, [documentType, tenantInfo.useSeparateSequenceFormat]);

  const selectedFormat = (selected: any) => {
    /*
     * Custom Numbering Format
     * RECEIVE Selected format {id: "", text: ""}
     */
    let updatedState: any = {};

    if (selected.manualMode) {
      updatedState = {
        documentSequenceCode: selected.text,
        sequenceFormat: selected.id,
        manualMode: selected.manualMode
      };
    } else {
      if (selected.id) {
        updatedState = {
          sequenceFormat: selected.id,
          manualMode: selected.manualMode
        };
      }
    }
    dispatch(
      updateMultipleKeysInDocument({
        draftId,
        keysToUpdate: {
          [DOCUMENT_KEYS.DOCUMENT_SEQUENCE_CODE]: selected.manualMode
            ? selected?.text
            : '',
          [DOCUMENT_KEYS.SEQUENCE_FORMAT]: selected.manualMode
            ? ''
            : selected?.id
        }
      })
    );
  };

  const showSequenceSelector =
    documentMode === DOCUMENT_MODE.NEW ||
    documentMode === DOCUMENT_MODE.COPY ||
    (documentMode === DOCUMENT_MODE.VIEW && !documentSequenceCode);

  const getDocCustomNumberSelector = showSequenceSelector ? (
    <CustomNumberFormatInput
      module={customNumberFormatModule}
      selectedFormat={selectedFormat}
      showCompact={true}
      extraClass="shadow-m mt-0 mb-0 right-0 top-8"
      buttonStylePadding="0"
      openList={openCustomNumberList}
      autoNumberingFormat={
        !!documentSequenceCode
          ? {
              id: null,
              manualMode: true,
              text: documentSequenceCode
            }
          : {}
      }
    />
  ) : (
    <DKLabel text={documentSequenceCode} className="text-align-right" />
  );

  return (
    <>
      {draftType === DraftTypes.DRAFT && draftCode && (
        <div className="row position-relative justify-content-between p-v-xs listPickerBG gap-2">
          <DKIconText
            className=""
            icon={DKIcons.data_type.ic_number}
            iconClassName="ic-with-text-height opacity-60"
            text="Draft No."
            textClassName="fw-m"
          />
          <DKLabel text={draftCode} className="text-align-right" />
        </div>
      )}
      <div className="row position-relative justify-content-between p-v-xs listPickerBG gap-2">
        <DKIconText
          className={showSequenceSelector ? 'cursor-hand' : ''}
          icon={DKIcons.data_type.ic_number}
          iconClassName="ic-with-text-height opacity-60"
          text="No."
          textClassName="fw-m"
          onClick={() => setOpenCustomNumberList((value) => !value)}
        />
        <div style={{ width: '75%' }}>{getDocCustomNumberSelector}</div>
      </div>
    </>
  );
};

export default DocSerialNumberSelector;
