import React, { Component } from "react";
import "../../index.css";
import "../../CommonStyles/FontStyle.css";
import { FONT_SIZE, EditorInfoType } from '../../Constants/Constants';
import DKCustomFieldSection from "../../DKUILibrary/DKCustomFieldSection";
import DKEInvoiceSection, { eInvoiceSectionPosition } from "../../DKUILibrary/DKEInvoiceSection";
import DKSignatureSection from "../../DKUILibrary/DKSignatureSection";
import { AddressType } from "../../Helper/AddressParser";
import AppManager from "../../Manager/AppManager";
import ComponentManager from "../../Manager/ComponentManager";
import DocumentManager from "../../Manager/DocumentManager";
import { getLocalisedText } from '../../Translate/LanguageManager';
import { HTMLTag } from "../../Utilities/HTMLTag";
import Utility, { getFontSizeClass, getCapitalized, getPageHeight, getVW, getIsFulfillment, getIsGoodsReceipt, getIsJournalEntry } from "../../Utilities/Utility";
import TopSectionAboveTableUtility from "../../Utilities/TopSectionAboveTableUtility";
import DKComponentListTable from "../../DKUILibrary/DKComponentListTable";
import DKApprovalSection from "../../DKUILibrary/DKApprovalSection";
export default class DocumentTemplate2 extends Component {
    arabicHeaderWidth = '82.5%'

    constructor(props) {
        super(props);
        this.state = {
            data: this.props.data,
        };
    }

    componentWillReceiveProps(nextProp) {
        this.setState({
            data: nextProp.data,
        });
    }

    addVerticalLabel() {
        return (
            <div className="Doc-Rotate" style={{width: 'inherit'}}>
                <text
                    style={{
                        ...Utility.getFontStyleProp({fontSize: FONT_SIZE.xLarge}, this.state.data.printScale),
                    }}>
                        {getCapitalized(AppManager.getLocalisedType(this.state.data.type))}  -
                </text>
                <text
                    style={{
                        color: this.state.data.themeColor,
                        ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.xxLarge }, this.state.data.printScale),
                    }}>
                        &nbsp;#{this.state.data.refNumber}
                </text>
            </div>
        )
    }

    getCompanyNameLabel() {
        return (
            <div className="RowDiv"
                style={{
                    width: Utility.getIsArabicLang() ? this.arabicHeaderWidth : '60%',
                    flexDirection: Utility.getIsArabicLang() ? 'row-reverse' : 'row',
                    overflow: 'hidden'
                }}>
                <text
                    style={{
                        fontWeight: "normal",
                        textAlign: "left",
                        color: this.state.data.themeColor,
                        ...Utility.getFontStyleProp({fontSize: FONT_SIZE.xLarge}, this.state.data.printScale)
                    }}
                >
                    {getLocalisedText(Utility.getCompanyName(this.state.data)).toUpperCase()}
                </text>
            </div>
        )
    }

    getCompanyInformationView() {
        return (
            <div className="ColumnDiv"
                style={{
                    width: Utility.getIsArabicLang() ? this.arabicHeaderWidth : '50%',
                    overflow: 'hidden'
                }}>
                <div className={"docFontStyleBold" + getFontSizeClass()}
                    style={{
                        width: '100%',
                        whiteSpace: 'pre-wrap',
                        textAlign: Utility.getIsArabicLang() ? 'right' : 'left',
                        fontWeight: 'normal',
                        ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.large, ownerType: Utility.getOwnerType(this.state.data, AddressType.from)}, this.state.data.printScale)
                    }}
                    dangerouslySetInnerHTML={{__html: getLocalisedText(Utility.getCompanyAddress(this.state.data))}}/>
            </div>
        )
    }

    getAddressAndDocDetailView() {
        return (
            <div className="RowDiv" style={{ alignItems: "flex-start" }}>
                <div
                    className="RowDiv"
                    style={{
                        justifyContent: "space-between",
                        width: "100%",
                        alignItems: "flex-start",
                    }}
                >
                    {this.state.data.showClientBillToAddress && ComponentManager.getDescriptionSection(
                        this.state.data.billToTitle,
                        Utility.getClientBillToAddress(this.state.data),
                        "40%",
                        this.state.data.themeColor,
                        false,
                        '#000000',
                        getVW(6),
                        Utility.getOwnerType(this.state.data, AddressType.billTo),
                        this.state.data.printScale
                    )}
                    {(this.state.data.showClientBillToAddress && this.state.data.showClientShipToAddress) && <div style={{ width: "10%" }} />}
                    {this.state.data.showClientShipToAddress && ComponentManager.getDescriptionSection(
                        this.state.data.shipToTitle,
                        Utility.getClientShipToAddress(this.state.data),
                        "40%",
                        this.state.data.themeColor,
                        false,
                        '#000000',
                        getVW(6),
                        Utility.getOwnerType(this.state.data, AddressType.shipTo),
                        this.state.data.printScale
                    )}
                    <div style={{ width: "10%" }} />
                </div>

                <div
                    className="RowDiv"
                    style={{ justifyContent: "flex-end", width: "100%" }}
                >
                    <div
                        className="ColumnDiv"
                        style={{
                            justifyContent: "space-between",
                            width: "70%",
                            height: "100%",
                        }}
                    >
                        {this.getDocumentDetailsSection()}
                    </div>
                </div>
            </div>
        );
    }

    getDocumentDetailsSection() {
        var documentDetailList = Utility.getDocumentDetailsList(this.state.data)
        return documentDetailList.map(element => {
            var value = ''
            if (element.key !== undefined) {
                value = this.state.data[element.key]
            }
            else if (element.customFieldValue !== undefined) {
                value = element.customFieldValue
            }
            return ComponentManager.getRowValueSection(
                element.label,
                value,
                this.state.data.themeColor,
                undefined,
                undefined,
                undefined,
                this.state.data.printScale
            )
        });
    }

    getSourceOfSupplyInfoSection() {
        return (
            <div
                className="RowDiv"
                style={{
                    alignItems: "flex-start",
                    justifyContent: 'space-between',
                    backgroundColor: "white",
                }}
            >
                {this.getSourceOfSupply()}

            </div>
        )
    }

    getSourceOfSupply() {
        return <div className='RowDiv' style={{ width: '70%' }}>
            <div className='ColumnDiv' style={{
                width: '35%'
            }}>
                {Utility.getIsShowSourceOfSupply(this.state.data) && ComponentManager.getDescriptionSection(
                    getLocalisedText('source_of_supply'),
                    this.state.data.sourceOfSupply,
                    "100%",
                    this.state.data.themeColor,
                    false,
                    '#000000',
                    getVW(6),
                    true,
                    this.state.data.printScale
                )}
            </div>
            <div className='ColumnDiv' style={{
                width: '40%'
            }}>
                {Utility.getIsShowDestinationOfSupply(this.state.data) && ComponentManager.getDescriptionSection(
                    getLocalisedText('destination_of_supply'),
                    this.state.data.destinationOfSupply,
                    "100%",
                    this.state.data.themeColor,
                    false,
                    '#000000',
                    getVW(6),
                    true,
                    this.state.data.printScale
                )}
            </div>
        </div>;
    }

    addFooterView() {
        return (
            <div style={{ padding: getVW(10), paddingBottom: 0,  bottom: 0, width: '100%' }}>
                {ComponentManager.addDivider({ backgroundColor: this.state.data.themeColor, height: getVW(1) })}
                {ComponentManager.addVerticalSpace(20)}
                {(getIsFulfillment(this.state.data.type) || getIsGoodsReceipt(this.state.data.type)) && <DKSignatureSection data={this.state.data} onSignatureSectionClicked={this.props.tableClicked} isThemeColor />}
                <div id={HTMLTag.TERMS_NOTE_PREFIX}>
                    {(this.state.data.showTermsAndCondition && this.state.data.termsAndCondition) &&
                        ComponentManager.getTermsAndConditionOrNotesSection(
                            Utility.getTermsAndConditionTitle(getLocalisedText('terms_&_condition')),
                            this.state.data.termsAndCondition,
                            "100%",
                            this.state.data.themeColor,
                            undefined,
                            undefined,
                            getVW(Utility.convertToPrintScale(6, this.state.data.printScale)),
                            this.state.data.printScale,
                            Utility.getTnCOrNotesFontSettingsByType(EditorInfoType.termsAndCondition)
                        )}
                    {((this.state.data.showTermsAndCondition && this.state.data.termsAndCondition) && (this.state.data.showNotes && this.state.data.notes)) && ComponentManager.addVerticalSpace(10)}
                    {(this.state.data.showNotes && this.state.data.notes) &&
                        ComponentManager.getTermsAndConditionOrNotesSection(
                            Utility.getNotesTitle(getIsJournalEntry(this.state.data.type) ? getLocalisedText('memo') : getLocalisedText('notes')),
                            this.state.data.notes,
                            "100%",
                            "gray",
                            undefined,
                            undefined,
                            getVW(Utility.convertToPrintScale(6, this.state.data.printScale)),
                            this.state.data.printScale,
                            Utility.getTnCOrNotesFontSettingsByType(EditorInfoType.notes)
                        )}
                </div>

            </div>
        )
    }

    render() {
        return (
            <div className="DocumentHolder RowDiv" style={{ alignItems: 'flex-start', minHeight: getPageHeight(this.state.data.printScale)}}>
                <div style={{ width: getVW(Utility.convertToPrintScale(60, this.state.data.printScale)), marginTop: getVW(Utility.convertToPrintScale(80, this.state.data.printScale)) }}>
                    {this.addVerticalLabel()}
                </div>
                <div className='DocumentHolder ColumnDiv' style={{ justifyContent: 'space-between', minHeight: getPageHeight(this.state.data.printScale) }}>
                    <div className='parent-width' style={{ width: '100%', paddingBottom: getVW(30) }}>
                        <DKEInvoiceSection
                            data={this.state.data}
                            position={eInvoiceSectionPosition.top}
                        />
                        {this.state.data.showCompanyName && this.getCompanyNameLabel()}
                        {ComponentManager.getLogoVisibility(this.state.data.showCompanyLogo, this.state.data.isReadOnlyMode, this.state.data.logo) && ComponentManager.getLogo(this.state.data.logo, Utility.convertToPrintScale(80, this.state.data.printScale), { right: 0, top: this.state.data.showEInvoiceSection ? undefined : 0, })}
						{/* section 1 */}
                        {(this.state.data.showCompanyName && this.state.data.showFrom) && ComponentManager.addVerticalSpace(20)}
                        {this.state.data.showFrom ? this.getCompanyInformationView() : ComponentManager.addVerticalSpace(50)}
                        {ComponentManager.addVerticalSpace(40)}
                        {ComponentManager.addVerticalSpace(Utility.getImageSize(EditorInfoType.logo))}
                        {ComponentManager.addDivider({ backgroundColor: this.state.data.themeColor, height: 1 })}
                        {ComponentManager.addVerticalSpace(30)}
                        {/* section 2 */}
                        {this.getAddressAndDocDetailView()}
                        {/* section 3 */}
                        {Utility.getIsShowPlaceOfSupply(this.state.data) && ComponentManager.addVerticalSpace(15)}
                        {Utility.getIsShowPlaceOfSupply(this.state.data) && this.getPlaceOfSupplyInfoSection()}
                        {(Utility.getIsShowSourceOfSupply(this.state.data) || Utility.getIsShowDestinationOfSupply(this.state.data)) && this.getSourceOfSupplyInfoSection()}
                        {Utility.getIsContainsCustomField('top') && ComponentManager.addVerticalSpace(20)}
                        <DKCustomFieldSection data={this.state.data} onCustomFieldClick={this.props.onCustomFieldClick} style={this.getCustomFieldTableStyle()} customFieldPosition={'top'} isThemeColor />
                        {ComponentManager.addVerticalSpace(15)}
                        {
                            TopSectionAboveTableUtility.getIsTopSectionAboveTableIsVisible() &&
                            <>
                                <div style={{ ...TopSectionAboveTableUtility.getTopSectionWithTableFontStyleProp(undefined, this.state.data.printScale) }}
                                    dangerouslySetInnerHTML={{ __html: TopSectionAboveTableUtility.getHtmlString(this.state.data) }} />
                                {ComponentManager.addVerticalSpace(5)}
                            </>
                        }
                        {this.getProductTable()}
                        {Utility.getIsShowAccountProductTable(this.state.data) && ComponentManager.addVerticalSpace(20)}
                        {this.getEditableDocumentTable()}
                        {Utility.getIsShowExchangeRate(this.state.data) && ComponentManager.addVerticalSpace(20)}
                        {Utility.getIsShowExchangeRate(this.state.data) && this.getExchangeRateTable(this.getTableStyle(), this.state.data)}
                        {Utility.getIsContainsCustomField('bottom') && ComponentManager.addVerticalSpace(20)}
                        <DKCustomFieldSection data={this.state.data} onCustomFieldClick={this.props.onCustomFieldClick} style={this.getCustomFieldTableStyle()} customFieldPosition={'bottom'} isThemeColor />
                        {Utility.getIsIncludesBomProductTable() && <DKComponentListTable data={this.state.data} />}
                        <div id={HTMLTag.SIGNATURE}>
                            {ComponentManager.addVerticalSpace(30)}
                            {this.state.data.showSignature && ComponentManager.getSignature(this.state.data.signature, this.state.data.printScale)}
                        </div>
                    </div>
                    <div className='parent-width' style={{ width: '100%' }}>
                        {this.addFooterView()}
                        {Utility.getIsShowApprovalSection() && <DKApprovalSection data={this.state.data} style={this.getTableStyle()} isThemeColor />}
                    </div>
                    <DKEInvoiceSection
                        data={this.state.data}
                        position={eInvoiceSectionPosition.bottom}
                    />
                </div>
            </div>
        );
    }

    getEditableDocumentTable() {
        return ComponentManager.getDKDocumentTable(
            this.props.tableColumn,
            this.getTableStyle(),
            this.props.data,
            this.props.tableClicked,
            this.props.stockTableClicked,
            this.props.onUpdateDataCallback,
            this.props.paymentTableClicked,
            this.props.additionalTableCallback,
        )
    }

    getProductTable() {
        if(Utility.getIsShowAccountProductTable(this.state.data)) {
            return ComponentManager.getDKProductTable(
                Utility.getTableColumnForAccountProduct(),
                this.getTableStyle(),
                this.props.data,
                this.props.tableClicked,
                this.props.stockTableClicked,
                this.props.onUpdateDataCallback,
                this.props.paymentTableClicked,
                this.props.additionalTableCallback,
            )
        }
    }

    getTableStyle() {
        let style = DocumentManager.getTableDefaultStyle();
        style.headerBackgroundColor = this.state.data.themeColor;
        style.headerTextColor = "#ffffff";
        style.headerBorderColor = this.state.data.themeColor;
        style.itemBorderColor = "lightgray";
        style.footerBackgroundColor = "transparent";
        style.footerTextColor = "black";
        style.footerBorderColor = "transparent";
        style.subTotalBorderColor = 'transparent';
        return style;
    }

    getCustomFieldTableStyle() {
        let style = {}
        style.columnCount = 3
        style.columnWidths = Utility.getIsArabicLang() ? ['20%', '25%', '55%'] : ['25%', '40%', '35%']
        return style;
    }
    getPlaceOfSupplyInfoSection() {
        return (
            <div
                className="RowDiv"
                style={{
                    alignItems: "flex-start",
                    justifyContent: 'space-between',
                    backgroundColor: "white",
                }}
            >
                {this.getPlaceOfSupply()}

            </div>
        )
    }
    getPlaceOfSupply() {
        return <div className='RowDiv' style={{ width: '70%' }}>
            <div className='ColumnDiv' style={{
                width: '40%'
            }}>
                {Utility.getIsShowPlaceOfSupply(this.state.data) && ComponentManager.getDescriptionSection(
                    getLocalisedText('place_of_supply'),
                    this.state.data.placeOfSupply,
                    "100%",
                    this.state.data.themeColor,
                    false,
                    '#000000',
                    getVW(6),
                    true,
                    this.state.data.printScale
                )}
            </div>

        </div>;

    }

    getExchangeRateTable(tableStyle, data) {
        return (
            <div id={HTMLTag.EXCHANGE_RATE} className="RowDiv" style={{ justifyContent: "flex-end" }}>
                <div style={{ width: "45%" }}>
                    {ComponentManager.getExchangeRateTable(tableStyle, data)}
                </div>
            </div>
        );
    }
}

