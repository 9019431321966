import { useMemo, useState } from 'react';
import { DKLabel, DKListPicker2, DKTooltipWrapper } from 'deskera-ui-library';
import Utility from '../../../../Utility/Utility';
import { populateProducts } from '../../JobCard/AddJobCard/AddJobCardPresenter';
import { JC_PROCESS_TYPE } from './JobCardListPresenter';
interface IJCItemProps {
  column: any;
  row: any;
  rowIndex: number;
  getProductByCode: (productCode: string) => string;
  onProductSelect: (product: any) => void;
  products: any[];
  jobCardMapping: { allJCDetails: any[] };
}
const JCItem: React.FC<IJCItemProps> = ({
  column,
  row,
  rowIndex,
  getProductByCode,
  products,
  onProductSelect,
  jobCardMapping
}: IJCItemProps) => {
  const [dropDownOpened, showHideDropdown] = useState<boolean>(false);

  const [linkedItem = {}] = row?.jobCardLinkDetails;
  const processType = linkedItem?.processType ?? row?.localProcessType;

  const existingProduct: string[] =
    jobCardMapping?.allJCDetails?.reduce((acc: any, jcObj: any) => {
      if (Utility.isNotEmpty(jcObj.jobCardLinkDetails?.[0]?.productCode)) {
        acc.push({
          productCode: jcObj.jobCardLinkDetails?.[0]?.productCode,
          processType: jcObj.jobCardLinkDetails?.[0]?.processType
        });
      }
      return acc;
    }, []) ?? [];
  const productInfo: any = useMemo(
    () => getProductByCode(linkedItem.productCode),
    [linkedItem]
  );

  return (
    <div
      key={`${column.name}-${rowIndex}`}
      className="d-flex pr-xs parent-height"
      style={{
        flexDirection: 'column',
        width: column.width || 100
      }}
      onClick={(e: any) => {
        if (
          Utility.isNotEmpty(linkedItem?.itemWarehouseInventoryData) ||
          linkedItem.processType === JC_PROCESS_TYPE.PRODUCTION
        )
          return;
        showHideDropdown(true);
        e.stopPropagation();
      }}
    >
      {Utility.isNotEmpty(linkedItem?.productCode) ? (
        <DKTooltipWrapper
          contentRenderer={() => (
            <>
              <div className="row">{productInfo?.name ?? '-'}</div>
              <div className="row fs-s">
                ({productInfo?.documentSequenceCode ?? '-'})
              </div>
            </>
          )}
          tooltipClassName="bg-deskera-secondary width-auto"
          style={{
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            width: 90
          }}
        >
          <DKLabel
            className=""
            text={productInfo?.name ?? '-'}
            style={{
              whiteSpace: 'nowrap',
              overflowX: 'hidden',
              textOverflow: 'ellipsis',
              width: 90
            }}
          />

          <DKLabel
            className="fs-s text-gray"
            text={productInfo?.documentSequenceCode ?? '-'}
            style={{
              whiteSpace: 'nowrap',
              overflowX: 'hidden',
              textOverflow: 'ellipsis',
              width: 90
            }}
          />
        </DKTooltipWrapper>
      ) : (
        <div>-</div>
      )}

      {dropDownOpened && (
        <DKListPicker2
          data={populateProducts(processType, products, existingProduct)}
          allowSearch={true}
          searchableKey="name"
          className={`position-absolute z-index-3 bg-white border-m`}
          renderer={(index: number, item: any) => (
            <div
              className="parent-width"
              onClick={(e: any) => {
                e.preventDefault();
                e.stopPropagation();
                onProductSelect(item);
                showHideDropdown(false);
              }}
            >
              <div className="parent-width">{item.name}</div>
              <div className="parent-width fs-s text-gray">
                ({item.documentSequenceCode})
              </div>
            </div>
          )}
          onSelect={() => {}}
          onClose={() => {
            showHideDropdown(false);
          }}
        />
      )}
    </div>
  );
};
export default JCItem;
