import DocumentDate from './DocumentDates/DocumentDate';
import DueDate from './DocumentDates/DueDate';
import ShipByDate from './DocumentDates/ShipByDate';

interface IDocDatesProps {
  showFullscreenLayout: boolean;
}

/**
 * Component to display all types of dates(document date, due date, fulfillment date)
 * - in the right panel when fullscreen is OFF
 * - in the center when fulscreen is ON
 */
const DocDates = (props: IDocDatesProps) => {
  return (
    <>
      <DocumentDate showFullscreenLayout={props.showFullscreenLayout} />
      <DueDate showFullscreenLayout={props.showFullscreenLayout} />
      <ShipByDate showFullscreenLayout={props.showFullscreenLayout} />
    </>
  );
};

export default DocDates;
