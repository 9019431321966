export class BarCodeScannerConfigManger {
  static docConfigs: any = [
    {
      name: 'Barcode',
      title: 'Barcode',
      key: 'barcode',
      width: 180,
      type: 'text',
      editable: true
    },
    {
      name: 'Product',
      title: 'Product',
      key: 'product',
      width: 232,
      type: 'text'
    },
    {
      name: 'Price',
      title: 'Price',
      key: 'productPrice',
      width: 120,
      type: 'text',
      editable: true
    },
    {
      name: 'Discount',
      title: 'Discount',
      key: 'productDiscount',
      width: 100,
      type: 'text',
      editable: true
    },
    {
      name: 'Qty',
      title: 'Qty',
      key: 'productQuantity',
      width: 100,
      type: 'text',
      editable: true
    },
    {
      name: 'Action',
      title: 'Action',
      key: 'deleteObj',
      width: 65,
      type: 'text'
    }
  ];
  static get() {
    return this.docConfigs;
  }
}
