import React, { useEffect, useRef, useState } from 'react';
import {
  DKInput,
  INPUT_VIEW_DIRECTION,
  INPUT_TYPE,
  DKSpinner
} from 'deskera-ui-library';
import { selectTDSNatureOfIncome } from '../../Redux/Slices/TDSSetupSlice';
import { useAppSelector } from '../../Redux/Hooks';
import TDSService from '../../Services/TDSSetup';
import {
  BOOKS_DATE_FORMAT,
  POPUP_CALLBACKS_TYPE
} from '../../Constants/Constant';
import DateFormatService from '../../Services/DateFormat';
import { CallBackPayloadType } from '../../Models/Interfaces';
import Utility from '../../Utility/Utility';
import { selectedAccounts } from '../../Redux/Slices/AccountsSlice';
import { DEFAULT_TDS_PAYABLE_ACCOUNT_CODE } from '../../Models/TDS';
import CoaService from '../../Services/COA';
import { selectNatureOfIncomeAccounts } from '../../Redux/Slices/CommonDataSlice';
export interface ITDSCalculationProps {
  tdsData?: any;
  passingInteraction: (callback: CallBackPayloadType) => void;
}
interface TDSCalculationInputState {
  title: String;
  value?: any;
  required?: boolean;
  placeHolder?: String;
  canValidate?: boolean;
  errorMessage?: String;
  readOnly?: boolean;
}
const getInitialState = (overriddenValues: any): TDSCalculationInputState => {
  return {
    title: '',
    value: null,
    required: false,
    placeHolder: '',
    canValidate: false,
    errorMessage: '',
    ...overriddenValues
  };
};
const TDSCalculation: React.FC<ITDSCalculationProps> = (props) => {
  /* All state goes here*/
  const [deducteeType, setDeducteeType] = useState<TDSCalculationInputState>(
    getInitialState({
      title: 'Deductee Type',
      placeHolder: 'Deductee Type',
      readOnly: true,
      value: props?.tdsData?.contact?.tdsInfoIndia?.deducteeType
    })
  );
  const [lineAmount, setLineAmount] = useState<TDSCalculationInputState>(
    getInitialState({
      title: 'LineAmount',
      placeHolder: 'LineAmount',
      readOnly: true,
      value: props?.tdsData?.lineAmount
    })
  );
  const [assessableAmount, setAssessableAmount] =
    useState<TDSCalculationInputState>(
      getInitialState({
        title: 'TDS Assessable Amount',
        placeHolder: 'TDS Assessable Amount',
        value: props.tdsData?.isDeductedTds
          ? props.tdsData?.assessableAmount
          : props.tdsData?.assessableAmount
          ? props.tdsData?.assessableAmount
          : props.tdsData?.lineAmount,

        required: true
      })
    );
  const [tdsRate, setTDSRate] = useState<TDSCalculationInputState>(
    getInitialState({
      title: 'TDS Rate (%)',
      placeHolder: 'TDS Rate',
      value: props?.tdsData?.tdsRate || 0,
      required: true
    })
  );
  const [tdsAmount, setTDSAmount] = useState<TDSCalculationInputState>(
    getInitialState({
      title: 'TDS Amount',
      placeHolder: 'TDS Amount',
      readOnly: true
    })
  );
  const [payableAccount, setPayableAccount] =
    useState<TDSCalculationInputState>(
      getInitialState({
        title: 'TDS Payable Account',
        placeHolder: 'Select TDS Payable Account',
        required: true
      })
    );
  const [remark, setRemark] = useState<TDSCalculationInputState>(
    getInitialState({
      title: 'Remark',
      placeHolder: 'Remark',
      value: props?.tdsData?.remark
    })
  );
  const [natureOfPayment, setNatureOfPayment] =
    useState<TDSCalculationInputState>(
      getInitialState({
        title: 'Nature Of Payment',
        placeHolder: 'Select Nature Of Payment',
        required: true
      })
    );
  const [tdsNotFound, setTDSNotFound] = useState(false);
  let localTDSData = useRef<any>({});
  const tdsNatureOfIncomeData = useAppSelector(selectNatureOfIncomeAccounts);
  const allAccountsData = useAppSelector(selectedAccounts);
  const [accountsData, setAccountsData] = useState(allAccountsData?.content);
  const [isLoadingAccount, setIsLoadingAccount] = useState(false);

  /* hooks goes here */
  useEffect(() => {
    if (!natureOfPayment.value) {
      getAndSetNatureOfPayment();
    }
    if (props?.tdsData?.isDeductedTds) {
      localTDSData.current = {
        ...props.tdsData,
        natureOfPayment: props.tdsData.incomePayment,
        tdsAccount: props.tdsData.payableAccount,
        resident: props.tdsData.contact.resident,
        remark: props.tdsData.remark
      };
      // getDefaultTax([props?.tdsData?.payableAccount]);
      populateTDSRate(true);
      if (!props.tdsData.tdsRate) {
        getTDSData();
        populateTDSRate(false);
      }
    } else {
      getTDSData();
    }
  }, []);

  useEffect(() => {
    calculateTDSAmount();
  }, [tdsRate, assessableAmount]);

  useEffect(() => {
    if (natureOfPayment?.value) {
      getTDSData(natureOfPayment.value.natureOfPayment);
    }
  }, [natureOfPayment]);

  useEffect(() => {
    registerInteractions();
  });

  /* helper function goes here*/

  const registerInteractions = () => {
    props.passingInteraction({
      type: POPUP_CALLBACKS_TYPE.DEDUCT_TDS_SUBMIT,
      data: () => {
        saveTDSAmount();
      }
    });
  };
  const getAndSetNatureOfPayment = () => {
    let value = tdsNatureOfIncomeData?.find(
      (data: any) => data.natureOfPayment === props?.tdsData?.incomePayment
    );
    setNatureOfPayment({
      ...natureOfPayment,
      value,
      canValidate: value === undefined,
      errorMessage: 'Please select a nature of payment'
    });
  };
  const getAndSetPayableAccount = (defaultPayableAccountCode = null) => {
    let account = accountsData?.find(
      (data: any) =>
        data.code ===
        (defaultPayableAccountCode
          ? defaultPayableAccountCode
          : localTDSData.current.tdsAccount)
    );
    if (!account) {
      CoaService.apiConfig = {};
      if (defaultPayableAccountCode) {
        CoaService.apiConfig.Query = `code=${defaultPayableAccountCode}`;
      } else {
        CoaService.apiConfig.Query = `accountCode=${props.tdsData.payableAccount}`;
      }
      setIsLoadingAccount(true);
      CoaService.getCOA().then(
        (res: any) => {
          if (defaultPayableAccountCode) {
            account = res.content.find(
              (data: any) => data.code === defaultPayableAccountCode
            );
          } else {
            account = res.content.find(
              (data: any) => data.accountCode === props.tdsData.payableAccount
            );
          }

          if (account) {
            setAccountsData([account]);
            setPayableAccount({ ...payableAccount, value: account });
          }
          setIsLoadingAccount(false);
        },
        (err) => {
          console.error('Error loading accounts data: ', err);
          setIsLoadingAccount(false);
        }
      );
    } else {
      setPayableAccount((prevState: any) => {
        return { ...prevState, value: account };
      });
    }
  };
  const populateTDSRate = (contactRate: boolean = false) => {
    if (contactRate) {
      let rate = localTDSData.current?.tdsRate || 0;
      if (
        localTDSData.current?.resident &&
        props.tdsData.contact.tdsInfoIndia.higherRate > 0
      ) {
        rate = props.tdsData.contact.tdsInfoIndia.higherRate;
      }
      if (
        localTDSData.current?.resident &&
        props.tdsData.contact.tdsInfoIndia.nonDeductionApplicable &&
        props.tdsData.contact.tdsInfoIndia.noDeductionTdsRate > 0
      ) {
        let fromDate = DateFormatService.getDateFromStr(
          props.tdsData.contact.tdsInfoIndia.noDeductionFromDate,
          BOOKS_DATE_FORMAT['YYYY-MM-DD']
        );
        let toDate = DateFormatService.getDateFromStr(
          props.tdsData.contact.tdsInfoIndia.noDeductionToDate,
          BOOKS_DATE_FORMAT['YYYY-MM-DD']
        );
        let documentDate = DateFormatService.getDateFromStr(
          props.tdsData.documentDate,
          BOOKS_DATE_FORMAT['YYYY-MM-DD']
        );
        if (fromDate <= documentDate && documentDate <= toDate) {
          rate = props.tdsData.contact.tdsInfoIndia.noDeductionTdsRate;
        }
      }
      if (
        !localTDSData.current?.resident &&
        props.tdsData.contact.tdsInfoIndia.dttaApplicable &&
        props.tdsData.contact.tdsInfoIndia.specialRate > 0
      ) {
        let fromDate = DateFormatService.getDateFromStr(
          props.tdsData.contact.tdsInfoIndia.dttaFromDate,
          BOOKS_DATE_FORMAT['YYYY-MM-DD']
        );
        let toDate = DateFormatService.getDateFromStr(
          props.tdsData.contact.tdsInfoIndia.dttaToDate,
          BOOKS_DATE_FORMAT['YYYY-MM-DD']
        );
        let documentDate = DateFormatService.getDateFromStr(
          props.tdsData.documentDate,
          BOOKS_DATE_FORMAT['YYYY-MM-DD']
        );

        if (fromDate <= documentDate && documentDate <= toDate) {
          rate = props.tdsData.contact.tdsInfoIndia.specialRate;
        }
      }

      setTDSRate({ ...tdsRate, value: rate });
    } else {
      setTDSRate({ ...tdsRate, value: props.tdsData.tdsRate });
    }
  };
  const calculateTDSAmount = () => {
    const amount = (Number(assessableAmount.value) * tdsRate.value) / 100;
    setTDSAmount({ ...tdsAmount, value: amount });
  };
  const getTDSData = (natureOfPayment = null) => {
    let payload = {
      deducteeType: props.tdsData.contact.tdsInfoIndia.deducteeType,
      documentDate: props.tdsData.documentDate,
      natureOfPayment: natureOfPayment || props.tdsData.incomePayment,
      resident: props.tdsData.contact.resident
    };
    TDSService.getTDSRateDetails(payload).then(
      (res: any) => {
        if (res) {
          localTDSData.current = res;
          if (props.tdsData.tdsRate && localTDSData.current) {
            localTDSData.current.tdsRate = props.tdsData.tdsRate;
          }
          if (
            props.tdsData.payableAccount !== DEFAULT_TDS_PAYABLE_ACCOUNT_CODE
          ) {
            getAndSetPayableAccount(props.tdsData.payableAccount);
          } else if (
            (payableAccount && !payableAccount.value) ||
            payableAccount?.value?.code !== localTDSData.current.tdsAccount
          ) {
            getAndSetPayableAccount();
          }

          setTDSNotFound(false);
          populateTDSRate(true);
        }
      },
      (err: any) => {
        if (err?.code === 404) {
          setTDSRate({ ...tdsRate, value: 0 });
          setTDSNotFound(true);
          localTDSData.current = {
            deducteeType: props.tdsData.contact.tdsInfoIndia.deducteeType,
            natureOfPayment: props.tdsData.incomePayment,
            resident: props.tdsData.contact.resident,
            tdsRate: props.tdsData.tdsRate,
            tdsAccount: props.tdsData.payableAccount
          };
          if (
            (payableAccount && !payableAccount.value) ||
            payableAccount?.value?.code !== localTDSData.current.tdsAccount
          ) {
            getAndSetPayableAccount();
          }
          populateTDSRate(true);
        }
      }
    );
  };
  const saveTDSAmount = () => {
    if (
      tdsAmount.value == null ||
      Utility.isEmpty(payableAccount.value) ||
      Utility.isEmpty(natureOfPayment.value) ||
      tdsNotFound
    ) {
      return;
    }
    const dataToSend = {
      lineAmount: lineAmount.value,
      assessableAmount: assessableAmount.value,
      tdsAmount: tdsAmount.value,
      deducteeType: deducteeType.value,
      tdsRate: tdsRate.value,
      incomePayment: natureOfPayment.value.natureOfPayment,
      remark: remark.value,
      payableAccount: payableAccount.value.code
    };
    props.passingInteraction({
      type: POPUP_CALLBACKS_TYPE.DEDUCT_TDS_SUCCESS,
      data: dataToSend
    });
  };

  /* subRender goes here*/

  /* Actual render goes here*/
  return (
    <div className={`tds-calculation-wrapper parent-size p-h-r`}>
      <div className="row">
        <DKInput
          title={deducteeType.title}
          type={INPUT_TYPE.TEXT}
          required={deducteeType.required}
          readOnly={deducteeType.readOnly}
          canValidate={deducteeType.canValidate}
          errorMessage={deducteeType.errorMessage}
          placeholder={deducteeType.placeHolder}
          direction={INPUT_VIEW_DIRECTION.VERTICAL}
          value={deducteeType.value}
          onChange={(value: any) => setDeducteeType({ ...deducteeType, value })}
        />
      </div>
      <div className="row mt-r">
        <div className="column parent-width">
          <DKInput
            title={lineAmount.title}
            type={INPUT_TYPE.NUMBER}
            required={lineAmount.required}
            readOnly={lineAmount.readOnly}
            canValidate={lineAmount.canValidate}
            errorMessage={lineAmount.errorMessage}
            placeholder={lineAmount.placeHolder}
            direction={INPUT_VIEW_DIRECTION.VERTICAL}
            value={lineAmount.value}
            onChange={(value: any) => setLineAmount({ ...lineAmount, value })}
          />
        </div>
        <div className="column parent-width ml-m">
          <DKInput
            title={assessableAmount.title}
            type={INPUT_TYPE.NUMBER}
            required={assessableAmount.required}
            readOnly={assessableAmount.readOnly}
            canValidate={true}
            errorMessage={assessableAmount.errorMessage}
            placeholder={assessableAmount.placeHolder}
            direction={INPUT_VIEW_DIRECTION.VERTICAL}
            value={assessableAmount.value}
            validator={(value: any) => value <= lineAmount.value}
            onChange={(value: any) => {
              let canValidate = false;
              let errorMessage = '';
              if (value > lineAmount.value) {
                canValidate = true;
                errorMessage = 'Assessable Amount can not exceed Line Amount';
              }
              setAssessableAmount({
                ...assessableAmount,
                value,
                canValidate,
                errorMessage
              });
              if (value < lineAmount.value) {
                setTDSAmount((prevState: any) => {
                  return {
                    ...prevState,
                    value: +value * (tdsRate.value / 100)
                  };
                });
              }
            }}
          />
        </div>
      </div>
      <div className="row mt-xl">
        <div className="column parent-width">
          <DKInput
            title={tdsRate.title}
            type={INPUT_TYPE.NUMBER}
            required={tdsRate.required}
            readOnly={tdsRate.readOnly}
            canValidate={true}
            validator={(value: any) => value <= 100}
            errorMessage={tdsRate.errorMessage}
            placeholder={tdsRate.placeHolder}
            direction={INPUT_VIEW_DIRECTION.VERTICAL}
            value={tdsRate.value}
            onChange={(value: any) => {
              let canValidate = false;
              let errorMessage = '';
              if (value > 100) {
                canValidate = true;
                errorMessage = 'Invalid percentage value';
              }
              setTDSRate((prevState: any) => {
                return {
                  ...prevState,
                  value: +value,
                  canValidate,
                  errorMessage
                };
              });

              if (+value >= 0 && value <= 100) {
                setTDSAmount((prevState: any) => {
                  return {
                    ...prevState,
                    value: +assessableAmount.value * (+value / 100)
                  };
                });
              }
            }}
          />
        </div>
        <div className="column parent-width ml-m">
          <DKInput
            title={tdsAmount.title}
            type={INPUT_TYPE.TEXT}
            required={tdsAmount.required}
            readOnly={tdsAmount.readOnly}
            canValidate={tdsAmount.canValidate}
            errorMessage={tdsAmount.errorMessage}
            placeholder={tdsAmount.placeHolder}
            direction={INPUT_VIEW_DIRECTION.VERTICAL}
            value={tdsAmount.value}
            onChange={(value: any) => {}}
          />
        </div>
      </div>
      <div className="row mt-xl">
        <div className="column parent-width position-relative">
          {isLoadingAccount && (
            <div className="position-absolute right-2 top-2">
              <DKSpinner iconClassName="ic-xs" />
            </div>
          )}

          {payableAccount && (
            <DKInput
              title={payableAccount.title}
              type={INPUT_TYPE.DROPDOWN}
              required={payableAccount.required}
              readOnly={payableAccount.readOnly}
              canValidate={payableAccount.canValidate}
              errorMessage={payableAccount.errorMessage}
              placeholder={payableAccount.placeHolder}
              direction={INPUT_VIEW_DIRECTION.VERTICAL}
              value={payableAccount.value}
              onChange={(value: any) => {
                setPayableAccount((prevState: any) => {
                  return { ...prevState, value: value };
                });
              }}
              formatter={(data: any) => data.name}
              dropdownConfig={{
                data: accountsData ? accountsData : [],
                allowSearch: true,
                searchableKey: 'name',
                renderer: (index: number, data: any) => data.name
              }}
            />
          )}
        </div>
        <div className="column parent-width ml-m position relative">
          <DKInput
            title={remark.title}
            type={INPUT_TYPE.TEXT}
            required={remark.required}
            readOnly={remark.readOnly}
            canValidate={remark.canValidate}
            errorMessage={remark.errorMessage}
            placeholder={remark.placeHolder}
            direction={INPUT_VIEW_DIRECTION.VERTICAL}
            value={remark.value}
            onChange={(value: any) => setRemark({ ...remark, value })}
          />
        </div>
      </div>
      <div className="row mt-r position-relative">
        <DKInput
          title={natureOfPayment.title}
          type={INPUT_TYPE.DROPDOWN}
          required={natureOfPayment.required}
          readOnly={natureOfPayment.readOnly}
          canValidate={natureOfPayment.canValidate}
          errorMessage={natureOfPayment.errorMessage}
          placeholder={natureOfPayment.placeHolder}
          direction={INPUT_VIEW_DIRECTION.VERTICAL}
          value={natureOfPayment.value}
          onChange={(value: any) =>
            setNatureOfPayment({
              ...natureOfPayment,
              value,
              canValidate: false,
              errorMessage: ''
            })
          }
          formatter={(data: any) => {
            return data.natureOfPayment;
          }}
          dropdownConfig={{
            data: tdsNatureOfIncomeData,
            allowSearch: true,
            searchableKey: 'natureOfPayment',
            renderer: (index: number, data: any) => data.natureOfPayment
          }}
        />
      </div>
      {tdsNotFound && (
        <div className="row mt-l fw-m text-red">
          We could not find TDS Rate for selected nature of payment.
        </div>
      )}
    </div>
  );
};

export default TDSCalculation;
