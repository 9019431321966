import ApiConstants from '../Constants/ApiConstants';
import { showAlert } from 'deskera-ui-library';
import http from './Interceptor';
import DateFormatService from './DateFormat';
import { BOOKS_DATE_FORMAT } from '../Constants/Constant';
import { MachineSchedule } from './MachineSchedulerSchedulesService';
import { ApiResponse } from '../Models/Common';
import { AxiosResponse } from 'axios';
import Utility from '../Utility/Utility';

let date = new Date();

export const initailSchedulerState: MachineSchedulerData = {
  frequencyType: 'Days',
  isAdhoc: false,
  machineId: 0,
  scheduleDuration: 0,
  id: null as any,
  scheduleName: '',
  schedules: [],
  totalEvents: 0,
  repeatInEvery: 0,
  maintenanceType: '',
  scheduleEndDate: DateFormatService.getDateStrFromDate(
    new Date(date.getFullYear(), date.getMonth(), date.getDate()),
    BOOKS_DATE_FORMAT['ISO-8601']
  ),
  scheduleStartDate: DateFormatService.getDateStrFromDate(
    new Date(date.getFullYear(), date.getMonth(), date.getDate()),
    BOOKS_DATE_FORMAT['ISO-8601']
  ),
  materialConsumptionId: null
};
export type MachineSchedulerData = {
  scheduleName: string;
  scheduleStartDate: string;
  scheduleDuration: number;
  maintenanceType: string;
  isAdhoc: boolean;
  repeatInEvery: number;
  frequencyType: string;
  totalEvents: number;
  assetId?: number;
  scheduleEndDate: string;
  machine?: {
    name: string;
    assetId: string;
    [key: string]: any;
  };
  machineId: number;
  schedules: MachineSchedule[];
  id: number;
  materialConsumptionId: number | null;
};

export interface MachineSchedulerConfig {
  customfield?: string;
  fetchAttachmentDetails?: boolean;
  limit?: number;
  page?: number;
  query?: string;
  search?: string;
  sort?: string;
  sortDir?: 'asc' | 'desc';
  queryParam: Partial<MachineSchedulerData>;
}

export type MachineSchedulerQueryParams = Partial<MachineSchedulerData>;

export class MachineSchedulerService {
  private static defaultConfig: MachineSchedulerConfig = {
    fetchAttachmentDetails: true,
    limit: 10,
    page: 0,
    sortDir: 'asc',
    queryParam: {},
    sort: '',
    search: '',
    query: ''
  };
  static config: MachineSchedulerConfig = this.defaultConfig;

  private static getParams() {
    const paramsObj = {
      limit: this.config.limit,
      page: this.config.page,
      query: this.getQueryParams(),
      sort: this.config.sort,
      sortDir: this.config.sortDir,
      search: this.config.search,
      fetchAttachmentDetails: this.config.fetchAttachmentDetails
    };

    const filteredParams = Object.entries(paramsObj)
      .filter(([_, value]) => value !== undefined && value !== null)
      .reduce((acc, [key, value]) => {
        acc[key] = String(value);
        return acc;
      }, {} as Record<string, string>);
    return new URLSearchParams(filteredParams);
  }

  private static getQueryParams() {
    if (!Utility.isEmpty(this.config.queryParam)) {
      const filteredParams = Object.fromEntries(
        Object.entries(this.config.queryParam).filter(
          ([, value]) => value !== undefined
        )
      );
      // @ts-ignore
      const queryString = new URLSearchParams(filteredParams).toString();
      return queryString;
    } else {
      return '';
    }
  }

  static async createMachineSchedule(payload: MachineSchedulerData) {
    try {
      const url = ApiConstants.URL.MRP.MACHINE_TOOLS_MASTER.MACHINE_SCHEDULER;
      const response = await http.post(url, payload);
      return response;
    } catch (error: any) {
      showAlert('Error', error?.message);
      console.error('Error creating machine schedule: ', error);
      return Promise.reject(error);
    }
  }

  static async updateMachineSchedule(
    id: string,
    payload: MachineSchedulerData
  ) {
    try {
      const url =
        ApiConstants.URL.MRP.MACHINE_TOOLS_MASTER.MACHINE_SCHEDULER + '/' + id;
      const response = await http.put(url, payload);
      return response;
    } catch (error: any) {
      showAlert('Error', error?.message);
      console.error('Error updating machine schedule: ', error);
      return Promise.reject(error);
    }
  }

  static async getMachineSchedulers(): Promise<
    ApiResponse<MachineSchedulerData>
  > {
    try {
      let url = ApiConstants.URL.MRP.MACHINE_TOOLS_MASTER.GET_MACHINE_SCHEDULER;
      url += '?' + this.getParams().toString() + '&';
      url += 'fetchAttachmentDetails=true';

      const response = await http.get(url);
      return response as ApiResponse<MachineSchedulerData>;
    } catch (error: any) {
      showAlert('Error', error?.message);
      console.error('Error fetching machine schedules: ', error);
      return Promise.reject(error);
    }
  }

  static async deleteMachineScheduler(id: string) {
    try {
      const url =
        ApiConstants.URL.MRP.MACHINE_TOOLS_MASTER.MACHINE_SCHEDULER + '/' + id;
      const response = await http.delete(url);
      return response;
    } catch (error: any) {
      showAlert('Error', error?.message);
      console.error('Error deleting machine schedule: ', error);
      return Promise.reject(error);
    }
  }
}
