import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { API_STATUS } from '../../Constants/Constant';
import { REMOTE_CONFIG_TABLES } from '../../Constants/TableConstants';
import { TableManager } from '../../Managers/TableManager';
import { ConfigPayload, GridState } from '../../Models/ReduxStore';
import { IColumn } from '../../Models/Table';
import WorkoutService from '../../Services/Workout';
import { deepClone } from '../../Utility/Utility';
import { RootState } from '../Store';

const initialState: GridState = {
  data: {} as any,
  columnConfig: [],
  configTableId: '',
  status: API_STATUS.IDLE,
  refreshCallback: []
};

export const fetchWorkOuts = createAsyncThunk('Work outs', async () => {
  const response = await WorkoutService.getWorkoutsByPage();
  return response;
});

export const WorkOutSlice = createSlice({
  name: 'WorkOut',
  initialState,
  reducers: {
    addWorkoutColumnConfig: (state, action: PayloadAction<ConfigPayload>) => {
      const tableId = action.payload.tableId;
      const configs = action.payload.config;
      let configsToStore: any[] = [];
      configs.forEach((config) => {
        configsToStore.push({
          ...config,
          key: config.columnCode,
          options: config.options ? config.options : []
        });
      });
      state.columnConfig = configsToStore;
      state.configTableId = tableId;

      TableManager.updateColumnConfig(
        configsToStore,
        REMOTE_CONFIG_TABLES.WORK_OUT
      );
    },
    updateColumnConfig: (state, action: PayloadAction<IColumn[]>) => {
      state.columnConfig = [...action.payload];
    },
    addColumnToWorkoutColumnConfig: (state, action: PayloadAction<IColumn>) => {
      state.columnConfig = [...state.columnConfig, action.payload];
    },
    updateWorkoutDetails: (state, action: PayloadAction<any>) => {
      const workOutDetails = action.payload;
      const pageDetails: any = deepClone(state.data);
      const content: any[] = pageDetails.content;
      const workOutIndex: number = content.findIndex(
        (contact: any) => contact.id === workOutDetails.id
      );
      if (workOutIndex >= 0) {
        content[workOutIndex] = workOutDetails;
        state.data['content'] = [...content];
      }
    },
    updateRefreshCallback: (state, action: PayloadAction<any>) => {
      state.refreshCallback = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchWorkOuts.pending, (state) => {
        state.gridContentLoadingStatus = API_STATUS.LOADING;
      })
      .addCase(fetchWorkOuts.rejected, (state) => {
        state.gridContentLoadingStatus = API_STATUS.IDLE;
      })
      .addCase(fetchWorkOuts.fulfilled, (state, action) => {
        state.gridContentLoadingStatus = API_STATUS.IDLE;
        state.data = action.payload;
      });
  }
});

export const {
  addWorkoutColumnConfig,
  updateColumnConfig,
  updateWorkoutDetails,
  updateRefreshCallback
} = WorkOutSlice.actions;

export const selectWorkOuts = (state: RootState) => state.workOut.data;

export const selectWorkOutsLoadingStatus = (state: RootState) =>
  state.workOut.gridContentLoadingStatus;

export const selectWorkOutsColumnConfig = (state: RootState) =>
  state.workOut.columnConfig;

export const selectWorkOutsColumnConfigTableId = (state: RootState) =>
  state.workOut.configTableId;

export const selectRefreshCallbackJWO = (state: RootState) =>
  state.workOut.refreshCallback;

export default WorkOutSlice.reducer;
