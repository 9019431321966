import {
  BOOKS_DATE_FORMAT,
  DOC_TYPE,
  LABELS,
  LOCAL_STORAGE_KEYS,
  PRODUCE_PRODUCT_TYPE,
  PRODUCT_TYPE,
  QTY_ROUNDOFF_PRECISION
} from '../../../Constants/Constant';
import { DKIcons, showLoader, removeLoader } from 'deskera-ui-library';
import ic_delivery from '../../../Assets/Icons/ic_delivery.png';
import ic_barcode_black from '../../../Assets/Icons/ic_barcode_black.svg';
import { ADVANCE_TRACKING } from '../../../Constants/Enum';
import { selectUOMs } from '../../../Redux/Slices/CommonDataSlice';
import { Store } from '../../../Redux/Store';
import Utility from '../../../Utility/Utility';
import { BOM_EXPLOSION_COLUMN_KEYS } from '../Constants/MRPColumnConfigs';
import { BOM_EXPLOSION_ALLOCATION_TYPE } from '../WorkOrder/BomExplosionAllocationConfirmation';
import {
  calculateAdvancedTrackedReservedQuantities,
  calculateNoneTrackedReservedQuantities,
  evaluateAllInventoryInTaggedWarehouse,
  getTaggedWHQtyMessage,
  mapInventoryToReservedQtyData,
  mergeAvailableAndPickedSubstituteDetailsFromExplosionData
} from '../WorkOrder/WorkOrderHelper';
import { WORK_ORDER_PR_PO } from '../../Settings/AdvancedSettings/AdvancedSettings';
import { OrderInitialState } from '../../../Models/Order';
import { OrderInitialState as RequisitionOrderInitialState } from '../../../Models/RequisitionOrder';
import DateFormatService from '../../../Services/DateFormat';
import { IWorkOrder } from '../../../Services/MRP/WorkOrder';
import ProductService from '../../../Services/Product';
import TaxService from '../../../Services/Tax';
import { getJobWorkOutObject } from '../Workout/WorkOutHelper';
import { JobWorkOutInitialState, WorkOut } from '../../../Models/Workout';
import { REQUIRED_ITEM_TABLE } from '../Constants/TableConstant';
import { getUpdatedRequisitonNewOrderObject } from '../../Requisitions/RequisitionHelper';
import {
  draftTableId,
  selectDraftsColumnConfig
} from '../../../Redux/Slices/DraftsSlice';
import { getUpdatedPurchaseOrderObject } from '../../PurchaseOrders/PurchaseOrderHelper';
import ContactService from '../../../Services/Contact';
import { checkUserPermission } from '../../Settings/GranularPermissions/GranularPermissionsHelper';
import { PERMISSIONS_BY_MODULE } from '../../../Constants/Permission';
import WarehouseManagementHelper from '../../../SharedComponents/WarehouseManagement/WarehouseManagementHelper';

export class BOMExplosionHelper {
  static showCheckboxTooltipExplosion = (
    data: any,
    taggedWHProductAvailability: any
  ) => {
    const condition = !(
      availabilityStatus(data) !== AVAILABILITY_STATUS.AVAILABLE ||
      (!Utility.isEmpty(taggedWHProductAvailability?.details) &&
        !taggedWHProductAvailability?.details?.allItemsAvailableInTaggedWH)
    );
    return condition;
  };

  static isWOAlreadyCreated = (data: any) => {
    const isCreated = data?.some(
      (item: any) => !Utility.isEmpty(item?.workOrderChildDetails)
    );

    return isCreated;
  };

  static isPOPRAlreadyCreated = (data: any) => {
    const isCreated = data?.some(
      (item: any) => !Utility.isEmpty(item?.linkedPOPRData)
    );

    return isCreated;
  };

  static isCreatePOPRVisible = (productsArr: any) => {
    const filtered = productsArr?.filter((item: any) => {
      return availabilityStatus(item) !== AVAILABILITY_STATUS.AVAILABLE;
    });
    const condition = !Utility.isEmpty(filtered);
    return condition;
  };
  static isCreateWOVisible = (productsArr: any) => {
    const filtered = (productsArr || []).filter((item: any) => {
      return (
        (item.productType ?? item.type) === PRODUCT_TYPE.BILL_OF_MATERIALS &&
        Utility.isEmpty(item?.workOrderChildDetails)
      );
    });
    return !Utility.isEmpty(filtered);
  };

  static getSubstituteAvailableQty = (substituteData: any) => {
    const availableQty = substituteData?.availableQuantity ?? 0;
    const reservedQty = substituteData?.reservedQuantity ?? 0;

    let qty = availableQty - reservedQty ?? 0;
    qty = qty ?? 0;

    const isNegativeInventoryOn =
      Store.getState().authInfo.currentTenantInfo.data?.allowNegativeInventory;

    if (qty < 0 && !isNegativeInventoryOn) {
      return 0;
    } else {
      return qty;
    }
  };

  static getFilteredComponentProductsForDropdown(
    allProducts: any[],
    bomExplosionData: any
  ) {
    const existingSelectedComponentProducts = (
      bomExplosionData?.bomProductConfiguration || []
    ).filter(
      (item: any) => item.produceProductType === PRODUCE_PRODUCT_TYPE.NONE
    );

    let selectedProductIds: string[] = existingSelectedComponentProducts.map(
      (product: any) => product?.pid
    );

    selectedProductIds.push(bomExplosionData?.pid);

    let filtered =
      allProducts?.filter(
        (prodItem: any) =>
          prodItem.active &&
          !selectedProductIds?.includes(prodItem.productId) &&
          prodItem?.hasVariants === false
      ) || [];
    return filtered;
  }
}

export const getSelectedUOMValue = (id: any) => {
  try {
    const uomList = selectUOMs(Store.getState());
    let selectedUomById = uomList?.find((item: any) => item.id === id);
    return selectedUomById?.name || '';
  } catch (err) {
    return '';
  }
};

export const calculateReservedQuantitiesDataForSubstitutes = (
  workOrderItem: any,
  existingWOItem: any
): any[] => {
  const originalWorkOrderItem =
    existingWOItem?.bomProductSubstitutesDetails?.find(
      (wo: any) => wo?.productId === workOrderItem?.productId
    );
  let reservedQuantities: any[] = [];
  const inventoryData =
    workOrderItem?.advancedTracking === ADVANCE_TRACKING.NONE
      ? calculateNoneTrackedReservedQuantities(
          workOrderItem,
          originalWorkOrderItem
        )
      : calculateAdvancedTrackedReservedQuantities(
          workOrderItem,
          originalWorkOrderItem
        );
  const updatedWorkOrderItem = {
    ...workOrderItem,
    itemName: {
      pid: workOrderItem.productId,
      inventory: { availableQuantity: workOrderItem.availableQuantity },
      advancedTracking: workOrderItem.advancedTracking
    }
  };
  reservedQuantities = inventoryData.map((inventoryItem: any) =>
    mapInventoryToReservedQtyData(inventoryItem, updatedWorkOrderItem)
  );
  return reservedQuantities;
};

export const saveTrackingDetails = (
  trackData: any,
  mainData: any,
  selectedProduct: any,
  type: any,
  isAutoAllocateFlow: boolean = false,
  substituteItem: any = null,
  existingWOItem?: any
) => {
  if (!Utility.isEmpty(mainData?.bomProductConfiguration)) {
    mainData.bomProductConfiguration = mainData?.bomProductConfiguration?.map(
      (innerData: any) => {
        if (innerData.uniqueId === selectedProduct?.uniqueId) {
          if (type === 'SUBSTITUTE') {
            if (isAutoAllocateFlow && !Utility.isEmpty(substituteItem)) {
              innerData[BOM_EXPLOSION_COLUMN_KEYS.ASSIGNED_SUBSTITUTES] =
                innerData?.[
                  BOM_EXPLOSION_COLUMN_KEYS.ASSIGNED_SUBSTITUTES
                ]?.map((innerDataItem: any) => {
                  if (innerDataItem?.productId === substituteItem?.productId) {
                    innerDataItem = {
                      ...innerDataItem,
                      warehouseInventoryData: trackData,
                      reservedQuantitiesData:
                        calculateReservedQuantitiesDataForSubstitutes(
                          {
                            ...innerDataItem,
                            warehouseInventoryData: trackData
                          },
                          existingWOItem
                        )
                    };
                  }
                  return innerDataItem;
                });
            } else {
              innerData[BOM_EXPLOSION_COLUMN_KEYS.ASSIGNED_SUBSTITUTES] =
                trackData?.[BOM_EXPLOSION_COLUMN_KEYS.AVAILABLE_SUBSTITUTES];
              const availableSubstitutes = (
                innerData[BOM_EXPLOSION_COLUMN_KEYS.AVAILABLE_SUBSTITUTES] || []
              ).map((substitute: any) => ({
                ...substitute,
                isProductPicked: false
              }));
              innerData[
                BOM_EXPLOSION_COLUMN_KEYS.ASSIGNED_SUBSTITUTES
              ]?.forEach((assignedSubstitute: any) => {
                const existingAvailableSubstitute = availableSubstitutes.find(
                  (availableSubstitute: any) =>
                    availableSubstitute.productId ===
                      assignedSubstitute.productId ||
                    availableSubstitute.pid === assignedSubstitute.pid
                );
                if (!existingAvailableSubstitute) {
                  availableSubstitutes.push(assignedSubstitute);
                } else if (assignedSubstitute?.isProductPicked) {
                  existingAvailableSubstitute.isProductPicked = true;
                }
              });
              innerData[BOM_EXPLOSION_COLUMN_KEYS.ASSIGNED_SUBSTITUTES] =
                innerData[
                  BOM_EXPLOSION_COLUMN_KEYS.ASSIGNED_SUBSTITUTES
                ]?.filter((assignedSub: any) => assignedSub.isProductPicked);
              innerData[BOM_EXPLOSION_COLUMN_KEYS.AVAILABLE_SUBSTITUTES] =
                availableSubstitutes;
            }
          } else {
            innerData = {
              ...innerData,
              warehouseInventoryData: trackData
            };
          }
          return innerData;
        } else {
          return saveTrackingDetails(
            trackData,
            innerData,
            selectedProduct,
            type
          );
        }
      }
    );
    return mainData;
  } else {
    mainData = {
      ...mainData
      // warehouseInventoryData: trackData
    };
    return mainData;
  }
};

export const isAvailable = (data: any) => {
  return availabilityStatus(data) !== AVAILABILITY_STATUS.NOT_AVAILABLE;
};

export const getTotalAlloted = (data: any) => {
  return (
    data?.warehouseInventoryData?.reduce((prev: any, current: any) => {
      return prev + Number(current.quantity);
    }, 0) ?? 0
  );
};

export const getTotalReservedQtyWithoutSubstitutes = (rowData: any) => {
  return Number(
    rowData?.unmodifiedSelectedProduct?.reduce((prev: any, current: any) => {
      return prev + Number(current.quantity || 0);
    }, 0) ?? 0
  );
};

export const calculateTotalSubstituteAlloted = (item: any) => {
  let totalAlloted =
    item?.[BOM_EXPLOSION_COLUMN_KEYS.ASSIGNED_SUBSTITUTES]?.reduce(
      (prev: any, current: any) => {
        let totalAllotedCurrent =
          current?.warehouseInventoryData?.reduce(
            (prevValue: any, currentObj: any) => {
              return Number(prevValue) + Number(currentObj.quantity);
            },
            0
          ) || 0;
        return Number(prev) + Number(totalAllotedCurrent);
      },
      0
    ) || 0;
  return totalAlloted;
};

export const getTotalAllotedQtyWithoutSubstitute = (data: any) => {
  // return getTotalAlloted(data) + calculateTotalSubstituteAlloted(data);
  return getTotalAlloted(data); // returns value without calculation substitute qry
};

export const getTotalAllotedQty = (data: any) => {
  return getTotalAlloted(data) + calculateTotalSubstituteAlloted(data);
};

export const calculateRequiredQtyForBomComponentProduct = (
  plannedQuantity: any,
  data: any
) => {
  let num =
    Number(plannedQuantity ?? 0) *
    data?.[BOM_EXPLOSION_COLUMN_KEYS.REQUIRED_UNIT_QUANTITY];
  if (data?.advancedTracking === ADVANCE_TRACKING.SERIAL) {
    return Math.round(num);
  }
  return Utility.roundingOff(num ?? 0, QTY_ROUNDOFF_PRECISION);
};

/** @deprecated  */
export const getRequiredQtyToShowOnGrid = (data: any) => {
  let num =
    data?.[BOM_EXPLOSION_COLUMN_KEYS.REQUIRED_QUANTITY] ??
    0 - getTotalAllotedQty(data) ??
    0;
  return num;
};

/** @deprecated  */
export const isMainProduct = (productId: any, data: any) => {
  return productId === data?.pid;
};

export const filterNonAvailableProductIds = (bomExplosionData: any) => {
  return bomExplosionData?.bomProductConfiguration
    ?.filter((componentProduct: any) => {
      return (
        componentProduct?.pid &&
        availabilityStatus(componentProduct) !== AVAILABILITY_STATUS.AVAILABLE
      );
    })
    .map((componentProduct: any) => componentProduct.pid);
};

export const filterWIPProductsOnly = (data: any) => {
  return data?.filter((item: any) => {
    return (
      item.productType === PRODUCT_TYPE.BILL_OF_MATERIALS ||
      item.type === PRODUCT_TYPE.BILL_OF_MATERIALS
    );
  });
};

export const getShortFallQuantity = (
  data: any
): { availableQty: number; requiredQty: number; shortFallQty: number } => {
  const availableQty = Utility.isNotEmpty(data?.documentUOMSchemaDefinition)
    ? Number(data?.availableUomQuantity ?? 0)
    : Number(data?.availableQuantity ?? 0);
  const totalReservedQty = getTotalReservedQtyWithoutSubstitutes(data);
  let requiredQty = Number(
    data?.[BOM_EXPLOSION_COLUMN_KEYS.REQUIRED_QUANTITY] ?? 0
  );
  requiredQty =
    requiredQty >= totalReservedQty
      ? requiredQty - totalReservedQty
      : requiredQty;

  const taggedWHProductAvailability = data?.taggedWHProductAvailabilityDetails;
  if (Utility.isNotEmpty(taggedWHProductAvailability)) {
    let totalAvailableStockInAllWH =
      taggedWHProductAvailability.totalAvailableStockInAllWH;
    let areAllQuantitiesInTargetWH =
      taggedWHProductAvailability.allItemsAvailableInTaggedWH;
    const isShortFall =
      !areAllQuantitiesInTargetWH && totalAvailableStockInAllWH !== 0;
    if (isShortFall)
      return {
        availableQty,
        requiredQty,
        shortFallQty: taggedWHProductAvailability.shortFallQty
      };
  }

  return {
    availableQty,
    requiredQty,
    shortFallQty: Utility.roundingOff(
      requiredQty - availableQty,
      QTY_ROUNDOFF_PRECISION
    )
  };
};

export const availabilityStatus = (data: any, forTargetWh?: boolean) => {
  if (data?.productType === PRODUCT_TYPE.NON_TRACKED) {
    return AVAILABILITY_STATUS.AVAILABLE;
  }

  const { availableQty, requiredQty, shortFallQty } =
    getShortFallQuantity(data);
  if (forTargetWh && shortFallQty > 0) {
    return AVAILABILITY_STATUS.SHORTFALL;
  }

  if (availableQty >= requiredQty) {
    return AVAILABILITY_STATUS.AVAILABLE;
  } else if (availableQty === 0) {
    return AVAILABILITY_STATUS.NOT_AVAILABLE;
  } else {
    return AVAILABILITY_STATUS.SHORTFALL;
  }
};

export const getAvailabilityStatus = (data: any, status: any) => {
  switch (status.toUpperCase()) {
    case AVAILABILITY_STATUS.AVAILABLE:
      return 'Available';
    case AVAILABILITY_STATUS.SHORTFALL:
      let { shortFallQty } = getShortFallQuantity(data);
      shortFallQty = Math.abs(
        Utility.roundOffToTenantDecimalScale(shortFallQty)
      );
      return `Shortfall (${shortFallQty})`;
    case AVAILABILITY_STATUS.NOT_AVAILABLE:
      return 'Not available';
  }
};

export const getAvailabilityStatusTextForTargetWH = (productData: any) => {
  const taggedWHProductAvailability =
    productData?.[BOM_EXPLOSION_COLUMN_KEYS.TAGGED_WH_DETAIL];
  const status =
    productData?.[BOM_EXPLOSION_COLUMN_KEYS.AVAILABILITY_TARGET_WH];

  switch (status) {
    case AVAILABILITY_STATUS.SHORTFALL:
      let shortFallQty = taggedWHProductAvailability?.shortFallQty || 0;
      return `Shortfall (${shortFallQty})`;
    case AVAILABILITY_STATUS.AVAILABLE:
      return 'Available';
    case AVAILABILITY_STATUS.NOT_AVAILABLE:
      return 'Not available';
    default:
      return '';
  }
};

export const getAvailabilityStatusColor = (status: any) => {
  const mrp_bg_green = '#08c569';
  const mrp_bg_orange = '#ffa404';
  const mrp_bg_red = '#e3505f';
  switch (status.toUpperCase()) {
    case AVAILABILITY_STATUS.AVAILABLE:
      return mrp_bg_green;
    case AVAILABILITY_STATUS.SHORTFALL:
      return mrp_bg_orange;
    case AVAILABILITY_STATUS.NOT_AVAILABLE:
      return mrp_bg_red;

    default:
      return mrp_bg_red;
  }
};

export const getTooltipMessageForTargetWHAvailability = (
  productData: any,
  activeWorkOrder: IWorkOrder
) => {
  if (!productData) return '';

  const isSubstitute = productData[BOM_EXPLOSION_COLUMN_KEYS.SUBSTITUTE];
  const isSubstituteOrComponentProductForWO =
    productData.level !== 0 || isSubstitute;
  const taggedWHDetails =
    productData[BOM_EXPLOSION_COLUMN_KEYS.TAGGED_WH_DETAIL];
  const hasStockInAllWHOrSubstitute =
    taggedWHDetails?.totalAvailableStockInAllWH !== 0 || isSubstitute;
  const areAllQuantitiesInTargetWH =
    taggedWHDetails?.allItemsAvailableInTaggedWH;
  const showWarningTooltip =
    isSubstituteOrComponentProductForWO &&
    hasStockInAllWHOrSubstitute &&
    !areAllQuantitiesInTargetWH;
  let tooltipMessage = '';
  if (showWarningTooltip) {
    let nonTaggedWHSummaryStr = '';
    if (!Utility.isEmpty(taggedWHDetails?.nonTaggedWHStockSummary)) {
      nonTaggedWHSummaryStr +=
        '<br><br>Quantities in other warehouse(s):<br><ul style="list-style-type: disc; margin-left: 15px; margin-top: 5px;">';
      taggedWHDetails.nonTaggedWHStockSummary.forEach((detail: any) => {
        nonTaggedWHSummaryStr += `<li><span class="fw-m">${detail.wh}</span>: ${detail.availableQuantity}</li>`;
      });
      nonTaggedWHSummaryStr += '</ul>';
    }
    tooltipMessage =
      getTaggedWHQtyMessage(activeWorkOrder.targetWarehouse?.name) +
      (nonTaggedWHSummaryStr ? `${nonTaggedWHSummaryStr}` : '');
  }

  return tooltipMessage;
};

export const AVAILABILITY_STATUS = {
  AVAILABLE: 'AVAILABLE',
  NOT_AVAILABLE: 'NOT_AVAILABLE',
  SHORTFALL: 'SHORTFALL'
};

export const isSubstituteAvailable = (data: any) => {
  let warehouseInventoryDataArray: any =
    data?.[BOM_EXPLOSION_COLUMN_KEYS.AVAILABLE_SUBSTITUTES]?.reduce(
      (prev: any, current: any) => [
        ...(prev || []),
        ...(current?.warehouseInventoryData || [])
      ],
      []
    ) || [];
  return !Utility.isEmpty(warehouseInventoryDataArray);
};

export const getTableCellLabel = (title: any, badgeDetails: any) => {
  let text = '';

  // if (!badgeDetails?.showBadge) {
  //   text = title;
  // } else {
  //   text = `<span class="row">${title} <div class="bg-chip-blue border-radius-l p-h-s ml-s p-v-xs">
  //   ${badgeDetails?.badgeName ?? ''}
  // </div></span>`;
  // }

  text = title;

  return text;
};

export const getWOCodeAttached = (
  lineData: any,
  parentLineData: any = null
) => {
  if (Utility.isEmpty(lineData?.workOrderChildDetails)) {
    return '';
  }
  const found = lineData?.workOrderChildDetails?.find((itemData: any) => {
    if (!Utility.isEmpty(parentLineData)) {
      return (
        itemData.productCode === lineData.pid &&
        parentLineData?.pid === itemData?.workOrderItemProductCode
      );
    } else {
      return itemData.productCode === lineData.pid;
    }
  });

  if (!Utility.isEmpty(found)) {
    return found?.workOrderSeqCode;
  } else {
    return '';
  }
};

export const MASTER_ALLOCATE_TYPE = {
  WIP: 'WIP',
  RM: 'RM'
};

export const BOM_EXPLOSION_BUTTON_TYPE = {
  PO: 'PO',
  WO: 'WO',
  PR: 'PR'
};

export const getProductsFromType = (
  bomProductConfiguration: any,
  type: any,
  getBoolean: boolean
) => {
  const specificTypeOfProduct = bomProductConfiguration?.filter(
    (componentProduct: any) => {
      if (type === MASTER_ALLOCATE_TYPE.WIP) {
        return (
          !Utility.isEmpty(componentProduct.bomProductConfiguration) &&
          componentProduct?.produceProductType === PRODUCE_PRODUCT_TYPE.NONE
        );
      } else {
        return (
          Utility.isEmpty(componentProduct.bomProductConfiguration) &&
          componentProduct?.productType !== PRODUCT_TYPE.NON_TRACKED &&
          componentProduct?.produceProductType === PRODUCE_PRODUCT_TYPE.NONE
        );
      }
    }
  );

  if (getBoolean) {
    return !Utility.isEmpty(specificTypeOfProduct);
  } else {
    return specificTypeOfProduct;
  }
};

const quantitiesAlreadyAllocated = (data: any) => {
  let totalQuantity = 0;

  // Loop over bomProductSubstitutesDetails
  if (
    data?.bomProductSubstitutesDetails &&
    data?.bomProductSubstitutesDetails?.length > 0
  ) {
    data.bomProductSubstitutesDetails?.forEach((bomProductSub: any) => {
      bomProductSub?.warehouseInventoryData?.forEach((substitute: any) => {
        totalQuantity += substitute.quantity || 0;
      });
    });
  }

  // Loop over warehouseInventoryData
  if (
    data?.warehouseInventoryData &&
    data?.warehouseInventoryData?.length > 0
  ) {
    data.warehouseInventoryData.forEach((warehouse: any) => {
      totalQuantity += warehouse.quantity || 0;
    });
  }

  return totalQuantity;
};

export const autoAllocateProduct = ({
  rawMaterialItem,
  warehouseProductsByIdResponse,
  activeWorkOrder,
  allocationType,
  rrbEnabled,
  quantitiesAllocated,
  updateTrackingDetails
}: {
  rawMaterialItem: any;
  warehouseProductsByIdResponse: {
    warehouses: any[];
  };
  activeWorkOrder: IWorkOrder;
  allocationType: BOM_EXPLOSION_ALLOCATION_TYPE;
  rrbEnabled: boolean;
  quantitiesAllocated?: boolean[];
  updateTrackingDetails: (
    advanceTracking: ADVANCE_TRACKING | 'SUBSTITUTE',
    result: any,
    rawMaterialItem: any,
    isSubstitute?: boolean,
    substituteRM?: any
  ) => void;
}) => {
  let allLoopedAndAllocatedResultQty =
    quantitiesAlreadyAllocated(rawMaterialItem);
  let allPreviouslyLoopedAndAllocatedResultQty =
    quantitiesAlreadyAllocated(rawMaterialItem);
  const warehouses = getProductWarehouseData(
    warehouseProductsByIdResponse?.warehouses ?? [],
    activeWorkOrder
  );
  let pickedSubstitutes = rawMaterialItem?.[
    BOM_EXPLOSION_COLUMN_KEYS.ASSIGNED_SUBSTITUTES
  ]?.filter((subItem: any) => subItem.isProductPicked);
  if (allocateMainProductAllowed(pickedSubstitutes, allocationType)) {
    if (rawMaterialItem.advancedTracking === ADVANCE_TRACKING.NONE) {
      let pendingQuantityToUpdate =
        rawMaterialItem?.[BOM_EXPLOSION_COLUMN_KEYS.REQUIRED_QUANTITY] -
        calculateTotalSubstituteAlloted(rawMaterialItem);
      pendingQuantityToUpdate = Utility.getUomWarehouseQuantity(
        pendingQuantityToUpdate,
        rawMaterialItem?.documentUOMSchemaDefinition
      );
      const selectedObj = {
        ...rawMaterialItem,
        pendingQuantity: pendingQuantityToUpdate,
        productCode: rawMaterialItem?.pid,
        targetWarehouse: activeWorkOrder.targetWarehouse
      };
      let result: any = [];
      if (!rrbEnabled) {
        result = Utility.masterAutoAllocateNoneTrackedWithoutRRB(
          warehouses,
          selectedObj,
          rawMaterialItem?.unmodifiedSelectedProduct
        );
      } else {
        result = Utility.masterAutoAllocateNoneTrackedWithRRB(
          warehouses,
          selectedObj,
          rawMaterialItem?.unmodifiedSelectedProduct
        );
      }

      allLoopedAndAllocatedResultQty +=
        result?.reduce((acc: any, item: any) => {
          return acc + (item.quantity ?? 0);
        }, 0) ?? 0;

      allLoopedAndAllocatedResultQty = +(
        allLoopedAndAllocatedResultQty -
        allPreviouslyLoopedAndAllocatedResultQty
      );

      if (!Utility.isEmpty(result)) {
        quantitiesAllocated?.push(true);
      } else {
        quantitiesAllocated?.push(false);
      }

      updateTrackingDetails(ADVANCE_TRACKING.NONE, result, rawMaterialItem);
    }

    if (rawMaterialItem.advancedTracking === ADVANCE_TRACKING.SERIAL) {
      let result = [];
      let selectedObj = prepareBomExplosionProductForAllocation(
        rawMaterialItem,
        ADVANCE_TRACKING.SERIAL
      );

      selectedObj = {
        ...selectedObj,
        productQuantity: selectedObj?.productQuantity,
        requiredQuantity: selectedObj?.productQuantity
      };

      result = Utility.masterAutoAllocateSerialTracked(
        warehouses,
        { ...selectedObj, targetWarehouse: activeWorkOrder?.targetWarehouse },
        rawMaterialItem?.unmodifiedSelectedProduct,
        rawMaterialItem?.pid
      );

      allLoopedAndAllocatedResultQty +=
        result?.reduce((acc: any, item: any) => {
          return acc + (item.qtyToFulfil ?? 0);
        }, 0) ?? 0;

      allLoopedAndAllocatedResultQty = +(
        allLoopedAndAllocatedResultQty -
        allPreviouslyLoopedAndAllocatedResultQty
      );

      if (rawMaterialItem.documentUOMSchemaDefinition) {
        const uomQty = Utility.getUomQuantityWithoutRoundOff(
          result.length,
          rawMaterialItem.documentUOMSchemaDefinition
        );
        if (!Number.isInteger(uomQty)) {
          // quantitiesAllocated?.push(false);
          return;
        }
      }
      if (!Utility.isEmpty(result)) {
        quantitiesAllocated?.push(true);
      } else {
        quantitiesAllocated?.push(false);
      }

      updateTrackingDetails(ADVANCE_TRACKING.SERIAL, result, rawMaterialItem);
    }

    if (rawMaterialItem.advancedTracking === ADVANCE_TRACKING.BATCH) {
      let result = [];
      let selectedObj: any = prepareBomExplosionProductForAllocation(
        rawMaterialItem,
        ADVANCE_TRACKING.BATCH
      );

      selectedObj = {
        ...selectedObj,
        requiredQuantity: selectedObj?.requiredQuantity,
        quantityRequired: Utility.getUomWarehouseQuantityWithoutRoundOff(
          selectedObj?.quantityRequired ?? selectedObj?.requiredQuantity,
          selectedObj?.documentUOMSchemaDefinition
        ),
        advancedTrackingFulfilmentData:
          selectedObj?.advancedTrackingFulfilmentData?.map(
            (itemBatchTrack: any) => {
              return {
                ...itemBatchTrack,
                qtyToFulfil: Utility.getUomWarehouseQuantityWithoutRoundOff(
                  itemBatchTrack?.qtyToFulfil,
                  selectedObj?.documentUOMSchemaDefinition
                ),
                quantity: Utility.getUomWarehouseQuantityWithoutRoundOff(
                  itemBatchTrack?.quantity,
                  selectedObj?.documentUOMSchemaDefinition
                ),
                advancedTrackingData: itemBatchTrack?.advancedTrackingData?.map(
                  (batchAdvanceTrack: any) => {
                    return {
                      ...batchAdvanceTrack,
                      qtyToFulfil:
                        Utility.getUomWarehouseQuantityWithoutRoundOff(
                          batchAdvanceTrack?.qtyToFulfil,
                          selectedObj?.documentUOMSchemaDefinition
                        ),
                      quantity: Utility.getUomWarehouseQuantityWithoutRoundOff(
                        batchAdvanceTrack?.quantity,
                        selectedObj?.documentUOMSchemaDefinition
                      )
                    };
                  }
                )
              };
            }
          ),
        targetWarehouse: activeWorkOrder?.targetWarehouse
      };

      result = Utility.masterAutoAllocateBatchTracked(
        warehouses,
        selectedObj,
        rawMaterialItem?.unmodifiedSelectedProduct,
        rawMaterialItem?.pid
      );

      result = result?.map((itemData: any) => {
        return {
          ...itemData,
          qtyToFulfil: Utility.getUomQuantity(
            itemData?.qtyToFulfil,
            selectedObj?.documentUOMSchemaDefinition
          ),
          advancedTrackingData: itemData?.advancedTrackingData?.map(
            (itemTrackingData: any) => {
              return {
                ...itemTrackingData,
                qtyToFulfil: Utility.getUomQuantity(
                  itemTrackingData?.qtyToFulfil,
                  selectedObj?.documentUOMSchemaDefinition
                )
              };
            }
          )
        };
      });

      allLoopedAndAllocatedResultQty +=
        result?.reduce((acc: any, item: any) => {
          return acc + (item.qtyToFulfil ?? 0);
        }, 0) ?? 0;

      allLoopedAndAllocatedResultQty = +(
        allLoopedAndAllocatedResultQty -
        allPreviouslyLoopedAndAllocatedResultQty
      );

      if (!Utility.isEmpty(result)) {
        quantitiesAllocated?.push(true);
      } else {
        quantitiesAllocated?.push(false);
      }

      updateTrackingDetails(ADVANCE_TRACKING.BATCH, result, rawMaterialItem);
    }
  }

  if (!Utility.isEmpty(pickedSubstitutes)) {
    //this sorting will be required, for not allocating from the already allocated ones
    let substituteArr = [...pickedSubstitutes];
    substituteArr.sort((itemA, itemB) => {
      if (itemA.warehouseInventoryData && !itemB.warehouseInventoryData) {
        return -1;
      } else if (
        !itemA.warehouseInventoryData &&
        itemB.warehouseInventoryData
      ) {
        return 1;
      } else {
        return 0;
      }
    });
    // substituteArr.forEach((subsRawMaterialItem) => {
    //   const qty =
    //     subsRawMaterialItem?.unmodifiedSelectedProduct?.reduce(
    //       (acc: any, obj: any) => {
    //         return acc + obj?.quantity;
    //       },
    //       0
    //     ) ?? 0;
    //   allLoopedAndAllocatedResultQty += qty;
    // });
    substituteArr.forEach((subsRawMaterialItem) => {
      if (
        subsRawMaterialItem?.isProductPicked &&
        subsRawMaterialItem.advancedTracking === ADVANCE_TRACKING.NONE &&
        (allocationType === BOM_EXPLOSION_ALLOCATION_TYPE.DEFAULT_ALLOCATION ||
          allocationType === BOM_EXPLOSION_ALLOCATION_TYPE.ONLY_SUBSTITUTE)
      ) {
        const existingWHData =
          subsRawMaterialItem.unmodifiedSelectedProduct?.reduce(
            (acc: any, obj: any) => {
              return acc + obj?.quantity;
            },
            0
          ) ?? 0;
        const selectedObj = {
          ...subsRawMaterialItem,
          pendingQuantity:
            rawMaterialItem?.[BOM_EXPLOSION_COLUMN_KEYS.REQUIRED_QUANTITY] !==
            allLoopedAndAllocatedResultQty
              ? rawMaterialItem?.[BOM_EXPLOSION_COLUMN_KEYS.REQUIRED_QUANTITY] -
                allLoopedAndAllocatedResultQty +
                existingWHData
              : 0,
          productCode: subsRawMaterialItem?.productId
        };
        let result: any = [];
        if (!rrbEnabled) {
          result = Utility.masterAutoAllocateNoneTrackedWithoutRRB(
            warehouses,
            selectedObj,
            subsRawMaterialItem?.unmodifiedSelectedProduct ?? []
          );
        } else {
          result = Utility.masterAutoAllocateNoneTrackedWithRRB(
            warehouses,
            selectedObj,
            subsRawMaterialItem?.unmodifiedSelectedProduct ?? []
          );
        }
        allLoopedAndAllocatedResultQty +=
          result?.reduce((acc: any, item: any) => {
            return acc + (item.quantity ?? 0);
          }, 0) ?? 0;

        allLoopedAndAllocatedResultQty = +(
          allLoopedAndAllocatedResultQty -
          allPreviouslyLoopedAndAllocatedResultQty
        );

        if (!Utility.isEmpty(result)) {
          quantitiesAllocated?.push(true);
        } else {
          quantitiesAllocated?.push(false);
        }

        updateTrackingDetails(
          'SUBSTITUTE',
          result,
          rawMaterialItem,
          true,
          subsRawMaterialItem
        );
      }

      if (
        subsRawMaterialItem?.isProductPicked &&
        subsRawMaterialItem.advancedTracking === ADVANCE_TRACKING.SERIAL &&
        (allocationType === BOM_EXPLOSION_ALLOCATION_TYPE.DEFAULT_ALLOCATION ||
          allocationType === BOM_EXPLOSION_ALLOCATION_TYPE.ONLY_SUBSTITUTE)
      ) {
        let result = [];
        const reqQty =
          rawMaterialItem?.[BOM_EXPLOSION_COLUMN_KEYS.REQUIRED_QUANTITY] !==
          allLoopedAndAllocatedResultQty
            ? rawMaterialItem?.[BOM_EXPLOSION_COLUMN_KEYS.REQUIRED_QUANTITY] -
              allLoopedAndAllocatedResultQty
            : 0;
        const selectedObj = prepareBomExplosionProductForAllocation(
          {
            ...subsRawMaterialItem,
            pid: subsRawMaterialItem?.productId,
            [BOM_EXPLOSION_COLUMN_KEYS.REQUIRED_QUANTITY]: reqQty
          },
          ADVANCE_TRACKING.SERIAL
        );

        result = Utility.masterAutoAllocateSerialTracked(
          warehouses,
          selectedObj,
          subsRawMaterialItem?.unmodifiedSelectedProduct ?? [],
          subsRawMaterialItem?.productId
        );

        allLoopedAndAllocatedResultQty +=
          result?.reduce((acc: any, item: any) => {
            return acc + (item.qtyToFulfil ?? 0);
          }, 0) ?? 0;

        allLoopedAndAllocatedResultQty = +(
          allLoopedAndAllocatedResultQty -
          allPreviouslyLoopedAndAllocatedResultQty
        );

        if (!Utility.isEmpty(result)) {
          quantitiesAllocated?.push(true);
        } else {
          quantitiesAllocated?.push(false);
        }

        updateTrackingDetails(
          'SUBSTITUTE',
          result,
          rawMaterialItem,
          true,
          subsRawMaterialItem
        );
      }

      if (
        subsRawMaterialItem?.isProductPicked &&
        subsRawMaterialItem.advancedTracking === ADVANCE_TRACKING.BATCH &&
        (allocationType === BOM_EXPLOSION_ALLOCATION_TYPE.DEFAULT_ALLOCATION ||
          allocationType === BOM_EXPLOSION_ALLOCATION_TYPE.ONLY_SUBSTITUTE)
      ) {
        let result = [];
        const reqQty =
          rawMaterialItem?.[BOM_EXPLOSION_COLUMN_KEYS.REQUIRED_QUANTITY] !==
          allLoopedAndAllocatedResultQty
            ? rawMaterialItem?.[BOM_EXPLOSION_COLUMN_KEYS.REQUIRED_QUANTITY] -
              allLoopedAndAllocatedResultQty
            : 0;
        const selectedObj = prepareBomExplosionProductForAllocation(
          {
            ...subsRawMaterialItem,
            pid: subsRawMaterialItem?.productId,
            [BOM_EXPLOSION_COLUMN_KEYS.REQUIRED_QUANTITY]: reqQty
          },
          ADVANCE_TRACKING.BATCH
        );

        result = Utility.masterAutoAllocateBatchTracked(
          warehouses,
          selectedObj,
          subsRawMaterialItem?.unmodifiedSelectedProduct ?? [],
          subsRawMaterialItem?.productId
        );

        allLoopedAndAllocatedResultQty +=
          result?.reduce((acc: any, item: any) => {
            return acc + (item.qtyToFulfil ?? 0);
          }, 0) ?? 0;

        allLoopedAndAllocatedResultQty = +(
          allLoopedAndAllocatedResultQty -
          allPreviouslyLoopedAndAllocatedResultQty
        );

        if (!Utility.isEmpty(result)) {
          quantitiesAllocated?.push(true);
        } else {
          quantitiesAllocated?.push(false);
        }

        updateTrackingDetails(
          'SUBSTITUTE',
          result,
          rawMaterialItem,
          true,
          subsRawMaterialItem
        );
      }
    });
  }
};

export const getLinkedPOPRCode = (poPRData: any, getOnlyCode: boolean) => {
  let poPRDocs = poPRData?.linkedPOPRData?.filter(
    (ele: any) => ele.documentType !== DOC_TYPE.JOB_WORK_OUT_ORDER
  );
  if (getOnlyCode) {
    return poPRDocs?.[0]?.documentSequenceCode ?? '';
  } else {
    return poPRDocs?.[0];
  }
};
export const getLinkedJWOCode = (poPRData: any, getOnlyCode: boolean) => {
  let jwoDocs = poPRData?.linkedPOPRData?.filter(
    (ele: any) => ele.documentType === DOC_TYPE.JOB_WORK_OUT_ORDER
  );
  if (getOnlyCode) {
    return jwoDocs?.[0]?.documentSequenceCode ?? '';
  } else {
    return jwoDocs?.[0];
  }
};

export enum MORE_CELL_OPTIONS {
  VIEW_LINKED_DOCS = 'PO/PR linked documents',
  VIEW_LINKED_DOCS_WITH_SR = 'PO/PR/SR linked documents',
  CREATE_JWO = 'Create JWO',
  CREATE_WO = 'Create WO',
  SELECT_SUBSTITUTE = 'Select Substitute',
  ALLOCATE_SUBSTITUTE = 'Allocate Substitutes',
  ALLOCATE_MATERIAL = 'Allocate Material',
  ALLOCATE_SERIAL = 'Allocate Serial',
  ALLOCATE_BATCH = 'Allocate Batch',
  AUTO_ALLOCATE = 'Auto Allocate',
  STOCK_TRANSFER = 'Stock Transfer',
  REMOVE_PR = 'Remove PR',
  REMOVE_PO = 'Remove PO',
  ADD_TO_PR = 'Add to PR',
  ADD_TO_PO = 'Add to PO',
  ADD_MATERIAL = 'Add Component',
  ADD_SUBSTITUTE = 'Add Substitute',
  DELETE_MATERIAL = 'Delete',
  VIEW_ADVANCED_TRACKED_INFO = 'View Allocated Data'
}

export const BOM_EXPLOSION_CONTEXT_MENU_OPTIONS = [
  {
    title: MORE_CELL_OPTIONS.ADD_MATERIAL,
    icon: DKIcons.ic_add,
    key: MORE_CELL_OPTIONS.ADD_MATERIAL
  },
  {
    title: MORE_CELL_OPTIONS.VIEW_LINKED_DOCS,
    icon: DKIcons.ic_view,
    key: MORE_CELL_OPTIONS.VIEW_LINKED_DOCS
  },
  {
    title: MORE_CELL_OPTIONS.VIEW_ADVANCED_TRACKED_INFO,
    icon: DKIcons.ic_view,
    key: MORE_CELL_OPTIONS.VIEW_ADVANCED_TRACKED_INFO
  },
  {
    title: MORE_CELL_OPTIONS.VIEW_LINKED_DOCS_WITH_SR,
    icon: DKIcons.ic_view,
    key: MORE_CELL_OPTIONS.VIEW_LINKED_DOCS_WITH_SR
  },
  {
    title: MORE_CELL_OPTIONS.CREATE_JWO,
    icon: DKIcons.ic_document,
    key: MORE_CELL_OPTIONS.CREATE_JWO
  },
  {
    title: MORE_CELL_OPTIONS.CREATE_WO,
    icon: DKIcons.ic_document,
    key: MORE_CELL_OPTIONS.CREATE_WO
  },
  {
    title: MORE_CELL_OPTIONS.SELECT_SUBSTITUTE,
    icon: DKIcons.ic_product,
    key: MORE_CELL_OPTIONS.SELECT_SUBSTITUTE
  },
  {
    title: MORE_CELL_OPTIONS.ALLOCATE_SUBSTITUTE,
    icon: ic_barcode_black,
    key: MORE_CELL_OPTIONS.ALLOCATE_SUBSTITUTE
  },
  {
    title: MORE_CELL_OPTIONS.ALLOCATE_MATERIAL,
    icon: ic_barcode_black,
    key: MORE_CELL_OPTIONS.ALLOCATE_MATERIAL
  },
  {
    title: MORE_CELL_OPTIONS.ALLOCATE_SERIAL,
    icon: ic_barcode_black,
    key: MORE_CELL_OPTIONS.ALLOCATE_SERIAL
  },
  {
    title: MORE_CELL_OPTIONS.ALLOCATE_BATCH,
    icon: ic_barcode_black,
    key: MORE_CELL_OPTIONS.ALLOCATE_BATCH
  },
  {
    title: MORE_CELL_OPTIONS.AUTO_ALLOCATE,
    icon: ic_barcode_black,
    key: MORE_CELL_OPTIONS.AUTO_ALLOCATE
  },
  {
    title: MORE_CELL_OPTIONS.STOCK_TRANSFER,
    icon: ic_delivery,
    key: MORE_CELL_OPTIONS.STOCK_TRANSFER
  },
  {
    title: MORE_CELL_OPTIONS.REMOVE_PR,
    icon: DKIcons.ic_delete,
    key: MORE_CELL_OPTIONS.REMOVE_PR
  },
  {
    title: MORE_CELL_OPTIONS.REMOVE_PO,
    icon: DKIcons.ic_delete,
    key: MORE_CELL_OPTIONS.REMOVE_PO
  },
  {
    title: MORE_CELL_OPTIONS.ADD_TO_PR,
    icon: DKIcons.ic_add,
    key: MORE_CELL_OPTIONS.ADD_TO_PR
  },
  {
    title: MORE_CELL_OPTIONS.ADD_TO_PO,
    icon: DKIcons.ic_add,
    key: MORE_CELL_OPTIONS.ADD_TO_PO
  },
  {
    title: MORE_CELL_OPTIONS.DELETE_MATERIAL,
    icon: DKIcons.ic_delete,
    key: MORE_CELL_OPTIONS.DELETE_MATERIAL
  }
];

export function assignBomExplosionRowContextMenu(
  productRow: any,
  handleContextMenuItemClick: (rowData: any, action: MORE_CELL_OPTIONS) => void,
  metaData: {
    woInsufficientSetting: WORK_ORDER_PR_PO;
    jwoList: any[];
    isReadOnly: boolean;
    isAdhocEnabled: boolean;
    hasProductionEntry?: boolean;
    componentWithSubstituteOnly?: boolean;
  }
) {
  if (!productRow || metaData.isReadOnly) return productRow;

  const contextMenuOptionsToAssign: {
    title: string;
    icon: string;
    key: MORE_CELL_OPTIONS;
    onClick: () => void;
  }[] = [];

  const isSubstitute = productRow[BOM_EXPLOSION_COLUMN_KEYS.SUBSTITUTE];
  const isWarehouseTaggingEnabled =
    Utility.isBinAllocationMandatory() || Utility.isWarehouseTaggingEnabled();
  const isBomType =
    (isSubstitute ? productRow.type : productRow.productType) ===
    PRODUCT_TYPE.BILL_OF_MATERIALS;
  const taggedWHDetails =
    productRow[BOM_EXPLOSION_COLUMN_KEYS.TAGGED_WH_DETAIL];
  const isScrapOrCoProduct = [
    PRODUCE_PRODUCT_TYPE.SCRAP,
    PRODUCE_PRODUCT_TYPE.CO_PRODUCT
  ].includes(
    (isSubstitute ? productRow.parentProductRowData : productRow)
      ?.productProductType
  );

  const isServiceProduct = productRow.productType === PRODUCT_TYPE.NON_TRACKED;

  let showAllocateBtnWithWHTagging = false;
  if (isWarehouseTaggingEnabled) {
    const totalAlloted = taggedWHDetails?.totalAlloted ?? 0;
    const totalAvailableStockInTaggedWH =
      taggedWHDetails?.totalAvailableStockInTaggedWH ?? 0;
    showAllocateBtnWithWHTagging =
      totalAlloted > 0 ||
      (totalAlloted === 0 && totalAvailableStockInTaggedWH > 0);
  }

  const isWorkOrderMainBomProduct = productRow.level === 0;
  const isWorkOrderComponentProduct = productRow.level === 1;

  BOM_EXPLOSION_CONTEXT_MENU_OPTIONS.forEach((option) => {
    let allowOption = false;
    if (
      isServiceProduct &&
      option.title !== MORE_CELL_OPTIONS.DELETE_MATERIAL
    ) {
      return;
    }

    if (
      metaData.componentWithSubstituteOnly &&
      option.title !== MORE_CELL_OPTIONS.ALLOCATE_SUBSTITUTE
    ) {
      return;
    }

    switch (option.title) {
      case MORE_CELL_OPTIONS.VIEW_LINKED_DOCS:
        allowOption =
          !isWarehouseTaggingEnabled &&
          Utility.isNotEmpty(
            productRow[BOM_EXPLOSION_COLUMN_KEYS.LINKED_DOC_CODE]
          );
        break;
      case MORE_CELL_OPTIONS.VIEW_LINKED_DOCS_WITH_SR:
        allowOption =
          isWarehouseTaggingEnabled &&
          Utility.isNotEmpty(
            productRow[BOM_EXPLOSION_COLUMN_KEYS.LINKED_DOC_CODE]
          );
        break;
      case MORE_CELL_OPTIONS.CREATE_JWO:
        allowOption =
          isBomType &&
          !isWorkOrderMainBomProduct &&
          checkUserPermission(PERMISSIONS_BY_MODULE.JOB_WORKOUTS.CREATE);
        break;
      case MORE_CELL_OPTIONS.CREATE_WO:
        allowOption =
          isBomType &&
          !isWorkOrderMainBomProduct &&
          checkUserPermission(PERMISSIONS_BY_MODULE.WORK_ORDER.CREATE) &&
          Utility.isEmpty(productRow[BOM_EXPLOSION_COLUMN_KEYS.LINKED_WO_CODE]);
        break;
      case MORE_CELL_OPTIONS.SELECT_SUBSTITUTE:
        allowOption =
          !isSubstitute &&
          metaData.isAdhocEnabled &&
          !isWorkOrderMainBomProduct &&
          !productRow.isParentASubstitute;
        break;
      case MORE_CELL_OPTIONS.ALLOCATE_SUBSTITUTE:
        allowOption =
          ((Utility.isNotEmpty(
            productRow[BOM_EXPLOSION_COLUMN_KEYS.AVAILABLE_SUBSTITUTES]
          ) &&
            !isWorkOrderMainBomProduct) ||
            (isSubstitute && !isScrapOrCoProduct)) &&
          !productRow.isParentASubstitute;
        break;
      case MORE_CELL_OPTIONS.ALLOCATE_MATERIAL:
        allowOption =
          !isSubstitute &&
          !isScrapOrCoProduct &&
          isWorkOrderComponentProduct &&
          productRow.advancedTracking === ADVANCE_TRACKING.NONE &&
          (isWarehouseTaggingEnabled
            ? showAllocateBtnWithWHTagging
            : productRow.availability !== AVAILABILITY_STATUS.NOT_AVAILABLE);
        break;
      case MORE_CELL_OPTIONS.ALLOCATE_SERIAL:
        allowOption =
          !isSubstitute &&
          !isScrapOrCoProduct &&
          isWorkOrderComponentProduct &&
          productRow.advancedTracking === ADVANCE_TRACKING.SERIAL &&
          (isWarehouseTaggingEnabled
            ? showAllocateBtnWithWHTagging
            : productRow.availability !== AVAILABILITY_STATUS.NOT_AVAILABLE);
        break;
      case MORE_CELL_OPTIONS.ALLOCATE_BATCH:
        allowOption =
          !isSubstitute &&
          !isScrapOrCoProduct &&
          isWorkOrderComponentProduct &&
          productRow.advancedTracking === ADVANCE_TRACKING.BATCH &&
          (isWarehouseTaggingEnabled
            ? showAllocateBtnWithWHTagging
            : productRow.availability !== AVAILABILITY_STATUS.NOT_AVAILABLE);
        break;
      case MORE_CELL_OPTIONS.STOCK_TRANSFER:
        allowOption =
          !isWarehouseTaggingEnabled &&
          !isWorkOrderMainBomProduct &&
          checkUserPermission(PERMISSIONS_BY_MODULE.STOCK_TRANSFER.CREATE) &&
          productRow[BOM_EXPLOSION_COLUMN_KEYS.AVAILABILITY] !==
            AVAILABILITY_STATUS.AVAILABLE;
        break;
      case MORE_CELL_OPTIONS.REMOVE_PR:
        allowOption =
          isWorkOrderComponentProduct &&
          productRow[BOM_EXPLOSION_COLUMN_KEYS.PRODUCT_SELECTION] &&
          metaData.woInsufficientSetting ===
            WORK_ORDER_PR_PO.PURCHASE_REQUISITION &&
          productRow[BOM_EXPLOSION_COLUMN_KEYS.AVAILABILITY] !==
            AVAILABILITY_STATUS.AVAILABLE;
        break;
      case MORE_CELL_OPTIONS.REMOVE_PO:
        allowOption =
          isWorkOrderComponentProduct &&
          productRow[BOM_EXPLOSION_COLUMN_KEYS.PRODUCT_SELECTION] &&
          metaData.woInsufficientSetting === WORK_ORDER_PR_PO.PURCHASE_ORDER &&
          productRow[BOM_EXPLOSION_COLUMN_KEYS.AVAILABILITY] !==
            AVAILABILITY_STATUS.AVAILABLE;
        break;
      case MORE_CELL_OPTIONS.ADD_TO_PR:
        allowOption =
          !isSubstitute &&
          isWorkOrderComponentProduct &&
          !productRow[BOM_EXPLOSION_COLUMN_KEYS.PRODUCT_SELECTION] &&
          metaData.woInsufficientSetting ===
            WORK_ORDER_PR_PO.PURCHASE_REQUISITION &&
          productRow[BOM_EXPLOSION_COLUMN_KEYS.AVAILABILITY] !==
            AVAILABILITY_STATUS.AVAILABLE;
        break;
      case MORE_CELL_OPTIONS.ADD_TO_PO:
        allowOption =
          !isSubstitute &&
          isWorkOrderComponentProduct &&
          !productRow[BOM_EXPLOSION_COLUMN_KEYS.PRODUCT_SELECTION] &&
          metaData.woInsufficientSetting === WORK_ORDER_PR_PO.PURCHASE_ORDER &&
          productRow[BOM_EXPLOSION_COLUMN_KEYS.AVAILABILITY] !==
            AVAILABILITY_STATUS.AVAILABLE;
        break;
      case MORE_CELL_OPTIONS.ADD_MATERIAL:
        allowOption =
          metaData.isAdhocEnabled &&
          isWorkOrderMainBomProduct &&
          !metaData.hasProductionEntry;
        break;
      case MORE_CELL_OPTIONS.DELETE_MATERIAL:
        allowOption =
          metaData.isAdhocEnabled &&
          productRow.isBomRowEditModeEnabled &&
          (isWorkOrderComponentProduct || isSubstitute);
        break;
      case MORE_CELL_OPTIONS.ADD_SUBSTITUTE:
        allowOption = metaData.isAdhocEnabled && isWorkOrderComponentProduct;
        break;
      case MORE_CELL_OPTIONS.VIEW_ADVANCED_TRACKED_INFO:
        allowOption =
          productRow.advancedTracking === ADVANCE_TRACKING.BATCH ||
          productRow.advancedTracking === ADVANCE_TRACKING.SERIAL ||
          productRow.advancedTracking === ADVANCE_TRACKING.NONE;
        break;
      default:
    }

    if (allowOption) {
      contextMenuOptionsToAssign.push({
        ...option,
        onClick: () => handleContextMenuItemClick(productRow, option.key)
      });
    }
  });

  productRow.rowContextMenu = Utility.isEmpty(contextMenuOptionsToAssign)
    ? [
        {
          title: 'No option available',
          className: 'text-gray text-align-center',
          onClick: () => {}
        }
      ]
    : contextMenuOptionsToAssign;

  return productRow;
}

/**
 * NEW BOM EXPLOSION UTILS
 */
export function parseBomExplosionResponseToTree({
  data,
  substituteBomExplosionData = [],
  showSubstituteOnly = true,
  majorIndex = 0,
  activeWorkOrder,
  isEditMode
}: {
  data: any;
  substituteBomExplosionData?: any[];
  majorIndex?: number;
  activeWorkOrder?: IWorkOrder;
  isEditMode?: boolean;
  showSubstituteOnly?: boolean;
}) {
  const plannedQuantity = Number(activeWorkOrder?.manufactureQuantity || 1);

  if (Utility.isNotEmpty(data)) {
    data = {
      ...data,
      expanded: data.expanded ?? majorIndex === 0,
      uom: getSelectedUOMValue(data.stockUom ?? data.uom),
      stockUom: data.stockUom ?? data.uom,
      uniqueId: data?.uniqueId ?? Utility.randstr('bom_explosion_div_'),
      level: majorIndex
    };

    if (majorIndex === 0) {
      data[BOM_EXPLOSION_COLUMN_KEYS.REQUIRED_QUANTITY] = plannedQuantity;
    }

    data.bomProductConfiguration = (data.bomProductConfiguration || [])
      .map((componentProduct: any) => {
        const childWO = activeWorkOrder?.workOrderChildDetails?.find(
          (childWoDetail: any) => {
            return childWoDetail.productCode === componentProduct?.pid;
          }
        );
        let childWOList = Utility.isEmpty(childWO) ? [] : [childWO];
        const linkedDocs =
          activeWorkOrder?.linkedDocuments?.filter((poPrDocument: any) => {
            return poPrDocument?.productCodes?.includes(componentProduct.pid);
          }) ?? [];

        const woItemDataForCurrentComponent =
          majorIndex === 0
            ? (activeWorkOrder?.workOrderItems || []).find(
                (rawMaterialItem) =>
                  rawMaterialItem?.itemName?.pid === componentProduct.pid ||
                  rawMaterialItem?.itemName?.productId === componentProduct.pid
              )
            : null;

        /** i.e. Will be required when component product gets removed from RawMaterialList component in AddWO screen */
        if (
          majorIndex === 0 &&
          !componentProduct.isNewRow &&
          Utility.isEmpty(woItemDataForCurrentComponent)
        ) {
          return null;
        }

        const pristineWorkOrderItemForCurrentComponent = (
          activeWorkOrder?.workOrderItemsClonedCopy || []
        ).find(
          (rawMaterialItem: any) =>
            rawMaterialItem?.itemName?.pid === componentProduct.pid
        );

        const isInitialParse = Utility.isEmpty(componentProduct.uniqueId);

        let availableSubstitutes =
          componentProduct[BOM_EXPLOSION_COLUMN_KEYS.AVAILABLE_SUBSTITUTES] ||
          woItemDataForCurrentComponent?.[
            BOM_EXPLOSION_COLUMN_KEYS.AVAILABLE_SUBSTITUTES
          ] ||
          componentProduct[BOM_EXPLOSION_COLUMN_KEYS.ASSIGNED_SUBSTITUTES] ||
          [];
        let currentComponentStoredShortProductDetails = null;

        if (majorIndex > 0) {
          currentComponentStoredShortProductDetails = data[
            REQUIRED_ITEM_TABLE.INNER_BOM_PRODUCT_DETAILS
          ]?.find(
            (innerComponentDetail: any) =>
              innerComponentDetail.productId ===
              (componentProduct.pid ?? componentProduct.productId)
          );
          availableSubstitutes = [...(availableSubstitutes || [])];
          currentComponentStoredShortProductDetails?.[
            REQUIRED_ITEM_TABLE.BOM_PRODUCT_SUBSTITUTE_DETAILS
          ]?.forEach((cachedSubstitute: any) => {
            const existingAvailableSubstitute = availableSubstitutes.find(
              (existingSubstitute: any) =>
                existingSubstitute.productId === cachedSubstitute.productId
            );
            if (existingAvailableSubstitute) {
              existingAvailableSubstitute.isProductPicked = true;
            } else {
              availableSubstitutes.push(cachedSubstitute);
            }
          });
        } else if (woItemDataForCurrentComponent) {
          woItemDataForCurrentComponent[
            BOM_EXPLOSION_COLUMN_KEYS.AVAILABLE_SUBSTITUTES
          ]?.forEach((cachedSubstitute: any) => {
            let existingAvailableSubstitute = availableSubstitutes.find(
              (existingSubstitute: any) =>
                existingSubstitute.productId === cachedSubstitute.productId
            );
            if (existingAvailableSubstitute) {
              existingAvailableSubstitute = {
                ...existingAvailableSubstitute,
                ...cachedSubstitute,
                isProductPicked: cachedSubstitute?.isProductPicked ?? false
              };
            } else {
              availableSubstitutes.push(cachedSubstitute);
            }
          });
        }

        if (
          woItemDataForCurrentComponent &&
          !Utility.isEmpty(woItemDataForCurrentComponent)
        ) {
          componentProduct = {
            ...componentProduct,
            warehouseInventoryData:
              woItemDataForCurrentComponent.warehouseInventoryData,
            [BOM_EXPLOSION_COLUMN_KEYS.REQUIRED_QUANTITY]:
              (isInitialParse
                ? woItemDataForCurrentComponent[
                    REQUIRED_ITEM_TABLE.REQUIRED_QTY
                  ]
                : componentProduct[
                    BOM_EXPLOSION_COLUMN_KEYS.REQUIRED_QUANTITY
                  ]) ??
              calculateRequiredQtyForBomComponentProduct(
                plannedQuantity,
                componentProduct
              ),
            [BOM_EXPLOSION_COLUMN_KEYS.CHARGES]:
              woItemDataForCurrentComponent.costPerUnit || 0,
            /** @deprecated need to rename this field to more appropriate name */
            unmodifiedSelectedProduct: isEditMode
              ? pristineWorkOrderItemForCurrentComponent?.warehouseInventoryData ??
                []
              : [],
            /** @description: This provides list of substitutes assignable to current component product  */
            [BOM_EXPLOSION_COLUMN_KEYS.AVAILABLE_SUBSTITUTES]:
              availableSubstitutes.map((substitute: any) => {
                const substituteFromPristineItem = (
                  pristineWorkOrderItemForCurrentComponent?.[
                    BOM_EXPLOSION_COLUMN_KEYS.AVAILABLE_SUBSTITUTES
                  ] || []
                ).find(
                  (pristineSubstitute: any) =>
                    pristineSubstitute?.productId === substitute.productId
                );

                substitute = {
                  ...substitute,
                  unmodifiedSelectedProduct:
                    substituteFromPristineItem?.warehouseInventoryData ?? []
                };
                return substitute;
              }),
            /** @description: This provides list of allocated/picked substitutes for current component product  */
            [BOM_EXPLOSION_COLUMN_KEYS.ASSIGNED_SUBSTITUTES]:
              availableSubstitutes.filter(
                (substitute: any) => substitute?.isProductPicked
              ),
            workOrderChildDetails: childWOList,
            addToRequisition:
              componentProduct.addToRequisition ??
              woItemDataForCurrentComponent.addToRequisition ??
              false,
            [BOM_EXPLOSION_COLUMN_KEYS.PRODUCT_SELECTION]:
              (componentProduct.addToRequisition ??
                woItemDataForCurrentComponent.addToRequisition) &&
              majorIndex === 1,
            linkedPOPRData: linkedDocs,
            bomMetaCode: woItemDataForCurrentComponent.bomMetaCode,
            innerBomProductDetails:
              woItemDataForCurrentComponent[
                REQUIRED_ITEM_TABLE.INNER_BOM_PRODUCT_DETAILS
              ]
          };
        } else {
          /**
           * @description
           * This case will be required for mainProduct > wip/fg > componentProducts, i.e. for products on level 2 and beyond
           */
          componentProduct = {
            ...componentProduct,
            [BOM_EXPLOSION_COLUMN_KEYS.REQUIRED_QUANTITY]:
              (majorIndex === 0
                ? componentProduct[BOM_EXPLOSION_COLUMN_KEYS.REQUIRED_QUANTITY]
                : null) ??
              calculateRequiredQtyForBomComponentProduct(
                data[BOM_EXPLOSION_COLUMN_KEYS.REQUIRED_QUANTITY],
                componentProduct
              ),
            [BOM_EXPLOSION_COLUMN_KEYS.CHARGES]: 0,
            [BOM_EXPLOSION_COLUMN_KEYS.AVAILABLE_SUBSTITUTES]:
              availableSubstitutes,
            [BOM_EXPLOSION_COLUMN_KEYS.ASSIGNED_SUBSTITUTES]:
              availableSubstitutes.filter(
                (substitute: any) => substitute?.isProductPicked
              ),
            workOrderChildDetails: childWOList,
            innerBomProductDetails:
              currentComponentStoredShortProductDetails?.innerBomProductDetails
          };
        }

        return parseBomExplosionResponseToTree({
          data: componentProduct,
          substituteBomExplosionData,
          majorIndex: majorIndex + 1,
          activeWorkOrder,
          isEditMode,
          showSubstituteOnly
        });
      })
      .filter((componentProduct: any) => {
        return (
          componentProduct &&
          ![
            PRODUCE_PRODUCT_TYPE.SCRAP,
            PRODUCE_PRODUCT_TYPE.CO_PRODUCT
          ].includes(componentProduct.produceProductType)
        );
      });

    data[BOM_EXPLOSION_COLUMN_KEYS.AVAILABLE_SUBSTITUTES] =
      mergeAvailableAndPickedSubstituteDetailsFromExplosionData(data).map(
        (substitute: any) => {
          return {
            ...substitute,
            [BOM_EXPLOSION_COLUMN_KEYS.SUBSTITUTE]: true,
            [BOM_EXPLOSION_COLUMN_KEYS.REQUIRED_UNIT_QUANTITY]:
              data[BOM_EXPLOSION_COLUMN_KEYS.REQUIRED_UNIT_QUANTITY],
            [BOM_EXPLOSION_COLUMN_KEYS.REQUIRED_QUANTITY]:
              data[BOM_EXPLOSION_COLUMN_KEYS.REQUIRED_QUANTITY],
            pid: substitute.productId,
            uniqueId:
              substitute?.uniqueId ?? Utility.randstr('bom_explosion_div_'),
            level: majorIndex + 1,
            uom: getSelectedUOMValue(substitute.stockUom ?? substitute.uom),
            stockUom: substitute.stockUom ?? substitute.uom,
            workOrderChildDetails: getChildWorkOrdersForComponentOrSubstitute(
              substitute,
              activeWorkOrder
            )
          };
        }
      );
    data[BOM_EXPLOSION_COLUMN_KEYS.ASSIGNED_SUBSTITUTES] = data[
      BOM_EXPLOSION_COLUMN_KEYS.AVAILABLE_SUBSTITUTES
    ]
      .filter((substitutes: any) => substitutes.isProductPicked)
      .map((assignedSubstitute: any) => {
        const substituteComponent = substituteBomExplosionData?.find(
          (substituteData) =>
            substituteData.pid ===
            (assignedSubstitute.pid ?? assignedSubstitute.productId)
        );
        if (substituteComponent) {
          assignedSubstitute = {
            ...assignedSubstitute,
            [BOM_EXPLOSION_COLUMN_KEYS.COMPONENT_PRODUCTS]:
              substituteComponent[BOM_EXPLOSION_COLUMN_KEYS.COMPONENT_PRODUCTS]
          };

          return parseBomExplosionResponseToTree({
            data: assignedSubstitute,
            substituteBomExplosionData,
            majorIndex: majorIndex + 1,
            activeWorkOrder,
            isEditMode,
            showSubstituteOnly
          });
        }

        return assignedSubstitute;
      });
  }

  return data;
}

function getChildWorkOrdersForComponentOrSubstitute(
  componentProduct: any,
  activeWorkOrder?: IWorkOrder
) {
  const childWO = activeWorkOrder?.workOrderChildDetails?.find((woChild) => {
    return woChild.productCode === componentProduct?.pid;
  });

  const childWOList = !Utility.isEmpty(childWO) ? [childWO] : [];
  return childWOList;
}

export function getTaggedWHProductAvailabilityDetails(
  rowData: any,
  workOrder: IWorkOrder,
  warehouseProductsInfo: any[]
) {
  let taggedWHInventoryDetails = {};
  if (
    Utility.isBinAllocationMandatory() ||
    Utility.isWarehouseTaggingEnabled()
  ) {
    let materialDetails = rowData.isExplodeSubstitute
      ? rowData
      : workOrder?.workOrderItems?.find(
          (item) => item.itemName?.pid === rowData.pid
        );
    /**
     * TODO: check if we need check for heirary here
     */
    if (Utility.isEmpty(materialDetails)) {
      materialDetails = {
        requiredQty: rowData[BOM_EXPLOSION_COLUMN_KEYS.REQUIRED_QUANTITY],
        pid: rowData.pid
      };
    }
    taggedWHInventoryDetails = evaluateAllInventoryInTaggedWarehouse(
      workOrder?.targetWarehouse,
      materialDetails,
      warehouseProductsInfo,
      rowData.isExplodeSubstitute
    );
  }

  return taggedWHInventoryDetails;
}

export function updateBomExplosionDataByWorkOrderData(
  data: any,
  activeWorkOrder: IWorkOrder,
  warehouseProductsInfo: any[],
  majorIndex = 1
) {
  if (Utility.isEmpty(data)) {
    return null;
  } else if (
    !Utility.isEmpty(data?.bomProductConfiguration) ||
    !Utility.isEmpty(data[BOM_EXPLOSION_COLUMN_KEYS.ASSIGNED_SUBSTITUTES])
  ) {
    data[BOM_EXPLOSION_COLUMN_KEYS.TAGGED_WH_DETAIL] =
      getTaggedWHProductAvailabilityDetails(
        data,
        activeWorkOrder,
        warehouseProductsInfo
      );

    data.bomProductConfiguration = data.bomProductConfiguration
      .map((componentProduct: any) => {
        const linkedDocs =
          activeWorkOrder?.linkedDocuments?.filter((poPrDocument: any) => {
            return poPrDocument?.productCodes?.includes(componentProduct.pid);
          }) ?? [];

        componentProduct = {
          ...componentProduct,
          [BOM_EXPLOSION_COLUMN_KEYS.TAGGED_WH_DETAIL]:
            getTaggedWHProductAvailabilityDetails(
              componentProduct,
              activeWorkOrder,
              warehouseProductsInfo
            ),
          linkedPOPRData: Utility.isNotEmpty(linkedDocs)
            ? linkedDocs
            : componentProduct.linkedPOPRData,
          workOrderChildDetails: getChildWorkOrdersForComponentOrSubstitute(
            componentProduct,
            activeWorkOrder
          )
        };

        return updateBomExplosionDataByWorkOrderData(
          componentProduct,
          activeWorkOrder,
          warehouseProductsInfo,
          majorIndex + 1
        );
      })
      .filter((componentProduct: any) => {
        return (
          componentProduct.produceProductType === PRODUCE_PRODUCT_TYPE.NONE
        );
      });

    data[BOM_EXPLOSION_COLUMN_KEYS.ASSIGNED_SUBSTITUTES] = (
      data[BOM_EXPLOSION_COLUMN_KEYS.ASSIGNED_SUBSTITUTES] || []
    ).map((substitute: any) => {
      const linkedDocs =
        activeWorkOrder?.linkedDocuments?.filter((poPrDocument: any) => {
          return poPrDocument?.productCodes?.includes(substitute.productCode);
        }) ?? [];

      return {
        ...substitute,
        [BOM_EXPLOSION_COLUMN_KEYS.TAGGED_WH_DETAIL]:
          getTaggedWHProductAvailabilityDetails(
            substitute,
            activeWorkOrder,
            warehouseProductsInfo
          ),
        linkedPOPRData: Utility.isNotEmpty(linkedDocs)
          ? linkedDocs
          : substitute.linkedPOPRData,
        workOrderChildDetails: getChildWorkOrdersForComponentOrSubstitute(
          substitute,
          activeWorkOrder
        )
      };
    });
    if (
      !Utility.isEmpty(data[BOM_EXPLOSION_COLUMN_KEYS.ASSIGNED_SUBSTITUTES])
    ) {
      data.expanded = true;
      return data;
    }
    return data;
  } else {
    data.expanded = true;
    return data;
  }
}

export function getNestedProductCodes(bomExplosionDetails: any) {
  let productCodes: string[] = [];
  function traverseConfig(config: any) {
    if (!Utility.isEmpty(config)) {
      productCodes.push(config.pid ?? config.productId);
      if (!Utility.isEmpty(config?.bomProductConfiguration)) {
        config?.bomProductConfiguration?.forEach((config: any) => {
          traverseConfig(config);
        });
      }
      if (!Utility.isEmpty(config?.bomProductConfiguration)) {
        config?.bomProductConfiguration?.forEach((config: any) => {
          if (
            !Utility.isEmpty(
              config?.[BOM_EXPLOSION_COLUMN_KEYS.AVAILABLE_SUBSTITUTES]
            )
          ) {
            config?.[BOM_EXPLOSION_COLUMN_KEYS.AVAILABLE_SUBSTITUTES]?.forEach(
              traverseConfig
            );
          }
        });
      }
      if (
        !Utility.isEmpty(
          config?.[BOM_EXPLOSION_COLUMN_KEYS.AVAILABLE_SUBSTITUTES]
        )
      ) {
        config?.[BOM_EXPLOSION_COLUMN_KEYS.AVAILABLE_SUBSTITUTES]?.forEach(
          traverseConfig
        );
      }
      if (
        !Utility.isEmpty(
          config?.[BOM_EXPLOSION_COLUMN_KEYS.ASSIGNED_SUBSTITUTES]
        )
      ) {
        config?.[BOM_EXPLOSION_COLUMN_KEYS.ASSIGNED_SUBSTITUTES]?.forEach(
          traverseConfig
        );
      }
    }
  }

  traverseConfig(bomExplosionDetails);
  const allUniqueProductCodes = Array.from(
    new Set(productCodes.map((code) => code))
  );

  return allUniqueProductCodes;
}

export function isAnySubstitutePicked(data: any) {
  let result = false;
  data?.bomProductConfiguration?.forEach((productItem: any) => {
    productItem?.[BOM_EXPLOSION_COLUMN_KEYS.ASSIGNED_SUBSTITUTES]?.forEach(
      (substituteOption: any) => {
        if (substituteOption?.isProductPicked) {
          result = true;
        }
      }
    );
  });
  return result;
}

function productSatisfiesShortFallCondition(componentProduct: any) {
  return [
    AVAILABILITY_STATUS.SHORTFALL,
    AVAILABILITY_STATUS.NOT_AVAILABLE
  ].includes(availabilityStatus(componentProduct));
}

export function getUniqueIdForBomProductData(bomProduct: any, key?: string) {
  return (
    bomProduct?.uniqueId ||
    bomProduct?.id ||
    (bomProduct?.isExplodeSubstitute
      ? `substitute-${key}`
      : `component-product-${key}`)
  );
}

export function findIfAnyProductShortFallInBomExplosion(bomExplosionData: any) {
  if (Utility.isEmpty(bomExplosionData?.bomProductConfiguration)) return false;

  let flag = false;

  bomExplosionData.bomProductConfiguration.some((componentProduct: any) => {
    /** Either current component is shortfall or it's inner dependency can be having shortfall */
    flag =
      productSatisfiesShortFallCondition(componentProduct) ||
      Boolean(
        componentProduct.expanded &&
          findIfAnyProductShortFallInBomExplosion(componentProduct)
      );
    return flag;
  });

  return flag;
}

function getProductTypeLabel(bomData: any) {
  const TYPE = bomData?.productType || bomData?.type;
  if (TYPE === PRODUCT_TYPE.NON_TRACKED) {
    return 'nt';
  } else if (TYPE === PRODUCT_TYPE.BILL_OF_MATERIALS) {
    return bomData?.isFinishedProduct ? 'fg' : 'wip';
  } else {
    return 'rm';
  }
}

function getReceivedByDateValueForBomProduct(bomProductData: any) {
  if (!Utility.isEmpty(bomProductData?.linkedPOPRData)) {
    return (
      DateFormatService.getFormattedDateString(
        bomProductData?.linkedPOPRData?.[0]?.documentReceiveByDate,
        BOOKS_DATE_FORMAT['DD-MM-YYYY']
      ) || '-'
    );
  } else {
    return '-';
  }
}

export function fetchBomExplosionDetailsWithProductCodeAndMetaCode(
  bomProductCode: any,
  bomMetaCode: any
) {
  if (!bomProductCode || !bomMetaCode) return;
  showLoader();
  return ProductService.fetchBOMExplosionDetails(bomProductCode, bomMetaCode)
    .then((explosionDetails) => {
      removeLoader();
      return Promise.resolve(explosionDetails);
    })
    .catch((err) => {
      removeLoader();
      return {};
    });
}

function parseProductRowObjectForBomExplosion(
  productData: any,
  productIndex: number = 0,
  heirarchyLevel: number = 0,
  parentProductRowData: any = null,
  props: {
    isReadOnlyMode: boolean;
    checkIfProductConsumed: (productCode: string) => boolean;
  }
) {
  const parentHasAvailableSubstitute =
    parentProductRowData &&
    !parentProductRowData.hideOnEnableAvailableSubstituteOnlySetting;
  const hasAvailableSubstitutes = Utility.isNotEmpty(
    productData?.[BOM_EXPLOSION_COLUMN_KEYS.AVAILABLE_SUBSTITUTES]
  );

  const parentHasAssignedSubstitute = Boolean(
    parentProductRowData?.[
      BOM_EXPLOSION_COLUMN_KEYS.ASSIGNED_SUBSTITUTES
    ]?.some((substitute: any) => substitute.isProductPicked)
  );
  const hasAssignedSubstitutes = Boolean(
    productData?.[BOM_EXPLOSION_COLUMN_KEYS.ASSIGNED_SUBSTITUTES]?.some(
      (substitute: any) => substitute.isProductPicked
    )
  );

  const currentBowProductRowId = getUniqueIdForBomProductData(
    productData,
    `${heirarchyLevel}-${productIndex}`
  );
  const productType = productData.productType || productData.type;

  const allotedQty = getTotalAllotedQtyWithoutSubstitute(productData);
  const childWoCode = getWOCodeAttached(
    productData,
    productData.isExplodeSubstitute ? parentProductRowData : null
  );

  const requiredQty = Number(
    productData[BOM_EXPLOSION_COLUMN_KEYS.REQUIRED_QUANTITY] ?? 0
  );
  const invalidFields: string[] = [];

  if (
    Utility.isEmptyObject(productData[BOM_EXPLOSION_COLUMN_KEYS.PRODUCT_NAME])
  ) {
    invalidFields.push(BOM_EXPLOSION_COLUMN_KEYS.PRODUCT_NAME);
  } else if (!requiredQty) {
    invalidFields.push(BOM_EXPLOSION_COLUMN_KEYS.REQUIRED_QUANTITY);
  }

  const substituteDocSequenceCode =
    productData.productDocumentSeqCode ?? productData.documentSequenceCode;

  const isSubstitute = productData[BOM_EXPLOSION_COLUMN_KEYS.SUBSTITUTE];
  const productCode = isSubstitute
    ? substituteDocSequenceCode
    : productData.productCode;

  let nonEditableColumns: string[] = [];
  const isConsumedProduct =
    heirarchyLevel === 1 && props.checkIfProductConsumed?.(productCode || '');

  const allowRowEdit =
    !getTotalAllotedQty(productData) &&
    Utility.isEmptyObject(childWoCode) &&
    !isConsumedProduct;
  if (
    heirarchyLevel !== 1 ||
    props.isReadOnlyMode ||
    isConsumedProduct ||
    !allowRowEdit ||
    isSubstitute
  ) {
    nonEditableColumns = Object.values(BOM_EXPLOSION_COLUMN_KEYS);
  } else if (productData.productType === PRODUCT_TYPE.NON_TRACKED) {
    nonEditableColumns = Object.values(BOM_EXPLOSION_COLUMN_KEYS).filter(
      (key) =>
        ![
          BOM_EXPLOSION_COLUMN_KEYS.PRODUCT_NAME,
          BOM_EXPLOSION_COLUMN_KEYS.CHARGES
        ].includes(key)
    );
  } else {
    nonEditableColumns = Object.values(BOM_EXPLOSION_COLUMN_KEYS).filter(
      (key) =>
        ![
          BOM_EXPLOSION_COLUMN_KEYS.PRODUCT_NAME,
          BOM_EXPLOSION_COLUMN_KEYS.REQUIRED_QUANTITY,
          BOM_EXPLOSION_COLUMN_KEYS.UOM
        ].includes(key)
    );
  }

  let productRow = {
    ...productData,
    [BOM_EXPLOSION_COLUMN_KEYS.STOCK_UOM]: productData?.stockUom,
    [BOM_EXPLOSION_COLUMN_KEYS.OLD_STOCK_UOM]:
      productData?.documentUOMSchemaDefinition?.sourceUOM ??
      productData?.oldStockUOM,
    [BOM_EXPLOSION_COLUMN_KEYS.PRODUCT_CODE]: productCode,
    [BOM_EXPLOSION_COLUMN_KEYS.AVAILABLE_QUANTITY]:
      Utility.roundOffToTenantDecimalScale(
        productData?.documentUOMSchemaDefinition
          ? productData?.availableUomQuantity
          : productData.availableQuantity || 0
      ),
    [BOM_EXPLOSION_COLUMN_KEYS.TOTAL_AVAILABLE_STOCK_IN_TAGGED_WH]:
      Utility.roundOffToTenantDecimalScale(
        productData[BOM_EXPLOSION_COLUMN_KEYS.TAGGED_WH_DETAIL]
          ?.totalAvailableStockInTaggedWH ?? 0
      ),
    [BOM_EXPLOSION_COLUMN_KEYS.REQUIRED_QUANTITY]: requiredQty,
    [BOM_EXPLOSION_COLUMN_KEYS.ALLOTED_QUANTITY]:
      productData.advancedTracking === ADVANCE_TRACKING.SERIAL
        ? productData?.documentUOMSchemaDefinition
          ? Utility.getUomQuantityWithoutRoundOff(
              allotedQty,
              productData?.documentUOMSchemaDefinition
            )
          : allotedQty
        : allotedQty,
    [BOM_EXPLOSION_COLUMN_KEYS.AVAILABILITY]: availabilityStatus(productData),
    [BOM_EXPLOSION_COLUMN_KEYS.AVAILABILITY_TARGET_WH]: availabilityStatus(
      productData,
      true
    ),
    [BOM_EXPLOSION_COLUMN_KEYS.PRODUCT_TYPE]: [
      getProductTypeLabel(productData)
    ],
    [BOM_EXPLOSION_COLUMN_KEYS.LINKED_WO_CODE]: childWoCode,
    [BOM_EXPLOSION_COLUMN_KEYS.LINKED_DOC_CODE]: getLinkedPOPRCode(
      productData,
      true
    ),
    [BOM_EXPLOSION_COLUMN_KEYS.LINKED_JWO_DOC_CODE]: getLinkedJWOCode(
      productData,
      true
    ),
    [BOM_EXPLOSION_COLUMN_KEYS.LEAD_TIME]: productData?.leadTime,
    [BOM_EXPLOSION_COLUMN_KEYS.RECEIVED_BY]:
      getReceivedByDateValueForBomProduct(productData),
    hideOnEnableSubstituteOnlySetting:
      !productData[BOM_EXPLOSION_COLUMN_KEYS.SUBSTITUTE] &&
      (parentHasAssignedSubstitute || hasAssignedSubstitutes),
    isParentASubstitute:
      parentProductRowData?.[BOM_EXPLOSION_COLUMN_KEYS.SUBSTITUTE] ||
      parentProductRowData?.isParentASubstitute,
    hideOnEnableAvailableSubstituteOnlySetting:
      !productData[BOM_EXPLOSION_COLUMN_KEYS.SUBSTITUTE] &&
      ((heirarchyLevel === 1 && !hasAvailableSubstitutes) ||
        (heirarchyLevel > 1 && !parentHasAvailableSubstitute)),
    allowAutoAllocateIcon:
      heirarchyLevel === 1 &&
      requiredQty > 0 &&
      (Utility.isNotEmpty(productData?.productCode) ||
        productData[BOM_EXPLOSION_COLUMN_KEYS.SUBSTITUTE]) &&
      productType !== PRODUCT_TYPE.NON_TRACKED,
    isRawMaterial: [PRODUCT_TYPE.TRACKED, PRODUCT_TYPE.NON_TRACKED].includes(
      productType
    ),
    level: heirarchyLevel,
    parentUniqueId: parentProductRowData?.uniqueId,
    parentRowIdSuffix: parentProductRowData?.rowIdSuffix,
    rowIdSuffix: currentBowProductRowId,
    parentProductRowData: parentProductRowData,
    isBomRowEditModeEnabled: allowRowEdit,
    nonEditableColumns: nonEditableColumns,
    invalidFields: invalidFields
  };

  return productRow;
}

export function parseBomExplosionGridRows(
  productData: any,
  productIndex: number = 0,
  heirarchyLevel: number = 0,
  parentProductRowData: any = null,
  props: {
    isReadOnlyMode: boolean;
    checkIfProductConsumed: (productCode: string) => boolean;
  }
) {
  if (!productData) return [];

  const productRowData = parseProductRowObjectForBomExplosion(
    productData,
    productIndex,
    heirarchyLevel,
    parentProductRowData,
    props
  );
  let rows = [productRowData];

  const showOnlySubstitutes =
    Utility.getPersistentValue(
      LOCAL_STORAGE_KEYS.BOM_EXPLOSION_SUBSTITUTE_ONLY
    ) === 'true';
  const hasAssignedSubstitutes = Utility.isNotEmpty(
    productData[BOM_EXPLOSION_COLUMN_KEYS.ASSIGNED_SUBSTITUTES]
  );

  const isExpanded =
    (hasAssignedSubstitutes && showOnlySubstitutes) || productData.expanded;

  !productData.isExplodeSubstitute &&
    isExpanded &&
    productData[BOM_EXPLOSION_COLUMN_KEYS.ASSIGNED_SUBSTITUTES]?.map(
      (substitute: any, substituteIndex: number) => {
        if (
          !Utility.isEmpty(substitute?.warehouseInventoryData) ||
          substitute?.isProductPicked
        ) {
          const innerRowsWithComponentProduct = parseBomExplosionGridRows(
            substitute,
            substituteIndex,
            heirarchyLevel,
            {
              ...productData,
              isParentASubstitute: productRowData.isParentASubstitute
            },
            props
          );
          rows = rows.concat(innerRowsWithComponentProduct);
        }

        return null;
      }
    );

  isExpanded &&
    productData.bomProductConfiguration?.forEach(
      (componentProduct: any, componentIndex: number) => {
        const innerRowsWithComponentProduct = parseBomExplosionGridRows(
          componentProduct,
          componentIndex,
          (heirarchyLevel || 0) + 1,
          {
            ...productData,
            isParentASubstitute: productRowData.isParentASubstitute
          },
          props
        );
        rows = rows.concat(innerRowsWithComponentProduct);
      }
    );

  return rows;
}

export function getUpdatedBomExplosionDataByRowId(
  productData: any,
  data: { rowUniqueId: string; key: string; value: any }
) {
  productData = { ...productData };
  if (data.rowUniqueId === productData.uniqueId) {
    if (data.key === BOM_EXPLOSION_COLUMN_KEYS.PRODUCT_NAME) {
      productData = { ...productData, ...data.value };
    } else if (data.key === BOM_EXPLOSION_COLUMN_KEYS.UOM) {
      const uomKey =
        productData?.documentUOMSchemaDefinition?.sourceUOM ??
        productData?.stockUom;
      const uomDefinition = data?.value?.sinkUOM ? data?.value : null;
      productData = {
        ...productData,
        oldStockUOM: uomKey,
        uom: data?.value?.name,
        stockUom: data?.value?.sinkUOM ? data?.value?.sinkUOM : uomKey,
        documentUOMSchemaDefinition: uomDefinition,
        availableQuantity: productData?.availableQuantity ?? 0,
        availableUomQuantity: Utility.getUomQuantity(
          productData?.availableQuantity,
          uomDefinition
        )
      };
    } else {
      productData[data.key] = data.value;
    }
    return productData;
  }

  productData.bomProductConfiguration =
    productData.bomProductConfiguration?.map((componentProduct: any) =>
      getUpdatedBomExplosionDataByRowId(componentProduct, data)
    ) ?? [];
  productData[BOM_EXPLOSION_COLUMN_KEYS.ASSIGNED_SUBSTITUTES] =
    productData[BOM_EXPLOSION_COLUMN_KEYS.ASSIGNED_SUBSTITUTES]?.map(
      (substitute: any) => getUpdatedBomExplosionDataByRowId(substitute, data)
    ) ?? [];

  return productData;
}

export function getAddMaterialBomExplosionDataByRowId(
  productData: any,
  rowUniqueId: string
) {
  productData = { ...productData };
  if (rowUniqueId === productData.uniqueId) {
    productData.bomProductConfiguration.push(getInitialRawMaterial());
    return productData;
  }

  productData.bomProductConfiguration = (
    productData.bomProductConfiguration || []
  ).map((componentProduct: any) =>
    getAddMaterialBomExplosionDataByRowId(componentProduct, rowUniqueId)
  );

  return productData;
}

export const allItemsHaveAvailQtyForStockRequest = (
  selectedProductList: any
) => {
  let allItemsHaveAvailQty = selectedProductList.every((product: any) => {
    const taggedWHProductAvailability =
      product[BOM_EXPLOSION_COLUMN_KEYS.TAGGED_WH_DETAIL];

    return (
      !taggedWHProductAvailability?.allItemsAvailableInTaggedWH &&
      taggedWHProductAvailability?.qytToBeRequested > 0
    );
  });
  return allItemsHaveAvailQty;
};

export const hideWIPRMButton = (bomExplosionData: any) => {
  let requiredQtyRM = 0,
    requiredQtyWIP = 0;
  bomExplosionData?.bomProductConfiguration?.forEach(
    (componentProduct: any) => {
      const requiredQty = Number(
        componentProduct[BOM_EXPLOSION_COLUMN_KEYS.REQUIRED_QUANTITY] || 0
      );
      if (Utility.isEmpty(componentProduct?.bomProductConfiguration)) {
        requiredQtyRM += requiredQty;
      } else {
        requiredQtyWIP += requiredQty;
      }
    }
  );

  let allocatedQtyWIP = 0;
  let allocatedQtyRM = 0;
  let subsAllocatedQtyWIP = 0;
  let subsAllocatedQtyRM = 0;
  const warehouseInventoryDataWIP: any = [];
  const warehouseInventoryDataRM: any = [];
  const subsWarehouseInventoryDataWIP: any = [];
  const subsWarehouseInventoryDataRM: any = [];
  bomExplosionData?.bomProductConfiguration?.forEach((item: any) => {
    if (!Utility.isEmpty(item?.bomProductConfiguration)) {
      warehouseInventoryDataWIP.push(...(item?.warehouseInventoryData || []));

      if (item?.[BOM_EXPLOSION_COLUMN_KEYS.ASSIGNED_SUBSTITUTES]?.length > 0) {
        item?.[BOM_EXPLOSION_COLUMN_KEYS.ASSIGNED_SUBSTITUTES]?.forEach(
          (subDetails: any) => {
            if (subDetails?.warehouseInventoryData?.length) {
              subsWarehouseInventoryDataWIP.push(
                ...(subDetails?.warehouseInventoryData || [])
              );
            }
          }
        );
      }
    } else {
      warehouseInventoryDataRM.push(...(item?.warehouseInventoryData || []));

      if (item?.[BOM_EXPLOSION_COLUMN_KEYS.ASSIGNED_SUBSTITUTES]?.length > 0) {
        item?.[BOM_EXPLOSION_COLUMN_KEYS.ASSIGNED_SUBSTITUTES]?.forEach(
          (subDetails: any) => {
            if (subDetails?.warehouseInventoryData?.length) {
              subsWarehouseInventoryDataRM.push(
                ...(subDetails?.warehouseInventoryData || [])
              );
            }
          }
        );
      }
    }
  });
  const quantityReducer = (acc: any, curr: any) =>
    acc + (Number(curr['quantity']) || 0);
  allocatedQtyWIP = warehouseInventoryDataWIP?.reduce(quantityReducer, 0);
  allocatedQtyRM = warehouseInventoryDataRM?.reduce(quantityReducer, 0);
  subsAllocatedQtyWIP = subsWarehouseInventoryDataWIP?.reduce(
    quantityReducer,
    0
  );
  subsAllocatedQtyRM = subsWarehouseInventoryDataRM?.reduce(quantityReducer, 0);

  return {
    disableAutoRMButton:
      Utility.roundOff(requiredQtyRM, QTY_ROUNDOFF_PRECISION) ===
      Utility.roundOff(
        allocatedQtyRM + subsAllocatedQtyRM,
        QTY_ROUNDOFF_PRECISION
      ),
    disableAutoWIPButton:
      Utility.roundOff(requiredQtyWIP, QTY_ROUNDOFF_PRECISION) ===
      Utility.roundOff(
        allocatedQtyWIP + subsAllocatedQtyWIP,
        QTY_ROUNDOFF_PRECISION
      )
  };
};

export const getFlatArrayForObjectAndKey = (
  obj: any,
  key: any,
  type: string
) => {
  let mapped;
  if (type === ADVANCE_TRACKING.BATCH || type === ADVANCE_TRACKING.SERIAL) {
    mapped = obj?.map((item: any) => {
      return item?.[key]?.map((lineItem: any) => {
        return {
          ...lineItem,
          ...item,
          warehouseName: item.warehouseName,
          warehouseCode: item.warehouseCode
        };
      });
    });
  } else {
    mapped = obj?.map((item: any) => {
      return item?.[key];
    });
  }

  let flatArray = mapped?.flat() || [];
  return flatArray;
};

export const calculateShortfallForActionButton = (row: any) => {
  const { shortFallQty } = getShortFallQuantity(row);
  const requiredQty = row[BOM_EXPLOSION_COLUMN_KEYS.REQUIRED_QUANTITY] ?? 1;
  return shortFallQty > 0 ? shortFallQty : requiredQty;
};

/**
 * ALLOCATION UTILS
 */
export const getProductWarehouseData = (
  allWarehouses: any,
  activeWorkOrder: any
) => {
  let productWarehouseData = [];
  if (
    Utility.isBinAllocationMandatory() ||
    Utility.isWarehouseTaggingEnabled()
  ) {
    productWarehouseData = allWarehouses.filter(
      (wh: any) => wh.code === activeWorkOrder?.targetWarehouse?.code
    );
    if (
      WarehouseManagementHelper.isRRBEnabledForWarehouse(
        activeWorkOrder?.targetWarehouse
      )
    ) {
      const taggedRRBDetails =
        productWarehouseData?.[0]?.rowRackBinProductAvailableQuantityDtos?.filter(
          (RRBItem: any) =>
            RRBItem.rowCode == activeWorkOrder?.targetWarehouse?.rowCode &&
            RRBItem.rackCode == activeWorkOrder?.targetWarehouse?.rackCode &&
            RRBItem.binCode == activeWorkOrder?.targetWarehouse?.binCode
        );
      productWarehouseData[0]['rowRackBinProductAvailableQuantityDtos'] =
        taggedRRBDetails;
    }
  } else {
    productWarehouseData = allWarehouses;
  }
  return productWarehouseData;
};

export const allocateMainProductAllowed = (
  pickedSubstitute: any,
  allocationType: string
) => {
  return (
    (Utility.isEmpty(pickedSubstitute) &&
      allocationType === BOM_EXPLOSION_ALLOCATION_TYPE.ONLY_SUBSTITUTE) ||
    allocationType === BOM_EXPLOSION_ALLOCATION_TYPE.DEFAULT_ALLOCATION ||
    allocationType === BOM_EXPLOSION_ALLOCATION_TYPE.ONLY_ORIGINAL_PRODUCT
  );
};

export const prepareBomExplosionProductForAllocation = (
  selectedProduct: any,
  allocationType: string
) => {
  let parsedTrackingItemData = {
    product: {
      ...selectedProduct,
      name: selectedProduct?.productName
    },
    documentSequenceCode:
      selectedProduct.productDocumentSeqCode ??
      selectedProduct.documentSequenceCode ??
      selectedProduct.productCode,
    productCode: selectedProduct?.pid,
    requiredQuantity:
      selectedProduct?.[BOM_EXPLOSION_COLUMN_KEYS.REQUIRED_QUANTITY] -
      calculateTotalSubstituteAlloted(selectedProduct),
    productQuantity:
      selectedProduct[BOM_EXPLOSION_COLUMN_KEYS.REQUIRED_QUANTITY] -
      calculateTotalSubstituteAlloted(selectedProduct),
    advancedTracking: selectedProduct.advancedTracking,
    advancedTrackingFulfilmentData: getFlatArrayForObjectAndKey(
      selectedProduct?.warehouseInventoryData,
      'advancedTrackingData',
      allocationType
    ),
    warehouseInventoryData: selectedProduct?.warehouseInventoryData ?? [],
    reservedQuantitiesData: getFlatArrayForObjectAndKey(
      selectedProduct?.reservedQuantitiesData,
      'advancedTrackingMetaDtos',
      allocationType
    ),
    isQuickCommit: false,
    documentUOMSchemaDefinition: selectedProduct?.documentUOMSchemaDefinition
  };

  if (allocationType === ADVANCE_TRACKING.NONE) {
    parsedTrackingItemData = {
      ...parsedTrackingItemData,
      parentQuantityToFulfill: parsedTrackingItemData.requiredQuantity,
      pendingQuantity: parsedTrackingItemData.requiredQuantity
    } as any;
  }

  return parsedTrackingItemData;
};

export const getBomProductIdsForAutoAllocation = (
  bomExplosionData: any,
  allocateProductType: string
) => {
  const specificTypeOfProduct =
    getProductsFromType(
      bomExplosionData?.bomProductConfiguration,
      allocateProductType,
      false
    ) || [];
  const pidArray = specificTypeOfProduct.map((item: any) => item.pid) || [];
  const productIdArray = specificTypeOfProduct?.map((item: any) => {
    if (
      Utility.isNotEmpty(item?.[BOM_EXPLOSION_COLUMN_KEYS.ASSIGNED_SUBSTITUTES])
    ) {
      return item[BOM_EXPLOSION_COLUMN_KEYS.ASSIGNED_SUBSTITUTES].map(
        (substitute: any) => substitute.productId
      );
    }
    return [];
  });
  const flattenedProductIdArray = productIdArray.flat();
  return pidArray.concat(flattenedProductIdArray);
};

export const checkIfJWOIsAlreadyCreated = (
  productData: any,
  jwoList: any[]
) => {
  let isAlreadyCreated = false;
  if (!productData || !jwoList) return isAlreadyCreated;

  jwoList.forEach((jwoData) => {
    jwoData?.jobWorkOutOrderItems?.forEach((jwoItem: any) => {
      if (jwoItem.productCode === productData.pid) {
        isAlreadyCreated = true;
        return;
      }
    });
  });

  return isAlreadyCreated;
};

export const getMaxDate = (itemList: any) => {
  if (Utility.isEmpty(itemList)) {
    return new Date();
  }
  let maxDateProductObject = itemList?.reduce(function (a: any, b: any) {
    let firstDate = a?.expectedDeliveryDt || new Date();
    let secondDate = b?.expectedDeliveryDt || new Date();
    return firstDate > secondDate ? a : b;
  });
  // compoare it to new
  if (
    typeof maxDateProductObject?.expectedDeliveryDt !== 'undefined' &&
    maxDateProductObject?.expectedDeliveryDt !== null
  ) {
    return DateFormatService.getDateStrFromDate(
      maxDateProductObject?.expectedDeliveryDt,
      BOOKS_DATE_FORMAT['DD-MM-YYYY']
    );
  } else {
    return DateFormatService.getDateStrFromDate(
      new Date(),
      BOOKS_DATE_FORMAT['DD-MM-YYYY']
    );
  }
};

export const payloadForWorkOrder = (WIPOrFGProducts: any) => {
  let productList = WIPOrFGProducts?.filter((fgItem: any) => {
    return Utility.isEmpty(fgItem?.workOrderChildDetails);
  })?.map((item: any) => {
    let shortFallQty = calculateShortfallForActionButton(item) ?? 0;
    let uomToApply = item?.uom;
    if (Utility.isNotEmpty(item?.documentUOMSchemaDefinition)) {
      shortFallQty = Utility.getUomWarehouseQuantity(
        shortFallQty,
        item?.documentUOMSchemaDefinition
      );
      uomToApply = item?.documentUOMSchemaDefinition?.sourceUOM;
    }
    return {
      ...item,
      selected: true,
      productDocumentSeqCode: item?.productCode ?? '',
      productName: item?.productName ?? '',
      requiredQty: shortFallQty,
      typeForGridDisplay: item?.displayProductType?.[0],
      stockUom: uomToApply
    };
  });
  return productList;
};

export const filterLinkedLineItems = (orderItems: any) => {
  let linkedLineItemsDetails: any = {};
  orderItems.forEach((item: any) => {
    linkedLineItemsDetails[item.lineNumber] = {
      productCode: item.productCode,
      itemProductCode: item.productCode,
      isSubstitute: false
    };
  });
  return linkedLineItemsDetails;
};

export const filterRequisitionProductObject = async (
  rowItemObj: any,
  activeWorkOrder: any
) => {
  let orderItems: any = [];

  const substituteItemProductIds = rowItemObj
    .filter((row: any) => row[BOM_EXPLOSION_COLUMN_KEYS.SUBSTITUTE])
    .map((row: any) => row.productId);
  const substituteProductDetails = await (substituteItemProductIds.length
    ? ProductService.getProductsByProductIds(substituteItemProductIds)
    : []);

  rowItemObj.forEach((row: any, index: number) => {
    let shortFallQty = calculateShortfallForActionButton(row);
    let uomQuantity = calculateShortfallForActionButton(row);

    if (Utility.isNotEmpty(row?.documentUOMSchemaDefinition)) {
      uomQuantity = Utility.getUomWarehouseQuantity(
        uomQuantity,
        row?.documentUOMSchemaDefinition
      );
    }

    const productId = row[BOM_EXPLOSION_COLUMN_KEYS.SUBSTITUTE]
      ? substituteProductDetails?.find(
          (substitute: any) => substitute.productId === row.productId
        )?.id
      : row.productId;
    let productObj = {
      ...row,
      name: row.productName,
      id: productId,
      productId
    };
    delete productObj['productId'];
    orderItems.push({
      ...RequisitionOrderInitialState.purchaseRequestItems?.[0],
      product: {
        ...productObj,
        documentUOMSchemaDefinition: row?.documentUOMSchemaDefinition
          ? {
              ...row?.documentUOMSchemaDefinition,
              schemaId: row?.uomSchemaDto?.id
            }
          : null,
        stockUom: row?.documentUOMSchemaDefinition
          ? row?.documentUOMSchemaDefinition?.sourceUOM
          : row?.stockUom,
        uomSchemaDto: row?.uomSchemaDto
      },
      unitPrice: row?.purchasePrice ?? 0,
      productQuantity: Utility.roundOff(shortFallQty, QTY_ROUNDOFF_PRECISION),
      lineNumber: index + 1,
      productName: row?.productName,
      productCode: row?.pid,
      documentSequenceCode: row?.productCode,
      documentUOMSchemaDefinition: row?.documentUOMSchemaDefinition
        ? {
            ...row?.documentUOMSchemaDefinition,
            schemaId: row?.uomSchemaDto?.id
          }
        : null,
      stockUom: row?.documentUOMSchemaDefinition
        ? row?.documentUOMSchemaDefinition?.sourceUOM
        : row?.stockUom,
      uomSchemaDto: row?.uomSchemaDto,
      documentUom: row?.stockUom,
      uomQuantity: Utility.roundOff(uomQuantity, QTY_ROUNDOFF_PRECISION),
      uomUnitPrice: row?.purchasePrice,
      productDescription: row?.description,
      pendingQuantity: 0,
      discountInPercent: false,
      userSetTaxes: false,
      expectedDeliveryDt: Utility.addDaysInDate(
        activeWorkOrder?.plannedStartDate,
        row?.leadTime || 0
      )
    });
  });
  return orderItems;
};

export const getPurchaseOrderPayloadFromProductData = async (
  productList: any[],
  workOrderData: IWorkOrder
) => {
  console.log(productList, workOrderData);
  const { orderItems, vendorCode } = await filterProductObjectForPO(
    productList,
    workOrderData
  );

  let linkedLineItemsDetails: any = filterLinkedLineItems(orderItems);

  let contact = null;
  try {
    if (vendorCode && vendorCode !== 'NA') {
      showLoader();
      ContactService.apiConfig.Query = `code=${vendorCode}`;
      const contactResponse: any = await ContactService.getContactsByPage();
      contact = contactResponse?.content?.[0] || null;
      removeLoader();
    }
  } catch (err) {
    contact = null;
    removeLoader();
  }

  let po: any = {
    ...OrderInitialState,
    purchaseOrderItems: orderItems,
    receiveByDate: getMaxDate(orderItems),
    showCustomAlert: true,
    updateResponseInStore: true,
    contact: contact,
    linkedDocuments: Utility.isNotEmpty(workOrderData?.documentSequenceCode)
      ? [
          {
            documentType: WORK_ORDER_PR_PO.WORK_ORDER,
            documentCode: workOrderData?.workOrderCode,
            documentSequenceCode: workOrderData?.documentSequenceCode,
            documentReceiveByDate: DateFormatService.getDateStrFromDate(
              workOrderData?.deliveryDate,
              BOOKS_DATE_FORMAT['DD-MM-YYYY']
            ),
            productCodes: [],
            lineItemDetails: linkedLineItemsDetails
          }
        ]
      : [],
    autoCreatePOFromWO: true,
    isCreateFromMRP: true
  };

  po = getUpdatedPurchaseOrderObject(po);

  const tableId = draftTableId(Store.getState());
  const draftsColumnConfig = selectDraftsColumnConfig(Store.getState());

  return {
    title: LABELS.PURCHASE_ORDERS,
    type: LABELS.PURCHASE_ORDERS,
    populateFormData: po,
    isCenterAlign: true,
    tableId: tableId,
    columnConfig: draftsColumnConfig,
    hideMinimizer: true
  };
};

export const getPurchaseRequisitionPayloadFromProductData = async (
  productList: any[],
  workOrderData: IWorkOrder
) => {
  console.log(productList, workOrderData);
  let orderItems: any =
    (await filterRequisitionProductObject(productList, workOrderData)) ?? [];

  let linkedLineItemsDetails: any = filterLinkedLineItems(orderItems);

  let formData = {
    dueDate: '',
    purchaseRequestItems: orderItems,
    receiveByDate: getMaxDate(orderItems),
    showCustomAlert: true,
    updateResponseInStore: true,
    linkedDocuments: [
      {
        documentType: WORK_ORDER_PR_PO.WORK_ORDER,
        documentCode: workOrderData?.workOrderCode,
        documentSequenceCode: workOrderData?.documentSequenceCode,
        documentReceiveByDate: DateFormatService.getDateStrFromDate(
          workOrderData?.deliveryDate,
          BOOKS_DATE_FORMAT['DD-MM-YYYY']
        ),
        productCodes: [],
        lineItemDetails: linkedLineItemsDetails
      }
    ]
  };

  formData = getUpdatedRequisitonNewOrderObject(formData);

  const tableId = draftTableId(Store.getState());
  const draftsColumnConfig = selectDraftsColumnConfig(Store.getState());

  return {
    title: LABELS.REQUISITION,
    type: LABELS.REQUISITION,
    tableId: tableId,
    columnConfig: draftsColumnConfig,
    populateFormData: formData,
    isCenterAlign: true,
    hideMinimizer: true
  };
};

export const filterProductObjectForPO = async (
  rowItemObj: any,
  activeWorkOrder: any
) => {
  let orderItems: any = [];
  let vendorCode: any = null;

  const substituteItemProductIds = rowItemObj
    .filter((row: any) => row[BOM_EXPLOSION_COLUMN_KEYS.SUBSTITUTE])
    .map((row: any) => row.productId);
  const substituteProductDetails = await (substituteItemProductIds.length
    ? ProductService.getProductsByProductIds(substituteItemProductIds)
    : []);

  rowItemObj.forEach((row: any, index: number) => {
    let shortFallQty = calculateShortfallForActionButton(row) ?? 0;
    let uomQuantity = calculateShortfallForActionButton(row) ?? 0;

    if (row.prefferedVendorCode) {
      vendorCode = row.prefferedVendorCode;
    }

    const productId = row[BOM_EXPLOSION_COLUMN_KEYS.SUBSTITUTE]
      ? substituteProductDetails?.find(
          (substitute: any) => substitute.productId === row.productId
        )?.id
      : row.productId;

    orderItems.push({
      ...OrderInitialState.purchaseOrderItems?.[0],
      product: {
        ...row,
        name: row.productName,
        documentSequenceCode: row.productCode ?? row.productDocumentSeqCode,
        documentUOMSchemaDefinition: row?.documentUOMSchemaDefinition
          ? {
              ...row?.documentUOMSchemaDefinition,
              schemaId: row?.uomSchemaDto?.id
            }
          : null,
        stockUom: row?.documentUOMSchemaDefinition
          ? row?.documentUOMSchemaDefinition?.sourceUOM
          : row?.stockUom,
        uomSchemaDto: row?.uomSchemaDto,
        id: productId,
        productId
      },
      documentUOMSchemaDefinition: row?.documentUOMSchemaDefinition
        ? {
            ...row?.documentUOMSchemaDefinition,
            schemaId: row?.uomSchemaDto?.id
          }
        : null,
      unitPrice: row?.purchasePrice ?? 0,
      productQuantity: Utility.roundOff(shortFallQty, QTY_ROUNDOFF_PRECISION),
      lineNumber: index + 1,
      productName: row?.productName,
      productCode: row?.pid,
      documentUom: row?.stockUom,
      uomQuantity: Utility.roundOff(uomQuantity, QTY_ROUNDOFF_PRECISION),
      uomUnitPrice: row?.purchasePrice,
      productDescription: row?.description,
      pendingQuantity: 0,
      discountInPercent: false,
      userSetTaxes: false,
      expectedDeliveryDt: Utility.addDaysInDate(
        activeWorkOrder.plannedStartDate,
        row?.leadTime || 0
      )
    });
  });

  return { orderItems, vendorCode };
};

export const parseStockRequestDataFromBomProducts = ({
  selectedProductList,
  stockRequestProducts,
  activeWorkOrder
}: {
  selectedProductList: any[];
  stockRequestProducts: any[];
  activeWorkOrder: IWorkOrder;
}) => {
  let newRowItems: any[] = [];
  selectedProductList.forEach((item: any, index: number) => {
    item = {
      ...item,
      requiredQty: item[BOM_EXPLOSION_COLUMN_KEYS.REQUIRED_QUANTITY]
    };
    const product = stockRequestProducts?.find(
      (prod: any) => prod.productId === item.pid
    );
    let qtyToBeRequested = calculateShortfallForActionButton(item);
    // qtyToBeRequested = Utility.getUomQuantity(
    //   qtyToBeRequested,
    //   item?.documentUOMSchemaDefinition
    // );
    const existingItemIndex = newRowItems?.findIndex(
      (newItem) => newItem.productVariantCode === item.pid
    );
    if (existingItemIndex === -1) {
      const newItem = {
        productName: item.productName,
        lineNumber: index,
        documentSequenceCode: item.productCode,
        productVariantCode: item.pid,
        documentUom: item.stockUom,
        documentUOMSchemaDefinition: item?.documentUOMSchemaDefinition ?? {
          name: item.uom
        },
        availableQuantity: qtyToBeRequested,
        targetWarehouse: activeWorkOrder?.targetWarehouse,
        dstWarehouseCode: activeWorkOrder?.targetWarehouse?.code,
        stockRequestWarehouseInventoryData: '',
        product: product,
        rowButtons: [],
        row: null,
        rack: null,
        bin: null,
        taggedWarehouseData: activeWorkOrder?.warehouseInventoryData
      };
      newRowItems.push(newItem);
    } else {
      newRowItems[existingItemIndex] = {
        ...newRowItems[existingItemIndex],
        availableQuantity:
          newRowItems[existingItemIndex]?.availableQuantity + qtyToBeRequested
      };
    }
  });

  return newRowItems;
};

export const parseProductRowsForAutoCreatePOPR = (
  filteredRows: any[],
  buttonType: string,
  nonAvailableProductDetailsById: { [id: number]: any }
) => {
  let productForPOPR = (filteredRows || []).filter(
    (row) =>
      row.level === 1 &&
      row[BOM_EXPLOSION_COLUMN_KEYS.AVAILABILITY] !==
        AVAILABILITY_STATUS.AVAILABLE
  );
  productForPOPR = productForPOPR?.map((item: any) => {
    let shortFallQty = calculateShortfallForActionButton(item) ?? 0;

    let productDetails = {
      ...item,
      selected: true,
      productDocumentSeqCode: item?.productCode ?? '',
      productName: item?.productName ?? '',
      requiredQty: shortFallQty ?? 0,
      typeForGridDisplay: item?.displayProductType?.[0],
      uom: item?.uom
    };

    if (buttonType === BOM_EXPLOSION_BUTTON_TYPE.PO) {
      const completeProductDetails = nonAvailableProductDetailsById?.[item.pid];
      const prefferedVendorCode =
        completeProductDetails?.reorderVendorCode || 'NA';
      const prefferedVendorName =
        prefferedVendorCode !== 'NA'
          ? completeProductDetails?.reorderVendorName ||
            `Vendor (${prefferedVendorCode})`
          : 'No preferred vendor';

      productDetails = {
        ...productDetails,
        prefferedVendorCode,
        prefferedVendorName
      };
    }

    return productDetails;
  });

  return productForPOPR ?? [];
};

export const getJwoDraftDocumentPayloadFromProductData = async ({
  productData,
  workOrderData
}: {
  productData: any;
  workOrderData: IWorkOrder;
}) => {
  const workOrderCode = workOrderData?.workOrderCode;
  const woDocSequenceCode = workOrderData?.documentSequenceCode;

  if (!workOrderCode || !woDocSequenceCode || !productData?.pid) {
    return Promise.reject(
      'No valid product or work order sequence code not found'
    );
  }

  const products = await ProductService.getProductsByProductIds([
    productData.pid
  ]);
  const taxCode = products?.[0]?.purchaseTaxCode;
  const taxDetailResponse = await TaxService.getTaxWithId(taxCode);

  let qty = calculateShortfallForActionButton(productData);
  if (Utility.isNotEmpty(productData?.documentUOMSchemaDefinition)) {
    qty = Utility.getUomWarehouseQuantity(
      qty,
      productData?.documentUOMSchemaDefinition
    );
  }

  let uomId = productData?.stockUom ?? products?.[0]?.stockUom;
  let jwo: any = {
    ...JobWorkOutInitialState,
    updateResponseInStore: true,
    jobWorkOutOrderItems: [
      {
        product: products?.[0],
        unitPrice: products?.[0]?.purchasePrice,
        productQuantity: qty,
        lineNumber: 1,
        productName: products?.[0]?.name,
        productCode: products?.[0]?.productId,
        documentUom: uomId,
        productDescription: products?.[0].description,
        uomQuantity: 1,
        uomUnitPrice: products?.[0]?.purchasePrice,
        pendingQuantity: 0,
        discountInPercent: false,
        userSetTaxes: false,
        tax: taxDetailResponse?.content?.[0],
        documentUOMSchemaDefinition: productData?.documentUOMSchemaDefinition
          ? {
              ...productData?.documentUOMSchemaDefinition,
              schemaId: productData?.uomSchemaDto?.id
            }
          : null,
        stockUom: uomId
        // uom: uomId ? getDefaultUom(uomId) : null
      }
    ],
    showCustomAlert: true,
    linkedDocuments: [
      {
        documentCode: workOrderCode,
        documentSequenceCode: woDocSequenceCode,
        documentType: DOC_TYPE.WORK_ORDER
      }
    ],
    isCreateFromMRP: true
  };
  jwo = getJobWorkOutObject(jwo) as WorkOut;

  const tableId = draftTableId(Store.getState());
  const draftsColumnConfig = selectDraftsColumnConfig(Store.getState());

  const payloadData = {
    title: LABELS.WORK_OUT,
    type: LABELS.WORK_OUT,
    populateFormData: jwo,
    isCenterAlign: true,
    tableId: tableId,
    columnConfig: draftsColumnConfig,
    hideMinimizer: true
  };
  console.log(payloadData, 'JWO payload');
  return Promise.resolve(payloadData);
};

export const removeMaterialFromBomexplosion = (
  bomExplosionProductData: any,
  materialToRemove: any
) => {
  if (
    !materialToRemove ||
    !bomExplosionProductData ||
    Utility.isEmpty(bomExplosionProductData.bomProductConfiguration)
  )
    return bomExplosionProductData;

  let bomExplosionData: any = { ...bomExplosionProductData };

  /** If removing substitute row  */
  if (materialToRemove[BOM_EXPLOSION_COLUMN_KEYS.SUBSTITUTE]) {
    const allComponents = [...bomExplosionData.bomProductConfiguration];
    let componentProductDataToUpdate =
      bomExplosionData.bomProductConfiguration.find(
        (componentProductData: any) =>
          componentProductData?.uniqueId ===
          materialToRemove.parentProductRowData?.uniqueId
      );

    if (componentProductDataToUpdate) {
      componentProductDataToUpdate = { ...componentProductDataToUpdate };
      componentProductDataToUpdate[
        BOM_EXPLOSION_COLUMN_KEYS.AVAILABLE_SUBSTITUTES
      ] = componentProductDataToUpdate[
        BOM_EXPLOSION_COLUMN_KEYS.AVAILABLE_SUBSTITUTES
      ].map((substitute: any) =>
        substitute?.uniqueId === materialToRemove?.uniqueId
          ? {
              ...substitute,
              isProductPicked: false
            }
          : substitute
      );
      componentProductDataToUpdate[
        BOM_EXPLOSION_COLUMN_KEYS.ASSIGNED_SUBSTITUTES
      ] = componentProductDataToUpdate[
        BOM_EXPLOSION_COLUMN_KEYS.ASSIGNED_SUBSTITUTES
      ].filter(
        (substitute: any) => substitute?.uniqueId !== materialToRemove?.uniqueId
      );

      bomExplosionData.bomProductConfiguration = allComponents.map(
        (componentProduct) =>
          componentProduct.uniqueId === componentProductDataToUpdate.uniqueId
            ? componentProductDataToUpdate
            : componentProduct
      );
    }
  } else if (
    /** If removing component product from 1st row  */
    materialToRemove.level === 1
  ) {
    bomExplosionData.bomProductConfiguration =
      bomExplosionData.bomProductConfiguration.filter(
        (componentProductData: any) =>
          componentProductData?.uniqueId !== materialToRemove?.uniqueId
      );
  }

  return bomExplosionData;
};

/** Generates same response as that received from Bom Explosion detail API */
export const getInitialRawMaterial = (product?: any) => {
  const productType = product?.type ?? PRODUCT_TYPE.TRACKED;
  const isServiceProduct = productType === PRODUCT_TYPE.NON_TRACKED;
  let defaultProduct = {
    addToRequisition: false,
    advancedTracking: product?.advancedTracking ?? 'NONE',
    availableQuantity:
      (product?.inventory?.availableQuantity ?? 0) -
      (product?.inventory?.reservedQuantity ?? 0),
    bomProductConfiguration: [],
    description: product?.description ?? '',
    isFinishedProduct: product?.isFinishedProduct ?? false,
    leadTime: null,
    pid: product?.productId ?? '',
    produceProductType: PRODUCE_PRODUCT_TYPE.NONE,
    productCode: product?.documentSequenceCode ?? '',
    productId: product?.id ?? '',
    productName: product?.name ?? '',
    [BOM_EXPLOSION_COLUMN_KEYS.AVAILABLE_SUBSTITUTES]: [],
    [BOM_EXPLOSION_COLUMN_KEYS.ASSIGNED_SUBSTITUTES]: [],
    productType: productType,
    purchasePrice: product?.purchasePrice ?? 0,
    [BOM_EXPLOSION_COLUMN_KEYS.CHARGES]: isServiceProduct
      ? product?.purchasePrice ?? 0
      : 0,
    [BOM_EXPLOSION_COLUMN_KEYS.REQUIRED_UNIT_QUANTITY]: isServiceProduct
      ? 1
      : 0,
    [BOM_EXPLOSION_COLUMN_KEYS.REQUIRED_QUANTITY]: isServiceProduct ? 1 : 0,
    reservedQuantity: 0,
    salesPrice: product?.salesPrice ?? 0,
    uom: getSelectedUOMValue(
      Utility.isNullish(product?.stockUom) ? 2 : product.stockUom
    ),
    stockUom: Utility.isNullish(product?.stockUom) ? 2 : product.stockUom,
    workOrderChildDetails: [],
    isNewRow: true,
    productDetails: product ?? null,
    uomSchemaDto: product?.uomSchemaDto ?? null
  };

  const bomExplosionTree = parseBomExplosionResponseToTree({
    data: defaultProduct,
    majorIndex: 1
  });

  return bomExplosionTree;
};
