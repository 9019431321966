import { TableColumnPopupType } from "../../../Constants/Constants";
import { getIsShowTableColumn } from "../../../Utilities/Utility";

export class FulfillmentTableWidth {

    isCodeVisible: boolean;
    isNameVisible: boolean;
    isDescriptionVisible: boolean;
    isSerialBatchVisible: boolean;
    isSerialNumberVisible: boolean;
    isBatchNumberVisible: boolean;
    isManufacturingDateVisible: boolean;
    isExpiryDateVisible: boolean;
    isUomVisible: boolean;
    isRequiredQuantityVisible: boolean;
    isCommittedQuantityVisible: boolean;
    isWarehouseCodeVisible: boolean;
    isWarehouseNameVisible: boolean;
    isProductCustomFieldVisible: boolean;

    code: number;
    name: number;
    description: number;
    serialBatch: number;
    serialNumber: number;
    batchNumber: number;
    manufacturingDate: number;
    expiryDate: number;
    uom: number;
    requiredQuantity: number;
    committedQuantity: number;
    warehouseCode: number;
    warehouseName: number;
    productCustomField: number;

    originalCode: number;
    originalName: number;
    originalDescription: number;
    originalUom: number;
    originalSerialBatch: number;
    originalSerialNumber: number;
    originalBatchNumber: number;
    originalManufacturingDate: number;
    originalExpiryDate: number;
    originalRequiredQuantity: number;
    originalCommittedQuantity: number;
    originalWarehouseCode: number;
    originalWarehouseName: number;
    originalProductCustomField: number;
    productCustomFieldList: any;

    constructor() {
        this.isCodeVisible = true
        this.isNameVisible = true
        this.isDescriptionVisible = true
        this.isUomVisible = true
        this.isRequiredQuantityVisible = true
        this.isCommittedQuantityVisible = true
        this.isWarehouseCodeVisible = true
        this.isWarehouseNameVisible = true
        this.isSerialBatchVisible = true
        this.isSerialNumberVisible = true
        this.isBatchNumberVisible = true
        this.isManufacturingDateVisible = true
        this.isExpiryDateVisible = true
        this.isProductCustomFieldVisible = true

        this.code = 8
        this.name = 15
        this.description = 18
        this.uom = 6
        this.serialBatch = 10
        this.serialNumber = 3
        this.batchNumber = 3
        this.manufacturingDate = 3.5
        this.expiryDate = 3.5
        this.requiredQuantity = 9
        this.committedQuantity = 9
        this.warehouseCode = 9
        this.warehouseName = 7
        this.productCustomField = 9

        this.originalCode = 8
        this.originalName = 15
        this.originalDescription = 18
        this.originalUom = 6
        this.originalSerialBatch = 10
        this.originalSerialNumber = 3
        this.originalBatchNumber = 3
        this.originalManufacturingDate = 3.5
        this.originalExpiryDate = 3.5
        this.originalCommittedQuantity = 9
        this.originalRequiredQuantity = 9
        this.originalWarehouseCode = 9
        this.originalWarehouseName = 7
        this.originalProductCustomField = 9
    }

    setIsShowWidth(columnData: any) {
        this.isCodeVisible = getIsShowTableColumn(columnData, TableColumnPopupType.code)
        this.isNameVisible = getIsShowTableColumn(columnData, TableColumnPopupType.productName)
        this.isDescriptionVisible = getIsShowTableColumn(columnData, TableColumnPopupType.description)
        this.isUomVisible = getIsShowTableColumn(columnData, TableColumnPopupType.uom)
        this.isSerialBatchVisible = getIsShowTableColumn(columnData, TableColumnPopupType.serialBatch)
        this.isSerialNumberVisible = getIsShowTableColumn(columnData, TableColumnPopupType.serialNumber)
        this.isBatchNumberVisible = getIsShowTableColumn(columnData, TableColumnPopupType.batchNumber)
        this.isManufacturingDateVisible = getIsShowTableColumn(columnData, TableColumnPopupType.manufacturingDate)
        this.isExpiryDateVisible = getIsShowTableColumn(columnData, TableColumnPopupType.expiryDate)
        this.isRequiredQuantityVisible = getIsShowTableColumn(columnData, TableColumnPopupType.requiredQuantity)
        this.isCommittedQuantityVisible = getIsShowTableColumn(columnData, TableColumnPopupType.committedQuantity)
        this.isWarehouseCodeVisible = getIsShowTableColumn(columnData, TableColumnPopupType.warehouseCode)
        this.isWarehouseNameVisible = getIsShowTableColumn(columnData, TableColumnPopupType.warehouseName)

        var productCustomFieldList = columnData.filter((x: any) => x.code !== undefined)
        if (productCustomFieldList.length > 0) {
            this.isProductCustomFieldVisible = false
            if (this.productCustomFieldList === undefined) {
                //assign product custom list with corresponding flag
                this.productCustomFieldList = []
                var width = 5
                var maxWidth = 20
                if (productCustomFieldList.length > 4) {
                    width = maxWidth / productCustomFieldList.length
                }
                productCustomFieldList.forEach((element: any) => {
                    var item = {} as any
                    item.code = element.code
                    item.isVisible = element.columnOption.isShowColumn
                    item.originalWidth = width
                    item.width = width
                    item.type = element.name
                    item.label = element.label
                    this.productCustomFieldList.push(item)
                });
            }
            else {
                this.productCustomFieldList.forEach((element: any) => {
                    var item = columnData.find((x: any) => x.code === element.code)
                    if (item !== undefined) {
                        element.isVisible = item.columnOption.isShowColumn
                    }
                });
            }
        }
    }
    getTableHeaderWidths() {
        var totalWidth = 0
        totalWidth = this.getTotalWidth(totalWidth);

        if (totalWidth === 100) {
            this.resetElementOriginalWidth();
        }
        else if (totalWidth === 0) {
            this.resetElementWidth();
        }
        else {
            var totalSelectedElement = this.getTotalSelectedElement()
            if (totalSelectedElement !== 0) {
                var totalOffset = (100 - totalWidth) / totalSelectedElement
                this.updateElementWidth(totalOffset)
            }
        }
    }
    private resetElementOriginalWidth() {
        this.code = this.originalCode
        this.name = this.originalName
        this.description = this.originalDescription
        this.uom = this.originalUom
        this.serialBatch = this.originalSerialBatch
        this.serialNumber = this.originalSerialNumber
        this.batchNumber = this.originalBatchNumber
        this.manufacturingDate = this.originalManufacturingDate
        this.expiryDate = this.originalExpiryDate
        this.committedQuantity = this.originalCommittedQuantity
        this.requiredQuantity = this.originalRequiredQuantity
        this.warehouseCode = this.originalWarehouseCode
        this.warehouseName = this.originalWarehouseName
        if (this.productCustomFieldList !== undefined) {
            this.productCustomFieldList.forEach((element: any) => {
                element.width = element.originalWidth
            });
        }
    }

    private resetElementWidth() {
        this.code = 0
        this.name = 0
        this.description = 0
        this.uom = 0
        this.serialBatch = 0
        this.serialNumber = 0
        this.batchNumber = 0
        this.manufacturingDate = 0
        this.expiryDate = 0
        this.committedQuantity = 0
        this.requiredQuantity = 0
        this.warehouseCode = 0
        this.warehouseName = 0
        if (this.productCustomFieldList !== undefined) {
            this.productCustomFieldList.forEach((element: any) => {
                element.width = 0
            });
        }
    }

    private getTotalWidth(totalWidth: number) {
        if (this.isCodeVisible) {
            totalWidth += this.code;
        }
        if (this.isNameVisible) {
            totalWidth += this.name;
        }
        if (this.isDescriptionVisible) {
            totalWidth += this.description;
        }
        if (this.isUomVisible) {
            totalWidth += this.uom;
        }
        if (this.isSerialBatchVisible) {
            totalWidth += this.serialBatch;
        }
        if (this.isSerialNumberVisible) {
            totalWidth += this.serialNumber;
        }
        if (this.isBatchNumberVisible) {
            totalWidth += this.batchNumber;
        }
        if (this.isManufacturingDateVisible) {
            totalWidth += this.manufacturingDate;
        }
        if (this.isExpiryDateVisible) {
            totalWidth += this.expiryDate;
        }
        if (this.isCommittedQuantityVisible) {
            totalWidth += this.committedQuantity;
        }
        if (this.isRequiredQuantityVisible) {
            totalWidth += this.requiredQuantity;
        }
        if (this.isWarehouseCodeVisible) {
            totalWidth += this.warehouseCode;
        }
        if (this.isWarehouseNameVisible) {
            totalWidth += this.warehouseName;
        }

        if (this.productCustomFieldList !== undefined) {
            this.productCustomFieldList.forEach((element: any) => {
                if (element.isVisible) {
                    totalWidth += element.width;
                }
            });
        }

        return totalWidth;
    }

    private getTotalSelectedElement() {
        var totalSelectedElement = 0
        if (this.isCodeVisible) {
            totalSelectedElement += 1
        }
        if (this.isNameVisible) {
            totalSelectedElement += 1
        }
        if (this.isDescriptionVisible) {
            totalSelectedElement += 1
        }
        if (this.isUomVisible) {
            totalSelectedElement += 1
        }
        if (this.isSerialBatchVisible) {
            totalSelectedElement += 1
        }
        if (this.isSerialNumberVisible) {
            totalSelectedElement += 1
        }
        if (this.isBatchNumberVisible) {
            totalSelectedElement += 1
        }
        if (this.isManufacturingDateVisible) {
            totalSelectedElement += 1
        }
        if (this.isExpiryDateVisible) {
            totalSelectedElement += 1
        }
        if (this.isRequiredQuantityVisible) {
            totalSelectedElement += 1
        }
        if (this.isCommittedQuantityVisible) {
            totalSelectedElement += 1
        }
        if (this.isWarehouseCodeVisible) {
            totalSelectedElement += 1
        }
        if (this.isWarehouseNameVisible) {
            totalSelectedElement += 1
        }

        if (this.productCustomFieldList !== undefined) {
            this.productCustomFieldList.forEach((element: any) => {
                if (element.isVisible) {
                    totalSelectedElement += 1
                }
            });
        }

        return totalSelectedElement
    }

    private updateElementWidth(additionalWidth: number) {
        if (this.isCodeVisible) {
            this.code = this.originalCode + additionalWidth
        }
        if (this.isNameVisible) {
            this.name = this.originalName + additionalWidth
        }
        if (this.isDescriptionVisible) {
            this.description = this.originalDescription + additionalWidth
        }
        if (this.isUomVisible) {
            this.uom = this.originalUom + additionalWidth
        }
        if (this.isSerialBatchVisible) {
            this.serialBatch = this.originalSerialBatch + additionalWidth
        }
        if (this.isSerialNumberVisible) {
            this.serialNumber = this.originalSerialNumber + additionalWidth
        }
        if (this.isBatchNumberVisible) {
            this.batchNumber = this.originalBatchNumber + additionalWidth
        }
        if (this.isManufacturingDateVisible) {
            this.manufacturingDate = this.originalManufacturingDate + additionalWidth
        }
        if (this.isExpiryDateVisible) {
            this.expiryDate = this.originalExpiryDate + additionalWidth
        }
        if (this.isRequiredQuantityVisible) {
            this.requiredQuantity = this.originalRequiredQuantity + additionalWidth
        }
        if (this.isCommittedQuantityVisible) {
            this.committedQuantity = this.originalCommittedQuantity + additionalWidth
        }
        if (this.isWarehouseCodeVisible) {
            this.warehouseCode = this.originalWarehouseCode + additionalWidth
        }
        if (this.isWarehouseNameVisible) {
            this.warehouseName = this.originalWarehouseName + additionalWidth
        }

        if (this.productCustomFieldList !== undefined) {
            this.productCustomFieldList.forEach((element: any) => {
                if (element.isVisible) {
                    element.width = element.originalWidth + additionalWidth
                }
            });
        }
    }

    getProductCustomFieldWidthByCode(code: string) {
        var item = this.productCustomFieldList.find((x: any) => x.code === code)
        if (item !== undefined) {
            return item.width
        }
        return undefined
    }
}