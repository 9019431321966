import { useState } from 'react';
import { DKLabel, DKListPicker2, showAlert } from 'deskera-ui-library';
import Utility, { getCapitalized } from '../../../../Utility/Utility';
import { PROCESS_QC_STATUS } from '../../Constants/MRPEnums';

interface IJCProcessTypeProps {
  column: any;
  row: any;
  rowIndex: number;
  processTypes: any[];
  onTypeSelect: (type: any) => void;
  jobCardMapping: { allJCDetails: any[]; currentJCDependentOn: any };
}

const JCProcessType: React.FC<IJCProcessTypeProps> = ({
  column,
  row,
  rowIndex,
  onTypeSelect,
  processTypes,
  jobCardMapping
}: IJCProcessTypeProps) => {
  const [dropDownOpened, showHideDropdown] = useState<boolean>(false);
  const [linkedItem = {}] = row?.jobCardLinkDetails;
  let localProcessType = row?.localProcessType ?? linkedItem?.processType;

  return (
    <div
      key={`${column.name}-${rowIndex}`}
      className="d-flex pr-xs parent-height"
      style={{
        flexDirection: 'column',
        width: column.width || 100
      }}
      onClick={(e: any) => {
        if (
          Utility.isNotEmpty(linkedItem?.itemWarehouseInventoryData) ||
          Utility.isNotEmpty(linkedItem?.processType)
        ) {
          return;
        }

        if (
          Utility.isNotEmpty(jobCardMapping?.currentJCDependentOn) &&
          Utility.isEmpty(
            jobCardMapping?.currentJCDependentOn?.jobCardLinkDetails
          ) &&
          linkedItem?.jobCardLinkDetails?.[0]?.status !==
            PROCESS_QC_STATUS.QA_DONE
        ) {
          e.stopPropagation();
          showAlert(
            'Please note.',
            `As this job card is a dependent job card, you need to tag material in dependent job card first.`
          );
          return;
        }

        showHideDropdown(true);
        e.stopPropagation();
      }}
    >
      {Utility.isNotEmpty(localProcessType) ? (
        <DKLabel
          key={`${column.name}-${rowIndex}`}
          className="p-h-xs"
          text={getCapitalized(localProcessType.toLowerCase())}
          style={{
            whiteSpace: 'nowrap',
            overflowX: 'hidden',
            textOverflow: 'ellipsis',
            width: column.width || 100
          }}
        />
      ) : (
        <div>-</div>
      )}
      {dropDownOpened && (
        <DKListPicker2
          data={processTypes}
          allowSearch={false}
          className={`position-absolute z-index-3 bg-white border-m`}
          renderer={(index: number, item: any) => (
            <div
              className="parent-width"
              onClick={(e: any) => {
                e.preventDefault();
                e.stopPropagation();
                onTypeSelect(item);
                showHideDropdown(false);
              }}
            >
              {item.name}
            </div>
          )}
          onSelect={() => {}}
          onClose={() => {
            showHideDropdown(false);
          }}
        />
      )}
    </div>
  );
};

export default JCProcessType;
