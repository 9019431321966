import { INPUT_TYPE, showAlert } from 'deskera-ui-library';
import {
  BOOKS_DATE_FORMAT,
  DOCUMENT_MODE,
  DOC_PATH_WITH_ID_REGEX,
  DOC_TYPE,
  FULFILLMENT_STATUS,
  FULFILLMENT_TYPE,
  MODULES_NAME,
  PAYMENT_STATUS,
  RECEIVED_GOODS_STATUS,
  STATUS_TYPE
} from '../../../Constants/Constant';
import { DraftTypes, IDocument } from '../../../Models/Drafts';
import { DocumentConfigManager } from '../../../Managers/DocumentConfigManger';
import {
  getCascadingDiscountsFromStore,
  getOrderedCascadingDiscountsFromSettings
} from '../Helper/DocumentHelper';
import Utility from '../../../Utility/Utility';
import { CASCADING_DISCOUNT_PREFIX } from '../../../SharedComponents/DocumentForm/NewDocumentHelper';
import { Store } from '../../../Redux/Store';
import { DOC_LINE_ITEM_KEYS } from './DocConstants';
import { IColumn } from '../../../Models/Table';
import DateFormatService from '../../../Services/DateFormat';
import AuthService from '../../../Services/Auth';
import { INVOICE_NOW_TYPES } from '../../Invoices/InvoiceHelper';
import { NUMBERS } from '../../../Constants/Enum';
import { PAGE_ROUTES } from '../../../Managers/RouteManager';

export const isPurchaseDocument = (documentType: DOC_TYPE) =>
  [DOC_TYPE.BILL, DOC_TYPE.ORDER, DOC_TYPE.JOB_WORK_OUT_ORDER].includes(
    documentType
  );

export const isSalesDocument = (documentType: DOC_TYPE) =>
  [DOC_TYPE.QUOTE, DOC_TYPE.SALES_ORDER, DOC_TYPE.INVOICE].includes(
    documentType
  );

export const isDocumentInDraftMode = (draftType: DraftTypes) =>
  [DraftTypes.NEW, DraftTypes.COPY, DraftTypes.DRAFT].includes(draftType);

export const isPriceListEnabled = (): boolean => {
  const appCustomization =
    AuthService.appCustomization ||
    Store.getState().authInfo?.appCustomization?.data;
  const hiddenApps = appCustomization?.hiddenApps?.split(',');
  return !hiddenApps?.includes('PRICE_LIST');
};

export const isPriceBookEnabled = (): boolean => {
  const tenantInfo =
    AuthService.currentTenantInfo ||
    Store.getState().authInfo?.currentTenantInfo?.data;
  return !!tenantInfo?.isAdvancePriceListEnabled;
};

export const isEditableDraft = (data: {
  draftType: DraftTypes;
  documentType: DOC_TYPE;
  receiveGoodsStatus?: RECEIVED_GOODS_STATUS;
  fulfillmentStatus?: FULFILLMENT_STATUS;
  paymentStatus: PAYMENT_STATUS;
  totalAmount: number;
}) => {
  const {
    draftType,
    documentType,
    receiveGoodsStatus,
    fulfillmentStatus,
    paymentStatus,
    totalAmount
  } = data;

  let canEdit = draftType !== DraftTypes.READONLY;

  if (canEdit && documentType === DOC_TYPE.BILL) {
    canEdit =
      receiveGoodsStatus === RECEIVED_GOODS_STATUS.NOT_RECEIVED &&
      paymentStatus === PAYMENT_STATUS.PENDING;
  } else if (canEdit && documentType === DOC_TYPE.INVOICE) {
    canEdit =
      fulfillmentStatus === FULFILLMENT_STATUS.UNFULFILLED &&
      paymentStatus === PAYMENT_STATUS.PENDING;
  }

  return canEdit;
};
/**
 * @todo create flow for setting dropship contact on mounting a draft doc..
 */
export const isDropShipDocument = ({
  dropShip = false,
  fulfillmentType = FULFILLMENT_TYPE.DEFAULT
}) => {
  return dropShip || fulfillmentType === FULFILLMENT_TYPE.DROP_SHIP;
};

export const getBlankRowItem = (
  document: IDocument,
  columnConfig: IColumn[],
  documentMode: DOCUMENT_MODE,
  draftType: DraftTypes,
  productCustomFields: any[],
  lineNumber?: number
): any => {
  const { additionalSettings } =
    Store.getState().authInfo.currentTenantInfo.data;
  const { documentType, items } = document.populateFormData;
  let newRow = DocumentConfigManager.getBlankRow(documentType);
  newRow[DOC_LINE_ITEM_KEYS.PRODUCT_QUANTITY] = 1;
  newRow[DOC_LINE_ITEM_KEYS.UNIT_PRICE] = 0;
  newRow.invalidFields = DocumentConfigManager.getRequiredFields(documentType);
  if (typeof lineNumber !== 'undefined' && lineNumber !== null) {
    newRow[DOC_LINE_ITEM_KEYS.LINE_NUMBER] = lineNumber;
  } else {
    newRow[DOC_LINE_ITEM_KEYS.LINE_NUMBER] = (items?.length ?? 0) + 1;
  }
  newRow['firstAmountChangeDone'] = false;

  const cascadingDiscountSettings: any =
    additionalSettings?.CASCADING_DISCOUNTS;
  if (cascadingDiscountSettings?.enable) {
    if (documentMode === DOCUMENT_MODE.NEW && draftType === DraftTypes.NEW) {
      const orderedDiscounts = getOrderedCascadingDiscountsFromSettings();
      if (orderedDiscounts?.length) {
        const discountType = cascadingDiscountSettings?.discountType;
        // Set itemDiscountMethod
        newRow[DOC_LINE_ITEM_KEYS.ITEM_DISCOUNT_METHOD] = discountType;
        orderedDiscounts.forEach((discount: any, index: number) => {
          // Need to handle buy/sell discounts
          const discountFromStore = getCascadingDiscountsFromStore(
            document.populateFormData
          )?.find((dis: any) => dis.id === discount.discountId);
          if (Utility.isNotEmpty(discountFromStore)) {
            const accountCode = !Utility.isSalesDocument(
              document.populateFormData
            )
              ? discountFromStore.incomeAccountCode
              : discountFromStore.expenseAccountCode;
            let discountValue = 0;
            if (discountFromStore.isPercent) {
              discountValue = +discountFromStore.percentageValue;
            } else {
              discountValue = +discountFromStore.chargeValue;
            }
            // Set cascading discount
            const discountKeyToUpdate =
              CASCADING_DISCOUNT_PREFIX + `${discountFromStore.id}`;
            const discountDetailsKeyToUpdate =
              CASCADING_DISCOUNT_PREFIX + `${discountFromStore.id}_details`;
            newRow[discountKeyToUpdate] = discountFromStore.isPercent
              ? discountValue + '%'
              : discountValue;
            newRow[discountDetailsKeyToUpdate] = {
              id: discountFromStore.id,
              discount: discountValue,
              isPercent: discountFromStore.isPercent,
              accountCode: accountCode,
              name: discountFromStore.name,
              discountIndex: index,
              unitDiscount: 0
            };
          }
        });
      }
    } else {
      if (!Utility.isEmpty(columnConfig)) {
        const allCascadingDiscountConfigs = columnConfig?.filter(
          (config: any) =>
            config?.key?.toString()?.startsWith(CASCADING_DISCOUNT_PREFIX) &&
            !config?.key?.toString()?.endsWith('_details')
        );
        // get itemDiscountMethod from existing items, or if no items then set it from cascadingDiscountSettings
        const itemWithDiscountMethod = items?.find(
          (item) =>
            typeof item.itemDiscountMethod !== 'undefined' &&
            item.itemDiscountMethod !== null
        );
        newRow['itemDiscountMethod'] = !Utility.isEmpty(itemWithDiscountMethod)
          ? itemWithDiscountMethod?.itemDiscountMethod
          : cascadingDiscountSettings?.discountType;

        allCascadingDiscountConfigs.forEach(
          (discountConfig: any, index: number) => {
            const discountId = discountConfig?.key?.substring(
              discountConfig?.key?.toString()?.lastIndexOf('_') + 1
            );
            let discountFromStore: any = getCascadingDiscountsFromStore(
              document.populateFormData
            )?.find((dis: any) => dis.id === +discountId);
            if (!Utility.isEmpty(discountFromStore)) {
              let accountCode = !isSalesDocument(documentType)
                ? discountFromStore.incomeAccountCode
                : discountFromStore.expenseAccountCode;
              if (
                accountCode !== discountConfig.accountCode &&
                !Utility.isEmpty(discountConfig.accountCode)
              ) {
                accountCode = discountConfig.accountCode;
              }

              let discountValue = 0;
              if (discountConfig.isPercent) {
                discountValue = +discountFromStore.percentageValue;
              } else {
                discountValue = +discountFromStore.chargeValue;
              }
              const discountKeyToUpdate =
                CASCADING_DISCOUNT_PREFIX + `${discountFromStore.id}`;
              const discountDetailsKeyToUpdate =
                CASCADING_DISCOUNT_PREFIX + `${discountFromStore.id}_details`;
              newRow[discountKeyToUpdate] = discountConfig.isPercent
                ? discountValue + '%'
                : discountValue;
              newRow[discountDetailsKeyToUpdate] = {
                id: discountFromStore.id,
                discount: discountValue,
                isPercent: discountConfig.isPercent,
                accountCode: accountCode,
                name: discountConfig.discountName,
                discountIndex: index,
                unitDiscount: 0
              };
            } else {
              // Handle new item addition when a cascading discount has been deleted
              const discountKeyToUpdate =
                CASCADING_DISCOUNT_PREFIX + `${discountId}`;
              const discountDetailsKeyToUpdate =
                CASCADING_DISCOUNT_PREFIX + `${discountId}_details`;
              newRow[discountKeyToUpdate] = discountConfig.isPercent
                ? 0 + '%'
                : 0;
              newRow[discountDetailsKeyToUpdate] = {
                id: +discountId,
                discount: 0,
                isPercent: discountConfig.isPercent,
                accountCode: discountConfig.accountCode,
                name: discountConfig.discountName,
                discountIndex: index,
                unitDiscount: 0
              };
            }
          }
        );
      }
    }
  }
  if (!Utility.isEmpty(productCustomFields)) {
    // Set default values of CFs when new line is added
    productCustomFields?.forEach((item: any) => {
      if (
        item.modules?.includes(MODULES_NAME.PRODUCT) &&
        item.status === STATUS_TYPE.ACTIVE
      ) {
        if (
          typeof item.defaultValue !== 'undefined' &&
          item.defaultValue !== null &&
          item.defaultValue !== ''
        ) {
          if (item.fieldType.toLowerCase() === INPUT_TYPE.DATE.toLowerCase()) {
            newRow[item.id] = DateFormatService.getDateFromStr(
              item.defaultValue,
              BOOKS_DATE_FORMAT['MM/DD/YYYY']
            );
          } else {
            newRow[item.id] = item.defaultValue;
          }
        } else {
          newRow[item.id] = '';
        }
      }
    });
  }
  return newRow;
};

export const getInvoiceNowStatusInfo = (invoiceNowStatus: string) => {
  switch (invoiceNowStatus) {
    case INVOICE_NOW_TYPES.FAILED:
      return {
        title: INVOICE_NOW_TYPES.FAILED,
        color: 'bg-chip-red text-red '
      };
    case INVOICE_NOW_TYPES.GENERATED:
      return {
        title: INVOICE_NOW_TYPES.GENERATED,
        color: 'bg-chip-green text-green'
      };
    case INVOICE_NOW_TYPES.PENDING:
      return {
        title: INVOICE_NOW_TYPES.PENDING,
        color: 'bg-chip-orange text-orange'
      };
    case INVOICE_NOW_TYPES.CANCELLED:
      return {
        title: INVOICE_NOW_TYPES.CANCELLED,
        color: 'bg-chip-red text-red '
      };
  }
};
export const showZipCodeAlert = (
  message: string,
  success: () => void,
  cancel: () => void
): void => {
  const alertButtonConfig = [
    {
      title: 'Cancel',
      className: 'bg-gray2 border-m mr-s',
      onClick: cancel
    },
    {
      title: 'Update',
      className: 'bg-button text-white',
      onClick: success
    }
  ];
  showAlert('Error', message, alertButtonConfig);
};
/**
 *
 * @param {Object} document object to check update
 * @returns {Boolean} weather document can be updated or not
 * @description This function equivalent to checkForCustomField state in NewDocument2
 */
export const canUpdateDocument = ({
  documentType,
  fulfillmentStatus,
  paymentStatus,
  draftType,
  receiveGoodsStatus,
  totalAmount
}: any) => {
  let canUpdate = true;
  if (draftType === DraftTypes.UPDATE) {
    if (documentType === DOC_TYPE.BILL) {
      canUpdate = !(
        receiveGoodsStatus !== RECEIVED_GOODS_STATUS.NOT_RECEIVED ||
        paymentStatus !== PAYMENT_STATUS.PENDING ||
        totalAmount === NUMBERS.ZERO
      );
    }
    if (documentType === DOC_TYPE.INVOICE) {
      canUpdate = !(
        fulfillmentStatus !== FULFILLMENT_STATUS.UNFULFILLED ||
        paymentStatus !== PAYMENT_STATUS.PENDING ||
        totalAmount === NUMBERS.ZERO
      );
    }
  }
  return canUpdate;
};

/**
 * For new document update
 * Replace doc URL with module URL
 * @param history
 */
export const docReplaceURLToModuleURL = (history: any) => {
  if (DOC_PATH_WITH_ID_REGEX.test(history.location?.pathname)) {
    if (history.location?.pathname?.includes(PAGE_ROUTES.INVOICES)) {
      history.replace(PAGE_ROUTES.INVOICES);
    }
    if (history.location?.pathname?.includes(PAGE_ROUTES.QUOTES)) {
      history.replace(PAGE_ROUTES.QUOTES);
    }
    if (history.location?.pathname?.includes(PAGE_ROUTES.SALES_ORDERS)) {
      history.replace(PAGE_ROUTES.SALES_ORDERS);
    }
    if (history.location?.pathname?.includes(PAGE_ROUTES.BILLS)) {
      history.replace(PAGE_ROUTES.BILLS);
    }
    if (history.location?.pathname?.includes(PAGE_ROUTES.ORDERS)) {
      history.replace(PAGE_ROUTES.ORDERS);
    }
  }
};
