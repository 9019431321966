import ApiConstants from '../Constants/ApiConstants';
import { BOOKS_DATE_FORMAT, DOC_TYPE } from '../Constants/Constant';
import { GateEntryItem, ISecurityGateEntry } from '../Models/SecurityGateEntry';
import DateFormatService from './DateFormat';
import httpClient from './Interceptor';
import Utility from '../Utility/Utility';
import { isBefore } from 'date-fns';
import { Store } from '../Redux/Store';
import { INPUT_TYPE } from 'deskera-ui-library';
export const fetchSecurityGateEntries = (data: any): Promise<any> => {
  return httpClient.get(ApiConstants.URL.SECURITY_GATE_ENTRY.FETCH, {
    params: data
  });
};
export const createGateEntry = (payload: ISecurityGateEntry) => {
  return httpClient.post(ApiConstants.URL.SECURITY_GATE_ENTRY.CREATE, payload);
};
export const updateGateEntry = (payload: ISecurityGateEntry) => {
  return httpClient.put(
    ApiConstants.URL.SECURITY_GATE_ENTRY.UPDATE(payload.id as any),
    payload
  );
};
export const getGateEntryByCode = (code: any) => {
  return httpClient.get(ApiConstants.URL.SECURITY_GATE_ENTRY.UPDATE(code));
};
export const deleteGateEntry = (gateEntryCode: any) => {
  return httpClient.delete(
    ApiConstants.URL.SECURITY_GATE_ENTRY.DELETE(gateEntryCode)
  );
};
export const getBlackGateEntryItem = (): GateEntryItem | any => {
  return {
    productCode: '',
    product: {},
    quantity: 0,
    uomQuantity: 0,
    warehouseCode: '',
    documentUom: null,
    documentUOMSchemaDefinition: null,
    approverId: null,
    invalidFields: ['product']
  };
};
export const convertDocItemsToGateEntryItem = (
  document: any
): GateEntryItem[] => {
  let items = [];
  const gateEntryItems: GateEntryItem[] = [];
  switch (document.docType) {
    case DOC_TYPE.ORDER:
      items = document.purchaseOrderItems;
      break;
    case DOC_TYPE.RFQ:
      break;
    case DOC_TYPE.BILL:
      items = document.purchaseInvoiceProducts;
      break;
    default:
      break;
  }
  items?.forEach((item: any) => {
    gateEntryItems.push({
      productCode: item.productCode,
      approverId: item?.approverId,
      documentUom: item.documentUom,
      documentUOMSchemaDefinition: item?.documentUOMSchemaDefinition,
      quantity: item?.documentUOMSchemaDefinition
        ? item.uomQuantity
        : item.productQuantity || 0,
      uomQuantity: item.uomQuantity || 0,
      product: item.product,
      productDescription: item.productDescription,
      tax: item?.tax,
      taxAmount: item?.taxAmount,
      taxCode: item?.taxCode,
      taxDetails: item?.taxDetails,
      unitPrice: item.unitPrice,
      rowContextMenu: [],
      approver: null,
      rowButtons: []
    });
  });

  return gateEntryItems;
};
export const preparePayload = (gateEntry: any, isEdit = false): any => {
  const getLinkedDocumentPayload = () => {
    const linkedDocument: any = {};
    if (!gateEntry.referenceDocument) {
      return null;
    } else {
      linkedDocument.documentSequenceCode =
        gateEntry.referenceDocument?.documentSequenceCode;
      linkedDocument.supplierInvoiceNo =
        gateEntry?.referenceDocument?.supplierInvoiceNo;

      if (gateEntry.referenceDocumentType === DOC_TYPE.ORDER) {
        let documentCreatedDate = DateFormatService.getDateFromStr(
          gateEntry.referenceDocument?.documentDate,
          BOOKS_DATE_FORMAT['DD-MM-YYYY']
        );
        linkedDocument.documentCode = gateEntry.referenceDocument?.poCode;
        linkedDocument.documentCreatedDate =
          DateFormatService.getDateStrFromDate(
            documentCreatedDate,
            BOOKS_DATE_FORMAT['YYYY-MM-DD']
          );
        linkedDocument.documentType = DOC_TYPE.ORDER;
        linkedDocument.documentReceiveByDate =
          gateEntry?.referenceDocument?.receiveByDate;
      }
      if (gateEntry.referenceDocumentType === DOC_TYPE.RFQ) {
      }
      if (gateEntry.referenceDocumentType === DOC_TYPE.BILL) {
        let documentCreatedDate = null;
        try {
          documentCreatedDate = DateFormatService.getDateFromStr(
            gateEntry.referenceDocument?.purchaseInvoiceDate,
            BOOKS_DATE_FORMAT.DATE_FORMAT_MEDIUM
          );
          documentCreatedDate = DateFormatService.getDateStrFromDate(
            documentCreatedDate,
            BOOKS_DATE_FORMAT['YYYY-MM-DD']
          );
        } catch (error) {}

        linkedDocument.documentCode =
          gateEntry.referenceDocument?.purchaseInvoiceCode;
        linkedDocument.documentCreatedDate = documentCreatedDate;
        linkedDocument.documentType = DOC_TYPE.BILL;
        linkedDocument.documentReceiveByDate =
          gateEntry.referenceDocument?.receiveByDate;
      }
    }
    return [linkedDocument];
  };
  const {
    challanNumber,
    contactNumber,
    customField,
    distanceKm,
    ewayBillNumber,
    driverName,
    freightType,
    fromAddress,
    gateEntryItems,
    licenseNumber,
    lrNumber,
    referenceNumber,
    remarks,
    toAddress,
    transporter,
    vehicleNumber,
    vehicleType,
    vehicleCapacity,
    weighbridgeSlipNumber,
    gateEntryCode,
    id,
    weighbridgeName,
    vendorInfo
  } = gateEntry;
  const arrivalDateTimeInUTC =
    new Date(gateEntry.arrivalTime).getTime() - new Date().getTimezoneOffset();

  const departureTimeInUTC =
    new Date(gateEntry.departureTime).getTime() -
    new Date().getTimezoneOffset();

  const payload: any = {
    challanNumber,
    contactNumber,
    customField,
    distanceKm,
    ewayBillNumber,
    driverName,
    freightType,
    fromAddress,
    gateEntryItems,
    licenseNumber,
    lrNumber,
    referenceNumber,
    remarks,
    toAddress,
    transporter,
    vehicleNumber,
    vehicleType,
    vehicleCapacity,
    weighbridgeSlipNumber,
    gateEntryCode,
    id,
    weighbridgeName,
    vendorInfo,
    vendorCode: gateEntry?.vendorInfo?.code,
    challanDate: DateFormatService.getDateStrFromDate(
      gateEntry.challanDate,
      BOOKS_DATE_FORMAT['DD-MM-YYYY']
    ),
    referenceDate: DateFormatService.getDateStrFromDate(
      gateEntry.referenceDate,
      BOOKS_DATE_FORMAT['DD-MM-YYYY']
    ),
    lrDate: DateFormatService.getDateStrFromDate(
      gateEntry.lrDate,
      BOOKS_DATE_FORMAT['DD-MM-YYYY']
    ),
    weighbridgeDate: DateFormatService.getDateStrFromDate(
      gateEntry.weighbridgeDate,
      BOOKS_DATE_FORMAT['DD-MM-YYYY']
    ),
    weighbridgeSlipDate: DateFormatService.getDateStrFromDate(
      gateEntry.weighbridgeSlipDate,
      BOOKS_DATE_FORMAT['DD-MM-YYYY']
    ),
    arrivalTime: DateFormatService.getDateStrFromDate(
      new Date(arrivalDateTimeInUTC),
      BOOKS_DATE_FORMAT['ISO-FORMAT']
    ),
    departureTime: DateFormatService.getDateStrFromDate(
      new Date(departureTimeInUTC),
      BOOKS_DATE_FORMAT['ISO-FORMAT']
    ),
    sequenceFormat: gateEntry?.sequenceFormat?.id,
    documentSequenceCode: gateEntry?.sequenceFormat?.text,
    linkedDocuments: isEdit
      ? gateEntry.linkedDocuments
      : getLinkedDocumentPayload(),
    warehouseCode: gateEntry?.warehouse?.code,
    warehouseName: gateEntry?.warehouse?.name,
    attachments: gateEntry.attachmentIds?.length
      ? gateEntry.attachmentIds.map((attachmentId: any) => `${attachmentId}`)
      : [],
    attachmentIds: gateEntry.attachmentIds?.length
      ? gateEntry.attachmentIds.map((attachmentId: any) => attachmentId)
      : []
  };
  return payload;
};

export const convertResponseToFormData = (
  data: ISecurityGateEntry | { [key: string]: any }
): ISecurityGateEntry | { [key: string]: any } => {
  data.challanDate = Utility.isNotEmpty(data.challanDate)
    ? DateFormatService.getDateFromStr(
        data.challanDate,
        BOOKS_DATE_FORMAT['DD-MM-YYYY']
      )
    : new Date();

  data.referenceDate = Utility.isNotEmpty(data.referenceDate)
    ? DateFormatService.getDateFromStr(
        data.referenceDate,
        BOOKS_DATE_FORMAT['DD-MM-YYYY']
      )
    : new Date();
  data.weighbridgeDate = Utility.isNotEmpty(data.weighbridgeDate)
    ? DateFormatService.getDateFromStr(
        data.weighbridgeDate,
        BOOKS_DATE_FORMAT['DD-MM-YYYY']
      )
    : new Date();

  data.ewayDate = Utility.isNotEmpty(data.ewayDate)
    ? DateFormatService.getDateFromStr(
        data.ewayDate,
        BOOKS_DATE_FORMAT['DD-MM-YYYY']
      )
    : new Date();

  data.lrDate = Utility.isNotEmpty(data.lrDate)
    ? DateFormatService.getDateFromStr(
        data.lrDate,
        BOOKS_DATE_FORMAT['DD-MM-YYYY']
      )
    : new Date();

  data.weighbridgeSlipDate = Utility.isNotEmpty(data.weighbridgeSlipDate)
    ? DateFormatService.getDateFromStr(
        data.weighbridgeSlipDate,
        BOOKS_DATE_FORMAT['DD-MM-YYYY']
      )
    : new Date();

  data.arrivalTime = Utility.isNotEmpty(data.arrivalTime)
    ? DateFormatService.getDateFromStr(
        data.arrivalTime,
        BOOKS_DATE_FORMAT['ISO-FORMAT']
      )
    : new Date();

  data.departureTime = Utility.isNotEmpty(data.departureTime)
    ? DateFormatService.getDateFromStr(
        data.departureTime,
        BOOKS_DATE_FORMAT['ISO-FORMAT']
      )
    : new Date();
  data.referenceDocumentType =
    data?.linkedDocuments?.[0]?.documentType || 'NONE';
  data.referenceDocument = data?.linkedDocuments?.[0];
  if ((data as any)?.warehouseName && (data as any)?.warehouseCode) {
    data.warehouse = {
      name: (data as any)?.warehouseName,
      code: (data as any)?.warehouseCode
    };
  }
  return data;
};
export const validateArrivalDate = (gateEntry: ISecurityGateEntry) => {
  let bookBeginningStartDate = new Date();
  try {
    bookBeginningStartDate = DateFormatService.getDateFromStr(
      Store.getState().authInfo?.currentTenantInfo?.data
        ?.bookBeginningStartDate,
      BOOKS_DATE_FORMAT['YYYY-MM-DD']
    );
  } catch (error) {}
  return isBefore(gateEntry.arrivalTime, bookBeginningStartDate);
};
export const validateDepartureDate = (gateEntry: ISecurityGateEntry) => {
  return isBefore(gateEntry.departureTime, gateEntry.arrivalTime);
};

export const linkSecurityGateCol = {
  name: 'Linked Security Gate No',
  columnCode: 'linkedSecurityGate',
  key: 'linkedSecurityGate',
  id: 'linkedSecurityGate',
  type: INPUT_TYPE.TEXT,
  editable: false,
  allowColumnSort: false,
  systemField: true,
  allowFilter: false,
  index: 0,
  width: 200
};
