import React, { Component } from "react";
import "../../index.css";
import "./css/Templates.css";
import ComponentManager from "../../Manager/ComponentManager";
import { EditorInfoType, FONT_SIZE, PAGE_PADDING } from '../../Constants/Constants';
import DocumentManager from "../../Manager/DocumentManager";
import Utility, { getFontSizeClass, getPageHeight, getVW, getIsFulfillment, getIsGoodsReceipt, getIsJournalEntry, getIsProductDocumentForBuySell, getCapitalized, getIsOrder, getIsBill } from "../../Utilities/Utility";
import { getLocalisedText } from '../../Translate/LanguageManager';
import DKEInvoiceSection, { eInvoiceSectionPosition } from "../../DKUILibrary/DKEInvoiceSection";
import DKCustomFieldSection from "../../DKUILibrary/DKCustomFieldSection";
import DKSignatureSection from "../../DKUILibrary/DKSignatureSection";
import { AddressType } from "../../Helper/AddressParser";
import { HTMLTag } from "../../Utilities/HTMLTag";
import DKIndiaTaxBreakdown from "../../DKUILibrary/DKIndiaTaxBreakdown";
import TopSectionAboveTableUtility from "../../Utilities/TopSectionAboveTableUtility";
import DKComponentListTable from "../../DKUILibrary/DKComponentListTable";
import DKCustomApprovalSection from "../../DKUILibrary/DKCustomApprovalSection";
import TemplateSettingsManager from "../../Manager/TemplateSettingsManager";

export default class IndiaTemplate11 extends Component {//based on template 51 - India Template 10 
    spacingRatio = 0.25

    constructor(props) {
        super(props);
        this.state = {
            data: this.props.data,
        };
    }

    componentWillReceiveProps(nextProp) {
        this.setState({
            data: nextProp.data,
        });
    }

    render() {
        return (
            <div className='DocumentHolder ColumnDiv' 
                style={{
                    justifyContent: 'space-between',
                    minHeight: getPageHeight(this.state.data.printScale),
            }}>
                {this.getCompanySection()}
                {this.state.data.showEInvoiceSection && this.getEInvoiceDetailsSection()}
                <div className='ColumnDiv'
                    style={{
                        border: 'black solid 1px'
                    }}>
                    <div className='parent-width' style={{ width: '100%' }}>
                        <table style={{
                            width: '100%',
                            borderCollapse: 'collapse',
                            margin: 'auto'
                        }}>
                            <tbody>
                                <tr>
                                    <td colSpan={2}
                                        style={{  width: '100%', verticalAlign: 'top'  }}>
                                        {this.getCompanyTaxDetailsSection()}
                                    </td>
                                </tr>
                                {this.getDocumentDetailsSection()}
                                {Utility.getIsShowPlaceOfSupply(this.state.data) && this.getPlaceOfSupplyInfoSection()}
                                {(Utility.getIsShowSourceOfSupply(this.state.data) || Utility.getIsShowDestinationOfSupply(this.state.data)) && this.getSourceOfSupplyInfoSection()}
                                {this.getBillToShipToSection()}
                                {this.getEwayBillDetailsSection()}
                            </tbody>
                        </table>
                        {/* section 2 */}
                        {/* {(Utility.getIsShowSourceOfSupply(this.state.data) || Utility.getIsShowDestinationOfSupply(this.state.data) || Utility.getIsShowPlaceOfSupply(this.state.data)) && ComponentManager.addVerticalSpace(20)} */}
                        {/* {Utility.getIsContainsCustomField('top') && ComponentManager.addVerticalSpace(10)} */}
                        <DKCustomFieldSection data={this.state.data} onCustomFieldClick={this.props.onCustomFieldClick} style={this.getCustomFieldTableStyle()} customFieldPosition={'top'} />
                        {this.getChildCustomFieldsSection()}
                        
                        {/* {ComponentManager.addVerticalSpace(20)} */}
                        {
                            TopSectionAboveTableUtility.getIsTopSectionAboveTableIsVisible() &&
                            <>
                                <div style={{ ...TopSectionAboveTableUtility.getTopSectionWithTableFontStyleProp(undefined, this.state.data.printScale) }}
                                    dangerouslySetInnerHTML={{ __html: TopSectionAboveTableUtility.getHtmlString(this.state.data) }} />
                                {ComponentManager.addVerticalSpace(5 * this.spacingRatio)}
                            </>
                        }
                        {this.getProductTable()}
                        {Utility.getIsShowAccountProductTable(this.state.data) && ComponentManager.addVerticalSpace(30 * this.spacingRatio)}
                        {this.getEditableDocumentTable()}
                        {Utility.getIsShowExchangeRate(this.state.data) && ComponentManager.addVerticalSpace(15 * this.spacingRatio)}
                        {Utility.getIsShowExchangeRate(this.state.data) && this.getExchangeRateTable(this.getTableStyle(), this.state.data)}
                        {/* {Utility.getIsContainsCustomField('bottom') && ComponentManager.addVerticalSpace(10)} */}
                        <DKCustomFieldSection data={this.state.data} onCustomFieldClick={this.props.onCustomFieldClick} style={this.getCustomFieldTableStyle()} customFieldPosition={'bottom'} />
                        {ComponentManager.addVerticalSpace(15 * this.spacingRatio)}
                        {getIsProductDocumentForBuySell(this.state.data) &&
                            Utility.getIsShowHsnSacTaxTable() &&
                            <DKIndiaTaxBreakdown
                                data={this.state.data}
                            />
                        }
                        {Utility.getIsIncludesBomProductTable() && <DKComponentListTable data={this.state.data} />}
                        {this.getTermsAndConditionOrNotesSection()}
                    </div>
                    <div className='parent-width' style={{ width: '100%' }}>
                        {(getIsFulfillment(this.state.data.type) || getIsGoodsReceipt(this.state.data.type)) && <DKSignatureSection data={this.state.data} onSignatureSectionClicked={this.props.tableClicked} />}
                        {Utility.getIsShowCustomApprovalSection() && <DKCustomApprovalSection data={this.state.data} style={this.getTableStyle()} isThemeColor />}
                    </div>
                    {Utility.getGeneratedVisibility() && this.getGenerateDocumentSection()}
                </div>
            </div>
        
        );
    }

    getCompanySection() {
        return <div className="RowDiv"
            style={{
                justifyContent: 'center'
            }}>
            <div className="ColumnDiv" style={{ alignItems: 'left', flex: 1 }}>
                {ComponentManager.getLogoVisibility(this.state.data.showCompanyLogo, this.state.data.isReadOnlyMode, this.state.data.logo) && ComponentManager.getLogo(this.state.data.logo, Utility.convertToPrintScale(80, this.state.data.printScale), { position: 'relative' })}
                {ComponentManager.addVerticalSpace(10 * this.spacingRatio)}
            </div>
            <div className="ColumnDiv" style={{ alignItems: 'center', flex: 2 }}>
                <div className="ColumnDiv" style={{ justifyContent: "center", alignItems: "flex-end", alignSelf: 'baseline', width: '100%' }}>
                    {this.state.data.showCompanyName && <text className={"docFontStyleBold" + getFontSizeClass()}
                        style={{
                            textAlign: 'center',
                            alignSelf: 'center',
                            ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.xMedium },this.state.data.printScale)
                        }}>
                        {getLocalisedText(Utility.getCompanyName(this.state.data))}
                    </text>}
                    {this.state.data.showCompanyName && ComponentManager.addVerticalSpace(5 * this.spacingRatio)}
                    {this.state.data.showFrom && <div className={"docFontStyle" + getFontSizeClass()}
                        style={{
                            color: '#000000',
                            textAlign: 'center',
                            width: '100%',
                            ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular, ownerType: Utility.getOwnerType(this.state.data, AddressType.from) },this.state.data.printScale)
                        }}
                        dangerouslySetInnerHTML={{ __html: getLocalisedText(Utility.getCompanyAddress(this.state.data)) }}>
                    </div>}
                    <div className={"docFontStyle" + getFontSizeClass()}
                        style={{
                            width: '100%',
                            marginTop: 0,
                            color: '#000000',
                            textAlign: 'center',
                            ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular, ownerType: undefined }, this.state.data.printScale)
                        }}
                        dangerouslySetInnerHTML={{ __html: getLocalisedText((getIsOrder(this.state.data.type) || getIsBill(this.state.data.type)) ? this.state.data.billOrderCompanyStateNameCode : this.state.data.companyStateNameAndCode,) }}>
                    </div>
                </div>
                <text className={"docFontStyleBold" + getFontSizeClass()}
                    style={{
                        ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.xLarge }, this.state.data.printScale)
                    }}>
                    {getCapitalized(getLocalisedText(this.state.data.documentType))}
                </text>
            </div>
            <div className="ColumnDiv" style={{ alignItems: 'left', flex: 1, padding: getVW(20 * this.spacingRatio) }}>
                <DKEInvoiceSection
                    data={this.state.data}
                    position={eInvoiceSectionPosition.fixed}
                    showCancelledEInvoice
                />
                {this.getEInvoiceStatusSection()}
            </div>
        </div>
    }

    getEInvoiceDetailsSection() {
        let irn = this.state.data.irn ?? ''
        let ackNo = this.state.data.ackNo ?? ''
        let ackDt = this.state.data.ackDt ?? ''

        if(irn === '' && ackNo === '' & ackDt === '') {
            return <></>
        }
        return <div className="RowDiv"
            style={{
                borderTop: '1px solid black',
                justifyContent: 'center',
                paddingTop: getVW(20 * this.spacingRatio),
                paddingBottom: getVW(20 * this.spacingRatio),
                ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.small }, this.state.data.printScale)
            }}>
            <div className="RowDiv" style={{ alignItems: 'left'}}>
                IRN:
                <b>
                    {irn}
                </b>
            </div>
            <div className="RowDiv" style={{ alignItems: 'center', paddingLeft: getVW(60 * this.spacingRatio) }}>
                Ack No:
                <b>
                    {ackNo}
                </b>
            </div>
            <div className="RowDiv" style={{ justifyContent: 'flex-end', paddingLeft: getVW(20 * this.spacingRatio) }}>
                Ack Date:
                <b>
                    {ackDt}
                </b>
            </div>
        </div>
    }

    getEInvoiceStatusSection() {
        let isCancelled = false
        let status = this.state.data.eInvoiceStatus ?? ''
        if (status !== '') {
            if (status === 'CANCELLED') {
                isCancelled = true
            }
        }

        if(isCancelled) {
            return <div className={"docFontStyle" + getFontSizeClass()}
                style={{
                    width: '100%',
                    marginTop: 0,
                    color: '#000000',
                    textAlign: 'center',
                    ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.small, ownerType: undefined }, this.state.data.printScale)
                }}>
                CANCELLED
            </div>
        }

        return <></>
    }

    getCompanyTaxDetailsSection() {
        const taxDetailsList = [
            { label: 'Plant Status', key: 'state' },
            { label: 'GSTIN No.', key: 'gstin' },
            { label: 'PAN No.', key: 'panIndia' },
            { label: 'CIN No.', key: 'cinIndia' },
        ]
        let taxes = taxDetailsList.map(element => {
            return <div className="RowDiv" style={{ alignItems: 'left' }}>
                <text className={"docFontStyle" + getFontSizeClass()}
                    style={{
                        ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular }, this.state.data.printScale)
                    }}>
                    {element.label}
                </text>:
                &nbsp;
                <text className={"docFontStyleBold" + getFontSizeClass()}
                    style={{
                        ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular }, this.state.data.printScale)
                    }}>
                    {this.state.data.tenantInfo[element.key] ?? ''} 
                </text>
            </div>
        });

        return <div className="RowDiv"
            style={{
                justifyContent: 'center',
                paddingTop: getVW(10 * this.spacingRatio),
                paddingBottom: getVW(10 * this.spacingRatio),
                ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular }, this.state.data.printScale)
            }}>
            {taxes}
        </div>
    }

    getDocumentType() {
        return <div className="RowDiv pl-s pt-s pb-s parent-width"
            style={{
                alignItems: 'center',
                flexDirection: Utility.getIsArabicLang() ? 'row-reverse' : 'row',
                width: '100%',
            }}>
            <text className={"docFontStyleBold" + getFontSizeClass()}
                style={{
                    ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.large },this.state.data.printScale)
                }}>
                {getLocalisedText(this.state.data.documentType)}
            </text>
        </div>;
    }

    getLeftPanelSection() {
        return <di className="RowDiv">
            <div className="ColumnDiv" style={{ width: '100%', paddingTop: getVW(10 * this.spacingRatio), paddingBottom: getVW(10 * this.spacingRatio) }}>
                <div className="RowDiv parent-width" style={{
                    paddingLeft: getVW(10 * this.spacingRatio),
                }}>
                    {ComponentManager.getLogoVisibility(this.state.data.showCompanyLogo, this.state.data.isReadOnlyMode, this.state.data.logo) && ComponentManager.getLogo(this.state.data.logo, Utility.convertToPrintScale(80, this.state.data.printScale), { position: 'relative' })}
                    {this.state.data.showCompanyLogo && ComponentManager.addHorizontalSpace(10)}
                    {this.state.data.showCompanyName && <div className="ColumnDiv parent-width">
                        {ComponentManager.getDescriptionSection(
                            getLocalisedText(Utility.getCompanyName(this.state.data)),
                            getLocalisedText(Utility.getCompanyAddress(this.state.data)),
                            "100%",
                            this.state.data.themeColor,
                            false,
                            '#000000',
                            getVW(0),
                            Utility.getOwnerType(this.state.data, AddressType.from),
                            this.state.data.printScale
                        )}
                    </div>}
                </div>
                {(this.state.data.showFrom && this.state.data.showClientBillToAddress) && ComponentManager.addVerticalSpace(10 * this.spacingRatio)}
                {(this.state.data.showClientBillToAddress)
                    && <div className="RowDiv"
                        style={{
                            width: '-webkit-fill-available',
                            borderTop: '1px solid',
                            borderBottom: '1px solid',
                            paddingTop: getVW(5 * this.spacingRatio),
                            paddingBottom: getVW(5 * this.spacingRatio),
                        }}>
                        <div className="ColumnDiv parent-width">
                            {ComponentManager.getDescriptionSection(
                                this.state.data.billToTitle,
                                Utility.getClientBillToAddress(this.state.data),
                                "100%",
                                this.state.data.themeColor,
                                false,
                                '#000000',
                                getVW(0),
                                Utility.getOwnerType(this.state.data, AddressType.billTo),
                                this.state.data.printScale
                            )}
                            {ComponentManager.getDescriptionSection(
                                "",
                                this.state.data.billToAddressStateCode,
                                "100%",
                                this.state.data.themeColor,
                                false,
                                undefined,
                                0,
                                undefined,
                                this.state.data.printScale
                            )}
                        </div>
                    </div>}
                {(this.state.data.showClientBillToAddress && this.state.data.showClientShipToAddress) && ComponentManager.addVerticalSpace(10 * this.spacingRatio)}
                {this.state.data.showClientShipToAddress && <div className="ColumnDiv parent-width">
                    {ComponentManager.getDescriptionSection(
                        this.state.data.shipToTitle,
                        Utility.getClientShipToAddress(this.state.data),
                        "100%",
                        this.state.data.themeColor,
                        false,
                        '#000000',
                        getVW(0),
                        Utility.getOwnerType(this.state.data, AddressType.shipTo),
                        this.state.data.printScale
                    )}
                    {ComponentManager.getDescriptionSection(
                        "",
                        this.state.data.shipToAddressStateCode,
                        "100%",
                        this.state.data.themeColor,
                        false,
                        undefined,
                        0,
                        undefined,
                        this.state.data.printScale
                    )}
                </div>}
            </div>
        </di>
    }

    getDocumentDetailsSection() {
        const documentDetailList = Utility.getDocumentDetailsList(this.state.data)
        const selectedDetailList = documentDetailList.filter(x => x.isSelected).sort((a, b) => a.index - b.index)

        let leftDetailsList = []
        let rightDetailsList = []

        if(selectedDetailList.length === 0) {
            return <></>
        }
        const maxLeftCount = selectedDetailList.length === 1 ? 1 : 2
        if (selectedDetailList.length > 0) {
            leftDetailsList = maxLeftCount === 1 ? [selectedDetailList[0]] : [selectedDetailList[0], selectedDetailList[1]]

            if (selectedDetailList.length > maxLeftCount) {
                rightDetailsList = selectedDetailList.splice(maxLeftCount)
            }
        }

        return this.getTableColumnTd(this.getDocumentDetailsList(leftDetailsList), this.getDocumentDetailsList(rightDetailsList))

    }

    getDocumentDetailsValue(element) {
        var value = ''
        if (element.key !== undefined) {
            value = this.state.data[element.key]
        }
        else if (element.customFieldValue !== undefined) {
            value = element.customFieldValue
        }

        return value
    }

    getDocumentDetailsList(items) {
        var leftColumnData = []
        var rightColumnData = []

        items.forEach((element, index) => {
            if (index % 2 === 0) {
                leftColumnData.push(element)
            }
            else {
                rightColumnData.push(element)
            }
        });
        
        var documentDetailsMapping = []
        for (let index = 0; index < leftColumnData.length; index++) {
            const left = leftColumnData[index]
            if (rightColumnData.length > index) {
                let right = rightColumnData[index]
                documentDetailsMapping.push({
                    left: left,
                    right: right
                })
            }
            else {
                documentDetailsMapping.push({
                    left: left,
                    right: undefined
                })
            }
        }

        return documentDetailsMapping.map(element => {
            return this.getLeftAndRightDetailsSection(element.left, element.right)
        });
    }

    getRowValueSection(title, value) {
        // const paddingHorizontal = getVW(Utility.convertToPrintScale(10, this.state.data.printScale))
        return <div className="RowDiv"
            style={{
                paddingTop: getVW(Utility.convertToPrintScale(2, this.state.data.printScale)),
                paddingBottom: getVW(Utility.convertToPrintScale(2, this.state.data.printScale)),
                flex: 1,
                // paddingLeft: paddingHorizontal,
                // paddingRight: paddingHorizontal,
                width: '-webkit-fill-available'
            }}>
            <text className={"docFontStyle" + getFontSizeClass()}
                style={{
                    color: this.state.data.themeColor,
                    ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular }, this.state.data.printScale)
                }}>
                {getLocalisedText(title)}
            </text>
            <text className={"docFontStyle" + getFontSizeClass()}
                style={{
                    color: this.state.data.themeColor,
                    ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular }, this.state.data.printScale)
                }}>
                :
            </text>
            &nbsp;
            <text className={"docFontStyleBold" + getFontSizeClass()}
                style={{
                    ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular }, this.state.data.printScale)
                }}>
                {value}
            </text>
        </div>
    }

    getLeftAndRightDetailsSection(left, right) {
        return <div className="RowDiv">
            {left ?
                this.getRowValueSection(
                    left.label,
                    this.getDocumentDetailsValue(left)
                ) : <></>
            }
            {(left && right) ?
                ComponentManager.addHorizontalSpace(Utility.convertToPrintScale(20, this.state.data.printScale))
            : <></>}
            {right ?
                this.getRowValueSection(
                    right.label,
                    this.getDocumentDetailsValue(right)
                ) : <></>
            }
            {(right) ?
                ComponentManager.addHorizontalSpace(Utility.convertToPrintScale(20, this.state.data.printScale))
                : <></>}
        </div>
    }


    getPlaceOfSupplyInfoSection() {
        // const reverseCharge = this.getRowValueSection('Reverse Charges', 'Not Applicable')
        const placeOfSupply = this.getRowValueSection(getLocalisedText('place_of_supply'), this.state.data.placeOfSupply)

        return this.getTableColumnTd(placeOfSupply, <></>)
    }

    getSourceOfSupplyInfoSection() {
        const sourceOfSupply = Utility.getIsShowSourceOfSupply(this.state.data) && this.getRowValueSection(getLocalisedText('source_of_supply'), this.state.data.sourceOfSupply)
        const destinationOfSupply = Utility.getIsShowDestinationOfSupply(this.state.data) && this.getRowValueSection(getLocalisedText('destination_of_supply'), this.state.data.destinationOfSupply)
        
        return this.getTableColumnTd(sourceOfSupply, destinationOfSupply)
    }

    getBillToShipToSection() {
        return <tr>
            <td style={{ border: '1px solid', width: '50%', verticalAlign: 'top' }}>
                {(this.state.data.showClientBillToAddress)
                    && <div className="RowDiv"
                        style={{
                            width: '-webkit-fill-available',
                            // borderTop: '1px solid',
                            // borderBottom: '1px solid',
                            paddingTop: getVW(5 * this.spacingRatio),
                            paddingBottom: getVW(5 * this.spacingRatio),
                        }}>
                        <div className="ColumnDiv parent-width">
                            {ComponentManager.getDescriptionSection(
                                this.state.data.billToTitle,
                                Utility.getClientBillToAddress(this.state.data),
                                "100%",
                                this.state.data.themeColor,
                                false,
                                '#000000',
                                getVW(0),
                                Utility.getOwnerType(this.state.data, AddressType.billTo),
                                this.state.data.printScale
                            )}
                            {ComponentManager.getDescriptionSection(
                                "",
                                this.state.data.billToAddressStateCode,
                                "100%",
                                this.state.data.themeColor,
                                false,
                                undefined,
                                0,
                                undefined,
                                this.state.data.printScale
                            )}
                        </div>
                    </div>}
            </td>
            <td style={{ border: '1px solid', width: '50%', verticalAlign: 'top' }}>
                {this.state.data.showClientShipToAddress && <div className="ColumnDiv parent-width">
                    {ComponentManager.getDescriptionSection(
                        this.state.data.shipToTitle,
                        Utility.getClientShipToAddress(this.state.data),
                        "100%",
                        this.state.data.themeColor,
                        false,
                        '#000000',
                        getVW(0),
                        Utility.getOwnerType(this.state.data, AddressType.shipTo),
                        this.state.data.printScale
                    )}
                    {ComponentManager.getDescriptionSection(
                        "",
                        this.state.data.shipToAddressStateCode,
                        "100%",
                        this.state.data.themeColor,
                        false,
                        undefined,
                        0,
                        undefined,
                        this.state.data.printScale
                    )}
                </div>}
            </td>
        </tr>
    }

    getEwayBillDetailsSection() {
        const left = [
            { label: getLocalisedText('delivery_order_doc_and_date'), key: 'deliveryOrderDocNoAndDate'},
            { label: getLocalisedText('e_way_bill_doc_and_date'), key: 'ewayBillNoAndDate' },
        ]

        const right = [
            // { label: getLocalisedText('party_order_doc_and_date'), key: 'customerOrderNumber' },
            { label: getLocalisedText('mode_of_transport'), key: 'modeOfTransport' },
        ]

        const leftSection = left.map(element => {
            const value = this.state.data[element.key] ?? ''
            return this.getRowValueSection(element.label, value)
        });

        const rightSection = right.map(element => {
            const value = this.state.data[element.key] ?? ''
            return this.getRowValueSection(element.label, value)
        });

        return this.getTableColumnTd(leftSection, rightSection)
    }

    getChildCustomFieldsSection() {
        let childCF = <DKCustomFieldSection data={this.state.data} onCustomFieldClick={this.props.onCustomFieldClick} style={this.getCustomFieldTableStyle()} customFieldPosition={undefined} childCustomField />
        if (childCF !== <></>) {
            return <div className="RowDiv parent-width" style={{ borderTop: '1px solid black' }}>
                {childCF}
            </div>
        }

    }

    getNotesValue() {
        let notes = ''
        if (TemplateSettingsManager.getRemoteTemplateUID() === undefined || TemplateSettingsManager.getRemoteTemplateUID() === null) {
            notes = 'notes_placeholder_text'
        }
        if (TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails.notes.text !== 'notes_placeholder_text') {
            notes = TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails.notes.text
        }
        return getLocalisedText(notes)
    }

    getTermsAndConditionOrNotesSection() {
        const termsAndConditionSection = (this.state.data.showTermsAndCondition && this.state.data.termsAndCondition ) ? 
            <div className="RowDiv"
                style={{
                    width: '-webkit-fill-available',
                    paddingTop: getVW(5 * this.spacingRatio),
                    paddingBottom: getVW(5 * this.spacingRatio),
                }}>
                {ComponentManager.getTermsAndConditionOrNotesSection(
                    Utility.getTermsAndConditionTitle(getLocalisedText('terms_&_condition')),
                    getLocalisedText(this.state.data.termsAndCondition),
                    "100%",
                    this.state.data.themeColor,
                    undefined,
                    undefined,
                    undefined,
                    this.state.data.printScale,
                    Utility.getTnCOrNotesFontSettingsByType(EditorInfoType.termsAndCondition)
                )}
            </div> : undefined
        
        const notesSection = (this.state.data.showNotes && this.getNotesValue()) ? 
            <div className="RowDiv"
                style={{
                    width: '-webkit-fill-available',
                    paddingTop: getVW(5),
                    paddingBottom: getVW(5),
                }}>
                {ComponentManager.getTermsAndConditionOrNotesSection(
                    Utility.getNotesTitle(getIsJournalEntry(this.state.data.type) ? getLocalisedText('memo') : getLocalisedText('notes')),
                    this.getNotesValue(),
                    "100%",
                    this.state.data.themeColor,
                    undefined,
                    undefined,
                    undefined,
                    this.state.data.printScale,
                    Utility.getTnCOrNotesFontSettingsByType(EditorInfoType.notes)
                )}
            </div> : undefined

        const remarks = <div className="RowDiv"
            style={{
                width: '-webkit-fill-available',
                paddingTop: getVW(5),
                paddingBottom: getVW(5),
            }}>
            {ComponentManager.getTermsAndConditionOrNotesSection(
                getLocalisedText('remarks'),
                this.state.data.notes ?? '',
                "100%",
                this.state.data.themeColor,
                undefined,
                undefined,
                undefined,
                this.state.data.printScale,
                undefined,
            )}
        </div> 
        
        return (
            <table
                style={{
                    alignItems: "flex-start",
                    justifyContent: 'space-between',
                    backgroundColor: "white",
                    border: '1px solid',
                    paddingTop: getVW(5 * this.spacingRatio),
                    paddingBottom: getVW(5 * this.spacingRatio),
                    width: '100%'
                }}
            >
                <tbody>
                    <tr>
                        {termsAndConditionSection && 
                            <td style={{ width: '50%', verticalAlign: 'top' }}>
                                {termsAndConditionSection}
                            </td>
                        }
                        {notesSection &&
                            <td style={{ width: '50%', verticalAlign: 'top' }}>
                                {notesSection}
                            </td>
                        }
                    </tr>
                    <tr>
                        {remarks}
                    </tr>
                </tbody>
            </table>
        )
    }


    getTableColumnTd(left, right) {
        return <tr>
            <td style={{ border: '1px solid', width: '50%', verticalAlign: 'top' }}>
                {left}
            </td>
            <td style={{ border: '1px solid', width: '50%', verticalAlign: 'top' }}>
                {right}
            </td>
        </tr>
    }

    getGenerateDocumentSection() {
        return <div style={{
            paddingBottom: getVW(PAGE_PADDING, true),// Utility.getGeneratedMessagePaddingBottom(this.spacingRatio),
            paddingTop: getVW(5 * this.spacingRatio),
            width: '100%'
        }}>
            <text className={'docFontStyleBold fontSizeNormal ' + getFontSizeClass()}
                style={{
                    ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular },this.state.data.printScale)
                }}>
                {Utility.getGeneratedMessage(this.state.data.type)}
            </text>
        </div>
    }

    getEditableDocumentTable() {
        return ComponentManager.getDKDocumentTable(
            this.props.tableColumn,
            this.getTableStyle(),
            this.props.data,
            this.props.tableClicked,
            this.props.stockTableClicked,
            this.props.onUpdateDataCallback,
            this.props.paymentTableClicked,
            this.props.additionalTableCallback,
        )
    }

    getProductTable() {
        if(Utility.getIsShowAccountProductTable(this.state.data)) {
            return ComponentManager.getDKProductTable(
                Utility.getTableColumnForAccountProduct(),
                this.getTableStyle(),
                this.props.data,
                this.props.tableClicked,
                this.props.stockTableClicked,
                this.props.onUpdateDataCallback,
                this.props.paymentTableClicked,
                this.props.additionalTableCallback,
            )
        }
    }

    getTableStyle() {
        let style = DocumentManager.getTableDefaultStyle();
        style.headerTextColor = this.state.data.themeColor;
        style.footerTextColor = this.state.data.themeColor;
        // style.headerBorderColor = "#BBBABA";
        // style.itemBorderColor = "#BBBABA";
        style.headerBorderColor = "#000000";
        style.itemBorderColor = "#000000";
        style.footerBackgroundColor = "white";
        // style.footerBorderColor = "#BBBABA";
        style.footerBorderColor = "#000000";
        style.headerBackgroundColor = "transparent";
        return style;
    }


    getCustomFieldTableStyle() {
        let style = {}
        style.columnCount = 3
        style.columnWidths = Utility.getIsArabicLang() ? ['33%', '33%', '33%'] : ['33%', '33%', '33%']
        return style;
    }

    getExchangeRateTable(tableStyle, data) {
        const style = {
            ...tableStyle,
            paddingTop: 4,
            paddingBottom: 4,
            paddingLeft: 6,
            paddingRight: 13,
        };

        return (
            <div id={HTMLTag.EXCHANGE_RATE} className="RowDiv" style={{ justifyContent: "flex-end" }}>
                <div style={{ width: "45%" }}>
                    {ComponentManager.getExchangeRateTable(style, data)}
                </div>
            </div>
        );
    }
}
