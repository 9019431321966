import {
  DKLabel,
  DKTooltipWrapper,
  DKButton,
  showAlert,
  DKIcon,
  DKIcons,
  showLoader,
  removeLoader
} from 'deskera-ui-library';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
  BOOKS_DATE_FORMAT,
  DOC_TYPE,
  GOOGLE_NO_TRANSLATE_CLASS,
  QTY_ROUNDOFF_PRECISION
} from '../../../../../Constants/Constant';
import useClickOutSide from '../../../../../Hooks/useClickOutside';
import { useAppSelector } from '../../../../../Redux/Hooks';
import { selectWorkstations } from '../../../../../Redux/Slices/MRP/WorkstationSlice';
import DateFormatService from '../../../../../Services/DateFormat';

import { JOB_CARD_STATUS } from '../../../Constants/MRPColumnConfigs';
import { STATUS_BG_CLASS } from '../../../Constants/UIHelper';
import AddJobCard from '../../../JobCard/AddJobCard/AddJobCard';
import JCItem from '../../../Shared/JobCardListComponent/JCItem';
import JCProcessType from '../../../Shared/JobCardListComponent/JCProcessType';
import {
  JobCardListPresenter,
  Job_Card_Keys,
  JC_PROCESS_TYPE
} from '../../../Shared/JobCardListComponent/JobCardListPresenter';
import {
  getStatusChipColor,
  hasWorkOrderItemsChanged
} from '../../WorkOrderHelper';
import Utility from '../../../../../Utility/Utility';
import ConsumeProduceHelper from '../ConsumptionProduction/ConsumeProduceHelper';
import {
  buildJCPayload,
  getQueriedJobCardsProcessed,
  getStatusCard,
  renderProductNameByProductCode,
  toolTipContent
} from './JobCardHelper';
import { JCStatusChip } from './JobCardStatus';

import ProcessManufacturingService from '../../../../../Services/MRP/ProcessManufacturing';
import {
  getAlertButtons,
  getAttachedSubstitutes,
  getConsumeProcessAlertHeader,
  getConsumeProcessAlertMessage,
  getUpdatedWODetailsFromShortInfo,
  isAssignOptionVisible,
  isAvailableForQC,
  isConsumptionOptionVisible,
  isProcessOptionVisible,
  populateProducts,
  validateJCFormAndList
} from '../../../JobCard/AddJobCard/AddJobCardPresenter';
import { PROCESS_QC_STATUS } from '../../../Constants/MRPEnums';
import NumberFormatService from '../../../../../Services/NumberFormat';
import DetailsOpener from '../../../../Common/DetailsOpener';
import JCDependency from '../../../Shared/JobCardListComponent/JCDependency';
import {
  JC_PROCESS_TYPE_DATA,
  JobCardHelper
} from '../../../JobCard/JobCardHelper';
import JobCardQC from '../../../Shared/JobCardQC/JobCardQC';
import JCQcNeeded from '../../../Shared/JobCardListComponent/JCQCNeeded';
import {
  checkUserPermission,
  showNoPermissionAlert
} from '../../../../Settings/GranularPermissions/GranularPermissionsHelper';
import { PERMISSIONS_BY_MODULE } from '../../../../../Constants/Permission';
import { parseDateFromStringForWorkOrder } from '../../../../../Services/MRP/WorkOrder';

export interface IJobCardListProps {
  onRowChange: (row: any) => void;
  operationDetails: any;
  jobCards?: any;
  isReadOnly?: boolean;
  workOrderData?: any;
  onWOSaveFromJCList?: any;
  componentProductShortInfoList?: any[];
}
const JobCardList: React.FC<IJobCardListProps> = (props: IJobCardListProps) => {
  const [selectedRow, setSelectedRow] = useState<any>({});
  const [showAddJobCardPopup, setShowAddJobCardPopup] = useState(false);

  const workstations = useAppSelector(selectWorkstations);
  const [showQCPopup, setShowQCPopup] = useState(false);
  var presenter = new JobCardListPresenter();
  const [showContextMenu, setShowContextMenu] = useState<boolean>(false);
  const [detailsPopupData, setDetailsPopupData] = useState<any>();
  const [localRows, setLocalRows] = useState<any>();

  const contextMenuRef = useRef<any>(null);
  const jcRowsHolderRef = useRef<any>(null);
  const contextMenuPosition = useRef<any>({ left: 0, top: 0 });
  const queriedJobCards = useMemo(() => props.jobCards || [], [props.jobCards]);
  const currentRow = useRef<any>(null);

  const hasWOObjectChanged = hasWorkOrderItemsChanged(
    props.workOrderData?.workOrderItems,
    props.workOrderData?.workOrderItemsClonedCopy
  );

  const hasJCViewPermission = checkUserPermission(
    PERMISSIONS_BY_MODULE.JOB_CARD.VIEW
  );

  //constants
  let updatedWorkOrderDetails = getUpdatedWODetailsFromShortInfo(
    props?.componentProductShortInfoList,
    props.workOrderData
  );
  let allAttachedSubstitutes: any[] =
    getAttachedSubstitutes(
      updatedWorkOrderDetails?.workOrderItems || [],
      props?.componentProductShortInfoList
    ) ?? [];
  const workOrderItemWithSubstitutes = [
    ...(updatedWorkOrderDetails?.workOrderItems || []),
    ...allAttachedSubstitutes
  ];

  useClickOutSide(contextMenuRef, () => {
    // currentRow.current = null;
    setShowContextMenu(false);
  });

  useEffect(() => {
    let rows = queriedJobCards;

    rows = getQueriedJobCardsProcessed(rows);

    setLocalRows(rows);
  }, [queriedJobCards]);

  const onJobCardRowClick = (row: any) => {
    if (props.isReadOnly) return;
    setSelectedRow(row);
    setShowAddJobCardPopup(true);
  };

  /**
   * @description - this function finds the name of product from workorderData (getProductShotInfo)
   * @param productCode - code of the product against the name is required
   * @returns - name of the product
   */
  const getProductByCode = (productCode: string) => {
    const item = workOrderItemWithSubstitutes?.find((woItem: any) => {
      const code = woItem?.itemName?.productId ?? woItem?.productId;
      return code === productCode;
    });

    if (item?.isSubstitute) {
      return item;
    }

    return item?.itemName;
  };

  const renderQCStatus = (column: any, row: any, rowIndex: number) => {
    const [linkedItem] = row?.jobCardLinkDetails || {};
    if (Utility.isEmptyObject(linkedItem)) return '-';
    let {
      status,
      passedQuantity,
      rejectedQuantity,
      itemQuantity,
      qcNeeded,
      processType
    } = linkedItem;
    let updatedNameStatus =
      status === PROCESS_QC_STATUS.QA_DONE
        ? PROCESS_QC_STATUS.QC_DONE_UI_ONLY
        : status;

    if (!qcNeeded && processType === JC_PROCESS_TYPE.PRODUCTION) {
      updatedNameStatus = PROCESS_QC_STATUS.NOT_APPLICABLE_UI_ONLY;
    }

    const readableStatus = Utility.convertInTitleCase(
      updatedNameStatus?.replaceAll('_', ' ')
    );

    return (
      <DKTooltipWrapper
        content={toolTipContent({
          itemQuantity,
          passedQuantity,
          rejectedQuantity,
          processType
        })}
        tooltipClassName="bg-deskera-secondary width-auto"
      >
        <DKLabel
          key={`${column.name}-${rowIndex}`}
          text={readableStatus ?? ''}
          className={`${getStatusChipColor(updatedNameStatus)}`}
        />
      </DKTooltipWrapper>
    );
  };

  const handleJCProcessChange = (row: any, process: any) => {
    const existingProduct: string[] =
      props?.jobCards?.reduce((acc: any, jcObj: any) => {
        if (Utility.isNotEmpty(jcObj.jobCardLinkDetails?.[0]?.productCode)) {
          acc.push({
            productCode: jcObj.jobCardLinkDetails?.[0]?.productCode,
            processType: jcObj.jobCardLinkDetails?.[0]?.processType
          });
        }
        return acc;
      }, []) ?? [];

    const products = populateProducts(
      process.key,
      workOrderItemWithSubstitutes || [],
      existingProduct
    );

    if (Utility.isEmpty(products)) {
      showAlert(
        'Please note...',
        'There are no items available to select for this process type.'
      );
      return;
    }

    let localRowsCopy = [...localRows];

    localRowsCopy = localRowsCopy?.map((itemRow: any) => {
      if (itemRow?.id === row.id) {
        itemRow = {
          ...itemRow,
          localProcessType: process.key
        };
      }
      return itemRow;
    });
    setLocalRows(localRowsCopy);
  };

  const handleJCProductChange = (row: any, product: any) => {
    const jobCardMapping = presenter.getJobCardMappingForRow(
      row,
      queriedJobCards
    );
    const formData = {
      processType: {
        key: row?.localProcessType ?? row?.jobCardLinkDetails?.[0]?.processType
      },
      jobCardCode: row?.jobCardCode
    };

    if (
      !validateJCFormAndList(
        product,
        jobCardMapping,
        formData,
        jobCardMapping?.currentJCDependentOn,
        false,
        (updatedDependentJc: any) => {}
      )
    ) {
      return;
    }

    const jcDetails = buildJCPayload(row, product);
    jcDetails.productCode = product.productId;
    jcDetails.processType =
      row?.localProcessType ?? row?.jobCardLinkDetails?.[0]?.processType;
    jcDetails.itemWarehouseInventoryData = product.warehouseInventoryData ?? [];
    updateJCDetails([jcDetails]);
  };

  const handleQCToggle = (row: any, qcNeeded: boolean) => {
    const jcDetails = buildJCPayload(row, null);
    jcDetails.qcNeeded = qcNeeded;
    updateJCDetails([jcDetails]);
  };

  const updateJCDetails = (jcDetails: any[]) => {
    showLoader();
    ProcessManufacturingService.addOrUpdateProcessDetailsJC(jcDetails)
      .then((res: any) => {
        removeLoader();
        props.onRowChange({});
      })
      .catch((err: any) => {
        removeLoader();
      });
  };

  /** Data Formatter Goes Here */
  const pickFormatter = (column: any, row: any, rowIndex: number) => {
    const data = row[column.key];
    switch (column.key) {
      case Job_Card_Keys.JOB_CARD_CODE:
        return row?.documentSequenceCode;
      case Job_Card_Keys.OPERATION_ID:
        return operationFormatter(row);
      case Job_Card_Keys.STATUS:
        const status: any = getStatusCard(data);
        return (
          <div className="column parent-width position-relative">
            <JCStatusChip
              title={status?.title}
              width={'auto'}
              className={`text-align-left cursor-hand ${status?.color}`}
              onClick={() => {}}
            />
          </div>
        );
      case Job_Card_Keys.WORKSTATION_ID:
        return workstationFormatter(row);
      case Job_Card_Keys.FINISHED_QUANTITY:
        return <DKLabel text="0" className="text-align-right mr-l" />;
      case Job_Card_Keys.JOB_CARD_DATE:
        return DateFormatService.getDateStrFromDate(
          DateFormatService.getDateFromStr(
            row?.jobCardDate,
            BOOKS_DATE_FORMAT['YYYY-MM-DD']
          )
        );
      case Job_Card_Keys.PLANNED_START_DATE:
        return !Utility.isEmpty(row?.plannedStartDate)
          ? DateFormatService.getDateStrFromDate(
              parseDateFromStringForWorkOrder(
                row?.plannedStartDate as string
              ) || new Date()
            )
          : '-';
      case Job_Card_Keys.PLANNED_END_DATE:
        return !Utility.isEmpty(row?.plannedEndDate)
          ? DateFormatService.getDateStrFromDate(
              parseDateFromStringForWorkOrder(row?.plannedEndDate as string) ||
                new Date()
            )
          : '-';
      case Job_Card_Keys.DEPENDENCY:
        return (
          <JCDependency
            row={row}
            jobCardMapping={presenter.getJobCardMappingForRow(
              row,
              props?.jobCards
            )}
            isReadOnlyMode={!!props.isReadOnly}
            isLinkedInventory={presenter.isLinkedInventory}
            onRefreshGrid={() => {
              props.onRowChange({});
            }}
          />
        );
      case Job_Card_Keys.PLANNED_TIME:
        return <DKLabel text={`${row?.plannedTime ?? 0} mins`} />;
      case Job_Card_Keys.ITEMS:
        return (
          <JCItem
            products={workOrderItemWithSubstitutes || []}
            column={column}
            row={row}
            rowIndex={rowIndex}
            getProductByCode={getProductByCode}
            onProductSelect={(product: any) =>
              handleJCProductChange(row, product)
            }
            jobCardMapping={presenter.getJobCardMappingForRow(
              row,
              props?.jobCards
            )}
          />
        );
      case Job_Card_Keys.QC_STATUS:
        return renderQCStatus(column, row, rowIndex);
      case Job_Card_Keys.QC_NEEDED:
        return (
          <JCQcNeeded
            products={workOrderItemWithSubstitutes || []}
            column={column}
            row={row}
            rowIndex={rowIndex}
            onQCToggle={(qcNeeded) => {
              handleQCToggle(row, qcNeeded);
            }}
          />
        );
      case Job_Card_Keys.PROCESS_TYPE:
        return (
          <JCProcessType
            processTypes={JC_PROCESS_TYPE_DATA}
            column={column}
            row={row}
            rowIndex={rowIndex}
            onTypeSelect={(type: any) => {
              handleJCProcessChange(row, type);
            }}
            jobCardMapping={presenter.getJobCardMappingForRow(
              row,
              props?.jobCards
            )}
          />
        );
      case Job_Card_Keys.TOTAL_ASSIGNED_QTY:
        let quantityAssigned = ConsumeProduceHelper.getWholeQty(
          row?.jobCardLinkDetails?.[0]?.itemWarehouseInventoryData
        );
        return (
          <DKLabel
            text={NumberFormatService.getNumber(quantityAssigned)}
            className="mr-r text-align-right"
          />
        );
      case Job_Card_Keys.TOTAL_PRODUCED_QTY:
        let quantityProduced = JobCardHelper.getProducedQtyOnJCList(
          props?.workOrderData,
          row
        );
        return (
          <DKLabel
            text={NumberFormatService.getNumber(quantityProduced)}
            className="mr-r text-align-right"
          />
        );
      default:
        return data;
    }
  };

  const operationFormatter = (row: any) => {
    const operation = props.operationDetails?.find(
      (operation: any) => operation.id === row['operationId']
    );
    return (
      <DKTooltipWrapper
        content={operation?.name || row.operationName || '-'}
        tooltipClassName="bg-deskera-secondary width-auto"
      >
        <DKLabel
          style={{
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            width: 90
          }}
          text={operation?.name || row.operationName || '-'}
          className="text-align-left white-space-nowrap"
        />
      </DKTooltipWrapper>
    );
  };

  const workstationFormatter = (row: any) => {
    const workstation = workstations?.content?.find(
      (workstation: any) => workstation.id === row['workstationId']
    );
    return (
      <DKTooltipWrapper
        content={workstation?.workstationName || '-'}
        tooltipClassName="bg-deskera-secondary width-auto"
      >
        <DKLabel
          style={{
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            width: 90
          }}
          text={workstation?.workstationName || '-'}
          className="text-align-left white-space-nowrap"
        />
      </DKTooltipWrapper>
    );
  };

  /** Renderer Goes Here */
  const renderColumns = () => {
    return (
      <div
        className="row mt-m p-h-r parent-width"
        style={{
          minWidth: '100%',
          width: 'auto'
        }}
      >
        {presenter.getColumnConfigs().map((column) => (
          <DKLabel
            text={column.name}
            className={`row fw-m`}
            style={{ width: column.width }}
          />
        ))}
      </div>
    );
  };

  const renderRows = () => {
    return (
      <div
        id="jc-rows-holder"
        ref={jcRowsHolderRef}
        className="position-relative column overflow-y-scroll hide-scroll-bar parent-height parent-width"
        style={{
          minWidth: '100%',
          width: 'auto'
        }}
      >
        {localRows?.map((jobCard: any, index: number) =>
          getRow(jobCard, index)
        )}
        {showContextMenu && getContextMenu()}
      </div>
    );
  };

  const getContextMenu = () => {
    const elementWidth = 200;
    const { top = 0, left = 0 } =
      jcRowsHolderRef.current?.getBoundingClientRect();

    const onQCClicked = (event: any) => {
      event.stopPropagation();
      setShowContextMenu(false);
      setShowQCPopup(true);
    };

    const onEditClick = (event: any) => {
      event.stopPropagation();
      if (checkUserPermission(PERMISSIONS_BY_MODULE.JOB_CARD.EDIT)) {
        setShowContextMenu(false);
        onRowOpenClick(currentRow.current);
      } else {
        showNoPermissionAlert();
      }
    };

    const onConsumeOrProcessClicked = (
      event: any,
      type: 'consume' | 'process'
    ) => {
      event.stopPropagation();
      setShowContextMenu(false);
      const [linkedItem = {}] = currentRow.current?.jobCardLinkDetails || {};
      const onConfirm = async () => {
        ProcessManufacturingService.jcDetailsCompletion([linkedItem?.id]).then(
          (res) => {
            if (Utility.isNotEmpty(res)) {
              props.onRowChange({});
            }
          },
          (err) => {}
        );
      };
      const onCancel = () => {};
      showAlert(
        getConsumeProcessAlertHeader(type),
        getConsumeProcessAlertMessage(
          type,
          getProductByCode(linkedItem.productCode)?.name
        ),
        getAlertButtons(onConfirm, onCancel)
      );
    };
    return (
      <div
        ref={contextMenuRef}
        className="column position-fixed p-x-s right-2 top-7 border shadow border-radius-m bg-white z-index-3"
        style={{
          width: elementWidth,
          top: contextMenuPosition.current.top,
          left: contextMenuPosition.current.left
        }}
      >
        {<DKButton title="Edit" onClick={onEditClick} />}
        {isAvailableForQC(currentRow.current) && (
          <DKButton title="QC For Assigned Quantities" onClick={onQCClicked} />
        )}
        {isConsumptionOptionVisible(currentRow.current) && (
          <DKButton
            title="Consume"
            onClick={(e: any) => onConsumeOrProcessClicked(e, 'consume')}
          />
        )}
        {isProcessOptionVisible(currentRow.current) && (
          <DKButton
            title="Process"
            onClick={(e: any) => onConsumeOrProcessClicked(e, 'process')}
          />
        )}
      </div>
    );
  };

  const handleRowClick = (e: any, row: any) => {
    contextMenuPosition.current.left = e.clientX;
    contextMenuPosition.current.top = e.clientY;
    const [linkedItem = {}] = row?.jobCardLinkDetails;
    currentRow.current = { ...row, productCode: linkedItem.productCode };
    e.preventDefault();
    e.stopPropagation();
    setShowContextMenu((prev) => !prev);
  };

  const handleAssignClick = (e: any, row: any) => {
    e.stopPropagation();
    const [jcDetails = {}] = row?.jobCardLinkDetails || {};
    const { jobCardLinkDetailsAvailableToAssign = [] } = row;
    const onConfirm = () => {
      const filterJCDetails = jobCardLinkDetailsAvailableToAssign.filter(
        (linkDetails: any) =>
          linkDetails?.productCode === jcDetails?.productCode
      );
      const payload = filterJCDetails?.map((item: any) => {
        let quantity = item?.warehouseInventoryData?.reduce(
          (total: number, whItem: any) => {
            const qty = whItem?.uomQuantity ?? whItem?.quantity;
            total += Number(qty ?? 0);
            return total;
          },
          0
        );

        quantity = Utility.roundingOff(quantity, QTY_ROUNDOFF_PRECISION);

        return {
          jobCardCode: item.jobCardCode,
          productCode: item.productCode,
          workOrderCode: item.workOrderCode,
          itemQuantity: quantity,
          uomItemQuantity: quantity,
          itemWarehouseInventoryData: item?.warehouseInventoryData ?? [],
          documentUOMSchemaDefinition: item?.documentUOMSchemaDefinition,
          stockUom: item?.stockUom
        };
      });

      ProcessManufacturingService.assignAllocatedQuantities(payload)
        .then((res) => {
          if (Utility.isNotEmpty(res)) {
            props.onRowChange({});
          }
        })
        .catch((err) => {});
    };
    const onCancel = () => {};
    showAlert(
      'Confirm assignment to material',
      `Are you sure, you want to assign all the allocated quantities to <span class="fw-b">${renderProductNameByProductCode(
        jcDetails?.productCode,
        props.workOrderData
      )}</span>?`,
      getAlertButtons(onConfirm, onCancel)
    );
  };

  const handleProduceClick = (e: any, row: any, woData: any) => {
    e.stopPropagation();
    setDetailsPopupData({
      documentSeqCode: woData?.workOrderSeqCode,
      documentType: DOC_TYPE.WORK_ORDER,
      showDetailsOpener: true
    });
  };

  const handleDeleteClick = (e: any, row: any) => {
    e.stopPropagation();
    const [jcDetails = {}] = row?.jobCardLinkDetails || {};
    const onDelete = () => {
      if (Utility.isNotEmpty(jcDetails?.id?.toString())) {
        ProcessManufacturingService.deleteProcessDetailsJC([jcDetails.id])
          .then((res: any) => {
            if (Utility.isNotEmpty(res)) {
              props.onRowChange({});
            }
          })
          .catch((error: any) => {});
      }
    };
    const onCancel = () => {};

    showAlert(
      'Confirm deletion of material',
      `Are you sure, you want to delete material <span class="fw-b">${
        jcDetails?.productCode ?? ''
      }</span>?`,
      getAlertButtons(onDelete, onCancel)
    );
  };

  const onRowOpenClick = (row: any) => {
    if (presenter.isLinkedInventory && hasWOObjectChanged) {
      let buttons = [
        {
          title: 'Cancel',
          className: 'bg-gray2 border-m ',
          onClick: () => {}
        },
        {
          title: 'Save',
          className: 'bg-button text-white ml-r',
          onClick: () => {
            props?.onWOSaveFromJCList();
          }
        }
      ];
      showAlert(
        'Warning !!!',
        'There has been an update in your current Work Order, Please save your current Work Order to update Job Card details',
        buttons
      );
      return;
    }
    onJobCardRowClick(row);
    setShowContextMenu(false);
  };

  /**
   * @description - This function is used to render the Job Description row
   * @param row - row data to render
   * @returns ReactNode
   */
  const getRow = (row: any, rowIndex: number) => {
    const jobCardMapping = presenter.getJobCardMappingForRow(
      row,
      queriedJobCards
    );
    const [jcDetails = {}] = row?.jobCardLinkDetails || {};

    const isStatusCompletedOrCancelled = [
      JOB_CARD_STATUS.COMPLETED,
      JOB_CARD_STATUS.CANCELLED
    ].includes(row.status);

    let showAssignButton = isAssignOptionVisible(
      row,
      { key: row?.jobCardLinkDetails?.[0]?.processType },
      jobCardMapping?.currentJCDependentOn
    );

    let showDeleteButton = JobCardHelper.showDeleteButtonOnJCList(
      jcDetails,
      isStatusCompletedOrCancelled,
      props?.workOrderData,
      row
    );

    const showProducedButton =
      jcDetails?.processType === JC_PROCESS_TYPE.PRODUCTION &&
      Utility.isNotEmpty(
        JobCardHelper.getWOAttachedObj(props?.workOrderData, row)
      ) &&
      !isStatusCompletedOrCancelled;

    const status: keyof typeof STATUS_BG_CLASS = row.status;
    return (
      <div
        style={{ height: 50 }}
        className="row p-h-r p-v-l mt-m border-m border-radius-s shadow-s-2 overflow-visible position-relative cursor-hand"
        onContextMenu={(e) => handleRowClick(e, row)}
        onClick={() => onRowOpenClick(row)}
      >
        <div
          className={
            'parent-height position-absolute ' + STATUS_BG_CLASS[status]
          }
          style={{ width: 3, left: 0 }}
        />
        {presenter.getColumnConfigs().map((column: any) => (
          <span
            style={{
              width: column.width,
              textAlign: column?.textAlign ?? 'left'
            }}
            className={
              [
                Job_Card_Keys.STATUS,
                Job_Card_Keys.PROCESS_TYPE,
                Job_Card_Keys.QC_NEEDED
              ].includes(column.key)
                ? ''
                : GOOGLE_NO_TRANSLATE_CLASS
            }
          >
            {pickFormatter(column, row, rowIndex)}
          </span>
        ))}
        <div className="row width-auto">
          {showAssignButton && (
            <DKButton
              title={'Assign'}
              className={'underline cursor-hand fs-m text-blue'}
              onClick={(e: any) => {
                handleAssignClick(e, row);
              }}
            />
          )}

          {showProducedButton && (
            <DKButton
              title={'Produce'}
              className={'underline cursor-hand fs-m text-blue'}
              onClick={(e: any) => {
                handleProduceClick(
                  e,
                  row,
                  JobCardHelper.getWOAttachedObj(props?.workOrderData, row)
                );
              }}
            />
          )}

          {showDeleteButton && (
            <DKButton
              title={'Delete'}
              className={'underline cursor-hand fs-m text-blue'}
              onClick={(e: any) => {
                handleDeleteClick(e, row);
              }}
            />
          )}
        </div>
      </div>
    );
  };

  const renderEmptyState = () => {
    return (
      <div className="column parent-size align-items-center justify-content-center">
        No job cards found for this work order
        <br />
        <span className="text-gray">
          It will start appearing here once available
        </span>
      </div>
    );
  };
  const totalJobCards = hasJCViewPermission
    ? props?.workOrderData?.totalJobCards
    : 0;
  const getHeader = () => {
    return (
      <div className="row parent-width justify-content-between">
        <div className="row width-auto">
          <DKLabel text="Job Card Details" className="fw-m text-app-color" />
          <DKLabel
            text={totalJobCards || 0}
            className="column p-xs bg-chip-red text-red circle ml-r justify-content-center align-items-center"
            style={{ width: 20, height: 20 }}
          />
        </div>
        <div className="row width-auto">
          {!props.workOrderData?.actualTime && (
            <DKTooltipWrapper
              content="Actual time will be updated on work order completion."
              tooltipClassName=""
            >
              <DKIcon
                src={DKIcons.ic_info}
                className="ic-xs opacity-40 mr-xs cursor-hand"
                onClick={() => {}}
              />
            </DKTooltipWrapper>
          )}
          <DKLabel text="Actual Time" className="fw-m text-app-color mr-xs" />
          <DKLabel
            text={`${
              props.workOrderData?.actualTime
                ? props.workOrderData?.actualTime + ' min'
                : '-'
            }`}
            className="mr-l"
          />
          <DKLabel
            text="Estimated Time"
            className="fw-m text-app-color mr-xs"
          />
          <DKLabel
            text={`${
              props.workOrderData?.estimatedTime
                ? props.workOrderData?.estimatedTime + ' min'
                : '-'
            }`}
            className=""
          />
        </div>
      </div>
    );
  };

  const getJobCardQCPopup = () => {
    return showQCPopup ? (
      <JobCardQC
        productCode={currentRow.current?.productCode}
        jobCardCode={currentRow.current?.jobCardCode}
        jobCardDocumentSequenceCode={currentRow.current?.documentSequenceCode}
        documentUOMSchemaDefinition={
          currentRow?.current?.jobCardLinkDetailsAvailableToAssign?.[0]
            ?.documentUOMSchemaDefinition ??
          currentRow?.current?.jobCardLinkDetails?.[0]
            ?.documentUOMSchemaDefinition
        }
        woCode={currentRow.current?.workOrderCode ?? ''}
        onClose={() => {
          setShowQCPopup(false);
        }}
        onSuccess={() => {
          setShowQCPopup(false);
          props.onRowChange({});
        }}
      />
    ) : null;
  };

  const showEmptyState = Utility.isEmpty(localRows);

  /** Main Renderer */
  return (
    <div
      className="column parent-height bg-white border-m border-radius-m p-l overflow-hidden"
      style={{ width: '59%', height: 'auto', maxHeight: 705 }}
    >
      {getHeader()}
      <div className="column parent-size">
        {!showEmptyState && hasJCViewPermission && (
          <div className="column parent-size overflow-x-scroll">
            {renderColumns()}
            {renderRows()}
          </div>
        )}
        {(showEmptyState || !hasJCViewPermission) && renderEmptyState()}
        {showAddJobCardPopup && (
          <AddJobCard
            editableJobCard={selectedRow}
            column
            onCancel={() => {
              setShowAddJobCardPopup(false);
              setSelectedRow({});
            }}
            updateJCList={() => {
              props.onRowChange({});
            }}
            onSuccess={() => {
              setShowAddJobCardPopup(false);
              setSelectedRow({});
              props.onRowChange(selectedRow);
            }}
          />
        )}
        {!Utility.isEmptyObject(detailsPopupData) &&
          detailsPopupData?.showDetailsOpener && (
            <DetailsOpener
              data={detailsPopupData}
              onCancel={() => {
                setDetailsPopupData(null);
                props.onRowChange(true);
              }}
              isReadOnly={false}
            />
          )}
        {showQCPopup && getJobCardQCPopup()}
      </div>
    </div>
  );
};
export default JobCardList;
