import {
  DKButton,
  DKCheckMark,
  DKIcon,
  DKIcons,
  DKInput,
  DKLabel,
  DKLine,
  DKListPicker2,
  DKSpinner,
  INPUT_TYPE,
  TOAST_TYPE,
  showAlert,
  showToast,
  showLoader,
  removeLoader,
  DKTooltipWrapper,
  Toggle
} from 'deskera-ui-library';

import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CreatableSelect from 'react-select/creatable';
import ApiConstants from '../../../../Constants/ApiConstants';
import {
  COUNTRY_CODES,
  CUSTOM_NUMBER_INPUT_MODULES,
  DOCUMENT_MODE,
  DOC_TYPE,
  INPUT_VIEW_DIRECTION,
  MODULES_NAME,
  MRP,
  POPUP_CALLBACKS_TYPE,
  POPUP_CLICK_TYPE,
  POPUP_TYPE,
  PRODUCE_PRODUCT_TYPE,
  PRODUCT_TYPE,
  STATUS_TYPE,
  allowedProductImageType,
  QTY_ROUNDOFF_PRECISION,
  BARCODE_LENGTH_ALLOWED
} from '../../../../Constants/Constant';
import ComponentManager from '../../../../Managers/ComponentManager';
import { CustomField } from '../../../../Models/CustomField';
import {
  BtnType,
  CallBackPayloadType,
  PopupClickActionType,
  ReactSelectOptionsType,
  UpdateCallBacksRefType
} from '../../../../Models/Interfaces';
import {
  FUTURE_ACCOUNTS_APPLY,
  PRODUCT_CHECKBOX_FIELDS,
  PRODUCT_DROPDOWN_FIELDS,
  ProductAttachmentResponse,
  ProductFormState,
  ProductInitialState,
  futureAccountInitialState,
  initialProductFormState
} from '../../../../Models/Product';
import { useAppDispatch, useAppSelector } from '../../../../Redux/Hooks';
import {
  appendAccountInStore,
  selectedAccounts
} from '../../../../Redux/Slices/AccountsSlice';
import {
  activeTenantInfo,
  fetchTenantSettings,
  tenantSettings
} from '../../../../Redux/Slices/AuthSlice';
import {
  fetchTaxes,
  selectNatureOfIncomeAccounts,
  selectPurchaseTax,
  selectSalesTax,
  selectUOMs
} from '../../../../Redux/Slices/CommonDataSlice';
import {
  fetchContacts,
  selectContacts
} from '../../../../Redux/Slices/ContactsSlice';
import { selectMRPProducts } from '../../../../Redux/Slices/MRP/MRPProductSlice';
import { fetchOperationsList } from '../../../../Redux/Slices/MRP/OperationSlice';
import {
  fetchAttributeList,
  fetchProductSummary,
  fetchProducts,
  selectAttributes,
  selectProductsColumnConfig,
  selectProductsColumnConfigTableId
} from '../../../../Redux/Slices/ProductsSlice';
import AccountsService, {
  AccountAPIConfig
} from '../../../../Services/Accounts';
import AttachmentService from '../../../../Services/Attachment';
import AuthService from '../../../../Services/Auth';
import ContactService, { ContactAPIConfig } from '../../../../Services/Contact';
import MRPProductsService from '../../../../Services/MRP/MRPProducts';
import NumberFormatService from '../../../../Services/NumberFormat';
import ProductService from '../../../../Services/Product';
import { CustomFieldsHolder } from '../../../../SharedComponents/CustomFieldsHolder/CustomFieldsHolder';
import CustomNumberFormatInput from '../../../../SharedComponents/CustomNumberFormat/CustomNumberFormatInput';
import { customFieldsContainsErrors } from '../../../../SharedComponents/DocumentForm/NewDocumentHelper';
import FormUtil, {
  formUtilComponentProductStyle
} from '../../../../SharedComponents/FormUtil/FormUtil';
import {
  productAuConfig,
  productBeConfig,
  productCaConfig,
  productGermanyConfig,
  productIndiaConfig,
  productIndonesiaConfig,
  productMyConfig,
  productNzConfig,
  productPhConfig,
  productSgConfig,
  productUaeConfig,
  productUkConfig
} from '../../../../SharedComponents/FormUtil/FormUtilConstants';
import InputLabel from '../../../../SharedComponents/InputLabel/InputLabel';
import PopupWrapper, {
  DynamicPopupWrapper
} from '../../../../SharedComponents/PopupWrapper';
import Utility, { deepClone } from '../../../../Utility/Utility';
import AddUomPopUp from '../../../Settings/UOM/AddUomPopUp';
import AddUomSchemaPopUp from '../../../Settings/UOMSchema/AddUomSchemaPopUp';
import BOMDetailsTabProduct from './../BomDetailsTabProduct/BOMDetailsTabProduct';
import { BomConfigurationModel } from './../BomDetailsTabProduct/BomConfigurationModel';
import SubstituteProductTabView from './../SubstituteProductTabView';
import SubstituteSelectionPopUp from './../SubstituteSelectionPopUp';
import { Attachments } from '../../../../SharedComponents/Attachments';
import { triggerDownload } from '../../../ImportExport/utility/ExportData';
import OperationsService from '../../../../Services/MRP/Operations';
import { checkUserPermission } from '../../../Settings/GranularPermissions/GranularPermissionsHelper';
import { PERMISSIONS_BY_MODULE } from '../../../../Constants/Permission';
import WarehouseService from '../../../../Services/Warehouse';
import {
  fetchWarehouseWithRRBCombinationWithoutRole,
  selectedWarehouseWithRRBCombinationWithoutRole
} from '../../../../Redux/Slices/WarehouseSlice';
import { isEqual } from 'lodash';

export enum FOCUS_FIELD_KEY {
  SALES_PRICE = 'salesPrice',
  PURCHASE_PRICE = 'purchasePrice',
  OPENING_VALUATION = 'openingValuation'
}

export interface CreateProductProps {
  isFromAdHoc?: boolean;
  product?: any;
  type?: any;
  selectedTab?: any;
  isCopy?: boolean;
  onProductDashboard?: any;
  hasError?: any;
  needProductRefresh?: boolean;
  onSuccess?: any;
  onCancel?: any;
  readOnly?: boolean;
  apiInProgress?: any;
}

export default function CreateMRPProductView(props: CreateProductProps) {
  const dispatch = useAppDispatch();
  //From store
  const accountList = useAppSelector(selectedAccounts);
  const contacts = useAppSelector(selectContacts);
  const uomList = useAppSelector(selectUOMs);
  const warehousesData: any = useAppSelector(
    selectedWarehouseWithRRBCombinationWithoutRole
  );
  const [transactionType, setTransactionType] = useState([
    { label: 'Purchase', value: 'PURCHASE' },
    { label: 'Sales', value: 'SALES' },
    { label: 'Both', value: 'BOTH' },
    { label: '-', value: '-' }
  ]);
  const defaultAccountValues = useAppSelector(tenantSettings);
  const natureOfIncome = useAppSelector(selectNatureOfIncomeAccounts);
  const [hasVariant, setHasVariant] = useState(false);
  const attributeMasterData = useAppSelector(selectAttributes);
  const [complianceFields, setComplianceFields] = useState({});
  const tenantInfo = useAppSelector(activeTenantInfo);
  const { t } = useTranslation();
  const [selectedWarehouseData, setSelectedWarehouseData] = useState<any>(null);
  const [isReadOnly] = useState<any>(
    props?.product?.id
      ? !checkUserPermission(PERMISSIONS_BY_MODULE.MATERIAL_MASTER.EDIT)
      : false
  );
  const columnConfig = useAppSelector(selectProductsColumnConfig);
  const tableId = useAppSelector(selectProductsColumnConfigTableId);
  const [showLandedCostCategory, setShowLandedCostCategory] =
    useState<boolean>(false);
  const [availabilitySelectedIndexes, setAvailabilitySelectedIndexes] =
    useState<any>([]);

  /**
   * PRODUCT IMAGE UPLOAD
   */
  const [attachments, setAttachments] = useState<any>([]);
  const [productImage, setProductImage] = useState<any>('');
  const [productImageLoading, setProductImageLoading] =
    useState<boolean>(false);
  const [productImageAttachmentIds, setProductImageAttachmentIds] = useState<
    number[]
  >([]);
  const [removeProductImage, setRemoveProductImage] = useState<boolean>(false);
  const [productDocAttachmentIDs, setProductDocAttachmentIDs] = useState<any>(
    []
  );
  const [isCopyAttachment, setIsCopyAttachment] = useState(false);
  const [previousOpeningQty, setPreviousOpeningQty] = useState<any>('');

  /**
   * PRODUCT IMAGE UPLOAD
   */
  // Data source
  const refInitialState: UpdateCallBacksRefType = {
    pushDataToParent: { type: POPUP_CALLBACKS_TYPE.NONE },
    storeCallbacksRef: {
      createProduct: 'click'
    }
  };
  const createProductRef = useRef<UpdateCallBacksRefType>(refInitialState);
  const [type, setType] = useState([
    { label: 'Raw Material', value: 'TRACKED' },
    { label: 'WIP Material', value: 'BILL_OF_MATERIALS' }
  ]); //, { title: 'Bill of Materials', value: "BILL_OF_MATERIALS" }])
  const [exemptedValues] = useState([
    { label: 'Non GST', value: 'NON_GST' },
    { label: 'Exempted', value: 'EXEMPTED' }
  ]);
  const [productValuationMethod, setProductValuationMethod] = useState([
    { label: 'FIFO', value: 'FIFO' },
    { label: 'AVERAGE', value: 'AVERAGE' }
  ]);

  const popupBtnConfigForAddUomSchema: BtnType[] = [
    {
      title: t(`SETTINGS.UOM_SCHEMA.BUTTON.CANCEL`),
      class: 'border-m mr-s',
      clickAction: POPUP_CLICK_TYPE.CLOSE_POPUP
    },
    {
      title: t(`SETTINGS.UOM_SCHEMA.BUTTON.SAVE`),
      class: 'bg-app text-white mr-ss',
      clickAction: POPUP_CLICK_TYPE.ADD_UOM_SCHEMA
    }
  ];

  const popupBtnConfigForAddUom: BtnType[] = [
    {
      title: t(`SETTINGS.UOM.BUTTON.CANCEL`),
      class: 'border-m mr-s',
      clickAction: POPUP_CLICK_TYPE.CLOSE_POPUP
    },
    {
      title: t(`SETTINGS.UOM.BUTTON.SAVE`),
      class: 'bg-button text-white mr-ss',
      clickAction: POPUP_CLICK_TYPE.ADD_UOM
    }
  ];

  const [attributeList, setAttributeList] = useState([] as any[]);
  //   const productsData = useAppSelector(selectProducts);

  // hooks states
  const [uomSchems, setUOMSchemes] = useState<any>([]);
  const isEdit = props.product && props.product.id ? true : false;
  const [saveButtonTapped, setSaveButtonTapped] = useState(isEdit);

  const getProductTypeFor = (typeParam: MRP) => {
    switch (typeParam) {
      case MRP.BILL_OF_MATERIAL:
        return PRODUCT_TYPE.BILL_OF_MATERIALS;
      case MRP.RAW_MATERIAL:
        return PRODUCT_TYPE.TRACKED;
      case MRP.FINISHED_GOODS:
        return PRODUCT_TYPE.BILL_OF_MATERIALS;
      case MRP.SERVICE:
        return PRODUCT_TYPE.NON_TRACKED;
    }
  };
  const productState: any = props.product
    ? props.product
    : { ...ProductInitialState, type: getProductTypeFor(props.type) };
  const [product, setProduct] = useState({
    ...productState,
    isFinishedProduct: props.product
      ? props.product.isFinishedProduct
      : props.type === MRP.FINISHED_GOODS
      ? true
      : false
  });
  const [attributeMapping, setAttributeMapping] = useState(
    product && product.attributes
      ? product.attributes?.map((attr: any) => {
          return { ...attr, isExisting: true };
        })
      : []
  );
  const [variantsEnabledOnEditMode, setVariantsEnabledOnEditMode] =
    useState<boolean>(false);
  const [isProductInUse, setIsProductInUse] = useState<boolean>(false);
  type ProductFormReactSelectOption = ReactSelectOptionsType<string, string>;
  const [productForm, setProductForm] = useState<ProductFormState>(
    deepClone(initialProductFormState)
  );
  const [baseUOM, setBaseUOM] = useState();
  const purchaseTaxesList = useAppSelector(selectPurchaseTax);
  const salesTaxesList = useAppSelector(selectSalesTax);
  const [productTypeChanged, setProductTypeChanged] = useState(false);
  const [isFocused, setIsFocused] = useState<FOCUS_FIELD_KEY | ''>();
  const elementRefList = useRef<{ [key: string]: HTMLDivElement }>({});
  const loadBomData = (product: any) => {
    var bomList: any[] = [];
    if (
      product &&
      product.type === 'BILL_OF_MATERIALS' &&
      product.bomProductsConfiguration
    ) {
      product.bomProductsConfiguration.forEach((bom: any) => {
        var tmpBom = { ...bom };
        tmpBom['name'] = bom['itemName'];
        bomList.push(tmpBom);
      });
    }
    return bomList;
  };

  const [accounts, setAccounts] = useState<any[]>([]);
  const [attributeFieldVal, setAttributeFieldVal] = useState(['']);
  const [apiCountry, setApiCountry] = useState('');
  const [showUomCheckbox, setShowUomCheckbox] = useState(true);
  const [selectedSegment, setSelectedSegment] = useState(
    props.selectedTab ? props.selectedTab : 0
  );
  const [addUomSchemaPopUp, setAddUomSchemaPopUp] = useState(false);
  const [addUomPopUp, setAddUomPopUp] = useState(false);
  const [apiCallInProgress, setApiCallInProgress] = useState(false);
  const [autoNumberingFormat, setAutoNumberingFormat] = useState<any>({});

  const [futureAccountsApply, setFutureAccountApply] = useState(
    futureAccountInitialState
  );
  const [selectedVendor, setSelectedVendor] = useState<any>(null);

  const [warehouseRows, setWarehouseRows] = useState<any>([]);
  const [warehouseRack, setWarehouseRack] = useState<any>([]);
  const [warehouseBin, setWarehouseBin] = useState<any>([]);
  const [currentSelection, setCurrentSelection] = useState<any>();
  const [showListPicker, setShowListPicker] = useState(false);
  const [usedBoms, setUsedBoms] = useState([]);

  const [productDocAttachments, setProductDocAttachments] = useState<any>([]);
  const [showAttachmentPopup, setShowAttachmentPopup] = useState(false);
  const [operationListForEdit, setOperationListForEdit] = useState<any>([]);
  const [isNameLengthCheckApplied, setIsNameLengthCheckApplied] =
    useState(false);

  useEffect(() => {
    let productType = getProductTypeFor(props.type);
    updateProductData('type', productType);
  }, [props.type]);

  useEffect(() => {
    // check if product is in use
    if (product?.id) {
      checkProductInUse(product.id);
      setProductImageLoading(true);
      fetchAttachments();
      fetchProductDocAttachments();
    }

    if (Utility.isEmpty(warehousesData)) {
      dispatch(fetchWarehouseWithRRBCombinationWithoutRole());
    }

    if (isEdit) {
      setPreviousOpeningQty(product?.inventory?.openingQuantity || 0);
    }
    if (
      (props?.isCopy || isEdit) &&
      product.type === PRODUCT_TYPE.BILL_OF_MATERIALS
    ) {
      getOperationsListForEdit();
    }
    if (props.isCopy && props?.product?.copyId) {
      fetchAttachments();
    }

    const updatedState = deepClone(productForm);
    const purchaseTaxOptions: ProductFormReactSelectOption[] = [];
    const salesTaxOptions: ProductFormReactSelectOption[] = [];
    let defaultPurchaseTax: ProductFormReactSelectOption | null = null;
    let defaultSalesTax: ProductFormReactSelectOption | null = null;

    let sgDefaultTaxForNewOrg: any;
    purchaseTaxesList.forEach((purchaseTax) => {
      const option: ProductFormReactSelectOption = {
        label: purchaseTax.name,
        value: purchaseTax.code
      };
      if (purchaseTax.isDefault) {
        defaultPurchaseTax = option;
      }
      if (purchaseTax.taxCode === 'TX (7%)') {
        sgDefaultTaxForNewOrg = option;
      }
      purchaseTaxOptions.push(option);
    });

    if (Utility.isEmpty(defaultPurchaseTax)) {
      defaultPurchaseTax = sgDefaultTaxForNewOrg;
      sgDefaultTaxForNewOrg = null;
    }

    salesTaxesList.forEach((salesTax) => {
      const option: ProductFormReactSelectOption = {
        label: salesTax.name,
        value: salesTax.code
      };
      if (salesTax.isDefault) {
        defaultSalesTax = option;
      }
      if (salesTax.taxCode === 'SR (7%)') {
        sgDefaultTaxForNewOrg = option;
      }
      salesTaxOptions.push(option);
    });

    if (Utility.isEmpty(defaultSalesTax)) {
      defaultSalesTax = sgDefaultTaxForNewOrg;
      sgDefaultTaxForNewOrg = null;
    }

    // purchase Tax Options
    if (purchaseTaxOptions.length > 0) {
      updatedState.compliance.singapore.purchaseTax.options =
        purchaseTaxOptions;
      updatedState.compliance.singapore.purchaseTax.value =
        purchaseTaxOptions[0];
      if (defaultPurchaseTax) {
        updatedState.compliance.singapore.purchaseTax.value =
          defaultPurchaseTax;
      }

      if (product?.purchaseTaxCode) {
        const purchaseTaxOption = purchaseTaxOptions.find(
          (tax) => tax.value === product?.purchaseTaxCode
        );
        if (purchaseTaxOption) {
          updatedState.compliance.singapore.purchaseTax.value =
            purchaseTaxOption;
        }
      }
    }
    // sales Tax Options
    if (salesTaxOptions.length > 0) {
      updatedState.compliance.singapore.salesTax.options = salesTaxOptions;
      updatedState.compliance.singapore.salesTax.value = salesTaxOptions[0];
      if (defaultSalesTax) {
        updatedState.compliance.singapore.salesTax.value = defaultSalesTax;
      }
      if (product?.salesTaxCode) {
        const salesTaxOption = salesTaxOptions.find(
          (tax) => tax.value === product?.salesTaxCode
        );
        if (salesTaxOption) {
          updatedState.compliance.singapore.salesTax.value = salesTaxOption;
        }
      }
    }

    if (productState?.customField) {
      updatedState.customFields = deepClone(productState.customField);
    } else {
      updatedState.customFields = [];
    }

    // SG Compliance
    switch (tenantInfo.country) {
      case COUNTRY_CODES.SG:
        updatedState.compliance.singapore.purchaseTax.hidden = false;
        updatedState.compliance.singapore.salesTax.hidden = false;
        updatedState.compliance.singapore.defaultPurchaseTax.hidden = false;
        updatedState.compliance.singapore.defaultSalesTax.hidden = false;
        break;
    }

    // check if taxes are enabled as default
    const purchaseTaxCode = product?.purchaseTaxCode
      ? product.purchaseTaxCode
      : '';
    const salesTaxCode = product?.salesTaxCode ? product.salesTaxCode : '';
    if (tenantInfo.country === COUNTRY_CODES.IN) {
      checkDefaultTaxes(updatedState, purchaseTaxCode, salesTaxCode);
    }

    if (product.offeringType === 'SERVICES') {
      setType([{ label: 'Non Tracked', value: 'NONTRACKED' }]);
    }

    // check auto fulfill
    if (productState?.autoFulfillmentEnabled) {
      updatedState.isAutoFulfillEnabled = true;
    }
    if (
      tenantInfo?.landedCostSettings &&
      tenantInfo?.landedCostSettings?.landedCost
    ) {
      let categoryOption: ReactSelectOptionsType<string, string>[] = [];
      tenantInfo?.landedCostSettings?.categoryList?.forEach((data: any) => {
        let option: ReactSelectOptionsType<string, string> = {
          label: data.name,
          value: data.allocationType
        };
        categoryOption.push(option);
      });

      updatedState?.landedCostCategory?.options &&
        updatedState?.landedCostCategory?.options.map(
          (landedCostCategoryItem, index) => {
            if (
              productForm?.landedCostCategory?.value?.[0].value ===
              landedCostCategoryItem?.value
            ) {
              let updatedArray = availabilitySelectedIndexes;
              updatedArray.push(index);
              setAvailabilitySelectedIndexes(updatedArray);
            }
          }
        );

      if (categoryOption.length > 0) {
        updatedState.landedCostCategory.options = categoryOption;
      }
      if (props && props?.product && props?.product?.landedCostCategory) {
        let selectedOption: ReactSelectOptionsType<string, string>[] = [];
        props?.product?.landedCostCategory.map((category: any) => {
          updatedState.landedCostCategory.options &&
            updatedState.landedCostCategory.options.map((item, index) => {
              if (
                category?.allocationType === item.value &&
                category?.name === item.label
              ) {
                let updatedArray = availabilitySelectedIndexes;
                updatedArray.push(index);
                setAvailabilitySelectedIndexes(updatedArray);
                selectedOption.push(item);
              }
            });
        });

        updatedState.landedCostCategory.defaultValue = selectedOption;
        updatedState.landedCostCategory.value = selectedOption;
      }
    }
    //Get used Bom Ids
    if (product?.id && product?.type === PRODUCT_TYPE.BILL_OF_MATERIALS) {
      MRPProductsService.getUsedBomListForProductId(product?.productId)
        .then((response: any) => {
          setUsedBoms(response);
        })
        .catch((err: any) => {
          console.log('Error fetching Used Boms list.');
        });
    }
    updateIsLengthCheckVariable();
    setTimeout(() => {
      setProductForm({ ...updatedState });
    }, 1000);
  }, []);

  useEffect(() => {
    if (!Utility.isEmpty(productImage) && props.isCopy) {
      const fetchData = async () => {
        let file = await fetch(productImage)
          .then((r) => r.blob())
          .then(
            (blobFile) =>
              new File([blobFile], 'CopiedImage', { type: 'image/png' })
          );
        setAttachments([file]);
      };
      fetchData();
    }
  }, [productImage]);

  const checkProductInUse = async (productId: number) => {
    ProductService.checkProductUsedStatus(productId)
      .then((res) => {
        const response: boolean = res;
        setIsProductInUse(response);
        updateProductTransactionType(product.productTransactionType, response);
        setAttributeMapping(
          product && product.attributes
            ? product.attributes?.map((attr: any) => {
                return { ...attr, isExisting: response ? true : false };
              })
            : []
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const validateAndUpdateProductData = (typeObj: any) => {
    let key = 'productTransactionType';
    let value = transactionType[typeObj].value;
    !Utility.isEmpty(typeObj)
      ? updateProductData(key, value)
      : updateProductData(key, null);
  };
  const getSelectedTransactionType = () => {
    let filterred = transactionType.filter((item) => {
      return item.value === product.productTransactionType;
    });
    if (filterred.length > 0) {
      let first = filterred[0];
      return transactionType.indexOf(first);
    } else {
      if (Utility.isEmpty(product?.id)) {
        return transactionType.length - 1;
      }
      return 0;
    }
  };
  const updateProductTransactionType = (
    productTransactionType: string,
    isProductInUse: boolean
  ) => {
    switch (productTransactionType) {
      case 'PURCHASE':
        if (!isProductInUse) {
          setTransactionType([
            { label: 'Purchase', value: 'PURCHASE' },
            { label: 'Sales', value: 'SALES' },
            { label: 'Both', value: 'BOTH' }
          ]);
        } else {
          setTransactionType([
            { label: 'Purchase', value: 'PURCHASE' },
            { label: 'Both', value: 'BOTH' }
          ]);
        }
        break;
      case 'SALES':
        if (!isProductInUse) {
          setTransactionType([
            { label: 'Purchase', value: 'PURCHASE' },
            { label: 'Sales', value: 'SALES' },
            { label: 'Both', value: 'BOTH' }
          ]);
        } else {
          setTransactionType([
            { label: 'Sales', value: 'SALES' },
            { label: 'Both', value: 'BOTH' }
          ]);
        }
        break;
      case 'BOTH':
        if (!isProductInUse) {
          setTransactionType([
            { label: 'Purchase', value: 'PURCHASE' },
            { label: 'Sales', value: 'SALES' },
            { label: 'Both', value: 'BOTH' }
          ]);
        } else {
          setTransactionType([{ label: 'Both', value: 'BOTH' }]);
        }
        break;
      default:
        if (product?.id) {
          setTransactionType([
            { label: 'Purchase', value: 'PURCHASE' },
            { label: 'Sales', value: 'SALES' },
            { label: 'Both', value: 'BOTH' },
            { label: '-', value: '-' }
          ]);
        } else {
          setTransactionType([
            { label: 'Purchase', value: 'PURCHASE' },
            { label: 'Sales', value: 'SALES' },
            { label: 'Both', value: 'BOTH' }
          ]);
        }
        break;
    }
  };

  const checkDefaultTaxes = (
    updatedState: ProductFormState,
    purchaseTaxCode: string,
    salesTaxCode: string
  ) => {
    const taxCodes: string[] = [];
    if (purchaseTaxCode) {
      taxCodes.push(purchaseTaxCode);
    }

    if (salesTaxCode) {
      taxCodes.push(salesTaxCode);
    }

    if (taxCodes.length === 0) {
      return;
    }

    ProductService.checkDefaultTaxCode(taxCodes)
      .then((res) => {
        const taxCodeMap: { [key: string]: boolean } = {};
        const response: any[] = res;
        response.forEach((codeDetails) => {
          taxCodeMap[codeDetails.code] = codeDetails.isDefault ? true : false;
        });

        if (
          taxCodeMap.hasOwnProperty(purchaseTaxCode) &&
          taxCodeMap[purchaseTaxCode]
        ) {
          updatedState.compliance.india.defaultPurchaseTax.isSelected = true;
        }

        if (
          taxCodeMap.hasOwnProperty(salesTaxCode) &&
          taxCodeMap[salesTaxCode]
        ) {
          updatedState.compliance.india.defaultSalesTax.isSelected = true;
        }

        setProductForm({ ...updatedState });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    callUOMSchemesAPI(product.stockUom);
    loadAttributeMasterData();
    getComplianceConfig();
    setCountryData();
    dispatch(fetchOperationsList());
  }, []);

  useEffect(() => {
    let preSelectedWarehouseCode: string | null = null;
    if (isEdit && props?.product?.inventory?.warehouseCode) {
      preSelectedWarehouseCode = props.product.inventory.warehouseCode;
    } else if (!isEdit && !Utility.isEmpty(product?.inventory?.warehouseCode)) {
      preSelectedWarehouseCode = product.inventory.warehouseCode;
    }

    if (preSelectedWarehouseCode) {
      const selectedWarehouse = warehousesData?.content?.find(
        (warehouse: any) => warehouse.code === preSelectedWarehouseCode
      );
      if (selectedWarehouse) {
        onChangeWarehouse(selectedWarehouse);
      } else {
        WarehouseService.getWarehouseByCode(preSelectedWarehouseCode).then(
          (res) =>
            Utility.isNotEmpty(res?.content) &&
            onChangeWarehouse(res.content[0])
        );
      }
    }

    if (!isEdit && Utility.isEmpty(product?.inventory?.warehouseCode)) {
      let primaryWarehouse = warehousesData?.content?.find(
        (item: any) => item?.primary
      );
      updateInventory('warehouseCode', primaryWarehouse);
    }
  }, [warehousesData]);

  useEffect(() => {
    if (attributeMasterData) {
      if (attributeMasterData['content'].length > 0) {
        var tmpAttributeList: any[] = [];
        attributeMasterData['content'].forEach((e: any) => {
          var attribute = {
            label: e.name,
            value: e.id
          };
          tmpAttributeList.push(attribute);
        });
        setAttributeList(tmpAttributeList);
      }
    }
  }, [attributeMasterData]);

  useEffect(() => {
    if (accountList && accountList.content && accountList.content.length > 0) {
      let result = accountList.content
        .reduce((acc: any[], account: any) => {
          if (account.status === STATUS_TYPE.ACTIVE) {
            acc.push({
              label: account.name,
              value: account.code
            });
          }
          return acc;
        }, [])
        .sort((a: any, b: any) => a.label.localeCompare(b.label));
      setAccounts(result);
    }
  }, [accountList]);

  useEffect(() => {
    var p: any = { ...product };
    p.attributes = [];
    p.hasVariants = false;
    if (attributeMapping.length > 0) {
      p.hasVariants = true;
      p.attributes = attributeMapping;
    }
    setProduct(p);
  }, [attributeMapping]);

  useEffect(() => {
    if (!Utility.isEmpty(tenantInfo)) {
      loadContacts({}, tenantInfo.country);
    }
    //Calling Contact search api for Product Edit
    if (product.id) {
      loadSelectedContact();
    }
  }, [tenantInfo]);

  useEffect(() => {
    if (props.isCopy && !isCopyAttachment) {
      const productAttachments = props.product?.attachmentsWithLink || [];
      setProductDocAttachments(productAttachments);
      setProductDocAttachmentIDs(
        productAttachments.map((attachment: any) => attachment.attachmentId) ||
          []
      );
      setIsCopyAttachment(true);
    }
  }, [product]);

  const loadSelectedContact = () => {
    if (product.reorderVendorCode) {
      ContactService.fetchContactByContactCode(product.reorderVendorCode)
        .then(
          (res: any) => {
            if (res && res.content && res.content.length > 0) {
              setSelectedVendor(res.content[0]);
            }
          },
          (err: any) => {
            console.log(err, 'error fetching selected Contact');
          }
        )
        .catch((err: any) => {
          console.log(err, 'error fetching selected Contact');
        });
    }
  };

  const loadContacts = async (config: ContactAPIConfig, apiCountry: string) => {
    try {
      ContactService.apiConfig = config;
      await dispatch(fetchContacts(apiCountry));
    } catch (err) {
      console.error('Error loading contacts: ', err);
    }
  };

  const setCountryData = () => {
    switch (tenantInfo.country) {
      case COUNTRY_CODES.IN:
      case COUNTRY_CODES.ID:
      case COUNTRY_CODES.PH:
      case COUNTRY_CODES.NZ:
      case COUNTRY_CODES.DE:
      case COUNTRY_CODES.AE:
      case COUNTRY_CODES.UK:
      case COUNTRY_CODES.BE:
      case COUNTRY_CODES.AU:
      case COUNTRY_CODES.CA:
      case COUNTRY_CODES.MY:
        setApiCountry(tenantInfo.country.toLowerCase());
        break;
      case COUNTRY_CODES.SG:
        break;

      default:
        break;
    }
  };

  const getComplianceConfig = () => {
    switch (tenantInfo.country) {
      case COUNTRY_CODES.IN:
        let updatedProductIndiaConfig: any = { ...productIndiaConfig };
        if (product.offeringType === 'SERVICES') {
          const updatedFields = updatedProductIndiaConfig.fields.filter(
            (item: any) => {
              return item.name !== 'UQC';
            }
          );
          updatedProductIndiaConfig = {
            ...updatedProductIndiaConfig,
            fields: updatedFields
          };
        }
        setComplianceFields(updatedProductIndiaConfig);
        break;

      case COUNTRY_CODES.SG:
        setComplianceFields(productSgConfig);
        break;

      case COUNTRY_CODES.MY:
        setComplianceFields(productMyConfig);
        break;

      case COUNTRY_CODES.ID:
        setComplianceFields(productIndonesiaConfig);
        break;

      case COUNTRY_CODES.PH:
        setComplianceFields(productPhConfig);
        break;

      case COUNTRY_CODES.NZ:
        setComplianceFields(productNzConfig);
        break;

      case COUNTRY_CODES.DE:
        setComplianceFields(productGermanyConfig);
        break;

      case COUNTRY_CODES.AE:
        setComplianceFields(productUaeConfig);
        break;
      case COUNTRY_CODES.UK:
        setComplianceFields(productUkConfig);
        break;
      case COUNTRY_CODES.BE:
        setComplianceFields(productBeConfig);
        break;
      case COUNTRY_CODES.AU:
        setComplianceFields(productAuConfig);
        break;
      case COUNTRY_CODES.CA:
        setComplianceFields(productCaConfig);
        break;

      default:
        break;
    }
  };

  useEffect(() => {
    if (product.stockUom === 5) {
      setShowUomCheckbox(false);
      updateProductData('uomSchema', null);
    } else {
      setShowUomCheckbox(true);
    }
  }, [product.stockUom]);

  const loadAttributeMasterData = async () => {
    try {
      const data = await dispatch(fetchAttributeList());
    } catch (err) {
      console.error('Error loading attribute list: ', err);
    }
  };

  const callUOMSchemesAPI = (id: any) => {
    AccountsService.fetchUOMSchemes(id)
      .then((response) => {
        setUOMSchemes(response);
      })
      .catch((err: any) => {});
  };

  const getSelectedAccountValue = (code: string) => {
    if (accounts && accounts?.length > 0 && code) {
      let accountObject = accounts.find((item: any) => {
        return item.value === code;
      });
      if (Utility.isEmpty(accountObject)) {
        return null;
      } else {
        return accountObject;
      }
    }
  };

  const getAccountSelector = (
    title: string,
    key: string,
    value?: any,
    callBack?: any
  ) => {
    return (
      <DKInput
        readOnly={isAvailableForTransaction(title)}
        required={true}
        canValidate={saveButtonTapped}
        title={title}
        value={value ? value : getSelectedAccountValue(product[key])}
        onChange={(value: any) => {
          dispatch(appendAccountInStore(value));
          if (callBack) {
            callBack(value);
          } else {
            updateProductData(key, value?.value);
          }
        }}
        formatter={(value: any) => value.label}
        type={INPUT_TYPE.DROPDOWN}
        dropdownConfig={{
          style: { minWidth: 230 },
          className: 'shadow-m',
          title: 'Select Account',
          allowSearch: true,
          searchableKey: 'name',
          canEdit: false,
          canDelete: false,
          data: accounts ? accounts : [],
          renderer: (index: any, account: any) => {
            return account.label;
          },
          searchApiConfig: {
            getUrl: (searchValue: string) => {
              const config: AccountAPIConfig = {
                ...AccountsService.apiConfig,
                Page: 0,
                SearchTerm: encodeURIComponent(searchValue),
                Limit: accounts.length > 0 ? accounts.length : 25,
                Query: `status=ACTIVE&sortDir=ASC&sort=name`
              };
              AccountsService.apiConfig = config;
              return (
                ApiConstants.URL.BASE + AccountsService.getAccountEndPoint()
              );
            },
            dataParser: (response: any) => {
              let mappedAccounts = response?.content?.map((acc: any) => {
                return {
                  ...acc,
                  label: acc.name,
                  value: acc.code
                };
              });
              return mappedAccounts ?? [];
            },
            debounceTime: 300
          }
          // button: GranularPermissionsHelper.hasPermissionFor(
          //   PERMISSIONS_BY_MODULE.COA.CREATE
          // )
          //   ? {
          //       title: 'Add Account',
          //       className: 'bg-button text-white',
          //       onClick: () => {
          //         // setShowAddAccount(true);
          //       }
          //     }
          //   : null
        }}
      />
    );
  };

  ////////////////////////////////////////////////////////////////////////
  ///////// Methods ////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  const isNonEmpty = (value: any) => {
    if (value && value.length > 0) {
      return true;
    } else {
      return false;
    }
  };

  ////////////////////////////////////////////////////////////////////////
  ///////// API CALLS ////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  const getSelectedAccount = (code: string) => {
    if (accounts && accounts?.length > 0 && code) {
      let filtered = accounts.filter((item: any) => {
        return item.value === code;
      });
      if (filtered.length > 0) {
        let obj: any = filtered[0];
        return accounts.indexOf(obj);
      } else if (accounts?.length > 0 && filtered.length === 0) {
        return 0;
      } else {
        return -1;
      }
    } else {
      return -1;
    }
  };

  const setDefaultActiveWarehouse = (product: any) => {
    if (Utility.isEmpty(product.id)) {
      let activeWarehouses = warehousesData?.content?.filter(
        (item: any) => item.active
      );

      let selectedWarehouse = activeWarehouses?.find(
        (warehouse: any) => warehouse.primary === true
      );
      if (!Utility.isEmpty(selectedWarehouse) && product.inventory) {
        product.inventory.warehouseCode = selectedWarehouse.code;
        onChangeWarehouse(selectedWarehouse);
      }
    }
  };

  const setDefaultValues = () => {
    if (defaultAccountValues.inventorySettings) {
      let editProduct: any = deepClone(product);
      if (editProduct.type === PRODUCT_TYPE.TRACKED) {
        editProduct.purchaseAccountCode =
          defaultAccountValues.inventorySettings.inventoryPurchaseAccount;
        editProduct.salesAccountCode =
          defaultAccountValues.inventorySettings.salesAccount;
        editProduct.purchaseReturnAccountCode =
          defaultAccountValues.inventorySettings.inventoryPurchaseAccount;
        editProduct.salesReturnAccountCode =
          defaultAccountValues.inventorySettings.salesAccount;
        editProduct.inventory.wipInventoryAccountCode =
          defaultAccountValues.inventorySettings.wipInventoryAccount;
        if (editProduct.inventory && warehousesData?.content) {
          setDefaultActiveWarehouse(editProduct);
        }
      } else if (editProduct.type === PRODUCT_TYPE.NON_TRACKED) {
        editProduct.purchaseAccountCode =
          defaultAccountValues.inventorySettings.nonInventoryPurchaseAccount;
        editProduct.salesAccountCode =
          defaultAccountValues.inventorySettings.salesAccount;
        editProduct.purchaseReturnAccountCode =
          defaultAccountValues.inventorySettings.nonInventoryPurchaseAccount;
        editProduct.salesReturnAccountCode =
          defaultAccountValues.inventorySettings.salesAccount;
      } else if (editProduct.type === PRODUCT_TYPE.BILL_OF_MATERIALS) {
        editProduct.salesAccountCode =
          defaultAccountValues.inventorySettings.salesAccount;
        editProduct.purchaseAccountCode =
          defaultAccountValues.inventorySettings.inventoryPurchaseAccount;
        editProduct.purchaseReturnAccountCode = defaultAccountValues
          .inventorySettings.inventoryPurchaseReturnAccount
          ? defaultAccountValues.inventorySetting.inventoryPurchaseReturnAccount
          : defaultAccountValues.inventorySettings.inventoryPurchaseAccount;
        editProduct.salesReturnAccountCode = defaultAccountValues
          .inventorySettings.salesReturnAccount
          ? defaultAccountValues.inventorySettings.salesReturnAccount
          : defaultAccountValues.inventorySettings.salesAccount;
        editProduct.manufacturingCostAccountCode =
          defaultAccountValues.inventorySettings.manufacturingCost;
        if (editProduct.inventory && warehousesData?.content) {
          setDefaultActiveWarehouse(editProduct);
        }
      }
      if (editProduct.inventory) {
        editProduct.inventory.costOfGoodsSoldAccountCode =
          defaultAccountValues.inventorySettings.costOfGoodsSoldAccount;
        editProduct.inventory.inventoryAccountCode =
          defaultAccountValues.inventorySettings.inventoryAccount;
        editProduct.inventory.wipInventoryAccountCode =
          defaultAccountValues.inventorySettings.wipInventoryAccount;
        editProduct.inventory.stockAdjustmentAccountCode =
          defaultAccountValues.inventorySettings.stockAdjustmentAccount;
      } else {
        editProduct.inventory = {
          costOfGoodsSoldAccountCode:
            defaultAccountValues.inventorySettings.costOfGoodsSoldAccount,
          inventoryAccountCode:
            defaultAccountValues.inventorySettings.inventoryAccount,
          wipInventoryAccountCode:
            defaultAccountValues.inventorySettings.wipInventoryAccount,
          stockAdjustmentAccountCode:
            defaultAccountValues.inventorySettings.stockAdjustmentAccount
        };
      }
      setProduct({ ...editProduct });
    }
  };

  useEffect(() => {
    if (!product.id && !props.isCopy) {
      setDefaultValues();
    }

    if (props.isCopy && productTypeChanged) {
      setDefaultValues();
    }

    if (product.id) {
      if (
        product.taxPreference === undefined ||
        product.taxPreference === null
      ) {
        updateProductData('taxPreference', true);
      }
    }
  }, [defaultAccountValues, product.type]);

  useEffect(() => {
    if (isReadOnly) {
      const divElem = document.getElementById('popup-container');
      const inputElements = divElem?.querySelectorAll('input');

      inputElements?.forEach((el) => (el.disabled = true));
    }
  });

  ////////////////////////////////////////////////////////////////////////
  ///////// Product operations ////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  function updateProductData(key: string, value: any) {
    setProduct((product: any) => ({ ...product, [key]: value }));
  }

  function variantClicked() {
    product.hasVariants = !product.hasVariants;
    if (isEdit) {
      setVariantsEnabledOnEditMode(!hasVariant);
    }
    setHasVariant(!hasVariant);
  }

  const getSelectedValueFromArray = (typeArray: any[], value: any) => {
    let filterred = typeArray.filter((item) => {
      return item.value === value;
    });
    if (filterred.length > 0) {
      let first = filterred[0];
      return typeArray.indexOf(first);
    } else {
      return undefined;
    }
  };

  const updateInventory = (key: string, value: any) => {
    let edited = { ...product };
    let inventory = { ...edited.inventory };
    inventory[key] = value;
    if (key === 'warehouseCode') {
      inventory[key] = value?.code;
      inventory['rowCode'] = null;
      inventory['rackCode'] = null;
      inventory['binCode'] = null;
      onChangeWarehouse(value);
    }
    edited.inventory = inventory;
    setProduct(edited);
  };

  const onChangeWarehouse = (warehouseObject: any) => {
    let warehouseRows = [],
      warehouseBin = [],
      warehouseRack = [];

    if (!Utility.isEmptyObject(warehouseObject)) {
      warehouseRows = warehouseObject?.warehouseRowInfos || [];
      warehouseRack = warehouseObject?.warehouseRackInfos || [];
      warehouseBin = warehouseObject?.warehouseBinInfos || [];
    }
    setSelectedWarehouseData(warehouseObject);
    setWarehouseBin(warehouseBin);
    setWarehouseRows(warehouseRows);
    setWarehouseRack(warehouseRack);
  };

  const getWarehouseFieldDataAvailable = () => {
    return (
      warehouseRows.length > 0 ||
      warehouseRack.length > 0 ||
      warehouseBin.length > 0
    );
  };

  const getNonEmptyInventoryValue = (key: string) => {
    if (product.inventory) {
      if (product.inventory[key]) {
        return product.inventory[key];
      }
    }
    return '';
  };

  const getSelectedUOMValue = (id: any, list: any[]) => {
    let filtered = list ? list.filter((item) => item.id === id) : [];
    if (filtered.length > 0) {
      let first = filtered[0];
      return list.indexOf(first);
    } else {
      return -1;
    }
  };

  const getSelectedUOMValueTest = (id: any, list: any[]) => {
    let filtered = list ? list.filter((item) => item.value === id) : [];
    if (filtered.length > 0) {
      let first = filtered[0];
      return list.indexOf(first);
    } else {
      return -1;
    }
  };

  const customFieldUpdated = (list: CustomField[]) => {
    const updatedState = productForm;

    updatedState.customFields = list;
    setProductForm({ ...updatedState });
  };

  const redirectToProductDashboard = () => {
    const data = { rowData: { id: product.id } };
    props.onProductDashboard(data);
  };

  ////////////////////////////////////////////////////////////////////////
  ///////// UI Common Methods ////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  const getTextField = (
    title: string,
    fieldName: string,
    value: any,
    type: string,
    required = false,
    direction = INPUT_VIEW_DIRECTION.HORIZONTAL
  ) => (
    <DKInput
      required={required}
      canValidate={saveButtonTapped}
      type={type}
      title={title}
      value={value}
      direction={direction}
      onChange={(text: any) => updateProductData(fieldName, text)}
    />
  );

  const getSelectionField = (
    title: string,
    fieldName: string,
    value: any,
    options: any[],
    hasError = false,
    required = false
  ) => {
    let optionsArray = Utility.isEmpty(options)
      ? []
      : options.map((item: any) => item.label);
    return (
      <div className="parent-width justify-content-between text-align-left mt-r">
        <DKInput
          required={required}
          title={title}
          value={value >= 0 ? [value] : []}
          options={optionsArray}
          onChange={(value: any) => {
            !Utility.isEmpty(value)
              ? updateProductData(fieldName, options[value].value)
              : updateProductData(fieldName, null);
          }}
          canValidate={saveButtonTapped}
          type={INPUT_TYPE.SELECT}
        />
      </div>
    );
  };

  type returnFunction = (index: number) => any;
  type handleCreate = (value: string) => any;
  type onChange = (value: string) => any;
  type onBlur = (value: string) => any;
  type onKeyDown = (event: Event) => any;

  const getSelectionFieldWithCallback = (
    title: string,
    value: any,
    options: any[],
    callback: returnFunction,
    hasError = false,
    required = false,
    isReadOnly = false
  ) => {
    let optionsArray = Utility.isEmpty(options)
      ? []
      : options.map((item: any) => item.label);
    return (
      <div className="parent-width justify-content-between text-align-left mt-r">
        <DKInput
          required={required}
          canValidate={saveButtonTapped}
          title={title}
          value={value >= 0 ? [value] : []}
          options={optionsArray}
          onChange={(value: any) => callback(value)}
          hasError={hasError}
          type={INPUT_TYPE.SELECT}
          readOnly={isReadOnly}
        />
      </div>
    );
  };

  const getCreatableSelectionFieldWithCallback = (
    title: string,
    value: any,
    options: any[],
    callback: returnFunction,
    hasError = false,
    placeholder = '',
    handleCreate: handleCreate
  ) => {
    const existingVariantsList: any[] = product.attributes
      ? product.attributes
      : [];
    const existingVariant = existingVariantsList.find(
      (variant) => variant?.name === value?.label && variant?.name
    );
    return (
      <div className="parent-width justify-content-between text-align-left">
        <DKLabel className="ml-0" text={title} />
        <div className="mt-0.5"></div>
        <CreatableSelect
          styles={formUtilComponentProductStyle}
          options={options}
          placeholder={placeholder ? placeholder : title}
          value={value}
          onChange={(value) => callback(value)}
          onCreateOption={(value) => handleCreate(value)}
          isDisabled={existingVariant?.id ? true : false}
        />
        {hasError && (
          <p
            style={{ marginTop: 4, marginBottom: 4 }}
            className={'text-xs mx-1  text-red-600'}
          >
            {t('PRODUCTS.DIALOG.PRODUCT.FIELD_EMPTY_ERROR')}
          </p>
        )}
      </div>
    );
  };

  const storeRef = (
    ref: HTMLDivElement | null,
    identifier: PRODUCT_DROPDOWN_FIELDS
  ) => {
    if (ref && identifier !== PRODUCT_DROPDOWN_FIELDS.NONE) {
      elementRefList.current[identifier] = ref;
    }
  };

  const getTextFieldWithCallback = (
    title: string,
    value: any,
    type: string,
    onChange: onChange,
    required = false,
    elementIdentifier: PRODUCT_DROPDOWN_FIELDS = PRODUCT_DROPDOWN_FIELDS.NONE,
    onBlur?: onBlur
  ) => (
    <div
      className="parent-width text-align-left mt-r"
      ref={(ref) => storeRef(ref, elementIdentifier)}
    >
      <DKInput
        required={required}
        canValidate={saveButtonTapped}
        hasError={false}
        type={type}
        title={title}
        value={value}
        placeholder={title}
        onChange={(text: any) => onChange(text)}
        onBlur={onBlur}
      />
    </div>
  );
  const getBasicTextFieldWithCallback = (
    title: string,
    value: any,
    type: string = 'text',
    onChange: onChange,
    onKeyDown: onKeyDown,
    placeholder = ''
  ) => (
    <div className="parent-width text-align-left">
      <div className="column flex flex-col">
        <DKLabel style={{}} text={title} />
        <input
          className={
            'mt-0.5 border rounded bg-gray1 border-gray1 w-full text-sm font-normal tracking-normal placeholder-gray-600::placeholder px-2.5 ' +
            (props.hasError
              ? 'focus:outline-none focus:ring-2 focus:ring-red-500 border-red-400'
              : 'focus:outline-none focus:ring-2 focus:ring-blue-500')
          }
          type={type}
          style={{ minHeight: '37px' }}
          placeholder={placeholder}
          onChange={(event) => {
            onChange(event.target.value);
          }}
          onKeyDown={(event: any) => onKeyDown(event)}
          value={value}
        />
        {props.hasError && (
          <p className={'text-xs mx-1 mt-1 text-red-600'}>
            {'can not be blank'}
          </p>
        )}
      </div>
    </div>
  );

  ////////////////////////////////////////////////////////////////////////
  ///////// UI Calls ////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  const descriptionTextArea = (
    <div className="column parent-width">
      <DKInput
        required={false}
        direction={INPUT_VIEW_DIRECTION.VERTICAL}
        title={t('PRODUCTS.DIALOG.PRODUCT.DESCRIPTION')}
        value={product.description}
        type={INPUT_TYPE.LONG_TEXT}
        className=""
        onChange={(e: string) => updateProductData('description', e)}
      />
    </div>
  );

  const taxPreferences = (
    <div className="column parent-width">
      <div
        className="row row-responsive align-items-center mt-2 parent-width"
        style={{ gap: 20, minHeight: 42 }}
      >
        <DKLabel
          className="fw-m fs-m "
          text={t(`PRODUCTS.DIALOG.PRODUCT.COMPANY_FIELDS.TAX_APPLICABLE`)}
        />
        <DKCheckMark
          className="text-black"
          title={t(`PRODUCTS.DIALOG.PRODUCT.COMPANY_FIELDS.TAXABLE`)}
          isSelected={product.taxPreference}
          onClick={() => {
            updateProductData('taxPreference', true);
            updateProductData('taxExemptionReason', null);
          }}
        />
        <DKCheckMark
          className="text-black"
          title={t(`PRODUCTS.DIALOG.PRODUCT.COMPANY_FIELDS.NON_TAXABLE`)}
          isSelected={!product.taxPreference}
          onClick={() => {
            updateProductData('taxPreference', false);
            updateProductData('taxExemptionReason', 'EXEMPTED');
          }}
        />
        {product.taxPreference && (
          <InputLabel
            className="border-box bg-gray1 border-radius-s p-s border-box text-gray"
            text={t(`PRODUCTS.DIALOG.PRODUCT.COMPANY_FIELDS.CESS_APPLICABLE`)}
          />
        )}
      </div>
      <div style={{ width: '33.3%' }}>
        {!product.taxPreference &&
          getSelectionFieldWithCallback(
            t(`PRODUCTS.DIALOG.PRODUCT.COMPANY_FIELDS.EXEMPTION_REASON`),
            getSelectedValueFromArray(
              exemptedValues,
              product.taxExemptionReason ? product.taxExemptionReason : ''
            ),
            exemptedValues,
            (value: any) => {
              updateProductData('taxExemptionReason', value.value);
            }
          )}
      </div>
    </div>
  );

  const getTDSSection = (
    <div
      className={'column parent-width ' + (product.taxPreference ? 'mt-l' : '')}
    >
      <div
        className="row row-responsive align-items-start parent-width"
        style={{ gap: 20 }}
      >
        <DKLabel
          className="fw-m fs-m "
          text={t(`PRODUCTS.DIALOG.PRODUCT.COMPANY_FIELDS.IS_TDS_APPLICABLE`)}
        />
        <DKCheckMark
          className="text-black"
          title={t(`PRODUCTS.DIALOG.PRODUCT.COMPANY_FIELDS.YES`)}
          isSelected={product.tdsApplicableIndia}
          onClick={() => {
            updateProductData('tdsApplicableIndia', true);
          }}
        />
        <DKCheckMark
          className="text-black"
          title={t(`PRODUCTS.DIALOG.PRODUCT.COMPANY_FIELDS.NO`)}
          isSelected={!product.tdsApplicableIndia}
          onClick={() => {
            updateProductData('tdsApplicableIndia', false);
            updateProductData('tdsNatureOfPaymentIndia', null);
          }}
        />
      </div>
      {product.tdsApplicableIndia && (
        <div
          className="align-items-start justify-content-start mt-2"
          style={{ width: '33.3%' }}
        >
          {getSelectionFieldWithCallback(
            t(
              `PRODUCTS.DIALOG.PRODUCT.COMPANY_FIELDS.NATURE_OF_INCOME_PAYMENT`
            ),
            product.tdsNatureOfPaymentIndia
              ? {
                  label: product.tdsNatureOfPaymentIndia,
                  value: product.tdsNatureOfPaymentIndia
                }
              : null,
            natureOfIncome.map((item: any) => {
              return {
                label: item.natureOfPayment,
                value: item.natureOfPayment
              };
            }),
            (item: any) => {
              updateProductData('tdsNatureOfPaymentIndia', item.value);
            }
          )}{' '}
        </div>
      )}
    </div>
  );

  /**
   * Custom Numbering Format
   */
  const selectedFormat = (selected: any) => {
    /**
     * RECEIVE Selected format {id: "", text: ""}
     */
    setAutoNumberingFormat({ ...selected });
    updateProductData('sequenceFormat', selected.id);
    updateProductData('documentSequenceCode', selected.text);
  };
  /**
   * Custom Numbering Format
   */

  const getSelectedType = () => {
    let filterred = type.filter((item) => {
      return item.value === product.type;
    });
    if (filterred.length > 0) {
      let first = filterred[0];
      return type.indexOf(first);
    } else {
      return -1;
    }
  };
  const getSelectedValuationMethod = () => {
    let filterred = productValuationMethod.filter((item) => {
      return item.value === product?.valuationMethod;
    });
    if (filterred.length > 0) {
      let first = filterred[0];
      return productValuationMethod.indexOf(first);
    } else {
      let tenantValuationMethod =
        tenantInfo?.additionalSettings?.PRODUCT_VALUATION_METHOD;
      let filtered = productValuationMethod.filter((item) => {
        return item.value === tenantValuationMethod;
      });
      if (filtered.length > 0) {
        let first = filtered[0];
        return productValuationMethod.indexOf(first);
      } else {
        let first = productValuationMethod[0];
        return productValuationMethod.indexOf(first);
      }
    }
  };

  const catchClicks = (data: PopupClickActionType) => {
    switch (data.type) {
      case POPUP_CLICK_TYPE.CLOSE_POPUP:
        setAddUomSchemaPopUp(false);
        setAddUomPopUp(false);
        break;
      case POPUP_CLICK_TYPE.ADD_UOM_SCHEMA:
        createProductRef.current.storeCallbacksRef.createUomSchema();
        break;
      case POPUP_CLICK_TYPE.ADD_UOM:
        createProductRef.current.storeCallbacksRef.createUom();
        break;
    }
  };

  const parentChildInteraction = (passingData: CallBackPayloadType) => {
    switch (passingData.type) {
      case POPUP_CALLBACKS_TYPE.ADD_UOM_SCHEMA:
        createProductRef.current.storeCallbacksRef.createUomSchema =
          passingData.data;
        break;
      case POPUP_CALLBACKS_TYPE.ADD_UOM:
        createProductRef.current.storeCallbacksRef.createUom = passingData.data;
        break;
      case POPUP_CALLBACKS_TYPE.CLOSE_POPUP:
        setAddUomSchemaPopUp(false);
        setAddUomPopUp(false);
        break;
      case POPUP_CALLBACKS_TYPE.UOM_SCHEMA_SUCCESS:
        const id = passingData.data.baseUOM.id;
        callUOMSchemesAPI(id);
        break;
      case POPUP_CALLBACKS_TYPE.UOM_SUCCESS:
        break;
    }
  };

  /**
   * PRODUCT IMAGE UPLOAD
   */
  const fetchAttachments = () => {
    const moduleType = MODULES_NAME.PRODUCT;

    let entityId = props?.product?.id;
    if (props.isCopy) {
      entityId = props?.product?.copyId;
    }

    if (!entityId) return;

    AttachmentService.attachmentConfig = {
      ...AttachmentService.attachmentConfig,
      Module: moduleType,
      EntityId: entityId
    };

    AttachmentService.getAllAttachments().then(
      (attachmentList: ProductAttachmentResponse[]) => {
        attachmentList = attachmentList || [];
        const existingProductImageIds = attachmentList.map(
          (attachment) => attachment.attachmentId
        );
        setProductImageAttachmentIds(existingProductImageIds);
        const latestImage = attachmentList.length
          ? attachmentList[attachmentList.length - 1]
          : null;
        if (latestImage) {
          triggerAttachmentDownload(latestImage.attachmentId);
        } else {
          setProductImageLoading(false);
        }
      }
    );
  };

  const fetchProductDocAttachments = () => {
    const moduleType = DOC_TYPE.PRODUCT_DOCUMENT;
    const entityId = props?.product?.id;
    if (!entityId) return;
    AttachmentService.attachmentConfig = {
      ...AttachmentService.attachmentConfig,
      Module: moduleType,
      EntityId: entityId
    };
    showLoader();
    AttachmentService.getAllAttachments().then((attachmentList: any) => {
      if (attachmentList.length) {
        setProductDocAttachments(attachmentList);
      }
      removeLoader();
    });
  };

  const triggerAttachmentUpload = () => {
    let selectedAttachement: File;
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.addEventListener('change', (e) => {
      const target = e.target as HTMLInputElement;
      target?.files &&
        Array.from(target.files).forEach((file: File) => {
          if (file.size && file.size / (1024 * 1024) > 5) {
            showAlert(
              'Attachment size limit exceeded',
              'It seems the file size is more than 5 MB, Please compress the file and try again.'
            );
            return;
          }

          if (!allowedProductImageType.includes(file.type)) {
            showAlert(
              'Attachment type is not valid',
              'Please select only JPEG / PNG images'
            );
            return;
          }

          setProductImage(URL.createObjectURL(file));
          /* To replace existing product image with newly uploaded one */
          setRemoveProductImage(true);
          selectedAttachement = file;
        });

      if (selectedAttachement) {
        setAttachments([selectedAttachement]);
      }
    });
    input.click();
  };

  const uploadAttachmentToAWS = (
    file: File,
    entityId?: any,
    module: any = MODULES_NAME.PRODUCT
  ) => {
    AttachmentService.attachmentConfig = {
      ...AttachmentService.attachmentConfig,
      Module: module,
      EntityId:
        module === DOC_TYPE.PRODUCT_DOCUMENT
          ? ''
          : entityId
          ? entityId
          : props.product && props.product.id
          ? props?.product?.id
          : ''
    };

    if (file.size && file.size / (1024 * 1024) > 5) {
      showAlert(
        'Attachment size limit exceeded',
        'It seems the file size is more than 5 MB, Please compress the file and try again.'
      );
      return;
    }

    showLoader();
    AttachmentService.uploadAttachment(file)
      .then((res) => {
        if (module === DOC_TYPE.PRODUCT_DOCUMENT) {
          let attachments = [...productDocAttachments];
          attachments.push(res);
          setProductDocAttachmentIDs([
            ...productDocAttachmentIDs,
            res.attachmentId
          ]);
          setProductDocAttachments(attachments);
        }
        removeLoader();
      })
      .catch((err) => {
        removeLoader();
        showToast(
          'Something went wrong while uploading the attachment, please try again.'
        );
      });
  };

  const triggerAttachmentDownload = (
    attachmentId: any,
    module: any = MODULES_NAME.PRODUCT,
    attachmentName?: string
  ) => {
    AttachmentService.downloadAttachment(attachmentId)
      .then((absolutePath) => {
        if (module === MODULES_NAME.PRODUCT) {
          setProductImageLoading(false);
          setProductImage(absolutePath);
        } else {
          triggerDownload(null, attachmentName, absolutePath);
        }
      })
      .catch(() => {});
  };

  const removeAndUpdateData = (attachmentId: any) => {
    let attachments = [...productDocAttachments];
    const attachmentForListing = attachments.filter(
      (attachment: any) => attachmentId !== attachment.attachmentId
    );
    const newlyAddedAttachmentsIds = productDocAttachmentIDs.filter(
      (id: any) => attachmentId !== id
    );
    setProductDocAttachmentIDs(newlyAddedAttachmentsIds);
    setProductDocAttachments(attachmentForListing);
    removeLoader();
  };

  const removeAttachment = (
    attachmentId: any,
    module: any = MODULES_NAME.PRODUCT
  ) => {
    if (props.isCopy) {
      removeAndUpdateData(attachmentId);
    } else {
      AttachmentService.deleteAttachment(attachmentId)
        .then((res) => {
          if (module === MODULES_NAME.PRODUCT) {
            setProductImage('');
          } else {
            removeAndUpdateData(attachmentId);
          }
        })
        .catch(() => {});
    }
  };

  const productImageSection = () => {
    return (
      <>
        {Utility.isEmpty(productImage) && (
          <>
            <div className="row justify-content-start flex">
              {/* <div className="row-auto-width mb-xs dkinput-header-section mt-r mr-l ">
                <div className="text-align-left ">Material Image</div>
              </div> */}
              <div
                className={` ${
                  productImageLoading
                    ? 'border bg-chip-gray'
                    : 'border-blue text-blue bg-chip-blue'
                }   p-v-s p-h-r border-radius-s cursor-pointer flex items-center justify-center`}
                style={{
                  border: 'dashed',
                  borderWidth: 1,
                  height: 200,
                  width: 200
                }}
                onClick={() => {
                  if (!productImageLoading) triggerAttachmentUpload();
                }}
              >
                {!productImageLoading && (
                  <DKLabel className="fs-s" text={`+ Add Image`} />
                )}
                {productImageLoading && (
                  <DKSpinner iconClassName="ic-s" className="column pl-0" />
                )}
              </div>
            </div>
          </>
        )}
        {productImage && (
          <div className="row mt-r justify-content-start flex p-v-s">
            {/* <div className="row-auto-width mb-xs dkinput-header-section mt-r mr-l ">
              <div className="text-align-left ">Material Image</div>
            </div> */}
            <div
              className="product-image-section position-relative"
              style={{
                height: 200,
                width: 200
              }}
            >
              <DKIcon
                src={DKIcons.ic_delete}
                className="ic-s cursor-pointer product-image-delete-icon p-xs border-radius-s"
                style={{ right: 0, marginLeft: 'auto' }}
                onClick={() => {
                  if (product.id) {
                    setRemoveProductImage(true);
                  }
                  setAttachments([]);
                  setProductImage('');
                }}
              />
              <img
                onClick={() => {
                  if (!productImageLoading) triggerAttachmentUpload();
                }}
                src={productImage}
                alt=""
                className={`product-image border text-blue border-radius-s cursor-pointer flex items-center justify-center`}
                style={{
                  height: 200,
                  width: 200
                }}
              />
            </div>
          </div>
        )}
      </>
    );
  };

  const triggerProductDocumentAttachmentUpload = () => {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.addEventListener('change', (e) => {
      const target = e.target as HTMLInputElement;
      target?.files &&
        Array.from(target.files).forEach((file: File) =>
          uploadAttachmentToAWS(file, null, DOC_TYPE.PRODUCT_DOCUMENT)
        );
    });
    input.click();
  };

  const getAttachmentPopup = () => {
    return (
      <Attachments
        documentDetails={props?.product}
        documentType={DOC_TYPE.PRODUCT_DOCUMENT}
        onClose={(docId: any, updatedAttachments: any[]) => {
          const docList: any[] = productDocAttachments;
          const docData = docList;
          let refreshList = false;
          if (docData?.length !== updatedAttachments?.length) {
            refreshList = true;
          }
          if (!refreshList && docData) {
            for (let attachment of updatedAttachments) {
              const existingAttachment = docData?.find(
                (att: any) => att.attachmentId === attachment.attachmentId
              );
              if (Utility.isEmpty(existingAttachment)) {
                refreshList = true;
                break;
              }
            }
          }
          if (refreshList) {
            fetchProductDocAttachments();
          }
          if (updatedAttachments?.length === 0) {
            setProductDocAttachments([]);
          }
          setShowAttachmentPopup(false);
        }}
      />
    );
  };

  const attachmentRowData = (attachment: any) => {
    return (
      <div className="row width-auto">
        <div
          className={`row border-m border-radius-s p-h-s p-v-s bg-gray0 justify-content-between`}
          key={attachment.attachmentId}
          style={{ width: 180 }}
        >
          <div className="flex flex-row items-center">
            <DKIcon
              src={DKIcons.ic_document}
              className="column ic-s cursor-pointer opacity-60"
              onClick={() => {
                triggerAttachmentDownload(
                  attachment.attachmentId,
                  DOC_TYPE.PRODUCT_DOCUMENT,
                  attachment.attachmentFileName
                );
              }}
            />
            <DKTooltipWrapper
              content={`${attachment.attachmentFileName}`}
              tooltipClassName="bg-gray0"
            >
              <DKLabel
                text={attachment.attachmentFileName}
                className=" ml-s cursor-pointer border-none"
                style={{
                  maxWidth: 120,
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis'
                }}
                onClick={() => {
                  triggerAttachmentDownload(
                    attachment.attachmentId,
                    DOC_TYPE.PRODUCT_DOCUMENT,
                    attachment.attachmentFileName
                  );
                }}
              />
            </DKTooltipWrapper>
          </div>
          <div className="flex flex-row items-center">
            <DKIcon
              src={DKIcons.ic_delete}
              className="ic-s cursor-pointer opacity-60 hover:opacity-80"
              onClick={() => {
                removeAttachment(
                  attachment.attachmentId,
                  DOC_TYPE.PRODUCT_DOCUMENT
                );
              }}
            />
          </div>
        </div>
      </div>
    );
  };

  const getAttachmentSection = () => {
    return (
      <div
        className="column parent-width border rounded p-2"
        style={{ height: 271 }}
      >
        <DKLabel
          text={`${
            productDocAttachments.length !== 0
              ? 'Attachments (' + productDocAttachments.length + ')'
              : ''
          }`}
          className="fw-m"
        />
        {productDocAttachments?.length === 0 && (
          <div className="column parent-width justify-content-center align-items-center parent-height m-auto">
            <DKLabel text="No attachments" className="text-gray" />
            <DKButton
              className="text-blue fw-m"
              title="+ Add files"
              onClick={() => {
                triggerProductDocumentAttachmentUpload();
              }}
            />
          </div>
        )}
        {productDocAttachments?.length > 0 && (
          <div className="row parent-width align-items-start flex-wrap gap-1 mt-m">
            <div
              className="column align-items-start gap-1 overflow-y-auto"
              style={{ height: 188 }}
            >
              {productDocAttachments.map((attachment: any, index: number) =>
                attachmentRowData(attachment)
              )}
            </div>
            <div className="row justify-content-between">
              <DKButton
                className="text-blue fw-m p-0"
                title="+ Add files"
                style={{ padding: 0 }}
                onClick={() => {
                  triggerProductDocumentAttachmentUpload();
                }}
              />
              {productDocAttachments?.length > 5 && isEdit && (
                <DKButton
                  className="text-blue fw-m p-0 mr-s"
                  title="More"
                  style={{ padding: 0 }}
                  onClick={() => setShowAttachmentPopup(true)}
                />
              )}
            </div>
          </div>
        )}

        {showAttachmentPopup && getAttachmentPopup()}
      </div>
    );
  };

  /**
   * PRODUCT IMAGE UPLOAD
   */

  const getProductTypeString = () => {
    if (product.isFinishedProduct) {
      return 'Finished Material';
    }
    if (product.type === PRODUCT_TYPE.TRACKED) {
      return 'Raw Material';
    }
    if (product.type === PRODUCT_TYPE.BILL_OF_MATERIALS) {
      return 'WIP Material';
    }
    if (product.type === PRODUCT_TYPE.NON_TRACKED) {
      return 'Non-Tracked';
    }
    return '';
  };
  const isAvailableForTransaction = (title: string) => {
    const purchaseCheckFields = [
      'purchase account',
      'purchase price',
      'purchase return account'
    ];
    const salesCheckFields = [
      'sales account',
      'sales price',
      'sales return account'
    ];
    let selectedTransactionType = '';
    if (Utility.isEmpty(product?.id)) {
      selectedTransactionType =
        transactionType[getSelectedTransactionType()].value.toLowerCase();
    } else {
      selectedTransactionType = product.transactionType.toLowerCase();
    }
    switch (selectedTransactionType) {
      case 'purchase':
        return salesCheckFields.includes(title.toLowerCase());
      case 'sales':
        return purchaseCheckFields.includes(title.toLowerCase());
    }
    return false;
  };

  const updateIsLengthCheckVariable = () => {
    if (props.product && props.product.id) {
      if (props?.product?.barcode?.trim().length > BARCODE_LENGTH_ALLOWED) {
        setIsNameLengthCheckApplied(false);
      } else {
        setIsNameLengthCheckApplied(true);
      }
    } else {
      setIsNameLengthCheckApplied(true);
    }
  };

  const generalInfoSection = (
    <div className="column parent-width">
      <div className="row align-items-start gap-3 pt-l">
        <div className="column parent-width gap-2" style={{ width: 250 }}>
          {productImageSection()}
          {getAttachmentSection()}
        </div>
        <div className="column parent-width gap-3">
          <div className="row row-responsive align-items-start parent-width">
            {product.id ? (
              <DKInput
                title={'Material Type'}
                type={INPUT_TYPE.TEXT}
                value={getProductTypeString()}
                direction={INPUT_VIEW_DIRECTION.VERTICAL}
                readOnly={true}
              />
            ) : (
              <div className="parent-width justify-content-between text-align-left">
                <DKInput
                  type={INPUT_TYPE.DROPDOWN}
                  readOnly={true}
                  required={true}
                  title={'Material Type'}
                  direction={INPUT_VIEW_DIRECTION.VERTICAL}
                  value={getProductTypeString()}
                  options={[]}
                  onChange={(value: any) => {
                    updateProductData('type', value?.value);
                    if (value && props.isCopy) {
                      setProductTypeChanged(true);
                    }
                  }}
                  canValidate={saveButtonTapped}
                  dropdownConfig={{
                    title: '',
                    allowSearch: false,
                    className: 'shadow-m width-auto',
                    searchApiConfig: {},
                    data: type,
                    renderer: (index: any, obj: any) => {
                      return <DKLabel text={`${obj.label}`} />;
                    },
                    onSelect: (index: any, obj: any, rowIndex: any) => {}
                  }}
                />
              </div>
            )}
          </div>
          <div className="row row-responsive align-items-start parent-width">
            {product.id ? (
              <div className="parent-width justify-content-between text-align-left">
                <DKInput
                  required={false}
                  canValidate={saveButtonTapped}
                  direction={INPUT_VIEW_DIRECTION.VERTICAL}
                  title={`Transaction Type`}
                  value={[getSelectedTransactionType()]}
                  options={
                    transactionType
                      ? transactionType.map((item: any) => item.label)
                      : []
                  }
                  onChange={(typeObj: any) => {
                    validateAndUpdateProductData(typeObj);
                  }}
                  type={INPUT_TYPE.SELECT}
                />
              </div>
            ) : (
              <div className="parent-width justify-content-between text-align-left">
                <DKInput
                  required={false}
                  canValidate={saveButtonTapped}
                  direction={INPUT_VIEW_DIRECTION.VERTICAL}
                  title={`Transaction Type`}
                  value={
                    getSelectedTransactionType() >= 0
                      ? [getSelectedTransactionType()]
                      : []
                  }
                  options={
                    transactionType
                      ? transactionType.map((item: any) => item.label)
                      : []
                  }
                  onChange={(typeObj: any) => {
                    !Utility.isEmpty(typeObj)
                      ? updateProductData(
                          'productTransactionType',
                          transactionType[typeObj].value
                        )
                      : updateProductData('productTransactionType', null);
                  }}
                  type={INPUT_TYPE.SELECT}
                />
              </div>
            )}
          </div>
          <div className="parent-width position-relative">
            {product.id ? (
              <div className="">
                {ComponentManager.getReadOnlyDKInput(
                  t(
                    `PRODUCTS.DIALOG.PRODUCT.GENERAL_INFO.TYPE.AUTO_NUMBERING_FORMAT`
                  ),
                  product.documentSequenceCode,
                  INPUT_VIEW_DIRECTION.VERTICAL
                )}
              </div>
            ) : (
              <div className="">
                <CustomNumberFormatInput
                  module={CUSTOM_NUMBER_INPUT_MODULES.PRODUCT}
                  selectedFormat={selectedFormat}
                  extraClass={'mt-s top-10 right-1'}
                  isRow={false}
                  autoNumberingFormat={autoNumberingFormat}
                />
              </div>
            )}
          </div>
          <div className="row row-responsive align-items-start parent-width">
            <DKInput
              required={true}
              canValidate={saveButtonTapped}
              type={INPUT_TYPE.TEXT}
              direction={INPUT_VIEW_DIRECTION.VERTICAL}
              title={`Material Name`}
              value={product.name}
              onChange={(text: any) => {
                updateProductData('name', text);
              }}
            />
          </div>
          <div
            className={`row row-responsive align-items-start parent-width ${
              isNameLengthCheckApplied
                ? product.barcode?.trim().length <= BARCODE_LENGTH_ALLOWED
                  ? ''
                  : 'pb-4'
                : ''
            }`}
          >
            <DKInput
              required={false}
              canValidate={true}
              type={INPUT_TYPE.TEXT}
              direction={INPUT_VIEW_DIRECTION.VERTICAL}
              title={t(`PRODUCTS.DIALOG.PRODUCT.GENERAL_INFO.TYPE.BARCODE`)}
              value={product.barcode}
              onChange={(text: any) => {
                updateProductData('barcode', text);
              }}
              validator={(value: string) => {
                if (isNameLengthCheckApplied) {
                  if (value?.trim().length <= BARCODE_LENGTH_ALLOWED) {
                    return true;
                  } else {
                    return false;
                  }
                } else {
                  return true;
                }
              }}
              errorMessage={
                isNameLengthCheckApplied
                  ? product.barcode?.trim().length <= BARCODE_LENGTH_ALLOWED
                    ? ''
                    : `Product barcode length cannot exceed ${BARCODE_LENGTH_ALLOWED} characters.`
                  : ''
              }
            />
          </div>
          {descriptionTextArea}
          {tenantInfo?.landedCostSettings?.landedCost && (
            <div className="column row-responsive align-items-start parent-width mt-r w-12/12">
              <DKLabel
                className="mb-s mt-0 w-80 mr-3"
                text={'Landed Cost Category'}
              />
              <div
                className="row ml-2x bg-dk-input border-radius-s position-relative"
                style={{
                  minHeight: 35,
                  border: '1px solid rgb(235, 235, 235)'
                }}
                onClick={() => {
                  setShowLandedCostCategory(!showLandedCostCategory);
                }}
              >
                {productForm.landedCostCategory?.value?.map(
                  (item: any, index: any) => {
                    return (
                      productForm.landedCostCategory?.options && (
                        <div className="m-xs bg-white p-h-s border-radius-s shadow-m">
                          {item.label}
                        </div>
                      )
                    );
                  }
                )}
                {showLandedCostCategory &&
                  productForm.landedCostCategory?.options && (
                    <DKListPicker2
                      style={{ top: 35, left: -18 }}
                      title=""
                      className="position-absolute z-index-3 shadow-m ml-l"
                      allowSearch={true}
                      searchableKey="label"
                      multiSelect={true}
                      checkMarkColor="bg-blue"
                      selectedIndexes={availabilitySelectedIndexes}
                      data={productForm.landedCostCategory.options}
                      renderer={(index: any, obj: any) => {
                        return <DKLabel text={`${obj.label}`} />;
                      }}
                      onSelect={(index: any, obj: any) => {
                        var selectedIndexes = availabilitySelectedIndexes;
                        let availabilitySelectedIndexesCopy: any = [];
                        if (selectedIndexes.includes(index)) {
                          let indexToDelete = selectedIndexes.indexOf(index);
                          selectedIndexes.splice(indexToDelete, 1);
                          availabilitySelectedIndexesCopy = selectedIndexes;
                        } else {
                          availabilitySelectedIndexesCopy = [
                            ...selectedIndexes,
                            ...[index]
                          ];
                        }
                        let arr: any = [];
                        availabilitySelectedIndexesCopy.forEach(
                          (item: any, index: any) => {
                            if (productForm.landedCostCategory.options)
                              arr.push(
                                productForm.landedCostCategory.options[item]
                              );
                          }
                        );
                        productForm.landedCostCategory.value = arr;
                        setProductForm({ ...productForm });
                        setAvailabilitySelectedIndexes(
                          availabilitySelectedIndexesCopy
                        );
                        setShowLandedCostCategory(false);
                      }}
                      onClear={() => {
                        setAvailabilitySelectedIndexes([]);
                        setShowLandedCostCategory(false);
                      }}
                      onClose={() => {}}
                    />
                  )}
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="parent-width">
        <div
          style={{
            width: '100%'
          }}
        >
          {tableId && productForm.customFields && (
            <div className="column parent-width mt-m">
              <div className="row justify-content-between mb-s">
                <DKLabel text="Custom fields" className="fw-m" />
              </div>
              <div
                className={`row parent-width justify-content-center rounded border pb-2 px-2`}
              >
                <CustomFieldsHolder
                  moduleName={MODULES_NAME.PRODUCT}
                  customFieldsList={productForm.customFields}
                  columnConfig={columnConfig}
                  columnConfigTableId={tableId}
                  documentMode={
                    props.product?.id ? DOCUMENT_MODE.EDIT : DOCUMENT_MODE.NEW
                  }
                  onUpdate={(list) => customFieldUpdated(list)}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );

  const showDefaultPurchaseCheckbox = () => {
    if (
      (product.type === PRODUCT_TYPE.TRACKED ||
        product.type === PRODUCT_TYPE.BILL_OF_MATERIALS) &&
      product?.purchaseAccountCode !==
        defaultAccountValues?.inventorySettings?.inventoryPurchaseAccount
    ) {
      return true;
    } else if (
      product.type === PRODUCT_TYPE.NON_TRACKED &&
      product?.purchaseAccountCode !==
        defaultAccountValues?.inventorySettings?.nonInventoryPurchaseAccount
    ) {
      return true;
    }
    return false;
  };

  const getQuotePriceEditableToggle = () => {
    return (
      <div className="column parent-width pt-l pb-m">
        <DKLine className="mt-2 mb-4" />
        <DKLabel className="text-app-color fw-m fs-m" text={`Other`} />
        <div className="row mt-m">
          <DKLabel
            className=""
            style={{
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              width: 200
            }}
            text={`${Utility.isUSorg() ? 'Estimate' : 'Quote'} Price Editable`}
          />
          <Toggle
            className="ml-2 box-content"
            isSelected={product.quotePriceEditable}
            color="bg-button"
            isOn={product.quotePriceEditable}
            onChange={() => {
              updateProductData(
                'quotePriceEditable',
                !product.quotePriceEditable
              );
            }}
          />
        </div>
      </div>
    );
  };

  const accountingSection = (
    <div className="column parent-width">
      <div className="column parent-width pl-s pr-s">
        <div className="column parent-width">
          <DKLabel
            className="parent-width fw-m fs-m mt-l text-app-color"
            text={'Buy Details'}
          />
          <div className="parent-width justify-content-between text-align-left mt-r">
            {getAccountSelector(
              t(`PRODUCTS.DIALOG.PRODUCT.ACCOUNTING.BUY.PURCHASE_ACCOUNT`),
              'purchaseAccountCode'
            )}
          </div>
          {showDefaultPurchaseCheckbox() && (
            <div className="parent-width flex justify-content-end text-align-right mt-r">
              <DKCheckMark
                title="Set as default account"
                className="mt-s"
                isSelected={futureAccountsApply.inventoryPurchaseAccount}
                onClick={(value: string) => {
                  setFutureAccountApply({
                    ...futureAccountsApply,
                    inventoryPurchaseAccount:
                      !futureAccountsApply.inventoryPurchaseAccount
                  });
                }}
              />
            </div>
          )}
          <div className="parent-width text-align-left mt-r">
            <DKInput
              readOnly={isAvailableForTransaction(
                t(`PRODUCTS.DIALOG.PRODUCT.ACCOUNTING.BUY.PURCHASE_PRICE`)
              )}
              required={false}
              canValidate={saveButtonTapped}
              hasError={false}
              type={INPUT_TYPE.NUMBER}
              title={t(`PRODUCTS.DIALOG.PRODUCT.ACCOUNTING.BUY.PURCHASE_PRICE`)}
              value={
                isFocused === FOCUS_FIELD_KEY.PURCHASE_PRICE
                  ? product.purchasePrice
                  : NumberFormatService.getNumber(
                      parseFloat(product.purchasePrice)
                    )
              }
              onChange={(text: any) => {
                updateProductData('purchasePrice', text);
              }}
              onFocus={() => setIsFocused(FOCUS_FIELD_KEY.PURCHASE_PRICE)}
              onBlur={() => {
                setIsFocused('');
                updateProductData(
                  'purchasePrice',
                  Utility.roundingOff(
                    product?.purchasePrice,
                    tenantInfo.decimalScale
                  )
                );
              }}
            />
          </div>
          {!productForm.compliance.india.defaultPurchaseTax.hidden && (
            <DKCheckMark
              title="Set as Default Purchase Tax"
              className="mt-s"
              isSelected={
                productForm.compliance.india.defaultPurchaseTax.isSelected
              }
              onClick={(value: string) =>
                formToggleChanged(
                  !productForm.compliance.india.defaultPurchaseTax.isSelected,
                  PRODUCT_CHECKBOX_FIELDS.IND_DEFAULT_PURCHASE_TAX
                )
              }
            />
          )}
        </div>
        <DKLine className="mt-xl" />
        <div className="column parent-width">
          <DKLabel
            className="parent-width fw-m fs-m text-app-color mt-l"
            text={'Sell Details'}
          />
          <div
            className="row row-responsive align-items-start mt-2 parent-width align-items-start"
            style={{ gap: 20 }}
          >
            {getAccountSelector(
              t(`PRODUCTS.DIALOG.PRODUCT.ACCOUNTING.SELL.SALES_ACCOUNT`),
              'salesAccountCode'
            )}
          </div>
          {product?.salesAccountCode !==
            defaultAccountValues?.inventorySettings?.salesAccount && (
            <div className="parent-width flex justify-content-end text-align-right mt-r">
              <DKCheckMark
                title="Set as default account"
                className="mt-s"
                isSelected={futureAccountsApply.salesAccount}
                onClick={(value: string) => {
                  setFutureAccountApply({
                    ...futureAccountsApply,
                    salesAccount: !futureAccountsApply.salesAccount
                  });
                }}
              />
            </div>
          )}
          <div className="parent-width text-align-left mt-r">
            <DKInput
              readOnly={isAvailableForTransaction(
                t(`PRODUCTS.DIALOG.PRODUCT.ACCOUNTING.SELL.SALES_PRICE`)
              )}
              required={false}
              canValidate={saveButtonTapped}
              hasError={false}
              type={INPUT_TYPE.NUMBER}
              title={t(`PRODUCTS.DIALOG.PRODUCT.ACCOUNTING.SELL.SALES_PRICE`)}
              value={
                isFocused === FOCUS_FIELD_KEY.SALES_PRICE
                  ? product.salesPrice
                  : NumberFormatService.getNumber(
                      parseFloat(product.salesPrice)
                    )
              }
              onChange={(text: any) => {
                updateProductData('salesPrice', text);
              }}
              onFocus={() => setIsFocused(FOCUS_FIELD_KEY.SALES_PRICE)}
              onBlur={() => {
                setIsFocused('');
                updateProductData(
                  'salesPrice',
                  Utility.roundingOff(
                    product?.salesPrice,
                    tenantInfo.decimalScale
                  )
                );
              }}
            />
          </div>
          {!productForm.compliance.india.defaultSalesTax.hidden && (
            <DKCheckMark
              title="Set as Default Sales Tax"
              className="mt-s"
              isSelected={
                productForm.compliance.india.defaultSalesTax.isSelected
              }
              onClick={(value: string) =>
                formToggleChanged(
                  !productForm.compliance.india.defaultSalesTax.isSelected,
                  PRODUCT_CHECKBOX_FIELDS.IND_DEFAULT_SALES_TAX
                )
              }
            />
          )}
          {tenantInfo.country === 'US' && (
            <DKCheckMark
              className="text-black mt-m"
              title={'Is Taxable'}
              isSelected={product.taxable}
              onClick={() => {
                updateProductData('taxable', !product.taxable);
              }}
            />
          )}
        </div>
        <DKLine className="mt-xl" />
        <div className="column parent-width mt-l gap-2">
          <DKLabel
            className="parent-width fw-m fs-m text-app-color"
            text={t(`PRODUCTS.DIALOG.PRODUCT.ACCOUNTING.RETURN.TITLE`)}
          />
          {getAccountSelector(
            t(`PRODUCTS.DIALOG.PRODUCT.ACCOUNTING.RETURN.SALES_RETURN_ACCOUNT`),
            'salesReturnAccountCode'
          )}
          {getAccountSelector(
            t(
              `PRODUCTS.DIALOG.PRODUCT.ACCOUNTING.RETURN.PURCHASE_RETURN_ACCOUNT`
            ),
            'purchaseReturnAccountCode'
          )}
        </div>
        {product.type === 'BILL_OF_MATERIALS' && (
          <div className="column parent-width pt-l">
            <DKLine className="mt-2 mb-4" />
            <DKLabel
              className="text-app-color fw-m fs-m"
              text={t(
                `PRODUCTS.DIALOG.PRODUCT.ACCOUNTING.RETURN.MANUFACTURING_ACCOUNT`
              )}
            />
            <div
              className="row row-responsive align-items-start mt-2 parent-width"
              style={{ gap: 20 }}
            >
              {getAccountSelector(
                t(
                  `PRODUCTS.DIALOG.PRODUCT.ACCOUNTING.RETURN.MANUFACTURING_ACCOUNT`
                ),
                'manufacturingCostAccountCode'
              )}
            </div>
          </div>
        )}
        {product.type === 'BILL_OF_MATERIALS' &&
          product?.manufacturingCostAccountCode !==
            defaultAccountValues?.inventorySettings?.manufacturingCost && (
            <div className="parent-width flex justify-content-end text-align-right mt-r">
              <DKCheckMark
                title="Set as default account"
                className="mt-s"
                isSelected={futureAccountsApply.manufacturingCost}
                onClick={(value: string) => {
                  setFutureAccountApply({
                    ...futureAccountsApply,
                    manufacturingCost: !futureAccountsApply.manufacturingCost
                  });
                }}
              />
            </div>
          )}
        {Utility.isQuotePriceEnabled() && getQuotePriceEditableToggle()}
      </div>
    </div>
  );

  const getAdvanceTrackingView = (
    <div className="column parent-width">
      <div className="row justify-content-between mt-r">
        <DKLabel
          text={t(
            `PRODUCTS.DIALOG.PRODUCT.INVENTORY.TRACKED_PRODUCT.ADVANCE_TRACKING.TITLE`
          )}
          className="fw-m fs-m text-app-color"
        />
        <div
          className="row width-auto bg-gray1 border-radius-s border-m p-h-s p-v-s"
          style={{ gap: 10 }}
        >
          <DKButton
            title={t(
              `PRODUCTS.DIALOG.PRODUCT.INVENTORY.TRACKED_PRODUCT.ADVANCE_TRACKING.BATCH_TRACKING`
            )}
            className={
              product.advancedTracking === 'BATCH'
                ? 'bg-white border rounded-sm shadow-s-1 fw-m'
                : ''
            }
            style={{
              padding: 5,
              paddingLeft: 10,
              paddingRight: 10
            }}
            onClick={() => {
              if (isEdit) {
                return;
              }
              updateProductData('advancedTracking', 'BATCH');
            }}
          />
          <DKButton
            className={
              product.advancedTracking === 'SERIAL'
                ? 'bg-white border rounded-sm shadow-s-1 fw-m'
                : ''
            }
            style={{
              padding: 5,
              paddingLeft: 10,
              paddingRight: 10
            }}
            title={t(
              `PRODUCTS.DIALOG.PRODUCT.INVENTORY.TRACKED_PRODUCT.ADVANCE_TRACKING.SERIAL_NUMBER`
            )}
            onClick={() => {
              if (isEdit) {
                return;
              }
              updateProductData('advancedTracking', 'SERIAL');
            }}
          />
        </div>
      </div>
      <DKLabel
        className="mt-m text-black"
        text={t(
          `PRODUCTS.DIALOG.PRODUCT.INVENTORY.TRACKED_PRODUCT.ADVANCE_TRACKING.LATER_CREATE_TEXT`
        )}
      />
    </div>
  );

  const uomSchemaOptions = () => {
    const uomSchemaOption: any[] = uomSchems.map((uom: any) => {
      return {
        label: uom.name,
        value: uom.id
      };
    });
    uomSchemaOption.splice(0, 0, {
      label: '+ Add UOM SCHEMA',
      value: 'addUOMSchema'
    });
    return uomSchemaOption;
  };

  const setUOMDetails = () => {
    const uomId = product.stockUom;
    const selectedUom = uomList.find((uom) => uom.id === uomId);
    if (selectedUom) {
      setBaseUOM(selectedUom);
      setTimeout(() => {
        setAddUomSchemaPopUp(true);
      }, 400);
    }
  };

  const getUOMSection = (
    <div className="column mt-6 parent-width">
      <div
        className="row row-responsive parent-width align-items-center"
        style={{ gap: 20 }}
      >
        <DKLabel
          className="fw-m fs-m text-app-color"
          text={t(`PRODUCTS.DIALOG.PRODUCT.INVENTORY.UOM_SCHEMA.TITLE`)}
        />
        <DKCheckMark
          className="text-black"
          title={t(`PRODUCTS.DIALOG.PRODUCT.INVENTORY.UOM_SCHEMA.MULTIPLE_UOM`)}
          isSelected={product.multipleUomSchema}
          onClick={() => {
            updateProductData('multipleUomSchema', !product.multipleUomSchema);
          }}
        />
      </div>
      {product.multipleUomSchema && (
        <div className="row parent-width mt-r">
          {getSelectionFieldWithCallback(
            t(`PRODUCTS.DIALOG.PRODUCT.INVENTORY.UOM_SCHEMA.TITLE`),
            getSelectedUOMValueTest(product.uomSchema, [...uomSchemaOptions()]),
            [...uomSchemaOptions()],
            (value: any) => {
              const optionList: any[] = uomSchemaOptions();
              const option = optionList[value[0]];
              if (value[0] === 0) {
                setUOMDetails();
              } else {
                updateProductData('uomSchema', option?.value);
              }
            },
            product.multipleUomSchema && !product.uomSchema,
            true
          )}
        </div>
      )}
    </div>
  );

  const uomOptions = () => {
    const uomOption: any[] = uomList.map((uom: any) => uom.name);
    uomOption.splice(0, 0, '+ Add UOM');
    return uomOption;
  };

  const nonTrackedUomOptions = () => {
    const uomOption: any[] = uomList.map((uom: any) => {
      return { label: uom.name, value: uom.id };
    });
    uomOption.splice(0, 0, {
      label: '+ Add UOM',
      value: 'addUOM'
    });
    return uomOption;
  };

  const getVendorsList = () => {
    let vendors = contacts?.content?.filter(
      (item: any) => item.status === STATUS_TYPE.ACTIVE && item.vendor === true
    );
    return Utility.isEmpty(vendors) ? [] : vendors;
  };

  const getVendorSelectionField = (
    <div className="parent-width justify-content-between text-align-left mt-r">
      <DKInput
        type={INPUT_TYPE.DROPDOWN}
        required={false}
        title={t(
          `PRODUCTS.DIALOG.PRODUCT.INVENTORY.TRACKED_PRODUCT.PREFERRED_VENDOR`
        )}
        value={selectedVendor ? selectedVendor.name : null}
        options={[]}
        onChange={(value: any) => {
          setSelectedVendor(value);
          updateProductData('reorderVendorCode', value.code);
        }}
        canValidate={saveButtonTapped}
        dropdownConfig={{
          title: 'Contact',
          allowSearch: true,
          searchableKey: 'name',
          style: { minWidth: 150 },
          className: 'shadow-m width-auto',
          searchApiConfig: {
            getUrl: (searchValue: string) => {
              const config: ContactAPIConfig = {
                ...ContactService.apiConfig,
                Page: 0,
                SearchTerm: searchValue,
                Limit: 20,
                IncludeOpeningAmounts: false,
                IncludeOweAmounts: false,
                Query: 'status=active'
              };
              ContactService.apiConfig = config;
              return ContactService.getContactsApiUrl();
            },
            dataParser: (response: any) => {
              return response?.content || [];
            },
            debounceTime: 300
          },
          data: getVendorsList(),
          renderer: (index: any, obj: any) => {
            return <DKLabel text={`${obj.name}`} />;
          },
          onSelect: (index: any, obj: any, rowIndex: any) => {}
          // button: {
          //   title: '+ Add Contact',
          //   className: 'bg-button text-white',
          //   onClick: () => {
          //     setShowAddContactPopup(true);
          //   }
          // }
        }}
      />
    </div>
  );

  const getRowBinRackTitle = (type: string) => {
    let rowRackBinDetails = tenantInfo?.additionalSettings?.ROW_RACK_BIN;
    return (
      'Select ' +
      (rowRackBinDetails.find((data: any) => data.name === type.toUpperCase())
        ?.label || '')
    );
  };

  const getCurrentSelectionData = (): any => {
    if (currentSelection === 'row') {
      return { data: warehouseRows, title: getRowBinRackTitle('row') };
    }
    if (currentSelection === 'rack') {
      return {
        data:
          warehouseRack?.filter(
            (item: any) => item.rowCode === product.inventory.rowCode
          ) || [],
        title: getRowBinRackTitle('rack')
      };
    }
    if (currentSelection === 'bin') {
      let binData = [];
      if (!Utility.isEmptyObject(product.inventory.rackCode)) {
        binData = warehouseBin.filter(
          (item: any) => item.rackCode === product.inventory.rackCode
        );
      } else if (!Utility.isEmptyObject(product.inventory.rowCode)) {
        binData = warehouseBin.filter(
          (item: any) => item.rowCode === product.inventory.rowCode
        );
      } else {
        binData = warehouseBin;
      }

      return {
        data: binData || [],
        title: getRowBinRackTitle('bin')
      };
    }
    return [];
  };

  const stepsForRBB = (step: string) => {
    let rowRackBinDetails = tenantInfo?.additionalSettings?.ROW_RACK_BIN;
    let wareHouseData = selectedWarehouseData;
    if (step === 'row') {
      if (
        rowRackBinDetails.find((data: any) => data.name === 'ROW')?.enable &&
        wareHouseData.warehouseRackInfos?.length > 0
      ) {
        setCurrentSelection('rack');
      } else if (
        rowRackBinDetails.find((data: any) => data.name === 'RACK')?.enable
      ) {
        setCurrentSelection('bin');
      } else if (
        rowRackBinDetails.find((data: any) => data.name === 'ROW')?.enable &&
        !rowRackBinDetails.find((data: any) => data.name === 'RACK')?.enable &&
        wareHouseData.warehouseBinInfos?.length > 0
      ) {
        setCurrentSelection('bin');
      } else {
        setCurrentSelection('undefined');
      }
    }
    if (step === 'rack') {
      if (rowRackBinDetails.find((data: any) => data.name === 'RACK').enable) {
        setCurrentSelection('bin');
      } else {
        setCurrentSelection('undefined');
      }
    }
    if (step === 'bin') {
      setCurrentSelection('undefined');
    }
  };

  const getRRBSelection = () => {
    if (getCurrentSelectionData()?.data?.length === 0) {
      setShowListPicker(false);
    }
    return (
      <DKListPicker2
        data={getCurrentSelectionData()?.data}
        className="position-absolute z-index-3 bg-white border-m"
        style={{
          bottom: 0,
          left: '50%',
          minWidth: 200
        }}
        title={getCurrentSelectionData()?.title}
        renderer={(index: number, value: any) => value.name}
        onSelect={(index: number, value: any) => {
          if (currentSelection === 'row') {
            stepsForRBB('row');
            updateInventory('rowCode', value.code);
          }
          if (currentSelection === 'rack') {
            stepsForRBB('rack');
            updateInventory('rackCode', value.code);
          }
          if (currentSelection === 'bin') {
            stepsForRBB('bin');
            updateInventory('binCode', value.code);
            setShowListPicker(false);
          }
        }}
        onClose={() => {
          setShowListPicker(false);
        }}
        allowSearch={true}
        searchableKey="name"
      />
    );
  };

  const getRowRackBinValue = () => {
    let value = [];
    if (!Utility.isEmptyObject(product?.inventory?.rowCode)) {
      value.push(
        warehouseRows.find(
          (rows: any) => product?.inventory?.rowCode === rows.code
        )?.name || ''
      );
    }
    if (!Utility.isEmptyObject(product?.inventory?.rackCode)) {
      value.push(
        warehouseRack.find(
          (rows: any) => product?.inventory?.rackCode === rows.code
        )?.name || ''
      );
    }
    if (!Utility.isEmptyObject(product?.inventory?.binCode)) {
      value.push(
        warehouseBin.find(
          (rows: any) => product?.inventory?.binCode === rows.code
        )?.name || ''
      );
    }
    return value?.filter((item) => item)?.length !== 0
      ? value?.toString()
      : 'Select Row Rack Bin';
  };

  const getRowRackBinSelection = () => {
    let wareHouseData = selectedWarehouseData;
    return (
      <div
        className={`row justify-content-between text-align-left mt-r position-relative overflow-visible ${
          getWarehouseFieldDataAvailable() ? 'mb-l' : ''
        }`}
      >
        {showListPicker && getRRBSelection()}
        <div style={{}}></div>
        <DKInput
          readOnly={true}
          value={getRowRackBinValue()}
          title={'Row Rack Bin'}
          direction={INPUT_VIEW_DIRECTION.HORIZONTAL}
          formatter={(obj: any) => {
            return obj;
          }}
          type={INPUT_TYPE.TEXT}
          required={false}
          onChange={(value: any) => {}}
          valueStyle={{
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
            borderRight: 'none'
          }}
        />
        <DKButton
          title={'Select'}
          onClick={() => {
            if (
              wareHouseData.warehouseRowInfos.length > 0 ||
              wareHouseData.warehouseRackInfos.length > 0
            ) {
              setCurrentSelection(
                wareHouseData.warehouseRowInfos.length > 0 ? 'row' : 'rack'
              );
            } else if (wareHouseData.warehouseBinInfos.length > 0) {
              setCurrentSelection('bin');
            }
            setShowListPicker(true);
          }}
          className="fw-m text-white border-m"
          style={{
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
            borderTopRightRadius: 4,
            borderBottomRightRadius: 4,
            paddingTop: 8,
            paddingBottom: 9,
            borderColor: 'var(--btn-bg-color)',
            backgroundColor: 'var(--btn-bg-color)'
          }}
        />
      </div>
    );
  };

  const getLeadTimeValue = () => {
    let leadTimeDetails = product?.leadTimeDetails ?? [];
    return leadTimeDetails?.[0]?.leadTime || 0;
  };

  const getLeadTimeField = () => {
    return (
      <div className="parent-width justify-content-between text-align-left">
        <DKInput
          required={false}
          canValidate={false}
          readOnly={false}
          title={'Lead Time (In Days)'}
          value={getLeadTimeValue()}
          onChange={(value: any) => {
            let nonDecimalValue = value.replace(/[^0-9]*/g, '');
            let copyPrpduct = { ...product };
            let copyLeadTimeDetails = copyPrpduct?.leadTimeDetails ?? [];
            copyLeadTimeDetails[0] = {
              leadTime: nonDecimalValue
            };
            copyPrpduct.leadTimeDetails = copyLeadTimeDetails;
            setProduct(copyPrpduct);
          }}
          type={INPUT_TYPE.NUMBER}
        />
      </div>
    );
  };

  const getRestockingAndMinBuildQtyFields = () => {
    let restockLevel = product?.restockLevel;
    let restockBuildQuantity = product?.restockBuildQuantity;
    return (
      <div className="parent-width justify-content-between text-align-left">
        <DKInput
          required={false}
          canValidate={false}
          readOnly={false}
          title={'Restock level'}
          value={restockLevel}
          onChange={(value: any) => {
            if (isNaN(value) || value === '') {
              updateProductData('restockLevel', null);
            } else {
              updateProductData('restockLevel', value);
            }
          }}
          onBlur={() => {
            let copyProduct = { ...product };
            if (copyProduct.restockLevel !== null) {
              copyProduct.restockLevel = Utility.roundingOff(
                copyProduct.restockLevel || 0,
                QTY_ROUNDOFF_PRECISION
              );
            }
            setProduct(copyProduct);
          }}
          type={INPUT_TYPE.NUMBER}
        />

        <DKInput
          required={false}
          canValidate={false}
          readOnly={false}
          className="mt-r"
          title={'Restock build quantity'}
          value={restockBuildQuantity}
          onChange={(value: any) => {
            let copyPrpduct = { ...product };
            copyPrpduct.restockBuildQuantity = value;
            setProduct(copyPrpduct);
          }}
          onBlur={() => {
            let copyPrpduct = { ...product };
            copyPrpduct.restockBuildQuantity = Utility.roundingOff(
              copyPrpduct.restockBuildQuantity || 0,
              QTY_ROUNDOFF_PRECISION
            );
            setProduct(copyPrpduct);
          }}
          type={INPUT_TYPE.NUMBER}
        />
      </div>
    );
  };

  const trackedProductInventoryView = (
    <div className="column parent-width pl-s pr-s gap-2">
      <DKLabel
        className="parent-width fw-m fs-m mt-l text-app-color"
        text={t(`PRODUCTS.DIALOG.PRODUCT.INVENTORY.TRACKED_PRODUCT.TITLE`)}
      />

      <div className="parent-width justify-content-between text-align-left mt-r">
        <DKInput
          required={false}
          canValidate={saveButtonTapped}
          title={t(
            `PRODUCTS.DIALOG.PRODUCT.INVENTORY.TRACKED_PRODUCT.UNIT_OF_MEASUREMENT`
          )}
          value={
            getSelectedUOMValue(product.stockUom, [
              { id: 'New UOM' },
              ...uomList
            ]) >= 0
              ? [
                  getSelectedUOMValue(product.stockUom, [
                    { id: 'New UOM' },
                    ...uomList
                  ])
                ]
              : []
          }
          options={!Utility.isEmpty(uomList) ? uomOptions() : []}
          onChange={(value: any) => {
            if (value[0] === 0) {
              setAddUomPopUp(true);
            } else if (value.length === 0) {
              updateProductData('stockUom', null);
            } else {
              const uomName = uomOptions()[value[0]];
              const selectedUOM = uomList.find((uom) => uom.name === uomName);
              if (selectedUOM) {
                const uomID = selectedUOM.id;
                updateProductData('stockUom', uomID);
                uomID && callUOMSchemesAPI(uomID);
              }
            }
          }}
          type={INPUT_TYPE.SELECT}
          readOnly={isEdit}
        />
      </div>
      {getAccountSelector(
        t(
          `PRODUCTS.DIALOG.PRODUCT.INVENTORY.TRACKED_PRODUCT.COST_OF_GOODS_SOLD_ACCOUNT`
        ),
        'costOfGoodsSoldAccountCode',
        getSelectedAccountValue(
          getNonEmptyInventoryValue('costOfGoodsSoldAccountCode')
        ),
        (value: any) => {
          updateInventory('costOfGoodsSoldAccountCode', value.value);
        }
      )}
      {product?.inventory?.costOfGoodsSoldAccountCode !==
        defaultAccountValues?.inventorySettings?.costOfGoodsSoldAccount && (
        <div className="parent-width flex justify-content-end text-align-right mt-r">
          <DKCheckMark
            title="Set as default account"
            className="mt-s"
            isSelected={futureAccountsApply.costOfGoodsSoldAccount}
            onClick={(value: string) => {
              setFutureAccountApply({
                ...futureAccountsApply,
                costOfGoodsSoldAccount:
                  !futureAccountsApply.costOfGoodsSoldAccount
              });
            }}
          />
        </div>
      )}
      {getAccountSelector(
        t(
          `PRODUCTS.DIALOG.PRODUCT.INVENTORY.TRACKED_PRODUCT.INVENTORY_ACCOUNT`
        ),
        'inventoryAccountCode',
        getSelectedAccountValue(
          getNonEmptyInventoryValue('inventoryAccountCode')
        ),
        (value: any) => {
          updateInventory('inventoryAccountCode', value.value);
        }
      )}
      {product?.inventory?.inventoryAccountCode !==
        defaultAccountValues?.inventorySettings?.inventoryAccount && (
        <div className="parent-width flex justify-content-end text-align-right">
          <DKCheckMark
            title="Set as default account"
            className=""
            isSelected={futureAccountsApply.inventoryAccount}
            onClick={(value: string) => {
              setFutureAccountApply({
                ...futureAccountsApply,
                inventoryAccount: !futureAccountsApply.inventoryAccount
              });
            }}
          />
        </div>
      )}
      {getAccountSelector(
        'WIP Inventory Account',
        'wipInventoryAccountCode',
        getSelectedAccountValue(
          getNonEmptyInventoryValue('wipInventoryAccountCode')
        ),
        (value: any) => {
          updateInventory('wipInventoryAccountCode', value.value);
        }
      )}

      {product?.inventory?.wipInventoryAccountCode !==
        defaultAccountValues?.inventorySettings?.wipInventoryAccount && (
        <div className="parent-width flex justify-content-end text-align-right mt-r">
          <DKCheckMark
            title="Set as default account"
            className=""
            isSelected={futureAccountsApply.wipInventoryAccount}
            onClick={(value: string) => {
              setFutureAccountApply({
                ...futureAccountsApply,
                wipInventoryAccount: !futureAccountsApply.wipInventoryAccount
              });
            }}
          />
        </div>
      )}

      {getAccountSelector(
        t(
          `PRODUCTS.DIALOG.PRODUCT.INVENTORY.TRACKED_PRODUCT.STOCK_ADJUSTMENT_ACCOUNT`
        ),
        'stockAdjustmentAccountCode',
        getSelectedAccountValue(
          getNonEmptyInventoryValue('stockAdjustmentAccountCode')
        ),
        (value: any) => {
          updateInventory('stockAdjustmentAccountCode', value.value);
        }
      )}
      {product?.inventory?.stockAdjustmentAccountCode !==
        defaultAccountValues?.inventorySettings?.stockAdjustmentAccount && (
        <div className="parent-width flex justify-content-end text-align-right mt-r">
          <DKCheckMark
            title="Set as default account"
            className="mt-s"
            isSelected={futureAccountsApply.stockAdjustmentAccount}
            onClick={(value: string) => {
              setFutureAccountApply({
                ...futureAccountsApply,
                stockAdjustmentAccount:
                  !futureAccountsApply.stockAdjustmentAccount
              });
            }}
          />
        </div>
      )}
      {product.type !== 'BILL_OF_MATERIALS' && (
        <div className="column parent-width">
          {getTextFieldWithCallback(
            t(
              `PRODUCTS.DIALOG.PRODUCT.INVENTORY.TRACKED_PRODUCT.REORDER_LEVEL`
            ),
            product.reorderLevel,
            INPUT_TYPE.NUMBER,
            (text) => {
              if (/^[0-9\b]+$/.test(text)) {
                updateProductData('reorderLevel', text);
              } else {
                updateProductData('reorderLevel', null);
              }
            }
          )}
          {getTextFieldWithCallback(
            t(
              `PRODUCTS.DIALOG.PRODUCT.INVENTORY.TRACKED_PRODUCT.REORDER_QUANTITY`
            ),
            product.reorderQuantity,
            INPUT_TYPE.NUMBER,
            (text) => {
              if (/^[0-9\b]+$/.test(text)) {
                updateProductData('reorderQuantity', text);
              }
            }
          )}
          {getVendorSelectionField}
          {getTextFieldWithCallback(
            'Weight',
            product.inventory && product.inventory.landedCostWeight,
            INPUT_TYPE.NUMBER,
            (value) => {
              if (Number(value) >= 0) {
                updateInventory('landedCostWeight', value);
              } else {
                showToast(
                  'Please enter non negative number',
                  TOAST_TYPE.FAILURE
                );
              }
            }
          )}
        </div>
      )}

      <div className="parent-width justify-content-between text-align-left">
        <DKInput
          required={false}
          canValidate={false}
          readOnly={isProductInUse}
          title={'Valuation Method'}
          value={
            getSelectedValuationMethod() >= 0
              ? [getSelectedValuationMethod()]
              : []
          }
          options={
            productValuationMethod
              ? productValuationMethod.map((item: any) => item.label)
              : []
          }
          onChange={(typeObj: any) => {
            !Utility.isEmpty(typeObj)
              ? updateProductData(
                  'valuationMethod',
                  productValuationMethod[typeObj].value
                )
              : updateProductData('valuationMethod', null);
          }}
          type={INPUT_TYPE.SELECT}
        />
      </div>

      {getLeadTimeField()}

      {product.type === PRODUCT_TYPE.BILL_OF_MATERIALS &&
        getRestockingAndMinBuildQtyFields()}

      <DKLine className="mt-xl" />
      <DKCheckMark
        className="text-black parent-width mt-xl"
        title={t(
          `PRODUCTS.DIALOG.PRODUCT.INVENTORY.TRACKED_PRODUCT.ENABLE_ADVANCED_TRACKING`
        )}
        isSelected={product.advancedTracking !== 'NONE'}
        onClick={() => {
          if (isEdit) {
            return;
          }
          if (product.advancedTracking === 'NONE') {
            updateProductData('advancedTracking', 'BATCH');
            updateProductData('multipleUomSchema', false);
            updateProductData('uomSchema', null);
          } else {
            updateProductData('advancedTracking', 'NONE');
          }
        }}
      />
      {getAdvanceTrackingView}
      {showUomCheckbox ? getUOMSection : ''}
      <DKLine className="mt-xl" />
      <div className="column parent-width mt-6">
        <DKLabel
          className="parent-width fw-m fs-m text-app-color"
          text={t(`PRODUCTS.DIALOG.PRODUCT.INVENTORY.OPENING_BALANCE.TITLE`)}
        />

        {getTextFieldWithCallback(
          t(
            `PRODUCTS.DIALOG.PRODUCT.INVENTORY.OPENING_BALANCE.OPENING_QUANTITY`
          ),
          product.inventory && product.inventory.openingQuantity,
          INPUT_TYPE.NUMBER,
          (value: string) => {
            if (Number(value) >= 0) {
              updateInventory('openingQuantity', value);
            } else {
              showToast('Please enter non negative number', TOAST_TYPE.FAILURE);
            }
          },
          product.type === 'TRACKED' || product.type === 'BILL_OF_MATERIALS',
          PRODUCT_DROPDOWN_FIELDS.OPENING_QUANITIY,
          () => {
            updateInventory(
              'openingQuantity',
              Utility.roundingOff(
                product?.inventory?.openingQuantity || 0,
                QTY_ROUNDOFF_PRECISION
              )
            );
          }
        )}
        <div
          className="parent-width text-align-left mt-r"
          onBlur={() => {
            setIsFocused('');
          }}
        >
          <DKInput
            required={true}
            canValidate={saveButtonTapped}
            hasError={false}
            type={INPUT_TYPE.NUMBER}
            title={t(
              `PRODUCTS.DIALOG.PRODUCT.INVENTORY.OPENING_BALANCE.OPENING_VALUATION`
            )}
            value={
              isFocused === FOCUS_FIELD_KEY.OPENING_VALUATION
                ? product?.inventory && product.inventory.openingValuation
                : product?.inventory?.openingValuation
                ? NumberFormatService.getNumber(
                    parseFloat(product?.inventory?.openingValuation)
                  )
                : product?.inventory?.openingValuation
            }
            onChange={(text: any) => {
              if (Number(text) >= 0) {
                updateInventory('openingValuation', text);
              } else {
                showToast('Enter non negative number', TOAST_TYPE.FAILURE);
              }
            }}
            onFocus={() => setIsFocused(FOCUS_FIELD_KEY.OPENING_VALUATION)}
            onBlur={() => {
              setIsFocused('');
              updateInventory(
                'openingValuation',
                Utility.roundingOff(
                  product?.inventory?.openingValuation,
                  tenantInfo.decimalScale
                )
              );
            }}
          />
        </div>
        <div
          className={`parent-width justify-content-between text-align-left mt-r ${
            !getWarehouseFieldDataAvailable() ? 'mb-l' : ''
          }`}
        >
          <DKInput
            className=""
            required={true}
            canValidate={saveButtonTapped}
            title={t(
              `PRODUCTS.DIALOG.PRODUCT.INVENTORY.OPENING_BALANCE.WHAREHOUSE`
            )}
            value={selectedWarehouseData}
            renderer={(obj: any) => obj.name}
            onChange={(value: any) => {
              !Utility.isEmpty(value)
                ? updateInventory('warehouseCode', value)
                : updateInventory('warehouseCode', null);
            }}
            type={INPUT_TYPE.DROPDOWN}
            dropdownConfig={{
              style: { minWidth: 230 },
              className: 'shadow-m',
              title: 'Select Warehouse',
              allowSearch: true,
              searchableKey: 'name',
              canEdit: false,
              canDelete: false,
              data: (warehousesData?.content || []).filter(
                (warehouse: any) =>
                  warehouse &&
                  warehouse.active &&
                  warehouse.warehouseType === 'NONE'
              ),
              renderer: (index: any, warehouse: any) => {
                return warehouse.name;
              },
              searchApiConfig: {
                getUrl: (searchValue: string) => {
                  const query: string = `?limit=50&page=0&filterOnRolesFlg=false&search=${searchValue}&allRRBDetails=true&query=active=true`;
                  const finalEndPoint =
                    ApiConstants.URL.BASE +
                    ApiConstants.URL.ACCOUNTS.WAREHOUSES +
                    query;
                  return finalEndPoint;
                },
                dataParser: (response: any) => {
                  var whData = (response?.content || []).filter(
                    (warehouse: any) =>
                      warehouse &&
                      warehouse.active &&
                      warehouse.warehouseType === 'NONE'
                  );
                  return whData;
                },
                debounceTime: 300
              }
            }}
          />
        </div>

        {getWarehouseFieldDataAvailable() && getRowRackBinSelection()}

        {/* {getSelectionFieldWithCallback(
            t(`PRODUCTS.DIALOG.PRODUCT.INVENTORY.OPENING_BALANCE.WHAREHOUSE`),
            getSelectedWarehousesValue(
              product.inventory && product.inventory.warehouseCode
            ),
            getActiveWarehouses(),
            (value: any) => {
              !Utility.isEmpty(value)
                ? updateInventory('warehouseCode', warehouses.content[value].code)
                : updateInventory('warehouseCode', null);
            }
          )} */}
      </div>
    </div>
  );

  const nonTrackedProductInventoryView = (
    <div className="column mt-l parent-width pl-s pr-s">
      {showUomCheckbox && (
        <>
          <DKLabel
            className="fw-m fs-m text-app-color"
            text={t(`PRODUCTS.DIALOG.PRODUCT.INVENTORY.UOM_SCHEMA.TITLE`)}
          />
          <DKCheckMark
            className="mt-l text-black"
            title={t(
              `PRODUCTS.DIALOG.PRODUCT.INVENTORY.UOM_SCHEMA.MULTIPLE_UOM`
            )}
            isSelected={product.multipleUomSchema}
            onClick={() => {
              updateProductData(
                'multipleUomSchema',
                !product.multipleUomSchema
              );
            }}
          />
        </>
      )}
      <div
        className="column align-items-start parent-width mt-2"
        style={{ gap: 20 }}
      >
        {getSelectionFieldWithCallback(
          t(
            `PRODUCTS.DIALOG.PRODUCT.INVENTORY.TRACKED_PRODUCT.UNIT_OF_MEASUREMENT`
          ),
          getSelectedUOMValue(product.stockUom, [
            { id: 'New UOM' },
            ...uomList
          ]) >= 0
            ? [
                getSelectedUOMValue(product.stockUom, [
                  { id: 'New UOM' },
                  ...uomList
                ])
              ]
            : [],
          !Utility.isEmpty(uomList) ? nonTrackedUomOptions() : [],
          (value: any) => {
            if (!Utility.isEmpty(value)) {
              if (value[0] === 0) {
                setAddUomPopUp(true);
              } else {
                const uomName = uomOptions()[value[0]];
                const selectedUOM = uomList.find((uom) => uom.name === uomName);
                if (selectedUOM) {
                  const uomID = selectedUOM.id;
                  updateProductData('stockUom', uomID);
                  uomID && callUOMSchemesAPI(uomID);
                }
              }
            } else {
              updateProductData('stockUom', null);
            }
          },
          false,
          false,
          isEdit
        )}

        {product.multipleUomSchema &&
          showUomCheckbox &&
          getSelectionFieldWithCallback(
            t(`PRODUCTS.DIALOG.PRODUCT.INVENTORY.UOM_SCHEMA.TITLE`),
            getSelectedUOMValueTest(product.uomSchema, [...uomSchemaOptions()]),
            [...uomSchemaOptions()],
            (value: any) => {
              if (!Utility.isEmpty(value)) {
                const optionList: any[] = uomSchemaOptions();
                const option = optionList[value[0]];
                if (value[0] === 0) {
                  setAddUomSchemaPopUp(true);
                } else {
                  updateProductData('uomSchema', option?.value);
                }
              } else {
                updateProductData('uomSchema', null);
              }
            },
            product.multipleUomSchema && !product.uomSchema,
            showUomCheckbox
          )}
      </div>

      {!showUomCheckbox && (
        <DKCheckMark
          title="Is Auto Fulfill"
          className="mt-m"
          isSelected={productForm.isAutoFulfillEnabled}
          onClick={(value: string) =>
            formToggleChanged(
              !productForm.isAutoFulfillEnabled,
              PRODUCT_CHECKBOX_FIELDS.AUTO_FULFILL
            )
          }
        />
      )}
    </div>
  );

  const getAttributeValueContainer = (index: any) => {
    return attributeMapping[index].values.map((value: any, i: any) => {
      return (
        <div
          className="row fs-m border-radius-s bg-chip-blue m-s"
          style={{
            width: 'auto',
            paddingTop: 2,
            paddingBottom: 2,
            paddingLeft: 6,
            paddingRight: 6
          }}
        >
          <div className="row parent-width">
            <label className="display-block text-blue">{value}</label>
            {!attributeMapping[index].isExisting && (
              <DKButton
                title="X"
                className="ml-s text-white bg-gray-300 border-radius-l p-1 attrValContainer"
                onClick={() => removeAttributeValue(index, i)}
              />
            )}
          </div>
        </div>
      );
    });
  };

  const getSelectedAttributeValue = (typeArray: any[], value: any) => {
    let filterred = typeArray.filter((item) => {
      return item.value === value;
    });
    if (filterred.length > 0) {
      let first = filterred[0];
      return first;
    } else {
      return null;
    }
  };

  const getAttributeFields = () => {
    //here
    const attrContainer = attributeMapping.map((value: any, index: any) => {
      return (
        <div className="parent-width">
          <div className="flex parent-width pt-4">
            <div style={{ width: '20rem' }}>
              {getCreatableSelectionFieldWithCallback(
                'Attribute Name',
                getSelectedAttributeValue(attributeList, value.id),
                attributeList,
                (typeObj: any) => {
                  addNewAttribute(index, typeObj.value);
                },
                product.attributes &&
                  product.attributes.length > 0 &&
                  product.attributes[index] &&
                  !product.attributes[index].id &&
                  saveButtonTapped,
                'Add New Attribute',
                (val: any) => {
                  createNewAttribute(val);
                }
              )}
            </div>
            <div className="column ml-xl mb-0.5" style={{ width: '20rem' }}>
              {getBasicTextFieldWithCallback(
                'Value',
                attributeFieldVal[index] ?? '',
                INPUT_TYPE.TEXT,
                (text: string) => {
                  var tmpAttr = [...attributeFieldVal];
                  tmpAttr[index] = text;
                  setAttributeFieldVal(tmpAttr);
                },
                (event: Event) => {
                  addAttributeValue(event, index);
                },
                'Enter attribute value, separate by enter or comma'
              )}
            </div>
          </div>
          <div className="row  border-b">
            <div className="column parent-width">
              <div className="row parent-width mb-0.5">
                <div className="row flex-wrap">
                  {getAttributeValueContainer(index)}
                </div>
              </div>
            </div>
            <div className="ml-m">
              <DKButton
                className="bg-gray1"
                onClick={() => removeAttribute(index)}
                icon={DKIcons.ic_delete}
              />
            </div>
          </div>
        </div>
      );
    });
    return (
      <div className="column parent-width mt-1 gap-2">{attrContainer}</div>
    );
  };

  const getVariantFields = () => (
    <div className="column parent-width gap-2">
      {getAttributeFields()}
      {attributeMapping.length < 3 && (
        <DKButton
          onClick={() => {
            addAttributes();
          }}
          title="+ Add Attributes"
          className={`bg-gray1 border-m ${
            attributeMapping.length > 0 ? 'mt-s' : ''
          }`}
        />
      )}
      <p className="text-align-left mt-1">
        {t(`PRODUCTS.DIALOG.PRODUCT.VARIANTS.VARIANT_DESCRIPTION`)}
      </p>
    </div>
  );

  const variantsSection = (
    <div className="column parent-width gap-2">
      <DKCheckMark
        className="mt-l text-black parent-width"
        title={t(`PRODUCTS.DIALOG.PRODUCT.VARIANTS.MULTIPLE_VARIANT_INFO`)}
        isSelected={product.hasVariants}
        onClick={() => {
          variantClicked();
        }}
        disabled={isEdit && product.hasVariants}
      />
      {product.hasVariants && getVariantFields()}
    </div>
  );

  const getSubstituteProductField = () => {
    return (
      <SubstituteProductTabView
        product={product}
        onUpdate={(data: any) => {
          setProduct(data);
        }}
      />
    );
  };

  const productsData = useAppSelector(selectMRPProducts);

  const [selectedComponentProductIndex, setSelectedComponentProductIndex] =
    useState<any>();
  const [showSubstituteProductView, setShowSubstituteProductView] =
    useState(false);

  const inventorySection = (
    <div className="column parent-width">
      {product.type === 'TRACKED' || product.type === 'BILL_OF_MATERIALS'
        ? trackedProductInventoryView
        : nonTrackedProductInventoryView}
    </div>
  );

  const addAttributes = () => {
    var attribute = {
      id: null,
      values: []
    };
    var list = [...attributeMapping];
    list.push(attribute);
    setAttributeMapping(list);
  };

  const addAttributeValue = (event: any, index: any) => {
    if (attributeMapping[index].values.length > 33) {
      return;
    }
    if (event.type === 'keydown') {
      if (event.keyCode === 13 || event.keyCode === 188) {
        event.preventDefault();
        if (event.target.value !== '') {
          const tmpAttr = deepClone(attributeMapping);
          const duplicate = tmpAttr[index].values.find(
            (value: any) => value === event.target.value
          );
          if (duplicate) {
            showToast('Attribute value is already present', TOAST_TYPE.FAILURE);
            return;
          }
          tmpAttr[index].values.push(event.target.value);

          setAttributeMapping(tmpAttr);
          var tmpAttrField = [...attributeFieldVal];
          tmpAttrField[index] = '';
          setAttributeFieldVal(tmpAttrField);
        }
      }
    }
  };

  const removeAttributeValue = (index: any, i: any) => {
    var tmpAttr = [...attributeMapping];
    tmpAttr[index].values.splice(i, 1);
    setAttributeMapping(tmpAttr);
  };

  const removeAttribute = (index: any) => {
    var tmpAttr = [...attributeMapping];
    if (
      isEdit &&
      props.product.attributes &&
      props.product.attributes.length > 0
    ) {
      if (index <= props.product.attributes.length - 1) {
        if (props.product.variantCount > 0) {
          showAlert('Warning', 'Not allow to delete existing attribute');
          return;
        }
      }
    }
    tmpAttr.splice(index, 1);
    setAttributeMapping(tmpAttr);
  };

  const addNewAttribute = (index: any, value: number) => {
    const tmpAttr = deepClone(attributeMapping);
    const duplicate = tmpAttr.find((att: any) => att.id === value);
    if (duplicate) {
      showToast('Attribute is already added', TOAST_TYPE.FAILURE);
      return;
    }
    tmpAttr[index].id = value;
    setAttributeMapping(tmpAttr);
  };

  const createNewAttribute = (value: string) => {
    //fire create new attribute api
    if (value !== '') {
      var data = {
        name: value
      };
      ProductService.createAttribute(data)
        .then((res) => {
          dispatch(fetchAttributeList());
        })
        .catch((error: any) => {});
    }
    var tmpAttr = [...attributeList];
    var newAttr = { label: value, value: value };
    tmpAttr.push(newAttr);
    setAttributeList(tmpAttr);
  };

  const scrollToElment = (elementIdentifier: PRODUCT_DROPDOWN_FIELDS) => {
    if (!Utility.isEmpty(elementRefList.current)) {
      setTimeout(() => {
        elementRefList.current[elementIdentifier].scrollIntoView({
          behavior: 'smooth',
          block: 'start'
        });
      }, 200);
    }
  };

  const validateForm = () => {
    if (Utility.isEmpty(product.type)) {
      showToast('Please select type of product.', TOAST_TYPE.FAILURE);
      setSelectedSegment(0);
      return false;
    } else if (
      Utility.isEmpty(product.name) ||
      product?.name?.toString().trim().length === 0
    ) {
      setSelectedSegment(0);
      return false;
    } else if (
      product?.barcode?.toString().trim().length > BARCODE_LENGTH_ALLOWED
    ) {
      setSelectedSegment(0);
      showAlert(
        '',
        `Product barcode length cannot exceed ${BARCODE_LENGTH_ALLOWED} characters.`
      );
      return false;
    } else if (Utility.isEmpty(product.salesAccountCode)) {
      setSelectedSegment(1);
      return false;
    } else if (Utility.isEmpty(product.purchaseAccountCode)) {
      setSelectedSegment(1);
      return false;
    } else if (Utility.isEmpty(product.purchaseReturnAccountCode)) {
      setSelectedSegment(1);
      return false;
    } else if (
      product.type === 'BILL_OF_MATERIALS' &&
      Utility.isEmpty(product.manufacturingCostAccountCode)
    ) {
      setSelectedSegment(1);
      return false;
    } else if (product.type === 'TRACKED' && !product.stockUom) {
      showToast('UOM cannot be left Emtpty.', TOAST_TYPE.FAILURE);
      setSelectedSegment(2);
      return false;
    } else if (
      product.type === 'TRACKED' &&
      Utility.isEmpty(product.inventory.costOfGoodsSoldAccountCode)
    ) {
      setSelectedSegment(2);
      return false;
    } else if (
      product.type === 'TRACKED' &&
      Utility.isEmpty(product.inventory.inventoryAccountCode)
    ) {
      setSelectedSegment(2);
      return false;
    } else if (
      (product.type === PRODUCT_TYPE.TRACKED ||
        product.type === PRODUCT_TYPE.BILL_OF_MATERIALS) &&
      Utility.isEmpty(product.inventory.wipInventoryAccountCode)
    ) {
      setSelectedSegment(2);
      return false;
    } else if (
      product.type === 'TRACKED' &&
      Utility.isEmpty(product.inventory.stockAdjustmentAccountCode)
    ) {
      setSelectedSegment(2);
      return false;
    } else if (
      product.type === 'TRACKED' &&
      Utility.isEmpty(selectedWarehouseData)
    ) {
      setSelectedSegment(2);
      return false;
    } else if (
      product.type === 'TRACKED' ||
      product.type === 'BILL_OF_MATERIALS'
    ) {
      if (product.inventory && product.inventory.openingQuantity === '') {
        scrollToElment(PRODUCT_DROPDOWN_FIELDS.OPENING_QUANITIY);
        setSelectedSegment(2);
        return false;
      }

      if (product?.inventory?.openingQuantity > 0) {
        if (
          product.inventory.openingValuation === '' ||
          (product.inventory.openingValuation &&
            product.inventory.openingValuation < 0)
        ) {
          scrollToElment(PRODUCT_DROPDOWN_FIELDS.OPENING_VALUATION);

          setSelectedSegment(2);
          return false;
        }
      }

      if (product.type === 'BILL_OF_MATERIALS') {
        if (Utility?.isEmpty(product?.bomMetaDetailsList)) {
          showToast(
            'Please add at least one BOM configuration.',
            TOAST_TYPE.FAILURE
          );
          setSelectedSegment(4);
          return false;
        } else {
          let emptyNames = product?.bomMetaDetailsList?.filter(
            (bomMeta: BomConfigurationModel) => {
              let nameLength = bomMeta?.name?.length ?? 0;
              return Utility.isEmpty(bomMeta?.name) || nameLength > 250;
            }
          );
          if (!Utility.isEmpty(emptyNames)) {
            showAlert(
              'Error',
              'Please enter a BoM (Bill of Materials) configuration name with a maximum length of 250 characters.'
            );
            setSelectedSegment(4);
            return false;
          }

          let invalidBomProductConfigs = false;
          let invalidOperationDependencyConfig = false;
          let invalidBlankOperationDependencyConfig = false;
          product?.bomMetaDetailsList?.every(
            (bomConfigModel: BomConfigurationModel) => {
              if (Utility.isEmpty(bomConfigModel?.bomProductsConfiguration)) {
                showAlert(
                  'Error',
                  t(
                    `PRODUCTS.DIALOG.PRODUCT.TOAST.ADD_ATLEASET_ONE_COMPONENT_PRODUCT`
                  )
                );
                setSelectedSegment(4);
                invalidBomProductConfigs = true;
                return false;
              } else if (
                !Utility.isEmpty(bomConfigModel?.bomProductsConfiguration)
              ) {
                let bomProducts =
                  bomConfigModel.bomProductsConfiguration?.filter(
                    (item: any) =>
                      item.produceProductType !== PRODUCE_PRODUCT_TYPE.SCRAP &&
                      item.produceProductType !==
                        PRODUCE_PRODUCT_TYPE.CO_PRODUCT
                  );
                let byProducts =
                  bomConfigModel.bomProductsConfiguration?.filter(
                    (item: any) =>
                      item.produceProductType === PRODUCE_PRODUCT_TYPE.SCRAP ||
                      item.produceProductType ===
                        PRODUCE_PRODUCT_TYPE.CO_PRODUCT
                  );
                // check for BOM component product
                let _blankComponentProduct: number = 0;
                var tmpComponentProduct = bomProducts?.map(
                  (e: any) => e.itemId
                );
                if (
                  new Set(tmpComponentProduct).size !==
                  tmpComponentProduct.length
                ) {
                  showToast(
                    t(
                      `PRODUCTS.DIALOG.PRODUCT.TOAST.DUPLICATE_COMPONENT_PRODUCT`
                    ),
                    TOAST_TYPE.FAILURE
                  );
                  setSelectedSegment(4);
                  invalidBomProductConfigs = true;
                  return false;
                }
                bomProducts?.forEach((cproduct: any) => {
                  if (
                    cproduct.itemid === null ||
                    typeof cproduct.itemid === undefined ||
                    Utility.isEmpty(cproduct.itemName) ||
                    cproduct.quantity === 0
                  ) {
                    _blankComponentProduct++;
                  }
                });

                if (_blankComponentProduct !== 0) {
                  showToast(
                    t(
                      `PRODUCTS.DIALOG.PRODUCT.TOAST.SELECT_PRODUCT_ADD_QUANTITY`
                    ),
                    TOAST_TYPE.FAILURE
                  );
                  setSelectedSegment(4);
                  invalidBomProductConfigs = true;
                  return false;
                }
                //Check For BOM BYProduct
                let _blankByProduct: number = 0;
                var tmpByProduct = bomProducts?.map((e: any) => e.itemId);
                if (new Set(tmpByProduct).size !== tmpByProduct.length) {
                  showToast(
                    'Please remove duplicate By-Products.',
                    TOAST_TYPE.FAILURE
                  );
                  setSelectedSegment(4);
                  return false;
                }
                byProducts?.forEach((cproduct: any) => {
                  if (
                    cproduct.itemid === null ||
                    typeof cproduct.itemid === undefined ||
                    Utility.isEmpty(cproduct.itemName) ||
                    cproduct.quantity === 0
                  ) {
                    _blankByProduct++;
                  }
                });

                if (_blankByProduct !== 0) {
                  showToast(
                    'Please select By-Product and add quantity.',
                    TOAST_TYPE.FAILURE
                  );
                  setSelectedSegment(4);
                  invalidBomProductConfigs = true;
                  return false;
                }
              }
              if (!Utility.isEmpty(bomConfigModel?.bomAddCostConfiguration)) {
                let _blankAdditionalCost: number = 0;
                bomConfigModel?.bomAddCostConfiguration?.forEach(
                  (addCost: any) => {
                    if (addCost.price === '' || addCost.label === '') {
                      _blankAdditionalCost++;
                    }
                  }
                );

                if (_blankAdditionalCost !== 0) {
                  showToast(
                    'Please enter additional cost values.',
                    TOAST_TYPE.FAILURE
                  );
                  setSelectedSegment(4);
                  invalidBomProductConfigs = true;
                  return false;
                }
              }

              if (
                !Utility.isEmpty(bomConfigModel?.bomOperationsConfiguration)
              ) {
                const operationIds =
                  bomConfigModel?.bomOperationsConfiguration?.map(
                    (operation) => operation.operationId
                  );

                bomConfigModel?.bomOperationsConfiguration?.forEach(
                  (operation) => {
                    const dependencyIds =
                      operation?.operationDependency?.opDependencyList;

                    dependencyIds?.forEach((dependencyId: any) => {
                      if (
                        !operationIds?.includes(dependencyId) &&
                        !invalidOperationDependencyConfig
                      ) {
                        const operationName = operation?.operationName;
                        showAlert(
                          'Error',
                          `Dependent operation of <span class="fw-b">${operationName}</span> doesnot exist in the operation list.`
                        );
                        setSelectedSegment(4);
                        invalidOperationDependencyConfig = true;
                        return false;
                      }
                    });
                  }
                );

                const atleastOneBlankFound =
                  bomConfigModel?.bomOperationsConfiguration?.find(
                    (bomOPItem: any) => {
                      return Utility.isEmpty(bomOPItem?.operationName);
                    }
                  );

                if (!Utility.isEmptyObject(atleastOneBlankFound)) {
                  showAlert('Error', `Please select operation`);
                  setSelectedSegment(4);
                  invalidBlankOperationDependencyConfig = true;
                  return false;
                }

                const atleastOneBlankDependencyFound =
                  bomConfigModel?.bomOperationsConfiguration?.find(
                    (bomOPItem: any) => {
                      return Utility.isEmpty(
                        bomOPItem?.operationDependency?.opDependencyList
                      );
                    }
                  );

                if (
                  !atleastOneBlankDependencyFound &&
                  !invalidOperationDependencyConfig
                ) {
                  showAlert(
                    'Error',
                    `You need to select on operation which is not dependent on any other operation.`
                  );
                  setSelectedSegment(4);
                  invalidBlankOperationDependencyConfig = true;
                  return false;
                }
              }

              return true;
            }
          );
          if (invalidBomProductConfigs) {
            return false;
          }
          if (
            invalidOperationDependencyConfig ||
            invalidBlankOperationDependencyConfig
          ) {
            return false;
          }
        }
      }
    } else if (Utility.isEmpty(product.salesReturnAccountCode)) {
      showToast(
        t(`PRODUCTS.DIALOG.PRODUCT.TOAST.SALES_RETURN_ACCOUNT_CANNOT_BE_EMPTY`),
        TOAST_TYPE.FAILURE
      );
      setSelectedSegment(1);
      return false;
    }

    if (product.hasVariants) {
      if (product.attributes.length === 0) {
        setSelectedSegment(4);
        return false;
      } else {
        product.attributes.forEach((attribute: any) => {
          if (attribute.id === null || attribute.values.length === 0) {
            setSelectedSegment(4);
            return false;
          }
        });
      }
    }

    if (product.multipleUomSchema && showUomCheckbox && !product.uomSchema) {
      setSelectedSegment(2);
      return;
    }

    // Custom fields validation
    const customFieldHasErrors = customFieldsContainsErrors(
      productForm.customFields
    );
    if (customFieldHasErrors) {
      setSelectedSegment(0);
      return false;
    }
    // Custom fields validation ends

    // Compliance Validation
    // TODO: Move validation to FormUtil
    if (tenantInfo.country === 'IN') {
      if (Utility.isEmpty(product.hsnOrSacCode)) {
        setSelectedSegment(3);
        return false;
      } else if (
        product.offeringType === 'GOODS' &&
        Utility.isEmpty(product.uqcIndia)
      ) {
        setSelectedSegment(3);
        return false;
      } else if (
        product.tdsApplicableIndia &&
        !product.tdsNatureOfPaymentIndia
      ) {
        showToast('Please select TDS Nature of Payment', TOAST_TYPE.FAILURE);
        setSelectedSegment(3);
        return false;
      }
    }

    // SINGAPORE
    if (!productForm.compliance.singapore.purchaseTax.hidden) {
      if (!productForm.compliance.singapore.purchaseTax.value.value) {
        showToast('Please select Purchase Tax', TOAST_TYPE.FAILURE);
        setSelectedSegment(3);
        return false;
      }
    }

    if (!productForm.compliance.singapore.salesTax.hidden) {
      if (!productForm.compliance.singapore.salesTax.value.value) {
        showToast('Please select Sales Tax', TOAST_TYPE.FAILURE);
        setSelectedSegment(3);
        return false;
      }
    }

    //Malaysia Comliance
    if (tenantInfo.country === COUNTRY_CODES.MY) {
      if (Utility.isEmpty(product.tariffOrServiceCodeMalaysia)) {
        setSelectedSegment(3);
        return false;
      }
      if (
        !product.exemptedMalaysia &&
        Utility.isEmpty(product.taxExemptionReasonMalaysia)
      ) {
        return false;
      }
    }
    if (tenantInfo.country === COUNTRY_CODES.IN) {
      if (!product?.taxPreference) {
        const isReasonNotEmpty = Utility.isNotEmpty(
          product?.taxExemptionReason
        );
        if (!isReasonNotEmpty) {
          setSelectedSegment(3);
        }
        return isReasonNotEmpty;
      }
    }

    return true;
  };

  const updateDefaultTaxForSGOrg = (product: any) => {
    let requestJson: any = {};
    if (Utility.isSGOrg()) {
      if (product.defaultPurchaseTax) {
        let selectedTaxObject = purchaseTaxesList.find(
          (item: any) => product.purchaseTaxCode === item.code
        );
        if (selectedTaxObject) {
          requestJson[selectedTaxObject?.id] = true;
        }
      }
      if (product.defaultSalesTax) {
        let selectedTaxObject = salesTaxesList.find(
          (item: any) => product.salesTaxCode === item.code
        );
        if (selectedTaxObject) {
          requestJson[selectedTaxObject?.id] = true;
        }
      }
    }
    if (!Utility.isEmpty(requestJson)) {
      ProductService.updateTaxPreferencesForSG(requestJson)
        .then((req: any) => {
          dispatch(fetchTaxes());
        })
        .catch((err: any) => {
          console.log('Failed to update the default tax rate ');
        });
    }
  };

  const createRequestObject = () => {
    let copyProduct = { ...product };
    copyProduct.attachments = productDocAttachmentIDs;
    copyProduct.bomMetaDetailsList?.forEach((row: any, index: any) => {
      let newRow = { ...row };
      if (newRow?.attachments?.length) {
        newRow.attachments = newRow.attachments?.map((attachment: any) =>
          Number(attachment.attachmentId ? attachment.attachmentId : attachment)
        );
      }

      copyProduct.bomMetaDetailsList[index] = newRow;
    });

    //For SG Compliance
    if (tenantInfo.country === COUNTRY_CODES.SG) {
      copyProduct.purchaseTaxCode =
        productForm.compliance.singapore.purchaseTax.value.value;
      copyProduct.salesTaxCode =
        productForm.compliance.singapore.salesTax.value.value;
      copyProduct.defaultPurchaseTax =
        productForm.compliance.singapore.defaultPurchaseTax.isSelected;
      copyProduct.defaultSalesTax =
        productForm.compliance.singapore.defaultSalesTax.isSelected;
    }
    //For India Compliance
    if (tenantInfo.country === COUNTRY_CODES.IN) {
      copyProduct.defaultPurchaseTax =
        productForm.compliance.india.defaultPurchaseTax.isSelected;
      copyProduct.defaultSalesTax =
        productForm.compliance.india.defaultSalesTax.isSelected;
    }

    if (tenantInfo.country === COUNTRY_CODES.MY) {
      copyProduct.purchaseTaxCode = copyProduct.salesTaxCode; // Both Purchase and Sales tax code are same in old books too.
    }

    if (
      tenantInfo?.landedCostSettings &&
      tenantInfo?.landedCostSettings?.landedCost
    ) {
      copyProduct.landedCostCategory = productForm.landedCostCategory.value.map(
        (ele) => {
          if (!Utility.isEmpty(ele.label) && !Utility.isEmpty(ele.value)) {
            return {
              name: ele.label,
              allocationType: ele.value
            };
          }
        }
      );
    }
    if (Utility.isEmpty(product.valuationMethod)) {
      copyProduct['valuationMethod'] =
        productValuationMethod[getSelectedValuationMethod()]?.value;
    }

    const customFieldsList: CustomField[] = [];
    if (productForm.customFields) {
      productForm.customFields.forEach((customField: CustomField) => {
        const cf: CustomField = {
          label: customField.label,
          code: customField.code,
          module: MODULES_NAME.PRODUCT,
          value: customField.value,
          customFieldIndex: customField.customFieldIndex,
          status: customField.status
        };
        customFieldsList.push(cf);
      });
    }
    copyProduct.customField = customFieldsList;

    // check auto fulfillment
    if (productForm.isAutoFulfillEnabled) {
      copyProduct.autoFulfillmentEnabled = true;
    } else {
      copyProduct.autoFulfillmentEnabled = false;
    }

    if (product.inventory === undefined || product.inventory === null) {
      var inventory = {
        openingQuantity: 0,
        availableQuantity: 0,
        openingValuation: 0
      };
      copyProduct.inventory = inventory;
    }
    if (
      Utility.isEmpty(copyProduct[`productTransactionType`]) ||
      copyProduct[`productTransactionType`] === '-'
    ) {
      copyProduct[`productTransactionType`] = null;
    } else {
      copyProduct[`productTransactionType`] =
        transactionType[getSelectedTransactionType()].value;
    }

    if (
      removeProductImage ||
      Utility.isEmptyObject(productImageAttachmentIds)
    ) {
      copyProduct.images = [];
    }

    delete copyProduct['copyId'];
    return copyProduct;
  };

  const updateProduct = () => {
    if (apiCallInProgress) {
      return;
    }
    setSaveButtonTapped(true);
    if (validateForm()) {
      setApiCallInProgress(true);
      let requestObject = createRequestObject();
      ProductService.updateProduct(requestObject)
        .then((res) => {
          if (attachments.length) {
            attachments.forEach((file: File) => {
              uploadAttachmentToAWS(file, res.id);
            });
          }

          if (!Utility.isEmpty(requestObject?.attributes)) {
            requestObject?.attributes?.forEach(
              (ele: any) => delete ele['isExisting']
            );
            if (
              !isEqual(requestObject?.attributes, props?.product?.attributes)
            ) {
              showAlert('Success', 'Updated variant product successfully');
            }
          }

          /* To keep only single image linked with a product */
          const needImageDeletion =
            (removeProductImage && productImageAttachmentIds.length) ||
            productImageAttachmentIds.length > 1;
          if (needImageDeletion) {
            const imageIds = removeProductImage
              ? productImageAttachmentIds
              : productImageAttachmentIds.slice(0, -1);
            try {
              imageIds.forEach(removeAttachment);
            } catch (err) {
              console.log('Error removing attachments: ', err);
            }
          }
          updateDefaultTaxForSGOrg(requestObject);
          updateTenantInventorySettings(requestObject);

          if (props.needProductRefresh ?? true) {
            dispatch(fetchProducts());
          }

          if (props.onSuccess) {
            props.onSuccess({ ...res, previousOpeningQty: previousOpeningQty });
          }
        })
        .catch((error: any) => {
          props.onCancel();
        });
    }
  };
  const getOperationsListForEdit = async () => {
    let ids: any = [];
    product?.bomMetaDetailsList?.forEach((bomList: any) => {
      if (!Utility.isEmpty(bomList?.bomOperationsConfiguration)) {
        bomList?.bomOperationsConfiguration.forEach((op: any) => {
          if (!ids.includes(op.operationId)) {
            ids.push(op.operationId);
          }
        });
      }
    });
    let operationDetails: any = [];
    if (!Utility.isEmpty(ids)) {
      operationDetails = await OperationsService.getOperationsById(ids);
      setOperationListForEdit(operationDetails);
      console.log(operationDetails);
    }
  };
  const preProcessFields = () => {
    if (Utility.isEmpty(product[`productTransactionType`])) {
      product[`productTransactionType`] = 'BOTH';
    }
    if (
      product['whtSectionToWhtRateId'] !== null ||
      product['whtSectionToWhtRateId'] !== undefined
    ) {
      product['whtApplicable'] = true;
    } else {
      product['whtApplicable'] = false;
    }
  };

  const updateTenantInventorySettings = (product: any) => {
    let Payload: any = { ...defaultAccountValues.inventorySettings };
    for (let [key, value] of Object.entries(futureAccountsApply)) {
      if (value) {
        switch (key) {
          case FUTURE_ACCOUNTS_APPLY.COST_OF_GOODS_SOLD_ACCOUNT:
            Payload.costOfGoodsSoldAccount =
              product?.inventory?.costOfGoodsSoldAccountCode;
            break;
          case FUTURE_ACCOUNTS_APPLY.INVENTORY_ACCOUNT:
            Payload.inventoryAccount = product?.inventory?.inventoryAccountCode;
            break;
          case FUTURE_ACCOUNTS_APPLY.WIP_INVENTORY_ACCOUNT:
            Payload.wipInventoryAccount =
              product?.inventory?.wipInventoryAccountCode;
            break;
          case FUTURE_ACCOUNTS_APPLY.STOCK_ADJUSTMENT_ACCOUNT:
            Payload.stockAdjustmentAccount =
              product?.inventory?.stockAdjustmentAccountCode;
            break;
          case FUTURE_ACCOUNTS_APPLY.INVENTORY_PURCHASE_ACCOUNT:
            Payload.inventoryPurchaseAccount = product?.purchaseAccountCode;
            break;
          case FUTURE_ACCOUNTS_APPLY.SALES_ACCOUNT:
            Payload.salesAccount = product?.salesAccountCode;
            break;
          case FUTURE_ACCOUNTS_APPLY.MANUFACTURING_COST:
            Payload.manufacturingCost = product?.manufacturingCostAccountCode;
            break;
        }
      }
    }

    if (Object.keys(Payload).length) {
      ProductService.updateInventorySettings(
        Payload,
        AuthService.getUserTenantID()
      )
        .then((res) => {
          dispatch(fetchTenantSettings(AuthService.getUserTenantID()));
        })
        .catch((error: any) => {
          console.error('error updating inventory settings', error);
        });
    }
  };

  const createProduct = () => {
    if (apiCallInProgress) {
      return;
    }
    setSaveButtonTapped(true);
    preProcessFields();
    if (validateForm()) {
      if (props.apiInProgress) {
        props.apiInProgress(true);
      }
      setApiCallInProgress(true);

      let requestObject = createRequestObject();

      ProductService.createProduct(requestObject)
        .then((res) => {
          if (attachments.length) {
            attachments.forEach((file: File) => {
              uploadAttachmentToAWS(file, res.id);
            });
          }
          updateDefaultTaxForSGOrg(requestObject);
          updateTenantInventorySettings(requestObject);
          ProductService.apiConfig = {};
          if (props.needProductRefresh ?? false) {
            dispatch(fetchProducts());
          }
          dispatch(fetchProductSummary());
          if (props.onSuccess) {
            props.onSuccess(res);
          }
        })
        .catch((error: any) => {
          if (props.apiInProgress) {
            props.apiInProgress(false);
          }
          setApiCallInProgress(false);
        });
    }
  };

  const getComplianceSection = () => {
    if (!Utility.isEmpty(complianceFields)) {
      return (
        <div className="column parent-width">
          <div className="row row-responsive align-items-start parent-width mt-2">
            <FormUtil
              parentData={product}
              complianceFields={complianceFields}
              updateParentData={(key: any, value: any) => {
                switch (tenantInfo.country) {
                  case COUNTRY_CODES.MY:
                    updateProductData(key, value);
                    break;
                  case COUNTRY_CODES.IN:
                    updateProductData(key, value);
                    break;
                  case COUNTRY_CODES.SG:
                    updateProductData(key, value);
                    break;
                  case COUNTRY_CODES.US:
                    updateProductData(key, value);
                    break;
                }
              }}
              canValidate={saveButtonTapped}
            />
          </div>
        </div>
      );
    } else {
      return <></>;
    }
  };

  const complianceSpecificFields = () => {
    return (
      <div className="column parent-width">
        <div className="row row-responsive align-items-start parent-width mt-2">
          {/* SINGAPORE COMPLIANCE */}
          <div className="parent-width p-v-s">
            {!productForm.compliance.singapore.purchaseTax.hidden && (
              <div className="parent-width mr-m">
                {sdkDropdown(
                  'Purchase Tax',
                  productForm.compliance.singapore.purchaseTax.value.label,
                  productForm.compliance.singapore.purchaseTax.options,
                  PRODUCT_DROPDOWN_FIELDS.SG_PURCHASE_TAX,
                  INPUT_VIEW_DIRECTION.HORIZONTAL,
                  productForm.compliance.singapore.purchaseTax.isMandatory,
                  productForm.compliance.singapore.purchaseTax.hasError,
                  productForm.compliance.singapore.purchaseTax.errorMsg,
                  false
                )}
              </div>
            )}
            {!productForm.compliance.singapore.defaultPurchaseTax.hidden && (
              <DKCheckMark
                title="Set as Default Purchase Tax"
                className="mt-s"
                isSelected={
                  productForm.compliance.singapore.defaultPurchaseTax.isSelected
                }
                onClick={(value: string) =>
                  formToggleChanged(
                    !productForm.compliance.singapore.defaultPurchaseTax
                      .isSelected,
                    PRODUCT_CHECKBOX_FIELDS.SG_DEFAULT_PURCHASE_TAX
                  )
                }
              />
            )}
            {!productForm.compliance.singapore.salesTax.hidden && (
              <div className="parent-width mt-l">
                {sdkDropdown(
                  'Sales Tax',
                  productForm.compliance.singapore.salesTax.value.label,
                  productForm.compliance.singapore.salesTax.options,
                  PRODUCT_DROPDOWN_FIELDS.SG_SALES_TAX,
                  INPUT_VIEW_DIRECTION.HORIZONTAL,
                  productForm.compliance.singapore.salesTax.isMandatory,
                  productForm.compliance.singapore.salesTax.hasError,
                  productForm.compliance.singapore.salesTax.errorMsg,
                  false
                )}
              </div>
            )}
            {!productForm.compliance.singapore.defaultSalesTax.hidden && (
              <DKCheckMark
                title="Set as Default Sales Tax"
                className="mt-s"
                isSelected={
                  productForm.compliance.singapore.defaultSalesTax.isSelected
                }
                onClick={(value: string) =>
                  formToggleChanged(
                    !productForm.compliance.singapore.defaultSalesTax
                      .isSelected,
                    PRODUCT_CHECKBOX_FIELDS.SG_DEFAULT_SALES_TAX
                  )
                }
              />
            )}
          </div>
        </div>
      </div>
    );
  };

  const sdkDropdown = (
    title: string,
    value: string,
    dropdownOptions: ProductFormReactSelectOption[] | undefined,
    fieldName: PRODUCT_DROPDOWN_FIELDS,
    direction: string = INPUT_VIEW_DIRECTION.HORIZONTAL,
    isMandatory: boolean = false,
    hasError: boolean = false,
    errMsg: string = '',
    isSearchable: boolean = false,
    searchableKey: string = 'label',
    index: number = 0
  ) => {
    return (
      <DKInput
        value={value}
        title={title}
        direction={direction}
        formatter={(obj: any) => {
          return obj;
        }}
        type={INPUT_TYPE.DROPDOWN}
        required={isMandatory}
        canValidate={hasError}
        onChange={(value: any) => {
          optionSelected(value, fieldName, index);
        }}
        errorMessage={errMsg}
        dropdownConfig={{
          allowSearch: isSearchable,
          searchableKey: searchableKey,
          data: dropdownOptions,
          renderer: (index: any, obj: any) => {
            return (
              <div className="flex flex-row w-full justify-content-between">
                <DKLabel
                  style={{ fontSize: '13px' }}
                  className="text-base border-radius-s"
                  text={obj.label}
                />
              </div>
            );
          }
        }}
      />
    );
  };

  const optionSelected = (
    option: ProductFormReactSelectOption | null,
    fieldName: PRODUCT_DROPDOWN_FIELDS,
    index: number = 0
  ) => {
    const updatedState = productForm;
    if (option) {
      switch (fieldName) {
        case PRODUCT_DROPDOWN_FIELDS.SG_PURCHASE_TAX:
          updatedState.compliance.singapore.purchaseTax.value = option;
          break;
        case PRODUCT_DROPDOWN_FIELDS.SG_SALES_TAX:
          updatedState.compliance.singapore.salesTax.value = option;
          break;
      }
    }
    setProductForm({ ...updatedState });
  };

  const formToggleChanged = (
    newValue: boolean,
    fieldName: PRODUCT_CHECKBOX_FIELDS
  ) => {
    const updatedState = productForm;
    switch (fieldName) {
      case PRODUCT_CHECKBOX_FIELDS.SG_DEFAULT_PURCHASE_TAX:
        updatedState.compliance.singapore.defaultPurchaseTax.isSelected =
          newValue;
        break;
      case PRODUCT_CHECKBOX_FIELDS.SG_DEFAULT_SALES_TAX:
        updatedState.compliance.singapore.defaultSalesTax.isSelected = newValue;
        break;
      case PRODUCT_CHECKBOX_FIELDS.IND_DEFAULT_PURCHASE_TAX:
        updatedState.compliance.india.defaultPurchaseTax.isSelected = newValue;
        break;
      case PRODUCT_CHECKBOX_FIELDS.IND_DEFAULT_SALES_TAX:
        updatedState.compliance.india.defaultSalesTax.isSelected = newValue;
        break;
      case PRODUCT_CHECKBOX_FIELDS.AUTO_FULFILL:
        updatedState.isAutoFulfillEnabled = newValue;
        break;
    }
    setProductForm({ ...updatedState });
  };

  const getOptionButton = (
    index: number,
    title: string,
    selected = index === selectedSegment
  ) => {
    return (
      <div
        className=" cursor-hand "
        onClick={() => {
          let _sequenceFormat = { ...autoNumberingFormat };
          _sequenceFormat.id = product.sequenceFormat;
          _sequenceFormat.text = product.documentSequenceCode;
          setAutoNumberingFormat({ ..._sequenceFormat });
          setSelectedSegment(index);
        }}
      >
        <DKLabel
          text={title}
          className={'mt-m' + (selected ? ' fw-b text-app-color ' : '')}
        />
        {selected && (
          <div className="parent-width bg-app mt-r" style={{ height: 2.5 }} />
        )}
      </div>
    );
  };

  const getHeaderSegment = () => {
    return (
      <div className="parent-width mobile-scroll-hidden">
        <DKLine />
        <div
          className="row bg-gray1 border-radius-s p-h-r align-items-start mobile-scroll-x mobile-pb"
          style={{ gap: 30, backgroundColor: 'rgb(250, 250, 250)' }}
        >
          {getOptionButton(0, 'General')}
          {!props.isFromAdHoc && getOptionButton(1, 'Accounting')}
          {getOptionButton(2, 'Inventory')}
          {complianceFields && Object.keys(complianceFields).length !== 0 && (
            <>{getOptionButton(3, 'Compliance')}</>
          )}
          {product.type !== PRODUCT_TYPE.NON_TRACKED &&
            getOptionButton(
              4,
              product.type !== PRODUCT_TYPE.BILL_OF_MATERIALS
                ? 'Variants'
                : 'BOM'
            )}
          {product.type !== PRODUCT_TYPE.NON_TRACKED &&
            !props.isFromAdHoc &&
            getOptionButton(5, 'Substitutes')}
        </div>
        <DKLine />
      </div>
    );
  };

  const getHeader = () => {
    return (
      <div className="row justify-content-between p-h-r p-v-s bg-gray1">
        <div className="row pop-header-drag-handle">
          <DKLabel text="Product Details" className="fw-m fs-l" />
          {isReadOnly && (
            <div className=" fs-r text-align-left text-dark-gray bg-chip-orange text-orange border-radius-r ml-r p-h-s p-v-xs fw-m">
              Read-Only
            </div>
          )}
        </div>
        <div className="row width-auto">
          <DKButton
            title={isReadOnly ? 'Close' : 'Cancel'}
            className="bg-white border-m mr-r"
            onClick={props.onCancel}
          />
          {product.id && props.onProductDashboard && (
            <DKButton
              title={'View Dashboard'}
              className={'bg-white border-m mr-r'}
              onClick={redirectToProductDashboard}
            />
          )}
          {!isReadOnly && (
            <div
              className={`row ${
                apiCallInProgress ? 'border-radius-m border-m' : ''
              }`}
            >
              <DKButton
                title={product.id ? 'Update' : 'Save'}
                onClick={() => (product.id ? updateProduct() : createProduct())}
                className={`${
                  apiCallInProgress
                    ? 'border-radius-none text-gray'
                    : 'bg-button text-white '
                }`}
              />
              {apiCallInProgress && (
                <DKSpinner iconClassName="ic-s" className="column pl-0 pr-s" />
              )}
            </div>
          )}
        </div>
      </div>
    );
  };

  const createVariantOrBOM = () => {
    if (product.type !== 'BILL_OF_MATERIALS') {
      // if (isProductInUse) {
      //   return (
      //     <div className="column parent-width mt-l">
      //       <DKLabel
      //         text="ⓘ This product is currently in use, enabling variants is not possible. ."
      //         className="text-red"
      //       />
      //     </div>
      //   );
      // }
      if (product.advancedTracking === 'NONE') {
        return <>{variantsSection}</>;
      } else {
        return (
          <DKLabel
            text="ⓘ You cannot enable variant for Advance Tracked or BOM product."
            className="text-red mt-l"
          />
        );
      }
    } else if (product.type === 'BILL_OF_MATERIALS') {
      return (
        <div className="column parent-width mt-m">
          <BOMDetailsTabProduct
            isFromAdHoc={props.isFromAdHoc}
            saveButtonTapped={saveButtonTapped}
            product={product}
            usedBoms={usedBoms}
            onChange={(data: any) => setProduct(data)}
            initialProductWithNoCurrentChange={deepClone(props.product)}
            operationListEditMode={operationListForEdit}
            isCopy={props.isCopy}
            isReplaceFlow={false}
          />
        </div>
      ); //getBomFields();
    }
  };

  const getVariantORBOM = () => {
    return (
      <div className="column parent-width gap-4">
        {createVariantOrBOM()}
        <DKLine />
      </div>
    );
  };

  return (
    <DynamicPopupWrapper>
      <div className="transparent-background">
        <div
          className="popup-window"
          style={{
            maxWidth: 800,
            width: '90%',
            maxHeight: '95%',
            height: 705,
            padding: 0,
            paddingBottom: 10,
            overflowY: 'hidden'
          }}
        >
          {getHeader()}
          {getHeaderSegment()}
          <div
            className="column p-h-xl parent-size hide-scroll-bar"
            style={{
              overflow: 'auto'
            }}
          >
            <div
              id="popup-container"
              className="column parent-width parent-height"
              style={{
                pointerEvents: isReadOnly ? 'none' : 'auto'
              }}
            >
              {selectedSegment === 0 && generalInfoSection}
              {selectedSegment === 1 && accountingSection}
              {selectedSegment === 2 && inventorySection}
              {selectedSegment === 3 &&
                (tenantInfo.country === COUNTRY_CODES.SG
                  ? complianceSpecificFields()
                  : getComplianceSection())}
              {selectedSegment === 4 && getVariantORBOM()}
              {selectedSegment === 5 && getSubstituteProductField()}
            </div>
          </div>

          {addUomSchemaPopUp && (
            <PopupWrapper
              clickAction={catchClicks}
              type={POPUP_TYPE.POPUP}
              title={t(`SETTINGS.UOM_SCHEMA.ADD_UOM_SCHEMA`)}
              btnList={popupBtnConfigForAddUomSchema}
              width={'40%'}
              height={'75%'}
            >
              <AddUomSchemaPopUp
                populateFormData={null}
                passingInteraction={(callback: CallBackPayloadType) => {
                  parentChildInteraction(callback);
                }}
                baseUOM={baseUOM}
                baseUOMDisabled={true}
              />
            </PopupWrapper>
          )}
          {addUomPopUp && (
            <PopupWrapper
              clickAction={catchClicks}
              type={POPUP_TYPE.POPUP}
              title={`+ ${t(`SETTINGS.UOM.ADD_UOM`)}`}
              btnList={popupBtnConfigForAddUom}
              width={'30%'}
              height={'200px'}
            >
              <AddUomPopUp
                populateFormData={null}
                passingInteraction={(callback: CallBackPayloadType) => {
                  parentChildInteraction(callback);
                }}
              />
            </PopupWrapper>
          )}
        </div>
        {showSubstituteProductView && (
          <SubstituteSelectionPopUp
            componentProduct={
              product?.bomProductsConfiguration?.[selectedComponentProductIndex]
            }
            onCancel={() => {
              setShowSubstituteProductView(false);
              setSelectedComponentProductIndex(undefined);
            }}
            onSave={(products: any) => {
              let copyProduct = { ...product };
              let copyOfComponentsProducts = [
                ...copyProduct?.bomProductsConfiguration
              ];
              let copyComponent =
                copyOfComponentsProducts[selectedComponentProductIndex];
              copyComponent.bomProductSubstitutesDetails = [...products];
              copyOfComponentsProducts[selectedComponentProductIndex] =
                copyComponent;
              copyProduct.bomProductsConfiguration = copyOfComponentsProducts;
              setProduct(copyProduct);
              setShowSubstituteProductView(false);
              setSelectedComponentProductIndex(undefined);
            }}
          />
        )}
      </div>
    </DynamicPopupWrapper>
  );
}
