import {
  DKButton,
  DKDataGrid,
  DKLabel,
  DKRadioButton,
  DKSpinner,
  showAlert,
  INPUT_TYPE
} from 'deskera-ui-library';
import { useEffect, useState } from 'react';
import { DOCUMENT_MODE, PRODUCT_TYPE } from '../../Constants/Constant';
import { useAppSelector } from '../../Redux/Hooks';
import { activeTenantInfo } from '../../Redux/Slices/AuthSlice';
import NumberFormatService from '../../Services/NumberFormat';
import { DocumentConfigUtility } from '../../Utility/DocumentConfigUtility';
import Utility, { deepClone } from '../../Utility/Utility';

export const enum ADDITIONAL_CHARGE_METHODS {
  APPORTION_ON_QTY = 'APPORTION_ON_QTY',
  APPORTION_ON_VALUE = 'APPORTION_ON_VALUE',
  APPORTION_MANUAL = 'APPORTION_MANUAL'
}

const AdditionalCharges = (props: any) => {
  const [updating, setUpdating] = useState<boolean>(false);

  const [booksDocument, setBooksDocument] = useState(props.booksDocument);
  const [chargeDetails, setChargeDetails] = useState(props.chargeDetails);
  const [columnConfig, setColumnConfig] = useState<any>([
    {
      id: 'productName',
      key: 'productName',
      name: 'Product',
      type: INPUT_TYPE.TEXT,
      width: 180,
      options: [],
      editable: false
    },
    {
      id: 'productQuantity',
      key: 'productQuantity',
      name: 'Qty',
      type: INPUT_TYPE.TEXT,
      width: 120,
      textAlign: 'right',
      options: [],
      editable: false,
      renderer: DocumentConfigUtility.quantityRenderer
    },
    {
      id: 'totalAmount',
      key: 'totalAmount',
      name: 'Amount',
      type: INPUT_TYPE.NUMBER,
      width: 130,
      textAlign: 'right',
      options: [],
      editable: false
    },
    {
      id: 'charge',
      key: 'charge',
      name: 'Charges',
      type: INPUT_TYPE.NUMBER,
      width: 130,
      textAlign: 'right',
      options: [],
      editable:
        (chargeDetails?.[0]?.apportionValue ===
          ADDITIONAL_CHARGE_METHODS?.APPORTION_MANUAL &&
          props.documentMode !== DOCUMENT_MODE.VIEW) ??
        false
    },
    {
      id: 'chargesTotal',
      key: 'chargesTotal',
      name: 'Total',
      type: INPUT_TYPE.NUMBER,
      width: 130,
      textAlign: 'right',
      options: [],
      editable: false
    }
  ]);
  const tenantInfo = useAppSelector(activeTenantInfo);
  const [gridRows, setGridRows] = useState<any>([]);

  const updateConfig = () => {
    let config = columnConfig;

    config.forEach((conf: any) => {
      switch (conf.key) {
        case 'productQuantity':
          conf.renderer = (data: any) => (
            <div className="row justify-content-end gap-1 ">
              <DKLabel
                text={NumberFormatService.getNumber(
                  data?.rowData?.productQuantity || 0
                )}
                className="fs-m"
              />
              <DKLabel
                style={{
                  height: 15
                }}
                text={data?.rowData?.uom?.name ?? ''}
                className="fs-s fw-m text-gray "
              />
            </div>
          );
          break;
        case 'totalAmount':
          conf.formatter = (data: any) =>
            Utility.amountFormatter(
              data?.rowData?.totalAmount ?? 0,
              booksDocument.currency
            );
          break;
        case 'charge':
          conf.formatter = (data: any) =>
            (conf.formatter = (data: any) =>
              Utility.amountFormatter(
                data?.rowData?.charge,
                booksDocument.currency
              ));
          break;
        case 'chargesTotal':
          conf.formatter = (data: any) =>
            (conf.formatter = (data: any) =>
              Utility.amountFormatter(
                data?.rowData?.chargesTotal,
                booksDocument.currency
              ));
          break;
      }
    });

    setColumnConfig(config.filter((col: any) => !col.hidden));
  };

  useEffect(() => {
    updateConfig();
  }, []);

  useEffect(() => {
    getProductRows();
  }, [booksDocument]);

  useEffect(() => {
    if (!Utility.isEmpty(chargeDetails)) {
      let cDetails = chargeDetails?.[0];
      let tempDoc = calculateAdditionalCharge(cDetails);
      setBooksDocument({ ...tempDoc });
    }
  }, [chargeDetails]);

  const isValidAdditionalCost = () => {
    switch (chargeDetails[0]?.apportionValue) {
      case ADDITIONAL_CHARGE_METHODS.APPORTION_MANUAL:
        let newBooksDocumentState = deepClone(booksDocument);
        let totalCharge = chargeDetails[0]?.chargeValue;
        let appliedProductCharge = 0;
        newBooksDocumentState?.items
          ?.filter(
            (item: any) =>
              item?.product?.type === PRODUCT_TYPE.TRACKED ||
              item?.product?.type === PRODUCT_TYPE.BILL_OF_MATERIALS
          )
          ?.forEach((item: any) => {
            appliedProductCharge += item.charge;
          });
        if (appliedProductCharge !== Number(totalCharge)) {
          showAlert(
            'Oops!',
            'Product charge values can not less or more than total charge value.'
          );
          return false;
        } else {
          return true;
        }
      case ADDITIONAL_CHARGE_METHODS.APPORTION_ON_QTY:
      case ADDITIONAL_CHARGE_METHODS.APPORTION_ON_VALUE:
        return true;
    }
  };

  const getHeader = () => {
    return (
      <div className="row justify-content-between p-h-r p-v-s bg-gray1 rounded-t">
        <div className="row width-auto">
          <DKLabel text={`Allocate Additional Cost`} className="fw-m fs-l" />
        </div>
        <div className="row width-auto">
          <DKButton
            title={'Cancel'}
            className={`bg-white border-m ${
              props.documentMode !== DOCUMENT_MODE.VIEW ? 'mr-r' : ''
            }`}
            onClick={() => {
              !updating && props.onCancel();
            }}
          />
          {props.documentMode !== DOCUMENT_MODE.VIEW && (
            <div
              className={`row border-radius-m ${
                updating ? ' bg-gray1 border-m pr-2' : ' bg-button text-white'
              }`}
            >
              <DKButton
                title={'Save'}
                onClick={() => {
                  if (isValidAdditionalCost()) {
                    let additionalChargeDetailedObj = {
                      ...chargeDetails[0],
                      items: booksDocument?.items
                    };
                    !updating &&
                      props.onSave({
                        booksDocument,
                        chargeDetails,
                        additionalChargeDetailedObj
                      });
                  }
                }}
              />

              {updating && <DKSpinner iconClassName="ic-s" />}
            </div>
          )}
        </div>
      </div>
    );
  };

  const getProductRows = () => {
    let rows: any[] = [];
    rows = deepClone(
      booksDocument?.items
        ?.filter(
          (item: any) =>
            item?.product?.type === PRODUCT_TYPE.TRACKED ||
            item?.product?.type === PRODUCT_TYPE.BILL_OF_MATERIALS
        )
        ?.map((item: any) => {
          let itemLevelAdditionalCharge =
            item.additionalCharges?.additionalChargesDetails?.find(
              (item: any) =>
                item?.additionalCharge === props?.chargeDetails?.[0]?.name
            );
          return {
            ...item,
            charge: itemLevelAdditionalCharge?.chargeAmount,
            chargesTotal: Utility.roundOffToTenantDecimalScale(
              itemLevelAdditionalCharge?.chargeAmount + item?.totalAmount
            )
          };
        })
    );

    rows.forEach((item) => {
      item.productName = item?.product?.name;
    });
    // console.log(rows, 'product rows on Additional Charge popup ');
    setGridRows(rows);
  };

  const getProductItemList = () => (
    <div className="flex flex-col items-start w-full p-h-r p-v-s">
      <DKDataGrid
        needShadow={false}
        needColumnIcons={false}
        needBorder={true}
        needTrailingColumn={true}
        allowBulkOperation={false}
        allowColumnSort={false}
        filterData={[]}
        allowColumnDelete={false}
        allowRowEdit={true}
        allowColumnEdit={false}
        allowFilter={false}
        allowColumnAdd={false}
        allowBottomRowAdd={false}
        allowSearch={false}
        allowShare={false}
        // width={960}
        rows={gridRows}
        columns={columnConfig}
        onRowUpdate={(data: any) => {
          switch (data?.columnKey) {
            case 'charge':
              let newBooksDocumentState = deepClone(booksDocument);
              let rowIndex = data?.rowIndex;
              let item = newBooksDocumentState?.items[rowIndex];
              let selectedCharge = chargeDetails[0];

              let copyAdditionalChargeDetails: any = item?.additionalCharges
                ? item?.additionalCharges
                : { additionalChargesDetails: [] };
              let existingChargeIndex =
                copyAdditionalChargeDetails?.additionalChargesDetails?.findIndex(
                  (charge: any) =>
                    charge.additionalCharge === selectedCharge?.name
                );
              let existingChargeDetails = [
                ...copyAdditionalChargeDetails.additionalChargesDetails
              ];
              let selectedChargesDetails =
                existingChargeDetails[existingChargeIndex];

              let tempDetails = {
                id: selectedCharge?.id,
                additionalCharge: selectedChargesDetails?.additionalCharge,
                additionalChargeAccountCode:
                  selectedChargesDetails?.additionalChargeAccountCode,
                addtionalChargeTax:
                  selectedChargesDetails?.addtionalChargeTax ?? null,
                addtionalChargeTaxCode:
                  selectedChargesDetails?.addtionalChargeTaxCode ?? null,
                isPreCharge: selectedChargesDetails.isPreCharge,
                isPercent: selectedChargesDetails?.isPercent,
                chargeAmount: Utility.roundOffToTenantDecimalScale(
                  data?.rowData?.charge
                ),
                apportionFlag: selectedCharge?.apportionFlag || true,
                apportionValue: selectedCharge?.apportionValue || null
              };

              if (existingChargeIndex !== -1) {
                existingChargeDetails[existingChargeIndex] = tempDetails;
              } else {
                existingChargeDetails.push(tempDetails);
              }

              item = {
                ...item,
                additionalCharges: {
                  additionalChargesDetails: existingChargeDetails
                },
                charge: Utility.roundOffToTenantDecimalScale(
                  data?.rowData?.charge
                ),
                chargesTotal: Utility.roundOffToTenantDecimalScale(
                  parseFloat(data?.rowData?.charge) +
                    parseFloat(data?.rowData?.totalAmount)
                )
              };
              newBooksDocumentState.items[rowIndex] = item;
              setBooksDocument({ ...newBooksDocumentState });
              break;
          }
        }}
        onRowClick={() => {}}
      />
    </div>
  );

  const onMethodChange = (type: string, rowIndex: number) => {
    let copyBooksDocument = { ...booksDocument };
    let copyAdditionalChargesDetails = booksDocument?.additionalCharges
      ?.additionalChargesDetails
      ? [...booksDocument?.additionalCharges?.additionalChargesDetails]
      : [];
    let findCurrentEditableAdditionalCost =
      copyAdditionalChargesDetails.findIndex(
        (item: any) => item.additionalCharge === chargeDetails?.[0]?.name
      );
    if (findCurrentEditableAdditionalCost > -1) {
      copyAdditionalChargesDetails[
        findCurrentEditableAdditionalCost
      ].apportionValue = type;
    }
    copyBooksDocument.additionalCharges.additionalChargesDetails =
      copyAdditionalChargesDetails;

    // update the apportion value in selected charge object
    let charges: any = deepClone(chargeDetails);
    let selectedCharge: any = charges[rowIndex];
    selectedCharge.apportionValue = type;
    setChargeDetails([...charges]);

    if (type === ADDITIONAL_CHARGE_METHODS.APPORTION_MANUAL) {
      copyBooksDocument.items = copyBooksDocument.items?.map((item: any) => {
        if (
          item?.product?.type !== PRODUCT_TYPE.TRACKED &&
          item?.product?.type !== PRODUCT_TYPE.BILL_OF_MATERIALS
        ) {
          return {
            ...item,
            additionalCharges: {
              additionalChargesDetails: []
            }
          };
        }
        let chargeDetails = item.additionalCharges?.additionalChargesDetails;
        const selectedChargeIndex = chargeDetails?.findIndex(
          (charge: any) => charge.additionalCharge === selectedCharge?.name
        );
        if (selectedChargeIndex > -1) {
          chargeDetails[selectedChargeIndex] = {
            ...chargeDetails[selectedChargeIndex],
            chargeAmount: 0
          };
        }
        return {
          ...item,
          additionalCharges: {
            additionalChargesDetails: chargeDetails
          }
        };
      });
    }

    //now update items calculation in books document copy object
    copyBooksDocument.items = calculateAdditionalCharge(
      selectedCharge,
      copyBooksDocument
    ).items;
    setBooksDocument(copyBooksDocument);
  };

  const calculateAdditionalCharge = (selectedCharge: any, tempDoc?: any) => {
    let newBooksDocumentState = !Utility.isEmpty(tempDoc)
      ? deepClone(tempDoc)
      : deepClone(booksDocument);
    let totalTempChargeAmount = 0;

    switch (selectedCharge?.apportionValue) {
      case ADDITIONAL_CHARGE_METHODS.APPORTION_ON_QTY:
        let totalProductQty = 0;
        let perQtyItemCharge = 0;
        totalProductQty = newBooksDocumentState?.items
          ?.filter(
            (item: any) =>
              item?.product?.type === PRODUCT_TYPE.TRACKED ||
              item?.product?.type === PRODUCT_TYPE.BILL_OF_MATERIALS
          )
          ?.reduce((total: number, array: any) => {
            return total + array.productQuantity;
          }, 0);

        perQtyItemCharge = selectedCharge?.chargeValue / totalProductQty;

        newBooksDocumentState.items = newBooksDocumentState?.items?.map(
          (object: any, index: number) => {
            let item = { ...object };
            if (
              item?.product?.type !== PRODUCT_TYPE.TRACKED &&
              item?.product?.type !== PRODUCT_TYPE.BILL_OF_MATERIALS
            ) {
              return {
                ...item,
                additionalCharges: {
                  additionalChargesDetails: []
                }
              };
            }

            let copyAdditionalChargeDetails: any = item?.additionalCharges
              ? item?.additionalCharges
              : { additionalChargesDetails: [] };
            let existingChargeIndex =
              copyAdditionalChargeDetails?.additionalChargesDetails?.findIndex(
                (charge: any) =>
                  charge.additionalCharge === selectedCharge?.name
              );
            let existingChargeDetails = [
              ...copyAdditionalChargeDetails.additionalChargesDetails
            ];
            let chargeAmount = Utility.roundOffToTenantDecimalScale(
              item?.productQuantity * perQtyItemCharge
            );
            totalTempChargeAmount += chargeAmount;
            if (index === newBooksDocumentState?.items?.length - 1) {
              if (totalTempChargeAmount !== +selectedCharge?.chargeValue) {
                const chargeDelta =
                  +selectedCharge.chargeValue - totalTempChargeAmount;
                chargeAmount += chargeDelta;
              }
            }
            let tempDetails = {
              id: selectedCharge?.id,
              additionalCharge: selectedCharge?.name,
              additionalChargeAccountCode:
                selectedCharge?.additionalChargeAccountCode,
              addtionalChargeTax: selectedCharge?.addtionalChargeTax ?? null,
              addtionalChargeTaxCode:
                selectedCharge?.addtionalChargeTaxCode ?? null,
              isPreCharge: selectedCharge.isPreCharge,
              isPercent: selectedCharge?.isPercent,
              percent: selectedCharge?.percent,
              chargeAmount: chargeAmount,
              apportionFlag: selectedCharge?.apportionFlag || true,
              apportionValue: selectedCharge?.apportionValue || null
            };

            if (existingChargeIndex !== -1) {
              existingChargeDetails[existingChargeIndex] = tempDetails;
            } else {
              existingChargeDetails.push(tempDetails);
            }

            item = {
              ...item,
              additionalCharges: {
                additionalChargesDetails: existingChargeDetails
              }
            };

            item.charge = chargeAmount;
            item.chargesTotal = Utility.roundOffToTenantDecimalScale(
              item.charge + item?.totalAmount
            );
            return item;
          }
        );
        break;
      case ADDITIONAL_CHARGE_METHODS.APPORTION_ON_VALUE:
        let totalSumAllAmount = 0;
        totalSumAllAmount = newBooksDocumentState?.items
          ?.filter(
            (item: any) =>
              item?.product?.type === PRODUCT_TYPE.TRACKED ||
              item?.product?.type === PRODUCT_TYPE.BILL_OF_MATERIALS
          )
          ?.reduce((total: number, item: any) => {
            return total + item.totalAmount;
          }, 0);

        totalTempChargeAmount = 0;
        newBooksDocumentState.items = newBooksDocumentState?.items?.map(
          (object: any, index: number) => {
            let item = { ...object };
            if (
              item?.product?.type !== PRODUCT_TYPE.TRACKED &&
              item?.product?.type !== PRODUCT_TYPE.BILL_OF_MATERIALS
            ) {
              return {
                ...item,
                additionalCharges: {
                  additionalChargesDetails: []
                }
              };
            }
            let copyAdditionalChargeDetails: any = item?.additionalCharges
              ? item?.additionalCharges
              : { additionalChargesDetails: [] };
            let existingChargeIndex =
              copyAdditionalChargeDetails?.additionalChargesDetails?.findIndex(
                (charge: any) =>
                  charge.additionalCharge === selectedCharge?.name
              );
            let existingChargeDetails = [
              ...copyAdditionalChargeDetails.additionalChargesDetails
            ];

            let chargeAmount = Utility.roundOffToTenantDecimalScale(
              (item?.totalAmount / totalSumAllAmount) *
                selectedCharge?.chargeValue
            );
            totalTempChargeAmount += chargeAmount;
            if (index === newBooksDocumentState?.items?.length - 1) {
              if (totalTempChargeAmount !== +selectedCharge?.chargeValue) {
                const chargeDelta =
                  +selectedCharge.chargeValue - totalTempChargeAmount;
                chargeAmount += chargeDelta;
              }
            }

            let tempDetails = {
              id: selectedCharge?.id,
              additionalCharge: selectedCharge?.name,
              additionalChargeAccountCode:
                selectedCharge?.additionalChargeAccountCode,
              addtionalChargeTax: selectedCharge?.addtionalChargeTax ?? null,
              addtionalChargeTaxCode:
                selectedCharge?.addtionalChargeTaxCode ?? null,
              isPreCharge: selectedCharge.isPreCharge,
              isPercent: selectedCharge?.isPercent,
              percent: selectedCharge?.percent,
              chargeAmount: chargeAmount,
              apportionFlag: selectedCharge?.apportionFlag || true,
              apportionValue: selectedCharge?.apportionValue || null
            };

            if (existingChargeIndex !== -1) {
              existingChargeDetails[existingChargeIndex] = tempDetails;
            } else {
              existingChargeDetails.push(tempDetails);
            }

            item = {
              ...item,
              additionalCharges: {
                additionalChargesDetails: existingChargeDetails
              }
            };

            item.charge = chargeAmount;
            item.chargesTotal = Utility.roundOffToTenantDecimalScale(
              item.charge + item?.totalAmount
            );
            return item;
          }
        );
        break;
      case ADDITIONAL_CHARGE_METHODS.APPORTION_MANUAL:
        newBooksDocumentState.items = newBooksDocumentState?.items.map(
          (object: any) => {
            let item = { ...object };
            if (
              item?.product?.type !== PRODUCT_TYPE.TRACKED &&
              item?.product?.type !== PRODUCT_TYPE.BILL_OF_MATERIALS
            ) {
              return {
                ...item,
                additionalCharges: {
                  additionalChargesDetails: []
                }
              };
            }

            let copyAdditionalChargeDetails: any = item?.additionalCharges
              ? item?.additionalCharges
              : { additionalChargesDetails: [] };
            let existingChargeIndex =
              copyAdditionalChargeDetails?.additionalChargesDetails?.findIndex(
                (charge: any) =>
                  charge.additionalCharge === selectedCharge?.name
              );
            let existingChargeDetails = [
              ...copyAdditionalChargeDetails.additionalChargesDetails
            ];
            let selectedChargesDetails =
              existingChargeDetails[existingChargeIndex];

            let tempDetails = {
              id: selectedCharge?.id,
              additionalCharge: selectedChargesDetails?.additionalCharge,
              additionalChargeAccountCode:
                selectedChargesDetails?.additionalChargeAccountCode,
              addtionalChargeTax:
                selectedChargesDetails?.addtionalChargeTax ?? null,
              addtionalChargeTaxCode:
                selectedChargesDetails?.addtionalChargeTaxCode ?? null,
              isPreCharge: selectedChargesDetails.isPreCharge,
              isPercent: selectedChargesDetails?.isPercent,
              chargeAmount: calculateManualAdditionalChargeDistribution(
                existingChargeDetails,
                existingChargeIndex,
                selectedCharge,
                newBooksDocumentState?.items?.filter(
                  (item: any) =>
                    item?.product?.type === PRODUCT_TYPE.TRACKED ||
                    item?.product?.type === PRODUCT_TYPE.BILL_OF_MATERIALS
                )?.length,
                !Utility.isEmpty(tempDoc)
              ),
              apportionFlag: selectedCharge?.apportionFlag || true,
              apportionValue: selectedCharge?.apportionValue || null
            };

            if (existingChargeIndex !== -1) {
              existingChargeDetails[existingChargeIndex] = tempDetails;
            } else {
              existingChargeDetails.push(tempDetails);
            }

            item = {
              ...item,
              additionalCharges: {
                additionalChargesDetails: existingChargeDetails
              }
            };

            item.charge = calculateManualAdditionalChargeDistribution(
              existingChargeDetails,
              existingChargeIndex,
              selectedCharge,
              newBooksDocumentState?.items?.filter(
                (item: any) =>
                  item?.product?.type === PRODUCT_TYPE.TRACKED ||
                  item?.product?.type === PRODUCT_TYPE.BILL_OF_MATERIALS
              )?.length,
              !Utility.isEmpty(tempDoc)
            );
            item.chargesTotal = Utility.roundOffToTenantDecimalScale(
              item.charge + item?.totalAmount
            );

            return item;
          }
        );
        break;
    }
    return newBooksDocumentState;
  };

  const calculateManualAdditionalChargeDistribution = (
    existingChargeDetails: any[],
    existingChargeIndex: number,
    selectedCharge: any,
    itemsLength: number,
    isManualSwitch: boolean
  ) => {
    if (existingChargeIndex > -1) {
      return existingChargeDetails[existingChargeIndex].chargeAmount
        ? existingChargeDetails[existingChargeIndex].chargeAmount
        : isManualSwitch
        ? Utility.roundOffToTenantDecimalScale(
            selectedCharge?.chargeValue / itemsLength
          )
        : 0;
    } else {
      return Utility.roundOffToTenantDecimalScale(
        selectedCharge?.chargeValue / itemsLength
      );
    }
  };

  const updateChargeFieldEditable = (value: boolean) => {
    let copyColumnConfig = [...columnConfig];
    // since the array is hard coded and the charge column is at index 3, hence hardcoding to avoid loop and filter
    copyColumnConfig[3].editable = value;
    setColumnConfig(copyColumnConfig);
  };

  const getChargesDetailSection = () =>
    chargeDetails?.length &&
    chargeDetails?.map((charge: any, rowIndex: number) => {
      return (
        <div className="p-h-r pt-r parent-width">
          <div className="row parent-width p-h-r p-v-s bg-gray-100 rounded">
            <div className="column parent-width">
              <div className="column">
                <DKLabel text={`Charge`} className="fs-m" />
              </div>
              <div className="column mt-xs">
                <DKLabel text={`${charge?.name}`} className="fw-m fs-m" />
              </div>
            </div>
            <div className="column parent-width">
              <div className="column">
                <DKLabel text={`Amount`} className="fs-m" />
              </div>
              <div className="column mt-xs">
                <div className="row">
                  <DKLabel
                    text={`${Utility.getCurrencySymbolFromCode(
                      booksDocument.currency
                    )} ${NumberFormatService.getNumber(charge?.chargeValue)}`}
                    className="fs-m"
                  />
                </div>
              </div>
            </div>
            <div className="column parent-width">
              <div className="column">
                <DKLabel text={`Method`} className="fs-m" />
              </div>
              <div className="row mt-xs">
                <DKRadioButton
                  title={`Quantity`}
                  isSelected={
                    charge?.apportionValue ===
                    ADDITIONAL_CHARGE_METHODS.APPORTION_ON_QTY
                  }
                  onClick={(data: any) => {
                    updateChargeFieldEditable(false);
                    onMethodChange(
                      ADDITIONAL_CHARGE_METHODS.APPORTION_ON_QTY,
                      rowIndex
                    );
                  }}
                  className="mr-m"
                  color={'bg-app'}
                  disabled={props.documentMode === DOCUMENT_MODE.VIEW}
                />
                <DKRadioButton
                  title={`Value`}
                  isSelected={
                    charge?.apportionValue ===
                    ADDITIONAL_CHARGE_METHODS?.APPORTION_ON_VALUE
                  }
                  onClick={(data: any) => {
                    updateChargeFieldEditable(false);
                    onMethodChange(
                      ADDITIONAL_CHARGE_METHODS?.APPORTION_ON_VALUE,
                      rowIndex
                    );
                  }}
                  className="mr-m"
                  color={'bg-app'}
                  disabled={props.documentMode === DOCUMENT_MODE.VIEW}
                />
                <DKRadioButton
                  title={`Manual`}
                  isSelected={
                    charge?.apportionValue ===
                    ADDITIONAL_CHARGE_METHODS?.APPORTION_MANUAL
                  }
                  onClick={(data: any) => {
                    updateChargeFieldEditable(true);
                    onMethodChange(
                      ADDITIONAL_CHARGE_METHODS?.APPORTION_MANUAL,
                      rowIndex
                    );
                  }}
                  className="mr-m"
                  color={'bg-app'}
                  disabled={props.documentMode === DOCUMENT_MODE.VIEW}
                />
              </div>
            </div>
          </div>
        </div>
      );
    });

  return (
    <div className="transparent-background">
      <div
        className="popup-window overflow-hidden"
        style={{
          width: 720,
          maxWidth: '90%',
          maxHeight: '90%',
          minHeight: 200,
          padding: 0,
          paddingBottom: 5,
          overflowY: 'scroll',
          pointerEvents: 'auto'
        }}
      >
        {getHeader()}
        <div className="column parent-width parent-height overflow-y-auto">
          {getChargesDetailSection()}
          {getProductItemList()}
        </div>
      </div>
    </div>
  );
};

export default AdditionalCharges;
