import {
  DKInput,
  DKLabel,
  INPUT_TYPE,
  INPUT_VIEW_DIRECTION
} from 'deskera-ui-library';
import { useEffect, useState } from 'react';
import ApiConstants from '../../Constants/ApiConstants';
import {
  formState,
  FORM_FIELD_NAME,
  initialFormState
} from '../../Constants/ReturnWarehouseManagementRRB';
import { useAppSelector } from '../../Redux/Hooks';
import { selectWarehouseByCode } from '../../Redux/Slices/StockAdjustmentSlice';
import { selectedWarehouseWithRRBCombination } from '../../Redux/Slices/WarehouseSlice';
import Utility from '../../Utility/Utility';
import { DOC_TYPE } from '../../Constants/Constant';

const ReturnWarehouseManagementRRB = (props: any) => {
  // const productWarehousesData: any = useAppSelector(
  //   props?.isIncludeRejectedWh
  //     ? selectedProductWarehouseWithRejectedWh
  //     : selectedProductWarehouse
  // );
  const productWarehousesData: any = useAppSelector(
    props?.editMode
      ? selectWarehouseByCode
      : selectedWarehouseWithRRBCombination
  );

  const [formState, setFormState] = useState<formState>(initialFormState);
  const [title, setTitle] = useState<any>({
    [FORM_FIELD_NAME.WAREHOUSE]: 'Warehouse',
    [FORM_FIELD_NAME.ROW]: 'Row',
    [FORM_FIELD_NAME.RACK]: 'Rack',
    [FORM_FIELD_NAME.BIN]: 'Bin'
  });

  useEffect(() => {
    if (!Utility.isEmpty(props?.title)) {
      let newState = { ...title };
      newState.WAREHOUSE = props?.title?.WAREHOUSE ?? newState.WAREHOUSE;
      newState.ROW = props?.title?.ROW ?? newState.ROW;
      newState.RACK = props?.title?.RACK ?? newState.RACK;
      newState.BIN = props?.title?.BIN ?? newState.BIN;
      setTitle({ ...newState });
    }
  }, [props]);

  useEffect(() => {
    if (!Utility.isEmpty(productWarehousesData)) {
      let selectedWarehouse = [];
      if (props?.editMode) {
        selectedWarehouse = productWarehousesData?.content?.find(
          (warehouse: any) => warehouse.code === props?.editData?.warehouseCode
        );
      } else {
        selectedWarehouse = productWarehousesData?.content?.find(
          (warehouse: any) => warehouse.primary
        );
        if (
          props.type &&
          props.type === DOC_TYPE.PURCHASE_RETURN &&
          productWarehousesData?.content &&
          Array.isArray(productWarehousesData.content)
        ) {
          selectedWarehouse = productWarehousesData.content.find(
            (warehouse: any) => warehouse.warehouseType === 'TOLERANCE_REJECTED'
          );
        }
        if (Utility.isEmpty(selectedWarehouse)) {
          selectedWarehouse = productWarehousesData?.content?.[0];
        }
      }
      if (!Utility.isEmpty(selectedWarehouse)) {
        onFormValueChange(selectedWarehouse, FORM_FIELD_NAME.WAREHOUSE);
        setFormState({
          ...formState,
          warehouse: {
            ...formState.warehouse,
            nonFilteredData: productWarehousesData?.content,
            data: getFilteredWarehouse(productWarehousesData?.content),
            value: selectedWarehouse
          },
          rows: {
            ...formState.rows,
            nonFilteredData: selectedWarehouse?.warehouseRowInfos,
            data: selectedWarehouse?.warehouseRowInfos,
            value: selectedWarehouse?.warehouseRowInfos?.find(
              (item: any) => item?.code === props?.editData?.rowCode
            ) ?? { code: null, name: null }
          },
          racks: {
            ...formState.racks,
            nonFilteredData: selectedWarehouse?.warehouseRackInfos,
            data: selectedWarehouse?.warehouseRackInfos,
            value: selectedWarehouse?.warehouseRackInfos?.find(
              (item: any) => item?.code === props?.editData?.rackCode
            ) ?? { code: null, name: null }
          },
          bins: {
            ...formState.bins,
            nonFilteredData: selectedWarehouse?.warehouseBinInfos,
            data: selectedWarehouse?.warehouseBinInfos,
            value: selectedWarehouse?.warehouseBinInfos?.find(
              (item: any) => item?.code === props?.editData?.binCode
            ) ?? { code: null, name: null }
          }
        });
      }
    }
  }, [productWarehousesData]);

  useEffect(() => {
    props.onSave(warehouseRRBData({ ...formState }));
  }, [formState]);

  const getFilteredWarehouse = (data: any) => {
    if (props?.isIncludeRejectedWh) {
      if (props.type === DOC_TYPE.PURCHASE_RETURN) {
        return data?.filter(
          (wh: any) =>
            wh.warehouseType === 'NONE' ||
            wh.warehouseType === 'REJECTED' ||
            wh.warehouseType === 'TOLERANCE_REJECTED'
        );
      } else {
        return data?.filter(
          (wh: any) =>
            wh.warehouseType === 'NONE' || wh.warehouseType === 'REJECTED'
        );
      }
    } else {
      if (props.type === DOC_TYPE.PURCHASE_RETURN) {
        return data?.filter(
          (wh: any) =>
            wh.warehouseType === 'NONE' ||
            wh.warehouseType === 'TOLERANCE_REJECTED'
        );
      } else {
        return data?.filter((wh: any) => wh.warehouseType === 'NONE');
      }
    }
  };

  const warehouseRRBData = (data: formState) => {
    let Payload = {};
    if (!Utility.isEmpty(data)) {
      Payload = {
        lastChangeKey: data?.lastChangeKey,
        warehouse: data?.warehouse?.value,
        rowCode: data?.rows?.value?.code ?? null,
        rackCode: data?.racks?.value?.code ?? null,
        binCode: data?.bins?.value?.code ?? null
      };
    }
    return Payload;
  };

  const onFormValueChange = (value: any, type: string) => {
    let newState = { ...formState };
    switch (type) {
      case FORM_FIELD_NAME.WAREHOUSE:
        newState.lastChangeKey = FORM_FIELD_NAME.WAREHOUSE;
        newState.warehouse.value = value;

        // Rows
        newState.rows.nonFilteredData = value?.warehouseRowInfos;
        newState.rows.data = value?.warehouseRowInfos;
        newState.rows.value = value?.warehouseRowInfos?.[0];

        // Racks
        let racks = !Utility.isEmpty(value?.warehouseRackInfos)
          ? value?.warehouseRackInfos?.filter((rackChip: any) => {
              if (newState.rows.value) {
                return rackChip.rowCode === newState.rows.value?.code;
              } else {
                return true;
              }
            })
          : [];
        newState.racks.nonFilteredData = value?.warehouseRackInfos;
        newState.racks.data = racks ?? [];
        newState.racks.value = racks?.[0];

        //Bins
        let bins = !Utility.isEmpty(value?.warehouseBinInfos)
          ? value?.warehouseBinInfos?.filter((binChip: any) => {
              if (newState.racks.value) {
                return binChip.rackCode === newState.racks.value?.code;
              } else if (newState.rows.value) {
                return binChip.rowCode === newState.rows.value?.code;
              } else {
                return true;
              }
            })
          : [];
        newState.bins.nonFilteredData = value?.warehouseBinInfos;
        newState.bins.data = bins;
        newState.bins.value = bins?.[0];

        setFormState({ ...newState });
        return;
      case FORM_FIELD_NAME.ROW:
        newState.lastChangeKey = FORM_FIELD_NAME.ROW;
        // Rows
        newState.rows.value = value;
        // Racks
        let filteredRacksDataForRow: any[] =
          newState?.racks?.nonFilteredData?.filter(
            (rack: any) =>
              Utility.isEmpty(rack.rowCode) ||
              rack.rowCode === newState.rows.value?.code
          );
        newState.racks.data = filteredRacksDataForRow?.length
          ? filteredRacksDataForRow
          : [];

        //Bins
        // let filteredBinsDataForRow: any[] =
        //   newState?.bins?.nonFilteredData?.filter(
        //     (bin: any) =>
        //       Utility.isEmpty(bin.rowCode) ||
        //       bin.rowCode === newState.rows.value?.code
        //   );

        let filteredBinsDataForRow: any[] =
          newState?.bins?.nonFilteredData?.filter((binChip: any) => {
            if (newState.racks.value) {
              return binChip.rackCode === newState.racks.value?.code;
            } else if (newState.rows.value) {
              return binChip.rowCode === newState.rows.value?.code;
            } else {
              return true;
            }
          });

        newState.bins.data = filteredBinsDataForRow.length
          ? filteredBinsDataForRow
          : [];
        setFormState({ ...newState });
        break;
      case FORM_FIELD_NAME.RACK:
        newState.lastChangeKey = FORM_FIELD_NAME.RACK;
        // Racks
        newState.racks.value = value;
        //Bins
        let filteredBinsDataForRack: any[] =
          newState?.bins?.nonFilteredData?.filter(
            (bin: any) =>
              Utility.isEmpty(bin.rackCode) ||
              bin.rackCode === newState.racks.value?.code
          );
        newState.bins.data = filteredBinsDataForRack.length
          ? filteredBinsDataForRack
          : [];

        setFormState({ ...newState });

        break;
      case FORM_FIELD_NAME.BIN:
        newState.lastChangeKey = FORM_FIELD_NAME.BIN;
        // Bins
        newState.bins.value = value;
        setFormState({ ...newState });
        break;
      default:
        break;
    }
  };

  return (
    <div className="row overflow-y-visible show-scroll-bar" style={{ gap: 12 }}>
      <div
        className={`${
          formState?.rows?.data?.length ? `row w-96` : `column w-full pr-s`
        }`}
      >
        <DKInput
          readOnly={
            props.type
              ? props.type === DOC_TYPE.PURCHASE_RETURN
              : props?.editMode ?? false
          }
          title={title.WAREHOUSE}
          onChange={(value: any) => {
            onFormValueChange(value, FORM_FIELD_NAME.WAREHOUSE);
          }}
          value={formState.warehouse.value}
          canValidate={props.saveButtonTapped}
          required={true}
          direction={INPUT_VIEW_DIRECTION.VERTICAL}
          type={INPUT_TYPE.DROPDOWN}
          formatter={(obj: any) => {
            return obj?.name;
          }}
          dropdownConfig={{
            title: '',
            allowSearch: true,
            searchableKey: 'name',
            style: { minWidth: 150 },
            className: 'shadow-m width-auto',
            searchApiConfig: {
              getUrl: (searchValue: string) => {
                const query: string = `?limit=50&page=0&allRRBDetails=true&search=${searchValue}&query=active=true`;
                const finalEndPoint =
                  ApiConstants.URL.BASE +
                  ApiConstants.URL.ACCOUNTS.WAREHOUSES +
                  query;
                return finalEndPoint;
              },
              dataParser: (response: any) => {
                let filtered = getFilteredWarehouse(response?.content);
                return filtered;
              },
              debounceTime: 300
            },
            data: formState.warehouse.data.length
              ? getFilteredWarehouse(formState.warehouse.nonFilteredData)
              : [],
            renderer: (index: any, obj: any) => {
              return <DKLabel text={obj?.name} />;
            }
          }}
        />
      </div>

      {!Utility.isEmpty(formState?.rows?.data) && (
        <div className="column parent-width">
          <DKInput
            title={title.ROW}
            readOnly={props?.editMode ?? false}
            onChange={(value: any) => {
              onFormValueChange(value, FORM_FIELD_NAME.ROW);
            }}
            canValidate={props.saveButtonTapped}
            required={true}
            value={formState?.rows?.value}
            direction={INPUT_VIEW_DIRECTION.VERTICAL}
            type={INPUT_TYPE.DROPDOWN}
            formatter={(obj: any) => {
              return obj?.name;
            }}
            dropdownConfig={{
              title: '',
              allowSearch: false,
              searchableKey: '',
              style: { minWidth: 150 },
              className: 'shadow-m width-auto',
              searchApiConfig: null,
              data: formState?.rows?.data?.length ? formState?.rows?.data : [],
              renderer: (index: any, obj: any) => {
                return <DKLabel text={obj?.name} />;
              }
            }}
          />
        </div>
      )}
      {!Utility.isEmpty(formState?.racks?.data) && (
        <div className="column parent-width">
          <DKInput
            title={title.RACK}
            readOnly={props?.editMode ?? false}
            onChange={(value: any) => {
              onFormValueChange(value, FORM_FIELD_NAME.RACK);
            }}
            canValidate={props.saveButtonTapped}
            required={true}
            value={formState?.racks?.value}
            direction={INPUT_VIEW_DIRECTION.VERTICAL}
            type={INPUT_TYPE.DROPDOWN}
            formatter={(obj: any) => {
              return obj?.name;
            }}
            dropdownConfig={{
              title: '',
              allowSearch: false,
              searchableKey: '',
              style: { minWidth: 150 },
              className: 'shadow-m width-auto',
              searchApiConfig: null,
              data: formState?.racks?.data?.length
                ? formState?.racks?.data
                : [],
              renderer: (index: any, obj: any) => {
                return <DKLabel text={obj?.name} />;
              }
            }}
          />
        </div>
      )}
      {!Utility.isEmpty(formState?.bins?.data) && (
        <div className="column parent-width">
          <DKInput
            title={title.BIN}
            readOnly={props?.editMode ?? false}
            onChange={(value: any) => {
              onFormValueChange(value, FORM_FIELD_NAME.BIN);
            }}
            canValidate={props.saveButtonTapped}
            required={true}
            value={formState?.bins?.value}
            direction={INPUT_VIEW_DIRECTION.VERTICAL}
            type={INPUT_TYPE.DROPDOWN}
            formatter={(obj: any) => {
              return obj?.name;
            }}
            dropdownConfig={{
              title: '',
              allowSearch: false,
              searchableKey: '',
              style: { minWidth: 150 },
              className: 'shadow-m width-auto',
              searchApiConfig: null,
              data: formState?.bins?.data?.length ? formState?.bins?.data : [],
              renderer: (index: any, obj: any) => {
                return <DKLabel text={obj?.name} />;
              }
            }}
          />
        </div>
      )}
    </div>
  );
};

export default ReturnWarehouseManagementRRB;
