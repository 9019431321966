import { reject } from 'lodash';
import ApiConstants from '../Constants/ApiConstants';
import Utility from '../Utility/Utility';
import http from './Interceptor';

export interface DebitAPIConfig {
  SearchTerm?: string;
  Limit?: number;
  Page?: number;
  SortDir?: string;
  Sort?: string;
  Query?: string;
  Dir?: string;
  Cntype?: string | null;
  IsCustomDetailLineReport?: boolean;
  QueryParam?: any;
}

const defaultConfig: DebitAPIConfig = {
  SearchTerm: '',
  Limit: 25,
  Page: 0,
  SortDir: 'DESC',
  Sort: 'dnDate',
  Query: '',
  Dir: 'DESC',
  IsCustomDetailLineReport: false,
  Cntype: null,
  QueryParam: ''
};

class DebitService {
  static apiConfig: DebitAPIConfig = defaultConfig;
  static abortController: any = null;

  static getDebit() {
    Utility.cancelRequest(DebitService.abortController);
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    }
    const queryString: string = `?limit=${this.apiConfig.Limit}&page=${
      this.apiConfig.Page
    }&sortDir=${this.apiConfig.SortDir}&sort=${this.apiConfig.Sort}&search=${
      this.apiConfig.SearchTerm ? encodeURIComponent(this.apiConfig.SearchTerm) : ''
    }&query=${this.apiConfig.Query}&dir=${this.apiConfig.Dir}&cntype=${
      this.apiConfig.Cntype
    }&isCustomDetailLineReport=${this.apiConfig.IsCustomDetailLineReport}${
      this.apiConfig.QueryParam ? this.apiConfig.QueryParam : ''
    }`;
    const finalEndpoint: string =
      ApiConstants.URL.DEBIT_NOTE.FETCH_DEBIT_NOTE + queryString;

    DebitService.abortController = Utility.createAbortController();

    return http
      .get(`${finalEndpoint}`, {
        signal: DebitService.abortController.signal
      })
      .catch((err: any) => {
        console.error('Error loading Debit: ', err);
        return Promise.reject(err);
      });
  }

  static getDebitNoteWithCode = (code: string) => {
    let url = `${ApiConstants.URL.BASE}notes/debit?limit=25&page=0&sortDir=DESC&sort=dnDate&search=${code}`;
    return http
      .get(url)
      .then((response: any) => {
        return Promise.resolve(response);
      })
      .catch((err: any) => {
        return Promise.reject(err);
      });
  };

  static createDebitNote(payload: any) {
    return http
      .post(`${ApiConstants.URL.DEBIT_NOTE.CREATE_DEBIT_NOTE}`, payload)
      .catch((err: any) => {
        console.error('Error while trying to post status: ', err);
        return Promise.reject(err);
      });
  }

  static updateDebitNote(id: number, payload: any) {
    return http
      .put(`${ApiConstants.URL.DEBIT_NOTE.UPDATE_DEBIT_NOTE(id)}`, payload)
      .catch((err: any) => {
        console.error('Error updating debit note: ', err);
        return Promise.reject(err);
      });
  }

  static deleteDebitNote(id: number) {
    return http
      .delete(`${ApiConstants.URL.DEBIT_NOTE.DELETE_DEBIT_NOTE(id)}`)
      .catch((err: any) => {
        console.error('Error updating credit note: ', err);
        return Promise.reject(err);
      });
  }

  static getDebitNote(id: string) {
    const queryString = '?search=' + id;
    const endpoint = ApiConstants.URL.DEBIT_NOTE.FETCH_DEBIT_NOTE + queryString;
    return http.get(endpoint);
  }
}

export default DebitService;
