import { showAlert } from 'deskera-ui-library';
import InvoiceService from '../../../Services/Invoice';
import Utility, { deepClone } from '../../../Utility/Utility';
import DateFormatService from '../../../Services/DateFormat';
import {
  BOOKS_DATE_FORMAT,
  LABELS,
  QTY_ROUNDOFF_PRECISION
} from '../../../Constants/Constant';
import {
  MachineMaterialConsumptionT,
  MachineMaterialItem
} from '../../../Services/MachineMaterialConsumptionService';
import {
  draftTableId,
  selectDraftsColumnConfig
} from '../../../Redux/Slices/DraftsSlice';
import { Store } from '../../../Redux/Store';
import {
  MachineMaintainceInitailState,
  MachineShortFallRowItem
} from '../../../Services/MachineMasterService';
import { getUpdatedRequisitonNewOrderObject } from '../../Requisitions/RequisitionHelper';
import { getUpdatedPurchaseOrderObject } from '../../PurchaseOrders/PurchaseOrderHelper';
import { OrderInitialState } from '../../../Models/Order';
import MachineMaterialShortFallService from '../../../Services/MachineMaterialShortFallService';

export class MachineMasterHelper {
  static async getMachineData(data: any, getBillData = true) {
    let purchaseInvoiceData = {};
    if (data.rowData.isAsset === true) {
      try {
        if (getBillData) {
          purchaseInvoiceData =
            await InvoiceService.getPurchaseInvoiceWithCountryAndCode(
              data.rowData.assetResponse.expenseBillCode
            );
        }
      } catch (error) {
        showAlert('Error', 'Unable to fetch purchase invoice data');
        console.error(error);
      }
    }

    const updatedMachine = deepClone(data.rowData);
    const assetRequest = updatedMachine.assetResponse;

    if (assetRequest !== null) {
      assetRequest.purchaseInvoiceRequest = { ...purchaseInvoiceData };
      updatedMachine.assetRequest = assetRequest;
      const purchaseInvoiceId = assetRequest?.purchaseInvoiceRequest?.id;
      updatedMachine.purchaseInvoiceId = purchaseInvoiceId;
      updatedMachine.assetRequest.purchaseInvoiceId = purchaseInvoiceId;
      updatedMachine.assetResponse = null;
      updatedMachine.assetRequest.assetGroupId =
        updatedMachine.localAssetGroupId;
      updatedMachine.assetRequest.assetGroup = updatedMachine.localAssetGroupId;
    } else {
      updatedMachine.assetRequest = {
        ...MachineMaintainceInitailState.assetRequest
      };
    }

    updatedMachine.assetid = updatedMachine.savedAssetId;
    updatedMachine.location = updatedMachine?.locationId;
    updatedMachine.assetGroup = updatedMachine.localAssetGroupId;
    updatedMachine.effectiveDate = DateFormatService.getDateStrFromDate(
      new Date(updatedMachine.effectiveDate),
      BOOKS_DATE_FORMAT['YYYY-MM-DD']
    );
    updatedMachine.warrantyExpirationDate =
      DateFormatService.getDateStrFromDate(
        new Date(updatedMachine.warrantyExpirationDate),
        BOOKS_DATE_FORMAT['YYYY-MM-DD']
      );
    updatedMachine.expectedNextMaintenanceDate =
      DateFormatService.getDateStrFromDate(
        new Date(updatedMachine.expectedNextMaintenanceDate),
        BOOKS_DATE_FORMAT['YYYY-MM-DD']
      );
    updatedMachine.latestFailure = DateFormatService.getDateStrFromDate(
      new Date(updatedMachine.latestFailure),
      BOOKS_DATE_FORMAT['YYYY-MM-DD']
    );
    delete updatedMachine.rowContextMenu;
    return updatedMachine;
  }
  static percentageOfSopCompleted(machineSop: string[], scheduleSop: string[]) {
    if (machineSop?.length === 0) {
      return 0;
    }
    const intersection = machineSop.filter((sop) => scheduleSop.includes(sop));
    let percent = (intersection.length / machineSop.length) * 100;
    return Math.floor(percent);
  }
}

const filterRequisitionProductObject = async (
  items: MachineShortFallRowItem[],
  consumption: MachineMaterialConsumptionT
) => {
  let orderItems: any[] = [];

  items.forEach((item, index) => {
    let shortFallQty = Math.abs(item.availability);
    let orderItem: any = {
      ...item,
      product: {
        ...item.productId,
        name: item?.productId?.name,
        documentSequenceCode: item?.productId?.documentSequenceCode,
        documentUOMSchemaDefinition: item?.documentUOMSchemaDefinition
          ? {
              ...item?.documentUOMSchemaDefinition,
              schemaId: item?.uom
            }
          : null,
        documentUom: item?.uom,
        uom: item?.uomInternal,
        uomQuantity: Utility.roundOff(
          item?.uomQuantity ?? 1,
          QTY_ROUNDOFF_PRECISION
        )
      },
      uom: item?.uomInternal,
      documentUom: item?.uom ? item?.uom : item?.uomInternal.id,
      unitPrice: item.unitPrice ?? 0,
      productQuantity: Utility.roundOff(shortFallQty, QTY_ROUNDOFF_PRECISION),
      lineNumber: index + 1,
      productCode: item.productId?.id,
      documentSequenceCode: item?.productCode,
      productName: item.productId?.name,
      uomUnitPrice: item.unitPrice,
      productDescription: item?.description,
      pendingQuantity: 0,
      discountInPercent: false,
      userSetTaxes: false,
      expectedDeliveryDt: DateFormatService.getDateFromISOStr(
        consumption?.endDate ?? ''
      )
    };
    delete orderItem.productId;
    orderItems.push(orderItem);
  });
  return orderItems;
};

const filterPurchaseOrderProductObject = async (
  items: MachineShortFallRowItem[],
  consumption: MachineMaterialConsumptionT
) => {
  let orderItems: any[] = [];
  items.forEach((item, index) => {
    let shortFallQty = Math.abs(item.availability);
    let orderItem: any = {
      ...item,
      productCode: item?.productId?.documentSequenceCode,
      product: {
        ...item.productId,
        name: item?.productId?.name,
        documentSequenceCode: item?.productId?.documentSequenceCode,
        documentUOMSchemaDefinition: item?.documentUOMSchemaDefinition
          ? {
              ...item?.documentUOMSchemaDefinition,
              schemaId: item?.uomInternal
            }
          : null,
        documentUom: item?.uom ? item?.uom : item?.uomInternal.id,
        uom: item?.uomInternal,
        uomQuantity: Utility.roundOff(
          item?.uomQuantity ?? 1,
          QTY_ROUNDOFF_PRECISION
        )
      },
      uom: item?.uomInternal,
      documentUom: item?.uom ? item?.uom : item?.uomInternal.id,
      unitPrice: item.unitPrice ?? 0,
      productQuantity: Utility.roundOff(shortFallQty, QTY_ROUNDOFF_PRECISION),
      lineNumber: index + 1,
      documentSequenceCode: item?.productCode,
      productName: item.productId?.name,
      uomUnitPrice: item.unitPrice,
      productDescription: item?.description,
      pendingQuantity: 0,
      discountInPercent: false,
      userSetTaxes: false,
      expectedDeliveryDt: DateFormatService.getDateFromISOStr(
        consumption?.endDate ?? ''
      )
    };
    // delete orderItem.product.productTransactionType;
    // delete orderItem.product.productTransactionType;
    delete orderItem.productId;
    orderItems.push(orderItem);
  });
  return orderItems;
};
const filterLinkedLineItems = (orderItems: any) => {
  let linkedLineItemsDetails: any = {};
  orderItems.forEach((item: any) => {
    linkedLineItemsDetails[item.lineNumber] = {
      productCode: item.productCode,
      itemProductCode: item.productCode,
      isSubstitute: false
    };
  });
  return linkedLineItemsDetails;
};

export const getPurchaseRequisitionPayloadFromProductDataForMachine = async (
  productList: MachineShortFallRowItem[],
  consumption: MachineMaterialConsumptionT
) => {
  let orderItems: any =
    (await filterRequisitionProductObject(productList, consumption)) ?? [];

  let linkedLineItemsDetails: any = filterLinkedLineItems(orderItems);

  let formData = {
    dueDate: '',
    purchaseRequestItems: orderItems,
    receiveByDate: DateFormatService.getDateStrFromDate(
      new Date(consumption.endDate ?? 0),
      BOOKS_DATE_FORMAT['DD-MM-YYYY']
    ),
    showCustomAlert: false,
    updateResponseInStore: true,
    linkedDocuments: [
      {
        documentType: 'MACHINE',
        documentCode: consumption.scheduleId,
        documentSequenceCode: consumption.scheduleId,
        documentReceiveByDate: DateFormatService.getDateStrFromDate(
          new Date(consumption.endDate ?? 0),
          BOOKS_DATE_FORMAT['DD-MM-YYYY']
        ),
        productCodes: [],
        lineItemDetails: linkedLineItemsDetails
      }
    ]
  };

  formData = {
    ...getUpdatedRequisitonNewOrderObject(formData),
    creationSource: {
      type: 'MACHINE_MATERIAL_CONSUMPTION_SHORT_FALL',
      data: consumption
    }
  };

  const tableId = draftTableId(Store.getState());
  const draftsColumnConfig = selectDraftsColumnConfig(Store.getState());

  return {
    title: LABELS.REQUISITION,
    type: LABELS.REQUISITION,
    tableId: tableId,
    columnConfig: draftsColumnConfig,
    populateFormData: formData,
    isCenterAlign: true,
    hideMinimizer: true
  };
};

export const getPurchaseOrderPayloadFromProductDataForMachine = async (
  productList: MachineShortFallRowItem[],
  consumption: MachineMaterialConsumptionT
) => {
  const orderItems = await filterPurchaseOrderProductObject(
    productList,
    consumption
  );

  let linkedLineItemsDetails: any = filterLinkedLineItems(orderItems);
  let contact = null;

  let po: any = {
    ...OrderInitialState,
    purchaseOrderItems: orderItems,
    receiveByDate: consumption.endDate,
    showCustomAlert: false,
    updateResponseInStore: true,
    contact: contact,
    linkedDocuments:
      consumption.machineId != null && consumption.machineId !== undefined
        ? [
            {
              documentType: 'MACHINE',
              documentCode: consumption.machineId,
              documentSequenceCode: consumption.machineId,
              documentReceiveByDate: DateFormatService.getDateStrFromDate(
                DateFormatService.getDateFromISOStr(consumption?.endDate ?? ''),
                BOOKS_DATE_FORMAT['DD-MM-YYYY']
              ),
              productCodes: [],
              lineItemDetails: linkedLineItemsDetails
            }
          ]
        : [],
    autoCreatePOFromWO: true,
    isCreateFromMRP: true,
    creationSource: {
      type: 'MACHINE_MATERIAL_CONSUMPTION_SHORT_FALL',
      data: consumption
    }
  };

  po = getUpdatedPurchaseOrderObject(po);
  const tableId = draftTableId(Store.getState());
  const draftsColumnConfig = selectDraftsColumnConfig(Store.getState());
  // let linkedLineItemsDetails: any = filterLinkedLineItems(orderItems);
  return {
    title: LABELS.PURCHASE_ORDERS,
    type: LABELS.PURCHASE_ORDERS,
    populateFormData: po,
    isCenterAlign: true,
    tableId: tableId,
    columnConfig: draftsColumnConfig,
    hideMinimizer: true
  };
};

export function handleMachineShortFallPOCreate(payload: any, response: any) {
  if (
    payload?.creationSource &&
    payload?.creationSource?.type === 'MACHINE_MATERIAL_CONSUMPTION_SHORT_FALL'
  ) {
    MachineMaterialShortFallService.linkShortFallInfo(
      payload?.creationSource?.data.scheduleId,
      {
        documentCode: response?.poCode,
        documentSequenceCode: response?.documentSequenceCode,
        documentType: 'PURCHASE_ORDER'
      }
    );
  }
}

export function handleMachineShortFallPRCreate(payload: any, response: any) {
  if (
    payload?.creationSource &&
    payload?.creationSource?.type === 'MACHINE_MATERIAL_CONSUMPTION_SHORT_FALL'
  ) {
    MachineMaterialShortFallService.linkShortFallInfo(
      payload?.creationSource?.data?.scheduleId,
      {
        documentCode: response?.purchaseRequestCode,
        documentSequenceCode: response?.documentSequenceCode,
        documentType: 'PURCHASE_ORDER'
      }
    );
  }
}
