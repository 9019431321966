import { useState, useEffect, useRef } from 'react';
import { useAppSelector } from '../../../../../Redux/Hooks';
import {
  DKButton,
  DKLabel,
  DKListPicker2,
  DKDataGrid,
  DKIcons
} from 'deskera-ui-library';
import { selectAdditionalBuyCharges } from '../../../../../Redux/Slices/AdditionalChargesSlice';
import Utility, { deepClone } from '../../../../../Utility/Utility';
import AddAdditionalCharges from '../../../../Invoices/AddAdditionalCharges';
import { REGEX } from '../../../../../Constants/Constant';
import { amountFormatter } from '../../WorkOrderHelper';
import { activeTenantInfo } from '../../../../../Redux/Slices/AuthSlice';
import {
  COST_CONFIG_TYPE,
  WO_ADDITIONAL_CHARGES_COLUMNS,
  WO_ADDITIONAL_CHARGES_KEYS
} from '../../../Constants/MRPColumnConfigs';
import {
  getChargeAmount,
  getUnselectedAdditionalCharges
} from './WorkOrderAdditionalChargeHelper';
import useScreenResize from '../../../../../Hooks/useScreenResize';
import { IColumn } from '../../../../../Models/Table';
import { selectIsWOAdhocEnable } from '../../../../../Redux/Slices/MRP/SettingsSlice';

export interface IAdditionalChargesProps {
  isEditMode?: boolean;
  isReadOnlyMode: boolean;
  totalCost: number;
  onChargesUpdated: (data: any) => void;
  currentTabWO: any;
  onClickNewAdditionalCharge?: () => void;
  onNameUpdated: (data: any) => void;
  onBOMDeleteRow: (data: any) => void;
}

export default function WorkOrderAdditionalCharges(
  props: IAdditionalChargesProps
) {
  const tenantInfo = useAppSelector(activeTenantInfo);
  const additionalBuyCharges = useAppSelector(selectAdditionalBuyCharges);

  const [selectedCharges, setSelectedCharges] = useState<any[]>([]);
  const [showChargesPicker, setShowChargesPicker] = useState<boolean>(false);
  const [selectChargeForEdit, setSelectChargeForEdit] = useState<any>();
  const [showAddAdditionalCharges, setShowAddAdditionalCharges] =
    useState(false);

  useEffect(() => {
    if (
      !Utility.isEmpty(props?.currentTabWO?.additionalCharges) &&
      !Utility.isEmpty(additionalBuyCharges)
    ) {
      let tempSelectedCharges: any[] = [];
      props?.currentTabWO?.additionalCharges?.additionalChargesDetails?.forEach(
        (charge: any) => {
          const chargeInAdditionalBuyCharges = additionalBuyCharges.find(
            (c: any) => c.name === charge.additionalCharge
          );
          if (chargeInAdditionalBuyCharges) {
            tempSelectedCharges.push({
              ...chargeInAdditionalBuyCharges,
              expenseAccountCode: charge.additionalChargeAccountCode,
              isPercent: charge.isPercent,
              percentageValue: charge.percent,
              chargeValue: charge.chargeAmount,
              chargeApplicableOn: charge.isPreCharge
                ? WO_ADDITIONAL_CHARGES_KEYS.PRE_CHARGES_SUB_TOTAL
                : WO_ADDITIONAL_CHARGES_KEYS.PRE_CHARGES_TOTAL,
              invalidRow: charge.invalidRow || false,
              additionalCostType: COST_CONFIG_TYPE.WO,
              invalidFields: charge?.invalidField ?? charge?.invalidFields ?? []
            });
          }
        }
      );

      // push bom products additional charges as readonly
      if (!Utility.isEmpty(props?.currentTabWO?.bomAddCostConfiguration)) {
        props?.currentTabWO?.bomAddCostConfiguration?.forEach(
          (bomCostConfig: any) => {
            tempSelectedCharges.push({
              chargeValue:
                bomCostConfig?.price *
                  props?.currentTabWO?.manufactureQuantity ?? 0,
              name: bomCostConfig?.label ?? '',
              additionalCostType: COST_CONFIG_TYPE.BOM,
              isNewRow: bomCostConfig?.isNewRow ?? false,
              rowSequenceNumber: bomCostConfig?.rowSequenceNumber ?? 0,
              invalidFields: bomCostConfig?.invalidFields ?? []
            });
          }
        );
      }
      setSelectedCharges(tempSelectedCharges);
    } else {
      // push bom products additional charges as readonly
      let tempSelectedCharges: any[] = [];
      if (!Utility.isEmpty(props?.currentTabWO?.bomAddCostConfiguration)) {
        props?.currentTabWO?.bomAddCostConfiguration?.forEach(
          (bomCostConfig: any) => {
            tempSelectedCharges.push({
              chargeValue:
                bomCostConfig?.price *
                  props?.currentTabWO?.manufactureQuantity ?? 0,
              name: bomCostConfig?.label ?? '',
              additionalCostType: COST_CONFIG_TYPE.BOM,
              isNewRow: bomCostConfig?.isNewRow ?? false,
              rowSequenceNumber: bomCostConfig?.rowSequenceNumber ?? 0,
              invalidFields: bomCostConfig?.invalidFields ?? []
            });
          }
        );
      }
      setSelectedCharges(tempSelectedCharges ?? []);
    }
  }, [
    props?.currentTabWO?.additionalCharges,
    additionalBuyCharges,
    props?.currentTabWO?.manufactureQuantity,
    props?.currentTabWO?.bomAddCostConfiguration
  ]);

  /**********************************GRID EVENTS
   *********************************************
   */
  const onActionButtonClick = (rowIndex: number, row: any) => {
    const tempArr = [...selectedCharges];
    tempArr.splice(rowIndex, 1);
    setSelectedCharges(tempArr);
    if (
      props.onChargesUpdated &&
      row.additionalCostType === COST_CONFIG_TYPE.WO
    ) {
      props.onChargesUpdated(tempArr);
    }
    if (row.additionalCostType === COST_CONFIG_TYPE.BOM) {
      props.onBOMDeleteRow(row);
    }
  };

  const onNewRowUpdate = (data: any) => {
    const { columnKey, rowData, rowIndex } = data;
    switch (columnKey) {
      case WO_ADDITIONAL_CHARGES_KEYS.CHARGE_VALUE:
        let cValue = rowData[columnKey] || '';
        let chargeValueInvalid = false;
        let isPercent = false;
        const matcher = String(cValue).match(REGEX.PERCENT_NUMER);
        if (!matcher) {
          chargeValueInvalid = true;
        } else if ('%' === matcher[4]) {
          const percentDiscount = Number(cValue.replace('%', ''));
          if (percentDiscount > 100) {
            chargeValueInvalid = true;
          } else {
            chargeValueInvalid = false;
            cValue = percentDiscount;
          }
          isPercent = true;
        } else if (cValue < 0) {
          chargeValueInvalid = true;
        }

        let tempSelectedCharges = [...selectedCharges];
        let tempChargeValue = 0;
        if (!chargeValueInvalid) {
          if (isPercent) {
            tempChargeValue = props.totalCost * (cValue / 100);
          } else {
            tempChargeValue = cValue;
          }
          tempSelectedCharges[rowIndex] = {
            ...tempSelectedCharges[rowIndex],
            chargeValue: tempChargeValue,
            isPercent: isPercent,
            percentageValue: isPercent ? cValue : 0,
            invalidRow: false,
            invalidFields: []
          };
        } else {
          tempChargeValue = cValue;
          const invalidFields = [
            ...(tempSelectedCharges[rowIndex].invalidFields || [])
          ];
          if (
            !invalidFields.includes(WO_ADDITIONAL_CHARGES_KEYS.CHARGE_VALUE)
          ) {
            invalidFields.push(WO_ADDITIONAL_CHARGES_KEYS.CHARGE_VALUE);
          }
          tempSelectedCharges[rowIndex] = {
            ...tempSelectedCharges[rowIndex],
            chargeValue: tempChargeValue,
            isPercent: isPercent,
            percentageValue: isPercent ? cValue : 0,
            invalidRow: true,
            invalidFields: invalidFields
          };
        }
        setSelectedCharges(tempSelectedCharges);
        if (props.onChargesUpdated) {
          props.onChargesUpdated(tempSelectedCharges);
        }
        break;

      case WO_ADDITIONAL_CHARGES_KEYS.NAME:
        let tempSelectedName: any = [...selectedCharges];
        tempSelectedName[rowIndex] = {
          ...tempSelectedName[rowIndex],
          name: rowData[columnKey]
        };
        if (Utility.isEmpty(tempSelectedName[rowIndex].name)) {
          tempSelectedName[rowIndex] = {
            ...tempSelectedName[rowIndex],
            invalidFields: (
              tempSelectedName[rowIndex].invalidFields || []
            ).concat(WO_ADDITIONAL_CHARGES_KEYS.NAME)
          };
        } else {
          let invalidRows = [
            ...(tempSelectedName[rowIndex].invalidFields || [])
          ].filter((rowKey: any) => rowKey !== columnKey);
          tempSelectedName[rowIndex] = {
            ...tempSelectedName[rowIndex],
            invalidFields: invalidRows
          };
        }
        if (props.onNameUpdated) {
          props.onNameUpdated(tempSelectedName[rowIndex]);
        }
        break;

      default:
        break;
    }
  };

  const addCharge = (charge: any) => {
    charge = {
      ...charge,
      additionalCostType: COST_CONFIG_TYPE.WO,
      chargeValue: getChargeAmount(charge, props.totalCost)
    };
    const updatedCharges = [...selectedCharges, charge];
    setSelectedCharges(updatedCharges);
    if (props.onChargesUpdated) {
      props.onChargesUpdated(updatedCharges);
    }
  };

  /**********************************GRID UTILS
   ********************************************
   */
  type AdditionalChargesFormatterData = {
    rowData: any;
    rowIndex: number;
    column: Partial<IColumn>;
    columnKey: string;
  };

  const getColumnConfig = () => {
    // @ts-ignore
    let copyOfColumnConfig: Partial<IColumn>[] = deepClone(
      WO_ADDITIONAL_CHARGES_COLUMNS
    );
    copyOfColumnConfig.forEach((column) => {
      column.editable = false;

      if (
        !props.isEditMode &&
        column.key !== WO_ADDITIONAL_CHARGES_KEYS.ACTION
      ) {
        column.width = 240;
      }
      switch (column.key) {
        case WO_ADDITIONAL_CHARGES_KEYS.CHARGE_VALUE:
          if (column.name === WO_ADDITIONAL_CHARGES_KEYS.CHARGE_VALUE_TITLE) {
            column.editable = true;
            // column.renderer = ({
            //   rowData,
            //   rowIndex
            // }: AdditionalChargesFormatterData) =>
            //   getChargeValueRender(rowData, rowIndex, column);
          } else if (column.name === WO_ADDITIONAL_CHARGES_KEYS.TOTAL) {
            column.renderer = ({ rowData }: AdditionalChargesFormatterData) => {
              return (
                <>
                  {!rowData.invalidRow && (
                    <DKLabel
                      className="parent-width text-align-right fs-m"
                      text={amountFormatter(
                        rowData.chargeValue,
                        tenantInfo?.currency
                      )}
                    />
                  )}
                </>
              );
            };
          }
          break;
        case WO_ADDITIONAL_CHARGES_KEYS.NAME:
          column.editable = true;
          column.renderer = ({
            rowData,
            rowIndex
          }: AdditionalChargesFormatterData) => {
            return (
              <DKLabel className="parent-width fs-m" text={rowData.name} />
            );
          };
          break;

        default:
          break;
      }
    });
    return copyOfColumnConfig;
  };

  // Open additional charge popup
  const getAdditionalChargesPopUp = () => {
    return (
      showAddAdditionalCharges && (
        <AddAdditionalCharges
          object={selectChargeForEdit}
          onCreated={(charge: any) => {
            if (!Utility.isEmpty(charge)) {
              // Set selected charge
            }
          }}
          onClose={() => {
            setShowAddAdditionalCharges(false);
          }}
        />
      )
    );
  };

  const getUnselectedChargesPicker = () => {
    const unSelectedCharges: any[] = getUnselectedAdditionalCharges(
      selectedCharges,
      [...additionalBuyCharges]
    );

    return (
      <DKListPicker2
        title="Select charge"
        data={unSelectedCharges}
        style={{
          width: 180,
          left: 0,
          top: 0
        }}
        allowSearch={true}
        searchableKey={'name'}
        canEdit={true}
        canDelete={false}
        className="position-absolute z-index-3 shadow-m border-m"
        onSelect={(index: number, charge: any) => {
          if (!Utility.isEmpty(charge)) {
            setShowChargesPicker(false);
            addCharge(charge);
          }
        }}
        onEdit={(index: any, charge: any) => {
          setShowChargesPicker(false);
          setSelectChargeForEdit(charge);
          setShowAddAdditionalCharges(true);
        }}
        onClose={() => {
          if (showChargesPicker) {
            setShowChargesPicker(false);
          }
        }}
        renderer={(index: number, charge: any) => {
          return <DKLabel text={charge.name} />;
        }}
      />
    );
  };

  const renderEmptyState = () => {
    return (
      <div className="column parent-size align-items-center justify-content-center text-gray">
        No additional charges found for this work order
      </div>
    );
  };

  const getHeader = () => {
    return (
      <div className="row justify-content-between mb-s">
        <DKLabel text="Additional Charges" className="fw-m text-app-color" />
        {!props.isReadOnlyMode ? (
          <div className="row width-auto gap-2">
            {/* {isAdhocEnabled && (
              <DKButton
                title="+ Add Additional Charges"
                disabled={props?.isReadOnlyMode}
                style={{
                  padding: 4,
                  paddingLeft: 6,
                  paddingRight: 6,
                  borderRadius: 5
                }}
                className="fw-m text-app-color"
                onClick={() => props.onClickNewAdditionalCharge?.()}
              />
            )} */}
            <div className="position-relative">
              <DKButton
                title="Select from existing"
                disabled={props?.isReadOnlyMode}
                style={{
                  padding: 4,
                  paddingLeft: 6,
                  paddingRight: 6,
                  borderRadius: 5
                }}
                className="fw-m text-app-color"
                onClick={() => setShowChargesPicker(true)}
              />
              {showChargesPicker && getUnselectedChargesPicker()}
            </div>

            <DKButton
              title="+ Create new"
              disabled={props?.isReadOnlyMode}
              style={{
                padding: 4,
                paddingLeft: 6,
                paddingRight: 6,
                borderRadius: 5
              }}
              className="fw-m text-app-color"
              onClick={() => setShowAddAdditionalCharges(true)}
            />
          </div>
        ) : null}
      </div>
    );
  };

  const updateGridRow = (rowData: any) => {
    return rowData.map((row: any, index: number) => {
      row = deepClone(row);
      row['rowButtons'] = [];
      if (
        props?.isReadOnlyMode ||
        row?.additionalCostType !== COST_CONFIG_TYPE.BOM ||
        row?.isNewRow
      ) {
        row['rowButtons'].push({
          title: '',
          className: 'padding-button-custom',
          icon: DKIcons.ic_delete,
          onClick: () => onActionButtonClick(index, row)
        });
      }
      if (
        props?.isReadOnlyMode ||
        row?.additionalCostType === COST_CONFIG_TYPE.WO
      ) {
        row.nonEditableColumns = [WO_ADDITIONAL_CHARGES_KEYS.NAME];
      }
      if (
        props?.isReadOnlyMode ||
        (row?.additionalCostType === COST_CONFIG_TYPE.BOM && !row?.isNewRow)
      ) {
        row.nonEditableColumns = [
          WO_ADDITIONAL_CHARGES_KEYS.CHARGE_VALUE,
          WO_ADDITIONAL_CHARGES_KEYS.NAME
        ];
      }
      return row;
    });
  };

  const gridColumns = getColumnConfig();
  const gridRows = deepClone(selectedCharges);
  const additionalChargesContainerRef = useRef<HTMLDivElement | null>(null);
  const [width] = useScreenResize(additionalChargesContainerRef);
  const containerMaxHeight = 640;
  const gridWidth = (width || 500) - 36;
  const gridMaxHeight = containerMaxHeight - 70;

  return (
    <div
      className="column bg-white border-m border-radius-m p-l parent-size"
      ref={additionalChargesContainerRef}
      style={{
        height: 'auto',
        width: props.isEditMode ? '49.5%' : '100%',
        maxHeight: containerMaxHeight
      }}
    >
      <div className="column parent-size">
        {getHeader()}
        <div className="column parent-size">
          {Utility.isEmpty(gridRows) ? (
            renderEmptyState()
          ) : (
            <DKDataGrid
              width={gridWidth}
              styles={{
                mainGridHolder: { marginBottom: -18 },
                gridScrollHolder: { maxHeight: gridMaxHeight },
                shadowHolder: { maxHeight: gridMaxHeight }
              }}
              allowRowEdit={true}
              allowColumnEdit={false}
              allowColumnSort={false}
              allowBulkOperation={false}
              columns={gridColumns}
              rows={updateGridRow(gridRows)}
              onRowUpdate={onNewRowUpdate}
            />
          )}
        </div>
        {getAdditionalChargesPopUp()}
      </div>
    </div>
  );
}
