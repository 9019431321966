/* eslint-disable react-hooks/exhaustive-deps */
import {
  DKLabel,
  DKSpinner,
  removeLoader,
  showAlert,
  DKButton,
  showLoader,
  DKListPicker,
  DKIcons,
  showToast,
  setExperimentalModeConfig
} from 'deskera-ui-library';
import { Suspense, useEffect, useRef, useState } from 'react';
import ReactDom from 'react-dom';
import TagManager from 'react-gtm-module';
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch
} from 'react-router-dom';
import './App.scss';
import './Components/Mrp/mrp.scss';
import {
  APP_NAME,
  COUNTRY_CODES,
  HIDE_CHAT_BUBBLE_CLASS,
  MODULES_NAME,
  OPENING_DOCUMENT_LABEL,
  WALKTHROUGH_STATUS_KEY,
  WELCOME_POPUP_STATUS_KEY
} from './Constants/Constant';
import { COLUMN_CODE, REMOTE_CONFIG_TABLES } from './Constants/TableConstants';
import AppManager from './Managers/AppManager';
import { isAccessingMRPURL, PAGE_ROUTES } from './Managers/RouteManager';
import { useAppDispatch, useAppSelector } from './Redux/Hooks';
import {
  activeTenantInfo,
  addUserInfo,
  allTenantsInfo,
  currentTenantInfo,
  fetchAllTenantsInfo,
  fetchAppCustomizationInfo,
  fetchCurrentTenantInfo,
  fetchCurrentTenantInfoWithoutCountryCode,
  fetchCurrentTenantSetupStatus,
  fetchFeaturePermissions,
  fetchSubscriptionInfo,
  fetchTenantSettings,
  fetchUserPreferences,
  permissionInfo,
  subscriptionInfo,
  updateIsBookKeeperFlag
} from './Redux/Slices/AuthSlice';
import { addBillColumnConfig } from './Redux/Slices/BillsSlice';
import { addCoaColumnConfig, setCoa } from './Redux/Slices/CoaSlice';
import {
  fetchAccountType,
  fetchAppIntegrationMapping,
  fetchClassesByDimensionId,
  fetchCurrency,
  fetchCurrencyExchangeRateByDocDate,
  fetchCurrencyWithExchangeRate,
  fetchCustomFields,
  fetchHSNCodes,
  fetchNatureOfIncomeAccounts,
  fetchPriceList,
  fetchProductCustomFields,
  fetchSACCodes,
  fetchUOMs,
  setTaxes
} from './Redux/Slices/CommonDataSlice';
import { addPriceColumnConfig } from './Redux/Slices/PriceList';
import { addTaxColumnConfig } from './Redux/Slices/TaxSlice';
import { isMacintosh } from './Utility/Utility';

import {
  addContactColumnConfig,
  fetchAsset,
  fetchLiability,
  fetchPaymentTerm
} from './Redux/Slices/ContactsSlice';
import { addCreditNoteColumnConfig } from './Redux/Slices/CreditNoteSlice';
import { addDebitNoteColumnConfig } from './Redux/Slices/DebitNoteSlice';
import { addDepositColumnConfig } from './Redux/Slices/DepositSlice';
import {
  addDraftColumnConfig,
  addTableId,
  fetchDrafts
} from './Redux/Slices/DraftsSlice';
import { addExpenseColumnConfig } from './Redux/Slices/ExpenseSlice';
import { addFinancialYearClosingColumnConfig } from './Redux/Slices/FinancialYearClosingSlice';

import {
  addAccountsColumnConfig,
  fetchAccountGroup,
  fetchAccoutnsList
} from './Redux/Slices/AccountsSlice';
import { addInventoryColumnConfig } from './Redux/Slices/InventorySlice';
import { addInvoiceColumnConfig } from './Redux/Slices/InvoicesSlice';
import { requisitionColumnConfig } from './Redux/Slices/RequisitionSlice';
import { addJournalColumnConfig } from './Redux/Slices/Journal';
import { addProductColumnConfig } from './Redux/Slices/ProductsSlice';
import { addOrderColumnConfig } from './Redux/Slices/PurchaseOrdersSlice';
import { addQuoteColumnConfig } from './Redux/Slices/QuotesSlice';
import AuthService from './Services/Auth';
import TableService from './Services/Table';
import DraftPopup from './SharedComponents/DraftsPopup/DraftPopup';
import Utility from './Utility/Utility';

import { addOBInvoiceColumnConfig } from './Redux/Slices/OBInvoicesSlice';
import {
  addWarehouseColumnConfig,
  fetchAllWarehouseWithoutRole,
  fetchWarehouse,
  fetchWarehouseWithRRBCombination
} from './Redux/Slices/WarehouseSlice';

import { addBanksColumnConfig } from './Redux/Slices/Banks';
import { fetchCustomNumberFormatForAll } from './Redux/Slices/CustomNumberFormat';

import {
  addPaymentTermsColumnConfig,
  fetchPaymentTerms
} from './Redux/Slices/PaymentTermsSlice';

import { addBankRulesColumnConfig } from './Redux/Slices/BankRules';

import { addAuditLogColumnConfig } from './Redux/Slices/AuditLogSlice';

import { addCurrencyColumnConfig } from './Redux/Slices/CurrencySlice';

import { addSalesOrderColumnConfig } from './Redux/Slices/SalesOrderSlice';

import { fetchTaxTable } from './Redux/Slices/TaxSlice';

import { addUomColumnConfig } from './Redux/Slices/UomSlice';

import { addDeskeraTransactionsColumnConfig } from './Redux/Slices/DeskeraTransactions';
import { addImportLogColumnConfig } from './Redux/Slices/ImportLogSlice';

import { addUomSchemaColumnConfig } from './Redux/Slices/UomSchemaSlice';

import { addBankTransactionsColumnConfig } from './Redux/Slices/BankTransactions';
import { RolePermissionUtil } from './RolePermission/RolePermissionUtil';
import WarehouseService, { WarehouseAPIConfig } from './Services/Warehouse';

import { fetchTCSNature } from './Redux/Slices/TCSSetupSlice';
import { fetchTDSDeducteeType } from './Redux/Slices/TDSSetupSlice';
import { Store } from './Redux/Store';

import { TableManager } from './Managers/TableManager';

import Dashboard from './Components/Dashboard/Dashboard';
import LeftMenu from './Components/Menu/LeftMenu';
import TopMenu from './Components/Menu/TopMenu';
import GettingStarted from './Components/Onboarding/GettingStarted/GettingStarted';

import Onboarding from './Components/Onboarding/Onboarding';
import {
  addAutomationColumnConfig,
  fetchapprovalConditionList
} from './Redux/Slices/AutomationSlice';
import AutomationService from './Services/Automation';

import { showSubscriptionAlert } from './Components/Subscription/SubscriptionAlert';

import { fetchAllLocations } from './Redux/Slices/LocationSlice';

import ErrorBoundary from './Components/ErrorBoundary/ErrorBoundary';

import ApiConstants from './Constants/ApiConstants';
import {
  IRoutingPermissionGuard,
  ROUTING_PATHS
} from './Constants/RoutingGuard';
import { fetchAllAdditionalCharge } from './Redux/Slices/AdditionalChargesSlice';
import { fetchTablesData } from './Redux/Slices/CustomReportsSlice';
import {
  fetchAllUserRoles,
  fetchAllUsersList,
  fetchListofInvitedUsers,
  fetchPermissionMetadata,
  fetchTenantsUserPermissionInfo,
  selectPermissionMetadata
} from './Redux/Slices/PermissionsSlice';
import {
  GranularPermissionsHelper,
  checkUserPermission
} from './Components/Settings/GranularPermissions/GranularPermissionsHelper';
import {
  addWorkStationColumnConfig,
  fetchWorkStations
} from './Redux/Slices/MRP/WorkstationSlice';
import SideBarService from './Services/sidebar';
import { isMobileWebView, isTabletView } from './Utility/ViewportSizeUtils';
import { addStockAdjustmentColumnConfig } from './Redux/Slices/StockAdjustmentSlice';
import { addStockTransferColumnConfig } from './Redux/Slices/StockTransferSlice';
import TabletToolbar from './Components/TabletDashboard/TabletToolbar';
import WorkoutService, { WorkoutAPIConfig } from './Services/Workout';
import {
  addWorkoutColumnConfig,
  fetchWorkOuts
} from './Redux/Slices/WorkOutSlice';
import WalkthroughTooltip from './SharedComponents/Walkthrough/WalkthroughTooltip';
import { DASHBOARD_WALKTHROUGH } from './SharedComponents/Walkthrough/DashboardWalkthrough';
import WelcomePopup from './SharedComponents/WelcomePopup';
import { addImportDraftColumnConfig } from './Redux/Slices/ImportDraftSlice';
import { addWorkOrderColumnConfig } from './Redux/Slices/MRP/WorkOrderSlice';
import { addOperatorColumnConfig } from './Redux/Slices/MRP/OperatorSlice';
import { addOperationsColumnConfig } from './Redux/Slices/MRP/OperationSlice';
import DocumentForm from './Components/Document';
import { addReportSODetailsColumnConfig } from './Redux/Slices/ReportSalesOrderSlice';
import { fetchPriceBookList } from './Redux/Slices/PriceBookSlice';
import { addBOMExplosionColumnConfig } from './Redux/Slices/MRP/BOMExplosionSlice';
import { addProductSubstituteColumnConfig } from './Redux/Slices/MRP/ProductSubstituteSlice';
import { addMRPReportsSKUOrderTrackingDetailsColumnConfig } from './Redux/Slices/MRP/SKUOrderTrackingSlice';
import IamService from './Services/iam';
import {
  addMachineSchedulesColumnConfig,
  addMachineToolMasterColumnConfig
} from './Redux/Slices/MRP/MachineToolMasterSlice';
import { addGoodsReceiptColumnConfig } from './Redux/Slices/GoodsReceiptMasterSlice';
import { addGoodsReceiptApprovalColumnConfig } from './Redux/Slices/GoodsReceiptApprovalSlice';
import { addFulfillmentMasterColumnConfig } from './Redux/Slices/FulfillmentMasterSlice';
import { addFulfillmentApprovalColumnConfig } from './Redux/Slices/FulfillmentApprovalSlice';
import { addAmortizationSchedulesColumnConfig } from './Redux/Slices/AmortizationSlice';
import { fetchCustomImportFormats } from './Redux/Slices/ImportSlice';
import TimeoutManager from './Managers/TimeoutManager';
import InactivityTimer from './Components/idle-timer/InactivityTimer';
import { addGRNColumnConfig } from './Redux/Slices/GRNSlice';
import { addFulfillmentReportColumnConfig } from './Redux/Slices/FufillmentReportSlice';
import { addPaymentLogsColumnConfig } from './Redux/Slices/PaymentLogsSlice';
import { addUploadToFTPLogsColumnConfig } from './Redux/Slices/UploadToFTPLogsSlice';
import { addGenerateBankFileColumnConfig } from './Redux/Slices/GenerateBankFileSlice';

// to add Chunk name in network tab
// const Dashboard = lazyWithPreload(
//   () =>
//     import(
//       /* webpackChunkName: "Dashboard" */ './components/dashboard/Dashboard'
//     )
// );

declare global {
  interface Window {
    deskeraChat?: (command: string, options?: Record<string, any>) => void;
  }
}

const App = (props: any) => {
  const [initialApiResponseReceived, setInitialApiResponseReceived] =
    useState(false);
  const permissionInfoData = useAppSelector(permissionInfo);
  const subscriptionInfoData = useAppSelector(subscriptionInfo);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [path, setPath] = useState(window.location.pathname);
  const [orgSetupCompleted, setOrgSetupCompleted] = useState<boolean>(true);
  const [tenantInfo, setTenantInfo] = useState<any>();
  const [showNoPermissionView, setShowNoPermissionView] = useState(false);
  // const [shouldCheckPermission, setShouldCheckPermission] = useState(false);
  const shouldCheckPermission = false;
  const [renderRouteMenu, setRenderRouteMenu] = useState(false);
  const isBookKeeper = localStorage.getItem('isBookKepper');
  const dispatch = useAppDispatch();
  const [routingPaths, setRoutingPaths] =
    useState<IRoutingPermissionGuard[]>(ROUTING_PATHS);
  const activeTenantDetails = useAppSelector(activeTenantInfo);
  const [showWalkthrough, setShowWalkthrough] = useState(false);
  const [showWelcomePopup, setShowWelcomePopup] = useState(false);
  const [showNoMRPEnableAlert, setShowNoMRPEnableAlert] = useState(false);
  const [showTenantList, setShowTenantList] = useState(false);
  const [tenantList, setTenantList] = useState<any>([]);
  const permissionMetaData = useAppSelector(selectPermissionMetadata);
  const allTenantInfo = useAppSelector(allTenantsInfo);

  const [timeoutData, setTimeoutData] = useState<any>();
  const isDocumentLoaderVisibleRef = useRef(false);

  const showDocumentLoader = () => {
    return (
      AppManager.isCurrentRoute(PAGE_ROUTES.INVOICE_WITH_ID) ||
      AppManager.isCurrentRoute(PAGE_ROUTES.QUOTE_WITH_ID) ||
      AppManager.isCurrentRoute(PAGE_ROUTES.SALES_ORDER_WITH_ID) ||
      AppManager.isCurrentRoute(PAGE_ROUTES.BILL_WITH_ID) ||
      AppManager.isCurrentRoute(PAGE_ROUTES.ORDER_WITH_ID) ||
      AppManager.isCurrentRoute(PAGE_ROUTES.ORDER_WITH_ID) ||
      AppManager.isCurrentRoute(PAGE_ROUTES.INVOICE_WITH_ID_AND_CONTACT) ||
      AppManager.isCurrentRoute(PAGE_ROUTES.QUOTE_WITH_ID_AND_CONTACT) ||
      AppManager.isCurrentRoute(PAGE_ROUTES.QUOTE_WITH_ID_AND_ACTION) ||
      AppManager.isCurrentRoute(PAGE_ROUTES.SALES_ORDER_WITH_ID_AND_CONTACT) ||
      AppManager.isCurrentRoute(PAGE_ROUTES.BILL_WITH_ID_AND_CONTACT) ||
      AppManager.isCurrentRoute(PAGE_ROUTES.ORDER_WITH_ID_AND_CONTACT) ||
      AppManager.isCurrentRoute(PAGE_ROUTES.REQUISITION_WITH_ID) ||
      AppManager.isCurrentRoute(PAGE_ROUTES.MRP_WORK_OUT_WITH_ID)
    );
  };

  useEffect(() => {
    setExperimentalModeConfig({
      enablePickerStore: true
    });
    updateFavicon();
    let pushState = window.history.pushState;
    window.history.pushState = function (state) {
      setPath(window.location.pathname);
      return pushState.apply(window.history, arguments as any);
    };
    SideBarService.setIsSideBarExpanded();
    // Initialize GTM
    const tagManagerArgs = {
      gtmId: process.env.REACT_APP_GTM_ID
    };
    TagManager.initialize(tagManagerArgs);

    if (AppManager.isPublicURL()) {
      setInitialApiResponseReceived(true);
      setRenderRouteMenu(true);
    } else {
      if (showDocumentLoader()) {
        isDocumentLoaderVisibleRef.current = true;
        showLoader(OPENING_DOCUMENT_LABEL);
      } else {
        showLoader();
      }
      /*
       * Checking for onboarding process
       *
       * */
      checkIfUserAuthorized(
        () => {
          loadCurrentTenantInfo()
            .then((res) => {
              const data = res;
              dispatch(fetchAllUserRoles());
              if (data) {
                dispatch(updateIsBookKeeperFlag(data.isBookKeeper));
                const isOrgSetupComplete: boolean =
                  data['orgSetupCompleted'] || data['isDemoOrg'];
                loadCurrency();
                // All the countries in complianceSupportedCountriesInOldBooksOnly, will be redirected to old books
                if (
                  complianceSupportedCountriesInOldBooksOnly.includes(
                    data.country
                  ) &&
                  isOrgSetupComplete
                ) {
                  window.location.replace(
                    ApiConstants.PRODUCT_URL_ERP as string
                  );
                  return;
                }
                if (isOrgSetupComplete) {
                  callInitialApis();
                } else {
                  removeLoader();
                }
                setOrgSetupCompleted(isOrgSetupComplete);
                setTenantInfo(data);
              }
              setWalkthroughAndWelcomePopupStatus();
            })
            .catch((err) => {
              showAlert(
                'Error!',
                'Problem while loading org details. Please try again.'
              );
              console.log(err, 'tenant api failing');
            });
        },
        (message: any) => {
          Store.dispatch(fetchCurrentTenantInfo());
          if (!AppManager.isCurrentRoute(PAGE_ROUTES.DASHBOARD)) {
            window.location.href = window.location.origin;
            return;
          }
          setInitialApiResponseReceived(true);

          showSubscriptionAlert(message);
          removeLoader();
        }
      );
    }
    if (isMobileWebView()) {
      document.body.classList.add(HIDE_CHAT_BUBBLE_CLASS);
    }
  }, []);

  useEffect(() => {
    // load chat ai button
    // showChatAIButton();

    // load script for site tracking active campaign

    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.text = `(function(e,t,o,n,p,r,i){e.visitorGlobalObjectAlias=n;e[e.visitorGlobalObjectAlias]=e[e.visitorGlobalObjectAlias]||function(){(e[e.visitorGlobalObjectAlias].q=e[e.visitorGlobalObjectAlias].q||[]).push(arguments)};e[e.visitorGlobalObjectAlias].l=(new Date).getTime();r=t.createElement("script");r.src=o;r.async=true;i=t.getElementsByTagName("script")[0];i.parentNode.insertBefore(r,i)})(window,document,"https://diffuser-cdn.app-us1.com/diffuser/diffuser.js","vgo");
 vgo('setAccount', ${process.env.REACT_APP_AC_ACCOUNT_ID});
 vgo('setTrackByDefault', true);

 vgo('process');`;
    document.body.appendChild(script);
  }, [initialApiResponseReceived]);

  useEffect(() => {
    if (tenantInfo && !Utility.isEmpty(tenantInfo.additionalSettings)) {
      /** @todo can we move this calls inside callInitialAPi's */
      dispatch(fetchAllLocations());
      loadCustomNumberFormatForAll(tenantInfo?.useSeparateSequenceFormat);
      dispatch(fetchClassesByDimensionId());
      dispatch(fetchTCSNature(tenantInfo.id));
      updateRoutingPaths(tenantInfo);
    }
  }, [tenantInfo]);

  useEffect(() => {
    if (
      activeTenantDetails &&
      !Utility.isEmpty(activeTenantDetails.additionalSettings)
    ) {
      updateRoutingPaths(activeTenantDetails);
    }
  }, [activeTenantDetails]);

  useEffect(() => {
    if (
      !Utility.isEmpty(subscriptionInfoData) &&
      isAccessingMRPURL() &&
      !showNoMRPEnableAlert
    ) {
      setShowNoMRPEnableAlert(
        !GranularPermissionsHelper.hasMrpModulePermission()
      );
    }
  }, [subscriptionInfoData]);

  useEffect(() => {
    if (Utility.isNotEmpty(allTenantInfo)) {
      setTenantList(allTenantInfo || []);
    }
  }, [allTenantInfo]);

  const showChatAIButton = () => {
    window?.deskeraChat?.('init', {
      tenantId: 70874,
      accentColor: 'rgb(56, 79, 190)',
      appName: 'ERP',
      accessToken: ApiConstants.ACCESS_TOKEN
    });
  };

  const updateFavicon = () => {
    const erp_icon = '/ic-erp-fill-bg.png';
    const mrp_icon = '/ic-mrp-fill-bg.png';
    const favicon = document.getElementById('favicon') as HTMLLinkElement;
    const isERP = window.location.href?.includes(
      process.env.REACT_APP_ERP_URL + ''
    );
    const isMRP = window.location.href?.includes(
      process.env.REACT_APP_URL_MRP + ''
    );
    if (favicon) {
      if (isERP) {
        favicon.href = erp_icon;
      } else if (isMRP) {
        favicon.href = mrp_icon;
      } else {
        favicon.href = erp_icon;
      }
    }
  };

  const updateRoutingPaths = (tenantDetails: any) => {
    if (!tenantDetails.additionalSettings?.ENABLE_SO) {
      const updatedRoutingPaths = routingPaths.filter(
        (routingPath: IRoutingPermissionGuard) =>
          routingPath.path !== PAGE_ROUTES.SALES_ORDERS &&
          routingPath.path !== PAGE_ROUTES.SALES_ORDER_WITH_ID
      );
      setRoutingPaths(updatedRoutingPaths);
    } else {
      const routingPathContainsSOPaths = routingPaths.filter(
        (routingPath: IRoutingPermissionGuard) =>
          routingPath.path === PAGE_ROUTES.SALES_ORDERS ||
          routingPath.path === PAGE_ROUTES.SALES_ORDER_WITH_ID
      );
      if (!routingPathContainsSOPaths.length) {
        const updatedRoutingPaths = ROUTING_PATHS;
        setRoutingPaths(updatedRoutingPaths);
      }
    }

    const listOfMachineRoutes = [
      PAGE_ROUTES.MRP_MACHINE_TOOL_MASTER,
      PAGE_ROUTES.MRP_ASSET_MAINTENANCE_SCHEDULE,
      PAGE_ROUTES.MRP_MACHINE_SCHEDULING
    ];
    if (!tenantDetails.additionalSettings?.ENABLE_MACHINE_MAINTENANCE) {
      const updatedRoutingPaths = routingPaths.filter(
        (routingPath: IRoutingPermissionGuard) =>
          !listOfMachineRoutes.includes(routingPath.path)
      );
      setRoutingPaths(updatedRoutingPaths);
    } else {
      const routingPathContainsMachineMaintenancePaths = routingPaths.filter(
        (routingPath: IRoutingPermissionGuard) =>
          listOfMachineRoutes.includes(routingPath.path)
      );
      if (!routingPathContainsMachineMaintenancePaths.length) {
        const updatedRoutingPaths = ROUTING_PATHS;
        setRoutingPaths(updatedRoutingPaths);
      }
    }
  };

  const makeApiCallsOnAppInitialization = async () => {
    /*
     * Api Calls will be made during app initialization
     *
     * */

    /* *************** BEFORE LOADER API CALLS *********** */
    const loadTenantDetailsWithoutCountryCode =
      loadCurrentTenantInfoForBookkeeper();
    const loadTenantSettingsInfo = loadTenantSettings();

    /* *************** NON LOADER API CALLS *********** */
    //granular permission specific calls
    const loadInvitedUsers = loadAllInvitedUsers();
    const loadAllTenants = loadAllTenantsInfo();
    const AppCustomization = loadAppCustomizationInfo();
    // const loadPermission = loadPermissionInfo();
    const loadFeaturePermissions = loadFeaturePermissionsInfo();
    const loadAppIntInfo = loadAppIntegrationInfo();
    const loadPaymentTerm = loadAppPaymentTerm();
    const loadReceiveableAccount = loadReceiveableAccounts();
    const loadPayableAccount = loadPayableAccounts();
    // const loadAllTaxes = loadTaxes();
    const loadSetupStatus = loadTenantSetupStatus();
    // const loadAllCurrency = loadCurrency();
    const loadAllTaxTable = loadTaxTable();
    const loadAllPriceList = loadPriceList();
    const loadAllPriceBookList = loadPriceBookList();
    const loadAllTypes = loadAccounts();
    // const loadAllCoas = loadCoaFromSlice();
    const loadAllWarehouses = loadWarehouses({});

    const loadUnitsOfMeaseurements = loadUOMs();
    const loadHSNCodesList = loadHSNCodes();
    const loadSacCodesList = loadSACCodes();

    const loadCustomFieldsList = loadCustomFields();

    const loadMulticurrencyWithExchangeRateList =
      loadMulticurrencyWithExchangeRate();
    const loadMulticurrencyWithExchangeRateBydocDateList =
      loadMulticurrencyWithExchangeRateBydocDate();
    const loadAccountList = loadAllAccountsList();
    const loadAccountGroupCall = loadAccountGroup(
      Store.getState().authInfo.currentTenantInfo.data.currency
    );
    const loadAllTDSDeducteeTypeList = loadTDDeducteeTypeList();
    const loadUserPreferences = loadUserPreferenceSettings();
    // const loadLocationAndClassList = loadLocationAndClass();
    const loadAdditionalCharges = loadAdditionalCharge();
    const loadSmartReportsTableData = loadSmartReportsData();
    // const loadWareHouseData = loadWarehouses({});

    // load product warehouse data
    const loadProductWarehouses = loadProductWarehouseData();
    const loadApprovalConditions = loadDocumentApprovalConditions();
    const loadPaymentTerms = loadAppPaymentTerms();

    // Load custom import formats
    const loadCustomImportFormats = loadCustomImportFormatList();

    /* *************** BEFORE LOADER API CALLS *********** */
    let requiredApiCalls: Promise<any>[] = [
      loadTenantSettingsInfo,
      loadTenantDetailsWithoutCountryCode
    ];
    const countryCode = AuthService.userDetails
      ? AuthService.userDetails.country
      : '';
    if (countryCode === COUNTRY_CODES.IN) {
      const loadNatureOFIncomeAccountsList = loadNatureOFIncomeAccounts();
      requiredApiCalls.push(loadNatureOFIncomeAccountsList);
    }
    Promise.all(requiredApiCalls)
      .then(() => {
        setInitialApiResponseReceived(true);
        // onInitialApiResponseReceived();
        if (!showDocumentLoader()) {
          removeLoader();
        }
      })
      .catch((err) => removeLoader());

    /* *************** NON LOADER API CALLS *********** */
    let nonLoaderApiCalls: Promise<any>[] = [
      loadInvitedUsers,
      loadUserPreferences,
      loadReceiveableAccount,
      loadPayableAccount,
      loadAllTaxTable,
      loadAllTypes,
      loadSacCodesList,
      loadMulticurrencyWithExchangeRateList,
      loadMulticurrencyWithExchangeRateBydocDateList,
      loadAllTDSDeducteeTypeList,
      loadHSNCodesList,
      loadPaymentTerm,
      AppCustomization,
      // loadPermission,
      loadFeaturePermissions,
      loadAppIntInfo,
      loadUnitsOfMeaseurements,
      // loadAllTaxes,
      loadSetupStatus,
      // loadAllCurrency,
      loadAccountList,
      loadAccountGroupCall,
      loadAllPriceList,
      // loadAllCoas,
      loadAllTenants,
      // loadLocationAndClassList,
      loadAdditionalCharges,
      loadSmartReportsTableData,
      loadAllWarehouses,
      // loadWareHouseData,
      loadCustomFieldsList,
      loadProductWarehouses,
      loadApprovalConditions,
      loadPaymentTerms,
      loadCustomImportFormats
    ];

    if (tenantInfo?.isAdvancePriceListEnabled) {
      nonLoaderApiCalls.push(loadAllPriceBookList);
    }

    if (isAccessingMRPURL()) {
      // Load MRP Data initial Loading
      const loadWorkStations = loadWorkStationData();
      const loadJobWorkOuts = loadJobWorkOutsData();
      nonLoaderApiCalls.push(...[loadWorkStations, loadJobWorkOuts]);
    }

    Promise.all(nonLoaderApiCalls)
      .then(() => {
        setInitialApiResponseReceived(true);
        // onInitialApiResponseReceived();
      })
      .catch((err) => {});
  };

  // Set initial state for showing welcome and walkthrough popup
  const setWalkthroughAndWelcomePopupStatus = () => {
    if (localStorage.getItem(WELCOME_POPUP_STATUS_KEY) === null) {
      localStorage.setItem(WELCOME_POPUP_STATUS_KEY, JSON.stringify(true));
    }
    if (localStorage.getItem(WALKTHROUGH_STATUS_KEY) === null) {
      const objectToStore = {
        display: true,
        skipped: false,
        finished: false
      };
      localStorage.setItem(
        WALKTHROUGH_STATUS_KEY,
        JSON.stringify(objectToStore)
      );
    }
  };

  const openWalkthrough = () => {
    let overlayContainerElement = document.getElementById(
      'walkthrough-overlay-container'
    );
    if (!overlayContainerElement) {
      const parentElement: HTMLElement | null =
        document.getElementsByTagName('body')[0];
      overlayContainerElement = document.createElement('div');
      overlayContainerElement.setAttribute(
        'id',
        'walkthrough-overlay-container'
      );
      parentElement.appendChild(overlayContainerElement);
    }

    if (overlayContainerElement) {
      return ReactDom.createPortal(
        <WalkthroughTooltip data={DASHBOARD_WALKTHROUGH} />,
        overlayContainerElement
      );
    } else {
      return null;
    }
  };

  const updateWalkthroughStatus = (
    displayWalkthrough: boolean,
    isSkipped: boolean
  ) => {
    let persistedWalkthroughStatus =
      localStorage.getItem(WALKTHROUGH_STATUS_KEY) !== null
        ? JSON.parse(localStorage.getItem(WALKTHROUGH_STATUS_KEY) as string)
        : null;
    if (persistedWalkthroughStatus) {
      persistedWalkthroughStatus = {
        ...persistedWalkthroughStatus,
        display: displayWalkthrough,
        skipped: isSkipped,
        finished: false
      };
    }
    Utility.setPersistentValue(
      WALKTHROUGH_STATUS_KEY,
      JSON.stringify(persistedWalkthroughStatus)
    );

    // Don't show the welcome popup after first appearance
    if (localStorage.getItem(WELCOME_POPUP_STATUS_KEY) !== null) {
      localStorage.setItem(WELCOME_POPUP_STATUS_KEY, JSON.stringify(false));
    }
  };

  const onInitialApiResponseReceived = () => {
    TimeoutManager.getIdleTimeoutTokenExpiry()
      .then(
        (response: any) => {
          if (response.id) {
            TimeoutManager.setTimeoutData(response);
          } else {
            // set default data
            TimeoutManager.setTimeoutData(TimeoutManager.TIMEOUT_DATA);
          }
        },
        (error) => {
          console.log(error);
          // set default data
          TimeoutManager.setTimeoutData(TimeoutManager.TIMEOUT_DATA);
        }
      )
      .finally(() => {
        setTimeoutData(TimeoutManager.getTimeoutData());
      });
  };

  const loadPermissionMetadataInfo = async () => {
    try {
      await dispatch(fetchPermissionMetadata());
    } catch (err) {
      console.error('Error fetching permission metadata: ', err);
    }
  };

  const loadProductWarehouseData = async () => {
    try {
      await dispatch(fetchWarehouseWithRRBCombination({}));
    } catch (err) {
      console.error('Error fetching product warehouses: ', err);
    }
  };

  const loadTenantsPermissionsUsersInfo = async () => {
    try {
      await dispatch(fetchTenantsUserPermissionInfo());
      loadPermissionMetadataInfo();
    } catch (err) {
      console.error('Error fetching tenants permission user: ', err);
    }
  };

  const loadAllUsersInfo = async () => {
    try {
      const response = await dispatch(fetchAllUsersList());
      return Promise.resolve(response);
    } catch (err) {
      console.error('Error fetching all users: ', err);
    }
  };

  const loadAllInvitedUsers = async () => {
    try {
      await dispatch(fetchListofInvitedUsers());
    } catch (err) {
      console.error('Error fetching invited users: ', err);
    }
  };

  const loadHSNCodes = async () => {
    try {
      await dispatch(fetchHSNCodes());
    } catch (err) {
      console.error('Error fetching HSN Codes details: ', err);
    }
  };
  const loadSACCodes = async () => {
    try {
      await dispatch(fetchSACCodes());
    } catch (err) {
      console.error('Error fetching Sac Codes details: ', err);
    }
  };
  const loadNatureOFIncomeAccounts = async () => {
    try {
      await dispatch(fetchNatureOfIncomeAccounts());
    } catch (err) {
      console.error('Error fetching Nature of income accounts details: ', err);
    }
  };
  const loadUOMs = async () => {
    try {
      await dispatch(fetchUOMs());
    } catch (err) {
      console.error('Error fetching UOMs details: ', err);
    }
  };

  /* const loadTaxes = async () => {
    try {
      await dispatch(fetchTaxes());
    } catch (err) {
      console.error('Error fetching UOMs details: ', err);
    }
  };
 */
  const loadCurrency = async () => {
    try {
      await dispatch(fetchCurrency());
    } catch (err) {
      console.error('Error fetching currency: ', err);
    }
  };
  const loadTaxTable = async () => {
    try {
      const taxesResponse = await dispatch(fetchTaxTable());

      /* Deriving active taxes in commonDataSlice, from all taxes */
      const taxList = (taxesResponse?.payload as any)?.content || [];
      const activeTaxList = taxList.filter(
        (tax: any) => tax.status === 'active'
      );
      dispatch(setTaxes(activeTaxList));
    } catch (err) {
      console.error('Error loading Tax Table: ', err);
    }
  };
  const loadPriceList = async () => {
    try {
      await dispatch(fetchPriceList());
    } catch (err) {
      console.error('Error fetching price List: ', err);
    }
  };
  const loadPriceBookList = async () => {
    try {
      let query = `isAdvance=true`;
      await dispatch(fetchPriceBookList({ search: '', limit: 500, query }));
    } catch (err) {
      console.error('Error fetching price book List: ', err);
    }
  };
  const loadAccounts = async () => {
    try {
      await dispatch(fetchAccountType());
    } catch (err) {
      console.error('Error fetching type: ', err);
    }
  };

  /* const loadCoaFromSlice = async () => {
    try {
      await dispatch(fetchCoa());
    } catch (err) {
      console.error('Error fetching COA: ', err);
    }
  }; */
  const loadWarehouses = async (config: WarehouseAPIConfig) => {
    try {
      WarehouseService.apiConfig = config;
      await dispatch(fetchWarehouse());
      await dispatch(fetchAllWarehouseWithoutRole());
    } catch (err) {
      console.error('Error loading contacts: ', err);
    }
  };

  const loadWorkStations = async (config: WarehouseAPIConfig) => {
    try {
      WarehouseService.apiConfig = config;
      await dispatch(fetchWorkStations());
    } catch (err) {
      console.error('Error loading contacts: ', err);
    }
  };

  const loadJobWorkOuts = async (config: WorkoutAPIConfig) => {
    try {
      WorkoutService.apiConfig = config;
      await dispatch(fetchWorkOuts());
    } catch (err) {
      console.error('Error loading job workouts: ', err);
    }
  };

  const loadAllTenantsInfo = async () => {
    try {
      await dispatch(fetchAllTenantsInfo());
    } catch (err) {
      console.error('Error fetching all tenants details: ', err);
    }
  };

  const loadAppCustomizationInfo = async () => {
    try {
      await dispatch(fetchAppCustomizationInfo());
    } catch (err) {
      console.error('Error fetching all tenants details: ', err);
    }
  };

  const loadSubscriptionInfo = async () => {
    try {
      const newRes = await dispatch(fetchSubscriptionInfo());
      return Promise.resolve(newRes);
    } catch (err) {
      console.error('Error loading subscription: ', err);
      return Promise.resolve(null);
    }
  };

  const loadCurrentTenantInfo = async () => {
    let tenantDetailsData;
    try {
      const [data, userListData] = await Promise.all([
        dispatch(fetchCurrentTenantInfo()),
        loadAllUsersInfo(),
        loadSubscriptionInfo(),
        loadTenantsPermissionsUsersInfo()
      ]);
      tenantDetailsData = data;
      const payload: any = data.payload;
      const userId: number = AuthService.getUserID() || payload.userId;
      dispatch(currentTenantInfo(payload)); // save in redux store

      if (userId) {
        loadUserInfo(userListData, userId);
      }

      return payload;
    } catch (err) {
      console.error('Error loading current tenant details: ', err);

      /* In case some error occured while fetching other metadata, continuing appflow */
      if (tenantDetailsData) {
        return Promise.resolve(tenantDetailsData);
      } else {
        return Promise.reject('');
      }
    }
  };

  const loadCurrentTenantInfoForBookkeeper = async () => {
    try {
      await dispatch(fetchCurrentTenantInfoWithoutCountryCode());
    } catch (err) {
      console.error(
        'Error loading current tenant details for bookkeeper: ',
        err
      );
    }
  };

  const loadTenantSetupStatus = async () => {
    try {
      await dispatch(fetchCurrentTenantSetupStatus());
    } catch (err) {
      console.error('Error loading tenant setup details: ', err);
    }
  };

  const updateUserInfo = (data: any) => {
    let userData = AuthService.getUserDetails();
    userData.name = data.firstName + ' ' + data.lastName;
    AuthService.setUserDetails(userData);
  };

  const loadUserInfo = async (userListData: any, userId: number) => {
    try {
      const payload: any = userListData?.payload;
      /* filtered content with loggedInUserInfo only */
      const content = (payload?.['content'] || []).filter(
        (user: any) => user.id === userId
      );
      updateUserInfo(content[0]);
      const roleInfo = content[0].roleShortInfo || [];
      RolePermissionUtil.setOwner(roleInfo);
      dispatch(addUserInfo(content)); // save in redux store
    } catch (err) {
      console.error('Error loading user info: ', err);
    }
  };

  /* const loadPermissionInfo = async () => {
    try {
      await dispatch(fetchPermissionInfo());
    } catch (err) {
      console.error('Error loading permission: ', err);
    }
  }; */

  const loadAppIntegrationInfo = async () => {
    try {
      await dispatch(fetchAppIntegrationMapping());
    } catch (err) {
      console.error('Error loading app integration info: ', err);
    }
  };

  const loadAppPaymentTerm = async () => {
    try {
      await dispatch(fetchPaymentTerm());
    } catch (err) {
      console.error('Error loading payment terms: ', err);
    }
  };

  const loadReceiveableAccounts = async () => {
    try {
      await dispatch(fetchAsset());
    } catch (err) {
      console.error('Error loading assets: ', err);
    }
  };

  const loadPayableAccounts = async () => {
    try {
      await dispatch(fetchLiability());
    } catch (err) {
      console.error('Error loading Liabilities: ', err);
    }
  };

  const loadMulticurrencyWithExchangeRate = async () => {
    try {
      await dispatch(fetchCurrencyWithExchangeRate());
    } catch (err) {
      console.error(
        'Error loading multi-currency list with exchange rate: ',
        err
      );
    }
  };

  const loadMulticurrencyWithExchangeRateBydocDate = async () => {
    try {
      await dispatch(fetchCurrencyExchangeRateByDocDate(''));
    } catch (err) {
      console.error(
        'Error loading multi-currency list with exchange rate: ',
        err
      );
    }
  };

  const loadCustomNumberFormatForAll = async (
    useSeparateSequenceFormat: boolean
  ) => {
    try {
      await dispatch(fetchCustomNumberFormatForAll(useSeparateSequenceFormat));
    } catch (err) {
      console.error(
        'Error loading multi-currency list with exchange rate: ',
        err
      );
    }
  };

  const loadAllAccountsList = async () => {
    try {
      const accountsResponse = await dispatch(fetchAccoutnsList());

      /* Deriving initial coa list from account list fetch */
      if (!Utility.isEmpty(accountsResponse?.payload)) {
        const initialCoaPageSize = 25;
        const coaPayload = {
          ...accountsResponse.payload
        };
        coaPayload.content = accountsResponse.payload.content.slice(
          0,
          initialCoaPageSize
        );
        coaPayload.numberOfElements = coaPayload.content.length;
        coaPayload.size = initialCoaPageSize;
        coaPayload.totalPages = Math.ceil(
          coaPayload.totalElements / initialCoaPageSize
        );
        dispatch(setCoa(coaPayload));
      }
    } catch (err) {
      console.error(
        'Error loading multi-currency list with exchange rate: ',
        err
      );
    }
  };

  const loadAccountGroup = async (currency: string) => {
    try {
      await dispatch(fetchAccountGroup(currency));
    } catch (err) {
      console.error('Error loading Account Group ', err);
    }
  };

  const loadTDDeducteeTypeList = async () => {
    try {
      await dispatch(fetchTDSDeducteeType());
    } catch (err) {
      console.error('Error loading tds deductee type: ', err);
    }
  };

  const loadTenantSettings = async () => {
    try {
      dispatch(fetchTenantSettings(AuthService.getUserTenantID()));
    } catch (err) {
      console.error('Error fetching Tenant Finance Details: ', err);
    }
  };

  const loadUserPreferenceSettings = async () => {
    try {
      dispatch(fetchUserPreferences());
    } catch (err) {
      console.error('Error fetching User Preferences: ', err);
    }
  };

  /* const loadLocationAndClass = async () => {
    try {
      await dispatch(fetchCategoryDimensions());
    } catch (err) {
      console.error('Error fetching location/classes data: ', err);
    }
  }; */

  const loadAdditionalCharge = async () => {
    try {
      dispatch(fetchAllAdditionalCharge());
    } catch (err) {
      console.error('Error fetching Additional Charge: ', err);
    }
  };

  /* const loadWarehousesData = async () => {
    try {
      ;
    } catch (err) {
      console.error('Error fetching Warehouses Data: ', err);
    }
  }; */

  const loadWorkStationData = async () => {
    try {
      loadWorkStations({});
    } catch (err) {
      console.error('Error fetching Workstation Data: ', err);
    }
  };

  const loadJobWorkOutsData = async () => {
    try {
      loadJobWorkOuts({});
    } catch (err) {
      console.error('Error fetching Job Work Outs Data: ', err);
    }
  };

  const loadSmartReportsData = async () => {
    try {
      dispatch(fetchTablesData());
    } catch (err) {
      console.error('Error fetching Smart Reports Table Data: ', err);
    }
  };

  const loadDocumentApprovalConditions = async () => {
    try {
      await dispatch(fetchapprovalConditionList());
    } catch (err) {
      console.log('Error fetching approval conditions', err);
    }
  };

  const loadAppPaymentTerms = async () => {
    try {
      await dispatch(fetchPaymentTerms());
    } catch (err) {
      console.log('Error fetching app payment terms', err);
    }
  };

  // List of compliance enabled countries in books plus
  const complianceSupportedCountries = [
    COUNTRY_CODES.IN,
    COUNTRY_CODES.SG,
    COUNTRY_CODES.US,
    COUNTRY_CODES.MY,
    COUNTRY_CODES.IL,
    COUNTRY_CODES.UK
  ];

  // List of compliance enabled countries which are not on books plus but present in old books
  const complianceSupportedCountriesInOldBooksOnly = [
    COUNTRY_CODES.ID,
    COUNTRY_CODES.AE,
    COUNTRY_CODES.CA,
    COUNTRY_CODES.DE,
    COUNTRY_CODES.AU,
    COUNTRY_CODES.BE,
    COUNTRY_CODES.NL,
    COUNTRY_CODES.NZ,
    COUNTRY_CODES.PH,
    COUNTRY_CODES.SA
  ];

  useEffect(() => {
    if (shouldCheckPermission && subscriptionInfoData?.Subscribed) {
      checkIfUserHasBooksPermission();
    }

    if (permissionInfoData && permissionInfoData.length && permissionMetaData) {
      setRenderRouteMenu(true);
      let displayWelcomePopup =
        localStorage.getItem(WELCOME_POPUP_STATUS_KEY) !== null
          ? JSON.parse(localStorage.getItem(WELCOME_POPUP_STATUS_KEY) as string)
          : null;
      setShowWelcomePopup(displayWelcomePopup);
    }
  }, [permissionInfoData, subscriptionInfoData, permissionMetaData]);

  const checkIfUserHasBooksPermission = () => {
    if (Utility.isEmpty(permissionInfoData)) {
      return;
    } else {
      let first = permissionInfoData[0];
      let apps = first.apps;
      let filtered = apps.filter((item: any) => item.appName === APP_NAME);
      if (Utility.isEmpty(filtered)) {
        setShowNoPermissionView(true);
      } else {
        setShowNoPermissionView(false);
      }
    }
  };
  const getNoPermissionForBooksView = () => {
    return (
      showNoPermissionView && (
        <div className="transparent-background">
          <div
            className="popup-window"
            style={{
              width: 350,
              height: 160,
              alignItems: 'center',
              justifyContent: 'center',
              borderRadius: 12,
              marginBottom: 500
            }}
          >
            <DKLabel className="alert-title" text="No Permission" />
            <DKLabel
              className="alert-message mt-4"
              text="You don't have permission to access to Books, please contact your organisation's owner."
            />
          </div>
        </div>
      )
    );
  };

  const getNoMRPView = () => {
    const handleTenantSelection = (newSelectedTenantDetails: any) => {
      if (newSelectedTenantDetails.tenantId !== AuthService.getUserTenantID()) {
        IamService.switchTenant({
          tenantId: newSelectedTenantDetails.tenantId
        }).then(
          (res) => {
            AppManager.refreshApp();
          },
          (err) => {}
        );
      } else {
        showToast('Current Organisation');
      }

      setShowTenantList(false);
    };
    return (
      <div className="transparent-background">
        <div
          className="popup-window"
          style={{
            width: 400,
            height: 200,
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: 12,
            marginBottom: 500,
            overflow: 'visible'
          }}
        >
          <DKLabel className="alert-title" text="No Subscription found!" />
          <DKLabel
            className="alert-message mt-4"
            text="It seems like you don't have any active MRP plan. Please subscribe to continue."
          />

          <div className="row justify-content-center position-relative mt-l">
            <DKButton
              title={`Switch Organisation`}
              icon={DKIcons.ic_arrow_down}
              isReverse
              onClick={() => setShowTenantList(true)}
              className="bg-gray2 border-m cursor-hand"
            />
            {showTenantList ? (
              <DKListPicker
                data={tenantList.map((tenant: any) => tenant.tenantName)}
                className="position-absolute parent-width z-index-3 border-m shadow-s hide-scroll-bar"
                style={{
                  top: '90%',
                  left: 46,
                  width: 180
                }}
                onSelect={(index: any, selectedName: any) => {
                  handleTenantSelection(tenantList[index]);
                }}
                onClose={() => setShowTenantList(false)}
              />
            ) : null}

            <DKButton
              title={'Subscribe'}
              className="bg-button ml-r text-white"
              onClick={() => {
                window.open(process.env.REACT_APP_URL_GO + `billing`, '_blank');
              }}
            />
          </div>
        </div>
      </div>
    );
  };

  const loadCustomFields = async () => {
    try {
      /* Fetching all custom fields */
      await dispatch(
        fetchCustomFields({
          status: 'ACTIVE',
          limit: '1000',
          isInitialRequest: true
        })
      );

      // Require this dispatch for getting product CF indices
      await dispatch(
        fetchProductCustomFields({
          status: 'ACTIVE',
          limit: '1000',
          module: MODULES_NAME.PRODUCT
        })
      );

      /* FA CF's will be filtered & stored via above call */
      /* await dispatch(
        fetchAssetCustomFields({
          status: 'ACTIVE',
          limit: '1000',
          module: MODULES_NAME.ASSET
        })
      ); */
    } catch (err) {
      console.error('Error fetching Dimensions: ', err);
    }
  };

  const callInitialApis = async () => {
    makeApiCallsOnAppInitialization();
    TableService.getAllViews().then(
      (data) => {
        setTimeout(async () => {
          setupColumConfigs(data);
          fetchAllData(data);
          TableManager.populateViewMap(
            data.filter((data) => !data.default && data.columnsMetaData)
          );
          AutomationService.getAllConnections().then(
            (data) => {},
            (err) => {
              console.error('Error loading existing connections: ', err);
            }
          );
          setInitialApiResponseReceived(true);
          onInitialApiResponseReceived();
        }, 100);
      },
      (err) => {
        showAlert('Problem While Loading!', 'Please try again!');
      }
    );
  };

  const checkIfUserAuthorized = (onSuccess: any, onFail: any) => {
    AuthService.checkIfUserLoggedIn(
      () => {
        onSuccess(true);
      },
      () => {
        onFail(null);
      }
    );
  };

  const fetchAllData = (tabledata: any) => {
    tabledata.forEach((table: any) => {
      switch (table.name) {
        case REMOTE_CONFIG_TABLES.DRAFTS:
          dispatch(addTableId(table._id));
          // let isSaved: any = Utility.getKeyOfColumn(
          //   table.columnsMetaData,
          //   COLUMN_CODE.DRAFTS.isSaved
          // );
          // dispatch(
          //   fetchDrafts({
          //     tableId: table._id,
          //     isSaveColumnId: isSaved[0].id
          //   })
          // );
          break;
        default:
          break;
      }
    });
  };
  const setupColumConfigs = (configs: any[]) => {
    configs.forEach((config) => {
      const columnsMetaData = config?.columnsMetaData;
      const tableId = config._id;
      switch (config.name) {
        case REMOTE_CONFIG_TABLES.CONTACT:
          dispatch(
            addContactColumnConfig({ tableId, config: columnsMetaData })
          );
          break;
        case REMOTE_CONFIG_TABLES.INVOICE:
          dispatch(
            addInvoiceColumnConfig({ tableId, config: columnsMetaData })
          );
          dispatch(addGenerateBankFileColumnConfig(columnsMetaData));
          break;
        case REMOTE_CONFIG_TABLES.PURCHASE_REQUISITION:
          dispatch(
            requisitionColumnConfig({ tableId, config: columnsMetaData })
          );
          break;

        case REMOTE_CONFIG_TABLES.SALES_ORDER:
          dispatch(
            addSalesOrderColumnConfig({ tableId, config: columnsMetaData })
          );
          break;
        case REMOTE_CONFIG_TABLES.PRODUCT:
          dispatch(
            addProductColumnConfig({ tableId, config: columnsMetaData })
          );
          break;
        case REMOTE_CONFIG_TABLES.BILL:
          dispatch(addBillColumnConfig({ tableId, config: columnsMetaData }));
          break;
        case REMOTE_CONFIG_TABLES.PURCHASE_ORDER:
          dispatch(addOrderColumnConfig({ tableId, config: columnsMetaData }));
          break;
        case REMOTE_CONFIG_TABLES.QUOTATION:
          dispatch(addQuoteColumnConfig({ tableId, config: columnsMetaData }));
          break;
        case REMOTE_CONFIG_TABLES.DRAFTS:
          dispatch(addDraftColumnConfig(columnsMetaData));
          break;
        case REMOTE_CONFIG_TABLES.DEPOSIT:
          dispatch(
            addDepositColumnConfig({ tableId, config: columnsMetaData })
          );
          break;
        case REMOTE_CONFIG_TABLES.COA:
          dispatch(addCoaColumnConfig({ tableId, config: columnsMetaData }));
          break;
        case REMOTE_CONFIG_TABLES.PRICE_LIST:
          dispatch(addPriceColumnConfig(columnsMetaData));
          break;
        case REMOTE_CONFIG_TABLES.EXPENSE:
          dispatch(
            addExpenseColumnConfig({ tableId, config: columnsMetaData })
          );
          break;
        case REMOTE_CONFIG_TABLES.JOURNAL:
          dispatch(
            addJournalColumnConfig({ tableId, config: columnsMetaData })
          );
          break;
        case REMOTE_CONFIG_TABLES.FINANCIAL_YEAR_CLOSING:
          dispatch(
            addFinancialYearClosingColumnConfig({
              tableId,
              config: columnsMetaData
            })
          );
          break;
        case REMOTE_CONFIG_TABLES.CREDIT_NOTES:
          dispatch(
            addCreditNoteColumnConfig({ tableId, config: columnsMetaData })
          );
          break;
        case REMOTE_CONFIG_TABLES.DEBIT_NOTES:
          dispatch(
            addDebitNoteColumnConfig({ tableId, config: columnsMetaData })
          );
          break;
        case REMOTE_CONFIG_TABLES.ACCOUNTS:
          dispatch(
            addAccountsColumnConfig({ tableId, config: columnsMetaData })
          );
          break;
        case REMOTE_CONFIG_TABLES.INVENTORY:
          dispatch(
            addInventoryColumnConfig({ tableId, config: columnsMetaData })
          );
          break;
        case REMOTE_CONFIG_TABLES.WAREHOUSE:
          dispatch(
            addWarehouseColumnConfig({ tableId, config: columnsMetaData })
          );
          break;
        case REMOTE_CONFIG_TABLES.OB_INVOICES:
          dispatch(
            addOBInvoiceColumnConfig({ tableId, config: columnsMetaData })
          );
          break;
        case REMOTE_CONFIG_TABLES.BANKS:
          dispatch(addBanksColumnConfig({ tableId, config: columnsMetaData }));
          break;
        case REMOTE_CONFIG_TABLES.BANK_RULES:
          dispatch(
            addBankRulesColumnConfig({ tableId, config: columnsMetaData })
          );
          break;
        case REMOTE_CONFIG_TABLES.PAYMENT_TERMS:
          dispatch(
            addPaymentTermsColumnConfig({ tableId, config: columnsMetaData })
          );
          break;
        case REMOTE_CONFIG_TABLES.AUDIT_LOG:
          dispatch(addAuditLogColumnConfig(columnsMetaData));
          break;
        case REMOTE_CONFIG_TABLES.CURRENCY:
          dispatch(addCurrencyColumnConfig(columnsMetaData));
          break;
        case REMOTE_CONFIG_TABLES.TAX_TABLE:
          dispatch(addTaxColumnConfig({ tableId, config: columnsMetaData }));
          break;

        case REMOTE_CONFIG_TABLES.UOM:
          dispatch(addUomColumnConfig({ tableId, config: columnsMetaData }));
          break;
        case REMOTE_CONFIG_TABLES.BANK_DETAILS_DK:
          dispatch(
            addDeskeraTransactionsColumnConfig({
              tableId,
              config: columnsMetaData
            })
          );
          break;
        case REMOTE_CONFIG_TABLES.BANK_DETAILS_STATEMENT:
          dispatch(
            addBankTransactionsColumnConfig({
              tableId,
              config: columnsMetaData
            })
          );
          break;
        case REMOTE_CONFIG_TABLES.IMPORT_LOG:
          dispatch(addImportLogColumnConfig(columnsMetaData));
          break;
        case REMOTE_CONFIG_TABLES.IMPORT_DRAFT:
          dispatch(addImportDraftColumnConfig(columnsMetaData));
          break;
        case REMOTE_CONFIG_TABLES.UOM_SCHEMA:
          dispatch(
            addUomSchemaColumnConfig({ tableId, config: columnsMetaData })
          );
          break;
        case REMOTE_CONFIG_TABLES.AUTOMATION:
          dispatch(
            addAutomationColumnConfig({ tableId, config: columnsMetaData })
          );
          break;
        case REMOTE_CONFIG_TABLES.STOCK_ADJUSTMENT:
          dispatch(
            addStockAdjustmentColumnConfig({ tableId, config: columnsMetaData })
          );
          break;
        case REMOTE_CONFIG_TABLES.STOCK_TRANSFER:
          dispatch(
            addStockTransferColumnConfig({ tableId, config: columnsMetaData })
          );
          break;
        case REMOTE_CONFIG_TABLES.STOCK_ISSUE:
          dispatch(
            addStockTransferColumnConfig({ tableId, config: columnsMetaData })
          );
          break;
        case REMOTE_CONFIG_TABLES.STOCK_REQUEST:
          dispatch(
            addStockTransferColumnConfig({ tableId, config: columnsMetaData })
          );
          break;
        case REMOTE_CONFIG_TABLES.WORK_ORDERS:
          dispatch(
            addWorkOrderColumnConfig({ tableId, config: columnsMetaData })
          );
          break;
        case REMOTE_CONFIG_TABLES.MRP_BOM_EXPLOSION:
          dispatch(
            addBOMExplosionColumnConfig({ tableId, config: columnsMetaData })
          );
          break;
        case REMOTE_CONFIG_TABLES.MRP_PRODUCT_SUBSTITUTE:
          dispatch(
            addProductSubstituteColumnConfig({
              tableId,
              config: columnsMetaData
            })
          );
          break;
        case REMOTE_CONFIG_TABLES.WORKSTATIONS:
          dispatch(
            addWorkStationColumnConfig({ tableId, config: columnsMetaData })
          );
          break;
        case REMOTE_CONFIG_TABLES.OPERATORS:
          dispatch(
            addOperatorColumnConfig({ tableId, config: columnsMetaData })
          );
          break;
        case REMOTE_CONFIG_TABLES.OPERATIONS:
          dispatch(
            addOperationsColumnConfig({ tableId, config: columnsMetaData })
          );
          break;
        case REMOTE_CONFIG_TABLES.WORK_OUT:
          dispatch(
            addWorkoutColumnConfig({ tableId, config: columnsMetaData })
          );
          break;
        case REMOTE_CONFIG_TABLES.BOOKS_REPORTS_SALES_ORDER_DETAILS:
          dispatch(
            addReportSODetailsColumnConfig({ tableId, config: columnsMetaData })
          );
          break;
        case REMOTE_CONFIG_TABLES.BOOKS_REPORT_SKU_ORDER_TRACKING:
          dispatch(
            addMRPReportsSKUOrderTrackingDetailsColumnConfig({
              tableId,
              config: columnsMetaData
            })
          );
          break;

        case REMOTE_CONFIG_TABLES.BOOKS_REPORTS_MRP_MACHINE_MASTER_TOOLS:
          dispatch(
            addMachineToolMasterColumnConfig({
              tableId,
              config: columnsMetaData
            })
          );
          break;
        case REMOTE_CONFIG_TABLES.MRP_MACHINE_ASSET_MAINTAINCE_SCHEUDLE:
          dispatch(
            addMachineSchedulesColumnConfig({
              tableId,
              config: columnsMetaData
            })
          );
          break;
        case REMOTE_CONFIG_TABLES.BOOKS_REPORT_GRN_REPORT:
          dispatch(
            addGRNColumnConfig({
              tableId,
              config: columnsMetaData
            })
          );
          break;
        case REMOTE_CONFIG_TABLES.BOOKS_REPORT_FULFILLMENT_REPORT:
          dispatch(
            addFulfillmentReportColumnConfig({
              tableId,
              config: columnsMetaData
            })
          );
          break;
        case REMOTE_CONFIG_TABLES.BOOKS_GOODS_RECEIPT_MASTER:
          dispatch(addGoodsReceiptColumnConfig(columnsMetaData));
          break;
        case REMOTE_CONFIG_TABLES.BOOKS_GOODS_RECEIPT_APPROVAL_LIST:
          dispatch(addGoodsReceiptApprovalColumnConfig(columnsMetaData));
          break;
        case REMOTE_CONFIG_TABLES.BOOKS_FULFILLMENT_MASTER:
          dispatch(addFulfillmentMasterColumnConfig(columnsMetaData));
          break;
        case REMOTE_CONFIG_TABLES.BOOKS_FULFILLMENT_APPROVAL_LIST:
          dispatch(addFulfillmentApprovalColumnConfig(columnsMetaData));
          break;
        case REMOTE_CONFIG_TABLES.AMORTIZATION_SCHEDULES:
          dispatch(
            addAmortizationSchedulesColumnConfig({
              tableId,
              config: columnsMetaData
            })
          );
          break;
        case REMOTE_CONFIG_TABLES.PAYMENT_LOGS:
          dispatch(addPaymentLogsColumnConfig(columnsMetaData));
          break;
        case REMOTE_CONFIG_TABLES.UPLOAD_TO_FTP_LOGS:
          dispatch(addUploadToFTPLogsColumnConfig(columnsMetaData));
          break;
        default:
          break;
      }
    });
  };

  const loadFeaturePermissionsInfo = async () => {
    try {
      await dispatch(fetchFeaturePermissions());
    } catch (err) {
      console.error('Error loading feature permission: ', err);
    }
  };

  const loadCustomImportFormatList = async () => {
    try {
      await dispatch(fetchCustomImportFormats());
    } catch (err) {
      console.error('Error loading custom import formats: ', err);
    }
  };

  const isMrpRoute = window.location.pathname.startsWith(
    PAGE_ROUTES.MRP_ROUTE_PREFIX
  );
  if (!isAccessingMRPURL() && isMrpRoute) {
    return (
      <Router basename="/">
        <Redirect to={PAGE_ROUTES.DASHBOARD} />
      </Router>
    );
  }

  if (orgSetupCompleted) {
    return (
      <>
        {initialApiResponseReceived && timeoutData && (
          <InactivityTimer
            name={ApiConstants.IDLE_TIMER_ID}
            inactivityTimeoutEnabled={timeoutData.idleTimeoutEnabled}
            inactivityTimeout={timeoutData.idleTimeoutValue}
            tokenExpiryNotifyEnabled={timeoutData.tokenExpiryEnabled}
            tokenExpiryTime={timeoutData.tokenExpiryTime}
            autoLogoutTime={ApiConstants.AUTO_LOGOUT_TIME}
            refreshTokenUrl={
              ApiConstants.URL.BASE + ApiConstants.URL.IAM.REFRESH_TOKEN
            }
            logoutUrl={ApiConstants.URL.BASE + ApiConstants.URL.IAM.LOG_OUT_V2}
            redirectUrl={ApiConstants.URL.IAM.REDIRECT}
          />
        )}
        {isTabletView() && isMobileWebView() ? (
          <Router basename="/">
            <div
              className={`flex flex-row items-start w-full h-full box-border fixed justify-start left-0 top-0 text-center text-13`}
            >
              <div
                className="parent-width parent-height column "
                style={{ flex: 1 }}
              >
                <Switch>
                  {initialApiResponseReceived && (
                    <Route path="/" component={TabletToolbar} />
                  )}
                </Switch>
                <Switch>
                  {initialApiResponseReceived && (
                    <Route path="/" component={DraftPopup} />
                  )}
                </Switch>
                <Switch>
                  {initialApiResponseReceived && (
                    <Route path="/" component={DocumentForm} />
                  )}
                </Switch>
                <div
                  id="containerDiv"
                  className={
                    'parent-width column position-relative dk-input-holder ' +
                    (AppManager.isPublicURL() ? '' : '') +
                    (isMacintosh() ? '' : 'show-scroll-bar')
                  }
                  style={{
                    overflowY: 'auto',
                    flexGrow: 1
                  }}
                >
                  <ErrorBoundary>
                    <Suspense
                      fallback={
                        <div
                          style={{
                            overflowY: 'auto',
                            flexGrow: 1
                          }}
                        />
                      }
                    >
                      <Switch>
                        {initialApiResponseReceived &&
                          routingPaths.map(
                            (module: IRoutingPermissionGuard) => (
                              <Route
                                exact
                                path={module.path}
                                component={module.component}
                              />
                            )
                          )}
                      </Switch>
                    </Suspense>
                  </ErrorBoundary>
                </div>
              </div>
            </div>
          </Router>
        ) : (
          <Router basename="/">
            <div
              className={`flex flex-row items-start w-full h-full box-border justify-start left-0 top-0 fixed text-center text-13`}
            >
              {/* LEFT MENU */}
              <Switch>
                {initialApiResponseReceived && !AppManager.isPublicURL() && (
                  <Route path="/" component={LeftMenu} />
                )}
              </Switch>
              {/* END - LEFT MENU */}
              <div
                className="parent-width parent-height column "
                style={{ flex: 1 }}
              >
                {/* TOP MENU */}
                <Switch>
                  {initialApiResponseReceived && !AppManager.isPublicURL() && (
                    <Route path="/" component={TopMenu} />
                  )}
                </Switch>
                {/* END - TOP MENU */}

                {/* DRAFT */}
                <Switch>
                  {initialApiResponseReceived && !AppManager.isPublicURL() && (
                    <Route path="/" component={DraftPopup} />
                  )}
                </Switch>
                <Switch>
                  {initialApiResponseReceived && (
                    <Route path="/" component={DocumentForm} />
                  )}
                </Switch>
                {/* END - DRAFT */}

                {initialApiResponseReceived && (
                  <div
                    id="containerDiv"
                    className={
                      'parent-width column position-relative dk-input-holder ' +
                      (AppManager.isPublicURL() ? '' : '') +
                      (isMacintosh() ? '' : 'show-scroll-bar')
                    }
                    style={{
                      overflowY: 'auto',
                      flexGrow: 1
                    }}
                  >
                    {showWelcomePopup && tenantInfo && (
                      <WelcomePopup
                        tenantInfo={tenantInfo}
                        onShowWalkthrough={() => {
                          updateWalkthroughStatus(true, false);
                          setShowWalkthrough(true);
                          setShowWelcomePopup(false);
                        }}
                        onSkipWalkthrough={() => {
                          updateWalkthroughStatus(false, true);
                          setShowWelcomePopup(false);
                        }}
                      />
                    )}
                    {showWalkthrough && openWalkthrough()}
                    {getNoPermissionForBooksView()}
                    {!showNoPermissionView &&
                      showNoMRPEnableAlert &&
                      getNoMRPView()}
                    <ErrorBoundary>
                      <Suspense
                        fallback={
                          <div className="row flex-1 justify-content-center">
                            <DKSpinner />
                          </div>
                        }
                      >
                        {renderRouteMenu && !isBookKeeper && (
                          <Switch>
                            <Route
                              exact
                              path={PAGE_ROUTES.DASHBOARD}
                              component={Dashboard}
                            />
                            <Route path={PAGE_ROUTES.ONBOARDING}>
                              <Redirect to="/" from={PAGE_ROUTES.ONBOARDING} />
                            </Route>
                            <Route path={PAGE_ROUTES.GETTING_STARTED}>
                              <Redirect
                                to="/"
                                from={PAGE_ROUTES.GETTING_STARTED}
                              />
                            </Route>
                            {/* Check Module Routing Permission */}
                            {isBookKeeper &&
                              routingPaths.map(
                                (module: IRoutingPermissionGuard) => (
                                  <Route
                                    exact
                                    path={module.path}
                                    component={module.component}
                                  />
                                )
                              )}
                            {/* Temporary Commented */}
                            {routingPaths.map(
                              (module: IRoutingPermissionGuard) => {
                                const hasModulePermission = checkUserPermission(
                                  module.permission
                                );

                                /* Removing document loader, if present, without requested module permission */
                                if (
                                  !hasModulePermission &&
                                  AppManager.isCurrentRoute(module.path) &&
                                  isDocumentLoaderVisibleRef.current
                                ) {
                                  isDocumentLoaderVisibleRef.current = false;
                                  removeLoader();
                                }

                                return (
                                  <Route
                                    exact
                                    path={
                                      hasModulePermission
                                        ? module.path
                                        : PAGE_ROUTES.DASHBOARD
                                    }
                                    component={
                                      hasModulePermission
                                        ? module.component
                                        : Dashboard
                                    }
                                  />
                                );
                              }
                            )}
                            {/* Temporary Commented */}
                            {/* Check Module Routing Permission */}
                            <Route render={() => <Redirect to="/" />} />
                          </Switch>
                        )}
                        {/* bookkeeper */}
                        {isBookKeeper && (
                          <Switch>
                            <Route
                              exact
                              path={PAGE_ROUTES.DASHBOARD}
                              component={Dashboard}
                            />
                            <Route path={PAGE_ROUTES.ONBOARDING}>
                              <Redirect to="/" from={PAGE_ROUTES.ONBOARDING} />
                            </Route>
                            <Route path={PAGE_ROUTES.GETTING_STARTED}>
                              <Redirect
                                to="/"
                                from={PAGE_ROUTES.GETTING_STARTED}
                              />
                            </Route>
                            {/* Check Module Routing Permission */}
                            {isBookKeeper &&
                              routingPaths.map(
                                (module: IRoutingPermissionGuard) => (
                                  <Route
                                    exact
                                    path={module.path}
                                    component={module.component}
                                  />
                                )
                              )}
                            {/* Temporary Commented */}
                            {/* Check Module Routing Permission */}
                            <Route render={() => <Redirect to="/" />} />
                          </Switch>
                        )}
                      </Suspense>
                    </ErrorBoundary>
                  </div>
                )}
              </div>
            </div>
          </Router>
        )}
      </>
    );
  } else {
    return (
      <Router basename="/">
        <Redirect from="/" to={PAGE_ROUTES.ONBOARDING} />
        <Switch>
          <Route exact path={PAGE_ROUTES.ONBOARDING} component={Onboarding} />
          <Route
            exact
            path={PAGE_ROUTES.GETTING_STARTED}
            render={(props) => (
              <GettingStarted details={tenantInfo} {...props} />
            )}
          />
        </Switch>
      </Router>
    );
  }
};

export default App;
