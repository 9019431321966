import { DKIcons, showAlert } from 'deskera-ui-library';
import {
  BOOKS_DATE_FORMAT,
  DOC_TYPE,
  DOCUMENT_MODE,
  GST_TYPE,
  PRODUCT_TYPE,
  TAX_SYSTEM
} from '../../../../Constants/Constant';
import { Store } from '../../../../Redux/Store';
import {
  getBlankRowItem,
  isDropShipDocument,
  isSalesDocument
} from '../../Utilities/DocCommonUtils';
import ic_barcode_green from '../../../../Assets/Icons/ic_barcode_green.svg';
import ic_barcode_red from '../../../../Assets/Icons/ic_barcode_red.svg';
import ic_inventory_fulfill from '../../../../Assets/Icons/ic_inventory_fulfill.svg';
import ic_inventory_pending from '../../../../Assets/Icons/ic_inventory_pending.svg';
import { getTenantTaxSystem } from '../../../../SharedComponents/DocumentForm/NewDocumentHelper';
import Utility from '../../../../Utility/Utility';
import { getDocumentByIDFromStore } from '../DocumentHelper';
import {
  addCopyOfRow,
  calculateTaxesAndAmountsForAllLineItems,
  deleteItem,
  insertNewRow
} from '../../../../Redux/Slices/DocumentSlice';
import {
  COMMON_EVENTS,
  DOC_POPUP_TYPE,
  commonCustomEvent
} from '../../../../Services/event/commonEvents';
import { DEFAULT_TDS_PAYABLE_ACCOUNT_CODE } from '../../../../Models/TDS';
import DateFormatService from '../../../../Services/DateFormat';
import { fetchProductReservedInfo } from '../DocRowHelper';
import { COUNTRIES_WITH_NO_MANUAL_TAX_UPDATE } from '../../Utilities/DocConstants';

export const getRowContextMenu = ({
  draftId,
  draftType,
  row,
  productCustomFields,
  documentMode,
  columns
}: any): any[] => {
  const { country: tenantCountry, landedCostSettings } =
    Store.getState().authInfo.currentTenantInfo.data;
  const document: any = getDocumentByIDFromStore(draftId) ?? {};
  const {
    documentDate,
    contactDto,
    documentType,
    contact,
    reservedStock,
    landedCost,
    items,
    linkedDocuments,
    isPartialInvoice,
    isPartialBill,
    isPartialSalesOrder,
    dropShip,
    fulfillmentType
  } = document?.populateFormData ?? {};

  const contextMenu: any[] = [];

  if (
    reservedStock &&
    isSalesDocument(documentType) &&
    [PRODUCT_TYPE.TRACKED, PRODUCT_TYPE.BILL_OF_MATERIALS].includes(
      row?.product?.type
    )
  ) {
    contextMenu.push({
      title: row.reservedQuantitiesData
        ? 'Update reserved stock'
        : 'Add reserved stock',
      icon: row.reservedQuantitiesData ? ic_barcode_green : ic_barcode_red,
      className: ' p-0',
      onClick: (data: any) => {
        fetchProductReservedInfo(data, items)
          .then((response) => {
            if (Utility.isNotEmpty(response)) {
              commonCustomEvent.dispatch(COMMON_EVENTS.DOC_POPUP_SHOW, {
                type: DOC_POPUP_TYPE.SHOW_RESERVE_STOCK_POPUP,
                selectedProductInfo: response?.selectedProductInfo,
                lineItem: response?.lineItem
              });
            }
          })
          .catch((error) => {
            console.error('error', error);
          });
      }
    });
  }

  if (tenantCountry === TAX_SYSTEM.INDIA_GST) {
    contextMenu.push({
      title: 'HSN/SAC',
      icon: DKIcons.ic_edit,
      className: ' p-0',
      onClick: (data: any) => {
        commonCustomEvent.dispatch(COMMON_EVENTS.DOC_POPUP_SHOW, {
          type: DOC_POPUP_TYPE.SHOW_HSN_SAC_POPUP,
          ...data
        });
      }
    });
  }

  const onDeductTDS = ({ rowIndex, rowData }: any) => {
    const lineAmount = rowData.totalWithDiscount;
    const payableAccount = DEFAULT_TDS_PAYABLE_ACCOUNT_CODE;
    const assessableAmount = lineAmount;
    const natureOfPayment = rowData?.product?.tdsNatureOfPaymentIndia;

    if (!contact && !documentDate && !natureOfPayment) return;

    let tdsInfoData = {
      lineAmount,
      assessableAmount,
      incomePayment: rowData?.product?.tdsNatureOfPaymentIndia,
      payableAccount,
      contact: contact.tdsInfoIndia ? contact : contactDto,
      documentDate: DateFormatService.getFormattedDateString(
        documentDate,
        BOOKS_DATE_FORMAT['DD-MM-YYYY'],
        BOOKS_DATE_FORMAT['YYYY-MM-DD']
      ),
      isDeductedTds: false
    };
    if (
      rowData?.tdsInfoIndia &&
      tdsInfoData.lineAmount === rowData.tdsInfoIndia.lineAmount
    ) {
      tdsInfoData = {
        ...rowData.tdsInfoIndia,
        contact: contact.tdsInfoIndia ? contact : contactDto,
        isDeductedTds: true,
        documentDate: DateFormatService.getFormattedDateString(
          documentDate,
          BOOKS_DATE_FORMAT['DD-MM-YYYY'],
          BOOKS_DATE_FORMAT['YYYY-MM-DD']
        )
      };
    }
    return tdsInfoData;
  };

  if (
    documentType === DOC_TYPE.BILL &&
    contact?.tdsInfoIndia?.deductionApplicable &&
    row?.product?.tdsApplicableIndia
  ) {
    contextMenu.push({
      title: 'Deduct TDS',
      icon: DKIcons.ic_add,
      className: ' p-0',
      onClick: async (data: any) => {
        let tdsInfoData = await onDeductTDS(data);
        if (tdsInfoData) {
          commonCustomEvent.dispatch(COMMON_EVENTS.DOC_POPUP_SHOW, {
            type: DOC_POPUP_TYPE.SHOW_TDS_CALCULATION_POPUP,
            ...data,
            tdsInfoData: tdsInfoData
          });
        } else {
          showAlert('Can not create tds data');
        }
      }
    });
  }

  const onOpenITCOptions = ({ rowIndex, rowData }: any) => {
    let itcValue = rowData.itcIneligibleType;
    return { rowIndex: rowIndex, itcValue: itcValue };
  };

  if (
    getTenantTaxSystem() === TAX_SYSTEM.INDIA_GST &&
    Utility.isNotEmpty(row?.product) &&
    [DOC_TYPE.BILL, DOC_TYPE.ORDER, DOC_TYPE.JOB_WORK_OUT_ORDER].includes(
      documentType
    )
  ) {
    contextMenu.push({
      title: 'ITC',
      icon: DKIcons.ic_edit,
      className: ' p-0',
      onClick: (data: any) => {
        let itcValue = onOpenITCOptions(data);
        if (itcValue) {
          commonCustomEvent.dispatch(COMMON_EVENTS.DOC_POPUP_SHOW, {
            type: DOC_POPUP_TYPE.SHOW_ITC_INFO_POPUP,
            ...data,
            itcValue: itcValue
          });
        } else {
          showAlert('Can not create tds data');
        }
      }
    });
  }

  const allocateLandedCost = (data: any) => {
    commonCustomEvent.dispatch(COMMON_EVENTS.DOC_POPUP_SHOW, {
      ...data,
      type: DOC_POPUP_TYPE.SHOW_LANDED_COST_POPUP
    });
  };

  if (
    landedCostSettings?.landedCost &&
    documentType === DOC_TYPE.BILL &&
    landedCost
  ) {
    contextMenu.push({
      title: 'Allocate Cost',
      icon: DKIcons.ic_edit,
      className: ' p-0',
      onClick: (data: any) => {
        allocateLandedCost(data);
      }
    });
  }

  if (
    getTenantTaxSystem() === TAX_SYSTEM.INDIA_GST &&
    row?.gstType !== GST_TYPE.EXEMPT
  ) {
    contextMenu.push({
      title: 'Tax',
      icon: DKIcons.ic_edit,
      className: ' p-0',
      onClick: (data: any) => {
        if (Utility.isEmpty(contact)) {
          showAlert('Warning!', 'Please select contact to edit tax.');
          return;
        }
        commonCustomEvent.dispatch(COMMON_EVENTS.DOC_POPUP_SHOW, {
          type: DOC_POPUP_TYPE.SHOW_GST_VALUE_FORM_POPUP,
          ...data
        });
      }
    });
  } else if (
    !COUNTRIES_WITH_NO_MANUAL_TAX_UPDATE.includes(getTenantTaxSystem())
  ) {
    contextMenu.push({
      title: 'Tax',
      icon: DKIcons.ic_edit,
      className: ' p-0',
      onClick: (data: any) => {
        commonCustomEvent.dispatch(COMMON_EVENTS.DOC_POPUP_SHOW, {
          type: DOC_POPUP_TYPE.SHOW_TAX_AMOUNT_VALUE_FORM_POPUP,
          ...data
        });
      }
    });
  }
  contextMenu.push({
    title: 'Arrange Custom Fields',
    icon: DKIcons.ic_settings,
    className: ' p-0',
    onClick: (data: any) => {
      commonCustomEvent.dispatch(COMMON_EVENTS.DOC_POPUP_SHOW, {
        productCustomFields,
        type: DOC_POPUP_TYPE.SHOW_PRODUCT_CUSTOM_FIELD
      });
    }
  });

  // Inserts new line item at given rowIndex
  const insertNewItem = (rowIndex: number) => {
    const newRow = getBlankRowItem(
      document,
      columns,
      documentMode,
      draftType,
      productCustomFields || []
    );
    Store.dispatch(insertNewRow({ rowIndex, draftId, newRow }));
  };

  if (
    !(
      isPartialInvoice ||
      isPartialBill ||
      isPartialSalesOrder ||
      isDropShipDocument({ dropShip, fulfillmentType }) ||
      documentMode === DOCUMENT_MODE.VIEW ||
      (documentType === DOC_TYPE.ORDER &&
        linkedDocuments?.[0]?.documentType === 'PURCHASE_REQUEST')
    )
  ) {
    contextMenu.push({
      title: 'Insert',
      icon: DKIcons.ic_add,
      className: ' p-0',
      onClick: (data: any, index: any) => {
        insertNewItem(data.rowIndex);
      }
    });

    contextMenu.push({
      title: 'Copy',
      icon: DKIcons.ic_copy,
      className: ' p-0',
      onClick: (data: any, index: any) => {
        Store.dispatch(addCopyOfRow({ rowIndex: data.rowIndex, draftId }));
        Store.dispatch(calculateTaxesAndAmountsForAllLineItems({ draftId }));
      }
    });
  }

  if (linkedDocuments?.[0]?.documentType !== DOC_TYPE.PURCHASE_REQUEST) {
    contextMenu.push({
      title: 'Delete',
      icon: DKIcons.ic_delete,
      className: ' p-0',
      onClick: (data: any) => {
        Store.dispatch(deleteItem({ rowIndex: data.rowIndex, draftId }));
        Store.dispatch(calculateTaxesAndAmountsForAllLineItems({ draftId }));
      }
    });
  }

  return contextMenu;
};

export const getRowButtons = ({ draftId, row }: any): any[] => {
  let buttons: any[] = [];
  const document: any = getDocumentByIDFromStore(draftId) ?? {};

  const { documentType, reservedStock, items } =
    document?.populateFormData ?? {};
  if (
    isSalesDocument(documentType) &&
    reservedStock &&
    row.product &&
    (row.product.type === PRODUCT_TYPE.TRACKED ||
      row.product.type === PRODUCT_TYPE.BILL_OF_MATERIALS)
  ) {
    buttons.push({
      title: '',
      icon: row.reservedQuantitiesData
        ? ic_inventory_fulfill
        : ic_inventory_pending,
      className: 'p-v-0 inventory-icon-document',
      onClick: (data: any) => {
        fetchProductReservedInfo(data, items)
          .then((response) => {
            if (Utility.isNotEmpty(response)) {
              commonCustomEvent.dispatch(COMMON_EVENTS.DOC_POPUP_SHOW, {
                type: DOC_POPUP_TYPE.SHOW_RESERVE_STOCK_POPUP,
                selectedProductInfo: response?.selectedProductInfo,
                lineItem: response?.lineItem
              });
            }
          })
          .catch((error) => {
            console.error('error', error);
          });
      }
    });
  }
  return buttons;
};
