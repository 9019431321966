import { DateFormat } from "../../Constants/Constants";
import CustomFieldManager from "../../Manager/CustomFieldManager";
import { getCapitalized, getDateFrom, getDateString } from "../../Utilities/Utility";
import Address from "../Address";
import AddressParser, { AddressType, OwnerType } from "../AddressParser";
import DataParser from "../DataParser";
import LineItemsParser from "../LineItemsParser";

export default class StockAdjustmentParser {
    static parseData(dataFromAPI, templateDataToReturn) {

        //custom field
        templateDataToReturn.customFields = dataFromAPI.stockAdjustmentInfo?.customField ? dataFromAPI.stockAdjustmentInfo?.customField : []
        CustomFieldManager.setCustomFields(templateDataToReturn.customFields)
        templateDataToReturn = DataParser.assignCustomFieldValue(templateDataToReturn)

        //company name
        templateDataToReturn.companyName = getCapitalized(dataFromAPI.tenantInfo?.name)
        templateDataToReturn.tenantInfo = dataFromAPI.tenantInfo;

        //company address
        var fromAddressObj = new Address(undefined, AddressParser.getTenantAddressFromApi(dataFromAPI.shipFromAddress, dataFromAPI.tenantInfo), dataFromAPI.pan, dataFromAPI.gstin, dataFromAPI.tenantInfo.contactNumber, undefined, OwnerType.tenant)
        templateDataToReturn.fromObj = fromAddressObj
        templateDataToReturn = AddressParser.assignAddressObjToStringForNonArabic(undefined, AddressType.from, templateDataToReturn)

        //ref number
        templateDataToReturn.refNumber = dataFromAPI.stockAdjustmentInfo?.code

        // templateDataToReturn.date = getConvertedDate(convertDateFromERPFormatToDocFormat(dataFromAPI.stockAdjustmentInfo?.adjustmentDate), templateDataToReturn.dateFormat)
        
        templateDataToReturn.date = getDateString(getDateFrom(dataFromAPI.stockAdjustmentInfo?.adjustmentDate, DateFormat.YYYY_MM_DD_DASH), templateDataToReturn.dateFormat)

        // templateDataToReturn.type = ''
        var adjustmentType = dataFromAPI.stockAdjustmentInfo?.adjustmentType
        templateDataToReturn.adjustmentType = ''
        if(adjustmentType !== undefined && adjustmentType !== null) {
            var type = this.StockAdjustmentType.find(x => x.value === adjustmentType)
            if(type) {
                templateDataToReturn.adjustmentType = type.name
            }
            else {
                templateDataToReturn.adjustmentReason = adjustmentType
            }
        }

        var adjustmentReason = dataFromAPI.stockAdjustmentInfo?.adjustmentReason
        templateDataToReturn.adjustmentReason = ''
        if (adjustmentReason !== undefined && adjustmentReason !== null) {
            var reason = this.StockOutReasons.find(x => x.value === adjustmentReason)
            if (reason) {
                templateDataToReturn.adjustmentReason = reason.name
            }
            else {
                templateDataToReturn.adjustmentReason = adjustmentReason
                var stockAdjustmentReason = dataFromAPI.stockAdjustmentInfo?.stockAdjustmentReason
                if (stockAdjustmentReason) {
                    templateDataToReturn.adjustmentReason = stockAdjustmentReason.reason
                }
            }
        }

        templateDataToReturn.warehouse = dataFromAPI.stockAdjustmentInfo?.warehouseCode

        if (dataFromAPI.stockAdjustmentInfo?.warehouseName !== undefined
            && dataFromAPI.stockAdjustmentInfo?.warehouseName !== null) {
            if (dataFromAPI.stockAdjustmentInfo?.warehouseNam !== '') {
                templateDataToReturn.warehouse = dataFromAPI.stockAdjustmentInfo?.warehouseName
            }
        }

        //line items
        templateDataToReturn.lineItems = LineItemsParser.getStockAdjustmentItems(dataFromAPI, templateDataToReturn.dateFormat)

        templateDataToReturn.notes = dataFromAPI.stockAdjustmentInfo?.notes
        return templateDataToReturn
    }

    static StockOutReasons = [
        { name: 'Damaged', value: 'DAMAGED' },
        { name: 'Shrinkage', value: 'SHRINKAGE' },
        { name: 'Stock Take', value: 'STOCKTAKE' },
        { name: 'Other', value: 'OTHER' }
    ];

    static StockAdjustmentType = [
        { name: 'Stock In', value: 'STOCK_IN' },
        { name: 'Stock Out', value: 'STOCK_OUT' }
    ];

}
